import { useMutation } from '@tanstack/react-query'
import { Checkbox, Flex, Typography } from 'antd'
import { Formik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import { getAllSystemCurrency, updateSystemCurrency } from '../../asyncThunks'
import { readMoneyInvoice, zeroInten, zeroInThousand } from '../../constants'
import { useSystemConfigSelector } from '../../SystemConfigurationSlice'
import FooterCard from '../FooterCard'

export default function TabCurrencySetting() {
  const { currency } = useSystemConfigSelector()
  const dispatch = useAppDispatch()

  const handleSaveChange = useMutation({
    mutationKey: ['systemConfig', 'currency'],
    mutationFn: (values) => dispatch(updateSystemCurrency(values)).unwrap(),
    onSettled: async () => {
      ToastHelper.showSuccess('Cập nhật thành công')
      await dispatch(getAllSystemCurrency())
    },
  })

  const readMoneyPositive = (value) => {
    if (value == '[Để trống/Âm][Tổng tiền thanh toán]') return 'Một triệu đồng'
    if (value == '[Tăng/Giảm][Tổng tiền thanh toán]')
      return 'Tăng một triệu đồng'
    if (value == 'Điều chỉnh [tăng giảm][Tổng tiền thanh toán]')
      return ' Điều chỉnh tăng một triệu đồng'
    if (value == '[Tổng tiền thanh toán][Tăng/Giảm]')
      return 'Một triệu đồng (Tăng)'
    if (value == '[Tổng tiền thanh toán](Điều chỉnh [tăng giảm])')
      return 'Một triệu đồng (Điều chỉnh tăng)'
    if (value == '[Tổng tiền thanh toán]') return 'Một triệu đồng'
  }

  const readMoneyNegative = (value) => {
    if (value == '[Để trống/Âm][Tổng tiền thanh toán]')
      return 'Âm một triệu đồng'
    if (value == '[Tăng/Giảm][Tổng tiền thanh toán]')
      return 'Giảm một triệu đồng'
    if (value == 'Điều chỉnh [tăng giảm][Tổng tiền thanh toán]')
      return 'Điều chỉnh giảm một triệu đồng'
    if (value == '[Tổng tiền thanh toán][Tăng/Giảm]')
      return 'Một triệu đồng (Giảm)'
    if (value == '[Tổng tiền thanh toán](Điều chỉnh [tăng giảm])')
      return 'Một triệu đồng (Điều chỉnh giảm)'
    if (value == '[Tổng tiền thanh toán]') return 'Một triệu đồng'
  }

  return (
    <Formik
      initialValues={currency}
      enableReinitialize
      onSubmit={(values) => {
        handleSaveChange.mutate(values)
      }}
    >
      {(formikProps) => (
        <Flex justify={'space-between'} vertical className="w-100 h-100">
          <section>
            <Typography.Title level={5} className="">
              Tiền tệ
            </Typography.Title>
            <div className="ml-10 row">
              <div className="col-xl-6 col-12">
                <div className="d-flex align-items-center mb-5">
                  <div style={{ width: '200px' }}>
                    Cách đọc số 0 ở hàng chục
                  </div>
                  <div className="ml-5" style={{ width: '100px' }}>
                    <CustomAntSelect
                      options={zeroInten}
                      value={formikProps.values.zeroInten}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('zeroInten', newValue)
                      }}
                    />
                  </div>
                  <div className="ml-5">
                    VD: 105 đọc là: một trăm{' '}
                    {formikProps.values.zeroInten?.toLowerCase()} năm đồng
                  </div>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <div style={{ width: '200px' }}>
                    Cách đọc số tiền ở hàng nghìn
                  </div>
                  <div className="ml-5" style={{ width: '100px' }}>
                    <CustomAntSelect
                      options={zeroInThousand}
                      value={formikProps.values.zeroInThousand}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('zeroInThousand', newValue)
                      }}
                    />
                  </div>
                  <div className="ml-5">
                    VD: 7200 đọc là: bảy{' '}
                    {formikProps.values.zeroInThousand?.toLowerCase()} hai trăm
                    đồng
                  </div>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <Checkbox
                    checked={formikProps.values.evenDisplay}
                    onChange={(e) =>
                      formikProps.setFieldValue('evenDisplay', e.target.checked)
                    }
                  >
                    {formikProps.values.evenDisplay
                      ? 'Hiển thị từ chẵn khi đọc số tiền (VD: 100 đọc là: một trăm đồng chẵn)'
                      : 'Hiển thị từ chẵn khi đọc số tiền (VD: 100 đọc là: một trăm đồng)'}
                  </Checkbox>
                </div>
                <div className="d-flex align-items-center mb-5">
                  <Checkbox
                    checked={formikProps.values.transactionForeign}
                    onChange={(e) =>
                      formikProps.setFieldValue(
                        'transactionForeign',
                        e.target.checked,
                      )
                    }
                  >
                    {'Có phát sinh nghiệp vụ đến ngoại tệ'}
                  </Checkbox>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="mb-5" style={{ fontWeight: '700' }}>
                  Thiết lập đọc số tiền bằng chữ trên hóa đơn điều chỉnh
                </div>
                <div className=" mb-5">
                  <div className="" style={{ width: '450px' }}>
                    <CustomAntSelect
                      options={readMoneyInvoice}
                      value={formikProps.values.readMoneyInvoice}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('readMoneyInvoice', newValue)
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <div>
                      Ví dụ: 1.000.000 đọc là{' '}
                      <span style={{ fontWeight: '700' }}>
                        {readMoneyPositive(formikProps.values.readMoneyInvoice)}{' '}
                      </span>{' '}
                    </div>
                    <div className="ml-13">
                      -1.000.000 đọc là{' '}
                      <span style={{ fontWeight: '700' }}>
                        {readMoneyNegative(formikProps.values.readMoneyInvoice)}{' '}
                      </span>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterCard
            onRestoreDefault={() => {
              _.entries(AppData.default_systemCurrency).forEach(
                ([key, value]) => {
                  formikProps.setFieldValue(key, value)
                },
              )
            }}
            onRestoreChange={formikProps.resetForm}
            onSave={formikProps.submitForm}
            isLoading={formikProps.isSubmitting}
            show={formikProps.dirty}
          />
        </Flex>
      )}
    </Formik>
  )
}
