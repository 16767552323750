import './style.scss'
import Select from 'react-select'
import CustomButton from 'general/components/Button/CustomButton'
import { Modal, Toast, ToastHeader } from 'react-bootstrap'
import ButtonGroupTab from 'general/components/Tab'
import { useEffect, useMemo, useState } from 'react'
import AppResource from 'general/constants/AppResource'
import Empty from 'general/components/Empty'
import { useTranslation } from 'react-i18next'
import DataTable from 'react-data-table-component'
import Loading from 'general/components/Loading'
import customDataTableStyleInput from 'assets/styles/customDataTableStyleInput'
import BaseTextFieldNoFormik from 'general/components/Forms/BaseTextFieldNoFormik'
import { Checkbox } from 'antd'
import SelectOptions from 'general/constants/SelectOptions'
import customSelectTableCellStyle from 'assets/styles/customSelectTableCellStyle'
import extendFieldApi from 'api/extendFieldApi'
import { useDispatch } from 'react-redux'
import { setAppSpinning } from 'app/appSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'

function AddCustomFieldModal(props) {
  const dispatch = useDispatch()

  const {
    invoiceTemplateId,
    buyerInfoExtendFields,
    setBuyerInfoExtendFields,
    tableDetailExtendFields,
    setTableDetailExtendFields,
    fetchExtendFields,
    ...restProps
  } = props
  const [currentTab, setCurrentTab] = useState(0)
  const { t } = useTranslation()

  const [tempInvoiceCustomField, setTempInvoiceCustomField] = useState([])
  const [tempTableCustomField, setTempTableCustomField] = useState([])

  useEffect(() => {
    // if (props.show === true) setTempInvoiceCustomField([...buyerInfoExtendFields]);
    // if (props.show === true) setTempTableCustomField([...tableDetailExtendFields]);

    return () => {
      setTempInvoiceCustomField([])
      setTempTableCustomField([])
      setCurrentTab(0)
    }
  }, [props.show])

  useEffect(() => {
    if (props.show) {
      setTempInvoiceCustomField([...buyerInfoExtendFields])
    }
  }, [buyerInfoExtendFields, props.show])

  useEffect(() => {
    if (props.show) {
      setTempTableCustomField([...tableDetailExtendFields])
    }
  }, [tableDetailExtendFields, props.show])

  const handleAddRow = () => {
    if (currentTab === 0) {
      setTempInvoiceCustomField([
        ...tempInvoiceCustomField,
        { name: `extendField-buyerInfo-${Utils.makeId(3)}`, dataType: 'TEXT' },
      ])
    } else {
      setTempTableCustomField([
        ...tempTableCustomField,
        {
          name: `extendField-tableDetail-${Utils.makeId(3)}`,
          dataType: 'TEXT',
        },
      ])
    }
  }

  const handleRemoveRow = async (row) => {
    try {
      let res = await extendFieldApi.deleteExtendField({ name: row.name })

      if (res.result == 'success') {
        if (currentTab === 0) {
          setBuyerInfoExtendFields((old) =>
            old.filter(({ name }) => name != row.name),
          )
        } else {
          setTableDetailExtendFields((old) =>
            old.filter(({ name }) => name != row.name),
          )
        }

        ToastHelper.showSuccess('Xóa thành công')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeTableData = (value, row, field) => {
    console.log(value)
    if (currentTab === 0) {
      setTempInvoiceCustomField((old) =>
        old.map((item) =>
          item.name == row.name ? { ...item, [field]: value } : item,
        ),
      )
    } else {
      setTempTableCustomField((old) =>
        old.map((item) =>
          item.name == row.name ? { ...item, [field]: value } : item,
        ),
      )
    }
  }

  const handleSaveExtendFields = async () => {
    dispatch(setAppSpinning(true))
    if (currentTab == 0) {
      try {
        let res = await extendFieldApi.updateExtendFields({
          invoiceTemplateId,
          fields: tempInvoiceCustomField.map(
            ({ name, dataType, label, labelEn, defaultValue, show }) => ({
              name,
              regionName: 'buyerInfo',
              dataType,
              label,
              labelEn,
              defaultValue,
              show,
            }),
          ),
        })

        await fetchExtendFields()

        dispatch(setAppSpinning(false))

        let extendFields = res?.extendFields || []
        setBuyerInfoExtendFields((old) =>
          old.map((field) => {
            let _ = extendFields.find(({ name }) => name == field.name) || {}
            let { dataType, label, labelEn, defaultValue, invoice_templates } =
              _
            let show = !!invoice_templates?.find(
              (item) => item.invoiceTemplateId == invoiceTemplateId,
            )?.invoice_template_extend_field?.active

            return { ...field, dataType, label, labelEn, defaultValue, show }
          }),
        )
        ToastHelper.showSuccess('Chỉnh sửa trường tùy chỉnh thành công')
      } catch (e) {
        console.log(e)
        dispatch(setAppSpinning(false))
      }
    } else {
      try {
        let res = await extendFieldApi.updateExtendFields({
          invoiceTemplateId,
          fields: tempTableCustomField.map(
            ({ name, dataType, label, labelEn, defaultValue, show }) => ({
              name,
              regionName: 'tableDetail',
              dataType,
              label,
              labelEn,
              defaultValue,
              show,
            }),
          ),
        })

        await fetchExtendFields()

        dispatch(setAppSpinning(false))

        let extendFields = res?.extendFields || []
        setTableDetailExtendFields((old) =>
          old.map((field) => {
            let _ = extendFields.find(({ name }) => name == field.name) || {}
            let { dataType, label, labelEn, defaultValue, invoice_templates } =
              _
            let show = !!invoice_templates?.find(
              (item) => item.invoiceTemplateId == invoiceTemplateId,
            )?.invoice_template_extend_field?.active

            return { ...field, dataType, label, labelEn, defaultValue, show }
          }),
        )
        ToastHelper.showSuccess('Chỉnh sửa trường tùy chỉnh thành công')
      } catch (e) {
        dispatch(setAppSpinning(false))
      }
    }
  }

  const columns = useMemo(() => {
    var data = currentTab === 0 ? tempInvoiceCustomField : tempTableCustomField
    const ret = [
      {
        name: 'Tên trường',
        minWidth: '100px',
        cell: (row, index) => {
          console.log('row', row)
          console.log('index', index)
          return (
            <div className="w-100" style={{ height: '40px' }}>
              <BaseTextFieldNoFormik
                onChange={(event) => {
                  handleChangeTableData(event.target.value, row, 'label')
                }}
                onBlur={(event) => {
                  handleChangeTableData(event.target.value, row, 'label')
                }}
                transparent={true}
                showBorder={false}
                inputValue={row?.label ?? ''}
              />
            </div>
          )
        },
      },
      {
        name: 'Tên trường tiếng anh',
        minWidth: '190px',
        cell: (row, index) => {
          return (
            <div className="w-100" style={{ height: '40px' }}>
              <BaseTextFieldNoFormik
                onBlur={(event) => {
                  handleChangeTableData(event.target.value, row, 'labelEn')
                }}
                onChange={(event) => {
                  handleChangeTableData(event.target.value, row, 'labelEn')
                }}
                transparent={true}
                showBorder={false}
                inputValue={row?.labelEn || ''}
              />
            </div>
          )
        },
      },
      {
        name: 'Kiểu dữ liệu',
        minWidth: '80px',
        cell: (row, index) => {
          return (
            <Select
              styles={customSelectTableCellStyle}
              className="w-100"
              classNamePrefix="select"
              options={SelectOptions.dataType}
              value={SelectOptions.dataType.find(
                ({ value }) => value == (row?.dataType || 'TEXT'),
              )}
              onChange={({ value }) => {
                handleChangeTableData(value, row, 'dataType')
              }}
              name="color"
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              // menuPortalTarget={document.getElementById('AddCustomFieldModalBody')}
              menuPosition="fixed"
              menuPlacement="auto"
            />
          )
        },
      },
      {
        name: 'Giá trị mặc định',
        minWidth: '190px',
        cell: (row, index) => {
          return (
            <div className="w-100" style={{ height: '40px' }}>
              <BaseTextFieldNoFormik
                onBlur={(event) => {
                  handleChangeTableData(event.target.value, row, 'defaultValue')
                }}
                onChange={(event) => {
                  handleChangeTableData(event.target.value, row, 'defaultValue')
                }}
                transparent={true}
                showBorder={false}
                inputValue={row?.defaultValue || ''}
              />
            </div>
          )
        },
      },
      {
        name: 'Hiển thị',
        minWidth: '90px',
        maxWidth: '90px',
        cell: (row) => {
          return (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: '40px' }}
            >
              <Checkbox
                checked={row.forceShow || row.show}
                disabled={row.forceShow}
                onChange={(event) => {
                  handleChangeTableData(event.target.checked, row, 'show')
                }}
              ></Checkbox>
            </div>
          )
        },
      },
      {
        // name: "",
        minWidth: '60px',
        maxWidth: '60px',

        cell: (row) => {
          return (
            <div
              className="d-flex justify-content-center w-100 h-100 align-items-center"
              style={{ zIndex: '1' }}
              onClick={() => {
                handleRemoveRow(row)
              }}
            >
              <i className="far fa-trash-alt" style={{ color: 'red' }}></i>
            </div>
          )
        },
      },
    ]
    return ret
  }, [tempInvoiceCustomField, tempTableCustomField, currentTab])

  return (
    <Modal
      className="font-sfpro-regular AddCustomFieldModal"
      {...restProps}
      size="lg"
      centered
      id="AddCustomFieldModal"
      scrollable={true}
    >
      <Modal.Header className="border-bottom-0 ">
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="font-weight-boldest" style={{ fontSize: '20px' }}>
            Trường mở rộng
          </span>

          <ButtonGroupTab
            textButton1="Thông tin hoá đơn"
            textButton2="Bảng hàng hoá, dịch vụ"
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            buttonWidth="200px"
          />
          <div
            className="d-flex justify-content-end"
            style={{ width: '100px' }}
          >
            <i
              className="fas fa-times"
              style={{ cursor: 'pointer', zIndex: '1' }}
              onClick={props.onHide}
            ></i>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0" id="AddCustomFieldModalBody">
        <DataTable
          fixedHeader
          // fixedHeaderScrollHeight={tableMaxHeight}
          columns={columns}
          data={
            currentTab === 0 ? tempInvoiceCustomField : tempTableCustomField
          }
          customStyles={customDataTableStyleInput}
          noHeader
          noDataComponent={
            <div className="pt-12">
              <Empty
                text={t('NoData')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
                imageEmptyPercentWidth={170}
              />
            </div>
          }
          // progressPending={isGettingApplicationList}
          progressComponent={
            <Loading showBackground={false} message={`${t('Loading')}...`} />
          }
          // onSelectedRowsChange={handleSelectedApplicationsChanged}
          // clearSelectedRows={toggledClearApplications}
          onRowClicked={(row) => {
            // handleEditApplication(row);
          }}
          pointerOnHover
          highlightOnHover
          selectableRowsHighlight
        />
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <CustomButton
          text="Thêm dòng"
          icon={'fal fa-plus'}
          width="100px"
          gap="5px"
          color="#304FFD"
          showBorder={false}
          onClick={handleAddRow}
          className="px-0"
        />
        <div className="d-flex flex-row" style={{ gap: '15px' }}>
          <CustomButton
            backgroundColor="white"
            text="Huỷ bỏ"
            width="90px"
            color="#304FFD"
            onClick={() => {
              props.onHide()
            }}
          ></CustomButton>
          <CustomButton
            backgroundColor="#304FFD"
            text="Lưu"
            width="90px"
            color="white"
            onClick={handleSaveExtendFields}
          ></CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AddCustomFieldModal
