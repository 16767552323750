import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { Space, Tooltip } from 'antd'
import ticketApi from 'api/ticketApi'
import dayjs from 'dayjs'
import ExportTicketModal from 'features/HandleTicket/Components/Modals/ExportTicketModal'
import 'features/HandleTicket/styles.scss'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import SelectOptions from 'general/constants/SelectOptions'
import InvoiceReportHelper from 'general/helpers/InvoiceReportHelper'
import Utils from 'general/utils/Utils'
import useExportById from 'hooks/Mutations/useExportById'
import useFilter from 'hooks/useFilter'
import useRouter from 'hooks/useRouter'
import { assign, get, keys, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

const { Body, Header } = ContentContainer

export default function AdjustedTicketScreen() {
  const { navigate } = useRouter()
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 1000)
  const [filter, setFilter] = useFilter(
    'adjustedTicketList',
    'gFilterAdjustedTicket',
  )
  const [modalState, setModalState] = useState({
    viewAdjusted: false,
    viewDetail: false,
    sendMail: false,
    exportAdjusted: false,
  })

  const { exportFunc } = useExportById(
    ticketApi.exportById,
    'Biên bản điều chỉnh.pdf',
    'application/pdf',
  )

  const {
    data: listData,
    isFetching: isGettingList,
    isSuccess,
  } = useQuery({
    queryKey: ['replacedTicketList', debouncedValue, filter],
    queryFn: () =>
      ticketApi.list({ ...filter, q: debouncedValue, ticketType: 'ADJUSTED' }),
    select: (res) => ({
      total: get(res, 'count', 0),
      rows: get(res, 'rows', []),
    }),
  })

  /**
   * Toggles the state of a modal.
   * @param {Object} options - The options object.
   * @param {keyof modalState} options.modalName - The name of the modal to toggle.
   * @param {boolean} options.status - The status to set for the modal.
   */

  const toggleModalState = ({ modalName, status }) =>
    setModalState((prev) => {
      const notModalName = keys(prev).filter((key) => key !== modalName)
      const turnEverythingOff = map(notModalName, (key) => ({ [key]: false }))
      return {
        ...assign({}, ...turnEverythingOff),
        [modalName]: status,
      }
    })

  const columns = useMemo(() => {
    /**
     * @type {import('react-data-table-component').TableColumn<ReturnTicketData>[]}
     */
    const arr = [
      {
        name: 'Ký hiệu',
        center: true,
        cell: ({ serial }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {serial}
          </p>
        ),
      },
      {
        name: 'Số vé',
        center: true,
        cell: ({ no }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {no}
          </p>
        ),
      },
      {
        name: 'Ngày xuất vé',
        center: true,
        selector: ({ dateRelease }) => dayjs(dateRelease).format('DD/MM/YYYY'),
      },
      {
        name: 'Mã CQT',
        center: true,
        selector: ({ taxAuthorityCode }) => taxAuthorityCode,
      },
      {
        name: 'Tổng tiền',
        center: true,
        cell: ({ totalPrice }) => (
          <p style={{ textAlign: 'right' }} className="w-100 m-0">
            {Utils.formatCurrency(totalPrice)}
          </p>
        ),
      },
      {
        name: 'Lý do hủy',
        center: true,
        cell: ({ errAnnouceReason }) => (
          <TableAction
            type="link"
            titleActionText="Xem chi tiết"
            text={errAnnouceReason}
            onClick={() =>
              toggleModalState({
                modalName: 'viewCanceledReason',
                status: true,
              })
            }
          />
        ),
        selector: ({ errAnnouceReason }) => errAnnouceReason,
      },
      //Vé thay thế
      {
        name: 'Biên bản điện tử',
        center: true,
        cell: ({ Report, ticketId }) => (
          <>
            <Tooltip
              title={InvoiceReportHelper.renderInvoiceReportStatus(
                Report?.reportStatus,
              )}
              placement="bottom"
            >
              <p
                data-tag="allowRowEvents"
                className={Utils.cn(
                  'm-0 mr-4 cursor-pointer',
                  Report?.no ? 'text-danger' : 'text-primary',
                )}
                onClick={() => {
                  if (Report?.reportStatus == 1) {
                    navigate(
                      `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-huy/them-moi/${ticketId}`,
                    )
                  }

                  if (Report?.reportStatus == 2) {
                    navigate(
                      `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-huy/cap-nhat/${ticketId}`,
                    )
                  }

                  if (![1, 2].includes(Report?.reportStatus)) {
                    navigate(
                      `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-huy/xem/${ticketId}`,
                    )
                  }
                }}
              >
                {Report?.no ? Report?.no : 'Lập biên bản'}
              </p>
            </Tooltip>
            {!Report
              ? null
              : ![1, 2].includes(Report?.reportStatus) && (
                  <i
                    className="fa-regular fa-eye"
                    style={{ color: 'inherit' }}
                    title="Xem biên bản điện tử"
                    onClick={() => exportFunc.mutate(Report.reportId)}
                  ></i>
                )}
          </>
        ),
      },
      {
        name: 'Ký hiệu',
        center: true,
        cell: ({ TicketRelated }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {TicketRelated[0]?.serial}
          </p>
        ),
      },
      {
        name: 'Số vé',
        center: true,
        cell: ({ TicketRelated }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {TicketRelated[0]?.no}
          </p>
        ),
      },
      {
        name: 'Ngày xuất vé',
        center: true,
        selector: ({ TicketRelated }) =>
          dayjs(TicketRelated[0]?.dateRelease).format('DD/MM/YYYY'),
      },
      {
        name: 'Tổng tiền',
        center: true,
        cell: ({ TicketRelated }) => (
          <p style={{ textAlign: 'right' }} className="w-100 m-0">
            {TicketRelated[0]?.totalPrice &&
              Utils.formatCurrency(TicketRelated[0]?.totalPrice)}
          </p>
        ),
      },
      {
        name: 'Gửi vé',
        center: true,
        cell: ({ isSent }) =>
          isSent ? (
            <i className="fa-light fa-circle-check text-success" />
          ) : (
            <i className="fa-duotone fa-do-not-enter" />
          ),
      },
      {
        name: 'Hành động',
        center: true,
        cell: () => (
          <Space>
            <TableAction
              titleActionText="Xem"
              icon={<IconAction className="fa-regular fa-eye" />}
              onClick={() =>
                toggleModalState({
                  modalName: 'viewDetail',
                  status: true,
                })
              }
            />
            <TableAction
              titleActionText="Gửi vé cho khách hàng"
              icon={<IconAction className="fa-regular fa-envelope" />}
              onClick={() =>
                toggleModalState({
                  modalName: 'viewDetail',
                  status: true,
                })
              }
            />
          </Space>
        ),
      },
    ]
    return arr
  }, [exportFunc, navigate])

  useEffect(() => {
    const setUp = () => {
      const ticket_been_adjusted = {
        startRange: 1,
        endRange: 5,
        width: 0,
      }

      document
        .querySelectorAll('#rowGroupContainer')
        .forEach((group) => group.remove())

      const rowDiv = document.querySelector('div[role="rowgroup"]')
      if (!rowDiv) return
      const hasRowGroup = rowDiv.querySelector('#rowGroupContainer')
      let containerDiv = null
      if (!hasRowGroup) {
        containerDiv = document.createElement('div')
        containerDiv.id = 'rowGroupContainer'
        containerDiv.classList.add('d-flex')
      } else containerDiv = hasRowGroup

      ticket_been_adjusted.width = Utils.calcWidthColumns(
        ticket_been_adjusted.startRange,
        ticket_been_adjusted.endRange,
      )
      const ticket_been_adjusted_div = document.createElement('div'),
        ticket_adjusted_div = document.createElement('div')
      ticket_adjusted_div.id = 'group_ticket_been_adjusted'
      ticket_been_adjusted_div.classList.add('table-error-header')
      ticket_been_adjusted_div.style.width = `${ticket_been_adjusted.width}px`
      ticket_been_adjusted_div.textContent = 'Vé đã bị điều chỉnh'

      ticket_adjusted_div.id = 'group_ticket_adjusted'
      ticket_adjusted_div.classList.add('table-primary-header', 'flex-fill')
      ticket_adjusted_div.textContent = 'Vé điều chỉnh'

      containerDiv.append(ticket_been_adjusted_div, ticket_adjusted_div)
      rowDiv.parentNode.insertBefore(containerDiv, rowDiv)
    }
    window.addEventListener('resize', setUp)
    isSuccess && setUp()
    return () => {
      window.removeEventListener('resize', setUp)
    }
  }, [isSuccess])

  return (
    <ContentContainer>
      <Header
        titleContent="Điều chỉnh vé"
        toolBar={
          <Space>
            <CustomAntButton
              text="Lập vé điều chỉnh"
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () =>
                  toggleModalState({
                    modalName: 'exportAdjusted',
                    status: true,
                  }),
              }}
            />
          </Space>
        }
      >
        <Space wrap align="center">
          <CustomAntInput
            type="search"
            inputProps={{
              defaultValue: search,
              onChange: (e) => setSearch(e.target.value),
            }}
            borderRadius="sm"
          />
          <FilterPopover
            menuPosition="bottomLeft"
            onApply={setFilter}
            savedKey="gFilterAdjustedTicket"
            defaultValues={[
              {
                label: 'Trạng thái biên bản',
                alias: 'Trạng thái biên bản',
                formName: 'reportStatus',
                value: null,
                options: SelectOptions.reportStatus,
              },
              {
                label: 'Tình trạng phát hành',
                alias: 'Tình trạng phát hành',
                formName: 'ticketPublishedStatus',
                value: null,
                options: SelectOptions.ticketPublishedStatus,
              },
            ]}
          />
        </Space>
      </Header>
      <Body>
        <CustomDataTable
          columns={columns}
          dataSource={listData?.rows}
          isLoading={isGettingList}
          pagination={{
            currentPage: filter.page,
            rowsPerPage: filter.limit,
            total: listData?.total,
            onChangePage: (page) => setFilter({ page }),
            onChangeRowsPerPage: (limit) => setFilter({ limit, page: 1 }),
          }}
        />
      </Body>
      <ExportTicketModal
        title="Xuất vé điều chỉnh"
        ticketType={'CAN_ADJUSTED'}
        open={modalState.exportAdjusted}
        onOpenChange={(s) =>
          toggleModalState({
            modalName: 'exportAdjusted',
            status: s,
          })
        }
      />
    </ContentContainer>
  )
}
