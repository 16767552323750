import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import './style.scss'
import Utils from 'general/utils/Utils'
import { useDispatch, useSelector } from 'react-redux'
import cartApi from 'api/cartApi'
import { useNavigate } from 'react-router-dom'
import { voucherGetByCode } from '../../CartSlice'

function Voucher() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const dataCart = useSelector((state) => state.cart.cartItems)

  let dataTotal = dataCart?.reduce(
    (sum, item) =>
      sum + Number(item?.productPrice) * Number(item?.productNumber),
    0,
  )

  const handleVoucher = (code) => {
    dispatch(voucherGetByCode({ code: code }))
  }

  const [code, setCode] = useState()

  const voucher = useSelector((state) => state?.cart?.voucher)

  const [discount, setDiscount] = useState(0)

  useEffect(() => {
    if (voucher) {
      setCode(voucher?.code)
      setDiscount((dataTotal / 100) * voucher?.percent)
    }
  }, [voucher])

  return (
    <div className="bg-white Voucher">
      <div className="header-voucher p-5">
        <div className="d-flex justify-content-between  ">
          <input
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
            }}
            placeholder="Mã giảm giá, Mã giới thiệu ..."
            className="mr-3 p-3 rounded flex-grow-1 input-voucher"
          ></input>
          <Button
            style={{ minWidth: 'max-content' }}
            variant="outline-primary fw-bold"
            onClick={() => handleVoucher(code)}
          >
            Áp dụng
          </Button>
        </div>
      </div>
      <div className="price-voucher  p-5 pl-7">
        <div className="d-flex justify-content-between">
          <p>Tổng cộng</p>
          <p className="pr-1">
            {Utils?.formatCurrency(dataTotal ?? 0)} <ins>đ</ins>
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p>Khuyễn mãi</p>
          {voucher ? (
            <p className="pr-1">
              {Utils?.formatCurrency(discount)} <ins>đ</ins>
            </p>
          ) : (
            <p className="pr-1">
              {Utils?.formatCurrency(0)} <ins>đ</ins>
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between text-danger fw-bold">
          <p>Tiền phải trả</p>
          <p className="pr-1">
            {Utils?.formatCurrency(dataTotal - discount)} <ins>đ</ins>
          </p>
        </div>
        <div className="d-flex justify-content-between ">
          <p className="mb-0"></p>
          <p className="mb-0">(Đã bao gồm VAT)</p>
        </div>
      </div>
      <div className="px-5 pb-5">
        <Button
          disabled={dataCart?.length <= 0}
          variant="primary w-100 fw-bolder"
          onClick={() => {
            navigate(`/hoa-don-dau-ra/thanh-toan`)
          }}
        >
          Tiếp tục
        </Button>
      </div>
    </div>
  )
}

export default Voucher
