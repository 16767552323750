import { components } from 'react-select'
const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div
        className="sticky-top p-5 z-10 border-bottom rounded-2 rounded-bottom-0"
        style={{
          backgroundColor: 'white',
        }}
      >
        <div className="row">
          <span className="fw-bold col-4">Ký hiệu</span>
          <span className="fw-bold text-truncate col-8">Tên mẫu</span>
        </div>
      </div>
      {props.children}
    </components.MenuList>
  )
}

export default MenuList
