import dashboardApi from 'api/dashboardApi'
import Global from 'general/utils/Global'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const thunkGetUsedInvoiceStatus = createAsyncThunk(
  'dashboard/used-invoice',
  async (params, thunkApi) => {
    const res = await dashboardApi.getOverall({
      ...params,
      type: 'createdInvoicesByDay',
    })
    return res
  },
)

// so luong hoa don da phat hanh
export const thunkGetIssuedInvoiceCount = createAsyncThunk(
  'dashboard/invoice-count',
  async (params, thunkApi) => {
    const res = await dashboardApi.getOverall({
      ...params,
      type: 'issuedInvoicesCount',
    })
    return res
  },
)

// so luong hoa don chua phat hanh
export const thunkGetNotIssuedInvoiceCount = createAsyncThunk(
  'dashboard/not-issued-invoice-count',
  async (params, thunkApi) => {
    const res = await dashboardApi.getOverall({
      ...params,
      type: 'notIssuedInvoicesCount',
    })
    return res
  },
)

// gia tri hoa don da phat hanh
export const thunkGetIssuedInvoiceValue = createAsyncThunk(
  'dashboard/issue-value-value',
  async (params, thunkApi) => {
    const res = await dashboardApi.getOverall({
      ...params,
      type: 'issuedInvoicesValue',
    })
    return res
  },
)

// thong ke so luong hoa don da va chua phat hanh
export const thunkGetStatisticInvoice = createAsyncThunk(
  'dashboard/statistic-invoice',
  async (params, thunkApi) => {
    const res = await dashboardApi.getOverall({
      ...params,
      type: 'statisticInvoice',
    })
    return res
  },
)

// thong ke toan bo hoa don
export const thunkGetAllInvoice = createAsyncThunk(
  'dashboard/all-invoice',
  async (params, thunkApi) => {
    const res = await dashboardApi.getOverall({
      ...params,
      type: 'allInvoiceByStatus',
    })
    return res
  },
)

export const thunkGetByCustomer = createAsyncThunk(
  'dashboard/customer',
  async (params, thunkApi) => {
    const res = await dashboardApi.getOverall({
      ...params,
      type: 'totalInvoiceValueByCustomer',
    })
    return res
  },
)

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    isGettingStatusOfUsingInvoiceByDate: false,
    statusOfUsingInvoiceByDate: [],

    isGettingIssueInvoiceCount: false,
    issuedInvoiceCount: 0,

    isGettingNotIssueInvoiceCount: false,
    notIssuedInvoiceCount: 0,

    isGettingIssuedInvoiceValue: false,
    issueInvoiceValue: 0,

    isGettingStatisticInvoice: false,
    statisticInvoice: [],

    isGettingAllInvoice: false,
    allInvoice: {},

    isGettingInvoiceValue: false,
    invoiceValue: [],

    isGettingDigitalCertificate: false,
    digitalCertificates: [
      {
        organization: 'EASYCA',
        serial: '7w68734658345872385967',
        effectiveDate: '2021-11-20',
        expiredDate: '2022-11-07',
        status: 'ACTIVE',
      },
    ],

    pagination: { perPage: Global.gDefaultPagination },
  },
  reducers: {
    setPaginationPerPage: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkGetUsedInvoiceStatus.pending, (state, action) => {
      state.isGettingStatusOfUsingInvoiceByDate = true
    })
    builder.addCase(thunkGetUsedInvoiceStatus.rejected, (state, action) => {
      state.isGettingStatusOfUsingInvoiceByDate = false
    })
    builder.addCase(thunkGetUsedInvoiceStatus.fulfilled, (state, action) => {
      state.isGettingStatusOfUsingInvoiceByDate = false
      const { data, result } = action.payload
      if (result === 'success') {
        state.statusOfUsingInvoiceByDate = data?.createdInvoicesByDay
      }
    })

    // so luong hoa don da phat hanh
    builder.addCase(thunkGetIssuedInvoiceCount.pending, (state, action) => {
      state.isGettingIssueInvoiceCount = true
    })
    builder.addCase(thunkGetIssuedInvoiceCount.rejected, (state, action) => {
      state.isGettingIssueInvoiceCount = false
    })
    builder.addCase(thunkGetIssuedInvoiceCount.fulfilled, (state, action) => {
      state.isGettingIssueInvoiceCount = false
      const { data, result } = action.payload
      if (result === 'success') {
        state.issuedInvoiceCount = data?.issuedInvoicesCount
      }
    })

    // so luong hoa don chua phat hanh
    builder.addCase(thunkGetNotIssuedInvoiceCount.pending, (state, action) => {
      state.isGettingNotIssueInvoiceCount = true
    })
    builder.addCase(thunkGetNotIssuedInvoiceCount.rejected, (state, action) => {
      state.isGettingNotIssueInvoiceCount = false
    })
    builder.addCase(
      thunkGetNotIssuedInvoiceCount.fulfilled,
      (state, action) => {
        state.isGettingNotIssueInvoiceCount = false
        const { data, result } = action.payload
        if (result === 'success') {
          state.notIssuedInvoiceCount = data?.notIssuedInvoicesCount
        }
      },
    )

    // gia tri hoa don da phat hanh
    builder.addCase(thunkGetIssuedInvoiceValue.pending, (state, action) => {
      state.isGettingIssuedInvoiceValue = true
    })
    builder.addCase(thunkGetIssuedInvoiceValue.rejected, (state, action) => {
      state.isGettingIssuedInvoiceValue = false
    })
    builder.addCase(thunkGetIssuedInvoiceValue.fulfilled, (state, action) => {
      state.isGettingIssuedInvoiceValue = false
      const { data, result } = action.payload
      if (result === 'success') {
        state.issueInvoiceValue = data?.issuedInvoicesValue
      }
    })

    // thong ke so luong hoa don da va chua phat hanh
    builder.addCase(thunkGetStatisticInvoice.pending, (state, action) => {
      state.isGettingStatisticInvoice = true
    })
    builder.addCase(thunkGetStatisticInvoice.rejected, (state, action) => {
      state.isGettingStatisticInvoice = false
    })
    builder.addCase(thunkGetStatisticInvoice.fulfilled, (state, action) => {
      state.isGettingStatisticInvoice = false
      const { data, result } = action.payload
      if (result === 'success') {
        if (data?.issuedInvoicesCount || data?.notIssuedInvoicesCount)
          state.statisticInvoice = [
            { type: 'USED', value: data?.issuedInvoicesCount },
            { type: 'UNUSED', value: data?.notIssuedInvoicesCount },
          ]
      }
    })

    // thong ke toan bo hoa don
    builder.addCase(thunkGetAllInvoice.pending, (state, action) => {
      state.isGettingAllInvoice = true
    })
    builder.addCase(thunkGetAllInvoice.rejected, (state, action) => {
      state.isGettingAllInvoice = false
    })
    builder.addCase(thunkGetAllInvoice.fulfilled, (state, action) => {
      state.isGettingAllInvoice = false
      const { data, result } = action.payload
      if (result === 'success') {
        state.allInvoice = data
      }
    })

    // thong ke toan bo hoa don
    builder.addCase(thunkGetByCustomer.pending, (state, action) => {
      state.isGettingInvoiceValue = true
    })
    builder.addCase(thunkGetByCustomer.rejected, (state, action) => {
      state.isGettingInvoiceValue = false
    })
    builder.addCase(thunkGetByCustomer.fulfilled, (state, action) => {
      state.isGettingInvoiceValue = false
      const { data, result } = action.payload
      if (result === 'success') {
        state.invoiceValue = data.totalInvoiceValueByCustomer.map((item) => {
          return { name: item.customerCompanyName, value: item.totalMoney }
        })
      }
    })
  },
})
const { reducer, actions } = dashboardSlice
export const { setPaginationPerPage } = actions
export default reducer
