import UserHelper from 'general/helpers/UserHelper'
import { Navigate } from 'react-router-dom'

function PrivateRoute(props) {
  const isAuth = UserHelper.checkAccessTokenValid()

  return isAuth ? props.children : <Navigate to="/auth/dang-nhap" />
}

export default PrivateRoute
