import { useTranslation } from 'react-i18next'
import { TableCustom, ButtonSave } from '../../TaxDeduction/styled'
import {
  TitleRight,
  ContainerSearch,
  ContainerTable,
  DivListFunction,
  ContainerFunction,
  FormOutlinedIcon,
  DivFunction,
  DeleteOutlinedIcon,
  CopyOutlinedIcon,
  SpanNotYetReleasedTd,
  SendOutlinedIcon,
  DownloadOutlinedIcon,
  FilePdfOutlinedIcon,
  FileExcelOutlinedIcon,
  DivRight,
  InputCustom,
} from '../../TaxDeductionDocuments/styled'
import {
  DatabaseOutlinedTitleIcon,
  DivColRightTitle,
  DivRight as DivRightTitle,
  DivRowTitle,
  EllipsisDiv,
} from '../styled'
// import AppResource from 'general/constants/AppResource'
// import { downloadZip, embedPDF, extractFields } from 'general/utils/Utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dropdown, Empty, Form, Space } from 'antd'
import {
  DatabaseOutlined,
  PlusOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  thunkAutomaticallyTaxpayer,
  thunkFindTaxpayer,
  thunkRemoveTaxpayer,
} from 'features/Taxpayer/taxpayerSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'hooks/useRedux'
import ToastHelper from 'general/helpers/ToastHelper'
import AlertTaxDectionDocumentsUpdate from '../../TaxDeductionDocuments/component/Alert'
import Utils, {
  convertDate,
  findObjectInArray,
  padNumber,
  removeItemByKeys,
} from 'general/utils/Utils'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { thunkGetAccountProfile } from 'app/authSlice'
import SelectOptions from 'general/constants/SelectOptions'
import { saveAs } from 'file-saver'
import { debounce } from 'lodash'
function SearchTaxTaxpayer() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataSource, setDataSource] = useState([])
  const [auth, setAuth] = useState({})
  const [dataActive, setDataActive] = useState({})
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
    key: '',
  })
  const [openModalAlertTemplateRemove, setOpenModalAlertTemplateRemove] =
    useState(false)

  const findDataSource = async () => {
    try {
      const data = unwrapResult(
        await dispatch(thunkFindTaxpayer(panningDataSource)),
      )
      setDataSource(data.items)
      setPanningDataSource({
        ...panningDataSource,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
      })
    } catch (error) {}
  }

  const remove = async () => {
    try {
      const result = unwrapResult(
        await dispatch(
          thunkRemoveTaxpayer({ taxpayersId: dataActive?.taxpayersId }),
        ),
      )
      setOpenModalAlertTemplateRemove(false)
      if (result?.message == 'success') {
        ToastHelper.showSuccess(`${result.data}`)
      } else {
        ToastHelper.showError(`${result.data}`)
      }
      findDataSource()
    } catch (error) {}
  }

  useEffect(() => {
    findDataSource()
  }, [panningDataSource.page, panningDataSource.limit, panningDataSource.key])

  const debouncedSetFormState = useCallback(
    debounce((keyValue, value) => {
      // Gọi API tại đây với keyValue và value
      setPanningDataSource((prevPanningDataSource) => ({
        ...prevPanningDataSource,
        [keyValue]: value,
      }))
    }, 300), // Thời gian chờ sau khi kết thúc nhập liệu, ví dụ: 500ms
    [], // Empty dependency array means this callback will only be created once
  )

  const handleChange = (key, value) => {
    // Xóa timeout hiện tại để tránh gọi API nếu người dùng vẫn đang nhập
    clearTimeout(timeoutId)

    // Thiết lập timeout mới với debounce
    timeoutId = setTimeout(() => {
      debouncedSetFormState(key, value)
    }, 500) // Đợi 500ms sau khi kết thúc nhập liệu
  }

  let timeoutId = null // Biến lưu trữ timeout ID

  useMemo(() => {
    const fetchData = async () => {
      const data = await dispatch(thunkGetAccountProfile())
      setAuth(data?.payload?.company)
    }
    fetchData()
  }, [dispatch])

  const items = [
    {
      key: '1',
      label: (
        <b>
          <FilePdfOutlinedIcon
            display="inherit"
            color="red"
            marginRight="10px"
            top="-2px"
            position="relative"
          />
          PDF
        </b>
      ),
    },
    {
      key: '2',
      label: (
        <b>
          <FileExcelOutlinedIcon
            display="inherit"
            color="#304ffd"
            marginRight="10px"
            top="-2px"
            position="relative"
          />
          XML
        </b>
      ),
    },
  ]

  const columns = [
    {
      title: t('taxpayer.employeeName'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <div className="left-cell">{record?.employeeCode}</div>
      ),
    },
    {
      title: t('taxpayer.taxpaperName'),
      width: 180,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record?.taxpayersName}</div>
      ),
    },
    {
      title: t('taxpayer.employeeBirthDate'),
      width: 110,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          {record.employeeBirthDate
            ? convertDate(record.employeeBirthDate, 'DD/MM/YYYY')
            : ''}
        </div>
      ),
    },
    {
      title: t('taxpayer.gender'),
      width: 100,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record.gender}</div>
      ),
    },
    {
      title: t('taxpayer.departmentName'),
      width: 180,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={180}
          title={record?.department?.departmentName}
        >
          {record?.department?.departmentName}
        </EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.job'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={`${record?.jobPosition?.jobPositionName ?? ''} ${record?.jobPosition?.jobPositionName && record?.jobTitle?.jobTitleName ? '/' : ''} ${record?.jobTitle?.jobTitleName ?? ''}`}
        >{`${record?.jobPosition?.jobPositionName ?? ''} ${record?.jobPosition?.jobPositionName && record?.jobTitle?.jobTitleName ? '/' : ''} ${record?.jobTitle?.jobTitleName ?? ''}`}</EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.contractType'),
      width: 240,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={240}
          title={record?.contractType}
        >
          {record?.contractType}
        </EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.taxCode'),
      width: 130,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={130}
          title={record?.employeeTaxCode}
        >
          {record?.employeeTaxCode}
        </EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.internshipStartDate'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          {record.internshipStartDate
            ? convertDate(record.internshipStartDate, 'DD/MM/YYYY')
            : ''}
        </div>
      ),
    },
    {
      title: t('taxpayer.probationStartDate'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          {record.probationStartDate
            ? convertDate(record.probationStartDate, 'DD/MM/YYYY')
            : ''}
        </div>
      ),
    },
    {
      title: t('taxpayer.phone'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record?.employeePhone}</div>
      ),
    },
    {
      title: t('taxpayer.email'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          <EllipsisDiv className="left-cell" width={200}>
            {record?.employeeEmail}
          </EllipsisDiv>
        </div>
      ),
    },
    {
      title: t('taxpayer.identification'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record?.employeeIdentification}</div>
      ),
    },
    {
      title: t('taxpayer.workingStatus'),
      width: 150,
      className: 'center-header',
      render: (text, record) => {
        const workingStatus = findObjectInArray(
          SelectOptions.listWorkingStatus,
          'value',
          record.workingStatus,
        )
        return (
          <div className="left-cell">
            <SpanNotYetReleasedTd
              color={workingStatus?.color}
              backGroundColor={workingStatus?.backGroundColor}
            >
              {workingStatus?.value}
            </SpanNotYetReleasedTd>
          </div>
        )
      },
    },
    {
      title: '',
      width: 0,
      className: 'center-header',
      fixed: 'right',
      render: (text, record, index) => {
        let listFunction = [
          {
            key: 'remove',
            title: t('taxDeductionDocuments.remove'),
            icon: (
              <DeleteOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDataActive({
                    taxpayersId: record.taxpayersId,
                    name: record.employeeIdentification,
                  })
                  setOpenModalAlertTemplateRemove(true)
                }}
              />
            ),
          },
          {
            key: 'edit',
            title: t('taxDeductionDocuments.editt'),
            icon: (
              <FormOutlinedIcon
                display="inherit"
                onClick={() => {
                  navigate(
                    `/hoa-don-dau-ra/chung-tu-tncn/nguoi-nop-thue/chinh-sua/${record.taxpayersId}`,
                  )
                }}
              />
            ),
          },
        ]
        // if (record.status === 1) {
        //   listFunction = removeItemByKeys(
        //     [...listFunction],
        //     ['edit', 'remove', 'release'],
        //   )
        // }
        return (
          <ContainerFunction className="left-cell">
            <DivListFunction className="listFuntionFlex">
              {listFunction.map((item, indexl) => (
                <DivFunction
                  title={item.title}
                  key={indexl}
                  right={`${indexl * 40}px`}
                >
                  {item.icon}
                </DivFunction>
              ))}
            </DivListFunction>
          </ContainerFunction>
        )
      },
    },
  ]

  return (
    <>
      <ContainerSearch>
        <DivRowTitle
          className="pt-4 row"
          style={{ borderBottom: '1px solid #e0e0e0' }}
        >
          <div className="col-sm-4">
            <TitleRight>{t('taxpayer.title')}</TitleRight>
          </div>
          <DivColRightTitle className="col-sm-8">
            <DivRightTitle>
              <DatabaseOutlinedTitleIcon />
              {t('taxpayer.titleRight')}
            </DivRightTitle>
          </DivColRightTitle>
        </DivRowTitle>
        <ContainerTable>
          <div className="pt-2 pb-5 row">
            <div className="col-sm-6">
              <Space.Compact>
                <InputCustom
                  onChange={(e) => {
                    handleChange('key', e.target.value)
                  }}
                  minWidth="300px"
                  addonBefore={
                    <SearchOutlined
                      onClick={() => {
                        findDataSource()
                      }}
                    />
                  }
                  placeholder={t('taxpayer.keySearch')}
                  allowClear
                />
              </Space.Compact>
            </div>
            <DivRight className="col-sm-6">
              <ButtonSave
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  navigate(
                    `/hoa-don-dau-ra/chung-tu-tncn/nguoi-nop-thue/them-moi`,
                  )
                }}
              >
                {t('taxpayer.add')}
              </ButtonSave>
            </DivRight>
          </div>
          <TableCustom
            scroll={{ x: 1200 }}
            dataSource={dataSource}
            columns={columns}
            locale={{
              emptyText: <Empty description={t('taxDeduction.noData')} />,
            }}
            marginRight={'0px'}
            marginLeft={'0px'}
            pagination={
              panningDataSource.totalPages > 1
                ? {
                    current: panningDataSource.page,
                    pageSize: panningDataSource.limit,
                    total: panningDataSource.totalItems,
                  }
                : false
            }
            onChange={(pagination) => {
              setPanningDataSource({
                ...pagination,
                page: pagination.current,
                limit: pagination.pageSize,
              })
            }}
          />
        </ContainerTable>
      </ContainerSearch>
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.remove')}
        openModalAlert={openModalAlertTemplateRemove}
        titleAlert={t('taxpayer.removennt')}
        // messageAlert={t('taxpayer.messagennt', dataActive)}
        setOpenModalAlert={(value) => {
          setOpenModalAlertTemplateRemove(value)
        }}
        onClickPrimary={() => {
          remove()
        }}
        colorButtonSave={'#ffffff'}
        backGroundButtonSave={'#e61d1d'}
        backGroundButtonSaveHover={'#cc0202!important'}
        nameActive={dataActive?.name}
        messageActive1={t('taxpayer.meremove1')}
        messageActive2={t('taxpayer.meremove2')}
      />
    </>
  )
}
export default SearchTaxTaxpayer
