import _ from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

/**
 *
 * @param {import('sweetalert2').SweetAlertOptions} [options]
 */
export default function useFireSwal(options) {
  const { t } = useTranslation()

  const swal = useMemo(() => {
    const mergedOptions = _.merge(
      {
        allowEscapeKey: true,
        showCancelButton: true,
        confirmButtonText: t('Yes'),
        cancelButtonText: t('Cancel'),
        reverseButtons: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-light-danger ',
          cancelButton: 'btn btn-secondary ',
        },
      },
      options,
    )
    return Swal.mixin(mergedOptions)
  }, [options, t])

  return {
    fireInfo: (title, text) => swal.fire(title, text, 'info'),
    fireSuccess: (title, text) => swal.fire(title, text, 'success'),
    fireError: (title, text) => swal.fire(title, text, 'error'),
    fireConfirm: (title, text) => swal.fire(title, text, 'question'),
  }
}
