import React from 'react'
import Select from 'react-select'

const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    // borderColor: state.isFocused ? 'grey' : 'red',
    borderWidth: '0',
    backgroundColor: 'transparent',
  }),
  // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //   const color = chroma(data.color);
  //   return {
  //     ...styles,
  //     backgroundColor: isDisabled
  //       ? undefined
  //       : isSelected
  //       ? data.color
  //       : isFocused
  //       ? color.alpha(0.1).css()
  //       : undefined,
  //     color: isDisabled
  //       ? '#ccc'
  //       : isSelected
  //       ? chroma.contrast(color, 'white') > 2
  //         ? 'white'
  //         : 'black'
  //       : data.color,
  //     cursor: isDisabled ? 'not-allowed' : 'default',

  //     ':active': {
  //       ...styles[':active'],
  //       backgroundColor: !isDisabled
  //         ? isSelected
  //           ? data.color
  //           : color.alpha(0.3).css()
  //         : undefined,
  //     },
  //   };
  // },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
}

function CustomSelect(props) {
  const { value, onChange, options } = props

  return (
    <div className="CustomSelect">
      <Select
        styles={style}
        className="w-100"
        classNamePrefix="select"
        options={options}
        value={value}
        onChange={onChange}
        name="color"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        menuPortalTarget={document.querySelector('body')}
        menuPosition="fixed"
        menuPlacement="auto"
      />
    </div>
  )
}

export default CustomSelect
