import axiosClient from './axiosClient'
const departmentApi = {
  getAll: (params) => {
    const url = '/department/getAll'
    return axiosClient.get(url, { params })
  },
  getById: (params) => {
    const url = '/department/getById'
    return axiosClient.get(url, { params })
  },
  save: (body) => {
    const url = '/department/save'
    return axiosClient.post(url, { body })
  },
  remove: (params) => {
    const url = '/department/remove'
    return axiosClient.delete(url, { params })
  },
}
export default departmentApi
