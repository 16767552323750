import React from 'react'
import PropTypes from 'prop-types'

export const KTAdvanceNavFontWeight = {
  light: '',
  lighter: 'lighter',
  bold: 'bold',
  bolder: 'bolder',
  boldest: 'boldest',
}

export const KTAdvanceNavColor = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
  dark: 'dark',
  light: 'light',
}

KTAdvanceNav.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      iconElement: PropTypes.element,
      text: PropTypes.string,
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      labelElement: PropTypes.element,
      showArrow: PropTypes.bool,
      showBullet: PropTypes.bool,
    }),
  ),
  fontWeight: PropTypes.oneOf(Object.values(KTAdvanceNavFontWeight)),
  enableHoverStyle: PropTypes.bool,
  enableActiveStyle: PropTypes.bool,
  enableLinkRounded: PropTypes.bool,
  enableAccentStyle: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(KTAdvanceNavColor)),
}

KTAdvanceNav.defaultProps = {
  itemList: [],
  fontWeight: KTAdvanceNavFontWeight.light,
  enableHoverStyle: false,
  enableActiveStyle: false,
  enableLinkRounded: false,
  enableAccentStyle: false,
  color: KTAdvanceNavColor.primary,
}

/**
 *
 * @param {{
 * itemList: {
 *  iconElement: element,
 *  text: string,
 *  active: boolean,
 *  disabled: boolean,
 *  labelElement: element,
 *  showArrow: boolean,
 *  showBullet: boolean,
 * }[],
 * fontWeight: string,
 * enableHoverStyle: boolean,
 * enableActiveStyle: boolean,
 * enableLinkRounded: boolean,
 * enableAccentStyle: boolean,
 * color: string,
 * }} props
 * @returns
 */
function KTAdvanceNav(props) {
  // MARK: --- Params ---
  const {
    itemList,
    fontWeight,
    enableHoverStyle,
    enableActiveStyle,
    enableLinkRounded,
    enableAccentStyle,
    color,
  } = props

  return (
    <div>
      <ul
        className={`navi navi-${fontWeight} ${
          enableHoverStyle ? 'navi-hover' : ''
        } ${enableActiveStyle ? 'navi-active' : ''} ${
          enableAccentStyle ? 'navi-accent' : ''
        } ${enableLinkRounded ? 'navi-link-rounded' : ''} navi-${color}`}
      >
        {itemList.map((item, index) => {
          return (
            <li className="navi-item" key={index} onClick={item.click}>
              <a
                className={`navi-link ${item.active ? 'active' : ''} ${
                  item.disabled ? 'disabled' : ''
                }`}
                href="#"
              >
                {item.showBullet && (
                  <span className="navi-bullet">
                    <i className="bullet" />
                  </span>
                )}
                {item.iconElement && (
                  <span className="navi-icon">{item.iconElement}</span>
                )}
                <span className="navi-text">{item.text}</span>
                {item.labelElement && (
                  <span className="navi-label">{item.labelElement}</span>
                )}
                {item.showArrow && <span className="navi-arrow"></span>}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default KTAdvanceNav
