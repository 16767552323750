import { useQuery } from '@tanstack/react-query'
import { Flex, Space } from 'antd'
import dayjs from 'dayjs'
import InvoiceFromPosList from 'features/TaxMessage/components/modals/InvoiceFromPosList'
import { taxMessageHelper } from 'features/TaxMessage/helpers/taxMessageHepler'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useToken from 'hooks/useToken'
import { useMemo, useState } from 'react'
import { listTaxMessageInvoiceFromPosQueries } from './queries'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import { useMutationGetBulkMessage } from 'features/Invoice/screens/ListInvoice/queries'
import _ from 'lodash'

export default function ListTaxMessageInvoiceFromPosScreen() {
  const { token } = useToken()
  const [filterParams, setFilterParams] = useFilter(
    'taxMessageInvoiceFromPos',
    'gFilterTaxMessageInvoiceFromPos',
  )
  const [invoiceFromPosList, setInvoiceFromPosList] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [transmissionModalShow, setTransmissionModalShow] = useState(false)

  const defaultColumns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<TaxMessageInvoiceFromPos>[]} */
    const arr = [
      {
        id: 'messageName',
        name: 'Tên thông điệp',
        center: true,
        selector: () => 'Thông điệp gửi hóa đơn điện tử đến cơ quan thuế',
      },
      {
        id: 'dateRelease',
        name: 'Ngày gửi',
        center: true,
        selector: (row) => row.dateRelease,
        format: (row) =>
          dayjs(row.dateRelease).isValid()
            ? dayjs(row.dateRelease).format('L HH:mm:ss')
            : null,
      },
      {
        id: 'invoiceQuantity',
        name: 'Số lượng hoá đơn',
        center: true,
        minWidth: '200px',
        grow: 0,
        style: {
          justifyContent: 'flex-end',
          color: token.colorPrimaryText,
        },
        selector: (row) => row.invoiceQuantity,
        format: (row) => {
          return (
            <span
              className="text-primary cursor-pointer fw-bold"
              onMouseEnter={(e) => {
                e.target.style.textDecoration = 'underline'
              }}
              onMouseLeave={(e) => {
                e.target.style.textDecoration = 'none'
              }}
              onClick={() => {
                setSelectedRow(row)
                setInvoiceFromPosList(true)
              }}
            >
              {Utils.formatNumber(row.invoiceQuantity)}
            </span>
          )
        },
      },
      {
        id: 'statusRelease',
        name: 'Trạng thái',
        center: true,
        selector: (row) => row.statusRelease,
        format: (row) => {
          const { text, className } = taxMessageHelper.renderStatus(
            row.statusRelease,
          )
          return <span className={Utils.cn('badge', className)}>{text}</span>
        },
      },
      {
        name: 'Hành động',
        center: true,
        compact: true,
        cell: (row) => (
          <Space onClick={() => {}}>
            <TableAction
              titleActionText="Xem lịch sử truyền nhận"
              icon={<IconAction className="fa-light fa-eye" />}
              onClick={() => {
                setSelectedRow(row)
                setTransmissionModalShow(true)
              }}
            />
          </Space>
        ),
      },
    ]
    return arr
  }, [token.colorPrimaryText])

  const defaultValues = useMemo(() => {
    /** @type {FilterDefaultValueProp[]} */
    const arr = [
      {
        alias: 'Trạng thái',
        label: 'Trạng thái',
        formName: 'statusRelease',
        options: SelectOptions.statusRelease,
        value: null,
        colSpan: 'FULL',
      },
    ]
    return arr
  }, [])

  const { data: summaryInvoiceData, isPending: isLoadingData } = useQuery(
    listTaxMessageInvoiceFromPosQueries.list(filterParams),
  )

  const { mutate: checkMessages, isPending: checkingBulkMessage } =
    useMutationGetBulkMessage()

  return (
    <ContentContainer>
      <ContentContainer.Header titleContent="Lịch sử truyền nhận dữ liệu hóa đơn khởi tạo từ máy tính tiền">
        <Flex justify={'space-between'} wrap className="w-100">
          <div>
            <FilterPopover
              savedKey="gFilterTaxMessageInvoiceFromPos"
              defaultValues={defaultValues}
              onApply={setFilterParams}
            />
          </div>
          <Space wrap>
            <CustomAntButton
              customStyle={{
                minWidth: 'unset',
              }}
              isLoading={checkingBulkMessage}
              text="Lấy mã CQT"
              antProps={{
                type: 'default',
                title: 'Lấy mã CQT',
                onClick: () =>
                  checkMessages(_.pick(filterParams, ['startDate', 'endDate'])),
              }}
            />
            <CustomAntButton
              customStyle={{
                minWidth: 'unset',
              }}
              // iconCn="fa-light fa-download"
              text="Xuất Excel"
              antProps={{
                type: 'default',
                title: 'Xuất Excel',
              }}
            />
          </Space>
        </Flex>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={defaultColumns}
          dataSource={summaryInvoiceData?.rows}
          isLoading={isLoadingData}
          handleDoubleClickRow={(row) => console.log(row)}
          pagination={{
            total: summaryInvoiceData?.total,
            currentPage: filterParams.page,
            rowsPerPage: filterParams.limit,
            onChangePage: (page) => setFilterParams({ page: parseInt(page) }),
            onChangeRowsPerPage: (limit) =>
              setFilterParams({ limit: parseInt(limit) }),
          }}
        />
      </ContentContainer.Body>
      <InvoiceFromPosList
        open={invoiceFromPosList}
        onOpenChange={setInvoiceFromPosList}
        invoiceIds={selectedRow?.summaryDetail?.map(
          ({ invoiceId }) => invoiceId,
        )}
      />

      <ModalDataTransmissionLog
        show={transmissionModalShow}
        onHide={() => setTransmissionModalShow(false)}
        summaryInvoice={selectedRow}
      />
    </ContentContainer>
  )
}
