import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import './style.scss'

TabCustom.propTypes = {
  className: PropTypes.string,

  tabs: PropTypes.array,

  activeTab: PropTypes.string,

  handleClick: PropTypes.func,
}

TabCustom.defaultProps = {
  className: '',

  tabs: [],

  activeTab: null,

  handleClick: null,
}

/**

 * @props className (string): không bắt buộc

 * @props tabs (array string): Mảng tên các tab theo thứ tự đảo ngược

 * @props activeTab (string): Tên active tab. K bắt buộc, mặc định là tab đầu

 * @props handleClick(tabName) : Hàm callback tới node cha khi click vào tab

 */

/**

 *

 * @param {{className: string, tabs: string[], activeTab: string, handleClick: function}} props

 * @returns

 */

function TabCustom(props) {
  const { tabs, handleClick, className, setTab } = props

  let activeTab = props.activeTab

  const { t } = useTranslation()

  const id = uuidv4()

  const navigate = useNavigate()

  if (_.isEmpty(activeTab)) {
    activeTab = tabs[tabs.length - 1]
  }

  const onClick = (tab, e) => {
    handleClick(tab)
  }

  useEffect(() => {
    var allTabs = document.getElementById(id).getElementsByClassName('tab')

    for (var i = 0, len = allTabs.length; i < len; i++) {
      allTabs[i].addEventListener('click', function () {
        if (this.classList.contains('active')) return

        var parent = this.parentNode,
          innerTabs = parent.querySelectorAll('.tab')

        for (var index = 0, iLen = innerTabs.length; index < iLen; index++) {
          innerTabs[index].classList.remove('active')
        }

        this.classList.add('active')
      })
    }
  }, [])

  return (
    <div id={id}>
      <div className="tabbed round d-flex flex-row">
        {tabs.map((text, index) => {
          return (
            <div
              key={index}
              className={text == activeTab ? 'tab active' : 'tab'}
              onClick={(e) => {
                e.preventDefault()

                onClick(text)

                if (setTab) {
                  setTab(text)
                }
              }}
              style={
                index == tabs.length - 1
                  ? { padding: '1rem 1.8rem 1rem 1.2rem' }
                  : {}
              }
            >
              {t(text)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TabCustom
