import DashboardCharts from './components/DashboardCharts'
import DashboardStatisticCards from './components/DashboardStatisticCards'

function DashboardHomeScreen() {
  return (
    <div className="container-fluid px-5 pb-5">
      <div className="card card-custom py-5">
        {/* card stat */}
        <DashboardStatisticCards />
        {/* Charts */}
        <DashboardCharts />
      </div>
    </div>
  )
}

export default DashboardHomeScreen
