import { useQuery } from '@tanstack/react-query'
import { Tooltip } from 'antd'
import orderApi from 'api/orderApi'
import resourceApi from 'api/resourceApi'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import dayjs from 'dayjs'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomSegmented from 'general/components/CustomSegmented'
import CustomTag from 'general/components/CustomTag'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import find from 'lodash/find'
import { useCallback, useMemo, useState } from 'react'
import { shallowEqual } from 'react-redux'
import ModalInfoOrder from './components/ModalInfoOrder'
import ResourcePlans from './components/ResourcePlans'
import { renderPaymentMethod, renderPaymentStatus } from './helpers'
import './style.scss'

const tabs = ['Lịch sử cấp tài nguyên', 'Quản lý đơn hàng']

function ResourceManagement() {
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const userFunction = useAppSelector(
    (states) => states?.function?.userFunctions,
  )
  const listFunction = useAppSelector(
    (states) => states?.function?.listFunctions,
  )

  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)
  const [infoModalState, setInfoModalState] = useState(false)
  const [showNoRightModal, setShowNoRightModal] = useState(false)
  const [activeRow, setActiveRow] = useState(null)
  // ================== Queries n mutations ==================

  const resourceQuery = useQuery({
    enabled: currentTab === tabs[0] && systemType.invoice,
    queryKey: ['resource', systemType.invoice],
    queryFn: () =>
      resourceApi.getByCompanyId({
        invoiceType: systemType.invoice ? 'INVOICE' : 'TICKET',
      }),
  })

  const orderQuery = useQuery({
    enabled: currentTab === tabs[1],
    queryKey: ['order'],
    queryFn: () => orderApi.getByCompanyId(),
  })

  //==================== Memos ============================

  const [isViewRight] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'INFO_RESOURCES_VIEW_LIST',
        },
        {
          listFunctionCode: 'INFO_RESOURCES_EDIT',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])

  const columns = useMemo(() => {
    /**@type {import('react-data-table-component').TableColumn<ReturnOrderData>[]} */
    const orderData = [
      {
        id: 'stt',
        center: true,
        name: 'STT',
        selector: (_row, index) => index + 1,
      },
      {
        id: 'orderCode',
        name: 'Số đơn hàng',
        selector: ({ orderCode }) => orderCode,
      },
      {
        id: 'dateOrder',
        name: 'Ngày đặt hàng',

        format: ({ dateOrder }) =>
          dayjs(dateOrder).isValid() && dayjs(dateOrder).format('L'),
        selector: ({ dateOrder }) => dateOrder,
      },
      {
        id: 'totalPayment',
        name: 'Tổng tiền thanh toán',
        right: true,

        format: ({ totalPayment }) => Utils.formatNumber(totalPayment),
        selector: ({ totalPayment }) => totalPayment,
      },
      {
        id: 'paymentMethod',
        name: 'Phương thức thanh toán',

        format: ({ paymentMethod }) => (
          <Tooltip title={renderPaymentMethod(paymentMethod)}>
            {renderPaymentMethod(paymentMethod)}
          </Tooltip>
        ),
        selector: ({ paymentMethod }) => renderPaymentMethod(paymentMethod),
      },
      {
        id: 'paymentStatus',
        name: 'Trạng thái thanh toán',
        minWidth: '200px',
        format: ({ paymentStatus }) => (
          <CustomTag
            text={renderPaymentStatus(paymentStatus).text}
            tagProps={{
              className: Utils.cn(
                'text-capitalize badge',
                renderPaymentStatus(paymentStatus).cn,
              ),
            }}
          />
        ),
        selector: ({ paymentStatus }) => renderPaymentStatus(paymentStatus),
      },
      {
        id: 'resourceIssuedStatus',
        name: 'Trạng thái cấp tài nguyên',
      },
    ]
    /** @type {import('react-data-table-component').TableColumn<ReturnResourceData>[]} */
    const resourceData = [
      {
        id: 'stt',
        center: true,
        name: 'STT',
        selector: (_row, index) => index + 1,
      },
      {
        id: 'createdAt',
        name: 'Ngày cấp',
        center: true,

        format: ({ createdAt }) =>
          dayjs(createdAt).isValid() && dayjs(createdAt).format('L'),
        selector: ({ createdAt }) => createdAt,
      },
      {
        id: 'invoiceType',
        name: 'Loại tài nguyên',
        center: true,

        selector: ({ invoiceType }) =>
          find(SelectOptions.resourceType, { value: invoiceType })?.label,
      },
      {
        id: 'purchasedQuantity',
        name: 'Số lượng',
        right: true,

        selector: ({ purchasedQuantity }) => purchasedQuantity,
      },
    ]
    return currentTab === tabs[0] ? resourceData : orderData
  }, [currentTab])

  //==================== function ============================

  const viewDetailOrder = useCallback(
    (row) => {
      if (!isViewRight) {
        setShowNoRightModal(true)
      } else {
        setActiveRow(row)
        setInfoModalState(true)
      }
    },
    [isViewRight],
  )

  return (
    <ContentContainer wrapperClassName="ResourceManagement">
      <ContentContainer.Header titleContent={'Quản lý tài nguyên'} />
      <ContentContainer.Body className={'gap-3'}>
        <ResourcePlans />
        <div className="w-50">
          <CustomSegmented
            options={tabs}
            defaultValue={currentTab}
            onChange={(tab) => setCurrentTab(tab)}
            fullWidth
          />
        </div>
        <CustomDataTable
          columns={columns}
          scrollY="400px"
          dataSource={
            currentTab === tabs[0] ? resourceQuery.data : orderQuery.data
          }
          isLoading={
            currentTab === tabs[0]
              ? resourceQuery.isLoading
              : orderQuery.isLoading
          }
          isClickable={currentTab === tabs[1]}
          customTableStyle={customDataTableStyle}
          handleDoubleClickRow={viewDetailOrder}
        />
        {/* <OrderManagement
          updateResource={updateResource}
          setUpdateResource={setUpdateResource}
          data={orders}
          userFunction={userFunction}
          listFunction={listFunction}
          viewRight={isViewRight}
          editRight={isEditRight}
        /> */}
      </ContentContainer.Body>
      {/* Modals */}
      <ModalInfoOrder
        rowData={activeRow}
        isOpen={infoModalState}
        onOpenChange={setInfoModalState}
      />
      <ModalUnauthorized
        show={showNoRightModal}
        setShow={(status) => setShowNoRightModal(status)}
      />
    </ContentContainer>
  )
}

export default ResourceManagement
