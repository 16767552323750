import { Tag, theme } from 'antd'

/**
 * @param {object} props - The props object
 * @param {import('antd').TagProps} props.tagProps - Additional props to pass to the Tag
 * @param {import('react').ReactNode} props.text - The text to display in the Tag
 * @returns {JSX.Element} A Tag component with the specified text and style.
 */
export default function CustomTag({ text, tagProps }) {
  const { token } = theme.useToken()
  return (
    <Tag
      {...tagProps}
      style={{
        fontWeight: 500,
        borderRadius: token.borderRadiusSM,
      }}
    >
      {text}
    </Tag>
  )
}
