import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Secment from 'general/components/eInvoiceComponents/AppSecment'
import { useTranslation } from 'react-i18next'
import EInvoiceProcess from 'features/Process/components/EInvoiceProcess'
import EInvoiceFromCashRegisters from 'features/Process/components/EInvoiceFromCashRegistersProcess'

ProcessHomeScreen.propTypes = {}

function ProcessHomeScreen(props) {
  // MARK --- Params: ---
  const { t } = useTranslation()
  const tabs = [
    t('Electronic invoice'),
    t('E-invoices generated from cash registers'),
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  // MARK --- Function: ---
  function handleSelectedTab(newTab) {
    setSelectedTab(newTab)
  }

  return (
    <div className="container-fluid">
      <div className="card card-custom">
        <div className="card-header border-bottom-0">
          <Secment
            tabs={tabs}
            activeTab={selectedTab}
            handleClick={handleSelectedTab}
            className="d-flex justify-content-center align-items-center"
          />
        </div>

        <div className="card-body p-0 mx-4 overflow-auto d-xxl-flex justify-content-center align-items-center">
          {selectedTab === t('Electronic invoice') ? (
            <EInvoiceProcess />
          ) : (
            <EInvoiceFromCashRegisters />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProcessHomeScreen
