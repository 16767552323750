import dayjs from 'dayjs'
import { useMemo } from 'react'

import RegistrationHelper from 'general/helpers/RegistrationHelper'
import Utils from 'general/utils/Utils'
import './style.scss'

function Message301(props) {
  const { message } = props
  console.log(message)
  const So = useMemo(
    () => message.contentRaw?.TDiep?.DLieu?.TBao?.STBao?.So,
    [message],
  )
  const isSent = useMemo(() => message.messageId?.startsWith('V'), [message])
  const TGNhan = useMemo(
    () =>
      message?.contentRaw?.TDiep?.DLieu?.TBao?.DSCKS?.CQT?.Signature?.Object
        ?.SignatureProperties?.SignatureProperty?.SigningTime,
    [message],
  )

  const isReceived = useMemo(
    () => !message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.DSLDKCNhan,
    [message],
  )

  const invoices = useMemo(() => {
    let invoices = message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.DSHDon?.HDon
    if (!invoices) return
    return Array.isArray(invoices) ? invoices : [invoices]
  }, [message])

  const renderErrReason = (LDos) => {
    let errReasons = Array.isArray(LDos) ? LDos : [LDos]
    let errString = ''
    errReasons.map(({ MTa }, index) => {
      errString += MTa
    })
    return errString
  }

  const renderSuccess = (TCTBao) => {
    let successString = ''
    switch (TCTBao) {
      case '1':
        successString = 'TBSS huỷ hoá đơn thành công'
        break
      case '2':
        successString = 'TBSS điều chỉnh hoá đơn thành công'
        break
      case '3':
        successString = 'TBSS thay thế hoá đơn thành công'
        break
      case '4':
        successString = 'TBSS giải trình hoá đơn thành công'
        break
      default:
    }
    return successString
  }

  return (
    <div className="Message301 border-bottom mb-5">
      <div className="d-flex flex-row">
        <div style={{ maxWidth: '500px' }}>
          <p className="mb-2">
            <i
              className={`fa-regular ${
                isSent ? 'fa-arrow-up' : 'fa-arrow-down'
              } mr-2 ${isSent ? 'i-send' : 'i-receivce'}`}
            ></i>
            <span className="font-weight-bold">
              {TGNhan && dayjs(TGNhan).format('DD/MM/YYYY hh:mm:ss')}
            </span>
          </p>

          <p className="mb-2">
            {`[${
              message.typeCode
            }] ${RegistrationHelper.renderRegistrationTypeCode(
              message.typeCode,
            )}`}
            Thông điệp thông báo về việc tiếp nhận và kết quả xử lý về việc hóa
            đơn điện tử đã lập có sai sót
          </p>

          {invoices && (
            <div className="p-3" style={{ backgroundColor: '#fff3cd' }}>
              {invoices?.map(
                ({ KHMSHDon, KHHDon, SHDon, DSLDKTNhan, TCTBao }, index) => (
                  <p key={index} className="mb-2">
                    {`[Ký hiệu: ${KHMSHDon}${KHHDon}, Số: ${SHDon}]: ${
                      DSLDKTNhan
                        ? renderErrReason(DSLDKTNhan?.LDo)
                        : renderSuccess(TCTBao)
                    }  `}
                  </p>
                ),
              )}
            </div>
          )}

          <p className="mb-2">
            Số thông báo:{' '}
            <a href="#" title="Xem thông báo">
              {So}
            </a>
          </p>

          <p className="mb-2 mt-2" style={{ color: '#c4c4c4' }}>
            {`Mã thông điệp: ${message.messageId}`}
          </p>
        </div>
        <div className="text-right flex-fill">
          <p className="mb-2 font-weight-bold">
            {isSent ? '0106870211' : 'Cơ quan thuế'}
          </p>

          <p className="mb-2">
            <span
              className="text-dark-75 font-weight-normal"
              style={{
                borderRadius: '40px',
                backgroundColor: isReceived ? '#a9efb7' : '#fff3cd',
                color: isReceived ? '#12a744' : '#856404',
                padding: '3px 12px',
              }}
            >
              CQT đã xử lý
            </span>
          </p>

          <p
            className="mb-2 cursor-pointer"
            onClick={() => {
              Utils.exportXmlFile({
                data: message.contentRaw.TDiep,
                fileName: 'thong-diep-301',
                _parse: true,
              })
            }}
            title="Xuất khẩu XML"
          >
            <i
              className="fa-regular fa-download"
              style={{ color: 'inherit' }}
            ></i>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Message301
