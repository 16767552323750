import * as y from 'yup'

/** @param {import("react-i18next").TFunction} t */
export const generateSchema = (t) => {
  return {
    sendDraftInvoiceMail: y.object().shape({
      customerName: y.string().required(t('Required')),
      customerEmail: y
        .string()
        .email('Email không đúng định dạng')
        .required(t('Required')),
      customerEmailCc: y
        .string()
        .email('Email không đúng định dạng')
        .nullable(),
      customerEmailBcc: y
        .string()
        .email('Email không đúng định dạng')
        .nullable(),
    }),
    emailSchema: y.string().email('Email không đúng định dạng'),
  }
}
