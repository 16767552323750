import axiosClient from './axiosClient'

const voucherApi = {
  getByCode: (params) => {
    const url = '/voucher/getByCode'
    return axiosClient.get(url, { params })
  },
}

export default voucherApi
