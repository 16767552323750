import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import CardStat from 'features/Dashboard/components/CardStat'
import { queriesKeys } from 'features/Dashboard/queries'
import CustomAntRangePicker from 'general/components/CustomAntRangePicker'
import Global from 'general/utils/Global'
import { useState } from 'react'
import CardCert from './CardCert'
import { useAppSelector } from 'hooks/useRedux'
import { shallowEqual } from 'react-redux'

export default function DashboardStatisticCards() {
  const [filterParams_issuedInvoice, setFilterParams_issuedInvoice] = useState(
    Global['gFiltersIssuedInvoiceCount'],
  )
  const [filterParams_notIssuedInvoice, setFilterParams_notIssuedInvoice] =
    useState(Global['gFiltersNotIssueInvoiceCount'])
  const [filterParams_issuedInvoiceValue, setFilterParams_issuedInvoiceValue] =
    useState(Global['gFiltersIssuedInvoiceValue'])
  // queries
  const { data: issuedInvoiceCount, isLoading: isLoadingIssueInvoice } =
    useQuery(queriesKeys.issuedInvoicesCount(filterParams_issuedInvoice))

  const { data: notIssuedInvoiceCount, isLoading: isLoadingNotIssuedInvoice } =
    useQuery(queriesKeys.notIssuedInvoicesCount(filterParams_notIssuedInvoice))

  const { data: issueInvoiceValue, isLoading: isLoadingIssuedInvoiceValue } =
    useQuery(queriesKeys.issuedInvoicesValue(filterParams_issuedInvoiceValue))

  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)
  return (
    <div className="row m-0">
      <div className="col-md-8 my-md-4 mb-0 mt-4">
        <div className="row gap-md-0 gap-sm-3 m-0 h-100">
          <div className="col-md-4 pl-md-0 pb-5 pb-md-0">
            <CardStat
              isLoading={isLoadingIssueInvoice}
              title={
                systemType?.ticket
                  ? 'Số vé đã phát hành'
                  : systemType?.personalIncomeTax
                    ? 'Số CT đã phát hành'
                    : 'Số HĐ đã phát hành'
              }
              value={issuedInvoiceCount}
              unit={systemType?.ticket ? 'Vé' : 'Hóa đơn'}
              backgroundColor="linear-gradient(to right, #664dc9, #9884ea)"
              toolbar={
                <CustomAntRangePicker
                  className="w-100"
                  colorStyle={'#9884ea'}
                  dateFormat="L"
                  defaultValue={[
                    dayjs(filterParams_issuedInvoice.startDate),
                    dayjs(filterParams_issuedInvoice.endDate),
                  ]}
                  defaultPeriod={filterParams_issuedInvoice.period}
                  onChange={(range, period) => {
                    setFilterParams_issuedInvoice({
                      period,
                      startDate: range[0]?.format(),
                      endDate: range[1]?.format(),
                    })
                  }}
                />
              }
            />
          </div>
          <div className="col-md-4 pb-5 pb-md-0">
            <CardStat
              isLoading={isLoadingNotIssuedInvoice}
              title={
                systemType?.ticket
                  ? 'Số vé chưa phát hành, gửi/ ký lỗi'
                  : systemType?.personalIncomeTax
                    ? 'Số CT chưa phát hành, gửi/ ký lỗi'
                    : 'Số HĐ chưa phát hành, gửi/ ký lỗi'
              }
              value={notIssuedInvoiceCount}
              unit={systemType?.ticket ? 'Vé' : 'Hóa đơn'}
              backgroundColor="linear-gradient(to right, #1D976C, #2fd38a)"
              toolbar={
                <CustomAntRangePicker
                  className="w-100"
                  colorStyle={'#2fd38a'}
                  dateFormat="L"
                  placement="bottomRight"
                  defaultValue={[
                    dayjs(filterParams_notIssuedInvoice.startDate),
                    dayjs(filterParams_notIssuedInvoice.endDate),
                  ]}
                  defaultPeriod={filterParams_notIssuedInvoice.period}
                  onChange={(range, period) => {
                    setFilterParams_notIssuedInvoice({
                      period,
                      startDate: range[0]?.format(),
                      endDate: range[1]?.format(),
                    })
                  }}
                />
              }
            />
          </div>
          <div className="col-md-4 pr-md-0 pb-5 pb-md-0">
            <CardStat
              isLoading={isLoadingIssuedInvoiceValue}
              title={
                systemType?.ticket
                  ? 'Giá trị vé đã phát hành'
                  : systemType?.personalIncomeTax
                    ? 'Giá trị CT đã phát hành'
                    : 'Giá trị HĐ đã phát hành'
              }
              value={issueInvoiceValue}
              unit="VNĐ"
              backgroundColor="linear-gradient(to right, #5b73e8, #44c4fa)"
              toolbar={
                <CustomAntRangePicker
                  className="w-100"
                  colorStyle={'#44c4fa'}
                  dateFormat="L"
                  defaultValue={[
                    dayjs(filterParams_issuedInvoiceValue.startDate),
                    dayjs(filterParams_issuedInvoiceValue.endDate),
                  ]}
                  defaultPeriod={filterParams_issuedInvoiceValue.period}
                  onChange={(range, period) => {
                    setFilterParams_issuedInvoiceValue({
                      period,
                      startDate: range[0]?.format(),
                      endDate: range[1]?.format(),
                    })
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
      <div className="col-md-4 my-md-4 mt-0 mb-4">
        <div className="row h-100 mx-0">
          <div className="col-md-12 p-md-0">
            <CardCert />
          </div>
        </div>
      </div>
    </div>
  )
}
