import { useTranslation } from 'react-i18next'
import { MoreOutlined, SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  ButtonTutorial,
  DivAdd,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalCustom,
  ModalWrapper,
  StyleButtonClose,
  TableCustom,
} from 'features/TaxDeduction/styled'
import {
  ContainerContentModal,
  DivItemTree,
  TreeSelectCustom,
  TreeSelectCustomH,
} from 'features/Department/styled'
import {
  ButtonCustom,
  ButtonCustomT,
  CheckboxCumstom,
  ContainerFunction,
  ContainerTable,
  DatePickerCustom,
  DeleteOutlinedIcon,
  DivFunction,
  DivListFunction,
  DivListFunctionView,
  FormCustom,
  FormOutlinedIcon,
  InputCustom,
  InputNumberCumstom,
  MinusOutlinedCustom,
  PlusOutlinedCustom,
  RadioCustom,
  RedStar,
  SelectCustom,
  SpanNotYetReleasedTd,
  TextAreaCustom,
} from 'features/TaxDeductionDocuments/styled'

import SelectOptions from 'general/constants/SelectOptions'
import { Button, Empty, Form, Space } from 'antd'
import { removeItemByKeys, toLower } from 'general/utils/Utils'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'hooks/useRedux'
import {
  thunkJobtitle,
  thunkRemoveJobtitle,
  thunkUpdateJobtitle,
} from 'features/JobTitle/jobTitleSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import AlertTaxDectionDocumentsUpdate from 'features/TaxDeductionDocuments/component/Alert'
function ViewJobTitle({ openModal, changeOpenModal, onSave }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState([])
  const [typeAdd, setTypeAdd] = useState(false)
  const [openModalAlertRemove, setOpenModalAlertRemove] = useState(false)
  const [valueCheck, setValueCheck] = useState()
  const [dataActive, setDataActive] = useState({
    jobTitleId: null,
    jobTitleName: null,
    valueCurrent: null,
    jobTitleIdRemove: null,
  })
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
  })
  const dispatch = useAppDispatch()

  const findDataSource = async () => {
    try {
      const result = unwrapResult(
        await dispatch(
          thunkJobtitle({
            page: panningDataSource.page,
            limit: panningDataSource.limit,
          }),
        ),
      )
      if (result?.message === 'success') {
        setDataSource(result?.data?.items)
        setPanningDataSource({
          ...panningDataSource,
          totalItems: result?.data?.totalItems,
          totalPages: result?.data?.totalPages,
        })
      }
    } catch (error) {}
  }

  const update = async (values) => {
    try {
      const result = unwrapResult(await dispatch(thunkUpdateJobtitle(values)))
      if (result?.message === 'success') {
        form.resetFields()
        setDataActive({
          jobTitleId: null,
          jobTitleName: null,
          valueCurrent: null,
        })
        if (values?.jobTitleId) {
          ToastHelper.showSuccess(t('jobTitle.suscessEdit'))
        } else {
          ToastHelper.showSuccess(t('jobTitle.suscessAdd'))
        }

        findDataSource()
      } else {
        ToastHelper.showError(result?.data)
      }
    } catch (error) {}
  }

  const remove = async (values) => {
    try {
      const result = unwrapResult(await dispatch(thunkRemoveJobtitle(values)))
      if (result?.message === 'success') {
        setDataActive({
          jobTitleId: null,
          jobTitleName: null,
          valueCurrent: null,
          jobTitleIdRemove: null,
        })
        setOpenModalAlertRemove(false)
        ToastHelper.showSuccess(result?.data)
        findDataSource()
        if (values?.jobTitleId == valueCheck) {
          setValueCheck(null)
        }
      } else {
        ToastHelper.showError(result?.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (openModal) {
      form.resetFields()
      findDataSource()
    }
  }, [openModal])

  useEffect(() => {
    if (typeAdd) {
      form.resetFields()
      setDataActive({
        jobTitleId: null,
        jobTitleName: null,
        valueCurrent: null,
      })
    }
  }, [typeAdd])

  const columns = [
    {
      title: t('jobTitle.nameJobTitle'),
      className: 'left-header',
      render: (text, record, index) => {
        let listFunction = [
          {
            key: 'remove',
            title: t('taxDeductionDocuments.remove'),
            icon: (
              <DeleteOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDataActive({
                    jobTitleId: null,
                    jobTitleName: null,
                    jobTitleIdRemove: record?.jobTitleId,
                  })
                  setTypeAdd(false)
                  setOpenModalAlertRemove(true)
                }}
              />
            ),
          },

          {
            key: 'edit',
            title: t('taxDeductionDocuments.editt'),
            icon: (
              <FormOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDataActive({
                    jobTitleId: record?.jobTitleId,
                    jobTitleName: record?.jobTitleName,
                    valueCurrent: record?.jobTitleName,
                  })
                  setTypeAdd(false)
                }}
              />
            ),
          },
        ]
        if (record.type === 1) {
          listFunction = removeItemByKeys([...listFunction], ['edit', 'remove'])
        }
        return (
          <ContainerFunction className="left-cell">
            <DivListFunctionView className="listFuntion">
              {listFunction.map((item, indexl) => (
                <DivFunction
                  title={item.title}
                  key={indexl}
                  right={`${indexl * 40}px`}
                >
                  {item.icon}
                </DivFunction>
              ))}
            </DivListFunctionView>
            {dataActive?.jobTitleId != record?.jobTitleId && (
              <RadioCustom
                value={record?.jobTitleId}
                onClick={() => {
                  setValueCheck(record?.jobTitleId)
                }}
              >
                {record?.jobTitleName}
              </RadioCustom>
            )}
            {dataActive?.jobTitleId == record?.jobTitleId && (
              <Space.Compact style={{ width: '100%' }}>
                <InputCustom
                  onChange={(e) => {
                    setDataActive({
                      ...dataActive,
                      jobTitleName: e.target.value,
                    })
                  }}
                  value={dataActive?.jobTitleName}
                  placeholder={t('department.keyPlacehoder', {
                    key: toLower(t('jobTitle.nameJobTitle')),
                  })}
                />
                <ButtonCustomT
                  onClick={() => {
                    if (dataActive?.jobTitleName && dataActive?.jobTitleId) {
                      update(dataActive)
                    }
                  }}
                  color="#ffffff"
                  backgroundColor="#1d88fe"
                  border="0px solid #e0e0e0"
                >
                  {t('jobTitle.save')}
                </ButtonCustomT>
                <ButtonCustomT
                  onClick={() => {
                    setDataActive({
                      jobTitleId: null,
                      jobTitleName: null,
                      valueCurrent: null,
                    })
                  }}
                  color="#000000"
                  backgroundColor="#f1e5e5"
                  border="0px solid #e0e0e0"
                >
                  {t('jobTitle.close')}
                </ButtonCustomT>
              </Space.Compact>
            )}
          </ContainerFunction>
        )
      },
    },
  ]

  return (
    <>
      <ModalCustom
        onClick={() => {
          setTypeAdd(false)
        }}
        title={t('jobTitle.title')}
        open={openModal}
        onCancel={() => changeOpenModal(false)}
        width={750}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    changeOpenModal(false)
                  }}
                >
                  {t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  onClick={() => {
                    if (valueCheck) {
                      onSave(valueCheck)
                    } else {
                      ToastHelper.showError(t('jobTitle.validateRequiredAdd'))
                    }
                  }}
                >
                  {t('jobTitle.chon')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <ContainerContentModal>
          <ContainerTable style={{ padding: '20px 20px 0px 20px' }}>
            <RadioCustom.Group style={{ width: '100%' }} value={valueCheck}>
              <TableCustom
                marginBottom={'0px'}
                heightTr={'40px !important'}
                dataSource={dataSource}
                columns={columns}
                locale={{
                  emptyText: <Empty description={t('taxDeduction.noData')} />,
                }}
                marginRight={'0px'}
                marginLeft={'0px'}
                pagination={
                  panningDataSource.totalPages > 1
                    ? {
                        current: panningDataSource.page,
                        pageSize: panningDataSource.limit,
                        total: panningDataSource.totalItems,
                      }
                    : false
                }
                onChange={(pagination) => {
                  setPanningDataSource({
                    ...pagination,
                    page: pagination.current,
                    limit: pagination.pageSize,
                  })
                }}
              />
            </RadioCustom.Group>
            <div>
              <FormCustom form={form} layout="vertical">
                {typeAdd && (
                  <FormCustom.Item
                    layout="horizontal"
                    name={'jobTitleName'}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value || value.length == 0) {
                            return Promise.reject(
                              new Error(
                                t('jobTitle.nameJobTitle', {
                                  key: t('department.keyRequired'),
                                }),
                              ),
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Space.Compact style={{ width: '100%' }}>
                      <InputCustom
                        placeholder={t('department.keyPlacehoder', {
                          key: toLower(t('jobTitle.nameJobTitle')),
                        })}
                      />
                      <ButtonCustomT
                        onClick={() =>
                          form
                            .validateFields()
                            .then((values) => {
                              update(values)
                            })
                            .catch((errorInfo) => {
                              console.error('Validation Failed:', errorInfo)
                            })
                        }
                        type="submit"
                        color="#ffffff"
                        backgroundColor="#1d88fe"
                        border="0px solid #e0e0e0"
                      >
                        {t('jobTitle.addt')}
                      </ButtonCustomT>
                    </Space.Compact>
                  </FormCustom.Item>
                )}
                <DivAdd
                  onClick={() => {
                    setTypeAdd(!typeAdd)
                  }}
                >
                  {typeAdd ? (
                    <MinusOutlinedCustom style={{ fontSize: 11 }} />
                  ) : (
                    <PlusOutlinedCustom style={{ fontSize: 11 }} />
                  )}

                  {` `}
                  {t('jobTitle.add')}
                </DivAdd>
              </FormCustom>
            </div>
          </ContainerTable>
        </ContainerContentModal>
      </ModalCustom>

      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.remove')}
        openModalAlert={openModalAlertRemove}
        titleAlert={t('jobTitle.titleRemove')}
        messageAlert={t('jobTitle.messageRemove')}
        setOpenModalAlert={(value) => {
          setOpenModalAlertRemove(value)
        }}
        onClickPrimary={() => {
          remove({ jobTitleId: dataActive?.jobTitleIdRemove })
        }}
        colorButtonSave={'#ffffff'}
        backGroundButtonSave={'#e61d1d'}
        backGroundButtonSaveHover={'#cc0202!important'}
      />
    </>
  )
}
export default ViewJobTitle
