import { Space, Typography } from 'antd'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useGetTicketSerial from 'hooks/Queries/useGetTicketSerial'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import { find, isNil, omit, concat } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { ticketCheckPointQueries } from 'features/Category/screens/CheckTicketPortalScreen/queries'
import { useGetAccount } from '../ReportListTicketScreen/queries'
import { useExportExcelMutation, useGetListTicketExported } from './queries'

export default function ListTicketExported() {
  const { t } = useTranslation()

  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)

  const { data: serials } = useGetTicketSerial({
    includeAllOption: true,
    disabled: false,
  })

  const [filter, setFilter] = useFilter(
    'ticketExportedList',
    'gFilterTicketExportedList',
  )

  const { data: listTicket, isFetching: isGettingListTicket } =
    useGetListTicketExported(filter)

  const { data: accounts } = useGetAccount()

  const columns = useMemo(() => {
    /**
     * @type {import('react-data-table-component').TableColumn<TicketExportedReport>[]}
     */
    const arr = [
      {
        name: t('listTicketExported.exportedByPerson'),
        id: 'accountId',
        center: true,
        minWidth: '150px',
        cell: (row) => (
          <Typography.Text
            className="w-100 text-left"
            ellipsis={{
              tooltip: find(accounts, { value: row?.accountId })?.label,
            }}
          >
            {find(accounts, { value: row?.accountId })?.label}
          </Typography.Text>
        ),
      },
      {
        name: t('listTicketExported.serial'),
        id: 'serial',
        center: true,
        selector: (row) => row?.serial,
      },
      {
        name: 'Mã thiết bị',
        id: 'serialDevice',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.serialDevice}
            </p>
          )
        },
      },
      {
        name: t('listTicketExported.templateName'),
        id: 'invoice_template.name',
        center: true,
        grow: 2,
        minWidth: '100px',
        cell: (row) => (
          <Typography.Text className="w-100 text-left">
            {row?.invoice_template?.name}
          </Typography.Text>
        ),
      },
      {
        name: t('listTicketExported.totalTicketQuantity'),
        id: 'quantityTicket',
        center: true,
        cell: (row) => (
          <Typography.Text className="w-100 text-right">
            {Utils.formatNumber(row?.quantityTicket, sysConfig.quantity)}
          </Typography.Text>
        ),
      },
      {
        name: t('listTicketExported.taxRate'),
        id: 'invoice_template.vatRateTicket',
        center: true,
        cell: (row) => (
          <Typography.Text className="">
            {isNil(row?.taxRate)
              ? isNil(row?.invoice_template?.vatRateTicket)
                ? ''
                : Utils.formatCurrency(
                    row?.invoice_template?.vatRateTicket,
                    ' %',
                  )
              : row?.taxRate}
          </Typography.Text>
        ),
      },
      {
        name: t('listTicketExported.priceTag'),
        id: 'totalPrice',
        center: true,
        cell: (row) => (
          <Typography.Text className="w-100 text-right">
            {Utils.formatNumber(row?.totalPrice, sysConfig.totalCost)}
          </Typography.Text>
        ),
      },
      {
        name: t('listTicketExported.totalBeforeTax'),
        id: 'totalBeforeTax',
        center: true,
        cell: (row) => {
          let taxRate = row?.taxRate
            ? row?.taxRate.replace('%', '')
            : row?.invoice_template?.vatRateTicket ?? 0
          const totalBeforeTax = row.sumTotalPrice / (1 + taxRate / 100)
          return (
            <Typography.Text className="w-100 text-right">
              {isNaN(totalBeforeTax)
                ? ''
                : Utils.formatNumber(totalBeforeTax, sysConfig.totalCost)}
            </Typography.Text>
          )
        },
      },
      {
        name: t('listTicketExported.taxPrice'),
        id: 'taxPrice',
        center: true,
        cell: (row) => {
          let taxRate = row?.taxRate
            ? row?.taxRate.replace('%', '')
            : row?.invoice_template?.vatRateTicket ?? 0
          const totalBeforeTax = row.sumTotalPrice / (1 + taxRate / 100)
          const taxPrice = row.sumTotalPrice - totalBeforeTax
          return (
            <Typography.Text className="w-100 text-right">
              {isNaN(totalBeforeTax)
                ? ''
                : Utils.formatNumber(taxPrice, sysConfig.totalCost)}
            </Typography.Text>
          )
        },
      },

      {
        name: t('listTicketExported.totalPrice'),
        id: 'sumTotalPrice',
        center: true,
        cell: (row) => (
          <Typography.Text className="w-100 text-right">
            {Utils.formatNumber(row?.sumTotalPrice, sysConfig.totalCost)}
          </Typography.Text>
        ),
      },
    ]
    return arr
  }, [accounts, sysConfig.quantity, sysConfig.totalCost, t])

  const { data: listTicketPortal } = useQuery({
    ...ticketCheckPointQueries.lists(),
    select: (res) => {
      return concat(
        { serialDevice: null, namePointCheckTicket: 'Tất cả' },
        res?.namePointCheckTickets,
      ).map((namePointCheckTicket) => ({
        label: namePointCheckTicket?.namePointCheckTicket,
        value: namePointCheckTicket?.serialDevice,
      }))
    },
  })

  const defaultValues = useMemo(() => {
    /** @type {FilterDefaultValueProp} */
    const arr = [
      {
        label: 'Ký hiệu',
        alias: 'Ký hiệu',
        formName: 'serials',
        options: serials,
        value: null,
        multiselect: true,
        colSpan: 24,
      },
      {
        label: 'Người xuất vé',
        alias: 'Người xiết vé',
        formName: 'accountIds',
        multiselect: true,
        colSpan: 24,
        options: accounts,
        value: null,
      },
      {
        label: 'Trạng thái vé',
        alias: 'Trạng thái vé',
        formName: 'typeTickets',
        multiselect: true,
        options: SelectOptions.ticketStatus,
        value: SelectOptions.ticketStatus[0].value,
      },
      {
        label: 'Trạng thái cấp mã',
        alias: 'Trạng thái cấp mã',
        formName: 'typeRelease',
        options: SelectOptions.ticketGrantedStatus,
        value: SelectOptions.ticketGrantedStatus[0].value,
        multiselect: true,
      },
      {
        label: 'Trạng thái gửi CQT',
        alias: 'Trạng thái gửi CQT',
        formName: 'typeSendCQTs',
        options: SelectOptions.ticketMailSentAuthorityStatus,
        value: SelectOptions.ticketMailSentAuthorityStatus[0].value,
        multiselect: true,
      },
      {
        label: 'Cổng soát vé',
        alias: 'Cổng soát vé',
        formName: 'serialDevices',
        options: listTicketPortal,
        value: null,
        multiselect: true,
      },
    ]
    return arr
  }, [accounts, listTicketPortal, serials])

  const { mutate: handleExportExcel } = useExportExcelMutation(columns)

  return (
    <ContentContainer wrapperClassName="ListTicketExported">
      <ContentContainer.Header
        titleContent={'Tổng hợp giá trị vé đã xuất theo người xuất vé'}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('ExportExcel')}
              iconCn="fa-regular fa-download"
              antProps={{
                type: 'default',
                className: 'btn btn-success',
                onClick: () =>
                  handleExportExcel(omit(filter, ['page', 'limit'])),
              }}
            />
          </Space>
        }
      >
        <Space>
          <FilterPopover
            menuPosition="bottomLeft"
            onApply={setFilter}
            savedKey="gFilterTicketExportedList"
            defaultValues={defaultValues}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={columns}
          dataSource={listTicket?.rows}
          isLoading={isGettingListTicket}
          customTableStyle={customDataTableStyle}
        />
      </ContentContainer.Body>
    </ContentContainer>
  )
}
