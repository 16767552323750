function FormLabel(props) {
  return (
    <span
      className={`d-flex justify-content-start align-items-center ${props.className}`}
      style={{
        height: props.height ?? '40px',
        minWidth: props.width ?? '130px',
        maxWidth: props.width ?? props.maxWidth ?? '130px',
        fontSize: props.fontSize ?? '14px',
        fontWeight: props.fontWeight ?? '400',
      }}
    >
      {props.children}{' '}
      {props.required && <span className="text-danger">{'\b * '}</span>}
      {props.requiredLabel && (
        <>
          <span className="text-danger">{' * '}</span> :
        </>
      )}
    </span>
  )
}

export default FormLabel
