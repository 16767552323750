import { useTranslation } from 'react-i18next'
import AppResource from 'general/constants/AppResource'
import { Empty, Form, Tooltip } from 'antd'
import {
  downloadZip,
  embedPDF,
  extractFields,
  isoDateToNumber,
  numberToIsoDate,
} from 'general/utils/Utils'
import { useEffect, useMemo, useState } from 'react'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import {
  ButtonSave,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalWrapper,
  StyleButtonClose,
  DivColBackGround,
  DivRowBackGround,
  DivColFrame,
  DivCenter,
  InfoCircleOutlinedIcon,
} from 'features/TaxDeduction/styled'
import {
  DatePickerCustom,
  FormCustom,
  InputCustom,
  InputCustomGY,
  InputNumberCumstom,
  RedStar,
  SelectCustom,
} from 'features/TaxDeductionDocuments/styled'
function ModalCustomField({
  visibleModal,
  setVisibleModal,
  listContentFeild,
  data,
  setData,
  setListContentFeild,
  activeTd,
  setActiveTd,
  activeLayout,
  setActiveLayout,
  language,
  formStatet,
}) {
  const { t } = useTranslation()
  //   const [activeDiv, setActiveDiv] = useState()
  const [listmct, setListmct] = useState([])
  const [formState, setFormState] = useState()
  const [form] = Form.useForm()

  const listAddFeildLan = [
    {
      key: 1,
      name: t('taxDeductionDocuments.addFeildLanop1'),
      lan: t('taxDeductionDocuments.addFeildLanop1L'),
    },
    {
      key: 2,
      name: t('taxDeductionDocuments.addFeildLanop2'),
      lan: t('taxDeductionDocuments.addFeildLanop2L'),
    },
    {
      key: 3,
      name: t('taxDeductionDocuments.addFeildLanop3'),
      lan: t('taxDeductionDocuments.addFeildLanop3L'),
    },
  ]

  const listType = [
    {
      value: 1,
      label: t('taxDeductionDocuments.text'),
    },
    {
      value: 2,
      label: t('taxDeductionDocuments.money'),
    },
    {
      value: 3,
      label: t('taxDeductionDocuments.number'),
    },
    {
      value: 4,
      label: t('taxDeductionDocuments.date'),
    },
  ]

  const findByProperty = (array, property, value) => {
    return array.find((item) => item[property] === value)
  }

  const getIndexList = (array) => {
    const indices = []
    const extractIndices = (obj) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => extractIndices(item))
      } else if (typeof obj === 'object' && obj !== null) {
        if ('index' in obj) {
          indices.push(obj.index)
        }
        Object.values(obj).forEach((value) => extractIndices(value))
      }
    }

    extractIndices(array)
    return indices
  }

  useEffect(() => {
    form.resetFields()
    if (!data) {
      form.setFieldValue('typeDataValue', 2)
      setFormState({ typeDataValue: 2 })
    } else {
      console.log('data', data)
      const setFields = {
        index: data.index,
        name: data?.[language[0]]?.label,
        nameLan: data?.[language[0]]?.valueLanguage,
        typeDataValue: data?.typeValue,
        defaultValue:
          data?.typeValue != 4
            ? data?.[language[0]]?.defaultValue
            : numberToIsoDate(data?.[language[0]]?.defaultValue),
      }
      // Object.entries(setFields).forEach(([key, value]) => {
      //   form.setFieldValue(key, value)
      // })
      console.log('language', language)
      console.log('setFields', setFields)
      form.setFieldsValue(setFields)
      setFormState(setFields)
    }
    if (!visibleModal) {
      setData(null)
    }
  }, [visibleModal])

  useEffect(() => {
    const indexList = getIndexList(listContentFeild)
    setListmct(indexList)
  }, [listContentFeild, visibleModal])

  useEffect(() => {
    if (!data) {
      setFormState({ ...formState, defaultValue: null })
      form.setFieldValue('defaultValue', null)
    }
  }, [formState?.typeDataValue])

  const selectAddFeildLan = (
    <SelectCustom
      style={{ width: 300 }}
      value={null}
      onChange={(value) => {
        const data = findByProperty(listAddFeildLan, 'key', value)
        form.setFieldValue('name', data.name)
        form.setFieldValue('nameLan', data.lan)
      }}
      notFoundContent={
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('taxDeduction.noData')}
        />
      }
    >
      {listAddFeildLan &&
        listAddFeildLan.map((item, index) => (
          <SelectCustom.Option value={item.key} key={index}>
            {item.name}
          </SelectCustom.Option>
        ))}
    </SelectCustom>
  )

  const addOrUpdateValue = (dataT, sectionIndex, contentIndex, newValue) => {
    const section = dataT[sectionIndex]
    const content = section.content[contentIndex]

    const existingValueIndex = content.value.findIndex(
      (item) => item.index === newValue.index,
    )

    if (existingValueIndex !== -1) {
      // Cập nhật đối tượng
      content.value[existingValueIndex] = {
        ...content.value[existingValueIndex],
        ...newValue,
      }
    } else {
      // Thêm đối tượng mới
      content.value.push(newValue)
    }
    return dataT
  }

  const submitForm = () => {
    form
      .validateFields()
      .then((dataT) => {
        let typeInput = chonseTypeInput(dataT.typeDataValue)
        let dataConvert = {
          [language[0]]: {
            label: dataT.name,
            labelStyle: {
              fontSize: 13,
              fontWeight: 400,
              textAlign: 'left',
              color: '#000000',
              textDecoration: false,
              lineHeight: 20,
              margin: '0px',
              fontFamily: 'Times New Roman',
            },
            value:
              `{{${dataT.index}}}` +
              (dataT.typeDataValue == 2
                ? ` ${
                    formStatet?.typeMoney == 'no'
                      ? ''
                      : formStatet?.typeMoney == 'vnd'
                        ? 'VND'
                        : formStatet?.typeMoney == 'd'
                          ? 'đ'
                          : ''
                  }`
                : ''),
            valueStyle: {
              fontSize: 13,
              fontWeight: 600,
              textAlign: 'left',
              color: '#000000',
              textDecoration: false,
              lineHeight: 20,
              margin: '0px',
              fontFamily: 'Times New Roman',
            },
            defaultValue: dataT.defaultValue,
            inforTypeMoney: typeInput.inforTypeMoney,
            valueLanguage: dataT.nameLan,
          },
          index: dataT.index,
          hide: false,
          update: true,
          typeValue: dataT.typeDataValue,
          typeInput: typeInput.text,
          format: typeInput.format,
          name: dataT.index,
          widthInput: dataT.typeDataValue == 4 ? 150 : 310,
        }

        const updatedData = addOrUpdateValue(
          listContentFeild,
          activeLayout.index,
          activeTd.index,
          dataConvert,
        )
        setListContentFeild(updatedData)
        setActiveLayout({
          ...activeLayout,
          data: updatedData?.[activeLayout.index],
        })
        setActiveTd({
          ...activeTd,
          data: updatedData?.[activeLayout.index]?.content?.[activeTd.index],
        })
        setVisibleModal(false)
        console.log('updatedData', updatedData)
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo)
      })
  }

  const formatter = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  const parser = (value) => value.replace(/\./g, '')

  const chonseTypeInput = (type) => {
    let result
    if (type == 1) {
      result = { text: 'inputText' }
    } else if (type == 2) {
      result = { text: 'inputNumber', format: 6, inforTypeMoney: true }
    } else if (type == 3) {
      result = { text: 'inputNumber' }
    } else if (type == 4) {
      result = { text: 'datepicker', format: 'DD/MM/YYYY' }
    } else {
      result = { text: 'inputText' }
    }
    return result
  }

  return (
    <>
      <ModalWrapper
        title={
          data
            ? t('taxDeductionDocuments.editFeild')
            : t('taxDeductionDocuments.addFeild')
        }
        open={visibleModal}
        onCancel={() => setVisibleModal(false)}
        width={750}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    setVisibleModal(false)
                  }}
                >
                  {t('taxDeduction.closeModal')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  onClick={() => {
                    submitForm()
                  }}
                >
                  {t('taxDeductionDocuments.dy')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <FormCustom
          form={form}
          onValuesChange={(value, dataForm) => {
            setFormState(dataForm)
          }}
          margin="0px -20px"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <FormCustom.Item
            label={
              <>
                {`${t('taxDeductionDocuments.stc')} `}
                <RedStar>*</RedStar>
              </>
            }
            name={'index'}
            style={{ paddingRight: 10 }}
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error(t('taxDeductionDocuments.requiredStc')),
                    )
                  }
                  if (listmct.includes(value) && data?.index !== value) {
                    return Promise.reject(
                      new Error(t('taxDeductionDocuments.stctt', { value })),
                    )
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <InputCustom
              placeholder={t('taxDeductionDocuments.placehoderderstcttVi')}
            />
          </FormCustom.Item>
          <FormCustom.Item
            label={
              <>
                {t('taxDeductionDocuments.addFeildVi')}
                <RedStar>*</RedStar>
              </>
            }
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error(t('taxDeductionDocuments.requiredaddFeildVi')),
                    )
                  }
                  return Promise.resolve()
                },
              }),
            ]}
            name={'name'}
          >
            <InputCustomGY
              widthSelect="5px"
              //   widthDropdown="35px !important"
              style={{ marginLeft: -5, paddingRight: 5 }}
              addonAfter={selectAddFeildLan}
              placeholder={t('taxDeductionDocuments.placehoderderstcttLan')}
            />
          </FormCustom.Item>
          <FormCustom.Item
            label={t('taxDeductionDocuments.addFeildLan')}
            name={'nameLan'}
            style={{ paddingRight: 10 }}
          >
            <InputCustom
              placeholder={t('taxDeductionDocuments.placehoderderstcttLan')}
            />
          </FormCustom.Item>
          <FormCustom.Item
            label={t('taxDeductionDocuments.typeDataAddFeild')}
            name="typeDataValue"
          >
            <SelectCustom
              style={{ width: 200, marginLeft: -3 }}
              options={listType}
            ></SelectCustom>
          </FormCustom.Item>
          <FormCustom.Item
            label={
              <>
                {`${t('taxDeductionDocuments.defaultFeild')}`}
                <Tooltip
                  placement="top"
                  title={`${t('taxDeductionDocuments.tooltiptypeDataFeild')}`}
                >
                  &nbsp; <InfoCircleOutlinedIcon />
                </Tooltip>
              </>
            }
            name={'defaultValue'}
            style={{ paddingRight: 10 }}
          >
            {(() => {
              switch (formState?.typeDataValue) {
                case 1:
                  return (
                    <InputCustom
                      placeholder={t(
                        'taxDeductionDocuments.placehoderdersDefault',
                      )}
                    />
                  )
                case 2:
                case 3:
                  return (
                    <InputNumberCumstom
                      type="number"
                      style={{ width: '100%' }}
                      min={0}
                      placeholder={t(
                        'taxDeductionDocuments.placehoderdersDefault',
                      )}
                      formatter={formatter}
                      parser={parser}
                    />
                  )
                case 4:
                  return (
                    <DatePickerCustom locale={locale} format="DD/MM/YYYY" />
                  )
                default:
                  return <></>
              }
            })()}
          </FormCustom.Item>
        </FormCustom>
      </ModalWrapper>
    </>
  )
}
export default ModalCustomField
