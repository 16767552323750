import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'

function CardInvoiceItem(props) {
  const { packageName, invoiceCount, packagePrice, addCartFunction } = props

  const navigate = useNavigate()
  return (
    <div className="CardInvoiceItem">
      <div
        className="w-100 text-center title mt-10"
        style={{ color: '#1C2256', fontSize: '20px', fontWeight: '900' }}
      >
        {packageName}
      </div>
      <div className="pt-5">
        <div className="w-100 d-flex justify-content-center flex-column text-center">
          <div
            className="contentBx"
            style={{ fontSize: '45px', fontWeight: '900' }}
          >
            {packagePrice}
          </div>
          <div
            className="textBx"
            style={{ fontSize: '18px', fontWeight: '700' }}
          >
            (VNĐ/Gói)
          </div>
        </div>

        <div className="w-100 d-flex justify-content-between pt-10">
          <div className="textBx">
            <div className="d-flex">
              <div>
                <i
                  className="fas fa-circle mr-3"
                  style={{
                    fontSize: '3px',
                    color: '#495057',
                    transform: 'translateY(-3px)',
                  }}
                ></i>
              </div>
              <div>
                Số lượng hóa đơn được xử lý: {invoiceCount}
                <br />
              </div>
            </div>
            {textRaw.map((item) => (
              <div key={item} className="d-flex mt-4">
                <div>
                  <i
                    className="fas fa-circle mr-3"
                    style={{
                      fontSize: '3px',
                      color: '#495057',
                      transform: 'translateY(-3px)',
                    }}
                  ></i>
                </div>
                <div>
                  {item}
                  <br />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex pt-6 w-100">
          <div
            className="w-50 pr-2"
            onClick={async () => {
              await addCartFunction()
              navigate('/hoa-don-dau-ra/gio-hang')
            }}
          >
            <button className="w-100 btn1">Mua ngay</button>
          </div>
          <div className="w-50 pl-2">
            <button className="w-100 btn2" onClick={addCartFunction}>
              Thêm vào giỏ hàng
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardInvoiceItem

const textRaw = [
  'Sử dụng miễn phí mẫu hoá đơn trong kho hóa đơn mẫu của Vietinvoice.',
  'Tư vấn, hỗ trợ thủ tục phát hành hóa đơn (bản mềm).',
  'Miễn phí trang tra cứu hóa đơn cho doanh nghiệp.',
  'Lưu trữ hóa đơn đã phát hành 10 năm trên hệ thống và trên điện toán đám mây, an toàn tuyệt đối.',
]
