import { useQuery } from '@tanstack/react-query'
import { Flex, Radio } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import CustomAntRangePicker from 'general/components/CustomAntRangePicker'
import CustomDataTable from 'general/components/CustomDataTable'
import BaseTextField from 'general/components/Forms/BaseTextField'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import KTFormInput, {
  KTFormInputBTDPickerType,
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import ButtonGroupTab from 'general/components/Tab'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import useToken from 'hooks/useToken'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import DraggableModal from '../DragableModal/DraggableModal'
import { queryKeys } from './queries'

FindInvoiceModal.propTypes = {
  selectableRows: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onExistDone: PropTypes.func,
  title: PropTypes.string,
  showTab: PropTypes.bool,
  textButtonTab1: PropTypes.string,
  textButtonTab2: PropTypes.string,
  checkBoxShow: PropTypes.bool,
  buttonAgreeOnlick: PropTypes.func,
  buttonAgreeTab2Onlick: PropTypes.func,
  onRowClicked: PropTypes.func,
  buttonAgreeVisibility: PropTypes.bool,
  invoiceType: PropTypes.string,
  defaultTab: PropTypes.number,
}

FindInvoiceModal.defaultProps = {
  selectableRows: false,
  show: false,
  onClose: null,
  showTab: true,
  onExistDone: null,
  textButtonTab1: 'Hóa đơn trên VietInvoice',
  textButtonTab2: 'Hóa đơn trên hệ thống khác',
  checkBoxShow: true,
  buttonAgreeVisibility: false,
  invoiceType: 'issued',
}

function FindInvoiceModal(props) {
  const {
    show,
    onHide,
    title,
    showTab,
    textButtonTab1,
    textButtonTab2,
    buttonAgreeOnlick,
    onRowClicked,
    selectableRows,
    buttonAgreeVisibility,
    buttonAgreeTab2Onlick,
    invoiceType,
    defaultTab,
  } = props
  const { token } = useToken()
  const { debouncedSearch, setSearch } = useSearchDebounce('')
  const modalRef = useRef(null)
  const [currentTab, setCurrentTab] = useState(0)
  const [filters, setFilters] = useState(Global.gFiltersInvoiceList_findmodal)
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [clearSelected, setClearSelected] = useState(false)
  const radioGroups = [
    { label: 'HĐ theo NĐ123 (Có mã)', value: 'HD' },
    { label: 'HĐ theo NĐ123 (Phiếu xuất kho)', value: 'PXK' },
    { label: 'HĐ theo NĐ123 (Khởi tạo từ máy tính tiền)', value: 'MTT' },
    // { label: 'HĐ theo NĐ123 (Không mã)', value: 'HD_KM' },
  ]
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)
  const formik = useFormik({
    initialValues: {
      no: '',
      serial: '',
      taxAuthorityCode: '',
      date: dayjs().utc().format('L'),
    },
    validationSchema: ValidationSchema.findInvoice,
    enableReinitialize: false,
    validateOnChange: false,
  })

  const { data: dataSource, isLoading: gettingListData } = useQuery({
    ...queryKeys.lists(filters, invoiceType),
    enabled: currentTab === 0 && show,
  })

  // Effect

  useEffect(() => {
    if (defaultTab === 1) setCurrentTab(defaultTab)
  }, [defaultTab])

  useEffect(() => {
    if (show) {
      setClearSelected((prev) => !prev)
      setFilters((prev) => ({
        ...prev,
        ...Global.gFiltersInvoiceList_findmodal,
        q: debouncedSearch,
      }))
    }
  }, [debouncedSearch, show])

  const allColumns = [
    {
      name: 'Ký hiệu',
      width: '110px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.serial}
          </p>
        )
      },
    },
    {
      name: 'Số',
      width: '110px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {Utils.padString(row?.no, 8, '0')}
          </p>
        )
      },
    },
    {
      name: 'Ngày HĐ',
      width: '125px',
      cell: (row) => {
        const date = dayjs(row?.date)
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {date.isValid() ? date.format('L') : ''}
          </p>
        )
      },
    },
    {
      name: 'Khách hàng',
      // width: '300px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.customerCompanyName}
          </p>
        )
      },
      hidden: filters.classify === 'PXK',
    },
    {
      name: 'Đại lý/Đơn vị nhận hàng',
      // width: '300px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.consigneeUnitName || row?.invoiceNote}
          </p>
        )
      },
      hidden: filters.classify !== 'PXK',
    },
    {
      name: 'Tổng tiền',
      // sortable: false,
      // right: true,
      width: '200px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {Utils.valueFormatted(
              row?.totalAfterTax,
              row?.numberFormat,
              sysConfig,
              'totalCost',
            )}{' '}
            {row?.currency}
          </p>
        )
      },
    },
  ]

  const columns = allColumns.filter((column) => !column.hidden)

  const handleApplyChanges = () => {
    switch (currentTab) {
      case 0:
        buttonAgreeOnlick(selectedInvoices, filters.classify)
        break
      case 1:
        buttonAgreeTab2Onlick(formik.values)
        break
      default:
        return
    }
  }

  const handleDoubleClickInvoice = (row) => {
    onRowClicked(row, filters.classify)
  }

  return (
    <DraggableModal
      applyText="Đồng ý"
      ref={modalRef}
      handleApplyChanges={handleApplyChanges}
      title={title}
      hasApplyButton={buttonAgreeVisibility}
      isOpen={show}
      onOpenChange={() => onHide()}
      width={1000}
      styles={{
        header: {
          borderBottom: Utils.cn('1px solid', token.colorBorderSecondary),
          margin: 0,
        },
        body: {
          padding: 0,
        },
        footer: {
          borderTop: Utils.cn('1px solid', token.colorBorderSecondary),
        },
      }}
    >
      <div className="d-flex justify-content-center align-items-center w-100 my-4">
        {showTab ? (
          <ButtonGroupTab
            textButton1={textButtonTab1}
            textButton2={textButtonTab2}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            buttonWidth="220px"
          />
        ) : null}
      </div>
      <div className="p-0">
        {currentTab === 0 ? (
          <>
            <div
              className="d-flex flex-column"
              style={{
                backgroundColor: '#f3f5f7',
                fontSize: '14px',
              }}
            >
              <Flex justify="flex-start" className="p-3 fw-light">
                <Radio.Group
                  options={radioGroups}
                  onChange={(e) => {
                    setFilters((prev) => ({
                      ...prev,
                      classify: e.target.value,
                    }))
                  }}
                  value={filters.classify}
                />
              </Flex>
              <Flex gap={'5px'} className="p-3 w-100">
                <CustomAntRangePicker
                  dateFormat="L"
                  defaultValue={[
                    dayjs(filters.startDate),
                    dayjs(filters.endDate),
                  ]}
                  defaultPeriod={filters.period}
                  onChange={(range, period) => {
                    setFilters({
                      ...filters,
                      startDate: dayjs(range[0]).format(),
                      endDate: dayjs(range[1]).format(),
                      period,
                    })
                  }}
                  position="bottomLeft"
                  borderRadius="sm"
                />

                <CustomAntInput
                  type="search"
                  borderRadius="sm"
                  inputProps={{
                    defaultValue: debouncedSearch,
                    onChange: (e) => setSearch(e.target.value),
                  }}
                  width={'100%'}
                />
              </Flex>
            </div>

            <div className="m-3">
              <CustomDataTable
                wrapperClassName="max-h-300px"
                columns={columns}
                dataSource={dataSource?.rows}
                isLoading={gettingListData}
                scrollY="300px"
                isSelectable={selectableRows}
                pagination={{
                  currentPage: filters?.page,
                  rowsPerPage: filters?.limit,
                  total: dataSource?.total,
                  onChangePage: (page) => {
                    setFilters({
                      ...filters,
                      page,
                    })
                  },
                  onChangeRowsPerPage: (limit) => {
                    setFilters({
                      ...filters,
                      limit,
                      page: 1,
                    })
                  },
                }}
                selectedRows={{
                  clearSelectedRows: clearSelected,
                  handleSelectedRows: ({ selectedRows }) => {
                    setSelectedInvoices(selectedRows)
                  },
                  selectableRowSelected: (row) =>
                    selectedInvoices?.some(
                      (item) => item.invoiceId === row.invoiceId,
                    ),
                }}
                isClickable
                handleDoubleClickRow={handleDoubleClickInvoice}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex flex-column w-50"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#333333',
                gap: '8px',
                padding: '15px',
                marginBottom: '20px',
                marginTop: '10px',
              }}
            >
              <div className="d-flex flex-column" style={{ gap: '5px' }}>
                <span>Mã cơ quan thuế (Với hóa đơn có mã)</span>
                <div>
                  <BaseTextField
                    className="flex-grow-1"
                    fieldProps={formik.getFieldProps('taxAuthorityCode')}
                    fieldMeta={formik.getFieldMeta('taxAuthorityCode')}
                    fieldHelper={formik.getFieldHelpers('taxAuthorityCode')}
                  />
                </div>
              </div>
              <div className="d-flex flex-column" style={{ gap: '5px' }}>
                <span>
                  Ký hiệu <span style={{ color: 'red' }}>(*)</span>
                </span>
                <div>
                  <BaseTextField
                    className="flex-grow-1"
                    fieldProps={formik.getFieldProps('serial')}
                    fieldMeta={formik.getFieldMeta('serial')}
                    fieldHelper={formik.getFieldHelpers('serial')}
                  />
                </div>
              </div>
              <div className="d-flex flex-column" style={{ gap: '5px' }}>
                <span>
                  Số hóa đơn <span style={{ color: 'red' }}>(*)</span>
                </span>
                <BaseTextField
                  className="flex-grow-1"
                  fieldProps={formik.getFieldProps('no')}
                  fieldMeta={formik.getFieldMeta('no')}
                  fieldHelper={formik.getFieldHelpers('no')}
                />
              </div>
              <div className="d-flex flex-column" style={{ gap: '5px' }}>
                <span>
                  Ngày hóa đơn <span style={{ color: 'red' }}>(*)</span>
                </span>
                <KTFormInput
                  name="1"
                  value={formik.getFieldProps('date').value}
                  onChange={(value) => {
                    formik.setFieldValue('date', value)
                  }}
                  onFocus={() => {
                    formik.setFieldTouched('date', true)
                  }}
                  enableCheckValid
                  btdPickerType={KTFormInputBTDPickerType.date}
                  // isValid={_.isEmpty(meta.error)}
                  // isTouched={meta.touched}
                  // feedbackText={meta.error}
                  type={KTFormInputType.date}
                  // disabled={!canEdit}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </DraggableModal>
  )
}

export default FindInvoiceModal
