import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  LeftContent,
  DivFrame,
  RndCustom,
  ImgRnd,
  ContainerLayout,
  DivText,
} from 'features/TaxDeductionDocuments/styled'
import AppResource from 'general/constants/AppResource'
import { findKeyInBorderBackground } from 'general/utils/Utils'
import LayoutCutom from './layout'
import { ReactComponent as ContentBackgroundSVG } from 'assets/images/taxDeductionDocuments/background/contentBackground14.svg'
function LeftContentTaxDeductionDocuments({
  form,
  styleInput,
  imgLogo,
  setImgLogo,
  fileOwnWallpaper,
  setFileOwnWallpaper,
  chonseBorder,
  fileBorderBackground,
  colorBorderBackground,
  listContentFeild,
  formState,
  fileContentBackground,
  setFileContentBackground,
  chonseContent,
  setChonseContent,
  contentBackgroundColor,
  tabActiveKey,
  setTabActiveKey,
  backgroundImg,
  setBackgroundImg,
  activeFeild,
  setActiveFeild,
  activeLayout,
  setActiveLayout,
  activeTd,
  setActiveTd,
  typePaper,
  language,
  setFormState,
  styleOwnWallpaper,
  setStyleOwnWallpaper,
  setVisibleSelectTaxpaper,
  setVisibleAddTaxpaper,
  listIndentify,
  setListIndentify,
  templateDefault,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [svgBase64Border, setSvgBase64Border] = useState(null)
  const [paper, setPaper] = useState({})
  const [svgBase64Content, setSvgBase64Content] = useState(null)
  const fetchAndModifySvg = async (url, fillColor) => {
    const response = await fetch(url)
    const text = await response.text()
    // Chỉnh sửa màu fill của SVG
    const modifiedText = text.replace('<svg', `<svg style="fill:${fillColor}"`)
    // Chuyển đổi SVG thành base64
    const base64Svg = `data:image/svg+xml;base64,${btoa(modifiedText)}`
    return base64Svg
  }

  const checkPaper = (paperSize) => {
    switch (paperSize) {
      case 'A4':
        return { width: '800px', height: '1131.2px' }
      default:
        return { width: '800px', height: '1131.2px' }
    }
  }

  useEffect(() => {
    setPaper(checkPaper(typePaper))
  }, [typePaper])

  useEffect(() => {
    const fetchSvg = async () => {
      if (fileBorderBackground) {
        const url = chonseBorder?.url
        if (url) {
          const base64 = await fetchAndModifySvg(url, colorBorderBackground)
          setSvgBase64Border(base64)
        }
      } else {
        setSvgBase64Border(true)
      }
    }
    fetchSvg()
  }, [fileBorderBackground, colorBorderBackground])

  useEffect(() => {
    const fetchSvg = async () => {
      if (fileContentBackground) {
        const url = chonseContent?.url
        if (url) {
          const base64 = await fetchAndModifySvg(url, contentBackgroundColor)
          setSvgBase64Content(base64)
        }
      } else {
        setSvgBase64Border(true)
      }
    }
    fetchSvg()
  }, [fileContentBackground, contentBackgroundColor])

  return (
    <LeftContent>
      <center>
        <DivFrame
          urlBackGroupImage={svgBase64Border}
          width={paper.width ?? '800px'}
          height={styleInput ? 'auto' : paper.height ?? '1131.2px'}
        >
          <ContainerLayout>
            {listContentFeild?.map((item, index) => {
              return (
                <>
                  {!(styleInput && item.foodter) ? (
                    <LayoutCutom
                      templateDefault={templateDefault}
                      styleInput={styleInput}
                      itemLayout={item}
                      listContent={item.content}
                      height={
                        styleInput
                          ? index == 0
                            ? item.height
                            : 'auto'
                          : item.height
                      }
                      padding={item.padding}
                      language={language}
                      imgLogo={imgLogo}
                      setImgLogo={setImgLogo}
                      formState={formState}
                      indexLayout={index}
                      setTabActiveKey={setTabActiveKey}
                      activeLayout={activeLayout}
                      setActiveLayout={setActiveLayout}
                      empty={item.empty}
                      activeFeild={activeFeild}
                      setActiveFeild={setActiveFeild}
                      activeTd={activeTd}
                      setActiveTd={setActiveTd}
                      form={form}
                      setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                      setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                      listIndentify={listIndentify}
                      setListIndentify={setListIndentify}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )
            })}
          </ContainerLayout>
          {fileOwnWallpaper.url && (
            <RndCustom
              bounds="parent"
              size={{
                width: fileOwnWallpaper.width,
                height: fileOwnWallpaper.height,
              }}
              opacity={formState.opacityOwnWallpaper / 100}
              unSetBorder={tabActiveKey !== '2'}
              position={{ x: fileOwnWallpaper.x, y: fileOwnWallpaper.y }}
              onDragStop={(e, d) => {
                setFileOwnWallpaper({ ...fileOwnWallpaper, x: d.x, y: d.y })
              }}
              onResizeStop={(e, direction, ref, delta, position) => {
                setFileOwnWallpaper({
                  width: ref.style.width,
                  height: ref.style.height,
                  ...position,
                })
              }}
              // lockAspectRatio={true}
            >
              <ImgRnd
                className="w-100 h-100"
                src={fileOwnWallpaper.url}
                width={fileOwnWallpaper.width}
                height={fileOwnWallpaper.height}
              />
            </RndCustom>
          )}
          {svgBase64Border && svgBase64Content && (
            <>
              {formState.describeownWallpaper && (
                <RndCustom
                  bounds="parent"
                  size={{
                    width: 300,
                    height: 150,
                  }}
                  opacity={formState.opacityTextOwnWallpaper / 100}
                  unSetBorder={tabActiveKey !== '2'}
                  position={{
                    x: styleOwnWallpaper.x ?? 0,
                    y: styleOwnWallpaper.y ?? 0,
                  }}
                  onDragStop={(e, d) => {
                    setStyleOwnWallpaper({
                      ...styleOwnWallpaper,
                      x: d.x,
                      y: d.y,
                    })
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    setStyleOwnWallpaper({
                      width: ref.style.width,
                      height: ref.style.height,
                      ...position,
                    })
                  }}
                  // lockAspectRatio={true}
                >
                  <DivText
                    fontSize={formState.fontSizeTextOwnWallpaper}
                    color={formState.ownWallpaperColor}
                    fontWeight={formState.boldOwnWallpaper && 'bold'}
                    fontFamily={formState.fontTextOwnWallpaper}
                  >
                    {formState.describeownWallpaper}
                  </DivText>
                </RndCustom>
              )}
              {chonseContent?.url && (
                <RndCustom
                  opacity={formState.opacityContentBackground / 100}
                  unSetBorder={tabActiveKey !== '2'}
                  bounds="parent"
                  //   lockAspectRatio={true}
                  size={{
                    width: chonseContent.width,
                    height: chonseContent.height,
                  }}
                  position={{
                    x: chonseContent.x,
                    y: chonseContent.y,
                  }}
                  onDragStop={(e, d) => {
                    setChonseContent({
                      ...chonseContent,
                      x: d.x,
                      y: d.y,
                    })
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    setChonseContent({
                      ...chonseContent,
                      ...position,
                      width: ref.style.width,
                      height: ref.style.height,
                    })
                  }}
                >
                  <ImgRnd
                    className="w-100 h-100"
                    src={svgBase64Content}
                    width={chonseContent.width}
                    height={chonseContent.height}
                  />
                </RndCustom>
              )}
            </>
          )}
        </DivFrame>
      </center>
    </LeftContent>
  )
}
export default LeftContentTaxDeductionDocuments
