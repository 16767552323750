import React, { useMemo } from 'react'
import dayjs from 'dayjs'

import './style.scss'
import RegistrationHelper from 'general/helpers/RegistrationHelper'
import Utils from 'general/utils/Utils'
import taxAuthorityMessageApi from 'api/taxAuthorityMessageApi'
import { useDispatch } from 'react-redux'
import { setAppSpinning } from 'app/appSlice'

function Message103(props) {
  const dispatch = useDispatch()
  const { message } = props

  console.log(message)
  const isSent = useMemo(() => message.messageId?.startsWith('V'), [message])
  const NTBao = useMemo(
    () => message.contentRaw?.TDiep?.DLieu?.TBao?.STBao?.NTBao,
    [message],
  )
  const So = useMemo(
    () => message.contentRaw?.TDiep?.DLieu?.TBao?.STBao?.So,
    [message],
  )
  const isReceived = useMemo(
    () => !message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.DSLDKCNhan,
    [message],
  )
  const LDos = useMemo(() => {
    let LDos = message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.DSLDKCNhan?.LDo
    if (!LDos) return
    return Array.isArray(LDos) ? LDos : [LDos]
  }, [message])

  const viewPdfMessage103 = async () => {
    if (!message?.messageId) return

    dispatch(setAppSpinning(true))
    try {
      let res = await taxAuthorityMessageApi.exportPdfMessage103(
        message.messageId,
      )
      dispatch(setAppSpinning(false))
      let newFile = new File(
        [res],
        'Tờ khai đăng ký/thay đổi thông tin sử dụng hóa đơn.pdf',
        {
          type: 'application/pdf',
        },
      )
      var fileURL = URL.createObjectURL(newFile)
      window.open(fileURL, '_blank')
    } catch (e) {
      dispatch(setAppSpinning(false))
      console.log(e)
    }
  }

  return (
    <div className="Message103 border-bottom mb-5">
      <div className="d-flex flex-row">
        <div style={{ maxWidth: '500px' }}>
          <p className="mb-2">
            <i
              className={`fa-regular ${
                isSent ? 'fa-arrow-up' : 'fa-arrow-down'
              } mr-2 ${isSent ? 'i-send' : 'i-receivce'}`}
            ></i>
            <span className="font-weight-bold">
              {NTBao && dayjs(NTBao).format('DD/MM/YYYY hh:mm:ss')}
            </span>
          </p>

          <p className="mb-2">
            {`[${
              message.typeCode
            }] ${RegistrationHelper.renderRegistrationTypeCode(
              message.typeCode,
            )}`}
          </p>

          {LDos && (
            <div className="p-3" style={{ backgroundColor: '#fff3cd' }}>
              {LDos?.map(({ MLoi, MTa }, index) => (
                <p key={index} className="mb-2">
                  {`[${MLoi}] ${MTa}`}
                </p>
              ))}
            </div>
          )}

          <p className="mb-2">
            Số thông báo:{' '}
            <a href="#" title="Xem thông báo">
              {So}
            </a>
          </p>

          <p className="mb-2" style={{ color: '#c4c4c4' }}>
            {`Mã thông điệp: ${message.messageId}`}
          </p>
        </div>
        <div className="text-right flex-fill">
          <p className="mb-2 font-weight-bold">
            {isSent ? '0106870211' : 'Cơ quan thuế'}
          </p>

          <p className="mb-2">
            <span
              className="text-dark-75 font-weight-normal"
              style={{
                borderRadius: '40px',
                backgroundColor: isReceived ? '#a9efb7' : '#fff3cd',
                color: isReceived ? '#12a744' : '#856404',
                padding: '3px 10px',
              }}
            >
              {isReceived ? 'CQT chấp nhận' : 'CQT không chấp nhận'}
            </span>
          </p>

          <div className="d-flex flex-row justify-content-end mb-2">
            <p
              className="cursor-pointer mr-7"
              onClick={() => {
                viewPdfMessage103()
              }}
              title="Xem"
            >
              <i className="fa-regular fa-eye" style={{ color: 'inherit' }}></i>
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                Utils.exportXmlFile({
                  data: message.contentRaw.TDiep,
                  fileName: 'thong-diep-103',
                })
              }}
              title="Xuất khẩu XML"
            >
              <i
                className="fa-regular fa-download"
                style={{ color: 'inherit' }}
              ></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Message103
