import TableAction from 'general/components/GroupButton/TableAction'
import './styles.scss'
import { IconAction } from 'general/components/GroupButton/styles'
import PropTypes from 'prop-types'
import { TicketCreateTableRow } from '../TicketCreateTableRow'

// TicketCreateTable.propTypes = {
//   tableData: PropTypes.array,
//   setTableData: PropTypes.func,
// }

// TicketCreateTable.defaultProps = {}

export const TicketCreateTable = (props) => {
  const { tableData, handleChangeTableData, handleRemoveRow, ticketTemplates } =
    props
  return (
    <div className="ticketTableContainer">
      <table className="ticketCreateTable">
        <tr className="ticketTableHeader">
          <th style={{ width: '30%' }}>Mẫu vé</th>
          <th style={{ width: '15%' }}>Số xe</th>
          <th style={{ width: '15%' }}>Ngày xuất vé</th>
          <th style={{ width: '15%' }}>Giá vé</th>
          <th style={{ width: '10%' }}>Số lượng</th>
          <th style={{ width: '10%' }}></th>
        </tr>
        {tableData.map((row, index) => {
          return (
            <TicketCreateTableRow
              row={row}
              tableData={tableData}
              handleChangeTableData={handleChangeTableData}
              handleRemoveRow={handleRemoveRow}
              ticketTemplates={ticketTemplates}
            />
          )
        })}
      </table>
    </div>
  )
}
