import { Space, Tooltip, Typography } from 'antd'
import _map from 'lodash/map'
import { Fragment } from 'react'

/**
 *
 * @param {{
 * items: any[],
 * maxItemCount?: number,
 * render: (item: any, idx: number) => JSX.Element
 * ellispsis?: (remainCount ?: number) => JSX.Element
 *  }} props
 */
export default function CustomList(props) {
  const { items, maxItemCount, render, ellispsis } = props
  const arrOfItem =
    maxItemCount < items.length ? items.slice(0, maxItemCount) : items
  const moreCount = items.length - maxItemCount
  return (
    <Fragment>
      <Space
        wrap
        size={'small'}
        align="center"
        className="w-100"
        data-tag={'allowRowEvents'}
      >
        {_map(arrOfItem, render)}
        {items.length > maxItemCount && (
          <Tooltip
            placement="right"
            getPopupContainer={(trigger) => trigger.parentNode}
            destroyTooltipOnHide
            color="#fff"
            title={
              <Space wrap size={'small'}>
                {_map(items.slice(maxItemCount), render)}
              </Space>
            }
          >
            {ellispsis ? (
              ellispsis(moreCount)
            ) : (
              <Typography.Text
                type="secondary"
                strong
                className=" font-size-sm"
              >
                ...({moreCount})
              </Typography.Text>
            )}
          </Tooltip>
        )}
      </Space>
    </Fragment>
  )
}
