import { Space } from 'antd'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import useCheckPermission from 'hooks/useCheckPermission'

export default function FooterCard({
  onRestoreDefault,
  onRestoreChange,
  onSave,
  show,
  isLoading,
}) {
  const { canEdit } = useCheckPermission([
    {
      keyAction: 'canEdit',
      permissionCode: 'OPTIONS_EDIT',
    },
  ])
  return (
    <section className="footerCard">
      <CustomAntButton
        text="Lấy thiết lập ngầm định"
        antProps={{
          title: !canEdit ? 'Bạn không có quyền sử dụng chức năng này' : '',
          disabled: !canEdit,
          type: 'primary',
          ghost: true,
          onClick: onRestoreDefault,
        }}
      />
      <Space>
        <CustomAntButton
          text="Khôi phục"
          iconCn="fas fa-rotate-left"
          isLoading={isLoading}
          antProps={{
            title: !canEdit ? 'Bạn không có quyền sử dụng chức năng này' : '',
            type: 'default',
            onClick: onRestoreChange,
          }}
        />
        <CustomAntButton
          text="Lưu"
          iconCn="fas fa-floppy-disk"
          variant="success"
          isLoading={isLoading}
          antProps={{
            title: !canEdit ? 'Bạn không có quyền sử dụng chức năng này' : '',
            disabled: !show,
            type: 'primary',
            onClick: onSave,
          }}
        />
      </Space>
    </section>
  )
}
