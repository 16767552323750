import React from 'react'
import { Modal, Button } from 'react-bootstrap'

function ModalDelete(props) {
  const { show, setShow, handleDelete } = props

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Bạn có thực sự muốn xóa quy trình phê duyệt đã chọn không?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Không
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDelete(), handleClose()
            }}
            style={{ width: '68.34px' }}
          >
            Có
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ModalDelete
