import { Dropdown } from 'antd'
import PropTypes from 'prop-types'
// import './styles.scss'

const AntdDropdown = (props) => {
  const { items, onClick } = props

  return (
    <Dropdown
      menu={{
        items,
        onClick,
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center flex-row position-absolute"
        style={{
          width: '30px',
          height: '30px',
          cursor: 'pointer',
          right: 0,
        }}
      >
        <i className="fas fa-caret-down" style={{ color: '#1877f2' }}></i>
      </div>
    </Dropdown>
  )
}

AntdDropdown.propTypes = {
  value: PropTypes.string,
}

AntdDropdown.defaultProps = {
  value: '',
}
export default AntdDropdown
