import 'assets/styles/keen/theme01/layout/brand/dark.css'
import AppResource from 'general/constants/AppResource'
import useRouter from 'hooks/useRouter'
import { useEffect } from 'react'
import './style.scss'
import KTLayoutHeaderMobile from 'assets/plugins/header/ktheader-mobile'
import KTLayoutHeaderTopbar from 'assets/plugins/header/ktheader-topbar'
import { Image } from 'antd'
import useProjectBranding from 'hooks/useProjectBranding'

function KT01HeaderMobile(props) {
  // MARK: --- Params ---
  const router = useRouter()
  const { logo } = useProjectBranding()
  // MARK: --- Hooks ---
  useEffect(() => {
    // Init header mobile
    if (KTLayoutHeaderMobile !== undefined) {
      KTLayoutHeaderMobile.init('kt_header_mobile')
    }

    // Init header mobile top bar
    if (KTLayoutHeaderTopbar !== undefined) {
      KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle')
    }
  }, [])

  return (
    <div
      id="kt_header_mobile"
      className="header-mobile align-items-center header-mobile-fixed"
      style={{}}
    >
      {/* logo */}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          router.navigate('/saymee')
        }}
      >
        <Image preview={false} className="h-50px" src={logo} alt="logo" />
      </a>

      {/* toolbar */}
      <div className="d-flex align-items-center">
        {/* button sidebar toggle */}
        <button
          id="kt_aside_mobile_toggle"
          className="btn p-0 burger-icon burger-icon-left"
        >
          <span className="svg-icon svg-icon-xxl"></span>
        </button>

        {/* button header menu mobile */}
        {/* <button
                    id='kt_header_mobile_toggle'
                    className='btn p-0 burger-icon ml-5'
                >
                    <span className='svg-icon svg-icon-xxl'></span>
                </button> */}

        {/* button top bar menu toggle */}
        <button
          id="kt_header_mobile_topbar_toggle"
          className="btn btn-hover-text-primary p-0 ml-3 border-0 ml-5"
        >
          <span className="svg-icon svg-icon-xl">
            <i className="fa-duotone fa-user"></i>
          </span>
        </button>
      </div>
    </div>
  )
}

export default KT01HeaderMobile
