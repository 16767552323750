import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  ModalWrapper,
  DivFooter,
  StyleButtonClose,
  ButtonSave,
  ButtonTutorial,
  DivRowFooter,
  DivColFooter,
  TableCustom,
} from '../../styled'
import AppResource from 'general/constants/AppResource'
import { Empty } from 'antd'
import { downloadZip, embedPDF, extractFields } from 'general/utils/Utils'
import { useEffect, useState } from 'react'
function FindDeductionDocuments(
  {
    openModalFind,
    changeOpenModalFind,
    dataSource,
    panningDataSource,
    changePaniningFind,
  },
  props,
) {
  const { t } = useTranslation()
  const [dataFile, setDataFile] = useState([])
  const columns = [
    {
      title: t('taxDeduction.stt'),
      dataIndex: 'stt',
      width: 80,
      className: 'center-header',
      render: (text, record, index) => (
        <div className="center-cell">
          {(panningDataSource.page - 1) * panningDataSource.limit + (index + 1)}
        </div>
      ),
    },
    {
      title: t('taxDeduction.listDocument'),
      dataIndex: 'nameDeductionDocuments',
      width: 600,
      className: 'center-header',
      render: (text, record, index) => (
        <div className="left-cell">
          {record.nameDeductionDocuments}
          {record.base64 && (
            <img
              className="view"
              src={AppResource.taxDeduction.keenTaxDuctionView}
              onClick={() => embedPDF(record.base64)}
            />
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setDataFile(extractFields(dataSource, ['nameFile', 'base64'], 'base64'))
  }, [dataSource])

  return (
    <>
      <ModalWrapper
        title={t('taxDeduction.registrationFile')}
        open={openModalFind}
        onCancel={() => changeOpenModalFind(false)}
        width={750}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6">
                <ButtonTutorial
                  onClick={() => {
                    window.open(
                      '/hoa-don-dau-ra/khau-tru-thue-thu-nhap-ca-nhan/huongdan',
                      '_blank',
                    )
                  }}
                >
                  {t('taxDeduction.viewTutorial')}
                </ButtonTutorial>
              </DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    changeOpenModalFind(false)
                  }}
                >
                  {t('taxDeduction.closeModal')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  disabled={dataFile.length == 0}
                  onClick={() => {
                    downloadZip(dataFile)
                  }}
                >
                  {t('taxDeduction.download')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <TableCustom
          dataSource={dataSource ?? []}
          columns={columns}
          locale={{
            emptyText: <Empty description={t('taxDeduction.noData')} />,
          }}
          pagination={
            panningDataSource.totalPages > 1
              ? {
                  current: panningDataSource.page,
                  pageSize: panningDataSource.limit,
                  total: panningDataSource.totalItems,
                }
              : false
          }
          onChange={(pagination) => {
            changePaniningFind(pagination.current, pagination.pageSize)
          }}
        />
      </ModalWrapper>
    </>
  )
}
export default FindDeductionDocuments
