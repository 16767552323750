import CardInvoiceItem from 'features/System/components/CardInvoiceItem'
import Utils from 'general/utils/Utils'
import { useEffect, useState } from 'react'
// import ModalCartPackage from 'features/System/components/ModalCartPackage';
import cartApi from 'api/cartApi'
import servicePackApi from 'api/servicePackApi'
import { getAllCart } from 'features/Cart/CartSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import { useDispatch } from 'react-redux'
import EmptyPackage from '../EmptyPackage/index'

TicketPackageScreen.propTypes = {}

function TicketPackageScreen() {
  const dispatch = useDispatch()
  // MARK --- Params ---

  // MARK --- Hooks ---

  const [servicePack, setServicePack] = useState()
  const [licensePack, setLicensePack] = useState()

  useEffect(() => {
    const fetchServicePacks = async () => {
      try {
        const result = await servicePackApi.getAll({ type: 'quantity' })
        setServicePack(result)
      } catch (error) {
        console.log(error)
      }
    }

    fetchServicePacks()
  }, [])

  useEffect(() => {
    const fetchPacks = async () => {
      try {
        const result = await servicePackApi.getAll({ type: 'license' })
        setLicensePack(result)
      } catch (error) {
        console.log(error)
      }
    }

    fetchPacks()
  }, [])

  const addCartFunction = async (item) => {
    const { servicePackId, name, unit, unitPrice, amount, type } = item
    const createCart = async () => {
      try {
        await cartApi.createCart({
          productId: servicePackId,
          productName: name,
          productUnit: unit,
          productAmount: amount,
          productType: type,
          productPrice: unitPrice,
        })
        ToastHelper.showSuccess('Đã thêm vào giỏ hàng')
      } catch (error) {
        console.log(error)
        ToastHelper.showError('Sản phẩm không được thêm vào giỏ')
      }
    }

    await createCart()
    await dispatch(getAllCart())
  }

  return (
    <div className="TicketPackageScreen">
      <div className="my-5 padding-slider">
        <p className="font-weight-boldest headName text-uppercase">
          Gói số lượng
        </p>
        {servicePack?.length > 0 ? (
          <div className="row">
            {servicePack.map((item) => {
              return (
                <div className="col-lg-4 col-xxl-3 col-md-6 col-12 mb-8">
                  <CardInvoiceItem
                    // packageImage={item?.packageImage}
                    invoiceCount={item?.amount}
                    packageName={item?.name}
                    packagePrice={Utils.formatNumber(item?.unitPrice)}
                    addCartFunction={() => addCartFunction(item)}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <EmptyPackage text={'Hệ thống đang không có gói số lượng nào!'} />
        )}
      </div>
      <div className="my-5 padding-slider">
        <p className="font-weight-boldest headName text-uppercase">
          Gói Thời hạn bản quyền
        </p>
        {licensePack?.length > 0 ? (
          <div className="row">
            {licensePack.map((item) => {
              return (
                <div className="col-lg-4 col-xxl-3 col-md-6 col-12 mb-8">
                  <CardInvoiceItem
                    // packageImage={item?.packageImage}
                    invoiceCount={item?.amount}
                    packageName={item?.name}
                    packagePrice={Utils.formatNumber(item?.unitPrice)}
                    addCartFunction={() => addCartFunction(item)}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <EmptyPackage
            text={'Hệ thống đang không có gói thời hạn bản quyền nào!'}
          />
        )}
      </div>
    </div>
  )
}

export default TicketPackageScreen
