import './style.scss'

function UnauthorizedScreen() {
  return (
    <div className="UnauthorizedScreen w-100 h-100 pt-12">
      <div className="mainBox">
        {/* <div className="imgBox">
          <img src={AppResource?.images?.img_prohited} />
        </div> */}
        <div>
          <i className="fas fa-ban" style={{ fontSize: '100px' }}></i>
        </div>
        <div className="mt-10">
          {' '}
          Bạn không có quyền thực hiện chức năng này.
          <br />
          Vui lòng liên hệ người dùng có vai trò Quản trị để được phân quyền.
        </div>
      </div>
    </div>
  )
}

export default UnauthorizedScreen
