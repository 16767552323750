import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'

export const ColumnsContext = createContext(null)
export const ColumnsDispatchContext = createContext(null)

export function ColumnsProvider({ children }) {
  const [columns, dispatchColumns] = useReducer(ColumnsReducer, initialCols)

  return (
    <ColumnsContext.Provider value={columns}>
      <ColumnsDispatchContext.Provider value={dispatchColumns}>
        {children}
      </ColumnsDispatchContext.Provider>
    </ColumnsContext.Provider>
  )
}

export function useColumns(columnsName) {
  return useContext(ColumnsContext)[columnsName]
}

export function useColumnsDispatch() {
  return useContext(ColumnsDispatchContext)
}

function ColumnsReducer(Columns, action) {
  let { type, colsName, payload } = action
  switch (type) {
    case 'set': {
      return {
        ...Columns,
        [colsName]: _.isFunction(payload)
          ? payload(Columns[colsName])
          : payload,
      }
    }
    default: {
      return { ...Columns }
    }
  }
}

const initialCols = {
  invoiceInfoColumns: [],
  firstInvoiceColumns: [],
  buyerInfoColumns: [],
  tableDetailColumns: [],
  tableDetail1Columns: [],
  tableFooterColumns: [],
  tableFooter1Columns: [],
  tableHospitalColumns: [],
  signXmlColumns: [],
}
