// All app configs: app name, email, phone, copyright...
const AppConfigs = {
  // enable show log
  enableLog: true,

  packageName: 'eInvoice',
  appName: '',
  version: '1.0',
  build: 1,
  recaptchaSiteKeyV2: '6LcshhwjAAAAAKpOa5hD2_6QNOIUec6hyKZk_YtL',

  wsUrl: `${process.env.REACT_APP_WEB_SOCKET}://${process.env.REACT_APP_SOCKET_SERVER_URL}/ws`,
}

export default AppConfigs
