import { useTranslation } from 'react-i18next'
import { MoreOutlined, SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  ButtonTutorial,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalCustom,
  ModalWrapper,
  StyleButtonClose,
} from 'features/TaxDeduction/styled'
import {
  ContainerContentModal,
  DivItemTree,
  TreeSelectCustom,
  TreeSelectCustomH,
} from 'features/Department/styled'
import {
  ButtonCustom,
  CheckboxCumstom,
  DatePickerCustom,
  FormCustom,
  InputCustom,
  InputNumberCumstom,
  RadioCustom,
  RedStar,
  SelectCustom,
  TextAreaCustom,
} from 'features/TaxDeductionDocuments/styled'
import { Empty, Form } from 'antd'
import {
  convertToOptions,
  findObjectInArray,
  padNumberWithZeros,
  removeAccents,
  toLower,
} from 'general/utils/Utils'
import { useEffect, useState } from 'react'
import { thunkGetAccountProfile } from 'app/authSlice'
import { useAppDispatch } from 'hooks/useRedux'
import { listFieldOfActivity } from 'general/constants/AppConstants'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { symbol } from 'prop-types'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  thunkDepartment,
  thunkSaveDepartment,
} from 'features/Department/departmentSlice'
import { thunkOrganizationallevel } from 'features/Organizationallevel/organizationallevelSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import dayjs from 'dayjs'
import { thunkGrounplocation } from 'features/Grouplocation/grouplocationSlice'
import { thunkJobtitle } from 'features/JobTitle/jobTitleSlice'
import {
  thunkfindByIdJobposition,
  thunkSaveJobposition,
} from 'features/Jobposition/jobpositionSlice'
import SelectOptions from 'general/constants/SelectOptions'
import ViewJobTitle from 'features/JobTitle/component/Update'
function UpdateJobposition({
  openModal,
  changeOpenModal,
  jobPositionId,
  maxId = 0,
  companyId,
  selectedKey,
  setSelectedKey,
  departmentActive,
  listDepartment,
  find,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [listGrouplocation, setListGrouplocation] = useState([])
  const [listJobTitle, setListJobTitle] = useState([])
  const [visiblleJobTitle, setVisiblleJobTitle] = useState(false)
  const dateNow = new Date()
  const day = dateNow.getDate()
  const month = dateNow.getMonth() + 1
  const year = dateNow.getFullYear()
  const dispatch = useAppDispatch()
  const [dataTree, setDataTree] = useState([])
  const resetForm = async () => {
    form?.resetFields()
    let data = {}
    if (jobPositionId) {
      const jobPositionUpdate = await getJoboisitionById(jobPositionId)
      const departments = jobPositionUpdate?.departments
        ? jobPositionUpdate.departments.split(',').map((item) => Number(item))
        : []
      data = {
        ...jobPositionUpdate,
        departments,
      }
    } else {
      data = {
        jobPositionCode: `VT${padNumberWithZeros(maxId, 6)}`,
        departments:
          listDepartment && listDepartment.length > 0
            ? [listDepartment[0]?.departmentId]
            : [],
      }
    }
    form.setFieldsValue(data)
  }

  const getGrouplocation = async () => {
    const result = unwrapResult(await dispatch(thunkGrounplocation()))
    setListGrouplocation(result?.data ?? [])
  }

  const getJobTitle = async (value) => {
    const result = unwrapResult(await dispatch(thunkJobtitle()))
    if (value && result?.data?.items) {
      const dataFind = findObjectInArray(
        result?.data?.items,
        'jobTitleId',
        value,
      )
      if (!dataFind) {
        form.setFieldValue('jobTitleId', null)
      }
    }
    setListJobTitle(result?.data?.items ?? [])
  }

  const buildDepartmentTree = (departments) => {
    if (departments) {
      // Tạo một bản sao của mảng departments trước khi sắp xếp
      const departmentsCopy = [...departments]

      // Sắp xếp danh sách departmentsCopy theo thứ tự tăng dần của thuộc tính orders,
      // đặt các phần tử có orders là null ở cuối cùng
      departmentsCopy.sort((a, b) => {
        if (a.orders === null && b.orders === null) return 0
        if (a.orders === null) return 1
        if (b.orders === null) return -1
        return a.orders - b.orders
      })

      const departmentMap = {}
      departmentsCopy.forEach((department) => {
        departmentMap[department.departmentId] = {
          title: <DivItemTree>{department.departmentName}</DivItemTree>,
          titleSearch: department.departmentName,
          key: department.departmentId,
          children: [],
          orders: department.orders,
          value: department.departmentId,
        }
      })

      const tree = []
      departmentsCopy.forEach((department) => {
        if (department.filiation === null) {
          // Nếu không có filiation, đây là một phần tử gốc
          tree.push(departmentMap[department.departmentId])
        } else {
          // Nếu có filiation, thêm nó vào danh sách con của phần tử cha
          if (departmentMap[department.filiation]) {
            departmentMap[department.filiation].children.push(
              departmentMap[department.departmentId],
            )
          }
        }
      })

      // Thiết lập isLeaf cho các phần tử không có children
      Object.values(departmentMap).forEach((node) => {
        if (node.children.length === 0) {
          node.isLeaf = true
        } else {
          // Sắp xếp các children của node theo thứ tự tăng dần của thuộc tính orders,
          // đặt các phần tử có orders là null ở cuối cùng
          node.children.sort((a, b) => {
            if (a.orders === null && b.orders === null) return 0
            if (a.orders === null) return 1
            if (b.orders === null) return -1
            return a.orders - b.orders
          })
        }
      })

      return tree
    } else {
      return []
    }
  }

  const getJoboisitionById = async (Id) => {
    const result = unwrapResult(
      await dispatch(thunkfindByIdJobposition({ jobPositionId: Id })),
    )
    return result?.data ?? {}
  }

  useEffect(() => {
    if (openModal) {
      getGrouplocation()
      getJobTitle()
      setDataTree(buildDepartmentTree(listDepartment))
      resetForm()
    }
  }, [openModal])

  const save = async (values) => {
    let data = {
      ...values,
      jobPositionId: jobPositionId,
      departments: values.departments?.toString(),
    }
    const result = unwrapResult(await dispatch(thunkSaveJobposition(data)))
    if (result?.message === 'success') {
      if (jobPositionId) {
        ToastHelper.showSuccess(`${t('jobposition.messagesucessedit')}`)
      } else {
        ToastHelper.showSuccess(`${t('jobposition.messagesucess')}`)
      }

      find()
      changeOpenModal(false)
    } else {
      ToastHelper.showError(result?.data)
    }
  }

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        save(values)
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo)
      })
  }

  return (
    <>
      <ModalCustom
        title={
          !jobPositionId
            ? t('jobposition.addTitleModal')
            : t('jobposition.editTitleModal')
        }
        open={openModal}
        onCancel={() => changeOpenModal(false)}
        width={600}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    changeOpenModal(false)
                  }}
                >
                  {t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  onClick={() => {
                    submit()
                  }}
                >
                  {t('taxDeductionDocuments.save')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <ContainerContentModal>
          <FormCustom form={form} layout="vertical">
            <div className="row" style={{ width: '100%', paddingLeft: 20 }}>
              <div className="col-12">
                <FormCustom.Item
                  layout="horizontal"
                  label={
                    <>
                      {`${t('jobposition.jobpositionCode')} `}
                      <RedStar>*</RedStar>
                    </>
                  }
                  name={'jobPositionCode'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              t('department.keyRequired', {
                                key: t('jobposition.jobpositionCode'),
                              }),
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputCustom
                    maxLength={45}
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('jobposition.jobpositionCode')),
                    })}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-12">
                <FormCustom.Item
                  layout="horizontal"
                  label={
                    <>
                      {`${t('jobposition.jobpositionName')} `}
                      <RedStar>*</RedStar>
                    </>
                  }
                  name={'jobPositionName'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              t('department.keyRequired', {
                                key: t('jobposition.jobpositionName'),
                              }),
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputCustom
                    maxLength={45}
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('jobposition.jobpositionName')),
                    })}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-12">
                <FormCustom.Item
                  layout="horizontal"
                  label={t('jobposition.groundJobposition')}
                  name={'groupLocationId'}
                >
                  <SelectCustom
                    showSearch
                    placeholder={t('jobposition.keySelectPl', {
                      key: toLower(t('jobposition.groundJobposition')),
                    })}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('taxDeduction.noData')}
                      />
                    }
                    filterOption={(input, option) =>
                      removeAccents(option?.label ?? '')
                        .toLowerCase()
                        .includes(removeAccents(input).toLowerCase())
                    }
                    options={convertToOptions(
                      listGrouplocation,
                      'groupLocationId',
                      'nameGroupLocation',
                    )}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-11">
                <FormCustom.Item
                  layout="horizontal"
                  label={t('jobposition.chucd')}
                  name={'jobTitleId'}
                >
                  <SelectCustom
                    showSearch
                    placeholder={t('jobposition.keySelectPl', {
                      key: toLower(t('jobposition.chucd')),
                    })}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('taxDeduction.noData')}
                      />
                    }
                    filterOption={(input, option) =>
                      removeAccents(option?.label ?? '')
                        .toLowerCase()
                        .includes(removeAccents(input).toLowerCase())
                    }
                    options={convertToOptions(
                      listJobTitle,
                      'jobTitleId',
                      'jobTitleName',
                    )}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-1" style={{ right: 10 }}>
                <FormCustom.Item layout="horizontal" label={<>&#160;</>}>
                  <ButtonCustom
                    icon={<MoreOutlined />}
                    onClick={() => {
                      setVisiblleJobTitle(true)
                    }}
                  ></ButtonCustom>
                </FormCustom.Item>
              </div>
              <div className="col-12">
                <FormCustom.Item
                  label={
                    <>
                      {`${t('jobposition.dvct')} `}
                      <RedStar>*</RedStar>
                    </>
                  }
                  name={'departments'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        console.log('value', value)
                        if (!value || value.length == 0) {
                          return Promise.reject(
                            new Error(
                              t('department.keyRequired', {
                                key: t('jobposition.dvct'),
                              }),
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <TreeSelectCustomH
                    placeholder={t('department.placehoderthdv')}
                    showSearch
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: 'auto',
                    }}
                    allowClear
                    treeDefaultExpandAll
                    treeCheckable
                    showCheckedStrategy={TreeSelectCustom.SHOW_PARENT}
                    treeData={dataTree}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('taxDeduction.noData')}
                      />
                    }
                  />
                </FormCustom.Item>
              </div>
              {jobPositionId && (
                <div className="col-12">
                  <FormCustom.Item
                    layout="horizontal"
                    label={t('jobposition.status')}
                    name={'status'}
                  >
                    <SelectCustom
                      showSearch
                      placeholder={t('jobposition.keySelectPl', {
                        key: toLower(t('jobposition.status')),
                      })}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={t('taxDeduction.noData')}
                        />
                      }
                      filterOption={(input, option) =>
                        removeAccents(option?.label ?? '')
                          .toLowerCase()
                          .includes(removeAccents(input).toLowerCase())
                      }
                      options={SelectOptions.listStatusDepartment}
                    />
                  </FormCustom.Item>
                </div>
              )}
            </div>
          </FormCustom>
        </ContainerContentModal>
      </ModalCustom>
      <ViewJobTitle
        openModal={visiblleJobTitle}
        changeOpenModal={(value) => {
          getJobTitle(form.getFieldValue('jobTitleId'))
          setVisiblleJobTitle(value)
        }}
        onSave={(value) => {
          getJobTitle()
          setVisiblleJobTitle(false)
          form.setFieldValue('jobTitleId', value)
        }}
      />
    </>
  )
}
export default UpdateJobposition
