import React from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './style.scss'

ModalChooseSignMethod.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
}

function ModalChooseSignMethod(props) {
  const {
    show,
    setShow,
    header,
    content,
    handleChooseUSBToken,
    handleChooseHSM,
    handleChooseManually,
  } = props

  let handleClose = () => setShow(false)

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="ModalChooseSignMethod"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>
            <i
              className="fa-light fa-triangle-exclamation mr-3"
              style={{ fontSize: 20, color: '#ff9f0e' }}
            ></i>
            {header}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-4"
            onClick={handleChooseUSBToken}
          >
            <i
              className="fa-duotone fa-usb-drive fa-2x mr-4"
              style={{ fontSize: 18, color: 'rgb(233, 46, 78)' }}
            ></i>
            USB Token
          </button>

          <button
            className="btn btn-outline-secondary mr-4"
            onClick={handleChooseHSM}
          >
            <i
              className="fa-solid fa-digital-tachograph fa-2x mr-4"
              style={{ fontSize: 18, color: 'rgb(233, 46, 78)' }}
            ></i>
            Chữ ký số HSM
          </button>

          <button
            className="btn btn-outline-secondary"
            onClick={handleChooseManually}
          >
            <i
              className="fa-solid fa-digital-tachograph fa-2x mr-4"
              style={{ fontSize: 18, color: 'rgb(233, 46, 78)' }}
            ></i>
            Nhập thủ công
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalChooseSignMethod
