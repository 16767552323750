import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { Rnd } from 'react-rnd'
import styled from 'styled-components'
export const ContainerCustomJp = styled.div`
  padding: 13px 10px;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  color: #000000;
  background-color: #f6f6f6;
`
export const SpanLabel = styled.span`
  color: #000000;
  font-size: 13px;
`
export const ColCustomJp = styled.div`
  span {
    margin-right: 10px;
    line-height: 26px;
  }
  span:last-child {
    margin-right: 0px;
  }
`
export const DownOutlinedCustom = styled(DownOutlined)`
  font-size: 10px;
  margin-left: ${(props) => props.marginLeft};
`
export const DropdownCustom = styled(Dropdown)`
  font-size: 13px;
  color: #000000;
  font-size: 500;
  cursor: pointer;
`

export const DivFlexCustom = styled.div`
  display: flex;
  float: right;
`
export const MenuCustom = styled(Menu)``
