import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jobtitleApi from 'api/jobtitleApi'

export const thunkJobtitle = createAsyncThunk(
  'job-title/getAll',
  async (param, thunkApi) => {
    try {
      const res = await jobtitleApi.getAll(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkUpdateJobtitle = createAsyncThunk(
  'job-title/update',
  async (body, thunkApi) => {
    try {
      const res = await jobtitleApi.update(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkRemoveJobtitle = createAsyncThunk(
  'job-title/remove',
  async (params, thunkApi) => {
    try {
      const res = await jobtitleApi.remove(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
const reportSlice = createSlice({
  name: 'jobtitle',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkJobtitle.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(thunkJobtitle.fulfilled, (state, action) => {
        state.loading = false
        state.processes = action.payload // Cập nhật state với dữ liệu trả về
      })
  },
})

const { reducer } = reportSlice
export default reducer
