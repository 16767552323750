import Utils from 'general/utils/Utils'
import { useEffect, useState } from 'react'

function TaxCodeDisplay(props) {
  const { taxCode } = props
  const [digitArrayTaxCode, setDigitArrayTaxCode] = useState([])
  const convertTaxCode = () => {
    const tempDigitsArray = Utils.isObjectEmpty(taxCode) ? [] : [...taxCode]
    setDigitArrayTaxCode(tempDigitsArray)
  }
  useEffect(() => {
    convertTaxCode()
  }, [taxCode])

  return (
    <div className="d-flex flex-row align-items-center tax-code">
      <div className="d-flex flex-row align-items-center tax-code-input mr-2">
        <div className="tax-code-number">{digitArrayTaxCode[0]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[1]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[2]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[3]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[4]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[5]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[6]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[7]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[8]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[9]}</div>
      </div>
      <div className="d-flex flex-row align-items-center tax-code-input">
        <div className="tax-code-number">{digitArrayTaxCode[11]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[12]}</div>
        <div className="tax-code-number">{digitArrayTaxCode[13]}</div>
      </div>
    </div>
  )
}

export default TaxCodeDisplay
