import { setShowModalExportTemplate } from 'features/TemplateInvoice/templateInvoiceSlice'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import './style.scss'

function ModalExportTemplate(props) {
  const dispatch = useDispatch()
  const {
    hasCompoundTaxRate = true,
    exportTemplateHandler,
    exportTemplateByIdHandler,
    invoiceGeneratedData,
  } = props

  const { showModalExportTemplate } = useSelector(
    (state) => state.templateInvoice,
  )

  const [exportOptions, setExportOptions] = useState({
    type: 1,
    mulTaxRate: 0,
    discount: false,
    foreignCurrency: false,
    format: 'pdf',
  })

  let handleClose = () => dispatch(setShowModalExportTemplate(false))

  let handleRadioChange = (name) => (e) => {
    setExportOptions((old) => ({ ...old, [name]: e.target.value }))
  }

  return (
    <Modal
      show={showModalExportTemplate}
      onHide={handleClose}
      centered
      className="ModalExportTemplate"
    >
      <Modal.Header closeButton>
        <Modal.Title>Xuất mẫu hóa đơn</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="mb-2 font-sfpro-bold">Hình thức hiển thị mẫu hóa đơn</p>

          <div className="row mx-0 g-2 mb-5">
            <div className="col-6 px-0">
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-template-invoice"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="display-form"
                  checked={exportOptions.type == 1}
                  value={1}
                  onChange={handleRadioChange('type')}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-template-invoice"
                >
                  Hóa đơn mẫu
                </label>
              </div>
            </div>

            <div className="col-6 px-0">
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-convert-invoice"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="display-form"
                  checked={exportOptions.type == 0}
                  value={0}
                  onChange={handleRadioChange('type')}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-convert-invoice"
                >
                  Hóa đơn dạng chuyển đổi
                </label>
              </div>
            </div>
          </div>

          {hasCompoundTaxRate && (
            <>
              <p className="mb-2 font-sfpro-bold">Loại thuế suất mẫu hóa đơn</p>

              <div className="row mx-0 g-2 mb-5">
                <div className="col-6 px-0">
                  <div className="d-flex flex-row align-items-center cursor-pointer">
                    <input
                      className="mr-3 cursor-pointer"
                      type="radio"
                      id="m-one-tax-rate"
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      name="mul-tax-rate"
                      checked={exportOptions.mulTaxRate == 0}
                      value={0}
                      onChange={handleRadioChange('mulTaxRate')}
                    />
                    <label
                      className="m-0 font-weight-bolder cursor-pointer"
                      htmlFor="m-one-tax-rate"
                    >
                      Một thuế suất
                    </label>
                  </div>
                </div>

                <div className="col-6 px-0">
                  <div className="d-flex flex-row align-items-center cursor-pointer">
                    <input
                      className="mr-3 cursor-pointer"
                      type="radio"
                      id="m-mul-tax-rate"
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      name="mul-tax-rate"
                      checked={exportOptions.mulTaxRate == 1}
                      value={1}
                      onChange={handleRadioChange('mulTaxRate')}
                    />
                    <label
                      className="m-0 font-weight-bolder cursor-pointer"
                      htmlFor="m-mul-tax-rate"
                    >
                      Nhiều thuế suất
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          {!!invoiceGeneratedData && (
            <>
              <p className="mt-10 mb-2 font-sfpro-bold">
                Hóa đơn phát sinh số liệu
              </p>

              <div className="row mx-0 g-2 mb-5">
                <div className="col-6 px-0">
                  <div className="d-flex flex-row align-items-center cursor-pointer">
                    <input
                      className="mr-3 cursor-pointer"
                      type="checkbox"
                      id="m-discount"
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      checked={exportOptions.discount}
                      onChange={(e) =>
                        setExportOptions((old) => ({
                          ...old,
                          discount: e.target.checked,
                        }))
                      }
                    />
                    <label
                      className="m-0 font-weight-bolder cursor-pointer"
                      htmlFor="m-discount"
                    >
                      Chiết khấu
                    </label>
                  </div>
                </div>

                <div className="col-6 px-0">
                  <div className="d-flex flex-row align-items-center cursor-pointer">
                    <input
                      className="mr-3 cursor-pointer"
                      type="checkbox"
                      id="m-foreignCurrency"
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      checked={exportOptions.foreignCurrency}
                      onChange={(e) =>
                        setExportOptions((old) => ({
                          ...old,
                          foreignCurrency: e.target.checked,
                        }))
                      }
                    />
                    <label
                      className="m-0 font-weight-bolder cursor-pointer"
                      htmlFor="m-foreignCurrency"
                    >
                      Ngoại lệ
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          <p className="mt-6 mb-2 font-sfpro-bold">Chọn định dạng tệp mẫu</p>

          <div>
            <i
              className="fa-regular fa-3x fa-file-pdf mr-5 cursor-pointer"
              onClick={() =>
                setExportOptions((old) => ({ ...old, format: 'pdf' }))
              }
              style={{
                color: exportOptions.format == 'pdf' ? '#007EE9' : '#B5B5C3',
              }}
            ></i>
            <i
              className="fa-regular fa-3x fa-file-word cursor-pointer"
              onClick={() =>
                setExportOptions((old) => ({ ...old, format: 'doc' }))
              }
              style={{
                color: exportOptions.format == 'doc' ? '#007EE9' : '#B5B5C3',
              }}
            ></i>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="align-seft-end">
          <button
            className="btn btn-outline-secondary mr-4 px-8"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>
          <button
            className="btn btn-danger px-8"
            onClick={() => {
              if (exportTemplateHandler) {
                exportTemplateHandler(exportOptions)
              } else if (exportTemplateByIdHandler) {
                let { discount, foreignCurrency, ...rest } = exportOptions
                exportTemplateByIdHandler(rest)
              }
            }}
          >
            Đồng ý
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalExportTemplate
