import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'

import './style.scss'
import Iframe from 'react-iframe'
import ticketApi from 'api/ticketApi'
import { useAppDispatch } from 'hooks/useRedux'
import { setAppSpinning } from 'app/appSlice'

function ModalShowAndPrintTickets(props) {
  const { ticketIds, show, handleClose } = props

  const dispatch = useAppDispatch()

  const [showNotification, setShowNotification] = useState(true)
  const [exportedTicketFiles, setExportedTicketFiles] = useState()
  const [activeTicketFileNum, setActiveTicketFileNum] = useState(0)

  let handleCloseNotification = () => {
    setShowNotification(false)
  }

  const getExportTicketFiles = async (ticketId) => {
    try {
      let res = await ticketApi.exportById(ticketId)
      let newFile = new File([res], 'Vé đã xuất.pdf', {
        type: 'application/pdf',
      })
      var fileURL = URL.createObjectURL(newFile)
      return fileURL
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseModal = () => {
    handleClose()

    setExportedTicketFiles()
  }

  useEffect(() => {
    if (!show || !ticketIds?.length) return
    ;(async function () {
      dispatch(setAppSpinning(true))
      try {
        let _exportTicketFiles = (
          await Promise.allSettled(
            ticketIds.map((ticketId) => getExportTicketFiles(ticketId)),
          )
        ).map(({ value }) => value)
        dispatch(setAppSpinning(false))

        setExportedTicketFiles(_exportTicketFiles)
      } catch (e) {
        dispatch(setAppSpinning(false))
      }
    })()
  }, [show, JSON.stringify(ticketIds)])

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      centered
      className="ModalShowAndPrintTickets"
      style={{ zIndex: 1040 }}
      scrollable={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>In Vé</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="w-100 h-100 position-relative d-flex flex-column">
          {showNotification && (
            <div className="notification mt-2 mr-2">
              <i className="fa-regular fa-circle-exclamation text-danger mr-2"></i>
              Vẫn còn vé đang chờ CQT cấp mã.{' '}
              <a href="#" target="_blank" className="ml-1">
                Kiểm tra lại trạng thái của CQT
              </a>
              <i
                className="fa-regular fa-xmark ml-5 cursor-pointer"
                onClick={handleCloseNotification}
              ></i>
            </div>
          )}

          <div>
            <span className="badge rounded-pill bg-primary mt-2">
              Đã in ( 1 )
            </span>
          </div>

          {exportedTicketFiles?.at(activeTicketFileNum) && (
            <Iframe
              id="ticket-viewer"
              className="flex-fill"
              width="550px"
              height="max-content"
              display="block"
              scrolling="no"
              url={exportedTicketFiles?.at(activeTicketFileNum)}
            />
          )}

          <button
            className="btn btn-icon chevron-btn chevron-left-btn"
            disabled={activeTicketFileNum == 0}
            onClick={() => setActiveTicketFileNum((preS) => preS - 1)}
          >
            <i className="fa-regular fa-chevron-left text-white"></i>
          </button>

          <button
            className="btn btn-icon chevron-btn chevron-right-btn"
            disabled={activeTicketFileNum == exportedTicketFiles?.length - 1}
            onClick={() => setActiveTicketFileNum((preS) => preS + 1)}
          >
            <i className="fa-regular fa-chevron-right text-white"></i>
          </button>

          {exportedTicketFiles?.length && (
            <div className="paging">
              <span className="badge rounded-pill bg-primary mt-2">
                {`${activeTicketFileNum + 1} / ${exportedTicketFiles.length}`}
              </span>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-note mr-4 px-8"
            onClick={handleCloseModal}
          >
            Đóng
          </button>

          <button
            className="btn btn-outline-primary mr-2 px-8"
            onClick={() => {
              var iframe = document.getElementById('ticket-viewer')
              iframe.contentWindow.print()
            }}
          >
            In vé
          </button>

          <button className="btn btn-danger px-8">In toàn bộ</button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalShowAndPrintTickets
