import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import useCheckPermission from 'hooks/useCheckPermission'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import DashboardTab from './screens/DashboardTab'

Dashboard.propTypes = {}

function Dashboard() {
  // MARK: --- Params ---
  const { t } = useTranslation()

  const { isLoadingPermission, hasAccess } = useCheckPermission([
    {
      keyAction: 'hasAccess',
      permissionCode: 'TABLE_WORK_VIEW_STATISTICS_REPORT',
    },
  ])

  return (
    <Routes>
      <Route
        path=""
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={hasAccess}
          >
            <DashboardTab />
          </ProtectedRoute>
        }
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Dashboard
