import React from 'react'
import PropTypes from 'prop-types'
// import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useEffect } from 'react'

VIToggleSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  onSwitch: PropTypes.func,
  status: PropTypes.bool,
  label: PropTypes.string,
  additionalClassName: PropTypes.string,
}

VIToggleSwitch.defaultProps = {
  name: 'select',
  onSwitch: null,
  status: false,
  label: '',
  additionalClassName: '',
}

function VIToggleSwitch(props) {
  // MARK --- Params ---
  const { name, onSwitch, status, label, additionalClassName } = props
  // const { t } = useTranslation()
  const [isSwitchOn, setIsSwitchOn] = useState(!status)

  // MARK --- Functions ---
  function handleChange(e) {
    const checked = e.target.checked
    if (onSwitch) {
      onSwitch(checked)
    }
    setIsSwitchOn(checked)
  }

  // MARK --- Hooks ---
  useEffect(() => {
    setIsSwitchOn(status)
  }, [status])

  return (
    <div>
      <div
        className={`d-flex align-items-center border px-4 py-1 bg-white ${additionalClassName}`}
        style={{ borderRadius: '14px', width: 'fit-content' }}
      >
        <label
          style={{ color: '#3C4376', whiteSpace: 'nowrap' }}
          className="mb-0 mr-4 font-weight-bolder"
          htmlFor={name}
        >
          {label}
        </label>
        <div>
          <span className="switch">
            <label>
              <input
                onChange={handleChange}
                checked={isSwitchOn}
                type="checkbox"
                name={name}
              />
              <span></span>
            </label>
          </span>
        </div>
      </div>
    </div>
  )
}

export default VIToggleSwitch
