import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import Home from '../TaxDeduction/screens/Home'
import { useState } from 'react'
TaxDeduction.propTypes = {}

function TaxDeduction() {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default TaxDeduction
