import { isArray } from 'lodash'

export const filterColumnsBaseOnInvoiceTemplate = ({ baseColumns, serial }) => {
  if (!isArray(baseColumns) || !serial) return baseColumns

  let columns = [...baseColumns]
  let category = serial.category

  /* GTGT nhiều thuế suất */
  if (category?.form == 1 && category?.taxType == 2)
    columns = columns.filter(
      ({ key }) => !['thuegtgt', 'tienthuegtgt'].includes(key),
    )

  /* GTGT một thuế suất*/
  if (category?.form == 1 && category?.taxType == 1) {
    columns = columns.filter(
      ({ key }) => !['thuegtgthh', 'tienthuegtgthh'].includes(key),
    )
  }

  /* BH */
  if (category?.form == 2) {
    columns = columns.filter(
      ({ key }) =>
        !['thuegtgt', 'tienthuegtgt', 'thuegtgthh', 'tienthuegtgthh'].includes(
          key,
        ),
    )
  }

  return columns
}
