import React from 'react'
import { Resizable } from 'react-resizable'

function ResizableTitle(props) {
  const {
    resizeEnable,
    tableHeaderColor,
    onResize,
    width,
    dataIndex,
    ...restProps
  } = props

  if (!width || !resizeEnable) {
    return <th {...restProps} style={{ backgroundColor: tableHeaderColor }} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      axis="x"
      draggableOpts={{ disable: true }}
      minConstraints={[0.5, 0.5]}
    >
      <th {...restProps} style={{ backgroundColor: tableHeaderColor }} />
    </Resizable>
  )
}

export default ResizableTitle
