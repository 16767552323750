import { colors } from 'general/constants/ColorConstants'
import styled from 'styled-components'

export const Button = styled.button`
  --bg-color: ${(props) =>
    props.variant === 'primary'
      ? colors.primary.color
      : colors.secondary.color};
  --text-color: ${(props) =>
    props.variant === 'primary' ? colors.primary.text : colors.secondary.text};

  border-radius: ${(props) => (props.rounded ? '50%' : '0.25rem')};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;
  background-color: var(--bg-color);
  color: var(--text-color);
  height: ${(props) => props.height || '3rem'};
  width: ${(props) => props.width || 'auto'};

  &:hover {
    box-shadow: ${colors.boxShadow};
  }

  & i {
    color: var(--text-color);
  }
`

export const EmblaWrapper = styled.div({
  overflow: 'hidden',
  position: 'relative',
  flexGrow: 1,
  userSelect: 'none',
  padding: '0.5rem 1rem',
})

export const EmblaContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  // alignItems: 'center',
  // justifyContent: 'center',
  flexWrap: 'nowrap',
})

export const EmblaSlide = styled.div({
  flex: '0 0 100%',
  minWidth: 0,
  paddingRight: '0.5rem',
})

export const EmblaButtons = styled.button`
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray.color};
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease-in-out;
  color: ${colors.gray.text};
  ${(props) => props.isPrev && 'left: 0;'}
  ${(props) => props.isNext && 'right: 0;'}
  
  & i {
    color: ${colors.gray.text};
  }
`

export const InvoiceStatusBox = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  padding: '0.75rem 1rem',
  height: '3.75rem',
  backgroundColor: colors.secondary.color,
  color: colors.text,
  borderRadius: '0.25rem',
  columnGap: '0.5rem',
  transition: 'all 0.3s ease-in-out',
  ' &:hover': {
    boxShadow: colors.boxShadow,
  },
})

export const InvoiceStatusNumber = styled.h3({
  fontSize: '2.5rem',
  fontWeight: 600,
  color: colors.primary.color,
})

export const InvoicetStatusText = styled.h4({
  fontSize: '1rem',
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
})

export const FilterTitleWrapper = styled.div({
  display: 'inline-flex',
  flexDirection: 'column',
  msFlexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  width: '100%',
  padding: '0.75rem',
  rowGap: '1rem',
})

export const FilterContentWrapper = styled.div({
  height: '30rem',
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'relative',
  display: 'inline-flex',
  flexDirection: 'column',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#a1a1a14a',
})

export const FilterFooter = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.25rem',
  position: 'sticky',
  bottom: '0',
})
