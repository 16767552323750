import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import StatisticItem from './StatisticItem'

const StatisticDraggable = ({ id, handleDeleteTag }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    data: { sortable },
  } = useSortable({ id })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition: isDragging ? 'none' : transition,
    opacity: isDragging ? 0.5 : 1,
  }
  return (
    <StatisticItem
      style={style}
      isActivated={sortable?.containerId !== 'items'}
      id={id}
      handleDeleteTag={handleDeleteTag}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    />
  )
}

export default StatisticDraggable
