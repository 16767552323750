import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
// import './style.scss';
import CustomButton from 'general/components/Button/CustomButton'
import CustomDropdown from 'general/components/Dropdown/CustomDropdown'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  fieldProps: PropTypes.object,
  fieldMeta: PropTypes.object,
  fieldHelper: PropTypes.object,

  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  spellCheck: PropTypes.bool,
  additionLabelClassName: PropTypes.string,
  additionalInputClassName: PropTypes.string,
  autoComplete: PropTypes.string,
  labelStyle: PropTypes.object,
  require: PropTypes.bool,
  // onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  showDropdownButton: PropTypes.bool,
  showDropdown: PropTypes.bool,
  transparent: PropTypes.bool,
  showBorder: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  allowNegative: PropTypes.bool,
}

InputNumber.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  disabled: false,
  text: '',
  spellCheck: false,
  fieldProps: {},
  fieldHelper: {},
  fieldMeta: {},
  className: 'form-group',
  additionLabelClassName: 'text-remaining',
  additionalInputClassName: '',
  autoComplete: 'on',
  labelStyle: {},
  require: false,
  onBlur: null,
  onKeyDown: null,
  showDropdownButton: false,
  showDropdown: false,
  transparent: false,
  showBorder: true,
  allowNegative: false,
}

function InputNumber(props) {
  // MARK: --- Params ---
  const {
    name,
    fieldProps,
    fieldHelper,
    fieldMeta,
    type,
    label,
    placeholder,
    disabled,
    text,
    className,
    spellCheck,
    additionLabelClassName,
    additionalInputClassName,
    labelStyle,
    require,
    onBlur,
    showDropdownButton,
    transparent,
    showBorder,
    systemConfigType,
    min,
    max,
    fontWeight,
    allowNegative,
    statusChangeNumericFormat,
  } = props
  const { error, touched } = fieldMeta
  const isError = error && touched
  const dropdownContainerRef = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)

  const [currentType, setCurrentType] = useState(type)
  const { formatNumber } = useSelector((state) => state?.systemConfig)

  function handleShowPass() {
    if (currentType === 'password') {
      setCurrentType('text')
    } else if (currentType === 'text') {
      setCurrentType('password')
    }
  }

  const handleFocus = (event) => {
    event.target.select()
  }

  function handleBlur(e) {
    if (onBlur) {
      onBlur(e?.target?.value)
    }
  }

  const onchangeNumericFormat = () => {}

  const commonProps = {
    className: `ps-3 BaseTextField_Input w-100 rounded border-0 bg-transparent ${
      isError ? 'is-invalid' : ''
    } ${additionalInputClassName} pr-4`,
    id: name,
    disabled: disabled,
    name: name,
    placeholder: placeholder,
    spellCheck: spellCheck,
    onFocus: handleFocus,
    ...fieldProps,
    style: {
      color: '#333333',
      fontWeight: fontWeight ?? '500',
      textAlign: 'right',
    },
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: formatNumber ? formatNumber[systemConfigType] ?? 0 : 0,
    min: min,
    max: max,
    fixedDecimalScale: true,
    allowNegative: allowNegative,
  }

  if (statusChangeNumericFormat) {
    commonProps.onChange = onchangeNumericFormat
    delete commonProps.thousandSeparator
  }

  useEffect(() => {
    function handleClickOutside(e) {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current?.contains(e.target)
      ) {
        setShowDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="BaseTextField position-relative w-100">
      <div className={`${className}`} ref={dropdownContainerRef}>
        {label && (
          <div className={`${require && 'd-flex flex-row'}`}>
            <label
              className={additionLabelClassName}
              htmlFor={name}
              style={labelStyle}
            >
              {label}
            </label>
            {require && (
              <span
                className="font-weight-boldest ml-1"
                style={{ color: '#E92E4E' }}
              >{`*`}</span>
            )}
          </div>
        )}
        <div
          className={`BaseTextField_Group rounded input-group ${
            showBorder ? null : 'border-0'
          } ${!disabled && 'bg-white'} ${
            transparent && 'bg-transparent'
          }  d-flex flex-row  justify-content-between ${
            disabled && 'BaseTextField_Disabled'
          }`}
        >
          <NumericFormat {...commonProps} />
        </div>
        {text.length > 0 && (
          <span className="form-text text-muted">{text}</span>
        )}
        {isError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{error}</div>
          </div>
        )}
      </div>
      {showDropdownButton && (
        <CustomButton
          className="d-flex align-items-center justify-content-center cursor-pointer"
          width="36px"
          height="36px"
          position="absolute"
          right="0"
          bottom="0"
          backgroundColor="#304FFD"
          icon="fal fa-chevron-down"
          color="white"
          borderRadius="4px"
          onClick={() => {
            setShowDropdown(!showDropdown)
          }}
        ></CustomButton>
      )}
      {showDropdown && <CustomDropdown></CustomDropdown>}
    </div>
  )
}

export default InputNumber
