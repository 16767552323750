import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useRedux'
import uniq from 'lodash/uniq'
import { shallowEqual } from 'react-redux'

const SLICE_NAME = 'listTransactions'

const initialState = {
  selectedRows: [],
  clearSelectedRows: false,
  /** @type {DataLogPetro} */
  selectedRow: null,
  modalState: {
    exportInvoice: false,
    cancelInvoice: false,
    combineExportInvoice: false,
    multipleExportInvoice: false,
    hsmSign: false,
    markInvoice: false,
    issueInvoiceFromPos: false,
    issueInvoices: false,
  },
}
export const transactionSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    /**@param {import('@reduxjs/toolkit').PayloadAction<any[]>} action */
    setSelectedRows: (state, action) => {
      state.selectedRows = uniq(action.payload)
    },
    setClearSelectedRows: (state) => {
      state.clearSelectedRows = !state.clearSelectedRows
    },
    /**
     * @param {import('@reduxjs/toolkit').PayloadAction<{
     *  name: keyof typeof initialState['modalState'],
     *  status: boolean
     * }>} action
     */
    toggleModalState: (state, action) => {
      state.modalState = Object.keys(state.modalState).reduce(
        (acc, key) => ({
          ...acc,
          [key]: key === action.payload.name ? action.payload.status : false,
        }),
        {},
      )
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload
    },
  },
})
export const transactionActions = transactionSlice.actions

export const useTransactionSelector = () =>
  useAppSelector((state) => state[SLICE_NAME], shallowEqual)
