import { useMemo, useRef } from 'react'
import {
  setClearRows,
  setModalsState,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import {
  setAppNotInstalledToolNotiModalShow,
  setAppSpinning,
  showNotification,
} from 'app/appSlice'
import { shallowEqual } from 'react-redux'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppSelector, useAppDispatch } from 'hooks/useRedux'
import Utils from 'general/utils/Utils'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import { INVOICE_STATUS, INVOICE_TYPE } from 'general/constants/AppConstants'
import {
  useMutationBulkInvoicePaidStatus,
  useMutationPrintInvoice,
  useMutationResendIssue,
} from '../../ListInvoice/queries'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ModalSendEmail from 'general/components/Modal/ModalSendEmail'

const RenderInvoiceActionButtons = () => {
  const { selectedInvoices, modalStates } = useAppSelector(
    (state) => state.invoice,
    shallowEqual,
  )
  const { haveCanDownloadInvoice, allCanSignInvoice, allCanDownloadInvoice } =
    useMemo(() => {
      const canDownloadInvoice = selectedInvoices?.some((invoice) => {
        return (
          (invoice.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
            invoice.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT) &&
          invoice.type !== INVOICE_TYPE.CANCELLED &&
          invoice.type !== INVOICE_TYPE.REPLACED &&
          invoice.type !== INVOICE_TYPE.DELETED
        )
      })
      const canAllSignInvoice = selectedInvoices?.some((invoice) => {
        return [
          INVOICE_STATUS.SEND_ERROR,
          INVOICE_STATUS.SIGN_ERROR,
          INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
        ].includes(invoice.issueStatus)
      })
      const canAllDownloadInvoice = !selectedInvoices?.some((invoice) => {
        return (
          (invoice.issueStatus !== INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
            invoice.issueStatus !== INVOICE_STATUS.AUTHORITY_ACCEPT) ||
          invoice.type === INVOICE_TYPE.CANCELLED ||
          invoice.type === INVOICE_TYPE.REPLACED
        )
      })

      return {
        haveCanDownloadInvoice: canDownloadInvoice,
        allCanSignInvoice: canAllSignInvoice,
        allCanDownloadInvoice: canAllDownloadInvoice,
      }
    }, [selectedInvoices])
  const modalSentMailRef = useRef(null)
  const buttonGroups = [
    {
      text: 'Phát hành',
      icon: 'fa-light fa-paper-plane',
      color: '#2563EB',
      disabled: !allCanSignInvoice,
      onClick: () => {
        allCanSignInvoice
          ? signInvoices(selectedInvoices)
          : dispatch(
              setModalsState({
                modalName: 'sign',
                status: true,
              }),
            )
      },
    },
    {
      text: 'Gửi lại',
      icon: 'fa-light fa-clock-rotate-left',
      color: '#0284C7',
      onClick: () => {
        resendInvoices({
          invoiceIds: selectedInvoices.map((invoice) => invoice.invoiceId),
        })
      },
    },
    {
      text: 'Gửi',
      icon: 'fa-light fa-envelope',
      color: '#0891B2',
      disabled: false,
      onClick: () => {
        const customer_arr = _.map(selectedInvoices, 'customerTaxCode').filter(
          (code) => !_.isEmpty(code),
        )
        const hasMultipleCode = _.uniq(customer_arr).length > 1
        if (hasMultipleCode) {
          dispatch(
            setModalsState({
              modalName: 'detectMultipleCustomer',
              status: true,
            }),
          )
          return
        } else {
          dispatch(
            setModalsState({
              modalName: 'sentMail',
              status: true,
            }),
          )
        }
      },
    },
    {
      text: 'Tải về',
      icon: 'fa-light fa-file-download',
      color: '#059669',
      disabled: !haveCanDownloadInvoice,
      onClick: () => {
        if (allCanDownloadInvoice) {
          downloadInvoices({
            invoices: selectedInvoices,
          })
        } else {
          dispatch(
            setModalsState({
              modalName: 'download',
              status: true,
            }),
          )
        }
      },
    },
    {
      text: 'In',
      icon: 'fa-light fa-print',
      color: '#4F46E5',
      disabled: false,
      onClick: () => {
        printInvoices(selectedInvoices.map((invoice) => invoice.invoiceId))
      },
    },
    {
      text: 'Đã thanh toán',
      icon: 'fa-light fa-check',
      color: '#3CC65E',
      onClick: () =>
        updateBulkInvoicePaidStatus({
          invoiceIds: selectedInvoices
            .filter((invoice) => !invoice.isPayment)
            .map((invoice) => invoice.invoiceId),
          isPayment: true,
        }),
    },
    {
      text: 'Xoá',
      icon: 'fa-light fa-trash',
      danger: true,
      disabled: false,
      onClick: () => {
        dispatch(
          setModalsState({
            modalName: 'delete',
            status: true,
          }),
        )
      },
    },
    {
      text: 'Huỷ chọn',
      icon: 'fa-light fa-times',
      type: 'default',
      danger: true,
      onClick: () => {
        dispatch(setSelectedInvoices([]))
        dispatch(setClearRows())
      },
    },
  ]
  const dispatch = useAppDispatch()
  const { mutate: resendInvoices } = useMutationResendIssue()
  const { mutate: printInvoices } = useMutationPrintInvoice()
  const { mutate: updateBulkInvoicePaidStatus } =
    useMutationBulkInvoicePaidStatus()
  // Function btn
  const signInvoices = async (passedInvoices) => {
    dispatch(setAppSpinning(true))
    let invoicesToSign = passedInvoices

    invoicesToSign = Array.isArray(invoicesToSign)
      ? invoicesToSign
      : [invoicesToSign]
    try {
      const invoiceIdsToSign = invoicesToSign
        .filter((invoice) =>
          [
            INVOICE_STATUS.SEND_ERROR,
            INVOICE_STATUS.SIGN_ERROR,
            INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
          ].includes(invoice.issueStatus),
        )
        .map((invoice) => invoice.invoiceId)
        .sort((a, b) => a - b)
      if (invoiceIdsToSign.length === 0) {
        ToastHelper.showError('Các hoá đơn đã chọn không thể phát hành')

        dispatch(setAppSpinning(false))
        return
      }

      if (activeSigningMethod == 'HSM') {
        hsmSignInvoiceMutation(invoiceIdsToSign)
      } else {
        let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
        let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},`
        invoiceIdsToSign.map((invoiceId, index) => {
          if (index + 1 === invoiceIdsToSign.length) {
            urlSchema = urlSchema + `${invoiceId}`
          } else {
            urlSchema = urlSchema + `${invoiceId};`
          }
        })
        Utils.openToolSignInvoice(urlSchema, accessToken, () =>
          dispatch(setAppNotInstalledToolNotiModalShow(true)),
        )
      }
    } catch (err) {
      console.log(err)
      ToastHelper.showError('Có lỗi xảy ra khi tải')
    } finally {
      dispatch(setAppSpinning(false))
      dispatch(
        setModalsState({
          modalName: 'sign',
          status: false,
        }),
      )
    }
  }
  return (
    <div
      className={Utils.cn(
        selectedInvoices.length > 0 ? 'd-flex' : 'd-none',
        'flex-row justify-content-end align-items-center',
      )}
      style={{ gap: '10px' }}
    >
      {buttonGroups.map((bg) => (
        <CustomAntButton
          key={bg.text}
          text={bg.text + ' (' + selectedInvoices.length + ')'}
          fontSizeText={12}
          iconCn={bg.icon}
          color={bg.color}
          antProps={{
            danger: bg.danger,
            disabled: bg.disabled,
            type: bg.type ?? 'primary',
            className: Utils.cn(bg.className, bg.disabled ? 'disabled' : ''),
            onClick: bg.onClick,
          }}
        />
      ))}
      <ModalConfirm
        header="Xác nhận"
        content="Chương trình nhận thấy bạn đang chọn nhiều hoá đơn với các khách hàng khác nhau. Bạn có chắc chắn muốn tiếp tục?"
        show={modalStates.detectMultipleCustomer}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'detectMultipleCustomer',
              status,
            }),
          )
        }
        handleConfirm={() => {
          dispatch(
            setModalsState({
              modalName: 'sentMail',
              status: true,
            }),
          )
        }}
      />
      <ModalSendEmail
        ref={modalSentMailRef}
        isOpen={modalStates.sentMail}
        selectedInvoices={selectedInvoices}
        onOpenChange={(status) =>
          dispatch(setModalsState({ modalName: 'sentMail', status }))
        }
      />
    </div>
  )
}

export default RenderInvoiceActionButtons
