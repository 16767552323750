import { createAsyncThunk } from '@reduxjs/toolkit'
import systemApi from 'api/systemApi'

const SLICE_NAME = 'systemConfig'

export const getAllSystemFormatNumber = createAsyncThunk(
  SLICE_NAME + '/systemFormatNumber',
  () => systemApi.configurations.getNumberFormat(),
)

export const updateSystemFormatNumber = createAsyncThunk(
  SLICE_NAME + '/systemFormatNumber/update',
  (params) => systemApi.configurations.updateNumberFormat(params),
)

export const getAllSystemCurrency = createAsyncThunk(
  SLICE_NAME + '/systemCurrency',
  () => systemApi.configurations.getCurrency(),
)

export const updateSystemCurrency = createAsyncThunk(
  SLICE_NAME + '/systemCurrency/update',
  (params) => systemApi.configurations.updateCurrency(params),
)

export const getAllSystemInvoiceSetting = createAsyncThunk(
  SLICE_NAME + '/systemInvoiceSetting',
  () => systemApi.configurations.getInvoiceSetting(),
)

export const updateSystemInvoiceSetting = createAsyncThunk(
  SLICE_NAME + '/systemInvoiceSetting/update',
  (params) => systemApi.configurations.updateInvoiceSetting(params),
)

export const getAllTimeRemind = createAsyncThunk(
  SLICE_NAME + '/timeRemind',
  () => systemApi.configurations.getTimeRemind(),
)

export const deleteAllTimeRemind = createAsyncThunk(
  SLICE_NAME + '/timeRemind/deleteAll',
  () => systemApi.configurations.deleteTimeRemind(),
)

export const createTimeRemind = createAsyncThunk(
  SLICE_NAME + '/timeRemind/create',
  (params) => systemApi.configurations.createTimeRemind(params),
)

export const getEmailConfig = createAsyncThunk(
  SLICE_NAME + '/emailConfig',
  () => systemApi.configurations.getEmailConfig(),
)

export const updateEmailConfig = createAsyncThunk(
  SLICE_NAME + '/emailConfig/updateEmailConfig',
  (params) => systemApi.configurations.updateEmailConfig(params),
)

export const getVATConfig = createAsyncThunk(SLICE_NAME + '/VAT', () =>
  systemApi.configurations.getVatSetting(),
)

export const updateVATConfig = createAsyncThunk(
  SLICE_NAME + '/VAT/updateVATConfig',
  (params) => systemApi.configurations.updateVatSetting(params),
)

export const getEmailTemplate = createAsyncThunk(
  SLICE_NAME + '/emailTemplate',
  () => systemApi.configurations.getEmailTemplate(),
)

export const updateEmailTemplate = createAsyncThunk(
  SLICE_NAME + '/emailTemplate/updateById',
  (params) => systemApi.configurations.updateEmailTemplate(params),
)

export const getDataLog = createAsyncThunk(SLICE_NAME + '/getDataLog', () =>
  systemApi.configurations.getDataLog(),
)

export const updateAutoLog = createAsyncThunk(
  SLICE_NAME + '/updateAutoLog',
  (body) => systemApi.configurations.updateDataLog(body),
)
