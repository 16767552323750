import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Space } from 'antd'
import errAnnouceApi from 'api/errAnnouceApi'
import invoiceApi from 'api/invoiceApi'
import taxAuthorityMessageApi from 'api/taxAuthorityMessageApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomSegmented from 'general/components/CustomSegmented'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import {
  ERR_ANNOUCE_STATUS,
  INVOICE_TYPE_TO_QUERY,
} from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useGetSerials from 'hooks/Queries/useGetSerials'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _, { find, map } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { queries } from './queries'
import './style.scss'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
const { Body, Header } = ContentContainer

function ListErrorAnnoucement({ currentTab }) {
  // MARK --- Parmas: ---
  const [findInvoiceModalShow, setFindInvoiceModalShow] = useState(false)

  const [tabs] = useState(() => [
    {
      id: 0,
      text: 'Thông báo hoá đơn sai sót',
      path: '/hoa-don-dau-ra/xu-ly-hoa-don/danh-sach-thong-bao-sai-sot',
    },
    {
      id: 1,
      text: 'Hóa đơn đã thông báo sai sót',
      path: '/hoa-don-dau-ra/xu-ly-hoa-don/danh-sach-thong-bao-sai-sot/hoa-don',
    },
    {
      id: 2,
      text: 'Hóa đơn chưa lập thông báo sai sót',
      path: '/hoa-don-dau-ra/xu-ly-hoa-don/danh-sach-thong-bao-sai-sot/chua-lap-thong-bao-sai-sot',
    },
  ])

  const dispatch = useDispatch()
  const clientQuery = useQueryClient()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [transmissionModalShow, setTransmissionModalShow] = useState(false)
  const [activeErrAnnouce, setActiveErrAnnouce] = useState(null)
  const [filters, setFilters] = useFilter(
    'errorInvoiceAnnouncement',
    'gFiltersErrAnnouceList',
  )
  const [showNoRight, setShowNoRight] = useState(false)

  const { currentAccount } = useAppSelector((state) => state?.auth)
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)
  const listFunction = useAppSelector((state) => state?.function?.listFunctions)
  const userFunction = useAppSelector((state) => state?.function?.userFunctions)
  const [isCreateAnnounceIssue, isEditAnnounceIssue, isDeleteAnnounceIssue] =
    useMemo(() => {
      return Utils.checkFunction(
        [
          { listFunctionCode: 'ANNOUNCE_ISSUE_ADD' },
          { listFunctionCode: 'ANNOUNCE_ISSUE_EDIT' },
          { listFunctionCode: 'ANNOUNCE_ISSUE_DELETE' },
        ],
        userFunction,
        listFunction,
      )
    }, [userFunction, listFunction])
  const { activeSigningMethod } = useAppSelector(
    (state) => state.system.company,
  )

  const { navigate } = useRouter()
  const { data: activeSerial } = useGetSerials('ALL')

  const {
    data: listData,
    isFetching: isFetchingList,
    refetch: refetchingList,
  } = useQuery(
    queries.lists({
      ...filters,
      invoiceType: currentTab,
    }),
  )

  const checkTaxAuthorityMessage = useMutation({
    mutationKey: 'checkTaxAuthorityMessage',
    mutationFn: (messageId) => invoiceApi.checkMessage(messageId),
    onSuccess: (res) => {
      console.log('Tax authority message', res)
      clientQuery.invalidateQueries(queries.lists().queryKey)
    },
  })

  const deleteErrAnnouce = useMutation({
    mutationKey: 'deleteErrAnnouce',
    mutationFn: async (errAnnouceIds) => {
      const res = await errAnnouceApi.delete([errAnnouceIds])
      if (res?.result !== 'success') {
        return ToastHelper.showError('Có lỗi xảy ra khi xoá')
      }
      ToastHelper.showSuccess('Xoá thành công')
      clientQuery.invalidateQueries(queries.lists().queryKey)
    },
  })

  const defaultValue = useMemo(
    () =>
      currentTab === 0
        ? [
            {
              label: 'Trạng thái phát hành',
              alias: 'Trạng thái phát hành',
              formName: 'issueStatus',
              options: SelectOptions.issueStatus,
              value: SelectOptions.issueStatus[0].value,
            },
            {
              label: 'Loại thông báo',
              alias: 'Loại',
              formName: 'error_annoucements',
              options: SelectOptions.error_annoucements,
              value: SelectOptions.error_annoucements[0].value,
            },
          ]
        : currentTab === 1
          ? [
              {
                label: 'Hình thức xử lý',
                alias: 'Hình thức xử lý',
                formName: 'invoiceTypeErrAnnouceList',
                options: SelectOptions.invoiceTypeErrAnnouceList,
                value: SelectOptions.invoiceTypeErrAnnouceList[0].value,
              },
              {
                label: 'Ký hiệu',
                alias: 'Ký hiệu',
                formName: 'serials',
                options: activeSerial,
                value: _.isEmpty(activeSerial) ? null : activeSerial[0]?.value,
              },
              {
                label: 'Trạng thái thông báo sai sót',
                alias: 'Trạng thái TBSS',
                formName: 'invoiceErrAnnouceStatus',
                options: SelectOptions.invoiceErrAnnouceStatus,
                value: SelectOptions.invoiceErrAnnouceStatus[0].value,
                colSpan: 'FULL',
              },
            ]
          : [
              {
                label: 'Ký hiệu',
                alias: 'Ký hiệu',
                formName: 'serials',
                options: activeSerial,
                value: _.isEmpty(activeSerial) ? null : activeSerial[0]?.value,
              },
              {
                label: 'Trạng thái hoá đơn',
                alias: 'Trạng thái HĐ',
                formName: 'invoiceType',
                options: SelectOptions.invoiceType,
                value: SelectOptions.invoiceType[0].value,
              },
            ],
    [activeSerial, currentTab],
  )

  const renderErrAnnouceType = (errAnnouceType) => {
    switch (errAnnouceType) {
      case 1:
        return <div className="badge badge-light-danger">Huỷ</div>
      case 2:
        return <div className="badge badge-light-success">Điều chỉnh</div>
      case 3:
        return <div className="badge badge-light-success">Thay thế</div>
      case 4:
        return <div className="badge badge-light-primary">Giải trình</div>
      default:
      // return <div class="label label-lg label-outline-primary label-inline">Hoá đơn gốc</div>;
    }
  }

  const renderIssueStatus = (issueStatus) => {
    switch (issueStatus) {
      case ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY:
        return <div className="badge badge-light-success">Chưa phát hành</div>
      case ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY:
        return <div className="badge badge-light-success">Chờ CQT xử lý</div>
      case ERR_ANNOUCE_STATUS.AUTHORITY_ACCEPT:
        return <div className="badge badge-light-primary">CQT đã tiếp nhận</div>
      case ERR_ANNOUCE_STATUS.AUTHORITY_DENY:
        return (
          <div className="badge badge-light-danger">CQT không tiếp nhận</div>
        )
      default:
      // return <div class="label label-lg label-outline-primary label-inline">Hoá đơn gốc</div>;
    }
  }

  const switchTab = (value) => {
    navigate(find(tabs, { text: value })?.path)
    setSearch('')
  }

  const handleViewErrorAnnounce = useCallback(
    async (errorAnnoucementId) => {
      let res

      dispatch(setAppSpinning(true))
      try {
        res = await errAnnouceApi.exportById(errorAnnoucementId)
        dispatch(setAppSpinning(false))

        let _newFile = new File([res], 'Thông báo sai sót', {
          type: 'application/pdf',
        })
        var _fileURL = URL.createObjectURL(_newFile)
        window.open(_fileURL, '_blank')
      } catch (e) {
        dispatch(setAppSpinning(false))
      }
    },
    [dispatch],
  )

  const columns = useMemo(() => {
    //Tab hoá đơn sai sót
    return currentTab === 0
      ? [
          {
            name: 'Số thông báo',
            center: true,
            width: '140px',
            cell: (row) => {
              return (
                <p
                  data-tag="allowRowEvents"
                  className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                >
                  {row?.customerNo}
                </p>
              )
            },
          },
          {
            name: 'Số thông báo của CQT',
            center: true,
            width: '210px',
            cell: (row) => {
              return (
                <p
                  data-tag="allowRowEvents"
                  className="text-primary text-hover-decoration font-weight-normal m-0 text-maxline-3 mr-4"
                  onClick={async () => {
                    let res

                    dispatch(setAppSpinning(true))
                    // console.log(row)
                    // return;
                    try {
                      let message300Id = row.tax_authority_messages?.find(
                        ({ typeCode }) => typeCode == 301,
                      )?.messageId
                      if (!message300Id) {
                        ToastHelper.showError('Không tìm thấy message300Id')
                        return
                      }

                      res =
                        await taxAuthorityMessageApi.exportPdfMessage301(
                          message300Id,
                        )
                      dispatch(setAppSpinning(false))

                      let _newFile = new File(
                        [res],
                        'Thông báo 01/TB-HĐSS.pdf',
                        {
                          type: 'application/pdf',
                        },
                      )
                      var _fileURL = URL.createObjectURL(_newFile)
                      window.open(_fileURL, '_blank')
                    } catch (e) {
                      dispatch(setAppSpinning(false))
                    }
                  }}
                >
                  {row?.no}
                </p>
              )
            },
          },
          {
            name: 'Ngày lập thông báo',
            center: true,
            width: '200px',
            cell: (row) => {
              const date = dayjs(row?.date)
              return (
                <p
                  data-tag="allowRowEvents"
                  className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                >
                  {date.isValid() ? dayjs(row?.date).format('L') : ''}
                </p>
              )
            },
          },
          {
            name: 'Nơi lập thông báo',
            center: true,
            // width: '300px',
            cell: (row) => {
              return (
                <p
                  data-tag="allowRowEvents"
                  className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                >
                  {row?.place}
                </p>
              )
            },
          },
          {
            name: 'Trạng thái phát hành',
            center: true,
            // width: '300px',
            cell: (row) => {
              return renderIssueStatus(row?.issueStatus)
            },
          },
          {
            name: 'Hành động',
            center: true,
            minWidth: '100px',
            compact: true,
            disabled: true,
            cell: (row) => {
              return (
                <Space className="w-100 justify-content-end">
                  <TableAction
                    titleActionText="Xem"
                    icon={<IconAction className="fa-light fa-eye" />}
                    onClick={() =>
                      handleViewErrorAnnounce(row.errorAnnoucementId)
                    }
                  />

                  {row?.issueStatus ===
                  ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY ? (
                    <TableAction
                      titleActionText="Kiểm tra trạng thái"
                      icon={<IconAction className="fa-light fa-file-alt" />}
                      onClick={() =>
                        checkTaxAuthorityMessage.mutate(row?.messageId)
                      }
                    />
                  ) : null}
                  {row?.issueStatus ===
                  ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY ? (
                    <TableAction
                      titleActionText="Phát hành thông báo"
                      icon={<IconAction className="fa-light fa-paper-plane" />}
                      onClick={async () => {
                        let errorAnnoucementId = row?.errorAnnoucementId

                        if (activeSigningMethod == 'HSM') {
                          try {
                            dispatch(setAppSpinning(true))

                            await errAnnouceApi.hsmSignErrAnnouce({
                              errorAnnoucementId,
                            })
                            dispatch(setAppSpinning(false))

                            ToastHelper.showSuccess(
                              'Ký thông báo sai sót thành công',
                            )

                            refetchingList()
                          } catch (e) {
                            console.log(e)
                            dispatch(setAppSpinning(false))
                          }
                        } else {
                          const urlSchema = `${
                            process.env.REACT_APP_BASE_URL_SCHEMA
                          }${localStorage.getItem(
                            'eInvoice_accessToken',
                          )},message300,${errorAnnoucementId},${
                            currentAccount?.taxCode
                          }`
                          window.location.href = urlSchema
                        }
                      }}
                    />
                  ) : null}
                  {row?.issueStatus ===
                  ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY ? (
                    <TableAction
                      titleActionText="Xoá thông báo"
                      icon={<IconAction className="fa-light fa-trash" />}
                      onClick={() => {
                        if (isDeleteAnnounceIssue)
                          deleteErrAnnouce.mutate(row?.errorAnnoucementId)
                        if (!isDeleteAnnounceIssue) setShowNoRight(true)
                      }}
                    />
                  ) : null}

                  {row?.issueStatus ===
                  ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY ? (
                    <TableAction
                      icon={
                        <IconAction className="fa-light fa-pen-to-square" />
                      }
                      titleActionText="Chỉnh sửa thông báo"
                      onClick={() => {
                        if (isEditAnnounceIssue)
                          navigate(
                            `/hoa-don-dau-ra/xu-ly-hoa-don/chinh-sua-thong-bao-sai-sot/${row?.errorAnnoucementId}`,
                          )
                        if (!isEditAnnounceIssue) setShowNoRight(true)
                      }}
                    />
                  ) : null}

                  {row?.issueStatus !==
                  ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY ? (
                    <TableAction
                      titleActionText="Xem lịch sử truyền nhận"
                      icon={
                        <IconAction className="fa-light fa-clock-rotate-left" />
                      }
                      onClick={() => {
                        setActiveErrAnnouce(row)
                        setTransmissionModalShow(true)
                      }}
                    ></TableAction>
                  ) : null}
                </Space>
              )
            },
          },
        ]
      : currentTab === 1
        ? //Tab hoá đơn đã thông báo sai sót
          [
            {
              name: 'Ký hiệu',
              center: true,
              width: '110px',
              cell: (row) => {
                return (
                  <p
                    data-tag="allowRowEvents"
                    className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                  >
                    {row?.serial}
                  </p>
                )
              },
            },
            {
              name: 'Số',
              center: true,
              minWidth: '120px',
              cell: (row) => {
                const no = row?.no && Utils.padString(row?.no, 8, '0')
                return (
                  <p
                    data-tag="allowRowEvents"
                    className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                  >
                    {no}
                  </p>
                )
              },
            },
            {
              name: 'Ngày HĐ',
              center: true,
              width: '120px',
              cell: (row) => {
                const date = dayjs(row?.date)

                return (
                  <p
                    data-tag="allowRowEvents"
                    className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                  >
                    {date.isValid() ? dayjs(row?.date).format('L') : ''}
                  </p>
                )
              },
            },
            {
              name: 'Khách hàng',
              center: true,
              width: '300px',
              cell: (row) => {
                return (
                  <p
                    data-tag="allowRowEvents"
                    className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                  >
                    {row?.customerCompanyName}
                  </p>
                )
              },
            },
            {
              name: 'Tổng tiền',
              center: true,
              // sortable: false,
              // right: true,
              width: '200px',
              cell: (row) => {
                const total =
                  row?.total &&
                  Utils.valueFormatted(
                    row?.total,
                    row?.numberFormat,
                    sysConfig,
                    'totalCost',
                  )
                return (
                  <p
                    style={{
                      textAlign: 'right',
                    }}
                    data-tag="allowRowEvents"
                    className="text-dark-75 font-weight-normal w-100 m-0 text-maxline-3 mr-4"
                  >
                    {total} {row?.currency}
                  </p>
                )
              },
            },
            {
              name: 'Mã CQT',
              center: true,
              // sortable: false,
              // right: true,
              width: '200px',
              cell: (row) => {
                return (
                  <p
                    data-tag="allowRowEvents"
                    className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                  >
                    {row?.taxAuthorityCode}
                  </p>
                )
              },
            },
            {
              name: 'Hình thức xử lý',
              center: true,
              // sortable: false,
              // right: true,
              width: '200px',
              cell: (row) => {
                return renderErrAnnouceType(row?.errAnnouceType)
              },
            },
            {
              name: 'Số thông báo của CQT',
              center: true,
              // sortable: false,
              // right: true,
              width: '200px',
              cell: (row) => {
                return (
                  <p
                    data-tag="allowRowEvents"
                    className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
                  >
                    {row?.errAnnouceNo}
                  </p>
                )
              },
            },
            {
              name: 'Hành động',
              center: true,
              compact: true,
              cell: (row) => {
                return row.isInternal ? (
                  <Space>
                    <TableAction
                      icon={<IconAction className="fa-light fa-eye" />}
                      titleActionText="Xem"
                      onClick={() =>
                        Utils.viewPdfInvoice(row, dispatch, setAppSpinning)
                      }
                    ></TableAction>
                  </Space>
                ) : (
                  <></>
                )
              },
            },
          ]
        : [
            {
              name: 'Ký hiệu',
              center: true,
              selector: (row) => row.serial,
              width: '9rem',
              cell: (row) => (
                <p className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4">
                  {row.serial}
                </p>
              ),
            },
            {
              name: 'Ngày HĐ',
              center: true,
              selector: (row) => row.date,
              cell: (row) => {
                const date = dayjs(row?.date)
                return (
                  <p className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4">
                    {date.isValid() ? dayjs(row?.date).format('L') : ''}
                  </p>
                )
              },
            },
            {
              name: 'Số HĐ',
              center: true,
              cell: (row) => (
                <p className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4">
                  {row.no && Utils.padString(row.no, 8, '0')}
                </p>
              ),
            },
            {
              name: 'Mã CQT',
              center: true,
              selector: (row) => row.taxAuthorityCode,
              cell: (row) => (
                <p className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4">
                  {row.taxAuthorityCode}
                </p>
              ),
            },
            {
              name: 'Khách hàng',
              center: true,
              selector: (row) => row.customerCompanyName,
              cell: (row) => (
                <p className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4">
                  {row.customerCompanyName}
                </p>
              ),
            },
            {
              name: 'Mã số thuế',
              center: true,
              selector: (row) => row.customerTaxCode,
              cell: (row) => (
                <p className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4">
                  {row.customerTaxCode}
                </p>
              ),
            },
            {
              name: 'Tổng tiền',
              center: true,
              cell: (row) => (
                <p
                  style={{
                    textAlign: 'right',
                  }}
                  data-tag="allowRowEvents"
                  className="text-dark-75 font-weight-normal w-100 m-0 text-maxline-3 mr-4"
                >
                  {Utils.valueFormatted(
                    row.total,
                    row?.numberFormat,
                    sysConfig,
                    'totalCost',
                  )}
                  {row.currency}
                </p>
              ),
            },
            {
              name: 'Hành động',
              center: true,
              autoOverFlow: true,
              cell: () => {
                return (
                  <Space>
                    <TableAction
                      titleActionText="Xem"
                      icon={<IconAction className="fa-light fa-eye" />}
                    />
                  </Space>
                )
              },
            },
          ]
  }, [
    activeSigningMethod,
    checkTaxAuthorityMessage,
    currentAccount?.taxCode,
    currentTab,
    deleteErrAnnouce,
    dispatch,
    handleViewErrorAnnounce,
    isDeleteAnnounceIssue,
    isEditAnnounceIssue,
    navigate,
    refetchingList,
    sysConfig,
  ])

  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilters])

  useEffect(() => {
    clientQuery.invalidateQueries(queries.lists().queryKey)
  }, [clientQuery])

  return (
    <ContentContainer
      wrapperClassName={'list-error-annoucement'}
      tabs={
        <CustomSegmented
          options={map(tabs, 'text')}
          defaultValue={tabs[currentTab].text}
          onChange={switchTab}
        />
      }
    >
      <Header
        titleContent={find(tabs, { id: currentTab }).text}
        toolBar={
          <Space>
            <CustomAntButton
              text="Lập thông báo"
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  isCreateAnnounceIssue
                    ? setFindInvoiceModalShow(true)
                    : setShowNoRight(true)
                },
              }}
            />
          </Space>
        }
      >
        <Space align="center">
          <CustomAntInput
            type="search"
            borderRadius="sm"
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            defaultValues={defaultValue}
            onApply={setFilters}
            savedKey="gFiltersErrAnnouceList"
          />
        </Space>
      </Header>
      <Body>
        <CustomDataTable
          columns={columns}
          dataSource={listData?.rows}
          isLoading={isFetchingList}
          pagination={{
            currentPage: filters?.page,
            rowsPerPage: filters?.limit,
            total: listData?.count,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 1,
              })
            },
          }}
        />
      </Body>

      {findInvoiceModalShow && (
        <FindInvoiceModal
          show={findInvoiceModalShow}
          onHide={() => setFindInvoiceModalShow(false)}
          title="Chọn hoá đơn sai sót"
          invoiceType={INVOICE_TYPE_TO_QUERY.CAN_ERR_ANNOUCED}
          buttonAgreeOnlick={(selectedInvoices, classify) => {
            let invoiceIdsString = ''
            if (selectedInvoices.length > 0) {
              selectedInvoices.map((invoice, index) => {
                if (index !== selectedInvoices.length - 1) {
                  invoiceIdsString = invoiceIdsString.concat(
                    invoice?.invoiceId,
                    ',',
                  )
                } else {
                  invoiceIdsString = invoiceIdsString.concat(invoice?.invoiceId)
                }
              })
              navigate(
                `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot/${invoiceIdsString}?invoiceClassify=${classify}`,
              )
              // debugger
            } else {
              ToastHelper.showError('Chưa chọn hoá đơn')
              return
            }
          }}
          buttonAgreeTab2Onlick={(invoice) => {
            invoice = InvoiceHelper.validateInvoiceFromExternal(invoice)
            if (!invoice) return

            const queryParams = new URLSearchParams(invoice)
            navigate(
              `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot-khac-he-thong/${queryParams.toString()}${
                invoice.serial?.at(4) == 'M' ? '?invoiceClassify=MTT' : ''
              }`,
            )
          }}
          buttonAgreeVisibility={true}
          checkBoxShow={false}
          selectableRows={true}
          showTab={true}
        />
      )}

      <ModalDataTransmissionLog
        show={transmissionModalShow}
        onHide={() => setTransmissionModalShow(false)}
        errAnnouce={activeErrAnnouce}
      />
      <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
    </ContentContainer>
  )
}

export default ListErrorAnnoucement
