import { Modal } from 'react-bootstrap'

import { setShowModalExportTemplate } from 'features/TemplateInvoice/templateInvoiceSlice'
import Iframe from 'react-iframe'
import { useDispatch } from 'react-redux'
import './style.scss'

function ModalViewPdf(props) {
  const dispatch = useDispatch()
  const { pdfUrl, showModalViewPdf, setShowModalViewPdf } = props

  let handleClose = () => setShowModalViewPdf(false)

  return (
    <Modal
      show={showModalViewPdf}
      onHide={handleClose}
      centered
      className="ModalViewPdf"
      style={{ zIndex: 1040 }}
    >
      <Modal.Header closeButton>
        <div className="w-100 d-flex flex-row justify-content-between">
          <Modal.Title>Mẫu hóa đơn</Modal.Title>
          <button
            className="btn btn-icon mr-3 mb-1"
            title="Xuất khẩu"
            onClick={() => dispatch(setShowModalExportTemplate(true))}
          >
            <i
              className="fa-regular fa-share-from-square"
              style={{ color: '#000' }}
            ></i>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Iframe url={pdfUrl} width="925px" height="100%" display="block" />
      </Modal.Body>
    </Modal>
  )
}

export default ModalViewPdf
