import axiosClient from './axiosClient'

const prefixPath = '/point-check-ticket'

const checkTicketPortalApi = {
  create: async (params) => {
    return axiosClient.post(`${prefixPath}/create`, params)
  },
  update: async (params) => {
    return axiosClient.put(
      `${prefixPath}/update/${params.pointCheckTicketId}`,
      params,
    )
  },
  find: async (params, signal) => {
    return axiosClient.get(`${prefixPath}/find`, { params, signal })
  },
  getDetail: async (params) => {
    return axiosClient.get(`${prefixPath}/detail/${params.pointCheckTicketId}`)
  },
  delete: async (data) => {
    return axiosClient.delete(`${prefixPath}/delete`, { data })
  },
}

export default checkTicketPortalApi
