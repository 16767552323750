import { Flex, Space } from 'antd'
import dayjs from 'dayjs'
import GeneralReportTable from 'features/Report/components/Tables/GeneralReportTable'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import Empty from 'general/components/Empty'
import Loading from 'general/components/Loading'
import AppResource from 'general/constants/AppResource'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import { merge, repeat } from 'lodash'
import { useTranslation } from 'react-i18next'
import { utils, writeFile } from 'xlsx-js-style'
import { useQueryGeneralReport } from './queries'
// import './style.scss'

function GeneralReport() {
  const { t } = useTranslation()
  const sysConfig = useAppSelector((state) => state?.systemConfig.formatNumber)
  const [filters, setFilters] = useFilter(
    'generalReport',
    'gFiltersGeneralReport',
  )

  const { isLoading, data: generalReportInfo } = useQueryGeneralReport(filters)

  // MARK --- Functions ---
  function handleExportExcel(nameContract) {
    var table_elt = document.getElementById('table-xlsx')
    var workbook = utils.table_to_book(table_elt, { raw: true })
    var ws = workbook.Sheets['Sheet1']
    var json = utils.sheet_to_json(ws)
    let arr = Object.keys(ws)
    var regex = /^([a-zA-Z]*)[2,1]$/
    const numberCellRegex = /^[F,G][\d]+$/
    const numberFormat = (config) =>
      config === 0 ? '#,##0' : `#,##0.${repeat('0', config)}`

    const commonStyle = {
      font: {
        name: 'Times New Roman',
        sz: 12,
        italic: false,
        underline: false,
      },
      alignment: {
        wrapText: true,
      },
    }
    arr.forEach((i) => {
      if (!i.includes('!') && !i.includes(':')) {
        ws[i].s = merge({}, commonStyle)
      }
      if (regex.test(i)) {
        ws[i].v = ws[i].v.replace(/\./g, '')
        ws[i].t = 'n'
        ws[i].z = numberFormat(sysConfig.totalCost)
        ws[i].s = merge({}, commonStyle, {
          font: {
            bold: true,
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
          },
        })
      } else if (i.match(numberCellRegex)) {
        ws[i].v = ws[i].v.replace(/\./g, '')
        ws[i].t = 'n'
        ws[i].z = numberFormat(sysConfig.totalCost)
        ws[i].s = merge({}, commonStyle, {
          alignment: {
            vertical: 'center',
            horizontal: 'right',
          },
        })
      }
    })
    let objectMaxLength = []
    let wss = []
    for (let i = 0; i < json.length; i++) {
      let value = Object.values(json[i])
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == 'number') {
          objectMaxLength[j] = 10 + 20
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j].length &&
            value[j].includes('hóa, dịch vụ')
              ? objectMaxLength[j] + 20
              : value[j].length + 20
        }
      }
    }
    for (let j = 0; j < objectMaxLength.length; j++) {
      wss.push({
        width: objectMaxLength[j],
      })
    }
    ws['!cols'] = wss

    utils.sheet_add_aoa(ws, [['']], {
      origin: -1,
    })

    writeFile(workbook, nameContract + '.xlsx')
  }

  return (
    <ContentContainer wrapperClassName="GeneralReport">
      <ContentContainer.Header
        titleContent={t('Bảng kê hóa đơn tổng hợp')}
        toolBar={
          <CustomAntButton
            text={t('ExportExcel')}
            iconCn="fa-solid fa-down-to-line"
            antProps={{
              type: 'primary',
              className: 'btn btn-success',
              onClick: () =>
                handleExportExcel(
                  `Bang_ke_hoa_don_tong_hop_${dayjs(filters.startDate).format(
                    'L',
                  )}_${dayjs(filters.endDate).format('L')}`,
                ),
            }}
          />
        }
      >
        <Space>
          <FilterPopover
            menuPosition="bottomRight"
            onApply={setFilters}
            savedKey="gFiltersGeneralReport"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        {isLoading ? (
          <Flex justify="center" align="center" className="w-100 h-100">
            <Loading message="Đang tải dữ liệu" showBackground={false} />
          </Flex>
        ) : (
          <>
            {generalReportInfo?.invoicesByTax?.length > 0 ? (
              <div className="overflow-auto">
                <GeneralReportTable />
              </div>
            ) : (
              <Empty
                text={t('NoData')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
                imageEmptyPercentWidth={30}
              />
            )}
          </>
        )}
      </ContentContainer.Body>
    </ContentContainer>
  )
}

export default GeneralReport
