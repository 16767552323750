import printJS from 'print-js-updated'

/**
 *
 * @param {Blob} blobFile
 * @param {import('print-js-updated').PrintTypes} type
 */
export default function PrintHelper(blobFile, type) {
  return printJS({
    printable: URL.createObjectURL(blobFile),
    type,
    // showModal: true,
    font_size: '12px',
    font: "-apple-system, Inter, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    onLoadingStart: () => {
      console.log('Loading print js...')
    },
    onLoadingEnd: () => {
      console.log('Done')
    },
    onPrintDialogClose: () => {
      URL.revokeObjectURL(blobFile)
    },
  })
}
