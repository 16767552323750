import { useQueryClient } from '@tanstack/react-query'
import { Space } from 'antd'
import { useFormik } from 'formik'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { useRef } from 'react'

export default function SendMailModal({ open, onOpenChange, ticketId }) {
  const modalRef = useRef(null)
  const queryClient = useQueryClient()
  const initialValues = queryClient.getQueryData([
    'canceledTicketList',
    { q: ticketId },
  ])
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values)
    },
  })
  return (
    <DraggableModal
      title="Gửi thông báo huỷ vé"
      ref={modalRef}
      isOpen={open}
      applyText="Gửi"
      onOpenChange={onOpenChange}
      height={'50vh'}
    >
      <form noValidate onSubmit={formik.handleSubmit} className="h-100">
        <Space direction="vertical" className="h-100 w-100">
          <div className="bg-gray">Thông tin về ticket</div>
          <div>Form gửi mail</div>
        </Space>
      </form>
    </DraggableModal>
  )
}
