import axiosClient from './axiosClient'

const moneyTypeApi = {
  find: (params) => {
    const url = '/money-type/find'
    return axiosClient.get(url, { params })
  },

  create: (params) => {
    const url = '/money-type/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = `/money-type/update/${params.moneyTypeId}`
    return axiosClient.put(url, params)
  },

  delete: (params) => {
    const url = '/money-type/delete'
    return axiosClient.delete(url, {
      data: {
        moneyTypeIds: params,
      },
    })
  },
}

export default moneyTypeApi
