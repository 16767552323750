import { useMutation } from '@tanstack/react-query'
import { Card, Typography } from 'antd'
import authApi from 'api/authApi'
import { Field, Form, Formik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import AppRegex from 'general/constants/AppRegex'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useRouter from 'hooks/useRouter'
import _ from 'lodash'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

export default function SignUpScreen() {
  const { navigate } = useRouter()
  const [searchParams] = useSearchParams()
  const handleSignUp = useMutation({
    mutationKey: ['auth', 'signUp'],
    mutationFn: (params) => {
      const cloned = _.cloneDeep(params)
      _.set(cloned, 'password', Utils.sha256(cloned.password))
      return authApi.signUp({
        linkParams: searchParams.toString(),
        body: cloned,
      })
    },
    onSuccess: (res, values) => {
      if (res.result !== 'success') return
      ToastHelper.showSuccess('Đăng ký tài khoản thành công')
      localStorage.setItem(PreferenceKeys.savedLoginEmail, values.email)
      localStorage.setItem(PreferenceKeys.savedLoginTaxCode, values.taxCode)
      localStorage.setItem(PreferenceKeys.savedLoginPassword)
      navigate('/dang-nhap', {
        replace: true,
      })
    },
  })

  const [inputsData] = useState([
    {
      name: 'fullname',
      label: 'Tên doanh nghiệp',
      placeholder: 'Tên doanh nghiệp',
      type: KTFormInputType.text,
    },
    {
      name: 'taxCode',
      label: 'Mã số thuế',
      placeholder: 'Nhập mã số thuế',
      type: KTFormInputType.text,
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'Nhập email',
      type: KTFormInputType.email,
    },
    {
      name: 'phone',
      label: 'Số điện thoại',
      placeholder: 'Nhập số điện thoại',
      type: KTFormInputType.text,
    },
    {
      name: 'password',
      label: 'Mật khẩu',
      placeholder: 'Nhập mật khẩu',
      type: KTFormInputType.password,
    },
  ])

  return (
    <Formik
      initialValues={{
        email: '',
        fullname: '',
        password: '',
        taxCode: '',
      }}
      enableReinitialize
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Email không hợp lệ')
          .required('Email không được để trống'),
        fullname: Yup.string().required('Họ và tên không được để trống'),
        password: Yup.string()
          .trim()
          .min(6, 'Mật khẩu chứa ít nhất 6 ký tự')
          .required('Mật khẩu không được để trống'),
        taxCode: Yup.string()
          .matches(AppRegex.mstRegex, 'Mã số thuế không hợp lệ')
          .optional()
          .nullable(),
        phone: Yup.string()
          .trim()
          .required('Số điện thoại không được để trống')
          .matches(AppRegex.phoneNumber, 'Số điện thoại không hợp lệ'),
      })}
      onSubmit={handleSignUp.mutate}
    >
      {() => (
        <Card
          bordered={false}
          title={
            <Typography.Title level={2} className="text-center m-0">
              Đăng ký tài khoản
            </Typography.Title>
          }
          bodyStyle={{
            padding: 0,
          }}
          style={{
            maxWidth: 500,
          }}
        >
          <Form>
            <section className="p-5 bg-light">
              <div>
                {inputsData.map((inputData, key) => {
                  return (
                    <KTFormGroup
                      key={key}
                      label={
                        <>
                          {inputData.label}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName={inputData.name}
                      inputElement={
                        <Field name={inputData.name}>
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={inputData.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(inputData.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(inputData.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={inputData.type}
                              placeholder={inputData.placeholder}
                            />
                          )}
                        </Field>
                      }
                    />
                  )
                })}
              </div>
              <div className="mt-5 text-end">
                Đã có tài khoản?{' '}
                <Link
                  to="/auth/dang-nhap"
                  className="font-size-base font-weight-bolder cursor-pointer text-primary text-hover-muted"
                >
                  Đăng nhập ngay
                </Link>
              </div>
            </section>
            <section className="p-5">
              <CustomAntButton
                text={'Đăng ký'}
                isLoading={handleSignUp.isPending}
                antProps={{
                  htmlType: 'submit',
                  block: true,
                  type: 'primary',
                }}
              />
            </section>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
