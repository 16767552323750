import { unwrapResult } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import moneyTypeApi from 'api/moneyTypeApi'
import { thunkGetMoneyTypeList } from 'features/Category/categorySlice'
import ModalEditMoneyType from 'features/Category/components/ModalEditMoneyType'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import Swal from 'sweetalert2'

function MoneyTypeScreen() {
  // MARK --- Parmas: ---
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { moneyTypes, pagination } = useAppSelector(
    (state) => state?.category,
    shallowEqual,
  )
  const { search, setSearch, debouncedSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'moneyTypeList',
    'gFiltersMoneyTypeList',
  )

  const moneyTypeQuery = useQuery({
    queryKey: ['moneyTypeList', filters, debouncedSearch],
    queryFn: () =>
      dispatch(thunkGetMoneyTypeList({ ...filters, q: debouncedSearch })),
    select: (res) => unwrapResult(res),
  })

  const [selectedMoneyTypes, setSelectedMoneyTypes] = useState([])
  const [toggleClearMoneyTypes, setToggledClearMoneyTypes] = useState(true)
  const [selectedMoneyTypeItem, setSelectedMoneyTypeItem] = useState(null)
  const [showingModalEditMoneyType, setShowingModalEditMoneyType] =
    useState(false)
  const [active, setActive] = useState('ADD')

  const fullColumns = useMemo(
    () => [
      {
        name: 'Mã loại tiền',
        // sortable: false,
        // width: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {/* {Utils.formatDateTime(row?.createTime, 'DD/MM/YYYY')} */}
              {row?.code}
            </p>
          )
        },
      },
      {
        name: 'Tên loại tiền',
        // sortable: false,
        // minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.name}
            </p>
          )
        },
      },

      {
        name: 'Tỷ giá quy đổi',
        sortable: true,
        sortFunction: (a, b) => {
          if (a.exchangeRate - b.exchangeRate > 0) {
            return 1
          } else if (a.exchangeRate - b.exchangeRate < 0) {
            return -1
          }
          return 0
        },
        minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {Utils.formatNumber(row?.exchangeRate)}
            </p>
          )
        },
      },
      {
        name: t('Status'),
        width: '150px',
        center: true,
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className={`badge badge-light-${
                row?.status === 'ACTIVE' ? 'success' : 'danger'
              } `}
            >
              {row.status === 'ACTIVE' ? 'Đang sử dụng' : 'Ngừng sử dụng'}
            </div>
          )
        },
      },
      {
        name: '',
        center: true,
        grow: 0,
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <TableAction
              titleActionText={t('Edit')}
              icon={<IconAction className="fa-light fa-edit" />}
              onClick={() => {
                handleEditMoneyType(row)
              }}
            />
            <TableAction
              titleActionText={t('Delete')}
              icon={<IconAction className="fa-light fa-trash" />}
              onClick={() => {
                handleDeleteMoneyType(row)
              }}
            />
            <TableAction
              titleActionText={t('Replicate')}
              icon={<IconAction className="fa-light fa-clone" />}
              onClick={() => {
                handleReplicateMoneyType(row)
              }}
            />
          </div>
        ),
      },
    ],
    [],
  )

  // MARK --- Function ---

  function handleDeleteMultiMoneyType() {
    const arrIdsToDelete = selectedMoneyTypes.map((item) => item.moneyTypeId)

    Swal.fire({
      title: t('Xác nhận'),
      text: `Bạn có muốn xóa ${arrIdsToDelete.length} loại tiền ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Đồng ý'),
      cancelButtonText: t('Hủy'),
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder',
        cancelButton: 'btn btn-light font-weight-bolder',
      },
    }).then(async function (result) {
      if (result.value) {
        const moneyTypeIds = arrIdsToDelete
        try {
          const res = await moneyTypeApi.delete(moneyTypeIds)
          const { result } = res
          if (result === 'success') {
            clearSelectedMoneyTypes()
            ToastHelper.showSuccess(t('Xóa loại tiền thành công'))
            moneyTypeQuery.refetch()
          }
        } catch (error) {
          console.log(`Delete moneyType error: ${error?.message}`)
        }
      }
    })
  }

  function handleSelectedMoneyTypeChanged(state) {
    setSelectedMoneyTypes(state.selectedRows)
  }

  function clearSelectedMoneyTypes() {
    setSelectedMoneyTypes([])
    setToggledClearMoneyTypes(!toggleClearMoneyTypes)
  }

  function handleEditMoneyType(moneyType) {
    setActive('EDIT')
    setSelectedMoneyTypeItem(moneyType)
    setShowingModalEditMoneyType(true)
  }

  function handleDeleteMoneyType(moneyType) {
    Swal.fire({
      title: t('Xác nhận'),
      text: `Bạn có muốn xóa loại tiền ${moneyType.name}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Đồng ý'),
      cancelButtonText: t('Hủy'),
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder',
        cancelButton: 'btn btn-light font-weight-bolder',
      },
    }).then(async function (result) {
      if (result.value) {
        try {
          const res = await moneyTypeApi.delete([moneyType.moneyTypeId])
          const { result } = res
          if (result === 'success') {
            Global.gNeedToRefreshMoneyTypeList = true
            ToastHelper.showSuccess(t('Xóa loại tiền thành công'))
            moneyTypeQuery.refetch()
          }
        } catch (error) {
          console.log(`Delete moneyType error: ${error?.message}`)
        }
      }
    })
  }

  function handleReplicateMoneyType(moneyType) {
    setActive('REPLICATE')
    setSelectedMoneyTypeItem(moneyType)
    setShowingModalEditMoneyType(true)
  }

  return (
    <ContentContainer wrapperClassName="money-type-screen">
      <ContentContainer.Header
        titleContent={t('Loại tiền')}
        description={`${t('Tổng')}: ${pagination?.total ?? 0} ${_.capitalize(
          t('Loại tiền'),
        )}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('Thêm loại tiền')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActive('ADD')
                  setShowingModalEditMoneyType(true)
                },
              }}
            />
          </Space>
        }
      >
        <Space>
          {selectedMoneyTypes.length > 0 ? (
            <CustomAntButton
              text={Utils.cn('Xóa', selectedMoneyTypes.length, 'loại tiền')}
              iconCn="fa-regular fa-trash"
              antProps={{
                type: 'primary',
                danger: true,
                onClick: () => {
                  handleDeleteMultiMoneyType()
                },
              }}
            />
          ) : (
            <>
              <CustomAntInput
                borderRadius="sm"
                type="search"
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
              <label className="mr-2 mb-0" htmlFor="state">
                {_.capitalize(t('Trạng thái'))}
              </label>
              <KTFormSelect
                name="state"
                isCustom
                options={[{ name: 'Tất cả', value: '' }, ...AppData.moneyType]}
                value={filters?.status}
                onChange={(newValue) => {
                  setFilters({
                    page: 0,
                    status: newValue,
                  })
                }}
              />
            </>
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={fullColumns}
          dataSource={moneyTypes}
          isClickable
          isLoading={moneyTypeQuery.isLoading}
          isSelectable
          handleDoubleClickRow={handleEditMoneyType}
          pagination={{
            currentPage: filters?.page + 1,
            rowsPerPage: filters?.limit,
            total: pagination?.total,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 0,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows: toggleClearMoneyTypes,
            handleSelectedRows: handleSelectedMoneyTypeChanged,
            selectableRowSelected: (row) => {
              const arrId = selectedMoneyTypes.map((item) => item.moneyTypeId)
              return arrId.includes(row.moneyTypeId)
            },
          }}
        />
      </ContentContainer.Body>

      {/* Modal moneyType edit */}
      <ModalEditMoneyType
        show={showingModalEditMoneyType}
        onClose={() => {
          setShowingModalEditMoneyType(false)
          setSelectedMoneyTypeItem(null)
        }}
        active={active}
        // onExistDone={() => {
        //   setSelectedMoneyTypeItem(null);
        // }}
        moneyTypeItem={selectedMoneyTypeItem}
        // onRefreshMoneyTypeList={() => {
        //   setSelectedMoneyTypeItem(null);
        //   getMoneyTypeList();
        // }}
      />
    </ContentContainer>
  )
}

export default MoneyTypeScreen
