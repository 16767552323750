import { useEffect } from 'react'

/**
 *
 * @param {string} keyName
 * @param {(e ?: KeyboardEvent) => void} callbackFn
 */
export default function useKeyboardPress(keyName, callbackFn) {
  useEffect(() => {
    /**
     * @param {KeyboardEvent} e
     */
    const keyboardShortcuts = (e) => {
      const isKey = e.key?.toLocaleLowerCase() === keyName?.toLocaleLowerCase()
      isKey && callbackFn()
    }
    window.addEventListener('keydown', keyboardShortcuts)
    return () => {
      window.removeEventListener('keydown', keyboardShortcuts)
    }
  }, [keyName, callbackFn])
}
