import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning, showNotification } from 'app/appSlice'
import dayjs from 'dayjs'
import {
  setClearRows,
  setModalsState,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import {
  INVOICE_STATUS,
  INVOICE_TYPE,
  MESSAGE_SUCCESS,
} from 'general/constants/AppConstants'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import PrintHelper from 'general/helpers/PrintHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useCallback } from 'react'
import { keyFactory } from './keyFactory'

// === Queries
export const useQueryListInvoice = (search, filterParams) => {
  const toTableData = (row, idx) => ({
    ...row,
    id: idx + 1,
    no: row?.no ? InvoiceHelper.padNumber(row.no, 8) : null,
  })

  const queryResult = useQuery({
    queryKey: keyFactory.lists(search, filterParams),
    queryFn: ({ signal }) =>
      invoiceApi.listAll(
        {
          ...filterParams,
          q: search,
        },
        signal,
      ),
    select: (res) => ({
      total: _.get(res, 'count', 0),
      tableData: !res?.rows ? [] : _.map(res.rows, toTableData),
      statistics: _.get(res, 'statistics', {}),
    }),
  })
  const { data, isLoading, isFetching, refetch } = queryResult
  return {
    invoiceListData: data,
    isGettingListData: isLoading,
    isRefetchingListData: isFetching,
    refetchListInvoice: refetch,
    ...queryResult,
  }
}

// === Mutations
export const useMutationInvoicePaidStatus = () => {
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.updatePaidStatus(),
    mutationFn: ({ invoiceId, isPaid }) =>
      invoiceApi.updatePaidStatus(invoiceId, { isPayment: isPaid }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: () => {
      ToastHelper.showSuccess('Cập nhật trạng thái thanh toán thành công')
      queryClient.invalidateQueries(
        keyFactory.lists('', { ...filterParams, page: 1 }),
      )
    },
  })
}
export const useMutationBulkInvoicePaidStatus = () => {
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)

  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.bulkUpdatePaidStatus(),
    mutationFn: ({ invoiceIds, isPayment }) =>
      invoiceApi.bulkUpdatePaidStatus({ invoiceIds, isPayment }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res.result !== 'success') {
        ToastHelper.showError('Cập nhật trạng thái thanh toán thất bại')
        return
      }
      dispatch(setSelectedInvoices([]))
      dispatch(setClearRows())
      ToastHelper.showSuccess('Cập nhật trạng thái thanh toán thành công')
      queryClient.invalidateQueries({
        queryKey: keyFactory.lists('', { ...filterParams, page: 1 }),
      })
    },
  })
}

export const useMutationDownloadPdf = () => {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationKey: keyFactory.downloadPdf(),
    mutationFn: (params) => invoiceApi.exportInvoiceById(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (_.isNil(res)) return
      const link = document.createElement('a')
      const url = URL.createObjectURL(res)
      link.href = url
      link.download = 'Hóa đơn.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
  })
}

export const useMutationDownloadXml = () => {
  const downloadXml = useCallback((xmlString, fileName) => {
    const blob = new Blob([xmlString], { type: 'application/xml' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = fileName || 'Hóa đơn.xml'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }, [])
  return { downloadXml }
}

export const useMutationHsmSigning = (classify = 'HD') => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)

  return useMutation({
    mutationKey: ['invoice', 'listInvoice', 'hsmSignInvoice'],
    mutationFn: (invoicesIds) =>
      invoiceApi.hsmInvoicesSign({
        invoiceIds: _.isArray(invoicesIds) ? invoicesIds : [invoicesIds],
        classify,
      }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: (res, params) => {
      if (res.data) res = res.data
      // Kí hàng loạt
      if (_.isArray(params)) {
        queryClient.invalidateQueries(
          keyFactory.lists('', { ...filterParams, page: 1 }),
        )
        let { errorSignInvoices, successSignInvoices } = res
        let describeText = `Hóa đơn kí thành công: <ul class="mb-0">${successSignInvoices
          .map(
            ({ no, serial }) =>
              `<li>Hóa đơn ký hiệu: ${serial}, số: ${no}</li>`,
          )
          .join('')}</ul>
                            Hóa đơn ký thất bại: <ul class="mb-0">${errorSignInvoices
                              .map(
                                ({ no, serial, reason }) =>
                                  `<li>Hóa đơn ký hiệu: ${serial}, số: ${no}.</br> Lí do ký lỗi: ${reason}</li>`,
                              )
                              .join('')}</ul>`
        dispatch(
          showNotification({
            title: 'Thông báo',
            describeText,
            icon: 'fa-light fa-circle-info text-primary',
          }),
        )
      } else {
        /* Kí một hóa đơn */
        // Kí thành công
        if (
          !!res?.successSignInvoices?.find(
            ({ invoiceId }) => invoiceId == params,
          )
        ) {
          ToastHelper.showSuccess(MESSAGE_SUCCESS.ISSUED_INVOICE)
          // reload page
          queryClient.invalidateQueries(
            keyFactory.lists('', { ...filterParams, page: 1 }),
          )
          return
        }
        // Kí thất bại
        ToastHelper.showError(res?.errorSignInvoices?.at(0)?.reason)
        // reload page
        queryClient.invalidateQueries(
          keyFactory.lists('', { ...filterParams, page: 1 }),
        )
      }
    },
  })
}

export const useMutationCancelInvoice = () => {
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.cancel(),
    mutationFn: ({ invoiceId, customerEmailCc, customerEmailBcc, ...others }) =>
      invoiceApi.cancel(invoiceId, {
        ...others,
        ccEmails: _.compact(_.split(customerEmailCc, ';')),
        bccEmails: _.compact(_.split(customerEmailBcc, ';')),
      }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res?.result === 'success') {
        ToastHelper.showSuccess('Huỷ hoá đơn thành công')
        dispatch(
          setModalsState({
            modalName: 'cancel',
            status: false,
          }),
        )
        queryClient.invalidateQueries(
          keyFactory.lists('', { ...filterParams, page: 1 }),
        )
      }
    },
  })
}

export const useMutationSendExplanationAnnounce = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)
  return useMutation({
    mutationKey: keyFactory.sendExplanationAnnounce(),
    mutationFn: ({ customerEmailCc, customerEmailBcc, invoiceId, ...others }) =>
      invoiceApi.sendExplanationAnnounceToCustomer(invoiceId, {
        ...others,
        ccEmails: _.compact(_.split(customerEmailCc, ';')),
        bccEmails: _.compact(_.split(customerEmailBcc, ';')),
      }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res?.result === 'success') {
        ToastHelper.showSuccess(
          'Gửi thông báo giải trình cho khách hàng thành công',
        )
        dispatch(
          setModalsState({
            modalName: 'sendExplanationAnnounce',
            status: false,
          }),
        )
        queryClient.invalidateQueries(
          keyFactory.lists('', { ...filterParams, page: 1 }),
        )
      }
    },
  })
}

export const useMutationDownloadInvoice = () => {
  const dispatch = useAppDispatch()
  const today = dayjs().format('L')
  return useMutation({
    mutationKey: keyFactory.downloadInvoice(),
    mutationFn: ({ invoices }) => {
      const invoiceIds = invoices
        .filter(
          (invoice) =>
            (invoice.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
              invoice.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
            invoice.type !== INVOICE_TYPE.CANCELLED &&
            invoice.type !== INVOICE_TYPE.REPLACED,
        )
        .map((invoice) => invoice.invoiceId)
      if (invoiceIds.length === 0) {
        ToastHelper.showError('Các hoá đơn đã chọn không thể download')
        dispatch(setAppSpinning(false))
        return
      }
      return invoiceApi.download({ invoiceIds })
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => {
      dispatch(setAppSpinning(false))
      dispatch(
        setModalsState({
          modalName: 'download',
          status: false,
        }),
      )
    },
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      const url = window.URL.createObjectURL(res)
      const a = document.createElement('a')
      a.href = url
      a.download = `Hoa_don_dien_tu_${today}.zip`
      a.click()
      window.URL.revokeObjectURL(url)
      dispatch(setSelectedInvoices([]))
      dispatch(setClearRows())
    },
  })
}

export const useMutationPrintInvoice = () => {
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.prints(),
    mutationFn: (invoiceIds) => {
      if (invoiceIds.length === 0) {
        ToastHelper.showError('Các hoá đơn đã chọn không thể In')
        return
      }
      return invoiceApi.print({
        invoiceIds,
      })
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      PrintHelper(res, 'pdf')
    },
  })
}

export const useMutationDeleteInvoice = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)
  return useMutation({
    mutationKey: keyFactory.delete(),
    mutationFn: ({ invoices }) => {
      if (_.isEmpty(invoices) || _.isNil(invoices)) {
        ToastHelper.showError('Các hoá đơn đã chọn không thể xoá')
        return
      }
      debugger
      const hasNotSentToAuthorityStatus =
        _.every(invoices, {
          issueStatus: INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
        }) ||
        _.every(invoices, {
          issueStatus: INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
        }) ||
        _.every(invoices, {
          issueStatus: INVOICE_STATUS.AUTHORITY_DENY,
        }) ||
        _.every(invoices, {
          issueStatus: INVOICE_STATUS.SEND_ERROR,
        }) ||
        _.every(invoices, {
          issueStatus: INVOICE_STATUS.SIGN_ERROR,
        })
      if (!hasNotSentToAuthorityStatus) {
        ToastHelper.showError('Chỉ được phép xóa các hóa đơn chưa phát hành')
        return
      }
      const ids = _.map(invoices, 'invoiceId')
      return invoiceApi.delete(ids)
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      if (res.result === 'success') {
        dispatch(
          setModalsState({
            modalName: 'delete',
            status: false,
          }),
        )
        ToastHelper.showSuccess('Xoá thành công các hoá đơn chưa phát hành')
        dispatch(setSelectedInvoices([]))
        dispatch(setClearRows())
        queryClient.invalidateQueries({
          queryKey: keyFactory.lists('', { ...filterParams, page: 1 }),
        })
      }
    },
  })
}

export const useMutationCheckMessage = () => {
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)
  return useMutation({
    mutationKey: keyFactory.listTaxAuthorityMessage(),
    mutationFn: (messageId) => invoiceApi.checkMessage(messageId),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res?.result === 'success') {
        queryClient.invalidateQueries(
          keyFactory.lists('', { ...filterParams, page: 1 }),
        )
      }
    },
  })
}
export const useMutationGetBulkMessage = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)
  return useMutation({
    mutationKey: keyFactory.listTaxAuthorityMessage(),
    mutationFn: ({ startDate, endDate }) =>
      invoiceApi.checkMessages({
        startDate,
        endDate,
      }),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      let describeText = `Kiểm tra mã thành công cho ${res?.count} hóa đơn.`
      dispatch(showNotification({ describeText }))
      queryClient.invalidateQueries(
        keyFactory.lists('', { ...filterParams, page: 1 }),
      )
    },
  })
}

export const useMutationViewPdf = () => {
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.viewPdf(),
    mutationFn: ({ invoiceId }) => invoiceApi.exportInvoiceById(invoiceId),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (_.isNil(res)) return
      const url = URL.createObjectURL(res)
      window.open(url, '_blank')
    },
  })
}

export const useMutationResendIssue = () => {
  const qc = useQueryClient()
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.resendIssue(),
    mutationFn: (params) => invoiceApi.reIssue(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      if (!_.isEmpty(res.reIssueErrorInvoices)) {
        ToastHelper.showError(res.reIssueErrorInvoices[0].reason)
        return
      }
      ToastHelper.showSuccess(MESSAGE_SUCCESS.RE_ISSUED_INVOICE)
      qc.invalidateQueries({
        queryKey: [keyFactory.base()],
      })
      dispatch(setClearRows())
      dispatch(setSelectedInvoices([]))
    },
  })
}
