import { Col, DatePicker, Row, theme, Typography } from 'antd'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { useFormikContext } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import SelectOptions from 'general/constants/SelectOptions'
import { transformToStartAndEnd } from 'general/helpers/DateHelper'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import { Fragment, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import customFilterHelpers from '../functions/helpers'
import {
  FilterContentWrapper,
  FilterFooter,
  FilterTitleWrapper,
} from '../styles'

const { periodFilterOptions } = SelectOptions
const { removeLetterInInputNumber } = Utils

/**
 * @param {object} params
 * @param {Array<FilterDefaultValueProp>} params.defaultValues
 * @param {Function} params.onReset
 * @param {boolean} params.canSelectTime
 * @param {import('formik').FormikProps<any>} params.formikProps
 */
export default function PopoverContent(params) {
  //#region Params
  const { defaultValues, onReset, canSelectTime = false, formikProps } = params
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const { submitForm, setFieldValue, values, getFieldProps } = formikProps
  //#endregion

  //#region Function
  const handleChangePeriod = (value) => {
    const dateRange = transformToStartAndEnd(value)
    setFieldValue('period', value)
    if (_.isEmpty(dateRange)) return
    setFieldValue('startDate', dateRange[0])
    setFieldValue('endDate', dateRange[1])
  }

  /**
   * @type {import('antd').DatePickerProps['cellRender']}
   */
  const customCellRender = useCallback(
    (current, info) => {
      if (info.type !== 'date') return info.originNode
      if (typeof current === 'number')
        return <div className="ant-picker-cell-inner">{current}</div>
      return (
        <div
          title={
            values.endDate.isSame(current, 'date')
              ? 'Đến ngày'
              : values.startDate.isSame(current, 'date')
                ? 'Từ ngày'
                : current.format('L')
          }
          className="ant-picker-cell-inner"
          style={
            values.endDate.isSame(current, 'date')
              ? {
                  color: token.colorWhite,
                  backgroundColor: token.colorTextSecondary,
                }
              : values.startDate.isSame(current, 'date')
                ? {
                    color: token.colorWhite,
                    backgroundColor: token.colorPrimary,
                  }
                : {}
          }
        >
          {current.date()}
        </div>
      )
    },
    [
      values.endDate,
      values.startDate,
      token.colorPrimary,
      token.colorTextSecondary,
      token.colorWhite,
    ],
  )

  return (
    <>
      <FilterContentWrapper onSubmit={submitForm}>
        <Row gutter={[13, 13]} align={'middle'}>
          <Col span={10}>
            <Typography.Text strong>{t('Period')}</Typography.Text>
            <CustomAntSelect
              notFoundContent="Không tìm thấy lựa chọn"
              placeholder="Chọn hoặc tìm kiếm"
              value={values.period}
              options={periodFilterOptions}
              autoMatchWidth
              onChange={handleChangePeriod}
            />
          </Col>
          {_.range(0, 2).map((idx) => (
            <Col span={7} key={idx}>
              <Typography.Text strong>
                {idx === 0 ? t('FromDate') : t('ToDate')}
              </Typography.Text>
              <DatePicker
                placement="bottomLeft"
                allowClear={false}
                changeOnBlur
                name={idx === 0 ? 'startDate' : 'endDate'}
                value={values[idx === 0 ? 'startDate' : 'endDate']}
                onChange={(date) => {
                  setFieldValue(
                    idx === 0 ? 'startDate' : 'endDate',
                    idx === 0
                      ? canSelectTime
                        ? date
                        : date.startOf('d')
                      : canSelectTime
                        ? date
                        : date.endOf('d'),
                  )
                  setFieldValue('period', 'CUSTOM')
                }}
                locale={locale}
                showTime={canSelectTime}
                format={canSelectTime ? 'L HH:mm' : 'L'}
                suffixIcon={<i className="fa-light fa-calendar"></i>}
                nextIcon={<i className="fa-regular fa-angle-right"></i>}
                prevIcon={<i className="fa-regular fa-angle-left"></i>}
                superNextIcon={
                  <i className="fa-regular fa-angle-double-right"></i>
                }
                superPrevIcon={
                  <i className="fa-regular fa-angle-double-left"></i>
                }
                cellRender={customCellRender}
                disabledDate={
                  idx === 0
                    ? null
                    : (current) => {
                        return current && current.isBefore(values.startDate)
                      }
                }
              />
            </Col>
          ))}
        </Row>
        <Row gutter={[13, 13]} align={'middle'}>
          {defaultValues?.map((opts) => {
            const onChangeSelect = (value) => {
              if (!opts.multiselect) return setFieldValue(opts.formName, value)
              if (
                value.length === opts.options.length - 1 &&
                opts.options.length > 2
              )
                return setFieldValue(opts.formName, null)

              if (_.includes(value, null)) {
                let result = []
                _.forEach(value, (v) => {
                  result = _.uniq(
                    _.filter(
                      opts.options,
                      (o) => o.value !== v && o.value !== null,
                    ).map((o) => o.value),
                  )
                })
                setFieldValue(opts.formName, result)
              } else {
                setFieldValue(opts.formName, value)
              }
            }

            return _.isArray(opts.label) ? (
              _.map(opts.label, (labelName, idx) => (
                <Col
                  key={labelName}
                  span={customFilterHelpers.getColSpan(opts.colSpan)}
                  className="mx-0"
                >
                  <Typography.Text className="mr-2" strong>
                    {t(labelName)}
                  </Typography.Text>
                  {_.isUndefined(opts.options) ? (
                    opts?.restrict?.type === 'number' ? (
                      <CustomAntInput
                        type="number"
                        inputProps={{
                          ...getFieldProps(opts.formName[idx]),
                          placeholder:
                            idx === 0
                              ? 'Min: ' + opts?.restrict?.min
                              : 'Max: ' + opts?.restrict?.max,
                          className: 'w-100',
                          min: opts?.restrict?.min,
                          max: opts?.restrict?.max,
                          disabled: _.has(values, 'serials')
                            ? _.includes(values.serials, null) ||
                              _.isEmpty(values.serials)
                            : opts.restrict?.disabled,
                          value: values[opts.formName[idx]],
                          onKeyDown: removeLetterInInputNumber,
                          onChange: (inputVal) =>
                            setFieldValue(
                              opts.formName[idx],
                              _.isNumber(inputVal) ? inputVal : null,
                            ),
                          style: {
                            padding: 0,
                          },
                        }}
                      />
                    ) : (
                      <CustomAntInput
                        type="text"
                        inputProps={{
                          ...getFieldProps(opts.formName[idx]),
                          className: 'w-100',
                          value: values[opts.formName[idx]],
                          onChange: (inputVal) =>
                            setFieldValue(opts.formName[idx], inputVal),
                        }}
                      />
                    )
                  ) : (
                    <CustomAntSelect
                      placeholder="Chọn hoặc tìm kiếm"
                      isMultiSelect={opts.multiselect}
                      options={opts.options}
                      allowClear
                      value={values[opts.formName]}
                      onChange={(value) => onChangeSelect(value)}
                      onSelected={(val) =>
                        val === null && setFieldValue(opts.formName, null)
                      }
                    />
                  )}
                </Col>
              ))
            ) : (
              <Fragment key={opts.label}>
                <Col
                  span={customFilterHelpers.getColSpan(opts.colSpan)}
                  className="mx-0"
                >
                  <Typography.Text strong>{t(opts.label)}</Typography.Text>
                  <CustomAntSelect
                    listHeight={150}
                    allowClear
                    placeholder="Chọn hoặc tìm kiếm"
                    isMultiSelect={opts.multiselect}
                    options={opts.options}
                    value={values[opts.formName]}
                    onChange={(value) => onChangeSelect(value)}
                    onSelected={(val) =>
                      val === null && setFieldValue(opts.formName, null)
                    }
                  />
                </Col>
              </Fragment>
            )
          })}
        </Row>
      </FilterContentWrapper>
      <FilterFooter
        className="d-flex w-100 align-items-end flex-grow-1 justify-content-end gap-2"
        style={{
          position: 'sticky',
          bottom: 0,
          paddingTop: '1rem',
          backgroundColor: token.colorBgBase,
        }}
      >
        <CustomAntButton
          antProps={{
            type: 'text',
            className: 'text-primary',
            onClick: onReset,
          }}
          text={t('ResetFilter')}
        />
        <CustomAntButton
          antProps={{
            type: 'primary',
            onClick: submitForm,
          }}
          text={t('ApplyFilter')}
        />
      </FilterFooter>
    </>
  )
}
