import { useFormik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomButton from 'general/components/Button/CustomButton'
import CustomLabel from 'general/components/CustomLabel'
import BaseTextField from 'general/components/Forms/BaseTextField'
import AppData from 'general/constants/AppData'
import AppRegex from 'general/constants/AppRegex'
import useMutationGetCompanyInfo from 'hooks/Mutations/useMutationGetCompanyInfo'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import './style.scss'

const InfoRow = (props) => {
  return (
    <div className="d-flex flex-row">
      <div
        className="font-weight-boldest"
        style={{ minWidth: '150px', maxWidth: '150px', fontSize: '14px' }}
      >
        {props?.title}
      </div>
      <div
        className="font-weight-normal flex-grow-0"
        style={{ fontSize: '14px' }}
      >
        {props?.content}
      </div>
    </div>
  )
}

function CheckCustomerStatusModal(props) {
  const {
    mutateAsync,
    isPending: isGettingCompanyInfo,
    data: companyResult,
  } = useMutationGetCompanyInfo()

  const {
    submitForm,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
    resetForm,
  } = useFormik({
    initialValues: {
      taxCode: props.taxCode,
      companyName: '',
      address: '',
      dateOfOperation: '',
      status: '',
    },
    validationSchema: Yup.object().shape({
      taxCode: Yup.string()
        .trim()
        .matches(AppRegex.mstRegex, 'Mã số thuế không hợp lệ')
        .required('Không được để trống'),
    }),
    onSubmit: async (values) => {
      const result = await mutateAsync({
        document_type: 'TAX',
        tax_code: values.taxCode,
      })
      setCompanyInfo(
        result?.data?.document_data?.tax_informations[0]?.company_information,
      )
    },
    enableReinitialize: true,
  })

  const [companyInfo, setCompanyInfo] = useState(
    () =>
      companyResult?.data?.document_data?.tax_informations[0]
        ?.company_information,
  )

  return (
    <Modal
      {...props}
      onHide={() => {
        props.onHide()
        resetForm()
        if (_.isEmpty(props.taxCode) || _.isNil(props.taxCode)) {
          setCompanyInfo({})
        }
      }}
      size="lg"
      scrollable
      centered
    >
      <Modal.Header className="border-bottom-0" closeButton>
        <span className="font-weight-boldest" style={{ fontSize: '20px' }}>
          Kiểm tra tình trạng hoạt động của khách hàng
        </span>
      </Modal.Header>
      {/* <Modal.Body> */}
      <div
        className="d-flex flex-row align-items-center px-4"
        style={{ backgroundColor: '#f0f2f5', height: '75px', gap: '17px' }}
      >
        <CustomLabel
          text="Mã số thuế"
          htmlFor="taxCode"
          isRequired
          fontWeight={800}
        />
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="taxCode"
            fieldProps={getFieldProps('taxCode')}
            fieldMeta={getFieldMeta('taxCode')}
            fieldHelper={getFieldHelpers('taxCode')}
          />
        </div>
        <CustomAntButton
          isLoading={isGettingCompanyInfo}
          text="Kiểm tra"
          customStyle={{
            width: '160px',
            color: 'white',
            backgroundColor: '#00a84e',
            borderColor: '#cfd9e0',
          }}
          antProps={{
            onClick: submitForm,
          }}
        />
      </div>
      <div className="d-flex flex-column px-5 mt-3" style={{ gap: '10px' }}>
        <span className="font-weight-boldest" style={{ fontSize: '20px' }}>
          {companyInfo?.official_name}
        </span>

        <InfoRow title="Mã số thuế:" content={companyInfo?.tax_code ?? ''} />
        <InfoRow title="Địa chỉ:" content={companyInfo?.address ?? ''} />
        <InfoRow
          title="Ngày thành lập:"
          content={companyInfo?.operation_start_date ?? ''}
        />
        <InfoRow
          title="Tình trạng: "
          content={AppData.dict_tax_state[companyInfo?.tax_state]}
        />
        <span style={{ fontSize: '14px' }}>
          <strong>Lưu ý:</strong>{' '}
          <em>
            {' '}
            Kết quả tra cứu chỉ mang tính chất tham khảo. Bạn nên kiểm tra lại
            tình trạng hoạt động mới nhất của khách hàng trên
            <a
              href="https://tracuunnt.gdt.gov.vn/tcnnt/mstdn.jsp"
              target="_blank"
            >
              {' '}
              trang Web của Tổng cục thuế{' '}
            </a>{' '}
            để đảm bảo tính đúng đắn.
          </em>
        </span>
      </div>
      {/* </Modal.Body> */}
      <Modal.Footer className="border-top-0">
        <CustomButton
          backgroundColor="#304FFD"
          text="Đóng"
          width="90px"
          color="white"
          onClick={props.onHide}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default CheckCustomerStatusModal
