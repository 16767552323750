import React, { useEffect } from 'react'
import './style.scss'

import { useSelector } from 'react-redux'
import CustomColorPicker from 'features/TemplateInvoice/components/CustomColorPicker'
import {
  setBackgroundInfo,
  setSurroundInfo,
  thunkGetBackgrounds,
  thunkGetSurrounds,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useDispatch } from 'react-redux'
import { Range, getTrackBackground } from 'react-range'

function SurroundBackgroundSelect(props) {
  const dispatch = useDispatch()
  const { style } = props

  const { activePanel, backgrounds, surrounds } = useSelector(
    (state) => state.templateInvoice,
  )
  const { backgroundInfo, surroundInfo } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  useEffect(() => {
    if (!surrounds.length) {
      dispatch(thunkGetSurrounds())
    }
  }, [])

  useEffect(() => {
    if (!backgrounds.length) {
      dispatch(thunkGetBackgrounds())
    }
  }, [])

  return (
    <div
      className="SurroundBackgroundSelect d-flex flex-column overflow-hidden"
      style={style}
    >
      {activePanel === 'background_sel' &&
        backgroundInfo?.backgroundColor &&
        !backgroundInfo.backgroundData?.name.startsWith('mau_') &&
        backgroundInfo.backgroundData?.filename?.endsWith('.svg') && (
          <div
            className="d-flex flex-column align-items-center mb-3"
            style={{ flex: '0 1 auto' }}
          >
            <div className="align-self-end">
              <CustomColorPicker
                selectedColor={backgroundInfo.backgroundColor}
                setSelectedColor={(color) =>
                  dispatch(setBackgroundInfo({ backgroundColor: color }))
                }
              />
            </div>
          </div>
        )}

      {activePanel === 'surround_sel' &&
        surroundInfo?.surroundColor &&
        surroundInfo?.surroundData?.filename?.endsWith('.svg') && (
          <div
            className="d-flex flex-column align-items-center mb-3"
            style={{ flex: '0 1 auto' }}
          >
            <div className="align-self-end">
              <CustomColorPicker
                selectedColor={surroundInfo.surroundColor}
                setSelectedColor={(color) =>
                  dispatch(setSurroundInfo({ surroundColor: color }))
                }
              />
            </div>
          </div>
        )}

      <div className="overflow-scroll" style={{ flex: '1 1 auto' }}>
        <div className="row mx-0 g-2">
          {activePanel === 'surround_sel' &&
            surrounds.map((surround, index) => (
              <div key={index} className="col-4 px-0">
                <div
                  className={`sr-item border bg-hover-light ${
                    surroundInfo?.surroundData.borderId == surround.borderId
                      ? 'active'
                      : ''
                  }`}
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/border/${surround.filename})`,
                  }}
                  onClick={() => {
                    if (
                      backgroundInfo?.backgroundData.name.startsWith('mau_')
                    ) {
                      dispatch(setBackgroundInfo(null))
                    }
                    dispatch(
                      setSurroundInfo({
                        surroundData: surround,
                        surroundColor: '#000000',
                      }),
                    )
                  }}
                ></div>
              </div>
            ))}

          {activePanel === 'background_sel' &&
            backgrounds
              .filter(({ name }) => !name.startsWith('mau_'))
              .map((background, index) => (
                <div key={index} className="col-4 px-0">
                  <div
                    className={`bg-item border bg-hover-light ${
                      backgroundInfo?.backgroundData.backgroundId ==
                      background.backgroundId
                        ? 'active'
                        : ''
                    }`}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/background/${background.filename})`,
                    }}
                    onClick={() => {
                      dispatch(
                        setBackgroundInfo({
                          backgroundData: background,
                          x:
                            document.getElementById('lc-frame').offsetWidth /
                              2 -
                            250,
                          y:
                            document.getElementById('lc-frame').offsetHeight /
                              2 -
                            250,
                          width: 500,
                          height: 500,
                          opacity: background.filename?.endsWith('svg')
                            ? 24
                            : 100,
                          backgroundColor: '#000000',
                        }),
                      )
                    }}
                  ></div>
                </div>
              ))}

          {activePanel === 'background_surround_sel' &&
            backgrounds
              .filter(({ name }) => name.startsWith('mau_'))
              .map((background, index) => (
                <div key={index} className="col-4 px-0">
                  <div
                    className={`bg-item border bg-hover-light ${
                      backgroundInfo?.backgroundData.backgroundId ==
                      background.backgroundId
                        ? 'active'
                        : ''
                    }`}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/background/${background.filename})`,
                    }}
                    onClick={() => {
                      dispatch(
                        setBackgroundInfo({
                          backgroundData: background,
                          x: undefined,
                          y: undefined,
                          width: undefined,
                          height: undefined,
                          opacity: 100,
                          backgroundColor: undefined,
                        }),
                      )
                      dispatch(setSurroundInfo(null))
                    }}
                  ></div>
                </div>
              ))}
        </div>
      </div>

      {backgroundInfo &&
        ((activePanel === 'background_sel' &&
          !backgroundInfo.backgroundData?.name.startsWith('mau_')) ||
          (activePanel === 'background_surround_sel' &&
            backgroundInfo.backgroundData?.name.startsWith('mau_'))) && (
          <div
            className="d-flex flex-row align-items-center py-3 border-top border-bottom"
            style={{ flex: '0 1 auto' }}
          >
            <p className="mb-0 mr-6">Độ nét</p>
            <div
              className="d-flex justify-content-center flex-wrap"
              style={{
                width: '300px',
              }}
            >
              <Range
                values={[backgroundInfo?.opacity]}
                step={1}
                min={0}
                max={100}
                onChange={(values) =>
                  dispatch(
                    setBackgroundInfo({
                      opacity: values[0],
                    }),
                  )
                }
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    className="d-flex w-100"
                    style={{
                      ...props.style,
                      height: '20px',
                    }}
                  >
                    <div
                      ref={props.ref}
                      className="w-100 align-self-center"
                      style={{
                        height: '5px',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values: [backgroundInfo?.opacity],
                          colors: ['#548BF4', '#ccc'],
                          min: 0,
                          max: 100,
                        }),
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '5px',
                      width: '20px',
                      backgroundColor: '#FFF',
                      boxShadow: '0px 2px 6px #AAA',
                    }}
                  ></div>
                )}
              />
            </div>

            <output id="output" className="ml-5">{`${[
              backgroundInfo?.opacity,
            ]}%`}</output>
          </div>
        )}
    </div>
  )
}

export default SurroundBackgroundSelect
