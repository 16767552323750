import { useWindowSize } from '@uidotdev/usehooks'
import useRouter from 'hooks/useRouter'
import _includes from 'lodash/includes'
import { useEffect, useState } from 'react'
function KT01Content(props) {
  // MARK --- Params ---
  const { pathname } = useRouter()
  const windowSize = useWindowSize()
  const heightOfHeader = document?.getElementById('kt_header')?.offsetHeight

  const [heightOfContent, setContentHeight] = useState('')
  const needPaddingTop = [
    '/xu-ly-hoa-don/dieu-chinh',
    '/xu-ly-hoa-don/thong-bao-sai-sot',
    '/xu-ly-hoa-don/thay-the',
    '/xu-ly-hoa-don/thong-bao-sai-sot',
    '/hoa-don-dau-ra/hoa-don/',
    '/hoa-don-dau-ra/to-khai/tao',
    '/hoa-don-dau-ra/to-khai/sua',
  ]
  const needEqualInPadding = ['/hoa-don-dau-ra/hoa-don/them-moi']

  const hasPadding = _includes(needPaddingTop, pathname)
    ? 'pt-6'
    : _includes(needEqualInPadding, pathname)
      ? 'p-0 pt-3'
      : 'p-0'

  useEffect(() => {
    setContentHeight(`${Math.floor(windowSize.height - heightOfHeader)}px`)
  }, [heightOfHeader, windowSize.height])

  return (
    <div
      id="kt_content"
      className={`d-flex flex-column flex-column-fluid z-1 ${hasPadding}`}
      style={{
        backgroundColor: '#F2F7FF',
        height: heightOfContent,
      }}
    >
      {props.children}
    </div>
  )
}

export default KT01Content
