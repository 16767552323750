import { Typography } from 'antd'
import { Skeleton } from 'antd'
import Utils from 'general/utils/Utils'
import './style.scss'

function CardStat(props) {
  // MARK --- Params: ---
  const { backgroundColor, toolbar, title, value, unit } = props

  return (
    <div
      className="CardStat p-5 h-100 d-flex flex-column justify-content-between"
      style={{ backgroundImage: backgroundColor }}
    >
      <div className="d-flex justify-content-center mb-2">{toolbar}</div>
      {props.isLoading ? (
        <Skeleton
          paragraph={{
            rows: 1,
            width: '100%',
          }}
          active
        />
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-between ">
            <Typography.Text className="text-white fs-6">
              {title}
            </Typography.Text>
          </div>
          <div className="text-white">
            <span className="font-weight-bolder font-size-h2">
              {Utils.formatNumber(value)}
            </span>{' '}
            <span>{unit}</span>
          </div>
        </>
      )}
    </div>
  )
}

export default CardStat
