import { INVOICE_STATUS } from 'general/constants/AppConstants'
import _ from 'lodash'

const reportHelper = {
  getPaymentMethod: (paymentMethod) => {
    switch (paymentMethod) {
      case 1:
        return 'TM/CK'
      case 2:
        return 'Tiền mặt'
      case 3:
        return 'Chuyển khoản'
      case 4:
        return 'Đối trừ công nợ'
      case 5:
        return 'Không thu tiền'
      case 6:
        return 'Xuất hàng cho chi nhánh'
      case 7:
        return 'Hàng biếu tặng'
      case 8:
        return 'Cấn trừ công nợ'
      case 9:
        return 'Trả hàng'
      case 10:
        return 'Hàng khuyến mại không thu tiền'
      case 11:
        return 'Xuất sử dụng'
      case 12:
        return 'D/A'
      case 13:
        return 'D/P'
      case 14:
        return 'TT'
      case 15:
        return 'L/C'
      case 16:
        return 'Công nợ'
      case 17:
        return 'Nhờ thu'
      case 18:
        return 'TM/CK/B'
      case 19:
        return 'Thẻ tín dụng'
      case 20:
        return 'CK/Cấn trừ công nợ'
      case 21:
        return 'Hàng hóa'
      case 22:
        return 'Hàng mẫu'
      case 23:
        return 'Thẻ tín dụng'
      case 24:
        return 'Bù trừ công nợ'
      case 25:
        return 'Qua LAZADA'
      case 26:
        return 'Qua TIKI'
      case 27:
        return 'Xuất hóa đơn nội bộ'
      case 28:
        return 'T/T'
      case 29:
        return 'TTR'
      case 30:
        return 'TM/CK/Qua LAZADA'
      case 31:
        return 'TM/CK/Qua TIKI'
      case 32:
        return 'TM/The'
      case 33:
        return 'CK/The'
      case 34:
        return 'TM/CK/The'
      case 35:
        return 'CK/LC'
      case 36:
        return 'L/C at sight - Thư tín dụng trả ngay'
      case 37:
        return 'Xuất hàng hoá, dịch vụ trả thay lương'
      case 38:
        return 'CAD - Giao chứng từ trả tiền'
      case 39:
        return 'Qua SHOPEE'
      case 40:
        return 'TM/CK/Qua SHOPEE'
      case 41:
        return 'Cho vay/Cho mượn'
      case 42:
        return 'Ví điện tử'
      case 43:
        return 'Điểm'
      case 44:
        return 'Voucher'
      case 45:
        return 'LC/TT'
      case 46:
        return 'Qua TIKTOK'
      case 47:
        return 'TM/CK/Qua TIKTOK'
      case 48:
        return 'Tạm xuất tái nhập'
      case 49:
        return 'Tạm nhập tái xuất'
      default:
        break
    }
  },

  getInvoiceTypes: (type) => {
    switch (Number(type)) {
      case 0:
        return 'Hóa đơn gốc'
      case 1:
        return 'Thay thế'
      case 2:
        return 'Điều chỉnh'
      case 3:
        return 'Đã hủy'
      case 4:
        return 'Đã bị thay thế'
      case 5:
        return 'Đã bị điều chỉnh'

      default:
        break
    }
  },

  getInvoiceTypeColor: (type) => {
    switch (type) {
      case 0:
        return 'primary'
      case 1:
        return 'warning'
      case 2:
        return 'success'
      case 3:
        return 'danger'
      case 4:
        return 'info'
      case 5:
        return 'secondary'

      default:
        break
    }
  },

  getInvoiceStatus: (status) => {
    switch (status) {
      case INVOICE_STATUS.AUTHORITY_ACCEPT:
        return 'CQT chấp nhận'
      case INVOICE_STATUS.AUTHORITY_CODE_GIVEN:
        return 'CQT đã cấp mã'
      case INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN:
        return 'CQT từ chối cấp mã'
      case INVOICE_STATUS.AUTHORITY_DENY:
        return 'CQT từ chối'
      case INVOICE_STATUS.NOT_SENT_TO_AUTHORITY:
        return 'Chưa phát hành'
      case INVOICE_STATUS.WAITING_FOR_AUTHORITY:
        return 'Chờ CQT xử lý'
      case INVOICE_STATUS.SENDING_TAX:
        return 'Đang gửi CQT'
      case INVOICE_STATUS.SEND_ERROR:
        return 'Gửi lỗi'
      case INVOICE_STATUS.SIGN_ERROR:
        return 'Ký lỗi'
      default:
        break
    }
  },

  getInvoiceStatusColor: (status) => {
    switch (status) {
      case INVOICE_STATUS.AUTHORITY_ACCEPT:
      case INVOICE_STATUS.AUTHORITY_CODE_GIVEN:
        return 'primary'
      case INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN:
      case INVOICE_STATUS.AUTHORITY_DENY:
      case INVOICE_STATUS.SEND_ERROR:
      case INVOICE_STATUS.SIGN_ERROR:
        return 'danger'
      case INVOICE_STATUS.NOT_SENT_TO_AUTHORITY:
        return 'success'
      case INVOICE_STATUS.SENDING_TAX:
      case INVOICE_STATUS.WAITING_FOR_AUTHORITY:
        return 'warning'
      default:
        break
    }
  },

  getSearchCode: (invoiceCode, lookupCode) => {
    if (_.isNil(invoiceCode)) {
      return lookupCode ?? ''
    }
    return invoiceCode
  },

  readXmlString: (xmlString = '') => {
    if (_.isEmpty(xmlString) || _.isNil(xmlString)) return null
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml')
    return xmlDoc
  },
}

export default reportHelper
