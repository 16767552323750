import { useMutation } from '@tanstack/react-query'
import { Flex, Typography } from 'antd'
import { Formik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppDispatch } from 'hooks/useRedux'
import { getVATConfig, updateVATConfig } from '../../asyncThunks'
import { VATValues } from '../../constants'
import { useSystemConfigSelector } from '../../SystemConfigurationSlice'
import FooterCard from '../FooterCard'

export default function TabVatSetting() {
  const { VATConfig } = useSystemConfigSelector()
  const dispatch = useAppDispatch()

  const handleSaveChange = useMutation({
    mutationKey: ['systemConfig', 'vat'],
    mutationFn: (values) => dispatch(updateVATConfig(values)).unwrap(),
    onSettled: async () => {
      ToastHelper.showSuccess('Cập nhật thành công')
      await dispatch(getVATConfig())
    },
  })

  return (
    <Formik
      initialValues={VATConfig}
      enableReinitialize
      onSubmit={(values) => {
        handleSaveChange.mutate(values)
      }}
    >
      {(formikProps) => (
        <Flex justify={'space-between'} vertical className="w-100 h-100">
          <section>
            <Typography.Title level={5} className="">
              Cấu hình thuế GTGT{' '}
            </Typography.Title>
            <div className="d-lg-flex d-block w-100 flex-wrap">
              <div className="w-400px">
                <CustomAntSelect
                  options={VATValues}
                  value={formikProps.values.value}
                  onChange={(newValue) => {
                    formikProps.setFieldValue('value', newValue)
                  }}
                  placeholder="Chọn thuế GTGT"
                />
              </div>
            </div>
          </section>
          <FooterCard
            onRestoreDefault={() => {
              formikProps.setFieldValue('value', '8%')
            }}
            onRestoreChange={formikProps.resetForm}
            onSave={formikProps.submitForm}
            isLoading={formikProps.isSubmitting}
            show={formikProps.dirty}
          />
        </Flex>
      )}
    </Formik>
  )
}
