import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import CustomButton from 'general/components/Button/CustomButton'
import CustomDropdown from 'general/components/Dropdown/CustomDropdown'
import './style.scss'
BaseTextFieldNoFormik.propTypes = {
  //   name: PropTypes.string.isRequired,

  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  spellCheck: PropTypes.bool,
  additionLabelClassName: PropTypes.string,
  additionalInputClassName: PropTypes.string,
  autoComplete: PropTypes.string,
  labelStyle: PropTypes.object,
  require: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  showDropdownButton: PropTypes.bool,
  showDropdown: PropTypes.bool,
  transparent: PropTypes.bool,
  showBorder: PropTypes.bool,
  miltiLine: PropTypes.bool,
  rounded: PropTypes.bool,
}

BaseTextFieldNoFormik.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  disabled: false,
  text: '',
  spellCheck: false,
  className: '',
  additionLabelClassName: 'text-remaining',
  additionalInputClassName: '',
  autoComplete: 'on',
  labelStyle: {},
  require: false,
  onBlur: null,
  onKeyDown: null,
  showDropdownButton: false,
  showDropdown: false,
  transparent: false,
  showBorder: true,
  multiLine: false,
  rounded: true,
}

function BaseTextFieldNoFormik(props) {
  // MARK: --- Params ---
  const {
    name,
    type,
    label,
    placeholder,
    disabled,
    text,
    className,
    spellCheck,
    additionLabelClassName,
    additionalInputClassName,
    autoComplete,
    labelStyle,
    require,
    onBlur,
    onKeyDown,
    showDropdownButton,
    transparent,
    showBorder,
    onChange,
    value,
    multiLine,
    rounded,
    textAlign,
    inputValue,
  } = props
  const dropdownContainerRef = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)
  // const [inputValue, setInputValue] = useState('')
  const [currentType, setCurrentType] = useState(type)

  let Input = !multiLine ? 'input' : 'textarea'
  const handleFocus = (event) => {
    setTimeout(event.target.select.bind(event.target), 20)
  }
  function handleBlur(e) {
    if (onBlur) {
      onBlur(e)
    }
  }
  // useEffect(() => {
  //   if (value || value !== '') setInputValue(value)
  // }, [value])
  useEffect(() => {
    function handleClickOutside(e) {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current?.contains(e.target)
      ) {
        setShowDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  // useEffect(() => {
  //   setInputValue(value)
  // }, [value])

  return (
    <div className={`BaseTextFieldNoFormik position-relative ${className}`}>
      {label && (
        <div className={`${require && 'd-flex flex-row'}`}>
          <label
            className={additionLabelClassName}
            htmlFor={name}
            style={labelStyle}
          >
            {label}
          </label>
          {require && (
            <span
              className="font-weight-boldest ml-1"
              style={{ color: '#E92E4E' }}
            >{`*`}</span>
          )}
        </div>
      )}
      <div
        className={`BaseTextFieldNoFormik_Group ${
          rounded ? 'rounded' : null
        }  input-group ${showBorder ? null : 'border-0'} ${
          !disabled && 'bg-white'
        } ${
          transparent && 'bg-transparent'
        }  d-flex flex-row  justify-content-between ${
          disabled && 'BaseTextFieldNoFormik_Disabled'
        }`}
      >
        <Input
          className={`ps-3 BaseTextFieldNoFormik_Input w-100  ${
            rounded ? 'rounded' : null
          }  border-0 bg-transparent ${
            multiLine && 'pt-3'
          } ${additionalInputClassName}`}
          id={name}
          disabled={disabled}
          type={currentType}
          name={name}
          placeholder={placeholder}
          spellCheck={spellCheck}
          onKeyDown={onKeyDown}
          onChange={onChange}
          // onChange={(e) => {
          //   console.log(e.target.value)
          //   setInputValue(e.target.value)
          // }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="new-password"
          acceptCharset="UTF-8"
          // lang="vi"
          value={inputValue ? inputValue : ''}
          style={{
            color: '#333333',
            fontWeight: '500',
            resize: 'none',
            textAlign: textAlign,
          }}
        />
      </div>
      {text.length > 0 && <span className="form-text text-muted">{text}</span>}
    </div>
  )
}

export default BaseTextFieldNoFormik
