import React from 'react'
import PropTypes from 'prop-types'
import AppResource from 'general/constants/AppResource'

EInvoiceProcess.propTypes = {}

function EInvoiceProcess(props) {
  return (
    <div className="position-relative">
      <img src={AppResource.images.imgEInvoiceProcessBG} />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '16px', top: '96px' }}
        src={AppResource.images.imgDangKySuDungHDDT}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '636px', top: '303px' }}
        src={AppResource.images.imgGuiHoaDonChoNguoiMua}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '477px', top: '303px' }}
        src={AppResource.images.imgGuiHoaDonDeCQTCapMa}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '16px', top: '492px' }}
        src={AppResource.images.imgKhoiTaoMau}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '318px', top: '303px' }}
        src={AppResource.images.imgLapHDDTKySo}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '968px', top: '492px' }}
        src={AppResource.images.imgLapHoaDonDieuChinh}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '787px', top: '96px' }}
        src={AppResource.images.imgLapHoaDonMoi}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '968px', top: '303px' }}
        src={AppResource.images.imgLapHoaDonThayThe}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '151px', top: '492px' }}
        src={AppResource.images.imgThayDoiThongTinDangKy}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '968px', top: '96px' }}
        src={AppResource.images.imgThongBaoChoNguoiMuaVeSaiSot}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '636px', top: '96px' }}
        src={AppResource.images.imgThongBaoHDDTSaiSot}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '787px', top: '303px' }}
        src={AppResource.images.imgThongBaoHDDTSaiSot}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '167px', top: '96px' }}
        src={AppResource.images.imgTieuHuy}
      />
    </div>
  )
}

export default EInvoiceProcess
