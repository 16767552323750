import { queryOptions } from '@tanstack/react-query'
import dashboardApi from 'api/dashboardApi'

export const dashboardQueries = {
  base: {
    scope: ['dashboard', 'cert'],
  },
  listCert: () =>
    queryOptions({
      queryKey: [dashboardQueries.base],
      queryFn: ({ signal }) => dashboardApi.getCerts(signal),
      select: (res) => {
        const { result, certs, userInfo } = res
        if (result === 'failed')
          return {
            certs: [],
            userInfo: {},
          }
        return {
          certs: certs ?? [],
          userInfo: userInfo ?? {},
        }
      },
    }),
}
