import styled from 'styled-components'
import BgImage from 'assets/images/img_signin_bg.jpg'

export const LoginHomeScreen = styled.section({
  backgroundImage: `url(${BgImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexGrow: 1,
  paddingTop: '1rem',
  '@media screen and (min-width: 768px)': {
    alignItems: 'center',
    paddingTop: '0',
  },
})

export const Title = styled.h2({
  color: 'white',
  fontSize: '2rem',
  fontWeight: 'bolder',
  textAlign: 'center',
  '@media screen and (min-width: 768px)': {
    fontSize: '2.5rem',
    textAlign: 'left',
  },
})

export const DescriptionText = styled.p({
  color: 'white',
  textAlign: 'center',
  fontSize: '1rem',
  '@media screen and (min-width: 768px)': {
    fontSize: '1.25rem',
    textAlign: 'left',
  },
})
