import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import Select from 'react-select'
// import { useTranslation } from 'react-i18next'
import StringUtils from 'general/utils/StringUtils'

function KTFormMultiSelect(props) {
  const {
    // name,
    options,
    value,
    text,
    disabled,
    // isCustom,
    // size,
    // showValidState,
    onChange,
    onBlur,
    onFocus,
    enableCheckValid,
    isValid,
    isTouched,
    feedbackText,
    // haveSearchBar,
    noChoice,
    // noSearchBar,
  } = props

  // const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState(null)
  // const [textSearch, setTextSearch] = useState('')
  const textSearch = ''
  useEffect(() => {
    if (value) {
      // const selected = options.find((item) => item.value == value);
      setSelectedOption(value)
    }
  }, [value, options])

  const modifiedOptions = noChoice
    ? [...options, { label: 'Không chọn', value: null }]
    : options

  const filteredOptions = modifiedOptions.filter((item) =>
    StringUtils.removeVietnameseTones(item.label.toLowerCase()).includes(
      StringUtils.removeVietnameseTones(textSearch.toLowerCase()),
    ),
  )

  const handleChange = (selected) => {
    if (onChange) {
      const selectedValues = selected ? selected : []
      onChange(selectedValues)
    }
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur()
    }
  }

  const handleFocus = () => {
    if (onFocus) {
      onFocus()
    }
  }

  return (
    <div>
      <Select
        isMulti
        isDisabled={disabled}
        value={selectedOption}
        options={filteredOptions}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        closeMenuOnSelect={false}
        styles={{
          placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#b6b6bf',
          }),
          control: (base) => ({
            ...base,
            // borderColor: state.isFocused ? 'grey' : 'red',
            borderRadius: '14px',
            height: '38.4px',
            color: '#3F4254',
            border: '1px solid #E4E6EF',
            maxHeight: '100px',
            overflowY: 'auto',
            minHeight: '38px',
            fontSize: '13px',
          }),
        }}
        placeholder={text ? text : ''}
      />
      {enableCheckValid && !isValid && isTouched && feedbackText && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{feedbackText}</div>
        </div>
      )}
    </div>
  )
}

export default KTFormMultiSelect
