import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { useRef } from 'react'

export default function ModalConfirmCancel({
  open,
  onOpenChange,
  description,
  onClick,
  title,
}) {
  const modalRef = useRef(null)
  return (
    <DraggableModal
      ref={modalRef}
      isOpen={open}
      onOpenChange={onOpenChange}
      handleApplyChanges={onClick}
      cancelText="Không"
      applyText="Có"
      status="warning"
      title={title}
      width={500}
    >
      <span className="fs-5 fw-medium">{description}</span>
    </DraggableModal>
  )
}
