import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import cityApi from 'api/cityApi'

export const thunkCity = createAsyncThunk(
  'taxpayer/find',
  async (param, thunkApi) => {
    try {
      const res = await cityApi.getAll(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
const reportSlice = createSlice({
  name: 'city',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkCity.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(thunkCity.fulfilled, (state, action) => {
        state.loading = false
        state.processes = action.payload // Cập nhật state với dữ liệu trả về
      })
  },
})

const { reducer } = reportSlice
export default reducer
