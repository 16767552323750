import { Button, Popover } from 'antd'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomDataTable from 'general/components/CustomDataTable'
import { useQueryBuyerByTaxCode } from 'general/components/Dropdown/CustomDropdown/queries'
import { IconAction } from 'general/components/GroupButton/styles'
import Utils from 'general/utils/Utils'
import useKeyboardPress from 'hooks/useKeyboardPress'
import useOnClickOutside from 'hooks/useOnClickOutside'
import useSearchDebounce from 'hooks/useSearchDebounce'
import useToken from 'hooks/useToken'
import _ from 'lodash'
import { useEffect, useId, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {import('antd').DropdownProps['placement']} [props.placement]
 * @param {import('formik').FieldInputProps<any>} props.fieldProps
 * @param {import('formik').FieldHelperProps<any>} props.fieldHelper
 * @param {import('formik').FieldMetaProps<any>} props.fieldMeta
 * @param {function} [props.onCreateNew]
 * @param {(values : any) => void } [props.onSelect]
 * @param {string} [props.placeholder]
 * @param {FocusEvent<HTMLInputElement>} [props.onBlur]
 */
export default function TextFieldSelect(props) {
  const {
    fieldProps,
    fieldHelper,
    fieldMeta,
    className,
    onBlur,
    onCreateNew,
    onSelect,
    placeholder,
    placement,
  } = props
  const { debouncedSearch, setSearch } = useSearchDebounce('')
  const { t } = useTranslation()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const popoverRef = useRef(null)
  const errors = useMemo(
    () => ({
      message: fieldMeta?.error,
      isInvalid: fieldMeta?.touched && fieldMeta?.error,
    }),
    [fieldMeta?.error, fieldMeta?.touched],
  )

  const id = useId()
  const { token } = useToken()

  const [filterParams] = useState({
    page: 1,
    limit: 20,
    status: 'ACTIVE',
  })

  const { dataSource, isLoading, total } = useQueryBuyerByTaxCode(
    debouncedSearch,
    filterParams,
  )

  const columns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<any>[]} */
    const arr = [
      {
        name: 'Mã khách hàng',
        width: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.code}
            </p>
          )
        },
      },
      {
        name: 'Tên khách hàng',
        width: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.name}
            </p>
          )
        },
      },
      {
        name: 'Địa chỉ',
        width: '200px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.address}
            </p>
          )
        },
      },

      {
        name: 'Mã số thuế',
        // sortable: false,
        // right: true,
        width: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.taxCode}
            </p>
          )
        },
      },
    ]
    return arr
  }, [])

  const handleOnBlur = (e) => {
    fieldProps.onBlur(e)
    fieldHelper.setValue(
      _.replace(e.target.value, /\s/g, '').toUpperCase() ?? '',
    )
  }

  const handleOnChange = (e) => {
    fieldProps.onChange(e)
    setIsPopoverOpen(true)
  }

  //Effects
  useKeyboardPress('Escape', () => setIsPopoverOpen(false))

  useEffect(() => {
    setSearch(fieldMeta?.value)
  }, [fieldMeta?.value, setSearch, total])

  useOnClickOutside(popoverRef, () => setIsPopoverOpen(false))

  return (
    <div className="BaseTextField position-relative" ref={popoverRef}>
      <Popover
        open={isPopoverOpen}
        arrow={false}
        getPopupContainer={(trigger) => trigger.parentNode}
        trigger={['click']}
        placement={placement ?? 'bottomRight'}
        content={
          <>
            <CustomDataTable
              wrapperClassName="max-h-350px"
              columns={columns}
              dataSource={dataSource}
              customTableStyle={customDataTableStyle}
              isLoading={isLoading}
              isClickable
              persistTableHead
              noShowEmpty
              handleClickRow={(row) => {
                onSelect(row)
                setIsPopoverOpen(false)
              }}
            />
            <div className="d-flex justify-content-between p-2">
              <CustomAntButton
                text={t('AddNew')}
                // iconCn="fas fa-plus"
                customStyle={{
                  color: token.colorPrimaryText,
                }}
                antProps={{
                  type: 'text',
                  onClick: onCreateNew,
                }}
              />
              <CustomAntButton
                text={t('Close') + ' (Esc)'}
                // iconCn="fas fa-xmark"
                customStyle={{
                  color: token.colorErrorText,
                }}
                antProps={{
                  type: 'text',
                  onClick: () => setIsPopoverOpen(false),
                }}
              />
            </div>
          </>
        }
      >
        <div className={`${className}`}>
          <div
            className={Utils.cn(
              'BaseTextField_Group rounded input-group bg-white d-flex flex-row justify-content-between',
            )}
          >
            <input
              {...fieldProps}
              className={Utils.cn(
                'ps-3 BaseTextField_Input w-100 rounded border-0 bg-transparent',
                errors.isInvalid && 'is-invalid',
              )}
              id={id}
              placeholder={placeholder}
              onBlur={onBlur ?? handleOnBlur}
              onChange={handleOnChange}
              autoComplete="new-password"
              onFocus={() => setIsPopoverOpen((prev) => prev)}
            />
            <Button
              type="text"
              icon={<IconAction className="far fa-chevron-down" />}
              className="position-absolute top-0 end-0 h-100"
              onClick={() => setIsPopoverOpen((prev) => !prev)}
            ></Button>
          </div>
          {errors.isInvalid && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errors.message}</div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
}
