import BaseTextField from 'general/components/Forms/BaseTextField'
import './style.scss'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CustomButton from 'general/components/Button/CustomButton'
import { Modal } from 'react-bootstrap'
import FormLabel from 'general/components/Forms/FormLabel'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import GroupTextField from 'general/components/Forms/GroupTextTextField'
import { useDispatch } from 'react-redux'
import { setAppSpinning } from 'app/appSlice'
import invoiceApi from 'api/invoiceApi'
import ToastHelper from 'general/helpers/ToastHelper'
import _ from 'lodash'
import Utils from 'general/utils/Utils'
import dayjs from 'dayjs'
import invoiceReportApi from 'api/invoiceReportApi'
import InvoiceReportHelper from 'general/helpers/InvoiceReportHelper'

SendInvoiceReportModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

SendInvoiceReportModal.defaultProps = {
  show: false,
  onClose: null,
}

function SendInvoiceReportModal(props) {
  const { reportType, show, onHide, invoice, addonsSubmitAction } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required(t('Required')),
    customerEmail: Yup.string()
      .email('Email không đúng định dạng')
      .nullable()
      .required(t('Required')),
    customerEmailCc: Yup.string().test(
      'is-valid-email-list',
      'Invalid email list format',
      (value) => {
        if (!value) return true

        const emailListPattern =
          /^([\w+-.%]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(;|$))+$/
        return emailListPattern.test(value)
      },
    ),
  })

  const formik = useFormik({
    initialValues: {
      customerEmail: invoice?.customerEmail,
      customerName: invoice?.customerName,
      customerEmailCc: undefined,
      customerEmailBcc: undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      let ccEmails =
        values.customerEmailCc?.split(';')?.filter((item) => item) || []
      let bccEmails =
        values.customerEmailBcc?.split(';')?.filter((item) => item) || []

      let params = {
        reportId: invoice?.report?.reportId,
        customerName: values.customerName,
        customerEmail: values.customerEmail,
        ccEmails,
        bccEmails,
      }

      dispatch(setAppSpinning(true))
      var res
      try {
        res = await invoiceReportApi.sendCancellationReportToCustomer(params)
      } catch (e) {
        console.log(e)
      } finally {
        dispatch(setAppSpinning(false))
      }

      if (res?.result == 'success') {
        ToastHelper.showSuccess(
          `Gửi biên bản ${InvoiceReportHelper.reportTypeToVnese(
            reportType,
          )} hóa đơn cho khách hàng thành công`,
        )
        onHide()
      }

      addonsSubmitAction()
    },
    enableReinitialize: true,
    validateOnChange: false,
  })

  const [showCc, setShowCc] = useState(false)
  const [showBcc, setShowBcc] = useState(false)

  return (
    <Modal
      className="SendInvoiceReportModal"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="border-bottom-0" closeButton>
        <span
          className="font-weight-boldest"
          style={{ fontSize: '20px', color: '#333333' }}
        >
          {`Gửi biên bản ${InvoiceReportHelper.reportTypeToVnese(
            reportType,
          )} hóa đơn cho khách hàng`}
        </span>
      </Modal.Header>
      {/* <Modal.Body> */}
      <div
        className="d-flex flex-row p-5 justify-content-between"
        style={{ backgroundColor: '#f0f2f5' }}
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Ký hiệu:</FormLabel>
            <div className="w-100 d-flex align-items-center">
              {invoice?.serial}
            </div>
          </div>

          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Số hoá đơn:</FormLabel>
            <div className="w-100 d-flex align-items-center">
              <span>{invoice?.no}</span>
            </div>
          </div>

          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Ngày hoá đơn:</FormLabel>
            <div className="w-100 d-flex align-items-center">
              <span>
                {invoice?.date && dayjs(invoice.date).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Khách hàng:</FormLabel>
            <div className="w-100 d-flex align-items-center">
              <span>{invoice?.customerCompanyName}</span>
            </div>
          </div>
        </div>
        {/* <div className="d-flex flex-column align-items-end">
          <div
            className="d-flex align-items-center"
            style={{ height: '40px', fontSize: '14px', fontWeight: '500' }}
          >
            Tổng tiền thanh toán
          </div>
          <span style={{ fontSize: '24px', fontWeight: '600' }}>
            {_.isNumber(invoice?.totalAfterTax)
              ? Utils.formatNumber(invoice?.totalAfterTax) +
                ' ' +
                invoice?.currency
              : invoice?.totalAfterTax + invoice?.currency}
          </span>
        </div> */}
      </div>
      <div className="d-flex flex-column px-5 mt-5">
        <div className="d-flex flex-column" style={{ gap: '5px' }}>
          <span style={{ fontSize: '14px', fontWeight: '500' }}>
            Tên người nhận
          </span>
          <BaseTextField
            name="customerName"
            fieldProps={formik.getFieldProps('customerName')}
            fieldMeta={formik.getFieldMeta('customerName')}
            fieldHelper={formik.getFieldHelpers('customerName')}
          />
        </div>
        <div className="d-flex flex-column" style={{ gap: '5px' }}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span style={{ fontSize: '14px', fontWeight: '500' }}>Email</span>

            <div>
              <span
                className={`cursor-pointer ${!showCc ? 'text-blue' : ''}`}
                onClick={() => setShowCc(true)}
              >
                Cc
              </span>
              <span className="vr-span  m-2"></span>
              <span
                className={`cursor-pointer ${!showBcc ? 'text-blue' : ''}`}
                onClick={() => setShowBcc(true)}
              >
                Bcc
              </span>
            </div>
          </div>
          <BaseTextField
            name="customerEmail"
            fieldProps={formik.getFieldProps('customerEmail')}
            fieldMeta={formik.getFieldMeta('customerEmail')}
            fieldHelper={formik.getFieldHelpers('customerEmail')}
          />

          {showCc && (
            <GroupTextField
              name="customerEmailCc"
              fieldProps={formik.getFieldProps('customerEmailCc')}
              fieldMeta={formik.getFieldMeta('customerEmailCc')}
              fieldHelper={formik.getFieldHelpers('customerEmailCc')}
              inputGroupText="Cc"
              placeholder="Mỗi email cách nhau bởi dấu ;"
            />
          )}

          {showBcc && (
            <GroupTextField
              name="customerEmailCc"
              fieldProps={formik.getFieldProps('customerEmailBcc')}
              fieldMeta={formik.getFieldMeta('customerEmailBcc')}
              fieldHelper={formik.getFieldHelpers('customerEmailBcc')}
              inputGroupText="Bcc"
              placeholder="Mỗi email cách nhau bởi dấu ;"
            />
          )}
        </div>
      </div>
      {/* </Modal.Body> */}
      <Modal.Footer className="border-top-0">
        <CustomButton
          backgroundColor="white"
          text="Đóng"
          width="90px"
          color="#304FFD"
          onClick={props.onHide}
        ></CustomButton>
        <CustomButton
          backgroundColor="#304FFD"
          text="Gửi"
          width="90px"
          color="white"
          onClick={formik.handleSubmit}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default SendInvoiceReportModal
