import cartApi from 'api/cartApi'
import voucherApi from 'api/voucherApi'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import ToastHelper from 'general/helpers/ToastHelper'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const getAllCart = createAsyncThunk('getAll/cart', async (params) => {
  const res = await cartApi.getAll()
  return res
})

export const updateNumberUp = createAsyncThunk(
  'updateNumberUp/cart',
  async (params, thunkApi) => {
    const res = await cartApi.updateNumber(params)
    return res
  },
)

export const updateNumberDown = createAsyncThunk(
  'updateNumberDown/cart',
  async (params, thunkApi) => {
    const res = await cartApi.updateNumber(params)
    return res
  },
)

export const voucherGetByCode = createAsyncThunk(
  '/voucher/getByCode',
  async (params) => {
    const res = await voucherApi.getByCode(params)
    return res
  },
)

export const remove = createAsyncThunk(
  'remove/cart',
  async (params, thunkApi) => {
    const res = await cartApi.remove(params)
    return res
  },
)

export const removeAll = createAsyncThunk(
  'removeAll/cart',
  async (params, thunkApi) => {
    const res = await cartApi.removeAll(params)
    return res
  },
)

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    pagination: { perPage: Global.gDefaultPagination, currentPage: 0 },
  },
  reducers: {
    setPaginationPerPage: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCart.fulfilled, (state, action) => {
      state.cartItems = action.payload
    })
    builder.addCase(updateNumberUp.fulfilled, (state, action) => {
      // Xử lý khi update thành công
    })
    builder.addCase(updateNumberDown.fulfilled, (state, action) => {
      // Xử lý khi update thành công
    })
    builder.addCase(remove.fulfilled, (state, action) => {
      // Xử lý khi remove thành công
    })
    builder.addCase(voucherGetByCode.pending, (state, action) => {})
    builder.addCase(voucherGetByCode.rejected, (state, action) => {
      ToastHelper.showError('mã voucher không tồn tại hoặc đã hết hạn')
    })
    builder.addCase(voucherGetByCode.fulfilled, (state, action) => {
      state.voucher = action.payload
    })
    builder.addCase(removeAll.fulfilled, (state, action) => {
      state.cartItems = []
    })
  },
})

const { actions, reducer } = cartSlice
export const { setPaginationPerPage } = actions
export default reducer
