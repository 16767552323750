import axiosClient from './axiosClient'
const jobpositionApi = {
  getAll: (params) => {
    const url = '/job-position/getAll'
    return axiosClient.get(url, { params })
  },
  find: (body) => {
    const url = '/job-position/find'
    return axiosClient.post(url, { body })
  },
  save: (body) => {
    const url = '/job-position/save'
    return axiosClient.post(url, { body })
  },
  findById: (params) => {
    const url = '/job-position/findById'
    return axiosClient.get(url, { params })
  },
  remove: (params) => {
    const url = '/job-position/remove'
    return axiosClient.delete(url, { params })
  },
}
export default jobpositionApi
