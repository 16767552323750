import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useRedux'
import SearchTaxTaxpayer from './screens/search'
import Update from './screens/update'
import AddAccountingDocuments from 'features/TaxDeduction/screens/addAccountingDocuments'
function Taxpayer() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <Routes>
      <Route path="/" element={<SearchTaxTaxpayer />} />
      <Route path="/them-moi" element={<Update />} />
      <Route path="/chinh-sua/:taxpayersId" element={<Update />} />
      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Taxpayer
