import { useEffect, useMemo, useRef, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import './style.scss'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import PrivateBaseLayout from 'general/components/eInvoiceComponents/BaseLayout/PrivateBaseLayout'
import Utils from 'general/utils/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import invoiceReportApi from 'api/invoiceReportApi'
import {
  setAppNotInstalledToolNotiModalShow,
  setAppSpinning,
} from 'app/appSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import invoiceApi from 'api/invoiceApi'
import moment from 'moment'
import {
  ACTION_TYPE,
  MAX_WAIT_SIGN_INVOICE,
} from 'general/constants/AppConstants'
import DatePicker from 'react-datepicker'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import toolApi from 'api/toolApi'
import customProtocolCheck from 'custom-protocol-check'
import wsHelperInstance from 'general/helpers/WebSocketHelper'
import Global from 'general/utils/Global'
import InvoiceReportHelper from 'general/helpers/InvoiceReportHelper'
import { useQuery } from '@tanstack/react-query'
import axiosClient from 'api/axiosClient'

dayjs.locale('en')

const externalAction = ['preview', 'sign', 'preview_not_save']

function CreateInvoiceReport() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const { currentAccount, currentCompany } = useSelector(state => state?.auth)
  const [invoice, setInvoice] = useState()
  const { pathname } = useLocation()
  const { invoiceId } = useParams()
  const today = new Date()
  const { company } = useSelector((state) => state.system)
  const _reportType = useMemo(
    () => pathname?.split('/')?.at(3),
    [pathname],
  ) /* bien-ban-huy/bien-ban-dieu-chinh */
  const screenAction = useMemo(() => pathname?.split('/')?.at(4), [pathname])
  const [actionType, setActionType] = useState()
  const [showModalConfirmPreview, setShowModalConfirmPreview] = useState(false)
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [showModalConfirmSign, setShowModalConfirmSign] = useState(false)
  const oldActionType = useRef()
  let reportType = useMemo(
    () =>
      _reportType == 'bien-ban-huy'
        ? 'CANCEL'
        : _reportType == 'bien-ban-dieu-chinh'
          ? 'ADJUST'
          : '',
    [_reportType],
  )

  useEffect(() => {
    fetchInvoiceData(invoiceId)
  }, [invoiceId])

  useEffect(() => {
    if (!invoice) return

    let invoiceId = invoice.invoiceId
    if (invoice.reportType && invoice.reportType != reportType) {
      ToastHelper.showError(
        `Không thể lập biên bản ${InvoiceReportHelper.reportTypeToVnese(
          reportType,
        )} khi hóa đơn đã có biên bản ${InvoiceReportHelper.reportTypeToVnese(
          invoice.reportType,
        )}`,
      )
      navigate('/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don')
    }

    if (invoice.reportStatus == 1 && screenAction != 'them-moi') {
      navigate(`/hoa-don-dau-ra/hoa-don/${_reportType}/them-moi/${invoiceId}`)
    }

    if (invoice.reportStatus == 2 && screenAction != 'cap-nhat') {
      navigate(`/hoa-don-dau-ra/hoa-don/${_reportType}/cap-nhat/${invoiceId}`)
    }

    if (![1, 2].includes(invoice.reportStatus) && screenAction != 'xem') {
      navigate(`/hoa-don-dau-ra/hoa-don/${_reportType}/xem/${invoiceId}`)
    }
  }, [invoice])

  useEffect(() => {
    const parts = pathname.split('/')
    if (parts.includes('them-moi')) {
      setActionType(ACTION_TYPE.CREATE)
    }
    if (parts.includes('cap-nhat')) {
      setActionType(ACTION_TYPE.UPDATE)
    }
  }, [pathname])

  // const userFunction = useSelector(state => state?.function?.userFunctions)
  // const listFunction = useSelector(state => state?.function?.listFunctions)

  const validationSchema = Yup.object().shape({
    // customerTaxCode: Yup.string().matches(mstRegex, 'Mã số thuế không hợp lệ'),
    // .required(t("Required")),
  })
  const [searchParam] = useSearchParams()
  const invoiceClassify = searchParam.get('invoiceClassify')

  const fetchInvoiceData = async (invoiceId) => {
    try {
      const res = await invoiceApi.findById(invoiceId)
      if (res?.result === 'success') {
        setInvoice(res?.data)
      } else {
        ToastHelper.showError('Không tìm thấy dữ liệu')
        navigate('/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don')
      }
    } catch (err) {
      ToastHelper.showError('Có lỗi khi tìm dữ liệu')
      navigate('/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don')
    }
  }

  const formik = useFormik({
    initialValues: {
      no: invoice?.report?.no ?? '',
      date: Utils.formatDate(today),
      sellerName: company?.companyName ?? '',
      sellerTaxCode: company?.taxCode ?? '',
      sellerFullAddress: company?.businessPermitAddress ?? '',
      sellerPhone: invoice?.report?.sellerPhone ?? company?.companyPhone ?? '',
      sellerContactPersonName:
        invoice?.report?.sellerContactPersonName ??
        company?.contactPersonName ??
        '',
      sellerContactPersonPosition:
        invoice?.report?.sellerContactPersonPosition ??
        company?.contactPersonPosition ??
        '',

      // Độ ưu tiên hiểu thị theo thứ tự: Hóa đơn thường => PXK nội bộ => PXK đại lý
      // Tên bên mua
      customerName:
        invoice?.report?.customerName ||
        invoice?.customerCompanyName ||
        invoice?.consigneeUnitName ||
        invoice?.invoiceNote ||
        '',
      //Mã số thuế bên mua
      customerTaxCode:
        invoice?.report?.customerTaxCode ||
        invoice?.customerTaxCode ||
        invoice?.consigneeUnitTaxcode ||
        invoice?.buyerTaxCode ||
        '',
      // Địa chỉ bên mua
      customerFullAddress:
        invoice?.report?.customerFullAddress ||
        invoice?.customerFullAddress ||
        invoice?.consigneeUnitAddress ||
        invoice?.toWarehouseName ||
        '',
      // sđt bên mua
      customerPhone:
        invoice?.report?.customerPhone ?? invoice?.customerPhone ?? '',
      // Người liên hệ bên mua
      customerContactPersonName:
        invoice?.report?.customerContactPersonName ??
        invoice?.customerName ??
        '',
      customerContactPersonPosition:
        invoice?.report?.customerContactPersonPosition ?? '',
      reason: invoice?.report?.reason ?? '',
      invoiceId: invoiceId ?? '',
      reportId: invoice?.report?.reportId ?? '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        let res
        dispatch(setAppSpinning(true))

        if (!reportType) {
          ToastHelper.showError('reportType không hợp lệ')
          return
        }

        switch (actionType) {
          case ACTION_TYPE.CREATE:
            res = await invoiceReportApi.create({ ...values, reportType })
            dispatch(setAppSpinning(false))
            break
          case ACTION_TYPE.UPDATE:
            res = await invoiceReportApi.update({ ...values, reportType })
            dispatch(setAppSpinning(false))
            break
          case 'preview': {
            setActionType(oldActionType.current)
            if (oldActionType.current == ACTION_TYPE.CREATE) {
              res = await invoiceReportApi.create({ ...values, reportType })
            } else if (oldActionType.current == ACTION_TYPE.UPDATE) {
              res = await invoiceReportApi.update({ ...values, reportType })
            }
            ToastHelper.showSuccess('Lưu biên bản thành công')
            setShowModalConfirmPreview(false)

            res = await invoiceReportApi.preview({
              reportId: values.reportId || res.data?.reportId,
              reportType,
            })
            dispatch(setAppSpinning(false))
            let newFile = new File(
              [res],
              `Biên bản ${InvoiceReportHelper.reportTypeToVnese(
                reportType,
              )}.pdf`,
              {
                type: 'application/pdf',
              },
            )
            var fileURL = URL.createObjectURL(newFile)
            window.open(fileURL, '_blank')

            return
          }
          case 'preview_not_save': {
            setActionType(oldActionType.current)
            res = await invoiceReportApi.preview({
              reportId: values.reportId,
              reportType,
            })
            dispatch(setAppSpinning(false))

            alert(reportType)
            let _newFile = new File(
              [res],
              `Biên bản ${InvoiceReportHelper.reportTypeToVnese(
                reportType,
              )}.pdf`,
              {
                type: 'application/pdf',
              },
            )
            var _fileURL = URL.createObjectURL(_newFile)
            window.open(_fileURL, '_blank')

            return
          }
          case 'sign':
            setActionType(oldActionType.current)
            if (oldActionType.current == ACTION_TYPE.CREATE) {
              res = await invoiceReportApi.create({ ...values, reportType })
            } else if (oldActionType.current == ACTION_TYPE.UPDATE) {
              res = await invoiceReportApi.update({ ...values, reportType })
            }
            if (res?.result === 'success') {
              ToastHelper.showSuccess('Lưu biên bản thành công')
              setShowModalConfirmSign(false)

              values.reportId = values.reportId || res.data?.reportId

              if (company?.activeSigningMethod == 'HSM') {
                await invoiceReportApi.hsmReportSign({
                  reportId: values.reportId,
                })
                dispatch(setAppSpinning(false))

                navigate(
                  `/hoa-don-dau-ra/hoa-don/${_reportType}/xem/${invoiceId}`,
                )
              } else {
                const { url } = await toolApi.genToolUrl({
                  param2: 'signReport',
                  param3: values.reportId,
                })
                if (url) {
                  customProtocolCheck(
                    url,
                    () => {
                      dispatch(setAppNotInstalledToolNotiModalShow(true))
                      dispatch(setAppSpinning(false))
                    },
                    () => {
                      Utils.openInCurrentTab(url)
                    },
                    2500,
                  )

                  if (reportType == 'CANCEL') {
                    wsHelperInstance.onExecutingSignCancellationReport(
                      values.reportId,
                      url
                        .replace(process.env.REACT_APP_BASE_URL_SCHEMA, '')
                        .split(',')
                        ?.at(0),
                    )
                  } else {
                    wsHelperInstance.onExecutingSignAdjustReport(
                      values.reportId,
                      url
                        .replace(process.env.REACT_APP_BASE_URL_SCHEMA, '')
                        .split(',')
                        ?.at(0),
                    )
                  }

                  Global.gExecutingSignInvoiceReportTimeOut = setTimeout(() => {
                    dispatch(setAppSpinning(false))
                  }, MAX_WAIT_SIGN_INVOICE)
                }
              }
            } else {
              ToastHelper.showError('Lưu biên bản thất bại')
            }

            return
          default:
            return
        }

        if (res) {
          if (res.result === 'success') {
            ToastHelper.showSuccess('Lưu biên bản thành công')
            navigate(
              '/hoa-don-dau-ra/xu-ly-hoa-don/danh-sach-hoa-don-dieu-chinh',
              { replace: true },
            )
          } else {
            ToastHelper.showError('Lưu biên bản thất bại')
          }
        }
      } catch (err) {
        console.log(err)
        dispatch(setAppSpinning(false))
        if (actionType == 'preview') return
        ToastHelper.showError('Lưu biên bản thất bại')
      }
    },
    enableReinitialize: true,
    validateOnChange: false,
  })

  const onChangeCancellationData = (fieldName, value) => {
    formik.setFieldValue(`${fieldName}`, value)
  }

  const handlePreparePrintCancellationRecord = () => {
    if ([1, 2].includes(invoice?.reportStatus)) {
      setShowModalConfirmPreview(true)
    } else {
      if (!externalAction.includes(actionType)) {
        oldActionType.current = actionType
      }
      setActionType('preview_not_save')
      formik.handleSubmit()
    }
  }

  // button xóa biên bản
  const handleDeleteInvoiceReport = () => {
    if ([1, 2].includes(invoice?.reportStatus)) {
      setShowModalConfirmDelete(true)
    } else {
      if (!externalAction.includes(actionType)) {
        oldActionType.current = actionType
      }
      setActionType('preview_not_save')
      formik.handleSubmit()
    }
  }

  const handlePrintCancellationRecord = () => {
    if (!externalAction.includes(actionType)) {
      oldActionType.current = actionType
    }
    setActionType('preview')
    formik.handleSubmit()
  }

  const handleSignCancellationRecord = () => {
    if (!externalAction.includes(actionType)) {
      oldActionType.current = actionType
    }
    setActionType('sign')
    formik.handleSubmit()
  }

  const { data: banners, isLoading } = useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['banner'],
    queryFn: ({ signal }) =>
      axiosClient.get('/notification-marquee/show', {
        signal,
      }),
  })

  if (isLoading) {
    return null
  }

  return (
    <div className="CreateInvoiceReport">
      <PrivateBaseLayout nonPadding nonPageUpBtn>
        <>
          <div className="invoice-report-container mb-5">
            <div className="invoice-report-paper mx-auto">
              <div className="d-flex flex-column justify-content-center">
                <div className="text-center pt-5">
                  <div
                    className="text-uppercase font-sfpro-bold"
                    style={{ fontSize: '20px', fontWeight: '400' }}
                  >
                    CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                  </div>

                  <div
                    className="font-sfpro-bold"
                    style={{ fontSize: '16px', fontWeight: '400' }}
                  >
                    Độc lập - Tự do - Hạnh phúc
                  </div>
                  <div
                    className="font-sfpro-bold"
                    style={{
                      fontSize: '20px',
                      fontWeight: '700',
                      marginTop: '30px',
                    }}
                  >
                    {`BIÊN BẢN ${InvoiceReportHelper.reportTypeToVnese(
                      reportType,
                    )?.toUpperCase()} ${invoiceClassify === 'PXK' ? 'PHIẾU XUẤT KHO' : 'HÓA ĐƠN'}`}
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-end justify-content-center"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">
                  Số biên bản:
                  <span className="ml-1" style={{ color: 'red' }}>
                    *
                  </span>
                </div>

                <div>
                  <input
                    type="text"
                    className="form-control input-dot"
                    readOnly={screenAction == 'xem'}
                    value={formik.values?.no}
                    onChange={(e) =>
                      onChangeCancellationData('no', e.target.value)
                    }
                    required
                  />
                </div>
              </div>

              <div
                className=" mt-5 "
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">
                  - Căn cứ Nghị định 123/2020/NĐ-CP ngày 19/10/2020 của Chính
                  phủ quy định về hóa đơn, chứng từ.
                </div>
                <div className="invoice-report-label">
                  - Căn cứ Thông tư 78/2021/TT-BTC ngày 17/09/2021 của Bộ Tài
                  chính hướng dẫn thực hiện Nghị định số 123/2020/NĐ-CP ngày 19
                  tháng 10 năm 2020 của Chính phủ quy định về hóa đơn, chứng từ.
                </div>{' '}
                <div className="invoice-report-label">
                  - Căn cứ vào thỏa thuận giữa các bên.
                </div>
              </div>

              <div
                className="d-flex align-items-end justify-content-start"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <DatePicker
                  className="d-flex justify-content-start"
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  customInput={
                    <div className="m-auto">
                      <span className="invoice-date">
                        Hôm nay, ngày{' '}
                        {moment(formik.values.date, 'YYYY-MM-DD').date()} tháng{' '}
                        {moment(formik.values.date, 'YYYY-MM-DD').month() + 1}{' '}
                        năm {moment(formik.values.date, 'YYYY-MM-DD').year()}{' '}
                        chúng tôi gồm có:
                      </span>
                    </div>
                  }
                  // showIcon={true}
                  todayButton={<button>Hôm nay</button>}
                  readOnly={screenAction == 'xem'}
                  // selected={new Date(registrationDate)}
                  onChange={(date) => {
                    const newDate = dayjs(date).format('YYYY-MM-DD HH:mm:ss')
                    formik.setFieldValue('date', newDate)

                    // formik.setFieldValue(
                    //   'date',
                    //   dayjs(
                    //     date,
                    //     'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Indochina Time)',
                    //   ).format('YYYY-MM-DD HH:mm:ss'),
                    // )
                    // changeRegistrationProperty(
                    //   dayjs(
                    //     date,
                    //     'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Indochina Time)',
                    //   ).format('YYYY-MM-DD HH:mm:ss'),
                    // );
                  }}
                />
                {/* <div className="invoice-report-label">
                  Hôm nay, ngày 06 tháng 9 năm 2023 chúng tôi gồm có:
                </div> */}
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label-bold">
                  Bên A (Bên bán):{' '}
                </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  {formik?.values?.sellerName}
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Địa chỉ: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  {formik.values?.sellerFullAddress}
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Mã số thuế: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    {formik.values?.sellerTaxCode}
                  </div>
                </div>
                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Số điện thoại: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    <input
                      type="text"
                      className="form-control input-dot"
                      style={{ flex: 'auto' }}
                      value={formik.values?.sellerPhone}
                      readOnly={screenAction == 'xem'}
                      onChange={(e) =>
                        onChangeCancellationData('sellerPhone', e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Đại diện: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    <input
                      type="text"
                      className="form-control input-dot"
                      style={{ flex: 'auto' }}
                      value={formik.values?.sellerContactPersonName}
                      readOnly={screenAction == 'xem'}
                      onChange={(e) =>
                        onChangeCancellationData(
                          'sellerContactPersonName',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>

                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Chức vụ: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    <input
                      type="text"
                      className="form-control input-dot"
                      style={{ flex: 'auto' }}
                      value={formik.values?.sellerContactPersonPosition}
                      readOnly={screenAction == 'xem'}
                      onChange={(e) =>
                        onChangeCancellationData(
                          'sellerContactPersonPosition',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label-bold">
                  Bên B (Bên mua):{' '}
                </div>
                <div className="flex-grow-1" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={formik.values?.customerName}
                    readOnly={screenAction == 'xem'}
                    onChange={(e) =>
                      onChangeCancellationData('customerName', e.target.value)
                    }
                  />
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Địa chỉ: </div>
                <div className="flex-grow-1" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot w-100"
                    style={{ flex: 'auto' }}
                    value={formik.values?.customerFullAddress}
                    readOnly={screenAction == 'xem'}
                    onChange={(e) =>
                      onChangeCancellationData(
                        'customerFullAddress',
                        e.target.value,
                      )
                    }
                  />
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Mã số thuế: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    <input
                      type="text"
                      className="form-control input-dot"
                      style={{ flex: 'auto' }}
                      value={formik.values?.customerTaxCode}
                      readOnly={screenAction == 'xem'}
                      onChange={(e) =>
                        onChangeCancellationData(
                          'customerTaxCode',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Số điện thoại: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    <input
                      type="text"
                      className="form-control input-dot"
                      style={{ flex: 'auto' }}
                      value={formik.values?.customerPhone}
                      readOnly={screenAction == 'xem'}
                      onChange={(e) =>
                        onChangeCancellationData(
                          'customerPhone',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Đại diện: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    <input
                      type="text"
                      className="form-control input-dot"
                      style={{ flex: 'auto' }}
                      value={formik.values?.customerContactPersonName}
                      readOnly={screenAction == 'xem'}
                      onChange={(e) =>
                        onChangeCancellationData(
                          'customerContactPersonName',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>

                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: '44px', paddingBottom: '8px' }}
                >
                  <div className="invoice-report-label">Chức vụ: </div>
                  <div className="" style={{ flexBasis: 'auto' }}>
                    <input
                      type="text"
                      className="form-control input-dot"
                      style={{ flex: 'auto' }}
                      value={formik.values?.customerContactPersonPosition}
                      readOnly={screenAction == 'xem'}
                      onChange={(e) =>
                        onChangeCancellationData(
                          'customerContactPersonPosition',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">
                  {`Hai bên thống nhất lập biên bản này để ${InvoiceReportHelper.reportTypeToVnese(
                    reportType,
                  )} ${invoiceClassify === 'PXK' ? 'phiếu xuất kho' : 'hóa đơn'} theo quy
                  định.`}
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">{`${invoiceClassify === 'PXK' ? 'Phiếu xuất kho' : 'Hóa đơn'} bị ${InvoiceReportHelper.reportTypeToVnese(
                  reportType,
                )}: `}</div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <div className="invoice-report-label">
                    Ký hiệu{' '}
                    <span className="font-weight-bold">{invoice?.serial}</span>,
                    số <span className="font-weight-bold">{invoice?.no}</span>,
                    ngày{' '}
                    <span className="font-weight-bold">
                      {Utils.formatDate(invoice?.date, 'DD-MM-YYYY')}
                    </span>
                    ,
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">
                  {`Lý do ${InvoiceReportHelper.reportTypeToVnese(
                    reportType,
                  )}: `}
                  <span className="ml-1" style={{ color: 'red' }}>
                    *
                  </span>
                </div>
                <div className="flex-grow-1">
                  <input
                    type="text"
                    className="form-control input-dot"
                    value={formik.values?.reason}
                    readOnly={screenAction == 'xem'}
                    onChange={(e) =>
                      onChangeCancellationData('reason', e.target.value)
                    }
                  />
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                Chúng tôi xin cam đoan các thông tin khai ở trên là hoàn toàn
                chính xác. Nếu có bất kỳ sai sót nào chúng tôi xin chịu trách
                nhiệm trước pháp luật.
              </div>

              <div className="d-flex flex-row justify-content-between align-items-start px-30">
                <div className="text-center">
                  <div>
                    <div className="font-sfpro-bold">Đại diện bên A</div>
                    <div className="mb-4">(Chữ ký số, chữ ký điện)</div>
                    <button
                      className="btn btn-light d-inline-flex align-items-center"
                      style={{ color: '#041847', backgroundColor: '#e4e6eb' }}
                      onClick={() => setShowModalConfirmSign(true)}
                      disabled={![1, 2].includes(invoice?.reportStatus)}
                    >
                      <i
                        className="fal fa-file-contract"
                        style={{ color: '#041847' }}
                      ></i>
                      Ký điện tử
                    </button>
                  </div>
                </div>

                <div className="text-center">
                  <div>
                    <div className="font-sfpro-bold">Đại diện bên B</div>
                    <div className="mb-4">(Chữ ký số, chữ ký điện)</div>
                    {/* <button
                      className="btn btn-light d-inline-flex align-items-center"
                      style={{ color: '#041847', backgroundColor: '#e4e6eb' }}
                    >
                      <i className="fal fa-file-contract" style={{ color: '#041847' }}></i>Ký điện
                      tử
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="registration-footer invoice-report-footer d-flex flex-row justify-content-between align-items-center px-6 py-2 border-top border-secondary"
            style={banners?.data?.length > 0 ? { bottom: '30px' } : {}}
          >
            <button className="btn btn-secondary" onClick={() => navigate(-1)}>
              <i className="fa-sharp fa-regular fa-arrow-left"></i>Quay lại
            </button>
            <div className="d-flex gap-3">
              <button
                className="btn btn-secondary"
                onClick={handlePreparePrintCancellationRecord}
              >
                <i className="fa-regular fa-print"></i>In
              </button>
              <button
                className="btn btn-secondary"
                onClick={handleDeleteInvoiceReport}
              >
                <i className="fa-regular fa-print"></i>Xóa biên bản
              </button>
            </div>

            <div className="d-flex flex-row">
              {screenAction != 'xem' && (
                <button
                  className="btn btn-primary d-flex flex-row align-items-center py-2 mr-2"
                  onClick={formik.handleSubmit}
                >
                  <i className="fa-regular fa-check"></i>Lưu
                </button>
              )}
              {screenAction != 'xem' && (
                <button
                  className="btn btn-primary d-flex flex-row align-items-center py-2"
                  onClick={handleSignCancellationRecord}
                >
                  <i className="fa-regular fa-pen-to-square"></i>Lưu và ký
                </button>
              )}
            </div>
          </div>

          <ModalConfirm
            show={showModalConfirmPreview}
            setShow={setShowModalConfirmPreview}
            header="Xác nhận"
            content="Để thực hiện chức năng này, bạn cần lưu các thông tin trên biên bản. Bạn có muốn lưu không?"
            handleConfirm={handlePrintCancellationRecord}
          />

          <ModalConfirm
            show={showModalConfirmDelete}
            setShow={setShowModalConfirmDelete}
            header="Xác nhận"
            content="Bạn chắc chắn muốn xoá biên bản này?"
            handleConfirm={handleDeleteInvoiceReport}
          />

          <ModalConfirm
            show={showModalConfirmSign}
            setShow={setShowModalConfirmSign}
            header="Xác nhận"
            content={`Để thực hiện ký biên bản ${InvoiceReportHelper.reportTypeToVnese(
              reportType,
            )}, bạn cần lưu các thông tin trên biên bản. Bạn có muốn lưu không?`}
            handleConfirm={handleSignCancellationRecord}
          />
        </>
      </PrivateBaseLayout>
    </div>
  )
}

const BoldText = (text) => {
  return <span className="text-bold">{text}</span>
}
export default CreateInvoiceReport
