import accountApi from 'api/accountApi'
import { useFormik } from 'formik'
import BaseTextField from 'general/components/Forms/BaseTextField'
import AppResource from 'general/constants/AppResource'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import * as Yup from 'yup'

ModalChangePassword.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  handleChange: PropTypes.func,
}

ModalChangePassword.defaultProps = {
  show: false,
  onClose: null,
  handleChange: null,
}

function ModalChangePassword(props) {
  const { show, onClose, handleChange } = props

  function handleClose() {
    formik.handleReset()
    if (onClose) {
      onClose()
    }
  }

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      try {
        const params = {
          ...values,
        }
        delete params.confirmPassword
        params.oldPassword = Utils.sha256(params.oldPassword)
        params.newPassword = Utils.sha256(params.newPassword)
        const res = await accountApi.changePassword(params)
        if (res) {
          ToastHelper.showSuccess('Đổi mật khẩu thành công')
          formik.handleReset()
          handleClose()
        }
      } catch (error) {
        console.log(`Change password error: ${error}`)
      }
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Bạn chưa nhập mật khẩu cũ'),
      newPassword: Yup.string()
        .required('Bạn chưa nhập mật khẩu')
        .min(6, 'Mật khẩu phải chứa ít nhất 6 kí tự')
        .matches(/^\S*$/, 'Mật khẩu không được chứa khoảng trắng'),
      confirmPassword: Yup.string()
        .required('Bạn chưa nhập lại mật khẩu mới')
        .oneOf([Yup.ref('newPassword'), null], 'Mật khẩu không khớp'),
    }),
  })

  function handleChangePass() {
    formik.handleSubmit()
  }

  return (
    <Modal show={show} centered size="md" onHide={handleClose}>
      <Modal.Header className="d-flex align-items-center justify-content-center">
        <Modal.Title>Đổi mật khẩu</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <div>
          <div className="d-flex align-items-center justify-content-center my-6">
            <img src={AppResource.icons.icLock} alt="icon change password" />
          </div>
          <BaseTextField
            label="Mật khẩu cũ"
            placeholder="Nhập mật khẩu"
            name="oldPassword"
            type="password"
            fieldHelper={formik.getFieldHelpers('oldPassword')}
            fieldProps={formik.getFieldProps('oldPassword')}
            fieldMeta={formik.getFieldMeta('oldPassword')}
          />
          <BaseTextField
            label="Mật khẩu mới"
            placeholder="Nhập mật khẩu"
            name="newPassword"
            type="password"
            fieldHelper={formik.getFieldHelpers('newPassword')}
            fieldProps={formik.getFieldProps('newPassword')}
            fieldMeta={formik.getFieldMeta('newPassword')}
          />
          <BaseTextField
            label="Nhập lại mật khẩu mới"
            placeholder="Nhập mật khẩu"
            name="confirmPassword"
            type="password"
            fieldHelper={formik.getFieldHelpers('confirmPassword')}
            fieldProps={formik.getFieldProps('confirmPassword')}
            fieldMeta={formik.getFieldMeta('confirmPassword')}
            className=""
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex row">
          <Button
            className="font-weight-bold flex-grow-1 col mr-3 AppButton ButtonCancel"
            variant="secondary"
            onClick={handleClose}
          >
            {`Huỷ`}
          </Button>
          <Button
            className={`font-weight-bold flex-grow-1 col ml-3 AppButton ButtonSuccess`}
            variant="primary"
            onClick={handleChangePass}
          >
            Lưu
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalChangePassword
