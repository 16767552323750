import { useState } from 'react'
import templateInvoiceApi from 'api/templateInvoiceApi'
import {
  setActivePanel,
  setActiveRegionBaseOnName,
  setSpecifiedDataField,
  setSpecifiedDataFieldProperty,
  setSpecifiedRegion,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DetailControl from './components/DetailControl'
import GeneralControl from './components/GeneralControl'
import LogoControl from './components/LogoControl'
import MainControl from './components/MainControl'
import { setAppSpinning } from 'app/appSlice'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import RouteControl from './components/RouteControl'
import Utils, {
  forCusId,
  formatNumber,
  validateTicket,
} from 'general/utils/Utils'

function RightControl(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  let { templateInvoiceId } = useParams()

  const { className, style, prepareTemplateInvoiceParams } = props

  const { activePanel, activeRegion, configDynamicFieldPanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const {
    templateInvoiceForm,
    templateInvoiceTicketPrice,
    isShowCurrencyUnit,
    templateInvoiceCurrencyUnit,
    vatRateTicket,
  } = useSelector((state) => state.templateInvoice.instance)

  const [showModalConfirmBack, setShowModalConfirmBack] = useState(false)
  const [showModalConfirmSave, setShowModalConfirmSave] = useState(false)

  useEffect(() => {
    if (activePanel !== 'detail_ctrl') {
      dispatch(setActiveRegionBaseOnName(null))
    }
  }, [activePanel])

  useEffect(() => {
    if (templateInvoiceForm !== undefined) {
      dispatch(
        setSpecifiedDataField({
          regionName: 'invoiceInfo',
          dataFieldName: 'invoiceCode',
          propertyData: { show: !!templateInvoiceForm },
        }),
      )
    }
  }, [templateInvoiceForm])

  /* hiển thị tổng tiền */
  useEffect(() => {
    // if (!_.isNull(templateInvoiceTicketPrice)) {
    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'ticketPrice',
        dataFieldName: 'ticketPrice',
        propertyName: 'value',
        propertyData: {
          value:
            (Utils.isValidNumber(templateInvoiceTicketPrice)
              ? formatNumber(templateInvoiceTicketPrice)
              : '') +
            (isShowCurrencyUnit
              ? ' ' + (templateInvoiceCurrencyUnit || '')
              : ''),
        },
      }),
    )
    // }
  }, [
    templateInvoiceTicketPrice,
    isShowCurrencyUnit,
    templateInvoiceCurrencyUnit,
  ])

  useEffect(() => {
    if (!vatRateTicket && vatRateTicket != 0) {
      dispatch(
        setSpecifiedDataFieldProperty({
          regionName: 'ticketPrice',
          dataFieldName: 'ticketPriceBeforeVAT',
          propertyName: 'value',
          propertyData: {
            value: isShowCurrencyUnit
              ? ' ' + (templateInvoiceCurrencyUnit || '')
              : '',
          },
        }),
      )

      dispatch(
        setSpecifiedDataFieldProperty({
          regionName: 'ticketPrice',
          dataFieldName: 'vatPrice',
          propertyName: 'value',
          propertyData: {
            value: isShowCurrencyUnit
              ? ' ' + (templateInvoiceCurrencyUnit || '')
              : '',
          },
        }),
      )
      return
    }

    let vatRate, ticketPriceBeforeVAT, vatPrice
    if (Utils.isValidNumber(vatRateTicket)) {
      vatRate = parseInt(vatRateTicket)
      ticketPriceBeforeVAT = Math.round(
        (templateInvoiceTicketPrice / (100 + vatRate)) * 100,
      )
      vatPrice = Math.round((ticketPriceBeforeVAT * vatRate) / 100)
    } else {
      vatRate = 'X'
      ticketPriceBeforeVAT = templateInvoiceTicketPrice
      vatPrice = 'X'
    }

    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'ticketPrice',
        dataFieldName: 'vatRate',
        propertyName: 'value',
        propertyData: {
          value: Utils.isValidNumber(vatRate) ? vatRate + '%' : vatRate,
        },
      }),
    )

    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'ticketPrice',
        dataFieldName: 'ticketPriceBeforeVAT',
        propertyName: 'value',
        propertyData: {
          value:
            formatNumber(ticketPriceBeforeVAT) +
            (isShowCurrencyUnit
              ? ' ' + (templateInvoiceCurrencyUnit || '')
              : ''),
        },
      }),
    )

    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'ticketPrice',
        dataFieldName: 'vatPrice',
        propertyName: 'value',
        propertyData: {
          value:
            formatNumber(vatPrice) +
            (isShowCurrencyUnit
              ? ' ' + (templateInvoiceCurrencyUnit || '')
              : ''),
        },
      }),
    )
  }, [isShowCurrencyUnit, vatRateTicket, templateInvoiceTicketPrice])

  const createTemplateInvoiceHandler = async () => {
    let params = await prepareTemplateInvoiceParams()
    const checkParams = validateTicket(params)
    if (checkParams.status) {
      dispatch(setAppSpinning(true))
      var res
      try {
        res = await templateInvoiceApi.createTemplate(params)
      } catch (e) {
        console.log('e', e)
        if (e?.response?.data?.idForcus) {
          await dispatch(setActivePanel('gen_ctrl'))
          forCusId(e?.response?.data?.idForcus)
        }
      } finally {
        dispatch(setAppSpinning(false))
      }

      if (res?.template) {
        ToastHelper.showSuccess('Tạo mẫu vé thành công!')
        navigate('/hoa-don-dau-ra/tem-ve/mau-tem-ve')
      }
    } else {
      await dispatch(setActivePanel('gen_ctrl'))
      checkParams?.data.forEach(function (item) {
        ToastHelper.showError(item)
      })
    }
  }

  const updateTemplateInvoiceHandler = async () => {
    let params = await prepareTemplateInvoiceParams()

    dispatch(setAppSpinning(true))
    var res
    try {
      res = await templateInvoiceApi.updateTemplate(templateInvoiceId, params)
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }
    if (res) {
      ToastHelper.showSuccess('Sửa mẫu hóa đơn thành công!')
      navigate('/hoa-don-dau-ra/tem-ve/mau-tem-ve')
    }
  }

  const handleConfirmBack = () => {
    history.back()
  }

  const handleConfirmSave = () => {
    updateTemplateInvoiceHandler()
  }

  return (
    <div className={`${className} RightControl`} style={style}>
      <div className="d-flex flex-column justify-content-between h-100">
        {(function () {
          switch (activePanel) {
            case 'main_ctrl':
              return <MainControl />
            case 'gen_ctrl':
              return <GeneralControl style={{ flex: '1 1 auto' }} />
            case 'logo_ctrl':
              return <LogoControl style={{ flex: '1 1 auto' }} />
            case 'detail_ctrl':
              return <DetailControl style={{ flex: '1 1 auto' }} />
            case 'route_ctrl':
              return <RouteControl style={{ flex: '1 1 auto' }} />
            default:
              return <MainControl />
          }
        })()}

        <div className="" style={{ flex: '0 1 auto' }}>
          {['tableDetail', 'tableDetail1'].includes(activeRegion?.regionName) &&
            !configDynamicFieldPanel && (
              <div className="d-flex flex-row justify-content-between align-items-center bg-secondary p-2 mb-2">
                <input
                  className="cursor-pointer"
                  type="checkbox"
                  defaultChecked
                  style={{ width: '1.5rem', height: '1.5rem' }}
                />
                <p className="mb-0">
                  Số dòng hàng hóa dịch vụ hiển thị tối thiểu
                </p>
                <input
                  type="number"
                  className="form-control number-input"
                  value={activeRegion?.minItemRows}
                  min={1}
                  max={20}
                  onChange={(e) => {
                    if (
                      e.target.value < 1 ||
                      e.target.value > 20 ||
                      !e.target.value
                    )
                      return
                    dispatch(
                      setSpecifiedRegion({
                        regionName: activeRegion?.regionName,
                        propertyData: { minItemRows: parseInt(e.target.value) },
                      }),
                    )
                  }}
                />
              </div>
            )}

          {!configDynamicFieldPanel && (
            <div className="d-flex flex-row justify-content-between">
              {[
                'surround_sel',
                'background_sel',
                'background_surround_sel',
              ].includes(activePanel) ? (
                <>
                  <button
                    type="button"
                    className="px-10 py-2 btn btn-outline-secondary"
                    onClick={() => dispatch(setActivePanel('logo_ctrl'))}
                  >
                    Hủy
                  </button>

                  <button
                    type="button"
                    className="px-10 py-2 btn btn-primary"
                    onClick={() => dispatch(setActivePanel('logo_ctrl'))}
                  >
                    Chọn
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="px-10 py-2 btn btn-outline-secondary"
                    onClick={() => setShowModalConfirmBack(true)}
                  >
                    Hủy bỏ
                  </button>

                  <button
                    type="button"
                    className="px-10 py-2 btn btn-primary"
                    onClick={
                      pathname.includes('/tao/')
                        ? createTemplateInvoiceHandler
                        : pathname.includes('/sua/')
                          ? () => setShowModalConfirmSave(true)
                          : null
                    }
                  >
                    Lưu
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <ModalConfirm
        show={showModalConfirmBack}
        setShow={setShowModalConfirmBack}
        header="Dữ liệu đã thay đổi"
        content="Dữ liệu đã bị thay đổi. Bạn chắc chắn vẫn muốn thoát?"
        handleConfirm={handleConfirmBack}
      />

      <ModalConfirm
        show={showModalConfirmSave}
        setShow={setShowModalConfirmSave}
        header="Xác nhận"
        content="Khi sửa mẫu hóa đơn, những nội dung thay đổi trên mẫu sẽ được cập nhật trên tất cả các hóa đơn đã phát hành thuộc mẫu hóa đơn này. Bạn có muốn tiếp tục sửa không?"
        handleConfirm={handleConfirmSave}
      />
    </div>
  )
}

export default RightControl
