import FormLabel from 'general/components/Forms/FormLabel'
import InputNumber from 'general/components/Forms/InputNumber'

function FinalMoneyDisplay(props) {
  const { formik, partsName, autoCalculate, isAdjustInvoice } = props

  return (
    <div className="d-flex flex-column gap-2 max-w-100">
      <div className="d-flex flex-row align-items-center flex-grow-1">
        <FormLabel width="160px">Tổng tiền hàng:</FormLabel>
        <div className="d-flex flex-row flex-grow-1" style={{ gap: '10px' }}>
          <InputNumber
            name="total"
            systemConfigType={
              !['VNĐ', 'VND'].includes(formik.values.currency)
                ? 'exchangeTotalCost'
                : 'totalCost'
            }
            fieldProps={formik.getFieldProps('total')}
            fieldMeta={formik.getFieldMeta('total')}
            fieldHelper={formik.getFieldHelpers('total')}
            disabled={!autoCalculate ? false : true}
            // disabled={true}
            allowNegative={true}
            className="w-100"
          />
          {!['VNĐ', 'VND'].includes(formik.values.currency) && (
            <InputNumber
              name="exchangedTotal"
              systemConfigType="currencyExchange"
              fieldProps={formik.getFieldProps('exchangedTotal')}
              fieldMeta={formik.getFieldMeta('exchangedTotal')}
              fieldHelper={formik.getFieldHelpers('exchangedTotal')}
              disabled={!autoCalculate ? false : true}
              className="w-100"
              allowNegative={true}
            />
          )}
        </div>
      </div>
      {formik.values.discountType !== 0 ? (
        <div className="d-flex flex-row align-items-center">
          <FormLabel width="160px">Tổng tiền chiết khấu:</FormLabel>
          <div className="d-flex flex-row flex-grow-1" style={{ gap: '10px' }}>
            <InputNumber
              name="discountMoney"
              systemConfigType="totalCost"
              fieldProps={formik.getFieldProps('discountMoney')}
              fieldMeta={formik.getFieldMeta('discountMoney')}
              fieldHelper={formik.getFieldHelpers('discountMoney')}
              disabled={!autoCalculate ? false : true}
              className="w-100"
              allowNegative={true}
            />
            {!['VNĐ', 'VND'].includes(formik.values.currency) && (
              <InputNumber
                systemConfigType="totalCost"
                name="exchangedDiscountMoney"
                fieldProps={formik.getFieldProps('exchangedDiscountMoney')}
                fieldMeta={formik.getFieldMeta('exchangedDiscountMoney')}
                fieldHelper={formik.getFieldHelpers('exchangedDiscountMoney')}
                disabled={!autoCalculate ? false : true}
                className="w-100"
                allowNegative={true}
              />
            )}
          </div>
        </div>
      ) : null}

      {parseInt(formik.values.serial?.charAt(0)) === 2 ? (
        <div className="d-flex flex-row align-items-center">
          <FormLabel width="160px">Tổng tiền thuế được giảm:</FormLabel>
          <div className="d-flex flex-row flex-grow-1" style={{ gap: '10px' }}>
            <InputNumber
              name="taxReduction"
              systemConfigType="totalCost"
              fieldProps={formik.getFieldProps('taxReduction')}
              fieldMeta={formik.getFieldMeta('taxReduction')}
              fieldHelper={formik.getFieldHelpers('taxReduction')}
              disabled={!autoCalculate ? false : true}
              className="w-100"
              allowNegative={true}
            />
            {!['VNĐ', 'VND'].includes(formik.values.currency) && (
              <InputNumber
                systemConfigType="ratio"
                name="exchangedTotalTaxReduction"
                fieldProps={formik.getFieldProps('exchangedTotalTaxReduction')}
                fieldMeta={formik.getFieldMeta('exchangedTotalTaxReduction')}
                fieldHelper={formik.getFieldHelpers(
                  'exchangedTotalTaxReduction',
                )}
                disabled={!autoCalculate ? false : true}
                className="w-100"
                allowNegative={true}
              />
            )}
          </div>
        </div>
      ) : null}

      <div className="d-flex flex-row align-items-center">
        {parseInt(formik.values?.serial?.charAt(0)) === 1 && (
          <>
            <FormLabel width="160px">Tiền thuế GTGT:</FormLabel>
            <div
              className="d-flex flex-row flex-grow-1"
              style={{ gap: '10px' }}
            >
              <InputNumber
                name="taxMoney"
                systemConfigType={
                  !['VNĐ', 'VND'].includes(formik.values.currency)
                    ? 'exchangeTotalCost'
                    : 'totalCost'
                }
                fieldProps={formik.getFieldProps('taxMoney')}
                fieldMeta={formik.getFieldMeta('taxMoney')}
                fieldHelper={formik.getFieldHelpers('taxMoney')}
                disabled={
                  isAdjustInvoice
                    ? autoCalculate && formik.values.taxRate !== 'KHAC'
                      ? true
                      : false
                    : formik.values.taxRate === 'KHAC' || !autoCalculate
                      ? false
                      : true
                }
                className="w-100"
                allowNegative={true}
              />
              {!['VNĐ', 'VND'].includes(formik.values.currency) && (
                <InputNumber
                  name="exchangedTaxMoney"
                  systemConfigType="currencyExchange"
                  fieldProps={formik.getFieldProps('exchangedTaxMoney')}
                  fieldMeta={formik.getFieldMeta('exchangedTaxMoney')}
                  fieldHelper={formik.getFieldHelpers('exchangedTaxMoney')}
                  disabled={!autoCalculate ? false : true}
                  className="w-100"
                  allowNegative={true}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="d-flex flex-row align-items-center">
        <FormLabel width="160px" fontWeight="700">
          Tổng tiền thanh toán:
        </FormLabel>
        <div className="d-flex flex-row" style={{ gap: '10px' }}>
          <InputNumber
            name="totalAfterTax"
            systemConfigType={
              !['VNĐ', 'VND'].includes(formik.values.currency)
                ? 'exchangeTotalCost'
                : 'totalCost'
            }
            fieldProps={formik.getFieldProps('totalAfterTax')}
            fieldMeta={formik.getFieldMeta('totalAfterTax')}
            fieldHelper={formik.getFieldHelpers('totalAfterTax')}
            disabled={!autoCalculate ? false : true}
            className="w-100"
            fontWeight="700"
            allowNegative={true}
          />

          {!['VNĐ', 'VND'].includes(formik.values.currency) && (
            <InputNumber
              name="exchangedTotalAfterTax"
              systemConfigType="currencyExchange"
              fieldProps={formik.getFieldProps('exchangedTotalAfterTax')}
              fieldMeta={formik.getFieldMeta('exchangedTotalAfterTax')}
              fieldHelper={formik.getFieldHelpers('exchangedTotalAfterTax')}
              disabled={!autoCalculate ? false : true}
              className="w-100"
              fontWeight="700"
              allowNegative={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FinalMoneyDisplay
