// import lifeStyleApi from 'api/lifeStyleApi';
import companyTitleApi from 'api/companyTitleApi'
import { thunkGetCompanyTitleList } from 'features/Category/categorySlice'
import { FastField, Formik } from 'formik'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import KTFormTextArea from 'general/components/Forms/KTFormTextArea'
import VIToggleSwitch from 'general/components/eInvoiceComponents/VIToggleSwitch'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

ModalEditCompanyTitle.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onRefreshCompanyTitleList: PropTypes.func,
  companyTitleItem: PropTypes.object,
  active: PropTypes.string,
  parentCompanyTitle: PropTypes.object,
}

ModalEditCompanyTitle.defaultProps = {
  show: false,
  onClose: null,
  onRefreshCompanyTitleList: null,
  companyTitleItem: null,
  active: 'ADD',
  parentCompanyTitle: {},
}

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * onRefreshCompanyTitleList: function,
 * companyTitleItem: object,
 * active: string,
 * }} props
 * @returns
 */
function ModalEditCompanyTitle(props) {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { show, onClose, companyTitleItem, active } = props

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose()
    }
  }

  // Request create new money type
  async function requestCreateCompanyTitle(values) {
    let params = { ...values }

    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(companyTitleItem)) {
      params.companyTitleId = ''
    }

    const res = await companyTitleApi.create(params)
    const { result, companyTitle } = res
    if (result === 'success') {
      ToastHelper.showSuccess(`Thêm chức danh ${params.name} thành công`)
      dispatch(thunkGetCompanyTitleList(Global.gFiltersCompanyTitleList))
      handleClose()
    }
  }

  // Request replicate money type
  async function requestReplicateCompanyTitle(values) {
    let params = { ...values }

    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(companyTitleItem)) {
      params.companyTitleId = ''
    }

    const res = await companyTitleApi.create(params)
    const { result, companyTitle } = res
    if (result === 'success') {
      ToastHelper.showSuccess(`Nhân bản chức danh ${params.name} thành công`)
      dispatch(thunkGetCompanyTitleList(Global.gFiltersCompanyTitleList))
      handleClose()
    }
  }

  // Request update money type
  async function requestUpdateCompanyTitle(values) {
    let params = { ...values }
    if (_.isNull(companyTitleItem)) {
      params.companyTitleId = ''
    }
    const res = await companyTitleApi.update(params)
    const { result, companyTitle } = res
    if (result === 'success') {
      ToastHelper.showSuccess(
        `Cập nhật thông tin chức danh ${companyTitleItem.name} thành công`,
      )
      dispatch(thunkGetCompanyTitleList(Global.gFiltersCompanyTitleList))
      handleClose()
    } else {
      ToastHelper.showError(
        `Cập nhật thông tin chức danh ${companyTitleItem.name} thành công`,
      )
    }
  }

  // MARK --- Hooks ---

  return (
    <div>
      <Formik
        initialValues={{
          companyTitleId:
            active === 'ADD'
              ? ''
              : companyTitleItem
                ? companyTitleItem.companyTitleId
                : '',
          description:
            active === 'ADD'
              ? ''
              : companyTitleItem
                ? companyTitleItem.description
                : '',
          name:
            active === 'ADD'
              ? ''
              : companyTitleItem
                ? companyTitleItem.name
                : '',
          status:
            active === 'ADD'
              ? 'ACTIVE'
              : companyTitleItem
                ? companyTitleItem.status
                : 'ACTIVE',
        }}
        validationSchema={Yup.object({
          name: Yup.string().trim().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={(values) => {
          switch (active) {
            case 'ADD':
              requestCreateCompanyTitle(values)
              break
            case 'EDIT':
              requestUpdateCompanyTitle(values)
              break
            case 'REPLICATE':
              requestReplicateCompanyTitle(values)
              break
            default:
              requestReplicateCompanyTitle(values)
              break
          }
        }}
      >
        {(formikProps) => (
          <>
            <Modal
              className=""
              show={show}
              backdrop="static"
              size="lg"
              onHide={handleClose}
              centered
              onExit={() => {
                formikProps.handleReset()
              }}
              onExited={() => {
                // handleExistDone();
                // formikProps.handleReset();
              }}
            >
              <Modal.Header className="px-5 py-5">
                <Modal.Title>
                  {active === 'ADD'
                    ? 'Thêm chức danh'
                    : active === 'EDIT'
                      ? 'Chỉnh sửa thông tin chức danh'
                      : 'Nhân bản chức danh'}
                </Modal.Title>
                <div
                  className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                  onClick={() => {
                    handleClose()
                    formikProps.handleReset()
                  }}
                >
                  <i className="far fa-times"></i>
                </div>
              </Modal.Header>

              <Modal.Body
                className="overflow-auto bg-light"
                style={{
                  maxHeight: '70vh',
                }}
              >
                <div className="row">
                  {/* ten chuc danh */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Tên chức danh')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="name"
                      inputElement={
                        <FastField name="name">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Tên chức danh'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* ten loai tien */}
                  <div className="">
                    <KTFormGroup
                      label={<>{t('Mô tả')}</>}
                      inputName="description"
                      inputElement={
                        <FastField name="description">
                          {({ field, form, meta }) => (
                            <KTFormTextArea
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              rows={5}
                              placeholder={`${_.capitalize(t('Mô tả'))}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="w-100 d-flex flex-wrap gap-3 justify-content-between">
                  <div>
                    <FastField name="status">
                      {({ field, form, meta }) => (
                        <VIToggleSwitch
                          label={
                            field.value === 'ACTIVE'
                              ? t('Using')
                              : t('StopUsing')
                          }
                          status={field.value === 'ACTIVE' ? true : false}
                          name={field.name}
                          onSwitch={(checked) => {
                            if (checked) {
                              form.setFieldValue(field.name, 'ACTIVE')
                            } else {
                              form.setFieldValue(field.name, 'INACTIVE')
                            }
                          }}
                        />
                      )}
                    </FastField>
                  </div>

                  <div className="d-flex">
                    <Button
                      className="font-weight-bold  mr-3"
                      variant="secondary"
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      {t('Close')}
                    </Button>

                    <Button
                      className="font-weight-bold "
                      variant="primary"
                      onClick={() => {
                        formikProps.handleSubmit()
                      }}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  )
}

export default ModalEditCompanyTitle
