import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import ChooseTemplateTicket from './screens/ChooseTemplateTicket'
import CreateTemplateTicket from './screens/CreateTemplateTicket'
import ListTemplateTicket from './screens/ListTemplateTicket'

function TemplateTicket() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const { listFunctions, userFunctions } = useAppSelector(
    (state) => state?.function,
    shallowEqual,
  )

  const [isViewTemplateInvoice] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'TEMPLATE_INVOICE_VIEW_LIST',
        },
      ],
      userFunctions,
      listFunctions,
    )
  }, [listFunctions, userFunctions])
  return (
    <Routes>
      <Route
        path=""
        element={
          <ProtectedRoute permission={isViewTemplateInvoice}>
            <ListTemplateTicket />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tao/:templateInvoiceId"
        element={<CreateTemplateTicket />}
      />

      <Route path="/chon" element={<ChooseTemplateTicket />} />

      <Route
        path="/sua/:templateInvoiceId"
        element={<CreateTemplateTicket />}
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default TemplateTicket
