import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setShowModalExportTicket } from 'features/TemplateInvoice/templateInvoiceSlice'
import { useSelector } from 'react-redux'

function CreateTemplateTicketHeader(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { style } = props

  const { templateInvoiceName, templateInvoiceSeries } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  return (
    <div
      className="CreateTemplateTicketHeader m-auto"
      style={{ ...style, maxWidth: 1265, minWidth: 1265 }}
    >
      <div className="px-4 py-2 d-flex flex-row justify-content-between align-items-center">
        <div className="position-relative">
          <h3 className="static-title mb-1">Thiết lập mẫu vé</h3>
          {templateInvoiceName && templateInvoiceSeries && (
            <p className="mb-0 dynamic-title">{`${templateInvoiceName} (${templateInvoiceSeries})`}</p>
          )}
          {/* <button
            className="btn btn-icon position-absolute"
            style={{ top: '10%', right: '100%' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fas fa-arrow-left" style={{ color: '#000000' }}></i>
          </button> */}
        </div>

        <div>
          <button
            className="btn btn-secondary d-flex flex-row align-items-center"
            style={{ fontSize: '1.1rem', fontWeight: 400 }}
            onClick={() => dispatch(setShowModalExportTicket(true))}
          >
            <i className="fa-solid fa-share-from-square mr-1"></i>
            <p className="mb-0">Xuất mẫu</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateTemplateTicketHeader
