import './style.scss'

function index() {
  return (
    <>
      <div className="h-50 container d-flex justify-content-center align-items-center text-center gap-3 flex-column">
        <i className="fas fa-user-slash fa-7x"></i>
        <div className="fs-4 fw-bold">
          Chỉ quản trị viên có quyền "Quản lý người dùng"
          <br />
          Người dùng không có quyền trong "Quản lý người dùng"
        </div>
      </div>
    </>
  )
}

export default index
