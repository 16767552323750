import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import { useFormik } from 'formik'
import CustomSelect from 'general/components/CustomSelect'
import FormLabel from 'general/components/Forms/FormLabel'
import InputNumberNoFormik from 'general/components/Forms/InputNumerNoFormik'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import './styles.scss'

ModalTicketTaxRateDetail.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  handleConfirm: PropTypes.func,
  ticketTemplate: PropTypes.object,
}

function ModalTicketTaxRateDetail(props) {
  const { show, setShow, header, handleConfirm, ticketTemplate = {} } = props

  const { nameTicket } = ticketTemplate

  const { VATConfig } = useSelector((state) => state?.systemConfig)

  const systemTaxRate = useMemo(() => {
    return VATConfig?.value?.replace('%', '') ?? '0'
  }, [VATConfig])

  const formik = useFormik({
    initialValues: {
      taxRate: systemTaxRate,
      taxMoney: 0,
      price: 0,
      totalPrice: 0,
    },
    // validationSchema: ValidationSchema.createNewInvoice,
    onSubmit: async (values) => {},
    enableReinitialize: true,
    validateOnChange: true,
  })

  useEffect(() => {
    const { taxRate } = formik.values
    const price = Utils.parseFormattedNumberToFloat(formik.values.price)
    const taxMoney = Utils.calculateTaxMoney(price, taxRate)
    const totalPrice = price + taxMoney
    formik.setFieldValue('taxMoney', taxMoney)
    formik.setFieldValue('totalPrice', totalPrice)
  }, [formik.values.taxRate, formik.values.price])

  let handleClose = () => setShow(false)

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="ModalTicketTaxRateDetail"
      className="modal-ticket-taxrate-detail"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{header}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div
          className="w-100 d-flex flex-column p-4"
          style={{ backgroundColor: '#edeff2', gap: '10px' }}
        >
          <div className="d-flex flex-row align-items-center">
            <span style={{ fontSize: '14px', fontWeight: '600' }}>
              {nameTicket}
            </span>
          </div>
          <div className="d-flex flex-row">
            <div
              className="d-flex flex-column align-items-center"
              style={{ gap: '8px' }}
            >
              <FormLabel>Thuế GTGT:</FormLabel>
              <FormLabel>Tổng tiền:</FormLabel>
            </div>
            <div className="d-flex flex-column">
              <CustomSelect
                selectOptions={SelectOptions.GTGT}
                menuParentRef={document.querySelector(
                  '.modal-ticket-taxrate-detail',
                )}
                currentValue={formik.values.taxRate}
                onChange={(selectedOption) => {
                  formik.setFieldValue('taxRate', selectedOption.value)
                }}
                haveMenuParentRef={false}
              />
              <div style={{ height: '40px' }}>
                <InputNumberNoFormik
                  value={Utils.parseFormattedNumberToFloat(formik.values.price)}
                  systemConfigType="totalAmount"
                  textAlign={'right'}
                  onBlur={(e) => {
                    let price = Utils.parseFormattedNumberToFloat(
                      e.target.value,
                    )
                    formik.setFieldValue('price', price)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-4 px-8"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>
          <button
            className="btn btn-primary px-8"
            onClick={() => {
              let { taxRate, price } = formik.values
              price = Utils.parseFormattedNumberToFloat(formik.values.price)
              if (_.isUndefined(price) || _.isNull(price) || _.isNaN(price)) {
                ToastHelper.showError('Vui lòng nhập tổng tiền')
                return
              }
              handleConfirm({ ticketTemplate, ...formik.values })
            }}
          >
            Đồng ý
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalTicketTaxRateDetail
