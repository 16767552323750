import AppResource from 'general/constants/AppResource'
import { env } from 'general/utils/env'
import $ from 'jquery'
import { useEffect } from 'react'

export default function useProjectBranding() {
  const partner = env.PROJECT_TYPE

  useEffect(() => {
    document.title = `${
      partner === 'TULUC'
        ? 'TỰ LỰC'
        : partner === 'SONPHAT'
          ? 'SƠN PHÁT'
          : 'VIETINVOICE'
    } - Giải pháp hóa đơn điện tử `
  }, [partner])

  useEffect(() => {
    const favicons = [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href:
          partner === 'TULUC'
            ? AppResource.branding.tuluc_ico
            : partner === 'SONPHAT'
              ? AppResource.branding.sonphat_ico
              : AppResource.branding.vietinvoice_ico,
      },
    ]

    // Remove existing favicons
    $('link[rel="icon"], link[rel="manifest"]').remove()

    // Create and append new favicon elements
    favicons.forEach((favicon) => {
      const link = document.createElement('link')
      link.rel = favicon.rel
      if (favicon.type) link.type = favicon.type
      link.href = favicon.href
      document.head.appendChild(link)
    })
  }, [partner])

  return {
    logo:
      partner === 'TULUC'
        ? AppResource.branding.tuluc_logo
        : partner === 'SONPHAT'
          ? AppResource.branding.sonphat_logo
          : AppResource.images.img_ICORP_logo,
  }
}
