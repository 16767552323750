/* eslint-disable no-undef */
import icActiveAccount from 'assets/icons/ic_active_account.png'
import icEmptyBox from 'assets/icons/ic_empty_box.svg'
import icLock from 'assets/icons/ic_lock.png'
import icPageUp from 'assets/icons/ic_page_up.png'
import icTrash from 'assets/icons/ic_trash.png'
// icons logo
import keenLogo1 from 'assets/icons/Keens/Logo/logo-1.svg'
import keenLogo2 from 'assets/icons/Keens/Logo/logo-2.svg'
import keenLogo3 from 'assets/icons/Keens/Logo/logo-3.svg'
import keenLogo4 from 'assets/icons/Keens/Logo/logo-4.svg'
import keenLogo5 from 'assets/icons/Keens/Logo/logo-5.svg'
import keenLogo6 from 'assets/icons/Keens/Logo/logo-6.svg'
import keenLogo7 from 'assets/icons/Keens/Logo/logo-7.svg'
// icons text
import keenIcToggleRight from 'assets/icons/Keens/Text/Toggle-Right.svg'
// icons general
import keenIcSearch from 'assets/icons/Keens/General/Search.svg'
import keenIcShieldCheck from 'assets/icons/Keens/General/Shield-check.svg'
import keenIcUser from 'assets/icons/Keens/General/User.svg'
// icons avatar
import keenIcAvatarBoy from 'assets/icons/Keens/Avatars/001-boy.svg'
import keenIcAvatarGirl from 'assets/icons/Keens/Avatars/002-girl.svg'
// icons flag
import keenIcFlagVi from 'assets/icons/Keens/Flags/220-vietnam.svg'
import keenIcFlagEn from 'assets/icons/Keens/Flags/226-united-states.svg'
// icons code
import keenIcCmd from 'assets/icons/Keens/Code/CMD.svg'
import keenIcCompiling from 'assets/icons/Keens/Code/Compiling.svg'
import keenIcLockOverturning from 'assets/icons/Keens/Code/Lock-overturning.svg'
// icons media
import keenIcEqualizer from 'assets/icons/Keens/Media/Equalizer.svg'
// icons layout
import keenIcLayout4Blocks from 'assets/icons/Keens/Layout/Layout-4-blocks.svg'
import keenIcLayoutPolygon from 'assets/icons/Keens/Layout/Layout-polygon.svg'
// icons communication
import keenIcCommunicationChatLocked from 'assets/icons/Keens/Communication/Chat-locked.svg'
import keenIcCommunicationChat from 'assets/icons/Keens/Communication/Chat6.svg'
import keenIcCommunicationClipboardCheck from 'assets/icons/Keens/Communication/Clipboard-check.svg'
import keenIcCommunicationGroupChat from 'assets/icons/Keens/Communication/Group-chat.svg'
import keenIcCommunicationGroup from 'assets/icons/Keens/Communication/Group.svg'
import keenIcCommunicationMailAt from 'assets/icons/Keens/Communication/Mail-at.svg'
import keenIcCommunicationMailBox from 'assets/icons/Keens/Communication/Mail-box.svg'
import keenIcCommunicationOutgoingBox from 'assets/icons/Keens/Communication/Outgoing-box.svg'
import keenIcCommunicationSafeChat from 'assets/icons/Keens/Communication/Safe-chat.svg'
import keenIcCommunicationSend from 'assets/icons/Keens/Communication/Send.svg'
import keenIcCommunicationSpam from 'assets/icons/Keens/Communication/Spam.svg'
import keenIcCommunicationThumbtack from 'assets/icons/Keens/Communication/Thumbtack.svg'
// icons clothes
import keenIcBriefcase from 'assets/icons/Keens/Clothes/Briefcase.svg'
import keenIcCrown from 'assets/icons/Keens/Clothes/Crown.svg'
// icons navigations
import keenIcNavigationArrowLeft from 'assets/icons/Keens/Navigation/Arrow-left.svg'
import keenIcNavigationUp from 'assets/icons/Keens/Navigation/Up-2.svg'
// icons tools
import keenIcToolCompass from 'assets/icons/Keens/Tools/Compass.svg'
// icons design
import keenIcDesignFlatten from 'assets/icons/Keens/Design/Flatten.svg'
// icons home
import keenIcLibrary from 'assets/icons/Keens/Home/Library.svg'
// icons files
import keenIcFilePlus from 'assets/icons/Keens/Files/File-plus.svg'
import keenIcUserBlank from 'assets/icons/Keens/Users/blank.png'
import imgEInvoiceProcessBG from 'assets/images/img_einvoice_process_bg.png'
// taxDeucation
import keenTaxDuctionborder from 'assets/images/taxDeduction/line.a2986ba1.svg'
import keenTaxDuctionView from 'assets/images/taxDeduction/icon-view.98396d04.svg'
import keenTaxDuction1 from 'assets/images/taxDeduction/GuiNguoiNopThue.ed63eecd.svg'
import keenTaxDuction2 from 'assets/images/taxDeduction/KhoiTaoMau.05cdae3e.svg'
import keenTaxDuction3 from 'assets/images/taxDeduction/LapBangKe.71cb3372.svg'
import keenTaxDuction4 from 'assets/images/taxDeduction/LapChungTu.70c0a28c.svg'
import keenTaxDuction5 from 'assets/images/taxDeduction/LapHoSoDangKy.020744d5.svg'
import keenTaxDuction6 from 'assets/images/taxDeduction/NopCoQuanThue.597b198b (1).svg'
import keenTaxDuction7 from 'assets/images/taxDeduction/NopCoQuanThue.597b198b.svg'
import keenTaxDuction8 from 'assets/images/taxDeduction/ThongBaoPhatHanhMau.97685d93.svg'
//taxDeucationDocuments
import keentddDelete from 'assets/images/taxDeductionDocuments/ico-delete-btn-white.a78eaa4a.svg'
import keentddEdit from 'assets/images/taxDeductionDocuments/ico-edit-btn-white.7b3084bc.svg'
import keentddTopLogo from 'assets/images/taxDeductionDocuments/logo-top.879e6d5a.svg'
import keentddBottomLogo from 'assets/images/taxDeductionDocuments/logo-under.516ccebb.svg'
//taxDeucationDocuments - background
import keentddborderBackground1 from 'assets/images/taxDeductionDocuments/background/borderBackground1.svg'
import keentddborderBackground2 from 'assets/images/taxDeductionDocuments/background/borderBackground2.svg'
import keentddborderBackground3 from 'assets/images/taxDeductionDocuments/background/borderBackground3.svg'
import keentddborderBackground4 from 'assets/images/taxDeductionDocuments/background/borderBackground4.svg'
import keentddborderBackground5 from 'assets/images/taxDeductionDocuments/background/borderBackground5.svg'
import keentddborderBackground6 from 'assets/images/taxDeductionDocuments/background/borderBackground6.svg'
import keentddborderBackground7 from 'assets/images/taxDeductionDocuments/background/borderBackground7.svg'
//taxDeucationDocuments - backgroundContent
import keentddcontentBackground1 from 'assets/images/taxDeductionDocuments/background/contentBackground1.svg'
import keentddcontentBackground2 from 'assets/images/taxDeductionDocuments/background/contentBackground2.svg'
import keentddcontentBackground3 from 'assets/images/taxDeductionDocuments/background/contentBackground3.svg'
import keentddcontentBackground4 from 'assets/images/taxDeductionDocuments/background/contentBackground4.svg'
import keentddcontentBackground5 from 'assets/images/taxDeductionDocuments/background/contentBackground5.svg'
import keentddcontentBackground6 from 'assets/images/taxDeductionDocuments/background/contentBackground6.svg'
import keentddcontentBackground7 from 'assets/images/taxDeductionDocuments/background/contentBackground7.svg'
import keentddcontentBackground8 from 'assets/images/taxDeductionDocuments/background/contentBackground8.svg'
import keentddcontentBackground9 from 'assets/images/taxDeductionDocuments/background/contentBackground9.svg'
import keentddcontentBackground10 from 'assets/images/taxDeductionDocuments/background/contentBackground10.svg'
import keentddcontentBackground11 from 'assets/images/taxDeductionDocuments/background/contentBackground11.svg'
import keentddcontentBackground12 from 'assets/images/taxDeductionDocuments/background/contentBackground12.svg'
import keentddcontentBackground13 from 'assets/images/taxDeductionDocuments/background/contentBackground13.svg'
import keentddcontentBackground14 from 'assets/images/taxDeductionDocuments/background/contentBackground14.svg'
//text-align
import keentddcontentLeft from 'assets/images/taxDeductionDocuments/ic_AlignLeft.c1f38311.svg'
import keentddcontentCenter from 'assets/images/taxDeductionDocuments/ic_AlignCenter.b03d2c01.svg'
import keentddcontentRight from 'assets/images/taxDeductionDocuments/ic_AlignRight.f5a07ece.svg'

import { keyBy } from 'lodash'

// App resources: icons, images, fonts...
const AppResource = {
  // icons
  icons: {
    icLineHeightUp: require('assets/icons/ic_lineheight-up.svg'),
    icLineHeightDown: require('assets/icons/ic_lineheight-down.svg'),
    icBreakLine: require('assets/icons/ic_break_line.svg'),
    icMergeLine: require('assets/icons/ic_merge_line.svg'),
    icContent: require('assets/icons/ic_content.svg'),
    icTitleContent: require('assets/icons/ic_title_content.svg'),
    icContentLeft: require('assets/icons/ic_content_left.svg'),
    icPageUp,
    icEmptyBox,
    icTrash,
    icLock,
    icActiveAccount,

    keens: {
      // logo
      logo1: keenLogo1,
      logo2: keenLogo2,
      logo3: keenLogo3,
      logo4: keenLogo4,
      logo5: keenLogo5,
      logo6: keenLogo6,
      logo7: keenLogo7,
      // text
      toggleRight: keenIcToggleRight,
      // general
      search: keenIcSearch,
      user: keenIcUser,
      shieldCheck: keenIcShieldCheck,
      // avatar
      avatarBoy: keenIcAvatarBoy,
      avatarGirl: keenIcAvatarGirl,
      // flag
      flagEn: keenIcFlagEn,
      flagVi: keenIcFlagVi,
      // code
      compiling: keenIcCompiling,
      cmd: keenIcCmd,
      lockOverTurning: keenIcLockOverturning,
      // media
      equalizer: keenIcEqualizer,
      // layout
      layout4Blocks: keenIcLayout4Blocks,
      layoutPolygon: keenIcLayoutPolygon,
      // communication
      chat: keenIcCommunicationChat,
      mailbox: keenIcCommunicationMailBox,
      spam: keenIcCommunicationSpam,
      safeChat: keenIcCommunicationSafeChat,
      send: keenIcCommunicationSend,
      mailAt: keenIcCommunicationMailAt,
      chatLocked: keenIcCommunicationChatLocked,
      groupChat: keenIcCommunicationGroupChat,
      thumbtack: keenIcCommunicationThumbtack,
      outgoingTask: keenIcCommunicationOutgoingBox,
      clipboardCheck: keenIcCommunicationClipboardCheck,
      group: keenIcCommunicationGroup,
      // clothes
      briefcase: keenIcBriefcase,
      crown: keenIcCrown,
      // navigation
      up: keenIcNavigationUp,
      arrowLeft: keenIcNavigationArrowLeft,
      // tools
      compass: keenIcToolCompass,
      // design
      flatten: keenIcDesignFlatten,
      // home
      library: keenIcLibrary,
      // files
      filePlus: keenIcFilePlus,
      // user blank
      userBlank: keenIcUserBlank,
    },
  },

  colors: {
    featureColor: '#2196f3',
    feature: '#0071ce',
  },
  branding: {
    tuluc_ico: require('assets/images/branding/tuluc/favicon.ico'),
    sonphat_ico: require('assets/images/branding/sonphat/favicon.ico'),
    vietinvoice_ico: require('assets/images/branding/vietinvoice/favicon.ico'),
    tuluc_apple_touch_icon: require('assets/images/branding/tuluc/apple-touch-icon.png'),
    sonphat_apple_touch_icon: require('assets/images/branding/sonphat/apple-touch-icon.png'),
    vietinvoice_apple_touch_icon: require('assets/images/branding/vietinvoice/apple-touch-icon.png'),
    tuluc_logo: require('assets/images/branding/tuluc/logo.png'),
    sonphat_logo: require('assets/images/branding/sonphat/logo.png'),
    vietinvoice_logo: require('assets/images/branding/vietinvoice/logo.png'),
  },
  // images
  images: {
    img_ICORP_logo: require('assets/images/img_ICORP_logo.png'),
    img_viet_invoice_logo: require('assets/images/img_vietinvoice.png'),
    img_tu_luc_logo: require('assets/images/img_TULUC_logo.png'),
    img_son_phat_log: require('assets/images/img_SONPHAT_logo.png'),
    img_viet_invoice: require('assets/images/img_vietinvoice.png'),
    img_chung_tu: require('assets/images/img_chung_tu.png'),
    img_qr_code: require('assets/images/img_qr_code.png'),
    img_merge_line: require('assets/images/img_merge_line.gif'),
    noCart: require('assets/images/img_noCart.png'),
    img_watermark: require('assets/images/img_watermark.png'),
    img_payment_bannner: require('assets/images/img_payment_banner.png'),
    img_user_circle: require('assets/images/user_circle.png'),
    img_invoice_dollar: require('assets/images/invoice_dollar.png'),
    img_invoice_dollar_active: require('assets/images/invoice_dollar_active.png'),
    img_money_check_dollar_pen: require('assets/images/money_check_dollar_pen.png'),
    img_money_check_dollar_pen_active: require('assets/images/money_check_dollar_pen_active.png'),
    img_image_outline_filled: require('assets/images/image_outline_filled.png'),
    img_VNPay: require('assets/images/VNPay.png'),
    img_email: require('assets/images/email.png'),
    img_VIETINVOICE_logo: require('assets/images/img_VIETINVOICE_logo.png'),
    img_nghiep_vu: require('assets/images/img_nghiep-vu.png'),
    img_an_toan: require('assets/images/img_an-toan.png'),
    img_hieu_qua: require('assets/images/img_hieu-qua.png'),
    img_tu_van: require('assets/images/img_tu-van.png'),
    // error states
    errorStates: {
      error404: require('assets/images/ErrorStates/404.png'),
      pageNotFound: require('assets/icons/ic_empty_box.svg'),
    },
    ServicePackPaymentSucceed: require('assets/images/img_service_pack_payment_succeed.svg'),
    ServicePackPaymentFail: require('assets/images/img_service_pack_payment_fail.svg'),
    imgEInvoiceProcessBG,
    imgDangKySuDungHDDT: require('assets/images/img_dang_ky_su_dung_HDDT.png'),
    imgGuiHoaDonChoNguoiMua: require('assets/images/img_gui_hoa_don_cho_nguoi_mua.png'),
    imgGuiHoaDonDeCQTCapMa: require('assets/images/img_gui_hoa_don_de_CQT_cap_ma.png'),
    imgKhoiTaoMau: require('assets/images/img_khoi_tao_mau.png'),
    imgLapHDDTKySo: require('assets/images/img_lap_HDDT_ky_so.png'),
    imgLapHoaDonDieuChinh: require('assets/images/img_lap_hoa_don_dieu_chinh.png'),
    imgLapHoaDonMoi: require('assets/images/img_lap_hoa_don_moi.png'),
    imgLapHoaDonThayThe: require('assets/images/img_lap_hoa_don_thay_the.png'),
    imgThayDoiThongTinDangKy: require('assets/images/img_thay_doi_thong_tin_dang_ky.png'),
    imgThongBaoChoNguoiMuaVeSaiSot: require('assets/images/img_thong_bao_cho_nguoi_mua_ve_sai_sot.png'),
    imgThongBaoHDDTSaiSot: require('assets/images/img_thong_bao_HDDT_sai_sot.png'),
    imgTieuHuy: require('assets/images/img_tieu_huy.png'),
    imgInvoiceFromCashRegisterProcessBG: require('assets/images/img_invoice_from_cash_registers.png'),
    imgCapMaCQT: require('assets/images/img_cap_ma_CQT.png'),
    imgKySoGuiMTTDenCQT: require('assets/images/img_ky_so_gui_du_lieu_MTT_den_CQT.png'),
  },
  //taxDeucation
  taxDeduction: {
    keenTaxDuctionborder: keenTaxDuctionborder,
    keenTaxDuctionView: keenTaxDuctionView,
    keenTaxDuction1: keenTaxDuction1,
    keenTaxDuction2: keenTaxDuction2,
    keenTaxDuction3: keenTaxDuction3,
    keenTaxDuction4: keenTaxDuction4,
    keenTaxDuction5: keenTaxDuction5,
    keenTaxDuction6: keenTaxDuction6,
    keenTaxDuction7: keenTaxDuction7,
    keenTaxDuction8: keenTaxDuction8,
  },
  //taxDeucationDocuments
  taxDeucationDocuments: {
    keentddDelete: keentddDelete,
    keentddEdit: keentddEdit,
    keentddTopLogo: keentddTopLogo,
    keentddBottomLogo: keentddBottomLogo,
  },
  borderBackground: {
    keentddborderBackground1: { url: keentddborderBackground1, key: 1 },
    keentddborderBackground2: { url: keentddborderBackground2, key: 2 },
    keentddborderBackground3: { url: keentddborderBackground3, key: 3 },
    keentddborderBackground4: { url: keentddborderBackground4, key: 4 },
    keentddborderBackground5: { url: keentddborderBackground5, key: 5 },
    keentddborderBackground6: { url: keentddborderBackground6, key: 6 },
    keentddborderBackground7: { url: keentddborderBackground7, key: 7 },
  },
  contentBackground: {
    keentddcontentBackground1: { url: keentddcontentBackground1, key: 1 },
    keentddcontentBackground2: { url: keentddcontentBackground2, key: 2 },
    keentddcontentBackground3: { url: keentddcontentBackground3, key: 3 },
    keentddcontentBackground4: { url: keentddcontentBackground4, key: 4 },
    keentddcontentBackground5: { url: keentddcontentBackground5, key: 5 },
    keentddcontentBackground6: { url: keentddcontentBackground6, key: 6 },
    keentddcontentBackground7: { url: keentddcontentBackground7, key: 7 },
    keentddcontentBackground8: { url: keentddcontentBackground8, key: 8 },
    keentddcontentBackground9: { url: keentddcontentBackground9, key: 9 },
    keentddcontentBackground10: { url: keentddcontentBackground10, key: 10 },
    keentddcontentBackground11: { url: keentddcontentBackground11, key: 11 },
    keentddcontentBackground12: { url: keentddcontentBackground12, key: 12 },
    keentddcontentBackground13: { url: keentddcontentBackground13, key: 13 },
    keentddcontentBackground14: { url: keentddcontentBackground14, key: 14 },
  },
  textAlign: {
    keentddcontentLeft: keentddcontentLeft,
    keentddcontentCenter: keentddcontentCenter,
    keentddcontentRight: keentddcontentRight,
  },
}
export default AppResource
