import React, { useState, useEffect, useRef } from 'react'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTRadio from 'general/components/Forms/KTFormControls/KTRadio'
import KTFormSelectCustom from 'general/components/Forms/KTFormSelectCustom'
import './SubStep.scss'

function SubStep(props) {
  const {
    index,
    index1,
    userList,
    companyTitles,
    addSubStep,
    deleteSubStep,
    updateSubStep,
    plusIcon,
    minusIcon,
    accountId,
    userName,
    companyTitleId,
    companyTitleName,
  } = props
  const [userState, setUserState] = useState(
    accountId ? true : companyTitleId ? false : true,
  )
  const [companyTitleState, setCompanyTitleState] = useState(
    companyTitleId ? true : false,
  )

  const [userNameChosen, setUserNameChosen] = useState({
    // accountId: accountId,
    // userName: userName,
  })
  const [companyTitleChosen, setCompanyTitleChosen] = useState({
    // companyTitleId: companyTitleId,
    // companyTitleName: companyTitleName,
  })

  useEffect(() => {
    setUserNameChosen({ accountId: accountId, userName: userName })
    setCompanyTitleChosen({
      companyTitleId: companyTitleId,
      companyTitleName: companyTitleName,
    })
  }, [accountId, companyTitleId])

  const [changeValue, setChangeValue] = useState(false)

  useEffect(() => {
    if (userNameChosen.userName || companyTitleChosen.companyTitleName) {
      const updatedValues = {
        accountId: userState ? userNameChosen.accountId : null,
        userName: userState ? userNameChosen.userName : null,
        companyTitleId: companyTitleState
          ? companyTitleChosen.companyTitleId
          : null,
        companyTitleName: companyTitleState
          ? companyTitleChosen.companyTitleName
          : null,
      }
      updateSubStep(index + 1, index1, updatedValues)

      // Cập nhật các prop tương ứng
      if (userState) {
        // Cập nhật accountId và userName khi userNameChosen thay đổi
        props.updateSubStep(index + 1, index1, {
          accountId: userNameChosen.accountId,
          userName: userNameChosen.userName,
        })
      } else {
        // Cập nhật companyTitleId và companyTitleName khi companyTitleChosen thay đổi
        props.updateSubStep(index + 1, index1, {
          companyTitleId: companyTitleChosen.companyTitleId,
          companyTitleName: companyTitleChosen.companyTitleName,
        })
      }
    }
  }, [userState, companyTitleState, changeValue])

  return (
    <div className="w-100 SubStep">
      <div className="d-flex align-items-center mt-3">
        <div
          className="d-flex border"
          style={{ borderRadius: '8px', width: 'fit-content' }}
        >
          <div
            className="pr-2 pt-2 pb-3 pr-5 pl-5"
            style={{ borderRight: '1px solid #EBEDF3' }}
          >
            <KTRadio
              // name="myRadio"
              label="Người dùng"
              checked={userState}
              onChange={(newValue) => {
                if (companyTitleState) {
                  setUserState(true)
                  setCompanyTitleState(false)
                }
              }}
            />
          </div>
          <div className="pt-2 pb-2 pr-5 pl-5">
            <KTRadio
              // name="myRadio"
              label="Chức danh"
              checked={companyTitleState}
              onChange={(newValue) => {
                if (userState) {
                  setCompanyTitleState(true)
                  setUserState(false)
                }
              }}
            />
          </div>
        </div>
        <div
          className="pr-3 pl-3 d-flex align-items-center"
          style={{ height: '100%' }}
        >
          {userState ? (
            <KTFormSelectCustom
              // name="userList"
              isCustom
              options={userList}
              value={userNameChosen}
              onChange={(newValue) => {
                setUserNameChosen(newValue)
                setChangeValue(!changeValue)
              }}
              fullWidth={true}
              style={{ width: '300px' }}
            />
          ) : (
            <KTFormSelectCustom
              // name="companyTitles"
              isCustom
              options={companyTitles}
              value={companyTitleChosen}
              onChange={(newValue) => {
                // console.log('newValue', newValue);
                setChangeValue(!changeValue)
                setCompanyTitleChosen(newValue)
              }}
              fullWidth={true}
              style={{ width: '300px' }}
            />
          )}
        </div>
        {minusIcon && (
          <div
            className="plusIcon cursor-pointer"
            onClick={() => deleteSubStep(index + 1, index1)}
          >
            <i className="fa-solid fa-minus" style={{ color: '#dc3545' }}></i>
          </div>
        )}
        {plusIcon && (
          <div
            className="plusIcon cursor-pointer"
            onClick={() => addSubStep(index + 1)}
          >
            <i className="fas fa-plus" style={{ color: '#28a745' }}></i>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubStep
