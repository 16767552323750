import { Button, Modal } from 'antd'
import {
  createInvoiceApprovalProcess,
  updateInvoiceApprovalProcess,
} from 'features/System/screens/InvoiceApprovalProcess/invoiceApprovalProcessSlice.js'
import { getAllUsers } from 'features/System/screens/UserManagement/userSlice.js'
import { useFormik } from 'formik'
import KTCheckbox from 'general/components/Forms/KTFormControls/KTCheckbox'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import KTFormMultiSelect from 'general/components/Forms/KTFormMultiSelect'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import './ModalEdit.scss'
import SubStep from './SubStep'

/**
 *
 * @param {object} props
 * @param {boolean} props.show
 * @param {Function} props.setShow
 * @param {boolean} props.editState
 * @param {boolean} props.addState
 * @param {Array} props.templateInvoiceSerials
 * @param {object} props.selectedRow
 * @returns
 */
export default function ModalEdit(props) {
  const {
    show,
    setShow,
    editState,
    // addState,
    templateInvoiceSerials,
    selectedRow,
  } = props

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [warningStep, setWarningStep] = useState() // Khởi tạo warningStep ban đầu là null

  const checkSteps = (steps) => {
    console.log(steps)
    for (let i = 0; i < steps.length; i++) {
      for (let j = 0; j < steps[i].length; j++) {
        if (
          steps[i][j].accountId == null &&
          steps[i][j].companyTitleId == null
        ) {
          setWarningStep({ ...steps[i][j], index: i })

          return false // Dừng việc tìm kiếm khi tìm thấy phần tử thỏa mãn điều kiện
        } else
          for (let k = j + 1; k < steps[i].length; k++) {
            if (
              (steps[i][j].accountId !== null &&
                steps[i][j].accountId === steps[i][k].accountId) ||
              (steps[i][j].companyTitleId !== null &&
                steps[i][j].companyTitleId === steps[i][k].companyTitleId)
            ) {
              setWarningStep({ ...steps[i][j], index: i })

              return false // Dừng việc tìm kiếm khi tìm thấy phần tử thỏa mãn điều kiện
            }
          }
      }
    }
    return true
  }

  const formik = useFormik({
    initialValues: {
      processName: editState ? selectedRow?.name : '',
      listSerial: editState ? selectedRow?.listSerial : [],
      description: editState ? selectedRow?.description : '',
      active: editState ? selectedRow?.active : true,
    },
    validationSchema: Yup.object({
      processName: Yup.string().required('Không được để trống tên quy trình'),
      listSerial: Yup.array()
        .min(1, 'Danh sách mã hóa đơn phải có ít nhất 1 phần tử')
        .required('Không được để trống mã hóa đơn'),

      description: Yup.string(),
      active: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      const result = checkSteps(steps)
      if (result == false) {
        setShowWarningModal(true)
      } else if (editState == false) {
        await dispatch(
          createInvoiceApprovalProcess({
            name: values?.processName,
            description: values?.description,
            listSerial: values?.listSerial,
            steps: steps,
          }),
          setShow(false),
        )
      } else {
        dispatch(
          updateInvoiceApprovalProcess({
            invoiceApprovalProcessId: selectedRow?.invoiceApprovalProcessId,
            name: values?.processName,
            description: values?.description,
            listSerial: values?.listSerial,
            steps: steps,
            active: values?.active,
          }),
        )
        setShow(false)
      }
    },
  })

  const user = useSelector((state) => state.auth.currentAccount)

  const userListTmp = useSelector((state) => state?.userList?.users?.accounts)
  const [userList, setUserList] = useState(userListTmp)

  useEffect(() => {
    dispatch(getAllUsers(user?.companyId))
  }, [dispatch, user?.companyId])

  // Sử dụng useEffect để cập nhật userList khi giá trị userListTmp thay đổi
  useEffect(() => {
    if (userListTmp && userListTmp.length > 0) {
      const updatedUserList = userListTmp.map((item) => ({
        ...item,
        name: item?.fullname || null, // Sử dụng fullname nếu tồn tại, ngược lại sẽ là chuỗi rỗng
        value: {
          userName: item?.fullname || null, // Sử dụng fullname nếu tồn tại, ngược lại sẽ là chuỗi rỗng
          accountId: item?.accountId || null, // Sử dụng accountId nếu tồn tại, ngược lại sẽ là số 0
        },
      }))

      setUserList(updatedUserList)
    }
  }, [userListTmp])

  const companyTitlesTmp = useSelector(
    (state) => state?.category?.companyTitles,
  )

  const [companyTitles, setCompanyTitles] = useState()

  useEffect(() => {
    // Kiểm tra nếu companyTitles không null và có phần tử trong mảng
    if (companyTitlesTmp && companyTitlesTmp.length > 0) {
      // Tạo một bản sao của companyTitles đã có để tránh sửa trực tiếp vào state Redux
      const updatedCompanyTitles = companyTitlesTmp.map((item) => ({
        ...item,
        value: {
          companyTitleName: item.name, // Thêm trường value và gán bằng giá trị của trường name
          companyTitleId: item.companyTitleId,
        },
      }))

      // Cập nhật lại companyTitles trong state Redux bằng mảng đã được thêm trường value
      setCompanyTitles(updatedCompanyTitles)
    }
  }, [companyTitlesTmp])

  const [steps, setSteps] = useState([
    [
      {
        ordinalNumber: 1,
        accountId: null,
        userName: null,
        companyTitleId: null,
        companyTitleName: null,
      },
    ],
  ])

  const addStep = () => {
    setSteps((prev) => [
      ...prev,
      [
        {
          ordinalNumber: steps.length + 1,
          accountId: null,
          userName: null,
          companyTitleId: null,
          companyTitleName: null,
        },
      ],
    ])
  }

  const deleteStep = (ordinalNumberToDelete) => {
    setSteps((prevSteps) => {
      // Lọc ra các phần tử có ordinalNumber khác với ordinalNumberToDelete
      const filteredSteps = prevSteps.filter(
        (step) => step[0].ordinalNumber !== ordinalNumberToDelete,
      )

      // Cập nhật lại ordinalNumber cho các phần tử còn lại
      const updatedSteps = filteredSteps.map((step, index) => {
        return [{ ...step[0], ordinalNumber: index + 1 }, ...step.slice(1)]
      })

      return updatedSteps
    })
  }

  const addSubStep = (ordinalNumber) => {
    setSteps((prevSteps) => {
      // Tạo một bản sao của prevSteps
      const stepsCopy = [...prevSteps]

      // Tìm vị trí của phần tử cha có ordinalNumber trùng với tham số đầu vào
      const parentStepIndex = stepsCopy.findIndex(
        (step) => step[0].ordinalNumber === ordinalNumber,
      )

      // Kiểm tra xem có phần tử cha nào tồn tại có ordinalNumber giống với tham số không
      if (parentStepIndex !== -1) {
        // Tạo một bản sao của phần tử cha
        const parentStep = [...stepsCopy[parentStepIndex]]

        // Thêm một phần tử con vào phần tử cha với các giá trị mặc định
        parentStep.push({
          ordinalNumber: ordinalNumber,
          accountId: null,
          userName: null,
          companyTitleId: null,
          companyTitleName: null,
        })

        // Cập nhật lại phần tử cha trong mảng stepsCopy
        stepsCopy[parentStepIndex] = parentStep
      }

      // Trả về một bản sao mới của mảng stepsCopy đã được cập nhật
      return [...stepsCopy]
    })
  }

  const deleteSubStep = (ordinalNumber, indexToDelete) => {
    setSteps((prevSteps) => {
      // Tạo một bản sao mới của mảng steps
      const newSteps = [...prevSteps]

      // Tìm phần tử cha có ordinalNumber trùng với tham số đầu vào
      const parentStepIndex = newSteps.findIndex(
        (step) => step[0].ordinalNumber === ordinalNumber,
      )

      if (parentStepIndex !== -1) {
        // Tạo một bản sao của phần tử cha
        const parentStep = [...newSteps[parentStepIndex]]

        // Xóa phần tử con tại indexToDelete
        parentStep.splice(indexToDelete, 1) // +1 để bỏ qua phần tử cha

        // Cập nhật lại phần tử cha trong mảng newSteps
        newSteps[parentStepIndex] = parentStep
      }

      // Trả về bản sao mới của mảng steps
      return newSteps
    })
  }

  const updateSubStep = (ordinalNumber, indexToUpdate, dataToUpdate) => {
    setSteps((prevSteps) => {
      // Tạo một bản sao mới của mảng steps
      const updatedSteps = prevSteps.map((step) => {
        if (step[0].ordinalNumber === ordinalNumber) {
          // Tìm phần tử con tại indexToUpdate và cập nhật giá trị
          const updatedSubStep = { ...step[indexToUpdate], ...dataToUpdate }
          return [
            ...step.slice(0, indexToUpdate),
            updatedSubStep,
            ...step.slice(indexToUpdate + 1),
          ]
        }
        return step
      })

      return updatedSteps
    })
  }

  const processData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        value: item.serial,
      }
    })
  }

  useEffect(() => {
    if (editState) setSteps(selectedRow?.steps)
  }, [editState, selectedRow])

  const [showWarningModal, setShowWarningModal] = useState(false)

  const handleWarningClose = () => {
    setShowWarningModal(false)
  }

  return (
    <>
      <Modal
        destroyOnClose
        open={show}
        onCancel={() => {
          setShow(false)
        }}
        title={
          editState
            ? 'Chỉnh sửa quy trình phê duyệt'
            : 'Thêm mới quy trình phê duyệt'
        }
        centered
        width={800}
        footer={null}
        styles={{
          content: {
            padding: 0,
          },
          header: {
            padding: '1rem',
          },
        }}
      >
        <section>
          <div
            style={{
              background: '#f3f5f7',
              padding: '10px',
              overflowY: 'visible',
            }}
          >
            <div className=" row m-0">
              {/* processName */}
              <div className="col-md-12 d-flex align-items-center">
                <div
                  style={{
                    width: '175px',
                    transform: 'translateY(-5px)',
                    color: '#333',
                    fontWeight: '600',
                  }}
                >
                  Tên quy trình <span style={{ color: 'red' }}>(*)</span>
                </div>
                <div className="h-100 w-50">
                  <KTFormGroup
                    // label={<>{t('Tên quy trình')}</>}
                    inputName="processName"
                    inputElement={
                      <KTFormInput
                        name="processName"
                        value={formik.getFieldProps('processName').value}
                        onChange={(value) => {
                          formik.getFieldHelpers('processName').setValue(value)
                        }}
                        onBlur={() => {
                          formik.getFieldHelpers('processName').setTouched(true)
                        }}
                        enableCheckValid={true}
                        isValid={_.isEmpty(
                          formik.getFieldMeta('processName').error,
                        )}
                        isTouched={formik.getFieldMeta('processName').touched}
                        feedbackText={formik.getFieldMeta('processName').error}
                        type={KTFormInputType.text}
                        placeholder={`${_.capitalize(t('Tên quy trình'))}`}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className=" pb-4 row m-0">
              <div className="d-flex col-md-12 align-items-center">
                <div
                  style={{
                    width: '175px',
                    color: '#333',
                    fontWeight: '600',
                  }}
                >
                  Ký hiệu
                  {/* <span style={{ color: 'red' }}>(*)</span> */}
                </div>
                <div className="w-50">
                  <KTFormMultiSelect
                    name="listSerial"
                    options={processData(templateInvoiceSerials)}
                    value={formik.getFieldProps('listSerial').value}
                    size="lg"
                    showValidState={true}
                    haveSearchBar={true}
                    noChoice={false}
                    noSearchBar={false}
                    text={'Danh sách mã hóa đơn'}
                    onChange={(newValue) => {
                      formik.getFieldHelpers('listSerial').setValue(newValue)
                    }}
                    enableCheckValid={true}
                    isValid={_.isEmpty(formik.getFieldMeta('listSerial').error)}
                    isTouched={formik.getFieldMeta('listSerial').touched}
                    feedbackText={formik.getFieldMeta('listSerial').error}
                  />
                </div>
              </div>
            </div>
            <div className=" pt-0 row m-0">
              {/* processName */}
              <div className="col-md-12 d-flex align-items-center">
                <div
                  style={{
                    width: '175px',
                    transform: 'translateY(-5px)',
                    color: '#333',
                    fontWeight: '600',
                  }}
                >
                  Mô tả
                </div>
                <div className="w-50">
                  <KTFormGroup
                    // label={<>{t('Tên quy trình')}</>}
                    inputName="description"
                    inputElement={
                      <KTFormInput
                        name="description"
                        value={formik.getFieldProps('description').value}
                        onChange={(value) => {
                          formik.getFieldHelpers('description').setValue(value)
                        }}
                        onBlur={() => {
                          formik.getFieldHelpers('description').setTouched(true)
                        }}
                        enableCheckValid={true}
                        isValid={_.isEmpty(
                          formik.getFieldMeta('description').error,
                        )}
                        isTouched={formik.getFieldMeta('description').touched}
                        feedbackText={formik.getFieldMeta('description').error}
                        type={KTFormInputType.text}
                        placeholder={`${_.capitalize(t('Mô tả quy trình'))}`}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-block" style={{ padding: '10px' }}>
            <div className="w-100 d-flex justify-content-between mb-2">
              <div className="" style={{ fontWeight: '600' }}>
                {' '}
                Thiết lập các bước phê duyệt{' '}
              </div>
              <div className=" " style={{ fontStyle: 'italic' }}>
                {' '}
                Thiết lập người phê duyệt theo chức năng hoặc người dùng{' '}
              </div>
            </div>
            <div
              className="w-100 d-flex flex-column"
              style={{
                minHeight: '270px',
                maxHeight: '270px',
                overflow: 'auto',
              }}
            >
              {userList &&
                companyTitles &&
                steps?.map((item, index) => {
                  return (
                    <div className="d-flex flex-column w-100 mb-3" key={index}>
                      <div
                        className="w-100 d-flex justify-content-between pr-3 pl-3 pt-2 pb-2"
                        style={{ background: '#f3f5f7' }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ color: '#333', fontWeight: '600' }}
                        >
                          <i
                            className="fas fa-bars mr-3"
                            style={{ fontSize: '15px', color: '#333' }}
                          ></i>
                          Bước {index + 1}
                        </div>
                        <div
                          className=""
                          onClick={() => {
                            if (steps.length > 1) {
                              deleteStep(index + 1)
                            }
                          }}
                          style={{
                            cursor: steps.length > 1 ? 'pointer' : 'no-drop',
                          }}
                        >
                          {' '}
                          <i
                            className="far fa-trash-alt"
                            style={{ color: '#3F4254' }}
                          ></i>
                        </div>
                      </div>
                      {item?.map((item1, index1) => {
                        return (
                          <div className="w-100" key={index1}>
                            <SubStep
                              index={index}
                              index1={index1}
                              userList={userList}
                              companyTitles={companyTitles}
                              addSubStep={addSubStep}
                              deleteSubStep={deleteSubStep}
                              updateSubStep={updateSubStep}
                              plusIcon={index1 === item?.length - 1}
                              minusIcon={item?.length > 1}
                              accountId={item1?.accountId || null}
                              userName={item1?.userName || null}
                              companyTitleId={item1?.companyTitleId || null}
                              companyTitleName={item1?.companyTitleName || null}
                            />
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
            </div>
            <div className="w-100">
              <div
                className=" cursor-pointer addStepBtn"
                style={{ color: '#1877f2', fontWeight: '600' }}
                onClick={addStep}
              >
                {' '}
                <i
                  className="fas fa-plus mr-2"
                  style={{ color: '#1877f2', fontWeight: '600' }}
                ></i>
                Thêm bước
              </div>
              <div className="w-100 pr-4 d-flex justify-content-between align-items-center border-top pt-3">
                <div>
                  {editState && (
                    <KTCheckbox
                      label={'Ngừng sử dụng'}
                      checked={!formik.getFieldProps('active').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('active').setValue(!value)
                      }}
                    />
                  )}
                </div>
                <div>
                  <Button
                    type="primary"
                    style={{ width: '100px', fontWeight: '600' }}
                    onClick={formik.submitForm}
                  >
                    Lưu
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
      <Modal
        destroyOnClose
        open={showWarningModal}
        onCancel={handleWarningClose}
        centered
        footer={null}
        title={
          <div style={{ fontSize: '18px', fontWeight: '600', color: '#000' }}>
            <i
              className="fal fa-exclamation-triangle mr-2"
              style={{
                fontSize: '18px',
                fontWeight: '600',
                color: 'rgb(255,163,24)',
              }}
            ></i>
            Cảnh báo
          </div>
        }
      >
        <div style={{ background: '#fff', padding: '0px 20px' }}>
          <div
            className="d-flex"
            style={{ fontSize: '14px', fontWeight: '500' }}
          >
            {warningStep?.accountId != null ? (
              <span>
                Người dùng{' '}
                <span style={{ color: '#D62A2A' }}>
                  {warningStep?.userName}
                </span>{' '}
                bị trùng lặp ở{' '}
                <span style={{ color: '#D62A2A' }}>
                  bước {warningStep?.index + 1}
                </span>
                . Vui lòng kiểm tra lại.
              </span>
            ) : warningStep?.companyTitleId != null ? (
              <span>
                Chức danh{' '}
                <span style={{ color: '#D62A2A' }}>
                  {warningStep?.companyTitleName}{' '}
                </span>
                bị trùng lặp ở{' '}
                <span style={{ color: '#D62A2A' }}>
                  bước {warningStep?.index + 1}
                </span>
                . Vui lòng kiểm tra lại.
              </span>
            ) : (
              <span>
                Không được <span style={{ color: '#D62A2A' }}> để trống </span>
                người dùng hoặc chức danh ở
                <span style={{ color: '#D62A2A' }}>
                  {' '}
                  bước {warningStep?.index + 1}
                </span>
                . Vui lòng kiểm tra lại.
              </span>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
