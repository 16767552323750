import styled from 'styled-components'
import { Tree, TreeSelect } from 'antd'
import { EditOutlined } from '@ant-design/icons'
const { DirectoryTree } = Tree

export const DivItemTree = styled.div`
  width: 265px;
`

export const DivHardLeft = styled.div`
  height: 800px;
  width: 400px;
  border: 1px solid #e0e0e0;
  overflow-y: auto;
  padding: 10px;
`

export const DivCustomCotent = styled.div`
  width: 100%;
  color: #000000;
`

export const DivHardRight = styled.div`
  height: 800px;
  width: 100%;
  border: 1px solid #e0e0e0;
  margin-left: 10px;
  overflow-y: auto;
  padding: 10px;
`
export const SpanTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
`

export const TreeCustom = styled(Tree)`
  color: #000000;
  .ant-tree-indent-unit:before {
    display: none;
  }
  .ant-tree-switcher-leaf-line:before {
    display: none;
  }
  .ant-tree-switcher-leaf-line:after {
    display: none;
  }
  .ant-tree-treenode {
  }
  .ant-tree-treenode .ant-tree-switcher {
  }
  .ant-tree-node-selected {
    color: #0c68b4 !important;
  }
`
export const EditOutlinedIconCustom = styled(EditOutlined)`
  font-size: 18px;
  margin-left: 10px;
  padding: 3px;
  border-radius: 3px;
  cursor: pointer;
  :hover {
    border: 1px solid #0c68b4;
    margin-left: 9px;
    color: #0c68b4;
  }
`

export const DivTitleTable = styled.div`
    font-weight: 700;
    padding: 15px 10px;
    margin-left: -12px;
    border: 1px solid #e0e0e0;
    border-left: none;
    border-right: 1px solid rgb(224, 224, 224);
    width: 100%;
    color:#000000;
    background-color: #f6f6f6;
    padding-right:2px;
}
`
export const DivTable = styled.div`
    margin-top:-4px;
    margin-left: -12px;
    width:100%
}
`
export const ContainerContentModal = styled.div`
    margin-left: -20px;
    margin-right: -20px;
    color:#000000;
    background-color: #ffffff;
    padding: ${(props) => props.padding};
}
`
export const TreeSelectCustom = styled(TreeSelect)`
  border-radius: 3px;
  .ant-tree-select {
    border-radius: 3px;
  }
  .ant-select-selector {
    border-radius: 3px;
  }
  color: #000000;
`
export const TreeSelectCustomH = styled(TreeSelect)`
  border-radius: 3px;
  .ant-tree-select {
    border-radius: 3px;
  }
  .ant-select-selector {
    border-radius: 3px;
  }
  color: #000000;
  .ant-select-tree {
    max-height: 80px;
    overflow-y: auto;
  }
  .ant-select-selection-item {
    width: 100%;
  }
  .ant-select-selection-overflow-item {
    width: 100%;
  }
  .ant-select-selection-overflow-item {
    width: 100%;
  }
  .ant-select-selection-item-content {
    width: 100%;
  }
`

export const DirectoryTreeCustom = styled(DirectoryTree)``
