import { useDroppable } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { Typography } from 'antd'
import { Fragment } from 'react'
import StatisticDraggable from './StatisticDraggable'

const StatisticDroppable = ({
  id,
  items,
  strategy,
  className,
  handleDeleteTag,
}) => {
  const { setNodeRef } = useDroppable({ id })
  return (
    <SortableContext id={id} items={items} strategy={strategy}>
      {id === 'items' ? (
        <Typography.Paragraph type="secondary" className="my-2">
          Bấm giữ và kéo thả chỉ tiêu muốn hiển thị lên trên
        </Typography.Paragraph>
      ) : null}
      <div
        ref={setNodeRef}
        className={className + ' my-2 p-2 w-100'}
        style={{
          userSelect: 'none',
          height: '100%',
          minHeight: '7rem',
        }}
      >
        {items?.map((item) => (
          <Fragment key={item.id}>
            <StatisticDraggable
              handleDeleteTag={handleDeleteTag}
              id={item.id}
            />
          </Fragment>
        ))}
      </div>
    </SortableContext>
  )
}

export default StatisticDroppable
