import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import taxDeductionDocumentsApi from 'api/taxDeductionDocumentsApi'

export const thunkFindTaxDeductionDocuments = createAsyncThunk(
  'taxDeductionDocuments/find',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.find(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

export const thunkGetAllTaxDeductionDocumentsDefault = createAsyncThunk(
  'taxDeductionDocuments/getAllDefault',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.getAllDefault(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

export const thunkSaveTaxDeductionDocumentsDefault = createAsyncThunk(
  'taxDeductionDocuments/save',
  async (formData, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.save(formData)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

export const thunkRemoveTaxDeductionDocumentsDefault = createAsyncThunk(
  'taxDeductionDocuments/remove',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.remove(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkFindByIdTaxDeductionDocuments = createAsyncThunk(
  'taxDeductionDocuments/findbyId',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.findById(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkFindByTempapeRreleaseTaxDeductionDocuments = createAsyncThunk(
  'taxDeductionDocuments/findByTempapeRrelease',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.findByTempapeRrelease(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

export const thunkGetIpTaxDeductionDocuments = createAsyncThunk(
  'taxDeductionDocuments/ip',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.getIp(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

export const thunkUpdateSignTaxDeductionDocuments = createAsyncThunk(
  'taxDeductionDocuments/updateSign',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.updateDateSign(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

export const thunkExportFileDeductionDocuments = createAsyncThunk(
  'taxDeductionDocuments/exportFile',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.exportFile(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

export const thunkCancelDeductionDocuments = createAsyncThunk(
  'taxDeductionDocuments/cancel',
  async (params, thunkApi) => {
    try {
      const res = await taxDeductionDocumentsApi.cancel(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
const reportSlice = createSlice({
  name: 'taxDeductionDocuments',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkFindTaxDeductionDocuments.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(thunkFindTaxDeductionDocuments.fulfilled, (state, action) => {
        state.loading = false
        state.processes = action.payload // Cập nhật state với dữ liệu trả về
      })
      .addCase(thunkFindTaxDeductionDocuments.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload // Lưu lỗi vào state
      })
      .addCase(thunkGetAllTaxDeductionDocumentsDefault.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        thunkGetAllTaxDeductionDocumentsDefault.fulfilled,
        (state, action) => {
          state.loading = false
          state.processes = action.payload // Cập nhật state với dữ liệu trả về
        },
      )
      .addCase(
        thunkGetAllTaxDeductionDocumentsDefault.rejected,
        (state, action) => {
          state.loading = false
          state.error = action.payload // Lưu lỗi vào state
        },
      )
  },
})

const { reducer } = reportSlice
export default reducer
