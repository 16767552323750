import { queryOptions, useQuery } from '@tanstack/react-query'
import reportApi from 'api/reportApi'
import _ from 'lodash'
const generalReportQueries = {
  base: {
    scope: ['report', 'generalReport'],
  },
  all: () => ({
    ...generalReportQueries.base,
    entity: ['invoicesByTax'],
  }),
  getList: (params) =>
    queryOptions({
      queryKey: [generalReportQueries.all(), params],
      queryFn: ({ signal }) => reportApi.getReportBytax(params, signal),
      staleTime: 1000 * 30,
    }),
}
export const useQueryGeneralReport = (filters) => {
  return useQuery({
    ...generalReportQueries.getList(filters),
    select: (res) => {
      return {
        invoicesByTax: _.get(res, 'data.taxArray', []),
        totalRevenueBeforeTax: _.get(res, 'data.totalBeforeTax', 0),
        totalTax: _.get(res, 'data.totalTaxMoney', 0),
        totalRevenueAfterTax: _.get(res, 'data.totalAfterTax', 0),
      }
    },
  })
}
