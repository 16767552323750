// import lifeStyleApi from 'api/lifeStyleApi';
import { useMutation } from '@tanstack/react-query'
import customerApi from 'api/customerApi'
import { setAppSpinning } from 'app/appSlice'
import { thunkGetCustomerList } from 'features/Category/categorySlice'
import { FastField, Formik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import VIToggleSwitch from 'general/components/eInvoiceComponents/VIToggleSwitch'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import useMutationGetCompanyInfo from 'hooks/Mutations/useMutationGetCompanyInfo'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

ModalEditCustomer.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onRefreshCustomerList: PropTypes.func,
  customerItem: PropTypes.object,
  onExistDone: PropTypes.func,
}

ModalEditCustomer.defaultProps = {
  show: false,
  onClose: null,
  onRefreshCustomerList: null,
  customerItem: null,
  onExistDone: null,
}

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * onRefreshCustomerList: function,
 * customerItem: object,
 * onExistDone: function,
 * }} props
 * @returns
 */

function ModalEditCustomer(props) {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const systemInvoiceType = useSelector((s) => s.auth?.activeInvoice)
  const {
    show,
    onClose,
    active,
    customerItem,
    onExistDone,
    onRefreshCustomerList,
  } = props
  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose()
    }
  }

  function handleExistDone() {
    if (onExistDone) {
      onExistDone()
    }
  }

  const { mutate } = useMutation({
    mutationKey: ['createCustomer', 'modalEditCustomer'],
    mutationFn: (params) => {
      const actionType = _.toLower(active)
      // Utils.formatEmptyKey(params)
      // Utils.removeNullKey(params)
      if (_.isNull(customerItem)) {
        params.customerId = ''
      }
      params.typeInvoice = systemInvoiceType?.invoice
        ? 'INVOICE'
        : systemInvoiceType?.ticket
          ? 'TICKET'
          : 'RECEIPT'
      return actionType === 'add'
        ? customerApi.create(params)
        : actionType === 'replicate'
          ? customerApi.create(params)
          : actionType === 'edit'
            ? customerApi.update(params)
            : null
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => {
      ToastHelper.showError(
        process.env.NODE_ENV === 'development' ? err?.message : 'Có lỗi xảy ra',
      )
    },
    onSuccess: (_res, variables) => {
      const resMess =
        active === 'ADD'
          ? 'Thêm mới khách hàng'
          : active === 'EDIT'
            ? 'Cập nhật thông tin khách hàng'
            : 'Nhân bản khách hàng ' + variables.name
      ToastHelper.showSuccess(`${resMess} thành công`)

      onRefreshCustomerList
        ? onRefreshCustomerList()
        : dispatch(thunkGetCustomerList(Global.gFiltersCustomerList))
      handleClose()
    },
  })

  return (
    <div>
      <Formik
        initialValues={{
          customerId: customerItem ? customerItem.customerId : '',
          type: customerItem ? customerItem.type : 'ORGANIZATION',
          status: customerItem ? customerItem.status : 'ACTIVE',
          code: customerItem ? customerItem.code : '',
          taxCode: customerItem ? customerItem.taxCode : '',
          name: customerItem ? customerItem.name : '',
          address: customerItem ? customerItem.address : '',
          bankAccount: customerItem ? customerItem.bankAccount : '',
          bankName: customerItem ? customerItem.bankName : '',
          email: customerItem ? customerItem.email : '',
          phone: customerItem ? customerItem?.phone : '',
          buyerName: customerItem ? customerItem?.buyerName : '',
          buyerEmail: customerItem ? customerItem?.buyerEmail : '',
          buyerPhone: customerItem ? customerItem?.buyerPhone : '',
          contractRecipientName: customerItem
            ? customerItem?.contractRecipientName
            : '',
          contractRecipientEmails: customerItem
            ? customerItem?.contractRecipientEmails
            : '',
          contractRecipientPhone: customerItem
            ? customerItem?.contractRecipientPhone
            : '',
        }}
        validationSchema={Yup.object({
          code: Yup.string().trim().required(t('Required')),
          name: Yup.string().trim().required(t('Required')),
          // taxCode: Yup.string().trim().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={(values) => mutate(values)}
      >
        {(formikProps) => (
          <>
            <Modal
              className=""
              show={show}
              backdrop="static"
              size="lg"
              onHide={handleClose}
              centered
              onExit={() => {
                formikProps.handleReset()
              }}
              onExited={() => {
                handleExistDone()
              }}
            >
              <Modal.Header className="px-5 py-5">
                <Modal.Title>
                  {active === 'ADD'
                    ? 'Thêm khách hàng'
                    : active === 'EDIT'
                      ? 'Chỉnh sửa thông tin khách hàng'
                      : 'Nhân bản khách hàng'}
                </Modal.Title>
                <div
                  className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <i className="far fa-times"></i>
                </div>
              </Modal.Header>

              <Modal.Body
                className="overflow-auto bg-light"
                style={{
                  maxHeight: '40vh',
                }}
              >
                <div className="row">
                  {/* type */}
                  <div className="mb-4 d-flex flex-column col-lg-6">
                    <label
                      className="mb-2 font-weight-bolder"
                      style={{ color: '#7177A8' }}
                      htmlFor="type"
                    >
                      {_.capitalize(t('Loại khách hàng'))}
                    </label>
                    <KTFormSelect
                      name="type"
                      isCustom
                      options={AppData.customerType}
                      value={formikProps
                        .getFieldProps('type')
                        .value?.toString()}
                      onChange={(newValue) => {
                        formikProps.getFieldHelpers('type').setValue(newValue)
                      }}
                    />
                  </div>

                  <div className="col-lg-6 d-flex flex-row gap-4">
                    <KTFormGroup
                      label={
                        <div>
                          {t('Mã số thuế')}
                          {formikProps.getFieldProps('type').value ===
                            'ORGANIZATION' && (
                            <span className="text-danger"> {`(*)`}</span>
                          )}
                        </div>
                      }
                      inputName="taxCode"
                      inputElement={
                        <FastField name="taxCode">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid={
                                formikProps.getFieldProps('type').value ===
                                'ORGANIZATION'
                              }
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Mã số thuế'),
                              )}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                    <ButtonGetCompanyProfile
                      taxCode={formikProps.getFieldProps('taxCode').value}
                      setFieldValue={(name, value) => {
                        formikProps.getFieldHelpers(name).setValue(value)
                      }}
                    />
                  </div>

                  {/* ma khach hàng */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Mã khách hàng')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="code"
                      inputElement={
                        <FastField name="code">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Mã khách hàng'),
                              )}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* ten khach hàng */}
                  <div className="">
                    <KTFormGroup
                      label={
                        <>
                          {t('Tên khách hàng')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="name"
                      inputElement={
                        <FastField name="name">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Tên khách hàng'),
                              )}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* dia chi */}
                  <div className="col-12">
                    <KTFormGroup
                      label={<>{t('Địa chỉ')}</>}
                      inputName="address"
                      inputElement={
                        <FastField name="address">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('Địa chỉ'))}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* so tai khoan */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={<>{t('Số tài khoản')}</>}
                      inputName="bankAccount"
                      inputElement={
                        <FastField name="bankAccount">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Số tài khoản'),
                              )}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* ten ngan hang */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={<>{t('Tên ngân hàng')}</>}
                      inputName="bankName"
                      inputElement={
                        <FastField name="bankName">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Tên ngân hàng'),
                              )}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="w-100">
                  {formikProps.getFieldProps('type').value ===
                  'ORGANIZATION' ? (
                    <div className="row">
                      <div className="col-lg-6">
                        <p className="font-weight-bolder">Người mua hàng</p>
                        <div className="">
                          <KTFormGroup
                            // label={
                            //   <>
                            //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                            //   </>
                            // }
                            inputName="buyerName"
                            inputElement={
                              <FastField name="buyerName">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true)
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(
                                      t('Họ và tên'),
                                    )}...`}
                                    // disabled={!canEdit}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                        <div className="">
                          <KTFormGroup
                            // label={
                            //   <>
                            //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                            //   </>
                            // }
                            inputName="buyerEmail"
                            inputElement={
                              <FastField name="buyerEmail">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true)
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(
                                      t('Email'),
                                    )}...`}
                                    // disabled={!canEdit}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                        <div className="">
                          <KTFormGroup
                            // label={
                            //   <>
                            //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                            //   </>
                            // }
                            inputName="buyerPhone"
                            inputElement={
                              <FastField name="buyerPhone">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true)
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(
                                      t('Số điện thoại'),
                                    )}...`}
                                    // disabled={!canEdit}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <p className="font-weight-bolder">Người nhận HĐ</p>
                        <div className="">
                          <KTFormGroup
                            // label={
                            //   <>
                            //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                            //   </>
                            // }
                            inputName="contractRecipientName"
                            inputElement={
                              <FastField name="contractRecipientName">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true)
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(
                                      t('Họ và tên'),
                                    )}...`}
                                    // disabled={!canEdit}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                        <div className="">
                          <KTFormGroup
                            // label={
                            //   <>
                            //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                            //   </>
                            // }
                            inputName="contractRecipientEmails"
                            inputElement={
                              <FastField name="contractRecipientEmails">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true)
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(
                                      t('Các email cách nhau bởi dấu ;'),
                                    )}...`}
                                    // disabled={!canEdit}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                        <div className="">
                          <KTFormGroup
                            // label={
                            //   <>
                            //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                            //   </>
                            // }
                            inputName="contractRecipientPhone"
                            inputElement={
                              <FastField name="contractRecipientPhone">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                    }}
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true)
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(
                                      t('Số điện thoại'),
                                    )}...`}
                                    // disabled={!canEdit}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <p className="font-weight-bolder">Người nhận HĐ</p>
                      <div className="">
                        <KTFormGroup
                          // label={
                          //   <>
                          //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                          //   </>
                          // }
                          inputName="contractRecipientName"
                          inputElement={
                            <FastField name="contractRecipientName">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Họ và tên'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          // label={
                          //   <>
                          //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                          //   </>
                          // }
                          inputName="contractRecipientEmails"
                          inputElement={
                            <FastField name="contractRecipientEmails">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Cách email cách nhau bởi dấu ;'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          // label={
                          //   <>
                          //     {t('Số điện thoại')} <span className="text-danger">(*)</span>
                          //   </>
                          // }
                          inputName="contractRecipientPhone"
                          inputElement={
                            <FastField name="contractRecipientPhone">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Số điện thoại'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-100 d-flex flex-wrap gap-3 justify-content-between">
                  <div>
                    <FastField name="status">
                      {({ field, form }) => (
                        <VIToggleSwitch
                          label={
                            field.value === 'ACTIVE'
                              ? t('Using')
                              : t('StopUsing')
                          }
                          status={field.value === 'ACTIVE' ? true : false}
                          name={field.name}
                          onSwitch={(checked) => {
                            if (checked) {
                              form.setFieldValue(field.name, 'ACTIVE')
                            } else {
                              form.setFieldValue(field.name, 'INACTIVE')
                            }
                          }}
                        />
                      )}
                    </FastField>
                  </div>

                  <div className="d-flex">
                    <Button
                      className="font-weight-bold  mr-3"
                      variant="secondary"
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      {t('Close')}
                    </Button>

                    <Button
                      className="font-weight-bold "
                      variant="primary"
                      onClick={() => {
                        formikProps.handleSubmit()
                      }}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  )
}

const ButtonGetCompanyProfile = ({ taxCode, setFieldValue }) => {
  const { mutateAsync: handleGetCompanyTaxInfo, isPending: isLoading } =
    useMutationGetCompanyInfo()
  const handleOnClicked = async () => {
    const result = await handleGetCompanyTaxInfo({
      document_type: 'TAX',
      tax_code: taxCode,
    })
    if (!result) return
    const { document_data, message } = result.data
    if (message !== 'SUCCESSFULLY') {
      setFieldValue('name', '')
      setFieldValue('address', '')
    } else {
      setFieldValue(
        'name',
        document_data.tax_informations[0].company_information.official_name,
      )
      setFieldValue(
        'address',
        document_data.tax_informations[0].company_information.address,
      )
    }
  }

  return (
    <CustomAntButton
      text="Lấy thông tin"
      iconCn={'fa-light fa-globe'}
      isLoading={isLoading}
      antProps={{
        type: 'primary',
        className: 'btn btn-success mt-8 flex-grow-1',
        onClick: handleOnClicked,
      }}
      customStyle={{
        height: '38px',
      }}
    />
  )
}

export default ModalEditCustomer
