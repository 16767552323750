import { message } from 'antd'
import { useMemo } from 'react'
import useToken from './useToken'

const MAX_TIME_OUT = 3000

export default function useAlertMessage() {
  const [messageApi, contextHolder] = message.useMessage({
    getContainer: () => document.getElementById('root'),
    maxCount: 3,
  })
  const { token } = useToken()
  /**
   * @type {{
   *  success: (content: string) => void
   *  error: (content: string) => void
   *  warning: (content: string) => void
   * }}
   */
  const openAlert = useMemo(() => {
    return {
      success: (content) =>
        messageApi.success({
          content,
          style: {
            userSelect: 'none',
            color: token.colorSuccessText,
          },
          icon: (
            <i
              className="fa-duotone fa-check-circle mr-3"
              style={{
                color: 'inherit',
              }}
            />
          ),
          onClick: () => messageApi.destroy(),
          duration: MAX_TIME_OUT,
        }),
      error: (content) =>
        messageApi.error({
          content,
          style: {
            userSelect: 'none',
            color: token.colorErrorText,
          },
          icon: (
            <i
              className="fa-duotone fa-times-circle  mr-3"
              style={{
                color: 'inherit',
              }}
            />
          ),
          onClick: () => messageApi.destroy(),
          duration: MAX_TIME_OUT,
        }),
      warning: (content) =>
        messageApi.warning({
          content,
          style: {
            userSelect: 'none',
            color: token.colorWarningText,
          },
          icon: (
            <i
              className="fa-duotone fa-triangle-exclamation  mr-3"
              style={{
                color: 'inherit',
              }}
            />
          ),
          onClick: () => messageApi.destroy(),
          duration: MAX_TIME_OUT,
        }),
    }
  }, [messageApi, token])

  return {
    openAlert,
    contextHolder,
  }
}
