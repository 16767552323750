import dayjs from 'dayjs'

export const STATUS_RELEASE = {
  NOT_SENT_TO_AUTHORITY: 'NOT_SENT_TO_AUTHORITY',
  WAITING_FOR_AUTHORITY: 'WAITING_FOR_AUTHORITY',
  TAX_VALID: 'TAX_VALID',
  TAX_NOT_VALID: 'TAX_NOT_VALID',
  INVOICE_INVALID: 'INVOICE_INVALID',
  AUTHORITY_ACCEPT: 'AUTHORITY_ACCEPT',
  SEND_ERROR: 'SEND_ERROR',
}

export const REGISTRATION_STATUS = {
  DRAFT: 'DRAFT',
  SENT_TAX: 'SENT_TAX',
  TAX_VALID: 'TAX_VALID',
  TAX_NOT_VALID: 'TAX_NOT_VALID',
  TAX_RECEIVE: 'TAX_RECEIVE',
  TAX_NOT_RECEIVE: 'TAX_NOT_RECEIVE',
  TAX_ACCEPT: 'TAX_ACCEPT',
  TAX_NOT_ACCEPT: 'TAX_NOT_ACCEPT',
}

export const INVOICE_STATUS = {
  NOT_SENT_TO_AUTHORITY: 'NOT_SENT_TO_AUTHORITY',
  WAITING_FOR_AUTHORITY: 'WAITING_FOR_AUTHORITY',
  AUTHORITY_CODE_GIVEN: 'AUTHORITY_CODE_GIVEN',
  AUTHORITY_CODE_NOT_GIVEN: 'AUTHORITY_CODE_NOT_GIVEN',
  AUTHORITY_DENY: 'AUTHORITY_DENY',
  AUTHORITY_ACCEPT: 'AUTHORITY_ACCEPT',
  SENDING_TAX: 'SENDING_TAX',
  SEND_ERROR: 'SEND_ERROR',
  SIGN_ERROR: 'SIGN_ERROR',
}

export const SUMMARY_INVOICE_STATUS = {
  NOT_SENT_TO_AUTHORITY: 'NOT_SENT_TO_AUTHORITY',
  WAITING_FOR_AUTHORITY: 'WAITING_FOR_AUTHORITY',
  INVOICE_INVALID: 'INVOICE_INVALID',
  AUTHORITY_ACCEPT: 'AUTHORITY_ACCEPT',
  SEND_ERROR: 'SEND_ERROR',
}

export const INVOICE_TYPE_TO_QUERY = {
  ISSUED: 'ISSUED',
  REPLACED: 'REPLACED',
  ADJUSTED: 'ADJUSTED',
  CANCEL: 'CANCEL',
  ERR_ANNOUCED: 'ERR_ANNOUCED',
  CAN_REPLACED: 'CAN_REPLACED',
  CAN_ADJUSTED: 'CAN_ADJUSTED',
  CAN_ERR_ANNOUCED: 'CAN_ERR_ANNOUCED',
  CAN_CANCELLED: 'CAN_CANCELLED',
  NEED_ERR_ANNOUCED: 'NEED_ERR_ANNOUCED',
}

export const ERR_ANNOUCE_STATUS = {
  ALL: null,
  NOT_SENT_TO_AUTHORITY: 'NOT_SENT_TO_AUTHORITY',
  WAITING_FOR_AUTHORITY: 'WAITING_FOR_AUTHORITY',
  AUTHORITY_DENY: 'AUTHORITY_DENY',
  AUTHORITY_ACCEPT: 'AUTHORITY_ACCEPT',
  SEND_ERROR: 'SEND_ERROR',
}

export const CANCEL_INVOICE_STATUS = {
  ALL: null,
  CANCELLED: 'CANCELLED',
  NOT_CANCELLED: 'NOT_CANCELLED',
}

export const ACTION_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
  ADJUST: 'adjust',
  REPLACE: 'replace',
}

export const INVOICE_TYPE = {
  ALL: null,
  ORIGINAL: 0,
  REPLACE: 1,
  ADJUST: 2,
  CANCELLED: 3,
  REPLACED: 4,
  ADJUSTED: 5,
  DELETED: 6,
}

export const DISCOUNT_TYPE = {
  NO_DISCOUNT: 0, // Khong chiet khau
  EACH_ITEM: 1, // chiet khau theo tung mat hang
  ALL: 2, // chiet khau theo tong gia tri
}

export const CANCELLATION_REPORT_STATUS = {
  ALL: null,
  NOT_CREATED: 1,
  CREATED_NOT_SIGNED: 2,
  NOT_SENT_TO_CUSTOMER: 3,
  WAIT_CUSTOMER_SIGN: 4,
  SIGNED_BY_CUSTOMER: 5,
}

export const INVOICE_ERR_ANNOUCE_STATUS = {
  ALL: null,
  NOT_CREATED: 1,
  NOT_SENT_TO_AUTHORITY: 2,
  WAITING_FOR_AUTHORITY: 3,
  AUTHORITY_DENY: 4,
  AUTHORITY_ACCEPT: 5,
  SEND_ERROR: 6, // Có lỗi khi gửi
}
export const SEND_MAIL_STATUS = {
  ALL: null,
  NOT_SEND_MAIL: 0,
  SENT_MAIL: 1,
  SENT_MAIL_ERROR: 2,
  SENDING_MAIL: 3,
}

const AppConstants = {
  REGISTRATION_STATUS,
}

export const INVOICE_STATISTIC = {
  invoiceNew: `hoá đơn mới`,
  invoiceCanceled: `hoá đơn đã bị huỷ`,
  invoiceAdjusted: `hoá đơn đã bị điều chỉnh`,
  invoiceAdjust: `hoá đơn điều chỉnh`,
  invoiceReplaced: `hoá đơn đã bị thay thế`,
  invoiceReplace: `hoá đơn thay thế`,
  invoiceNotSendAuthority: `chưa phát hành`,
  invoiceWaitingAuthority: `chờ CQT xử lý`,
  invoiceCodeGivenAuthority: `đã cấp mã`,
  invoiceNoCodeGivenAuthority: `từ chối cấp mã`,
  invoiceDenyAuthority: `CQT từ chối`,
  invoiceAcceptAuthority: `CQT xác nhận hợp lệ`,
  invoicePayment: `Đã thanh toán`,
  invoiceNoPayment: `Chưa thanh toán`,
  invoiceSentMailCustomer: `Đã gửi mail KH`,
  invoiceNoSendMailCustomer: `Chưa gửi mail KH`,
}

export const SUBMIT_ACTION = {
  SAVE: `SAVE`,
  SAVE_AND_SIGN: `SAVE_AND_SIGN`,
}

export const ERROR_ANNOUCEMENTS = {
  ALL: null,
  TAX_PAYER_RELATED: 1,
  TAX_AUTHORITIES_RELATED: 2,
}
export const ADJUST_INVOICE_VNESE = {
  '[Để trống/Âm][Tổng tiền thanh toán]': {
    positive: '',
    negative: 'Âm',
    position: 'start',
  },
  '[Tăng/Giảm][Tổng tiền thanh toán]': {
    positive: 'Tăng',
    negative: 'Giảm',
    position: 'start',
  },
  'Điều chỉnh [tăng giảm][Tổng tiền thanh toán]': {
    positive: 'Điều chỉnh tăng',
    negative: 'Điều chỉnh giảm',
    position: 'start',
  },

  '[Tổng tiền thanh toán][Tăng/Giảm]': {
    positive: '(Tăng)',
    negative: '(Giảm)',
    position: 'end',
  },
  '[Tổng tiền thanh toán](Điều chỉnh [tăng giảm])': {
    positive: '(Điều chỉnh tăng)',
    negative: '(Điều chỉnh giảm)',
    position: 'end',
  },

  '[Tổng tiền thanh toán]': [{ positive: '', negative: '' }],
}

export const MAX_WAIT_SIGN_INVOICE = 60000

export const TICKET_GRANTED_STATUS = {
  ALL: null,
  WAITING: 'AUTHORITY_PROCESS',
  GRANTED: 'AUTHORITY_ACCEPT',
  DENIED: 'AUTHORITY_DENY',
}

export const TYPE_TICKET = {
  ADJUST: 'ADJUST',
  REPLACE: 'REPLACE',
}

export const TICKET_TYPE_SERVICE = {
  VT: 'VT',
  GX: 'GX',
}

export const TICKET_TYPE_DETUCT = {
  KT: 'KT',
  TT: 'TT',
}

export const ROUTE_TYPE = {
  MULTIPLE: 'MULTIPLE',
  SINGLE: 'SINGLE',
}

export const TYPE_PRICE_TICKET = {
  PREPRINTED_PRICE: 1,
  NOT_PREPRINTED_PRICE: 2,
}

export const TICKET_STATUS = {
  ALL: null,
  NEW: 'ORIGINAL',
  REPLACE: 'REPLACE',
  ADJUST: 'ADJUST',
  CANCELLED: 'CANCELLED',
  REPLACED: 'REPLACED',
  ADJUSTED: 'ADJUSTED',
}

export const TICKET_MAIL_SENT_CUSTOMER_STATUS = {
  ALL: null,
  NOT_SENT: 'NOT_SEND',
  SENDING: 'SENDING',
  SEND_ERROR: 'SEND_ERROR',
  SENT: 'SENT',
}
export const TICKET_MAIL_SENT_AUTHORITY_STATUS = {
  ALL: null,
  NOT_SENT_TO_AUTHORITY: 'NOT_SENT_TO_AUTHORITY',
  SENT_TO_AUTHORITY: 'SENT_TO_AUTHORITY',
  MESSAGE_NOT_VALID: 'MESSAGE_NOT_VALID',
  TICKET_VALID: 'TICKET_VALID',
  TICKET_NOT_VALID: 'TICKET_NOT_VALID',
  EMPTY: 'EMPTY',
}

export const SIGN_TOOL_PARAMETER = {
  MESSAGE_300: 'message300',
  TICKETS_SIGNED_BY_SELLER: 'ticketsSignedBySeller',
}

export const TICKET_PUBLISHED_STATUS = {
  ALL: null,
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  IS_PUBLISHING: 'IS_PUBLISHING',
  ERROR: 'ERROR',
  WAITING_FOR_AUTHORITY: 'WAITING_FOR_AUTHORITY',
  GRANTED: 'GRANTED',
  DENIED: 'DENIED',
  INVALID: 'INVALID',
}

export const TICKET_TYPE_ERR_ANNOUCEMENT = {
  ALL: null,
  CANCELED: 1,
  ADJUSTED: 2,
  REPLACED: 3,
  EXPLAINED: 4,
}

export const IMPORT_TICKET_STEPS = [
  { stepNumber: 1, stepDescription: 'CHỌN TỆP NGUỒN' },
  { stepNumber: 2, stepDescription: 'GHÉP CỘT DỮ LIỆU' },
  { stepNumber: 3, stepDescription: 'XEM TRƯỚC DỮ LIỆU' },
  // { stepNumber: 4, stepDescription: 'KIỂM TRA DỮ LIỆU' },
  { stepNumber: 4, stepDescription: 'NHẬP KHẨU' },
]

export const PAYMENT_METHOD_LOOKUP = [
  {
    id: 1,
    value: 'CASH_BANKING',
    name: 'TM/CK',
  },
  {
    id: 2,
    value: 'CASH',
    name: 'Tiền mặt',
  },
  {
    id: 3,
    value: 'BANKING',
    name: 'Chuyển khoản',
  },
  {
    id: 4,
    value: 'CLEARING_DEBT',
    name: 'Đối trừ công nợ',
  },
  {
    id: 5,
    value: 'FREE',
    name: 'Không thu tiền',
  },
]

export const PAYMENT_METHOD = {
  TRANSFER: 'TRANSFER',
  VNPAY: 'VNPAY',
}

export const PAYMENT_STATUS = {
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELLED',
  PENDING: 'PENDING',
}

export const RESOURCE_TYPE = {
  INVOICE: 'INVOICE',
  TICKET: 'TICKET',
  PERSONAL_INCOME: 'TTCN',
  INVOICE_MANAGEMENT: 'MANAGE_INVOICE',
  RECEIPT: 'RECEIPT',
}

export const INVOICE_UPLOAD_GUIDES = {
  title: 'FILE MẪU DANH SÁCH HÓA ĐƠN ĐỂ NHẬP VÀO PHẦN MỀM VIETINVOICE',
  subtitle: 'Hướng dẫn:',
  guides: [
    '- Điền dữ liệu hóa đơn cần lập trên phần mềm vào các cột tương ứng trên file này',
    '- Các cột có dấu (*) là những cột bắt buộc',
    '- Nếu hóa đơn chiết khấu theo tổng tiền hàng thì điền thông tin về tỷ lệ CK và tiền CK ở cột L và M. Nếu chiết khấu theo từng mặt hàng thì điền thông tin ở cột T và U',
    `- Loại tiền tệ lấy theo cột "Mã tiền tệ" trong chức năng "Hệ thống => Quản lý tiền tệ"`,
    `- Mã khách hàng (cột D) chỉ hợp lệ nếu đã tồn tại trong chức năng "Danh mục => Khách hàng"`,
    `- Mã hàng (cột O) chỉ hợp lệ nếu đã tồn tại trong chức năng "Danh mục => Hàng hóa, dịch vụ"`,
    `- Các dòng dữ liệu phía dưới chỉ là ví dụ minh họa`,
    `- Cột ngày hóa đơn phải có dấu ' trước ngày tháng và để kiểu dữ liệu là "General"`,
  ],
}

export const TICKET_UPLOAD_GUIDES = {
  title: 'FILE MẪU DANH SÁCH VÉ ĐỂ NHẬP VÀO PHẦN MỀM VIETINVOICE',
  subtitle: 'Hướng dẫn:',
  guides: [
    '- Điền dữ liệu vé cần lập trên phần mềm vào các cột tương ứng trên file này',
    '- Các cột có dấu (*) là những cột bắt buộc',
    '- Nếu muốn nhập thêm thông tin khác, người dùng có thể tự thêm cột trên file này',
    '- Các dòng dữ liệu phía dưới chỉ là ví dụ minh họa',
  ],
}

export const INVOICE_UPLOAD_HEADER_COLUMNS = [
  {
    header: 'Số thứ tự hóa đơn (*)',
    key: 'stt',
    width: 21,
    webWidth: '105px',
    description: 'Mỗi số thứ tự sẽ tương ứng với một hóa đơn',
    require: true,
    mappingKeywords:
      'Số thứ tự hóa đơn (*);Số thứ tự hóa đơn;So thu tu hoa don;Số TT HĐ;Số TT hóa đơn;Số thứ tự HĐ',
  },
  {
    header: 'Ngày hóa đơn',
    key: 'ngayhoadon',
    width: 16,
    webWidth: '120px',
    description: 'Ngày hóa đơn',
    mappingKeywords:
      'Ngày hóa đơn;Ngày hóa đơn (*);Ngay hoa đon (*);Ngày hóa đơn;Ngay hoa don;Ngày HĐ;Ngay HD;Ngày;Ngay',
  },
  {
    header: 'Tên đơn vị mua hàng',
    key: 'tendonvimuahang',
    width: 40,
    webWidth: '120px',
    description: 'Tên đơn vị mua hàng',
    mappingKeywords: 'Tên đơn vị mua hàng',
  },
  {
    header: 'Mã khách hàng',
    key: 'makhachhang',
    width: 16,
    webWidth: '120px',
    description: 'Mã khách hàng',
    mappingKeywords: 'Mã khách hàng;Ma khach hang;Mã;Ma;Mã KH;Ma KH',
  },
  {
    header: 'Địa chỉ',
    key: 'diachi',
    webWidth: '130px',
    width: 40,
    description: 'Địa chỉ',
    mappingKeywords: 'Địa chỉ;Dia chi;Đ/C;D/C',
  },
  {
    header: 'Mã số thuế',
    key: 'masothue',
    width: 20,
    webWidth: '120px',
    description: 'Mã số thuế',
    mappingKeywords: 'Mã số thuế;Ma so thue;MST',
  },
  {
    header: 'Người mua hàng',
    key: 'nguoimuahang',
    width: 25,
    webWidth: '150px',
    description: 'Người mua hàng',
    mappingKeywords: 'Người mua hàng (*);Người mua hàng',
  },
  {
    header: 'Email',
    key: 'email',
    width: 25,
    webWidth: '130px',
    description: 'Email',
    require: true,
    mappingKeywords: 'Email',
  },
  {
    header: 'Hình thức thanh toán',
    key: 'hinhthucthanhtoan',
    width: 20,
    webWidth: '105px',
    description: 'Hình thức thanh toán',
    mappingKeywords: 'Hình thức thanh toán',
  },
  {
    header: 'Loại tiền',
    key: 'loaitien',
    width: 20,
    webWidth: '105px',
    description: 'Loại tiền',
    mappingKeywords: 'Loại tiền',
  },
  {
    header: 'Tỷ giá',
    key: 'tygia',
    width: 20,
    webWidth: '140px',
    description: 'Tỷ giá',
    mappingKeywords: 'Tỷ giá',
  },
  {
    header: 'Tỷ lệ CK(%)',
    key: 'tyleck',
    width: 20,
    webWidth: '140px',
    description: 'Tỷ lệ CK(%)',
    mappingKeywords: 'Tỷ lệ CK(%)',
  },
  {
    header: 'Tiền CK',
    key: 'tienck',
    width: 25,
    webWidth: '120px',
    description: 'Tiền CK',
    mappingKeywords: 'Tiền CK',
  },
  {
    header: '% thuế GTGT',
    key: 'thuegtgt',
    width: 26,
    webWidth: '115px',
    description: '% thuế GTGT',
    mappingKeywords: '% thuế GTGT',
  },
  {
    header: 'Tiền thuế GTGT',
    key: 'tienthuegtgt',
    width: 21,
    description: 'Tiền thuế GTGT',
    mappingKeywords: 'Tiền thuế GTGT',
  },

  {
    header: 'Tên hàng hóa/dịch vụ (*)',
    key: 'tenhanghoadichvu',
    width: 30,
    description: 'Tên hàng hóa/dịch vụ (*)',
    mappingKeywords: 'Tên hàng hóa/dịch vụ (*)',
  },
  {
    header: 'Mã hàng',
    key: 'mahang',
    width: 21,
    description: 'Mã hàng',
    mappingKeywords: 'Mã hàng',
  },
  {
    header: 'ĐVT',
    key: 'dvt',
    width: 21,
    description: 'ĐVT',
    mappingKeywords: 'ĐVT',
  },
  {
    header: 'Số lượng',
    key: 'soluong',
    width: 21,
    description: 'Số lượng',
    mappingKeywords: 'Số lượng',
  },
  {
    header: 'Đơn giá',
    key: 'dongia',
    width: 21,
    description: 'Đơn giá',
    mappingKeywords: 'Đơn giá',
  },
  {
    header: 'Tỷ lệ CK (%)',
    key: 'tyleckhh',
    width: 21,
    description: 'Tỷ lệ CK (%)',
    mappingKeywords: 'Tỷ lệ CK (%)',
  },
  {
    header: 'Tiền CK',
    key: 'tienckhh',
    width: 21,
    description: 'Tiền CK',
    mappingKeywords: 'Tiền CK',
  },
  {
    header: '% thuế GTGT',
    key: 'thuegtgthh',
    width: 21,
    description: '% thuế GTGT',
    mappingKeywords: '% thuế GTGT',
  },
  {
    header: 'Tiền thuế GTGT',
    key: 'tienthuegtgthh',
    width: 21,
    description: 'Tiền thuế GTGT',
    mappingKeywords: 'Tiền thuế GTGT',
  },
  {
    header: 'Thành tiền(*)',
    key: 'thanhtienhh',
    width: 21,
    description: 'Thành tiền(*)',
    mappingKeywords: 'Thành tiền(*)',
  },
]

export const TICKET_UPLOAD_HEADER_COLUMNS = [
  {
    header: 'Số thứ tự vé (*)',
    key: 'stt',
    width: 21,
    webWidth: '105px',
    description: 'Mỗi số thứ tự sẽ tương ứng với một vé',
    require: true,
    mappingKeywords:
      'Số thứ tự vé (*);Số thứ tự hóa đơn;So thu tu hoa don;Số TT HĐ;Số TT hóa đơn;Số thứ tự HĐ',
  },
  {
    header: 'Ngày xuất vé',
    key: 'ngayxuatve',
    width: 16,
    webWidth: '120px',
    description: 'Ngày xuất vé',
    mappingKeywords:
      'Ngày xuất vé;Ngày hóa đơn (*);Ngay hoa đon (*);Ngày hóa đơn;Ngay hoa don;Ngày HĐ;Ngay HD;Ngày;Ngay',
  },
  {
    header: 'Mã khách hàng',
    key: 'makhachhang',
    description: 'Mã khách hàng',
    onlyWeb: true,
    mappingKeywords: 'Mã khách hàng;Ma khach hang;Mã;Ma;Mã KH;Ma KH',
  },
  {
    header: 'Tên khách hàng',
    key: 'tenkhachhang',
    width: 25,
    webWidth: '150px',
    description: 'Tên khách hàng',
    mappingKeywords:
      'Tên khách hàng (*);Ten khach hang (*);Tên khách hàng;Ten khach hang;Khách hàng (*);Khach hang (*);Khách hàng;Khach hang;Tên KH (*);Ten KH (*);Tên KH;Ten KH;KH (*);KH',
  },
  {
    header: 'Địa chỉ',
    key: 'diachi',
    webWidth: '130px',
    width: 40,
    description: 'Địa chỉ',
    mappingKeywords: 'Địa chỉ;Dia chi;Đ/C;D/C',
  },
  {
    header: 'Mã số thuế',
    key: 'masothue',
    width: 20,
    webWidth: '120px',
    description: 'Mã số thuế',
    mappingKeywords: 'Mã số thuế;Ma so thue;MST',
  },
  {
    header: 'Tuyến đường (*)',
    key: 'tuyenduong',
    width: 25,
    webWidth: '130px',
    description: 'Tuyến đường',
    require: true,
    mappingKeywords: 'Tuyen duong;Tuyến đường;Tuyến đường (*)',
  },
  {
    header: 'Số xe',
    key: 'soxe',
    width: 20,
    webWidth: '105px',
    description: 'Số xe',
    mappingKeywords: 'Số xe;So xe;Biển số xe;Bien so xe',
  },
  {
    header: 'Số ghế',
    key: 'soghe',
    width: 20,
    webWidth: '105px',
    description: 'Số ghế',
    mappingKeywords: 'Số ghế;So ghe',
  },
  {
    header: 'Ngày khởi hành',
    key: 'ngayhkhoihanh',
    width: 20,
    webWidth: '140px',
    description: 'Ngày khởi hành',
    mappingKeywords: 'Ngay khoi hanh;Ngày khởi hành',
  },
  {
    header: 'Giờ khởi hành',
    key: 'giokhoihanh',
    width: 20,
    webWidth: '140px',
    description: 'Giờ khởi hành',
    mappingKeywords:
      'Giờ khởi hành;Gio khoi hanh;Thời gian khởi hành;Thoi gian khoi hanh',
  },
  {
    header: 'Thuế suất GTGT (%)',
    key: 'thuesuatgtgt',
    width: 25,
    webWidth: '120px',
    description: 'Thuế suất GTGT (%)',
    mappingKeywords:
      'Thuế suất GTGT;Thue suat GTGT;Thuế suất thuế GTGT;Thue suat thue GTGT;% thuế GTGT;% thue GTGT;Thuế suất GTGT (%);Thue suat GTGT (%);Thuế suất thuế GTGT (%);Thue suat thue GTGT (%);Thuế suất GTGT (%)',
  },
  {
    header: 'Tổng tiền',
    key: 'tongtien',
    width: 26,
    webWidth: '115px',
    description: 'Tổng tiền',
    mappingKeywords: 'Tổng tiền;Số tiền;So tien;Thành tiền;Thanh tien',
  },
  {
    header: 'Ghi chú',
    key: 'ghichu',
    width: 21,
    description: 'Ghi chú',
    mappingKeywords: 'Ghi chú;Ghi chu;',
  },
]

export const TICKET_UPLOAD_DATA_SAMPLES = [
  {
    stt: 1,
    ngayxuatve: '03/03/2022',
    tenkhachhang: 'Nguyễn Quang Huy',
    diachi:
      'Ngõ 132 Phan Đình Phùng, Phường Hải Châu 1, Q. Hải Châu, TP. Đà Nẵng',
    masothue: '',
    tuyenduong: 'Hà Nội - Lào Cai',
    soxe: '29B-0536',
    soghe: '5',
    ngayhkhoihanh: '03/03/2022',
    giokhoihanh: '05:00',
    thuesuatgtgt: '10',
    tongtien: '50000',
    ghichu: '',
  },
  {
    stt: 2,
    ngayxuatve: '03/03/2022',
    tenkhachhang: 'Nguyễn Minh Hùng',
    diachi:
      'Số 152 Phan Đình Phùng, Phường Hải Châu 1, Q. Hải Châu, TP. Đà Nẵng ',
    masothue: '',
    tuyenduong: 'Hà Nội - Lào Cai',
    soxe: '29B-0536',
    soghe: '6',
    ngayhkhoihanh: '01/02/2021',
    giokhoihanh: '05:00',
    thuesuatgtgt: '10',
    tongtien: '50000',
    ghichu: '',
  },
  {
    stt: 3,
    ngayxuatve: '03/03/2022',
    tenkhachhang: 'Phạm Thu Giang',
    diachi:
      'Số 233 - Phan Đình Phùng, Phường Hải Châu 1, Q. Hải Châu, TP. Đà Nẵng',
    masothue: '',
    tuyenduong: 'Hà Nội - Lào Cai',
    soxe: '29B-0536',
    soghe: '7',
    ngayhkhoihanh: '03/03/2022',
    giokhoihanh: '05:00',
    thuesuatgtgt: '10',
    tongtien: '50000',
    ghichu: '',
  },
]

export const INVOICE_UPLOAD_DATA_SINGLE_TAX_RATE_SAMPLES = () => [
  {
    stt: '1',
    ngayhoadon: dayjs().format('DD/MM/YYYY'),
    tendonvimuahang: 'Công ty TNHH Linh Dược',
    makhachhang: '',
    diachi: '26 Đội Cấn, Ba Đình, Hà Nội',
    masothue: '0107402706',
    nguoimuahang: 'Trần Đình Chiến',
    email: 'chientd@gmail.com',
    hinhthucthanhtoan: 'Chuyển khoản',
    loaitien: 'VND',
    tygia: '',
    tyleck: '5',
    tienck: '12,250',
    thuegtgt: '10',
    tienthuegtgt: '23,275',

    tenhanghoadichvu: 'Áo sơ mi nam',
    mahang: '',
    dvt: 'Cái',
    soluong: '1',
    dongia: '200,000',
    tyleckhh: '',
    tienckhh: '',
    thuegtgthh: '',
    tienthuegtgthh: '',
    thanhtienhh: '200,000',
  },
  {
    stt: undefined,
    ngayhoadon: '',
    tendonvimuahang: '',
    makhachhang: '',
    diachi: '',
    masothue: '',
    nguoimuahang: '',
    email: '',
    hinhthucthanhtoan: '',
    loaitien: '',
    tygia: '',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Áo sơ mi nữ',
    mahang: '',
    dvt: 'Cái',
    soluong: '1',
    dongia: '45,000',
    tyleckhh: '',
    tienckhh: '',
    thuegtgthh: '',
    tienthuegtgthh: '',
    thanhtienhh: '45,000',
  },
  {
    stt: '2',
    ngayhoadon: dayjs().format('DD/MM/YYYY'),
    tendonvimuahang: 'Công ty Truyền Thông Online VN',
    makhachhang: '',
    diachi: '32 Cổ Nhuế, Từ Liêm, Hà Nội',
    masothue: '0101390765',
    nguoimuahang: 'Vũ Tuấn Minh',
    email: 'minhvt@amazon.com',
    hinhthucthanhtoan: 'Tiền mặt',
    loaitien: 'VND',
    tygia: '',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Quần tất',
    mahang: '',
    dvt: 'Chiếc',
    soluong: '1',
    dongia: '50,000',
    tyleckhh: '10',
    tienckhh: '5,000',
    thuegtgthh: '',
    tienthuegtgthh: '',
    thanhtienhh: '45,000',
  },
  {
    stt: undefined,
    ngayhoadon: '',
    tendonvimuahang: '',
    makhachhang: '',
    diachi: '',
    masothue: '',
    nguoimuahang: '',
    email: '',
    hinhthucthanhtoan: '',
    loaitien: '',
    tygia: '',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Quần nam',
    mahang: '',
    dvt: 'Cái',
    soluong: '2',
    dongia: '10,000',
    tyleckhh: '5',
    tienckhh: '1,000',
    thuegtgthh: '',
    tienthuegtgthh: '',
    thanhtienhh: '19,000',
  },
  {
    stt: '3',
    ngayhoadon: dayjs().format('DD/MM/YYYY'),
    tendonvimuahang: 'Công ty TNHH Tâm Anh',
    makhachhang: '',
    diachi: '218, Đội Cấn, Ba Đình, Hà Nội',
    masothue: '0101391325',
    nguoimuahang: 'Phan Văn Tới',
    email: 'minhvt@amazon.com',
    hinhthucthanhtoan: 'Tiền mặt',
    loaitien: 'USD',
    tygia: '23,000',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Đồng hồ Rolex',
    mahang: '',
    dvt: 'Cái',
    soluong: '4',
    dongia: '80',
    tyleckhh: '',
    tienckhh: '',
    thuegtgthh: '',
    tienthuegtgthh: '',
    thanhtienhh: '320',
  },
]

export const INVOICE_UPLOAD_DATA_MULTIPLE_TAX_RATE_SAMPLES = () => [
  {
    stt: '1',
    ngayhoadon: dayjs().format('DD/MM/YYYY'),
    tendonvimuahang: 'Công ty TNHH Linh Dược',
    makhachhang: '',
    diachi: '26 Đội Cấn, Ba Đình, Hà Nội',
    masothue: '0107402706',
    nguoimuahang: 'Trần Đình Chiến',
    email: 'chientd@gmail.com',
    hinhthucthanhtoan: 'Chuyển khoản',
    loaitien: 'VND',
    tygia: '',
    tyleck: '5',
    tienck: '10,000',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Áo sơ mi nam',
    mahang: '',
    dvt: 'Cái',
    soluong: '1',
    dongia: '200,000',
    tyleckhh: '',
    tienckhh: '',
    thuegtgthh: 'KCT',
    tienthuegtgthh: '0',
    thanhtienhh: '200,000',
  },
  {
    stt: undefined,
    ngayhoadon: '',
    tendonvimuahang: '',
    makhachhang: '',
    diachi: '',
    masothue: '',
    nguoimuahang: '',
    email: '',
    hinhthucthanhtoan: '',
    loaitien: '',
    tygia: '',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Áo sơ mi nữ',
    mahang: '',
    dvt: 'Cái',
    soluong: '1',
    dongia: '45,000',
    tyleckhh: '',
    tienckhh: '',
    thuegtgthh: '10',
    tienthuegtgthh: '4,500',
    thanhtienhh: '49,500',
  },
  {
    stt: '2',
    ngayhoadon: dayjs().format('DD/MM/YYYY'),
    tendonvimuahang: 'Công ty Truyền Thông Online VN',
    makhachhang: '',
    diachi: '32 Cổ Nhuế, Từ Liêm, Hà Nội',
    masothue: '0101390765',
    nguoimuahang: 'Vũ Tuấn Minh',
    email: 'minhvt@amazon.com',
    hinhthucthanhtoan: 'Tiền mặt',
    loaitien: 'VND',
    tygia: '',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Quần nam',
    mahang: '',
    dvt: 'Cái',
    soluong: '2',
    dongia: '10,000',
    tyleckhh: '5',
    tienckhh: '1,000',
    thuegtgthh: '0',
    tienthuegtgthh: '0',
    thanhtienhh: '19,000',
  },
  {
    stt: undefined,
    ngayhoadon: '',
    tendonvimuahang: '',
    makhachhang: '',
    diachi: '',
    masothue: '',
    nguoimuahang: '',
    email: '',
    hinhthucthanhtoan: '',
    loaitien: '',
    tygia: '',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Quần tất',
    mahang: '',
    dvt: 'Chiếc',
    soluong: '1',
    dongia: '50,000',
    tyleckhh: '10',
    tienckhh: '5,000',
    thuegtgthh: '10',
    tienthuegtgthh: '4,500',
    thanhtienhh: '49,500',
  },
  {
    stt: '3',
    ngayhoadon: dayjs().format('DD/MM/YYYY'),
    tendonvimuahang: 'Công ty TNHH Tâm Anh',
    makhachhang: '',
    diachi: '218, Đội Cấn, Ba Đình, Hà Nội',
    masothue: '0101391325',
    nguoimuahang: 'Phan Văn Tới',
    email: 'minhvt@amazon.com',
    hinhthucthanhtoan: 'Tiền mặt',
    loaitien: 'USD',
    tygia: '23,000',
    tyleck: '',
    tienck: '',
    thuegtgt: '',
    tienthuegtgt: '',

    tenhanghoadichvu: 'Đồng hồ Rolex',
    mahang: '',
    dvt: 'Cái',
    soluong: '4',
    dongia: '80',
    tyleckhh: '',
    tienckhh: '',
    thuegtgthh: '5',
    tienthuegtgthh: '16',
    thanhtienhh: '336',
  },
]

export const ALPHABET_CHARACTERS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const PERIOD = {
  YESTERDAY: 'YESTERDAY',
  TODAY: 'TODAY',
  LAST_WEEK: 'LAST_WEEK',
  THIS_WEEK: 'THIS_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  THIS_MONTH: 'THIS_MONTH',
  RECENT_30_DAYS: 'RECENT_30_DAYS',
  RECENT_7_DAYS: 'RECENT_7_DAYS',
  LAST_QUARTER: 'LAST_QUARTER',
  THIS_QUARTER: 'THIS_QUARTER',
  FIRST_HALF_YEAR: 'FIRST_HALF_YEAR',
  SECOND_HALF_YEAR: 'SECOND_HALF_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  THIS_YEAR: 'THIS_YEAR',
  MONTH_1: 'MONTH_1',
  MONTH_2: 'MONTH_2',
  MONTH_3: 'MONTH_3',
  MONTH_4: 'MONTH_4',
  MONTH_5: 'MONTH_5',
  MONTH_6: 'MONTH_6',
  MONTH_7: 'MONTH_7',
  MONTH_8: 'MONTH_8',
  MONTH_9: 'MONTH_9',
  MONTH_10: 'MONTH_10',
  MONTH_11: 'MONTH_11',
  MONTH_12: 'MONTH_12',
  QUARTER_1: 'QUARTER_1',
  QUARTER_2: 'QUARTER_2',
  QUARTER_3: 'QUARTER_3',
  QUARTER_4: 'QUARTER_4',
  CUSTOM: 'CUSTOM',
}

export const REGISTRATION_TAX_MESS_ORDER = {
  103: 0,
  102: 1,
  999: 2,
  100: 3,
}

export const DIGITAL_SIGN_METHOD = {
  USB: 'USB_TOKEN',
  HSM: 'HSM',
}

export const MESSAGE_SUCCESS = {
  ISSUED_INVOICE: 'Phát hành hóa đơn thành công',
  RE_ISSUED_INVOICE: 'Phát hành lại hóa đơn thành công',
}

export const TRANSACTION_STATUS = {
  NOT_CREATE_INVOICE: 'NOT_CREATE_INVOICE',
  CREATED_INVOICE: 'CREATED_INVOICE',
  RELEASED_INVOICE: 'RELEASED_INVOICE',
  MARKED: 'MARKED',
  RELEASING_INVOICE: 'RELEASING_INVOICE',
  RELEASE_ERROR: 'RELEASE_ERROR',
  CANCELLED: 'CANCELLED',
}

export const TYPE_TIME = {
  MOMENT: 'MOMENT',
  LOG: 'LOG',
}

export const SYSTEM_TYPE_INVOICE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
}

export const SEND_TAX_STATUS = {
  NOT_SENT_TO_AUTHORITY: 'NOT_SENT_TO_AUTHORITY',
  WAITING_FOR_AUTHORITY: 'WAITING_FOR_AUTHORITY',
  INVOICE_VALID: 'INVOICE_VALID',
  INVOICE_INVALID: 'INVOICE_INVALID',
  SENDING_TAX: 'SENDING_TAX',
  SEND_ERROR: 'SEND_ERROR',
  SIGN_ERROR: 'SIGN_ERROR',
}

export const TAX_STATE = {
  OPERATED: 'OPERATED',
  STOPPED_TAX_PAID: 'STOPPED_TAX_PAID',
  STOPPED_TAX_NOT_PAID: 'STOPPED_TAX_NOT_PAID',
  NOT_AT_REG_ADDRESS: 'NOT_AT_REG_ADDRESS',
}

export const CONFIRM_STATUS = {
  DRAFT: 'DRAFT',
  REJECTED: 'REJECTED',
  WAITING: 'WAITING',
  CONFIRMED: 'CONFIRMED',
  EMPTY: 'EMPTY',
}

export const INVOICE_CLASSIFY = {
  HD: 'HD',
  MTT: 'MTT',
  PXK: 'PXK',
}

export const listGender = ['Nam', 'Nữ']

export const listFieldOfActivity = [
  'Sản xuất',
  'Kinh doanh',
  'Hỗ trợ',
  'Văn phòng',
]

export default AppConstants

/**
 * @typedef {keyof PERIOD} Period
 */
