import { unwrapResult } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import companyTitleApi from 'api/companyTitleApi'
import { thunkGetCompanyTitleList } from 'features/Category/categorySlice'
import ModalEditCompanyTitle from 'features/Category/components/ModalEditCompanyTitle'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'

function CompanyTitleScreen() {
  // MARK --- Parmas: ---
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { companyTitles, pagination } = useAppSelector(
    (state) => state?.category,
  )

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')

  const [filters, setFilters] = useFilter(
    'companyTitleList',
    'gFiltersCompanyTitleList',
  )

  const companyTitleQuery = useQuery({
    queryKey: ['companyTitleList', filters, debouncedSearch],
    queryFn: () =>
      dispatch(thunkGetCompanyTitleList({ ...filters, q: debouncedSearch })),
    select: (res) => unwrapResult(res),
  })

  const [selectedCompanyTitles, setSelectedCompanyTitles] = useState([])
  const [toggleClearCompanyTitles, setToggledClearCompanyTitles] =
    useState(false)
  const [selectedCompanyTitleItem, setSelectedCompanyTitleItem] = useState(null)
  const [showingModalEditCompanyTitle, setShowingModalEditCompanyTitle] =
    useState(false)
  const [active, setActive] = useState('ADD')

  const handleDeleteCompanyTitle = useCallback(
    async (companyTitle) => {
      Swal.fire({
        title: t('Xác nhận'),
        text: `Bạn có muốn xóa chức danh ${companyTitle.name}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Đồng ý'),
        cancelButtonText: t('Hủy'),
        customClass: {
          confirmButton: 'btn btn-danger font-weight-bolder',
          cancelButton: 'btn btn-light font-weight-bolder',
        },
      }).then(async function (result) {
        if (result.value) {
          try {
            const deleteRes = await companyTitleApi.delete([
              companyTitle.companyTitleId,
            ])
            const { result: deleteResult } = deleteRes
            if (deleteResult === 'success') {
              ToastHelper.showSuccess(t('Xóa chức danh thành công'))
              companyTitleQuery.refetch()
            }
          } catch (error) {
            console.log(`Delete companyTitle error: ${error?.message}`)
          }
        }
      })
    },
    [t, companyTitleQuery],
  )

  const fullColumns = useMemo(
    () => [
      {
        name: 'Tên chức danh',
        // sortable: false,
        // width: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.name}
            </p>
          )
        },
      },
      {
        name: 'Mô tả',
        // sortable: false,
        // minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.description}
            </p>
          )
        },
      },
      {
        name: t('Status'),
        width: '150px',
        center: true,
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className={`badge badge-light-${
                row?.status === 'ACTIVE' ? 'success' : 'danger'
              } `}
            >
              {row.status === 'ACTIVE' ? 'Đang sử dụng' : 'Ngừng sử dụng'}
            </div>
          )
        },
      },
      {
        name: '',
        center: true,
        disabled: true,
        grow: 0,
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <TableAction
              titleActionText={t('Edit')}
              icon={<IconAction className="fa-light fa-edit"></IconAction>}
              onClick={() => handleEditCompanyTitle(row)}
            />
            <TableAction
              titleActionText={t('Delete')}
              icon={<IconAction className="fa-light fa-trash"></IconAction>}
              onClick={() => handleDeleteCompanyTitle(row)}
            />
            <TableAction
              titleActionText={t('Replicate')}
              icon={<IconAction className="fa-light fa-clone"></IconAction>}
              onClick={() => handleReplicateCompanyTitle(row)}
            />
          </div>
        ),
      },
    ],
    [handleDeleteCompanyTitle, t],
  )

  // MARK --- Function ---

  function handleDeleteMultiCompanyTitle() {
    const arrIdsToDelete = selectedCompanyTitles.map(
      (item) => item.companyTitleId,
    )

    Swal.fire({
      title: t('Xác nhận'),
      text: `Bạn có muốn xóa ${arrIdsToDelete.length} chức danh ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Đồng ý'),
      cancelButtonText: t('Hủy'),
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder',
        cancelButton: 'btn btn-light font-weight-bolder',
      },
    }).then(async function (result) {
      if (result.value) {
        const companyTitleIds = arrIdsToDelete
        try {
          const res = await companyTitleApi.delete(companyTitleIds)
          const { result } = res
          if (result === 'success') {
            clearSelectedCompanyTitles()
            ToastHelper.showSuccess(t('Xóa chức danh thành công'))
            companyTitleQuery.refetch()
          }
        } catch (error) {
          console.log(`Delete companyTitle error: ${error?.message}`)
        }
      }
    })
  }

  function handleSelectedCompanyTitleChanged(state) {
    setSelectedCompanyTitles(state.selectedRows)
  }

  function clearSelectedCompanyTitles() {
    setSelectedCompanyTitles([])
    setToggledClearCompanyTitles(!toggleClearCompanyTitles)
  }

  function handleEditCompanyTitle(companyTitle) {
    setActive('EDIT')
    setSelectedCompanyTitleItem(companyTitle)
    setShowingModalEditCompanyTitle(true)
  }

  function handleReplicateCompanyTitle(companyTitle) {
    setActive('REPLICATE')
    setSelectedCompanyTitleItem(companyTitle)
    setShowingModalEditCompanyTitle(true)
  }

  // MARK --- Hooks ---

  return (
    <ContentContainer wrapperClassName="list-replaced-invoice">
      <ContentContainer.Header
        titleContent={t('Chức danh')}
        description={`${t('Tổng')}: ${pagination?.total ?? 0} ${_.capitalize(
          t('Chức danh'),
        )}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('Thêm chức danh')}
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActive('ADD')
                  setShowingModalEditCompanyTitle(true)
                },
              }}
              iconCn="fa-regular fa-plus"
            />
          </Space>
        }
      >
        <Space>
          {selectedCompanyTitles.length > 0 ? (
            <CustomAntButton
              text={Utils.cn(
                t('Xoá'),
                selectedCompanyTitles.length,
                'chức danh',
              )}
              iconCn="fa-regular fa-trash"
              antProps={{
                type: 'primary',
                danger: true,
                onClick: handleDeleteMultiCompanyTitle,
              }}
            />
          ) : (
            <>
              <CustomAntInput
                type="search"
                borderRadius="sm"
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
              <label className="mr-2 mb-0" htmlFor="state">
                {_.capitalize(t('Trạng thái'))}
              </label>
              <KTFormSelect
                name="state"
                isCustom
                options={[{ name: 'Tất cả', value: '' }, ...AppData.status]}
                value={filters.status}
                onChange={(newValue) => {
                  setFilters({
                    page: 0,
                    status: newValue,
                  })
                }}
              />
            </>
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={fullColumns}
          dataSource={companyTitles}
          isLoading={companyTitleQuery.isFetching}
          isClickable
          handleDoubleClickRow={handleEditCompanyTitle}
          isSelectable
          selectedRows={{
            clearSelectedRows: toggleClearCompanyTitles,
            handleSelectedRows: handleSelectedCompanyTitleChanged,
            selectableRowSelected: (row) => {
              const arrId = selectedCompanyTitles.map(
                (item) => item.companyTitleId,
              )
              return arrId.includes(row.companyTitleId)
            },
          }}
          pagination={{
            currentPage: filters.page + 1,
            rowsPerPage: filters.limit ?? 0,
            total: pagination?.total ?? 0,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              if (iNewPage > 0) {
                iNewPage = iNewPage - 1
              }
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 0,
              })
            },
          }}
        />
      </ContentContainer.Body>

      {/* Modal companyTitle edit */}
      <ModalEditCompanyTitle
        show={showingModalEditCompanyTitle}
        onClose={() => {
          setShowingModalEditCompanyTitle(false)
          setSelectedCompanyTitleItem(null)
        }}
        active={active}
        // onExistDone={() => {
        //   setSelectedCompanyTitleItem(null);
        // }}
        companyTitleItem={selectedCompanyTitleItem}
        // onRefreshCompanyTitleList={() => {
        //   setSelectedCompanyTitleItem(null);
        //   getCompanyTitleList();
        // }}
      />
    </ContentContainer>
  )
}

export default CompanyTitleScreen
