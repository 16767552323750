// Import reducers
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

// app store
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_DEV_TOOLS_ENABLE == 1 ? true : false,
  middleware: (gdm) =>
    gdm({
      serializableCheck: false,
    }),
})

export default store

/**
 * @typedef {ReturnType<typeof store['getState']>} RootState
 * @typedef {store['dispatch']} AppDispatch
 */

/**
 * @template T
 * @typedef {import('@reduxjs/toolkit').PayloadAction<T>} RDAction<T>
 */
