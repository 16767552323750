import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import taxAuthorityMessageApi from 'api/taxAuthorityMessageApi'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import { ERR_ANNOUCE_STATUS } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import useFilter from 'hooks/useFilter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import './style.scss'
import dayjs from 'dayjs'

function ListTaxMessageErrAnnouce() {
  // MARK --- Parmas: ---
  const [tranmissionModalShow, setTranmissionModalShow] = useState(false)
  const [activeErrAnnouce, setActiveErrAnnouce] = useState(null)
  const { search, setSearch, debouncedSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'taxErrorAnnouceList',
    'gFiltersTaxErrorAnnouceList',
  )

  const { data: listErrAnnouceMessageData, isFetching: gettingList } = useQuery(
    {
      queryKey: ['listErrAnnouceMessage', filters, debouncedSearch],
      queryFn: () =>
        taxAuthorityMessageApi.listErrAnnouceMessage({
          ...filters,
          q: debouncedSearch,
        }),
      select: (res) => ({
        total: _.get(res, 'count', 0),
        rows: !res?.rows
          ? []
          : _.map(res.rows, (row, idx) => ({
              ...row,
              id: idx + 1,
            })),
      }),
    },
  )
  const renderIssueStatus = (issueStatus) => {
    switch (issueStatus) {
      case ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY:
        return <div className="badge badge-light-success">Chưa phát hành</div>
      case ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY:
        return <div className="badge badge-light-success">Chờ CQT xử lý</div>
      case ERR_ANNOUCE_STATUS.AUTHORITY_ACCEPT:
        return <div className="badge badge-light-primary">CQT đã tiếp nhận</div>
      case ERR_ANNOUCE_STATUS.AUTHORITY_DENY:
        return <div className="badge badge-light-danger">CQT từ chối</div>
      default:
      // return <div class="label label-lg label-outline-primary label-inline">Hoá đơn gốc</div>;
    }
  }

  const columns = useMemo(() => {
    return [
      {
        name: 'Tên thông điệp',
        center: true,
        width: '420px',
        cell: () => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              Thông điệp thông báo về hóa đơn điện tử đã lập có sai sót
            </p>
          )
        },
      },
      {
        name: 'Số',
        center: true,
        width: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.no}
            </p>
          )
        },
      },
      {
        name: 'Ngày lập',
        center: true,
        width: '120px',
        cell: (row) => {
          const date = dayjs(row?.date)
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {date.isValid() ? date.format('L') : ''}
            </p>
          )
        },
      },
      {
        name: 'Nơi lập thông báo',
        center: true,
        width: '120px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.place}
            </p>
          )
        },
      },
      {
        name: 'Mã thông điệp',
        center: true,
        width: '400px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.messageId}
            </p>
          )
        },
      },
      {
        name: 'Trạng thái phát hành',
        center: true,
        // width: '300px',
        cell: (row) => {
          return renderIssueStatus(row?.issueStatus)
        },
      },
      {
        name: 'Hành động',
        center: true,
        compact: true,
        grow: 0,
        cell: (row) => {
          return (
            <>
              <TableAction
                icon={<IconAction className="fa-light fa-clock-rotate-left" />}
                title="Xem lịch sử truyền nhận"
                onClick={() => {
                  setActiveErrAnnouce(row)
                  setTranmissionModalShow(true)
                }}
              />
            </>
          )
        },
      },
    ]
  }, [])

  return (
    <ContentContainer wrapperClassName="list-error=annoucement">
      <ContentContainer.Header
        titleContent={'Lịch sử truyền nhận TBSS'}
        description={'Tổng : ' + listErrAnnouceMessageData?.total + ' bản tin'}
        toolBar={
          <>
            <CustomAntInput
              type="search"
              borderRadius="sm"
              inputProps={{
                value: search,
                onChange: (e) => {
                  setSearch(e.target.value)
                },
              }}
            />
          </>
        }
      >
        <Space>
          <FilterPopover
            menuPosition="bottomRight"
            defaultValues={[
              {
                alias: 'Trạng thái phát hành',
                label: 'Trạng thái phát hành',
                formName: 'issueStatus',
                options: SelectOptions.taxMessageErrAnnouceIssueStatus,
                value: null,
                colSpan: 'FULL',
              },
            ]}
            onApply={setFilters}
            savedKey="gFiltersTaxErrorAnnouceList"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={columns}
          dataSource={listErrAnnouceMessageData?.rows}
          isLoading={gettingList}
          isClickable
          handleDoubleClickRow={(row) => {
            setActiveErrAnnouce(row)
            setTranmissionModalShow(true)
          }}
          pagination={{
            total: listErrAnnouceMessageData?.total,
            rowsPerPage: filters?.limit,
            currentPage: filters?.page,
            onChangePage: (page) => setFilters({ page }),
            onChangeRowsPerPage: (limit) => setFilters({ limit, page: 1 }),
          }}
        />
      </ContentContainer.Body>

      <ModalDataTransmissionLog
        show={tranmissionModalShow}
        onHide={() => setTranmissionModalShow(false)}
        errAnnouce={activeErrAnnouce}
      />
    </ContentContainer>
  )
}

export default ListTaxMessageErrAnnouce
