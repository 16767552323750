import { Card } from 'antd'
import TabCustom from 'general/components/TabCustom'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import TabCurrencySetting from './components/tabs/TabCurrencySetting'
import TabEmailSetting from './components/tabs/TabEmailSetting'
import TabInvoiceSetting from './components/tabs/TabInvoiceSetting'
import TabLogSetting from './components/tabs/TabLogSetting'
import TabNumberSetting from './components/tabs/TabNumberSetting'
import TabVatSetting from './components/tabs/TabVatSetting'
import './style.scss'
import { useSystemConfigSelector } from './SystemConfigurationSlice'

export default function SystemConfiguration() {
  const [tabs] = useState([
    'Định dạng số',
    'Tiền tệ',
    'Hóa đơn',
    'Email',
    'Thuế GTGT',
    'Cấu hình tự động',
  ])
  const [currentTab, setTab] = useSearchParams({
    tab: tabs[0],
  })

  const systemConfig = useSystemConfigSelector()

  return (
    <div className="SystemConfiguration">
      <TabCustom
        tabs={tabs}
        activeTab={currentTab.get('tab')}
        handleClick={(tabName) => {
          setTab({ tab: tabName })
        }}
      />
      <Card
        className="flex-grow-1"
        bordered={false}
        bodyStyle={{
          height: '100%',
        }}
      >
        {currentTab.get('tab') === 'Định dạng số' &&
        systemConfig.formatNumber ? (
          <TabNumberSetting />
        ) : null}
        {currentTab.get('tab') === 'Tiền tệ' && systemConfig.currency ? (
          <TabCurrencySetting />
        ) : null}
        {currentTab.get('tab') === 'Hóa đơn' && systemConfig.invoiceSetting ? (
          <TabInvoiceSetting />
        ) : null}
        {currentTab.get('tab') === 'Email' && systemConfig.emailTemplate ? (
          <TabEmailSetting />
        ) : null}
        {currentTab.get('tab') === 'Thuế GTGT' && systemConfig.VATConfig ? (
          <TabVatSetting />
        ) : null}
        {currentTab.get('tab') === 'Cấu hình tự động' &&
        systemConfig.autoDataLog ? (
          <TabLogSetting />
        ) : null}
      </Card>
    </div>
  )
}
