import styled from 'styled-components'

export const FilterTitleWrapper = styled.div({
  display: 'inline-flex',
  flexDirection: 'row',
  msFlexDirection: 'column',
  justifyContent: 'space-between',
  WebkitJustifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '0.75rem',
  rowGap: '1rem',
})

export const FilterContentWrapper = styled.form({
  minHeight: '100%',
  maxHeight: '30rem',
  padding: '0.75rem',
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  gap: '5px',
})

export const FilterFooter = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'end',
  flexGrow: 1,
  justifyContent: 'end',
  padding: '0.25rem',
  position: 'sticky',
  bottom: '0',
  gap: '1.25rem',
  paddingTop: '1rem',
})
