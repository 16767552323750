import dayjs from 'dayjs'

export default function diffMonthDay(expireDate) {
  try {
    const _expireDate = dayjs(expireDate)
    let now = dayjs().startOf('date')
    let tmpDate = _expireDate.clone().set('date', now.get('date'))
    let diffMonth = tmpDate.diff(now, 'months')
    let diffDate = parseInt(_expireDate.get('date')) - parseInt(now.get('date'))
    if (diffDate < 0) diffMonth = diffMonth - 1
    diffDate = diffDate < 0 ? diffDate * -1 : diffDate

    return {
      diffMonth: diffMonth,
      diffDate: diffDate,
    }
  } catch (error) {
    console.error(error)
  }
}
