import { unwrapResult } from '@reduxjs/toolkit'
import { Badge, Button, Dropdown, Tooltip } from 'antd'
import ticketDraftApi from 'api/ticektDraftApi'
import {
  setAppNotInstalledToolNotiModalShow,
  setAppSpinning,
} from 'app/appSlice'
import dayjs from 'dayjs'
import ListDraftTicketDropdown from 'features/Ticket/components/ListDraftTicketDropdown'
import ModalTicketDetail from 'features/Ticket/components/ModalTicketDetail'
import ModalTicketTaxRateDetail from 'features/Ticket/components/ModalTicketTaxRateDetail'
import { TicketCreateTable } from 'features/Ticket/components/TicketCreateTable'
import { thunkGetListRoute } from 'features/Ticket/ticketSlice'
import CustomButton from 'general/components/Button/CustomButton'
import TicketFooter from 'general/components/Footers/TicketFooter'
import TicketLayout from 'general/components/TicketLayout'
import {
  SIGN_TOOL_PARAMETER,
  SUBMIT_ACTION,
  TICKET_TYPE_SERVICE,
} from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useGetTicketTemplates from 'hooks/Queries/useGetTicketTemplates'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import ModalShowAndPrintTickets from 'features/Ticket/components/ModalShowAndPrintTickets'
import ModalTicketGXDetail from 'features/Ticket/components/ModalTicketGXDetail'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import { thunkGetAccountProfile } from 'app/authSlice'
let submitAction

export const CreateTicket = () => {
  const todayInUTC = dayjs().utc()
  const navigate = useNavigate()
  const { currency } = useAppSelector((state) => state?.systemConfig)
  const { company } = useAppSelector((state) => state.system)
  const { currentAccount } = useAppSelector((state) => state?.auth)
  const [modalTicketDetailShow, setModalTicketDetailShow] = useState(false)
  const [modalTicketGXDetailShow, setModalTicketGXDetailShow] = useState(false)
  const [modalTicketTaxRateDetailShow, setModalTicketTaxRateDetailShow] =
    useState(false)
  const dispatch = useAppDispatch()
  const [activeTemplate, setActiveTemplate] = useState({})
  const [tableData, setTableData] = useState([])
  const [draftTickets, setDraftTickets] = useState([]) // Danh sách các ticket nháp
  const [ticketDraftId, setTicketDraftId] = useState(null)
  const [modalShowAndPrintTicketsInfo, setModalShowAndPrintTicketsInfo] =
    useState({ show: false, ticketIds: [] })

  const { data: ticketTemplates } = useGetTicketTemplates()
  console.log(ticketTemplates)
  const namePersonCreate = useMemo(() => {
    return currentAccount.fullname
  }, [currentAccount])

  const getListRoute = useCallback(
    async (params) => {
      try {
        unwrapResult(dispatch(thunkGetListRoute(params)))
      } catch (error) {
        console.error(`[get list route error]:${error.message}`)
      }
    },
    [dispatch],
  )

  useEffect(() => {
    fetchListDraftTickets()
    getListRoute()
  }, [getListRoute])

  const fetchListDraftTickets = async () => {
    try {
      const res = await ticketDraftApi.listDraft()
      if (res?.result === 'success') {
        const { data } = res
        setDraftTickets(data)
      }
    } catch (err) {
      ToastHelper.showError('Không tìm thấy dữ liệu')
    }
  }

  const createDraftTicket = async (ticketDraftItems) => {
    try {
      dispatch(setAppSpinning(true))
      const params = { ticketDraftItems }
      const res = await ticketDraftApi.create(params)
      if (res?.result === 'success') {
        ToastHelper.showSuccess('Tạo vé nháp thành công')
        handleChangeTableData([])
        fetchListDraftTickets()
        // ký vé
        if (submitAction === SUBMIT_ACTION.SAVE_AND_SIGN) {
          const ticketDraft = res.data
          const { ticketDraftId, ticketDraftItems } = ticketDraft

          if (company?.activeSigningMethod == 'HSM') {
            await handleHsmSignTicketDraft(ticketDraftId)
          } else {
            const accessToken = localStorage.getItem(PreferenceKeys.accessToken)
            let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${SIGN_TOOL_PARAMETER.TICKETS_SIGNED_BY_SELLER},`
            ticketDraftItems.map((item, index) => {
              const { ticketDraftItemId } = item
              if (index + 1 === ticketDraftItems.length) {
                urlSchema = urlSchema + `${ticketDraftItemId}`
              } else {
                urlSchema = urlSchema + `${ticketDraftItemId};`
              }
            })
            Utils.openToolSignInvoice(urlSchema, accessToken, () =>
              dispatch(setAppNotInstalledToolNotiModalShow(true)),
            )
          }
        }
      }
    } catch (err) {
      ToastHelper.showError('Tạo vé nháp thất bại')
      console.log(err)
    } finally {
      dispatch(setAppSpinning(false))
    }
  }

  const updateDraftTicket = async (ticketDraftItems) => {
    try {
      dispatch(setAppSpinning(true))
      const params = { ticketDraftId, ticketDraftItems }
      const res = await ticketDraftApi.update(params)
      if (res?.result === 'success') {
        ToastHelper.showSuccess('Cập nhật vé nháp thành công')
        fetchListDraftTickets()
        changeActionUpdateToCreate()
        handleChangeTableData([])
        // ký vé
        if (submitAction === SUBMIT_ACTION.SAVE_AND_SIGN) {
          const ticketDraft = res.data
          const { ticketDraftId, ticketDraftItems } = ticketDraft

          if (company?.activeSigningMethod == 'HSM') {
            await handleHsmSignTicketDraft(ticketDraftId)
          } else {
            const accessToken = localStorage.getItem(PreferenceKeys.accessToken)
            let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${SIGN_TOOL_PARAMETER.TICKETS_SIGNED_BY_SELLER},`
            ticketDraftItems.map((item, index) => {
              const { ticketDraftItemId } = item
              if (index + 1 === ticketDraftItems.length) {
                urlSchema = urlSchema + `${ticketDraftItemId}`
              } else {
                urlSchema = urlSchema + `${ticketDraftItemId};`
              }
            })
            Utils.openToolSignInvoice(urlSchema, accessToken, () =>
              dispatch(setAppNotInstalledToolNotiModalShow(true)),
            )
          }
        }
      }
    } catch (err) {
      ToastHelper.showError('Cập nhật vé nháp thất bại')
      console.log(err)
    } finally {
      dispatch(setAppSpinning(false))
    }
  }

  const handleSelectDraftTicket = (draftTicket) => {
    const { ticketDraftItems } = draftTicket
    const currentTicketDraftId = draftTicket.ticketDraftId
    let modifiedTicketDraftItems = []
    for (let ticketDraftItem of ticketDraftItems) {
      const { serial } = ticketDraftItem
      const ticketTemplate = _.find(ticketTemplates, { serial: serial })
      const { category } = ticketTemplate
      if (category == 1) {
        const index = _.findIndex(modifiedTicketDraftItems, {
          serial: serial,
        })
        if (index !== -1) {
          modifiedTicketDraftItems[index].quantity =
            modifiedTicketDraftItems[index].quantity + 1
        } else {
          modifiedTicketDraftItems.push({ ...ticketDraftItem })
        }
      } else {
        modifiedTicketDraftItems.push({ ...ticketDraftItem })
      }
    }
    handleChangeTableData(modifiedTicketDraftItems)
    setTicketDraftId(currentTicketDraftId)
  }

  const handleChangeTableData = useCallback(
    (newTableData) => {
      newTableData.map((item, index) => {
        item.index = index
        item.namePersonCreate = namePersonCreate
      })
      setTableData(newTableData)
    },
    [namePersonCreate],
  )

  const { totalTicketQuantity, totalTicketPrice } = useMemo(() => {
    let totalQty = 0,
      totalPrc = 0
    for (let ticket of tableData) {
      const { quantity, totalPrice } = ticket
      totalQty += quantity
      totalPrc += totalPrice * quantity
    }
    return { totalTicketQuantity: totalQty, totalTicketPrice: totalPrc }
  }, [tableData])

  const handleChooseTicketTemplate = (ticketTemplate) => {
    let { nameTicket, serial, taxRate, category_ticket, invoiceTemplateId } =
      ticketTemplate
    const { typeServices } = category_ticket
    const totalPrice = ticketTemplate?.ticketPrice
    setActiveTemplate(ticketTemplate)
    let newTableData
    switch (typeServices) {
      case 1:
        // check trong các vé nháp đã có mẫu này chưa
        const checkExistedTicketTemplate = _.find(tableData, {
          serial: ticketTemplate.serial,
        })

        if (checkExistedTicketTemplate) {
          ToastHelper.showError(
            'Mẫu vé đã được chọn, vui lòng thay đổi số lượng',
          )
          return
        }
        const taxMoney = Utils.calculateTaxMoneyWithTotalPrice(
          totalPrice,
          taxRate,
        )
        newTableData = [
          ...tableData,
          {
            nameTicket: nameTicket,
            invoiceTemplateId,
            serial: serial,
            quantity: 1,
            price: totalPrice - taxMoney,
            dateCreate: Utils.formatDate(todayInUTC),
            // nameRoute: nameRoute,
            // pointStart: pointStart,
            // pointEnd: pointEnd,
            // noSeatCar: ticketTemplate?.noSeatCar,
            // noCar: ticketTemplate?.noCar,
            taxRate: taxRate,
            taxMoney: taxMoney,
            totalPrice: totalPrice,
          },
        ]
        handleChangeTableData(newTableData)
        break

      case 3:
        setModalTicketTaxRateDetailShow(true)
        break

      case TICKET_TYPE_SERVICE.VT:
        setModalTicketDetailShow(true)
        break

      case TICKET_TYPE_SERVICE.GX:
        setModalTicketGXDetailShow(true)
        break

      default:
        return
    }
  }

  const changeActionUpdateToCreate = () => {
    setTicketDraftId(null)
  }

  const handleRemoveRow = (row) => {
    // const newTableData = _.filter(
    //   tableData,
    //   (item) => item?.serial !== row?.serial,
    // )
    // let newTableData
    const newTableData = _.filter(
      tableData,
      (item) => item?.index !== row?.index,
    )
    handleChangeTableData(newTableData)
  }

  const handleSubmit = async () => {
    // Có ticketDraftId thì là update
    const ticketDraftItems = _.map(tableData, (item) => {
      const { totalPrice } = item
      const totalPriceVnese = Utils.numberToWords(
        totalPrice, //num
        'vi', // unit
        '', // separation
        'đồng', //endSymbol
        'phẩy', // afterdecimal
        '',
        '',
        currency?.zeroInten,
        currency?.zeroInThousand,
        currency?.evenDisplay,
      )
      return {
        ...item,
        totalPriceVnese,
        sellerName: company?.companyName,
        sellerTaxCode: company?.taxCode,
        sellerFullAddress: company?.businessPermitAddress,
        sellerPhone: company?.companyPhone,
        sellerEmail: company?.companyEmail,
        sellerAccountNumber: company?.bankAccountNumber,
        sellerBankName: company.bankName,
        sellerFax: company?.fax,
        sellerWebsite: company?.website,
      }
    })
    if (ticketDraftId) {
      await updateDraftTicket(ticketDraftItems)
      fetchListDraftTickets()
    } else {
      const res = await createDraftTicket(ticketDraftItems)
      fetchListDraftTickets()
      if (res) {
        dispatch(thunkGetAccountProfile())
      }
    }
  }

  const handleAddTicketItem = ({
    ticketTemplate,
    noCar,
    noSeatCar,
    departureDate,
    departureTime,
    price,
    taxRate,
    taxMoney,
    totalPrice,
    routeStart,
    routeEnd,
    nameRoute,
    ticketPriceReduce,
    ticketPriceWithoutReduce,
    rateVatInAmount,
    isTaxReduction,
  }) => {
    const {
      name,
      serial,
      invoiceTemplateId,
      // taxRate,
      // price,
    } = ticketTemplate
    const departureDateTime =
      departureDate && departureTime
        ? departureDate + ' ' + departureTime
        : null
    const tokenizationString = ';'
    const noSeatCarArray = _.split(noSeatCar, tokenizationString)
    // const ticketPrice = ticketTemplate?.price ?? price
    // const ticketTaxRate = ticketTemplate?.taxRate ?? taxRate

    const newTickets = noSeatCarArray.map((seat) => {
      return {
        nameTicket: name,
        invoiceTemplateId,
        serial,
        quantity: 1,
        price,
        taxRate,
        taxMoney,
        totalPrice,
        dateCreate: Utils.formatDate(todayInUTC),
        nameRoute,
        routeStart,
        routeEnd,
        noSeatCar: seat,
        noCar,
        departureDateTime,
        ticketPriceReduce,
        ticketPriceWithoutReduce,
        rateVatInAmount,
        isTaxReduction,
      }
    })
    switch (activeTemplate?.category_ticket?.typeServices) {
      case 'VT':
        setModalTicketDetailShow(false)
        break
      case 'GX':
        setModalTicketGXDetailShow(false)
        break
    }

    handleChangeTableData([...tableData, ...newTickets])
  }

  const handleHsmSignTicketDraft = async (ticketDraftId) => {
    let res = await ticketDraftApi.hsmSign({
      ticketDraftId,
    })

    let { errorSignTickets, successSignTickets } = res
    let describeText = `Phát hành thành công ${successSignTickets.length} vé, thất bại ${errorSignTickets.length} vé.`

    // dispatch(
    //   showNotification({
    //     title: 'Thông báo',
    //     describeText,
    //     icon: 'fa-light fa-circle-info text-primary',
    //   }),
    // )
    ToastHelper.showSuccess(describeText)
    setModalShowAndPrintTicketsInfo({
      show: true,
      ticketIds: successSignTickets?.map(
        ({ createdTicketId: ticketId }) => ticketId,
      ),
    })
  }

  const handleCloseModalShowAndPrintTicketsInfo = () => {
    setModalShowAndPrintTicketsInfo({ show: false, ticketIds: [] })
  }

  return (
    <div className="create-ticket-screen">
      <div className="left-column">
        <div className="left-column-header">
          <span>Chọn mẫu vé cần xuất</span>
          {/* <CustomButton
            height="36px"
            width="38px"
            borderColor="#CFD9E0"
            color="#212529"
            icon={'far fa-search'}
          /> */}
        </div>
        <div className="ticket-container">
          {!_.isEmpty(ticketTemplates) &&
            ticketTemplates.map((ticketTemplate, index) => {
              const { name, serial, ticketPrice } = ticketTemplate
              return (
                <TicketLayout
                  name={name}
                  serial={serial}
                  price={ticketPrice}
                  key={index}
                  onClick={() => {
                    handleChooseTicketTemplate(ticketTemplate)
                  }}
                />
              )
            })}
        </div>
      </div>

      <div className="right-column">
        <div className="right-column-header">
          {ticketDraftId ? (
            <CustomButton
              text="Đổi sang xuất vé mới"
              onClick={changeActionUpdateToCreate}
            />
          ) : (
            <span className="right-column-header-name">Xuất vé mới</span>
          )}

          <div className="group-button">
            <Tooltip
              destroyTooltipOnHide
              placement="leftTop"
              title="Xem danh sách vé bị CQT từ chối cấp mã"
            >
              <Button
                style={{
                  height: '36px',
                  width: '38px',
                  padding: '0px',
                }}
                type="default"
              >
                <i
                  className="fa-regular fa-file-exclamation "
                  style={{
                    color: '#212529',
                  }}
                />
              </Button>
              {/* <CustomButton
                borderColor="#CFD9E0"
                color="#212529"
                icon={'far fa-file-exclamation'}
                height="36px"
                width="38px"
              /> */}
            </Tooltip>
            <Tooltip destroyTooltipOnHide title="Vé nháp">
              <Dropdown
                dropdownRender={() => (
                  <ListDraftTicketDropdown
                    fetchListDraftTickets={fetchListDraftTickets}
                    draftTickets={draftTickets}
                    handleSelectDraftTicket={handleSelectDraftTicket}
                    handleChangeTableData={handleChangeTableData}
                  />
                )}
              >
                <Badge
                  style={{
                    zIndex: 3,
                  }}
                  count={draftTickets?.length ?? 0}
                >
                  <Button
                    style={{
                      height: '36px',
                      width: '38px',
                      padding: '0px',
                    }}
                    type="default"
                  >
                    <i
                      className="fa-regular fa-file-alt "
                      style={{
                        color: '#212529',
                      }}
                    />
                  </Button>
                  {/* <CustomButton
                    height="36px"
                    width="38px"
                    borderColor="#CFD9E0"
                    color="#212529"
                    icon={'far fa-file-alt'}
                  /> */}
                </Badge>
              </Dropdown>
            </Tooltip>

            <Tooltip
              destroyTooltipOnHide
              placement="topLeft"
              title="Bảng tổng hợp vé đã xuất"
            >
              <Button
                style={{
                  height: '36px',
                  width: '38px',
                  padding: '0px',
                }}
                type="default"
              >
                <i
                  className="fa-regular fa-chart-line"
                  style={{
                    color: '#212529',
                  }}
                />
              </Button>
              {/* <CustomButton
                height="36px"
                width="38px"
                borderColor="#CFD9E0"
                color="#212529"
                icon={'far fa-file-chart-line'}
              /> */}
            </Tooltip>
          </div>
        </div>
        {tableData.length !== 0 && (
          <TicketCreateTable
            tableData={tableData}
            handleChangeTableData={handleChangeTableData}
            handleRemoveRow={handleRemoveRow}
            ticketTemplates={ticketTemplates}
          />
        )}
        <TicketFooter
          buttonCancelOnClick={() => {
            navigate('/hoa-don-dau-ra/tem-ve/ve-dien-tu/ve-da-xuat')
          }}
          buttonSaveOnClick={() => {
            submitAction = SUBMIT_ACTION.SAVE
            handleSubmit()
          }}
          buttonSaveAndSignOnClick={() => {
            submitAction = SUBMIT_ACTION.SAVE_AND_SIGN
            handleSubmit()
          }}
          totalTicketQuantity={totalTicketQuantity}
          totalTicketPrice={totalTicketPrice}
        />
      </div>

      <ModalTicketDetail
        show={modalTicketDetailShow}
        header="Chi tiết vé"
        setShow={setModalTicketDetailShow}
        ticketTemplate={activeTemplate}
        handleConfirm={handleAddTicketItem}
      />

      <ModalTicketGXDetail
        show={modalTicketGXDetailShow}
        header="Chi tiết vé"
        setShow={setModalTicketGXDetailShow}
        ticketTemplate={activeTemplate}
        handleConfirm={handleAddTicketItem}
      />

      <ModalTicketTaxRateDetail
        show={modalTicketTaxRateDetailShow}
        header="Chi tiết vé"
        setShow={setModalTicketTaxRateDetailShow}
        ticketTemplate={activeTemplate}
        handleConfirm={handleAddTicketItem}
      />

      <ModalShowAndPrintTickets
        ticketIds={modalShowAndPrintTicketsInfo.ticketIds}
        show={!!modalShowAndPrintTicketsInfo.show}
        handleClose={handleCloseModalShowAndPrintTicketsInfo}
      />
    </div>
  )
}
