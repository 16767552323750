import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import ToastHelper from 'general/helpers/ToastHelper'
import QueryString from 'qs'

const KNOWN_ERROR_MESSAGES = {
  'NOT FOUND': 'Không tìm thấy thông tin doanh nghiệp',
  'UNKNOWN EXCEPTION': 'Lỗi không xác định',
}

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => QueryString.stringify(params),
})

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message
      ToastHelper.showError(
        KNOWN_ERROR_MESSAGES[errorMessage] ?? 'Đã có lỗi xảy ra',
      )
    } else if (error instanceof Error) {
      ToastHelper.showError(error.message)
    }
    // return Promise.reject(error)
  },
)

/** @returns {import("axios").AxiosPromise<ReturnCompanyTaxCodeInfo>} */
const fetcher = (body) =>
  axiosClient.post('/company-identity/get-detail-info', body)

export default function useMutationGetCompanyInfo() {
  return useMutation({
    mutationKey: ['shared', 'get-company-info'],
    mutationFn: fetcher,
  })
}
