import extendFieldApi from 'api/extendFieldApi'
import routeApi from 'api/routeApi'
import templateInvoiceApi from 'api/templateInvoiceApi'
import templateTicketApi from 'api/templateTicketApi'
import TemplateInvoiceHelper from 'general/helpers/TemplateInvoiceHelper'
import Global from 'general/utils/Global'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

// initialState: {
//   activePanel: 'main_ctrl',

//   instance: {
//     logoInfo: {
//       logoFile: null,
//       x: 0,
//       y: 0,
//       width: 100,
//       height: 100,
//       opacity: 0.24,
//     },
//     backgroundInfo: {
//       backgroundData: null,
//       x: 100,
//       y: 100,
//       width: 500,
//       height: 500,
//       opacity: 0.24,
//     },
//     RegionConfigs: [
//       {
//         regionName: 'seller-info',
//         dataconfig: [
//           {
//             dataField: 'sellerName',
//             value: {
//               value: 'CÔNG TY CỔ PHẦN ĐẦU TƯ INDO',
//               show: true,
//             },
//             label: {
//               value: 'Đơn vị bán hàng',
//               show: false,
//             },
//             labelEn: {
// fontStyle: "italic",
//               value: '(Seller)',
//               show: false,
//             },
//             mergeField: '',
//           },
//         ],
//       },
//     ],
//   },
// },

export const thunkGetSurrounds = createAsyncThunk(
  'template-invoice/surrounds',
  async (params, thunkParams) => {
    try {
      const response = await templateInvoiceApi.getSurrounds()

      const { rejectWithValue } = thunkParams

      if (response.result === 'failed') {
        rejectWithValue(response)
      }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

export const thunkGetBackgrounds = createAsyncThunk(
  'template-invoice/backgrounds',
  async (params, thunkParams) => {
    try {
      const response = await templateInvoiceApi.getBackgrounds()

      const { rejectWithValue } = thunkParams

      if (response.result === 'failed') {
        rejectWithValue(response)
      }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

export const thunkGetExtendFields = createAsyncThunk(
  'template-invoice/extend-fields',
  async (params, thunkParams) => {
    try {
      const response = await extendFieldApi.getExtendFields()

      const { rejectWithValue } = thunkParams

      if (response.result === 'failed') {
        rejectWithValue(response)
      }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

export const thunkGetRoutes = createAsyncThunk(
  'template-invoice/routes',
  async (params, thunkParams) => {
    try {
      const response = await routeApi.getListOfRoute()

      const { rejectWithValue } = thunkParams

      if (response.result === 'failed') {
        rejectWithValue(response)
      }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

export const thunkGetCategories = createAsyncThunk(
  'template-invoice/categories',
  async (params, thunkParams) => {
    try {
      const response = await templateInvoiceApi.getCategories()

      const { rejectWithValue } = thunkParams

      if (response.result === 'failed') {
        rejectWithValue(response)
      }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

export const thunkGetTicketCategories = createAsyncThunk(
  'template-invoice/category_tickets',
  async (params, thunkParams) => {
    try {
      const response = await templateTicketApi.getCategories()

      const { rejectWithValue } = thunkParams

      if (response.result === 'failed') {
        rejectWithValue(response)
      }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

export const thunkGetTemplates = createAsyncThunk(
  'template-invoice/templates',
  async (params, thunkParams) => {
    try {
      const response = await templateInvoiceApi.getTemplates(params)

      const { rejectWithValue } = thunkParams

      if (response.result === 'failed') {
        rejectWithValue(response)
      }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

const initialInstance = {}

const templeInvoiceSlice = createSlice({
  name: 'templeInvoice',
  initialState: {
    pagination: { perPage: Global.gDefaultPagination, currentPage: 0 },

    activePanel: 'main_ctrl',
    activeRegion: null,
    activeDataField: null,
    activeDataFieldPropertyName: null,
    configDynamicFieldPanel: false /* Chuyển sang  thiết lập truờng nhập liệu */,
    mulTaxRate: false,
    showWatermark: false,

    extendFields: null,
    backgrounds: [],
    surrounds: [],
    categories: [],
    ticketCategories: [],
    routes: [],
    totalTemplate: 0,
    templates: [],

    instance: initialInstance,

    showModalExportTemplate: false,
    showModalExportTicket: false,
  },
  reducers: {
    setPaginationPerPage: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
        },
      }
    },

    setShowWatermark: (state, action) => {
      state.showWatermark = !!action.payload
    },

    setMulTaxRate: (state, action) => {
      state.mulTaxRate = !!action.payload
    },

    setConfigDynamicFieldPanel: (state, action) => {
      state.configDynamicFieldPanel = action.payload
    },

    setActiveDataFieldPropertyName: (state, action) => {
      state.activeDataFieldPropertyName = action.payload
    },

    setActiveRegionBaseOnName: (state, action) => {
      let regionName = action.payload

      if (!regionName) {
        state.activeRegion = null
        state.activeDataField = null
        return
      }

      state.activeRegion = state.instance.RegionConfigs.find(
        (region) => region.regionName === regionName,
      )
    },

    setActiveDataField: (state, action) => {
      state.activeDataField = action.payload
      if (!state.activeDataFieldPropertyName) {
        state.activeDataFieldPropertyName = 'label'
      }
    },

    setActivePanel: (state, action) => {
      state.activePanel = action.payload
    },

    setShowModalExportTemplate: (state, action) => {
      state.showModalExportTemplate = action.payload
    },

    setShowModalExportTicket: (state, action) => {
      state.showModalExportTicket = action.payload
    },

    // instance
    setInstanceInfo: (state, action) => {
      if (!action.payload) {
        state.instance = null
        return
      }
      for (const [key, value] of Object.entries(action.payload)) {
        state.instance[key] = value
      }
    },

    setLogoInfo: (state, action) => {
      if (!action.payload) {
        state.instance.logoInfo = null
        return
      }

      let { logoFile, logoPosition, x, y, width, height } = action.payload

      let originLogoInfo = state.instance.logoInfo
      state.instance.logoInfo = {
        logoFile: logoFile === undefined ? originLogoInfo?.logoFile : logoFile,
        logoPosition:
          logoPosition === undefined
            ? originLogoInfo?.logoPosition
            : logoPosition,
        x: x === undefined ? originLogoInfo?.x : x,
        y: y === undefined ? originLogoInfo?.y : y,
        width: width === undefined ? originLogoInfo?.width : width,
        height: height === undefined ? originLogoInfo?.height : height,
      }
    },

    setBackgroundInfo: (state, action) => {
      if (!action.payload) {
        state.instance.backgroundInfo = null
        return
      }

      let { backgroundData, x, y, width, height, opacity, backgroundColor } =
        action.payload

      let originBackgroundInfo = state.instance.backgroundInfo
      state.instance.backgroundInfo = {
        backgroundData:
          backgroundData === undefined
            ? originBackgroundInfo?.backgroundData
            : backgroundData,
        x: x === undefined ? originBackgroundInfo?.x : x,
        y: y === undefined ? originBackgroundInfo?.y : y,
        width: width === undefined ? originBackgroundInfo?.width : width,
        height: height === undefined ? originBackgroundInfo?.height : height,
        opacity:
          opacity === undefined ? originBackgroundInfo?.opacity : opacity,
        backgroundColor:
          backgroundColor === undefined
            ? originBackgroundInfo?.backgroundColor
            : backgroundColor,
      }
    },

    setCustomBackgroundInfo: (state, action) => {
      if (!action.payload) {
        state.instance.customBackgroundInfo = null
        return
      }

      let { file, opacity, x, y, width, height, position } = action.payload

      let originCustomBackground = state.instance.customBackgroundInfo
      state.instance.customBackgroundInfo = {
        file: file === undefined ? originCustomBackground?.file : file,
        opacity:
          opacity === undefined ? originCustomBackground?.opacity : opacity,
        position:
          position === undefined ? originCustomBackground?.position : position,
        x: x === undefined ? originCustomBackground?.x : x,
        y: y === undefined ? originCustomBackground?.y : y,
        width: width === undefined ? originCustomBackground?.width : width,
        height: height === undefined ? originCustomBackground?.height : height,
      }
    },

    setWatermarkInfo: (state, action) => {
      if (!action.payload) {
        state.instance.watermarkInfo = null
        return
      }

      let { opacity } = action.payload

      let originWatermarkInfo = state.instance.watermarkInfo
      state.instance.watermarkInfo = {
        opacity: opacity === undefined ? originWatermarkInfo.opacity : opacity,
      }
    },

    plusMinusFontSizeLineHeightAll: (state, action) => {
      const { func, propertyName } = action.payload

      if (func === 'PLUS') {
        state.instance[propertyName] =
          state.instance[propertyName] +
          (propertyName == 'lineHeight' ? 0.1 : 1)

        state.instance.RegionConfigs = state.instance.RegionConfigs.map(
          (region) => ({
            ...region,
            regionConfigs: region.regionConfigs.map((reConf) => ({
              ...reConf,
              label: {
                ...reConf.label,
                [propertyName]: reConf.label[propertyName]
                  ? reConf.label[propertyName] +
                    (propertyName == 'lineHeight' ? 0.1 : 1)
                  : reConf.label[propertyName],
              },
              labelEn: {
                ...reConf.labelEn,
                [propertyName]: reConf.labelEn[propertyName]
                  ? reConf.labelEn[propertyName] +
                    (propertyName == 'lineHeight' ? 0.1 : 1)
                  : reConf.labelEn[propertyName],
              },
              value: {
                ...reConf.value,
                [propertyName]: reConf.value[propertyName]
                  ? reConf.value[propertyName] +
                    (propertyName == 'lineHeight' ? 0.1 : 1)
                  : reConf.value[propertyName],
              },
            })),
          }),
        )
      } else if (func === 'MINUS') {
        state.instance[propertyName] =
          state.instance[propertyName] -
          (propertyName == 'lineHeight' ? 0.1 : 1)

        state.instance.RegionConfigs = state.instance.RegionConfigs.map(
          (region) => ({
            ...region,
            regionConfigs: region.regionConfigs.map((reConf) => ({
              ...reConf,
              label: {
                ...reConf.label,
                [propertyName]: reConf.label[propertyName]
                  ? reConf.label[propertyName] -
                    (propertyName == 'lineHeight' ? 0.1 : 1)
                  : reConf.label[propertyName],
              },
              labelEn: {
                ...reConf.labelEn,
                [propertyName]: reConf.labelEn[propertyName]
                  ? reConf.labelEn[propertyName] -
                    (propertyName == 'lineHeight' ? 0.1 : 1)
                  : reConf.labelEn[propertyName],
              },
              value: {
                ...reConf.value,
                [propertyName]: reConf.value[propertyName]
                  ? reConf.value[propertyName] -
                    (propertyName == 'lineHeight' ? 0.1 : 1)
                  : reConf.value[propertyName],
              },
            })),
          }),
        )
      } else {
        return
      }
    },

    setSurroundInfo: (state, action) => {
      if (!action.payload) {
        state.instance.surroundInfo = null
        return
      }
      let { surroundData, surroundColor } = action.payload

      let originSurroundInfo = state.instance.surroundInfo
      state.instance.surroundInfo = {
        surroundData:
          surroundData === undefined
            ? originSurroundInfo?.surroundData
            : surroundData,
        surroundColor:
          surroundColor === undefined
            ? originSurroundInfo?.surroundColor
            : surroundColor,
      }
    },

    setSpecifiedRegion: (state, action) => {
      let { regionName, propertyData } = action.payload

      state.instance.RegionConfigs = state.instance.RegionConfigs?.map(
        (reconf) =>
          reconf.regionName === regionName
            ? { ...reconf, ...propertyData }
            : reconf,
      )

      let currentRegionName = state.activeRegion?.regionName
      state.activeRegion = state.instance.RegionConfigs.find(
        (region) => region.regionName === currentRegionName,
      )
    },

    setSpecifiedDataField: (state, action) => {
      const { regionName, dataFieldName, propertyData } = action.payload

      if (!state.instance.RegionConfigs) return

      state.instance.RegionConfigs = state.instance.RegionConfigs.map(
        (region) =>
          region.regionName === regionName
            ? {
                ...region,
                regionConfigs: region.regionConfigs.map((reConf) =>
                  reConf.dataField === dataFieldName
                    ? { ...reConf, ...propertyData }
                    : reConf,
                ),
              }
            : region,
      )

      let currentRegionName = state.activeRegion?.regionName
      state.activeRegion = state.instance.RegionConfigs.find(
        (region) => region.regionName === currentRegionName,
      )

      let currentDataField = state.activeDataField?.dataField
      state.activeDataField = state.activeRegion?.regionConfigs.find(
        (reConf) => reConf.dataField === currentDataField,
      )
    },

    setSpecifiedDataFieldProperty: (state, action) => {
      const { regionName, dataFieldName, propertyName, propertyData } =
        action.payload

      state.instance.RegionConfigs = state.instance.RegionConfigs.map(
        (region) =>
          region.regionName === regionName
            ? {
                ...region,
                regionConfigs: region.regionConfigs.map((reConf) =>
                  reConf.dataField === dataFieldName
                    ? {
                        ...reConf,
                        [propertyName]: {
                          ...reConf[propertyName],
                          ...propertyData,
                        },
                      }
                    : reConf,
                ),
              }
            : region,
      )

      let currentRegionName = state.activeRegion?.regionName
      state.activeRegion = state.instance.RegionConfigs.find(
        (region) => region.regionName === currentRegionName,
      )

      let currentDataField = state.activeDataField?.dataField
      state.activeDataField = state.activeRegion?.regionConfigs.find(
        (reConf) => reConf.dataField === currentDataField,
      )
    },

    addNewDataFieldToRegionConfigs: (state, action) => {
      const { regionName, newDataField } = action.payload
      if (!newDataField || !regionName) return

      state.instance.RegionConfigs = state.instance.RegionConfigs?.map(
        (reconf) =>
          reconf.regionName === regionName
            ? {
                ...reconf,
                regionConfigs: [...reconf.regionConfigs, newDataField],
              }
            : reconf,
      )

      let currentRegionName = state.activeRegion?.regionName
      state.activeRegion = state.instance.RegionConfigs.find(
        (region) => region.regionName === currentRegionName,
      )
      let currentDataField = newDataField.dataField
      state.activeDataField = state.activeRegion?.regionConfigs.find(
        (reConf) => reConf.dataField === currentDataField,
      )
      state.activeDataFieldPropertyName = 'label'
    },

    deleteSpecifiedDataField: (state, action) => {
      let { regionName, dataFieldName } = action.payload

      state.instance.RegionConfigs = state.instance.RegionConfigs.map(
        (region) =>
          region.regionName === regionName
            ? {
                ...region,
                regionConfigs: region.regionConfigs.filter(
                  (reConf) => reConf.dataField !== dataFieldName,
                ),
              }
            : region,
      )

      let currentRegionName = state.activeRegion?.regionName
      state.activeRegion = state.instance.RegionConfigs.find(
        (region) => region.regionName === currentRegionName,
      )

      let currentDataField = state.activeDataField?.dataField
      state.activeDataField = state.activeRegion?.regionConfigs.find(
        (reConf) => reConf.dataField === currentDataField,
      )
    },

    configsToMatrixRegionBaseOnName: (state, action) => {
      let { regionName } = action.payload

      state.instance.RegionConfigs = state.instance.RegionConfigs?.map(
        (reconf) =>
          reconf.regionName === regionName
            ? {
                ...reconf,
                regionConfigs:
                  TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
                    state.activeRegion.regionConfigs,
                  )
                    .flat()
                    .filter((item) => typeof item !== 'string'),
              }
            : reconf,
      )

      let currentRegionName = state.activeRegion?.regionName
      state.activeRegion = state.instance.RegionConfigs.find(
        (region) => region.regionName === currentRegionName,
      )
    },

    clearTemplateInvoiceState: (state, actione) => {
      state.activePanel = 'main_ctrl'
      state.activeRegion = null
      state.activeDataField = null
      state.activeDataFieldPropertyName = null
      state.configDynamicFieldPanel = false
      state.mulTaxRate = false

      state.extendFields = null
      state.backgrounds = []
      state.surrounds = []
      state.categories = []
      state.templates = []

      state.showModalExportTemplate = false

      state.instance = initialInstance
    },
  },

  extraReducers: (builder) => {
    builder.addCase(thunkGetSurrounds.fulfilled, (state, action) => {
      let surrounds = action.payload
      if (Array.isArray(surrounds)) {
        state.surrounds = surrounds
      }
    })
    builder.addCase(thunkGetBackgrounds.fulfilled, (state, action) => {
      let backgrounds = action.payload
      if (Array.isArray(backgrounds)) {
        state.backgrounds = backgrounds
      }
    })
    builder.addCase(thunkGetCategories.fulfilled, (state, action) => {
      let categories = action.payload.category
      if (Array.isArray(categories)) {
        state.categories = categories
      }
    })
    builder.addCase(thunkGetTicketCategories.fulfilled, (state, action) => {
      let categories = action.payload.category
      if (Array.isArray(categories)) {
        state.ticketCategories = categories
      }
    })
    builder.addCase(thunkGetRoutes.fulfilled, (state, action) => {
      let routes = action.payload.routes
      if (Array.isArray(routes)) {
        state.routes = routes
      }
    })
    builder.addCase(thunkGetTemplates.fulfilled, (state, action) => {
      let total = action.payload?.total
      state.totalTemplate = total

      let templates = action.payload?.templates
      if (Array.isArray(templates)) {
        state.templates = templates
      }
    })
    builder.addCase(thunkGetExtendFields.fulfilled, (state, action) => {
      let extendFields = action.payload.extendFields
      if (Array.isArray(extendFields)) {
        state.extendFields = extendFields
      }
    })
  },
})

const { actions, reducer } = templeInvoiceSlice

export const {
  setPaginationPerPage,
  setShowWatermark,
  setMulTaxRate,
  setShowModalExportTemplate,
  setShowModalExportTicket,
  setConfigDynamicFieldPanel,
  setActiveDataField,
  setActivePanel,
  clearTemplateInvoiceState,
  setLogoInfo,
  setBackgroundInfo,
  setSurroundInfo,
  setCustomBackgroundInfo,
  setInstanceInfo,
  setActiveRegionBaseOnName,
  setSpecifiedRegion,
  setSpecifiedDataField,
  setSpecifiedDataFieldProperty,
  setActiveDataFieldPropertyName,
  plusMinusFontSizeLineHeightAll,
  addNewDataFieldToRegionConfigs,
  deleteSpecifiedDataField,
  configsToMatrixRegionBaseOnName,
  setWatermarkInfo,
} = actions

export default reducer
