import React from 'react'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import DataTable from 'react-data-table-component'
import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Utils from 'general/utils/Utils'
import KTTooltip from 'general/components/eInvoiceComponents/KTTooltip'
import _ from 'lodash'
import Loading from 'general/components/Loading'
import AppResource from 'general/constants/AppResource'
import Empty from 'general/components/Empty'
import './style.scss'
import KeenSearchBarNoFormik from 'general/components/KeenSearchBarNoFormik'
import { Form } from 'react-bootstrap'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import Global from 'general/utils/Global'
import AppData from 'general/constants/AppData'

const fakeData = [
  {
    emailMessageId: 374679283,
    emailTemplateName: 'NCC-Đại lý',
    subject: 'Hợp đồng giữa nhà cung cấp ICORP và đại lý',
    sentDate: 'Đại lý A MST: 0426482926',
    toAddress: 'Đã ký',
    fromUserFullName: 'Đã thanh toán',
    Saler: 'Đại lý A MST: 0426482926',
  },
  {
    emailMessageId: 374679283,
    emailTemplateName: 'NCC-Khách hàng',
    subject: 'Hợp đồng giữa nhà cung cấp ICORP và khách hàng',
    sentDate: 'Đại lý A MST: 0426482926',
    toAddress: 'Chờ tôi ký',
    fromUserFullName: 'Đã thanh toán',
    Saler: 'Đại lý A MST: 0426482926',
  },
  {
    emailMessageId: 374679283,
    emailTemplateName: 'NCC-Đại lý',
    subject: 'Hợp đồng giữa nhà cung cấp ICORP và khách hàng',
    sentDate: 'Đại lý A MST: 0426482926',
    toAddress: 'Chờ tôi ký',
    fromUserFullName: 'Chưa thanh toán',
    Saler: 'Đại lý A MST: 0426482926',
  },
]

const fullColumns = [
  {
    name: 'Số hợp đồng',
    sortable: false,
    width: '140px',
    center: true,
    cell: (row) => {
      return (
        <div
          data-tag="allowRowEvents"
          className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
        >
          {row?.emailMessageId}
        </div>
      )
    },
  },
  {
    name: 'Loại hợp đồng',
    sortable: false,
    width: '200px',
    center: true,
    // minWidth: '220px',
    cell: (row) => {
      return (
        <div className={`label label-lg label-outline-danger label-inline`}>
          {row?.emailTemplateName}
        </div>
        // <div className={`${row?.emailTemplateName == 'NCC-Đại lý' ? 'redBox' : 'blueBox'}`}>
        //   {row?.emailTemplateName}
        // </div>
      )
    },
  },
  {
    name: 'Tên hợp đồng',
    sortable: false,
    center: true,
    minWidth: '350px',
    cell: (row) => {
      return (
        <p
          data-tag="allowRowEvents"
          className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
        >
          {row?.subject}
        </p>
      )
    },
  },
  {
    name: 'Trạng thái',
    sortable: false,
    center: true,
    width: '200px',
    cell: (row) => {
      return (
        <div className={`label label-lg label-outline-danger label-inline`}>
          {row?.toAddress}
        </div>
        // <div className={`${row?.toAddress == 'Đã ký' ? 'greenBox' : 'blueBox'}`}>
        //   {row?.toAddress}
        // </div>
      )
    },
  },

  {
    name: 'Bên mua',
    sortable: false,
    center: true,
    width: '140px',
    cell: (row) => {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          {formatText(row?.sentDate)}
        </div>
      )
    },
  },
  {
    name: 'Thanh toán',
    sortable: false,
    center: true,
    width: '200px',
    cell: (row) => {
      return (
        <div className={`label label-lg label-outline-danger label-inline`}>
          {row?.fromUserFullName}
        </div>

        // <div className={`${row?.fromUserFullName == 'Đã thanh toán' ? 'greenBox' : 'redBox'}`}>
        //   {row?.fromUserFullName}
        // </div>
      )
    },
  },

  {
    name: 'Bên bán',
    sortable: false,
    width: '150px',
    center: true,
    // minWidth: '140px',
    cell: (row) => {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          {formatText(row?.sentDate)}
        </div>
      )
    },
  },
  {
    name: 'Hành động',
    center: true,
    width: '150px',
    cell: (row) => (
      <div className="d-flex align-items-center">
        {/* {VoucherHelper.checkCanEdit(row?.state) && ( */}
        <KTTooltip text="Xem chi tiết">
          <div
            className="btn btn-icon btn-sm btn-primary btn-hover-primary mr-2"
            onClick={(e) => {
              //   e.preventDefault();
              //   handleEditVoucher(row);
            }}
          >
            <i className="fa-solid fa-eye"></i>
          </div>
        </KTTooltip>
        {/* )} */}
        <KTTooltip text="Gửi lại">
          <div
            className="btn btn-icon btn-sm btn-warning btn-hover-warning"
            onClick={(e) => {
              //   e.preventDefault();
              //   handleDeleteVoucher(row);
            }}
          >
            <i className="fa-regular fa-paper-plane"></i>
          </div>
        </KTTooltip>
      </div>
    ),
  },
]

function formatText(a) {
  let MST = a.substring(a.indexOf('MST'), a.length)
  let daiLyA = a.substring(0, a.indexOf('MST') - 1)
  return (
    <p className="p-0 m-0">
      {daiLyA}
      <br />
      {MST}
    </p>
  )
}

function SalesContract() {
  const { t } = useTranslation()
  const [tableMaxHeight, setTableMaxHeight] = useState('100vh')
  const columns = fullColumns
  const [status, setStatus] = useState('all')
  const [invoiceType, setInvoiceType] = useState('all')
  return (
    <div className="SalesContract w-100 bg-white">
      <div className="card-body pt-0 pr-0 pl-0">
        <div className="w-100 d-flex justify-content-end pr-5">
          <div>
            {' '}
            <KeenSearchBarNoFormik
              name="searchQuery"
              className="mb-0 pb-0"
              placeholder={`${t('Tìm kiếm')}...`}
              // value={Global.gFiltersUsedInvoiceStatistic.name}
              onSubmit={(text) => {
                // needToRefreshData.current = true;
                // Global.gFiltersCustomerList = {
                //   ...filters,
                //   name: text,
                //   pageId: 1,
                // };
                // setFilters(Global.gFiltersCustomerList);
              }}
            />
          </div>
          <div className="ml-4">
            <KTFormSelect
              name="state"
              isCustom
              options={[
                { name: 'Trạng thái', value: 'all' },
                { name: 'Đã ký', value: 'signed' },
                { name: 'Chờ tôi ký', value: 'waiting' },
              ]}
              value={status}
              onChange={(newValue) => {
                setStatus(newValue)
              }}
            />
          </div>
          <div className="ml-4">
            <KTFormSelect
              name="state"
              isCustom
              options={[
                { name: 'Loại hợp đồng', value: 'all' },
                { name: 'NCC-Đại lý', value: 'agent' },
                { name: 'NCC-Khách hàng', value: 'customer' },
              ]}
              value={invoiceType}
              onChange={(newValue) => {
                setInvoiceType(newValue)
              }}
            />
          </div>
        </div>
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight={tableMaxHeight}
          columns={columns}
          data={fakeData}
          customStyles={customDataTableStyle}
          responsive={true}
          noHeader
          className="mt-5"
          // selectableRows={true}
          striped
          noDataComponent={
            <div className="pt-12">
              <Empty
                text={t('NoData')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
                imageEmptyPercentWidth={170}
              />
            </div>
          }
          // progressPending={isGettingApplicationList}
          progressComponent={
            <Loading showBackground={false} message={`${t('Loading')}...`} />
          }
          // onSelectedRowsChange={handleSelectedApplicationsChanged}
          // clearSelectedRows={toggledClearApplications}
          onRowClicked={(row) => {
            // handleEditApplication(row);
          }}
          pointerOnHover
          highlightOnHover
          selectableRowsHighlight
        />

        {/* Pagination */}
        {/* {pagination && (
              <div className="d-flex align-items-center justify-content-center mt-3">
                <Pagination
                rowsPerPage={filters?.limit}
                rowCount={applications?.length}
                currentPage={filters?.pageId}
                onChangePage={(newPage) => {
                  let iNewPage = parseInt(newPage);
                  needToRefreshData.current = true;
                  Global.gFiltersApplicationList = { ...filters, page: iNewPage };
                  setFilters({
                    ...filters,
                    pageId: iNewPage,
                  });
                }}
                onChangeRowsPerPage={(newPerPage) => {
                  const iNewPerPage = parseInt(newPerPage);
                  dispatch(setPaginationPerPage(iNewPerPage));
                  needToRefreshData.current = true;
                  Global.gFiltersApplicationList = { ...filters, limit: iNewPerPage };
                  setFilters({
                    ...filters,
                    limit: iNewPerPage,
                    pageId: 1,
                  });
                }}
                />
              </div>
            )} */}
      </div>
    </div>
  )
}

export default SalesContract
