import React from 'react'
import { Resizable } from 'react-resizable'

function ResizableCell(props) {
  const {
    resizeEnable,
    lineType,
    color,
    onResize,
    width,
    id,
    maxWidth,
    ...restProps
  } = props

  let borderStyle =
    lineType === 'NONE' ? 0 : !lineType ? null : `1px ${lineType} ${color}`

  if (!width || !resizeEnable) {
    return (
      <td
        id={id}
        {...restProps}
        style={{ borderTop: borderStyle, borderBottom: borderStyle }}
      />
    )
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      axis="x"
      maxConstraints={maxWidth ? [maxWidth, undefined] : undefined}
      minConstraints={[0.5, 0.5]}
    >
      <td {...restProps} id={id} />
    </Resizable>
  )
}

export default ResizableCell
