import PropTypes from 'prop-types'
import { useRef } from 'react'
// import AppResource from 'general/constants/AppResource'
// import PrivateHeader from '../../AppHeader/PrivateHeader'
import './style.scss'

PrivateBaseLayout.propTypes = {
  nonPadding: PropTypes.bool,
  nonPageUpBtn: PropTypes.bool,
}

PrivateBaseLayout.defaultProps = {
  nonPadding: false,
  nonPageUpBtn: false,
}

function PrivateBaseLayout(props) {
  // MARK --- Params: ---
  // const { nonPadding, nonPageUpBtn } = props

  // MARK --- Functions: ---
  const PageUp = useRef()

  // const handleScrollTop = () => {
  //   document.documentElement.scrollTop = 0
  // }

  window.onscroll = function () {
    if (PageUp.current) {
      PageUp.current.style.display =
        document.documentElement.scrollTop > 450 ? 'block' : 'none'

      PageUp.current.style.animation = 'scroll-to-top-animation 0.5s'
    }
  }

  return (
    // <div className={`PrivateBaseLayout min-vh-100 d-flex flex-column font-sfpro-regular`}>
    //   {/* Header */}
    //   <PrivateHeader />
    //   {/* Content */}

    //   <div
    //     className={`flex-grow-1 w-100 ${
    //       nonPadding ? '' : 'py-4'
    //     } align-self-center d-flex flex-column bg-light`}
    //   >
    //     {props.children}
    //   </div>

    //   {!nonPageUpBtn && <div className="fixed-button">
    //     <button
    //       ref={PageUp}
    //       id="page-up-dashboard"
    //       style={{
    //         marginBottom: '0px',

    //         display: 'none',
    //       }}
    //       onClick={handleScrollTop}
    //     >
    //       <img src={AppResource.icons.icPageUp} alt="page-up-button" />
    //     </button>
    //   </div>}
    // </div>
    <>{props.children}</>
  )
}

export default PrivateBaseLayout
