import React from 'react'
import './style.scss'
import AppResource from 'general/constants/AppResource'
import Empty from 'general/components/Empty'

function EmptyPackage(props) {
  const { text } = props
  return (
    <div className="EmptyPackage">
      <div className="text">{text}</div>
      <div className="mt-10" style={{ width: '120px' }}>
        <Empty
          //   text={t('Không có dữ liệu')}
          visible={false}
          imageEmpty={AppResource.icons.icEmptyBox}
          imageEmptyPercentWidth={170}
        />
      </div>
    </div>
  )
}

export default EmptyPackage
