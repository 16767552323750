import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

KeenSearchBarNoFormik.propTypes = {
  name: PropTypes.string.isRequired,

  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,

  className: PropTypes.string,
  onSubmit: PropTypes.func,

  value: PropTypes.string,
}

KeenSearchBarNoFormik.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  className: 'mr-2',
  onSubmit: null,
  value: '',
}

function KeenSearchBarNoFormik(props) {
  // MARK: --- Params ---
  const {
    type,
    name,
    placeholder,
    disabled,
    className,
    onSubmit,
    value,
    height,
  } = props
  const typingTimeoutRef = useRef(null)
  const [text, setText] = useState('')

  // MARK: --- Functions ---
  function handleTextChanged(e) {
    const value = e.target.value
    setText(value)

    if (!onSubmit) {
      return
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      onSubmit(value)
    }, 300)
  }

  useEffect(() => {
    value && setText(value)
  }, [value])

  return (
    <div
      className={`input-icon  ${className}`}
      style={{
        height,
      }}
    >
      <input
        id={name}
        type={type}
        className="form-control h-100"
        placeholder={placeholder}
        value={text}
        onChange={handleTextChanged}
        disabled={disabled}
      />
      <span>
        <i className="fal fa-search text-muted"></i>
      </span>
    </div>
  )
}

export default KeenSearchBarNoFormik
