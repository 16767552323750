import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FormCustom,
  SelectCustom,
  InputCustom,
  RedStar,
  DivTddCode,
  DatePickerCustom,
  DivCenterImg,
  UploadCustom,
  IconFileImageOutlined,
  DivUpdateFileLogo,
  DivRemmoveFileLogo,
  RadioCustom,
  DivFlex,
  DivBorder,
  TextlogoFont,
  TextFont,
  ButtonCustom,
  DivfontS,
  ColorPickerCustom,
  LabelForm,
  CheckboxCustom,
  DivCustomForm,
  ImgTextAglin,
  DivRightContent,
  ButtonCustomAddFeild,
  CheckboxCumstom,
  SpanImgFeild,
  DivFieldValue,
  DivImgFeild,
  FormOutlinedIcon,
  SpanImgCN,
  DeleteOutlinedIcon,
} from 'features/TaxDeductionDocuments/styled'
import { Checkbox, Empty } from 'antd'
import AppResource from 'general/constants/AppResource'
import { stubFalse } from 'lodash'
import SelectOptions from 'general/constants/SelectOptions'
import { FormOutlined, PlusOutlined } from '@ant-design/icons'
import ModalCustomField from './Modal/modalCustomField'
function TabContent({
  form,
  resetForm,
  activeFeild,
  setActiveFeild,
  activeLayout,
  activeTd,
  listContentFeild,
  setListContentFeild,
  setActiveTd,
  setActiveLayout,
  visibleAddFeild,
  setVisibleAddFeild,
  data,
  setData,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const language = 'vi'

  useMemo(() => {}, [activeFeild])

  const changePropertiveStyleFeild = (key, value) => {
    if (!activeFeild || !activeFeild.data) return

    const updatedActiveFeild = {
      ...activeFeild,
      data: {
        ...activeFeild.data,
        [`${activeFeild.type}Style`]: {
          ...activeFeild.data[`${activeFeild.type}Style`],
          [key]: value,
        },
      },
    }

    setActiveFeild(updatedActiveFeild)

    const updatedActiveTd = {
      ...activeTd,
      data: {
        ...activeTd?.data,
        value: activeTd?.data?.value.map((item, index) =>
          index === updatedActiveFeild.index
            ? {
                ...item,
                [language]: {
                  ...item[language],
                  [`${updatedActiveFeild.type}Style`]: {
                    ...item[language]?.[`${updatedActiveFeild.type}Style`],
                    [key]: value,
                  },
                },
              }
            : item,
        ),
      },
    }

    setActiveTd(updatedActiveTd)

    const updatedActiveLayout = {
      ...activeLayout,
      data: {
        ...activeLayout?.data,
        content: activeLayout?.data?.content.map((contentItem, index) =>
          index === updatedActiveTd.index
            ? {
                ...contentItem,
                value: contentItem.value.map((valueItem, vIndex) =>
                  vIndex === updatedActiveFeild.index
                    ? {
                        ...valueItem,
                        [language]: {
                          ...valueItem[language],
                          [`${updatedActiveFeild.type}Style`]: {
                            ...valueItem[language]?.[
                              `${updatedActiveFeild.type}Style`
                            ],
                            [key]: value,
                          },
                        },
                      }
                    : valueItem,
                ),
              }
            : contentItem,
        ),
      },
    }

    setActiveLayout(updatedActiveLayout)

    const updatedListContentFeild = listContentFeild.map(
      (contentFeild, layoutIndex) =>
        layoutIndex === updatedActiveLayout.index
          ? {
              ...contentFeild,
              content: contentFeild.content.map((contentItem, contentIndex) =>
                contentIndex === updatedActiveTd.index
                  ? {
                      ...contentItem,
                      value: contentItem.value.map((valueItem, vIndex) =>
                        vIndex === updatedActiveFeild.index
                          ? {
                              ...valueItem,
                              [language]: {
                                ...valueItem[language],
                                [`${updatedActiveFeild.type}Style`]: {
                                  ...valueItem[language]?.[
                                    `${updatedActiveFeild.type}Style`
                                  ],
                                  [key]: value,
                                },
                              },
                            }
                          : valueItem,
                      ),
                    }
                  : contentItem,
              ),
            }
          : contentFeild,
    )
    setListContentFeild(updatedListContentFeild)
  }

  const changePropertiveCheckbox = (key, value, indexFeild) => {
    if (!activeTd || !listContentFeild) return

    // Update activeTd
    const updatedActiveTd = {
      ...activeTd,
      data: {
        ...activeTd.data,
        value: activeTd.data.value.map((item, index) =>
          index === indexFeild
            ? {
                ...item,
                [key]: value,
              }
            : item,
        ),
      },
    }

    setActiveTd(updatedActiveTd)
    // Update listContentFeild
    const updatedListContentFeild = listContentFeild.map(
      (contentFeild, layoutIndex) =>
        layoutIndex === activeLayout.index
          ? {
              ...contentFeild,
              content: contentFeild.content.map((contentItem, tdIndex) =>
                tdIndex === updatedActiveTd.index
                  ? {
                      ...contentItem,
                      value: contentItem.value.map((valueItem, valueIndex) =>
                        valueIndex === indexFeild
                          ? {
                              ...valueItem,
                              [key]: value,
                            }
                          : valueItem,
                      ),
                    }
                  : contentItem,
              ),
            }
          : contentFeild,
    )

    setListContentFeild(updatedListContentFeild)
  }

  const options = []
  for (let i = 10; i <= 100; i++) {
    options.push({ label: `${i}`, value: i })
  }

  const deleteValue = (dataT, sectionIndex, contentIndex, valueIndex) => {
    const section = dataT[sectionIndex]
    const content = section.content[contentIndex]
    content.value = content.value.filter((item) => item.index !== valueIndex)
    return dataT
  }

  const removeData = (index) => {
    let updatedData = deleteValue(
      listContentFeild,
      activeLayout.index,
      activeTd.index,
      index,
    )
    setListContentFeild(updatedData)
    setActiveLayout({
      ...activeLayout,
      data: updatedData?.[activeLayout.index],
    })
    setActiveTd({
      ...activeTd,
      data: updatedData?.[activeLayout.index]?.content?.[activeTd.index],
    })
  }

  return (
    <div>
      <DivFlex paddingRightV1="20px" backGround="#f5f5f5" paddingTop="22px">
        <DivCustomForm paddingLeft={'22px'}>
          <FormCustom.Item style={{ width: 200 }}>
            <SelectCustom
              onChange={(value) => {
                changePropertiveStyleFeild('fontFamily', value)
              }}
              value={
                activeFeild?.data?.[`${activeFeild?.type}Style`]?.fontFamily
              }
              options={SelectOptions.fontText}
              placeholder={t('taxDeductionDocuments.chonseFontFamily')}
            ></SelectCustom>
          </FormCustom.Item>
        </DivCustomForm>
        <DivCustomForm>
          <FormCustom.Item style={{ width: 120 }}>
            <SelectCustom
              value={activeFeild?.data?.[`${activeFeild?.type}Style`]?.fontSize}
              options={options}
              placeholder={t('taxDeductionDocuments.chonseSize')}
              onChange={(value) => {
                changePropertiveStyleFeild('fontSize', value)
              }}
            ></SelectCustom>
          </FormCustom.Item>
        </DivCustomForm>
        <DivCustomForm>
          <LabelForm
            lineHeight="30px"
            padding="5px"
            // style={{ backgroundColor: '#a7c9f1' }}
            pointer="pointer"
            color={
              activeFeild?.data?.[`${activeFeild?.type}Style`]?.fontWeight
                ?.toString()
                .toLowerCase() === 'bold' ||
              Number(
                activeFeild?.data?.[`${activeFeild?.type}Style`]?.fontWeight,
              ) >= 600
                ? '#089ddd'
                : '#000000'
            }
            backGround={
              activeFeild?.data?.[`${activeFeild?.type}Style`]?.fontWeight
                ?.toString()
                .toLowerCase() === 'bold' ||
              Number(
                activeFeild?.data?.[`${activeFeild?.type}Style`]?.fontWeight,
              ) >= 600
                ? '#fff'
                : 'initial' // or any other default value you prefer
            }
            onClick={() => {
              changePropertiveStyleFeild(
                'fontWeight',
                (!activeFeild?.data?.[`${activeFeild?.type}Style`]
                  ?.textDecoration,
                activeFeild?.data?.[`${activeFeild?.type}Style`]?.fontWeight
                  ?.toString()
                  .toLowerCase() === 'bold' ||
                  Number(
                    activeFeild?.data?.[`${activeFeild?.type}Style`]
                      ?.fontWeight,
                  ) >= 600)
                  ? '400'
                  : '600',
              )
              // setChonseB(!chonseB)
            }}
          >
            B
          </LabelForm>
          <LabelForm
            lineHeight="30px"
            padding="5px"
            style={{ padding: '0px 15px' }}
          >
            /
          </LabelForm>
          <LabelForm
            lineHeight="30px"
            padding="5px 5px 0px 5px"
            borderBottom="2px solid"
            backGround={
              activeFeild?.data?.[`${activeFeild?.type}Style`]
                ?.textDecoration && '#fff'
            }
            pointer="pointer"
            color={
              activeFeild?.data?.[`${activeFeild?.type}Style`]?.textDecoration
                ? '#089ddd'
                : '#000000'
            }
            onClick={() => {
              changePropertiveStyleFeild(
                'textDecoration',
                !activeFeild?.data?.[`${activeFeild?.type}Style`]
                  ?.textDecoration,
              )
              // setChonseU(!chonseU)
            }}
          >
            U
          </LabelForm>
        </DivCustomForm>
      </DivFlex>

      <DivFlex paddingRightV1="10px" backGround="#f5f5f5">
        <DivCustomForm paddingBottom="15px">
          <LabelForm
            marginLeft="20px"
            lineHeight="30px"
            padding="5px"
            // style={{ backgroundColor: '#a7c9f1' }}
            pointer="pointer"
            color={
              activeFeild?.data?.[`${activeFeild?.type}Style`].textTransform ===
              'uppercase'
                ? '#089ddd'
                : '#000000'
            }
            backGround={
              activeFeild?.data?.[`${activeFeild?.type}Style`].textTransform ===
                'uppercase' && '#fff'
            }
            onClick={() => {
              changePropertiveStyleFeild(
                'textTransform',
                activeFeild?.data?.[`${activeFeild?.type}Style`]
                  .textTransform === 'uppercase'
                  ? 'lowercase'
                  : 'uppercase',
              )
            }}
          >
            Aa
          </LabelForm>
          <ColorPickerCustom
            defaultFormat="hex"
            onChange={(value, hex) => {
              changePropertiveStyleFeild('color', hex)
            }}
          >
            <LabelForm
              color={activeFeild?.data?.[`${activeFeild?.type}Style`].color}
              borderBottom={'3px solid'}
              padding="5px"
              paddingBottom={'2px'}
              pointer="pointer"
              paddingLeft={'5px'}
              marginLeft={'15px'}
            >
              A
            </LabelForm>
          </ColorPickerCustom>
        </DivCustomForm>

        <ImgTextAglin
          src={AppResource.textAlign.keentddcontentLeft}
          onClick={() => {
            changePropertiveStyleFeild('textAlign', 'left')
          }}
        />

        <ImgTextAglin
          src={AppResource.textAlign.keentddcontentCenter}
          onClick={() => {
            changePropertiveStyleFeild('textAlign', 'center')
          }}
        />

        <ImgTextAglin
          src={AppResource.textAlign.keentddcontentRight}
          onClick={() => {
            changePropertiveStyleFeild('textAlign', 'right')
          }}
        />
      </DivFlex>
      <DivRightContent>
        <LabelForm fontWeight="600">
          {t('taxDeductionDocuments.labelContent')}
        </LabelForm>
      </DivRightContent>
      <DivRightContent>
        <LabelForm fontWeight="400">{activeLayout?.data?.label}</LabelForm>
      </DivRightContent>

      {activeTd?.data?.value?.map((item, index) => {
        const content = item[language]
        if (item.index) {
          return (
            <DivRightContent
              marginLeft="0px"
              hoverBackgroundColor="#f4f4f4"
              paddingBottom="15px"
              cursor="pointer"
              position="relative"
            >
              <CheckboxCumstom
                key={index}
                marginLeft="25px"
                checked={!item.hide}
                disabled={item.disableCheckbox}
                onClick={(e) => {
                  changePropertiveCheckbox('hide', !item.hide, index)
                }}
              >
                <LabelForm fontWeight="400">
                  {content.label?.replace(':', '')}
                </LabelForm>
              </CheckboxCumstom>
              {item.update && (
                <DivImgFeild className="divFeildImg">
                  <SpanImgCN
                    title={t('taxDeductionDocuments.edit')}
                    onClick={() => {
                      setData(item)
                      setVisibleAddFeild(true)
                    }}
                  >
                    <FormOutlinedIcon />
                  </SpanImgCN>
                  <SpanImgCN
                    title={t('taxDeductionDocuments.remove')}
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      removeData(item.index)
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </SpanImgCN>
                </DivImgFeild>
              )}
            </DivRightContent>
          )
        }
      })}
      {activeTd?.data?.addFeild && (
        <ButtonCustomAddFeild
          icon={<PlusOutlined />}
          width="100%"
          margin="20px 0px 0px 0px"
          onClick={() => {
            setVisibleAddFeild(true)
          }}
        >
          {t('taxDeductionDocuments.addFeild')}
        </ButtonCustomAddFeild>
      )}
    </div>
  )
}
export default TabContent
