import { Navigate, Route, Routes } from 'react-router-dom'

// import ListRegistration from './ListRegistration';
import AppResource from 'general/constants/AppResource'

import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ListTaxMessageErrAnnouce from './screens/ListTaxMessageErrAnnouce'
import ListTaxMessageInvoice from './screens/ListTaxMessageInvoice'
import ListTaxMessageRegistration from './screens/ListTaxMessageRegistration'
import ListTaxMessageInvoiceFromPosScreen from './screens/ListTaxMessageInvoiceFromPos'

function TaxMessage() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  // HISTORY_INVOICE_VIEW_LIST
  const listFunction = useAppSelector((state) => state?.function?.listFunctions)
  const userFunction = useAppSelector((state) => state?.function?.userFunctions)
  const [isViewListHistoryInvoice] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'HISTORY_INVOICE_VIEW_LIST',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])
  return (
    <Routes>
      <Route path="" element={<Navigate to="hoa-don" />} />
      <Route
        path={'to-khai'}
        element={
          <ProtectedRoute permission={isViewListHistoryInvoice}>
            <ListTaxMessageRegistration />
          </ProtectedRoute>
        }
      />
      <Route
        path={'hoa-don'}
        element={
          <ProtectedRoute permission={isViewListHistoryInvoice}>
            <ListTaxMessageInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path="hoa-don-khoi-tao-tu-may-tinh-tien"
        element={
          <ProtectedRoute permission={isViewListHistoryInvoice}>
            <ListTaxMessageInvoiceFromPosScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path={'xu-ly-hoa-don'}
        element={
          <ProtectedRoute permission={isViewListHistoryInvoice}>
            <ListTaxMessageErrAnnouce />
          </ProtectedRoute>
        }
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default TaxMessage
