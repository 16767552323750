import { SYSTEM_TYPE_INVOICE, TAX_STATE, TYPE_TIME } from './AppConstants'

const AppData = {
  customerType: [
    { name: 'Tổ chức', value: 'ORGANIZATION' },
    { name: 'Cá nhân', value: 'PERSONAL' },
  ],
  moneyType: [
    { name: 'Theo dõi', value: 'ACTIVE' },
    { name: 'Ngừng theo dõi', value: 'INACTIVE' },
  ],
  status: [
    { name: 'Theo dõi', value: 'ACTIVE' },
    { name: 'Ngừng theo dõi', value: 'INACTIVE' },
  ],
  userPosition: [
    {
      name: 'Kế toán',
      value: 'Kế toán',
    },
    {
      name: 'Kế toán trưởng',
      value: 'Kế toán trưởng',
    },
    {
      name: 'Tổng giám đốc',
      value: 'Tổng giám đốc',
    },
    {
      name: 'Thủ trưởng đơn vị',
      value: 'Thủ trưởng đơn vị',
    },
  ],
  userRole: [
    { name: 'Quản trị', value: 'Quản trị' },
    { name: 'Người dùng', value: 'Người dùng' },
  ],
  // phan trang
  perPageItems: [
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 50 },
    { value: 100 },
  ],

  VAT: [
    { name: '0%', value: '0' },
    { name: '5%', value: '5' },
    { name: '8%', value: '8' },
    { name: '10%', value: '10' },
    { name: 'KCT', value: 'KCT' },
    { name: 'KKKNT', value: 'KKKNT' },
    { name: 'KHAC', value: 'KHAC' },
  ],

  chartColors: [
    '#F87171',
    '#FB923C',
    '#FBBF24',
    '#A3E635',
    '#34D399',
    '#22D3EE',
    '#818CF8',
    '#C084FC',
    '#F472B6',
    '#A3A3A3',
    // '#007aff',
    // '#ff2d55',
    // '#5856d6',
    // '#ff9500',
    // '#ffcc00',
    // '#ff3b30',
    // '#5ac8fa',
    // '#4cd964',
    // '#FF53CC',
    // '#785EFF',
    // '#32BBFF',
  ],

  invoiceType: [
    { name: 'Hóa đơn gốc', value: '0' },
    { name: 'Thay thế', value: '1' },
    { name: 'Điều chỉnh', value: '2' },
    { name: 'Đã hủy', value: '3' },
    { name: 'Đã bị thay thế', value: '4' },
    { name: 'Đã bị điều chỉnh', value: '5' },
    { name: 'Đã bị xóa', value: '6' },
  ],

  issuedStatus: [
    { name: 'Chưa gửi cơ quan thuế', value: 'NOT_SENT_TO_AUTHORITY' },
    { name: 'Cơ quan thuế đang xử lý', value: 'AUTHORITY_PROCESS' },
    { name: 'Cơ quan thuế đã cấp mã', value: 'AUTHORITY_CODE_GIVEN' },
    { name: 'Cơ quan thuế không cấp mã', value: 'AUTHORITY_CODE_NOT_GIVEN' },
    { name: 'Cơ quan thuế từ chối', value: 'AUTHORITY_DENY' },
    { name: 'Cơ quan thuế chấp nhận', value: 'AUTHORITY_ACCEPT' },
    { name: 'Chờ cơ quan thuế', value: 'WAITING_FOR_AUTHORITY' },
  ],

  dateFilter: {
    homQua: 'HOM_QUA',
    homNay: 'HOM_NAY',
    tuanTruoc: 'TUAN_TRUOC',
    tuanNay: 'TUAN_NAY',
    thangTruoc: 'THANG_TRUOC',
    thangNay: 'THANG_NAY',
    baMuoiNgayGanNhat: '30_NGAY_GAN_NHAT',
    quyTruoc: 'QUY_TRUOC',
    quyNay: 'QUY_NAY',
    sauThangDauNam: 'SAU_THANG_DAU_NAM',
    sauThangCuoiNam: 'SAU_THANG_CUOI_NAM',
    namTruoc: 'NAM_TRUOC',
    namNay: 'NAM_NAY',
    thang1: 'THANG_1',
    thang2: 'THANG_2',
    thang3: 'THANG_3',
    thang4: 'THANG_4',
    thang5: 'THANG_5',
    thang6: 'THANG_6',
    thang7: 'THANG_7',
    thang8: 'THANG_8',
    thang9: 'THANG_9',
    thang10: 'THANG_10',
    thang11: 'THANG_11',
    thang12: 'THANG_12',
    quy1: 'QUY_1',
    quy2: 'QUY_2',
    quy3: 'QUY_3',
    quy4: 'QUY_4',
    tuyChon: 'TUY_CHON',
  },

  dateFilterOptions: [
    { name: 'Hôm qua', value: 'HOM_QUA' },
    { name: 'Hôm nay', value: 'HOM_NAY' },
    { name: 'Tuần trước', value: 'TUAN_TRUOC' },
    { name: 'Tuần này', value: 'TUAN_NAY' },
    { name: 'Tháng trước', value: 'THANG_TRUOC' },
    { name: 'Tháng này', value: 'THANG_NAY' },
    { name: '30 ngày gần nhất', value: '30_NGAY_GAN_NHAT' },
    { name: 'Quý trước', value: 'QUY_TRUOC' },
    { name: 'Quý này', value: 'QUY_NAY' },
    { name: 'Sáu tháng đầu năm', value: 'SAU_THANG_DAU_NAM' },
    { name: 'Sáu tháng cuối năm', value: 'SAU_THANG_CUOI_NAM' },
    { name: 'Năm trước', value: 'NAM_TRUOC' },
    { name: 'Năm nay', value: 'NAM_NAY' },
    { name: 'Tháng 1', value: 'THANG_1' },
    { name: 'Tháng 2', value: 'THANG_2' },
    { name: 'Tháng 3', value: 'THANG_3' },
    { name: 'Tháng 4', value: 'THANG_4' },
    { name: 'Tháng 5', value: 'THANG_5' },
    { name: 'Tháng 6', value: 'THANG_6' },
    { name: 'Tháng 7', value: 'THANG_7' },
    { name: 'Tháng 8', value: 'THANG_8' },
    { name: 'Tháng 9', value: 'THANG_9' },
    { name: 'Tháng 10', value: 'THANG_10' },
    { name: 'Tháng 11', value: 'THANG_11' },
    { name: 'Tháng 12', value: 'THANG_12' },
    { name: 'Quý 1', value: 'QUY_1' },
    { name: 'Quý 2', value: 'QUY_2' },
    { name: 'Quý 3', value: 'QUY_3' },
    { name: 'Quý 4', value: 'QUY_4' },
    { name: 'Tùy chọn', value: 'TUY_CHON' },
  ],
  default_formatNumber: {
    currencyExchange: 0,
    exchangeRate: 2,
    foreignCurrency: 2,
    ratio: 2,
    quantity: 2,
    conversionUnitPrice: 2,
    currencyUnitPrice: 2,
    unitPrice: 0,
    totalAmount: 0,
    totalCost: 0,
  },
  default_autoDataLog: {
    isAuto: false,
    typeTime: TYPE_TIME.LOG,
    timeMoment: null,
    timeLog: null,
    typeInvoice: SYSTEM_TYPE_INVOICE.SINGLE,
    customerName: '',
    invoiceTemplateId: null,
  },
  default_systemCurrency: {
    zeroInten: 'linh',
    zeroInThousand: 'nghìn',
    evenDisplay: true,
    transactionForeign: true,
    readMoneyInvoice: 'Điều chỉnh [tăng giảm][Tổng tiền thanh toán]',
  },
  default_invoiceSetting: {
    priceAfterTax: 1,
    caculatedAfterTax: 0,
    amountAfterTax: 0,
    caculatedAfterAmountAndTax: 0,
    alertWhenDifference: 1,
    invoiceBeforeIssue: 1,
    manageStaffInvoice: 1,
    managementFromCash: 1,
    autoGetInfo: 1,
    dateSameDate: 0,
    generateNumberAfterCreate: 1,
    isAutoSentFromPosToTaxAuthority: 0,
    autoSentTime: ['06:00', '12:00', '18:00', '23:45', '23:59'],
    autoSentType: 'PERIOD_TIME',
  },
  default_emailSetting: {
    defaultConfig: 1,
    sendEmailAfterCreateInvoice: 1,
    sendEmailAfterCreateAdjustInvoice: 1,
    sendEmailAfterCreateReplaceInvoice: 1,
    sendEmailAfterCancelInvoice: 1,
    typeEmail: 'Khác',
    stmpHost: '',
    stmpPort: '',
    emailUserName: '',
    emailPassword: '',
    sender: '',
  },
  dict_tax_state: {
    [TAX_STATE.OPERATED]: 'NTT Đang hoạt động',
    [TAX_STATE.STOPPED_TAX_PAID]: 'NNT ngừng hoạt động và đã đóng MST',
    [TAX_STATE.STOPPED_TAX_NOT_PAID]:
      'NNT ngừng hoạt động và chưa hoàn thành thủ tục đóng MST',
    [TAX_STATE.NOT_AT_REG_ADDRESS]: 'NNT không hoạt động tại địa chỉ đăng ký',
  },
  contractType: [
    'Cứ trú và có HĐLĐ từ 3 tháng trở lên',
    'Cư trú và không ký HĐLĐ/HĐLĐ dưới 3 tháng',
    'Không cư trú',
  ],
  workingStatus: ['Đang làm việc', 'Đã nghỉ việc'],
}

export default AppData
