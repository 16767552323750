import { useQuery } from '@tanstack/react-query'
import { Modal, Space, Tooltip } from 'antd'
import invoiceApi from 'api/invoiceApi'
import {
  setAppNotInstalledToolNotiModalShow,
  setAppSpinning,
} from 'app/appSlice'
import dayjs from 'dayjs'
import { useSystemConfigSelector } from 'features/System/screens/SystemConfiguration/SystemConfigurationSlice'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomTag from 'general/components/CustomTag'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import IssueInvoiceFromPosModal from 'general/components/Modal/IssueInvoiceFromPosModal'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import { TRANSACTION_STATUS } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TransactionHeader from './components/header'
import CancelledInvoiceModal from './components/modals/CancelledInvoiceModal'
import ExportInvoiceModal from './components/modals/ExportInvoiceModal'
import MarkInvoiceModal from './components/modals/MarkInvoiceModal'
import { renderStatus } from './helpers'
import {
  transactionQueries,
  useMutationIssueInvoiceFromDataLog,
  useMutationToggleMark,
  useMutationViewInvoice,
} from './queries'
import { transactionActions, useTransactionSelector } from './slice'

export default function TransactionsScreen() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { clearSelectedRows, selectedRows, modalState, selectedRow } =
    useTransactionSelector()
  const { autoDataLog } = useSystemConfigSelector()
  const { email } = useAppSelector((s) => s.auth.currentAccount)
  const { activeSigningMethod } = useAppSelector(
    (state) => state.system.company,
  )
  const { partnerKey } = useAppSelector((s) => s.auth.currentAccount)
  const { company } = useAppSelector((state) => state?.system)

  const [filterParams, setFilterParams] = useFilter(
    'listTransactions',
    'gFilterListTransactions',
  )

  const selectedInvoice = useMemo(
    () =>
      selectedRow?.dataLogPetroOfDataLogPetroInvoice?.at(0)
        ?.dataLogPetroInvoiceByInvoice,
    [selectedRow],
  )

  const issueInvoiceFromDataLog = useCallback(
    async (row) => {
      let invoice =
        row?.dataLogPetroOfDataLogPetroInvoice?.at(
          0,
        )?.dataLogPetroInvoiceByInvoice

      if (invoice?.classify == 'MTT') {
        dispatch(
          transactionActions.toggleModalState({
            name: 'issueInvoiceFromPos',
            status: true,
          }),
        )
      } else {
        if (activeSigningMethod == 'HSM') {
          dispatch(
            transactionActions.toggleModalState({
              name: 'hsmSign',
              status: true,
            }),
          )
        } else {
          let invoiceId =
            row?.dataLogPetroOfDataLogPetroInvoice?.at(0)?.invoiceId
          let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
          const urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},${invoiceId}`
          Utils.openToolSignInvoice(urlSchema, accessToken, () =>
            dispatch(setAppNotInstalledToolNotiModalShow(true)),
          )
        }
      }
    },
    [activeSigningMethod, company, dispatch],
  )

  const { data: stations } = useQuery(transactionQueries.stationList())

  const {
    data: transactions,
    isLoading,
    isRefetching,
    refetch: refetchListTransactions,
  } = useQuery(
    transactionQueries.lists({
      ...filterParams,
      stationCode:
        filterParams.stationCode ??
        stations?.flatMap((v) => (v.value ? [v.value] : [])) ??
        [],
    }),
  )

  const {
    mutate: handleViewInvoice,
    isPending: isViewing,
    variables: viewInvoiceVariables,
  } = useMutationViewInvoice()

  const { mutate: handleUnMarkInvoice } = useMutationToggleMark()

  const [modal, contextHolder] = Modal.useModal()

  const { mutate: issueInvoiceFromDataLogMutation } =
    useMutationIssueInvoiceFromDataLog()

  const handleToggleMark = useCallback(
    (row) => {
      if (row.isMarked) {
        handleUnMarkInvoice({
          stationPetroId: row.dataLogPetroId,
          body: {
            isMarked: false,
            note: '',
          },
        })
      } else {
        dispatch(
          transactionActions.toggleModalState({
            name: 'markInvoice',
            status: true,
          }),
        )
      }
    },
    [dispatch, handleUnMarkInvoice],
  )

  const defaultColumns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<DataLogPetro>[]} */
    const arr = [
      {
        id: 'code',
        name: 'Mã bơm',
        center: true,
        minWidth: '150px',
        format: (row) => (
          <Tooltip title={row.saleNumber}>
            <span>{row.saleNumber}</span>
          </Tooltip>
        ),
        selector: (row) => row.saleNumber,
      },
      {
        id: 'productName',
        name: 'Hàng hóa',
        center: true,
        minWidth: '150px',
        format: (row) => (
          <Tooltip destroyTooltipOnHide title={row.productName}>
            <span>{row.productName}</span>
          </Tooltip>
        ),
        selector: (row) => row.productName,
      },
      {
        id: 'stationName',
        name: 'Trạm/Cửa hàng',
        center: true,
        minWidth: '150px',
        grow: 2,
        format: (row) => (
          <Tooltip
            placement="topLeft"
            destroyTooltipOnHide
            title={row.stationName}
          >
            {row.stationName}
          </Tooltip>
        ),
        selector: (row) => row.stationName,
      },
      {
        id: 'nozzleName',
        name: 'Cột bơm',
        center: true,
        minWidth: '100px',
        selector: (row) => row.nozzleName ?? row.nozzleCode,
      },
      {
        id: 'quantity',
        name: 'Số lít',
        center: true,
        minWidth: '100px',
        format: (row) => _.replace(row.quantity, '.', ','),
        selector: (row) => row.quantity,
        style: {
          justifyContent: 'flex-end',
        },
      },
      {
        id: 'unitPriceAfterTax',
        name: 'Đơn giá (+VAT)',
        center: true,
        minWidth: '100px',
        format: (row) => Utils.formatNumber(row.unitPriceAfterTax),
        selector: (row) => row.unitPriceAfterTax,
        style: {
          justifyContent: 'flex-end',
        },
      },
      {
        id: 'totalAmountAfterTax',
        name: 'Thành tiền (+VAT)',
        center: true,
        minWidth: '100px',
        format: (row) => Utils.formatNumber(row.totalAmountAfterTax),
        selector: (row) => row.totalAmountAfterTax,
        style: {
          justifyContent: 'flex-end',
        },
      },
      {
        id: 'saleDate',
        center: true,
        minWidth: '150px',
        name: 'Thời gian',
        format: (row) => dayjs(row.saleDate).format('L HH:mm'),
        selector: (row) => row.saleDate,
      },
      {
        id: 'invoiceNo',
        name: 'Số HĐ',
        center: true,
        minWidth: '100px',
        // format: (row) => (
        //   <Tooltip title={row.invoiceId}>
        //     <span>{row.invoiceId}</span>
        //   </Tooltip>
        // ),
        selector: (row) => row.invoiceNo,
      },
      {
        id: 'userCreated',
        name: 'Người tạo',
        center: true,
        minWidth: '150px',
        selector: () => email,
        format: () => <Tooltip title={email}>{email}</Tooltip>,
      },
      {
        id: 'status',
        name: 'Trạng thái hóa đơn',
        minWidth: '150px',
        center: true,
        cell: (row) => {
          return (
            <CustomTag
              text={renderStatus(row.status).text}
              tagProps={{
                className: Utils.cn(
                  'badge badge-light-' + renderStatus(row.status).className,
                ),
              }}
            />
          )
        },
      },
      {
        id: 'noteMarked',
        name: 'Ghi chú',
        center: true,
        format: (row) => (
          <Tooltip title={row.noteMarked}>
            <span>{row.noteMarked}</span>
          </Tooltip>
        ),
        selector: (row) => row.noteMarked,
      },
      {
        name: 'Hành động',
        center: true,
        disabled: true,
        compact: true,
        style: {
          justifyContent: 'flex-end',
        },
        minWidth: '150px',
        cell: (row) => {
          return (
            <Space
              size={4}
              onClick={() => {
                dispatch(transactionActions.setSelectedRow(row))
              }}
            >
              {/* Tạo hóa đơn */}
              {[
                TRANSACTION_STATUS.NOT_CREATE_INVOICE,
                TRANSACTION_STATUS.RELEASE_ERROR,
              ].includes(row.status) &&
              (autoDataLog?.isAuto ? !row.isMarked : true) ? (
                <TableAction
                  icon={<IconAction className="fa-light fa-plus" />}
                  onClick={() =>
                    dispatch(
                      transactionActions.toggleModalState({
                        name: 'exportInvoice',
                        status: true,
                      }),
                    )
                  }
                  titleActionText={t('listTransaction.createNew')}
                />
              ) : null}
              {/* Xem hóa đơn */}
              {![
                TRANSACTION_STATUS.NOT_CREATE_INVOICE,
                TRANSACTION_STATUS.CANCELLED,
              ].includes(row.status) && (
                <TableAction
                  isLoading={
                    isViewing && viewInvoiceVariables === row?.invoiceId
                  }
                  icon={<IconAction className={'fa-light fa-eye'} />}
                  onClick={() => {
                    handleViewInvoice(row?.invoiceId)
                  }}
                  titleActionText={t('listTransaction.viewInvoice')}
                />
              )}
              {/* Phát hành hóa đơn */}
              {[TRANSACTION_STATUS.CREATED_INVOICE].includes(row.status) &&
              !row.isMarked ? (
                <TableAction
                  icon={<IconAction className="fa-light fa-paper-plane" />}
                  onClick={() => issueInvoiceFromDataLog(row)}
                  titleActionText={t('listTransaction.issueInvoice')}
                />
              ) : null}
              {/* Tải hóa đơn */}
              {[TRANSACTION_STATUS.RELEASED_INVOICE].includes(row.status) && (
                <TableAction
                  icon={<IconAction className="fa-light fa-file-download" />}
                  onClick={() => alert('tai hoa don')}
                  titleActionText={t('listTransaction.downloadInvoice')}
                />
              )}
              {/* Hủy giao dịch */}
              {[
                TRANSACTION_STATUS.NOT_CREATE_INVOICE,
                TRANSACTION_STATUS.RELEASE_ERROR,
              ].includes(row.status) && !row.isMarked ? (
                <TableAction
                  titleActionText={t('listTransaction.cancel')}
                  icon={<IconAction className="fa-light fa-ban" />}
                  onClick={() => {
                    dispatch(
                      transactionActions.toggleModalState({
                        name: 'cancelInvoice',
                        status: true,
                      }),
                    )
                  }}
                />
              ) : null}
              {/* More actions */}
              {![TRANSACTION_STATUS.CANCELLED].includes(row.status) && (
                <TableAction
                  titleActionText={
                    row.isMarked
                      ? t('listTransaction.unMark')
                      : t('listTransaction.mark')
                  }
                  icon={
                    <IconAction
                      className={Utils.cn(
                        row.isMarked
                          ? 'fa-light fa-lock-keyhole '
                          : 'fa-light fa-lock-keyhole-open ',
                      )}
                    />
                  }
                  onClick={() => {
                    handleToggleMark(row)
                  }}
                />
              )}
              {[TRANSACTION_STATUS.CANCELLED].includes(row.status) && (
                <TableAction
                  icon={<IconAction className="fa-light fa-file-xmark " />}
                  titleActionText={t('listTransaction.viewCancelledDetail')}
                  onClick={() => {
                    modal.info({
                      maskClosable: true,
                      title: 'Lý do hủy',
                      closable: true,
                      centered: true,
                      okText: t('Close'),
                      content: (
                        <Space direction="vertical">
                          <span>Lý do: {row?.reasonCancel}</span>
                          <span>
                            Ngày hủy:{' '}
                            {dayjs(row?.dateCancel).isValid()
                              ? dayjs(row?.dateCancel).format('L HH:mm')
                              : ''}
                          </span>
                        </Space>
                      ),
                    })
                  }}
                />
              )}
            </Space>
          )
        },
      },
    ]
    return arr
  }, [
    email,
    autoDataLog?.isAuto,
    t,
    isViewing,
    viewInvoiceVariables,
    dispatch,
    handleViewInvoice,
    issueInvoiceFromDataLog,
    handleToggleMark,
    modal,
  ])

  const columns = useAppDnD(defaultColumns)

  useEffect(() => {
    if (partnerKey) {
      setFilterParams({
        partnerKey,
      })
    }
  }, [partnerKey, setFilterParams])

  return (
    <ContentContainer wrapperClassName="list-transaction-screen">
      <TransactionHeader defaultColumns={defaultColumns} />
      <ContentContainer.Body>
        <CustomDataTable
          columns={columns}
          dataSource={transactions?.rows}
          isLoading={isLoading || isRefetching}
          isSelectable
          isClickable
          pagination={{
            currentPage: Number(filterParams.page),
            rowsPerPage: Number(filterParams.limit),
            total: transactions?.total,
            onChangePage: (page) => {
              setFilterParams({
                page: Number(page),
              })
            },
            onChangeRowsPerPage: (limit) => {
              setFilterParams({
                limit: Number(limit),
              })
            },
          }}
          handleDoubleClickRow={(row) => {
            if (row?.invoiceId) handleViewInvoice(row?.invoiceId)
            else if ([TRANSACTION_STATUS.CANCELLED].includes(row.status))
              modal.info({
                maskClosable: true,
                title: 'Lý do hủy',
                closable: true,
                centered: true,
                okText: t('Close'),
                content: (
                  <Space direction="vertical">
                    <span>Lý do: {row?.reasonCancel}</span>
                    <span>
                      Ngày hủy:{' '}
                      {dayjs(row?.dateCancel).isValid()
                        ? dayjs(row?.dateCancel).format('L HH:mm')
                        : ''}
                    </span>
                  </Space>
                ),
              })
          }}
          selectedRows={{
            clearSelectedRows,
            selectableRowSelected: (row) => _.includes(selectedRows, row),
            handleSelectedRows: (selected) =>
              dispatch(
                transactionActions.setSelectedRows(selected.selectedRows),
              ),
          }}
        />
      </ContentContainer.Body>

      <ExportInvoiceModal
        title={'Xuất hóa đơn điện tử'}
        type={'SINGLE'}
        isOpen={modalState.exportInvoice}
        onOpenChange={(status) =>
          dispatch(
            transactionActions.toggleModalState({
              name: 'exportInvoice',
              status,
            }),
          )
        }
        onApply={() => {
          dispatch(transactionActions.setSelectedRow(null))
          dispatch(transactionActions.setClearSelectedRows())
          dispatch(transactionActions.setSelectedRows([]))
        }}
      />

      <ExportInvoiceModal
        title={'Xuất gộp hóa đơn điện tử'}
        type={'COMBINE'}
        isOpen={modalState.combineExportInvoice}
        onOpenChange={(status) =>
          dispatch(
            transactionActions.toggleModalState({
              name: 'exportInvoice',
              status,
            }),
          )
        }
        onApply={() => {
          dispatch(transactionActions.setSelectedRow(null))
          dispatch(transactionActions.setClearSelectedRows())
          dispatch(transactionActions.setSelectedRows([]))
        }}
      />

      <ExportInvoiceModal
        title={'Xuất nhiều hóa đơn điện tử'}
        type={'MULTIPLE'}
        isOpen={modalState.multipleExportInvoice}
        onOpenChange={(status) =>
          dispatch(
            transactionActions.toggleModalState({
              name: 'multipleExportInvoice',
              status,
            }),
          )
        }
        onApply={() => {
          dispatch(transactionActions.setSelectedRow(null))
          dispatch(transactionActions.setClearSelectedRows())
          dispatch(transactionActions.setSelectedRows([]))
        }}
      />

      <CancelledInvoiceModal />

      <MarkInvoiceModal />

      <ModalConfirm
        show={modalState.hsmSign}
        setShow={(value) =>
          dispatch(
            transactionActions.toggleModalState({
              name: 'hsmSign',
              status: value,
            }),
          )
        }
        header="Ký số HSM"
        content="Chương trình sẽ thực hiện ký số HSM. Bạn chắc chắn vẫn ký?"
        handleConfirm={() =>
          issueInvoiceFromDataLogMutation(selectedRow.dataLogPetroId)
        }
        runWhenClosedFunc={() =>
          dispatch(transactionActions.setSelectedRow(null))
        }
      />

      <IssueInvoiceFromPosModal
        invoice={selectedInvoice}
        onIssueSuccess={refetchListTransactions}
        show={modalState.issueInvoiceFromPos}
        onHide={() => {
          dispatch(
            transactionActions.toggleModalState({
              modalName: 'issueInvoiceFromPos',
              status: false,
            }),
          )
          // dispatch(setSelectedInvoice(null))
        }}
      />
      {contextHolder}
    </ContentContainer>
  )
}
