import { Space, Table } from 'antd'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useWindowSize from 'hooks/useWindowSize'
import $ from 'jquery'
import { forEach, map, merge } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { utils, writeFile } from 'xlsx-js-style'
import { useQueryIssuedInvoice } from './queries'
import './style.scss'

const { Column, ColumnGroup } = Table

function IssueInvoiceScreen() {
  const { t } = useTranslation()
  const cardHeader = useRef(null)
  const [tableMaxHeight, setTableMaxHeight] = useState(500)
  const windowSize = useWindowSize()
  // const { issuedInvoices, pagination } = useAppSelector(
  //   (state) => state?.report,
  //   shallowEqual,
  // )

  const [filters, setFilters] = useFilter(
    'issuedInvoiceList',
    'gFiltersIssuedInvoices',
  )

  const { isLoading, data: issuedInvoiceData } = useQueryIssuedInvoice(filters)

  // MARK --- Functions ---
  function handleExportExcel() {
    const tableEle = $('#table-issued-invoices')[0]
    const workbook = utils.table_to_book(tableEle, {
      raw: true,
      sheet: 'Báo cáo hóa đơn đã phát hành',
    })
    const ws = workbook.Sheets['Báo cáo hóa đơn đã phát hành']
    const numberCellRegex = /^[CDE](?!3$).*$/
    const headerRegex = /^([a-zA-Z]*)[1,2]$/
    const commonStyle = {
      font: {
        name: 'Times New Roman',
        sz: 12,
        italic: false,
        underline: false,
      },
      alignment: {
        wrapText: true,
      },
    }

    Object.keys(ws).forEach((i) => {
      if (!i.includes('!') && !i.includes(':')) {
        ws[i].s = merge({}, commonStyle)
      }
      if (headerRegex.test(i)) {
        ws[i].t = 'n'
        ws[i].s = merge({}, commonStyle, {
          font: {
            bold: true,
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
          },
        })
      } else if (i.match(numberCellRegex)) {
        ws[i].t = 'n'
        ws[i].z = `0`
        ws[i].s = merge({}, commonStyle, {
          alignment: {
            vertical: 'center',
            horizontal: 'right',
          },
        })
      }
    })

    const objectMaxLength = []
    const json = utils.sheet_to_json(ws)
    forEach(json, (value, i) => {
      value = Object.values(json[i])
      forEach(value, (val, j) => {
        if (typeof val == 'number') {
          objectMaxLength[j] = 10 + 20
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= val.length && val.includes('hóa, dịch vụ')
              ? objectMaxLength[j] + 20
              : val.length + 20
        }
      })
    })
    const wss = map(objectMaxLength, (width) => ({ width }))
    ws['!cols'] = wss

    utils.sheet_add_aoa(ws, [['']], {
      origin: -1,
    })

    writeFile(workbook, 'Báo cáo hóa đơn đã phát hành' + '.xlsx')
  }

  // MARK --- Hooks ---
  // useEffect(() => {
  //   dispatch(thunkGetIssuedInvoices(filters))
  // }, [dispatch, filters])

  useEffect(() => {
    function resizeScreen() {
      setTableMaxHeight(
        windowSize.height - cardHeader?.current?.clientHeight - 270,
      )
    }
    window.addEventListener('resize', resizeScreen)
    resizeScreen()
    return () => {
      window.removeEventListener('resize', resizeScreen)
    }
  }, [windowSize.height])

  return (
    <ContentContainer wrapperClassName="IssueInvoiceScreen">
      <ContentContainer.Header
        titleContent={t('IssuedInvoice')}
        description={`${t('Tổng')}: ${Utils.formatNumber(
          issuedInvoiceData?.total,
        )} ${t('Hóa đơn')}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('ExportExcel')}
              iconCn="fa-solid fa-down-to-line"
              antProps={{
                className: 'btn btn-success',
                onClick: handleExportExcel,
              }}
            />
          </Space>
        }
      >
        <Space wrap>
          <FilterPopover
            menuPosition="bottomRight"
            onApply={setFilters}
            savedKey="gFiltersIssuedInvoices"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <Table
          id="table-issued-invoices"
          pagination={false}
          dataSource={issuedInvoiceData?.issuedInvoice}
          scroll={{ y: tableMaxHeight }}
          loading={isLoading}
        >
          <Column
            className="border-right border-left text-uppercase"
            title={t('InvoiceTypeName')}
            dataIndex="invoiceTemplateName"
            key="invoiceTemplateName"
          />
          <Column
            className="border-right text-uppercase"
            title={t('Symbol')}
            dataIndex="serial"
            key="serial"
          />
          <ColumnGroup
            className="border-right text-uppercase"
            title="Số lượng hóa đơn đã sử dụng, hủy trong kỳ"
          >
            <Column
              className="border-right text-uppercase"
              title={t('Total')}
              dataIndex="isssuedInvoiceCount"
              key="isssuedInvoiceCount"
            />
            <Column
              className="border-right text-uppercase"
              title={t('UsedQuantity')}
              dataIndex="used"
              key="used"
            />
            <Column
              className="border-right text-uppercase"
              title={t('CancelQuantity')}
              dataIndex="cancelledInvoiceCount"
              key="cancelledInvoiceCount"
            />
          </ColumnGroup>
        </Table>
        {/* {issuedInvoiceData?.issuedInvoice?.length > 0 ? (
        ) : (
          <Empty
            text={t('NoData')}
            visible={false}
            imageEmpty={AppResource.icons.icEmptyBox}
            imageEmptyPercentWidth={30}
          />
        )} */}
      </ContentContainer.Body>
    </ContentContainer>
  )
}

export default IssueInvoiceScreen
