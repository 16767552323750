import axiosClient from './axiosClient'

const extendFieldApi = {
  getExtendFields: () => {
    const url = '/extend-field'

    return axiosClient.get(url)
  },

  createExtendField: (params) => {
    const url = '/extend-field'

    return axiosClient.post(url, params)
  },

  updateExtendFields: (params) => {
    const url = '/extend-field'

    return axiosClient.put(url, params)
  },

  deleteExtendField: (params) => {
    const url = `/extend-field`

    return axiosClient.delete(url, { data: params })
  },
}

export default extendFieldApi
