import { queryOptions } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import _ from 'lodash'

export const queries = {
  base: {
    scope: ['handleInvoice', 'listReplacedInvoice'],
  },
  lists: () => ({
    ...queries.base,
    entity: 'replaced_invoice',
  }),
  list: (params = {}) =>
    queryOptions({
      staleTime: 1000 * 30,
      queryKey: [queries.lists(), params],
      queryFn: ({ signal }) =>
        invoiceApi.listAll(
          {
            ...params,
            invoiceType: 'replaced',
          },
          signal,
        ),
      select: (res) => ({
        total: _.get(res, 'count', 0),
        rows: !res?.rows
          ? []
          : _.map(res?.rows, (row, idx) => ({
              ...row,
              id: idx + 1,
            })),
      }),
    }),
}
