import { useDispatch, useSelector } from 'react-redux'

/**
 * @typedef {() => import("app/store").AppDispatch} TypeUseAppDispatch
 * @typedef {import("react-redux").TypedUseSelectorHook<import("app/store").RootState>} TypeUseAppSelector
 */

/**
 * @type {TypeUseAppDispatch}
 */
export const useAppDispatch = useDispatch

/**
 * @type {TypeUseAppSelector}
 */
export const useAppSelector = useSelector
