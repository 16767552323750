import { Button, Dropdown, Tooltip } from 'antd'
import { isEqual } from 'lodash'
import { IconAction } from './styles'

/**
 * @typedef {'button' | 'dropdown' } ActionType
 */

/**
 * @param {Object} props
 * @param {ActionType} props.type - The type of action to render, either 'button' or 'dropdown' or a 'link' defaults to 'button'
 * @param {string} props.titleActionText - The text to display in the tooltip for the button action
 * @param {?React.ReactNode} props.icon - The icon to display for the button action
 * @param {Function} props.onClick - The function to call when the button is clicked
 * @param {?import('antd').MenuProps['items']} props.items - The function to call when the button is clicked
 * @param {?import('antd').ButtonProps['size']} props.buttonSize - The size of the button
 * @param {import('antd').TypographyProps['Link']['defaultProps']} props.textProps - Link text props
 * @param {boolean} props.isLoading - The loading state of the button
 */
const TableAction = ({
  type = 'button',
  titleActionText,
  icon,
  onClick,
  items,
  buttonSize,
  isLoading,
}) => {
  return (
    <>
      {isEqual(type, 'button') ? (
        <Tooltip
          arrow={false}
          placement="bottomRight"
          destroyTooltipOnHide
          title={titleActionText}
        >
          <Button
            size={buttonSize ?? 'middle'}
            shape="circle"
            type="text"
            icon={
              isLoading ? (
                <IconAction className="far fa-spinner-third fa-spin" />
              ) : (
                icon
              )
            }
            disabled={isLoading}
            onClick={onClick}
          />
        </Tooltip>
      ) : isEqual(type, 'dropdown') ? (
        <Dropdown
          trigger={['click', 'hover']} // 1 Trick để người dùng có thể hover sang row khác mà không cần phải ẩn
          placement="bottomLeft"
          mouseEnterDelay={100} // set time cao để người dùng không mở dropdown khi hover vào
          mouseLeaveDelay={0.5}
          arrow={false}
          menu={{
            items,
          }}
          getPopupContainer={() => document.querySelector('.rdt_TableBody')}
        >
          <Button
            size={buttonSize ?? 'middle'}
            shape="circle"
            type="text"
            icon={icon ?? <IconAction className="fa-solid fa-ellipsis" />}
          />
        </Dropdown>
      ) : null}
    </>
  )
}

export default TableAction
