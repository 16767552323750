import { Space, Tooltip } from 'antd'
import invoiceTemplateFunctionApi from 'api/invoiceTemplateFuntionApi.js'
import listFunctionApi from 'api/listFunctionApi'
import templateInvoiceApi from 'api/templateInvoiceApi'
import {
  getAllListFunction,
  getAllUserFunction,
} from 'features/System/screens/UserManagement/functionSlice.js'
import {
  createUser,
  deleteUser,
  getAllUsers,
} from 'features/System/screens/UserManagement/userSlice.js'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import { useAppSelector } from 'hooks/useRedux'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import AddUserModal from './tabs/AddUserModal/'
import DeleteUserModal from './tabs/DeleteUserModal'
import UpdateUserModal from './tabs/UpdateUserModal'

function UserManagement() {
  const [userAddModalShow, setUserAddModalShow] = useState(false)
  const [userUpdateModalShow, setUserUpdateModalShow] = useState(false)
  const [userDeleteModalShow, setUserDeleteModalShow] = useState(false)
  const user = useAppSelector((state) => state.auth.currentAccount)

  const [userSelected, setUserSelected] = useState()

  const fullColumns = useMemo(
    () => [
      {
        name: 'Họ và tên',
        center: true,
        minWidth: '150px',
        format: (row) => (
          <Tooltip title={row?.fullname}>{row?.fullname}</Tooltip>
        ),
        selector: (row) => row?.fullname,
      },
      {
        name: 'Chức danh',
        center: true,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.companyTitle}
            </p>
          )
        },
      },
      {
        name: 'Vai trò',
        center: true,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.accountLevel == 'COMPANY_ADMIN' ? 'Quản trị' : 'Người dùng'}
            </p>
          )
        },
      },
      {
        name: 'Đơn vị',
        center: true,
        minWidth: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.companyUnit}
            </p>
          )
        },
      },
      {
        name: 'Số điện thoại',
        center: true,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.phone}
            </p>
          )
        },
      },
      {
        name: 'Email',
        center: true,
        minWidth: '200px',
        format: (row) => <Tooltip title={row?.email}>{row?.email}</Tooltip>,
        selector: (row) => row?.email,
      },
      {
        name: 'Hành động',
        minWidth: '100px',
        center: true,
        compact: true,
        cell: (row) => {
          return (
            <Space align="center">
              <TableAction
                titleActionText="Cập nhật thông tin"
                icon={<IconAction className="fa-regular fa-pen-to-square" />}
                onClick={() => {
                  setUserSelected(row)
                  setUserUpdateModalShow(true)
                }}
              />

              {row?.accountId != user?.accountId && (
                <TableAction
                  icon={<IconAction className="fa-regular fa-trash-alt" />}
                  titleActionText="Xoá người dùng"
                  onClick={() => {
                    setUserSelected(row)
                    setUserDeleteModalShow(true)
                  }}
                ></TableAction>
              )}
            </Space>
          )
        },
      },
    ],
    [user?.accountId],
  )

  // MARK --- Parmas: ---
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const columns = useAppDnD(fullColumns)

  useEffect(() => {
    dispatch(getAllUserFunction())
    dispatch(getAllListFunction(user?.accountId))
  }, [])

  useEffect(() => {
    if (user) {
      dispatch(getAllUsers(user?.companyId))
    }
  }, [user])

  const userListTmp = useSelector((state) => state?.userList?.users?.accounts)
  const [userList, setUserList] = useState(userListTmp)

  // Sử dụng useEffect để cập nhật userList khi giá trị userListTmp thay đổi
  useEffect(() => {
    setUserList(userListTmp)
  }, [userListTmp])

  useEffect(() => {
    const updatedUserList = userList?.map((row, index) => ({
      ...row,
      id: index + 1,
    }))

    setUserList(updatedUserList)
    fetchTemplateSerial()
  }, [])

  const deleteFunction = () => {
    dispatch(deleteUser(userSelected?.accountId))
    listFunctionApi.deleteByUserId(userSelected?.accountId)
    const updatedUserList = userList.filter(
      (user) => user.accountId !== userSelected?.accountId,
    )
    setUserList(updatedUserList)
    invoiceTemplateFunctionApi.deleteByUserId(userSelected?.accountId)
  }

  const addUserFunction = async (values, listFunction, selectedRows) => {
    const { email, phone, fullname, title, agency, role } = values

    let res = await dispatch(
      createUser({
        email,
        phone,
        fullname: fullname,
        password:
          '8d969eef6ecad3c29a3a629280e686cf0c3f5d5a86aff3ca12020c923adc6c92',
        title,
        agency,
        role,
      }),
    )

    res = res.payload

    const tmp = [...userList, res.account]

    const updatedUserList = tmp?.map((row, index) => ({
      ...row,
      id: index + 1,
    }))

    setUserList(updatedUserList)

    const updatedListFunction = listFunction.map((item) => ({
      ...item,
      accountId: res?.account?.accountId,
    }))

    await listFunctionApi.createAll(updatedListFunction)

    if (selectedRows) {
      selectedRows.map(async (row) => {
        await invoiceTemplateFunctionApi.create({
          ...row,
          accountId: res?.account?.accountId,
        })
      })
    }

    setUserAddModalShow(false)
  }

  const [userTmp, setUserTmp] = useState()

  useEffect(() => {
    setUserTmp(userList)
  }, [userList])

  const [filteredText, setFilteredText] = useState('')

  useEffect(() => {
    if (userList) {
      // Sử dụng map để lọc danh sách người dùng dựa trên trường chứa filteredText
      const filteredUsers = userList.map((user) => {
        if (
          user?.fullname?.toLowerCase().includes(filteredText.toLowerCase()) ||
          user?.email?.toLowerCase().includes(filteredText.toLowerCase()) ||
          user?.companyTitle
            ?.toLowerCase()
            .includes(filteredText.toLowerCase()) ||
          user?.companyUnit
            ?.toLowerCase()
            .includes(filteredText.toLowerCase()) ||
          user?.phone?.toLowerCase().includes(filteredText.toLowerCase())
        ) {
          return user
        }
        return null // Trả về null nếu người dùng không thỏa mãn điều kiện tìm kiếm
      })

      // Lọc bỏ các phần tử null khỏi mảng filteredUsers
      const filteredAndNotNullUsers = filteredUsers.filter(
        (user) => user !== null,
      )

      setUserTmp(filteredAndNotNullUsers)
    }
  }, [filteredText])

  const [templateInvoiceSerials, setTemplateInvoiceSerials] = useState([])

  const fetchTemplateSerial = async () => {
    const res = await templateInvoiceApi.getAllTemplateSerials()
    if (!Utils.isObjectEmpty(res?.data)) {
      let serials = res?.data
      serials.map((item, index) => {
        item.label = item.serial
        item.value = item.invoiceTemplateId
      })
      setTemplateInvoiceSerials(serials)
    } else {
      ToastHelper.showError('Không lấy được thông tin ký hiệu hoá đơn')
    }
  }

  return (
    <ContentContainer wrapperClassName="UserManagement">
      <ContentContainer.Header
        titleContent={t('Quản lý người dùng')}
        toolBar={
          <Space>
            <CustomAntButton
              text="Thêm người dùng"
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setUserAddModalShow(true)
                },
              }}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={PreferenceKeys.savedColumnsUser}
            />
          </Space>
        }
      >
        <Space>
          <CustomAntInput
            borderRadius="sm"
            type="search"
            inputProps={{
              name: 'searchQuery',
              value: filteredText,
              onChange: (e) => setFilteredText(e.target.value),
            }}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={columns}
          dataSource={userTmp}
          isClickable
          handleDoubleClickRow={(row) => {
            setUserSelected(row)
            setUserUpdateModalShow(true)
          }}
        />
      </ContentContainer.Body>
      {userUpdateModalShow && (
        <UpdateUserModal
          show={userUpdateModalShow}
          setShow={setUserUpdateModalShow}
          userSelected={userSelected}
          templateInvoiceSerials={templateInvoiceSerials}
        />
      )}

      <DeleteUserModal
        show={userDeleteModalShow}
        setShow={setUserDeleteModalShow}
        deleteFunction={deleteFunction}
      />

      <AddUserModal
        show={userAddModalShow}
        setShow={setUserAddModalShow}
        templateInvoiceSerialsTmp={templateInvoiceSerials}
        addUserFunction={addUserFunction}
      />
    </ContentContainer>
  )
}

export default UserManagement
