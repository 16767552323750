import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PrivateBaseLayout from 'general/components/eInvoiceComponents/BaseLayout/PrivateBaseLayout'
import AppBreadcrumb from 'general/components/AppBreadcumb'
import Secment from 'general/components/eInvoiceComponents/AppSecment'
import SalesContract from './tabs/SalesContract/'
import ServiceContract from './tabs/ServiceContract/'

function ContractManagement() {
  const tabs = ['Hợp đồng cung cấp dịch vụ', 'Hợp đồng mua bán hóa đơn']

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  function handleSelectedTab(newTab) {
    setSelectedTab(newTab)
  }

  return (
    <div className="ContractManagement">
      <PrivateBaseLayout nonPadding={true}>
        <div className="px-6">
          <div className="card card-custom">
            <div className="w-100 d-flex justify-content-between">
              <div>
                <Secment
                  tabs={tabs}
                  activeTab={selectedTab}
                  handleClick={handleSelectedTab}
                  className="d-flex justify-content-center align-items-center"
                />
              </div>
            </div>
          </div>
          <div className="w-100">
            {selectedTab === 'Hợp đồng cung cấp dịch vụ' ? (
              <SalesContract />
            ) : selectedTab === 'Hợp đồng mua bán hóa đơn' ? (
              <ServiceContract />
            ) : null}
          </div>
        </div>
      </PrivateBaseLayout>
    </div>
  )
}

export default ContractManagement
