import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'
import './style.scss'

// sondang
AppBreadcrumb.propTypes = {
  items: PropTypes.array,
  fromHome: PropTypes.bool,
  addtionalClassName: PropTypes.string,
}

AppBreadcrumb.defaultProps = {
  items: [],
  fromHome: true,
  addtionalClassName: 'mb-6 flex-fill',
  RightComponent: <></>,
}
function AppBreadcrumb(props) {
  const { items, fromHome, addtionalClassName, RightComponent } = props

  return (
    <div className="d-flex flex-row justify-content-between">
      <Breadcrumb className={`breadcrumb-contract ${addtionalClassName}`}>
        {fromHome && (
          <li className="breadcrumb-item text-remaining">
            <Link to={'/ban-lam-viec'}>
              <i className="fal fa-home-alt"></i>
            </Link>
          </li>
        )}
        {items.length > 0 &&
          items.map((item, index) =>
            item.url === '' ? (
              <li key={index} className="breadcrumb-item active">
                {item.text}
              </li>
            ) : (
              <li key={index} className="breadcrumb-item">
                <Link to={item.url}>{item.text}</Link>
              </li>
            ),
          )}
      </Breadcrumb>

      {RightComponent}
    </div>
  )
}

export default AppBreadcrumb
