import axiosClient from './axiosClient'

const goodsApi = {
  /** @deprecated */
  find: (params, signal) => {
    const url = '/goods/find'
    return axiosClient.get(url, { params, signal })
  },
  list: (params, signal) => axiosClient.get('/goods/list', { params, signal }),
  create: (params) => {
    const url = '/goods/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = `/goods/update/${params.goodsId}`
    return axiosClient.put(url, params)
  },

  delete: (params) => {
    const url = '/goods/delete'
    return axiosClient.delete(url, {
      data: {
        goodsIds: params,
      },
    })
  },

  uploadFile: async (file) => {
    const url = '/goods/upload'
    const formData = new FormData()
    formData.set('file', file)
    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  findDataLogGoods: (params, signal) =>
    axiosClient.get('/data-log/product', {
      params,
      signal,
    }),

  exportToExcel: async (params) => {
    const url = '/goods/export-by-excel'
    return await axiosClient.post(url, params, {
      responseType: 'arraybuffer',
    })
  },
}

export default goodsApi
