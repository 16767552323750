import invoiceApi from 'api/invoiceApi'
import reportApi from 'api/reportApi'
import templateInvoiceApi from 'api/templateInvoiceApi'
import Global from 'general/utils/Global'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const thunkGetInvoiceDetail = createAsyncThunk(
  'report/detail',
  async (params, thunkApi) => {
    const res = await reportApi.getDetailInvoices(params)
    return res
  },
)

export const thunkGetUsedInvoices = createAsyncThunk(
  'report/used-invoices',
  async (params, thunkApi) => {
    const res = await invoiceApi.listAll(params)
    return res
  },
)

export const thunkGetIssuedInvoices = createAsyncThunk(
  'report/issued-invoices',
  async (params, thunkApi) => {
    const res = await reportApi.getIssuedInvoice(params)
    return res
  },
)

export const thunkGetInvoiceByTax = createAsyncThunk(
  'report/tax-invoices',
  async (params, thunkApi) => {
    const res = await reportApi.getReportBytax(params)
    return res
  },
)

export const thunkGetListSerial = createAsyncThunk(
  'report/invoice-serial',
  async (params, thunkApi) => {
    const res = await templateInvoiceApi.getTemplateSerials()
    return res
  },
)

const reportSlice = createSlice({
  name: 'report',
  initialState: {
    usedInvoiceStatistic: [],
    isGettingUsedInvoiceStatistic: false,

    detailInvoices: [],
    isGettingDetailInvoice: false,

    issuedInvoices: [],
    isGettingIssuedInvoice: false,

    invoicesByTax: [],
    isGettingInvoiceByTax: false,
    totalRevenueBeforeTax: 0,
    totalTax: 0,
    totalRevenueAfterTax: 0,

    serials: [],
    isGettingSerials: false,

    pagination: { total: 0, perPage: Global.gDefaultPagination },
  },
  reducers: {
    setPaginationPerPage: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
        },
      }
    },
  },

  extraReducers: (builder) => {
    // used invoice
    builder.addCase(thunkGetUsedInvoices.pending, (state, action) => {
      state.isGettingUsedInvoiceStatistic = true
    })
    builder.addCase(thunkGetUsedInvoices.rejected, (state, action) => {
      state.isGettingUsedInvoiceStatistic = false
    })
    builder.addCase(thunkGetUsedInvoices.fulfilled, (state, action) => {
      state.isGettingUsedInvoiceStatistic = false
      const { count, result, rows } = action.payload
      if (result === 'success') {
        state.usedInvoiceStatistic = rows
        state.pagination = {
          ...state.pagination,
          total: count,
        }
      }
    })

    // detail invoice
    builder.addCase(thunkGetInvoiceDetail.pending, (state, action) => {
      state.isGettingDetailInvoice = true
    })
    builder.addCase(thunkGetInvoiceDetail.rejected, (state, action) => {
      state.isGettingDetailInvoice = false
    })
    builder.addCase(thunkGetInvoiceDetail.fulfilled, (state, action) => {
      state.isGettingDetailInvoice = false
      const { result, data } = action.payload
      if (result === 'success') {
        state.detailInvoices = data.rows
        state.pagination = {
          ...state.pagination,
          total: data.rowsCount,
        }
      }
    })

    // issued invoice
    builder.addCase(thunkGetIssuedInvoices.pending, (state, action) => {
      state.isGettingIssuedInvoice = true
    })
    builder.addCase(thunkGetIssuedInvoices.rejected, (state, action) => {
      state.isGettingIssuedInvoice = false
    })
    builder.addCase(thunkGetIssuedInvoices.fulfilled, (state, action) => {
      state.isGettingIssuedInvoice = false
      const { result, data } = action.payload
      if (result === 'success') {
        state.pagination = {
          ...state.pagination,
          total: data.invoiceTemplateStats.length,
        }
        state.issuedInvoices = data.invoiceTemplateStats.map((item) => {
          return {
            ...item,
            used: item.isssuedInvoiceCount - item.cancelledInvoiceCount,
          }
        })
        // state.pagination = {
        //   ...state.pagination,
        //   total: count,
        // }
      }
    })

    // invoice by tax
    builder.addCase(thunkGetInvoiceByTax.pending, (state, action) => {
      state.isGettingInvoiceByTax = true
    })
    builder.addCase(thunkGetInvoiceByTax.rejected, (state, action) => {
      state.isGettingInvoiceByTax = false
    })
    builder.addCase(thunkGetInvoiceByTax.fulfilled, (state, action) => {
      state.isGettingInvoiceByTax = false
      const { result, data } = action.payload
      if (result === 'success') {
        state.invoicesByTax = data.taxArray
        state.totalRevenueBeforeTax = data.totalBeforeTax
        state.totalTax = data.totalTaxMoney
        state.totalRevenueAfterTax = data.totalAfterTax
        // state.pagination = {
        //   ...state.pagination,
        //   total: count,
        // }
      }
    })

    // invoice by tax
    builder.addCase(thunkGetListSerial.pending, (state, action) => {
      state.isGettingSerials = true
    })
    builder.addCase(thunkGetListSerial.rejected, (state, action) => {
      state.isGettingSerials = false
    })
    builder.addCase(thunkGetListSerial.fulfilled, (state, action) => {
      state.isGettingSerials = false
      const { data } = action.payload
      state.serials = data
    })
  },
})

const { actions, reducer } = reportSlice
export const { setPaginationPerPage } = actions
export default reducer
