import axiosClient from './axiosClient'

const prefixUrl = '/resource/'

const resourceApi = {
  getByCompanyId: (params) => {
    const url = '/resource/getByCompanyId'
    return axiosClient.get(url, { params })
  },
  getSynthetic: () => axiosClient.get(prefixUrl + 'synthetic'),
}

export default resourceApi
