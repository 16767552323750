import { useTranslation } from 'react-i18next'
import {
  TitleLeft,
  ContainerHeader,
  ContainerTable,
  DivRight,
  IconLeftOutlined,
  SpanNotYetReleased,
} from '../../../TaxDeductionDocuments/styled'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonSave, StyleButtonClose } from '../../../TaxDeduction/styled'
import { LeftOutlined } from '@ant-design/icons'
function HeaderTaxpayer({
  titleCancel,
  onClickCancel,
  titleSave,
  hideSave,
  onClickSave,
  titleSavePrimary,
  onClickSavePrimary,
  disableSubmit,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  //   useEffect(() => {

  //   }, [dataSource])

  return (
    <>
      <DivRight>
        <StyleButtonClose onClick={onClickCancel}>
          {titleCancel ? titleCancel : t('taxDeductionDocuments.cancel')}
        </StyleButtonClose>
        {!hideSave && (
          <StyleButtonClose onClick={onClickSave} disabled={disableSubmit}>
            {titleSave ? titleSave : t('taxpayer.saveAndAdd')}
          </StyleButtonClose>
        )}

        <ButtonSave
          type="primary"
          onClick={onClickSavePrimary}
          disabled={disableSubmit}
        >
          {titleSavePrimary
            ? titleSavePrimary
            : t('taxDeductionDocuments.save')}
        </ButtonSave>
      </DivRight>
    </>
  )
}
export default HeaderTaxpayer
