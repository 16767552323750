import { queryOptions } from '@tanstack/react-query'
import taxAuthorityMessageApi from 'api/taxAuthorityMessageApi'
import { RESOURCE_TYPE } from 'general/constants/AppConstants'
import _ from 'lodash'

export const queries = {
  base: {
    scope: ['taxMessage', 'listTaxMessageInvoice'],
    entity: 'taxMessageInvoice',
  },
  list: (params) => ({
    ...queries.base,
    params,
  }),
  lists: (params, systemType) =>
    queryOptions({
      staleTime: 1000 * 30,
      queryKey: [queries.list(params), systemType],
      queryFn: ({ signal }) => {
        switch (systemType) {
          case RESOURCE_TYPE.INVOICE:
            return taxAuthorityMessageApi.listInvoiceMessage(
              {
                ...params,
                invoiceType: 'used',
              },
              signal,
            )
          case RESOURCE_TYPE.TICKET:
            return taxAuthorityMessageApi.listTicketMessage(
              {
                ...params,
              },
              signal,
            )
          default:
            return taxAuthorityMessageApi.listInvoiceMessage(
              {
                ...params,
                invoiceType: 'used',
              },
              signal,
            )
        }
      },
      select: (res) => ({
        total: _.get(res, 'count', 0),
        rows: !res?.rows
          ? []
          : _.map(res?.rows, (row, idx) => ({
              ...row,
              id: idx + 1,
            })),
      }),
    }),
}
