const { default: axiosClient } = require('./axiosClient')

const invoiceTemplateFunctionApi = {
  // Lấy các invoiceTemplateFunction theo userId
  getAllByUserId: (params) => {
    const url = '/invoiceTemplateFunction/getAllByUserId'
    return axiosClient.get(url, { params })
  },

  // Cập nhật nhiều invoiceTemplateFunction
  update: (params) => {
    const url = '/invoiceTemplateFunction/update'
    return axiosClient.put(url, params)
  },

  // Tạo nhiều invoiceTemplateFunction
  create: (params) => {
    console.log(params)
    const url = '/invoiceTemplateFunction/create'
    return axiosClient.post(url, params)
  },

  // Xóa các invoiceTemplateFunction theo userId
  deleteByUserId: (params) => {
    const url = '/invoiceTemplateFunction/deleteByUserId'
    return axiosClient.delete(url, { data: { params } })
  },
}

export default invoiceTemplateFunctionApi
