import {
  thunkGetCompanyTitleList,
  thunkGetCompanyUnitList,
} from 'features/Category/categorySlice'
import { thunkGetProvince } from 'features/System/systemSlice'
import {
  getAllSystemFormatNumber,
  getAllSystemCurrency,
  getAllSystemInvoiceSetting,
  getAllTimeRemind,
  getEmailConfig,
  getVATConfig,
  getEmailTemplate,
  getDataLog,
} from 'features/System/screens/SystemConfiguration/asyncThunks'
// import { getUserById } from 'features/System/screens/UserManagement/userSlice.js'
import {
  getAllUserFunction,
  getAllListFunction,
} from 'features/System/screens/UserManagement/functionSlice.js'
import LogHelper from 'general/helpers/LogHelper'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllUsers } from 'features/System/screens/UserManagement/userSlice'
import { thunkGetListSerial } from 'features/Report/reportSlice'

const sTag = '[DataCommonListener]'
// const data =

function DataCommonListener() {
  // MARK: --- Params ---
  const dispatch = useDispatch()
  const { signedIn } = useSelector((state) => state?.auth)
  const { accountId, companyId } = useSelector(
    (state) => state?.auth?.currentAccount,
  )

  // MARK: --- Hooks ---
  useEffect(() => {
    LogHelper.log(`${sTag} did load`)

    return () => {
      LogHelper.log(`${sTag} will dismiss`)
    }
  }, [])

  useEffect(() => {
    if (signedIn) {
      dispatch(thunkGetProvince())
      dispatch(thunkGetCompanyUnitList())
      dispatch(thunkGetCompanyTitleList())
      dispatch(getAllSystemFormatNumber())
      dispatch(getAllSystemCurrency())
      dispatch(getAllSystemInvoiceSetting())
      dispatch(getAllTimeRemind())
      dispatch(getEmailConfig())
      dispatch(getVATConfig())
      dispatch(getEmailTemplate())
      dispatch(thunkGetListSerial())
      dispatch(getDataLog())
    }
  }, [dispatch, signedIn])

  useEffect(() => {
    if (accountId) {
      dispatch(getAllUserFunction())
      dispatch(getAllListFunction(accountId))
      dispatch(getAllUsers(companyId))
    }
  }, [accountId, companyId])

  return <></>
}

export default DataCommonListener
