import { Button, ConfigProvider, Typography } from 'antd'
import { isEmpty } from 'lodash'
import { forwardRef } from 'react'

/**
 * A custom button component that extends Ant Design's Button component.
 * @param {object} props - The props object.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.iconCn - The class name of the icon to display on the button.
 * @param {boolean} props.isLoading - The class name of the icon to display on the button.
 * @param {import('react').CSSProperties} props.customStyle - The class name of the icon to display on the button.
 * @param {'default' | 'success' | 'warning' | 'error'} props.variant - Kiểu của button. AntProps.type sẽ override thuộc tính này. Default là 'default'
 * @param {string} [props.color] - Hex color code of the button.
 * @param {import('antd').ButtonProps} props.antProps - The props object.
 * @param {string | number} props.fontSizeText - The font size of text.
 * @param {import('react').Ref} ref - The ref object.
 * @returns {JSX.Element} - The custom button component.
 */
const CustomAntButton = (
  {
    title,
    text,
    iconCn,
    customStyle,
    variant = 'default',
    antProps,
    fontSizeText,
    htmlType,
    isLoading,
    color,
  },
  ref,
) => {
  /** @type {import('react').CSSProperties} */
  const baseStyle = {
    borderRadius: '5px',
    height: 'auto',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    padding: '8px 13px',
    fontWeight: '600',
    minWidth: '120px',
    ...customStyle,
  }
  /**
   * @param {typeof variant} type
   * @returns {import("antd").ThemeConfig['token']}
   */
  const generateVariantTheme = (type) => {
    switch (type) {
      case 'success':
        return {
          colorPrimary: '#3CC65E',
        }
      case 'warning':
        return {
          colorPrimary: '#ea580c',
        }
      case 'error':
        return {
          colorPrimary: '#dc2626',
        }
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: color
          ? {
              colorPrimary: color,
            }
          : generateVariantTheme(variant),
      }}
    >
      <Button
        htmlType={htmlType || 'button'}
        title={title || text}
        style={baseStyle}
        icon={
          isEmpty(iconCn) && !isLoading ? null : isLoading ? (
            <i
              className="far fa-spinner-third fa-spin"
              style={{ color: 'inherit' }}
            />
          ) : (
            <i className={iconCn} style={{ color: 'inherit' }} />
          )
        }
        ref={ref}
        {...antProps}
        disabled={isLoading || antProps.disabled}
      >
        {isEmpty(text) ? null : (
          <Typography.Text
            className="m-0"
            ellipsis
            style={{
              fontWeight: 'inherit',
              color: 'inherit',
              fontSize: fontSizeText ?? '14px',
            }}
          >
            {text}
          </Typography.Text>
        )}
      </Button>
    </ConfigProvider>
  )
}

export default forwardRef(CustomAntButton)
