export const colors = {
  primary: {
    text: '#ffffff',
    color: 'rgb(48, 79, 253)',
  },
  secondary: {
    text: '#000',
    color: '#ffffff',
  },
  gray: {
    text: '#fff',
    color: '#9b9b9b',
  },
  deepBlue: {
    text: '#3c4376',
    color: '#3c4376',
  },
  cellColorBg: '#f3f6f9',
  text: '#1c1e21',
  boxShadow: '0 1px 4px rgb(0 0 0/16%)',
}
