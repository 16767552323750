import { useQuery } from '@tanstack/react-query'
import companyUnitApi from 'api/companyUnitApi'
import _ from 'lodash'

/**
 * @typedef {{
 *  companyUnitId: string,
 *  companyName: string,
 *  companyCode: string,
 *  companyId: string,
 *  childrenCompany: Array<CompanyUnit>
 * }} CompanyUnit
 */

/** @return {Array<CompanyUnit>} */
const toMapped = (arr = []) =>
  arr.map((data) => ({
    companyUnitId: data.companyUnitId,
    companyName: data.name,
    companyCode: data.code,
    companyId: data.companyId,
    childrenCompany: _.isEmpty(data?.ChildrenUnits)
      ? []
      : toMapped(data.ChildrenUnits),
  }))

export default function useQueryCompanyUnit(params) {
  return useQuery({
    queryKey: ['category', { entity: 'companyUnit' }, params],
    queryFn: ({ signal }) => companyUnitApi.find(params, signal),
    select: (res) => {
      if (res.result === 'success') {
        return toMapped(res?.companyUnits)
      }
    },
  })
}
