import { Typography } from 'antd'
import { useFormik } from 'formik'
import CustomButton from 'general/components/Button/CustomButton'
import Tagnify from 'general/components/CustomTag/Tagnify'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import FormLabel from 'general/components/Forms/FormLabel'
import GroupTextField from 'general/components/Forms/GroupTextTextField'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import Utils from 'general/utils/Utils'
import { generateSchema } from 'general/utils/YupSchema/InvoiceFromPos'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import './style.scss'

ExplanationInvoiceModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  buttonAgreeOnlick: PropTypes.func,
  invoice: PropTypes.object,
}

ExplanationInvoiceModal.defaultProps = {
  title: 'Xác nhận',
  show: false,
  onClose: null,
}

function ExplanationInvoiceModal(props) {
  const { title, show, buttonAgreeOnClick, invoice, onHide, ...restProps } =
    props
  const { t } = useTranslation()

  const [showCc, setShowCc] = useState(false)
  const [showBcc, setShowBcc] = useState(false)
  const validationSchema = Yup.object().shape({
    errExplanationReason: Yup.string().required('Không được để trống'),
    customerName: Yup.string().required('Không được để trống'),
    customerEmail: Yup.string()
      .required('Không được để trống')
      .email('Email không hợp lệ'),
    // customerTaxCode: Yup.string().required(t('Required')),
  })
  const formik = useFormik({
    initialValues: {
      invoiceId: invoice?.invoiceId,
      errExplanationReason: '',
      sendEmailToCustomer: true,
      customerName: invoice?.customerName,
      customerEmail: invoice?.customerEmail || '',
      customerEmailCc: '',
      customerEmailBcc: '',
    },
    validationSchema,
    onSubmit: buttonAgreeOnClick,
    enableReinitialize: true,
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
    },
    maxFiles: 1,
    onDrop: async (files) => {
      await new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(files[0])
        img.onload = ({ target: img }) => {
          const { width, height } = img

          let logoContainer = document.getElementById('lc-frame')
          let containerWidth = logoContainer?.clientWidth
          let containerHeight = logoContainer?.clientHeight

          let imageBiggerThanContainer =
            width > containerWidth * 0.96 || height > containerHeight
          let scalingFactor = imageBiggerThanContainer
            ? Math.min(
                (containerWidth * 0.96) / width,
                containerHeight / height,
              )
            : 1
          let imageWidth = scalingFactor * width
          let imageHeight = scalingFactor * height
          let x = (containerWidth - width * scalingFactor) / 2
          let y = (containerHeight - height * scalingFactor) / 2

          // dispatch(
          //   setCustomBackgroundInfo({
          //     file: files[0],
          //     opacity: 35,
          //     position: 'center',
          //     x,
          //     y,
          //     width: imageWidth,
          //     height: imageHeight,
          //   }),
          // )
          resolve()
        }
      })
    },
  })

  return (
    <Modal
      className="ExplanationInvoiceModal font-sfpro-regular"
      {...restProps}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-bottom-0" closeButton>
        <span
          className="font-weight-bold"
          style={{ fontSize: '18px', color: '#333' }}
        >
          {title}
        </span>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div
          className="d-flex flex-row justify-content-between p-3"
          style={{ backgroundColor: '#F3F5F7' }}
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="110px" fontWeight="600">
                Số hoá đơn:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {invoice?.no}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="110px" fontWeight="600">
                Ký hiệu:
              </FormLabel>
              <span style={{ fontSize: '14px', color: '#333333' }}>
                {invoice?.serial}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="110px" fontWeight="600">
                Mã của CQT:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {invoice?.taxAuthorityCode}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="110px" fontWeight="600">
                Ngày hóa đơn:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {invoice?.date?.slice(0, 10)}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="110px" fontWeight="600">
                Khách hàng:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {invoice?.customerCompanyName}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end justify-content-between">
            <div className="d-flex flex-column align-items-end">
              <FormLabel height="30px" width="100%" fontWeight="500">
                Tổng tiền thanh toán
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '700',
                }}
              >
                {Utils.formatNumber(invoice?.totalAfterTax)} {invoice?.currency}
              </span>
            </div>

            {invoice?.invoiceErrAnnouceStatus != 5 && (
              <span
                className="badge badge-pill mb-2"
                style={{
                  fontSize: '1.1em',
                  color: InvoiceHelper.cancelInvoiceWarning(
                    invoice?.invoiceErrAnnouceStatus,
                  )?.color,
                  backgroundColor: InvoiceHelper.cancelInvoiceWarning(
                    invoice?.invoiceErrAnnouceStatus,
                  )?.bgColor,
                }}
              >
                <i
                  class="fa-regular fa-circle-exclamation"
                  style={{ color: 'inherit' }}
                ></i>{' '}
                {
                  InvoiceHelper.cancelInvoiceWarning(
                    invoice?.invoiceErrAnnouceStatus,
                  )?.text
                }
              </span>
            )}
          </div>
        </div>

        {invoice?.invoiceErrAnnouceStatus != 5 && (
          <div
            className="border p-3"
            style={{
              fontSize: '1.1em',
              backgroundColor: '#fbfbe3',
              color: '#333',
            }}
          >
            <i
              className="fa-light fa-triangle-exclamation text-warning mr-2"
              style={{ fontSize: '1.4em' }}
            ></i>
            Hóa đơn đã được duyệt bởi cơ quan thuế (hoặc phân biệt được 2 TH có
            mã thì là được cấp mã CQT, không mã thì là : CQT xác nhận hợp lệ) DN
            cần làm TBSS trước khi giải trình hóa đơn theo quy định của CQT
            (TT78 -NĐ 123)
          </div>
        )}

        <div className="d-flex flex-column pt-3" style={{ gap: '10px' }}>
          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel height="30px" width="120px" fontWeight="500">
              Lý do
            </FormLabel>
            <div className="w-100">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  ...formik.getFieldProps('errExplanationReason'),
                  status:
                    formik.getFieldMeta('errExplanationReason').touched &&
                    formik.getFieldMeta('errExplanationReason').error
                      ? 'error'
                      : undefined,
                }}
              />
              <Typography.Text type="danger" className="font-size-sm">
                {formik.getFieldMeta('errExplanationReason').touched &&
                  formik.getFieldMeta('errExplanationReason').error}
              </Typography.Text>
            </div>
          </div>

          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel height="30px" width="120px" fontWeight="500"></FormLabel>

            <div className="w-100">
              <div
                {...getRootProps({
                  className: 'dropzone',
                })}
                className="d-inline-flex flex-row justify-content-center align-items-center bg-secondary p-3 rounded cursor-pointer"
              >
                <input
                  {...getInputProps()}
                  id="upload-custom-background-input"
                />
                <i
                  className="fa-regular fa-paperclip-vertical mr-2"
                  style={{ fontSize: `1.2em`, color: 'inherit' }}
                ></i>
                <p className="mb-0">Đính kèm biên bản</p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row mt-5">
            <FormLabel height="30px" width="120px" fontWeight="500">
              Tên người nhận:
            </FormLabel>
            <div className="w-100">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  ...formik.getFieldProps('customerName'),
                  status:
                    formik.getFieldMeta('customerName').touched &&
                    formik.getFieldMeta('customerName').error
                      ? 'error'
                      : undefined,
                }}
              />
              <Typography.Text type="danger" className="font-size-sm">
                {formik.getFieldMeta('customerName').touched &&
                  formik.getFieldMeta('customerName').error}
              </Typography.Text>
            </div>
          </div>

          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel height="30px" width="120px" fontWeight="500">
              Email
            </FormLabel>
            <div className="w-100">
              <GroupTextField
                className="w-100"
                name="customerEmail"
                disabled={!formik.values.sendEmailToCustomer}
                fieldProps={formik.getFieldProps('customerEmail')}
                fieldMeta={formik.getFieldMeta('customerEmail')}
                fieldHelper={formik.getFieldHelpers('customerEmail')}
                inputGroupEnd={
                  <div className="h-100 p-2 border-left d-flex align-items-center">
                    <button
                      className={`btn btn-text btn-icon ${
                        !showCc ? 'btn-text-primary' : ''
                      }`}
                      onClick={
                        formik.values.sendEmailToCustomer
                          ? () => setShowCc((prev) => !prev)
                          : () => {}
                      }
                    >
                      Cc
                    </button>
                    <span className="vr-span mx-2"></span>
                    <button
                      className={`btn btn-text btn-icon ${
                        !showBcc ? 'btn-text-primary' : ''
                      }`}
                      onClick={
                        formik.values.sendEmailToCustomer
                          ? () => setShowBcc((prev) => !prev)
                          : () => {}
                      }
                    >
                      Bcc
                    </button>
                  </div>
                }
              />

              {/* {showBcc && (
                <GroupTextField
                  className="mt-4"
                  name="customerEmailBcc"
                  fieldProps={formik.getFieldProps('customerEmailBcc')}
                  fieldMeta={formik.getFieldMeta('customerEmailBcc')}
                  fieldHelper={formik.getFieldHelpers('customerEmailBcc')}
                  inputGroupText="Bcc"
                  placeholder="Mỗi email cách nhau bởi dấu ;"
                />
              )} */}
            </div>
          </div>

          {showCc && (
            <div className="d-flex flex-row align-items-start justify-content-start w-100">
              <FormLabel height="30px" width="120px" fontWeight="500">
                Email CC
              </FormLabel>
              <Tagnify
                defaultValue={formik.getFieldProps('customerEmailCc').value}
                delimiterString=";"
                placeholder="Mỗi email cách nhau bằng dấu ;"
                setFieldValue={formik.setFieldValue}
                name="customerEmailCc"
                pillColor="success"
                restriction={{
                  type: 'text',
                  validationSchema: generateSchema(t).emailSchema.nullable(),
                }}
              />
            </div>
          )}

          {showBcc && (
            <div className="d-flex flex-row align-items-start justify-content-start w-100">
              <FormLabel height="30px" width="120px" fontWeight="500">
                Email Bcc
              </FormLabel>
              <Tagnify
                defaultValue={formik.getFieldProps('customerEmailBcc').value}
                delimiterString=";"
                placeholder="Mỗi email cách nhau bằng dấu ;"
                setFieldValue={formik.setFieldValue}
                name="customerEmailBcc"
                pillColor="info"
                restriction={{
                  type: 'text',
                  validationSchema: generateSchema(t).emailSchema.nullable(),
                }}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="py-2">
        <CustomButton
          backgroundColor="white"
          text="Huỷ bỏ"
          width="90px"
          color="#304FFD"
          onClick={props.onHide}
        ></CustomButton>
        <CustomButton
          backgroundColor="#304FFD"
          text="Đồng ý"
          width="90px"
          color="white"
          onClick={() => {
            formik.submitForm()
          }}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ExplanationInvoiceModal
