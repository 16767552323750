import companyTitleApi from 'api/companyTitleApi'
import companyUnitApi from 'api/companyUnitApi'
import customerApi from 'api/customerApi'
import employeeApi from 'api/employeeApi'
import goodsApi from 'api/goodsApi'
import goodsGroupApi from 'api/goodsGroupApi'
import moneyTypeApi from 'api/moneyTypeApi'
import Global from 'general/utils/Global'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const thunkGetMoneyTypeList = createAsyncThunk(
  'money-type/list',
  async (params, thunkApi) => {
    const res = await moneyTypeApi.find(params)
    return res
  },
)

export const thunkGetCompanyUnitList = createAsyncThunk(
  'company-unit/list',
  async (params, thunkApi) => {
    const res = await companyUnitApi.find(params)
    return res
  },
)

export const thunkGetCompanyTitleList = createAsyncThunk(
  'company-title/list',
  async (params, thunkApi) => {
    const res = await companyTitleApi.find(params)
    return res
  },
)

export const thunkGetEmployeeList = createAsyncThunk(
  'employee/list',
  async (params, thunkApi) => {
    const res = await employeeApi.find(params)
    return res
  },
)

export const thunkGetCustomerList = createAsyncThunk(
  'customer/list',
  async (params, thunkApi) => {
    const res = await customerApi.find(params)
    return res
  },
)

export const thunkGetCustomerDetail = createAsyncThunk(
  'customer/detail',
  async (customerId, thunkApi) => {
    const res = await customerApi.detail(customerId)
    return res
  },
)

export const thunkGetGoodsGroupList = createAsyncThunk(
  'goods-group/list',
  async (params, thunkApi) => {
    const res = await goodsGroupApi.find(params)
    return res
  },
)

export const thunkGetGoodsList = createAsyncThunk(
  'goods/list',
  async (params, thunkApi) => {
    const res = await goodsApi.find(params)
    return res
  },
)

const reportSlice = createSlice({
  name: 'category',
  initialState: {
    customers: [],
    isGettingCustomer: false,

    moneyTypes: [],
    isGettingMoneyType: false,

    companyUnits: [],
    isGettingCompanyUnit: false,

    companyTitles: [],
    isGettingCompanyTitle: false,

    employees: [],
    isGettingEmployee: false,

    goodsGroups: [],
    isGettingGoodsGroup: false,

    goods: [],
    isGettingGoods: false,

    pagination: { perPage: Global.gDefaultPagination, currentPage: 0 },
  },
  reducers: {
    setPaginationPerPage: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    // get list money type
    builder.addCase(thunkGetMoneyTypeList.pending, (state, action) => {
      state.isGettingMoneyType = true
    })
    builder.addCase(thunkGetMoneyTypeList.rejected, (state, action) => {
      state.isGettingMoneyType = false
    })
    builder.addCase(thunkGetMoneyTypeList.fulfilled, (state, action) => {
      state.isGettingMoneyType = false
      const { result, moneyTypes, total, count, page } = action.payload
      if (result === 'success') {
        if (moneyTypes) {
          state.moneyTypes = moneyTypes
        }
        state.pagination = {
          ...state.pagination,
          total: total,
          count: count,
          currentPage: page + 1,
        }
      }
    })

    // get list company unit
    builder.addCase(thunkGetCompanyUnitList.pending, (state, action) => {
      state.isGettingCompanyUnit = true
    })
    builder.addCase(thunkGetCompanyUnitList.rejected, (state, action) => {
      state.isGettingCompanyUnit = false
    })
    builder.addCase(thunkGetCompanyUnitList.fulfilled, (state, action) => {
      state.isGettingCompanyUnit = false
      const { result, companyUnits, total, count, page } = action.payload
      if (result === 'success') {
        if (companyUnits) {
          state.companyUnits = companyUnits
        }
        // state.pagination = {
        //   ...state.pagination,
        //   total: total,
        //   count: count,
        //   currentPage: page + 1,
        // };
      }
    })

    // get list company title
    builder.addCase(thunkGetCompanyTitleList.pending, (state, action) => {
      state.isGettingCompanyTitle = true
    })
    builder.addCase(thunkGetCompanyTitleList.rejected, (state, action) => {
      state.isGettingCompanyTitle = false
    })
    builder.addCase(thunkGetCompanyTitleList.fulfilled, (state, action) => {
      state.isGettingCompanyTitle = false
      const { result, companyTitles, total, count, page } = action.payload
      if (result === 'success') {
        if (companyTitles) {
          state.companyTitles = companyTitles
        }
        if (total && count) {
          state.pagination = {
            ...state.pagination,
            total: total,
            count: count,
            currentPage: page + 1,
          }
        }
      }
    })

    // get list employee
    builder.addCase(thunkGetEmployeeList.pending, (state, action) => {
      state.isGettingEmployee = true
    })
    builder.addCase(thunkGetEmployeeList.rejected, (state, action) => {
      state.isGettingEmployee = false
    })
    builder.addCase(thunkGetEmployeeList.fulfilled, (state, action) => {
      state.isGettingEmployee = false
      const { result, employees, total, count, page } = action.payload
      if (result === 'success') {
        if (employees) {
          state.employees = employees
        }
        state.pagination = {
          ...state.pagination,
          total: total,
          count: count,
          currentPage: page + 1,
        }
      }
    })

    // get list customer
    builder.addCase(thunkGetCustomerList.pending, (state, action) => {
      state.isGettingCustomer = true
    })
    builder.addCase(thunkGetCustomerList.rejected, (state, action) => {
      state.isGettingCustomer = false
    })
    builder.addCase(thunkGetCustomerList.fulfilled, (state, action) => {
      state.isGettingCustomer = false
      const { result, customers, total, count, page } = action.payload
      if (result === 'success') {
        if (customers) {
          state.customers = customers
        }
        state.pagination = {
          ...state.pagination,
          total: total,
          count: count,
          currentPage: page + 1,
        }
      }
    })

    // get list goods group
    builder.addCase(thunkGetGoodsGroupList.pending, (state, action) => {
      state.isGettingGoodsGroup = true
    })
    builder.addCase(thunkGetGoodsGroupList.rejected, (state, action) => {
      state.isGettingGoodsGroup = false
    })
    builder.addCase(thunkGetGoodsGroupList.fulfilled, (state, action) => {
      state.isGettingGoodsGroup = false
      const { result, goodsGroups, total, count, page } = action.payload
      if (result === 'success') {
        if (goodsGroups) {
          state.goodsGroups = goodsGroups
        }
        state.pagination = {
          ...state.pagination,
          total: total,
          count: count,
          currentPage: page + 1,
        }
      }
    })

    // get list goods
    builder.addCase(thunkGetGoodsList.pending, (state, action) => {
      state.isGettingGoods = true
    })
    builder.addCase(thunkGetGoodsList.rejected, (state, action) => {
      state.isGettingGoods = false
    })
    builder.addCase(thunkGetGoodsList.fulfilled, (state, action) => {
      state.isGettingGoods = false
      const { result, goods, total, count, page } = action.payload
      if (result === 'success') {
        if (goods) {
          state.goods = goods
        }
        state.pagination = {
          ...state.pagination,
          total: total,
          count: count,
          currentPage: page + 1,
        }
      }
    })
  },
})

const { actions, reducer } = reportSlice
export const { setPaginationPerPage } = actions
export default reducer
