import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  DivCustomForm,
  DivFieldValue,
  DivFiledSingleLogo,
  DivTddCode,
  ImgRnd,
  ImgRndTemplate,
  RndCustom,
  RndCustomTemplate,
  SpanFieldValue,
} from 'features/TaxDeductionDocuments/styled'
import {
  createDivFieldValue,
  createSpanFieldValue,
} from 'features/TaxDeductionDocuments/Utils/component'
import SpanFieldValueComponent from 'features/TaxDeductionDocuments/Utils/component/SpanFieldValueCp'
import DivFieldValueComponent from 'features/TaxDeductionDocuments/Utils/component/DivFieldValueCp'
function ValueImgContent({
  styleInput,
  data,
  language,
  textAlign,
  img,
  widthtd,
  heighttd,
  imgLogo,
  setImgLogo,
  formState,
  setTabActiveKey,
  activeFeild,
  setActiveFeild,
  activeLayout,
  indexLayout,
  itemLayout,
  indexTd,
  activeTd,
  listIndentify,
  setListIndentify,
  templateDefault,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useMemo(() => {
    setImgLogo({ ...imgLogo, location: formState.positionLogo, x: 0, y: 0 })
  }, [formState.positionLogo])

  const enableResizing = {
    top: false,
    right: false,
    bottom: false,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  }

  const renderRndCustom = (imgLogo, widthtd, location, imgData) => {
    return imgData.type === 'logo' ? (
      imgLogo.url && (
        <RndCustom
          style={{ position: 'relative', width: '100%', maxWidth: widthtd }}
          bounds="parent"
          disableDragging={true}
          // enableResizing={enableResizing}
          size={{
            width: imgLogo.width,
            height: imgLogo.height,
          }}
          // position={{ x: imgLogo.x, y: imgLogo.y }}
          position={{
            x: (widthtd - imgLogo.width) / 2,
            y: location === 'under' ? (heighttd - imgLogo.height) / 2 + 30 : 0,
          }}
          onDragStop={(e, d) => {
            setImgLogo({ ...imgLogo, x: d.x, y: d.y })
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            setImgLogo({
              ...position,
              width: Number(ref.style.width.replace('px', '')),
              height: Number(ref.style.height.replace('px', '')),
            })
          }}
        >
          <ImgRnd
            className="w-100 h-100"
            src={imgLogo.url}
            width={imgLogo.width}
            height={imgLogo.height}
          />
        </RndCustom>
      )
    ) : (
      <div>
        <img src={imgData.url} height={imgData.height} width={imgData.width} />
      </div>
    )
  }

  return (
    <>
      {language?.map((lang, index) => (
        <div key={index}>
          {/* Data Vs Image */}
          {data.value &&
            data.value.length > 0 &&
            (imgLogo?.url || img.url) &&
            data.value?.map((dataItem, idx) => {
              const content = dataItem[lang]
              if (
                content &&
                !dataItem.hide &&
                (!content.transformationTemplate ||
                  formState.viewConversionTemplate)
              ) {
                return (
                  <DivTddCode key={idx} padding={content.labelStyle?.padding}>
                    {content.label ? (
                      <DivTddCode textAlign={textAlign}>
                        {(imgLogo?.url || img.url) &&
                          imgLogo.location == 'top' &&
                          idx == 0 &&
                          !styleInput &&
                          renderRndCustom(imgLogo, widthtd, 'top', img)}
                        <SpanFieldValueComponent
                          value={content.label}
                          style={content.labelStyle}
                          funClick={() => {
                            setActiveFeild({
                              type: 'label',
                              data: content,
                              index: idx,
                            })
                          }}
                          type="label"
                          activeFeild={activeFeild}
                          indexLayout={indexLayout}
                          activeLayout={activeLayout}
                          idx={idx}
                          itemLayout={itemLayout}
                          itemFeild={dataItem}
                          indexTd={indexTd}
                          activeTd={activeTd}
                          language={lang}
                          formState={formState}
                          listIndentify={listIndentify}
                          setListIndentify={setListIndentify}
                          templateDefault={templateDefault}
                        />
                        <SpanFieldValueComponent
                          value={content.value}
                          style={content.valueStyle}
                          funClick={() => {
                            setActiveFeild({
                              type: 'value',
                              data: content,
                              index: idx,
                            })
                          }}
                          type="value"
                          activeFeild={activeFeild}
                          indexLayout={indexLayout}
                          activeLayout={activeLayout}
                          idx={idx}
                          itemFeild={dataItem}
                          indexTd={indexTd}
                          activeTd={activeTd}
                          language={lang}
                          formState={formState}
                          listIndentify={listIndentify}
                          setListIndentify={setListIndentify}
                          templateDefault={templateDefault}
                        />
                        {(imgLogo?.url || img.url) &&
                          imgLogo.location == 'under' &&
                          idx == 0 &&
                          !styleInput &&
                          renderRndCustom(imgLogo, widthtd, 'under', img)}
                      </DivTddCode>
                    ) : (
                      <DivCustomForm textAlign={img.style?.textAlign}>
                        {(imgLogo?.url || img.url) &&
                          imgLogo.location == 'top' &&
                          idx == 0 &&
                          !styleInput &&
                          renderRndCustom(imgLogo, widthtd, 'top', img)}
                        <DivFieldValueComponent
                          styleInput={styleInput}
                          content={content}
                          idx={idx}
                          funClick={() => {
                            // alert(content.value)
                            setActiveFeild({
                              type: 'value',
                              data: content,
                              index: idx,
                            })
                          }}
                          type="value"
                          activeFeild={activeFeild}
                          indexLayout={indexLayout}
                          activeLayout={activeLayout}
                          itemFeild={dataItem}
                          indexTd={indexTd}
                          activeTd={activeTd}
                          language={lang}
                          formState={formState}
                          listIndentify={listIndentify}
                          setListIndentify={setListIndentify}
                          templateDefault={templateDefault}
                        />
                        {(imgLogo?.url || img.url) &&
                          imgLogo.location == 'under' &&
                          idx == 0 &&
                          !styleInput &&
                          renderRndCustom(imgLogo, widthtd, 'under', img)}
                      </DivCustomForm>
                    )}
                  </DivTddCode>
                )
              }
              return null
            })}
          {/* Image */}
          {(!data.value || data.value.length === 0) &&
            (imgLogo?.url || img.url) &&
            !styleInput && (
              <DivTddCode>
                <DivCustomForm textAlign={'center'}>
                  {(imgLogo?.url || img.url) &&
                    renderRndCustom(imgLogo, widthtd, 'top', img)}
                  <DivFieldValueComponent
                    content={{
                      valueStyle: {
                        width: '100%',
                        height: heighttd - imgLogo.height,
                      },
                    }}
                  />
                </DivCustomForm>
              </DivTddCode>
            )}
        </div>
      ))}
    </>
  )
}

export default ValueImgContent
