import { Tabs } from 'antd'
import { useState } from 'react'
// import ChangingHistory from './tabs/ChangingHistory'
import EInvoiceFile from './tabs/EInvoiceFile'

EnterpriseProfile.propTypes = {}

function EnterpriseProfile(props) {
  //MARK --- Params: ---
  const [selectedTab] = useState([
    {
      key: 'e-invoice-profile',
      label: 'Hồ sơ hóa đơn điện tử',
      children: <EInvoiceFile />,
    },
  ])
  return (
    <>
      <div className="card card-custom w-100">
        {/* card header */}
        <div className="card-header border-0 pt-6 pb-6">
          <div className="w-100">
            <Tabs items={selectedTab} />
          </div>
        </div>
      </div>
    </>
  )
}

export default EnterpriseProfile
