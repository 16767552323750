import React, { Fragment, useEffect, useMemo, useState } from 'react'
import './style.scss'
import '../../resize.scss'

import { Rnd } from 'react-rnd'
import Table from 'rc-table'
import 'react-resizable/css/styles.css'
import { useSelector } from 'react-redux'
import AppResource from 'general/constants/AppResource'
import { useDispatch } from 'react-redux'
import {
  setActiveRegionBaseOnName,
  setBackgroundInfo,
  setCustomBackgroundInfo,
  setLogoInfo,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import _ from 'lodash'
import Utils from 'general/utils/Utils'
import { ReactSVG } from 'react-svg'
import TemplateInvoiceHelper, {
  fieldAlignConvert,
} from 'general/helpers/TemplateInvoiceHelper'
import ResizableFlexBox from 'features/TemplateInvoice/components/ResizableFlexbox'
import ResizableCell from 'features/TemplateInvoice/components/ResizableCell'
import { Resizable } from 're-resizable'
import Draggable from 'react-draggable'
// import { useColumns, useColumnsDispatch } from '../../ColumnsContext'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { useColumnsDispatch } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'

const handleResize =
  (index, cb) =>
  (e, { size }) => {
    cb((oldColumns) => {
      const nextColumns = [...oldColumns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      if (nextColumns[index]?.children?.length) {
        nextColumns[index].children = nextColumns[index].children.map(
          (item) => ({
            ...item,
            width: size.width / 2,
          }),
        )
      }
      return nextColumns
    })
  }

const genSignXmlData = (
  regionConfigsGroupByMergeField,
  activeDataFieldName,
  isShowLabelEn,
  paperSize,
) => {
  let returnValue = regionConfigsGroupByMergeField.reduce(
    (
      returnObj,
      [mergeField, [dataField1, dataField2, dataField3, dataField4]],
    ) => {
      returnObj[mergeField] = (
        <div key={Utils.makeId(2)}>
          {dataField1.show && (
            <div
              className={`horizontal-content text-center justify-content-center ${
                dataField1.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(dataField1.label)}
              >
                {dataField1.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(dataField1.labelEn)}
                >
                  {dataField1.labelEn.value}
                </div>
              )}
            </div>
          )}
          {dataField2.show && (
            <div
              className={`vertical-content text-center ${
                dataField2.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(dataField2.label)}
              >
                {dataField2.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(dataField2.labelEn)}
                >
                  {dataField2.labelEn.value}
                </div>
              )}
            </div>
          )}

          {dataField3 && dataField4 && (
            <div
              className="p-2 mx-auto"
              style={{
                backgroundColor: '#E3F2E4',
                border: '1px solid #7cc576',
                marginTop: `${paperSize == 'A5' ? 3 : 15}pt`,
                width: '200pt',
              }}
            >
              <div style={{ fontWeight: 600 }}>Signature Valid</div>
              {dataField3.show && (
                <div
                  className={`horizontal-content ${
                    dataField3.dataField === activeDataFieldName
                      ? 'active-data-field-l '
                      : ''
                  }`}
                >
                  <div
                    className="edit-label mr-1"
                    style={Utils.filterStyles(dataField3.label)}
                  >
                    {dataField3.label.value}
                  </div>
                  {isShowLabelEn && (
                    <div
                      className="edit-label-en"
                      style={Utils.filterStyles(dataField3.labelEn)}
                    >
                      {dataField3.labelEn.value}
                    </div>
                  )}
                </div>
              )}
              {dataField4.show && (
                <div
                  className={`horizontal-content ${
                    dataField4.dataField === activeDataFieldName
                      ? 'active-data-field-l '
                      : ''
                  }`}
                >
                  <div
                    className="edit-label mr-1"
                    style={Utils.filterStyles(dataField4.label)}
                  >
                    {dataField4.label.value}
                  </div>
                  {isShowLabelEn && (
                    <div
                      className="edit-label-en"
                      style={Utils.filterStyles(dataField4.labelEn)}
                    >
                      {dataField4.labelEn.value}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )

      return returnObj
    },
    {},
  )
  return [returnValue]
}

const LogoContainer = (props) => {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()

  const setFirstInvoiceColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'firstInvoiceColumns',
      payload,
    })
  }
  const { activePanel } = useSelector((state) => state.templateInvoice)
  const { logoInfo, logoPosition } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const oldLogoContainerWidth = useMemo(() => 140, [])

  return (
    <>
      {logoInfo && (
        <Draggable
          bounds="#logo-container"
          handle=".lc-frame-background"
          cancel=".icon-gripsmall"
          disabled={activePanel !== 'logo_ctrl'}
          position={{ x: logoInfo.x, y: logoInfo.y }}
          onStop={(e, data) => dispatch(setLogoInfo({ x: data.x, y: data.y }))}
        >
          <Resizable
            width={logoInfo.width}
            height={logoInfo.height}
            maxWidth={'250px'}
            enable={
              activePanel !== 'logo_ctrl'
                ? false
                : {
                    top: false,
                    right: true,
                    bottom: true,
                    left: false,
                    topRight: false,
                    bottomRight: true,
                    bottomLeft: false,
                    topLeft: false,
                  }
            }
            size={{ width: logoInfo.width, height: logoInfo.height }}
            onResize={(e, direction, ref, d) => {
              let parentWidth =
                document.getElementById('logo-container')?.offsetWidth

              let style = window.getComputedStyle(ref)
              let matrix = new DOMMatrix(style.transform)
              let { m41: x, m42: y } = matrix

              let w = x + logoInfo.width + d.width
              if (w > parentWidth || parentWidth > oldLogoContainerWidth) {
                setFirstInvoiceColumns((oldColumns) => {
                  let index = logoPosition == 1 ? 0 : 1
                  const nextColumns = [...oldColumns]
                  nextColumns[index] = {
                    ...nextColumns[index],
                    width: w,
                  }
                  return nextColumns
                })
              }
            }}
            onResizeStop={(e, direction, ref, d) => {
              dispatch(
                setLogoInfo({
                  width: logoInfo.width + d.width,
                  height: logoInfo.height + d.height,
                }),
              )
            }}
          >
            <div
              className={`lc-frame-background w-100 h-100 ${
                activePanel === 'logo_ctrl' ? 'border border-primary' : ''
              }`}
              // style={{width: logoInfo.width + 'px', height: logoInfo.height + 'px'}}
            >
              <img
                className="w-100 h-100"
                src={
                  logoInfo?.logoFile instanceof File
                    ? URL.createObjectURL(logoInfo.logoFile)
                    : `${process.env.REACT_APP_BASE_URL}/resources/logo/${logoInfo?.logoFile}`
                }
                alt="..."
                draggable={false}
                title="Logo"
              />
              {activePanel === 'logo_ctrl' && (
                <div className="icon-gripsmall"></div>
              )}
            </div>
          </Resizable>
        </Draggable>
      )}
    </>
  )
}

/* SellerInfo */
const SellerInfo = (props) => {
  const dispatch = useDispatch()
  const { activeDataField, activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const sellerInfoRegion = useMemo(
    () => _.find(RegionConfigs, (region) => region.regionName === 'sellerInfo'),
    [RegionConfigs],
  )
  const regionConfigs = useMemo(
    () => sellerInfoRegion?.regionConfigs,
    [sellerInfoRegion],
  )

  const [maxWidth, setMaxWidth] = useState(0)

  useEffect(() => {
    setMaxWidth(0)

    if (sellerInfoRegion) {
      if (sellerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
        setTimeout(() => {
          let items = document.querySelectorAll('.SI-wrap')
          let mw = 0
          items.forEach((item) => {
            mw = Math.max(mw, item.offsetWidth)
          })

          setMaxWidth(mw)
        }, [0.2])
      }
    }
  }, [isShowLabelEn])

  useEffect(() => {
    if (!sellerInfoRegion) return

    if (sellerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
      let items = document.querySelectorAll('.SI-wrap')
      let mw = 0
      items.forEach((item) => {
        mw = Math.max(mw, item.offsetWidth)
      })

      setMaxWidth(mw)
    } else {
      setMaxWidth(0)
    }
  }, [sellerInfoRegion])

  useEffect(() => {
    if (_.isNumber(maxWidth)) {
      sessionStorage.setItem(PreferenceKeys.sellerInfoMaxWidth, maxWidth)
    }
  }, [maxWidth])

  return (
    <div
      className={`w-100 sellerInfo ${
        activeRegion?.regionName == 'sellerInfo' ? 'region-active' : ''
      }`}
      style={{ minHeight: '100%' }}
      onClick={() => {
        if (activePanel === 'detail_ctrl') {
          dispatch(setActiveRegionBaseOnName('sellerInfo'))
        }
      }}
    >
      {regionConfigs &&
        TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
          regionConfigs,
        )?.map(([mergeField, ...regionConfArr], indexP) =>
          regionConfArr?.length > 1 ? (
            <ResizableFlexBox
              mergeField={mergeField}
              regionName="sellerInfo"
              regionConfArr={regionConfArr?.filter(({ show }) => show)}
              maxWidth={maxWidth}
              key={indexP}
            />
          ) : (
            <div className="d-flex flex-row" key={indexP}>
              {regionConfArr?.map((reConf, index) =>
                reConf.show ? (
                  !reConf?.typeShow || reConf?.typeShow == 3 ? (
                    <div
                      key={index}
                      className={`horizontal-content same-width-child text-${fieldAlignConvert(
                        reConf.fieldAlign || 'start',
                      )} justify-content-${reConf.fieldAlign || 'start'} ${
                        reConf.dataField === activeDataField?.dataField
                          ? 'active-data-field-l '
                          : ''
                      }`}
                    >
                      <div
                        className={`SI-wrap ${
                          reConf?.displayType === 'CONTENT_LEFT'
                            ? 'd-none'
                            : 'horizontal-content'
                        }`}
                        style={{
                          minWidth: maxWidth /* , whiteSpace: 'normal' */,
                        }}
                      >
                        {/* label */}
                        <div
                          className={`SI-item edit-label mr-1 ${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : ''
                          }`}
                          style={Utils.filterStyles(reConf.label)}
                        >
                          {reConf.label.value}
                        </div>
                        {/* labelEn */}
                        {isShowLabelEn && (
                          <div
                            className={`SI-item edit-label-en ${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                            style={Utils.filterStyles(reConf.labelEn)}
                          >
                            {reConf.labelEn.value}
                          </div>
                        )}
                        {sellerInfoRegion?.alignType !== 'ALIGN_TYPE_3' && (
                          <div
                            className={`two-dot ${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                            style={Utils.filterStyles(reConf.label)}
                          >
                            :
                          </div>
                        )}
                      </div>
                      {sellerInfoRegion?.alignType === 'ALIGN_TYPE_3' && (
                        <div className="two-dot">
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : reConf.displayType === 'CONTENT_LEFT'
                                  ? 'd-none'
                                  : ''
                            }`}
                          >
                            :
                          </span>
                        </div>
                      )}
                      {/* value */}
                      <div
                        className={`SI-item edit-value ${
                          reConf?.displayType != 'CONTENT_LEFT' ? 'ml-1' : ''
                        }`}
                        style={{
                          ...Utils.filterStyles(reConf.value),
                          flexShrink: 100,
                        }}
                      >
                        {reConf.dataField.includes('TaxCode') &&
                        reConf?.mstSplitCells ? (
                          <div className="d-flex flex-row align-items-center tax-code-input mr-2">
                            {reConf.value.value.split('').map((num, index) => (
                              <div key={index} className="tax-code-number">
                                {num}
                              </div>
                            ))}
                          </div>
                        ) : (
                          reConf.value.value
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`SI-item edit-value text-${fieldAlignConvert(
                        reConf.fieldAlign || 'start',
                      )} ${
                        reConf.dataField === activeDataField?.dataField
                          ? 'active-data-field-l '
                          : ''
                      }`}
                      style={{
                        ...Utils.filterStyles(reConf.value),
                        flexShrink: 100,
                      }}
                    >
                      {reConf.value.value}
                    </div>
                  )
                ) : (
                  <Fragment key={index}></Fragment>
                ),
              )}
            </div>
          ),
        )}
    </div>
  )
}

/* InvoiceInfo */
const InvoiceInfo = (props) => {
  const dispatch = useDispatch()

  const { activeDataField, activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceInfoRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'invoiceInfo'),
    [RegionConfigs],
  )
  const regionConfigs = invoiceInfoRegion?.regionConfigs

  return (
    <div
      className={`w-100 invoice-info ${
        activeRegion?.regionName == 'invoiceInfo' ? 'region-active' : ''
      }`}
      style={{ minHeight: '100%' }}
      onClick={() => {
        if (activePanel === 'detail_ctrl') {
          dispatch(setActiveRegionBaseOnName('invoiceInfo'))
        }
      }}
    >
      {regionConfigs?.map((reConf, index) =>
        reConf.show ? (
          <Fragment key={index}>
            {(!reConf?.typeShow || reConf?.typeShow == 2) && (
              <div
                className={`vertical-content text-center ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
              >
                <div
                  className="edit-label"
                  style={Utils.filterStyles(reConf.label)}
                >
                  {reConf.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(reConf.labelEn)}
                  >
                    {reConf.labelEn.value}
                  </div>
                )}
              </div>
            )}

            {reConf?.typeShow === 1 && (
              <div
                key={index}
                className={`text-center ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
                style={Utils.filterStyles(reConf.value)}
              >
                {reConf.value.value}
              </div>
            )}

            {reConf?.typeShow === 3 && (
              <div
                className={`horizontal-content justify-content-center ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
              >
                <div
                  className="edit-label mr-1"
                  style={Utils.filterStyles(reConf.label)}
                >
                  {reConf.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(reConf.labelEn)}
                  >
                    {reConf.labelEn.value}
                  </div>
                )}
                {reConf?.dataField != 'typeInvoice' && (
                  <div style={Utils.filterStyles(reConf.label)}>:</div>
                )}
                <div
                  className="edit-value ml-1"
                  style={Utils.filterStyles(reConf.value)}
                >
                  {reConf.value.value}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment key={index}></Fragment>
        ),
      )}
    </div>
  )
}

/* InvoiceInfoOther */
const InvoiceOtherInfo = (props) => {
  const dispatch = useDispatch()

  const { activeDataField, activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceInfoOtherRegion = useMemo(
    () =>
      _.find(
        RegionConfigs,
        (region) => region.regionName === 'invoiceInfoOther',
      ),
    [RegionConfigs],
  )
  const regionConfigs = invoiceInfoOtherRegion?.regionConfigs

  return (
    <table
      className="invoice-other-info"
      onClick={(e) => {
        e.stopPropagation()
        if (activePanel === 'detail_ctrl') {
          dispatch(setActiveRegionBaseOnName('invoiceInfoOther'))
        }
      }}
    >
      <colgroup>
        <col style={{ width: 'min-content' }}></col>
      </colgroup>
      <tbody>
        {regionConfigs?.map((reConf, index) =>
          reConf.show ? (
            <tr>
              <td>
                <p
                  className="mb-0"
                  // key={index}
                  // className={`horizontal-content ${
                  //   reConf.dataField === activeDataField?.dataField
                  //     ? 'active-data-field-l '
                  //     : ''
                  // }`}
                >
                  <span
                    className="edit-label mr-1"
                    style={Utils.filterStyles(reConf.label)}
                  >
                    {reConf.label.value}
                  </span>
                  {isShowLabelEn && (
                    <span
                      className="edit-label-en"
                      style={Utils.filterStyles(reConf.labelEn)}
                    >
                      {reConf.labelEn.value}
                    </span>
                  )}
                  <span style={Utils.filterStyles(reConf.label)}>:</span>
                </p>
              </td>
              <td>
                <span
                  className="edit-value ml-1"
                  style={Utils.filterStyles(reConf.value)}
                >
                  {reConf.value.value}
                </span>
              </td>
            </tr>
          ) : (
            <></>
          ),
        )}
      </tbody>
    </table>
  )
}

/* InvoiceTable */
const InvoiceTable = (props) => {
  const columnsDispatch = useColumnsDispatch()

  const invoiceInfoColumns = useColumns('invoiceInfoColumns')
  const setInvoiceInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'invoiceInfoColumns',
      payload,
    })
  }

  const { activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { sellerInfoPosition } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceTableData = useMemo(() => {
    return [
      {
        col1: sellerInfoPosition == 3 ? <SellerInfo /> : <></>,
        col2: <InvoiceInfo />,
        col3: <InvoiceOtherInfo />,
      },
    ]
  }, [sellerInfoPosition])

  const invoiceTableActive = useMemo(
    () =>
      ['gen_ctrl'].includes(activePanel) ||
      (sellerInfoPosition == 1 &&
        (activeRegion?.regionName === 'invoiceInfo' ||
          activeRegion?.regionName === 'invoiceInfoOther')),
    [activePanel, sellerInfoPosition, activeRegion],
  )

  return (
    <Table
      className={`custom-resizable-table invoiceInfo ${
        invoiceTableActive ? 'region-active' : ''
      }`}
      showHeader={false}
      tableLayout="fixed"
      emptyText={<></>}
      components={{
        body: {
          cell: ResizableCell,
        },
      }}
      columns={invoiceInfoColumns.map((col, indexCol) => ({
        ...col,
        onCell: (_) => ({
          width: col.width,
          resizeEnable: invoiceTableActive,
          onResize: handleResize(indexCol, setInvoiceInfoColumns),
        }),
      }))}
      data={invoiceTableData}
    />
  )
}

/* BuyerInfoContent */
const BuyerInfoContent = (props) => {
  const { activeDataField } = useSelector((state) => state.templateInvoice)
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const buyerInfoRegion = useMemo(
    () => _.find(RegionConfigs, (region) => region.regionName === 'buyerInfo'),
    [RegionConfigs],
  )
  const regionConfigs = useMemo(
    () => buyerInfoRegion?.regionConfigs,
    [buyerInfoRegion],
  )

  const [maxWidth, setMaxWidth] = useState(0)

  useEffect(() => {
    setMaxWidth(0)

    if (buyerInfoRegion) {
      if (buyerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
        setTimeout(() => {
          let items = document.querySelectorAll('.BIC-wrap')
          let mw = 0
          items.forEach((item) => {
            mw = Math.max(mw, item.offsetWidth)
          })

          setMaxWidth(mw)
        }, [0.2])
      }
    }
  }, [isShowLabelEn])

  useEffect(() => {
    if (!buyerInfoRegion) return

    if (buyerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
      let items = document.querySelectorAll('.BIC-wrap')
      let mw = 0
      items.forEach((item) => {
        mw = Math.max(mw, item.offsetWidth)
      })

      setMaxWidth(mw)
    } else {
      setMaxWidth(0)
    }
  }, [buyerInfoRegion])

  useEffect(() => {
    if (_.isNumber(maxWidth)) {
      sessionStorage.setItem(PreferenceKeys.buyerInfoMaxWidth, maxWidth)
    }
  }, [maxWidth])

  return (
    <div
      className="w-100 buyerInfoContent"
      style={{ minHeight: '100%' }}
      id="buyerInfoContent"
    >
      {regionConfigs &&
        TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
          regionConfigs,
        )?.map(([mergeField, ...regionConfArr], indexP) =>
          regionConfArr?.length > 1 ? (
            <ResizableFlexBox
              mergeField={mergeField}
              regionName="buyerInfo"
              regionConfArr={regionConfArr?.filter(({ show }) => show)}
              maxWidth={maxWidth}
              key={indexP}
            />
          ) : (
            <div className="d-flex flex-row" key={indexP}>
              {regionConfArr.map((reConf, index) =>
                reConf.show ? (
                  <div
                    key={index}
                    className={`horizontal-content same-width-child ${
                      reConf.dataField === activeDataField?.dataField
                        ? 'active-data-field-l '
                        : ''
                    }`}
                    style={{ marginLeft: `${(reConf.mrRank || 0) * 12}pt` }}
                  >
                    <div
                      className={`BIC-wrap ${
                        reConf?.displayType === 'CONTENT_LEFT'
                          ? 'd-none'
                          : 'd-flex flew-row align-items-center'
                      }`}
                      style={{ minWidth: maxWidth /*  whiteSpace: 'nowrap' */ }}
                    >
                      {/* label */}
                      <div
                        className="BIC-item edit-label mr-1"
                        style={Utils.filterStyles(reConf.label)}
                      >
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : ''
                          }`}
                        >
                          {reConf.label.value}
                        </span>
                      </div>
                      {/* labelEn */}
                      {isShowLabelEn && (
                        <div
                          className="BIC-item edit-label-en"
                          style={Utils.filterStyles(reConf.labelEn)}
                        >
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                          >
                            {reConf.labelEn.value}
                          </span>
                        </div>
                      )}
                      {buyerInfoRegion?.alignType !== 'ALIGN_TYPE_3' && (
                        <div className="two-dot">
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                            style={Utils.filterStyles(reConf.label)}
                          >
                            :
                          </span>
                        </div>
                      )}
                    </div>
                    {buyerInfoRegion?.alignType === 'ALIGN_TYPE_3' && (
                      <div className="two-dot">
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : reConf.displayType === 'CONTENT_LEFT'
                                ? 'd-none'
                                : ''
                          }`}
                        >
                          :
                        </span>
                      </div>
                    )}
                    {/* value */}
                    <div
                      className="BIC-item edit-value ml-1"
                      style={{
                        ...Utils.filterStyles(reConf.value),
                        flexShrink: 100,
                      }}
                    >
                      {reConf.dataField.includes('TaxCode') &&
                      reConf?.mstSplitCells ? (
                        <div className="d-flex flex-row align-items-center tax-code-input mr-2">
                          {reConf.value.value.split('').map((num, index) => (
                            <div key={index} className="tax-code-number">
                              {num}
                            </div>
                          ))}
                        </div>
                      ) : (
                        reConf.value.value
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
          ),
        )}
    </div>
  )
}

/* InvoiceInfoOther */
const InvoiceInfoOther = (props) => {
  const dispatch = useDispatch()

  const { activeRegion, activeDataField, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceInfoOtherRegion = useMemo(
    () =>
      _.find(
        RegionConfigs,
        (region) => region.regionName === 'invoiceInfoOther',
      ),
    [RegionConfigs],
  )
  const regionConfigs = useMemo(
    () => invoiceInfoOtherRegion?.regionConfigs,
    [invoiceInfoOtherRegion],
  )

  // const [maxWidth, setMaxWidth] = useState(0)

  // useEffect(() => {
  //   setMaxWidth(0)

  //   if (invoiceInfoOtherRegion) {
  //     if (invoiceInfoOtherRegion.alignType !== 'ALIGN_TYPE_1') {
  //       setTimeout(() => {
  //         let items = document.querySelectorAll('.IIO-wrap')
  //         let mw = 0
  //         items.forEach((item) => {
  //           mw = Math.max(mw, item.offsetWidth)
  //         })

  //         setMaxWidth(mw)
  //       }, [0.2])
  //     }
  //   }
  // }, [isShowLabelEn])

  // useEffect(() => {
  //   if (!invoiceInfoOtherRegion) return

  //   if (invoiceInfoOtherRegion.alignType !== 'ALIGN_TYPE_1') {
  //     let items = document.querySelectorAll('.IIO-wrap')
  //     let mw = 0
  //     items.forEach((item) => {
  //       mw = Math.max(mw, item.offsetWidth)
  //     })

  //     setMaxWidth(mw)
  //   } else {
  //     setMaxWidth(0)
  //   }
  // }, [invoiceInfoOtherRegion])

  // useEffect(() => {
  //   if (_.isNumber(maxWidth)) {
  //     sessionStorage.setItem(PreferenceKeys.invoiceInfoOtherMaxWidth, maxWidth)
  //   }
  // }, [maxWidth])

  return (
    <div
      id="invoiceInfoOtherContent"
      className={`w-100 invoiceInfoOtherContent ${
        activeRegion?.regionName == 'invoiceInfoOther'
      }`}
      style={{ minHeight: '100%' }}
      onClick={() => {
        if (activePanel === 'detail_ctrl') {
          dispatch(setActiveRegionBaseOnName('invoiceInfoOther'))
        }
      }}
    >
      {regionConfigs &&
        TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
          regionConfigs,
        )?.map(([mergeField, ...regionConfArr], indexP) =>
          regionConfArr?.length > 1 ? (
            <ResizableFlexBox
              mergeField={mergeField}
              regionName="invoiceInfoOther"
              regionConfArr={regionConfArr?.filter(({ show }) => show)}
              // maxWidth={maxWidth}
              key={indexP}
            />
          ) : (
            <div className="d-flex flex-row" key={indexP}>
              {regionConfArr.map((reConf, index) =>
                reConf.show ? (
                  <div
                    key={index}
                    className={`horizontal-content same-width-child ${
                      reConf.dataField === activeDataField?.dataField
                        ? 'active-data-field-l '
                        : ''
                    }`}
                    style={{ marginLeft: `${(reConf.mrRank || 0) * 12}pt` }}
                  >
                    <div
                      className={`IIO-wrap ${
                        reConf?.displayType === 'CONTENT_LEFT'
                          ? 'd-none'
                          : 'd-flex flew-row align-items-center'
                      }`}
                      // style={{
                      //   minWidth: maxWidth /* , whiteSpace: 'nowrap' */,
                      // }}
                    >
                      {/* label */}
                      <div
                        className="IIO-item edit-label mr-1"
                        style={Utils.filterStyles(reConf.label)}
                      >
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : ''
                          }`}
                        >
                          {reConf.label.value}
                        </span>
                      </div>
                      {/* labelEn */}
                      {isShowLabelEn && (
                        <div
                          className="IIO-item edit-label-en"
                          style={Utils.filterStyles(reConf.labelEn)}
                        >
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                          >
                            {reConf.labelEn.value}
                          </span>
                        </div>
                      )}
                      {invoiceInfoOtherRegion?.alignType !== 'ALIGN_TYPE_3' && (
                        <div className="two-dot">
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                            style={Utils.filterStyles(reConf.label)}
                          >
                            :
                          </span>
                        </div>
                      )}
                    </div>
                    {invoiceInfoOtherRegion?.alignType === 'ALIGN_TYPE_3' && (
                      <div className="two-dot">
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : reConf.displayType === 'CONTENT_LEFT'
                                ? 'd-none'
                                : ''
                          }`}
                        >
                          :
                        </span>
                      </div>
                    )}
                    {/* value */}
                    <div
                      className="IIO-item edit-value ml-1"
                      style={{
                        ...Utils.filterStyles(reConf.value),
                        flexShrink: 100,
                      }}
                    >
                      {reConf.dataField.includes('TaxCode') &&
                      reConf?.mstSplitCells ? (
                        <div className="d-flex flex-row align-items-center tax-code-input mr-2">
                          {reConf.value.value.split('').map((num, index) => (
                            <div key={index} className="tax-code-number">
                              {num}
                            </div>
                          ))}
                        </div>
                      ) : (
                        reConf.value.value
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
          ),
        )}
    </div>
  )
}

/* TicketPriceContent */
const TicketPriceContent = (props) => {
  const { activeDataField } = useSelector((state) => state.templateInvoice)
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const ticketPriceRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'ticketPrice'),
    [RegionConfigs],
  )
  const regionConfigs = useMemo(
    () => ticketPriceRegion?.regionConfigs,
    [ticketPriceRegion],
  )

  return (
    <div
      className="w-100 ticketPriceContent"
      style={{ minHeight: '100%' }}
      id="ticketPriceContent"
    >
      {regionConfigs?.map((reConf, index) =>
        reConf.show ? (
          <>
            {reConf?.typeShow == 2 ? (
              <div
                className={`vertical-content text-${fieldAlignConvert(
                  reConf.fieldAlign || 'start',
                )} ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
                style={{ alignItems: 'normal' }}
              >
                <div
                  className="edit-label"
                  style={Utils.filterStyles(reConf.label)}
                >
                  {reConf.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(reConf.labelEn)}
                  >
                    {reConf.labelEn.value}
                  </div>
                )}
              </div>
            ) : reConf?.typeShow == 3 ? (
              <div className="d-flex flex-row" key={index}>
                <div
                  className={`horizontal-content justify-content-${
                    reConf.fieldAlign || 'start'
                  } same-width-child ${
                    reConf.dataField === activeDataField?.dataField
                      ? 'active-data-field-l '
                      : ''
                  }`}
                >
                  <div
                    className="TPC-wrap d-flex flew-row align-items-center"
                    // style={{ whiteSpace: 'nowrap' }}
                  >
                    {/* label */}
                    <div
                      className="TPC-item edit-label mr-1"
                      style={Utils.filterStyles(reConf.label)}
                    >
                      <span>{reConf.label.value}</span>
                    </div>
                    {/* labelEn */}
                    {isShowLabelEn && (
                      <div
                        className="TPC-item edit-label-en"
                        style={Utils.filterStyles(reConf.labelEn)}
                      >
                        <span>{reConf.labelEn.value}</span>
                      </div>
                    )}

                    <div className="two-dot">
                      <span style={Utils.filterStyles(reConf.label)}>:</span>
                    </div>
                  </div>
                  {/* value */}
                  <div
                    className="TPC-item edit-value ml-1"
                    style={{
                      ...Utils.filterStyles(reConf.value),
                      flexShrink: 100,
                    }}
                  >
                    {reConf.value.value}
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={`text-${fieldAlignConvert(
                  reConf.fieldAlign || 'start',
                )} ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
                style={Utils.filterStyles(reConf.value)}
              >
                {reConf.value.value}
              </div>
            )}
          </>
        ) : (
          <React.Fragment key={index}></React.Fragment>
        ),
      )}
    </div>
  )
}

/* QRCode */
const QRCode = (props) => {
  let { activeDataField } = useSelector((state) => state.templateInvoice)
  let { isShowQRCode } = useSelector((state) => state.templateInvoice.instance)
  return isShowQRCode ? (
    <div
      className={`${
        activeDataField?.dataField == 'QRCodeField' ? 'active-data-field-l' : ''
      }`}
      style={{
        padding: '10pt 0 10pt',
      }}
    >
      <div
        style={{
          margin: '0 auto 0',
          width: '100pt',
          height: '100pt',
          backgroundImage: `url(${AppResource.images.img_qr_code})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </div>
  ) : (
    <></>
  )
}

const buyerInfoData = [
  {
    col1: <BuyerInfoContent />,
    col2: <QRCode />,
  },
]

const genSignXmlItems = ({ reConfs, activeDataFieldName, isShowLabelEn }) => {
  if (!reConfs?.length) return <></>

  let fieldActive = reConfs.find(
    ({ dataField }) => dataField == activeDataFieldName,
  )
  let [dataField1, dataField2] = reConfs

  if (!dataField1.show) return <></>

  return (
    <div className={`${fieldActive ? 'active-data-field-l' : ''}`}>
      <div
        className="d-flex flex-row align-items-center mx-auto"
        style={{
          padding: '5pt',
          border: '1px solid black',
          marginTop: '3pt',
        }}
      >
        <div>
          <i
            className="fa-regular fa-3x fa-circle-check"
            style={{ color: 'black', marginRight: '5pt' }}
          ></i>
        </div>
        <div>
          <div style={{ fontWeight: 600 }}>Signature Valid</div>
          {dataField1.show && (
            <div
              className={`horizontal-content ${
                dataField1.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(dataField1.label)}
              >
                {dataField1.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(dataField1.labelEn)}
                >
                  {dataField1.labelEn.value}
                </div>
              )}
            </div>
          )}
          {dataField2.show && (
            <div
              className={`horizontal-content ${
                dataField2.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(dataField2.label)}
              >
                {dataField2.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(dataField2.labelEn)}
                >
                  {dataField2.labelEn.value}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function LeftContent(props) {
  const dispatch = useDispatch()
  const {
    className,
    style,
    firstInvoiceColumns,
    setFirstInvoiceColumns,
    buyerInfoColumns,
    setBuyerInfoColumns,
    signXmlColumns,
    setSignXmlColumns,
  } = props

  const {
    activePanel,
    activeRegion,
    activeDataField,
    mulTaxRate,
    showWatermark,
  } = useSelector((state) => state.templateInvoice)

  const {
    sellerInfoPosition,
    paperSize,
    logoPosition,
    backgroundInfo,
    customBackgroundInfo,
    surroundInfo,
    watermarkInfo,
    fontSize,
    color,
    lineHeight,
    fontFamily,
    lineType,
    isShowLabelEn,
    isShowQRCode,
    RegionConfigs,
  } = useSelector((state) => state.templateInvoice.instance)

  const currencyBlockRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'currencyBlock'),
    [RegionConfigs],
  )

  const currencyBlockRegionConfigs = useMemo(
    () => currencyBlockRegion?.regionConfigs,
    [currencyBlockRegion],
  )

  const exchangeRateField = currencyBlockRegion?.regionConfigs.find(
    ({ dataField }) => dataField === 'exchangeRate',
  )

  const signXmlRegion = useMemo(
    () => _.find(RegionConfigs, (region) => region.regionName === 'signXml'),
    [RegionConfigs],
  )

  const signRegionConfigsGroupByMergeField = useMemo(() => {
    let signXmlRegionConfigs = signXmlRegion?.regionConfigs
    return TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc(
      signXmlRegionConfigs,
    )
  }, [signXmlRegion])

  let firstInvoiceData = useMemo(() => {
    // let content = sellerInfoPosition == 1 ? <SellerInfo /> : <InvoiceTable />
    let content = <InvoiceInfoOther />
    let leftContent = logoPosition == 1 ? <LogoContainer /> : content
    let rightContent = logoPosition == 1 ? content : <LogoContainer />

    return [{ col1: leftContent, col2: rightContent }]
  }, [/* sellerInfoPosition, */ logoPosition])

  let firstInvoiceActive = useMemo(
    () =>
      (sellerInfoPosition == 1 &&
        activeRegion?.regionName === 'invoiceInfoOther') ||
      (sellerInfoPosition == 2 &&
        (activeRegion?.regionName === 'invoiceInfo' ||
          activeRegion?.regionName === 'invoiceInfoOther')) ||
      (sellerInfoPosition == 3 &&
        (activeRegion?.regionName === 'sellerInfo' ||
          activeRegion?.regionName === 'invoiceInfo' ||
          activeRegion?.regionName === 'invoiceInfoOther')),
    [activePanel, sellerInfoPosition, activeRegion],
  )

  let buyerInfoActive = useMemo(
    () =>
      activeRegion?.regionName === 'buyerInfo' ||
      (activePanel == 'gen_ctrl' && isShowQRCode),
    [activeRegion, activePanel, isShowQRCode],
  )

  let pageWidth = useMemo(
    () => TemplateInvoiceHelper.widthBaseOnPaperSize(paperSize),
    [paperSize],
  )
  let pageHeight = useMemo(
    () => TemplateInvoiceHelper.heightBaseOnPaperSize(paperSize),
    [paperSize],
  )

  return (
    <div className={className} style={{ ...style, overflow: 'hidden scroll' }}>
      <div
        className="lc-frame"
        id="lc-frame"
        style={{
          width: pageWidth,
          height: pageHeight,
        }}
      >
        {/* surround */}
        {surroundInfo?.surroundData &&
          (surroundInfo.surroundData?.filename?.endsWith('.svg') ? (
            <ReactSVG
              className="position-absolute lc-frame-surround"
              src={`${process.env.REACT_APP_BASE_URL}/resources/border/${surroundInfo.surroundData.filename}`}
              beforeInjection={(svg) => {
                let parent = document.querySelector('.lc-frame-surround')
                svg.setAttribute(
                  'style',
                  paperSize == 'A5'
                    ? `width: ${parent.offsetHeight}px;height: ${parent.offsetWidth}px;transform: rotate(-90deg);transform-origin: 280px 280px;`
                    : 'height: 100%;width: 100%;',
                )
                svg.setAttribute('fill', surroundInfo?.surroundColor)
              }}
            />
          ) : (
            <div
              className="position-absolute lc-frame-surround"
              style={
                paperSize != 'A5'
                  ? {
                      backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/border/${surroundInfo.surroundData.filename})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                    }
                  : {}
              }
            />
          ))}

        {/* background image */}
        {backgroundInfo &&
          backgroundInfo.backgroundData?.name?.startsWith('mau_') && (
            <div
              className="position-absolute lc-frame-surround"
              style={
                paperSize != 'A5'
                  ? {
                      backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo.backgroundData.filename})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      // backgroundPosition: '-5px -1px',
                      opacity: backgroundInfo.opacity / 100,
                    }
                  : {}
              }
            >
              {paperSize == 'A5' ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo.backgroundData.filename}`}
                  style={{
                    height: pageWidth,
                    width: pageHeight,
                    opacity: backgroundInfo.opacity / 100,
                    transform: `rotate(90deg) translate(0,-${pageWidth})`,
                    transformOrigin: '0 0',
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        {/* background svg */}
        {backgroundInfo &&
          !backgroundInfo.backgroundData?.name?.startsWith('mau_') &&
          backgroundInfo.backgroundData?.filename?.endsWith('.svg') && (
            <Rnd
              bounds=".lc-frame"
              lockAspectRatio={true}
              disableDragging={activePanel !== 'logo_ctrl'}
              enableResizing={
                activePanel !== 'logo_ctrl'
                  ? false
                  : {
                      top: false,
                      right: true,
                      bottom: true,
                      left: false,
                      topRight: false,
                      bottomRight: true,
                      bottomLeft: false,
                      topLeft: false,
                    }
              }
              style={{ zIndex: activePanel === 'logo_ctrl' ? 2 : 1 }}
              size={{
                width: backgroundInfo.width,
                height: backgroundInfo.height,
              }}
              position={{ x: backgroundInfo.x, y: backgroundInfo.y }}
              onDragStop={(e, d) => {
                dispatch(setBackgroundInfo({ x: d.x, y: d.y }))
              }}
              onResizeStop={(e, direction, ref, delta, position) => {
                dispatch(
                  setBackgroundInfo({
                    x: position.x,
                    y: position.y,
                    width: parseFloat(
                      ref.style.width.slice(ref.style.width - 1),
                    ),
                    height: parseFloat(
                      ref.style.height.slice(ref.style.height - 1),
                    ),
                  }),
                )
              }}
            >
              <div
                className={`lc-frame-background w-100 h-100 ${
                  activePanel === 'logo_ctrl' ? 'border border-primary' : ''
                }`}
              >
                <ReactSVG
                  className="w-100 h100"
                  title="File hình nền"
                  src={`${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo?.backgroundData.filename}`}
                  beforeInjection={(svg) => {
                    svg.setAttribute(
                      'style',
                      `opacity: ${backgroundInfo?.opacity / 100}`,
                    )
                    svg.setAttribute('fill', backgroundInfo?.backgroundColor)
                  }}
                />
                {activePanel === 'logo_ctrl' && (
                  <div className="icon-gripsmall"></div>
                )}
              </div>
            </Rnd>
          )}

        {/* background !svg */}
        {backgroundInfo &&
          !backgroundInfo.backgroundData?.name?.startsWith('mau_') &&
          !backgroundInfo.backgroundData?.filename?.endsWith('.svg') && (
            <Rnd
              bounds=".lc-frame"
              lockAspectRatio={true}
              disableDragging={activePanel !== 'logo_ctrl'}
              enableResizing={
                activePanel !== 'logo_ctrl'
                  ? false
                  : {
                      top: false,
                      right: true,
                      bottom: true,
                      left: false,
                      topRight: false,
                      bottomRight: true,
                      bottomLeft: false,
                      topLeft: false,
                    }
              }
              style={{ zIndex: activePanel === 'logo_ctrl' ? 2 : 1 }}
              size={{
                width: backgroundInfo.width,
                height: backgroundInfo.height,
              }}
              position={{ x: backgroundInfo.x, y: backgroundInfo.y }}
              onDragStop={(e, d) => {
                dispatch(setBackgroundInfo({ x: d.x, y: d.y }))
              }}
              onResizeStop={(e, direction, ref, delta, position) => {
                dispatch(
                  setBackgroundInfo({
                    x: position.x,
                    y: position.y,
                    width: parseFloat(
                      ref.style.width.slice(ref.style.width - 1),
                    ),
                    height: parseFloat(
                      ref.style.height.slice(ref.style.height - 1),
                    ),
                  }),
                )
              }}
            >
              <div
                className={`lc-frame-background w-100 h-100 ${
                  activePanel === 'logo_ctrl' ? 'border border-primary' : ''
                }`}
              >
                {/* <ReactSVG
                  className="w-100 h100"
                  title="File hình nền"
                  src={`${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo?.backgroundData.filename}`}
                  beforeInjection={svg => {
                    svg.setAttribute(
                      'style',
                      `opacity: ${backgroundInfo?.opacity / 100}`,
                    )
                    svg.setAttribute('fill', backgroundInfo?.backgroundColor)
                  }}
                /> */}
                <img
                  className="w-100 h-100"
                  src={`${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo.backgroundData.filename}`}
                  style={{ opacity: backgroundInfo?.opacity / 100 }}
                />
                {activePanel === 'logo_ctrl' && (
                  <div className="icon-gripsmall"></div>
                )}
              </div>
            </Rnd>
          )}

        {/* customBackground */}
        {customBackgroundInfo?.file && (
          <Rnd
            bounds=".lc-frame"
            lockAspectRatio={true}
            disableDragging={activePanel !== 'logo_ctrl'}
            enableResizing={
              activePanel !== 'logo_ctrl'
                ? false
                : {
                    top: false,
                    right: true,
                    bottom: true,
                    left: false,
                    topRight: false,
                    bottomRight: true,
                    bottomLeft: false,
                    topLeft: false,
                  }
            }
            style={{ zIndex: activePanel === 'logo_ctrl' ? 2 : 1 }}
            size={{
              width: customBackgroundInfo.width,
              height: customBackgroundInfo.height,
            }}
            position={{ x: customBackgroundInfo.x, y: customBackgroundInfo.y }}
            onDragStop={(e, d) => {
              dispatch(setCustomBackgroundInfo({ x: d.x, y: d.y }))
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              dispatch(
                setCustomBackgroundInfo({
                  x: position.x,
                  y: position.y,
                  width: parseFloat(ref.style.width.slice(ref.style.width - 1)),
                  height: parseFloat(
                    ref.style.height.slice(ref.style.height - 1),
                  ),
                }),
              )
            }}
          >
            <div
              className={`lc-frame-background w-100 h-100 ${
                activePanel === 'logo_ctrl' ? 'border border-primary' : ''
              }`}
            >
              <img
                className="w-100 h-100"
                src={
                  customBackgroundInfo?.file instanceof File
                    ? URL.createObjectURL(customBackgroundInfo.file)
                    : `${process.env.REACT_APP_BASE_URL}/resources/customBackground/${customBackgroundInfo?.file}`
                }
                alt="..."
                draggable={false}
                title="File hình nền tùy chỉnh"
                style={{ opacity: (customBackgroundInfo?.opacity || 0) / 100 }}
              />
              {activePanel === 'logo_ctrl' && (
                <div className="icon-gripsmall"></div>
              )}
            </div>
          </Rnd>
        )}

        {/* watermark */}
        {showWatermark && (
          <div
            className="lc-frame-watermark"
            style={{
              backgroundImage: `url(${AppResource.images.img_watermark})`,
              opacity: (watermarkInfo?.opacity || 0) / 100,
            }}
          ></div>
        )}

        {/* content */}
        <div
          className="lc-frame-content"
          style={{
            fontSize,
            lineHeight,
            fontFamily,
            color,

            // whiteSpace: 'nowrap',
            width: `calc(100% - 5pt * 2)`,
            marginLeft: '5pt',
            marginRight: '5pt',
            paddingTop: '5pt',
            paddingBottom: '5pt',
          }}
        >
          <div
            className="lc-frame-content-detail"
            id="lc-frame-content-detail"
            style={{ minHeight: paperSize == 'A4' ? '975px' : '460px' }}
          >
            {/* invoice-first */}
            <div className="invoice-first">
              <Table
                className={`custom-resizable-table firstInvoice ${
                  firstInvoiceActive ? 'region-active' : ''
                }`}
                showHeader={false}
                tableLayout="fixed"
                emptyText={<></>}
                components={{
                  body: {
                    cell: ResizableCell,
                  },
                }}
                columns={firstInvoiceColumns.map((col, indexCol) => ({
                  ...col,
                  onCell: (_) => ({
                    id: col.id,
                    width: col.width,
                    resizeEnable: firstInvoiceActive,
                    onResize: handleResize(indexCol, setFirstInvoiceColumns),
                  }),
                }))}
                data={firstInvoiceData}
              />
            </div>

            {/* seller-info */}
            <SellerInfo />

            {/* invoice-second */}
            {/* <div
              className="invoice-second border-bottom"
            >
              {sellerInfoPosition == 1 ? (
                <InvoiceTable />
              ) : sellerInfoPosition == 2 ? (
                <SellerInfo />
              ) : (
                <></>
              )}
            </div> */}

            {/* invoice-info */}
            <div className="invoice-info">
              <InvoiceInfo />
            </div>

            {/* buyerInfo */}
            <div
              className={`border-bottom ${
                activeRegion?.regionName == 'buyerInfo' ? 'region-active' : ''
              }`}
              onClick={() => {
                if (activePanel === 'detail_ctrl') {
                  dispatch(setActiveRegionBaseOnName('buyerInfo'))
                }
              }}
              style={{ marginTop: '2pt' }}
            >
              {/* <Table
                className={`custom-resizable-table buyerInfo ${
                  buyerInfoActive ? 'region-active' : ''
                }`}
                showHeader={false}
                tableLayout="fixed"
                emptyText={<></>}
                components={{
                  body: {
                    cell: ResizableCell,
                  },
                }}
                columns={buyerInfoColumns.map((col, indexCol) => ({
                  ...col,
                  onCell: (_, indexRow) => {
                    let props = {
                      width: col.width,
                      resizeEnable: buyerInfoActive,
                      onResize: handleResize(indexCol, setBuyerInfoColumns),
                      maxWidth:
                        document.getElementById('lc-frame-content-detail')
                          ?.offsetWidth - (paperSize == 'A5' ? 72 : 82),
                    }

                    return props
                  },
                }))}
                data={buyerInfoData}
              /> */}
              <BuyerInfoContent />
            </div>

            {/* ticketPrice */}
            <div
              className={`border-bottom ${
                activeRegion?.regionName == 'ticketPrice' ? 'region-active' : ''
              }`}
              onClick={() => {
                if (activePanel === 'detail_ctrl') {
                  dispatch(setActiveRegionBaseOnName('ticketPrice'))
                }
              }}
              style={{ marginTop: '2pt' }}
            >
              <TicketPriceContent />
            </div>

            {/* currencyBlock */}
            {/* {exchangeRateField?.show ? (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('currencyBlock'))
                  }
                }}
                className={`${
                  activeRegion?.regionName === 'currencyBlock'
                    ? 'region-active'
                    : ''
                }`}
              >
                {currencyBlockRegionConfigs?.map((reConf, index) =>
                  reConf?.show ? (
                    <div
                      key={index}
                      className={`horizontal-content ${
                        reConf.dataField === activeDataField?.dataField
                          ? 'active-data-field-l '
                          : ''
                      }`}
                    >
                      <div
                        className="edit-label mr-1"
                        style={Utils.filterStyles(reConf.label)}
                      >
                        {reConf.label.value}
                      </div>
                      {isShowLabelEn && (
                        <div
                          className="edit-label-en"
                          style={Utils.filterStyles(reConf.labelEn)}
                        >
                          {reConf.labelEn.value}
                        </div>
                      )}
                      <div style={Utils.filterStyles(reConf.label)}>:</div>
                      {reConf.value.value && (
                        <div
                          className="edit-value ml-1"
                          style={Utils.filterStyles(reConf.value)}
                        >
                          {reConf.value.value}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  ),
                )}
              </div>
            ) : (
              <></>
            )} */}

            {/* signXml */}
            <div
              className={`border-bottom ${
                activeRegion?.regionName == 'signXml' ? 'region-active' : ''
              }`}
              onClick={() => {
                if (activePanel === 'detail_ctrl') {
                  dispatch(setActiveRegionBaseOnName('signXml'))
                }
              }}
            >
              {signRegionConfigsGroupByMergeField?.map(
                ([mergeField, reConfs]) =>
                  mergeField && mergeField != 'undefined'
                    ? genSignXmlItems({
                        reConfs,
                        activeDataFieldName: activeDataField?.dataField,
                        isShowLabelEn,
                      })
                    : reConfs.map((reConf, index) =>
                        reConf.dataField == 'QRCodeField' ? (
                          <QRCode />
                        ) : reConf.show ? (
                          reConf?.typeShow == 1 ? (
                            <div
                              key={index}
                              className={`text-center ${
                                reConf.dataField === activeDataField?.dataField
                                  ? 'active-data-field-l '
                                  : ''
                              }`}
                              style={Utils.filterStyles(reConf.value)}
                            >
                              {reConf.value.value}
                            </div>
                          ) : (
                            <div className="d-flex flex-row" key={index}>
                              <div
                                className={`horizontal-content justify-content-${
                                  reConf.fieldAlign || 'start'
                                } same-width-child ${
                                  reConf.dataField ===
                                  activeDataField?.dataField
                                    ? 'active-data-field-l '
                                    : ''
                                }`}
                              >
                                <div
                                  className="TPC-wrap d-flex flew-row align-items-center"
                                  // style={{ whiteSpace: 'nowrap' }}
                                >
                                  <div
                                    className="TPC-item edit-label mr-1"
                                    style={Utils.filterStyles(reConf.label)}
                                  >
                                    <span>{reConf.label.value}</span>
                                  </div>
                                  {isShowLabelEn && (
                                    <div
                                      className="TPC-item edit-label-en"
                                      style={Utils.filterStyles(reConf.labelEn)}
                                    >
                                      <span>{reConf.labelEn.value}</span>
                                    </div>
                                  )}

                                  <div className="two-dot">
                                    <span
                                      style={Utils.filterStyles(reConf.label)}
                                    >
                                      :
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="TPC-item edit-value ml-1"
                                  style={{
                                    ...Utils.filterStyles(reConf.value),
                                    flexShrink: 100,
                                  }}
                                >
                                  {reConf.value.value}
                                </div>
                              </div>
                            </div>
                          )
                        ) : (
                          <></>
                        ),
                      ),
              )}
            </div>
          </div>

          <div className="lc-frame-search-block" style={{ fontSize: '0.73em' }}>
            {/* <div className="info-website text-center">
              <span>
                <span>Tra cứu tại Website</span>
                {isShowLabelEn && (
                  <span className="pl-1">(Search in Website)</span>
                )}
                <span>:</span>
              </span>

              <span className="pl-1">https://tracuu.vietinvoice.vn</span>

              <span className="pl-1">
                <span>- Mã tra cứu</span>
                {isShowLabelEn && <span className="pl-1">(Invoice code)</span>}
                <span>:</span>
              </span>
            </div> */}

            {/* <div className="note text-center">
              <span>Phát hành bởi MISA meInvoice -</span>
              <span>Công ty CP MISA - MST</span>
              <span>0101243150</span>
            </div> */}

            <div className="text-center">
              Phát hành bởi VietInvoice.vn - Công ty cổ phần ICORP - MST
              0106870211
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftContent
