import { isBoolean } from 'lodash'
import { memo } from 'react'
import LoadingCustom from '../LoadingCustom'
import UnauthorizedScreen from '../UnauthorizedScreen'
import UserHelper from 'general/helpers/UserHelper'
import { Navigate } from 'react-router-dom'
import ToastHelper from 'general/helpers/ToastHelper'

const ProtectedRoute = ({ children, permission, noPermission, isLoading }) => {
  const isAuth = UserHelper.checkAccessTokenValid()

  if (!isAuth)
    return (
      <>
        <Navigate to="/auth/dang-nhap" />
        {ToastHelper.showError(
          'Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!',
        )}
      </>
    )
  if (!isBoolean(permission)) return <></>
  return isLoading ? (
    <LoadingCustom size="large" variant="primary" />
  ) : permission ? (
    children
  ) : (
    (noPermission ?? <UnauthorizedScreen />)
  )
}

export default memo(ProtectedRoute)
