import { useTranslation } from 'react-i18next'
import { Result, Button, Empty, Form } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalCustom,
  StyleButtonClose,
} from 'features/TaxDeduction/styled'
import { ContainerContentModal } from 'features/Department/styled'
import {
  FormCustom,
  InputCustom,
  RadioCustom,
  RadioCustomT,
  RedStar,
  SelectCustom,
} from 'features/TaxDeductionDocuments/styled'
import { toLower } from 'general/utils/Utils'
import SelectOptions from 'general/constants/SelectOptions'
import { useEffect, useState } from 'react'
function PopupCancelDocument({
  visible,
  setVisible,
  submit,
  form,
  disableSave,
  setDisableSave,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    setDisableSave(false)
    form.resetFields()
    form.setFieldsValue({
      cancellationReport: 0,
      settlementStatus: 0,
    })
  }, [visible])

  const save = () => {
    form
      .validateFields()
      .then((data) => {
        setDisableSave(true)
        submit(data)
      })
      .catch((errorInfo) => {})
  }

  return (
    <>
      <ModalCustom
        title={t('accountingDocuments.titleCancel')}
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        width={700}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    // changeOpenModal(false)
                  }}
                >
                  {t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  onClick={() => {
                    save()
                  }}
                  disabled={disableSave}
                >
                  {t('taxDeductionDocuments.dy')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <ContainerContentModal padding="20px 20px 20px 20px">
          <FormCustom form={form}>
            <div>
              <FormCustom.Item
                label={
                  <>
                    {`${t('accountingDocuments.reson')} `}
                    <RedStar>*</RedStar>
                  </>
                }
                name={'reson'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            t('taxpayer.requiredKey', {
                              key: t('accountingDocuments.reson'),
                            }),
                          ),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <InputCustom
                  placeholder={t('department.keyPlacehoder', {
                    key: toLower(t('accountingDocuments.reson')),
                  })}
                  maxLength={500}
                  allowClear
                />
              </FormCustom.Item>
            </div>
            <div>
              <FormCustom.Item
                label={t('accountingDocuments.cancellationReport')}
                name={'cancellationReport'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <SelectCustom
                  options={SelectOptions.cancellationReport}
                ></SelectCustom>
              </FormCustom.Item>
            </div>
            <div>
              <FormCustom.Item
                label={t('accountingDocuments.nnt')}
                name={'settlementStatus'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <RadioCustom.Group>
                  <RadioCustom value={0}>
                    {t('accountingDocuments.cqt')}
                  </RadioCustom>
                  <RadioCustom value={1}>
                    {t('accountingDocuments.dqt')}
                  </RadioCustom>
                </RadioCustom.Group>
              </FormCustom.Item>
            </div>
          </FormCustom>
        </ContainerContentModal>
      </ModalCustom>
    </>
  )
}
export default PopupCancelDocument
