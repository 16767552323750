import axiosClient from './axiosClient'

const userApi = {
  // lay toan bo
  getAll: (params) => {
    const url = '/users'
    return axiosClient.get(url, params)
  },

  getUserById: (params) => {
    const url = '/users/getUserById'
    return axiosClient.get(url, params)
  },

  create: (query) => {
    const url = '/users/create'
    return axiosClient.post(url, query)
  },

  update: (query) => {
    const url = '/users/update'
    return axiosClient.put(url, query)
  },

  delete: (query) => {
    console.log(query)
    const url = '/users/delete'
    return axiosClient.delete(url, { data: { accountId: query } })
  },
}

export default userApi
