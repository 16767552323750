import { queryOptions } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import _ from 'lodash'

export const queries = {
  base: {
    scope: ['handleInvoice', 'listAdjustedInvoice'],
    entity: 'listAdjustedInvoice',
  },
  lists: (params) =>
    queryOptions({
      staleTime: 1000 * 30,
      queryKey: [queries.base, params],
      queryFn: () =>
        invoiceApi.listAll({
          ...params,
          invoiceType: 'adjusted',
        }),
      select: (res) => ({
        total: _.get(res, 'count', 0),
        rows: !res?.rows
          ? []
          : _.flatMap(res?.rows, (row, idx) =>
              row.Related && row.Related.length > 0
                ? row.Related.map((item) => ({
                    ...row,
                    Relate: item,
                    id: idx + 1,
                  }))
                : [
                    {
                      ...row,
                      Relate: [],
                      id: idx + 1,
                    },
                  ],
            ),
      }),
    }),
}
