import { useQuery } from '@tanstack/react-query'
import { Typography } from 'antd'
import invoiceApi from 'api/invoiceApi'
import ticketApi from 'api/ticketApi'
import dayjs from 'dayjs'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import {
  INVOICE_STATUS,
  RESOURCE_TYPE,
  TICKET_GRANTED_STATUS,
} from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useExportById from 'hooks/Mutations/useExportById'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { queries } from './queries'
import './style.scss'

function ListTaxMessageInvoice() {
  // MARK --- Parmas: ---
  const systemType = useAppSelector((s) => s.auth.activeInvoice)
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const { t } = useTranslation()
  const [tranmissionModalShow, setTranmissionModalShow] = useState(false)
  const [activeInvoice, setActiveInvoice] = useState(null)
  // const [copy, setCopy] = useCopyToClipboard()
  // const hasCopied = Boolean(copy)
  const [filters, setFilters] = useFilter(
    'taxMessageInvoiceList',
    'gFilterListTaxMessageInvoice',
  )
  const { data: listTaxInvoiceData, isFetching: gettingTaxInvoice } = useQuery(
    queries.lists(
      filters,
      systemType?.invoice
        ? RESOURCE_TYPE.INVOICE
        : systemType?.ticket
          ? RESOURCE_TYPE.TICKET
          : RESOURCE_TYPE.INVOICE,
    ),
  )
  const { exportFunc } = useExportById(
    (id) =>
      systemType?.invoice
        ? invoiceApi.exportInvoiceById(id)
        : systemType?.ticket
          ? ticketApi.exportById(id)
          : invoiceApi.exportInvoiceById(id),
    'Hoá đơn điện tử.pdf',
    'application/pdf',
  )

  const renderIssueStatus = (issueStatus) => {
    switch (issueStatus) {
      case INVOICE_STATUS.AUTHORITY_CODE_GIVEN:
        return <div className="badge badge-light-primary">Đã cấp mã</div>
      case INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN:
        return <div className="badge badge-light-danger">Từ chối cấp mã</div>
      case INVOICE_STATUS.NOT_SENT_TO_AUTHORITY:
        return <div className="badge badge-light-success">Chưa phát hành</div>
      case INVOICE_STATUS.WAITING_FOR_AUTHORITY:
        return <div className="badge badge-light-success">Chờ CQT xử lý</div>
      case INVOICE_STATUS.AUTHORITY_ACCEPT:
        return (
          <div className="badge badge-light-primary">CQT xác nhận hợp lệ</div>
        )
      case INVOICE_STATUS.AUTHORITY_DENY:
        return <div className="badge badge-light-danger">CQT từ chối</div>
      default:
      // return <div className="label label-lg label-outline-primary label-inline">Hoá đơn gốc</div>;
    }
  }

  const renderTypeRelease = (type) => {
    const $div = (className, text) => (
      <div className={Utils.cn('badge ', className)}>{text}</div>
    )
    switch (type) {
      case TICKET_GRANTED_STATUS.WAITING:
        return $div('badge-light-success', 'Chờ cấp mã')

      case TICKET_GRANTED_STATUS.GRANTED:
        return $div('badge-light-primary', 'Đã cấp mã')

      case TICKET_GRANTED_STATUS.DENIED:
        return $div('badge-light-danger', 'Từ chối cấp mã')

      default:
        return $div('badge-light-success', 'Chờ cấp mã')
    }
  }

  const columns = useMemo(() => {
    const arr = [
      {
        name: 'Tên thông điệp',
        center: true,
        cell: () => (
          <Typography.Text
            ellipsis={{
              tooltip:
                'Thông điệp gửi hóa đơn điện tử tới cơ quan thuế để cấp mã',
            }}
          >
            Thông điệp gửi hóa đơn điện tử tới cơ quan thuế để cấp mã
          </Typography.Text>
        ),
      },
      {
        name: 'Ngày hoá đơn',
        width: '120px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.dateRelease ? dayjs(row?.dateRelease).format('L') : ''}
            </p>
          )
        },
      },
      {
        name: 'Ký hiệu',
        width: '110px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        name: 'Số hoá đơn',
        center: true,
        width: '120px',
        cell: (row) => {
          const no = row?.no && Utils.padString(row?.no, 8, '0')
          return <Typography.Text className="m-0 mr-4">{no}</Typography.Text>
        },
      },
      {
        name: 'Mã cơ quan thuế',
        center: true,
        minWidth: '250px',
        ignoreRowClick: true,
        cell: (row) => {
          if (!row?.taxAuthorityCode) return ''
          return (
            <Typography.Text className="m-0">
              {row?.taxAuthorityCode}
            </Typography.Text>
          )
        },
      },
      {
        name: 'Trạng thái',
        center: true,
        width: '150px',
        cell: (row) => {
          return systemType?.invoice
            ? renderIssueStatus(row?.issueStatus)
            : renderTypeRelease(row?.typeRelease)
        },
      },
      {
        name: '',
        grow: 0,
        center: true,
        compact: true,
        cell: (row) => {
          return (
            <>
              <TableAction
                icon={<IconAction className="fa-light fa-eye" />}
                titleActionText="Xem thông điệp"
                onClick={() => {
                  setActiveInvoice(row)
                  setTranmissionModalShow(true)
                }}
              />
              <TableAction
                titleActionText="Xem hoá đơn"
                icon={<IconAction className="fa-light fa-receipt" />}
                onClick={() =>
                  systemType?.invoice
                    ? exportFunc(row?.invoiceId)
                    : systemType?.ticket
                      ? exportFunc(row?.ticketId)
                      : exportFunc(row?.invoiceId)
                }
              />
            </>
          )
        },
      },
    ]
    return arr
  }, [exportFunc, systemType?.invoice, systemType?.ticket])

  /** @type {FilterDefaultValueProp[]} */
  const defaultFilter = [
    systemType?.invoice
      ? {
          alias: 'Trạng thái phát hành',
          label: 'Trạng thái phát hành',
          formName: 'issueStatus',
          options: SelectOptions.taxMessageInvoiceStatus,
          value: null,
          colSpan: 'FULL',
        }
      : systemType?.ticket
        ? {
            alias: 'Trạng thái cấp mã',
            label: 'Trạng thái',
            formName: 'typeRelease',
            options: SelectOptions.ticketGrantedStatus,
            value: null,
            colSpan: 'FULL',
          }
        : {},
  ]

  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilters])

  return (
    <ContentContainer wrapperClassName="list-replaced-invoice">
      <ContentContainer.Header
        titleContent={t('Lịch sử truyền nhận hoá đơn')}
        description={`Tổng: ${Utils.formatNumber(
          listTaxInvoiceData?.total,
        )} bản tin`}
        toolBar={
          <CustomAntInput
            type="search"
            borderRadius="sm"
            inputProps={{
              value: search,
              placeholder: 'Tìm kiếm theo ký hiệu, số hoá đơn, mã CQT...',
              onChange: (e) => {
                setSearch(e.target.value)
              },
            }}
            width={300}
          />
        }
      >
        <FilterPopover
          menuPosition="bottomRight"
          defaultValues={defaultFilter}
          onApply={setFilters}
          savedKey="gFilterListTaxMessageInvoice"
        />
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={columns}
          dataSource={listTaxInvoiceData?.rows}
          isLoading={gettingTaxInvoice}
          isClickable
          handleDoubleClickRow={(row) => {
            setActiveInvoice(row)
            setTranmissionModalShow(true)
          }}
          pagination={{
            total: listTaxInvoiceData?.total,
            rowsPerPage: filters?.limit,
            currentPage:
              filters?.page === 0 ? filters?.page + 1 : filters?.page,
            onChangePage: (page) => setFilters({ page }),
            onChangeRowsPerPage: (limit) => setFilters({ limit, page: 1 }),
          }}
        />
      </ContentContainer.Body>
      <ModalDataTransmissionLog
        show={tranmissionModalShow}
        onHide={() => setTranmissionModalShow(false)}
        invoice={activeInvoice}
      />
    </ContentContainer>
  )
}

export default ListTaxMessageInvoice
