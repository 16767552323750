import { CaretDownOutlined } from '@ant-design/icons'
import { Checkbox, Flex } from 'antd'
import { hasPinnedActionCol } from 'assets/styles/DatatableStyles/styles'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import { useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import Empty from '../Empty'
import Loading from '../Loading'
import Pagination from '../Pagination'

/**
 * @template T
 * @param {Object} prop
 * @param {import('react').HTMLAttributes['className']} [prop.wrapperClassName] - Column of the table
 * @param {import('react-data-table-component').TableColumn<T>[]} prop.columns - Column of the table
 * @param {Array} prop.dataSource - Data of the table
 * @param {boolean} [prop.isLoading] - Loading state of the table
 * @param {boolean} [prop.isClickable] - Whether the row is clickable
 * @param {boolean} [prop.isSelectable] - Whether the row has checkbox
 * @param {{total: number,
 *  rowsPerPage: number,
 *  currentPage: number,
 *  onChangePage: (page: number) => void,
 *  onChangeRowsPerPage: (rowsPerPage: number) => void
 * }} prop.pagination - Pagination setting for the table
 * @param {{
 *  selectableRowSelected: (row: any) => boolean,
 *  clearSelectedRows: boolean,
 *  handleSelectedRows:  (selected: {
 * allSelected: boolean;
 * selectedCount: number;
 * selectedRows: any[];
 * }) => void
 * }} [prop.selectedRows] - An object contains selected rows properties when isSelectable is true
 * @param {import('react-data-table-component').TableProps<T>['onRowClicked']} [prop.handleClickRow] - Handle click row event
 * @param {import('react-data-table-component').TableProps<T>['onRowDoubleClicked']} [prop.handleDoubleClickRow] - Handle double click row event
 * @param {string} prop.scrollY - Fixed header height
 * @param {import('react-data-table-component').TableProps<T>['customStyles']} [prop.customTableStyle] - Custom style for the table
 * @param {boolean} [prop.noShowEmpty] - Whether to show empty component, default is false
 * @param {boolean} [prop.persistTableHead] - Whether to persist table head, default is false
 * @param {import('react-data-table-component').ConditionalStyles<T>[]} [prop.conditionalRowStyles] - Conditional row styles
 * @param {boolean} [prop.showStripe] - Stripe style
 * @param {import('react-data-table-component').TableProps<T>['onSort']} [prop.customSortFunction]
 */
function CustomDataTable(prop) {
  const {
    wrapperClassName,
    columns,
    dataSource,
    isLoading,
    pagination,
    isClickable,
    isSelectable,
    selectedRows,
    handleClickRow,
    handleDoubleClickRow,
    scrollY,
    customTableStyle,
    noShowEmpty = false,
    persistTableHead = false,
    conditionalRowStyles,
    showStripe = true,
    customSortFunction,
  } = prop
  const { t } = useTranslation()
  const [selectedState] = useState(selectedRows)
  const clearSelectedRows = useMemo(
    () => selectedRows?.clearSelectedRows,
    [selectedRows?.clearSelectedRows],
  )

  return (
    <>
      <DataTable
        className={
          scrollY ? '' : Utils.cn('max-h-100 flex-grow-1', wrapperClassName)
        }
        columns={columns}
        data={dataSource}
        fixedHeader
        fixedHeaderScrollHeight={scrollY ?? 'calc(100vh - 300px)'}
        striped={showStripe}
        responsive
        persistTableHead={persistTableHead}
        customStyles={customTableStyle ?? hasPinnedActionCol}
        onSort={customSortFunction}
        sortServer={!!customSortFunction}
        noDataComponent={
          noShowEmpty ? null : (
            <div className="pt-12">
              <Empty
                text={t('NoData')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
                imageEmptyPercentWidth={170}
              />
            </div>
          )
        }
        progressPending={isLoading}
        progressComponent={
          <Loading showBackground={false} message={`${t('Loading')}...`} />
        }
        sortIcon={
          <CaretDownOutlined />
          // <i className="fa-light fa-arrow-down mx-2" />
        }
        {...(isSelectable && {
          selectableRows: true,
          selectableRowsVisibleOnly: false,
          selectableRowsHighlight: true,
          selectableRowsComponent: Checkbox,
          selectableRowsComponentProps: {
            indeterminate: (state) => state,
          },
          selectableRowSelected: selectedState.selectableRowSelected,
          clearSelectedRows,
          onSelectedRowsChange: selectedState.handleSelectedRows,
        })}
        {...(isClickable && {
          onRowClicked: handleClickRow ? handleClickRow : () => {},
          onRowDoubleClicked: handleDoubleClickRow
            ? handleDoubleClickRow
            : () => {},
          pointerOnHover: true,
        })}
        highlightOnHover
        conditionalRowStyles={conditionalRowStyles}
      />
      {pagination && pagination?.total > 0 && (
        <Flex align="center" justify="center" className="mt-5">
          <Pagination
            rowsPerPage={pagination.rowsPerPage}
            rowCount={pagination.total}
            currentPage={pagination.currentPage}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        </Flex>
      )}
    </>
  )
}

export default CustomDataTable
