import invoiceApi from 'api/invoiceApi'
import Utils from 'general/utils/Utils'
import moment from 'moment'

const DownloadInvoicesHelper = async ({ invoiceIds }) => {
  const today = new Date()
  try {
    const response =
      invoiceIds &&
      (await invoiceApi.download({
        invoiceIds: invoiceIds,
      }))
    const filename = `Hoa_don_dien_tu_${Utils.formatDate(
      today,
      'DD-MM-YYYY',
    )}.zip`
    Utils.handleDownloadZipFile(response, filename)
  } catch (err) {
    console.log(err)
  }
}

export default DownloadInvoicesHelper
