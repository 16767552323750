import styled from 'styled-components'

export const Footer = styled.footer({
  position: 'sticky',
  left: 0,
  bottom: 0,
  width: '100%',
  padding: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 10px 20px 5px',
  zIndex: 5,
})
