import React, { useEffect, useState } from 'react'
import { SpanFieldValue } from 'features/TaxDeductionDocuments/styled'
import dayjs from 'dayjs'
import Utils, {
  convertDate,
  numberToIsoDate,
  removeTextBetweenBraces,
} from 'general/utils/Utils'
import TypeInputAuto from './TypeInput'
import imgCheckbox from '../../../../assets/images/taxDeductionDocuments/svg-image-6.svg'
import { useTranslation } from 'react-i18next'
const SpanFieldValueComponent = ({
  styleInput,
  value,
  style,
  idx,
  funClick,
  activeFeild,
  indexLayout,
  activeLayout,
  type,
  itemFeild,
  indexTd,
  activeTd,
  language,
  formState,
  setVisibleSelectTaxpaper,
  setVisibleAddTaxpaper,
  form,
  listIndentify,
  setListIndentify,
  itemLayout,
  templateDefault,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {itemFeild?.inforSign != true && itemFeild?.inforSign != false ? (
        (itemFeild?.[language]?.transformationTemplate != null &&
          itemFeild?.[language]?.transformationTemplate === true) ||
        (itemFeild?.[language]?.transformationTemplate == null && (
          <SpanFieldValue
            active={
              idx == activeFeild?.index &&
              activeLayout.index == indexLayout &&
              type === activeFeild?.type &&
              indexTd == activeTd.index
            }
            onClick={funClick}
            textAlign={style?.textAlign}
            fontSize={style?.fontSize && `${style.fontSize}px`}
            color={style?.color}
            fontWeight={style?.fontWeight}
            textDecoration={style?.textDecoration && 'underline'}
            textTransform={style?.textTransform}
            lineHeight={style?.lineHeight && `${style.lineHeight}px`}
            padding={style?.padding}
            margin={style?.margin}
            width={style?.width && `${style.width}px`}
            height={style?.height && `${style.height}px`}
            fontFamily={style?.fontFamily}
          >
            {itemFeild.index && type === 'label' && !itemFeild.hideIndex && (
              <SpanFieldValue
                textAlign={style?.textAlign}
                fontSize={style?.fontSize && `${style.fontSize}px`}
                color={style?.color}
                textDecoration={style?.textDecoration && 'underline'}
                textTransform={style?.textTransform}
                lineHeight={style?.lineHeight && `${style.lineHeight}px`}
                padding={
                  itemFeild?.indexStyle?.padding && styleInput
                    ? '0px 0px 0px 30px'
                    : itemFeild?.indexStyle?.padding
                }
                margin={style?.margin}
                width={style?.width && `${style.width}px`}
                height={style?.height && `${style.height}px`}
                fontFamily={style?.fontFamily}
                fontWeight="600"
              >
                {itemFeild.index}
              </SpanFieldValue>
            )}{' '}
            {styleInput && type === 'value' && itemFeild.typeInput ? (
              <TypeInputAuto
                form={form}
                itemFeild={itemFeild}
                language={language}
                setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                listIndentify={listIndentify}
                setListIndentify={setListIndentify}
              />
            ) : (
              <>
                {(() => {
                  switch (itemFeild?.typeValue) {
                    case 1:
                      return (
                        <>
                          {value && type === 'value'
                            ? removeTextBetweenBraces(value)
                            : value}
                        </>
                      )
                    case 2:
                      return (
                        <>
                          {value && type === 'value'
                            ? removeTextBetweenBraces(value)
                            : value}
                        </>
                      )
                    case 3:
                      return (
                        <>
                          {value && type === 'value'
                            ? removeTextBetweenBraces(value)
                            : value}
                        </>
                      )
                    case 4:
                      return (
                        <>
                          {type === 'value' && value
                            ? removeTextBetweenBraces(value)
                              ? convertDate(
                                  numberToIsoDate(
                                    removeTextBetweenBraces(value),
                                  ),
                                  'DD/MM/YYYY',
                                )
                              : removeTextBetweenBraces(value)
                            : value}
                        </>
                      )
                    default:
                      return (
                        <>
                          {value && type === 'value'
                            ? removeTextBetweenBraces(value)
                            : value}
                        </>
                      )
                  }
                })()}
                {type === 'label' &&
                  `${itemFeild?.[language]?.valueLanguage ? itemFeild[language].valueLanguage : ''} ${!itemFeild?.[language]?.hideEndText ? ':' : ''}`}
              </>
            )}
            {value &&
              type === 'value' &&
              value?.toString().includes('⬜') &&
              !styleInput && (
                <>
                  <img src={imgCheckbox} width={15} />
                </>
              )}
          </SpanFieldValue>
        ))
      ) : (
        <>
          {itemFeild?.inforSign === true &&
            templateDefault?.status == 1 &&
            !styleInput && (
              <div
                className="p-2"
                style={{
                  backgroundColor: '#E3F2E4',
                  border: '1px solid #7cc576',
                  width: '100%',
                  color: 'black',
                  marginTop: '10px',
                }}
              >
                <div style={{ fontWeight: 600 }}>Signature Valid</div>
                {/* {dataField3.show && ( */}
                <div className={`horizontal-content ${'active-data-field-l '}`}>
                  <div
                    className="edit-label mr-1"
                    style={Utils.filterStyles({ color: 'black', fontSize: 12 })}
                  >
                    {t('accountingDocuments.signBy')}{' '}
                    <b>{itemFeild?.[language]?.value}</b>
                  </div>
                  <div
                    className="edit-label mr-1"
                    style={Utils.filterStyles({ color: 'black', fontSize: 12 })}
                  >
                    {t('accountingDocuments.signDate')}{' '}
                    <b>{itemFeild?.[language]?.value?.signDate}</b>
                  </div>
                </div>
              </div>
            )}
        </>
      )}
    </>
  )
}

export default SpanFieldValueComponent
