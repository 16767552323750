import * as y from 'yup'

/** @param {import("react-i18next").TFunction} t */
export const generateSchema = (t) => {
  return {
    sendEmail: y.object({
      name: y.string().nullable(),
      email: y
        .string()
        .required(t('validation.email.required'))
        .email(t('validation.email.invalid')),
      emailCC: y.string().nullable().email(t('validation.email.invalid')),
      emailBCC: y.string().nullable().email(t('validation.email.invalid')),
    }),
  }
}
