import * as y from 'yup'

/** @param {import("react-i18next").TFunction} t */
export const generateSchema = (t) => {
  return {
    goodsGroup: y.object({
      code: y.string().required(t('Required')),
      name: y.string().required(t('Required')),
    }),
  }
}
