import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { useRef } from 'react'
import Iframe from 'react-iframe'

export default function ViewDetailModal({ open, onOpenChange }) {
  const modalRef = useRef(null)
  return (
    <DraggableModal
      ref={modalRef}
      title="Xem vé"
      isOpen={open}
      onOpenChange={onOpenChange}
    >
      <Iframe url="" id="view-detail" />
    </DraggableModal>
  )
}
