import React from 'react'
import PropTypes from 'prop-types'

DropdownHeaderNotification.propTypes = {}

function DropdownHeaderNotification(props) {
  return <div className="p-10">Notification</div>
}

export default DropdownHeaderNotification
