import { PAYMENT_METHOD, PAYMENT_STATUS } from 'general/constants/AppConstants'

export const renderPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case PAYMENT_METHOD.TRANSFER:
      return 'Chuyển khoản'
    case PAYMENT_METHOD.VNPAY:
      return 'VNPAY'
  }
}

export const renderPaymentStatus = (paymentStatus) => {
  let textObj = {
    text: '',
    cn: '',
  }
  switch (paymentStatus) {
    case PAYMENT_STATUS.COMPLETED:
      textObj.text = 'Đã thanh toán'
      textObj.cn = 'badge-light-success'
      break
    case PAYMENT_STATUS.PENDING:
      textObj.text = 'Chờ thanh toán'
      textObj.cn = 'badge-light-info'
      break
    case PAYMENT_STATUS.CANCELED:
      textObj.text = 'Đã hủy'
      textObj.cn = 'badge-light-danger'
      break
  }
  return textObj
}
