import { useMutation, useQuery } from '@tanstack/react-query'
import statisticTicketApi from 'api/statisticTicketApi'
import { setAppSpinning } from 'app/appSlice'
import {
  calcMaxCellWidth,
  generateCellObject,
  generateCellStyle,
} from 'general/helpers/ExcelsHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useGetTicketTemplates from 'hooks/Queries/useGetTicketTemplates'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { find, get, isEmpty, isNil, merge, repeat } from 'lodash'
import { utils, writeFile } from 'xlsx-js-style'
import { useGetAccount } from '../ReportListTicketScreen/queries'

const keyFactory = {
  base: {
    scope: ['report', 'listTicketExported'],
  },
  lists: (f) => [
    {
      ...keyFactory.base,
      params: f,
    },
  ],
  exportExcel: () => [
    {
      ...keyFactory.base,
      action: 'exportExcel',
    },
  ],
}

export const useGetListTicketExported = (filterParams) => {
  return useQuery({
    queryKey: keyFactory.lists(filterParams),
    queryFn: () => statisticTicketApi.getExported(filterParams),
    select: (res) => ({
      rows: get(res, 'tickets', []),
    }),
  })
}

export const useExportExcelMutation = (dynamicColumns = []) => {
  const dispatch = useAppDispatch()
  const { data: accounts } = useGetAccount({
    page: 0,
    limit: 50,
  })
  const { data: templateData } = useGetTicketTemplates()
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)

  return useMutation({
    mutationKey: keyFactory.exportExcel(),
    mutationFn: (params) => statisticTicketApi.getExported(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (error) => ToastHelper.showError(error.message),
    onSuccess: (res) => {
      const commonCellStyle = generateCellStyle({
        alignment: {
          horizontal: 'left',
          vertical: 'center',
        },
        isTextWrap: true,
        border: {
          directions: 'around',
          widthStyle: 'thin',
        },
      })
      const numberFormat = (config) =>
        config === 0 ? '#,##0' : `#,##0.${repeat('0', config)}`
      const percentageFormat = (config) =>
        config === 0 ? '0%' : `0.${repeat('0', config)}%`

      //====================HEADER====================
      const headerStyles = merge(
        {},
        commonCellStyle,
        generateCellStyle({
          font: {
            bold: true,
          },
          border: {
            directions: 'around',
            widthStyle: 'medium',
          },
        }),
      )
      const headers = dynamicColumns.map((col) =>
        generateCellObject(col.name, 's', headerStyles),
      )
      //====================CELLS====================
      const numberFormatStyle = merge(
        {},
        commonCellStyle,
        generateCellStyle({
          alignment: {
            horizontal: 'right',
          },
        }),
      )
      const cells = res?.tickets?.map((row) => {
        const getFormatNumber = (key) =>
          isNil(row?.numberFormat)
            ? get(sysConfig, key, 0)
            : get(row?.numberFormat, key, 0)

        return dynamicColumns.map((col) => {
          let rowValue = get(row, col.id, '')
          rowValue = isNil(rowValue) ? '' : rowValue
          switch (col.id) {
            default:
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'quantityTicket':
              rowValue = Utils.formatNumber(
                rowValue,
                getFormatNumber('quantity'),
              )
                .replace(/\./g, '')
                .replace(/,/g, '.')
              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                numberFormat(getFormatNumber('quantity')),
              )

            case 'invoice_template.vatRateTicket':
              if (isEmpty(rowValue))
                return generateCellObject('', 's', commonCellStyle)
              else
                rowValue = row?.taxRate
                  ? row?.taxRate.replace('%', '') / 100
                  : rowValue / 100

              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                percentageFormat(getFormatNumber('taxRate')),
              )
            case 'totalPrice':
            case 'sumTotalPrice':
              rowValue = Utils.formatNumber(
                rowValue,
                getFormatNumber('totalCost'),
              )
                .replace(/\./g, '')
                .replace(/,/g, '.')
              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                numberFormat(getFormatNumber('totalCost')),
              )
            case 'totalBeforeTax':
              rowValue =
                row?.sumTotalPrice /
                (1 + row?.invoice_template?.vatRateTicket / 100)
              if (isNaN(rowValue))
                return generateCellObject('', 's', numberFormatStyle)
              rowValue = Utils.formatNumber(
                rowValue,
                getFormatNumber('totalCost'),
              )
                .replace(/\./g, '')
                .replace(/,/g, '.')
              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                numberFormat(getFormatNumber('totalCost')),
              )
            case 'taxPrice':
              rowValue =
                row?.sumTotalPrice -
                row?.sumTotalPrice /
                  (1 + row?.invoice_template?.vatRateTicket / 100)
              if (isNaN(rowValue))
                return generateCellObject('', 's', numberFormatStyle)
              rowValue = Utils.formatNumber(
                rowValue,
                getFormatNumber('totalCost'),
              )
                .replace(/\./g, '')
                .replace(/,/g, '.')
              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                numberFormat(getFormatNumber('totalCost')),
              )
            case 'accountId':
              rowValue = find(accounts, { rowValue: rowValue })?.label
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'invoiceTemplateId':
              rowValue = find(templateData, {
                invoiceTemplateId: rowValue,
              })?.name
              return generateCellObject(rowValue, 's', commonCellStyle)
          }
        })
      })
      const aoa = [headers, ...cells]
      const newBook = utils.book_new()
      const ws = utils.aoa_to_sheet(aoa, {
        cellStyles: true,
        sheetStubs: true,
      })
      utils.book_append_sheet(newBook, ws, 'sheet 1')
      ws['!cols'] = calcMaxCellWidth(aoa, ws['!ref'], 15)
      writeFile(newBook, 'Tổng hợp giá trị vé đã xuất theo người xuất vé.xlsx')
    },
  })
}
