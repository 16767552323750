import { Empty, InputNumber, Select, Space, Typography, theme } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import plugin_localized from 'dayjs/plugin/localizedFormat'
import plugin_quarter from 'dayjs/plugin/quarterOfYear'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import { colors } from 'general/constants/ColorConstants'
import SelectOptions from 'general/constants/SelectOptions'
import useFilter from 'hooks/useFilter'
import { includes, toLower } from 'lodash'
import { useState } from 'react'
dayjs.extend(plugin_localized)
dayjs.extend(plugin_quarter)
dayjs().locale('vi')

const dayNmonthFormat = 'DD/MM'
const ticketPublishedDateOptions = SelectOptions.ticketPublishedDateOptions
/**
 * @type {React.CSSProperties}
 */
const commonStyle = {
  fontSize: '13px',
  color: colors.deepBlue.text,
}

export default function CustomFilter({ onApply }) {
  const { token } = theme.useToken()
  const [filter, setFilter] = useFilter(
    'ticketPublished',
    'gFilterTicketPublished',
  )

  const [yearInput, setYearInput] = useState({
    value: dayjs().year(),
    status: '',
  })

  const handleChangeYear = (year, stepInfo = {}) => {
    const now = dayjs().year()
    const { type } = stepInfo
    if (year > now || year < 0) {
      setYearInput((prev) => ({ ...prev, status: type ? '' : 'error' }))
    } else {
      setFilter({
        year,
      })
      setYearInput({
        value: year,
        status: '',
      })
    }
  }

  const transformDate = ({
    month,
    thisMonth,
    quarter,
    thisQuarter,
    thisYear,
    firstHalf,
    secondHalf,
    lastYear,
    fullYear,
  }) => {
    const obj = thisMonth
      ? {
          startDate: dayjs().startOf('month').format(dayNmonthFormat),
          endDate: dayjs().endOf('month').format(dayNmonthFormat),
        }
      : quarter
        ? {
            startDate: dayjs()
              .startOf('year')
              .quarter(quarter)
              .startOf('quarter')
              .format(dayNmonthFormat),
            endDate: dayjs()
              .startOf('year')
              .quarter(quarter)
              .endOf('quarter')
              .format(dayNmonthFormat),
          }
        : thisQuarter
          ? {
              startDate: dayjs()
                .startOf('year')
                .quarter(dayjs().quarter())
                .startOf('quarter')
                .format(dayNmonthFormat),
              endDate: dayjs()
                .startOf('year')
                .quarter(dayjs().quarter())
                .endOf('quarter')
                .format(dayNmonthFormat),
            }
          : thisYear
            ? {
                startDate: dayjs().startOf('year').format(dayNmonthFormat),
                endDate: dayjs().endOf('year').format(dayNmonthFormat),
              }
            : firstHalf
              ? {
                  startDate: dayjs()
                    .startOf('year')
                    .startOf('month')
                    .format(dayNmonthFormat),
                  endDate: dayjs()
                    .month(5)
                    .endOf('month')
                    .format(dayNmonthFormat),
                }
              : secondHalf
                ? {
                    startDate: dayjs()
                      .month(6)
                      .startOf('month')
                      .format(dayNmonthFormat),
                    endDate: dayjs().endOf('year').format(dayNmonthFormat),
                  }
                : lastYear
                  ? {
                      startDate: dayjs()
                        .subtract(1, 'year')
                        .startOf('year')
                        .format(dayNmonthFormat),
                      endDate: dayjs()
                        .subtract(1, 'year')
                        .endOf('year')
                        .format(dayNmonthFormat),
                    }
                  : fullYear
                    ? {
                        startDate: dayjs()
                          .year(yearInput.status)
                          .startOf('year')
                          .format(dayNmonthFormat),
                        endDate: dayjs()
                          .year(yearInput.value)
                          .endOf('year')
                          .format(dayNmonthFormat),
                      }
                    : {
                        startDate: dayjs()
                          .startOf('year')
                          .month(month - 1)
                          .startOf('month')
                          .format(dayNmonthFormat),
                        endDate: dayjs()
                          .startOf('year')
                          .month(month - 1)
                          .endOf('month')
                          .format(dayNmonthFormat),
                      }

    setYearInput((prev) => ({
      ...prev,
      value: lastYear
        ? dayjs().subtract(1, 'year').year()
        : firstHalf || secondHalf || thisMonth || thisQuarter || thisYear
          ? dayjs().year()
          : prev.value,
    }))

    setFilter({
      ...obj,
      year: lastYear
        ? dayjs().subtract(1, 'year').year()
        : firstHalf || secondHalf || thisMonth || thisQuarter || thisYear
          ? dayjs().year()
          : filter.year,
    })
  }

  const handleChangePeriod = (value) => {
    setFilter({
      period: value,
    })
    switch (value) {
      case 'THANG_NAY':
      default:
        transformDate({ thisMonth: true })
        break
      case 'THANG_1':
        transformDate({ month: 1 })
        break
      case 'THANG_2':
        transformDate({ month: 2 })
        break
      case 'THANG_3':
        transformDate({ month: 3 })
        break
      case 'THANG_4':
        transformDate({ month: 4 })
        break
      case 'THANG_5':
        transformDate({ month: 5 })
        break
      case 'THANG_6':
        transformDate({ month: 6 })
        break
      case 'THANG_7':
        transformDate({ month: 7 })
        break
      case 'THANG_8':
        transformDate({ month: 8 })
        break
      case 'THANG_9':
        transformDate({ month: 9 })
        break
      case 'THANG_10':
        transformDate({ month: 10 })
        break
      case 'THANG_11':
        transformDate({ month: 11 })
        break
      case 'THANG_12':
        transformDate({ month: 12 })
        break
      case 'QUY_NAY':
        transformDate({ thisQuarter: true })
        break
      case 'QUY_1':
        transformDate({ quarter: 1 })
        break
      case 'QUY_2':
        transformDate({ quarter: 2 })
        break
      case 'QUY_3':
        transformDate({ quarter: 3 })
        break
      case 'QUY_4':
        transformDate({ quarter: 4 })
        break
      case 'NAM_NAY':
        transformDate({ thisYear: true })
        break
      case 'NAM_TRUOC':
        transformDate({ lastYear: true })
        break
      case 'SAU_THANG_DAU_NAM':
        transformDate({ firstHalf: true })
        break
      case 'SAU_THANG_CUOI_NAM':
        transformDate({ secondHalf: true })
        break
      case 'CA_NAM':
        transformDate({ fullYear: true })
        break
    }
  }

  return (
    <Space align="center">
      <>
        <Typography.Text strong style={{ ...commonStyle }}>
          Kỳ
        </Typography.Text>
        <Select
          showSearch
          suffixIcon={<i className="fa-regular fa-angle-down" />}
          value={filter.period}
          options={ticketPublishedDateOptions}
          onChange={handleChangePeriod}
          notFoundContent={<Empty description={'Không tìm thấy lựa chọn'} />}
          // placement="bottom"
          filterOption={(input, option) => {
            const _input = toLower(input).trim(),
              _value = toLower(option.value).trim(),
              _label = toLower(option.label).trim()
            return _value.includes(_input) || _label.includes(_input)
          }}
          popupMatchSelectWidth
          style={{
            ...commonStyle,
            width: 150,
            borderRadius: token.borderRadiusSM,
          }}
          dropdownStyle={{
            ...commonStyle,
          }}
        />
      </>
      <>
        <Typography.Text strong style={{ ...commonStyle }}>
          Năm
        </Typography.Text>
        <InputNumber
          disabled={includes(
            [
              'THANG_NAY',
              'QUY_NAY',
              'NAM_NAY',
              'NAM_TRUOC',
              'SAU_THANG_DAU_NAM',
              'SAU_THANG_CUOI_NAM',
            ],
            filter.period,
          )}
          onStep={(year, stepInfo) => handleChangeYear(year, stepInfo)}
          value={filter.year}
          status={yearInput.status}
          styles={{
            input: {
              ...commonStyle,
              width: 100,
              borderRadius: token.borderRadiusSM,
            },
          }}
          onChange={handleChangeYear}
        />
      </>
      <>
        <CustomAntButton
          text="Lấy dữ liệu"
          antProps={{
            type: 'primary',
            onClick: onApply,
          }}
          customStyle={{
            padding: '',
          }}
        />
      </>
      <>
        <Typography.Text type="secondary" strong>
          Ngày hoá đơn:
        </Typography.Text>
        <Typography.Text
          style={{
            ...commonStyle,
            fontSize: '13px',
          }}
        >
          Từ {filter.startDate + '/' + filter.year} đến{' '}
          {filter.endDate + '/' + filter.year}
        </Typography.Text>
      </>
    </Space>
  )
}
