import { queryOptions } from '@tanstack/react-query'
import goodsApi from 'api/goodsApi'
import _ from 'lodash'

export const goodsQueries = {
  base: {
    scope: ['category', 'goods'],
  },
  oldListOfGoods: (params) =>
    queryOptions({
      queryKey: [goodsQueries.base, { entity: 'old-goods' }, params],
      queryFn: ({ signal }) => goodsApi.find(params, signal),
      select: (res) => {
        if (res.result !== 'success') return
        return {
          data: res.goods ?? [],
          total: _.defaultTo(res.total, 0),
        }
      },
    }),
  listOfGoods: (params) =>
    queryOptions({
      queryKey: [goodsQueries.base, { entity: 'goods' }, params],
      queryFn: ({ signal }) => goodsApi.list(params, signal),
      select: (res) => {
        if (res.result !== 'success') return
        return {
          data: res.metadata.rows ?? [],
          total: _.get(res, 'metadata.count', 0),
        }
      },
    }),
  dataLogGoods: (params) =>
    queryOptions({
      queryKey: [goodsQueries.base, { entity: 'data-log-goods' }, params],
      queryFn: ({ signal }) => goodsApi.findDataLogGoods(params, signal),
      select: (res) => {
        const { result, metadata } = res
        if (result !== 'success') return
        return _.concat(
          [{ value: null, label: 'Tất cả hàng hóa' }],
          metadata.products ?? [],
        )
      },
    }),
}
