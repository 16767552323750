import { DatePicker, Space, Typography } from 'antd'
import { useFormik } from 'formik'
import locale from 'antd/es/date-picker/locale/vi_VN'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useRef } from 'react'

export default function ModalSignatureInfo({ open, onOpenChange, onApply }) {
  const modalRef = useRef(null)
  const formik = useFormik({
    initialValues: {
      issuedBy: '',
      idValue: '',
      issuedDate: '',
      expireDate: '',
    },
    onSubmit: (values, { resetForm }) => {
      onApply(values)
      resetForm()
    },
    validationSchema: ValidationSchema.signatureInfo,
    enableReinitialize: true,
  })

  return (
    <DraggableModal
      ref={modalRef}
      title={'Nhập thông tin chữ ký số'}
      isOpen={open}
      onOpenChange={(state) => {
        onOpenChange(state)
        formik.resetForm()
      }}
      handleApplyChanges={() => formik.submitForm()}
      width={500}
    >
      <Space direction="vertical" size={'middle'} className="w-100">
        <div className="w-100">
          <CustomLabel
            htmlFor="SIM-item1"
            text="Tên tổ chức cơ quan chứng thực/cấp/công nhận chữ ký số"
            width={'100%'}
            fontWeight={500}
          />
          <CustomAntInput
            borderRadius="sm"
            type="text"
            inputProps={{
              autoComplete: 'off',
              name: 'SIM-item1',
              placeholder: 'Tên tổ chức chứng thực',
              status:
                formik.errors.issuedBy && formik.touched.issuedBy && 'error',
              ...formik.getFieldProps('issuedBy'),
            }}
          />
          {formik.touched.issuedBy ? (
            <Typography.Text type="danger">
              {formik.errors.issuedBy}
            </Typography.Text>
          ) : null}
        </div>

        <div className="w-100">
          <CustomLabel
            htmlFor="SIM-item2"
            isRequired
            text="Số sê-ri chứng thư"
            fontWeight={500}
            width={'100%'}
          />
          <CustomAntInput
            borderRadius="sm"
            type="text"
            inputProps={{
              autoComplete: 'off',
              name: 'SIM-item2',
              placeholder: 'Nhập số sê-ri chứng thư',
              status:
                formik.errors.idValue && formik.touched.idValue && 'error',
              ...formik.getFieldProps('idValue'),
            }}
          />
          {formik.touched.idValue ? (
            <Typography.Text type="danger">
              {formik.errors.idValue}
            </Typography.Text>
          ) : null}
        </div>

        <div className="w-100">
          <CustomLabel
            htmlFor="SIM-item3"
            isRequired
            text="Thời hạn sử dụng chứng thư số (Từ ngày)"
            width={'100%'}
            fontWeight={500}
          />
          <DatePicker
            placement="bottomLeft"
            allowClear={false}
            changeOnBlur
            name={'SIM-item3'}
            value={formik.values.issuedDate}
            onChange={(date) => {
              formik.setFieldValue('issuedDate', date)
            }}
            locale={locale}
            // showTime={canSelectTime}
            format={'L' /*  : 'L' */}
            suffixIcon={<i className="fa-light fa-calendar"></i>}
            nextIcon={<i className="fa-regular fa-angle-right"></i>}
            prevIcon={<i className="fa-regular fa-angle-left"></i>}
            superNextIcon={<i className="fa-regular fa-angle-double-right"></i>}
            superPrevIcon={<i className="fa-regular fa-angle-double-left"></i>}
          />
          {/* {formik.touched.issuedDate ? (
            <Typography.Text type="danger">
              {formik.errors.issuedDate}
            </Typography.Text>
          ) : null} */}
        </div>

        <div className="w-100">
          <CustomLabel
            htmlFor="SIM-item3"
            isRequired
            text="Thời hạn sử dụng chứng thư số (Đến ngày)"
            width={'100%'}
            fontWeight={500}
          />
          <DatePicker
            placement="bottomLeft"
            allowClear={false}
            changeOnBlur
            name={'SIM-item3'}
            value={formik.values.expireDate}
            onChange={(date) => {
              formik.setFieldValue('expireDate', date)
            }}
            locale={locale}
            // showTime={canSelectTime}
            format={'L' /*  : 'L' */}
            suffixIcon={<i className="fa-light fa-calendar"></i>}
            nextIcon={<i className="fa-regular fa-angle-right"></i>}
            prevIcon={<i className="fa-regular fa-angle-left"></i>}
            superNextIcon={<i className="fa-regular fa-angle-double-right"></i>}
            superPrevIcon={<i className="fa-regular fa-angle-double-left"></i>}
          />
          {/* {formik.touched.expireDate ? (
            <Typography.Text type="danger">
              {formik.errors.expireDate}
            </Typography.Text>
          ) : null} */}
        </div>
      </Space>
    </DraggableModal>
  )
}
