import { combineReducers } from '@reduxjs/toolkit'
import authReducer from 'app/authSlice'
import cartReducer from 'features/Cart/CartSlice'
import categoryReducer from 'features/Category/categorySlice'
import dashboardReducer from 'features/Dashboard/dashboardSlice'
import { invoiceFromPosReducer } from 'features/Invoice/screens/ListInvoiceFromPOS/invoiceFromPosSlice'
import { transactionSlice } from 'features/Invoice/screens/ListTransactions/slice'
import invoiceApprovalProcessReducer from 'features/System/screens/InvoiceApprovalProcess/invoiceApprovalProcessSlice'
import orderReducer from 'features/System/screens/ResourceManagement/orderSlice.js'
import systemConfigReducer from 'features/System/screens/SystemConfiguration/SystemConfigurationSlice.js'
import functionReducer from 'features/System/screens/UserManagement/functionSlice.js'
import userReducer from 'features/System/screens/UserManagement/userSlice.js'
import systemReducer from 'features/System/systemSlice'
import importTicketReducer from 'features/Ticket/screens/ImportTicket/importTicketSlice'
import invoiceReducer from '../features/Invoice/invoiceSlice'
import reportReducer from '../features/Report/reportSlice'
import templateInvoiceReducer from '../features/TemplateInvoice/templateInvoiceSlice'
import ticketReducer from '../features/Ticket/ticketSlice'
import appReducer from './appSlice'
import filterReducer from './filterSlice'
import TableReducer from './tableSettingSlice'
import toolReducer from './toolSlice'
import deductionDocumentsReducer from '../features/TaxDeduction/taxDeductionSlice'
import taxDeductionDocumentsSlice from '../features/TaxDeductionDocuments/taxDeductionDocumentsSlice'
import taxpayerSlice from '../features/Taxpayer/taxpayerSlice'
import citySlice from '../features/City/citySlice'
import countrySlice from '../features/Country/countrySlice'
import nationlitySlice from '../features/Nationlity/nationlitySlice'
import districtSlice from '../features/District/districtSlice'
import wardSlice from '../features/Ward/wardSlice'
import departmentSlice from '../features/Department/departmentSlice'
import jobpositionSlice from '../features/Jobposition/jobpositionSlice'
import jobtitleSlice from '../features/JobTitle/jobTitleSlice'
import organizationallevelSlice from '../features/Organizationallevel/organizationallevelSlice'
import grouplocationSlice from '../features/Grouplocation/grouplocationSlice'
export default combineReducers({
  app: appReducer,
  report: reportReducer,
  templateInvoice: templateInvoiceReducer,
  auth: authReducer,
  system: systemReducer,
  dashboard: dashboardReducer,
  category: categoryReducer,
  tool: toolReducer,
  invoice: invoiceReducer,
  cart: cartReducer,
  systemConfig: systemConfigReducer,
  function: functionReducer,
  userList: userReducer,
  orders: orderReducer,
  invoiceApprovalProcess: invoiceApprovalProcessReducer,
  filter: filterReducer,
  ticket: ticketReducer,
  table: TableReducer,
  importTicket: importTicketReducer,
  [transactionSlice.name]: transactionSlice.reducer,
  invoiceFromPos: invoiceFromPosReducer,
  deductionDocuments: deductionDocumentsReducer,
  taxDeductionDocuments: taxDeductionDocumentsSlice,
  taxpayer: taxpayerSlice,
  city: citySlice,
  country: countrySlice,
  nationlity: nationlitySlice,
  district: districtSlice,
  ward: wardSlice,
  department: departmentSlice,
  jobposition: jobpositionSlice,
  jobtitle: jobtitleSlice,
  organizationallevel: organizationallevelSlice,
  grouplocation: grouplocationSlice,
})
