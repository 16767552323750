import React from 'react'
import { Modal, Button } from 'react-bootstrap'

function DeleteUserModal(props) {
  const { show, setShow, deleteFunction } = props
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="" style={{ fontSize: '13px' }}>
            Bạn có chắc chắn muốn xóa người dùng này
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              deleteFunction()
              handleClose()
            }}
          >
            Có
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Không
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DeleteUserModal
