import React from 'react'
import PropTypes from 'prop-types'
import PrivateBaseLayout from 'general/components/eInvoiceComponents/BaseLayout/PrivateBaseLayout'
import AppBreadcrumb from 'general/components/AppBreadcumb'
import Secment from 'general/components/eInvoiceComponents/AppSecment'
import { useState } from 'react'
import InvoicePackageScreen from './tabs/InvoicePackageScreen/'
import TicketPackageScreen from './tabs/TicketPackageScreen/'

ExtendScreen.propTypes = {}

function ExtendScreen(props) {
  //MARK --- Params: ---
  const tabs = [
    'Hóa đơn',
    // 'Tem vé'
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  // MARK: --- Functions ---
  function handleSelectedTab(newTab) {
    setSelectedTab(newTab)
  }

  return (
    <PrivateBaseLayout nonPadding={true}>
      <div className="px-6">
        <div className="">
          {/* card header */}
          <div className=" border-0">
            <div className="w-100 d-flex justify-content-between">
              <div>
                <Secment
                  tabs={tabs}
                  activeTab={selectedTab}
                  handleClick={handleSelectedTab}
                  className="d-flex justify-content-center align-items-center"
                />
              </div>
            </div>

            <div className="w-100">
              {selectedTab === 'Hóa đơn' ? (
                <InvoicePackageScreen />
              ) : selectedTab === 'Tem vé' ? (
                <TicketPackageScreen />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </PrivateBaseLayout>
  )
}

export default ExtendScreen
