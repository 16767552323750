import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Col, Row, Space, Switch, Typography } from 'antd'
import checkTicketPortalApi from 'api/checkTicketPortalApi'
import { setAppSpinning } from 'app/appSlice'
import { useFormik } from 'formik'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ToastHelper from 'general/helpers/ToastHelper'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useAppDispatch } from 'hooks/useRedux'
import { useEffect, useRef } from 'react'

export const CreateNewModal = ({
  open,
  toggleModalState,
  refetchData,
  defaultFormData,
}) => {
  // const { token } = theme.useToken()
  const dispatch = useAppDispatch()
  const modalRef = useRef(null)
  const { mutate: createNewCheckPoint } = useMutation({
    mutationKey: 'createNewCheckPoint',
    mutationFn: (params) => checkTicketPortalApi.create(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => ToastHelper.showError(err?.message ?? 'Có lỗi xảy ra'),
    onSuccess: () => {
      ToastHelper.showSuccess('Tạo mới cổng soát vé thành công')
      toggleModalState(false)
      void refetchData()
    },
  })
  const formik = useFormik({
    initialValues: defaultFormData,
    validationSchema: ValidationSchema.ticketCheckPortalSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => createNewCheckPoint(values),
  })
  const { resetForm } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <DraggableModal
      title="Thêm mới cổng soát vé"
      cancelText="Huỷ bỏ"
      applyText="Lưu"
      ref={modalRef}
      isOpen={open}
      // isLoading={isCreating}
      onOpenChange={(state) => toggleModalState(state)}
      handleApplyChanges={() => formik.handleSubmit()}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <Space
          direction="vertical"
          size="large"
          style={{ paddingTop: '1rem', width: '100%' }}
        >
          {/* Tên  */}
          <Row align={'middle'}>
            <Col span={6}>
              <Typography.Text strong>
                Tên cổng soát vé
                <span className="text-danger ml-1">*</span>
              </Typography.Text>
            </Col>
            <Col span={24 - 6}>
              <CustomAntInput
                borderRadius="sm"
                type="text"
                inputProps={{
                  autoComplete: 'off',
                  placeholder: 'Nhập tên cổng soát vé',
                  status: formik.getFieldMeta('namePointCheckTicket').error
                    ? 'error'
                    : '',
                  value: formik.getFieldProps('namePointCheckTicket').value,
                  name: formik.getFieldProps('namePointCheckTicket').name,
                  onChange: formik.getFieldProps('namePointCheckTicket')
                    .onChange,
                }}
              />
            </Col>
          </Row>
          {/* Mô tả */}
          <Row align={'middle'}>
            <Col span={6}>
              <Typography.Text strong>Mô tả</Typography.Text>
            </Col>
            <Col span={24 - 6}>
              <CustomAntInput
                type="textarea"
                borderRadius="sm"
                inputProps={{
                  value: formik.getFieldProps('description').value,
                  name: formik.getFieldProps('description').name,
                  onChange: formik.getFieldProps('description').onChange,
                  autoComplete: 'off',
                  placeholder: 'Một vài mô tả về cổng soát vé....',
                  status: formik.getFieldMeta('description').error
                    ? 'error'
                    : '',
                  maxLength: 100,
                  style: {
                    minHeight: 120,
                    maxHeight: 300,
                  },
                }}
              />
            </Col>
          </Row>
          {/* serrial number */}
          <Row align={'middle'}>
            <Col span={6}>
              <Typography.Text strong>
                Serial Number
                <span className="text-danger ml-1">*</span>
              </Typography.Text>
            </Col>
            <Col span={24 - 6}>
              <CustomAntInput
                borderRadius="sm"
                type="text"
                inputProps={{
                  autoComplete: 'off',
                  placeholder: 'Nhập Serial Number',
                  status: formik.getFieldMeta('serialDevice').error
                    ? 'error'
                    : '',
                  value: formik.getFieldProps('serialDevice').value,
                  name: formik.getFieldProps('serialDevice').name,
                  onChange: formik.getFieldProps('serialDevice').onChange,
                }}
              />
            </Col>
          </Row>
          {/* Tình trạng sử dụng */}
          <Row align={'middle'}>
            <Col span={6}>
              <Typography.Text strong>Tình trạng sử dụng</Typography.Text>
            </Col>
            <Col span={24 - 6}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={formik.getFieldProps('availability').value}
                onChange={(checked) =>
                  formik.setFieldValue('availability', checked)
                }
              />
            </Col>
          </Row>
        </Space>
      </form>
    </DraggableModal>
  )
}
