import PropTypes from 'prop-types'
import CustomButton from '../Button/CustomButton'
import './style.scss'
ButtonGroupTab.propTypes = {
  //   tabs: PropTypes.array,
  currentTab: PropTypes.number,
  textButton1: PropTypes.string,
  textButton2: PropTypes.string,
  setCurrentTab: PropTypes.any,
  buttonWidth: PropTypes.string,
}

ButtonGroupTab.defaultProps = {
  //   tabs: [],
  currentTab: 0,
  buttonWidth: '160px',
}

function ButtonGroupTab(props) {
  const {
    textButton1,
    textButton2,
    onClick,
    currentTab,
    setCurrentTab,
    buttonWidth,
  } = props
  return (
    <div className="d-flex flex-row">
      <CustomButton
        text={textButton1}
        backgroundColor={currentTab === 0 ? '#304FFD' : '#f0f2f5'}
        color={currentTab === 0 ? 'white' : '#333333'}
        width={buttonWidth}
        onClick={() => {
          setCurrentTab(0)
        }}
        showBorder={false}
      />
      <CustomButton
        text={textButton2}
        backgroundColor={currentTab === 1 ? '#304FFD' : '#f0f2f5'}
        color={currentTab === 1 ? 'white' : '#333333'}
        width={buttonWidth}
        onClick={() => {
          setCurrentTab(1)
        }}
        showBorder={false}
      />
    </div>
  )
}

export default ButtonGroupTab
