import { Flex, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import CustomLabel from 'general/components/CustomLabel'
import Tagnify from 'general/components/CustomTag/Tagnify'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import Utils from 'general/utils/Utils'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useEffect, useMemo, useRef, useState } from 'react'
import { string } from 'yup'
/**
 *
 * @param {Object} param
 * @param {boolean} param.open
 * @param {function} param.onOpenChange
 * @param {string} param.title
 * @param {ReturnTicketData} param.ticketData
 * @param {function} param.handleApplyChanges
 */
export default function SendTicketToCustomerModal({
  open,
  onOpenChange,
  title,
  ticketData,
  handleApplyChanges,
}) {
  const modalRef = useRef(null)
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      emailCC: '',
      emailBCC: '',
    },
    validationSchema: ValidationSchema.sendTicket,
    onSubmit: (values) => {
      handleApplyChanges(values)
    },
  })
  return (
    <DraggableModal
      isOpen={open}
      onOpenChange={onOpenChange}
      title={title}
      width={600}
      applyText="Gửi"
      ref={modalRef}
      handleApplyChanges={formik.submitForm}
      styles={{
        body: {
          padding: '0px',
        },
      }}
    >
      {/* Description */}
      <TopDescription ticketData={ticketData} />
      {/* Form */}
      <FormRegister formik={formik} />
    </DraggableModal>
  )
}

const TopDescription = ({ ticketData }) => {
  const items = useMemo(
    () => [
      {
        label: 'Số vé',
        value: ticketData?.no,
      },
      {
        label: 'Ký hiệu',
        value: ticketData?.serial,
      },
      {
        label: 'Ngày xuất vé',
        value: dayjs(ticketData?.dateRelease).format('L'),
      },
    ],
    [ticketData?.dateRelease, ticketData?.no, ticketData?.serial],
  )

  return (
    <Flex justify="space-between" className="p-4 bg-light">
      <div className="d-flex flex-column">
        {items.map((item, index) => (
          <div key={index} className="d-flex flex-row gap-3">
            <CustomLabel
              htmlFor={item.label}
              text={item.label}
              fontWeight={600}
            />
            <Typography.Text className="flex-fill fw-medium">
              {item.value}
            </Typography.Text>
          </div>
        ))}
      </div>
      <div className="d-flex flex-column align-items-end">
        <CustomLabel
          fontWeight={600}
          fontSize={14}
          htmlFor="totalPrice"
          text="Tổng tiền thanh toán"
        />
        <span style={{ fontSize: '24px', fontWeight: '600' }}>
          {Utils.formatCurrency(ticketData?.totalPrice, ' VNĐ')}
        </span>
      </div>
    </Flex>
  )
}

const FormRegister = ({ formik }) => {
  const [showCC, setShowCC] = useState(false)
  const [showBCC, setShowBCC] = useState(false)
  const { setFieldValue } = formik
  const fields = [
    {
      label: 'Tên khách hàng',
      placeholder: 'Tên khách hàng',
      name: 'name',
      show: true,
      type: 'text',
      required: true,
    },
    {
      label: 'Email',
      placeholder: 'Email',
      name: 'email',
      show: true,
      type: 'text',
      required: true,
    },
  ]

  useEffect(() => {
    if (!showCC) setFieldValue('emailCC', '')
    if (!showBCC) setFieldValue('emailBCC', '')
  }, [setFieldValue, showBCC, showCC])

  return (
    <Flex vertical gap={'0.75rem'} className="p-4">
      {fields.map((field, index) =>
        field.show ? (
          <Space
            key={index}
            direction="vertical"
            size={'small'}
            className="w-100"
          >
            <CustomLabel
              fontWeight={600}
              htmlFor={field.name}
              fontSize={14}
              width={field.name === 'email' ? '100%' : ''}
              text={
                field.name === 'email' ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <Typography.Text>
                      {field.label}
                      {field.required && (
                        <span className="text-danger ml-1">*</span>
                      )}
                    </Typography.Text>
                    <div className="d-flex gap-2">
                      <Typography.Link
                        onClick={() => setShowCC(!showCC)}
                        className="font-size-sm"
                      >
                        {showCC ? 'Ẩn' : 'Hiện'} CC
                      </Typography.Link>
                      <Typography.Link
                        onClick={() => setShowBCC(!showBCC)}
                        className="font-size-sm"
                      >
                        {showBCC ? 'Ẩn' : 'Hiện'} BCC
                      </Typography.Link>
                    </div>
                  </div>
                ) : (
                  <Typography.Text>
                    {field.label}
                    {field.required && (
                      <span className="text-danger ml-1">*</span>
                    )}
                  </Typography.Text>
                )
              }
            />
            <CustomAntInput
              borderRadius="sm"
              type="text"
              inputProps={{
                placeholder: field.placeholder,
                autoComplete: 'off',
                autoCorrect: 'off',
                autoSave: 'off',
                autoFocus: index === 0,
                status:
                  formik.getFieldMeta(field.name).touched &&
                  formik.getFieldMeta(field.name).error
                    ? 'error'
                    : '',
                ...formik.getFieldProps(field.name),
              }}
            />
            {formik.getFieldMeta(field.name).touched &&
            formik.getFieldMeta(field.name).error ? (
              <Typography.Text type="danger" strong className="font-size-sm">
                {formik.getFieldMeta(field.name).error}
              </Typography.Text>
            ) : null}
          </Space>
        ) : null,
      )}
      {showCC && (
        <Space direction="vertical" size={'small'} className="w-100">
          <CustomLabel htmlFor={'emailCC'} text={'Email CC'} fontWeight={600} />
          <Tagnify
            defaultValue={formik.values.emailCC}
            delimiterString=";"
            placeholder="Các email cách nhau bằng dấu ;"
            setFieldValue={formik.setFieldValue}
            name="emailCC"
            pillColor="success"
            restriction={{
              type: 'text',
              validationSchema: string().email('Email không hợp lệ'),
            }}
          />
        </Space>
      )}
      {showBCC && (
        <Space direction="vertical" size={'small'} className="w-100">
          <CustomLabel
            htmlFor={'showBCC'}
            text={'Email BCC'}
            fontWeight={600}
          />
          <Tagnify
            defaultValue={formik.values.showBCC}
            delimiterString=";"
            placeholder="Các email cách nhau bằng dấu ;"
            setFieldValue={formik.setFieldValue}
            name="showBCC"
            pillColor="info"
            restriction={{
              type: 'text',
              validationSchema: string().email('Email không hợp lệ'),
            }}
          />
        </Space>
      )}
    </Flex>
  )
}
