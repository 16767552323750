import React, { useMemo } from 'react'

import './style.scss'
import Utils from 'general/utils/Utils'
function Message_1(props) {
  const { message } = props

  const isSent = useMemo(() => message.messageId?.startsWith('V'), [message])

  const MLoi = useMemo(() => message?.contentRaw?.TDiep?.DLieu?.MLoi, [message])
  const MTa = useMemo(() => message?.contentRaw?.TDiep?.DLieu?.MTa, [message])

  const name = useMemo(
    () => message?.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.Ten,
    [message],
  )

  return (
    <div className="Message_1 border-bottom mb-5">
      <div className="d-flex flex-row">
        <div style={{ maxWidth: '500px' }}>
          <p className="mb-2">
            <i
              className={`fa-regular ${
                isSent ? 'fa-arrow-up' : 'fa-arrow-down'
              } mr-2 ${isSent ? 'i-send' : 'i-receivce'}`}
            ></i>
          </p>

          <p className="mb-2">
            {`[${message.typeCode}] Thông điệp phản hồi kỹ thuật`}
            {name}
          </p>

          <div className="p-3" style={{ backgroundColor: '#fff3cd' }}>
            <p className="mb-2">
              {`Mã lỗi: [${MLoi}]. `}
              {MTa}
            </p>
          </div>

          <p className="mb-2" style={{ color: '#c4c4c4' }}>
            {`Mã thông điệp: ${message.messageId}`}
          </p>
        </div>

        <div className="text-right flex-fill">
          <p className="mb-2 font-weight-bold">
            {isSent ? '0106870211' : 'Cơ quan thuế'}
          </p>

          <p className="mb-2">
            <span
              className="text-dark-75 font-weight-normal"
              style={{
                borderRadius: '40px',
                backgroundColor: '#FF7F7F',
                color: '#f64e60',
                padding: '3px 10px',
              }}
            >
              {'Thông điệp không hợp lệ'}
            </span>
          </p>

          <p
            className="mb-2 cursor-pointer"
            onClick={() => {
              Utils.exportXmlFile({
                data: message.contentRaw.TDiep,
                fileName: 'thong-diep--1',
              })
            }}
            title="Xuất khẩu XML"
          >
            <i
              className="fa-regular fa-download"
              style={{ color: 'inherit' }}
            ></i>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Message_1
