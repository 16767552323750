// import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import CustomSelect from 'general/components/CustomSelect'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import PropTypes from 'prop-types'
import './styles.scss'
import { useMemo } from 'react'
import { useAppSelector } from 'hooks/useRedux'
import { useDispatch } from 'react-redux'
import {
  setHeaderIndex,
  setSelectedSheetName,
} from 'features/Ticket/screens/ImportTicket/importTicketSlice'

ImportTicketChooseSheetAndLine.propTypes = {
  tableData: PropTypes.array,
  setTableData: PropTypes.func,
}

ImportTicketChooseSheetAndLine.defaultProps = {}

function ImportTicketChooseSheetAndLine(props) {
  const { sheetNamesOption } = props
  const dispatch = useDispatch()

  const { selectedSheetName, headerIndex } = useAppSelector(
    (state) => state.importTicket,
  )

  const handleChangeSelectedSheetName = async (value) => {
    dispatch(setSelectedSheetName(value))
  }

  return (
    <div className="border d-flex flex-row rounded" style={{ height: '90px' }}>
      <div
        className="d-flex flex-column w-50 p-3 border-right"
        style={{ gap: '5px' }}
      >
        <span style={{ fontSize: '14px', fontWeight: 700 }}>
          Chọn Sheet chứa dữ liệu
        </span>
        <CustomSelect
          autoSize
          width="max-content"
          selectOptions={sheetNamesOption}
          currentValue={
            sheetNamesOption?.find(({ value }) => value == selectedSheetName)
              ?.value
          }
          onChange={handleChangeSelectedSheetName}
        />
      </div>
      <div className="d-flex flex-column w-50 p-4" style={{ gap: '5px' }}>
        <span style={{ fontSize: '14px', fontWeight: 700 }}>
          Chọn dòng làm tiêu đề cột
        </span>
        <CustomAntInput
          type="text"
          inputProps={{
            placeholder: '',
            defaultValue: '',
            value: headerIndex,
            onChange: (e) => dispatch(setHeaderIndex(e.target.value)),
          }}
          borderRadius="sm"
        />
      </div>
    </div>
  )
}

export default ImportTicketChooseSheetAndLine
