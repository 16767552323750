import CustomButton from 'general/components/Button/CustomButton'
import CustomDropdown from 'general/components/Dropdown/CustomDropdown'
import useSearchDebounce from 'hooks/useSearchDebounce'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import './style.scss'
import Utils from 'general/utils/Utils'

BaseTextField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldProps: PropTypes.object,
  fieldMeta: PropTypes.object,
  fieldHelper: PropTypes.object,

  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  spellCheck: PropTypes.bool,
  additionLabelClassName: PropTypes.string,
  additionalInputClassName: PropTypes.string,
  autoComplete: PropTypes.string,
  labelStyle: PropTypes.object,
  require: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  showDropdownButton: PropTypes.bool,
  showDropdown: PropTypes.bool,
  transparent: PropTypes.bool,
  showBorder: PropTypes.bool,
  handleSelectDropdown: PropTypes.func,
}

BaseTextField.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  disabled: false,
  text: '',
  spellCheck: false,
  fieldProps: {},
  fieldHelper: {},
  fieldMeta: {},
  className: 'form-group',
  additionLabelClassName: 'text-remaining',
  additionalInputClassName: '',
  autoComplete: 'on',
  labelStyle: {},
  require: false,
  onBlur: null,
  onKeyDown: null,
  transparent: false,
  showBorder: true,
}

function BaseTextField(props) {
  // MARK: --- Params ---
  const {
    name,
    fieldProps,
    fieldMeta,
    type,
    label,
    placeholder,
    disabled,
    text,
    className,
    spellCheck,
    additionLabelClassName,
    additionalInputClassName,
    labelStyle,
    require,
    onBlur,
    onKeyDown,
    showDropdownButton,
    transparent,
    showBorder,
    handleSelectCustomer,
    onClickAddCustomer,
  } = props
  const { error, touched } = fieldMeta
  const isError = error && touched
  const dropdownRef = useRef(null)
  const dropdownButtonRef = useRef(null)

  const [showDropdown, setShowDropdown] = useState(false)

  const [currentType, setCurrentType] = useState(type)

  function handleShowPass() {
    if (currentType === 'password') {
      setCurrentType('text')
    } else if (currentType === 'text') {
      setCurrentType('password')
    }
  }

  const { debouncedSearch, setSearch } = useSearchDebounce('')

  useEffect(() => {
    setSearch(fieldProps.value)
  }, [fieldProps.value, setSearch])

  return (
    <div className="BaseTextField position-relative">
      <div className={`${className}`}>
        {label && (
          <div className={`${require && 'd-flex flex-row'}`}>
            <label
              className={additionLabelClassName}
              htmlFor={name}
              style={labelStyle}
            >
              {label}
            </label>
            {require && (
              <span
                className="font-weight-boldest ml-1"
                style={{ color: '#E92E4E' }}
              >{`*`}</span>
            )}
          </div>
        )}
        <div
          className={`BaseTextField_Group rounded input-group ${
            showBorder ? null : 'border-0'
          } ${!disabled && 'bg-white'} ${
            transparent && 'bg-transparent'
          }  d-flex flex-row  justify-content-between ${
            disabled && 'BaseTextField_Disabled'
          }`}
        >
          <input
            className={`ps-3 BaseTextField_Input w-100 rounded border-0 bg-transparent ${
              isError && 'is-invalid'
            } ${additionalInputClassName}`}
            id={name}
            disabled={disabled}
            type={currentType}
            name={name}
            placeholder={placeholder}
            spellCheck={spellCheck}
            {...fieldProps}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            ref={dropdownRef}
            autoComplete="new-password"
            style={
              disabled
                ? { color: '#333333', fontWeight: '500' }
                : { color: '#333333', fontWeight: '500' }
            }
          />
          {type === 'password' && fieldProps.value?.length !== 0 && (
            <div
              className="BaseTextField_Eye d-flex align-items-center justify-content-center cursor-pointer"
              onClick={handleShowPass}
            >
              <i
                className={`fas fa-eye${
                  currentType === 'text' ? '-slash' : ''
                }`}
              ></i>
            </div>
          )}
        </div>
        {text.length > 0 && (
          <span className="form-text text-muted">{text}</span>
        )}
        {isError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{error}</div>
          </div>
        )}
      </div>
      {showDropdownButton && (
        <CustomButton
          className="d-flex align-items-center justify-content-center cursor-pointer mr-1 mt-1"
          width="35px"
          height="35px"
          position="absolute"
          right="0"
          top="0"
          backgroundColor="#fff"
          icon={Utils.cn(
            'fal',
            showDropdown ? 'fa-chevron-up' : 'fa-chevron-down',
          )}
          color="black"
          borderColor="#fff"
          borderRadius="4px"
          ref={dropdownButtonRef}
          onClick={() => {
            setShowDropdown((prev) => !prev)
          }}
          id="dropdownButton"
        />
      )}
      {showDropdown && (
        <CustomDropdown
          searchValue={debouncedSearch}
          handleSelectCustomer={(customer) => {
            handleSelectCustomer(customer)
            setShowDropdown(false)
          }}
          onOpenChange={(value) => setShowDropdown(value)}
          onClickAddCustomer={onClickAddCustomer}
          inputRef={dropdownRef}
        />
      )}
    </div>
  )
}

export default BaseTextField
