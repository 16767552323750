import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import { useMemo } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import CheckPointScreen from './screens/CheckPointScreen/CheckPointScreen'
import CheckTicketPortalScreen from './screens/CheckTicketPortalScreen/CheckTicketPortalScreen'
import CompanyTitleScreen from './screens/CompanyTitleScreen'
import CompanyUnitScreen from './screens/CompanyUnitScreen'
import CustomerScreen from './screens/CustomerScreen'
import EmployeeScreen from './screens/Employee'
import GoodsGroupScreen from './screens/GoodsGroupScreen'
import GoodsScreen from './screens/GoodsScreen'
import MoneyTypeScreen from './screens/MoneyTypeScreen'
import RouteScreen from './screens/RouteScreen/RouteScreen'

Category.propTypes = {}

function Category() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const listFunction = useSelector((state) => state?.function?.listFunctions)
  const userFunction = useSelector((state) => state?.function?.userFunctions)
  const [
    isViewUnitCompany,
    isViewPositionStaff,
    isViewCustomer,
    isViewGoods,
    isViewGroupGoods,
    isViewTypeMoney,
    hasView_route_permission,
    hasView_check_box_permission,
    hasView_view_staff_permission,
  ] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'UNIT_COMPANY_VIEW_LIST',
        },
        {
          listFunctionCode: 'POSITION_STAFF_VIEW_LIST',
        },
        {
          listFunctionCode: 'CUSTOMER_VIEW_LIST',
        },
        {
          listFunctionCode: 'GOODS_VIEW_LIST',
        },
        {
          listFunctionCode: 'GROUP_GOODS_VIEW_LIST',
        },
        {
          listFunctionCode: 'TYPE_MONEY_VIEW_LIST',
        },
        {
          listFunctionCode: 'ROUTE_VIEW_LIST',
        },
        {
          listFunctionCode: 'CHECK_POINT_VIEW_LIST',
        },
        {
          listFunctionCode: 'STAFF_VIEW_LIST',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])
  return (
    <Routes>
      <Route path="" element={<Navigate to="don-vi" />} />
      <Route
        path="don-vi"
        element={
          <ProtectedRoute permission={isViewUnitCompany}>
            <CompanyUnitScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="chuc-danh"
        element={
          <ProtectedRoute permission={isViewPositionStaff}>
            <CompanyTitleScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="nhan-vien"
        element={
          <ProtectedRoute permission={hasView_view_staff_permission}>
            <EmployeeScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="khach-hang"
        element={
          <ProtectedRoute permission={isViewCustomer}>
            <CustomerScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="hang-hoa"
        element={
          <ProtectedRoute permission={isViewGoods}>
            <GoodsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="nhom-hang-hoa"
        element={
          <ProtectedRoute permission={isViewGroupGoods}>
            <GoodsGroupScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="loai-tien"
        element={
          <ProtectedRoute permission={isViewTypeMoney}>
            <MoneyTypeScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="tuyen-duong"
        element={
          <ProtectedRoute permission={hasView_route_permission}>
            <RouteScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="diem-soat-ve"
        element={
          <ProtectedRoute permission={hasView_check_box_permission}>
            <CheckPointScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="cong-soat-ve"
        element={
          <ProtectedRoute permission={hasView_check_box_permission}>
            <CheckTicketPortalScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Category
