import { TICKET_STATUS } from 'general/constants/AppConstants'

export default {
  renderTicketType: (type) => {
    let result = {
      text: '',
      className: '',
    }
    switch (type) {
      case TICKET_STATUS.NEW:
      default:
        result.text = 'Vé mới'
        result.className = 'badge-light-success'
        break
      case TICKET_STATUS.ADJUST:
        result.text = 'Điều chỉnh'
        result.className = 'badge-light-primary'
        break
      case TICKET_STATUS.REPLACE:
        result.text = 'Thay thế'
        result.className = 'badge-light-primary'
        break
      case TICKET_STATUS.CANCELLED:
        result.text = 'Đã hủy'
        result.className = 'badge-light-danger'
        break
      case TICKET_STATUS.REPLACED:
        result.text = 'Đã bị thay thế'
        result.className = 'badge-light-danger'
        break
      case TICKET_STATUS.ADJUSTED:
        result.text = 'Đã bị điều chỉnh'
        result.className = 'badge-light-danger'
        break
    }
    return result
  },

  renderTicketRelease: (type) => {
    let result = {
      text: '',
      className: '',
    }
    switch (type) {
      case TICKET_STATUS.WAITING:
        result.text = 'CQT đang xử lý'
        result.className = 'badge-light-primary'
        break
      case TICKET_STATUS.DENIED:
        result.text = 'CQT từ chối'
        result.className = 'badge-light-danger'
        break
    }
    return result
  },
}
