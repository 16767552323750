import axiosClient from './axiosClient'

const errAnnouceApi = {
  create: (params) => {
    const url = '/err-annouce/create'
    return axiosClient.post(url, params)
  },
  list: (params, signal) => {
    const url = '/err-annouce/list'
    return axiosClient.get(url, { params, signal })
  },
  // delete: (ids) => {
  //   const url = `/err-annouce/delete`;
  //   return axiosClient.delete(url, { params: ids });
  // },
  update: (params) => {
    const url = '/err-annouce/update'
    return axiosClient.post(url, params)
  },
  getTotal: (params) => {
    const url = '/err-annouce/get-total-err-annouce'
    return axiosClient.get(url, { params })
  },
  delete: (invoiceIds) => {
    // debugger
    const url = `/err-annouce/delete`
    return axiosClient.delete(url, {
      data: {
        invoiceIds: invoiceIds,
      },
    })
  },
  exportById: (errorAnnoucementId) => {
    const url = `/err-annouce/export/${errorAnnoucementId}`

    return axiosClient.get(url, { responseType: 'blob' })
  },

  hsmSignErrAnnouce: (params) => {
    const url = '/err-annouce/hsm-sign-err-annouce'

    return axiosClient.post(url, params)
  },
}

export default errAnnouceApi
