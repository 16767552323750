import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import {
  ContainerFunction,
  ContainerSearch,
  DeleteOutlinedIcon,
  DivFlex,
  DivFlexRight,
  DivFunction,
  DivListFunction,
  DivRight,
  FormOutlinedIcon,
  SearchCustom,
  SpanNotYetReleasedTd,
  TitleRight,
} from 'features/TaxDeductionDocuments/styled'
import { DivRowTitle, EllipsisDiv } from 'features/Taxpayer/styled'
import { ButtonSave, TableCustom } from 'features/TaxDeduction/styled'
import { PlusOutlined } from '@ant-design/icons'
import {
  ColCustomJp,
  ContainerCustomJp,
  DivFlexCustom,
  DivTitleJP,
  DownOutlinedCustom,
  DropdownCustom,
  MenuCustom,
  SpanLabel,
} from '../styled'
import SelectOptions from 'general/constants/SelectOptions'
import { filterByValuesAndKey, findObjectInArray } from 'general/utils/Utils'
import { useCallback, useEffect, useState } from 'react'
import { Empty, Space } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'hooks/useRedux'
import {
  thunkFindJobposition,
  thunkRemoveJobposition,
} from '../jobpositionSlice'
import { debounce } from 'lodash'
import { thunkDepartment } from 'features/Department/departmentSlice'
import UpdateJobposition from '../component/Update'
import AlertTaxDectionDocumentsUpdate from 'features/TaxDeductionDocuments/component/Alert'
import ToastHelper from 'general/helpers/ToastHelper'
function SearchJobposition() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
    key: '',
    status: 1,
    maxId: 1,
  })
  const [listData, setListData] = useState([])
  const [listDepartment, setListDepartment] = useState([])
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [maxJobPositionId, setMaxJobPositionId] = useState(0)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [visibleRemove, setVisibleRemove] = useState(false)
  const [jobPositionIdActive, setJobPositionIdActive] = useState(null)

  const getAllDepartment = async () => {
    const data = unwrapResult(await dispatch(thunkDepartment({})))
    const departments = data?.data ?? []
    setListDepartment(departments)
  }

  const formatItems = (list) => {
    let data = list.map((item) => ({
      label: (
        <div
          onClick={() => {
            setPanningDataSource({ ...panningDataSource, status: item.value })
          }}
        >
          {item.label}
        </div>
      ),
      key: item.value,
    }))
    return data
  }

  const columns = [
    {
      title: t('jobposition.jobpositionCode'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={record?.jobPositionCode}
        >
          {record?.jobPositionCode}
        </EllipsisDiv>
      ),
    },
    {
      title: t('jobposition.jobpositionName'),
      width: 180,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={180}
          title={record?.jobPositionName}
        >
          {record?.jobPositionName}
        </EllipsisDiv>
      ),
    },
    {
      title: t('jobposition.groundJobposition'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={record?.groupLocation?.nameGroupLocation}
        >
          {record?.groupLocation?.nameGroupLocation}
        </EllipsisDiv>
      ),
    },
    {
      title: t('jobposition.chucd'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={record?.jobTitle?.jobTitleName}
        >
          {record?.jobTitle?.jobTitleName}
        </EllipsisDiv>
      ),
    },
    {
      title: t('jobposition.dvct'),
      width: 200,
      className: 'center-header',
      render: (text, record) => {
        const values = record?.departments ? record.departments.split(',') : []
        const listDvct = filterByValuesAndKey(
          values,
          'departmentId',
          listDepartment,
        )
        const departmentNames = listDvct
          .map((obj) => obj.departmentName)
          .join(', ')
        return (
          <EllipsisDiv
            className="left-cell"
            width={200}
            title={departmentNames}
          >
            {listDvct.map((obj, index) => (
              <span key={index}>
                {index != 0 && `, `} {obj.departmentName}
              </span>
            ))}
          </EllipsisDiv>
        )
      },
    },
    {
      title: t('jobposition.status'),
      width: 200,
      className: 'center-header',
      fixed: 'right',
      render: (text, record, index) => {
        let listFunction = [
          {
            key: 'remove',
            title: t('taxDeductionDocuments.remove'),
            icon: (
              <DeleteOutlinedIcon
                display="inherit"
                onClick={() => {
                  setJobPositionIdActive(record)
                  setVisibleRemove(true)
                }}
              />
            ),
          },
          {
            key: 'edit',
            title: t('taxDeductionDocuments.editt'),
            icon: (
              <FormOutlinedIcon
                display="inherit"
                onClick={() => {
                  setJobPositionIdActive(record)
                  setVisibleEdit(true)
                }}
              />
            ),
          },
        ]
        return (
          <>
            <ContainerFunction className="left-cell">
              <DivListFunction className="listFuntionFlex">
                {listFunction.map((item, indexl) => (
                  <DivFunction
                    style={{ top: 17 }}
                    title={item.title}
                    key={indexl}
                    right={`${indexl * 40}px`}
                  >
                    {item.icon}
                  </DivFunction>
                ))}
              </DivListFunction>
              <SpanNotYetReleasedTd
                color={record.status == 1 ? '#019160' : '#000000'}
                backGroundColor={record.status == 1 ? '#ebfef7' : '#f1f3f5'}
              >
                {
                  findObjectInArray(
                    SelectOptions.listStatusDepartmentAll,
                    'value',
                    record?.status,
                  )?.label
                }
              </SpanNotYetReleasedTd>
            </ContainerFunction>
          </>
        )
      },
    },
  ]

  const find = async () => {
    const result = unwrapResult(
      await dispatch(thunkFindJobposition(panningDataSource)),
    )
    if (result?.message === 'success') {
      setListData(result?.items)
      setMaxJobPositionId(result?.maxJobPositionId)
    } else {
      setListData([])
    }
  }

  const remove = async () => {
    const result = unwrapResult(
      await dispatch(
        thunkRemoveJobposition({
          jobPositionId: jobPositionIdActive?.jobPositionId,
        }),
      ),
    )
    if (result?.message === 'success') {
      ToastHelper.showSuccess(result?.data)
      setVisibleRemove(false)
      find()
    } else {
      ToastHelper.showError(result?.data)
    }
  }

  const debouncedSetFormState = useCallback(
    debounce((keyValue, value) => {
      // Gọi API tại đây với keyValue và value
      setPanningDataSource((prevPanningDataSource) => ({
        ...prevPanningDataSource,
        [keyValue]: value,
      }))
    }, 300), // Thời gian chờ sau khi kết thúc nhập liệu, ví dụ: 500ms
    [], // Empty dependency array means this callback will only be created once
  )

  const handleChange = (key, value) => {
    // Xóa timeout hiện tại để tránh gọi API nếu người dùng vẫn đang nhập
    clearTimeout(timeoutId)

    // Thiết lập timeout mới với debounce
    timeoutId = setTimeout(() => {
      debouncedSetFormState(key, value)
    }, 500) // Đợi 500ms sau khi kết thúc nhập liệu
  }
  let timeoutId = null // Biến lưu trữ timeout ID

  useEffect(() => {
    find()
  }, [
    panningDataSource.page,
    panningDataSource.limit,
    panningDataSource.key,
    panningDataSource.status,
  ])

  useEffect(() => {
    getAllDepartment()
  }, [])

  return (
    <>
      <ContainerCustomJp>
        <div className="row">
          <ColCustomJp className="col-4">
            <SpanLabel>{t('jobposition.status')} :</SpanLabel>
            <DropdownCustom
              menu={{
                items: formatItems(SelectOptions.listStatusDepartmentAll),
              }}
              trigger={['click']}
            >
              <Space>
                {findObjectInArray(
                  SelectOptions.listStatusDepartmentAll,
                  'value',
                  panningDataSource?.status,
                )?.label ?? t('jobposition.all')}
                <DownOutlinedCustom />
              </Space>
            </DropdownCustom>
          </ColCustomJp>
          <div className="col-8">
            <DivFlexCustom>
              <SearchCustom
                placeholder={t('department.placehodersearch')}
                style={{ width: 294 }}
                onChange={(e) => {
                  handleChange('key', e.target.value)
                }}
              />
              <ButtonSave
                marginLeft="8px"
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  setVisibleAdd(true)
                }}
              >
                {t('jobposition.addtile')}
              </ButtonSave>
            </DivFlexCustom>
          </div>
        </div>
      </ContainerCustomJp>

      <div className="pt-2">
        <TableCustom
          dataSource={listData}
          columns={columns}
          marginRight={'0px'}
          marginLeft={'0px'}
          locale={{
            emptyText: <Empty description={t('taxDeduction.noData')} />,
          }}
          pagination={
            panningDataSource.totalPages > 1
              ? {
                  current: panningDataSource.page,
                  pageSize: panningDataSource.limit,
                  total: panningDataSource.totalItems,
                }
              : false
          }
          onChange={(pagination) => {
            setPanningDataSource({
              ...pagination,
              page: pagination.current,
              limit: pagination.pageSize,
            })
          }}
        />
      </div>
      {/* add */}
      <UpdateJobposition
        listDepartment={listDepartment}
        openModal={visibleAdd}
        changeOpenModal={(value) => {
          setVisibleAdd(value)
        }}
        maxId={maxJobPositionId}
        find={() => {
          find()
        }}
      />
      {/* edit */}
      <UpdateJobposition
        listDepartment={listDepartment}
        openModal={visibleEdit}
        changeOpenModal={(value) => {
          setVisibleEdit(value)
        }}
        maxId={maxJobPositionId}
        find={() => {
          find()
        }}
        jobPositionId={jobPositionIdActive?.jobPositionId}
      />

      {/* remove */}
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.remove')}
        openModalAlert={visibleRemove}
        titleAlert={t('jobposition.titleModalRemove')}
        // messageAlert={t('taxpayer.messagennt', dataActive)}
        setOpenModalAlert={(value) => {
          setVisibleRemove(value)
        }}
        onClickPrimary={() => {
          remove()
        }}
        colorButtonSave={'#ffffff'}
        backGroundButtonSave={'#e61d1d'}
        backGroundButtonSaveHover={'#cc0202!important'}
        messageActive1={t('jobposition.messageRemove1')}
        messageActive2={t('jobposition.messageRemove2')}
        nameActive={jobPositionIdActive?.jobPositionCode}
      />
    </>
  )
}

export default SearchJobposition
