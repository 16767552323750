import invoiceTemplateFunctionApi from 'api/invoiceTemplateFuntionApi.js'
import listFunctionApi from 'api/listFunctionApi'
import { setAppSpinning } from 'app/appSlice'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import { FastField, Formik } from 'formik'
import Empty from 'general/components/Empty'
import KTCheckbox from 'general/components/Forms/KTFormControls/KTCheckbox'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import KTFormSearchDropDown from 'general/components/Forms/KTFormSearchDropDown'
import Loading from 'general/components/Loading'
import AppResource from 'general/constants/AppResource'
import ToastHelper from 'general/helpers/ToastHelper'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Accordion, Button, ListGroup, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { updateListFunction } from '../../functionSlice'
import { updateUser } from '../../userSlice.js'
import './style.scss'

function UpdateUserModal(props) {
  const {
    show,
    setShow,
    onRefreshCustomerList,
    userSelected,
    onExistDone,
    selectedUserId,
    templateInvoiceSerials,
  } = props
  const [showBx, setShowBx] = useState(true)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isEditMode, setIsEditMode] = useState(true)
  const [activities, setActivities] = useState([])

  const [tableMaxHeight, setTableMaxHeight] = useState('55vh')
  const { customers, isGettingCustomer, pagination } = useSelector(
    (state) => state?.category,
  )
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [toggleClearCustomers, setToggledClearCustomers] = useState(true)
  const [selectedCustomerItem, setSelectedCustomerItem] = useState(null)
  const [showingModalEditCustomer, setShowingModalEditCustomer] =
    useState(false)
  const refCsvFile = useRef()
  const [csvImporting, setCsvImporting] = useState(false)

  const [userFunction, setUserFunction] = useState(
    useSelector((state) => state?.function?.userFunctions),
  )

  const [listFunction, setListFunction] = useState([])
  const [listFunctionOrigin, setListFunctionOrigin] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const functions = await listFunctionApi.getAllByUserId({
          accountId: userSelected?.accountId,
        })
        setListFunction(functions)
        setListFunctionOrigin(functions)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  const [invoiceTemplateFunction, setInvoiceTemplateFunction] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const tmp = await invoiceTemplateFunctionApi.getAllByUserId({
        accountId: userSelected?.accountId,
      })
      setInvoiceTemplateFunction(tmp)
    }
    fetchData()
  }, [])

  const [dataFunctionList, setDataFunctionList] = useState([])

  useEffect(() => {
    if (userFunction) {
      const filteredFunctions = userFunction.filter(
        (item) => item.type == 'parent',
      )
      const newFunctionList = filteredFunctions.map((item) => ({
        name: item.functionName,
        userFunctionId: item.userFunctionId,
      }))

      const updatedDataFunctionList = newFunctionList.map((parentItem) => {
        const childFunctions = userFunction.filter(
          (item) =>
            item.type == 'children' &&
            item.parentId == parentItem.userFunctionId,
        )
        const childList = childFunctions.map((childItem) => ({
          name: childItem.functionName,
          userFunctionId: childItem.userFunctionId,
        }))

        const activities = listFunction.filter(
          (item) => item.userFunctionId == parentItem.userFunctionId,
        )

        const updatedChildList = childList.map((childItem) => {
          const childActivities = listFunction.filter(
            (item) => item.userFunctionId == childItem.userFunctionId,
          )
          return { ...childItem, activities: childActivities }
        })

        return { ...parentItem, list: updatedChildList, activities }
      })

      if (dataFunctionList == [])
        setDataFunctionList((prevList) => [
          ...prevList,
          ...updatedDataFunctionList,
        ])
      else setDataFunctionList(updatedDataFunctionList)
    }
  }, [userFunction, listFunction])

  const fullColumns = [
    {
      name: 'Tên mẫu',
      cell: (row) => {
        return <div data-tag="allowRowEvents">{row?.name}</div>
      },
    },
    {
      name: 'Mẫu số - Ký hiệu',
      // sortable: false,
      // minWidth: '220px',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.serial}
          </p>
        )
      },
    },
    {
      name: 'Ngừng sử dụng',
      center: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents" className="text-center w-100">
            {row?.active == 1 ? (
              <i className="fa-solid fa-circle-minus"></i>
            ) : (
              <i
                style={{ color: 'red' }}
                className="fa-solid fa-circle-minus"
              ></i>
            )}
          </div>
        )
      },
    },
  ]

  const columns = fullColumns

  function handleExistDone() {
    if (onExistDone) {
      onExistDone()
    }
  }

  const changeListFunction = (id) => {
    const tmp = ['Xem danh sách', 'Xem']
    let view = null
    let restList = []
    let prevListFunction = [...listFunction]
    let prevActivities = [...activities]

    prevActivities.map((item) => {
      if (tmp.includes(item.actionName)) {
        view = item
      } else {
        restList.push(item)
      }
    })

    const targetItem = prevActivities.find(
      (activity) => activity.listFunctionId == id,
    )

    if (restList == [] || view == null) {
      setActivities((prevList) => {
        return prevList.map((item) => {
          if (item == targetItem) {
            return { ...item, active: !item.active }
          }
          return item
        })
      })
      setListFunction((prevList) => {
        return prevList.map((item) => {
          if (item == targetItem) {
            return { ...item, active: !item.active }
          }
          return item
        })
      })
      return
    }
    if (targetItem == view) {
      if (view.active == 1) {
        prevListFunction = prevListFunction.map((item) => {
          if (restList.some((restItem) => restItem == item) || item == view) {
            return { ...item, active: 0 }
          }
          return item
        })

        prevActivities = prevActivities.map((item) => {
          if (restList.some((restItem) => restItem == item) || item == view) {
            return { ...item, active: 0 }
          }
          return item
        })
      } else {
        prevListFunction = prevListFunction.map((item) => {
          if (item == view) {
            return { ...item, active: 1 }
          }
          return item
        })

        prevActivities = prevActivities.map((item) => {
          if (item == view) {
            return { ...item, active: 1 }
          }
          return item
        })
      }
    } else if (restList.some((item) => item == targetItem)) {
      prevListFunction = prevListFunction.map((item) => {
        if (item == view && targetItem.active == 0) {
          return { ...item, active: 1 }
        } else {
          if (item == targetItem) {
            return { ...item, active: !item.active }
          }
        }
        return item
      })

      prevActivities = prevActivities.map((item) => {
        if (item == view) {
          return { ...item, active: 1 }
        } else {
          if (item == targetItem) {
            return { ...item, active: !item.active }
          }
        }
        return item
      })
    }

    prevListFunction.forEach(function (item) {
      if (
        prevActivities.some(
          (activity) => activity.listFunctionId == item.listFunctionId,
        )
      ) {
      }
    })

    setActivities(prevActivities)
    setListFunction(prevListFunction)
  }
  // setActivities((prevList) => {
  //   return prevList.map((item) => {
  //     if (item.listFunctionId == id) {
  //       return { ...item, active: !item.active }
  //     }
  //     return item
  //   })
  // })
  // setListFunction((prevList) => {
  //   return prevList.map((item) => {
  //     if (item.listFunctionId == id) {
  //       return { ...item, active: !item.active }
  //     }
  //     return item
  //   })
  // })

  const selectedRows = useRef()
  const defaultSelectedRows = (row) => {
    // Kiểm tra xem có `invoiceTemplateId` của `row` nào trùng khớp trong `invoiceTemplateFunction` hay không
    for (let i = 0; i < invoiceTemplateFunction.length; i++) {
      if (
        invoiceTemplateFunction[i].invoiceTemplateId == row.invoiceTemplateId
      ) {
        // selectedRows.current.push(row);
        return row
      }
    }
    return ''
  }

  // Xử lý khi người dùng chọn hàng
  const handleSelectedRowsChange = (rows) => {
    selectedRows.current = rows.selectedRows
    // console.log(selectedRows.current);
  }

  const checkFullFunction = (item1) => {
    // console.log(item1);
    if (item1?.activities?.length > 0) {
      const allActiveIs1InActivities = item1.activities.every(
        (activity) => activity.active == 1,
      )
      if (allActiveIs1InActivities) {
        return '(Toàn quyền)'
      } else {
        return ''
      }
    } else {
      if (item1?.list?.length > 0) {
        if (item1?.list?.length > 0) {
          const allActiveIs1 = item1.list.every((obj) => {
            return obj.activities.every((activity) => activity.active == 1)
          })

          if (allActiveIs1) {
            return '(Toàn quyền)'
          }
        }
        return ''
      }
    }
  }

  const companyUnitsTmp = useSelector((state) => state?.category?.companyUnits)

  const [companyUnits, setCompanyUnits] = useState()

  useEffect(() => {
    // Kiểm tra nếu companyUnits không null và có phần tử trong mảng
    if (companyUnitsTmp && companyUnitsTmp.length > 0) {
      // Tạo một bản sao của companyUnits đã có để tránh sửa trực tiếp vào state Redux
      const updatedCompanyUnits = companyUnitsTmp.map((item) => ({
        ...item,
        value: item.name, // Thêm trường value và gán bằng giá trị của trường name
      }))

      // Cập nhật lại companyUnits trong state Redux bằng mảng đã được thêm trường value
      setCompanyUnits(updatedCompanyUnits)
    }
  }, [companyUnitsTmp])

  const companyTitlesTmp = useSelector(
    (state) => state?.category?.companyTitles,
  )

  const [companyTitles, setCompanyTitles] = useState()

  useEffect(() => {
    // Kiểm tra nếu companyTitles không null và có phần tử trong mảng
    if (companyTitlesTmp && companyTitlesTmp.length > 0) {
      // Tạo một bản sao của companyTitles đã có để tránh sửa trực tiếp vào state Redux
      const updatedCompanyTitles = companyTitlesTmp.map((item) => ({
        ...item,
        value: item.name, // Thêm trường value và gán bằng giá trị của trường name
      }))

      // Cập nhật lại companyTitles trong state Redux bằng mảng đã được thêm trường value
      setCompanyTitles(updatedCompanyTitles)
    }
  }, [companyTitlesTmp])

  function diffArrayObjects(arr1, arr2) {
    const compare = (obj1, obj2) => JSON.stringify(obj1) == JSON.stringify(obj2)
    const inFirstOnly = arr1.filter((a) => !arr2.some((b) => compare(a, b)))
    const inSecondOnly = arr2.filter((a) => !arr1.some((b) => compare(a, b)))
    return [...inFirstOnly]
  }

  // const Checkbox = forwardRef(({ onClick, ...rest }, ref) => {
  //   const { isAdmin } = userSelected // giả sử userSelected là đối tượng chứa thông tin người dùng
  //   console.log(isAdmin)
  //   return (
  //     <div className="form-check pb-5" style={{ backgroundColor: '' }}>
  //       <input
  //         type="checkbox"
  //         className="form-check-input"
  //         style={{ height: '20px', width: '20px' }}
  //         ref={ref}
  //         onClick={onClick}
  //         disabled={isAdmin} // vô hiệu hóa checkbox nếu người dùng là admin
  //         {...rest}
  //       />
  //       <label className="form-check-label" id="booty-check" />
  //     </div>
  //   )
  // })

  return (
    <div className="UpdateUserModal">
      <Formik
        initialValues={{
          title: userSelected ? userSelected.companyTitle : '',
          fullname: userSelected ? userSelected.fullname : '',
          agency: userSelected ? userSelected.companyUnit : '',
          role: userSelected ? userSelected.role : '',
          email: userSelected ? userSelected.email : '',
          phone: userSelected ? userSelected?.phone : '',
        }}
        validationSchema={Yup.object({
          fullname: Yup.string().required('Không được để trống').trim(),
          phone: Yup.string().required('Không được để trống'),
          email: Yup.string()
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email không hợp lệ')
            .required('Không được để trống'),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          dispatch(setAppSpinning(true))
          try {
            await invoiceTemplateFunctionApi.update({
              accountId: userSelected.accountId,
              selectedRows: selectedRows.current,
            })
            await dispatch(
              updateUser({ ...values, accountId: userSelected?.accountId }),
            )
            for (const item of diffArrayObjects(
              listFunction,
              listFunctionOrigin,
            )) {
              await dispatch(updateListFunction(item))
            }
            // await dispatch(updateAllListFunction(listFunction)
            dispatch(setAppSpinning(false))
            handleClose()
            ToastHelper.showSuccess('Cập nhật người dùng thành công')
          } catch (error) {
            dispatch(setAppSpinning(false))
            console.error(error)
            ToastHelper.showError('Có lỗi xảy ra khi cập nhật người dùng')
            handleClose()
          }
        }}
      >
        {(formikProps) => (
          <>
            <Modal
              className=""
              show={show}
              backdrop="static"
              onHide={handleClose}
              centered
              size="lg"
              onExit={() => {
                formikProps.handleReset()
              }}
              onExited={() => {
                handleExistDone()
              }}
            >
              <Modal.Header className="px-5 py-5">
                <Modal.Title>Chỉnh sửa thông tin người dùng</Modal.Title>
                <div
                  className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <i className="far fa-times"></i>
                </div>
              </Modal.Header>

              <Modal.Body
                className="bg-light"
                style={{
                  maxHeight: '70vh',
                  overflowY: 'inherit !important',
                  padding: '10px 20px',
                }}
              >
                <div className="row">
                  <div className="col-lg-6 pr-lg-3">
                    <KTFormGroup
                      label={
                        <>
                          {t('Email')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="email"
                      inputElement={
                        <FastField name="email">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('Email'))}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  <div className="mb-4 d-flex flex-column col-lg-6">
                    <label
                      className="mb-2 font-weight-bolder"
                      style={{ color: '#7177A8' }}
                      htmlFor="title"
                    >
                      {_.capitalize(t('Chức danh'))}
                    </label>
                    <KTFormSearchDropDown
                      name="title"
                      noSearchBar={true}
                      isCustom
                      options={companyTitles}
                      value={formikProps
                        .getFieldProps('title')
                        .value?.toString()}
                      onChange={(newValue) => {
                        formikProps.getFieldHelpers('title').setValue(newValue)
                      }}
                    />
                  </div>

                  {/* so dien thoai */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Số điện thoại')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="phone"
                      inputElement={
                        <FastField name="phone">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Số điện thoại'),
                              )}...`}
                              // disabled={!canEdit}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* Đơn vị */}
                  <div className="mb-4 d-flex flex-column col-lg-6">
                    <label
                      className="mb-2 font-weight-bolder"
                      style={{ color: '#7177A8' }}
                      htmlFor="title"
                    >
                      {_.capitalize(t('Đơn vị'))}
                    </label>
                    <KTFormSearchDropDown
                      name="title"
                      noSearchBar={true}
                      isCustom
                      options={companyUnits}
                      value={formikProps
                        .getFieldProps('agency')
                        .value?.toString()}
                      onChange={(newValue) => {
                        formikProps.getFieldHelpers('agency').setValue(newValue)
                      }}
                    />
                  </div>

                  {/* Họ và tên*/}
                  <div className="col-lg-6 d-flex">
                    <div className="w-100 pr-3">
                      <KTFormGroup
                        label={
                          <>
                            {t('Họ và tên')}{' '}
                            <span className="text-danger">(*)</span>
                          </>
                        }
                        inputName="fullname"
                        inputElement={
                          <FastField name="fullname">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value)
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true)
                                }}
                                enableCheckValid
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.text}
                                placeholder={`${_.capitalize(
                                  t('Họ và đệm'),
                                )}...`}
                                // disabled={!canEdit}
                              />
                            )}
                          </FastField>
                        }
                      />
                    </div>
                  </div>

                  {/* Quyền truy cập*/}
                  {/* <div className="mb-4 d-flex flex-column col-lg-6">
                    <label
                      className="mb-2 font-weight-bolder"
                      style={{ color: '#7177A8' }}
                      htmlFor="role"
                    >
                      {_.capitalize(t('Quyền truy cập'))} <span className="text-danger">(*)</span>
                    </label>
                    <KTFormSearchDropDown
                      name="role"
                      isCustom
                      options={AppData.userRole}
                      value={formikProps.getFieldProps('role').value?.toString()}
                      onChange={(newValue) => {
                        formikProps.getFieldHelpers('role').setValue(newValue);
                      }}
                    />
                  </div> */}
                </div>
              </Modal.Body>

              <Modal.Footer style={{ zIndex: 90, padding: '0px' }}>
                <div
                  className="w-100 d-flex cursor-pointer"
                  style={{ padding: '10px 20px' }}
                >
                  <div
                    className="pr-5"
                    style={{
                      color: `${showBx == true ? '#1877F2' : '#3F4254'}`,
                      borderBottom: showBx == true && '1px solid #1877F2',
                    }}
                    onClick={() => {
                      setShowBx(true)
                    }}
                  >
                    Phân quyền chức năng
                  </div>
                  <div
                    className="pr-5"
                    style={{
                      color: `${showBx == false ? '#1877F2' : '#3F4254'}`,
                      borderBottom: showBx == false && '1px solid #1877F2',
                    }}
                    onClick={() => {
                      setShowBx(false)
                    }}
                  >
                    Phân quyền mẫu hóa đơn
                  </div>
                </div>
                <div className="w-100 m-0">
                  {showBx ? (
                    <div className="row m-0 w-100">
                      <div
                        className="w-100 d-flex pt-3 pb-3 pl-5 pr-5 mb-2"
                        style={{ background: '#F4F5F8' }}
                      >
                        <div className="w-50">Chức năng</div>
                        <div className="w-50">Hoạt động của chức năng</div>
                      </div>
                      <div
                        className="col-lg-6"
                        style={{ maxHeight: '300px', overflow: 'auto' }}
                      >
                        <Accordion>
                          {dataFunctionList?.map((item1, index1) => {
                            return (
                              <Accordion.Item eventKey={index1} key={index1}>
                                <Accordion.Header
                                  className={
                                    item1?.list.length > 0 ? '' : 'no-arrow'
                                  }
                                  onClick={() => {
                                    if (item1?.activities)
                                      setActivities(item1?.activities)
                                  }}
                                >
                                  <div>
                                    {item1?.name}
                                    <span
                                      className="pl-1"
                                      style={{ color: 'red' }}
                                    >
                                      {checkFullFunction(item1)}
                                    </span>
                                  </div>
                                </Accordion.Header>
                                {item1?.list.length > 0 && (
                                  <Accordion.Body>
                                    <ListGroup>
                                      {item1?.list.map((item2, index2) => {
                                        return (
                                          <div key={index2}>
                                            <ListGroup.Item
                                              action
                                              className="pl-5 w-100 mt-2"
                                              onClick={() => {
                                                if (item2?.activities)
                                                  setActivities(
                                                    item2?.activities,
                                                  )
                                              }}
                                            >
                                              <div>
                                                {item2?.name}
                                                <span
                                                  className="pl-1"
                                                  style={{ color: 'red' }}
                                                >
                                                  {checkFullFunction(item2)}
                                                </span>
                                              </div>
                                            </ListGroup.Item>
                                          </div>
                                        )
                                      })}
                                    </ListGroup>
                                  </Accordion.Body>
                                )}
                              </Accordion.Item>
                            )
                          })}
                        </Accordion>
                      </div>

                      <div
                        className="col-lg-6"
                        style={{ maxHeight: '400px', overflow: 'auto' }}
                      >
                        {activities?.map((item, index) => {
                          return (
                            <div className="mt-3" key={index}>
                              <KTCheckbox
                                name={item?.actionName}
                                label={item?.actionName}
                                checked={item?.active == 1 ? true : false}
                                onChange={() =>
                                  changeListFunction(item?.listFunctionId)
                                }
                                disabled={userSelected?.isAdmin}
                              ></KTCheckbox>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) : (
                    <div style={{ maxHeight: '345px', overflow: 'auto' }}>
                      <DataTable
                        fixedHeader
                        fixedHeaderScrollHeight={tableMaxHeight}
                        columns={columns}
                        data={templateInvoiceSerials}
                        customStyles={customDataTableStyle}
                        responsive={true}
                        noHeader
                        selectableRows={true}
                        // selectableRowsComponent={Checkbox}
                        striped
                        noDataComponent={
                          <div className="pt-12">
                            <Empty
                              text={t('NoData')}
                              visible={false}
                              imageEmpty={AppResource.icons.icEmptyBox}
                              imageEmptyPercentWidth={170}
                            />
                          </div>
                        }
                        progressComponent={
                          <Loading
                            showBackground={false}
                            message={`${t('Loading')}...`}
                          />
                        }
                        onSelectedRowsChange={handleSelectedRowsChange}
                        pointerOnHover
                        highlightOnHover
                        selectableRowsHighlight
                        selectableRowSelected={
                          userSelected?.isAdmin === true
                            ? (row) => row
                            : defaultSelectedRows
                        }
                        selectableRowDisabled={
                          userSelected?.isAdmin === true ? (row) => row : null
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="w-100 d-flex row">
                  <Button
                    className="font-weight-bold flex-grow-1 col mr-3"
                    variant="secondary"
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    {t('Đóng')}
                  </Button>
                  <Button
                    className="font-weight-bold flex-grow-1 col"
                    variant="primary"
                    onClick={() => {
                      formikProps.handleSubmit()
                    }}
                  >
                    {t('Lưu')}
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  )
}

export default UpdateUserModal
