import { useMutation } from '@tanstack/react-query'
import { Checkbox, Flex, Radio, Space } from 'antd'
import registrationApi from 'api/registrationApi'
import toolApi from 'api/toolApi'
import {
  setAppNotInstalledToolNotiModalShow,
  setAppSpinning,
} from 'app/appSlice'
import { setSelectedDigitalCertificate } from 'app/toolSlice'
import customProtocolCheck from 'custom-protocol-check'
import dayjs from 'dayjs'
import ModalChooseSignMethod from 'features/Invoice/screens/GuestSignInvoiceReport/components/ModalChooseSignMethod'
import CustomSelect from 'features/Registration/components/CustomSelect'
import ModalConnectConfig from 'features/System/components/Modals/ModalConnectConfig'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import { MAX_WAIT_SIGN_INVOICE } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import wsHelperInstance from 'general/helpers/WebSocketHelper'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import useCheckPermission from 'hooks/useCheckPermission'
import { useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ModalSignatureInfo from './components/SignatureInfoModal'
import './style.scss'
import { useQuery } from '@tanstack/react-query'
import axiosClient from 'api/axiosClient'
dayjs.locale('en')

const cerTypeOptions = [
  { label: 'Thêm mới', value: 1 },
  { label: 'Gia hạn', value: 2 },
  { label: 'Ngừng sử dụng', value: 3 },
]

const MAX_WAIT_SIGN_REGISTRATION = 30000

const initialRegistration = {
  type: 1,
  no: 'ĐKTĐ00001',
  formWithTaxAuthCode: 1,
  formWithoutTaxAuthCode: 0,
  formPosTaxAuthCode: 0,

  transTacDiaBanKK: 0,
  transTacUBND: 0,

  transNoTacDirect: 0,
  transNoTacIndirect: 0,

  transferMethodDetail: 1,
  transferMethodSummary: 0,

  usingVat: 1,
  usingSale: 0,
  usingSalePublicProperty: 0,
  usingSaleNationalReserve: 0,
  usingOther: 0,
  usingBill: 0,

  // locationName: 'Hà Nội',
}
export default function CreateRegistration() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { registrationId } = useParams()
  const { pathname } = useLocation()

  const selectedDigitalCertificate = useAppSelector(
    (state) => state.tool.selectedDigitalCertificate,
  )
  const company = useAppSelector((state) => state.system.company)
  const systemType = useAppSelector((s) => s.auth?.activeInvoice)

  const [registration, setRegistration] = useState()
  const [selectedCertificates, setSelectedCertificates] = useState([])
  const [showModalConnectConfig, setShowModalConnectConfig] = useState(false)
  const [showModalSignatureInfo, setShowModalSignatureInfo] = useState(false)
  const [showModalChooseSignMethod, setShowModalChooseSignMethod] =
    useState(false)

  const screenAction = useMemo(() => pathname?.split('/')?.at(3), [pathname])

  const { canAddNew, canEdit } = useCheckPermission([
    {
      keyAction: 'canAddNew',
      permissionCode: 'TOKHAI_ADD',
    },
    {
      keyAction: 'canEdit',
      permissionCode: 'TOKHAI_EDIT',
    },
  ])

  const gotDetailRegistration = useRef(false)

  //#region Mutations-Queries
  const { mutate: printMutation, isPending: isPrinting } = useMutation({
    mutationKey: ['registration', 'print'],
    mutationFn: () => {
      return registrationApi.exportRegistration({
        ..._.omit(registration, 'digital_certificates'),
        certificates: _.concat(
          _.map(
            registration.digital_certificates,
            ({ registration_cert, ...rest }) => ({
              ...rest,
              type: registration_cert?.type,
            }),
          ),
          selectedCertificates,
        ),
      })
    },
    onSuccess: (res) => {
      Utils.openFileInNewTab({
        blobData: res,
        fileName: 'Tờ khai đăng ký/thay đổi thông tin sử dụng hóa đơn.pdf',
        fileType: 'application/pdf',
      })
    },
  })
  //#endregion

  //#region Handlers
  const hsmSign = async (id) => {
    try {
      dispatch(setAppSpinning(true))

      await registrationApi.hsmSignRegistration({
        registrationId: id,
      })
      dispatch(setAppSpinning(false))

      ToastHelper.showSuccess('Ký tờ khai thành công')

      if (systemType?.invoice) navigate('/hoa-don-dau-ra/to-khai')
      if (systemType?.ticket) navigate('/hoa-don-dau-ra/tem-ve/to-khai')
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  async function handlePressedAddDigitalCertificate() {
    const res = await toolApi.genToolUrl({
      param2: 'addDigitalSignature',
      param3: company.taxCode,
    })
    let { url } = res
    if (url) {
      customProtocolCheck(
        url,
        () => dispatch(setAppNotInstalledToolNotiModalShow(true)),
        () => {
          Utils.openInCurrentTab(url)
        },
        2500,
      )

      let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
      wsHelperInstance.onAddingDigitalCertificate(accessToken)
    }
  }

  async function handleCreateRegistration() {
    let { digital_certificates, ...params } = registration

    let certificates =
      digital_certificates?.map((cer) => {
        let { registration_cert, ...rest } = cer
        rest.type = registration_cert?.type

        return rest
      }) || []

    certificates.push(...selectedCertificates)

    params = { ...params, certificates }

    dispatch(setAppSpinning(true))
    var response
    try {
      response = await registrationApi.createRegistration(params)
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }

    if (response?.registration) {
      ToastHelper.showSuccess('Tạo tờ khai thành công')

      if (systemType?.invoice) navigate('/hoa-don-dau-ra/to-khai')
      if (systemType?.ticket) navigate('/hoa-don-dau-ra/tem-ve/to-khai')
    }
  }

  async function handleCreateAndSignRegistration() {
    let { digital_certificates, ...params } = registration
    let certificates =
      digital_certificates?.map((cer) => {
        let { registration_cert, ...rest } = cer
        rest.type = registration_cert?.type

        return rest
      }) || []

    certificates.push(...selectedCertificates)

    params = { ...params, certificates }

    dispatch(setAppSpinning(true))
    var response
    try {
      response = await registrationApi.createRegistration(params)
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }

    if (response?.registration) {
      const _registrationId = response.registration.registrationId

      let activeSigningMethod = company?.activeSigningMethod
      if (activeSigningMethod == 'HSM') {
        await hsmSign(_registrationId)
      } else {
        const { url } = await toolApi.genToolUrl({
          param2: 'toKhai100',
          param3: _registrationId,
        })
        if (url) {
          customProtocolCheck(
            url,
            () => dispatch(setAppNotInstalledToolNotiModalShow(true)),
            () => {
              Utils.openInCurrentTab(url)
            },
            2500,
          )

          dispatch(setAppSpinning(true))
          wsHelperInstance.onExecutingSignRegistration(_registrationId)
          Global.gExecutingSignRegistrationTimeOut = setTimeout(() => {
            dispatch(setAppSpinning(false))
          }, MAX_WAIT_SIGN_INVOICE)
        }
      }
    }
  }

  async function handleUpdateRegistration() {
    let { digital_certificates, ...params } = registration
    let certificates =
      digital_certificates?.map((cer) => {
        let { registration_cert, ...rest } = cer
        rest.type = registration_cert?.type

        return rest
      }) || []

    certificates.push(...selectedCertificates)

    params = { ...params, certificates }
    dispatch(setAppSpinning(true))
    var response
    try {
      response = await registrationApi.updateRegistration(
        registrationId,
        params,
      )
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }

    if (response?.registration) {
      ToastHelper.showSuccess('Cập nhật tờ khai thành công')

      if (systemType?.invoice) navigate('/hoa-don-dau-ra/to-khai')
      if (systemType?.ticket) navigate('/hoa-don-dau-ra/tem-ve/to-khai')
    }
  }

  async function handleUpdateAndSignRegistration() {
    let { digital_certificates, ...params } = registration

    let certificates =
      digital_certificates?.map((cer) => {
        let { registration_cert, ...rest } = cer
        rest.type = registration_cert?.type

        return rest
      }) || []
    certificates.push(...selectedCertificates)

    params = { ...params, certificates }

    dispatch(setAppSpinning(true))
    var response
    try {
      response = await registrationApi.updateRegistration(
        registrationId,
        params,
      )
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }

    if (response?.registration) {
      const _registrationId = response.registration.registrationId

      let activeSigningMethod = company?.activeSigningMethod
      if (activeSigningMethod == 'HSM') {
        await hsmSign(_registrationId)
      } else {
        const { url } = await toolApi.genToolUrl({
          param2: 'toKhai100',
          param3: _registrationId,
        })
        if (url) {
          customProtocolCheck(
            url,
            () => dispatch(setAppNotInstalledToolNotiModalShow(true)),
            () => {
              Utils.openInCurrentTab(url)
            },
            2500,
          )

          dispatch(setAppSpinning(true))
          wsHelperInstance.onExecutingSignRegistration(registrationId)
          Global.gExecutingSignRegistrationTimeOut = setTimeout(() => {
            dispatch(setAppSpinning(false))
          }, MAX_WAIT_SIGN_REGISTRATION)
        }
      }
    }
  }

  function changeRegistrationProperty(prop, value) {
    setRegistration((old) => ({
      ...old,
      [prop]: value,
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    }))
  }

  function changeCertType(certsName, index, value) {
    if (certsName == 'selectedCertificates') {
      setSelectedCertificates((old) =>
        old.map((item, i) => (i == index ? { ...item, type: value } : item)),
      )
    } else if (certsName == 'digital_certificates') {
      setRegistration((old) => ({
        ...old,
        digital_certificates: old.digital_certificates.map((item, i) =>
          i == index
            ? {
                ...item,
                registration_cert: { ...item.registration_cert, type: value },
              }
            : item,
        ),
      }))
    }
  }

  async function handleAddDigitalCertificateHSM(values) {
    dispatch(setAppSpinning(true))
    try {
      let res = await registrationApi.getCertificateDetailHSM({
        hsmAgreementUUID: values.agreementUUID,
      })
      dispatch(setAppSpinning(false))
      if (res?.result == 'success') {
        res = res.data

        dispatch(setSelectedDigitalCertificate(res))
        setShowModalConnectConfig(false)
      }
    } catch (e) {
      dispatch(setAppSpinning(false))
      throw new Error(e.message)
    }
  }

  async function handleAddDigitalCertificateManual(values) {
    dispatch(setSelectedDigitalCertificate(values))
    setShowModalSignatureInfo(false)
  }

  function handleShowModalChooseSignMethod() {
    setShowModalChooseSignMethod(true)
  }
  //#endregion

  //#region Effects
  useEffect(() => {
    if (registrationId) {
      if (!gotDetailRegistration.current)
        (async function () {
          if (!canEdit) {
            ToastHelper.showError('Bạn không có quyền sửa tờ khai')
            gotDetailRegistration.current = true
            return
          }

          dispatch(setAppSpinning(true))
          var res
          try {
            res = await registrationApi.getDetail(registrationId)
          } catch (e) {
            console.log(e)
          } finally {
            dispatch(setAppSpinning(false))
            gotDetailRegistration.current = true
          }

          if (res?.registration) {
            let { ...rest } = res.registration
            setRegistration(rest)
          }
        })()
    } else {
      if (!Utils.isObjectEmpty(company))
        (async function () {
          if (!canAddNew) {
            ToastHelper.showError('Bạn không có quyền thêm tờ khai')
            gotDetailRegistration.current = true
            return
          }

          dispatch(setAppSpinning(true))
          try {
            let res = await registrationApi.getPrototype()
            if (!res.metadata) return
            let {
              type,
              no,
              formPosTaxAuthCode,
              formWithTaxAuthCode,
              formWithoutTaxAuthCode,
              transNoTacDirect,
              transTacDiaBanKK,
              transTacUBND,
              transferMethodDetail,
              transferMethodSummary,
              digital_certificates,
              ...rest
            } = res.metadata

            const data = {
              ...initialRegistration,
              date: dayjs(new Date()).format('YYYY-MM-DD hh:mm:ss'),
              companyName: company.companyName,
              companyTaxCode: company.taxCode,
              taxAuthorityName: company.taxAuthority?.name,
              companyContactPerson:
                company?.contactTaxInfo?.fullname || company.contactPersonName,
              companyAddress:
                company?.contactTaxInfo?.address ||
                company.businessPermitAddress,
              companyEmail:
                company?.contactTaxInfo?.email || company.contactPersonEmail,
              companyPhoneNumber:
                company?.contactTaxInfo?.phone || company.contactPersonPhone,

              type: type ?? initialRegistration.type,
              no: no ?? initialRegistration.no,
              formPosTaxAuthCode:
                formPosTaxAuthCode == undefined ? 0 : formPosTaxAuthCode,
              formWithTaxAuthCode:
                !formWithTaxAuthCode &&
                !formPosTaxAuthCode &&
                !formWithoutTaxAuthCode
                  ? 1
                  : formWithTaxAuthCode,
              formWithoutTaxAuthCode:
                formWithoutTaxAuthCode == undefined
                  ? 0
                  : formWithoutTaxAuthCode,
              transNoTacDirect:
                transNoTacDirect == undefined ? 0 : transNoTacDirect,
              transNoTacIndirect: 1,
              transTacDiaBanKK:
                transTacDiaBanKK == undefined ? 0 : transTacDiaBanKK,
              transTacUBND: transTacUBND == undefined ? 0 : transTacUBND,
              transferMethodDetail:
                !formWithTaxAuthCode &&
                !formPosTaxAuthCode &&
                !formWithoutTaxAuthCode
                  ? 1
                  : transferMethodDetail,
              transferMethodSummary:
                transferMethodSummary == undefined ? 0 : transferMethodSummary,
              digital_certificates,
              ...rest,
            }
            setRegistration(data)
          } catch (e) {
            console.log(e)
          } finally {
            dispatch(setAppSpinning(false))
            gotDetailRegistration.current = true
          }
        })()
    }
  }, [registrationId, company, canEdit, dispatch, canAddNew])

  useEffect(() => {
    if (selectedDigitalCertificate) {
      let existed = selectedCertificates
        .concat(registration?.digital_certificates ?? [])
        .find((s) =>
          Utils.shallowEqualCertificate(s, selectedDigitalCertificate),
        )

      if (existed) return
      setSelectedCertificates((old) => [
        ...old,
        { ...selectedDigitalCertificate, type: 1 },
      ])
    }
  }, [
    registration?.digital_certificates,
    selectedCertificates,
    selectedDigitalCertificate,
  ])

  useEffect(() => {
    return () => dispatch(setSelectedDigitalCertificate(undefined))
  }, [dispatch])
  //#endregion

  const { data: banners, isLoading } = useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['banner'],
    queryFn: ({ signal }) =>
      axiosClient.get('/notification-marquee/show', {
        signal,
      }),
  })

  if (isLoading) {
    return null
  }

  return (
    <div className="CreateRegistration">
      <div className="registration-container mb-5">
        <div className="registration-paper mx-auto">
          <div className="d-flex flex-column justify-content-center">
            <div className="text-center pt-5">
              <div
                className="text-uppercase "
                style={{ fontSize: '20px', fontWeight: '400' }}
              >
                Tờ khai
              </div>

              <div className="" style={{ fontSize: '16px', fontWeight: '400' }}>
                Đăng ký/Thay đổi thông tin sử dụng hóa đơn điện tử
              </div>

              <div
                className="d-flex justify-content-center align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="form-check mr-5">
                  <Radio.Group
                    value={registration?.type}
                    onChange={(e) =>
                      changeRegistrationProperty('type', e.target.value)
                    }
                    options={[
                      {
                        label: 'Đăng ký mới',
                        value: 1,
                      },
                      {
                        label: 'Thay đổi thông tin',
                        value: 2,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">Số quyết định:</div>
            <div>
              <input
                type="text"
                className="form-control input-dot"
                readOnly={screenAction == 'xem'}
                value={registration?.no}
                onChange={(e) =>
                  changeRegistrationProperty('no', e.target.value)
                }
                required
              />
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">Ngày lập:</div>
            <div>
              <input
                type="text"
                className="form-control input-dot"
                value={dayjs().format('L')}
                disabled
              />
              {/* <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={
                        <input
                          type="text"
                          className="form-control input-dot input-date"
                        />
                      }
                      // showIcon={true}
                      todayButton={<button>Hôm nay</button>}
                      readOnly={screenAction == 'xem'}
                      selected={new Date(registration?.date) || new Date()}
                      onChange={(date) => {
                        changeRegistrationProperty(
                          'date',
                          dayjs(
                            date,
                            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Indochina Time)',
                          ).format('YYYY-MM-DD HH:mm:ss'),
                        )
                      }}
                    /> */}
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">Tên người nộp thuế:</div>
            <div>{registration?.companyName}</div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">Mã số thuế:</div>
            <div className="d-flex flex-row align-items-center tax-code">
              <div className="d-flex flex-row align-items-center tax-code-input mr-2">
                {registration?.companyTaxCode
                  .split('-')
                  .at(0)
                  .split('')
                  .map((num, index) => (
                    <div key={index} className="tax-code-number">
                      {num}
                    </div>
                  ))}
              </div>
              <div className="d-flex flex-row align-items-center tax-code-input">
                {(registration?.companyTaxCode.split('-').at(1) || '   ')
                  .split('')
                  .map((num, index) => (
                    <div key={index} className="tax-code-number">
                      {num}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">Cơ quan thuế quản lý: </div>
            <div className="flex-grow-1">
              <input
                defaultValue={registration?.taxAuthorityName}
                type="text"
                className="form-control input-dot"
                disabled
              />
            </div>
          </div>

          <div
            className="d-flex flex-row align-items-center p-3"
            style={{
              fontSize: '13.5px',
              fontStyle: 'italic',
              backgroundColor: '#eaff0e63',
            }}
          >
            <div className="mr-2">
              <i
                className="fa-solid fa-lightbulb-on fa-lg"
                style={{ color: '#efd358' }}
              ></i>
            </div>
            <div className="remind">
              <p className="mb-0 remind-subtitle">
                Sau khi tờ khai được <b>chấp nhận</b>, cơ quan thuế sẽ{' '}
                <b>gửi các thông báo quan trọng</b> liên quan đến hóa đơn điện
                tử tới email/số điện thoại/địa chỉ này.
              </p>
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">
              Người liên hệ:
              <span className="text-danger">*</span>
            </div>
            <div className="" style={{ flexBasis: 'auto' }}>
              <input
                type="text"
                className="form-control input-dot"
                style={{ flex: 'auto' }}
                value={registration?.companyContactPerson}
                readOnly={screenAction == 'xem'}
                onChange={(e) =>
                  changeRegistrationProperty(
                    'companyContactPerson',
                    e.target.value,
                  )
                }
              />
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">
              Địa chỉ liên hệ:
              <span className="text-danger">*</span>
            </div>
            <div className="flex-grow-1">
              <input
                type="text"
                className="form-control input-dot"
                value={registration?.companyAddress}
                readOnly={screenAction == 'xem'}
                onChange={(e) =>
                  changeRegistrationProperty('companyAddress', e.target.value)
                }
              />
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">
              Thư điện tử:
              <span className="text-danger">*</span>
            </div>
            <div className="" style={{ flexBasis: 'auto' }}>
              <input
                type="email"
                className="form-control input-dot"
                readOnly={screenAction == 'xem'}
                value={registration?.companyEmail}
                onChange={(e) =>
                  changeRegistrationProperty('companyEmail', e.target.value)
                }
              />
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            <div className="registration-label">
              Điện thoại liên hệ:
              <span className="text-danger">*</span>
            </div>
            <div className="" style={{ flexBasis: 'auto' }}>
              <input
                type="text"
                className="form-control input-dot"
                readOnly={screenAction == 'xem'}
                value={registration?.companyPhoneNumber}
                onChange={(e) =>
                  changeRegistrationProperty(
                    'companyPhoneNumber',
                    e.target.value.trim(),
                  )
                }
              />
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            Theo nghị định số 123/2020/NĐ-CP ngày 19 tháng 10 năm 2020 của chính
            phủ, chúng tôi/tôi thuộc đối tượng sử dụng hóa đơn điện tử. Chúng
            tôi/tôi đăng ký/thay đổi thông tin đã đăng ký với cơ quan thuế về
            việc sử dụng hóa đơn điện tử như sau:
          </div>

          <div>
            <div
              className="d-flex align-items-end "
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              1. Hình thức hóa đơn:
            </div>
            <div>
              <div className="form-check">
                <Checkbox
                  name="hasTaxAuthCode"
                  checked={registration?.formWithTaxAuthCode}
                  onChange={(e) => {
                    let checkValue = e.target.checked ? 1 : 0
                    changeRegistrationProperty(
                      'formWithTaxAuthCode',
                      checkValue,
                    )
                    if (
                      !checkValue &&
                      !registration?.formPosTaxAuthCode &&
                      registration?.transTacDiaBanKK
                    ) {
                      changeRegistrationProperty('transTacDiaBanKK', checkValue)
                    }
                    if (
                      !checkValue &&
                      !registration?.formPosTaxAuthCode &&
                      registration?.transTacUBND
                    ) {
                      changeRegistrationProperty('transTacUBND', checkValue)
                    }
                    if (checkValue && !registration?.transferMethodDetail) {
                      changeRegistrationProperty(
                        'transferMethodDetail',
                        checkValue,
                      )
                    }
                  }}
                >
                  Có mã của cơ quan thuế
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  name="posHasTaxAuthCode"
                  checked={registration?.formPosTaxAuthCode}
                  onChange={(e) => {
                    let checkValue = e.target.checked ? 1 : 0
                    changeRegistrationProperty('formPosTaxAuthCode', checkValue)
                    if (
                      !checkValue &&
                      !registration?.formWithTaxAuthCode &&
                      registration?.transTacDiaBanKK
                    ) {
                      changeRegistrationProperty('transTacDiaBanKK', checkValue)
                    }
                    if (
                      !checkValue &&
                      !registration?.formWithTaxAuthCode &&
                      registration?.transTacUBND
                    ) {
                      changeRegistrationProperty('transTacUBND', checkValue)
                    }
                  }}
                >
                  Có mã của cơ quan thuế (Khởi tạo từ máy tính tiền)
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  name="hasNotTaxAuthCode"
                  checked={registration?.formWithoutTaxAuthCode}
                  onChange={(e) => {
                    let checkValue = e.target.checked ? 1 : 0
                    changeRegistrationProperty(
                      'formWithoutTaxAuthCode',
                      checkValue,
                    )
                    if (!checkValue && registration?.transNoTacIndirect) {
                      changeRegistrationProperty(
                        'transNoTacIndirect',
                        checkValue,
                      )
                    }
                    if (!checkValue && registration?.transferMethodSummary) {
                      changeRegistrationProperty(
                        'transferMethodSummary',
                        checkValue,
                      )
                    }
                    if (checkValue && !registration?.transNoTacIndirect) {
                      changeRegistrationProperty(
                        'transNoTacIndirect',
                        checkValue,
                      )
                    }
                  }}
                >
                  Không có mã của cơ quan thuế
                </Checkbox>
              </div>
            </div>
          </div>

          <div className="taxpayer">
            <div
              className="d-flex align-items-end "
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              2. Hình thức gửi dữ liệu hóa đơn điện tử:
            </div>
            <div
              className="d-flex flex-row align-items-center p-3"
              style={{
                fontSize: '13.5px',
                fontStyle: 'italic',
                backgroundColor: '#eaff0e63',
              }}
            >
              <div className="mr-2">
                <i
                  className="fa-solid fa-lightbulb-on fa-lg"
                  style={{ color: '#efd358' }}
                ></i>
              </div>
              <div className="remind">
                <p className="mb-0 remind-subtitle">
                  Vui lòng bỏ qua nếu bạn không thuộc đối tượng nào trong các
                  đối tượng dưới đây.
                </p>
              </div>
            </div>
            <div>
              <div className="form-check">
                <Checkbox
                  disabled={
                    !registration?.formWithTaxAuthCode &&
                    !registration?.formPosTaxAuthCode
                  }
                  // readOnly={screenAction == 'xem'}
                  checked={
                    registration?.transTacDiaBanKK || registration?.transTacUBND
                  }
                  onChange={(e) => {
                    let checkValue = e.target.checked ? 1 : 0
                    changeRegistrationProperty('transTacDiaBanKK', checkValue)
                    changeRegistrationProperty('transTacUBND', checkValue)
                  }}
                >
                  a. Trường hợp sử dụng hóa đơn điện tử có mã không phải trả
                  tiền dịch vụ theo khoản 1 Điều 14 của Nghị định:
                </Checkbox>
                <div>
                  <div className="form-check">
                    <Checkbox
                      disabled={
                        (!registration?.formWithTaxAuthCode &&
                          !registration?.formPosTaxAuthCode) ||
                        (!registration?.transTacDiaBanKK &&
                          !registration?.transTacUBND)
                      }
                      // readOnly={screenAction == 'xem'}
                      checked={registration?.transTacDiaBanKK}
                      onChange={(e) =>
                        changeRegistrationProperty(
                          'transTacDiaBanKK',
                          e.target.checked ? 1 : 0,
                        )
                      }
                    >
                      Doanh nghiệp nhỏ và vừa, hợp tác xã, hộ, cá nhân kinh
                      doanh tại địa bàn có điều kiện kinh tế xã hội khó khăn,
                      địa bàn có điều kiện kinh tế xã hội đặc biệt khó khăn.
                    </Checkbox>
                  </div>
                  <div className="form-check">
                    <Checkbox
                      disabled={
                        // screenAction == 'xem'
                        (!registration?.formWithTaxAuthCode &&
                          !registration?.formPosTaxAuthCode) ||
                        (!registration?.transTacDiaBanKK &&
                          !registration?.transTacUBND)
                      }
                      readOnly={screenAction == 'xem'}
                      checked={registration?.transTacUBND}
                      onChange={(e) =>
                        changeRegistrationProperty(
                          'transTacUBND',
                          e.target.checked ? 1 : 0,
                        )
                      }
                    >
                      Doanh nghiệp nhỏ và vừa khác theo đề nghị của Ủy ban nhân
                      dân tỉnh, thành phố trực thuộc trung ương gửi Bộ Tài chính
                      trừ doanh nghiệp hoạt động tại các khu kinh tế, khu công
                      nghiệp, khu công nghệ cao.
                    </Checkbox>
                  </div>
                </div>
              </div>
              <div className="form-check">
                <Checkbox
                  disabled={!registration?.formWithoutTaxAuthCode}
                  // readOnly={screenAction == 'xem'}
                  checked={
                    registration?.transNoTacDirect ||
                    registration?.transNoTacIndirect
                  }
                  onChange={(e) => {
                    changeRegistrationProperty(
                      'transNoTacIndirect',
                      e.target.checked ? 1 : 0,
                    )
                  }}
                >
                  b. Trường hợp sử dụng hóa đơn điện tử không có mã của cơ quan
                  thuế:
                </Checkbox>
                <div>
                  <div className="form-check">
                    <Checkbox
                      disabled
                      // readOnly={screenAction == 'xem'}
                      checked={false}
                    >
                      Chuyển dữ liệu hóa đơn điện tử trực tiếp đến cơ quan thuế
                      (điểm b1, khoản 3, Điều 22 của Nghị định).
                    </Checkbox>
                  </div>
                  <div className="form-check">
                    <Checkbox
                      disabled={
                        !registration?.formWithoutTaxAuthCode ||
                        (!registration?.transNoTacDirect &&
                          !registration?.transNoTacIndirect)
                      }
                      // readOnly={screenAction == 'xem'}
                      checked={registration?.transNoTacIndirect}
                      onChange={(e) => {
                        changeRegistrationProperty(
                          'transNoTacIndirect',
                          e.target.checked ? 1 : 0,
                        )
                      }}
                    >
                      Thông qua tổ chức cung cấp dịch vụ hóa đơn điện tử (điểm
                      b2, khoản 3, Điều 22 của Nghị định).
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              className="d-flex align-items-end "
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              3. Phương thức chuyển dữ liệu hóa đơn điện tử:
            </div>
            <div>
              <div className="form-check">
                <Checkbox
                  disabled={
                    !registration?.formWithoutTaxAuthCode ||
                    registration?.formWithTaxAuthCode
                  }
                  checked={registration?.transferMethodDetail}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'transferMethodDetail',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Chuyển đầy đủ nội dung từng hóa đơn.
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  disabled={!registration?.formWithoutTaxAuthCode}
                  checked={registration?.transferMethodSummary}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'transferMethodSummary',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Chuyển theo bảng tổng hợp dữ liệu hóa đơn điện tử (điểm a1,
                  khoản 3, Điều 22 của Nghị định).
                </Checkbox>
              </div>
            </div>
          </div>

          <div>
            <div
              className="d-flex align-items-end "
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              4. Loại hóa đơn sử dụng
            </div>
            <div>
              <div className="form-check">
                <Checkbox
                  name="usingVat"
                  checked={registration?.usingVat}
                  // readOnly={screenAction == 'xem'}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'usingVat',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Hóa đơn GTGT
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  name="usingSale"
                  checked={registration?.usingSale}
                  // readOnly={screenAction == 'xem'}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'usingSale',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Hóa đơn bán hàng
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  name="usingSalePublicProperty"
                  checked={registration?.usingSalePublicProperty}
                  // readOnly={screenAction == 'xem'}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'usingSalePublicProperty',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Hóa đơn bán tài sản công
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  name="usingSaleNationalReserve"
                  checked={registration?.usingSaleNationalReserve}
                  // readOnly={screenAction == 'xem'}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'usingSaleNationalReserve',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Hóa đơn bán hàng dự trữ quốc gia
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  name="usingOther"
                  checked={registration?.usingOther}
                  // readOnly={screenAction == 'xem'}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'usingOther',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Các loại hóa đơn khác
                </Checkbox>
              </div>
              <div className="form-check">
                <Checkbox
                  name="usingBill"
                  checked={registration?.usingBill}
                  // readOnly={screenAction == 'xem'}
                  onChange={(e) =>
                    changeRegistrationProperty(
                      'usingBill',
                      e.target.checked ? 1 : 0,
                    )
                  }
                >
                  Các chứng từ được in, phát hành, sử dụng và quản lý như hóa
                  đơn
                </Checkbox>
              </div>
            </div>
          </div>

          <div>
            <div
              className="d-flex align-items-end "
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              5. Danh sách chứng thư số sử dụng
            </div>
            <div className="digital-certificate">
              <table className="table table-bordered">
                <thead>
                  <tr role="row">
                    <th scope="col" rowSpan="2" colSpan="1">
                      STT
                    </th>
                    <th scope="col" rowSpan="2" colSpan="1">
                      Tên tổ chức cơ quan chứng thực/cấp/công nhận chữ ký số,
                      chữ ký điện tử
                    </th>
                    <th scope="col" rowSpan="2" colSpan="1">
                      Số sê-ri chứng thư
                    </th>
                    <th scope="col" rowSpan="1" colSpan="2">
                      Thời hạn sử dụng chứng thư số
                    </th>
                    <th scope="col" rowSpan="2" colSpan="1">
                      Hình thức đăng ký (Thêm mới, gia hạn, ngừng sử dụng)
                    </th>
                    <th
                      scope="col"
                      rowSpan="2"
                      colSpan="1"
                      style={{ width: '70px' }}
                    >
                      {selectedCertificates.length == 0 &&
                        (!registration?.digital_certificates ||
                          registration?.digital_certificates?.length == 0) && (
                          <div>
                            <i
                              className="fal fa-plus mr-4 cursor-pointer mx-auto"
                              onClick={
                                screenAction != 'xem' &&
                                handleShowModalChooseSignMethod
                              }
                            ></i>
                          </div>
                        )}
                    </th>
                  </tr>
                  <tr role="row">
                    <th scope="col" rowSpan="1" colSpan="1">
                      Từ ngày
                    </th>
                    <th scope="col" rowSpan="1" colSpan="1">
                      Đến ngày
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {registration?.digital_certificates?.map(
                    (certificate, index, arr) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{certificate.issuedBy}</td>
                        <td>{certificate.idValue}</td>
                        <td>
                          {dayjs(certificate.issuedDate).format('DD/MM/YYYY')}
                        </td>
                        <td>
                          {dayjs(certificate.expireDate).format('DD/MM/YYYY')}
                        </td>
                        <td>
                          <CustomSelect
                            value={cerTypeOptions.find(
                              ({ value }) =>
                                value == certificate.registration_cert?.type,
                            )}
                            onChange={({ value }) =>
                              changeCertType(
                                'digital_certificates',
                                index,
                                value,
                              )
                            }
                            options={cerTypeOptions}
                          />
                        </td>
                        <td>
                          <div>
                            {!selectedCertificates?.length &&
                              index === arr.length - 1 && (
                                <i
                                  className="fal fa-plus mr-4 cursor-pointer"
                                  onClick={
                                    screenAction != 'xem' &&
                                    handleShowModalChooseSignMethod
                                  }
                                ></i>
                              )}
                            <i
                              className="far fa-trash-alt cursor-pointer"
                              onClick={() => {
                                setRegistration((old) => ({
                                  ...old,
                                  digital_certificates:
                                    old.digital_certificates.filter(
                                      (cer) =>
                                        cer.idValue != certificate.idValue,
                                    ),
                                }))
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
                  {selectedCertificates.map((certificate, index, arr) => (
                    <tr key={index}>
                      <th scope="row">
                        {(registration?.digital_certificates?.length || 0) +
                          index +
                          1}
                      </th>
                      <td>{certificate.issuedBy}</td>
                      <td>{certificate.idValue}</td>
                      <td>
                        {dayjs(certificate.issuedDate).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {dayjs(certificate.expireDate).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <CustomSelect
                          value={cerTypeOptions.find(
                            ({ value }) => value == certificate?.type,
                          )}
                          onChange={({ value }) =>
                            changeCertType('selectedCertificates', index, value)
                          }
                          options={cerTypeOptions}
                        />
                      </td>
                      <td>
                        <div>
                          {index === arr.length - 1 && (
                            <i
                              className="fal fa-plus mr-4 cursor-pointer"
                              onClick={
                                screenAction != 'xem' &&
                                handleShowModalChooseSignMethod
                              }
                            ></i>
                          )}
                          <i
                            className="far fa-trash-alt cursor-pointer"
                            onClick={() =>
                              setSelectedCertificates((old) =>
                                old.filter(
                                  (cer) => cer.idValue != certificate.idValue,
                                ),
                              )
                            }
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className="d-flex align-items-end"
            style={{ minHeight: '44px', paddingBottom: '8px' }}
          >
            Chúng tôi cam kết hoàn toàn chịu trách nhiệm trước pháp luật về tính
            chính xác, trung thực của nội dung nêu trên và thực hiện theo đúng
            quy định của pháp luật.
          </div>

          <div className="d-flex flex-row justify-content-between align-items-start">
            <div>
              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="registration-label">Nơi lập:</div>
                <div>
                  <input
                    type="text"
                    className="form-control input-dot"
                    value={registration?.locationName}
                    readOnly={screenAction == 'xem'}
                    onChange={(e) =>
                      changeRegistrationProperty('locationName', e.target.value)
                    }
                  />
                </div>
              </div>
              {/* <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="registration-label">Ngày có hiệu lực:</div>
              <div>
                <input defaultValue="29/03/2023" type="text" className="form-control input-dot" />
              </div>
            </div> */}
            </div>

            <div className="text-center">
              <div>{`${registration?.locationName}, ngày ${dayjs(
                registration?.date,
              ).format('DD/MM/YYYY')}`}</div>
              <div>
                <div className="">NGƯỜI NỘP THUẾ</div>
                <div className="mb-4">
                  (Chữ ký số, chữ ký điện tử của người nộp thuế)
                </div>
                <button
                  className="btn btn-light d-inline-flex align-items-center"
                  style={{ color: '#041847', backgroundColor: '#e4e6eb' }}
                  onClick={
                    screenAction == 'tao'
                      ? handleCreateAndSignRegistration
                      : handleUpdateAndSignRegistration
                  }
                >
                  <i
                    className="fal fa-file-contract"
                    style={{ color: '#041847' }}
                  ></i>
                  Ký điện tử
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Flex
        justify={'space-between'}
        align="center"
        className="registration-footer px-6 py-2"
        style={banners.data?.length > 0 ? { bottom: '30px' } : 0}
      >
        <CustomAntButton
          text="Quay lại"
          iconCn="fa-regular fa-arrow-left"
          color="#e4e6ef"
          customStyle={{
            color: '#3f4254',
          }}
          antProps={{
            type: 'primary',
            onClick: () => navigate('/hoa-don-dau-ra/to-khai'),
          }}
        />
        <CustomAntButton
          text="In"
          iconCn="fa-regular fa-print"
          color="#e4e6ef"
          customStyle={{
            color: '#3f4254',
            minWidth: 'fit-content',
          }}
          isLoading={isPrinting}
          antProps={{
            type: 'primary',
            onClick: printMutation,
          }}
        />

        <Space>
          <CustomAntButton
            text="Lưu"
            iconCn="fa-regular fa-check"
            customStyle={{
              minWidth: 'fit-content',
            }}
            antProps={{
              type: 'primary',
              ghost: true,
              disabled: screenAction == 'xem',
              onClick:
                screenAction == 'sua'
                  ? handleUpdateRegistration
                  : handleCreateRegistration,
            }}
          />
          <CustomAntButton
            text="Lưu và ký"
            iconCn="fa-regular fa-pen-to-square"
            antProps={{
              type: 'primary',
              onClick:
                screenAction == 'tao'
                  ? handleCreateAndSignRegistration
                  : handleUpdateAndSignRegistration,
            }}
          />
        </Space>
      </Flex>

      <ModalConnectConfig
        title={'Nhập thông tin chữ ký số HSM'}
        method={'HSM'}
        requirePassCode={false}
        open={showModalConnectConfig}
        onOpenChange={(state) => setShowModalConnectConfig(state)}
        onApply={handleAddDigitalCertificateHSM}
      />

      <ModalSignatureInfo
        open={showModalSignatureInfo}
        onOpenChange={(state) => setShowModalSignatureInfo(state)}
        onApply={handleAddDigitalCertificateManual}
      />

      <ModalChooseSignMethod
        show={showModalChooseSignMethod}
        setShow={setShowModalChooseSignMethod}
        header={'Chọn phương thức thêm chứng thư số'}
        content={'Vui lòng chọn phương thức thêm chứng thư số'}
        handleChooseUSBToken={() => {
          setShowModalChooseSignMethod(false)
          handlePressedAddDigitalCertificate()
        }}
        handleChooseHSM={() => {
          setShowModalChooseSignMethod(true)

          setShowModalChooseSignMethod(false)
          setShowModalConnectConfig(true)
        }}
        handleChooseManually={() => {
          setShowModalSignatureInfo(true)
          setShowModalChooseSignMethod(false)
        }}
      />
    </div>
  )
}
