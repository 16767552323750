import KT01BaseLayout from 'general/components/BaseLayout/KT01BaseLayout'
import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import { useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import { useMemo } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import Cart from './Cart'
import Category from './Category'
import Dashboard from './Dashboard'
import HandleInvoice from './HandleInvoice'
import HandleTicket from './HandleTicket'
import Invoice from './Invoice'
import SuccessFulOrder from './Payment/components/succesFulOrder/'
import Payment from './Payment/index.js'
import Process from './Process'
import Registration from './Registration'
import Report from './Report'
import System from './System'
import TaxMessage from './TaxMessage'
import TemplateInvoice from './TemplateInvoice'
import TemplateTicket from './TemplateTicket'
import Ticket from './Ticket'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import Jobposition from './Jobposition'
import UserManagement from './System/screens/UserManagement'
import Department from './Department'
import EnterpriseProfile from './System/screens/EnterpriseProfile'
import TaxDeductionDocuments from './TaxDeductionDocuments'
import Taxpayer from './Taxpayer'
import TaxDeduction from './TaxDeduction'
import UserNoFunction from 'general/components/UserNoFunction'
const EinvoiceContainer = styled.section({
  minHeight: '100%',
  maxHeight: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
})

function OutPutEInvoice() {
  // MARK: --- Props ---
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const systemType = useAppSelector((s) => s.auth?.activeInvoice)
  const prefixPath = useMemo(
    () =>
      systemType?.invoice
        ? ''
        : systemType?.ticket
          ? 'tem-ve/'
          : systemType?.personalIncomeTax
            ? 'chung-tu-tncn/'
            : '',
    [systemType?.invoice, systemType?.ticket, systemType?.personalIncomeTax],
  )
  const user = useAppSelector((state) => state?.auth?.currentAccount)
  return (
    <KT01BaseLayout>
      <EinvoiceContainer
        id="einvoice-container" /* container-fluid */
        className={`${
          pathname.includes('/hoa-don/them-moi') ||
          pathname.includes('/xu-ly-hoa-don/dieu-chinh') ||
          pathname.includes('/xu-ly-hoa-don/thay-the')
            ? 'px-0'
            : ''
        }`}
      >
        <Routes>
          <Route path={'/'} element={<Navigate to={'ban-lam-viec'} />} />
          <Route path={'ban-lam-viec/*'} element={<Dashboard />} />
          <Route
            path={prefixPath + 'lich-su-truyen-nhan/*'}
            element={<TaxMessage />}
          />
          <Route path={prefixPath + 'to-khai/*'} element={<Registration />} />
          <Route path={prefixPath + 'hoa-don/*'} element={<Invoice />} />
          <Route path={prefixPath + 've-dien-tu/*'} element={<Ticket />} />

          <Route path={prefixPath + 'bao-cao/*'} element={<Report />} />
          <Route
            path={'khau-tru-thue-thu-nhap-ca-nhan/*'}
            element={<TaxDeduction />}
          />
          <Route
            path={prefixPath + 'mau-hoa-don/*'}
            element={<TemplateInvoice />}
          />
          <Route path={prefixPath + 'danh-muc/*'} element={<Category />} />
          <Route path={prefixPath + 'gio-hang/*'} element={<Cart />} />
          <Route path={prefixPath + 'thanh-toan/*'} element={<Payment />} />
          <Route
            path={prefixPath + 'dat-hang-thanh-cong/*'}
            element={<SuccessFulOrder />}
          />
          <Route
            path={prefixPath + 'xu-ly-hoa-don/*'}
            element={<HandleInvoice />}
          />

          <Route
            path={prefixPath + 'quy-trinh-nghiep-vu/*'}
            element={<Process />}
          />

          <Route
            path={prefixPath + 'mau-tem-ve/*'}
            element={<TemplateTicket />}
          />

          <Route path={prefixPath + 'xu-ly-ve/*'} element={<HandleTicket />} />

          <Route path={prefixPath + 'he-thong/*'} element={<System />} />

          <Route
            path="*"
            element={
              <Container fluid className="h-100 d-flex">
                <Row className=" justify-content-center align-items-center flex-grow-1">
                  <Empty
                    text={t('PageNotFound')}
                    buttonText={t('Refresh')}
                    visible={false}
                    imageEmptyPercentWidth={30}
                    imageEmpty={AppResource.icons.icEmptyBox}
                  />
                </Row>
              </Container>
            }
          />
          <Route
            path={prefixPath + 'khau-tru-thue-thu-nhap-ca-nhan/*'}
            element={<TaxDeduction />}
          />
          <Route
            path={prefixPath + 'nguoi-nop-thue/*'}
            element={<Taxpayer />}
          />
          <Route
            path={prefixPath + 'thiet-lap/*'}
            element={<TaxDeductionDocuments />}
          />
          <Route
            path={prefixPath + 'mau-hoa-don/*'}
            element={<TemplateInvoice />}
          />
          <Route
            path={prefixPath + 'thong-tin-don-vi'}
            element={<EnterpriseProfile />}
          />
          <Route
            path={prefixPath + 'co-cau-to-chuc'}
            element={<Department />}
          />
          <Route
            path={prefixPath + 'vi-tri-cong-viec'}
            element={<Jobposition />}
          />
          <Route
            path={prefixPath + 'quan-ly-nguoi-dung'}
            element={
              <ProtectedRoute
                permission={user && user?.isAdmin}
                noPermission={UserNoFunction}
              >
                <UserManagement />
              </ProtectedRoute>
            }
          />
        </Routes>
      </EinvoiceContainer>
    </KT01BaseLayout>
  )
}

export default OutPutEInvoice
