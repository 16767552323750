import PropTypes from 'prop-types'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

DateRangePickerInput.propTypes = {
  format: PropTypes.string,
  className: PropTypes.string,
  haveTitle: PropTypes.bool,
  getDateRange: PropTypes.func,
  customRange: PropTypes.object,
  showingRange: PropTypes.string,
  initialLabel: PropTypes.string,
  initialStartDate: PropTypes.object,
  initialEndDate: PropTypes.object,
  buttonHeight: PropTypes.string,
}

DateRangePickerInput.defaultProps = {
  format: 'DD/MM/YYYY',
  className: '',
  haveTitle: true,
  getDateRange: null,
  customRange: {},
  showingRange: '',
  initialLabel: 'Tất cả',
  initialStartDate: null,
  initialEndDate: null,
  buttonHeight: '33px',
}

function DateRangePickerInput(props) {
  const {
    format,
    className,
    getDateRange,
    customRange,
    showingRange,
    initialLabel,
    initialStartDate,
    initialEndDate,
    buttonHeight,
  } = props
  const dateRangePickerInput = useRef(null)
  const [range, setRange] = useState(initialLabel)
  const { t } = useTranslation()

  function handleCallback(start, end, label) {
    let dateRange = {}
    if (label === 'Tất cả') {
      setRange('Tất cả')
      dateRange = {
        label: label,
        startDate: '',
        endDate: '',
      }
    } else {
      const startStr = moment(start._d).format(format)
      const endStr = moment(end._d).format(format)
      setRange(label === 'Hôm qua' ? 'Hôm qua' : `${startStr} - ${endStr}`)
      dateRange = {
        label: `${startStr} - ${endStr}`,
        startDate: start._d,
        endDate: end._d,
      }
    }

    if (getDateRange) {
      getDateRange(dateRange)
    }
  }

  function handleFocus() {
    dateRangePickerInput.current.ref.focus()
  }

  function handleShowCalender(event) {
    console.log('LOG:', event)
  }

  const handleApply = (event) => {
    console.log('LOG:', event)
  }

  useEffect(() => {
    if (showingRange) {
      setRange(showingRange)
    }
  }, [showingRange])

  return (
    <div
      className={`DateRangePickerInput d-flex flex-row align-items-center justify-content-between bg-white ${className}`}
      onClick={handleFocus}
      style={{ height: buttonHeight }}
    >
      <div>
        <span
          className="font-size-base fw-semibold mx-3"
          style={{ whiteSpace: 'nowrap' }}
        >
          {range}
        </span>
      </div>
      <i
        className="fas fa-chevron-down mr-3"
        style={{ color: 'hsl(0, 0%, 80%)' }}
      ></i>

      <DateRangePicker
        {...props}
        onShow={handleShowCalender}
        ref={dateRangePickerInput}
        onCallback={handleCallback}
        onApply={handleApply}
        // style={{ display: 'none' }}
        initialSettings={{
          startDate: initialStartDate ?? moment().subtract(28, 'days'),
          endDate: initialEndDate ?? moment(),
          opens: 'left',
          locale: {
            format: format,
            cancelLabel: t('Hủy'),
            applyLabel: t('Áp dụng'),
            customRangeLabel: t('Tùy chỉnh'),
            daysOfWeek: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'],
            monthNames: [
              'Tháng Một',
              'Tháng Hai',
              'Tháng Ba',
              'Tháng Tư',
              'Tháng Năm',
              'Tháng Sáu',
              'Tháng Bảy',
              'Tháng Tám',
              'Tháng Chín',
              'Tháng Mười',
              'Tháng Mười Một',
              'Tháng Mười Hai',
            ],
          },

          ranges: customRange ?? {
            'Tất cả': ['', ''],
            'Hôm qua': [
              moment().subtract(1, 'day').startOf('day'),
              moment().subtract(1, 'day').endOf('day'),
            ],
            'Tuần này': [moment().startOf('week').add(1, 'days'), moment()],
            '7 ngày gần đây': [moment().subtract(6, 'days'), moment()],
            '30 ngày gần đây': [moment().subtract(29, 'days'), moment()],
            'Tháng trước': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Tháng này': [moment().startOf('month'), moment()],
          },
        }}
      >
        <input
          style={{
            color: 'transparent',
            height: '100%',
            width: '0px',
          }}
          className="rounded p-0"
        />
      </DateRangePicker>
    </div>
  )
}

export default DateRangePickerInput
