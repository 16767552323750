import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import goodsGroupApi from 'api/goodsGroupApi'
import ModalEditGoodsGroup from 'features/Category/components/ModalEditGoodsGroup'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useFireSwal from 'hooks/useFireSwal'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { goodsGroupQueries } from './queries'

function GoodsGroupScreen() {
  // MARK --- Parmas: ---
  const { t } = useTranslation()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'goodsGroupList',
    'gFiltersGoodsGroupList',
  )
  const [selectedGoodsGroups, setSelectedGoodsGroups] = useState([])
  const [toggleClearGoodsGroups, setToggledClearGoodsGroups] = useState(true)
  const [selectedGoodsGroupItem, setSelectedGoodsGroupItem] = useState(null)
  const [showingModalEditGoodsGroup, setShowingModalEditGoodsGroup] =
    useState(false)
  const [active, setActive] = useState('ADD')
  const { fireConfirm } = useFireSwal()
  const {
    data: goodsGroups,
    isLoading: isLoadingGoodsGroup,
    isRefetching: isRefetchingGoodsGroup,
    refetch: refetchGoodsGroup,
  } = useQuery(goodsGroupQueries.listOfGoodsGroup(filters))

  const handleDeleteGoodsGroup = useCallback(
    async (goodsGroup) => {
      const result = await fireConfirm(
        t('Confirm'),
        t('DoYouWantToDeleteGoodsGroup', { name: goodsGroup.name }),
      )
      if (!result.isConfirmed) return
      const res = await goodsGroupApi.delete([goodsGroup.goodsGroupId])
      if (res.result === 'success') {
        ToastHelper.showSuccess(t('DeleteGoodsGroupSuccessful'))
        refetchGoodsGroup()
      }
    },
    [fireConfirm, refetchGoodsGroup, t],
  )

  const fullColumns = useMemo(
    () => [
      {
        name: t('GoodsGroupCode'),
        // sortable: false,
        //   width: '110px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal q-max-line-1 m-0 text-maxline-3 mr-4"
            >
              {row?.code}
            </p>
          )
        },
      },
      {
        name: t('GoodsGroupName'),
        // sortable: false,
        //   width: '250px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="q-max-line-3  font-weight-normal  m-0 text-maxline-3 mr-4"
            >
              {row?.name}
            </p>
          )
        },
      },
      {
        name: t('Status'),
        width: '150px',
        center: true,
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className={`badge badge-light-${
                row?.status === 'ACTIVE' ? 'success' : 'danger'
              } `}
            >
              {row.status === 'ACTIVE' ? 'Đang sử dụng' : 'Ngừng sử dụng'}
            </div>
          )
        },
      },
      {
        name: '',
        center: true,
        grow: 0,
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <TableAction
              titleActionText={t('Edit')}
              icon={<IconAction className="fa-light fa-edit" />}
              onClick={() => {
                handleEditGoodsGroup(row)
              }}
            />
            <TableAction
              titleActionText={t('Delete')}
              icon={<IconAction className="fa-light fa-trash" />}
              onClick={() => {
                handleDeleteGoodsGroup(row)
              }}
            />
            <TableAction
              titleActionText={t('Replicate')}
              icon={<IconAction className="fa-light fa-clone" />}
              onClick={() => {
                handleReplicateGoodsGroup(row)
              }}
            />
          </div>
        ),
      },
    ],
    [handleDeleteGoodsGroup, t],
  )

  // MARK --- Function ---

  async function handleDeleteMultiGoodsGroup() {
    const arrIdsToDelete = selectedGoodsGroups.map((item) => item.goodsGroupId)
    const result = await fireConfirm(
      t('Confirm'),
      t('DoYouWantToDeleteMultipleGoodsGroup', {
        length: arrIdsToDelete.length,
      }),
    )
    if (!result.isConfirmed) return
    const res = await goodsGroupApi.delete(arrIdsToDelete)
    if (res.result !== 'success') return false
    clearSelectedGoodsGroups()
    ToastHelper.showSuccess(t('DeleteGoodsGroupSuccessful'))
    refetchGoodsGroup()
  }

  function handleSelectedGoodsGroupChanged(state) {
    setSelectedGoodsGroups(state.selectedRows)
  }

  function clearSelectedGoodsGroups() {
    setSelectedGoodsGroups([])
    setToggledClearGoodsGroups(!toggleClearGoodsGroups)
  }

  function handleEditGoodsGroup(goodsGroup) {
    setActive('EDIT')
    setSelectedGoodsGroupItem(goodsGroup)
    setShowingModalEditGoodsGroup(true)
  }

  function handleReplicateGoodsGroup(goodsGroup) {
    setActive('REPLICATE')
    setSelectedGoodsGroupItem(goodsGroup)
    setShowingModalEditGoodsGroup(true)
  }

  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 0,
    })
  }, [debouncedSearch, setFilters])

  return (
    <ContentContainer wrapperClassName="goods-group-screen">
      <ContentContainer.Header
        titleContent={t('GoodsGroup')}
        description={`${t('Tổng')}: ${goodsGroups?.total} ${_.capitalize(
          t('GoodsGroup'),
        )}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('NewGoodsGroup')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActive('ADD')
                  setShowingModalEditGoodsGroup(true)
                },
              }}
            />
          </Space>
        }
      >
        <Space>
          {selectedGoodsGroups.length > 0 ? (
            <CustomAntButton
              iconCn="fa-regular fa-trash"
              text={Utils.cn(
                t('Delete'),
                selectedGoodsGroups.length,
                'nhóm hàng',
              )}
              antProps={{
                type: 'primary',
                danger: true,
                onClick: () => {
                  handleDeleteMultiGoodsGroup()
                },
              }}
            />
          ) : (
            <>
              <CustomAntInput
                borderRadius="sm"
                type="search"
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
              <label className="mr-2 mb-0" htmlFor="state">
                {_.capitalize(t('Status'))}
              </label>
              <KTFormSelect
                name="status"
                isCustom
                options={[{ name: t('All'), value: '' }, ...AppData.status]}
                value={filters.status}
                onChange={(newValue) => {
                  setFilters({
                    page: 0,
                    status: newValue,
                  })
                }}
              />
            </>
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={fullColumns}
          dataSource={goodsGroups?.data}
          isClickable
          isSelectable
          isLoading={isLoadingGoodsGroup || isRefetchingGoodsGroup}
          handleDoubleClickRow={handleEditGoodsGroup}
          pagination={{
            currentPage: filters.page + 1,
            rowsPerPage: filters.limit,
            total: goodsGroups?.total,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              iNewPage -= 1
              if (iNewPage < 0) {
                iNewPage = 0
              }
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 0,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows: toggleClearGoodsGroups,
            handleSelectedRows: handleSelectedGoodsGroupChanged,
            selectableRowSelected: (row) => {
              const arrId = selectedGoodsGroups.map((item) => item.goodsGroupId)
              return arrId.includes(row.goodsGroupId)
            },
          }}
        />
      </ContentContainer.Body>

      {/* Modal goodsGroup edit */}
      <ModalEditGoodsGroup
        show={showingModalEditGoodsGroup}
        onClose={(status) => {
          setShowingModalEditGoodsGroup(status)
          setSelectedGoodsGroupItem(null)
        }}
        onExistDone={() => {
          refetchGoodsGroup()
        }}
        active={active}
        goodsGroupItem={selectedGoodsGroupItem}
        onToggleClear={clearSelectedGoodsGroups}
      />
    </ContentContainer>
  )
}

export default GoodsGroupScreen
