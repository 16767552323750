import { TRANSACTION_STATUS } from 'general/constants/AppConstants'

export const renderStatus = (statusId) => {
  const result = {
    text: '',
    className: '',
  }
  switch (statusId) {
    case TRANSACTION_STATUS.NOT_CREATE_INVOICE:
      result.text = 'Chưa xuất HĐ'
      result.className = 'secondary'
      break
    case TRANSACTION_STATUS.CREATED_INVOICE:
      result.text = 'Chưa phát hành'
      result.className = 'warning'
      break
    case TRANSACTION_STATUS.RELEASED_INVOICE:
      result.text = 'Đã phát hành'
      result.className = 'success'
      break
    case TRANSACTION_STATUS.RELEASING_INVOICE:
      result.text = 'Đang phát hành'
      result.className = 'primary'
      break
    case TRANSACTION_STATUS.RELEASE_ERROR:
      result.text = 'Lỗi phát hành'
      result.className = 'danger'
      break
    case TRANSACTION_STATUS.CANCELLED:
      result.text = 'Đã hủy giao dịch'
      result.className = 'danger'
      break
  }
  return result
}
