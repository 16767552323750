import React from 'react'

import './style.scss'
import { useDropzone } from 'react-dropzone'

import { useDispatch } from 'react-redux'
import {
  setActivePanel,
  setInstanceInfo,
  setLogoInfo,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useSelector } from 'react-redux'
import { useColumnsDispatch } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'

function LogoControl(props) {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()
  const { style } = props

  const { logoPosition, logoInfo } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const setFirstInvoiceColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'firstInvoiceColumns',
      payload,
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
    },
    maxFiles: 1,
    onDrop: async (files) => {
      await new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(files[0])
        img.onload = ({ target: img }) => {
          const { width, height } = img

          let logoContainer = document.getElementById('logo-container')
          let containerWidth = logoContainer?.clientWidth
          let containerHeight = logoContainer?.clientHeight - 2

          let scalingFactor = Math.min(
            containerWidth / width,
            containerHeight / height,
          )
          let imageWidth = scalingFactor * width
          let imageHeight = scalingFactor * height
          let x = (containerWidth - width * scalingFactor) / 2
          let y = (containerHeight - height * scalingFactor) / 2

          dispatch(
            setLogoInfo({
              logoFile: files[0],
              x,
              y,
              width: imageWidth,
              height: imageHeight,
            }),
          )
          resolve()
        }
      })
    },
  })

  const handleChangeLogoPosition = (e) => {
    let containerWidth = document.getElementById(
      'lc-frame-content-detail',
    )?.offsetWidth
    let originLogoContainerWidth =
      document.getElementById('logo-container')?.offsetWidth

    dispatch(setInstanceInfo({ logoPosition: parseInt(e.target.value) }))
    setFirstInvoiceColumns([
      {
        title: '',
        dataIndex: 'col1',
        key: 'col1',
        width:
          e.target.value == 1
            ? originLogoContainerWidth
            : containerWidth - originLogoContainerWidth,
        id: e.target.value == 1 ? 'logo-container' : undefined,
      },
      {
        title: '',
        dataIndex: 'col2',
        key: 'col2',
        id: e.target.value == 0 ? 'logo-container' : undefined,
      },
    ])
    // dispatch(setLogoInfo({ x: 0, y: 0, width: 100, height: 100 }));
  }

  const handleReUploadLogo = () => {
    const input = document.getElementById('upload-logo-input')
    if (!input) return

    input.value = null
    input.click()
  }

  return (
    <div
      className="LogoControl d-flex flex-column overflow-hidden"
      style={style}
    >
      <div
        className="d-flex flex-row align-items-center mb-3"
        style={{ flex: '0 1 auto' }}
      >
        <button
          type="button"
          className="btn btn-icon mr-2"
          onClick={() => dispatch(setActivePanel('main_ctrl'))}
        >
          <i className="fas fa-arrow-left" style={{ color: '#000000' }}></i>
        </button>
        <h3 className="static-title">Logo</h3>
      </div>

      <div
        className="overflow-scroll"
        style={{ flex: '1 1 auto', fontSize: '0.95em' }}
      >
        <div
          className={`logo-dropzone w-100 justify-content-center align-items-center cursor-pointer ${
            logoInfo?.logoFile ? '' : 'bg-hover-secondary'
          } ${!logoInfo?.logoFile ? 'd-flex' : 'd-none'}`}
        >
          <div
            {...getRootProps({
              className: 'dropzone',
            })}
            className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          >
            <input {...getInputProps()} id="upload-logo-input" />
            <i
              className="fal fa-image-polaroid fa-4x mb-3"
              style={{ opacity: 0.8 }}
            ></i>
            <p className="text-danger">
              Kéo thả tệp logo vào đây hoặc Chọn từ máy tính
            </p>
          </div>
        </div>

        {logoInfo?.logoFile && (
          <div>
            <div className="d-flex flex-row mb-3">
              <div className="logo-preview p-3 d-flex justify-content-center align-items-center">
                <img
                  src={
                    logoInfo?.logoFile instanceof File
                      ? URL.createObjectURL(logoInfo?.logoFile)
                      : `${process.env.REACT_APP_BASE_URL}/resources/logo/${logoInfo?.logoFile}`
                  }
                  alt="..."
                  className="mw-100 mh-100"
                ></img>
              </div>
              <div className="setting-logo-position p-3">
                <div className="mb-1">
                  <p className="mb-0 font-sfpro-bold text-left">Vị trí logo</p>
                </div>

                <div className="mb-1">
                  <div className="d-flex flex-row align-items-center cursor-pointer">
                    <input
                      className="mr-3 cursor-pointer"
                      type="radio"
                      name="logoPosition"
                      id="LOGO_LEFT"
                      checked={logoPosition === 1}
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      value={1}
                      onChange={handleChangeLogoPosition}
                    />
                    <label className="m-0 cursor-pointer" htmlFor="LOGO_LEFT">
                      Bên trái
                    </label>
                  </div>
                </div>
                <div className="mb-1">
                  <div className="d-flex flex-row align-items-center cursor-pointer mb-2">
                    <input
                      className="mr-3 cursor-pointer"
                      type="radio"
                      name="logoPosition"
                      id="LOGO_RIGHT"
                      checked={logoPosition === 0}
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      value={0}
                      onChange={handleChangeLogoPosition}
                    />
                    <label className="m-0 cursor-pointer" htmlFor="LOGO_RIGHT">
                      Bên phải
                    </label>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-outline-secondary bg-white mb-2"
                  style={{ minWidth: 104 }}
                  onClick={() => dispatch(setLogoInfo(null))}
                >
                  <i
                    className="fal fa-trash-alt mb-1"
                    style={{ color: '#495057' }}
                  ></i>{' '}
                  Xóa logo
                </button>

                <button
                  type="button"
                  className="btn btn-secondary bg-primary text-white"
                  style={{ minWidth: 104 }}
                  onClick={handleReUploadLogo}
                >
                  <i
                    className="fa-regular fa-upload mb-1 text-white"
                    style={{ color: '#495057' }}
                  ></i>{' '}
                  Tải lại
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LogoControl
