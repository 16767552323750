import { Upload } from 'antd'
import vi from 'antd/es/locale/vi_VN'
import axios from 'axios'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { env } from 'general/utils/env'
import Utils from 'general/utils/Utils'
import _ from 'lodash'

import ToastHelper from 'general/helpers/ToastHelper'
import classes from './Dropzone.module.scss'
/**
 * @template T
 * @param {object} props
 * @param {string[]} props.extensions
 * @param {Array<import('antd').UploadFile<T>>} [props.defaultValue]
 * @param {boolean} props.allowMultiple
 * @param {NumberInputClassKey} props.maxFileAllow
 * @param {object} [props.server]
 * @param {string} [props.server.url]
 * @param {import('axios').Method} [props.server.method]
 * @param {string} [props.server.fieldName]
 * @param {(responseData?: Blob | null) => void} [props.onSuccess]
 */
export default function Dropzone(props) {
  /** @type {import('antd').UploadProps['customRequest']} */
  const handleUpload = async (options) => {
    if (!props.server) return ToastHelper.showError('Server is not defined')
    const formData = new FormData()
    const fieldName = _.defaultTo(props.server.fieldName, 'file')
    if (props.allowMultiple) formData.append(fieldName, options.file)
    else formData.set(fieldName, options.file)
    try {
      const res = await axios({
        baseURL: env.API_URL,
        method: props.server.method,
        url: props.server.url,
        data: formData,
        withCredentials: options.withCredentials,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem(PreferenceKeys.accessToken)}`,
          'Accept-Ext': props.extensions.join(','),
        },
        responseType: 'arraybuffer',
        onUploadProgress: (event) => {
          options.onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      })
      if (res.data && res.data.byteLength > 0) {
        props.onSuccess(
          new Blob([res.data], { type: res.headers['content-type'] }),
        )
      } else props.onSuccess()
      ToastHelper.showSuccess('Upload file thành công')
      options.onSuccess(res)
    } catch (error) {
      const message = axios.isAxiosError(error)
        ? error?.response?.data?.message ?? error?.message
        : 'Upload file thất bại'
      ToastHelper.showError(message)
      options.onError({
        message,
      })
    }
  }

  return (
    <div className="my-3">
      <Upload.Dragger
        defaultFileList={props.defaultValue}
        name="dropzone"
        maxCount={props.allowMultiple ? props.maxFileAllow ?? 3 : 1}
        listType={props.allowMultiple ? 'picture-card' : 'picture'}
        accept={props.extensions.join(', ')}
        multiple={props.allowMultiple}
        locale={vi.Upload}
        progress={{
          strokeColor: {
            '0%': '#0891b2',
            '100%': '#2563eb',
          },
          strokeWidth: 3,
        }}
        customRequest={handleUpload}
        showUploadList={{
          showDownloadIcon: false,
          showPreviewIcon: false,
        }}
      >
        <p className="ant-upload-drag-icon">
          <i
            className={Utils.cn(
              'fa-duotone text-primary fa-3x',
              props.allowMultiple ? 'fa-folder-arrow-up' : 'fa-file-arrow-up',
            )}
          />
        </p>
        <p className="ant-upload-text">
          Kéo thả tệp hoặc bấm vào đây để tải{' '}
          {props.allowMultiple ? 'tệp' : 'file'}
        </p>
        <p className="ant-upload-hint">
          Chỉ hỗ trợ các tệp có định dạng{' '}
          {props.extensions
            .map((item) => {
              return `${item.toLowerCase()}`
            })
            .join(', ')}
        </p>
        {props.allowMultiple ? (
          <p className={classes['helper-count']}>
            Số lượng tối đa: {props.maxFileAllow ?? 3}
          </p>
        ) : null}
      </Upload.Dragger>
    </div>
  )
}
