const { default: axiosClient } = require('./axiosClient')
import _ from 'lodash'
import QueryString from 'qs'

const templateTicketApi = {
  // getTemplates: (params) => {
  //   const clonedParams = _.clone(params)
  //   clonedParams.page -= 1
  //   const url = '/template'
  //   return axiosClient.get(url, { params: clonedParams })
  // },

  // createTemplate: (params) => {
  //   const url = `/template`
  //   let formData = new FormData()

  //   for (let [key, value] of Object.entries(params)) {
  //     if (['region', 'calcConfig', 'extendFields'].includes(key)) {
  //       formData.append(key, JSON.stringify(value))
  //     } else {
  //       formData.append(key, value)
  //     }
  //   }

  //   return axiosClient.post(url, formData, {
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //   })
  // },

  // updateTemplate: (invoiceTemplateId, params) => {
  //   const url = `/template/${invoiceTemplateId}`
  //   let formData = new FormData()

  //   for (let [key, value] of Object.entries(params)) {
  //     if (['region', 'calcConfig', 'extendFields'].includes(key)) {
  //       formData.append(key, JSON.stringify(value))
  //     } else {
  //       formData.append(key, value)
  //     }
  //   }

  //   return axiosClient.put(url, formData, {
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //   })
  // },

  // deleteTemplate: (invoiceTemplateId) => {
  //   const url = `/template/${invoiceTemplateId}`

  //   return axiosClient.delete(url)
  // },

  getSystemTemplatesByCategoryTicketIds: (categoryTicketIds) => {
    const url = `/template/system`

    return axiosClient.get(url, {
      params: { invoiceType: 'TICKET', categoryTicketIds },
      paramsSerializer: (params) => QueryString.stringify(params),
    })
  },

  exportTicketTemplate: (params) => {
    const url = `/ticket-template/export`

    let formData = new FormData()

    for (let [key, value] of Object.entries(params)) {
      if (key == 'region') {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    }

    return axiosClient.post(url, formData, { responseType: 'blob' })
  },

  exportTicketTemplateById: (invoiceTemplateId, params) => {
    const url = `/ticket-template/export/${invoiceTemplateId}`

    return axiosClient.get(url, { responseType: 'blob', params })
  },

  // getDetail: (templateInvoiceId) => {
  //   const url = `/template/${templateInvoiceId}`

  //   return axiosClient.get(url)
  // },

  getCategories: () => {
    const url = '/category-ticket'

    return axiosClient.get(url)
  },

  // getTemplateSerials: () => {
  //   const url = '/template/serials'

  //   return axiosClient.get(url, { params: { active: 1 } })
  // },

  // getAllTemplateSerials: () => {
  //   const url = '/template/serials'

  //   return axiosClient.get(url)
  // },
}

export default templateTicketApi
