import './style.scss'

function InvoicePackageScreen() {
  return (
    <div className="InvoicepackageScreen">
      <div className="my-5 padding-slider">
        <p className="font-weight-boldest headName text-uppercase text-center mb-10">
          Báo giá ICORP eInvoice - Dịch vụ xử lý hóa đơn
        </p>
        <div className="mt-10">
          Lưu ý <br />
          <div className="d-flex">
            <div>
              <i
                className="fas fa-circle mr-3"
                style={{
                  fontSize: '3px',
                  color: '#495057',
                  transform: 'translateY(-3px)',
                }}
              ></i>
            </div>
            <div>
              Khách hàng có thể mua trước nhiều hóa đơn, sử dụng trong nhiều
              năm, số lượng hóa đơn chưa sử dụng hết trong thời gian sử dụng
              dịch vụ sẽ được tiếp tục sử dụng sau khi khách hàng gia hạn dịch
              vụ <br />
            </div>
          </div>
          <div className="d-flex">
            <div>
              <i
                className="fas fa-circle mr-3"
                style={{
                  fontSize: '3px',
                  color: '#495057',
                  transform: 'translateY(-3px)',
                }}
              ></i>
            </div>
            <div>
              Không giới hạn số lượng người dùng <br />
            </div>
          </div>
          <div className="d-flex">
            <div>
              <i
                className="fas fa-circle mr-3"
                style={{
                  fontSize: '3px',
                  color: '#495057',
                  transform: 'translateY(-3px)',
                }}
              ></i>
            </div>
            <div>
              Báo giá có hiệu lực từ ngày 10/10/2022 <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoicePackageScreen
