import { useMutation } from '@tanstack/react-query'
import { Divider } from 'antd'
import { Space, Typography } from 'antd'
import invoiceApi from 'api/invoiceApi'
import dayjs from 'dayjs'
import {
  setModalsState,
  useInvoiceSelector,
} from 'features/Invoice/invoiceSlice'
import { listInvoiceFromPosQueries } from 'features/Invoice/screens/ListInvoiceFromPOS/queries'
import { useSystemConfigSelector } from 'features/System/screens/SystemConfiguration/SystemConfigurationSlice'
import { useFormik } from 'formik'
import CustomLabel from 'general/components/CustomLabel'
import Tagnify from 'general/components/CustomTag/Tagnify'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { generateSchema } from 'general/utils/YupSchema/InvoiceFromPos'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FormGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function ModalSendInvoiceMail({ onHandleApply }) {
  const { selectedInvoice, modalStates } = useInvoiceSelector()
  const { formatNumber } = useSystemConfigSelector()
  const dispatch = useAppDispatch()
  const ref = useRef(null)
  const { t } = useTranslation()
  const [showCC, setShowCC] = useState(false)
  const [showBCC, setShowBCC] = useState(false)

  const totalPayment = useMemo(
    () =>
      _.isNumber(selectedInvoice?.totalAfterTax)
        ? Utils.formatNumber(
            selectedInvoice?.totalAfterTax,
            selectedInvoice?.numberFormat?.totalCost || formatNumber?.totalCost,
          ) +
          ' ' +
          selectedInvoice?.currency
        : selectedInvoice?.totalAfterTax + ' ' + selectedInvoice?.currency,
    [
      formatNumber?.totalCost,
      selectedInvoice?.currency,
      selectedInvoice?.numberFormat?.totalCost,
      selectedInvoice?.totalAfterTax,
    ],
  )

  const { mutate: handleSendMail, isPending: isSendingMail } = useMutation({
    mutationKey: [listInvoiceFromPosQueries.base, { action: 'sendMail' }],
    mutationFn: (params) => invoiceApi.sendInvoiceToCustomer(params),
    onSuccess: (res) => {
      if (res?.status !== 200 && res.result !== 'success') {
        ToastHelper.showError('Đã có lỗi trong quá trình gửi mail')
        return
      }
      ToastHelper.showSuccess('Gửi email thành công')
      onHandleApply()
      dispatch(
        setModalsState({
          modalName: 'sendDraftInvoice',
          status: false,
        }),
      )
    },
  })

  const { setFieldValue, submitForm, getFieldProps, getFieldMeta, resetForm } =
    useFormik({
      initialValues: {
        customerEmail: selectedInvoice?.customerEmail ?? '',
        customerName: selectedInvoice?.customerName ?? '',
        customerEmailCc: '',
        customerEmailBcc: '',
      },
      enableReinitialize: true,
      validationSchema: generateSchema(t).sendDraftInvoiceMail,
      onSubmit: (values) => {
        const params = {
          invoiceId: selectedInvoice?.invoiceId,
          customerName: values.customerName,
          customerEmail: values.customerEmail,
          ccEmails:
            values.customerEmailCc?.split(';')?.filter((item) => item) || [],
          bccEmails:
            values.customerEmailBcc?.split(';')?.filter((item) => item) || [],
        }
        handleSendMail(params)
      },
    })

  // ========= EFFECTS ============
  useEffect(() => {
    if (!showCC) setFieldValue('customerEmailCc', '')
    if (!showBCC) setFieldValue('customerEmailBcc', '')
  }, [setFieldValue, showBCC, showCC])

  return (
    <DraggableModal
      title="Gửi hóa đơn cho khách hàng"
      isOpen={modalStates.sendDraftInvoice}
      onOpenChange={(status) => {
        resetForm()
        dispatch(
          setModalsState({
            modalName: 'sendDraftInvoice',
            status,
          }),
        )
      }}
      applyText="Gửi"
      cancelText="Đóng"
      isLoading={isSendingMail}
      ref={ref}
      handleApplyChanges={submitForm}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <div className="bg-light container d-flex flex-row justify-content-between">
        <div className="my-4">
          <FormGroup className="row">
            <div className="col-lg-4">
              <CustomLabel text="Ký hiệu" fontWeight={600} />
            </div>
            <div className="col-lg-8">{selectedInvoice?.serial}</div>
          </FormGroup>
          <FormGroup className="row">
            <div className="col-lg-4">
              <CustomLabel text="Ngày hóa đơn" fontWeight={600} />
            </div>
            <div className="col-lg-8">
              {dayjs(selectedInvoice?.date).isValid()
                ? dayjs(selectedInvoice?.date).format('L')
                : ''}
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="col-lg-4">
              <CustomLabel text="Khách hàng" fontWeight={600} />
            </div>
            <div className="col-lg-8">
              {selectedInvoice?.customerCompanyName}
            </div>
          </FormGroup>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <Typography.Text strong className="fs-5">
            Tổng tiền thanh toán
          </Typography.Text>
          <Typography.Paragraph strong className="fs-5">
            {totalPayment}
          </Typography.Paragraph>
        </div>
      </div>
      <div className="container my-4">
        <div className="d-flex flex-column gap-3">
          {/* Tên khách hàng */}
          <Space direction="vertical" className="w-100">
            <CustomLabel
              fontWeight={600}
              text={'Tên khách hàng'}
              isRequired
              htmlFor="customerName"
            />
            <CustomAntInput
              type="text"
              inputProps={{
                ...getFieldProps('customerName'),
                placeholder: 'Nhập tên khách hàng',
                autoComplete: 'off',
                autoFocus: true,
                status:
                  getFieldMeta('customerName').touched &&
                  getFieldMeta('customerName').error
                    ? 'error'
                    : undefined,
              }}
            />
            <Typography.Text type="danger" className="font-size-sm">
              {getFieldMeta('customerName').touched &&
              getFieldMeta('customerName').error
                ? getFieldMeta('customerName').error
                : ''}
            </Typography.Text>
          </Space>
          {/* Email */}
          <Space direction="vertical" className="w-100">
            <CustomLabel
              fontWeight={600}
              htmlFor="customerEmail"
              width={'100%'}
              text={
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <Typography.Text>
                    Email
                    <span className="text-danger ml-1">*</span>
                  </Typography.Text>
                  <div className="d-flex gap-2">
                    <Typography.Link
                      onClick={() => setShowCC(!showCC)}
                      className="font-size-sm"
                    >
                      {showCC ? 'Ẩn' : 'Hiện'} CC
                    </Typography.Link>
                    <Divider type="vertical" className="bg-secondary" />
                    <Typography.Link
                      onClick={() => setShowBCC(!showBCC)}
                      className="font-size-sm"
                    >
                      {showBCC ? 'Ẩn' : 'Hiện'} BCC
                    </Typography.Link>
                  </div>
                </div>
              }
            />
            <CustomAntInput
              type="text"
              inputProps={{
                ...getFieldProps('customerEmail'),
                placeholder: 'Nhập email',
                autoComplete: 'off',
                status:
                  getFieldMeta('customerEmail').touched &&
                  getFieldMeta('customerEmail').error
                    ? 'error'
                    : undefined,
              }}
            />
            <Typography.Text type="danger" className="font-size-sm">
              {getFieldMeta('customerEmail').touched &&
              getFieldMeta('customerEmail').error
                ? getFieldMeta('customerEmail').error
                : ''}
            </Typography.Text>
          </Space>
          {showCC && (
            <Space direction="vertical" size={'small'} className="w-100">
              <CustomLabel
                htmlFor={'emailCC'}
                text={'Email CC'}
                fontWeight={600}
              />
              <Tagnify
                defaultValue={getFieldProps('emailCC').value}
                delimiterString=";"
                placeholder="Các email cách nhau bằng dấu ;"
                setFieldValue={setFieldValue}
                name="emailCC"
                pillColor="success"
                restriction={{
                  type: 'text',
                  validationSchema: generateSchema(t).emailSchema.nullable(),
                }}
              />
            </Space>
          )}
          {showBCC && (
            <Space direction="vertical" size={'small'} className="w-100">
              <CustomLabel
                htmlFor={'showBCC'}
                text={'Email BCC'}
                fontWeight={600}
              />
              <Tagnify
                defaultValue={getFieldProps('showBCC').value}
                delimiterString=";"
                placeholder="Các email cách nhau bằng dấu ;"
                setFieldValue={setFieldValue}
                name="showBCC"
                pillColor="info"
                restriction={{
                  type: 'text',
                  validationSchema: generateSchema(t).emailSchema.nullable(),
                }}
              />
            </Space>
          )}
        </div>
      </div>
    </DraggableModal>
  )
}
