import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { SketchPicker } from 'react-color'

import './style.scss'

function CustomColorPicker(props) {
  const { selectedColor, setSelectedColor } = props

  return (
    <div className="CustomColorPicker">
      <Dropdown>
        <Dropdown.Toggle className="dd-color-picker active-border-blue">
          <>
            <span
              className="selected-color rounded-circle mr-2"
              style={{ backgroundColor: selectedColor }}
            ></span>
            Đổi màu
          </>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <SketchPicker
            color={selectedColor}
            onChangeComplete={(c) => setSelectedColor(c.hex)}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default CustomColorPicker
