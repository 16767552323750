import axiosClient from './axiosClient'

const companyUnitApi = {
  find: (params, signal) => {
    const url = '/company-unit/find'
    return axiosClient.get(url, { params, signal })
  },

  create: (params) => {
    const url = '/company-unit/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = `/company-unit/update/${params.companyUnitId}`
    return axiosClient.put(url, params)
  },

  delete: (params) => {
    const url = '/company-unit/delete'
    return axiosClient.delete(url, {
      data: {
        companyUnitIds: params,
      },
    })
  },
}

export default companyUnitApi
