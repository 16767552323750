// import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import { useAppSelector } from 'hooks/useRedux'
import ImportTicketStep from '../ImportTicketStep'
import './styles.scss'
import { IMPORT_TICKET_STEPS } from 'general/constants/AppConstants'

const ImportTicketStepLine = ({ active }) => {
  return (
    <div
      style={{
        height: '1px',
        width: '180px',
        backgroundColor: active ? '#1877f2' : '#c1c1c1',
      }}
    />
  )
}

function ImportTicketStepBar(props) {
  const { currentStep } = useAppSelector((state) => state.importTicket)

  return (
    <div className="importTicketStepBar">
      {IMPORT_TICKET_STEPS.map((step, index) => {
        return (
          <>
            <ImportTicketStep
              active={currentStep > index}
              {...step}
              key={index}
            />
            {index != IMPORT_TICKET_STEPS.length - 1 && (
              <ImportTicketStepLine
                // key={index}
                active={currentStep - 1 > index}
              />
            )}
          </>
        )
      })}
    </div>
  )
}

export default ImportTicketStepBar
