import React, { useEffect, useState } from 'react'
import {
  FormCustom,
  InputCustom,
  SpanFieldValue,
  SpanAutoType,
  SelectCustom,
  RadioCustomT,
  DatePickerCustom,
  InputNumberCumstom,
  RangePickerCustom,
  TextAreaCustom,
  SpanAutoTypeBorder,
  PlusOutlinedCustom,
} from 'features/TaxDeductionDocuments/styled'
import dayjs from 'dayjs'
import {
  convertDate,
  convertToOptions,
  convertToOptionsValueString,
  formatTaxCode,
  numberToIsoDate,
  removeAccents,
  removeTextBetweenBraces,
} from 'general/utils/Utils'
import { useTranslation } from 'react-i18next'
import { Empty, Radio } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import { thunkNationlity } from 'features/Nationlity/nationlitySlice'
import { useAppDispatch } from 'hooks/useRedux'
import { thunkCity } from 'features/City/citySlice'
import locale from 'antd/es/date-picker/locale/vi_VN'
import {
  PlusOutlined,
  UserAddOutlined,
  ZoomInOutlined,
} from '@ant-design/icons'
import { Form } from 'react-router-dom'
import SelectOptions from 'general/constants/SelectOptions'
const TypeInputAuto = ({
  itemFeild,
  language,
  setVisibleSelectTaxpaper,
  setVisibleAddTaxpaper,
  form,
  listIndentify,
  setListIndentify,
}) => {
  const { t } = useTranslation()
  const [listNationality, setListNationality] = useState([])
  const [listCity, setListCity] = useState([])
  const dispatch = useAppDispatch()
  const getAllNationality = async () => {
    const data = unwrapResult(await dispatch(thunkNationlity({})))
    if (!listNationality || listNationality.length == 0) {
      setListNationality(data?.data)
    }
  }
  const [countValidate, setCountValidate] = useState({
    totalTaxableIncomeCount: 0,
    totalDeductedTaxCount: 0,
  })
  const getAllCity = async (param) => {
    const data = unwrapResult(await dispatch(thunkCity(param)))
    if (!listCity || listCity.length == 0) {
      setListCity(data?.data)
    }
  }
  useEffect(() => {
    if (
      itemFeild?.name == 'employeeIdentification' ||
      itemFeild?.name == 'employeeNationality'
    ) {
      getAllNationality()
      getAllCity({ countryId: 1 })
    }
  }, [])

  const excludedFields = [
    'employeeIdentification',
    'employeeIdentification_city',
    'employeeIdentificationDate',
    //number
    'insurance',
    'totalTaxableIncome',
    'totalTaxIncome',
    'totalDeductedTax',
  ]

  const validateFieldsEmployeeTaxCode = (name, value) => {
    if (name == 'employeeTaxCode' && !value) {
      form.validateFields(excludedFields)
    }
  }

  return (
    <>
      &nbsp;&nbsp;
      {(() => {
        switch (itemFeild?.typeInput) {
          case 'inputText':
            return (
              <>
                <SpanAutoType>
                  <FormCustom.Item
                    name={itemFeild?.name}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (itemFeild?.name == 'employeeIdentification') {
                            //Trường hợp đặt biệt nếu cccd có thì bắt buộc các thông tin cccd
                            if (value && value.trim()) {
                              if (value.trim().length == 8) {
                                setListIndentify(
                                  convertToOptions(
                                    listNationality,
                                    'nationalityId',
                                    'nationalityName',
                                  ),
                                )
                              } else if (value.trim().length == 9) {
                                setListIndentify(
                                  convertToOptionsValueString(
                                    listCity,
                                    'cityCode',
                                    'cityName',
                                  ),
                                )
                              } else if (value.trim().length == 12) {
                                setListIndentify(
                                  SelectOptions.listCitizenidentificationLocation,
                                )
                              } else {
                                setListIndentify([])
                              }
                            }
                          }
                          if (
                            itemFeild?.name == 'employeeTaxCode' &&
                            value &&
                            value.trim() &&
                            value.trim().length > 10
                          ) {
                            form.setFieldsValue({
                              employeeTaxCode: formatTaxCode(value.trim()),
                            })
                          }

                          if (
                            itemFeild?.name == 'employeeTaxCode' &&
                            value &&
                            value.trim()
                          ) {
                            form.validateFields([
                              'employeeIdentification',
                              'employeeIdentification_city',
                              'employeeIdentificationDate',
                            ])
                          }

                          if (
                            !value &&
                            excludedFields.includes(itemFeild?.name) &&
                            !form.getFieldValue('employeeTaxCode')
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }
                          //required
                          if (
                            (!value || !value.trim()) &&
                            itemFeild?.required &&
                            !excludedFields.includes(itemFeild?.name)
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }

                          //minLength
                          if (
                            value &&
                            itemFeild?.minLength &&
                            itemFeild.minLength?.[language]?.value &&
                            value.length <
                              itemFeild.minLength?.[language]?.value
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild.minLength?.[language]?.message ??
                                  t('accountingDocuments.minLength', {
                                    value:
                                      itemFeild.minLength?.[language]?.value,
                                  }),
                              ),
                            )
                          }
                          //maxLength
                          if (
                            value &&
                            itemFeild?.maxLength &&
                            itemFeild.maxLength?.[language]?.value &&
                            value.length >
                              itemFeild.maxLength?.[language]?.value
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild.maxLength?.[language]?.message ??
                                  t('accountingDocuments.maxLength', {
                                    value:
                                      itemFeild.maxLength?.[language]?.value,
                                  }),
                              ),
                            )
                          }
                          // Regex
                          if (value && itemFeild?.regex) {
                            for (const itemRegex of itemFeild.regex) {
                              try {
                                const regex = new RegExp(
                                  itemRegex?.[language]?.value,
                                )
                                if (value && !regex.test(value.trim())) {
                                  return Promise.reject(
                                    new Error(
                                      itemRegex?.[language]?.message ??
                                        t('accountingDocuments.errordd'),
                                    ),
                                  )
                                }
                              } catch (e) {
                                console.error(
                                  'Invalid regex:',
                                  itemRegex?.[language]?.value,
                                  e,
                                )
                                return Promise.reject(
                                  new Error(t('regex.invalid')),
                                )
                              }
                            }
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <InputCustom
                      placeholder={itemFeild?.placeholder?.[language]}
                      onChange={(e) => {
                        validateFieldsEmployeeTaxCode(
                          itemFeild?.name,
                          e.target.value,
                        )
                        if (
                          e.target.value &&
                          itemFeild?.name === 'employeeIdentification'
                        ) {
                          form.setFieldsValue({
                            employeeIdentification_city: null,
                          })
                        }
                      }}
                      style={{ width: itemFeild?.widthInput }}
                      addonAfter={
                        itemFeild?.name == 'taxpayersName' && (
                          <PlusOutlinedCustom
                            style={{ fontSize: 16 }}
                            onClick={() => {
                              setVisibleAddTaxpaper(true)
                            }}
                          />
                        )
                      }
                    />
                  </FormCustom.Item>
                </SpanAutoType>

                {itemFeild?.name == 'taxpayersName' && (
                  <SpanAutoTypeBorder
                    style={{ position: 'relative', top: 3 }}
                    onClick={() => {
                      setVisibleSelectTaxpaper(true)
                    }}
                  >
                    <UserAddOutlined style={{ marginRight: 17 }} />
                    {t('accountingDocuments.chonsennt')}
                  </SpanAutoTypeBorder>
                )}

                {/* {itemFeild?.name == 'employeeTaxCode' && (
                  <SpanAutoTypeBorder>
                    <ZoomInOutlined style={{ marginRight: 17 }} />
                    {t('taxpayer.getInfor')}
                  </SpanAutoTypeBorder>
                )} */}
              </>
            )
          case 'select':
            //Những trường hợp đặc biệt lấy dữ liệu từ db
            let dataOption = []
            if (itemFeild?.databaseOption == 'nationality') {
              dataOption = convertToOptions(
                listNationality,
                'nationalityId',
                'nationalityName',
              )
            } else if (itemFeild?.databaseOption == 'city') {
              dataOption = convertToOptions(listCity, 'cityCode', 'cityName')
            }
            return (
              <>
                <SpanAutoType>
                  <FormCustom.Item
                    name={itemFeild?.name}
                    rules={[
                      () => ({
                        validator(_, value) {
                          //Trường hợp đặt biệt nếu cccd có thì bắt buộc các thông tin cccd
                          if (
                            !value &&
                            excludedFields.includes(itemFeild?.name) &&
                            !form.getFieldValue('employeeTaxCode')
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }
                          //required
                          if (
                            (!value || !value.toString().trim()) &&
                            !excludedFields.includes(itemFeild?.name) &&
                            itemFeild?.required
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <SelectCustom
                      allowClear
                      showSearch
                      placeholder={
                        itemFeild?.placeholder?.[language] ??
                        t('accountingDocuments.plSelect')
                      }
                      filterOption={(input, option) =>
                        removeAccents(option?.label ?? '')
                          .toLowerCase()
                          .includes(removeAccents(input).toLowerCase())
                      }
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={t('taxDeduction.noData')}
                        />
                      }
                      style={{ width: itemFeild?.widthInput }}
                      options={
                        //trường hợp đặc biệt
                        itemFeild?.name == 'employeeIdentification_city'
                          ? listIndentify
                          : itemFeild?.databaseOption
                            ? dataOption
                            : itemFeild?.options ?? []
                      }
                    />
                  </FormCustom.Item>
                </SpanAutoType>
              </>
            )
          case 'radio':
            return (
              <>
                <SpanAutoType>
                  <FormCustom.Item
                    name={itemFeild?.name}
                    rules={[
                      () => ({
                        validator(_, value) {
                          //required
                          if (
                            value != 0 &&
                            (!value || !value.toString().trim()) &&
                            itemFeild?.required
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <RadioCustomT.Group>
                      {itemFeild?.valueRadio?.map((item, idx) => (
                        <RadioCustomT value={item.value} key={idx}>
                          {item.label}
                        </RadioCustomT>
                      ))}
                    </RadioCustomT.Group>
                  </FormCustom.Item>
                </SpanAutoType>
              </>
            )
          case 'datepicker':
            return (
              <>
                <SpanAutoType>
                  <FormCustom.Item
                    name={itemFeild?.name}
                    rules={[
                      () => ({
                        validator(_, value) {
                          //Trường hợp đặt biệt nếu cccd có thì bắt buộc các thông tin cccd
                          if (
                            !value &&
                            excludedFields.includes(itemFeild?.name) &&
                            !form.getFieldValue('employeeTaxCode')
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }
                          //required
                          if (
                            (!value || !value.toString().trim()) &&
                            !excludedFields.includes(itemFeild?.name) &&
                            itemFeild?.required
                          ) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <DatePickerCustom
                      style={{ width: itemFeild?.widthInput }}
                      locale={locale}
                      format={itemFeild?.format}
                      placeholder={
                        itemFeild?.placeholder?.[language] ??
                        t('accountingDocuments.plSelectdate')
                      }
                    />
                  </FormCustom.Item>
                </SpanAutoType>
              </>
            )
          case 'rangeDate':
            return (
              <>
                <SpanAutoType>
                  <FormCustom.Item
                    name={itemFeild?.name}
                    rules={[
                      () => ({
                        validator(_, value) {
                          //required
                          if (!value && itemFeild?.required) {
                            return Promise.reject(
                              new Error(
                                itemFeild?.required?.[language]?.message ??
                                  t('accountingDocuments.required'),
                              ),
                            )
                          }
                          if (itemFeild?.inyear) {
                            if (
                              !(
                                value[0] &&
                                value[1] &&
                                dayjs(value[0]).year() == dayjs(value[1]).year()
                              )
                            ) {
                              return Promise.reject(
                                new Error(
                                  itemFeild?.inyear?.[language]?.message ??
                                    t('accountingDocuments.inyear'),
                                ),
                              )
                            }
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <RangePickerCustom
                      style={{ width: itemFeild?.widthInput }}
                      locale={locale}
                      format={itemFeild?.format}
                      picker={itemFeild?.picker}
                      //   placeholder={
                      //     itemFeild?.placeholder?.[language] ??
                      //     t('accountingDocuments.plSelectdate')
                      //   }
                    />
                  </FormCustom.Item>
                </SpanAutoType>
              </>
            )
          case 'inputNumber':
            return (
              <SpanAutoType style={{ width: itemFeild?.widthInput }}>
                <FormCustom.Item
                  name={itemFeild?.name}
                  rules={[
                    () => ({
                      validator(_, value) {
                        //các trường hợp đặc biệt
                        if (
                          !value &&
                          excludedFields.includes(itemFeild?.name)
                        ) {
                          form.setFieldsValue({ [itemFeild?.name]: 0 })
                        }
                        if (
                          value &&
                          excludedFields.includes(itemFeild?.name) &&
                          itemFeild?.maxLength &&
                          itemFeild.maxLength?.[language]?.value
                        ) {
                          form.setFieldsValue({
                            [itemFeild?.name]: value
                              ?.toString()
                              .substring(
                                0,
                                itemFeild.maxLength?.[language]?.value,
                              ),
                          })
                        }
                        if (
                          (value == null || value == undefined) &&
                          itemFeild?.required
                        ) {
                          //required
                          return Promise.reject(
                            new Error(
                              itemFeild?.required?.[language]?.message ??
                                t('accountingDocuments.required'),
                            ),
                          )
                        }
                        //minLength
                        if (
                          value &&
                          itemFeild?.minLength &&
                          itemFeild.minLength?.[language]?.value &&
                          value.toString().length <
                            itemFeild.minLength?.[language]?.value
                        ) {
                          return Promise.reject(
                            new Error(
                              itemFeild.minLength?.[language]?.message ??
                                t('accountingDocuments.minLength', {
                                  value: itemFeild.minLength?.[language]?.value,
                                }),
                            ),
                          )
                        }
                        //maxLength
                        if (
                          value &&
                          itemFeild?.maxLength &&
                          itemFeild.maxLength?.[language]?.value &&
                          value.toString().length >
                            itemFeild.maxLength?.[language]?.value
                        ) {
                          return Promise.reject(
                            new Error(
                              itemFeild.maxLength?.[language]?.message ??
                                t('accountingDocuments.maxLength', {
                                  value: itemFeild.maxLength?.[language]?.value,
                                }),
                            ),
                          )
                        }
                        //min
                        if (
                          itemFeild?.min &&
                          itemFeild.min?.[language]?.value &&
                          value < itemFeild.minLength?.[language]?.value
                        ) {
                          return Promise.reject(
                            new Error(
                              itemFeild.minLength?.[language]?.message ??
                                t('accountingDocuments.min', {
                                  value: itemFeild.minLength?.[language]?.value,
                                }),
                            ),
                          )
                        }
                        //max
                        if (
                          itemFeild?.max &&
                          itemFeild.max?.[language]?.value &&
                          value > itemFeild.minLength?.[language]?.value
                        ) {
                          return Promise.reject(
                            new Error(
                              itemFeild.minLength?.[language]?.message ??
                                t('accountingDocuments.max', {
                                  value: itemFeild.minLength?.[language]?.value,
                                }),
                            ),
                          )
                        }
                        //TH Đặc biệt totalTaxableIncome
                        if (
                          value &&
                          itemFeild?.name == 'totalTaxableIncome' &&
                          form.getFieldValue('totalDeductedTax') != null
                        ) {
                          if (
                            Number(value) <
                            Number(form.getFieldValue('totalDeductedTax'))
                          ) {
                            if (countValidate.totalTaxableIncomeCount == 0) {
                              form.validateFields(['totalDeductedTax'])
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTax: 1,
                              })
                            } else {
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTax: 0,
                              })
                            }
                            return Promise.reject(
                              new Error(t('accountingDocuments.ct16')),
                            )
                          } else {
                            if (countValidate.totalTaxableIncomeCount == 0) {
                              form.validateFields(['totalDeductedTax'])
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTax: 1,
                              })
                            } else {
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTax: 0,
                              })
                            }
                          }
                        }
                        //TH Đặc biệt totalDeductedTax
                        if (
                          value &&
                          itemFeild?.name == 'totalDeductedTax' &&
                          form.getFieldValue('totalTaxableIncome') != null
                        ) {
                          if (
                            Number(value) >
                            Number(form.getFieldValue('totalTaxableIncome'))
                          ) {
                            if (countValidate.totalDeductedTaxCount == 0) {
                              form.validateFields(['totalTaxableIncome'])
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTaxCount: 1,
                              })
                            } else {
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTaxCount: 0,
                              })
                            }
                            return Promise.reject(
                              new Error(t('accountingDocuments.ct18')),
                            )
                          } else {
                            if (countValidate.totalDeductedTaxCount == 0) {
                              form.validateFields(['totalTaxableIncome'])
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTaxCount: 1,
                              })
                            } else {
                              setCountValidate({
                                ...countValidate,
                                totalDeductedTaxCount: 0,
                              })
                            }
                          }
                        }
                        // if (
                        //   !value && ['totalTaxableIncome', 'totalDeductedTax']
                        // )
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputNumberCumstom
                    textAlignNumber={itemFeild?.textAlignNumber}
                    controls={itemFeild?.controls}
                    min={itemFeild.min?.[language]?.value}
                    max={itemFeild.max?.[language]?.value}
                    style={{ width: itemFeild?.widthInput }}
                    placeholder={itemFeild?.placeholder?.[language]}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  />
                </FormCustom.Item>
              </SpanAutoType>
            )
          case 'textara':
            return (
              <SpanAutoType>
                <FormCustom.Item
                  name={itemFeild?.name}
                  rules={[
                    () => ({
                      validator(_, value) {
                        //required
                        if ((!value || !value.trim()) && itemFeild?.required) {
                          return Promise.reject(
                            new Error(
                              itemFeild?.required?.[language]?.message ??
                                t('accountingDocuments.required'),
                            ),
                          )
                        }
                        // //minLength
                        // if (
                        //   itemFeild?.minLength &&
                        //   itemFeild.minLength?.[language]?.value &&
                        //   value.length < itemFeild.minLength?.[language]?.value
                        // ) {
                        //   return Promise.reject(
                        //     new Error(
                        //       itemFeild.minLength?.[language]?.message ??
                        //         t('accountingDocuments.minLength', {
                        //           value: itemFeild.minLength?.[language]?.value,
                        //         }),
                        //     ),
                        //   )
                        // }
                        // //maxLength
                        // if (
                        //   itemFeild?.maxLength &&
                        //   itemFeild.maxLength?.[language]?.value &&
                        //   value.length > itemFeild.maxLength?.[language]?.value
                        // ) {
                        //   return Promise.reject(
                        //     new Error(
                        //       itemFeild.maxLength?.[language]?.message ??
                        //         t('accountingDocuments.maxLength', {
                        //           value: itemFeild.maxLength?.[language]?.value,
                        //         }),
                        //     ),
                        //   )
                        // }
                        // Regex
                        // if (itemFeild?.regex) {
                        //   for (const itemRegex of itemFeild.regex) {
                        //     try {
                        //       const regex = new RegExp(
                        //         itemRegex?.[language]?.value,
                        //       )
                        //       if (value && !regex.test(value.trim())) {
                        //         return Promise.reject(
                        //           new Error(
                        //             itemRegex?.[language]?.message ??
                        //               t('accountingDocuments.errordd'),
                        //           ),
                        //         )
                        //       }
                        //     } catch (e) {
                        //       console.error(
                        //         'Invalid regex:',
                        //         itemRegex?.[language]?.value,
                        //         e,
                        //       )
                        //       return Promise.reject(
                        //         new Error(t('regex.invalid')),
                        //       )
                        //     }
                        //   }
                        // }

                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <TextAreaCustom
                    placeholder={itemFeild?.placeholder?.[language]}
                    style={{ width: itemFeild?.widthInput }}
                    col={itemFeild?.col}
                    row={itemFeild?.row}
                    maxLength={itemFeild.maxLength?.[language]?.value}
                  />
                </FormCustom.Item>
              </SpanAutoType>
            )
          default:
            return <>{itemFeild?.typeValue}</>
        }
      })()}
    </>
  )
}

export default TypeInputAuto
