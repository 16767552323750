import { ToastContainer } from 'react-toastify'

function AppToast() {
  return (
    <ToastContainer
      className="user-select-none"
      position="top-right"
      autoClose={5000}
      newestOnTop
      pauseOnFocusLoss={false}
      theme="light"
      closeOnClick
      draggable
      limit={3}
    />
  )
}

export default AppToast
