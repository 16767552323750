import systemApi from 'api/systemApi'
import Global from 'general/utils/Global'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

// MARK --- Thunk: ---
export const thunkGetCompanyProfile = createAsyncThunk(
  'system/company',
  async (params, thunkApi) => {
    const res = await systemApi.getCompanyProfile(params)
    return res
  },
)

export const thunkGetProvince = createAsyncThunk(
  'system/province',
  async (params, thunkApi) => {
    const res = await systemApi.getProvince(params)
    return res
  },
)

export const thunkGetTaxAuthority = createAsyncThunk(
  'system/tax-authority',
  async (params, thunkApi) => {
    const res = await systemApi.getTaxAuthorityByProvine(params)
    return res
  },
)

export const thunkUpdateCompanyProfile = createAsyncThunk(
  'system/update-company',
  async (params, thunkApi) => {
    const res = await systemApi.updateCompanyProfile(params)
    return res
  },
)

const systemSlice = createSlice({
  name: 'system',
  initialState: {
    pagination: { perPage: Global.gDefaultPagination },

    isGettingCompanyProfile: false,
    company: {},

    provinces: [],

    taxAuthorities: [],
  },
  reducers: {
    setPaginationPerPage: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: action.payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    // company profile
    builder.addCase(thunkGetCompanyProfile.pending, (state, action) => {
      state.isGettingCompanyProfile = true
    })
    builder.addCase(thunkGetCompanyProfile.rejected, (state, action) => {
      state.isGettingCompanyProfile = false
    })
    builder.addCase(thunkGetCompanyProfile.fulfilled, (state, action) => {
      state.isGettingCompanyProfile = false
      const company = action.payload
      if (company) {
        state.company = company
      }
    })

    // province
    builder.addCase(thunkGetProvince.fulfilled, (state, action) => {
      const { provinces } = action.payload
      if (provinces) {
        state.provinces = provinces
      }
    })

    // tax authority
    builder.addCase(thunkGetTaxAuthority.fulfilled, (state, action) => {
      const { taxAuthorities } = action.payload
      if (taxAuthorities) {
        state.taxAuthorities = taxAuthorities
      }
    })

    // update company profile
    builder.addCase(thunkUpdateCompanyProfile.pending, (state, action) => {
      state.isGettingCompanyProfile = true
    })
    builder.addCase(thunkUpdateCompanyProfile.rejected, (state, action) => {
      state.isGettingCompanyProfile = false
    })
    builder.addCase(thunkUpdateCompanyProfile.fulfilled, (state, action) => {
      state.isGettingCompanyProfile = false
      // const { company, result } = action.payload;
      // if (result === 'success') {
      //   state.company = company;
      // }
    })
  },
})

const { actions, reducer } = systemSlice
export const { setPaginationPerPage } = actions
export default reducer
