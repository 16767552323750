import React, { useState, useEffect } from 'react'
import PrivateBaseLayout from 'general/components/eInvoiceComponents/BaseLayout/PrivateBaseLayout'
import DataTable from 'react-data-table-component'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import Empty from 'general/components/Empty'
import { useTranslation } from 'react-i18next'
import AppResource from 'general/constants/AppResource'
import Loading from 'general/components/Loading'
import Utils from 'general/utils/Utils'
import { Button } from 'react-bootstrap'
import './style.scss'
import { fetchInvoiceApprovalProcesses } from './invoiceApprovalProcessSlice'
import { useDispatch, useSelector } from 'react-redux'
import ModalEdit from './tabs/ModalEdit'
import ModalDelete from './tabs/ModalDelete'
import templateInvoiceApi from 'api/templateInvoiceApi'
import ToastHelper from 'general/helpers/ToastHelper'
import { deleteInvoiceApprovalProcess } from 'features/System/screens/InvoiceApprovalProcess/invoiceApprovalProcessSlice.js'

InvoiceApprovalProcess.propTypes = {}

function InvoiceApprovalProcess() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const [tableMaxHeight, setTableMaxHeight] = useState('100vh')
  const tableMaxHeight = '100vh'
  const [show, setShow] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [editState, setEditState] = useState(false)
  const [addState, setAddState] = useState(false)
  const fullColumns = [
    {
      name: 'Tên quy trình',
      sortable: false,
      minWidth: '150px',
      center: true,
      cell: (row) => {
        return <div className="">{row?.name}</div>
      },
    },
    {
      name: 'Mô tả',
      sortable: false,
      minWidth: '300px',
      center: true,
      cell: (row) => {
        return (
          <div
            className="notLongtext"
            style={{ overflowY: 'auto', maxHeight: '150px' }}
          >
            {row?.description}
          </div>
        )
      },
    },
    {
      name: 'Ký hiệu',
      sortable: false,
      minWidth: '270px',
      center: true,
      cell: (row) => {
        return (
          <div
            className="d-flex justify-content-center flex-wrap w-100"
            style={{ maxWidth: '100%', maxHeight: '100px', overflowY: 'auto' }}
          >
            {row?.listSerial.map((item, index) => {
              return (
                <div className="serialBox" key={index} style={{}}>
                  {item?.serial}
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      name: 'Sử dụng',
      sortable: false,
      minWidth: '120px',
      maxWidth: '150px',
      center: true,
      cell: (row) => {
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ maxWidth: '100%', maxHeight: '100px', overflowY: 'auto' }}
          >
            <div className="" style={{}}>
              {row?.active == 1 ? (
                <i className="text-success fa-regular fa-circle-check"></i>
              ) : (
                <i className="text-danger fa-regular fa-circle-xmark"></i>
              )}
            </div>
          </div>
        )
      },
    },
    {
      name: 'Hành động',
      minWidth: '120px',
      maxWidth: '120px',
      center: true,
      compact: true,
      style: {
        position: 'sticky',
        top: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        opacity: 0,
        visible: false,
        zIndex: 100,

        '&.hover': {
          visible: true,
          opacity: 1,
          zIndex: 100,
        },
      },
      cell: (row) => {
        return (
          <>
            <div
              className="cell-actions d-flex flex-row justify-content-end align-items-center w-100 h-100"
              style={{
                padding: 'inherit',
                backgroundColor: '#F3F6F9',
                zIndex: '1000',
              }}
            >
              <button
                className="btn btn-icon btn-icon-custom"
                title="Chỉnh sửa quy trình"
                onClick={() => {
                  setSelectedRow(row)
                  setShow(true)
                  setEditState(true)
                  setAddState(false)
                }}
              >
                <i
                  className="far fa-edit"
                  style={{ color: '#3F4254', fontSize: '18px' }}
                ></i>
              </button>
              <button
                className="btn btn-icon btn-icon-custom"
                title="Xóa quy trình"
                onClick={() => {
                  setSelectedRow(row)
                  setShowCancel(true)
                }}
              >
                <i
                  className="far fa-trash-alt"
                  style={{ color: '#3F4254' }}
                ></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]

  const [templateInvoiceSerials, setTemplateInvoiceSerials] = useState([])

  const fetchTemplateSerial = async () => {
    const res = await templateInvoiceApi.getAllTemplateSerials()
    if (!Utils.isObjectEmpty(res?.data)) {
      let serials = res?.data
      serials.map((item) => {
        item.label = item.serial
        item.value = item.invoiceTemplateId
      })
      setTemplateInvoiceSerials(serials)
    } else {
      ToastHelper.showError('Không lấy được thông tin ký hiệu hoá đơn')
    }
  }

  const invoiceApprovalProcess = useSelector(
    (state) => state?.invoiceApprovalProcess?.processes,
  )

  const processData = (data) => {
    const newData = data?.map((row, index) => ({
      ...row,
      id: index + 1,
    }))
    return newData
  }

  useEffect(() => {
    fetchTemplateSerial()
    dispatch(fetchInvoiceApprovalProcesses())
  }, [dispatch])

  const configProcess = (data) => {
    if (!data?.listSerial) {
      return []
    }

    const tmp = data
    const newListSerial = data.listSerial.map((row) => ({
      ...row,
      value: row.serial,
      label: row.serial,
    }))
    tmp.listSerial = newListSerial
    return tmp
  }

  const handleDelete = () => {
    dispatch(
      deleteInvoiceApprovalProcess({
        invoiceApprovalProcessId: selectedRow?.invoiceApprovalProcessId,
      }),
    )
  }

  return (
    <PrivateBaseLayout nonPadding={true}>
      <div
        className="InvoiceApprovalProcess bg-white p-6 mt-5"
        style={{ borderRadius: '10px' }}
      >
        <div className="w-100 d-flex justify-content-between">
          <div className="mb-5" style={{ fontSize: '24px', fontWeight: '600' }}>
            Quy trình phê duyệt hóa đơn
          </div>
          <div>
            <Button
              variant="primary"
              style={{
                width: '120px',
                color: '#fff',
                fontWeight: '600',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setShow(true)
                setEditState(false)
                setAddState(true)
              }}
            >
              {' '}
              <i
                className="fas fa-plus mr-2"
                style={{ color: '#fff', fontWeight: '600' }}
              ></i>
              Thêm mới
            </Button>
          </div>
        </div>
        <div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight={tableMaxHeight}
            columns={fullColumns}
            data={processData(invoiceApprovalProcess)}
            customStyles={customDataTableStyle}
            responsive={true}
            // noHeader
            striped
            noDataComponent={
              <div className="pt-12">
                <Empty
                  text={t('NoData')}
                  visible={false}
                  imageEmpty={AppResource.icons.icEmptyBox}
                  imageEmptyPercentWidth={170}
                />
              </div>
            }
            progressComponent={
              <Loading showBackground={false} message={`${t('Loading')}...`} />
            }
            // pointerOnHover
            highlightOnHover
            onRowMouseEnter={(row) => {
              document
                .getElementById('cell-5-' + row.id)
                ?.classList.add('hover')
            }}
            onRowMouseLeave={(row) => {
              document
                .getElementById('cell-5-' + row.id)
                .classList.remove('hover')
              let ddMenus = document.querySelectorAll(
                '.action-dd .dropdown-menu',
              )
              ddMenus.forEach((menu) => menu.classList.remove('show'))
            }}
          />
        </div>
      </div>
      <div>
        {show && (
          <ModalEdit
            show={show}
            setShow={setShow}
            editState={editState}
            addState={addState}
            templateInvoiceSerials={templateInvoiceSerials}
            selectedRow={configProcess(selectedRow)}
          />
        )}
      </div>
      <div>
        {showCancel && (
          <ModalDelete
            show={showCancel}
            setShow={setShowCancel}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </PrivateBaseLayout>
  )
}

export default InvoiceApprovalProcess
