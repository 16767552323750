import { useMutation, useQuery } from '@tanstack/react-query'
import accountApi from 'api/accountApi'
import reportTicketApi from 'api/ReportTicketApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import SelectOptions from 'general/constants/SelectOptions'
import {
  calcMaxCellWidth,
  generateCellObject,
  generateCellStyle,
} from 'general/helpers/ExcelsHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import {
  concat,
  get,
  isEmpty,
  isNil,
  keyBy,
  map,
  merge,
  repeat,
  replace,
} from 'lodash'
import { utils, writeFile } from 'xlsx-js-style'

const keyFactory = {
  base: {
    scope: ['report', 'reportListTicket'],
  },

  lists: (q, f) => [
    {
      ...keyFactory.base,
      params: {
        ...f,
        q,
      },
    },
  ],
  listAccount: (q, f) => [
    {
      ...keyFactory.base,
      entity: 'accounts',
      params: {
        ...f,
      },
    },
  ],
  exportExcel: () => [
    {
      ...keyFactory.base,
      action: 'exportExcel',
    },
  ],
}

export function useGetReportList(debouncedSearch, filterParams) {
  return useQuery({
    queryKey: keyFactory.lists(debouncedSearch, filterParams),
    queryFn: () =>
      reportTicketApi.getList({
        ...filterParams,
        isSum: true,
        q: debouncedSearch,
      }),
    select: (data) => ({
      total: get(data, 'count', 0),
      sumMoney: get(data, 'sumMoney', 0),
      rows:
        data?.rows?.map((row, idx) => ({
          ...row,
          stt: idx + 1,
        })) || [],
    }),
  })
}

export const useGetAccount = (staffFilter) => {
  return useQuery({
    staleTime: 1000 * 60 * 10, // Cho tăng thời gian cache vì dữ liệu ít thay đổi
    refetchOnWindowFocus: true,
    queryKey: keyFactory.listAccount(staffFilter),
    queryFn: ({ signal }) => accountApi.getStaffInfo(staffFilter, signal),
    select: (data) =>
      isEmpty(data.accounts)
        ? []
        : concat(
            { value: null, label: 'Tất cả' },
            map(data.accounts, (item) => ({
              label: item.email,
              value: item.accountId,
            })),
          ),
  })
}

export function useExportMutation(dynamicColumns = []) {
  const dispatch = useAppDispatch()
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)
  const { data: accounts } = useGetAccount({
    page: 0,
    limit: 50,
  })
  return useMutation({
    mutationKey: keyFactory.exportExcel(),
    mutationFn: (params) => reportTicketApi.getList(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (err) => ToastHelper.showError(err?.message),
    onSuccess: (res, { startDate, endDate }) => {
      if (res.result !== 'success')
        return ToastHelper.showError('Không có dữ liệu')
      const commonCellStyle = generateCellStyle({
        alignment: {
          horizontal: 'left',
          vertical: 'center',
        },
        isTextWrap: true,
        border: {
          directions: 'around',
          widthStyle: 'thin',
        },
      })
      const numberFormat = (config) =>
        config === 0 ? '#,##0' : `#,##0.${repeat('0', config)}`
      // const visibleColumns = dynamicColumns.flatMap(({ name, id }) =>
      //   dynamicColumns.find((col) => col.name === name) ? [{ name, id }] : [],
      // )
      //====================HEADER====================
      const headerStyles = merge(
        {},
        commonCellStyle,
        generateCellStyle({
          font: {
            bold: true,
          },
          border: {
            directions: 'around',
            widthStyle: 'medium',
          },
        }),
      )

      const headers = dynamicColumns.map((col) =>
        generateCellObject(col.name, 's', headerStyles),
      )
      //====================CELLS====================
      const numberFormatStyle = merge(
        {},
        commonCellStyle,
        generateCellStyle({
          alignment: {
            horizontal: 'right',
          },
        }),
      )

      const accountIdToLabel = keyBy(accounts, 'value')
      const typeSendCQTToLabel = keyBy(
        SelectOptions.ticketMailSentAuthorityStatus,
        'value',
      )
      const typeTicketToLabel = keyBy(SelectOptions.ticketStatus, 'value')

      const cells = map(res?.rows, (row, idx) =>
        map(dynamicColumns, (col) => {
          let rowValue = get(row, col.id, '')
          rowValue = isNil(rowValue) ? '' : rowValue
          switch (col.id) {
            default:
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'stt':
              return generateCellObject(idx + 1, 'n', commonCellStyle, '0')
            case 'typeSendCQT':
              rowValue = typeSendCQTToLabel[rowValue]?.label
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'typeTicket':
              rowValue = typeTicketToLabel[rowValue]?.label
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'accountId':
              rowValue = accountIdToLabel[rowValue]?.label
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'dateRelease':
              rowValue = dayjs(rowValue).toDate()
              return generateCellObject(
                rowValue,
                'd',
                commonCellStyle,
                'DD/MM/YYYY',
              )
            case 'taxRate':
              rowValue = replace(rowValue, '%', '') / 100
              if (isNaN(rowValue))
                return generateCellObject('', 's', commonCellStyle)
              return generateCellObject(rowValue, 'n', commonCellStyle, '0%')
            case 'price':
              rowValue = isNil(rowValue) ? 0 : rowValue
              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                numberFormat(sysConfig.totalCost),
              )
            case 'priceVAT':
              rowValue = (row?.price * replace(row?.taxRate, '%', '')) / 100
              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                numberFormat(sysConfig.totalCost),
              )
            case 'totalPrice':
              rowValue = replace(rowValue, /\./g)
              return generateCellObject(
                rowValue,
                'n',
                numberFormatStyle,
                numberFormat(sysConfig.totalCost),
              )
          }
        }),
      )
      const aoa = [headers, ...cells]
      const newBook = utils.book_new()
      const ws = utils.aoa_to_sheet(aoa, {
        cellStyles: true,
        sheetStubs: true,
      })
      utils.book_append_sheet(newBook, ws, 'sheet 1')
      ws['!cols'] = calcMaxCellWidth(aoa, ws['!ref'], 15)
      writeFile(
        newBook,
        `Bảng kê vé đã sử dụng từ ${dayjs(startDate).format('L')} đến ${dayjs(
          endDate,
        ).format('L')}.xlsx`,
      )
    },
  })
}
