import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './style.scss'

export const KTFormSelectSize = {
  default: '',
  large: 'form-control-lg',
  small: 'form-control-sm',
}

KTFormSelect.propTypes = {
  // required
  name: PropTypes.string.isRequired,

  // optional
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  // value: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isCustom: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(KTFormSelectSize)),
  multiple: PropTypes.bool,
  rows: PropTypes.number,
  showValidState: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  enableCheckValid: PropTypes.bool,
  isTouched: PropTypes.bool,
  isValid: PropTypes.bool,
  feedbackText: PropTypes.string,
  haveSearchBar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  style: PropTypes.object,
}

KTFormSelect.defaultProps = {
  options: [],
  // value: '',
  text: '',
  disabled: false,
  isCustom: false,
  size: KTFormSelectSize.default,
  multiple: false,
  rows: 1,
  showValidState: false,

  onChange: null,
  onBlur: null,
  onFocus: null,
  enableCheckValid: false,
  isValid: true,
  isTouched: false,
  feedbackText: '',
  haveSearchBar: false,
  fullWidth: false,
  style: {},
}

/**
 *
 * @param {{
 * options: {name: string, value: string}[]
 * name: string,
 * value: string,
 * text: string,
 * disabled: boolean,
 * isCustom: boolean,
 * size: string,
 * multiple: boolean,
 * rows: number,
 * showValidState: boolean,
 * onChange: function,
 * onBlur: function,
 * onFocus: function,
 * enableCheckValid: boolean,
 * isValid: boolean,
 * isTouched: boolean,
 * feedbackText: string,
 * haveSearchBar: boolean,
 * fullWidth: boolean,
 * style: object,
 * }} props
 * @returns
 */
function KTFormSelect(props) {
  // MARK: --- Params ---
  const {
    options,
    value,
    text,
    disabled,
    isCustom,
    size,
    showValidState,
    onChange,
    onBlur,
    onFocus,
    enableCheckValid,
    isValid,
    isTouched,
    feedbackText,
    fullWidth,
    style,
  } = props
  const { t } = useTranslation()
  const [label, setLabel] = useState('')

  // MARK: --- Functions ---
  function handleChange(targetValue) {
    if (onChange) {
      onChange(targetValue)
    }
  }

  function handleBlur() {
    if (onBlur) {
      onBlur()
    }
  }

  function handleFocus() {
    if (onFocus) {
      onFocus()
    }
  }

  // MARK --- Hooks ---
  useEffect(() => {
    const findSelectedOption = _.find(options, {
      value: value,
    })
    setLabel(findSelectedOption?.name ?? '')
  }, [options, value])

  // useEffect(() => {
  //   if (options.length === 0) return
  //   if (options[0].name === 'Tất cả' || options[0].name === t('All'))
  //     setLabel(options[0].name)
  // }, [options, t])

  return (
    <div>
      <Dropdown style={style} className="KTFormSelect">
        <Dropdown.Toggle
          disabled={disabled}
          variant=""
          style={{
            backgroundColor: disabled ? '#f3f6f9' : '#fff',
            border: '1px solid #e4e6ef',
          }}
          className={`
                    form-control custom-dropdown-toggle d-flex justify-content-between  
                    ${
                      isCustom
                        ? 'custom-select shadow-none'
                        : `form-select ${size}`
                    }
                    ${
                      enableCheckValid && isTouched
                        ? `${
                            isValid
                              ? `${showValidState ? 'is-valid' : ''}`
                              : 'is-invalid'
                          }`
                        : ''
                    }
                `}
          id="dropdown-basic"
        >
          {label}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="overflow-auto max-h-400px"
          style={{ width: fullWidth ? '100%' : 'auto' }}
        >
          {options.map((item, index) => {
            if (!!item.name || item.name == 0)
              return (
                <Dropdown.Item
                  className="d-flex justify-content-between align-items-center"
                  key={index}
                  onClick={() => {
                    handleChange(item.value)
                    setLabel(item.name)
                  }}
                >
                  {t(item.name)}
                  {(!!value || value == 0) && value === item.value && (
                    <i className="text-success fa-solid fa-check ml-4"></i>
                  )}
                </Dropdown.Item>
              )
          })}
        </Dropdown.Menu>
      </Dropdown>
      {enableCheckValid &&
        !isValid &&
        isTouched &&
        !_.isEmpty(feedbackText) && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{feedbackText}</div>
          </div>
        )}
      {!_.isEmpty(text) && <span className="form-text text-muted">{text}</span>}
    </div>
  )
}

export default KTFormSelect
