import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { components } from 'react-select'
import './style.scss'
const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <ToolTipOption title={props.data.name}>
        <div className="row mx-2 py-3">
          <span className="fw-medium col-4 ">{props.data.label}</span>
          <span className="fw-medium text-truncate  col-8">
            {props.data.name}
          </span>
        </div>
      </ToolTipOption>
    </components.Option>
  )
}

const ToolTipOption = ({ title, children }) => {
  return (
    <OverlayTrigger
      flip
      // show
      placement="bottom-start"
      delay={500}
      offset={10}
      overlay={<Tooltip className="Kyhieu-toolTip fw-medium">{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

export default CustomOption
