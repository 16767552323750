import { useMutation, useQuery } from '@tanstack/react-query'
import { Space, Tooltip } from 'antd'
import invoiceReportApi from 'api/invoiceReportApi'
import ticketApi from 'api/ticketApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import ExportTicketModal from 'features/HandleTicket/Components/Modals/ExportTicketModal'
import SendMailModal from 'features/HandleTicket/Components/Modals/SendMailModal'
import ViewDetailModal from 'features/HandleTicket/Components/Modals/ViewDetailModal'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import { colors } from 'general/constants/ColorConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import InvoiceReportHelper from 'general/helpers/InvoiceReportHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useExportById from 'hooks/Mutations/useExportById'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppDispatch } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { assign, get, keys, map } from 'lodash'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export default function CanceledTicketScreen() {
  const companyUnit = useSelector((s) => s.auth.currentCompany.companyName)
  const dispatch = useAppDispatch()
  const { navigate } = useRouter()
  const [modalState, setModalState] = useState({
    canceled: false,
    createdNew: false,
    view: false,
    sendMail: false,
    upload: false,
  })

  /**
   * Toggles the state of a modal.
   * @param {Object} options - The options object.
   * @param {keyof modalState} options.modalName - The name of the modal to toggle.
   * @param {boolean} options.status - The status to set for the modal.
   */

  const toggleModalState = ({ modalName, status }) =>
    setModalState((prev) => {
      const notModalName = keys(prev).filter((key) => key !== modalName)
      const turnEverythingOff = map(notModalName, (key) => ({ [key]: false }))
      return {
        ...assign({}, ...turnEverythingOff),
        [modalName]: status,
      }
    })

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')

  const [filters, setFilters] = useFilter(
    'canceledTicketList',
    'gFilterCanceledTicket',
  )
  const {
    data: listData,
    isFetching: isGettingList,
    refetch: refreshData,
  } = useQuery({
    queryKey: ['canceledTicketList', filters, debouncedSearch],
    queryFn: () =>
      ticketApi.list({ ...filters, q: debouncedSearch, ticketType: 'CANCEL' }),
    select: (res) => ({
      total: get(res, 'count', 0),
      rows: get(res, 'rows', []),
    }),
  })

  const cancelTicketMutation = useMutation({
    mutationKey: ['cancelTicket'],
    mutationFn: (ticketIds) => ticketApi.bulkCancels(ticketIds),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: () => {
      ToastHelper.showSuccess('Huỷ vé thành công')
      toggleModalState({
        modalName: 'canceled',
        status: false,
      })
      refreshData()
    },
  })

  const { exportFunc: exportReportFunc } = useExportById(
    (reportId) => invoiceReportApi.preview({ reportId, invoiceType: 'TICKET' }),
    'Biên bản hủy.pdf',
    'application/pdf',
  )

  const default_columns = useMemo(() => {
    /**
     * @type {import('react-data-table-component').TableColumn<ReturnTicketData>[]}
     */
    const arr = [
      {
        name: 'Đơn vị',
        center: true,
        grow: 2,
        cell: () => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {companyUnit}
          </p>
        ),
      },
      {
        name: 'Ký hiệu',
        center: true,
        cell: ({ serial }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {serial}
          </p>
        ),
      },
      {
        name: 'Biên bản điện tử',
        center: true,
        width: '200px',
        cell: ({ Report, ticketId }) => (
          <>
            <Tooltip
              title={InvoiceReportHelper.renderReportStatus(
                Report?.reportStatus,
              )}
              placement="bottom"
            >
              <p
                data-tag="allowRowEvents"
                className={Utils.cn(
                  'm-0 mr-4 cursor-pointer',
                  Report?.no ? 'text-danger' : 'text-primary',
                )}
                onClick={() => {
                  if (!Report) {
                    navigate(
                      `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-huy/them-moi/${ticketId}`,
                    )
                    return
                  }

                  if (Report?.reportStatus == 1) {
                    navigate(
                      `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-huy/cap-nhat/${ticketId}`,
                    )
                    return
                  }

                  navigate(
                    `/hoa-don-dau-ra/tem-ve/ve-dien-tu/bien-ban-huy/xem/${ticketId}`,
                  )
                }}
              >
                {Report?.no ? Report?.no : 'Lập biên bản'}
              </p>
            </Tooltip>
            {!Report
              ? null
              : Report.reportStatus != 1 && (
                  <i
                    className="fa-light fa-eye cursor-pointer"
                    style={{ color: 'inherit' }}
                    title="Xem biên bản điện tử"
                    onClick={() => exportReportFunc(Report.reportId)}
                  ></i>
                )}
          </>
        ),
      },
      {
        name: 'Ngày xuất vé',
        center: true,
        selector: ({ dateRelease }) => dayjs(dateRelease).format('DD/MM/YYYY'),
      },
      {
        name: 'Số vé',
        center: true,
        cell: ({ no }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {no}
          </p>
        ),
      },
      {
        name: 'Mã CQT',
        grow: 2,
        center: true,
        selector: ({ taxAuthorityCode }) => taxAuthorityCode,
      },
      {
        name: 'Tổng tiền',
        center: true,
        cell: ({ totalPrice }) => (
          <p style={{ textAlign: 'right' }} className="w-100 m-0">
            {Utils.formatCurrency(totalPrice)}
          </p>
        ),
      },
      {
        name: 'Ngày huỷ',
        center: true,
        cell: ({ canceledDate }) => (
          <p style={{ textAlign: 'center' }} className="w-100 m-0">
            {dayjs(canceledDate).format('DD/MM/YYYY')}
          </p>
        ),
      },
      {
        name: 'Lí do huỷ',
        center: true,
        cell: ({ errAnnouceReason }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {errAnnouceReason}
          </p>
        ),
      },
      {
        name: 'Hành động',
        center: true,
        button: true,
        style: {
          position: 'sticky',
          '&.row-hover': {
            right: 0,
            backgroundColor: colors.cellColorBg,
          },
        },
        cell: () => (
          <>
            <TableAction
              titleActionText="Xem"
              icon={<IconAction className="fa-light fa-eye" />}
              onClick={() =>
                toggleModalState({
                  modalName: 'view',
                  status: true,
                })
              }
            />
            <TableAction
              titleActionText="Gửi thông báo hủy HĐ"
              icon={<IconAction className="fa-light fa-envelope" />}
              onClick={() => {
                toggleModalState({ modalName: 'sendMail', status: true })
              }}
            />
          </>
        ),
      },
    ]
    return arr
  }, [companyUnit, exportReportFunc, navigate])

  const dynamicColums = useAppDnD(default_columns)

  return (
    <ContentContainer>
      <ContentContainer.Header
        titleContent={'Huỷ vé'}
        toolBar={
          <Space>
            <CustomAntButton
              text="Huỷ vé"
              iconCn="fa-regular fa-plus "
              antProps={{
                type: 'primary',
                onClick: () =>
                  toggleModalState({
                    modalName: 'canceled',
                    status: true,
                  }),
              }}
            />
            <AppDnD
              defaultColumns={default_columns}
              localStorageName={PreferenceKeys.canceledTicket}
            />
          </Space>
        }
      >
        <Space wrap align="center">
          <CustomAntInput
            type="search"
            inputProps={{
              defaultValue: search,
              onChange: (e) => setSearch(e.target.value),
            }}
            borderRadius="sm"
          />
          <FilterPopover
            menuPosition="bottomLeft"
            savedKey="gFilterCanceledTicket"
            onApply={setFilters}
            defaultValues={[
              {
                label: 'Trạng thái biên bản',
                alias: 'Trạng thái biên bản',
                colSpan: 24,
                formName: 'reportStatus',
                options: SelectOptions.reportStatus,
                value: SelectOptions.reportStatus[0].value,
              },
              {
                label: 'Trạng thái thông báo sai sót',
                alias: 'Trạng thái TBSS',
                colSpan: 24,
                formName: 'invoiceErrAnnouceStatus',
                options: SelectOptions.invoiceErrAnnouceStatus,
                value: SelectOptions.invoiceErrAnnouceStatus[0].value,
              },
            ]}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColums}
          dataSource={listData?.rows}
          isLoading={isGettingList}
          pagination={{
            currentPage: filters.page,
            total: listData?.total,
            rowsPerPage: filters.limit,
            onChangePage: (newPage) => {
              const iNewPage = parseInt(newPage)
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 1,
              })
            },
          }}
        />
      </ContentContainer.Body>
      <ExportTicketModal
        title={'Chọn vé huỷ'}
        ticketType={'CAN_CANCELLED'}
        open={modalState.canceled}
        onOpenChange={(s) =>
          toggleModalState({
            modalName: 'cancel',
            status: s,
          })
        }
        hasTabs={false}
        handleApplyChanges={(activedRows) =>
          cancelTicketMutation.mutate(map(activedRows))
        }
      />
      <ViewDetailModal
        open={modalState.view}
        onOpenChange={(s) =>
          toggleModalState({
            modalName: 'view',
            status: s,
          })
        }
      />
      <SendMailModal
        open={modalState.sendMail}
        onOpenChange={(s) =>
          toggleModalState({
            modalName: 'sendMail',
            status: s,
          })
        }
      />
    </ContentContainer>
  )
}
