const { default: axiosClient } = require('./axiosClient')

const userFunction = {
  // lay toan bo
  getAll: () => {
    const url = '/userFunction'
    return axiosClient.get(url)
  },
}

export default userFunction
