import { Space, Typography } from 'antd'
import { useFormik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useRef } from 'react'

export default function ModalConnectConfig({
  title,
  description,
  requirePassCode = true,
  open,
  onOpenChange,
  method,
  onApply,
}) {
  const modalRef = useRef(null)
  const formik = useFormik({
    initialValues: {
      agreementUUID: '',
      passcode: '',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await onApply(values)
        resetForm()
      } catch (e) {
        console.log(e)
      }
    },
    validationSchema: requirePassCode
      ? ValidationSchema.connectConfig
      : ValidationSchema.connectConfigNotPasscode,
    enableReinitialize: true,
  })

  const handleApplyUSB_TOKEN = async () => {
    onApply()
  }

  return (
    <DraggableModal
      ref={modalRef}
      title={title}
      description={description}
      isOpen={open}
      onOpenChange={(state) => {
        onOpenChange(state)
        formik.resetForm()
      }}
      handleApplyChanges={() => {
        method === 'HSM' ? formik.submitForm() : handleApplyUSB_TOKEN()
        // onOpenChange(false)
      }}
      width={500}
    >
      {method === 'HSM' ? (
        <Space direction="vertical" size={'middle'} className="w-100">
          <div className="w-100">
            <CustomLabel
              htmlFor="provider-service-hsm"
              text="Nhà cung cấp dịch vụ HSM"
              width={'100%'}
              fontWeight={500}
            />
            <CustomAntSelect
              options={[{ label: 'Icorp', value: 'icorp' }]}
              value={'icorp'}
              allowClear={false}
              showSearch={false}
            />
          </div>

          <div className="w-100">
            <CustomLabel
              htmlFor="agreement-uuid"
              isRequired
              text="Số thoả thuận (agreementUUID)"
              fontWeight={500}
              width={'100%'}
            />
            <CustomAntInput
              borderRadius="sm"
              type="text"
              inputProps={{
                autoComplete: 'off',
                name: 'agreement-uuid',
                placeholder: 'Nhập số thoả thuận',
                status:
                  formik.errors.agreementUUID &&
                  formik.touched.agreementUUID &&
                  'error',
                ...formik.getFieldProps('agreementUUID'),
              }}
            />
            {formik.touched.agreementUUID ? (
              <Typography.Text type="danger">
                {formik.errors.agreementUUID}
              </Typography.Text>
            ) : null}
          </div>

          {requirePassCode && (
            <div className="w-100">
              <CustomLabel
                htmlFor="passcode"
                isRequired
                text="Mã xác thực"
                fontWeight={500}
              />
              <CustomAntInput
                borderRadius="sm"
                type="text"
                inputProps={{
                  autoComplete: 'off',
                  name: 'passcode',
                  placeholder: 'Nhập mã xác thực',
                  status:
                    formik.errors.passcode &&
                    formik.touched.passcode &&
                    'error',
                  ...formik.getFieldProps('passcode'),
                }}
              />
              {formik.touched.passcode ? (
                <Typography.Text type="danger">
                  {formik.errors.passcode}
                </Typography.Text>
              ) : null}
            </div>
          )}
        </Space>
      ) : null}
    </DraggableModal>
  )
}
