import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import ListCart from 'features/Cart/components/ListCart'
import Voucher from 'features/Cart/components/Voucher'
import { useDispatch } from 'react-redux'
import { getAllCart } from 'features/Cart/CartSlice'

// import { clearValueTemPayment } from "features/Payment/components/TempPayment/TemPaymentSlice";

function CartScreen() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllCart())
  }, [])

  return (
    <Container fluid={'xxl'}>
      {/* <h1>Giỏ hàng</h1> */}
      <div className="pt-10">
        <Row>
          <Col lg={8} md={12} sm={12}>
            <ListCart />
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Voucher />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default CartScreen
