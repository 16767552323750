import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Iframe from 'react-iframe'
import { useDispatch } from 'react-redux'

import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import DownloadXMLFileHelper from 'general/helpers/DownloadXMLFileHelper'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import ModalConvertPaper from '../ModalConvertPaper'
import './style.scss'

function ModalViewInvoice(props) {
  const dispatch = useDispatch()
  const {
    invoiceUrl,
    lookupCode,
    invoice,
    showModalViewInvoice,
    setShowModalViewInvoice,
  } = props

  // const [invoiceUrl, setInvoiceUrl] = useState()
  const [showModalConvertPaper, setShowModalConvertPaper] = useState(false)

  // useEffect(() => {
  //   const exporting = async () => {
  //     const res = await invoiceApi.exportInvoiceByLookupCode(lookupCode)
  //     let newFile = new File([res], 'Mẫu hóa đơn.pdf', {
  //       type: 'application/pdf',
  //     })
  //     var fileURL = URL.createObjectURL(newFile)
  //     setInvoiceUrl(fileURL)
  //   }

  //   if (showModalViewInvoice && invoice) {
  //     dispatch(setAppSpinning(true))
  //     try {
  //       exporting()
  //     } catch (e) {
  //       console.log(e)
  //     } finally {
  //       dispatch(setAppSpinning(false))
  //     }
  //   }
  // }, [showModalViewInvoice])

  const handleClose = () => setShowModalViewInvoice(false)

  const handleConvertPaper = async (converterName) => {
    dispatch(setAppSpinning(true))
    var res
    try {
      res = await invoiceApi.convertPaper({ lookupCode, converterName })
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }
    let newFile = new File([res], 'Hóa đơn chuyển đổi.pdf', {
      type: 'application/pdf',
    })
    var fileURL = URL.createObjectURL(newFile)
    window.open(fileURL, '_blank')
  }

  return (
    <>
      <Modal
        show={showModalViewInvoice}
        onHide={handleClose}
        centered
        className="ModalViewInvoice"
        style={{ zIndex: 1040 }}
      >
        <Modal.Header closeButton>
          <div className="w-100 d-flex flex-row justify-content-between">
            <Modal.Title>Thông tin hóa đơn</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row h-100">
            <Iframe
              url={invoiceUrl}
              width="1200px"
              height="100%"
              display="block"
              className="pl-4"
            />

            <div className="d-flex flex-column px-4" style={{ minWidth: 280 }}>
              <p className="mb-3 p-title">Thông tin hóa đơn</p>

              <div className="d-flex flex-row justify-content-between border-bottom px-2 py-3">
                <span className="p-item">Số hóa đơn:</span>
                <span className="p-item">{invoice?.no}</span>
              </div>

              <div className="d-flex flex-row justify-content-between border-bottom px-2 py-3">
                <span className="p-item">Ký hiệu:</span>
                <span className="p-item">{invoice?.serial}</span>
              </div>

              <div className="d-flex flex-row justify-content-between border-bottom p-2">
                <span className="p-item">Tổng tiền thanh toán:</span>
                <span className="p-item">
                  {_.isNumber(invoice?.totalAfterTax)
                    ? Utils.formatNumber(invoice.totalAfterTax) +
                      ' ' +
                      invoice.currency
                    : invoice.totalAfterTax + invoice.currency}{' '}
                </span>
              </div>

              {/* <div className="d-flex flex-row justify-content-between border-bottom p-2">
              <span className="p-item">Hạn thanh toán:</span>
              <span className="p-item">Không có hạn</span>
            </div> */}

              <div className="mt-10">
                <button
                  className="w-100 btn btn-outline-primary d-flex flex-row align-items-center mb-3"
                  onClick={() => {
                    window.open(invoiceUrl, '_blank')
                  }}
                >
                  <i className="fa-solid fa-file-pdf"></i>
                  <span className="flex-fill">Tải hóa đơn PDF</span>
                </button>

                <button
                  className="w-100 btn btn-outline-secondary d-flex flex-row align-items-center mb-3"
                  onClick={() => DownloadXMLFileHelper({ lookupCode })}
                >
                  <i className="fa-regular fa-download"></i>
                  <span className="flex-fill">Tải hóa đơn XML</span>
                </button>

                <button
                  className="w-100 btn btn-outline-success d-flex flex-row align-items-center mb-3"
                  onClick={() => setShowModalConvertPaper(true)}
                >
                  <i className="fa-regular fa-copy"></i>
                  <span className="flex-fill">Chuyển thành hóa đơn giấy</span>
                </button>

                <button
                  className="w-100 btn btn-outline-dark d-flex flex-row align-items-center mb-3"
                  onClick={() =>
                    window.open(
                      'https://tracuunnt.gdt.gov.vn/tcnnt/mstdn.jsp',
                      '_blank',
                    )
                  }
                >
                  <i className="fa-regular fa-search"></i>
                  <span className="flex-fill">
                    Tra cứu MST người bán trên TCT
                  </span>
                </button>

                <button
                  className="w-100 btn btn-outline-danger d-flex flex-row align-items-center mb-3"
                  onClick={() => {
                    var wnd = window.open(invoiceUrl)
                    wnd.print()
                  }}
                >
                  <i className="fa-regular fa-print"></i>
                  <span className="flex-fill">In hóa đơn</span>
                </button>

                <button className="w-100 btn btn-outline-warning d-flex flex-row align-items-center mb-3">
                  <i className="fa-regular fa-pen-nib"></i>
                  <span className="flex-fill">Ký hóa đơn</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ModalConvertPaper
        showModalConvertPaper={showModalConvertPaper}
        setShowModalConvertPaper={setShowModalConvertPaper}
        handleConvertPaper={handleConvertPaper}
      />
    </>
  )
}

export default ModalViewInvoice
