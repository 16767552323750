import axiosClient from './axiosClient'

const goodsGroupApi = {
  find: (params, signal) => {
    const url = '/goods-group/find'
    return axiosClient.get(url, { params, signal })
  },

  create: (params) => {
    const url = '/goods-group/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = `/goods-group/update/${params.goodsGroupId}`
    return axiosClient.put(url, params)
  },

  delete: (params) => {
    const url = '/goods-group/delete'
    return axiosClient.delete(url, {
      data: {
        goodsGroupIds: params,
      },
    })
  },
}

export default goodsGroupApi
