import invoiceApi from 'api/invoiceApi'
import clsx from 'clsx'
import customProtocolCheck from 'custom-protocol-check'
import dayjs from 'dayjs'
import EnglishNumber from 'english-number'
import exportFromJSON from 'export-from-json'
import { ADJUST_INVOICE_VNESE } from 'general/constants/AppConstants'
import wsHelperInstance from 'general/helpers/WebSocketHelper'
import { sha256 } from 'js-sha256'
import { parse } from 'js2xmlparser'
import JSZip from 'jszip'
import _ from 'lodash'
import moment from 'moment'
import { stringify } from 'query-string'
import {
  InvalidFormatError,
  InvalidNumberError,
  parseNumberData,
  ReadingConfig,
  readNumber,
  UnitNotEnoughError,
  validateNumber,
} from 'read-vietnamese-number'
import * as XLSX from 'xlsx'

// Util functions
const Utils = {
  /**
   * Lock body document scroll
   */
  lockedBodyScroll: () => {
    document.body.classList.add('body-overflow-hidden')
  },

  /**
   * Unlock body document scroll
   */
  unlockedBodyScroll: () => {
    document.body.classList.remove('body-overflow-hidden')
  },

  /**
   *
   * @param {string} text Text can copy vao clipboard
   * @param {function} callback Callback khi hoan thanh copy
   */
  copyTextToClipboard: (text, callback) => {
    navigator.clipboard.writeText(text)
    if (_.isFunction(callback)) {
      callback()
    }
  },

  /**
   * Mo url trong tab moi
   * @param {string} url Duong dan
   */
  openInNewTab: (url) => {
    window.open(url, '_blank').focus()
  },

  /**
   * Mo url trong tab hien tai
   * @param {string} url Duong dan
   */
  openInCurrentTab: (url) => {
    // window.open(url);
    window.location = url
  },

  openPage: (path) => {
    const { hostname, port, protocol } = window.location
    const baseURL = port ? `${hostname}:${port}` : hostname
    const url = protocol + '//' + baseURL + path

    window.location.href = url
  },

  /**
   * Get url
   * @param {string} url Duong dan
   * @returns
   */
  getFullUrl: (url) => {
    if (url && !url.startsWith('http') && !url.startsWith('blob')) {
      // console.log(`${process.env.REACT_APP_BASE_URL}${encodeURI(url)}`);
      return `${process.env.REACT_APP_BASE_FILE_URL}${encodeURI(url)}`
    }
    // console.log(encodeURI(url));
    return encodeURI(url)
  },

  /**
   * Dinh dang number: 100.300,345
   * @param {number} iNumber So can dinh dang
   * @returns
   */
  formatNumber: (iNumber, fractionDigits = 0) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(iNumber)
  },

  formatDecimal: (a, x) => {
    // console.log(x);
    let tmp1 = Math.floor(a / Math.pow(10, x))
    let tmp2 = Math.floor(a % Math.pow(10, x))
    let formattedNumber = tmp1.toLocaleString('vi', {
      minimumFractionDigits: 0,
    })
    if (tmp2 == 0) {
      return formattedNumber
    } else return formattedNumber + ',' + tmp2.toString()
  },

  /**
   * Set empty key to null
   * @param {object} items
   */
  setEmptyKeyToNull: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === '' || value === undefined) {
        items[key] = null
      }
    }
  },

  // Change empty to null
  formatEmptyKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === '' || value === undefined) {
        items[key] = null
      }
    }
  },

  /**
   * Remove null key
   * @param {object} items
   */
  removeNullKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (_.isNull(value)) {
        delete items[key]
      }
    }
  },

  /**
   * Get current window url
   * @returns
   */
  getCurrentUrl: () => {
    return window.location.href
  },

  /**
   * Scroll div to bottom
   * @param {string} id id of element
   */
  scrollToBottom: (id) => {
    var div = document.getElementById(id)
    if (div) {
      div.scrollTop = div.scrollHeight - div.clientHeight
    }
  },

  toBase64: (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    }),

  isFunction: (fn) => {
    return fn && {}.toString.call(fn) === '[object Function]'
  },

  listToMatrix: (list, elementsPerSubArray) => {
    var matrix = [],
      i,
      k

    for (i = 0, k = -1; i < list.length; i++) {
      if (i % elementsPerSubArray === 0) {
        k++
        matrix[k] = []
      }

      matrix[k].push(list[i])
    }

    return matrix
  },

  filterStyles: (styles) => {
    return styles
    // const { ...rest } = styles

    // return rest
  },

  makeId: (length) => {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },

  // sha256
  sha256: (text) => {
    return sha256(text)
  },

  // Check object null|undefine
  isObjectNull: (obj) => {
    return obj === null || obj === undefined || obj === 'NULL' || obj === 'null'
  },

  // Check object empty
  isObjectEmpty: (obj) => {
    return (
      Utils.isObjectNull(obj) ||
      (Object.keys(obj).length === 0 && obj.constructor === Object)
    )
  },

  getRangeDates: (range) => {
    const startDate = new Date(range.startDate)
    const endDate = new Date(range.endDate)
    const datesArray = []

    for (
      let d = moment(startDate).add(1, 'day');
      d < moment(endDate).add(1, 'day');
      d.add(1, 'day')
    ) {
      datesArray.push(moment(d).format('DD/MM/YYYY'))
    }

    return datesArray
  },
  /** @param {{startDate: string, endDate: string}} rangeDate */
  getNewRangeDate: (rangeDate) => {
    const diff = dayjs(rangeDate.endDate).diff(
      dayjs(rangeDate.startDate),
      'days',
    )
    return _.range(diff + 1).map((day) =>
      dayjs(rangeDate.startDate).add(day, 'day').format('DD/MM/YYYY'),
    )
  },

  // format date time
  formatDateTime: (sDateTime, sFormat = 'DD/MM/YYYY HH:mm', utc = false) => {
    if (utc) {
      return moment(sDateTime).utc().format(sFormat)
    }
    return moment(sDateTime).local().format(sFormat)
  },

  removeDuplicates: (arr) => {
    return [...new Set(arr)]
  },

  blurColor: (colorCode, opacity) => {
    return `rgba(${parseInt(colorCode.slice(1, 3), 16)}, ${parseInt(
      colorCode.slice(3, 5),
      16,
    )}, ${parseInt(colorCode.slice(5, 7), 16)}, ${opacity})`
  },

  insertElement: (array, element, index) => {
    return [...array.slice(0, index), element, ...array.slice(index)]
  },

  convertToVietnameseWords: (
    num,
    unit = '',
    separation = 'phẩy',
    prefix,
    endSymbol = '',
    afterdecimal = '',
    zeroInten,
    zeroInThousand,
    evenDisplay,
  ) => {
    // exception handler
    // set value if it null or undefined
    unit = unit ?? ''
    endSymbol = endSymbol ?? ''
    afterdecimal = afterdecimal ?? ''

    let after = String(num).includes(',') ? (afterdecimal ?? '') : ''
    const config = new ReadingConfig()
    config.unit = [unit]
    const number = num.toString().replaceAll('.', '').replaceAll(',', '.')
    try {
      const validatedNumber = validateNumber(number) // Step 2
      const numberData = parseNumberData(config, validatedNumber) // Step 3
      let result = _.capitalize(readNumber(config, numberData)) // Step 4
      if (zeroInten && zeroInThousand) {
        result = result.replace('lẻ', zeroInten)
        result = result.replace('nghìn', zeroInThousand)
      }

      if (
        Number.isInteger(Utils.parseFormattedNumberToFloat(num)) &&
        evenDisplay
      ) {
        result = result + ' chẵn'
      }
      let beforeMoney, afterMoney
      if (result.includes('chấm')) {
        beforeMoney = result.split('chấm')[0]
        afterMoney = result.split('chấm')[1].replace(_.toLower(unit), '') || ''
        result = `${beforeMoney} ${separation} ${afterMoney} ${after} ${unit}`
      } else {
        result = `${result.replace('chấm', separation)} ${after}`
      }

      if (prefix) {
        if (endSymbol) {
          return prefix + ' ' + result + ' ' + endSymbol
        } else {
          return prefix + ' ' + result
        }
      } else {
        if (endSymbol) {
          return result + ' ' + endSymbol
        } else {
          return result
        }
      }
    } catch (err) {
      if (err instanceof InvalidFormatError) {
        console.error('Định dạng input không hợp lệ')
      } else if (err instanceof InvalidNumberError) {
        console.error('Số không hợp lệ')
      } else if (err instanceof UnitNotEnoughError) {
        console.error('Không đủ đơn vị đọc số')
      }
    }
  },
  convertToEnglishWords: (
    num,
    unit,
    separation = 'and',
    prefix,
    endSymbol,
    afterdecimal = '',
  ) => {
    let after = String(num).includes(',') ? afterdecimal : ''
    const number = num.toString().replaceAll('.', '').replaceAll(',', '.')
    const englishString = EnglishNumber.nameOf(number)
    // const replaceString = englishString.replace(' and', '');
    let result = _.capitalize(englishString)
    let beforeMoney, afterMoney
    if (result.includes('plus')) {
      beforeMoney = result.split('plus')[0]
      afterMoney = result.split('plus')[1].replace(_.toLower(unit), '') || ''
      result = `${beforeMoney} ${unit} ${separation} ${afterMoney} ${after}`
    } else {
      result = `${result.replace('plus', separation)} ${after}`
    }

    if (prefix) {
      if (endSymbol) {
        return prefix + ' ' + result + ' ' + endSymbol
      } else {
        return prefix + ' ' + result
      }
    } else {
      if (endSymbol) {
        return result + ' ' + endSymbol
      } else {
        return result
      }
    }
  },

  formatCurrency: (str, currency = '', separation = '.') => {
    const format = String(str).replace(/\B(?=(\d{3})+(?!\d))/g, separation)
    return format + currency
  },

  formatDate: (date, formatType = 'YYYY-MM-DD') => {
    return moment(date).format(formatType)
  },

  numberToWords: (
    number,
    language,
    prefix,
    unit,
    separation,
    endSymbol,
    afterdecimal,
    zeroInten,
    zeroInThousand,
    evenDisplay,
  ) => {
    if (language === 'vi') {
      return Utils.convertToVietnameseWords(
        number,
        unit,
        separation,
        prefix,
        endSymbol,
        afterdecimal,
        zeroInten,
        zeroInThousand,
        evenDisplay,
      )
    } else if (language === 'en') {
      return Utils.convertToEnglishWords(
        number,
        unit,
        separation,
        prefix,
        endSymbol,
        afterdecimal,
      )
    } else {
      return 'Unsupported language'
    }
  },

  shallowEqualObject: (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false
      }
    }

    return true
  },

  shallowEqualCertificate: (object1, object2) => {
    const keys = ['idValue', 'issuedBy', 'issuedDate', 'expireDate']

    for (let key of keys) {
      if (key.includes('Date')) {
        let date1 = dayjs(object1[key])
        let date2 = dayjs(object2[key])

        if (date1.isAfter(date2, 's') || date1.isBefore(date2, 's')) {
          return false
        }
      } else if (object1[key] !== object2[key]) {
        return false
      }
    }

    return true
  },

  exportXmlFile: ({ data, fileName, _parse = true }) => {
    if (_parse) {
      const xmlOptions = {
        declaration: {
          include: false,
        },
      }
      let xmlString
      xmlString = parse('TDiep', data, xmlOptions)
      const downloadLink = document.createElement('a')
      const fileBlob = new Blob([xmlString], { type: 'application/xml' })
      downloadLink.href = URL.createObjectURL(fileBlob)
      downloadLink.download = fileName + '.xml'
      downloadLink.click()
    } else {
      exportFromJSON({
        data,
        fileName,
        extension: 'xml',
        exportType: 'txt',
      })
    }
  },

  depthSearch: (listOfTree) => {
    let responseDepth = 0

    // Hàm đệ quy để tính toán chiều sâu
    function treeDepthSearch(tree, depth) {
      if (tree?.ChildrenUnits?.length === 0) {
        responseDepth = Math.max(responseDepth, depth)
        return
      }

      for (let i = 0; i < tree?.ChildrenUnits?.length; i++) {
        treeDepthSearch(tree?.ChildrenUnits[i], depth + 1)
      }
    }

    treeDepthSearch(listOfTree, 1)

    return responseDepth
  },

  findDepthFromChildToParent: (treeObject, childNode) => {
    let maxDepth = 0

    // Hàm đệ quy để tìm chiều sâu từ nút con đến nút cha
    function findDepthInTree(tree, _childNode, currentDepth) {
      if (tree.companyUnitId === _childNode.companyUnitId) {
        if (currentDepth > maxDepth) {
          maxDepth = currentDepth
        }
        return
      }

      if (Array.isArray(tree.ChildrenUnits)) {
        for (let i = 0; i < tree.ChildrenUnits.length; i++) {
          findDepthInTree(tree.ChildrenUnits[i], _childNode, currentDepth + 1)
        }
      }
    }

    // Xử lý từng nút gốc
    for (let i = 0; i < treeObject.length; i++) {
      findDepthInTree(treeObject[i], childNode, 1)
    }

    return maxDepth
  },

  handleHoverRow(id, event) {
    let rowId = null
    if (event.target.parentNode.id.includes('row')) {
      rowId = event.target.parentNode.id
    } else if (event.target.id) {
      rowId = event.target.id
    }

    let divElement = document.getElementById(rowId)
    if (divElement) {
      let rowHeight = divElement?.offsetHeight
      let listChild = Array.from(divElement?.children)

      let actionChild = listChild?.find((item) => item?.children[0]?.id == id)
      if (actionChild) {
        actionChild.classList.add('custom-hover-cell')
        actionChild.style.height = rowHeight
      }
    }
  },

  handleMouseLeaveRow(row, event) {
    let rowId = null
    if (event.target.parentNode.id.includes('row')) {
      rowId = event.target.parentNode.id
    } else if (event.target.id) {
      rowId = event.target.id
    }
    let divElement = document.getElementById(rowId)
    if (divElement) {
      let customHoverCells =
        divElement.getElementsByClassName('custom-hover-cell')

      // Convert HTMLCollection to an array
      let customHoverCellsArray = Array.from(customHoverCells)

      // Remove each custom-hover-cell element
      customHoverCellsArray.forEach((element) => {
        element.classList.remove('custom-hover-cell')
      })
    }
  },

  checkFullUrl: (url) => {
    if (url && url.startsWith('http')) {
      return true
    }
    return false
  },

  convertString: (inputString) => {
    // Remove accents and convert to lowercase
    const normalizedString = inputString
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()

    // Remove spaces and convert "đ" to "d"
    const convertedString = normalizedString
      .replace(/\s+/g, '')
      .replace(/đ/g, 'd')

    return convertedString
  },

  capitalizeFirstLetter: (str) => {
    return str.charAt(0).toUpperCase().concat(str.slice(1))
  },

  shortenDate: (date) => {
    let newDate
    if (date) {
      newDate = date.slice(0, 10)
    }
    return newDate
  },

  viewPdfInvoice: async (row, dispatch, setAppSpinning) => {
    let invoiceId = row?.invoiceId
    if (!invoiceId) return

    dispatch(setAppSpinning(true))
    var res
    try {
      res = await invoiceApi.exportInvoiceById(invoiceId)
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }

    let newFile = new File([res], 'Hóa đơn.pdf', { type: 'application/pdf' })
    var fileURL = URL.createObjectURL(newFile)
    window.open(fileURL, '_blank')
  },

  openToolSignInvoice: (
    url,
    accessToken,
    sendEmailInfosString,
    notInstalledCb,
  ) => {
    // const MAX_WAIT_SIGN_INVOICE = process.env.REACT_APP_MAX_WAIT_SIGN
    if (url && accessToken) {
      customProtocolCheck(
        url,
        notInstalledCb,
        () => {
          Utils.openInCurrentTab(url)
        },
        2500,
      )
      // dispatch(setAppSpinning(true));
      wsHelperInstance.onExecutingSignInvoice(accessToken, sendEmailInfosString)
      // Global.gExecutingSignInvoiceTimeOut = setTimeout(() => {
      //   dispatch(setAppSpinning(false));
      // }, MAX_WAIT_SIGN_INVOICE);
    }
  },

  openToolSignErrAnnouce: (url, accessToken, notInstalledCb) => {
    // const MAX_WAIT_SIGN_ERR_ANNOUCE = process.env.REACT_APP_MAX_WAIT_SIGN
    if (url && accessToken) {
      customProtocolCheck(
        url,
        notInstalledCb,
        () => {
          Utils.openInCurrentTab(url)
        },
        2500,
      )

      // dispatch(setAppSpinning(true));
      wsHelperInstance.onExecutingSignErrAnnouce(accessToken)
      // Global.gExecutingSignErrAnnouceTimeOut = setTimeout(() => {
      //   dispatch(setAppSpinning(false));
      // }, MAX_WAIT_SIGN_ERR_ANNOUCE);
    }
  },

  openToolSignSummaryInvoice: (
    url,
    accessToken,
    sendEmailInfosString,
    notInstalledCb,
  ) => {
    // const MAX_WAIT_SIGN_INVOICE = process.env.REACT_APP_MAX_WAIT_SIGN
    if (url && accessToken) {
      customProtocolCheck(
        url,
        notInstalledCb,
        () => {
          Utils.openInCurrentTab(url)
        },
        2500,
      )
      // dispatch(setAppSpinning(true));
      wsHelperInstance.onExecutingSignSummaryInvoice(
        accessToken,
        sendEmailInfosString,
      )
      // Global.gExecutingSignInvoiceTimeOut = setTimeout(() => {
      //   dispatch(setAppSpinning(false));
      // }, MAX_WAIT_SIGN_INVOICE);
    }
  },

  openToolSignTemplatePaper: (
    url,
    accessToken,
    //  dispatch, setAppSpinning
  ) => {
    // const MAX_WAIT_SIGN_INVOICE = process.env.REACT_APP_MAX_WAIT_SIGN
    if (url && accessToken) {
      let notInstalledCb = () => {
        alert('Chua cai tool!')
      }

      customProtocolCheck(
        url,
        notInstalledCb,
        () => {
          Utils.openInCurrentTab(url)
        },
        2500,
      )
      // dispatch(setAppSpinning(true));
      wsHelperInstance.onExecutingSignSummaryTemplatePaper(accessToken)
      // Global.gExecutingSignInvoiceTimeOut = setTimeout(() => {
      //   dispatch(setAppSpinning(false));
      // }, MAX_WAIT_SIGN_INVOICE);
    }
  },

  openToolSignAccountingDocuments: (
    url,
    accessToken,
    //  dispatch, setAppSpinning
  ) => {
    // const MAX_WAIT_SIGN_INVOICE = process.env.REACT_APP_MAX_WAIT_SIGN
    if (url && accessToken) {
      let notInstalledCb = () => {
        alert('Chua cai tool!')
      }

      customProtocolCheck(
        url,
        notInstalledCb,
        () => {
          Utils.openInCurrentTab(url)
        },
        2500,
      )
      // dispatch(setAppSpinning(true));
      wsHelperInstance.onExecutingSignSummaryAccountingDocments(accessToken)
      // Global.gExecutingSignInvoiceTimeOut = setTimeout(() => {
      //   dispatch(setAppSpinning(false));
      // }, MAX_WAIT_SIGN_INVOICE);
    }
  },

  checkFunction: (values, userFunction, listFunction) => {
    return values.map((value) => {
      const {
        name,
        subName,
        userFunctionCode,
        listFunctionName,
        listFunctionCode,
      } = value

      if (!userFunction?.length || !listFunction?.length) return null

      let foundItem = null
      if (userFunctionCode && userFunctionCode !== '') {
        for (const item of userFunction) {
          if (item.functionCode === userFunctionCode) {
            foundItem = item
            break
          }
        }
      } else if (subName === '') {
        for (const item of userFunction) {
          if (item.functionName === name) {
            foundItem = item
            break
          }
        }
      } else {
        for (const item of userFunction) {
          if (item.functionName === subName) {
            foundItem = item
            break
          }
        }
      }

      let active = null

      for (const item of listFunction) {
        if (
          (item.userFunctionId === foundItem?.userFunctionId &&
            item.actionName === listFunctionName) ||
          item.listFunctionCode === listFunctionCode
        ) {
          active = item.active
          break
        }
      }

      return active == 1 || active == true
    })
  },

  checkIfValueIsValidFloatType: (val) => {
    if (!val) return false
    const removeDotAndComma = val.replaceAll('.', '').replace(',', '.')
    const parseNumToFloat = parseFloat(removeDotAndComma)
    return _.isNumber(parseNumToFloat) && parseNumToFloat > 0
  },

  getDateFilterOption: (type) => {
    switch (type) {
      case 'HOM_QUA':
        return [
          moment().subtract(1, 'day').startOf('day'),
          moment().subtract(1, 'day').endOf('day'),
        ]
      case 'HOM_NAY':
        return [moment(), moment()]
      case 'TUAN_TRUOC':
        return [
          moment().subtract(1, 'weeks').startOf('isoWeek'),
          moment().subtract(1, 'weeks').endOf('isoWeek'),
        ]
      case 'TUAN_NAY':
        return [moment().startOf('week'), moment()]
      case 'THANG_TRUOC':
        return [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ]
      case 'THANG_NAY':
        return [moment().startOf('month'), moment()]
      case '30_NGAY_GAN_NHAT':
        return [moment().subtract(29, 'days'), moment()]
      case 'QUY_TRUOC':
        return [
          moment().subtract(1, 'quarters').startOf('quarter'),
          moment().subtract(1, 'quarters').endOf('quarter'),
        ]
      case 'QUY_NAY':
        return [moment().startOf('quarters'), moment()]
      case 'SAU_THANG_DAU_NAM':
        return [
          moment().startOf('year'),
          moment().startOf('year').add(6, 'months'),
        ]
      case 'SAU_THANG_CUOI_NAM':
        return [
          moment().endOf('year').subtract(6, 'months'),
          moment().endOf('year'),
        ]
      case 'NAM_TRUOC':
        return [
          moment().subtract(1, 'years').startOf('year'),
          moment().subtract(1, 'years').endOf('year'),
        ]
      case 'NAM_NAY':
        return [moment().startOf('years'), moment()]
      case 'THANG_1':
        return [
          moment().month(0).startOf('month'),
          moment().month(0).endOf('month'),
        ]
      case 'THANG_2':
        return [
          moment().month(1).startOf('month'),
          moment().month(1).endOf('month'),
        ]
      case 'THANG_3':
        return [
          moment().month(2).startOf('month'),
          moment().month(2).endOf('month'),
        ]
      case 'THANG_4':
        return [
          moment().month(3).startOf('month'),
          moment().month(3).endOf('month'),
        ]
      case 'THANG_5':
        return [
          moment().month(4).startOf('month'),
          moment().month(4).endOf('month'),
        ]
      case 'THANG_6':
        return [
          moment().month(5).startOf('month'),
          moment().month(5).endOf('month'),
        ]
      case 'THANG_7':
        return [
          moment().month(6).startOf('month'),
          moment().month(6).endOf('month'),
        ]
      case 'THANG_8':
        return [
          moment().month(7).startOf('month'),
          moment().month(7).endOf('month'),
        ]
      case 'THANG_9':
        return [
          moment().month(8).startOf('month'),
          moment().month(8).endOf('month'),
        ]
      case 'THANG_10':
        return [
          moment().month(9).startOf('month'),
          moment().month(9).endOf('month'),
        ]
      case 'THANG_11':
        return [
          moment().month(10).startOf('month'),
          moment().month(10).endOf('month'),
        ]
      case 'THANG_12':
        return [
          moment().month(11).startOf('month'),
          moment().month(11).endOf('month'),
        ]
      case 'QUY_1':
        return [
          moment().quarter(1).startOf('quarter'),
          moment().quarter(1).endOf('quarter'),
        ]
      case 'QUY_2':
        return [
          moment().quarter(2).startOf('quarter'),
          moment().quarter(2).endOf('quarter'),
        ]
      case 'QUY_3':
        return [
          moment().quarter(3).startOf('quarter'),
          moment().quarter(3).endOf('quarter'),
        ]
      case 'QUY_4':
        return [
          moment().quarter(4).startOf('quarter'),
          moment().quarter(4).endOf('quarter'),
        ]
      case 'TUY_CHON':
        return []
      default:
        return null
    }
  },

  exportToExcel: (rows, fileName) => {
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName)

    // Get the number of columns in the worksheet
    const maxCol = XLSX.utils.decode_range(worksheet['!ref']).e.c
    const columnWidths = []
    // console.log(Object.entries(rows[0]))
    for (let i = 0; i <= maxCol; i++) {
      let max_width = 20
      rows.forEach((item) => {
        max_width = Math.max(Object.entries(item)[i][1]?.length ?? 0, max_width)
      })

      columnWidths[i] = { wch: max_width }
    }
    worksheet['!cols'] = columnWidths

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true })
  },
  //**
  removeAtIndex: (array, index) => [
    ...array.slice(0, index),
    ...array.slice(index + 1),
  ],
  insertAtIndex: (array, index, item) => [
    ...array.slice(0, index),
    item,
    ...array.slice(index),
  ],

  formatNumberWithSeparators: (num) => {
    const numString = num.toString()
    const [integerPart, decimalPart] = numString.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.',
    )
    const formattedNumber = decimalPart
      ? `${formattedIntegerPart},${decimalPart}`
      : formattedIntegerPart

    return formattedNumber
  },

  parseFormattedNumberToFloat: (num) => {
    // Định dạng đã format là 5.555,555
    try {
      if (typeof num === 'string') {
        const numericString = num.replace(/\./g, '').replace(',', '.')
        const numericValue = parseFloat(numericString)
        return numericValue
      }
      if (typeof num === 'number') {
        return num
      }
    } catch (err) {
      return 0
    }
  },

  roundNumber: (num, numAfterDecimal = 3) => {
    if (_.isNumber(num)) return parseFloat(num.toFixed(numAfterDecimal))
    const toJsNumber = _.chain(num)
      .replace(/\./g, '')
      .replace(',', '.')
      .toNumber()
      .value()
    return parseFloat(toJsNumber.toFixed(numAfterDecimal))
  },

  changeVneseAdjustInvoice: (numInVnese, num, readMoneyInvoice) => {
    // bỏ chữ âm ở đầu nếu có
    if (_.toUpper(numInVnese.split(' ')[0]) === 'ÂM') {
      let arrNumberInVnese = numInVnese.split(' ')
      arrNumberInVnese.splice(0, 1)
      numInVnese = arrNumberInVnese.join(' ')
    }
    let numSign
    let concatVneseObj = ADJUST_INVOICE_VNESE[readMoneyInvoice]
    if (concatVneseObj) {
      let textToConcat,
        textToConcatPosition = concatVneseObj?.position
      if (parseFloat(num) >= 0) {
        numSign = 'positive'
      } else {
        numSign = 'negative'
      }
      textToConcat = concatVneseObj[numSign]
      if (textToConcatPosition === 'start') {
        return textToConcat + ' ' + Utils.lowerFirstLetter(numInVnese)
      }
      if (textToConcatPosition === 'end') {
        return numInVnese + ' ' + textToConcat
      }
    }
  },

  lowerFirstLetter: (inputString) => {
    if (inputString) {
      return inputString.charAt(0).toLowerCase() + inputString.slice(1)
    }
    return inputString
  },

  handleDownloadXMLFile: (content, fileName) => {
    const blob = new Blob([content], {
      type: 'application/xml',
    })
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },

  handleDownloadZipFile: (data, fileName) => {
    const blob = new Blob([data], { type: 'application/zip' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  },

  simplifyString: (string) => {
    // Remove spaces and convert special characters to Latin characters
    const latinizedString = string
      .toLowerCase() // Convert to lowercase
      .replace(/ /g, '') // Remove spaces
      .normalize('NFD') // Normalize special characters
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritics

    return latinizedString
  },

  isValidEmail: (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailRegex.test(email)
  },

  openFileInNewTab: ({ blobData, fileName, fileType }) => {
    const file = new File([blobData], fileName, { type: fileType })
    window.open(URL.createObjectURL(file), '_blank')
  },

  isValidTimeFormat: (timeString) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/
    return regex.test(timeString)
  },

  floatToFormatString: (number) => {
    let formattedNumber
    if (typeof number === 'number') {
      formattedNumber = number.toLocaleString('de-DE')
      console.log(formattedNumber)
    } else {
      console.error('The variable is not a valid number.')
    }

    return null
  },
  /**
   * @param {import('react').KeyboardEvent<HTMLInputElement>} event
   * @returns
   */
  removeLetterInInputNumber: (event) => {
    const strings = ['e', 'E', '+', '-']
    return _.includes(strings, event.key) && event.preventDefault()
  },

  // truyền taxRate string
  // totalPrice là tổng tiền sau thuế
  // Tính tiền thuế khi có taxRate và tổng tiền sau thuế
  calculateTaxMoneyWithTotalPrice: (totalPrice, taxRate) => {
    let taxMoney = 0
    if (
      taxRate === '0' ||
      taxRate === '5' ||
      taxRate === '8' ||
      taxRate === '10'
    ) {
      taxRate = _.parseInt(taxRate)
      taxMoney = (totalPrice / (100 + taxRate)) * taxRate
    }
    return taxMoney
  },

  calculateTaxMoney: (price, taxRate) => {
    let taxMoney = 0
    if (
      taxRate === '0' ||
      taxRate === '5' ||
      taxRate === '8' ||
      taxRate === '10'
    ) {
      taxRate = _.parseInt(taxRate)
      taxMoney = (price * taxRate) / 100
    }
    return taxMoney
  },

  isValidNumber: (param) => {
    if (
      ((!_.isString(param) || _.isEmpty(param)) && !_.isNumber(param)) ||
      _.isNull(param) ||
      _.isUndefined(param)
    ) {
      return false
    }
    return !isNaN(param) && isFinite(param)
  },
  randomizeDate: (startDate) => {
    const start = dayjs(startDate, 'DD/MM/YYYY')
    const end = dayjs().startOf('day')
    const diff = end.diff(start, 'days')
    const randomDiff = _.random(0, diff)
    const randomDate = start.add(randomDiff, 'days')
    return randomDate
  },
  /**
 
   * @param  {...any} args
   * @returns a string of class names
   */
  cn: (...args) => clsx(args),

  /**
   * @param {number} startRange
   * @param {number} endRange
   * @returns {number}
   */
  calcWidthColumns: (startRange, endRange) =>
    Math.floor(
      _.reduce(
        _.range(startRange, endRange + 1),
        (prev, curr) =>
          prev +
          document
            .querySelector(`div[data-column-id="${curr}"]`)
            ?.getBoundingClientRect().width,
        startRange,
      ),
    ),
  /**
   *
   * @param {string | number} value - string to pad
   * @param {number} numToPad - number of characters to pad
   * @param {string} char - character to pad
   * @returns
   */
  padString: (value, numToPad, char) => {
    let str = _.isString(value) ? value : _.toString(value)
    return _.padStart(str, numToPad, char)
  },

  /**
   * @param {string} value
   * @param {AppData['default_formatNumber'] | undefined} formatObject
   * @param {AppData['default_formatNumber'] | undefined} defaultFormat
   * @param {keyof AppData['default_formatNumber']} key
   */
  valueFormatted: (value, formatObject, defaultFormat, key) => {
    const fractionDigits = _.isNil(formatObject)
      ? _.get(defaultFormat, key, 0)
      : _.get(formatObject, key, 0)

    return _.isNil(value)
      ? ''
      : Utils.formatNumber(_.toNumber(value), fractionDigits)
  },

  /**
   *
   * @param {Record<string,any>} data
   * @param {StringifyOptions} [opts]
   */
  stringifySearchParams: (data, opts) => {
    return stringify(data, {
      skipNull: true,
      skipEmptyString: true,
      ...opts,
    })
  },
}

export function formatNumber(iNumber, fractionDigits = 0) {
  if (!Utils.isValidNumber(iNumber)) return iNumber

  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(iNumber)
}

export function invoiceTemplateFieldNameToVnese(fieldName) {
  switch (fieldName) {
    case 'buyerName':
      return '[Họ tên người mua hàng]'
    case 'buyerLegalName':
      return '[Tên đơn vị]'
    case 'BuyerTaxCode':
      return '[Mã số thuế]'
    case 'buyerAddress':
      return '[Địa chỉ]'
    case 'paymentMethod':
      return '[Hình thức thanh toán]'
    case 'PaymentCurrency':
      return '[Đồng tiền thanh toán]'
    case 'buyerBankAccount':
      return '[Số tài khoản]'

    default:
      return
  }
}

export function cutOrRoundNumber(number, precision) {
  let strNumber = number.toString()
  let decimalIndex = strNumber.indexOf('.')
  if (decimalIndex === -1) {
    return number
  }
  let digitsToKeep = precision
  let cutStrNumber = strNumber.slice(0, decimalIndex + digitsToKeep + 1)
  return parseFloat(cutStrNumber)
}

export const regexTaxCode = (taxCode) => {
  const REGEX_MST =
    /^([0-9]{10}|[0-9]{13}|[0-9]{10}[-][0-9]{3}|[a-zA-Z0-9.]{8})$/g
  return REGEX_MST.test(taxCode)
}

export function base64ToBlob(base64String) {
  var byteCharacters = atob(base64String)
  var byteArrays = []
  for (var offset = 0; offset < byteCharacters.length; offset += 512) {
    var slice = byteCharacters.slice(offset, offset + 512)
    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, { type: 'application/pdf' })
}

export function embedPDF(base64String) {
  var blob = base64ToBlob(base64String)
  var blobUrl = URL.createObjectURL(blob)
  var newWindow = window.open(blobUrl, '_blank')
  if (newWindow) {
    newWindow.onload = function () {
      URL.revokeObjectURL(blobUrl)
    }
  } else {
  }
}

export function base64toBlobContentType(base64Data, contentType) {
  contentType = contentType || 'application/pdf'
  const byteCharacters = atob(base64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export function downloadZip(pdfFiles) {
  // Tạo đối tượng Zip
  const zip = new JSZip()

  // Thêm các file PDF vào zip
  pdfFiles.forEach((pdf, index) => {
    const { nameFile, base64 } = pdf
    const blob = base64toBlobContentType(base64, 'application/pdf')
    zip.file(nameFile ?? `File${index}.pdf`, blob)
  })

  // Tải file zip
  zip
    .generateAsync({ type: 'blob' })
    .then(function (content) {
      const zipFilename = 'Ho_so_dang_ky_su_dung_chung_tu_dien_tu.zip'

      // Tạo một đường dẫn URL để tải xuống file zip
      const url = URL.createObjectURL(content)

      // Tạo một thẻ <a> ẩn và bấm vào nó để tải file zip
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = zipFilename
      document.body.appendChild(a)
      a.click()

      // Xóa đối tượng <a> sau khi đã bấm vào nó
      setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 100)
    })
    .catch(function (error) {
      console.error('Error creating zip:', error)
    })
}
export function extractFields(documents, nameArray, fieldName) {
  return documents
    .map((doc) => {
      let extracted = {}
      nameArray.forEach((name) => {
        if (
          doc.hasOwnProperty(name) &&
          fieldName &&
          doc[fieldName] !== null &&
          doc[fieldName] !== undefined
        ) {
          extracted[name] = doc[name]
        }
      })
      return extracted
    })
    .filter((obj) => Object.keys(obj).length > 0)
}

export const findKeyInBorderBackground = (obj, keyToFind) => {
  for (const property in obj) {
    if (obj[property].key === keyToFind) {
      return obj[property]
    }
  }
  return null
}

export const groupByMerge = (data) => {
  return data?.value.reduce((acc, item, index) => {
    item.indexArrayMerge = index
    if (item.merge) {
      if (!acc[item.merge]) {
        acc[item.merge] = []
      }
      acc[item.merge].push(item)
    }
    return acc
  }, {})
}
export const isoDateToNumber = (isoDate) => {
  try {
    if (isoDate) {
      return dayjs(isoDate).valueOf()
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const numberToIsoDate = (timestamp) => {
  try {
    if (timestamp) {
      return dayjs(timestamp)
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const convertDate = (date, format) => {
  try {
    return dayjs(date).format(format)
  } catch (error) {
    return null
  }
}

export const removeTextBetweenBraces = (str) => {
  try {
    if (str) {
      return str
        .replace('{{dayNow}}', '...')
        .replace('{{monthNow}}', '...')
        .replace('{{yearNow}}', '...')
        .replace(/{{.*?}}/g, '')
        .replace('⬜', '')
        .trim()
    } else {
      return null
    }
  } catch (error) {
    return str
  }
}
export const removeItemByKey = (items, key) => {
  return items.filter((item) => item.key !== key)
}
export const removeItemByKeys = (items, keysToRemove) => {
  return items.filter((item) => !keysToRemove.includes(item.key))
}

export const padNumber = (number, length) => {
  let str = String(number)
  while (str.length < length) {
    str = '0' + str
  }
  return str
}

export const setCookie = (name, value, days = 1) => {
  // days mặc định là 1 nếu không được cung cấp
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const getCookie = (name) => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const findObjectInArray = (array, key, value) => {
  try {
    return array.find((obj) => obj[key] == value) || null
  } catch (error) {
    return null
  }
}
export const convertToOptions = (list, valueKey, valueLabel) => {
  try {
    return list.map((item) => {
      if (typeof item === 'object') {
        return {
          value: item[valueKey],
          label: item[valueLabel],
        }
      } else {
        return {
          value: item,
          label: item,
        }
      }
    })
  } catch (error) {
    return []
  }
}
export const convertToOptionsValueString = (list, valueKey, valueLabel) => {
  try {
    return list.map((item) => {
      if (typeof item === 'object') {
        return {
          value: item[valueKey]?.toString(),
          label: item[valueLabel],
        }
      } else {
        return {
          value: item?.toString(),
          label: item,
        }
      }
    })
  } catch (error) {
    return []
  }
}
export const getNonEmptyValues = (obj) => {
  try {
    return Object.values(obj)
      .filter((value) => value !== '' && value !== null)
      .join(', ')
  } catch (error) {
    return ''
  }
}
export const setDate = (date) => {
  try {
    if (date) return dayjs(date)
    else return null
  } catch (error) {
    return null
  }
}

export const toLower = (data) => {
  try {
    return data.toLowerCase()
  } catch (error) {
    return data
  }
}

export const filterByValuesAndKey = (values, key, listObjects) => {
  try {
    return listObjects.filter((obj) => values.includes(String(obj[key])))
  } catch (error) {
    return []
  }
}
export const padNumberWithZeros = (number, length) => {
  try {
    let strNumber = number.toString()
    let zerosNeeded = length - strNumber.length
    while (zerosNeeded > 0) {
      strNumber = '0' + strNumber
      zerosNeeded--
    }
    return strNumber
  } catch (error) {
    return number
  }
}
export const getParam = (nameParam) => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=')

    if (sParameterName[0] === nameParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1])
    }
  }
  return false
}
export const isISODateString = (str) => {
  const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
  return isoFormat.test(str)
}

export const convertToDayjs = (obj) => {
  const newObj = {}

  for (const key in obj) {
    if (obj[key] === null) {
      newObj[key] = null
    } else if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map((item) =>
        isISODateString(item) ? dayjs(item) : item,
      )
    } else if (typeof obj[key] === 'string' && isISODateString(obj[key])) {
      newObj[key] = dayjs(obj[key])
    } else {
      newObj[key] = obj[key]
    }
  }

  return newObj
}

export const formatTaxCode = (data) => {
  try {
    let value = data.replace(/-/g, '')
    if (value.length > 10) {
      return value.slice(0, 10) + '-' + value.slice(10)
    } else {
      return value
    }
  } catch (error) {
    return data
  }
}

export const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const updateInforCompanyValue = (data, newValue, keyCheck) => {
  // Tạo một bản sao của dữ liệu để tránh thay đổi dữ liệu gốc
  const updatedData = data
  // Duyệt qua từng phần tử trong mảng updatedData
  for (let item of updatedData) {
    // Kiểm tra nếu phần tử có thuộc tính content
    if (item.content && Array.isArray(item.content)) {
      // Duyệt qua từng phần tử trong content
      for (let contentItem of item.content) {
        // Kiểm tra nếu phần tử có thuộc tính value
        if (contentItem.value && Array.isArray(contentItem.value)) {
          // Duyệt qua từng phần tử trong value
          for (let valueItem of contentItem.value) {
            // Kiểm tra nếu phần tử có thuộc tính keyCheck và giá trị là true
            if (valueItem.vi && valueItem.vi[keyCheck]) {
              // Sửa giá trị value
              valueItem.vi.value = newValue
            }
          }
        }
      }
    }
  }
  // Trả về dữ liệu đã được cập nhật
  return updatedData
}

export const updateJsonKey = (data, keyToUpdate, newValue) => {
  // Duyệt qua tất cả các key trong đối tượng
  for (let key in data) {
    // Kiểm tra nếu key là keyToUpdate
    if (key === keyToUpdate) {
      data[key] = newValue // Cập nhật giá trị mới
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      // Nếu giá trị là một đối tượng, đệ quy để duyệt qua các thuộc tính lồng nhau
      updateJsonKey(data[key], keyToUpdate, newValue)
    }
  }
  return data // Trả về đối tượng đã được cập nhật
}

export const validateTicket = (params) => {
  let status = true
  let data = []
  let idForcus
  //check name
  if (!params?.name) {
    status = false
    data.push('Thiếu tên mẫu')
    if (!idForcus) {
      idForcus = 'template-inv-name'
    }
  }
  if (!params?.managementCode) {
    status = false
    data.push('Thiếu hoặc ký hiệu quản lý trong mẫu không hợp lệ')
    if (!idForcus) {
      idForcus = 'template-invoice-series-input'
    }
  }
  if (!params?.serviceName) {
    status = false
    data.push('Thiếu tên dịch vụ')
    if (!idForcus) {
      idForcus = 'template-inv-service-name'
    }
  }
  if (!params?.currencyUnit) {
    status = false
    data.push('Tổng tiền phải là số')
    if (!idForcus) {
      idForcus = 'template-inv-ticket-price'
    }
  }
  forCusId(idForcus)
  return {
    status,
    data,
  }
}

export const forCusId = (idForcus) => {
  setTimeout(function () {
    const inputElement = document.getElementById(idForcus)
    if (inputElement) {
      inputElement.focus()
    }
  }, 500)
  return true
}

export default Utils
