import axiosClient from './axiosClient'

const accountApi = {
  getAccountProfile: (params) => {
    const url = '/account/me'
    return axiosClient.get(url, { params })
  },
  changePassword: (params) => {
    const url = '/change-password'
    return axiosClient.post(url, params)
  },
  requestToResetPassword: (params) => {
    const url = '/request-to-reset-password'
    return axiosClient.post(url, params)
  },
  resetPassword: (params) => {
    const url = '/reset-password'
    return axiosClient.post(url, params)
  },
  changeActiveInvoice: (params) => {
    const url = '/active-invoice/update'
    return axiosClient.post(url, params)
  },
  getStaffInfo: (params, signal) => {
    const url = '/account/staff'
    return axiosClient.get(url, { params, signal })
  },
}

export default accountApi
