import { Empty, TreeSelect, Typography } from 'antd'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useMemo } from 'react'

const transforming = (companyUnitObj) => {
  const hasChildren = _.isArray(companyUnitObj.ChildrenUnits)
  const children = hasChildren
    ? _.map(companyUnitObj.ChildrenUnits, (unit) => transforming(unit))
    : []
  return {
    title: companyUnitObj.name,
    label: companyUnitObj.name,
    value: companyUnitObj.companyUnitId,
    children,
  }
}

export default function CreatedInvoiceUnit({ value, onSelect }) {
  const { companyUnits } = useAppSelector((state) => state.category)
  const treeData = useMemo(
    () => _.map(companyUnits, (unit) => transforming(unit)),
    [companyUnits],
  )
  const customFiltering = (input, option) => {
    const _title = _.get(option, 'title', '').toLowerCase(),
      _input = input.toLowerCase(),
      _label = _.get(option, 'label', '').toLowerCase()
    return _.includes(_label, _input) || _.includes(_title, _input)
  }
  return (
    <TreeSelect
      popupClassName="created-invoice-unit__popup"
      showSearch
      style={{
        width: '300px',
      }}
      treeData={treeData}
      value={value}
      switcherIcon={(props) => (
        <i
          className={Utils.cn(
            `fa-regular fa-angle-right`,
            props.expanded && 'fa-rotate-90',
          )}
        />
      )}
      allowClear
      listHeight={300}
      onChange={(selected) => onSelect(selected)}
      // treeDefaultExpandAll
      filterTreeNode={customFiltering}
      placeholder="Chọn đơn vị lập đơn"
      treeLine
      suffixIcon={
        _.isEmpty(value) && <i className="fa-solid fa-angle-down text-muted" />
      }
      notFoundContent={
        <Empty
          description={
            <Typography.Text type="secondary">
              Không tìm thấy đơn vị
            </Typography.Text>
          }
          image={AppResource.icons.icEmptyBox}
        />
      }
    />
  )
}
