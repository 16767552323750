import dayjs from 'dayjs'
import { clone } from 'lodash'
import axiosClient from './axiosClient'

const prefixPath = '/statistic-ticket'

const statisticTicketApi = {
  find: async (params) => {
    const cloned = clone(params)
    cloned.startDate = dayjs(cloned.startDate + '/' + cloned.year, 'L').toDate()
    cloned.endDate = dayjs(cloned.endDate + '/' + cloned.year, 'L').toDate()
    return axiosClient.get(`${prefixPath}/number-ticket-release`, {
      params: cloned,
    })
  },
  getExported: async (params) => {
    return axiosClient.get(`${prefixPath}/ticket-release`, {
      params,
    })
  },
}

export default statisticTicketApi
