import { useSelector } from 'react-redux'

function TitleOfHeader() {
  const { companyName, taxCode } = useSelector(
    (state) => state.auth?.currentCompany,
  )
  return (
    <div id="company-info" className="d-flex flex-column py-1 fs-6">
      <b className="fs-5">{companyName}</b>
      <div className="d-flex align-ittem-center fw-medium">
        Mã số thuế:
        <span className="fw-semibold text-primary ml-2">{taxCode}</span>
      </div>
    </div>
  )
}

export default TitleOfHeader
