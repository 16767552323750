import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import { Dropdown } from 'react-bootstrap'

Secment.propTypes = {
  tabs: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  styleSM: PropTypes.object,
  classNameSM: PropTypes.string,
  labelSm: PropTypes.string,
}

Secment.defaultProps = {
  tabs: [],
  className: '',
  style: {},
  styleSM: {},
  classNameSM: '',
  labelSm: '',
}

function Secment(props) {
  const { tabs, className, style, classNameSM, styleSM, labelSm } = props
  const activeTab = props.activeTab ? props.activeTab : tabs[tabs.length - 1]

  const onClick = (tab) => {
    props.handleClick(tab)
  }

  return (
    <div className="AppTabs">
      {/* UI MD */}
      <div className="d-none d-md-block">
        <div
          className={`rounded bg-transparent row m-0 py-3 flex-stack flex-wrap mb-4 ${className}`}
          style={style}
        >
          {tabs.map((item, index) => {
            return (
              <div key={index} className="nav-item col p-0">
                <div
                  className={`nav-link cursor-pointer text-center ${
                    item == activeTab ? 'active' : ''
                  }`}
                  style={{
                    height: 'calc(100% - 3px)',
                    fontWeight: 400,
                    fontSize: '1.1rem',
                    color: item !== activeTab && '#667080',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    onClick(item)
                  }}
                >
                  {item}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* UI SM */}
      <div
        className={`d-flex justify-content-center align-items-center py-4 d-md-none ${classNameSM}`}
        style={styleSM}
      >
        <span className="mr-4">{labelSm}</span>
        <Dropdown>
          <Dropdown.Toggle
            className="d-flex justify-content-between align-items-center"
            variant="secondary"
          >
            {activeTab}
            <i className="ml-4 fa-solid fa-caret-down"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-4">
            <div className="overflow-auto max-h-200px w-100">
              {tabs.map((item, index) => (
                <Dropdown.Item
                  className="d-flex justify-content-between"
                  onClick={(e) => {
                    e.preventDefault()
                    onClick(item)
                  }}
                  key={item}
                >
                  <span>{item}</span>
                  {item === activeTab && (
                    <i className="fas fa-check text-primary"></i>
                  )}
                </Dropdown.Item>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default Secment
