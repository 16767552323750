import { queryOptions } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import _ from 'lodash'

export const queries = {
  base: {
    scope: ['handleInvoice', 'listCancelledInvoice'],
    entity: 'listCancelledInvoice',
  },
  lists: (params) =>
    queryOptions({
      queryKey: [queries.base, params],
      queryFn: ({ signal }) =>
        invoiceApi.listAll(
          {
            ...params,
            invoiceType: 'cancel',
          },
          signal,
        ),
      staleTime: 1000 * 60,
      select: (res) => ({
        total: _.get(res, 'count', 0),
        data: _.get(res, 'rows', []),
      }),
    }),
}
