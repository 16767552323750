const { default: axiosClient } = require('./axiosClient')

const cartApi = {
  // tao moi
  createCart: (query) => {
    const url = '/cart/create'
    return axiosClient.post(url, query)
  },

  // lay toan bo
  getAll: () => {
    const url = '/cart/'
    return axiosClient.get(url)
  },

  updateNumber: (query) => {
    const url = '/cart/updateNumber'
    return axiosClient.put(url, query)
  },

  // xoa san pham
  remove: (query) => {
    const url = '/cart/delete'

    return axiosClient.delete(url, { data: { cartId: query } })
  },

  removeAll: (query) => {
    const url = '/cart/deleteAll'
    return axiosClient.delete(url)
  },
}

export default cartApi
