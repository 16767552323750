import AppResource from 'general/constants/AppResource'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './style.scss'

AppDeleteModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  deleteItem: PropTypes.func,
  deleteTitle: PropTypes.string,
  deleteText: PropTypes.string,
  icon: PropTypes.element,
  dialogIcon: PropTypes.element,
}

AppDeleteModal.defaultProps = {
  show: null,
  onClose: null,
  deleteItem: null,
  deleteTitle: null,
  deleteText: null,
  icon: null,
  dialogIcon: null,
}

function AppDeleteModal(props) {
  const { t } = useTranslation()

  const {
    show,
    onClose,
    deleteItem,
    deleteTitle,
    deleteText,
    icon,
    dialogIcon,
  } = props

  function handleClose() {
    if (onClose) {
      onClose()
    }
  }

  function handleDeleteItem() {
    if (deleteItem) {
      deleteItem()
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      className="AppDeleteModal p-0"
      // backdrop="static"
      // keyboard={false}
    >
      {/* modal header */}
      <Modal.Header className="d-flex align-items-center justify-content-center">
        {/* <span className="font-weight-bolder">{deleteTitle}</span> */}
        <Modal.Title>{deleteTitle}</Modal.Title>
      </Modal.Header>

      {/* modal content */}
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center bg-light py-0">
        {icon ? (
          icon
        ) : (
          <img
            className="py-5 my-2"
            src={AppResource.icons.icTrash}
            alt="delete icon"
          />
        )}
        <p className="text-center font-weight-bold">{deleteText}</p>
      </Modal.Body>

      {/* modal footer */}
      <Modal.Footer className="d-flex flex-row align-items-center justify-content-center">
        <div className="w-100 d-flex row">
          <Button
            className="font-weight-bold flex-grow-1 col mr-3 AppButton ButtonCancel"
            variant="secondary"
            onClick={handleClose}
          >
            Huỷ
          </Button>
          <Button
            className="font-weight-bold flex-grow-1 col ml-3"
            variant="success"
            onClick={() => {
              handleClose()
              handleDeleteItem()
            }}
          >
            Xác nhận
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AppDeleteModal
