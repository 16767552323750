import {
  INVOICE_STATUS,
  INVOICE_TYPE,
  SEND_TAX_STATUS,
  CONFIRM_STATUS,
} from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import _ from 'lodash'
import ToastHelper from './ToastHelper'

const InvoiceHelper = {
  cancelInvoiceWarning: (invoiceErrAnnouceStatus) => {
    if (!invoiceErrAnnouceStatus) return

    switch (invoiceErrAnnouceStatus) {
      case 1:
        return {
          text: 'Chưa lập TB sai sót gửi CQT',
          color: 'rgb(251, 0, 0)',
          bgColor: 'rgb(255, 175, 175)',
        }
      case 2:
        return {
          text: 'Chưa gửi TB sai sót gửi CQT',
          color: '#FB862C',
          bgColor: '#FFD99F',
        }
      case 3:
        return {
          text: '',
          color: '',
          bgColor: '',
        }
      case 4:
        return {
          text: '',
          color: '',
          bgColor: '',
        }
      // case 5:
      //   return {
      //     text: '',
      //     color: '',
      //     bgColor: '',
      //   }
      case 6:
        return {
          text: '',
          color: '',
          bgColor: '',
        }
    }
  },

  errAnnouceTypeToVnese: (errAnnouceType) => {
    switch (errAnnouceType) {
      case 1:
        return 'Hủy'
      case 2:
        return 'Điều chỉnh'
      case 3:
        return 'Thay thế'
      case 4:
        return 'Giải trình'
      case 5:
        return 'Sai sót tổng hợp'
      default:
        return
    }
  },
  padNumber(number, length) {
    return number.toString().padStart(length ?? 8, '0')
  },
  renderInvoiceType: (type) => {
    let result = {
      text: SelectOptions.invoiceType.find((item) => item.value === type).label,
      color: '',
    }
    switch (type) {
      case INVOICE_TYPE.ADJUST:
      case INVOICE_TYPE.REPLACE:
        result.color = 'success'
        break
      case INVOICE_TYPE.CANCELLED:
      case INVOICE_TYPE.REPLACED:
      case INVOICE_TYPE.ADJUSTED:
      case INVOICE_TYPE.DELETED:
        result.color = 'danger'
        break
      case INVOICE_TYPE.ORIGINAL:
        result.color = 'primary'
        break
    }
    return result
  },
  renderPaymentMethod: (method) => {
    return (
      SelectOptions.paymentMethod.find((item) => item.value === method)
        ?.label ?? ''
    )
  },
  renderIsPaid: (isPaid) => {
    return (
      <div className={`badge badge-light-${isPaid ? 'success' : 'danger'}`}>
        {isPaid ? 'Đã thanh toán' : 'Chưa thanh toán'}
      </div>
    )
  },

  renderIssueStatus: (status) => {
    const statusColorMap = {
      [INVOICE_STATUS.AUTHORITY_CODE_GIVEN]: 'primary',
      [INVOICE_STATUS.AUTHORITY_ACCEPT]: 'primary',
      [INVOICE_STATUS.SENDING_TAX]: 'warning',
      [INVOICE_STATUS.WAITING_FOR_AUTHORITY]: 'warning',
      [INVOICE_STATUS.NOT_SENT_TO_AUTHORITY]: 'success',
      [INVOICE_STATUS.AUTHORITY_DENY]: 'danger',
      [INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN]: 'danger',
      [INVOICE_STATUS.SEND_ERROR]: 'danger',
      [INVOICE_STATUS.SIGN_ERROR]: 'danger',
    }

    return {
      text:
        SelectOptions.issueStatus.find((item) => item.value === status)
          ?.label ?? '',
      color: statusColorMap[status] || 'secondary',
    }
  },

  renderSendTaxStatus: (status) => {
    const options = _.reject(SelectOptions.sendTaxStatus, { value: null })
    const statusColorMap = {
      [SEND_TAX_STATUS.INVOICE_INVALID]: 'danger',
      [SEND_TAX_STATUS.SEND_ERROR]: 'danger',
      [SEND_TAX_STATUS.SIGN_ERROR]: 'danger',
      [SEND_TAX_STATUS.NOT_SENT_TO_AUTHORITY]: 'primary',
      [SEND_TAX_STATUS.WAITING_FOR_AUTHORITY]: 'warning',
      [SEND_TAX_STATUS.SENDING_TAX]: 'success',
      [SEND_TAX_STATUS.INVOICE_VALID]: 'success',
    }

    return {
      text: options.find((item) => item.value === status)?.label ?? '',
      color: statusColorMap[status] || 'secondary',
    }
  },

  /**
   * @param {keyof CONFIRM_STATUS} status
   */
  renderConfirmStatus: (status) => {
    const opts = _.reject(
      SelectOptions.confirmStatus,
      (c) => c.value === null || c.value === CONFIRM_STATUS.EMPTY,
    )
    const colorMap = {
      [CONFIRM_STATUS.CONFIRMED]: 'primary',
      [CONFIRM_STATUS.DRAFT]: 'secondary',
      [CONFIRM_STATUS.WAITING]: 'warning',
      [CONFIRM_STATUS.REJECTED]: 'danger',
    }
    return {
      text: opts.find((item) => item.value === status)?.label ?? '',
      color: colorMap[status],
    }
  },

  validateInvoiceFromExternal: (invoice) => {
    if (!invoice) {
      ToastHelper.showError('Không tìm thấy dữ liệu')
      return
    }
    const currentYear = new Date().getFullYear().toString().slice(-2)
    const previousYear = (new Date().getFullYear() - 1).toString().slice(-2)
    if (!invoice.taxAuthorityCode) {
      if (invoice.serial?.at(1) == 'C') {
        ToastHelper.showError('Mã cơ quan thuế không được để trống')
        return
      }
    } else {
      if (
        invoice.serial?.at(4) == 'M' &&
        !new RegExp(
          `^M[12]-(${currentYear}|${previousYear})-[A-Z]{4}\\d-\\d{11}$`,
        ).test(invoice.taxAuthorityCode)
      ) {
        ToastHelper.showError('Mã cơ quan thuế không phù hợp cho máy tính tiền')
        return
      }

      if (
        invoice.serial?.at(4) == 'T' &&
        !new RegExp(`[A-Z0-9]{34}$`).test(invoice.taxAuthorityCode)
      ) {
        ToastHelper.showError('Mã cơ quan thuế không hợp lệ')
        return
      }
    }

    if (!invoice.serial) {
      ToastHelper.showError('Bạn chưa nhập ký hiệu hoá đơn')
      return
    } else if (
      !new RegExp(/^[1-6][CK]2[3-9][TDLMNBGH][A-Z]{2}$/).test(invoice.serial)
    ) {
      ToastHelper.showError('Ký hiệu hóa đơn không hợp lệ!')
      return
    }

    if (!invoice.no) {
      ToastHelper.showError('Bạn chưa nhập số hoá đơn')
      return
    } else if (!new RegExp(/^\d+$/).test(invoice.no)) {
      ToastHelper.showError('Số hóa đơn không hợp lệ!')
      return
    }

    if (!invoice.date) {
      ToastHelper.showError('Ngày hóa đơn không được để trống')
      return
    }

    invoice.taxAuthorityCode = invoice.taxAuthorityCode?.trim()
    invoice.serial = invoice.serial?.trim()
    invoice.no = invoice.no?.trim()?.replace(/^0+/, '')

    return invoice
  },
}

export default InvoiceHelper
