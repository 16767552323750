import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Checkbox, Space, Typography } from 'antd'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import {
  setClearRows,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import { useFormik } from 'formik'
import Tagnify from 'general/components/CustomTag/Tagnify'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { map, upperFirst } from 'lodash'
import { forwardRef } from 'react'
import { shallowEqual } from 'react-redux'
import { string } from 'yup'
import DraggableModal from '../DragableModal/DraggableModal'

const types = [
  {
    id: 'join',
    text: 'Gộp thành 1 file',
  },
  {
    id: 'single',
    text: 'Gửi riêng từng HĐ',
  },
]
const initialValues = {
  emailCustomer: '',
  nameCustomer: '',
  type: [types[1].id],
}
const ModalSendEmail = forwardRef(({ isOpen, onOpenChange }, ref) => {
  const appDispatch = useAppDispatch()
  const qc = useQueryClient()
  const { selectedInvoices } = useAppSelector(
    (state) => state.invoice,
    shallowEqual,
  )

  const sendMailMutation = useMutation({
    mutationKey: ['sent-invoice'],
    mutationFn: (formValues) =>
      invoiceApi.sendBulkInvoiceToCustomer({
        ...formValues,
        invoiceIds: map(selectedInvoices, 'invoiceId'),
      }),
    onSettled: () => appDispatch(setAppSpinning(false)),
    onMutate: () => appDispatch(setAppSpinning(true)),
    onSuccess: (res) => {
      if (res?.status !== 200 && res.result !== 'success') {
        ToastHelper.showError('Đã có lỗi trong quá trình gửi mail')
        throw new Error(res.statusText ?? res.result)
      }
      onOpenChange(false)
      ToastHelper.showSuccess('Gửi email thành công')
      qc.invalidateQueries(['invoiceListData'], { exact: true })
      appDispatch(setSelectedInvoices([]))
      appDispatch(setClearRows())
    },
  })
  const formik = useFormik({
    initialValues,
    onSubmit: (values, formHelper) => {
      sendMailMutation.mutate(values)
      formHelper.resetForm()
    },
    validateOnChange: false,
    validateOnBlur: true,
  })

  return (
    <DraggableModal
      title="Gửi hoá đơn"
      isOpen={isOpen}
      onOpenChange={(state) => {
        onOpenChange(state)
        formik.resetForm({
          values: initialValues,
        })
      }}
      handleApplyChanges={() => formik.submitForm()}
      ref={ref}
      applyText={'Gửi hoá đơn'}
      width={'40rem'}
    >
      <form onSubmit={formik.handleSubmit}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Typography.Text strong>Tên người nhận</Typography.Text>
            <CustomAntInput
              type="text"
              borderRadius="sm"
              inputProps={{
                ...formik.getFieldProps('nameCustomer'),
                placeholder: 'Nhập tên người nhận',
              }}
            />
            <Typography.Text type="danger">
              {formik.getFieldMeta('nameCustomer').error
                ? formik.getFieldMeta('nameCustomer').error
                : ''}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text strong>
              Email
              <span className="ml-1 text-danger">*</span>
            </Typography.Text>
            <Tagnify
              delimiterString=";"
              pillColor="info"
              name="emailCustomer"
              setFieldValue={formik.setFieldValue}
              placeholder="Email cách nhau bởi dấu chấm phẩy"
              defaultValue={formik.getFieldMeta('emailCustomer').value}
              restriction={{
                type: 'text',
                validationSchema: string()
                  .trim()
                  .email('Định dạng mail không hợp lệ'),
              }}
            />
            {/* <Input
                {...formik.getFieldProps('emailCustomer')}
                placeholder="Nhập email cách nhau bởi dấu chấm phẩy"
                status={formik.getFieldMeta('emailCustomer').error && 'error'}
                
              /> */}
            <Typography.Text type="danger">
              {formik.getFieldMeta('emailCustomer').error
                ? formik.getFieldMeta('emailCustomer').error
                : ''}
            </Typography.Text>
          </div>
          <div
            style={{
              marginTop: '4px',
            }}
          >
            <Checkbox.Group
              name="type-check"
              value={formik.getFieldMeta('type').value}
              onChange={(checked_options) =>
                formik.setFieldValue('type', checked_options)
              }
            >
              <Space direction="horizontal" size={'middle'}>
                {types.map((type) => (
                  <Checkbox key={type.id} value={type.id}>
                    <Typography.Text>{upperFirst(type.text)}</Typography.Text>
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </div>
          <Typography.Text type="danger">
            {formik.getFieldMeta('type').error
              ? formik.getFieldMeta('type').error
              : ''}
          </Typography.Text>
        </Space>
      </form>
    </DraggableModal>
  )
})

export default ModalSendEmail
