import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './style.scss'

import templateInvoiceApi from 'api/templateInvoiceApi'
import { thunkGetTicketCategories } from 'features/TemplateInvoice/templateInvoiceSlice'
import PrivateBaseLayout from 'general/components/eInvoiceComponents/BaseLayout/PrivateBaseLayout'
import TemplateInvoiceHelper from 'general/helpers/TemplateInvoiceHelper'
import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import templateTicketApi from 'api/templateTicketApi'

function ChooseTemplateTicket() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { ticketCategories } = useSelector((state) => state.templateInvoice)

  const [systemTemplates, setSystemTemplates] = useState()
  const [typeTicket, setTypeTicket] = useState(1)
  const [typeDeduct, setTypeDeduct] = useState('KT')
  const [typeServices, setTypeServices] = useState('GT')
  const [typePrice, setTypePrice] = useState(1)

  const menuFirstCategories = useMemo(
    () =>
      Object.keys(
        ticketCategories.reduce((group, { typeTicket }) => {
          group[typeTicket] = group[typeTicket] ?? ''

          return group
        }, {}),
      ).map((i) => parseInt(i)),
    [ticketCategories],
  )

  const menuSecondCategories = useMemo(
    () =>
      Object.keys(
        ticketCategories
          .filter((c) => c.typeTicket == typeTicket)
          .reduce((group, { typeDeduct }) => {
            group[typeDeduct] = group[typeDeduct] ?? ''

            return group
          }, {}),
      ),
    [ticketCategories, typeTicket],
  )

  const menuThirdCategories = useMemo(
    () =>
      Object.keys(
        ticketCategories
          .filter((c) => c.typeTicket == typeTicket)
          .filter((c) => c.typeDeduct == typeDeduct)
          .reduce((group, { typeServices }) => {
            group[typeServices] = group[typeServices] ?? ''

            return group
          }, {}),
      ),
    [ticketCategories, typeTicket, typeDeduct],
  )

  const menuFourthCategories = useMemo(
    () =>
      Object.keys(
        ticketCategories
          .filter((c) => c.typeTicket == typeTicket)
          .filter((c) => c.typeDeduct == typeDeduct)
          .filter((c) => c.typeServices == typeServices)
          .reduce((group, { typePrice }) => {
            group[typePrice] = group[typePrice] ?? ''

            return group
          }, {}),
      ),
    [ticketCategories, typeTicket, typeDeduct, typeServices],
  )

  const ticketCategoryIds = useMemo(
    () =>
      ticketCategories
        .filter((c) => c.typeTicket == typeTicket)
        .filter((c) => c.typeDeduct == typeDeduct)
        .filter((c) => c.typeServices == typeServices)
        .filter((c) => c.typePrice == typePrice)
        .map(({ categoryTicketId }) => categoryTicketId),
    [ticketCategories, typeTicket, typeDeduct, typeServices, typePrice],
  )

  useEffect(() => {
    if (ticketCategoryIds?.length) {
      const getSystemTemplatesByCategoryTicketIds = async () => {
        const res =
          await templateTicketApi.getSystemTemplatesByCategoryTicketIds(
            ticketCategoryIds,
          )
        setSystemTemplates(res.templates)
      }
      getSystemTemplatesByCategoryTicketIds()
    }
  }, [ticketCategoryIds])

  useEffect(() => {
    dispatch(thunkGetTicketCategories())
  }, [dispatch])

  return (
    <div className="ChooseTemplateTicket container-fluid">
      <PrivateBaseLayout nonPadding={true}>
        <div className="">
          <div className="d-flex flex-row align-items-center mb-4">
            <button
              className="btn btn-icon mr-5 btn-back"
              onClick={() => navigate(-1)}
            >
              <i className="fas fa-arrow-left"></i>
            </button>

            <Dropdown
              className="se-dd mr-5"
              onSelect={(num) => setTypeTicket(parseInt(num))}
            >
              <Dropdown.Toggle className="">
                {TemplateInvoiceHelper.displayTypeTicket(typeTicket)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {menuFirstCategories.map((num) => (
                  <Dropdown.Item key={num} eventKey={num}>
                    {TemplateInvoiceHelper.displayTypeTicket(num)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="se-dd mr-5" onSelect={(s) => setTypeDeduct(s)}>
              <Dropdown.Toggle className="">
                {TemplateInvoiceHelper.displayTypeDeduct(typeDeduct)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {menuSecondCategories.map((item) => (
                  <Dropdown.Item key={item} eventKey={item}>
                    {TemplateInvoiceHelper.displayTypeDeduct(item)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="se-dd mr-5"
              onSelect={(s) => setTypeServices(s)}
            >
              <Dropdown.Toggle className="">
                {TemplateInvoiceHelper.displayTypeServices(typeServices)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {menuThirdCategories.map((item) => (
                  <Dropdown.Item key={item} eventKey={item}>
                    {TemplateInvoiceHelper.displayTypeServices(item)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="se-dd mr-5"
              onSelect={(s) => setTypePrice(parseInt(s))}
            >
              <Dropdown.Toggle className="">
                {TemplateInvoiceHelper.displayTypePrice(typePrice)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {menuFourthCategories.map((item) => (
                  <Dropdown.Item key={item} eventKey={item}>
                    {TemplateInvoiceHelper.displayTypePrice(item)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <div className="d-flex flex-row bg-light p-3 ml-5">
              <div className="mr-4 mt-1">
                <i
                  className="fa-solid fa-lightbulb-on fa-lg"
                  style={{ color: '#efd358' }}
                ></i>
              </div>
              <div className="remind">
                <p className="mb-0">
                  Toàn bộ mẫu đã cho phép thiết lập hiển thị song ngữ trong mục
                  thiết lập chung.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row flex-wrap">
            {systemTemplates?.length ? (
              systemTemplates.map(
                (
                  {
                    invoiceTemplateId,
                    thumbnail,
                    category_ticket,
                    noOnCategory,
                  },
                  index,
                ) => (
                  <div className="item-template" key={index}>
                    <div
                      className="img-container"
                      onClick={() =>
                        navigate(
                          `/hoa-don-dau-ra/tem-ve/mau-tem-ve/tao/${invoiceTemplateId}`,
                        )
                      }
                    >
                      <img
                        className="w-100"
                        alt=""
                        src={`${process.env.REACT_APP_BASE_URL}/resources/thumbnail/${thumbnail}`}
                      />
                    </div>
                    <p className="template-symbol pb-2 mb-0">{`${TemplateInvoiceHelper.typeTicketToSymbol(
                      category_ticket?.typeTicket,
                    )}.${category_ticket?.typeDeduct}.${
                      category_ticket?.typeServices
                    }.${noOnCategory}`}</p>
                  </div>
                ),
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </PrivateBaseLayout>
    </div>
  )
}

export default ChooseTemplateTicket
