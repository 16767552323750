import AppResource from 'general/constants/AppResource'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import GuestHeader from '../../AppHeader/GuestHeader'
import './style.scss'

GuestBaseLayout.propTypes = {
  nonPadding: PropTypes.bool,
}

GuestBaseLayout.defaultProps = {
  nonPadding: false,
}

function GuestBaseLayout(props) {
  // MARK --- Params: ---
  const { nonPadding } = props

  // MARK --- Functions: ---
  const PageUp = useRef()

  const handleScrollTop = () => {
    document.documentElement.scrollTop = 0
  }

  window.onscroll = function () {
    if (PageUp.current) {
      PageUp.current.style.display =
        document.documentElement.scrollTop > 450 ? 'block' : 'none'

      PageUp.current.style.animation = 'scroll-to-top-animation 0.5s'
    }
  }

  return (
    <div className={`GuestBaseLayout min-vh-100 d-flex flex-column`}>
      {/* Header */}
      <GuestHeader />
      {/* Content */}

      <div
        className={`flex-grow-1 w-100 ${
          nonPadding ? '' : 'py-4'
        } align-self-center d-flex flex-column justify-content-between`}
      >
        {props.children}
      </div>

      <div className="fixed-button">
        <button
          ref={PageUp}
          id="page-up-dashboard"
          style={{
            marginBottom: '0px',

            display: 'none',
          }}
          onClick={handleScrollTop}
        >
          <img src={AppResource.icons.icPageUp} alt="page-up-button" />
        </button>
      </div>

      {/* Footer */}
      {/* <GuestFooter /> */}
    </div>
  )
}

export default GuestBaseLayout
