import React, { useEffect, useMemo } from 'react'

import './style.scss'
import { useDispatch } from 'react-redux'
import {
  setActivePanel,
  setMulTaxRate,
  setSpecifiedDataField,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useSelector } from 'react-redux'
import _ from 'lodash'

function MainControl(props) {
  const dispatch = useDispatch()
  // const {} = props

  const { mulTaxRate } = useSelector((state) => state.templateInvoice)
  const { RegionConfigs, category } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceInfoRegionConfigs = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'invoiceInfo')
        ?.regionConfigs,
    [RegionConfigs],
  )

  const convertInvoiceField = useMemo(
    () =>
      invoiceInfoRegionConfigs?.find(
        ({ dataField }) => dataField === 'descriptionInvoicePaperClient',
      ),
    [invoiceInfoRegionConfigs],
  )

  const tableFooterRegionConfigs = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'tableFooter')
        ?.regionConfigs,
    [RegionConfigs],
  )

  const discountRateField = useMemo(
    () =>
      tableFooterRegionConfigs?.find(
        ({ dataField }) => dataField === 'discountRate',
      ),
    [tableFooterRegionConfigs],
  )

  const currencyBlockRegionConfigs = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'currencyBlock')
        ?.regionConfigs,
    [RegionConfigs],
  )

  const exchangeRateField = useMemo(
    () =>
      currencyBlockRegionConfigs?.find(
        ({ dataField }) => dataField === 'exchangeRate',
      ),
    [currencyBlockRegionConfigs],
  )

  return (
    <div className="MainControl">
      <div>
        <p className="mt-2 mb-5 font-sfpro-bold text-uppercase">
          Xem trước cách hiển thị
        </p>

        <div className="row mx-0 g-2 mb-5">
          <div className="col-12 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="checkbox"
                id="convert-invoice"
                style={{ width: '1.2rem', height: '1.2rem' }}
                checked={convertInvoiceField?.show}
                onChange={(e) =>
                  dispatch(
                    setSpecifiedDataField({
                      regionName: 'invoiceInfo',
                      dataFieldName: 'descriptionInvoicePaperClient',
                      propertyData: { show: e.target.checked },
                    }),
                  )
                }
              />
              <label
                className="m-0 font-weight-bolder cursor-pointer"
                htmlFor="convert-invoice"
              >
                Hóa đơn dạng chuyển đổi
              </label>
            </div>
          </div>
          <div className="col-6 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="checkbox"
                id="DISCOUNT_INVOICE"
                style={{ width: '1.2rem', height: '1.2rem' }}
                checked={discountRateField?.show}
                onChange={(e) => {
                  dispatch(
                    setSpecifiedDataField({
                      regionName: 'tableFooter',
                      dataFieldName: 'discountRate',
                      propertyData: { show: e.target.checked },
                    }),
                  )
                  dispatch(
                    setSpecifiedDataField({
                      regionName: 'tableFooter',
                      dataFieldName: 'notShowDiscountRate',
                      propertyData: { show: e.target.checked },
                    }),
                  )
                  dispatch(
                    setSpecifiedDataField({
                      regionName: 'tableFooter',
                      dataFieldName: 'totalDiscountAmount',
                      propertyData: { show: e.target.checked },
                    }),
                  )

                  dispatch(
                    setSpecifiedDataField({
                      regionName: 'tableFooter1',
                      dataFieldName: 'TotalDiscountAmount',
                      propertyData: { show: e.target.checked },
                    }),
                  )
                }}
              />
              <label
                className="m-0 font-weight-bolder cursor-pointer"
                htmlFor="DISCOUNT_INVOICE"
              >
                Phát sinh chiết khấu
              </label>
            </div>
          </div>
          <div className="col-6 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="checkbox"
                id="currency-gen"
                style={{ width: '1.2rem', height: '1.2rem' }}
                checked={exchangeRateField?.show}
                onChange={(e) => {
                  dispatch(
                    setSpecifiedDataField({
                      regionName: 'currencyBlock',
                      dataFieldName: 'exchangeRate',
                      propertyData: { show: e.target.checked },
                    }),
                  )
                  dispatch(
                    setSpecifiedDataField({
                      regionName: 'currencyBlock',
                      dataFieldName: 'translationCurrency',
                      propertyData: { show: e.target.checked },
                    }),
                  )
                }}
              />
              <label
                className="m-0 font-weight-bolder cursor-pointer"
                htmlFor="currency-gen"
              >
                Phát sinh ngoại tệ
              </label>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row p-3">
          <div className="mr-4 mt-1">
            <i
              className="fa-solid fa-lightbulb-on fa-lg"
              style={{ color: '#efd358' }}
            ></i>
          </div>
          <div className="remind">
            <p className="mb-0 remind-title font-sfpro-bold">
              Hệ thống không lưu lại tùy chọn này
            </p>
            <p className="mb-0 remind-subtitle">
              Tích vào tùy chọn để xem trước nội dung hiển thị trên hóa đơn khi
              có phát sinh tương ứng
            </p>
          </div>
        </div>

        <p className="my-5 font-sfpro-bold text-uppercase">
          Điều chỉnh nhanh các thông tin
        </p>

        <div
          className="d-flex flex-row align-items-center justify-content-between bg-light p-3 mb-4 adjust-info-hover"
          onClick={() => dispatch(setActivePanel('gen_ctrl'))}
        >
          <div className="">
            <p className="mb-0 font-sfpro-bold">Thiết lập chung</p>
            <p className="mb-0">
              Tên mẫu, ký hiệu, font chữ, cỡ chữ, QR code, song ngữ, tùy chọn
              hiển thị hóa đơn.
            </p>
          </div>

          <div className="mr-4 mt-1">
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>

        <div
          className="d-flex flex-row align-items-center justify-content-between bg-light p-3 mb-4 adjust-info-hover"
          onClick={() => dispatch(setActivePanel('logo_ctrl'))}
        >
          <div className="">
            <p className="mb-0 font-sfpro-bold">
              Logo, khung viền, hình nền, logo chìm
            </p>
          </div>

          <div className="mr-4 mt-1">
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>

        <div
          className="d-flex flex-row align-items-center justify-content-between bg-light p-3 mb-4 adjust-info-hover"
          onClick={() => dispatch(setActivePanel('detail_ctrl'))}
        >
          <div className="">
            <p className="mb-0 font-sfpro-bold">
              Tùy chỉnh nội dung chi tiết hóa đơn
            </p>
            <p className="mb-0">
              Thêm, sửa, xóa, sắp xếp, thay đổi định dạng, gộp các dòng thông
              tin của hóa đơn
            </p>
          </div>

          <div className="mr-4 mt-1">
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>

        <div>
          <div className="wrap-button d-inline-flex flex-row">
            {category && category.taxType != 2 && (
              <div
                className={`cursor-pointer button ${
                  !mulTaxRate ? 'active' : ''
                }`}
                onClick={() => dispatch(setMulTaxRate(false))}
              >
                Một thuế suất
              </div>
            )}
            {category && category.taxType != 1 && (
              <div
                className={`cursor-pointer button ${
                  mulTaxRate ? 'active' : ''
                }`}
                onClick={() => dispatch(setMulTaxRate(true))}
              >
                Nhiều thuế suất
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainControl
