import axiosClient from './axiosClient'

const employeeApi = {
  find: (params) => {
    const url = '/employee/find'
    return axiosClient.get(url, { params })
  },

  create: (params) => {
    const url = '/employee/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = `/employee/update/${params.employeeId}`
    return axiosClient.put(url, params)
  },

  delete: (params) => {
    const url = '/employee/delete'
    return axiosClient.delete(url, {
      data: {
        employeeIds: params,
      },
    })
  },
}

export default employeeApi
