import _ from 'lodash'
import { useMemo } from 'react'
import useGetUserPermissions from './Queries/useGetPermissions'

/**
 * @typedef {Object} Permission
 * @property {string} keyAction - The key of the permission.
 * @property {string} permissionCode - The permission code.
 *
 * @param {Array<Permission>} params - An array of permissions.
 * @example ```jsx
 * const { isLoadingPermission, nameOfThePermissionKey } = useCheckPermission([{
 *   keyAction: 'nameOfThePermissionKey',
 *   permissionCode: 'THE_PERMISSION_CODE',
 * }], arrayOfPermissions)
 * ```
 */

export default function useCheckPermission(params) {
  const { data: functions, isLoading: isLoadingPermission } =
    useGetUserPermissions()
  const permissions = useMemo(() => {
    return _.chain(params)
      .map(({ keyAction, permissionCode }) => [
        keyAction,
        _.some(functions?.rows, { listFunctionCode: permissionCode }),
      ])
      .fromPairs()
      .value()
  }, [functions?.rows, params])

  return {
    isLoadingPermission,
    ...permissions,
  }
}
