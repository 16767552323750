import { useQueryGeneralReport } from 'features/Report/screens/GeneralReport/queries'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { Table } from 'react-bootstrap'

export default function GeneralReportTable() {
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)
  const filter = useAppSelector((s) => s.filter.generalReport)
  const { data: generalReportInfo } = useQueryGeneralReport(filter)
  const TaxRateBody = (props) => {
    const { invoiceProducts, totalBeforeTax, taxMoney } = props?.obj
    return (
      <>
        {invoiceProducts?.map((item, index) => (
          <tr key={index}>
            <td>{item?.serial}</td>
            <td>{item?.no ? Utils.padString(item?.no, 8, '0') : ''}</td>
            <td>{item?.date}</td>
            <td>{item?.customerCompanyName}</td>
            <td>{item?.customerTaxCode}</td>
            <td className="text-right">
              {Utils.formatNumber(item?.totalUnitPrice, sysConfig.totalCost)}
            </td>
            <td className="text-right">
              {Utils.formatNumber(item?.taxMoney, sysConfig.totalCost)}
            </td>
          </tr>
        ))}
        <tr className="font-weight-bolder table-light">
          <td colSpan="5">
            <b>Tổng cộng</b>
          </td>
          <td className="text-right">
            <b>{Utils.formatNumber(totalBeforeTax, sysConfig.totalCost)}</b>
          </td>
          <td className="text-right">
            <b>{Utils.formatNumber(taxMoney, sysConfig.totalCost)}</b>
          </td>
        </tr>
      </>
    )
  }

  return (
    <>
      <Table responsive bordered id="table-xlsx">
        <thead>
          <tr>
            <th colSpan="2" className="text-center">
              Hóa đơn, Chứng từ bán
            </th>
            <th
              style={{ verticalAlign: 'middle' }}
              rowSpan="2"
              className="text-center min-w-150px"
            >
              Ngày, tháng, năm phát hành hóa đơn
            </th>
            <th
              style={{ verticalAlign: 'middle' }}
              rowSpan="2"
              className="text-center min-w-150px"
            >
              Tên khách hàng
            </th>
            <th
              style={{ verticalAlign: 'middle' }}
              rowSpan="2"
              className="text-center min-w-150px"
            >
              Mã số thuế khách hàng
            </th>
            <th
              style={{ verticalAlign: 'middle' }}
              rowSpan="2"
              className="text-center min-w-150px"
            >
              Doanh số bán chưa có thuế
            </th>
            <th
              style={{ verticalAlign: 'middle' }}
              rowSpan="2"
              className="text-center min-w-150px"
            >
              Thuế GTGT
            </th>
          </tr>
          <tr>
            <th className="text-center min-w-100px">Ký hiệu</th>
            <th className="text-center min-w-100px">Số hóa đơn</th>
          </tr>
        </thead>
        <tbody className="">
          <tr
            style={{ backgroundColor: '#d1ecf1' }}
            className="font-weight-bolder"
          >
            <th colSpan="8">Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 0%</th>
          </tr>
          <TaxRateBody obj={generalReportInfo?.invoicesByTax[0]} />

          <tr
            style={{ backgroundColor: '#d1ecf1' }}
            className="font-weight-bolder"
          >
            <th colSpan="8">Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 5%</th>
          </tr>
          <TaxRateBody obj={generalReportInfo?.invoicesByTax[1]} />
          <tr
            style={{ backgroundColor: '#d1ecf1' }}
            className="font-weight-bolder"
          >
            <th colSpan="8">Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 8%</th>
          </tr>
          <TaxRateBody obj={generalReportInfo?.invoicesByTax[2]} />
          <tr
            style={{ backgroundColor: '#d1ecf1' }}
            className="font-weight-bolder"
          >
            <th colSpan="8">Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 10%</th>
          </tr>
          <TaxRateBody obj={generalReportInfo?.invoicesByTax[3]} />

          <tr
            style={{ backgroundColor: '#d1ecf1' }}
            className="font-weight-bolder"
          >
            <th colSpan="8">
              Hàng hóa, dịch vụ không chịu thuế giá trị gia tăng
            </th>
          </tr>
          <TaxRateBody obj={generalReportInfo?.invoicesByTax[4]} />

          <tr
            style={{ backgroundColor: '#d1ecf1' }}
            className="font-weight-bolder"
          >
            <th colSpan="8">Hàng hóa, dịch vụ không kê khai thuế</th>
          </tr>
          <TaxRateBody obj={generalReportInfo?.invoicesByTax[6]} />

          <tr
            style={{ backgroundColor: '#d1ecf1' }}
            className="font-weight-bolder"
          >
            <th colSpan="8">Hàng hóa, dịch vụ chịu thuế suất thuế GTGT khác</th>
          </tr>

          <TaxRateBody obj={generalReportInfo?.invoicesByTax[5]} />
        </tbody>
      </Table>
      {/* General summary */}
      <div className="w-100 d-flex flex-column gap-3">
        <b>
          Tổng doanh thu hàng hóa, dịch vụ bán ra:
          {Utils.formatNumber(
            generalReportInfo?.totalRevenueBeforeTax,
            sysConfig.totalCost,
          )}
        </b>

        <b>
          Tổng số thuế GTGT của hàng hóa, dịch vụ bán ra:
          {Utils.formatNumber(generalReportInfo?.totalTax, sysConfig.totalCost)}
        </b>

        <b>
          Tổng doanh thu hàng hóa, dịch vụ bán ra chịu thuế GTGT:
          {Utils.formatNumber(
            generalReportInfo?.totalRevenueAfterTax,
            sysConfig.totalCost,
          )}
        </b>
      </div>
    </>
  )
}
