import React from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './style.scss'

ModalConfirm.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
}

function ModalConfirm(props) {
  const { show, setShow, header, content, handleConfirm, runWhenClosedFunc } =
    props

  let handleClose = () => {
    setShow(false)
    if (runWhenClosedFunc) {
      runWhenClosedFunc()
    }
  }

  return (
    <Modal show={show} onHide={handleClose} centered className="ModalConfirm">
      <Modal.Header closeButton>
        <Modal.Title>
          <span>
            <i
              className="fa-light fa-triangle-exclamation mr-3"
              style={{ fontSize: 20, color: '#ff9f0e' }}
            ></i>
            {header}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-4 px-8"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>
          <button
            className="btn btn-danger px-8"
            onClick={async (e) => {
              try {
                await handleConfirm(e)
                handleClose()
              } catch (e) {
                console.log(e)
              }
            }}
          >
            Đồng ý
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm
