import { useTranslation } from 'react-i18next'
import { Result, Button, Empty, Form } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  LabelContainer,
  ModalCustom,
  Span,
  SpanContainer,
  StyleButtonClose,
} from 'features/TaxDeduction/styled'
import { ContainerContentModal } from 'features/Department/styled'
import {
  FormCustom,
  InputCustom,
  RadioCustom,
  RadioCustomT,
  RedStar,
  SelectCustom,
} from 'features/TaxDeductionDocuments/styled'
import { toLower } from 'general/utils/Utils'
import SelectOptions from 'general/constants/SelectOptions'
import { useEffect, useState } from 'react'
import { FormLabel } from 'react-bootstrap'
import { REGEX_EMAIL_MAX, regexLong } from 'general/constants/AppRegex'
function PopupSendEmail({
  visible,
  setVisible,
  submit,
  form,
  disableSave,
  setDisableSave,
  dataActive,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [cc, setCc] = useState(false)
  const [bcc, setBcc] = useState(false)
  useEffect(() => {
    setDisableSave(false)
    form.resetFields()
    form.setFieldsValue({
      customerName: dataActive?.taxpayersName,
      employeeEmail: dataActive?.employeeEmail,
    })
    setBcc(false)
    setCc(false)
  }, [visible])

  const save = () => {
    form
      .validateFields()
      .then((data) => {
        setDisableSave(true)
        submit(data)
      })
      .catch((errorInfo) => {})
  }

  return (
    <>
      <ModalCustom
        title={t('accountingDocuments.titleSendGmail')}
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        width={700}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    // changeOpenModal(false)
                  }}
                >
                  {t('accountingDocuments.close')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  onClick={() => {
                    save()
                  }}
                  disabled={disableSave}
                >
                  {t('accountingDocuments.send')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <ContainerContentModal padding="0px 20px 20px 20px">
          <div
            className="d-flex flex-row justify-content-between"
            style={{ backgroundColor: '#f0f2f5', marginBottom: '10px' }}
          ></div>
          <FormCustom form={form} layout="vertical">
            <div>
              <FormCustom.Item
                label={
                  <>
                    {`${t('accountingDocuments.namenn')} `}
                    <RedStar>*</RedStar>
                  </>
                }
                name={'customerName'}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            t('department.keyRequired', {
                              key: t('accountingDocuments.namenn'),
                            }),
                          ),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <InputCustom
                  maxLength={255}
                  placeholder={t('accountingDocuments.pltennn')}
                />
              </FormCustom.Item>
            </div>
            <SpanContainer>
              <Span
                onClick={() => {
                  setCc(!cc)
                }}
              >
                Cc
              </Span>
              <Span
                onClick={() => {
                  setBcc(!bcc)
                }}
              >
                Bcc
              </Span>
            </SpanContainer>
            <div>
              <FormCustom.Item
                label={
                  <>
                    {`${t('accountingDocuments.email')} `}
                    <RedStar>*</RedStar>
                  </>
                }
                name={'customerEmail'}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            t('department.keyRequired', {
                              key: t('taxpayer.email'),
                            }),
                          ),
                        )
                      }
                      const regex = new RegExp(REGEX_EMAIL_MAX)
                      if (value && !regex.test(value.trim())) {
                        return Promise.reject(
                          new Error(
                            ` ${t('taxpayer.typeFeild', { key: t('taxpayer.email') })}`,
                          ),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <InputCustom
                  style={{ width: 650 }}
                  maxLength={255}
                  placeholder={t('accountingDocuments.plEm')}
                />
              </FormCustom.Item>
            </div>
            {cc && (
              <div>
                <FormCustom.Item
                  label={<>Cc</>}
                  name={'ccEmails'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        const regex = new RegExp(regexLong.REGEX_LIST_EMAIL)
                        if (value && !regex.test(value.trim())) {
                          return Promise.reject(
                            new Error(
                              ` ${t('taxpayer.typeFeild', { key: t('taxpayer.email') })}`,
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputCustom
                    maxLength={2500}
                    placeholder={t('accountingDocuments.placListEmail')}
                  />
                </FormCustom.Item>
              </div>
            )}
            {bcc && (
              <div>
                <FormCustom.Item
                  label={<>Bcc</>}
                  name={'bccEmails'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        const regex = new RegExp(regexLong.REGEX_LIST_EMAIL)
                        if (value && !regex.test(value.trim())) {
                          return Promise.reject(
                            new Error(
                              ` ${t('taxpayer.typeFeild', { key: t('taxpayer.email') })}`,
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputCustom
                    maxLength={2500}
                    placeholder={t('accountingDocuments.placListEmail')}
                  />
                </FormCustom.Item>
              </div>
            )}
          </FormCustom>
        </ContainerContentModal>
      </ModalCustom>
    </>
  )
}
export default PopupSendEmail
