import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Row, Space, Switch, Typography } from 'antd'
import { useFormik } from 'formik'
import Tagnify from 'general/components/CustomTag/Tagnify'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useEffect, useRef } from 'react'
import { string } from 'yup'
import { ROUTE_ACTION_TYPE } from '../../enums'

const initialValues = {
  nameRoute: '',
  routeStart: '',
  routeEnd: '',
  noCar: '',
  startTime: '',
  availability: true,
}

export const RouteActionModal = ({
  title,
  open,
  toggleModalState,
  description,
  actionType,
  formData,
  onClickApply,
  isLoading,
}) => {
  const modalRef = useRef(null)
  const formik = useFormik({
    initialValues:
      actionType === ROUTE_ACTION_TYPE.NEW ? initialValues : formData,
    enableReinitialize: true,
    validationSchema: ROUTE_ACTION_TYPE.DELETE
      ? null
      : ValidationSchema.createNewRoute,
    validateOnChange: false,
    onSubmit: (values) => {
      onClickApply(values)
    },
  })
  const { resetForm } = formik

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <DraggableModal
      title={title}
      description={description}
      cancelText="Huỷ bỏ"
      applyText="Xác nhận"
      ref={modalRef}
      isOpen={open}
      isLoading={isLoading}
      onOpenChange={(state) => toggleModalState(state)}
      handleApplyChanges={() => {
        actionType === ROUTE_ACTION_TYPE.DELETE
          ? onClickApply()
          : formik.submitForm()
      }}
      width={actionType === ROUTE_ACTION_TYPE.DELETE ? 'max-content' : 800}
    >
      {actionType === ROUTE_ACTION_TYPE.DELETE ? null : (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Space
            direction="vertical"
            size="large"
            style={{ paddingTop: '1rem', width: '100%' }}
          >
            {/* Tên tuyến đường */}
            <Row align={'middle'}>
              <Col span={6}>
                <Typography.Text strong>
                  Tên tuyến đường
                  <span className="text-danger ml-1">*</span>
                </Typography.Text>
              </Col>
              <Col span={24 - 6}>
                <CustomAntInput
                  borderRadius="sm"
                  type="text"
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'Nhập tên tuyến đường',
                    status: formik.getFieldMeta('nameRoute').error
                      ? 'error'
                      : '',
                    value: formik.getFieldProps('nameRoute').value,
                    name: formik.getFieldProps('nameRoute').name,
                    onChange: formik.getFieldProps('nameRoute').onChange,
                  }}
                />
              </Col>
            </Row>
            {/* Điểm đến - điểm đi */}
            <Row align={'middle'}>
              <Col span={6}>
                <Typography.Text strong>
                  Điểm đi
                  <span className="text-danger ml-1">*</span>
                </Typography.Text>
              </Col>
              <Col span={12 - 6}>
                <CustomAntInput
                  type="text"
                  borderRadius="sm"
                  inputProps={{
                    value: formik.getFieldProps('routeStart').value,
                    name: formik.getFieldProps('routeStart').name,
                    onChange: formik.getFieldProps('routeStart').onChange,
                    autoComplete: 'off',
                    placeholder: 'Nhập điểm đi',
                    status: formik.getFieldMeta('routeStart').error
                      ? 'error'
                      : '',
                  }}
                />
              </Col>
              <Col
                span={6}
                style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                }}
              >
                <Typography.Text strong>
                  Điểm đến
                  <span className="text-danger ml-1">*</span>
                </Typography.Text>
              </Col>
              <Col span={12 - 6}>
                <CustomAntInput
                  type="text"
                  borderRadius="sm"
                  inputProps={{
                    value: formik.getFieldProps('routeEnd').value,
                    name: formik.getFieldProps('routeEnd').name,

                    onChange: formik.getFieldProps('routeEnd').onChange,
                    autoComplete: 'off',
                    placeholder: 'Nhập điểm đến',
                    status: formik.getFieldMeta('routeEnd').error
                      ? 'error'
                      : '',
                  }}
                />
              </Col>
            </Row>
            {/* Giờ khởi hành */}
            <Row align={'middle'}>
              <Col span={6}>
                <Typography.Text strong>Giờ khởi hành</Typography.Text>
              </Col>
              <Col span={24 - 6}>
                <Tagnify
                  defaultValue={formik.getFieldProps('startTime').value}
                  name={formik.getFieldProps('startTime').name}
                  placeholder={'Nhập thêm giờ khởi hành'}
                  pillColor={'primary'}
                  delimiterString={';'}
                  setFieldValue={formik.setFieldValue}
                  restriction={{
                    type: 'datetime',
                    dateFormat: ['HH:mm', 'H:m', 'HH:m', 'H:mm'],
                  }}
                />
              </Col>
              <Col span={12} offset={6} className="mt-2">
                <Typography.Text type="secondary">
                  Ví dụ: 01:02; 02:03; 07:30
                </Typography.Text>
              </Col>
            </Row>
            {/* Số xe */}
            <Row align={'middle'}>
              <Col span={6}>
                <Typography.Text strong>Số xe</Typography.Text>
              </Col>
              <Col span={24 - 6}>
                <Tagnify
                  defaultValue={formik.getFieldProps('noCar').value}
                  name={formik.getFieldProps('noCar').name}
                  placeholder={'Nhập số xe'}
                  pillColor={'primary'}
                  delimiterString={';'}
                  setFieldValue={formik.setFieldValue}
                  restriction={{
                    type: 'text',
                    validationSchema: string(),
                  }}
                />
              </Col>
              <Col span={12} offset={6} className="mt-2">
                <Typography.Text type="secondary">
                  Ví dụ: 29-A1 xxx-xxx; 34-D5 xxx-xxx;
                </Typography.Text>
              </Col>
            </Row>
            {/* Tình trạng sử dụng */}
            <Row align={'middle'}>
              <Col span={6}>
                <Typography.Text strong>Tình trạng sử dụng</Typography.Text>
              </Col>
              <Col span={24 - 6}>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={formik.getFieldProps('availability').value}
                  onChange={(checked) =>
                    formik.setFieldValue('availability', checked)
                  }
                />
              </Col>
            </Row>
          </Space>
        </form>
      )}
    </DraggableModal>
  )
}
