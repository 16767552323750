import { queryOptions } from '@tanstack/react-query'
import goodsGroupApi from 'api/goodsGroupApi'
import _ from 'lodash'

export const goodsGroupQueries = {
  base: {
    scope: ['category', 'goods-group'],
  },
  listOfGoodsGroup: (params) =>
    queryOptions({
      queryKey: [goodsGroupQueries.base, { entity: 'goods-group' }, params],
      queryFn: ({ signal }) => goodsGroupApi.find(params, signal),
      select: (res) => {
        if (res.result !== 'success') return
        return {
          data: res.goodsGroups ?? [],
          total: _.get(res, 'total', 0),
        }
      },
    }),
}
