import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import dayjs from 'dayjs'
import { listTaxMessageInvoiceFromPosQueries } from 'features/TaxMessage/screens/ListTaxMessageInvoiceFromPos/queries'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomLabel from 'general/components/CustomLabel'
import CustomTag from 'general/components/CustomTag'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { STATUS_RELEASE } from 'general/constants/AppConstants'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import { useMemo, useRef, useState } from 'react'

export default function InvoiceFromPosList({ open, onOpenChange, invoiceIds }) {
  const modalRef = useRef(null)
  const [filterParams, setFilterParams] = useState('ALL')
  const { data } = useQuery(
    listTaxMessageInvoiceFromPosQueries.detailInvoice({
      invoiceIdsArray: invoiceIds || [],
      classify: 'MTT',
    }),
  )
  const defaultColumns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<TaxMessageInvoiceFromPos['summaryDetail'][0]>[]} */
    const arr = [
      {
        id: 'serial',
        name: 'Ký hiệu',
        center: true,
        selector: (row) => row.serial,
      },
      {
        id: 'dateInvoice',
        name: 'Ngày hóa đơn',
        center: true,
        selector: (row) => row.date,
        format: (row) =>
          dayjs(row.date).isValid() ? dayjs(row.date).format('L') : null,
      },
      {
        id: 'invoiceNo',
        name: 'Số hóa đơn',
        center: true,
        selector: (row) => row.no,
      },
      {
        id: 'taxAuthorityCode',
        name: 'Mã của CQT',
        center: true,
        minWidth: '200px',
        selector: (row) => row.taxAuthorityCode,
      },
      {
        id: 'sendTaxStatus',
        name: 'Trạng thái',
        center: true,
        selector: (row) => row.sendTaxStatus,
        format: (row) => {
          const { color, text } = InvoiceHelper.renderSendTaxStatus(
            row.sendTaxStatus,
          )
          return (
            <CustomTag
              text={text}
              tagProps={{
                className: 'badge badge-light-' + color,
              }}
            />
          )
        },
      },
      {
        name: 'Hành động',
        center: true,
        compact: true,
        cell: () => (
          <Space onClick={() => {}}>
            <TableAction
              titleActionText="Xem chi tiết"
              icon={<IconAction className="fa-light fa-eye" />}
              onClick={() => {}}
            />
          </Space>
        ),
      },
    ]
    return arr
  }, [])

  return (
    <DraggableModal
      ref={modalRef}
      title="Danh sách hóa đơn"
      isOpen={open}
      onOpenChange={onOpenChange}
      cancelText="Đóng"
      hasApplyButton={false}
      width="80%"
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <div className="bg-secondary">
        <div className="pl-5 my-5">
          <CustomLabel text="Trạng thái" width={'fit-content'} />
          <CustomAntSelect
            value={filterParams}
            options={[
              { label: 'Tất cả trạng thái', value: 'ALL' },
              { label: 'Hợp lệ', value: STATUS_RELEASE.TAX_VALID },
              { label: 'Không hợp lệ', value: STATUS_RELEASE.TAX_NOT_VALID },
            ]}
            onChange={(value) => setFilterParams(value)}
            className="ml-3 w-200px"
          />
        </div>
        <CustomDataTable
          persistTableHead
          columns={defaultColumns}
          dataSource={data?.rows}
        />
      </div>
    </DraggableModal>
  )
}
