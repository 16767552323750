import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { DivImg, ContainerDivImg, HeaderLabel } from '../../styled'
import AppResource from 'general/constants/AppResource'
import FindDeductionDocuments from '../DeductionDocuments/find'
import { useNavigate } from 'react-router-dom'
function TabProcedure(
  {
    openModalFind,
    changeOpenModalFind,
    //dataFind
    dataSource,
    panningDataSource,
    reloadFind,
    changePaniningFind,
    setTabActiveKey,
  },
  props,
) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <HeaderLabel>
        <div>{t('taxDeduction.businesSprocess')}</div>
        <div>
          {t('taxDeduction.electronicDocumentsForPersonalIncomeTaxDeduction')}
        </div>
        <div>{t('taxDeduction.circularNoTTBTC')}</div>
      </HeaderLabel>
      <center>
        <ContainerDivImg>
          <img src={AppResource.taxDeduction.keenTaxDuctionborder} />
          <DivImg
            position={'absolute'}
            top={'60px'}
            left={'90px'}
            onClick={() => {
              changeOpenModalFind(true)
            }}
          >
            <img src={AppResource.taxDeduction.keenTaxDuction5} />
            <div>{t('taxDeduction.registeringProfile')}</div>
          </DivImg>
          <DivImg
            position={'absolute'}
            top={'250px'}
            left={'90px'}
            onClick={() => {
              navigate(`/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau`)
            }}
          >
            <img src={AppResource.taxDeduction.keenTaxDuction2} />
            <div>{t('taxDeduction.templateInitialization')}</div>
          </DivImg>
          <DivImg position={'absolute'} top={'160px'} left={'275px'}>
            <img src={AppResource.taxDeduction.keenTaxDuction6} />
            <div>{t('taxDeduction.fileWithTheTaxAuthority')}</div>
          </DivImg>
          <DivImg position={'absolute'} top={'160px'} left={'435px'}>
            <img src={AppResource.taxDeduction.keenTaxDuction8} />
            <div>{t('taxDeduction.notification')}</div>
            <div>{t('taxDeduction.releaseTemplate')}</div>
          </DivImg>
          <DivImg
            position={'absolute'}
            top={'160px'}
            left={'600px'}
            onClick={() => {
              setTabActiveKey('2')
            }}
          >
            <img src={AppResource.taxDeduction.keenTaxDuction4} />
            <div>{t('taxDeduction.prepareDocuments')}</div>
          </DivImg>
          <DivImg position={'absolute'} top={'65px'} right={'250px'}>
            <img src={AppResource.taxDeduction.keenTaxDuction1} />
            <div>{t('taxDeduction.sendToTheTaxpayer')}</div>
            <div>{t('taxDeduction.taxReceipts')}</div>
          </DivImg>
          <DivImg position={'absolute'} top={'250px'} right={'245px'}>
            <img src={AppResource.taxDeduction.keenTaxDuction3} />
            <div>{t('taxDeduction.prepareAList')}</div>
            <div>{t('taxDeduction.utilizeDocuments')}</div>
          </DivImg>
          <DivImg position={'absolute'} top={'250px'} right={'80px'}>
            <img src={AppResource.taxDeduction.keenTaxDuction7} />
            <div>{t('taxDeduction.fileWithTheTaxAuthority')}</div>
          </DivImg>
        </ContainerDivImg>
        <FindDeductionDocuments
          openModalFind={openModalFind}
          changeOpenModalFind={changeOpenModalFind}
          dataSource={dataSource}
          panningDataSource={panningDataSource}
          findDataSource={reloadFind}
          changePaniningFind={changePaniningFind}
        />
      </center>
    </>
  )
}
TabProcedure.propTypes = {
  openModalFind: PropTypes.bool,
  changeOpenModalFind: PropTypes.func,
  dataSource: PropTypes.array,
  panningDataSource: PropTypes.object,
  findDataSource: PropTypes.func,
}
export default TabProcedure
