import React from 'react'
import { DivFieldValue } from 'features/TaxDeductionDocuments/styled'
import Utils, {
  convertDate,
  numberToIsoDate,
  removeTextBetweenBraces,
} from 'general/utils/Utils'
import TypeInputAuto from './TypeInput'
import { useTranslation } from 'react-i18next'

const DivFieldValueComponent = ({
  styleInput,
  content,
  idx,
  activeFeild,
  funClick,
  type,
  indexLayout,
  activeLayout,
  itemFeild,
  indexTd,
  activeTd,
  language,
  formState,
  setVisibleSelectTaxpaper,
  listIndentify,
  setListIndentify,
  templateDefault,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {itemFeild?.inforSign != true && itemFeild?.inforSign != false ? (
        itemFeild?.[language]?.transformationTemplate !== false && (
          <DivFieldValue
            active={
              idx == activeFeild?.index &&
              activeLayout.index == indexLayout &&
              indexTd == activeTd.index
            }
            onClick={funClick}
            key={idx}
            textAlign={content?.valueStyle?.textAlign}
            fontSize={
              content?.valueStyle?.fontSize &&
              `${content.valueStyle?.fontSize}px`
            }
            color={content?.valueStyle?.color}
            fontWeight={content?.valueStyle?.fontWeight}
            textDecoration={content?.valueStyle?.textDecoration && 'underline'}
            textTransform={content?.valueStyle?.textTransform}
            lineHeight={
              content?.valueStyle?.lineHeight &&
              `${content?.valueStyle?.lineHeight}px`
            }
            padding={content?.valueStyle?.padding}
            margin={content?.valueStyle?.margin}
            width={
              content?.valueStyle?.width && `${content?.valueStyle?.width}px`
            }
            height={
              content?.valueStyle?.height && `${content?.valueStyle?.height}px`
            }
            fontFamily={content?.valueStyle?.fontFamily}
          >
            {(() => {
              switch (itemFeild?.typeValue) {
                case 1:
                  return (
                    <>
                      {content?.value && type === 'value'
                        ? removeTextBetweenBraces(content?.value)
                        : content?.value}
                    </>
                  )
                case 2:
                  return (
                    <>
                      {content?.value && type === 'value'
                        ? removeTextBetweenBraces(content?.value)
                        : content?.value}
                    </>
                  )
                case 3:
                  return (
                    <>
                      {content?.value && type === 'value'
                        ? removeTextBetweenBraces(content?.value)
                        : content?.value}
                    </>
                  )
                case 4:
                  return (
                    <>
                      {type === 'value' && content?.value
                        ? removeTextBetweenBraces(content?.value)
                          ? convertDate(
                              numberToIsoDate(
                                removeTextBetweenBraces(content?.value),
                              ),
                              'DD/MM/YYYY',
                            )
                          : removeTextBetweenBraces(content?.value)
                        : content?.value}
                    </>
                  )
                default:
                  return (
                    <>
                      {content?.value && type === 'value'
                        ? removeTextBetweenBraces(content?.value)
                        : content?.value}
                    </>
                  )
              }
            })()}

            {styleInput && type === 'value' && itemFeild.typeInput ? (
              <TypeInputAuto
                itemFeild={itemFeild}
                language={language}
                setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                listIndentify={listIndentify}
                setListIndentify={setListIndentify}
              />
            ) : (
              <>
                {type === 'label' &&
                  `${itemFeild?.[language]?.valueLanguage ? itemFeild[language].valueLanguage : ''} ${!itemFeild?.[language]?.hideEndText ? ':' : ''}`}
              </>
            )}
          </DivFieldValue>
        )
      ) : (
        <>
          {itemFeild?.inforSign === true &&
            templateDefault?.status == 1 &&
            !styleInput && (
              <div
                className="p-2"
                style={{
                  backgroundColor: '#E3F2E4',
                  border: '1px solid #7cc576',
                  width: '100%',
                  color: 'black',
                  marginTop: '10px',
                }}
              >
                <div style={{ fontWeight: 600 }}>Signature Valid</div>
                {/* {dataField3.show && ( */}
                <div className={`horizontal-content ${'active-data-field-l '}`}>
                  <div
                    className="edit-label mr-1"
                    style={Utils.filterStyles({ color: 'black', fontSize: 12 })}
                  >
                    {t('accountingDocuments.signBy')} <b>{content?.value}</b>
                  </div>
                  <div
                    className="edit-label mr-1"
                    style={Utils.filterStyles({ color: 'black', fontSize: 12 })}
                  >
                    {t('accountingDocuments.signDate')}{' '}
                    <b>{content?.signDate}</b>
                  </div>
                </div>
              </div>
            )}
        </>
      )}
    </>
  )
}

export default DivFieldValueComponent
