import axiosClient from './axiosClient'

const ticketDraftApi = {
  create: (params) => {
    const url = '/ticket/draft/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = '/ticket/draft/update'
    return axiosClient.put(url, params)
  },

  listDraft: (params) => {
    const url = '/ticket/draft/list'
    return axiosClient.get(url, { params })
  },

  findById: (params) => {
    const url = '/ticket/draft/find-by-id'
    return axiosClient.get(url, { params })
  },

  deleteById: (ids) => {
    const url = `/ticket/draft/delete-by-id`
    return axiosClient.delete(url, {
      data: {
        ticketDraftIds: ids,
      },
    })
  },

  deleteAll: () => {
    const url = `/ticket/draft/delete-all`
    return axiosClient.delete(url)
  },

  exportTicketDraftItem: (params) => {
    const url = '/ticket/draft-item/export'

    return axiosClient.post(url, params, { responseType: 'blob' })
  },

  hsmSign: (params) => {
    const url = '/ticket/draft/hsm-sign'

    return axiosClient.post(url, params)
  },

  checkUpload: (params) => {
    const url = '/ticket/draft-item/check-upload'

    return axiosClient.post(url, params)
  },

  upload: (params) => {
    const url = '/ticket/draft-item/upload'

    return axiosClient.post(url, params)
  },
}

export default ticketDraftApi
