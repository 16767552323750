import 'assets/styles/custom.scss'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
// // fonts
// import "assets/fonts/sf-pro-fonts/SFPRODISPLAYBOLD.OTF"
// import "assets/fonts/sf-pro-fonts/SFPRODISPLAYREGULAR.OTF"
// style
// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
// keen
import 'assets/styles/keen/theme01/plugins.bundle.css'
import 'assets/styles/keen/theme01/style.bundle.css'
// app custom style
import 'assets/styles/app.style.scss'
import './index.css'
// react-datepicker
import 'react-datepicker/dist/react-datepicker.css'
// i18
import i18n from 'i18n'
import { I18nextProvider } from 'react-i18next'
// redux
import store from 'app/store'
import { Provider as ReduxProvider } from 'react-redux'
// tanstack query
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'antd'
import queryClient from 'api/queryClient'
import { ColumnsProvider } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'
import { SubColumnsProvider } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/SubColumnsContext'
import { customTheme } from 'general/utils/CustomAntTheme'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <ColumnsProvider>
            <SubColumnsProvider>
              <ConfigProvider theme={customTheme}>
                <App />
              </ConfigProvider>
            </SubColumnsProvider>
          </ColumnsProvider>
        </BrowserRouter>
      </I18nextProvider>
      <div style={{ fontSize: '15px' }}>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
          client={queryClient}
        />
      </div>
    </QueryClientProvider>
  </ReduxProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
