import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import CreateTemplateInvoiceHeader from './components/CreateTemplateInvoiceHeader'

import './style.scss'
import RightControl from './components/RightControl'
import LeftContent from './components/LeftContent'
import {
  clearTemplateInvoiceState,
  setInstanceInfo,
  setMulTaxRate,
  thunkGetExtendFields,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useDispatch } from 'react-redux'
import templateInvoiceApi from 'api/templateInvoiceApi'
import { useSelector } from 'react-redux'
import Utils from 'general/utils/Utils'
import ModalExportTemplate from '../../components/ModalExportTemplate'
import { useSubColumns, useSubColumnsDispatch } from './SubColumnsContext'
import { useColumns, useColumnsDispatch } from './ColumnsContext'
import { setAppSpinning } from 'app/appSlice'
import { useRef } from 'react'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'

const initialInstance = {
  templateInvoiceName: 'Hóa đơn GTGT',
  templateInvoiceForm: 0 /* 1: Có mã CQT, 0: Ko có mã CQT */,
  templateInvoiceSeries: '1K23TYY',
  dataTransferMethod: '1' /* 1: chuyển đầy đủ nội dung tưng hóa đơn, 2: ... */,
  sellerInfoPosition: 1 /* 1,2,3 */,
  fontFamily: 'Times New Roman',
  fontSize: 14,
  color: '#000000',
  lineType: 'solid',
  lineHeight: 1.4,
  isShowQRCode: true,
  duplicateInfoMulPages: false,
  isShowLabelEn: true,
  isShowWatermarkInAdjustedInvoice: false,

  logoPosition: 1,

  logoInfo: null,
  backgroundInfo: null,
  surroundInfo: null,

  RegionConfigs: [
    {
      regionName: 'sellerInfo',
      regionConfigs: [
        {
          dataField: 'sellerName',
          value: {
            value: 'CÔNG TY CỔ PHẦN ĐẦU TƯ INDO',
            fontSize: 20,
            fontWeight: 'bold',
            // fontStyle: 'italic',
            // color: '#FF0000',
            lineHeight: 1.4,
            autoBreakLine: false,
            editable: false,
          },
          label: {
            value: 'Đơn vị bán hàng',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Seller)',
          },
          show: true,
          canHide: false,

          mergeField: '',
          displayType: 'CONTENT_LEFT',
          borderType: '',
          mstSplitCells: false,
        },
        {
          dataField: 'sellerTaxCode',
          value: {
            value: '0106660221',
            show: true,
            editable: false,
          },
          label: {
            value: 'Mã số thuế',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Tax code)',
            show: false,
          },
          show: true,
          canHide: false,
          mergeField: '',
          mstSplitCells: false,
        },
        {
          dataField: 'sellerAddress',
          value: {
            value:
              'Số 32/337, Phố Cầu Giấy, Phường Dịch Vọng, Quận Cầu Giấy, Hà Nội, Việt Nam',
            show: true,
            editable: false,
          },
          label: {
            value: 'Địa chỉ',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Address)',
            show: false,
          },
          show: true,
          mergeField: '',
        },
        {
          dataField: 'sellerPhone',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Điện thoại',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Tel)',
            show: false,
          },
          show: true,
          mergeField: '',
        },
        {
          dataField: 'sellerFax',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Fax',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '',
            show: false,
          },
          show: false,
          mergeField: '',
        },
        {
          dataField: 'sellerWeb',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Website',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '',
            show: false,
          },
          show: false,
          mergeField: '',
        },
        {
          dataField: 'sellerEmail',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Email',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '',
            show: false,
          },
          show: false,
          mergeField: '',
        },
        {
          dataField: 'sellerBankAccount',
          value: {
            value:
              '0081100902001 - Ngân hàng TMCP Quân Đội-Chi nhánh Trần Duy Hưng',
            show: true,
            editable: false,
          },
          label: {
            value: 'Số tài khoản',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Bank account)',
            show: false,
          },
          show: true,
          mergeField: '',
        },
      ],
      alignType: 'ALIGN_TYPE_1',
      tableHeaderColor: null,
      showColumnIndex: false,
    },
    {
      regionName: 'invoiceInfo',
      regionConfigs: [
        {
          dataField: 'typeInvoice',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'HÓA ĐƠN GIÁ TRỊ GIA TĂNG',
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: 1.4,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(VAT INVOICE)',
            show: true,
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: 1.4,
          },
          typeShow: 2,
          show: true,
          canHide: false,
          mergeField: '',
        },
        {
          dataField: 'subTitleInvoice',
          value: {
            value: 'Tên khác',
            show: true,
          },
          label: {
            value: '',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '',
            show: false,
          },
          typeShow: 1,
          show: true,
          mergeField: '',
        },
        {
          dataField: 'descriptionInvoicePaperClient',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: '(Hóa đơn chuyển đổi từ hóa đơn điện tử)',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Invoice converted from E-invoice)',
            show: false,
          },
          typeShow: 2,
          show: false,
          previewField: true,
          canHide: false,
        },
        {
          dataField: 'descriptionInvoiceClient',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: '(Bản thể hiện của hóa đơn điện tử)',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(E-Invoice viewer)',
            show: false,
          },
          typeShow: 2,
          show: false,
          mergeField: '',
        },
        {
          dataField: 'dateInvoice',
          value: {
            value: 'Ngày (Date)   tháng (month)   năm (year)   ',
            show: true,
            editable: false,
          },
          label: {
            value: '',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '',
            show: false,
          },
          typeShow: 1,
          show: true,
          canHide: false,
          mergeField: '',
        },
        {
          dataField: 'customInvoiceInfo_0',
          value: {
            value: 'Custom',
            show: true,
          },
          label: {
            value: '',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '',
            show: false,
          },
          typeShow: 1,
          show: true,
          mergeField: '',
        },
        {
          dataField: 'invoiceCode',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Mã CQT',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Code)',
            show: false,
          },
          typeShow: 2,
          canHide: false,
          editable: false,
          show: true,
          mergeField: '',
        },
      ],
    },
    {
      regionName: 'invoiceInfoOther',
      regionConfigs: [
        {
          dataField: 'invoiceSeries',
          value: {
            value: '1K23TYY',
            show: true,
            fontSize: 15,
            editable: false,
          },
          label: {
            value: 'Ký hiệu',
            show: true,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Serial)',
            show: true,
          },
          typeShow: 3,
          show: true,
          canHide: false,
          mergeField: '',
        },
        {
          dataField: 'invoiceNumber',
          value: {
            value: '00000000',
            show: true,
            fontWeight: 'bold',
            editable: false,
          },
          label: {
            value: 'Số',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(No.)',
            show: false,
          },
          typeShow: 3,
          show: true,
          canHide: false,
          mergeField: '',
        },
      ],
    },
    {
      regionName: 'buyerInfo',
      regionConfigs: [
        {
          dataField: 'buyerName',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Họ tên người mua hàng',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Buyer)',
            show: false,
          },
          show: true,
          canHide: false,
          mergeField: '',
        },
        {
          dataField: 'buyerLegalName',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Tên đơn vị',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: "(Company's name)",
            show: false,
          },
          show: true,
          canHide: false,
          mergeField: '',
        },
        {
          dataField: 'BuyerTaxCode',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Mã số thuế',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Tax code)',
            show: false,
          },
          show: true,
          canHide: false,
          mergeField: '',
        },
        {
          dataField: 'buyerAddress',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Địa chỉ',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Address)',
            show: false,
          },
          show: true,
          canHide: false,
          mergeField: '',
        },
        {
          dataField: 'paymentMethod',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Hình thức thanh toán',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Payment method)',
            show: false,
          },
          show: true,
          mergeField: '',
        },
        {
          dataField: 'buyerBankAccount',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Số tài khoản',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Bank account)',
            show: false,
          },
          show: true,
          mergeField: '',
        },
        {
          dataField: 'PaymentCurrency',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'Đồng tiền thanh toán',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Payment Currency)',
            show: false,
          },
          show: true,
          mergeField: '',
        },
        {
          dataField: 'CustomField1',
          value: {
            value: '',
            show: true,
            editable: false,
          },
          label: {
            value: 'sản phẩm',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '()',
            show: false,
          },
          show: true,
          mergeField: '',
        },
      ],
      alignType: 'ALIGN_TYPE_1',
    },
    {
      regionName: 'tableDetail',
      regionConfigs: [
        {
          dataField: 'lineNumber',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'STT',
            show: true,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(No)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          canHide: false,
          mergeField: '',
          typeShow: 2,

          width: 38,
        },
        {
          dataField: 'inventoryItemCode',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Mã hàng',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Item code)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          mergeField: '',
          typeShow: 2,

          width: 54,
        },
        {
          dataField: 'itemName',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Tên hàng hóa, dịch vụ',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Name of goods and services)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          canHide: false,
          mergeField: '',
          typeShow: 2,

          width: 120,
        },
        {
          dataField: 'serialNumber',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Quy cách',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Specification)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          mergeField: '',
          typeShow: 2,

          width: 112,
        },
        {
          dataField: 'unitName',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Đơn vị tính',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Unit)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          mergeField: '',
          typeShow: 2,

          width: 67,
        },
        {
          dataField: 'quantity',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Số lượng',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Quantity)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          mergeField: '',
          typeShow: 2,

          width: 82,
        },
        {
          dataField: 'unitPrice',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Đơn giá',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Unit price)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          mergeField: '',
          typeShow: 2,

          width: 75,
        },
        {
          dataField: 'totalAmount',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Thành tiền',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Amount)',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          canHide: false,
          mergeField: '',
          typeShow: 2,

          width: 102,
        },
        {
          dataField: 'customTableDetail_0',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'new',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          labelEn: {
            fontStyle: 'italic',
            value: '()',
            show: false,
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          },
          show: true,
          mergeField: '',
          typeShow: 2,
        },
      ],
      tableHeaderColor: null,
      minItemRows: 3,
    },
    {
      regionName: 'tableFooter',
      regionConfigs: [
        {
          dataField: 'discountRate',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Tỷ lệ CK',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Discount rate)',
            show: false,
          },
          show: false,
          typeShow: 2,
          previewField: true,
          canHide: false,
        },
        {
          dataField: 'totalDiscountAmount',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Số tiền chiết khấu',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Discount amount)',
            show: false,
          },
          show: false,
          typeShow: 2,
          previewField: true,
          canHide: false,
        },
        {
          dataField: 'notShow1',
          label: {
            value: '',
            show: true,
          },
          labelEn: {
            value: '',
            show: true,
          },
          value: {
            value: '',
            show: true,
          },
          show: true,
        },
        {
          dataField: 'totalAmountWithoutVAT',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Cộng tiền hàng',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Total amount excl. VAT)',
            show: false,
          },
          show: true,
          canHide: false,
          typeShow: 2,
          mergeField: '',
        },
        {
          dataField: 'vatPercentage',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Thuế suất GTGT',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(VAT rate)',
            show: false,
          },
          show: true,
          canHide: false,
          typeShow: 2,
          mergeField: '',
        },
        {
          dataField: 'totalVATAmount',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Tiền thuế GTGT',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(VAT amount)',
            show: false,
          },
          show: true,
          canHide: false,
          typeShow: 2,
          mergeField: '',
        },
        {
          dataField: 'notShow2',
          label: {
            value: '',
            show: true,
          },
          labelEn: {
            value: '',
            show: true,
          },
          value: {
            value: '',
            show: true,
          },
          show: true,
        },
        {
          dataField: 'totalAmountWithVAT',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Tổng tiền thanh toán',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Total amount)',
            show: false,
          },
          show: true,
          canHide: false,
          typeShow: 2,
          mergeField: '',
        },
        {
          dataField: 'totalAmountInWords',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Số tiền viết bằng chữ',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Total amount in words)',
            show: false,
          },
          show: true,
          canHide: false,
          typeShow: 2,
          mergeField: '',
        },
        {
          dataField: 'templateNote',
          value: {
            value: 'abcdefght',
            show: true,
          },
          label: {
            value: 'Ghi chú',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Note)',
            show: false,
          },
          show: true,
          mergeField: '',
        },
      ],
    },
    {
      regionName: 'currencyBlock',
      regionConfigs: [
        {
          dataField: 'exchangeRate',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Tỷ giá',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Exchange Rate)',
            show: false,
          },
          show: false,
          typeShow: 2,
          previewField: true,
          canHide: false,
        },
        {
          dataField: 'translationCurrency',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Quy đổi',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Equivalence)',
            show: false,
          },
          show: false,
          typeShow: 2,
          previewField: true,
        },
      ],
    },
    {
      regionName: 'signXml',
      regionConfigs: [
        {
          dataField: 'buyerSign',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Người mua hàng',
            fontWeight: 'bold',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Buyer)',
            show: false,
          },
          typeShow: 2,
          canHide: false,
          show: true,
          mergeField: 'buyerSignRegion',
        },
        {
          dataField: 'buyerSignFull',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: '(Chữ ký số (nếu có))',
            fontStyle: 'italic',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Signature, full name)',
            show: false,
          },
          typeShow: 2,
          show: true,
          mergeField: 'buyerSignRegion',
        },
        {
          dataField: 'customSign0Name',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Người ký mới',
            fontWeight: 'bold',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(New Sign)',
            show: false,
          },
          typeShow: 2,
          canHide: false,
          show: true,
          mergeField: 'customSign0',
        },
        {
          dataField: 'customSign0Full',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: '(Ký, ghi rõ họ, tên)',
            fontStyle: 'italic',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Signature, full name)',
            show: false,
          },
          typeShow: 2,
          show: true,
          mergeField: 'customSign0',
        },
        {
          dataField: 'sellerSign',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Người bán hàng',
            fontWeight: 'bold',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Seller)',
            show: false,
          },
          typeShow: 2,
          canHide: false,
          show: true,
          mergeField: 'sellerSignRegion',
        },
        {
          dataField: 'sellerSignFull',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: '(Chữ ký điện tử, chữ ký số)',
            fontStyle: 'italic',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Signature, full name)',
            show: false,
          },
          typeShow: 2,
          show: true,
          mergeField: 'sellerSignRegion',
        },
        {
          dataField: 'sellerSignByClient',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Ký bởi',
            fontWeight: 'bold',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Signed By)',
            fontWeight: 'bold',
            show: false,
          },
          typeShow: 2,
          canHide: false,
          show: true,
          mergeField: 'sellerSignRegion',
        },
        {
          dataField: 'sellerSignDateClient',
          value: {
            value: '',
            show: true,
          },
          label: {
            value: 'Ký ngày',
            fontWeight: 'bold',
            show: false,
          },
          labelEn: {
            fontStyle: 'italic',
            value: '(Signing Date)',
            fontWeight: 'bold',
            show: false,
          },
          typeShow: 2,
          canHide: false,
          show: true,
          mergeField: 'sellerSignRegion',
        },
      ],
    },
  ],
}

const INVOICE_INVOICE_TEMPLATE_DICT = {
  sellerName: 'companyName',
  sellerTaxCode: 'taxCode',
  sellerAddress: 'businessPermitAddress',
  sellerEmail: 'contactPersonEmail',
  sellerPhone: 'contactPersonPhone',
  sellerWeb: 'website',
  sellerFax: 'fax',
}

const genTemplateInvoiceInstance = (
  templateInvoice,
  setAllSubColumns,
  sellerInfoData,
  extendFields,
) => {
  let returnValue = {
    templateInvoiceName: templateInvoice.name,
    // templateInvoiceDecscription :templateInvoice.decscription,
    templateInvoiceForm: templateInvoice.hasTaCode === 'C' ? 1 : 0,
    templateInvoiceSeries:
      templateInvoice.form +
      templateInvoice.hasTaCode +
      templateInvoice.year +
      templateInvoice.type +
      templateInvoice.managementCode,

    dataTransferMethod: templateInvoice.directTranfer + '',
    sellerInfoPosition: templateInvoice.sellerInfoPosition,
    fontFamily: templateInvoice.metadata?.fontFamily,
    fontSize: templateInvoice.metadata?.fontSize,
    color: templateInvoice.metadata?.color,
    lineType: templateInvoice.metadata?.lineType,
    lineHeight: templateInvoice.metadata?.lineHeight,
    isShowQRCode: !!templateInvoice.isShowQRCode,
    duplicateInfoMulPages: !!templateInvoice.duplicateInfoMulPages,
    isShowLabelEn: !!templateInvoice.isShowLabelEn,
    isShowWatermarkInAdjustedInvoice:
      !!templateInvoice.isShowWatermarkInAdjustedInvoice,
    isNonTariffArea: !!templateInvoice.isNonTariffArea,

    logoPosition: templateInvoice.logoPosition,
    paperSize: templateInvoice.paperSize,
    // mulTaxRateSupport: templateInvoice.mulTaxRateSupport,
    category: templateInvoice.category,
    calcConfig: templateInvoice.calcConfig,

    extendFields: templateInvoice.extend_fields,
    notDraftInvoices: templateInvoice.notDraftInvoices,
  }

  if (templateInvoice.logo && templateInvoice.metadata?.logo) {
    returnValue.logoInfo = {
      logoFile: templateInvoice.logo,
      ...templateInvoice.metadata?.logo,
    }
  }

  if (templateInvoice.border) {
    returnValue.surroundInfo = {
      surroundData: templateInvoice.border,
      ...templateInvoice.metadata?.surround,
    }
  }

  if (templateInvoice.background) {
    returnValue.backgroundInfo = {
      backgroundData: templateInvoice.background,
      ...templateInvoice.metadata?.background,
    }
  }

  if (
    templateInvoice.customBackground &&
    templateInvoice.metadata?.customBackground
  ) {
    returnValue.customBackgroundInfo = {
      file: templateInvoice.customBackground,
      ...templateInvoice.metadata?.customBackground,
    }
  }

  if (templateInvoice.metadata?.watermark) {
    returnValue.watermarkInfo = {
      ...templateInvoice.metadata?.watermark,
    }
  }

  if (templateInvoice.metadata?.subFields) {
    setAllSubColumns(templateInvoice.metadata.subFields)
  }

  /* lọc những trường đã có rồi */
  extendFields = extendFields
    .filter(
      ({ name }) => !templateInvoice.fields.find((item) => item.name == name),
    )
    .map((field) => ({
      ...field,
      label: { value: field.label, editable: false },
      labelEn: { value: field.labelEn, editable: false },
      value: { value: '', editable: false },
      extendField: true,
    }))
  let groupByRegionName = templateInvoice.fields
    .concat(extendFields)
    .reduce((group, field) => {
      const { regionName } = field
      group[regionName] = group[regionName] ?? []
      group[regionName].push(field)

      return group
    }, {})

  returnValue.RegionConfigs = Object.entries(groupByRegionName).map(
    ([regionName, fields]) => ({
      regionName,
      regionConfigs:
        regionName == 'sellerInfo' && sellerInfoData
          ? fields.map((field) => ({
              dataField: field.name,
              label: field.label,
              labelEn: field.labelEn,
              value: {
                ...field.value,
                value:
                  field.name == 'sellerBankAccount'
                    ? sellerInfoData['bankAccountNumber'] &&
                      sellerInfoData['bankName']
                      ? `${sellerInfoData['bankAccountNumber']} - ${sellerInfoData['bankName']}`
                      : ''
                    : sellerInfoData[
                        INVOICE_INVOICE_TEMPLATE_DICT[field.name]
                      ] ?? field.value.value,
              },
              show: field.active,
              children: field.children,

              ...field.metadata,
            }))
          : fields.map((field) => ({
              dataField: field.name,
              label: field.label,
              labelEn: field.labelEn,
              value: field.value,
              show: field.active,
              children: field.children?.map((fieldC) => ({
                dataField: fieldC.name,
                label: fieldC.label,
                labelEn: fieldC.labelEn,
                value: fieldC.value,
                show: fieldC.active,

                ...fieldC.metadata,
              })),

              dataType: field.dataType,
              defaultValue: field.defaultValue,

              ...field.metadata,
            })),
      ...(templateInvoice.metadata?.region[regionName] || {}),
    }),
  )
  return returnValue
}

function CreateTemplateInvoice() {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()
  const subColumnsDispatch = useSubColumnsDispatch()
  let { templateInvoiceId } = useParams()
  const { pathname } = useLocation()

  const { company } = useSelector((state) => state.system)
  const { currentAccount } = useSelector((state) => state.auth)
  const { instance: templateInvoiceInstance, extendFields } = useSelector(
    (state) => state.templateInvoice,
  )
  const {
    templateInvoiceName,
    templateInvoiceSeries,
    dataTransferMethod,
    sellerInfoPosition,
    isShowQRCode,
    duplicateInfoMulPages,
    isShowLabelEn,
    isShowWatermarkInAdjustedInvoice,
    isNonTariffArea,
    logoPosition,
    paperSize,
    // mulTaxRateSupport,
    category,
    calcConfig,

    fontFamily,
    fontSize,
    color,
    lineType,
    lineHeight,
    logoInfo,

    backgroundInfo,
    customBackgroundInfo,
    surroundInfo,
    watermarkInfo,

    RegionConfigs,

    extendFields: instanceExtendFields,
  } = useSelector((state) => state.templateInvoice.instance)

  // const [invoiceInfoColumns, setInvoiceInfoColumns] = useState([]);
  const invoiceInfoColumns = useColumns('invoiceInfoColumns')
  const setInvoiceInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'invoiceInfoColumns',
      payload,
    })
  }
  // const [firstInvoiceColumns, setFirstInvoiceColumns] = useState([]);
  const firstInvoiceColumns = useColumns('firstInvoiceColumns')
  const setFirstInvoiceColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'firstInvoiceColumns',
      payload,
    })
  }
  // const [buyerInfoColumns, setBuyerInfoColumns] = useState([]);
  const buyerInfoColumns = useColumns('buyerInfoColumns')
  const setBuyerInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'buyerInfoColumns',
      payload,
    })
  }
  // const [tableDetailColumns, setTableDetailColumns] = useState([]);
  const tableDetailColumns = useColumns('tableDetailColumns')
  const setTableDetailColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableDetailColumns',
      payload,
    })
  }
  const tableDetail1Columns = useColumns('tableDetail1Columns')
  const setTableDetail1Columns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableDetail1Columns',
      payload,
    })
  }
  const tableFooter1Columns = useColumns('tableFooter1Columns')
  const setTableFooter1Columns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableFooter1Columns',
      payload,
    })
  }

  const signXmlColumns = useColumns('signXmlColumns')
  const setSignXmlColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'signXmlColumns',
      payload,
    })
  }
  const tableFooterColumns = useColumns('tableFooterColumns')
  const setTableFooterColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableFooterColumns',
      payload,
    })
  }

  const tableHospitalColumns = useColumns('tableHospitalColumns')
  const setTableHospitalColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableHospitalColumns',
      payload,
    })
  }
  const subColumns = useSubColumns()

  const userFunction = useSelector((state) => state?.function?.userFunctions)
  const listFunction = useSelector((state) => state?.function?.listFunctions)

  const [themFunction, suaFunction] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'TEMPLATE_INVOICE_ADD',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_EDIT',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])

  useEffect(() => {
    dispatch(clearTemplateInvoiceState())
    dispatch(thunkGetExtendFields())

    return () => {
      dispatch(clearTemplateInvoiceState())
    }
  }, [])

  const gotDetailTemplateInvoice = useRef(false)
  useEffect(() => {
    if (
      templateInvoiceId &&
      !Utils.isObjectEmpty(company) &&
      extendFields &&
      userFunction?.length &&
      listFunction?.length
    ) {
      if (!gotDetailTemplateInvoice.current)
        (async function () {
          if (pathname.includes('/tao/') && !themFunction) {
            ToastHelper.showError('Bạn không có quyền thêm mẫu hóa đơn')
            gotDetailTemplateInvoice.current = true
            return
          }

          if (pathname.includes('/sua/') && !suaFunction) {
            ToastHelper.showError('Bạn không có quyền sửa mẫu hóa đơn')
            gotDetailTemplateInvoice.current = true
            return
          }

          try {
            let templateInvoice =
              await templateInvoiceApi.getDetail(templateInvoiceId)
            if (!templateInvoice) return

            gotDetailTemplateInvoice.current = true
            let sellerInfoData = {
              ...company,
              contactPersonEmail:
                company?.invoiceDisplayedInfo?.email ||
                company?.contactPersonEmail,
              contactPersonPhone:
                company?.invoiceDisplayedInfo?.phone ||
                company?.contactPersonPhone,
              website:
                company?.invoiceDisplayedInfo?.website || company?.website,
              sellerFax: company?.invoiceDisplayedInfo?.fax || company?.fax,
            }
            dispatch(
              setInstanceInfo({
                ...genTemplateInvoiceInstance(
                  templateInvoice,
                  setAllSubColumns,
                  sellerInfoData,
                  extendFields,
                ),
              }),
            )

            if (templateInvoice.category.taxType == 2) {
              dispatch(setMulTaxRate(true))
            }

            let firstInvoiceRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'firstInvoice',
            )
            if (firstInvoiceRegion.widths?.length) {
              setFirstInvoiceColumns([
                ...firstInvoiceRegion.widths.map((width, index) =>
                  width != '*'
                    ? {
                        width,
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                        id:
                          templateInvoice?.logoPosition == 1
                            ? 'logo-container'
                            : undefined,
                      }
                    : {
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                        id:
                          templateInvoice?.logoPosition == 0
                            ? 'logo-container'
                            : undefined,
                      },
                ),
              ])
            }

            let invoiceInfoRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'invoiceInfo',
            )
            if (invoiceInfoRegion.widths?.length) {
              setInvoiceInfoColumns([
                ...invoiceInfoRegion.widths.map((width, index) =>
                  width != '*'
                    ? {
                        width,
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      }
                    : {
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      },
                ),
              ])
            }

            let buyerInfoRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'buyerInfo',
            )
            if (buyerInfoRegion.widths?.length) {
              setBuyerInfoColumns([
                ...buyerInfoRegion.widths.map((width, index) =>
                  width != '*'
                    ? {
                        width,
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      }
                    : {
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      },
                ),
              ])
            }

            if (templateInvoice.category.taxType != 2) {
              let tableDetailRegion = templateInvoice.region?.find(
                ({ regionName }) => regionName == 'tableDetail',
              )
              if (tableDetailRegion.widths?.length) {
                setTableDetailColumns([
                  ...tableDetailRegion.widths.map(({ width, dataIndex }) =>
                    width !== '*'
                      ? { width, dataIndex, key: dataIndex }
                      : { dataIndex, key: dataIndex },
                  ),
                ])
              }
            }

            if (templateInvoice.category.taxType != 1) {
              let tableDetail1Region = templateInvoice.region?.find(
                ({ regionName }) => regionName == 'tableDetail1',
              )
              if (tableDetail1Region) {
                if (tableDetail1Region.widths?.length) {
                  setTableDetail1Columns([
                    ...tableDetail1Region.widths.map(({ width, dataIndex }) =>
                      width !== '*'
                        ? { width, dataIndex, key: dataIndex }
                        : { dataIndex, key: dataIndex },
                    ),
                  ])
                }
              }
            }

            if (templateInvoice.category.taxType != 2) {
              let tableFooterRegion = templateInvoice.region?.find(
                ({ regionName }) => regionName == 'tableFooter',
              )
              if (tableFooterRegion.widths?.length) {
                setTableFooterColumns([
                  ...tableFooterRegion.widths.map((width, index) =>
                    width != '*'
                      ? {
                          width,
                          dataIndex: 'col' + (index + 1),
                          key: 'col' + (index + 1),
                        }
                      : {
                          dataIndex: 'col' + (index + 1),
                          key: 'col' + (index + 1),
                        },
                  ),
                ])
              }
            }

            if (templateInvoice.category.code == 'BV') {
              let tableHospitalRegion = templateInvoice.region?.find(
                ({ regionName }) => regionName == 'tableHospital',
              )
              if (tableHospitalRegion.widths?.length) {
                setTableHospitalColumns([
                  ...tableHospitalRegion.widths.map((width, index) =>
                    width != '*'
                      ? {
                          width,
                          dataIndex: 'col' + (index + 1),
                          key: 'col' + (index + 1),
                        }
                      : {
                          dataIndex: 'col' + (index + 1),
                          key: 'col' + (index + 1),
                        },
                  ),
                ])
              }
            }

            if (templateInvoice.category.taxType != 1) {
              let tableFooter1Region = templateInvoice.region?.find(
                ({ regionName }) => regionName == 'tableFooter1',
              )
              if (tableFooter1Region) {
                if (tableFooter1Region.widths?.length) {
                  setTableFooter1Columns([
                    ...tableFooter1Region.widths.map((width, index) =>
                      width != '*'
                        ? {
                            width,
                            dataIndex: 'col' + (index + 1),
                            key: 'col' + (index + 1),
                          }
                        : {
                            dataIndex: 'col' + (index + 1),
                            key: 'col' + (index + 1),
                          },
                    ),
                  ])
                }
              }
            }

            let signXmlRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'signXml',
            )
            if (signXmlRegion.widths?.length) {
              setSignXmlColumns([
                ...signXmlRegion.widths.map(({ width, dataIndex }) =>
                  width != '*'
                    ? { width, dataIndex, key: dataIndex }
                    : { dataIndex, key: dataIndex },
                ),
              ])
            }
          } catch (e) {
            console.log(e)
          }
        })()
    }
    // else {
    //   dispatch(setInstanceInfo({ ...initialInstance }));
    // }
  }, [company, extendFields, userFunction, listFunction])

  const prepareTemplateInvoiceParams = async (toExport = false) => {
    let metadata = {
      fontFamily,
      fontSize,
      color,
      lineType,
      lineHeight,
    }

    let region = [
      {
        regionName: 'firstInvoice',
        widths: firstInvoiceColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'invoiceInfo',
        widths: invoiceInfoColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'buyerInfo',
        widths: buyerInfoColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'tableDetail',
        widths: tableDetailColumns.map(({ width, dataIndex, show }) => ({
          width: width ?? '*',
          show,
          dataIndex,
        })),
      },
      {
        regionName: 'tableDetail1',
        widths: tableDetail1Columns.map(({ width, dataIndex, show }) => ({
          width: width ?? '*',
          show,
          dataIndex,
        })),
      },
      {
        regionName: 'tableFooter',
        widths: tableFooterColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'tableHospital',
        widths: tableHospitalColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'tableFooter1',
        widths: tableFooter1Columns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'signXml',
        widths: signXmlColumns.map(({ width, dataIndex }) => ({
          width: width ?? '*',
          dataIndex,
        })),
      },
    ]

    let params = {
      categoryId: category?.categoryId,
    }

    if (logoInfo?.logoFile) {
      let { logoFile, ...rest } = logoInfo
      if (toExport && logoFile instanceof File) {
        params.logo = await Utils.toBase64(logoFile)
      } else {
        params.logo = logoFile
      }
      metadata.logo = { ...rest }
    } else {
      metadata.logo = null
    }

    if (backgroundInfo?.backgroundData) {
      let { backgroundData, ...rest } = backgroundInfo
      params.backgroundId = backgroundData.backgroundId
      metadata.background = { ...rest }
    } else {
      params.backgroundId = null
      metadata.background = null
    }

    if (surroundInfo?.surroundData) {
      let { surroundData, ...rest } = surroundInfo
      params.borderId = surroundData.borderId
      metadata.surround = { ...rest }
    } else {
      params.borderId = null
      metadata.surround = null
    }

    if (customBackgroundInfo?.file) {
      let { file, ...rest } = customBackgroundInfo
      if (toExport && file instanceof File) {
        params.customBackground = await Utils.toBase64(file)
      } else {
        params.customBackground = file
      }
      metadata.customBackground = { ...rest }
    } else {
      metadata.customBackground = null
    }

    if (!Utils.isObjectNull(watermarkInfo)) {
      metadata.watermark = { ...watermarkInfo }
    } else {
      metadata.watermark = {}
    }

    if (!toExport) {
      params.companyId = currentAccount?.companyId
    }

    if (!toExport) {
      params.name = templateInvoiceName
    }
    // params.decscription = ""
    params.form = templateInvoiceSeries[0]
    params.hasTaCode = templateInvoiceSeries[1]
    params.type = templateInvoiceSeries[4]
    params.managementCode = templateInvoiceSeries.slice(5)
    if (!toExport) {
      params.directTranfer = parseInt(dataTransferMethod)
    }
    params.paperSize = paperSize
    // params.mulTaxRateSupport = mulTaxRateSupport;
    params.calcConfig = calcConfig
    params.extendFields = instanceExtendFields

    params.extendFields = instanceExtendFields

    params.region = region

    params.sellerInfoPosition = sellerInfoPosition
    params.isShowQRCode = isShowQRCode ? 1 : 0
    params.duplicateInfoMulPages = duplicateInfoMulPages ? 1 : 0
    params.isShowLabelEn = isShowLabelEn ? 1 : 0
    params.isShowWatermarkInAdjustedInvoice = isShowWatermarkInAdjustedInvoice
      ? 1
      : 0
    params.isNonTariffArea = isNonTariffArea ? 1 : 0
    params.logoPosition = logoPosition ? 1 : 0

    let index = 0
    let regionMetadata = {}
    for (let i = 0; i < RegionConfigs.length; i++) {
      RegionConfigs[i].regionConfigs.forEach((dataField, dfi) => {
        if (
          dataField.dataField.startsWith('extendField') &&
          !instanceExtendFields.find((item) => item.name == dataField.dataField)
        ) {
          return
        }

        let dfMetadata = {}
        if (dataField.mergeField) {
          dfMetadata.mergeField = dataField.mergeField
        }
        if (dataField.displayType) {
          dfMetadata.displayType = dataField.displayType
        }
        if (dataField.borderType) {
          dfMetadata.borderType = dataField.borderType
        }
        // if (dataField.mstSplitCells) {
        dfMetadata.mstSplitCells = dataField.mstSplitCells
        // }
        if (dataField.typeShow) {
          dfMetadata.typeShow = dataField.typeShow
        }
        // if (dataField.canHide) {
        dfMetadata.canHide = dataField.canHide
        // }
        // if (dataField.previewField) {
        dfMetadata.previewField = dataField.previewField
        // }
        dfMetadata.mrRank = dataField.mrRank

        params[`fields[${index}][name]`] = dataField.dataField
        params[`fields[${index}][regionName]`] = RegionConfigs[i].regionName
        params[`fields[${index}][type]`] = 'STATIC'
        params[`fields[${index}][label]`] = JSON.stringify(dataField.label)
        params[`fields[${index}][labelEn]`] = JSON.stringify(dataField.labelEn)
        params[`fields[${index}][value]`] = JSON.stringify(dataField.value)
        params[`fields[${index}][metadata]`] = JSON.stringify(dfMetadata)
        params[`fields[${index}][children]`] = JSON.stringify(
          dataField.children?.map((item) => {
            let { fieldId, parentId, dataField, ...rest } = item
            rest.name = dataField
            rest.regionName = RegionConfigs[i].regionName
            return rest
          }) || [],
        )

        let hideToSave = 0
        /* Hóa đơn dạng chuyển đổi */
        if (
          RegionConfigs[i].regionName === 'invoiceInfo' &&
          dataField.dataField == 'descriptionInvoicePaperClient'
        ) {
          // if (!toExport) {
          hideToSave = 1
          // }
        }

        /* Phát sinh chiết khấu */
        if (
          RegionConfigs[i].regionName === 'tableFooter' &&
          ['discountRate', 'totalDiscountAmount'].includes(dataField.dataField)
        ) {
          // if (!toExport) {
          hideToSave = 1
          // }
        }
        if (
          RegionConfigs[i].regionName === 'tableFooter1' &&
          ['DiscountRate', 'TotalDiscountAmount'].includes(dataField.dataField)
        ) {
          // if (!toExport) {
          hideToSave = 1
          // }
        }

        /* Phát sinh ngoại tệ */
        if (
          RegionConfigs[i].regionName === 'currencyBlock' &&
          ['exchangeRate', 'translationCurrency'].includes(dataField.dataField)
        ) {
          // if (!toExport) {
          hideToSave = 1
          // }
        }

        params[`fields[${index}][active]`] = hideToSave
          ? 0
          : dataField.show
            ? 1
            : 0

        index += 1
      })

      // region metadata
      let { regionConfigs, regionName, ...rest } = RegionConfigs[i]
      if (regionName == 'sellerInfo') {
        rest.maxWidth = parseInt(
          sessionStorage.getItem(PreferenceKeys.sellerInfoMaxWidth),
        )
      } else if (regionName == 'buyerInfo') {
        rest.maxWidth = parseInt(
          sessionStorage.getItem(PreferenceKeys.buyerInfoMaxWidth),
        )
      }

      regionMetadata[regionName] = rest
    }
    metadata.region = regionMetadata

    metadata.subFields = {}
    Object.entries(subColumns).forEach(([mergeField, columns]) => {
      metadata.subFields[mergeField] = columns.map(({ width }) =>
        width ? { width } : { width: '*' },
      )
    })

    params.metadata = JSON.stringify(metadata)

    return params
  }

  const exportTemplateHandler = async (exportOptions) => {
    let params = await prepareTemplateInvoiceParams(true, exportOptions)
    params.exportOptions = JSON.stringify(exportOptions)

    dispatch(setAppSpinning(true))
    var res
    try {
      res = await templateInvoiceApi.exportTemplate(params)
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }

    let newFile = new File([res], 'Mẫu hóa đơn.pdf', {
      type: 'application/pdf',
    })
    var fileURL = URL.createObjectURL(newFile)
    window.open(fileURL, '_blank')
  }

  const setAllSubColumns = (param) => {
    subColumnsDispatch({
      type: 'set_all',
      payload: param,
    })
  }

  return (
    <div>
      {!Utils.isObjectEmpty(templateInvoiceInstance) ? (
        <div className="CreateTemplateInvoice d-flex flex-column vh-100">
          <CreateTemplateInvoiceHeader style={{ flex: '0 1 auto' }} />

          <div
            className="mx-auto mt-1 d-flex flex-row"
            style={{ flex: '1 1 auto', overflow: 'hidden' }}
          >
            <LeftContent
              className="left-content h-100"
              invoiceInfoColumns={invoiceInfoColumns}
              setInvoiceInfoColumns={setInvoiceInfoColumns}
              firstInvoiceColumns={firstInvoiceColumns}
              setFirstInvoiceColumns={setFirstInvoiceColumns}
              buyerInfoColumns={buyerInfoColumns}
              setBuyerInfoColumns={setBuyerInfoColumns}
              tableDetailColumns={tableDetailColumns}
              setTableDetailColumns={setTableDetailColumns}
              tableDetail1Columns={tableDetail1Columns}
              setTableDetail1Columns={setTableDetail1Columns}
              tableFooterColumns={tableFooterColumns}
              setTableFooterColumns={setTableFooterColumns}
              tableHospitalColumns={tableHospitalColumns}
              setTableHospitalColumns={setTableHospitalColumns}
              tableFooter1Columns={tableFooter1Columns}
              setTableFooter1Columns={setTableFooter1Columns}
              signXmlColumns={signXmlColumns}
              setSignXmlColumns={setSignXmlColumns}
            />

            <RightControl
              className="right-control h-100 p-3"
              prepareTemplateInvoiceParams={prepareTemplateInvoiceParams}
            />
          </div>
        </div>
      ) : (
        <div>Loading</div>
      )}

      <ModalExportTemplate
        hasCompoundTaxRate={category?.taxType == 3}
        exportTemplateHandler={exportTemplateHandler}
        invoiceGeneratedData={true}
      />
    </div>
  )
}

export default CreateTemplateInvoice
