import React, { useState } from 'react'

import './style.scss'
import { useDropzone } from 'react-dropzone'

import CustomColorPicker from 'features/TemplateInvoice/components/CustomColorPicker'
import { Range, getTrackBackground } from 'react-range'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import {
  setActivePanel,
  setBackgroundInfo,
  setCustomBackgroundInfo,
  setInstanceInfo,
  setLogoInfo,
  setSurroundInfo,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useSelector } from 'react-redux'
import { useColumnsDispatch } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'

function LogoControl(props) {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()
  const { style } = props

  const {
    logoPosition,
    logoInfo,
    backgroundInfo,
    surroundInfo,
    customBackgroundInfo,
  } = useSelector((state) => state.templateInvoice.instance)

  const setFirstInvoiceColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'firstInvoiceColumns',
      payload,
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
    },
    maxFiles: 1,
    onDrop: async (files) => {
      await new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(files[0])
        img.onload = ({ target: img }) => {
          const { width, height } = img

          let logoContainer = document.getElementById('logo-container')
          //check trường hợp có id logo-container chưa nhận kích thước ảnh
          const checkSize = logoContainer?.clientHeight == 0

          let containerWidth = logoContainer?.clientWidth
          if (containerWidth <= 1) {
            let newWidth = 140
            containerWidth = newWidth
            setFirstInvoiceColumns((oldColumns) => {
              let newWidth =
                logoPosition == 1
                  ? 140
                  : document.getElementById('lc-frame-content-detail')
                      ?.offsetWidth - 140
              const nextColumns = [...oldColumns]
              nextColumns[0] = {
                ...nextColumns[0],
                width: newWidth,
              }
              return nextColumns
            })
          }

          let containerHeight = checkSize
            ? height
            : logoContainer?.clientHeight - 2

          let scalingFactor = Math.min(
            containerWidth / width,
            containerHeight / height,
          )
          let imageWidth = scalingFactor * width
          let imageHeight = scalingFactor * height
          let x = (containerWidth - width * scalingFactor) / 2
          let y = ((containerHeight - height) * scalingFactor) / 2

          dispatch(
            setLogoInfo({
              logoFile: files[0],
              x,
              y,
              width: imageWidth,
              height: imageHeight,
            }),
          )
          resolve()
        }
      })
    },
  })

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/svg+xml': ['.svg'],
      },
      maxFiles: 1,
      onDrop: async (files) => {
        await new Promise((resolve) => {
          const img = new Image()
          img.src = URL.createObjectURL(files[0])
          img.onload = ({ target: img }) => {
            const { width, height } = img

            let logoContainer = document.getElementById('lc-frame')
            let containerWidth = logoContainer?.clientWidth
            let containerHeight = logoContainer?.clientHeight

            let imageBiggerThanContainer =
              width > containerWidth * 0.96 || height > containerHeight
            let scalingFactor = imageBiggerThanContainer
              ? Math.min(
                  (containerWidth * 0.96) / width,
                  containerHeight / height,
                )
              : 1
            let imageWidth = scalingFactor * width
            let imageHeight = scalingFactor * height
            let x = (containerWidth - width * scalingFactor) / 2
            let y = (containerHeight - height * scalingFactor) / 2

            dispatch(
              setCustomBackgroundInfo({
                file: files[0],
                opacity: 35,
                position: 'center',
                x,
                y,
                width: imageWidth,
                height: imageHeight,
              }),
            )
            resolve()
          }
        })
      },
    })

  const handleChangeLogoPosition = (e) => {
    let containerWidth = document.getElementById(
      'lc-frame-content-detail',
    )?.offsetWidth
    let originLogoContainerWidth =
      document.getElementById('logo-container')?.offsetWidth

    dispatch(setInstanceInfo({ logoPosition: parseInt(e.target.value) }))
    setFirstInvoiceColumns([
      {
        title: '',
        dataIndex: 'col1',
        key: 'col1',
        width:
          e.target.value == 1
            ? originLogoContainerWidth
            : containerWidth - originLogoContainerWidth,
        id: e.target.value == 1 ? 'logo-container' : undefined,
      },
      {
        title: '',
        dataIndex: 'col2',
        key: 'col2',
        id: e.target.value == 0 ? 'logo-container' : undefined,
      },
    ])
    // dispatch(setLogoInfo({ x: 0, y: 0, width: 100, height: 100 }));
  }

  const handleReUploadLogo = () => {
    const input = document.getElementById('upload-logo-input')
    if (!input) return

    input.value = null
    input.click()
  }

  const handleReUploadCustomBackground = () => {
    const input = document.getElementById('upload-custom-background-input')
    console.log(input)
    if (!input) return

    input.value = null
    input.click()
  }

  const handleChangeCustomBackgroundPositionSelect = async (t) => {
    await new Promise((resolve) => {
      const img = new Image()
      img.src =
        customBackgroundInfo?.file instanceof File
          ? URL.createObjectURL(customBackgroundInfo?.file)
          : `${process.env.REACT_APP_BASE_URL}/resources/customBackground/${customBackgroundInfo?.file}`
      img.onload = ({ target: img }) => {
        const { width, height } = img

        let logoContainer = document.getElementById('lc-frame')
        let containerWidth = logoContainer?.clientWidth
        let containerHeight = logoContainer?.clientHeight

        let imageBiggerThanContainer =
          width > containerWidth || height > containerHeight
        let scalingFactor =
          imageBiggerThanContainer || t == 'full'
            ? Math.min(containerWidth / width, containerHeight / height)
            : 1
        let imageWidth = scalingFactor * width
        let imageHeight = scalingFactor * height
        let x = (containerWidth - width * scalingFactor) / 2
        let y = (containerHeight - height * scalingFactor) / 2

        dispatch(
          setCustomBackgroundInfo({
            x,
            y,
            width: imageWidth,
            height: imageHeight,
            position: t,
          }),
        )
        resolve()
      }
    })
  }

  return (
    <div
      className="LogoControl d-flex flex-column overflow-hidden"
      style={style}
    >
      <div
        className="d-flex flex-row align-items-center mb-3"
        style={{ flex: '0 1 auto' }}
      >
        <button
          type="button"
          className="btn btn-icon mr-2"
          onClick={() => dispatch(setActivePanel('main_ctrl'))}
        >
          <i className="fas fa-arrow-left" style={{ color: '#000000' }}></i>
        </button>
        <h3 className="static-title">Logo</h3>
      </div>

      <div
        className="overflow-scroll"
        style={{ flex: '1 1 auto', fontSize: '0.95em' }}
      >
        <div
          className={`logo-dropzone w-100 justify-content-center align-items-center cursor-pointer ${
            logoInfo?.logoFile ? '' : 'bg-hover-secondary'
          } ${!logoInfo?.logoFile ? 'd-flex' : 'd-none'}`}
        >
          <div
            {...getRootProps({
              className: 'dropzone',
            })}
            className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          >
            <input {...getInputProps()} id="upload-logo-input" />
            <i
              className="fal fa-image-polaroid fa-4x mb-3"
              style={{ opacity: 0.8 }}
            ></i>
            <p className="text-danger">
              Kéo thả tệp logo vào đây hoặc Chọn từ máy tính
            </p>
          </div>
        </div>

        {logoInfo?.logoFile && (
          <div>
            <div className="d-flex flex-row mb-3">
              <div className="logo-preview p-3 d-flex justify-content-center align-items-center">
                <img
                  src={
                    logoInfo?.logoFile instanceof File
                      ? URL.createObjectURL(logoInfo?.logoFile)
                      : `${process.env.REACT_APP_BASE_URL}/resources/logo/${logoInfo?.logoFile}`
                  }
                  alt="..."
                  className="mw-100 mh-100"
                ></img>
              </div>
              <div className="setting-logo-position p-3">
                <div className="mb-1">
                  <p className="mb-0 font-sfpro-bold text-left">Vị trí logo</p>
                </div>

                <div className="mb-1">
                  <div className="d-flex flex-row align-items-center cursor-pointer">
                    <input
                      className="mr-3 cursor-pointer"
                      type="radio"
                      name="logoPosition"
                      id="LOGO_LEFT"
                      checked={logoPosition === 1}
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      value={1}
                      onChange={handleChangeLogoPosition}
                    />
                    <label className="m-0 cursor-pointer" htmlFor="LOGO_LEFT">
                      Bên trái
                    </label>
                  </div>
                </div>
                <div className="mb-1">
                  <div className="d-flex flex-row align-items-center cursor-pointer mb-2">
                    <input
                      className="mr-3 cursor-pointer"
                      type="radio"
                      name="logoPosition"
                      id="LOGO_RIGHT"
                      checked={logoPosition === 0}
                      style={{ width: '1.2rem', height: '1.2rem' }}
                      value={0}
                      onChange={handleChangeLogoPosition}
                    />
                    <label className="m-0 cursor-pointer" htmlFor="LOGO_RIGHT">
                      Bên phải
                    </label>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-outline-secondary bg-white mb-2"
                  style={{ minWidth: 104 }}
                  onClick={() => {
                    dispatch(setLogoInfo(null))
                    setFirstInvoiceColumns((oldColumns) => {
                      let newWidth = 1
                      const nextColumns = [...oldColumns]
                      nextColumns[0] = {
                        ...nextColumns[0],
                        width: newWidth,
                      }
                      return nextColumns
                    })
                  }}
                >
                  <i
                    className="fal fa-trash-alt mb-1"
                    style={{ color: '#495057' }}
                  ></i>{' '}
                  Xóa logo
                </button>

                <button
                  type="button"
                  className="btn btn-secondary bg-primary text-white"
                  style={{ minWidth: 104 }}
                  onClick={handleReUploadLogo}
                >
                  <i
                    className="fa-regular fa-upload mb-1 text-white"
                    style={{ color: '#495057' }}
                  ></i>{' '}
                  Tải lại
                </button>
              </div>
            </div>
          </div>
        )}

        <h3 className="static-title mt-5">Chọn nền hóa đơn</h3>

        <div className="d-flex flex-row">
          <div className="d-flex flex-column mb-5 mr-5">
            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
              <p className="mb-0">Bộ khung nền tùy chọn</p>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
              <div
                className="border pl-4 py-2 pr-3 overflow-hidden d-flex flex-row justify-content-between active-border-blue"
                style={{ height: '38px', width: '251px' }}
                onClick={() =>
                  dispatch(setActivePanel('background_surround_sel'))
                }
              >
                <div className="overflow-hidden" style={{ width: '190px' }}>
                  {backgroundInfo &&
                  backgroundInfo.backgroundData.name.startsWith('mau_')
                    ? backgroundInfo.backgroundData.name
                    : 'Chọn từ bộ khung nền có sẵn'}
                </div>

                <div>
                  <i
                    className="fas fa-chevron-right"
                    style={{ color: '#495057' }}
                  ></i>
                </div>
              </div>

              {backgroundInfo &&
                backgroundInfo.backgroundData?.name.startsWith('mau_') && (
                  <span
                    className="ml-2 cursor-pointer active-border-blue p-2"
                    style={{ height: '38px', border: '1px solid transparent' }}
                    onClick={() => dispatch(setBackgroundInfo(null))}
                  >
                    <i
                      className="fal fa-trash-alt mr-2"
                      style={{ color: '#495057' }}
                    ></i>
                    Xóa
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row">
          <div className="d-flex flex-column mb-5 mr-5">
            <div className="mb-2">
              <p className="mb-0">Khung viền</p>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
              <div
                className="border pl-4 pt-3 pr-3 overflow-hidden d-flex flex-row justify-content-between active-border-blue"
                style={{ width: '251px', height: '38px' }}
                onClick={() => dispatch(setActivePanel('surround_sel'))}
              >
                <div className="overflow-hidden" style={{ width: '190px' }}>
                  {surroundInfo ? (
                    <div
                      style={{
                        width: '598px',
                        height: '680px',
                        backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/border/${surroundInfo.surroundData.filename})`,
                      }}
                    ></div>
                  ) : (
                    'Chọn từ bộ khung viền có sẵn'
                  )}
                </div>

                <div>
                  <i
                    className="fas fa-chevron-right"
                    style={{ color: '#495057' }}
                  ></i>
                </div>
              </div>

              {surroundInfo && (
                <span
                  className="ml-2 cursor-pointer active-border-blue p-2"
                  style={{ height: '38px', border: '1px solid transparent' }}
                  onClick={() => dispatch(setSurroundInfo(null))}
                >
                  <i
                    className="fal fa-trash-alt mr-2"
                    style={{ color: '#495057' }}
                  ></i>
                  Xóa
                </span>
              )}
            </div>
          </div>

          {surroundInfo?.surroundData?.filename?.endsWith('.svg') && (
            <div className="d-flex flex-column mb-5" style={{}}>
              <div className="d-flex flex-row w-100 justify-content-between mb-2">
                <p
                  className="mb-0"
                  onClick={() => dispatch(setActivePanel('surround_sel'))}
                >
                  Màu khung viền
                </p>
              </div>

              <div>
                <CustomColorPicker
                  selectedColor={surroundInfo.surroundColor}
                  setSelectedColor={(color) =>
                    dispatch(setSurroundInfo({ surroundColor: color }))
                  }
                />
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-row">
          <div className="d-flex flex-column mb-5 mr-5">
            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
              <p className="mb-0">Hình nền</p>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
              <div
                className="border pl-4 py-2 pr-3 overflow-hidden d-flex flex-row justify-content-between active-border-blue"
                style={{ height: '38px', width: '251px' }}
                onClick={() => dispatch(setActivePanel('background_sel'))}
              >
                <div className="overflow-hidden" style={{ width: '190px' }}>
                  {backgroundInfo &&
                  !backgroundInfo.backgroundData.name.startsWith('mau_')
                    ? backgroundInfo.backgroundData.name
                    : 'Chọn từ bộ hình nền có sẵn'}
                </div>

                <div>
                  <i
                    className="fas fa-chevron-right"
                    style={{ color: '#495057' }}
                  ></i>
                </div>
              </div>

              {backgroundInfo &&
                !backgroundInfo.backgroundData?.name.startsWith('mau_') && (
                  <span
                    className="ml-2 cursor-pointer active-border-blue p-2"
                    style={{ height: '38px', border: '1px solid transparent' }}
                    onClick={() => dispatch(setBackgroundInfo(null))}
                  >
                    <i
                      className="fal fa-trash-alt mr-2"
                      style={{ color: '#495057' }}
                    ></i>
                    Xóa
                  </span>
                )}
            </div>
          </div>

          {backgroundInfo &&
            !backgroundInfo.backgroundData?.name.startsWith('mau_') &&
            backgroundInfo.backgroundData?.filename?.endsWith('.svg') && (
              <div className="d-flex flex-column mb-5" style={{}}>
                <div className="d-flex flex-row w-100 justify-content-between mb-2">
                  <p className="mb-0">Màu hình nền</p>
                </div>

                <div>
                  <CustomColorPicker
                    selectedColor={backgroundInfo?.backgroundColor}
                    setSelectedColor={(color) =>
                      dispatch(setBackgroundInfo({ backgroundColor: color }))
                    }
                  />
                </div>
              </div>
            )}
        </div>

        {backgroundInfo && (
          <div className="d-flex flex-row align-items-center mb-5">
            <p className="mb-0 mr-6">Độ nét</p>
            <div
              className="d-flex justify-content-center flex-wrap"
              style={{
                width: '300px',
              }}
            >
              <Range
                values={[backgroundInfo?.opacity]}
                step={1}
                min={0}
                max={100}
                onChange={(values) =>
                  dispatch(
                    setBackgroundInfo({
                      opacity: values[0],
                    }),
                  )
                }
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    className="d-flex w-100"
                    style={{
                      ...props.style,
                      height: '20px',
                    }}
                  >
                    <div
                      ref={props.ref}
                      className="w-100 align-self-center"
                      style={{
                        height: '5px',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values: [backgroundInfo?.opacity],
                          colors: ['#548BF4', '#ccc'],
                          min: 0,
                          max: 100,
                        }),
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '5px',
                      width: '20px',
                      backgroundColor: '#FFF',
                      boxShadow: '0px 2px 6px #AAA',
                    }}
                  ></div>
                )}
              />
            </div>

            <output id="output" className="ml-5">{`${[
              backgroundInfo?.opacity,
            ]}%`}</output>
          </div>
        )}

        <div className="mb-2">
          <p className="mb-2">Tự thiết lập hình nền riêng (Logo chìm)</p>

          <div
            className={`logo-dropzone w-100 justify-content-center align-items-center cursor-pointer ${
              customBackgroundInfo?.file ? '' : 'bg-hover-secondary'
            } ${!customBackgroundInfo?.file ? 'd-flex' : 'd-none'}`}
          >
            <div
              {...getRootProps1({
                className: 'dropzone',
              })}
              className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
            >
              <input
                {...getInputProps1()}
                id="upload-custom-background-input"
              />
              <i
                className="fal fa-image-polaroid fa-4x mb-3"
                style={{ opacity: 0.8 }}
              ></i>
              <p>Kéo thả tệp logo vào đây hoặc Chọn từ máy tính</p>
            </div>
          </div>

          {customBackgroundInfo?.file && (
            <div className="d-flex flex-row align-items-center mb-3">
              <div className="custom-logo-preview p-3 d-flex justify-content-center align-items-center">
                <img
                  src={
                    customBackgroundInfo?.file instanceof File
                      ? URL.createObjectURL(customBackgroundInfo?.file)
                      : `${process.env.REACT_APP_BASE_URL}/resources/customBackground/${customBackgroundInfo?.file}`
                  }
                  alt="..."
                  className="mw-100 mh-100"
                ></img>
              </div>
              <div className="setting-custom-logo-position p-3">
                <div className="mb-2">
                  <p className="mb-0 text-left">Vị trí hình nền</p>
                </div>

                <div className="mb-2">
                  <DropdownButton
                    title={
                      customBackgroundInfo.position === 'center'
                        ? 'Căn giữa hóa đơn'
                        : 'Toàn hóa đơn'
                    }
                    onSelect={handleChangeCustomBackgroundPositionSelect}
                    className="w-100"
                  >
                    <Dropdown.Item eventKey="center">
                      Căn giữa hóa đơn
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="full">Toàn hóa đơn</Dropdown.Item>
                  </DropdownButton>
                </div>

                <div className="w-100">
                  <div className="d-flex flex-row align-items-center justify-content-between mb-1 w-100">
                    <p className="mb-0 mr-6">Độ nét</p>

                    <output id="output-cb" className="ml-5">{`${
                      customBackgroundInfo?.opacity || 0
                    }%`}</output>
                  </div>

                  <div className="d-flex justify-content-center flex-wrap w-100">
                    <Range
                      values={[customBackgroundInfo?.opacity || 0]}
                      step={1}
                      min={0}
                      max={100}
                      onChange={(values) =>
                        dispatch(
                          setCustomBackgroundInfo({ opacity: values[0] }),
                        )
                      }
                      renderTrack={({ props, children }) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          className="d-flex w-100"
                          style={{
                            ...props.style,
                            height: '20px',
                          }}
                        >
                          <div
                            ref={props.ref}
                            className="w-100 align-self-center"
                            style={{
                              height: '5px',
                              borderRadius: '4px',
                              background: getTrackBackground({
                                values: [customBackgroundInfo?.opacity || 0],
                                colors: ['#548BF4', '#ccc'],
                                min: 0,
                                max: 100,
                              }),
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '5px',
                            width: '20px',
                            backgroundColor: '#FFF',
                            boxShadow: '0px 2px 6px #AAA',
                          }}
                        ></div>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column">
                <span
                  className="ml-2 cursor-pointer active-border-blue p-2 mb-2"
                  style={{ height: '38px', border: '1px solid transparent' }}
                  onClick={() => dispatch(setCustomBackgroundInfo(null))}
                >
                  <i
                    className="fal fa-trash-alt mr-2"
                    style={{ color: '#495057' }}
                  ></i>
                  Xóa
                </span>
                <span
                  className="ml-2 rounded bg-primary text-white cursor-pointer active-border-blue px-1 py-2"
                  style={{ height: '38px', border: '1px solid transparent' }}
                  onClick={handleReUploadCustomBackground}
                >
                  <i className="fa-regular fa-upload text-white"></i> Tải lại
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LogoControl
