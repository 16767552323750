import { Checkbox, Input } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import CustomSelect from 'general/components/CustomSelect'
import Tagnify from 'general/components/CustomTag/Tagnify'
import AntdDropdown from 'general/components/Dropdown/AntdDropdown'
import CustomTimePicker from 'general/components/Forms/CustomTimePicker'
import FormLabel from 'general/components/Forms/FormLabel'
import InputNumberNoFormik from 'general/components/Forms/InputNumerNoFormik'
import {
  ROUTE_TYPE,
  TICKET_TYPE_DETUCT,
  TYPE_PRICE_TICKET,
} from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import _, { includes, split } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { string } from 'yup'

ModalTicketGXDetail.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
  ticketTemplate: PropTypes.object,
}

function ModalTicketGXDetail(props) {
  const {
    show,
    setShow,
    header,
    handleConfirm,
    ticketTemplate = {},
    currentTicket, // vé để chỉnh sửa
  } = props
  const {
    category_ticket = {},
    route = {},
    ticketPriceReduce,
    serial,
    isTaxReduction,
    ticketPriceWithoutReduce,
    rateVatInAmount,
  } = ticketTemplate
  const totalPrice = ticketTemplate.ticketPrice
  const taxRate = ticketTemplate.vatRateTicket
  const taxMoney = Math.round(
    Utils.calculateTaxMoneyWithTotalPrice(totalPrice, taxRate),
  )
  const price = totalPrice - taxMoney

  const { noCar } = route

  const calculateAndSetTaxMoneyAndPrice = (totalPrice, taxRate) => {
    let taxMoney = 0,
      price = totalPrice
    if (_.isNumber(totalPrice) && totalPrice >= 0 && taxRate) {
      taxMoney = Math.round(
        Utils.calculateTaxMoneyWithTotalPrice(totalPrice, taxRate),
      )
      price = totalPrice - taxMoney
    }
    formik.setFieldValue('taxMoney', taxMoney)
    formik.setFieldValue('price', price)
  }

  const calculateAndSetTaxReduceMoneyAndTotalPrice = (
    ticketPriceWithoutReduce,
    rateVatInAmount,
  ) => {
    let totalPrice = ticketPriceWithoutReduce
    let ticketPriceReduce = 0
    if (
      _.isNumber(ticketPriceWithoutReduce) &&
      ticketPriceWithoutReduce >= 0 &&
      _.isNumber(rateVatInAmount)
    ) {
      ticketPriceReduce = Math.round(
        ((ticketPriceWithoutReduce * parseInt(rateVatInAmount)) / 100) * 0.2,
      )
      totalPrice = ticketPriceWithoutReduce - ticketPriceReduce
    }
    formik.setFieldValue('totalPrice', totalPrice)
    formik.setFieldValue('ticketPriceReduce', ticketPriceReduce)
  }

  const noCarSelectOption = useMemo(() => {
    let noCarArray
    if (!noCar) return null
    const tokenizationString = ';'
    const hasToken = includes(noCar, tokenizationString)
    if (hasToken) {
      const inputArray = split(noCar, tokenizationString)
      noCarArray = inputArray
    } else {
      noCarArray = [noCar]
    }

    return _.map(noCarArray, (item, key) => {
      return { label: item, value: item, key }
    })
  }, [noCar])

  const { isPrePrintPrice, isDirectDeduct } = useMemo(() => {
    if (_.isEmpty(category_ticket)) {
      return { isPrePrintPrice: null, isDirectDeduct: null }
    }
    const { typePrice, typeDeduct } = category_ticket
    return {
      isPrePrintPrice: typePrice === TYPE_PRICE_TICKET.PREPRINTED_PRICE,
      isDirectDeduct: typeDeduct === TICKET_TYPE_DETUCT.TT,
    }
  }, [category_ticket])

  const today = new Date()
  const formik = useFormik({
    initialValues: {
      index: currentTicket?.index,
      noCar: currentTicket?.noCar ?? '',
      taxRate: currentTicket?.taxRate ?? taxRate,
      taxMoney: currentTicket?.taxMoney ?? taxMoney,
      price: currentTicket?.price ?? price,
      totalPrice: currentTicket?.totalPrice ?? totalPrice ?? 0,
      ticketPriceReduce: currentTicket?.ticketPriceReduce ?? ticketPriceReduce,
      ticketPriceWithoutReduce:
        currentTicket?.ticketPriceWithoutReduce ?? ticketPriceWithoutReduce,
      rateVatInAmount: currentTicket?.rateVatInAmount ?? rateVatInAmount ?? 3,
      isTaxReduction: !_.isEmpty(currentTicket)
        ? currentTicket?.isTaxReduction
        : isTaxReduction
          ? true
          : false,
    },
    // validationSchema: ValidationSchema.createNewInvoice,
    onSubmit: async (values) => {},
    enableReinitialize: true,
    validateOnChange: true,
  })

  useEffect(() => {
    const { totalPrice, taxRate } = formik.values
    if (!isPrePrintPrice && !isDirectDeduct) {
      calculateAndSetTaxMoneyAndPrice(totalPrice, taxRate)
    }
  }, [formik.values.totalPrice, formik.values.taxRate])

  useEffect(() => {
    const { ticketPriceWithoutReduce, rateVatInAmount } = formik.values
    if (formik.values.isTaxReduction && !isPrePrintPrice) {
      calculateAndSetTaxReduceMoneyAndTotalPrice(
        ticketPriceWithoutReduce,
        rateVatInAmount,
      )
    }
  }, [formik.values.ticketPriceWithoutReduce, formik.values.rateVatInAmount])

  let handleClose = () => {
    setShow(false)
    resetForm()
  }

  const resetForm = () => {
    formik.resetForm()
  }

  const handleChangeNoCar = (e) => {
    e.preventDefault()
    formik.setFieldValue('noCar', e.target.value)
  }

  const handleSelectNoCar = ({ key }) => {
    const selectedNoCar = noCarSelectOption[parseInt(key)].value
    formik.setFieldValue('noCar', selectedNoCar)
  }

  const onChangeIsTaxReduction = (e) => {
    formik.setFieldValue('isTaxReduction', e.target.checked)
    if (e.target.checked) {
      formik.setFieldValue('ticketPriceWithoutReduce', formik.values.totalPrice)
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="ModalTicketGXDetail"
      className="modal-ticket-detail"
      size="lg"
      // width={'55rem'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ width: '120px' }}>
            <span>{header}</span>
          </div>
        </Modal.Title>
        {isDirectDeduct && (
          <div className="d-flex flexr-row justify-content-end w-100">
            <Checkbox
              checked={formik.values.isTaxReduction}
              onChange={onChangeIsTaxReduction}
              disabled={isPrePrintPrice}
            >
              <span style={{ color: '#333333', fontWeight: '500' }}>
                Giảm thuế theo NQ 101/2023/QH15
              </span>
            </Checkbox>
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="p-0">
        <div
          className="w-100 d-flex flex-column p-4"
          style={{ backgroundColor: '#edeff2', gap: '5px' }}
        >
          {formik.values.isTaxReduction && (
            <div className="d-flex flex-row justify-content-start">
              <div className="d-flex flex-row align-items-center w-50 pr-5">
                <FormLabel width={formik.values.isTaxReduction && '200px'}>
                  Tổng tiền(Chưa giảm thuế):
                </FormLabel>
                {!isPrePrintPrice ? (
                  <div style={{ height: '38px' }}>
                    <InputNumberNoFormik
                      onBlur={(e) => {
                        const value = Utils.parseFormattedNumberToFloat(
                          e.target.value,
                        )
                        formik.setFieldValue('ticketPriceWithoutReduce', value)
                      }}
                      value={formik.values.ticketPriceWithoutReduce}
                    />
                  </div>
                ) : (
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#ff6f00',
                    }}
                  >
                    {formik.values.ticketPriceWithoutReduce &&
                      Utils.formatNumber(
                        formik.values.ticketPriceWithoutReduce,
                      )}
                  </span>
                )}
              </div>
              {!isPrePrintPrice && (
                <div className="d-flex flex-row align-items-center w-50">
                  <FormLabel
                    width={formik.values.isTaxReduction ? '200px' : null}
                  >
                    % Tính thuế
                  </FormLabel>
                  <div className="flex-grow-1">
                    <CustomSelect
                      selectOptions={SelectOptions.rateVatInAmount}
                      menuParentRef={document.querySelector(
                        '.modal-ticket-detail',
                      )}
                      currentValue={formik.values.rateVatInAmount}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          'rateVatInAmount',
                          selectedOption.value,
                        )
                      }}
                      haveMenuParentRef={false}
                      width="100%"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="d-flex flex-row justify-content-start">
            {!isPrePrintPrice && !isDirectDeduct && (
              <div className="d-flex flex-row align-items-center w-50 pr-5">
                <FormLabel>Thuế GTGT:</FormLabel>
                <div style={{ width: '110px' }}>
                  <CustomSelect
                    selectOptions={SelectOptions.GTGT}
                    menuParentRef={document.querySelector(
                      '.modal-ticket-detail',
                    )}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('taxRate', selectedOption.value)
                    }}
                    haveMenuParentRef={false}
                    width="100%"
                  />
                </div>
              </div>
            )}
            {formik.values.isTaxReduction && (
              <div className="d-flex flex-row align-items-center w-50 pr-5">
                <FormLabel width="200px">Tiền thuế GTGT được giảm:</FormLabel>
                {!isPrePrintPrice ? (
                  <div style={{ height: '38px' }}>
                    <InputNumberNoFormik
                      onBlur={(e) => {
                        const value = Utils.parseFormattedNumberToFloat(
                          e.target.value,
                        )
                        formik.setFieldValue('ticketPriceReduce', value)
                      }}
                      value={formik.values.ticketPriceReduce}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#ff6f00',
                    }}
                  >
                    {ticketPriceReduce && Utils.formatNumber(ticketPriceReduce)}
                  </span>
                )}
              </div>
            )}
            <div className="d-flex flex-row align-items-center w-50">
              <FormLabel
                width={
                  formik.values.isTaxReduction && !isPrePrintPrice
                    ? '200px'
                    : null
                }
              >
                Tiền vé:
              </FormLabel>
              {!isPrePrintPrice ? (
                <div style={{ height: '38px' }}>
                  <InputNumberNoFormik
                    onBlur={(e) => {
                      const value = Utils.parseFormattedNumberToFloat(
                        e.target.value,
                      )
                      formik.setFieldValue('totalPrice', value)
                    }}
                    value={formik.values.totalPrice}
                    disabled={!isPrePrintPrice && formik.values.isTaxReduction}
                  />
                </div>
              ) : (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#ff6f00',
                  }}
                >
                  {formik.values.totalPrice &&
                    Utils.formatNumber(formik.values.totalPrice)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column p-4" style={{ gap: '8px' }}>
          <div className="d-flex flex-row align-items-start justify-content-between">
            <div
              className="d-flex flex-column"
              style={{ width: '40%', gap: '10px' }}
            >
              <div className="d-flex flex-row align-items-center position-relative">
                <FormLabel>Biển số xe:</FormLabel>
                <Input
                  placeholder="Nhập biển số xe"
                  onChange={handleChangeNoCar}
                  value={formik.values.noCar}
                />
                {!_.isEmpty(noCarSelectOption) && (
                  <AntdDropdown
                    items={noCarSelectOption}
                    onClick={handleSelectNoCar}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-4 px-8"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>
          <button
            className="btn btn-primary px-8"
            onClick={() => {
              const { totalPrice, taxRate } = formik.values
              console.log(formik.values)
              if (!isDirectDeduct) {
                if (!taxRate) {
                  ToastHelper.showError('Chưa có thuế GTGT')
                  return
                }
              }
              if (isDirectDeduct) {
                if (isTaxReduction) {
                }
              }
              if (!_.isNumber(totalPrice) || totalPrice < 0) {
                ToastHelper.showError('Chưa nhập tiền vé')
                return
              }
              handleConfirm({
                ticketTemplate,
                ...formik.values,
              })
              resetForm()
            }}
          >
            Đồng ý
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalTicketGXDetail
