import axiosClient from './axiosClient'

const orderApi = {
  getByCompanyId: (params) => {
    const url = '/order/getByCompanyId'
    return axiosClient.get(url, { params })
  },

  create: (params) => {
    const url = '/order/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = '/order/update'
    return axiosClient.put(url, params)
  },

  deleteOrder: (orderId) => {
    const url = `/order/deleteOrder`
    return axiosClient.delete(url, { data: { orderId } })
  },
}

export default orderApi
