import { Flex } from 'antd'
import dayjs from 'dayjs'

import { useFormik } from 'formik'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'

export default function ExportTicketForm() {
  const formik = useFormik({
    initialValues: {
      taxAuthorityCode: '',
      serial: '',
      ticketNo: '',
      dateRelease: dayjs(),
    },
  })

  return (
    <Flex wrap="wrap" vertical align="center" className="h-100 w-100 p-3">
      <div className="w-50">
        <CustomLabel
          text="Mã của cơ quan thuế (Với hoá đơn có mã)"
          htmlFor="taxAuthorityCode"
        />
        <CustomAntInput
          type="text"
          borderRadius="sm"
          inputProps={{
            id: 'taxAuthorityCode',
            value: formik.values.taxAuthorityCode,
            onChange: (val) =>
              formik.setFieldValue('taxAuthorityCode', val.target.value),
            status: formik.errors.taxAuthorityCode ? 'error' : '',
          }}
        />
      </div>
      <div className="w-50">
        <CustomLabel text="Ký hiệu" htmlFor="serial" isRequired />
        <CustomAntInput
          type="text"
          borderRadius="sm"
          inputProps={{
            id: 'serial',
            value: formik.values.serial,
            onChange: (val) => formik.setFieldValue('serial', val.target.value),
            status: formik.errors.serial ? 'error' : '',
          }}
        />
      </div>
      <div className="w-50">
        <CustomLabel text="Số hoá đơn" htmlFor="ticketNo" isRequired />
        <CustomAntInput
          type="text"
          borderRadius="sm"
          inputProps={{
            id: 'ticketNo',
            value: formik.values.ticketNo,
            onChange: (val) =>
              formik.setFieldValue('ticketNo', val.target.value),
            status: formik.errors.ticketNo ? 'error' : '',
          }}
        />
      </div>
      <div className="w-50">
        <CustomLabel text="Ngày hoá đơn" htmlFor="dateRelease" isRequired />
        <CustomAntInput
          type="datepicker"
          inputProps={{
            id: 'dateRelease',
            allowClear: false,
            getPopupContainer: (triggered) => triggered.parentNode,
            className: 'w-100',
            value: formik.values.dateRelease,
            onChange: (date) => formik.setFieldValue('dateRelease', date),
            status: formik.errors.dateRelease ? 'error' : '',
          }}
          borderRadius="sm"
        />
      </div>
    </Flex>
  )
}
