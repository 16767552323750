import _ from 'lodash'
import { v4 as uuid4 } from 'uuid'

export const transformingElements = (arr = []) =>
  _.isEmpty(arr)
    ? []
    : _.map(arr, (item) => ({
        id: item?.id ?? uuid4().split('-').join(''),
        name: item.name,
        isAppear: item.isAppear ?? true,
        hidden: item.hidden ?? false,
        disabled: _.isEmpty(item.name) || item.disabled,
      }))

export const transformingBack = (changed, original) =>
  _.map(changed, (changedItem) => {
    const found = _.find(original, { name: changedItem.name })
    return {
      ...found,
      omit: changedItem.hidden ?? false,
    }
  })

/**
 * Dịch chuyen phần tử trong mảng từ vị trí from sang vị trí to
 * @param {Array} arr
 * @param {number} from
 * @param {number} to
 */
export const arrayMove = (arr, from, to) => {
  return _.chain(arr)
    .clone()
    .thru((cloned) => {
      cloned.splice(to, 0, cloned.splice(from, 1)[0])
      return cloned
    })
    .value()
}
