import { Tag } from 'antd'
import { INVOICE_STATISTIC } from 'general/constants/AppConstants'
import _ from 'lodash'
import { forwardRef } from 'react'

const StatisticItem = forwardRef(
  ({ id, dragOverlay, isActivated, handleDeleteTag, style, ...props }, ref) => {
    const text = _.upperFirst(INVOICE_STATISTIC[id])
    return (
      <Tag
        closable={isActivated}
        onClose={(e) => handleDeleteTag(e, id)}
        style={{
          ...style,
          cursor: dragOverlay ? 'grabbing' : 'grab',
          padding: '1rem',
          margin: '0.5rem',
        }}
        color={isActivated ? 'magenta' : 'blue-inverse'}
        ref={ref}
        {...props}
      >
        {text}
      </Tag>
    )
  },
)

export default StatisticItem
