import Utils from 'general/utils/Utils'
import PropTypes from 'prop-types'
import './styles.scss'

TicketLayout.propTypes = {
  name: PropTypes.string,
  serial: PropTypes.string,
  price: PropTypes.number,
  onClick: PropTypes.func,
}

TicketLayout.defaultProps = {
  name: '',
}

function TicketLayout(props) {
  const { name, serial, price, onClick } = props
  return (
    <div className="ticket-layout" onClick={onClick}>
      <div className="ticket-header">{name}</div>
      <div className="holes"></div>
      <div className="ticket-content">
        <span className="serial">{serial}</span>
        <span className="price">
          {Utils.isValidNumber(price) ? Utils.formatNumber(price) : '-------'}
        </span>
      </div>
    </div>
  )
}

export default TicketLayout
