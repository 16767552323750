import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import taxAuthorityMessageApi from 'api/taxAuthorityMessageApi'
import dayjs from 'dayjs'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomTag from 'general/components/CustomTag'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import { REGISTRATION_STATUS } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import RegistrationHelper from 'general/helpers/RegistrationHelper'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const getTaxMessage100 = (registration) =>
  registration?.tax_authority_messages?.find(({ typeCode }) => typeCode == 100)

function ListTaxMessageRegistration() {
  // MARK --- Parmas: ---
  const { t } = useTranslation()
  const [showModalDataTransmissionLog, setShowModalDataTransmissionLog] =
    useState(false)
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'taxMessageRegistrationList',
    'gFilterTaxMessageRegistrationList',
  )

  const { data: registrationData, isFetching: gettingRegistrationData } =
    useQuery({
      queryKey: ['listRegistrationMessage', filters, debouncedSearch],
      queryFn: () =>
        taxAuthorityMessageApi.listRegistrationMessage({
          ...filters,
          q: debouncedSearch,
        }),
      select: (res) => {
        return {
          total: _.get(res, 'total', 0),
          registrations: res?.registrations
            ? _.map(res?.registrations, (reg, idx) => ({
                ...reg,
                id: idx + 1,
              }))
            : [],
        }
      },
    })

  const [activeRegistration, setActiveRegistration] = useState(null)

  const showTransmissionLog = () => setShowModalDataTransmissionLog(true)

  const fullColumns = useMemo(() => {
    return [
      {
        name: 'Tên thông điệp',
        center: true,
        sortable: true,
        sortFunction: (a, b) => a.type - b.type,
        // width: 'auto',
        minWidth: '430px',
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
            >
              {row.type == 1
                ? 'Thông điệp gửi tờ khai đăng ký sử dụng hóa đơn điện tử'
                : row.type == 2
                  ? 'Thông điệp gửi tờ khai thay đổi thông tin sử dụng hóa đơn điện tử'
                  : ''}
            </div>
          )
        },
      },
      {
        name: 'Loại tờ khai',
        center: true,
        sortable: true,
        sortFunction: (a, b) => {
          if (a.type - b.type > 0) {
            return 1
          } else if (a.type - b.type < 0) {
            return -1
          }
          return 0
        },
        // width: 'auto',
        minWidth: '185px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {RegistrationHelper.renderRegistrationType(row?.type)}
            </p>
          )
        },
      },
      {
        name: 'Ngày tạo',
        center: true,
        sortable: true,
        minWidth: '185px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-bold m-0 text-maxline-3 mr-4"
            >
              {dayjs(row?.date).format('DD/MM/YYYY hh:mm:ss')}
            </p>
          )
        },
      },
      {
        name: 'Ngày gửi',
        center: true,
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = dayjs(
            getTaxMessage100(a)?.contentRaw?.TDiep?.TTChung?.TGGui,
          )
          const dateB = dayjs(
            getTaxMessage100(b)?.contentRaw?.TDiep?.TTChung?.TGGui,
          )
          if (dateA.isBefore(dateB)) {
            return -1
          } else if (dateA.isAfter(dateB)) {
            return 1
          }
          return 0
        },
        // width: 'auto',
        minWidth: '175px',
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
            >
              {dayjs(
                getTaxMessage100(row)?.contentRaw?.TDiep?.TTChung?.TGGui,
              ).format('DD/MM/YYYY hh:mm:ss')}
            </div>
          )
        },
      },
      {
        name: 'Số tờ khai',
        center: true,
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.no || !b.no) return 0
          return a.no.localeCompare(b.no)
        },
        // width: 'auto',
        minWidth: '125px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.no}
            </p>
          )
        },
      },
      {
        name: 'Trạng thái',
        center: true,
        sortable: true,
        sortFunction: (a, b) => {
          let aT = RegistrationHelper.renderRegistrationStatus(a?.status)
          let bT = RegistrationHelper.renderRegistrationStatus(b?.status)
          if (!aT || !bT) return 0
          return aT.localeCompare(bT)
        },
        minWidth: '200px',
        cell: (row) => {
          return (
            <CustomTag
              tagProps={{
                className: `badge badge-light-${
                  row?.status === 'DRAFT'
                    ? 'secondary'
                    : row?.status === 'SENT_TAX'
                      ? 'success'
                      : row?.status === 'TAX_NOT_RECEIVE' ||
                          row?.status === 'TAX_NOT_ACCEPT'
                        ? 'danger'
                        : 'primary'
                }`,
                // 'data-tag': 'allowRowEvents',
              }}
              text={RegistrationHelper.renderRegistrationStatus(row?.status)}
            />
          )
        },
      },
      {
        name: 'Hành động',
        grow: 0,
        center: true,
        compact: true,
        cell: (row) => {
          return (
            <Space
              align="center"
              onClick={() =>
                activeRegistration?.registrationId != row.registrationId
                  ? setActiveRegistration(row)
                  : null
              }
            >
              <TableAction
                titleActionText="Xem nhật ký"
                icon={<IconAction className="fa-light fa-eye" />}
                onClick={() => showTransmissionLog(row)}
              />
            </Space>
          )
        },
      },
    ]
  }, [activeRegistration?.registrationId])

  const dynamicColumns = useAppDnD(fullColumns)
  return (
    <ContentContainer wrapperClassName="ListTaxMessageRegistration">
      <ContentContainer.Header
        titleContent={t('Lịch sử truyền nhận tờ khai')}
        description={`${t('Tổng')}: ${registrationData?.total} ${t('Tờ khai')}`}
        toolBar={
          <>
            <CustomAntInput
              type="search"
              borderRadius="sm"
              inputProps={{
                defaultValue: search,
                onChange: (e) => setSearch(e.target.value),
                style: {
                  height: '100%',
                },
              }}
              width={150}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={
                PreferenceKeys.savedColumnsTaxMessageRegistration
              }
            />
          </>
        }
      >
        <FilterPopover
          menuPosition="bottomRight"
          defaultValues={[
            {
              label: 'Trạng thái',
              alias: 'Trạng thái',
              formName: 'status',
              value: SelectOptions.registrationStatus.filter(
                (item) => item.value !== REGISTRATION_STATUS.DRAFT,
              )[0].value,
              options: SelectOptions.registrationStatus.filter(
                (item) => item.value !== REGISTRATION_STATUS.DRAFT,
              ),
              colSpan: 'FULL',
            },
          ]}
          savedKey="gFilterTaxMessageRegistrationList"
          onApply={setFilters}
        />
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={registrationData?.registrations}
          isLoading={gettingRegistrationData}
          isClickable
          handleDoubleClickRow={(row) => showTransmissionLog(row)}
          pagination={{
            rowsPerPage: filters?.limit,
            currentPage: filters?.page,
            total: registrationData?.total,
            onChangePage: (page) => setFilters({ page }),
            onChangeRowsPerPage: (limit) => setFilters({ limit, page: 1 }),
          }}
        />
      </ContentContainer.Body>
      <ModalDataTransmissionLog
        show={showModalDataTransmissionLog}
        onHide={() => setShowModalDataTransmissionLog(false)}
        viewUrl={
          activeRegistration &&
          `/hoa-don-dau-ra/to-khai/xem/${activeRegistration.registrationId}`
        }
        registration={activeRegistration}
      />
    </ContentContainer>
  )
}

export default ListTaxMessageRegistration
