import { getCookie, setCookie } from 'assets/plugins/ktcookie'
import { useEffect, useState } from 'react'
import { Collapse, Container } from 'react-bootstrap'
import ScrollableDashboard from './ScrollableDashboard'
import { Button } from './styles'

const InvoiceDashboard = (props) => {
  const [isOpenDashboard, setIsOpenDashboard] = useState(
    () => getCookie('isOpenDashboard') === 'true',
  )
  useEffect(() => {
    setCookie('isOpenDashboard', isOpenDashboard)
  }, [isOpenDashboard])

  return (
    <Container fluid className="px-2 position-relative">
      <Collapse in={isOpenDashboard}>
        <div id="collapse">
          <ScrollableDashboard {...props} />
        </div>
      </Collapse>
      <Button
        variant="secondary"
        width="2rem"
        height="2rem"
        rounded
        style={{
          position: 'absolute',
          bottom: '-1.25rem',
          zIndex: 2,
          right: '50%',
        }}
        onClick={() => setIsOpenDashboard((prev) => !prev)}
      >
        <i
          className={`fa-light fa-chevron-down ${
            !isOpenDashboard ? '' : 'fa-rotate-180'
          } `}
        />
      </Button>
    </Container>
  )
}

export default InvoiceDashboard
