import Utils from 'general/utils/Utils'
import _ from 'lodash'

const customFilterHelpers = {
  removeAccents: (str = '') => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  },

  /**
   * @param {string} input
   * @param {FilterDefaultValueProp} opt
   */
  customFilterOptionFn: (input, opt) => {
    const removedAccentsInput = customFilterHelpers.removeAccents(input.trim())
    const removedAccentsLabel = customFilterHelpers.removeAccents(
      opt.label.trim(),
    )
    return removedAccentsLabel
      .toLowerCase()
      .includes(removedAccentsInput.toLowerCase())
  },

  /** @param {ColSpanType} input */
  getColSpan: (input) =>
    typeof input === 'string'
      ? input === 'FULL'
        ? 24
        : input === 'HALF'
          ? 12
          : 24
      : typeof input === 'number'
        ? input
        : 12,
  isOverFlown: (currElement, targetParent) => {
    return (
      currElement?.scrollHeight > targetParent?.clientHeight ||
      currElement?.scrollWidth > targetParent?.clientWidth
    )
  },
}

export default customFilterHelpers
