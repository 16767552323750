import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, useEffect, useState } from 'react'

export default function useEmbla() {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
  })

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  )

  const [canItShowButton, setShowButton] = useState({
    isPrev: false,
    isNext: true,
  })

  useEffect(() => {
    if (!emblaApi) return
    const updatingStatusOfButtons = () =>
      setShowButton({
        isNext: emblaApi.canScrollNext(),
        isPrev: emblaApi.canScrollPrev(),
      })
    emblaApi.on('select', updatingStatusOfButtons)
    return () => emblaApi.off('select', updatingStatusOfButtons)
  }, [emblaApi])

  return {
    emblaRef,
    canItShowButton,
    functions: {
      scrollNext,
      scrollPrev,
    },
  }
}
