import React, { useMemo } from 'react'
import dayjs from 'dayjs'

import './style.scss'
import RegistrationHelper from 'general/helpers/RegistrationHelper'
import Utils from 'general/utils/Utils'
import { parse } from 'js2xmlparser'
import { Tooltip } from 'antd'

function Message204(props) {
  const { message } = props

  const isSent = useMemo(() => message.messageId?.startsWith('V'), [message])

  const TGNhan = useMemo(() => {
    return message.contentRaw?.TDiep?.DLieu?.TBao?.DSCKS?.CQT?.Signature?.Object
      ?.SignatureProperties?.SignatureProperty?.SigningTime
  }, [message])

  const So = useMemo(
    () => message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.So,
    [message],
  )

  const LDos = useMemo(() => {
    let LDos =
      message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.LCMa?.DSLDo?.LDo ??
      message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.LHDKMa?.DSHDon?.HDon
        ?.DSLDo?.LDo ??
      message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.KHLKhac?.DSLDo?.LDo ??
      message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.LHDMTTien?.DSLDo?.LDo
    if (!LDos) return

    return Array.isArray(LDos) ? LDos : [LDos]
  }, [message])

  const isErr = useMemo(() => {
    if (LDos) {
      return true
    } else {
      return false
    }
  }, [LDos])

  const name = useMemo(
    () => message?.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.Ten,
    [message],
  )

  return (
    <div className="Message204 border-bottom mb-5">
      <div className="d-flex flex-row">
        <div style={{ maxWidth: '500px' }}>
          <p className="mb-2">
            <i
              className={`fa-regular ${
                isSent ? 'fa-arrow-up' : 'fa-arrow-down'
              } mr-2 ${isSent ? 'i-send' : 'i-receivce'}`}
            ></i>
            <span className="font-weight-bold">
              {TGNhan && dayjs(TGNhan).format('DD/MM/YYYY hh:mm:ss')}
            </span>
          </p>

          <p className="mb-2">
            {`[${
              message.typeCode
            }] ${RegistrationHelper.renderRegistrationTypeCode(
              message.typeCode,
            )} `}
            {name}
          </p>

          {LDos && (
            <div className="p-3" style={{ backgroundColor: '#fff3cd' }}>
              {LDos?.map(({ MLoi, MTLoi, HDXLy }, index) => (
                <>
                  <p key={index} className="mb-2">
                    {`Mã lỗi: [${MLoi}]. `}
                    {MTLoi}
                  </p>
                  {HDXLy && (
                    <p key={index} style={{ color: '#5180fb' }}>
                      <a>{`Hướng dẫn xử lý cho mã lỗi [${MLoi}]: ${HDXLy} `}</a>
                    </p>
                  )}
                </>
              ))}
            </div>
          )}

          <p className="my-2">
            Số thông báo:{' '}
            <a href="#" title="Xem thông báo">
              {So}
            </a>
          </p>

          <p className="mb-2" style={{ color: '#c4c4c4' }}>
            {`Mã thông điệp: ${message.messageId}`}
          </p>
        </div>
        <div className="text-right flex-fill">
          <p className="mb-2 font-weight-bold">
            {isSent ? '0106870211' : 'Cơ quan thuế'}
          </p>

          <p className="mb-2">
            <span
              className="text-dark-75 font-weight-normal"
              style={{
                borderRadius: '40px',
                backgroundColor: isErr ? '#FF7F7F' : '#a9efb7',
                color: isErr ? '#f64e60' : '#12a744',
                padding: '3px 10px',
              }}
            >
              {isErr ? 'CQT không chấp nhận' : 'CQT chấp nhận'}
            </span>
          </p>

          <p
            className="mb-2 cursor-pointer"
            onClick={() => {
              // const xmlOptions = {
              //   declaration: {
              //     include: false,
              //   },
              // };
              // let xmlString = parse('TKhai', message.contentRaw, xmlOptions);

              // const downloadLink = document.createElement('a');
              // const fileBlob = new Blob([xmlString], { type: 'application/xml' });
              // downloadLink.href = URL.createObjectURL(fileBlob);
              // downloadLink.download = 'thong-diep-204.xml';
              // downloadLink.click();
              Utils.exportXmlFile({
                data: message.contentRaw.TDiep,
                fileName: 'thong-diep-204',
              })
            }}
            title="Xuất khẩu XML"
          >
            <i
              className="fa-regular fa-download"
              style={{ color: 'inherit' }}
            ></i>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Message204
