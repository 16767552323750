import dayjs from 'dayjs'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomAntRangePicker from 'general/components/CustomAntRangePicker'
import CustomAntSelect from 'general/components/CustomAntSelect'
import SelectOptions from 'general/constants/SelectOptions'
import Global from 'general/utils/Global'
import useGetSerials from 'hooks/Queries/useGetSerials'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutationDownloadExcel } from './queries'

function ExportToExcelFile() {
  // MARK --- Params ---
  const { t } = useTranslation()
  const [filters, setFilters] = useState(Global.gFiltersExportReportFile)
  // MARK --- Functions ---
  const { data: serials } = useGetSerials('POS')
  const { mutate: handleExportData, isPending } = useMutationDownloadExcel()

  const onChangeSelect = (value, options, name) => {
    let result
    if (value.length === options.length - 1) result = null
    else if (!_.includes(value, null)) result = value
    else {
      result = _.uniq(
        _.differenceWith(_.map(options, 'value'), value, _.isEqual),
      )
    }
    return setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className="p-3 d-flex h-100 flex-column">
      <div className="card card-custom container-xxl w-100 flex-grow-1">
        {/* card header */}
        <div className="card-header border-0 py-2">
          <div className="w-100 d-flex flex-wrap gap-2 justify-content-between">
            <div className="card-title my-0">
              <h3 className="card-label">{t('Xuất dữ liệu báo cáo')}</h3>
            </div>
          </div>
        </div>

        {/* card body */}
        <div className="card-body pt-0 px-5 pb-4">
          <div className="container">
            <div className="row">
              <span className="col-lg-4">Khoảng ngày</span>
              <div className="col-lg-8">
                <div style={{ width: 'fit-content' }}>
                  <CustomAntRangePicker
                    dateFormat="L"
                    defaultValue={[
                      dayjs(filters.startDate),
                      dayjs(filters.endDate),
                    ]}
                    defaultPeriod={filters.period}
                    borderRadius="sm"
                    onChange={(dates, period) =>
                      setFilters((prev) => ({
                        ...prev,
                        startDate: dates[0].format(),
                        endDate: dates[1].format(),
                        period,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row mt-9">
              <span className="col-lg-4">Mẫu báo cáo</span>
              <div className="col-lg-8">
                <div style={{ minWidth: '100px' }}>
                  <CustomAntSelect
                    className="w-100"
                    options={SelectOptions.exportTemplate}
                    value={filters.exportTemplateId}
                    onSelected={(val) =>
                      val === null &&
                      setFilters((prev) => ({
                        ...prev,
                        exportTemplateId: null,
                      }))
                    }
                    onChange={(val) =>
                      onChangeSelect(
                        val,
                        SelectOptions.exportTemplate,
                        'exportTemplateId',
                      )
                    }
                    maxTagCount={10}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-9">
              <span className="col-lg-4">Ký hiệu</span>
              <div className="col-lg-8">
                <div style={{ minWidth: '100px' }}>
                  <CustomAntSelect
                    isMultiSelect
                    className="w-100"
                    options={serials}
                    value={filters.serial}
                    onSelected={(val) =>
                      val === null &&
                      setFilters((prev) => ({ ...prev, serial: null }))
                    }
                    onChange={(val) => onChangeSelect(val, serials, 'serial')}
                    maxTagCount={10}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-6">
              <span className="col-lg-4">Loại hóa đơn</span>
              <div className="col-lg-8">
                <div style={{ minWidth: '100px' }}>
                  <CustomAntSelect
                    isMultiSelect
                    className="w-100"
                    options={SelectOptions.invoiceType}
                    value={filters.type}
                    onSelected={(val) =>
                      val === null &&
                      setFilters((prev) => ({ ...prev, type: null }))
                    }
                    onChange={(val) =>
                      onChangeSelect(val, SelectOptions.invoiceType, 'type')
                    }
                    customFilterOptionFn={(input, opt) =>
                      _.toLower(opt.label).includes(_.toLower(input))
                    }
                    maxTagCount={10}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-6">
              <span className="col-lg-4">Trạng thái gửi CQT (từ MTT)</span>
              <div className="col-lg-8">
                <div style={{ minWidth: '100px' }}>
                  <CustomAntSelect
                    isMultiSelect
                    className="w-100"
                    options={SelectOptions.sendTaxStatus}
                    value={filters.sendTaxStatus}
                    onSelected={(val) =>
                      val === null &&
                      setFilters((prev) => ({ ...prev, sendTaxStatus: null }))
                    }
                    onChange={(val) =>
                      onChangeSelect(
                        val,
                        SelectOptions.sendTaxStatus,
                        'sendTaxStatus',
                      )
                    }
                    maxTagCount={10}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-6">
              <span className="col-lg-4">Trạng thái phát hành</span>
              <div className="col-lg-8">
                <div style={{ minWidth: '100px' }}>
                  <CustomAntSelect
                    isMultiSelect
                    className="w-100"
                    options={SelectOptions.issueStatus}
                    value={filters.issueStatus}
                    onSelected={(val) =>
                      val === null &&
                      setFilters((prev) => ({ ...prev, issueStatus: null }))
                    }
                    onChange={(val) =>
                      onChangeSelect(
                        val,
                        SelectOptions.issueStatus,
                        'issueStatus',
                      )
                    }
                    customFilterOptionFn={(input, opt) =>
                      _.toLower(opt.label).includes(_.toLower(input))
                    }
                    maxTagCount={10}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-6">
              <CustomAntButton
                iconCn="fa-solid fa-download"
                text={'Xuất dữ liệu'}
                antProps={{
                  type: 'primary',
                  onClick: () => handleExportData(filters),
                  disabled: isPending,
                }}
                // onClick={() => handleExportData(filters)}
                // disabled={isPending}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExportToExcelFile
