const {
  SpanFieldValue,
  DivFieldValue,
} = require('features/TaxDeductionDocuments/styled')

export const createSpanFieldValue = (value, style, idx, funClick) => {
  return (
    <SpanFieldValue
      onClick={() => {
        funClick
      }}
      key={idx}
      textAlign={style?.textAlign}
      fontSize={style?.fontSize && `${style.fontSize}px`}
      color={style?.color}
      fontWeight={style?.fontWeight}
      textDecoration={style?.textDecoration && 'underline'}
      textTransform={style?.textTransform}
      lineHeight={style?.lineHeight && `${style?.lineHeight}px`}
      padding={style?.padding}
      margin={style?.margin}
      width={style?.width && `${style?.width}px`}
      height={style?.height && `${style?.height}px`}
      fontFamily={style?.fontFamily}
    >
      {value}
    </SpanFieldValue>
  )
}

export const createDivFieldValue = (content, idx) => {
  return (
    <DivFieldValue
      key={idx}
      textAlign={content.valueStyle?.textAlign}
      fontSize={
        content.valueStyle?.fontSize && `${content.valueStyle?.fontSize}px`
      }
      color={content.valueStyle?.color}
      fontWeight={content.valueStyle?.fontWeight}
      textDecoration={content.valueStyle?.textDecoration && `underline`}
      textTransform={content.valueStyle?.textTransform}
      lineHeight={
        content.valueStyle?.lineHeight && `${content.valueStyle?.lineHeight}px`
      }
      padding={content.valueStyle?.padding}
      margin={content.valueStyle?.margin}
      width={content.valueStyle?.width && `${content.valueStyle?.width}px`}
      height={content.valueStyle?.height && `${content.valueStyle?.height}px`}
      fontFamily={content.valueStyle?.fontFamily}
    >
      {content.value}
    </DivFieldValue>
  )
}
