import { createContext, useContext, useReducer } from 'react'

export const SubColumnsContext = createContext(null)
export const SubColumnsDispatchContext = createContext(null)

export function SubColumnsProvider({ children }) {
  const [subColumns, dispatchSubColumns] = useReducer(
    subColumnsReducer,
    initialSubCols,
  )

  return (
    <SubColumnsContext.Provider value={subColumns}>
      <SubColumnsDispatchContext.Provider value={dispatchSubColumns}>
        {children}
      </SubColumnsDispatchContext.Provider>
    </SubColumnsContext.Provider>
  )
}

export function useSubColumns() {
  return useContext(SubColumnsContext)
}

export function useSubColumnsDispatch() {
  return useContext(SubColumnsDispatchContext)
}

function subColumnsReducer(subColumns, action) {
  switch (action.type) {
    case 'set': {
      return {
        ...subColumns,
        [action.field]: action.payload,
      }
    }
    case 'set_all': {
      return { ...action.payload }
    }
    default: {
      return { ...subColumns }
    }
  }
}

const initialSubCols = {}
