import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './style.scss'

import templateInvoiceApi from 'api/templateInvoiceApi'
import { thunkGetCategories } from 'features/TemplateInvoice/templateInvoiceSlice'
import PrivateBaseLayout from 'general/components/eInvoiceComponents/BaseLayout/PrivateBaseLayout'
import TemplateInvoiceHelper from 'general/helpers/TemplateInvoiceHelper'
import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'

function ChooseTemplateInvoice() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { categories } = useSelector((state) => state.templateInvoice)

  const [systemTemplates, setSystemTemplates] = useState()
  const [type, setType] = useState(1)
  const [category, setCategory] = useState({ code: 'CB', title: 'Mẫu cơ bản' })
  const [taxType, setTaxType] = useState(1)
  const [paperSize, setPaperSize] = useState('A4')

  const menuFirstCategories = useMemo(
    () =>
      Object.keys(
        categories.reduce((group, { type }) => {
          group[type] = group[type] ?? ''

          return group
        }, {}),
      ).map((i) => parseInt(i)),
    [categories],
  )

  const menuSecondCategories = useMemo(
    () =>
      Object.keys(
        categories
          .filter((c) => c.type == type)
          .reduce((group, { code, title }) => {
            let groupName = `${code}###${title}`
            group[groupName] = group[groupName] ?? ''

            return group
          }, {}),
      ),
    [categories, type],
  )

  const menuThirdCategories = useMemo(
    () =>
      Object.keys(
        categories
          .filter((c) => c.type == type)
          .filter((c) => c.code == category.code)
          .reduce((group, { taxType }) => {
            group[taxType] = group[taxType] ?? ''
            return group
          }, {}),
      ),
    [categories, type, category],
  )

  const menuFourthCategories = useMemo(
    () =>
      Object.keys(
        categories
          .filter((c) => c.type == type)
          .filter((c) => c.code == category.code)
          .filter((c) => c.taxType == taxType)
          .reduce((group, { paperSize }) => {
            group[paperSize] = group[paperSize] ?? ''

            return group
          }, {}),
      ),
    [categories, type, category, taxType],
  )

  const categoryIds = useMemo(
    () =>
      categories
        .filter((c) => c.type == type)
        .filter((c) => c.code == category.code)
        .filter((c) => c.taxType == taxType)
        .filter((c) => c.paperSize == paperSize)
        .map(({ categoryId }) => categoryId),
    [categories, type, category, taxType, paperSize],
  )

  useEffect(() => {
    if (categoryIds?.length) {
      const getSystemTemplatesByCategoryIds = async () => {
        const res =
          await templateInvoiceApi.getSystemTemplatesByCategoryIds(categoryIds)
        setSystemTemplates(res.templates)
      }
      getSystemTemplatesByCategoryIds()
    }
  }, [categoryIds])

  useEffect(() => {
    dispatch(thunkGetCategories())
  }, [dispatch])

  useEffect(() => {
    if (menuFourthCategories && menuFourthCategories.length > 0) {
      if (!menuFourthCategories.includes(paperSize)) {
        setPaperSize(menuFourthCategories[0])
      }
    }
  }, [type])

  return (
    <div className="ChooseTemplateInvoice container-fluid">
      <PrivateBaseLayout nonPadding={true}>
        <div className="">
          <div className="d-flex flex-row align-items-center mb-4">
            <button
              className="btn btn-icon mr-5 btn-back"
              onClick={() => navigate(-1)}
            >
              <i className="fas fa-arrow-left"></i>
            </button>

            <Dropdown
              className="se-dd mr-5"
              onSelect={(num) => setType(parseInt(num))}
            >
              <Dropdown.Toggle className="">
                {TemplateInvoiceHelper.displayType(type)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {menuFirstCategories.map((num) => (
                  <Dropdown.Item key={num} eventKey={num}>
                    {TemplateInvoiceHelper.displayType(num)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="se-dd mr-5"
              onSelect={(s) =>
                setCategory({
                  code: s.split('###')[0],
                  title: s.split('###')[1],
                })
              }
            >
              <Dropdown.Toggle className="">{category.title}</Dropdown.Toggle>

              <Dropdown.Menu>
                {menuSecondCategories.map((item) => (
                  <Dropdown.Item key={item} eventKey={item}>
                    {item.split('###')[1]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="se-dd mr-5" onSelect={(s) => setTaxType(s)}>
              <Dropdown.Toggle className="">
                {TemplateInvoiceHelper.displayTaxType(taxType)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {menuThirdCategories.map((item) => (
                  <Dropdown.Item key={item} eventKey={item}>
                    {TemplateInvoiceHelper.displayTaxType(item)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="se-dd mr-5" onSelect={(s) => setPaperSize(s)}>
              <Dropdown.Toggle className="">{paperSize}</Dropdown.Toggle>

              <Dropdown.Menu>
                {menuFourthCategories.map((item) => (
                  <Dropdown.Item key={item} eventKey={item}>
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <div className="d-flex flex-row bg-light p-3 ml-5">
              <div className="mr-4 mt-1">
                <i
                  className="fa-solid fa-lightbulb-on fa-lg"
                  style={{ color: '#efd358' }}
                ></i>
              </div>
              <div className="remind">
                <p className="mb-0">
                  Toàn bộ mẫu đã cho phép thiết lập hiển thị song ngữ trong mục
                  thiết lập chung.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row flex-wrap">
            {systemTemplates?.length ? (
              systemTemplates.map((template, index) => (
                <div
                  className="item-template"
                  key={index}
                  onClick={() =>
                    navigate(
                      `/hoa-don-dau-ra/mau-hoa-don/tao/${template.invoiceTemplateId}`,
                    )
                  }
                >
                  <div className="p-2">
                    <img
                      className="w-100"
                      alt=""
                      src={`${process.env.REACT_APP_BASE_URL}/resources/thumbnail/${template.thumbnail}`}
                    />
                  </div>
                  <p className="template-symbol pb-2 mb-0">{`${TemplateInvoiceHelper.displayTypeSymbol(
                    type,
                  )}.${template.category.code}.${template.category.taxType}.${
                    template.paperSize
                  }.${template.noOnCategory}`}</p>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </PrivateBaseLayout>
    </div>
  )
}

export default ChooseTemplateInvoice
