import { useTranslation } from 'react-i18next'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  StyleButtonClose,
  DivFooter,
  DivRowFooter,
} from '../../../TaxDeduction/styled'
import {
  ButtonNotRecommended,
  DivColFooterNotBorder,
  ModalWrapperAlert,
} from 'features/TaxDeductionDocuments/styled'
function AlertTaxDectionDocumentsUpdate(
  {
    titleCancel,
    openModalAlert,
    setOpenModalAlert,
    titleAlert,
    messageAlert,
    titlePrimary,
    onClickPrimary,
    titleButtonNotRecommended,
    onClickButtonNotRecommended,
    colorButtonSave,
    backGroundButtonSave,
    backGroundButtonSaveHover,
    nameActive,
    messageActive1,
    messageActive2,
  },
  props,
) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <ModalWrapperAlert
        open={openModalAlert}
        title={titleAlert}
        onCancel={() => setOpenModalAlert(false)}
        width={450}
        centered
        notBorderTopFoodter
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooterNotBorder
                className="col-12"
                justifyContent={'right'}
              >
                <StyleButtonClose
                  onClick={() => {
                    setOpenModalAlert(false)
                  }}
                >
                  {titleCancel
                    ? titleCancel
                    : t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                {titleButtonNotRecommended && (
                  <ButtonNotRecommended onClick={onClickButtonNotRecommended}>
                    {titleButtonNotRecommended}
                  </ButtonNotRecommended>
                )}
                {titlePrimary && (
                  <ButtonSave
                    type="primary"
                    onClick={onClickPrimary}
                    colorButtonSave={colorButtonSave}
                    backGroundButtonSave={backGroundButtonSave}
                    backGroundButtonSaveHover={backGroundButtonSaveHover}
                  >
                    {titlePrimary}
                  </ButtonSave>
                )}
              </DivColFooterNotBorder>
            </DivRowFooter>
          </DivFooter>
        }
      >
        {nameActive ? (
          <>
            {messageActive1} <b>{nameActive}</b> {messageActive2}
          </>
        ) : (
          messageAlert
        )}
      </ModalWrapperAlert>
    </>
  )
}
export default AlertTaxDectionDocumentsUpdate
