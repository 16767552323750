import { useTranslation } from 'react-i18next'
import { Result, Button } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  TableLayout,
  TdTableLayout,
  DivTableLayout,
  TrTableLayout,
} from 'features/TaxDeductionDocuments/styled'
import ValueContent from './ContentLayout/valueContent'
import ValueImgContent from './ContentLayout/valueImgContent'
function Layout({
  form,
  styleInput,
  itemLayout,
  listContent,
  language,
  height,
  imgLogo,
  setImgLogo,
  formState,
  padding,
  indexLayout,
  setTabActiveKey,
  activeLayout,
  setActiveLayout,
  empty,
  activeFeild,
  setActiveFeild,
  activeTd,
  setActiveTd,
  setVisibleSelectTaxpaper,
  setVisibleAddTaxpaper,
  listIndentify,
  setListIndentify,
  templateDefault,
}) {
  return (
    <TableLayout
      position={itemLayout?.foodter && 'absolute'}
      bottom={itemLayout?.foodter && '15px'}
    >
      <TrTableLayout
        activeLayout={indexLayout == activeLayout.index && !empty}
        empty={empty}
        onClick={() => {
          setActiveLayout({ index: indexLayout, data: itemLayout })
          setTabActiveKey('3')
        }}
      >
        {listContent &&
          listContent.map((item, index) => (
            <TdTableLayout
              key={index}
              width={item.width ?? 'auto'}
              border={'1px solid'}
              height={height}
              padding={padding}
              onClick={() => {
                setActiveTd({ data: item, index })
              }}
            >
              <DivTableLayout
                height={item.height ? `${item.height}px` : `${height}px`}
                width={item.width && `${item.width}px`}
                textAlign={item?.style?.textAlign}
              >
                {item.value && !item.img && !item.label && (
                  <ValueContent
                    styleInput={styleInput}
                    data={item}
                    language={language}
                    textAlign={item?.style?.textAlign}
                    formState={formState}
                    indexTd={index}
                    indexLayout={indexLayout}
                    setTabActiveKey={setTabActiveKey}
                    activeFeild={activeFeild}
                    setActiveFeild={setActiveFeild}
                    activeLayout={activeLayout}
                    itemLayout={itemLayout}
                    activeTd={activeTd}
                    form={form}
                    setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                    setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                    listIndentify={listIndentify}
                    setListIndentify={setListIndentify}
                    templateDefault={templateDefault}
                  />
                )}
                {item.value && item.img && !item.label && (
                  <ValueImgContent
                    form={form}
                    styleInput={styleInput}
                    data={item}
                    language={language}
                    textAlign={item?.style?.textAlign}
                    img={item.img}
                    widthtd={item.width}
                    heighttd={height}
                    imgLogo={imgLogo}
                    setImgLogo={setImgLogo}
                    formState={formState}
                    indexTd={index}
                    indexLayout={indexLayout}
                    setTabActiveKey={setTabActiveKey}
                    activeFeild={activeFeild}
                    setActiveFeild={setActiveFeild}
                    activeLayout={activeLayout}
                    itemLayout={itemLayout}
                    activeTd={activeTd}
                    setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                    setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                    listIndentify={listIndentify}
                    setListIndentify={setListIndentify}
                    templateDefault={templateDefault}
                  />
                )}
              </DivTableLayout>
            </TdTableLayout>
          ))}
      </TrTableLayout>
    </TableLayout>
  )
}
export default Layout
