import { useAuth } from 'app/authSlice'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import KTTooltip from 'general/components/eInvoiceComponents/KTTooltip'
import Empty from 'general/components/Empty'
import Loading from 'general/components/Loading'
import AppData from 'general/constants/AppData'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import { Pie } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import '../styles.scss'

ChartJS.register(ArcElement, Tooltip, Legend)

/**
 *
 * @param {{
 * additionalClassName: string,
 * chartLabels: string[],
 * chartSeries: number[],
 * title: string,
 * chartColors: string[],
 * additionalElement: JSX.Element,
 * loading: boolean,
 * subTitle: string,
 * toolbarElement: JSX.Element,
 * headerSidebar: JSX.Element,
 * }} props
 * @returns
 */
function CardPieChartV2(props) {
  // MARK: --- Params ---
  const {
    additionalClassName,
    chartLabels,
    chartSeries,
    title,
    chartColors = AppData.chartColors,
    loading,
    subTitle,
    headerSidebar,
  } = props
  const { t } = useTranslation()

  const {
    activeInvoice: { systemType },
  } = useAuth()

  return (
    <div className="CardPieChartV2 h-100">
      <div className={`card card-custom card-stretch ${additionalClassName}`}>
        {/* card header */}
        <div className="card-header border-0 d-flex flex-wrap align-items-center justify-content-between">
          <div
            className={`${
              headerSidebar
                ? 'd-flex flex-wrap justify-content-between w-100'
                : ''
            }`}
          >
            {/* card title */}
            <h3 className="card-title">
              <span className="card-label font-weight-bolder font-size-h4 text-dark-75">
                {title}
              </span>
              <span className="d-block text-muted mt-2 font-size-base">
                {subTitle}
              </span>
            </h3>
            <div>{headerSidebar}</div>
          </div>
        </div>

        {/* card body */}
        <div className="card-body d-flex align-items-center justify-content-center flex-wrap">
          <div className="w-100 h-lg-100">
            {loading ? (
              <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                <Loading showBackground={false} />
              </div>
            ) : chartSeries.length > 0 ||
              !chartSeries?.every((item) => item === 0) ? (
              <div className="row">
                <div className="col-lg-6 pl-0">
                  <div className="overflow-auto max-h-300px mt-2 flex-column justify-content-center">
                    {chartLabels.map((item, index) => {
                      return (
                        <KTTooltip
                          key={index}
                          text={`${Utils.formatNumber(chartSeries[index])}`}
                        >
                          <p
                            className={`cursor-pointer d-flex align-items-center ${
                              index === chartLabels.length - 1 ? 'm-0' : 'mb-1'
                            }`}
                          >
                            <i
                              style={{ color: chartColors[index] }}
                              className="fa-solid fa-circle mr-3"
                            ></i>
                            <span>
                              {systemType?.ticket
                                ? item.replace('hoá đơn', 'vé')
                                : item}
                            </span>
                          </p>
                        </KTTooltip>
                      )
                    })}
                  </div>
                </div>
                <div className="col-lg-6" style={{ minHeight: '300px' }}>
                  <Pie
                    options={{
                      devicePixelRatio: 1,
                      maintainAspectRatio: true,
                      plugins: {
                        legend: {
                          display: false,
                          position: 'left',
                          align: 'center',
                          labels: {
                            font: {
                              size: 12,
                            },
                            usePointStyle: true,
                          },
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              return Utils.formatNumber(context.parsed)
                            },
                          },
                        },
                      },
                    }}
                    data={{
                      labels: chartLabels,
                      datasets: [
                        {
                          label: '',
                          data: chartSeries,
                          backgroundColor: chartColors,
                          borderColor: '#fff',
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="pt-12">
                <Empty
                  text={t('NoData')}
                  visible={false}
                  imageEmpty={AppResource.icons.icEmptyBox}
                  imageEmptyPercentWidth={50}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardPieChartV2
