import { createSlice } from '@reduxjs/toolkit'
import AppData from 'general/constants/AppData'
import { useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { shallowEqual } from 'react-redux'
import {
  createTimeRemind,
  deleteAllTimeRemind,
  getAllSystemCurrency,
  getAllSystemFormatNumber,
  getAllSystemInvoiceSetting,
  getAllTimeRemind,
  getDataLog,
  getEmailConfig,
  getEmailTemplate,
  getVATConfig,
  updateEmailConfig,
  updateEmailTemplate,
  updateSystemCurrency,
  updateSystemFormatNumber,
  updateSystemInvoiceSetting,
  updateVATConfig,
} from './asyncThunks'
const SLICE_NAME = 'systemConfig'

const initialState = {
  formatNumber: AppData.default_formatNumber, // Các thông tin định dạng số hệ thống
  currency: AppData.default_systemCurrency, // Các thông tin đơn vị tiền tệ hệ thống
  invoiceSetting: AppData.default_invoiceSetting, // Các thông tin cài đặt hóa đơn hệ thống
  timeRemind: [],
  emailConfig: AppData.default_emailSetting,
  VATConfig: {},
  emailTemplate: [],
  autoDataLog: AppData.default_autoDataLog,
  selectedTemplate: null,
}

const systemConfigSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    /** @param {import('@reduxjs/toolkit').PayloadAction<{name:keyof typeof initialState['formatNumber'], value: any}>} action */
    setFormatNumber: (state, action) => {
      state.formatNumber[action.payload.name] = action.payload.value
    },
    /** @param {import('@reduxjs/toolkit').PayloadAction<{name:keyof typeof initialState['currency'], value: any}>} action */
    setCurrency: (state, action) => {
      state.currency[action.payload.name] = action.payload.value
    },
    /** @param {import('@reduxjs/toolkit').PayloadAction<{name:keyof typeof initialState['invoiceSetting'], value: any}>} action */
    setInvoiceSetting: (state, action) => {
      state.invoiceSetting[action.payload.name] = action.payload.value
    },
    setTimeRemind: (state, action) => {
      state.timeRemind = action.payload
    },
    /** @param {import('@reduxjs/toolkit').PayloadAction<{name: keyof typeof initialState['emailConfig'], value: any}>} action */
    setEmailConfig: (state, action) => {
      state.emailConfig[action.payload.name] = action.payload.value
    },
    setVATConfig: (state, action) => {
      state.VATConfig = action.payload
    },
    setEmailTemplate: (state, action) => {
      state.emailTemplate = action.payload
    },
    /** @param {import('@reduxjs/toolkit').PayloadAction<{name: keyof typeof initialState['autoDataLog'], value: any}>} action */
    setAutoDataLog: (state, action) => {
      state.autoDataLog[action.payload.name] = action.payload.value
    },
    /** @param {import('@reduxjs/toolkit').PayloadAction<keyof typeof initialState>} action */
    resetDefault: (state, action) => {
      state[action.payload] = initialState[action.payload]
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSystemFormatNumber.fulfilled, (state, action) => {
      // Xử lý kết quả thành công của API và cập nhật state
      state.formatNumber = action.payload[0]
    })
    builder.addCase(updateSystemFormatNumber.fulfilled, (state, action) => {
      state.formatNumber = action.payload
    })
    builder.addCase(getAllSystemCurrency.fulfilled, (state, action) => {
      // Xử lý kết quả thành công của API và cập nhật state
      state.currency = action.payload[0]
    })
    builder.addCase(updateSystemCurrency.fulfilled, (state, action) => {
      state.currency = action.payload
    })
    // getAllSystemInvoiceSetting
    builder.addCase(getAllSystemInvoiceSetting.fulfilled, (state, action) => {
      // Xử lý kết quả thành công của API và cập nhật state
      let payload = action.payload[0]
      if (payload.autoSentTime) {
        payload.autoSentTime = payload.autoSentTime.split(',')
      }
      state.invoiceSetting = payload
    })
    builder.addCase(updateSystemInvoiceSetting.fulfilled, (state, action) => {
      state.invoiceSetting = {
        ...action.payload,
        autoSentTime: action.payload.autoSentTime.split(','),
      }
    })
    builder.addCase(getAllTimeRemind.fulfilled, (state, action) => {
      // Xử lý kết quả thành công của API và cập nhật state
      const { result, data } = action.payload
      if (result === 'success') {
        state.timeRemind = data
      }
    })
    // deleteAllTimeRemind
    builder.addCase(deleteAllTimeRemind.fulfilled, (state) => {
      state.timeRemind = []
    })
    // createTimeRemind
    builder.addCase(createTimeRemind.fulfilled, (state, action) => {
      const { result, data } = action.payload
      if (result === 'success') {
        state.timeRemind = data
      }
    })
    // emailConfig
    builder.addCase(getEmailConfig.fulfilled, (state, action) => {
      // Xử lý kết quả thành công của API và cập nhật state
      state.emailConfig = action.payload
    })
    builder.addCase(updateEmailConfig.fulfilled, (state, action) => {
      state.emailConfig = action.payload
    })

    // VATConfig
    builder.addCase(getVATConfig.fulfilled, (state, action) => {
      // Xử lý kết quả thành công của API và cập nhật state
      state.VATConfig = action.payload
    })
    builder.addCase(updateVATConfig.fulfilled, (state, action) => {
      state.VATConfig = action.payload
    })

    // emailTemplate
    builder.addCase(getEmailTemplate.fulfilled, (state, action) => {
      // Xử lý kết quả thành công của API và cập nhật state
      state.emailTemplate = action.payload
    })
    builder.addCase(updateEmailTemplate.fulfilled, (state, action) => {
      const updatedEmailTemplateId = action.payload.emailTemplateId
      const updatedContent = action.payload.content

      state.emailTemplate = state.emailTemplate.map((template) => {
        if (template.emailTemplateId === updatedEmailTemplateId) {
          return {
            ...template,
            content: updatedContent,
          }
        }
        return template
      })
    })
    builder.addCase(getDataLog.fulfilled, (state, action) => {
      const { result, data } = action.payload
      if (result === 'success') {
        state.autoDataLog = data
      }
      if (_.isNil(data)) {
        state.autoDataLog = initialState.autoDataLog
      }
    })
  },
})

export const systemConfigSliceActions = systemConfigSlice.actions

export const useSystemConfigSelector = () =>
  useAppSelector((s) => s[SLICE_NAME], shallowEqual)

export default systemConfigSlice.reducer
