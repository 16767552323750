import { Space, Tag, Typography, theme } from 'antd'
import dayjs from 'dayjs'
// import useAlertMessage from 'hooks/useAlertMessage'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { includes, isEmpty, join, map, split, uniq } from 'lodash'
import { TweenOneGroup } from 'rc-tween-one'
import { useCallback, useEffect, useRef, useState } from 'react'
import CustomAntInput from '../Forms/CustomAntInput/CustomAntInput'

/**
 * A component that allows users to add and remove tags with a customizable delimiter.
 *
 * @param {Object} props - The component props.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {string} props.defaultValue - The default value for the input field.
 * @param {function} props.setFieldValue - A function to set the value of the field in the parent form.
 * @param {string} [props.name] - The name of the field in the parent form.
 * @param {RestrictionType} [props.restriction] - An object containing the type of restriction and the validation schema.
 * Will force the input to be the first date format in the array. Exp: ['HH:mm', 'H:m'] => 'HH:mm'
 * @param {string} props.delimiterString - The delimiter string to use for separating tags. Exp: ';'
 * @param {PillColorProps} [props.pillColor] - The color of the tag pills.
 * @returns {JSX.Element} The Tagnify component.
 */

export default function Tagnify({
  placeholder,
  defaultValue,
  setFieldValue,
  name,
  restriction,
  delimiterString,
  pillColor,
}) {
  const { token } = theme.useToken()
  // const { openAlert, contextHolder } = useAlertMessage()
  const [tags, setTags] = useState([])
  const [inputCreateNewHidden, setInputCreateNewHidden] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)

  const handleCheckRestrictionDate = useCallback(
    (hasToken) => {
      try {
        let isValidTime = false
        if (hasToken) {
          const inputArray = split(inputValue, delimiterString).reduce(
            (prev, curr) => {
              const currTrim = curr.trim()
              return currTrim ? [...prev, currTrim] : prev
            },
            [],
          )
          isValidTime = inputArray.some((input) =>
            dayjs(input, restriction?.dateFormat, true).isValid(),
          )
          if (!isValidTime) throw new Error('Định dạng không hợp lệ')
          const validInputArray = inputArray.map((input) =>
            dayjs(input, restriction?.dateFormat).format(
              restriction?.dateFormat[0],
            ),
          )
          setTags((prev) => uniq([...prev, ...validInputArray]))
        } else {
          isValidTime = dayjs(
            inputValue,
            restriction?.dateFormat,
            true,
          ).isValid()
          if (!isValidTime) throw new Error('Định dạng không hợp lệ')
          const validInput = dayjs(inputValue, restriction?.dateFormat).format(
            restriction?.dateFormat[0],
          )
          setTags((prev) => uniq([...prev, validInput]))
        }
      } catch (error) {
        ToastHelper.showError(error.message)
      }
    },
    [delimiterString, inputValue, restriction?.dateFormat],
  )

  const handleCheckRestriction = useCallback(
    (hasToken) => {
      try {
        if (hasToken) {
          const inputArray = split(inputValue, delimiterString).reduce(
            (prev, curr) => {
              const currTrim = curr.trim()
              return currTrim ? [...prev, currTrim] : prev
            },
            [],
          )
          inputArray.some((input) =>
            restriction?.validationSchema.validateSync(input),
          )
          setTags((prev) => uniq([...prev, ...inputArray]))
        } else {
          restriction?.validationSchema.validateSync(inputValue)
          setTags((prev) => uniq([...prev, inputValue]))
        }
      } catch (error) {
        ToastHelper.showError(error.message)
      }
    },
    [delimiterString, inputValue, restriction?.validationSchema],
  )
  const handleCreateInput = useCallback(() => {
    setInputCreateNewHidden(false)
    setInputValue('')
    if (!inputValue) return null
    const hasToken = includes(inputValue, delimiterString)

    switch (restriction.type) {
      case 'datetime':
        handleCheckRestrictionDate(hasToken)
        break
      case 'text':
        handleCheckRestriction(hasToken)
        break
      default:
        ToastHelper.showError('Kiểu dữ liệu không hợp lệ')
        break
    }
  }, [
    delimiterString,
    handleCheckRestriction,
    handleCheckRestrictionDate,
    inputValue,

    restriction.type,
  ])

  useEffect(() => {
    if (inputCreateNewHidden) {
      inputRef.current?.focus()
    }
  }, [inputCreateNewHidden])

  useEffect(() => {
    !isEmpty(tags) && setFieldValue(name, join(tags, delimiterString))
  }, [delimiterString, name, setFieldValue, tags])

  useEffect(() => {
    !isEmpty(defaultValue) &&
      setTags(defaultValue ? split(defaultValue, delimiterString) : [])
  }, [defaultValue, delimiterString])

  return (
    <Space
      direction="vertical"
      style={{ width: '100%' }}
      styles={{
        item: {
          flexGrow: 1,
        },
      }}
    >
      {isEmpty(tags) ? null : (
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
          }}
          leave={{
            opacity: 0,
            scale: 0,
            duration: 200,
          }}
          appear={false}
          exclusive={true}
        >
          {map(tags, (tag) => (
            <Tag
              className={Utils.cn(
                'badge',
                pillColor ? `badge-light-${pillColor}` : 'badge-light-primary',
                'mx-1 my-1',
              )}
              key={tag}
              closable
              closeIcon={
                <i
                  className="fa-solid fa-times"
                  style={{
                    color: token.colorErrorText,
                    marginLeft: '0.5rem',
                  }}
                />
              }
              onClose={() =>
                setTags((prev) => prev.filter((item) => item !== tag))
              }
            >
              {tag}
            </Tag>
          ))}
        </TweenOneGroup>
      )}
      {inputCreateNewHidden ? (
        <CustomAntInput
          ref={inputRef}
          type="text"
          width={'100%'}
          borderRadius="sm"
          inputProps={{
            placeholder,
            value: inputValue,
            onChange: (e) => setInputValue(e.target.value),
            onBlur: handleCreateInput,
            onPressEnter: handleCreateInput,
          }}
        />
      ) : (
        <Tag
          tabIndex={0}
          onClick={() => setInputCreateNewHidden(true)}
          style={{
            background: token.colorBgContainer,
            borderRadius: token.borderRadiusSM,
            padding: `0.5rem 1rem`,
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <i className="fa-regular fa-plus " />
          <Typography.Text
            style={{
              fontWeight: '500',
              marginLeft: '0.5rem',
              color: token.colorTextPlaceholder,
            }}
          >
            {placeholder}
          </Typography.Text>
        </Tag>
      )}
      {/* {contextHolder} */}
    </Space>
  )
}
