import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import useCheckPermission from 'hooks/useCheckPermission'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import CreateRegistration from './screens/CreateRegistration'
import ListRegistration from './screens/ListRegistration'

function Registration() {
  // MARK: --- Params ---
  const { t } = useTranslation()

  const { isLoadingPermission, canViewList, canCreate, canEdit } =
    useCheckPermission([
      {
        keyAction: 'canViewList',
        permissionCode: 'TOKHAI_VIEW_LIST',
      },
      {
        keyAction: 'canCreate',
        permissionCode: 'TOKHAI_ADD',
      },
      {
        keyAction: 'canEdit',
        permissionCode: 'TOKHAI_EDIT',
      },
    ])
  return (
    <Routes>
      <Route
        path=""
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={canViewList}
          >
            <ListRegistration />
          </ProtectedRoute>
        }
      />
      <Route
        path="tao"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={canCreate}
          >
            <CreateRegistration />
          </ProtectedRoute>
        }
      />
      <Route path="xem/:registrationId" element={<CreateRegistration />} />
      <Route
        path="sua/:registrationId"
        element={
          <ProtectedRoute isLoading={isLoadingPermission} permission={canEdit}>
            <CreateRegistration />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                imageEmptyPercentWidth={30}
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Registration
