import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import statisticTicketApi from 'api/statisticTicketApi'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomAntTable from 'general/components/CustomAntTable'
import {
  calcMaxCellWidth,
  generateCellObject,
  generateCellStyle,
} from 'general/helpers/ExcelsHelper'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import { get } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { utils, writeFile } from 'xlsx-js-style'
import CustomFilter from './Components/CustomFilter'
import $ from 'jquery'

const { Body, Header } = ContentContainer

export default function ListTicketPublishedScreen() {
  const { t } = useTranslation()
  const [filter, setFilter] = useFilter(
    'ticketPublished',
    'gFilterTicketPublished',
  )

  const {
    data: listData,
    isFetching: isGettingList,
    refetch: refreshList,
  } = useQuery({
    queryKey: ['numberTicketReleased', filter],
    queryFn: () => statisticTicketApi.find(filter),
    select: (res) => ({
      rows: get(res, 'invoiceTemplateStatisticTickets', []),
      total: get(res, 'total', 0),
    }),
  })

  const columns = useMemo(() => {
    /**
     * @type {import('antd/es/table').ColumnsType<ReturnTicketPublishedData>}
     */
    const arr = [
      {
        title: 'STT',
        key: 'stt',
        render: (_text, _record, index) => index + 1,
        align: 'center',
      },
      {
        title: 'Tên loại vé',
        key: 'name',
        dataIndex: 'name',
        align: 'center',
      },
      {
        title: 'Ký hiệu',
        key: 'serial',
        align: 'center',

        render: (_text, record) =>
          `${record.form}${record.hasTaCode}${record.year}${record.type}${record.managementCode}`,
      },
      {
        title: 'Số lượng vé đã sử dụng, huỷ đăng ký',
        align: 'center',
        children: [
          {
            title: 'Tổng số',
            key: 'countTicketTotal',
            dataIndex: 'countTicketTotal',
            align: 'center',
          },
          {
            title: 'Số lượng đã sử dụng',
            key: 'countTicketUse',
            dataIndex: 'countTicketUse',
            align: 'center',
          },
          {
            title: 'Số lượng huỷ',
            key: 'countTicketCancel',
            dataIndex: 'countTicketCancel',
            align: 'center',
          },
        ],
      },
    ]
    return arr
  }, [])

  const handleExportExcel = useCallback(() => {
    const headerStyle = generateCellStyle({
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
      isTextWrap: true,
      font: {
        bold: true,
      },
    })

    const cellStyle = generateCellStyle({
      alignment: { horizontal: 'center', vertical: 'center' },
      isTextWrap: true,
    })

    const headers = columns.map((col) =>
      generateCellObject(col.title, 's', headerStyle),
    )
    const subHeaders = columns.flatMap((col) =>
      col?.children
        ? col.children.map((subCol) =>
            generateCellObject(subCol.title, 's', headerStyle),
          )
        : generateCellObject('', 'z'),
    )
    const keys = columns.flatMap((col) =>
      col?.children ? col.children.map((child) => child.key) : col.key,
    )
    const cells = listData?.rows.map((row, rowIdx) =>
      keys.map((key) =>
        generateCellObject(
          key === 'stt'
            ? rowIdx + 1
            : key === 'serial'
              ? `${row.form}${row.hasTaCode}${row.year}${row.type}${row.managementCode}`
              : row[key],
          key === 'stt' ? 'n' : 's',
          cellStyle,
        ),
      ),
    )
    const aoa = [headers, subHeaders, ...cells]
    const newBook = utils.book_new()
    const ws = utils.aoa_to_sheet(aoa, {
      sheetStubs: true,
      cellStyles: true,
    })
    // Merge cells
    for (let i = 0; i < headers.length; i++) {
      if (subHeaders[i].v === '') {
        // if the subHeader cell is empty
        if (!ws['!merges']) ws['!merges'] = []
        ws['!merges'].push({ s: { r: 0, c: i }, e: { r: 1, c: i } })
      }
      if (columns[i].children) {
        // if the column has children
        if (!ws['!merges']) ws['!merges'] = []
        ws['!merges'].push({
          s: { r: 0, c: i },
          e: { r: 0, c: i + columns[i].children.length - 1 },
        })
      }
    }
    ws['!cols'] = calcMaxCellWidth(aoa, ws['!ref'], 20)
    utils.book_append_sheet(newBook, ws, Utils.cn('sheet1'))
    writeFile(newBook, 'Thống kê số lượng vé đã phát hành.xlsx')
  }, [columns, listData?.rows])

  useEffect(() => {
    if (isGettingList) return
    $('.custom-row-position')
      .toArray()
      .forEach((row) => {
        const $tds = $(row).find('td')
        if ($tds.length === 0) return
        const tds = $tds.toArray()
        /** @type {import('react').CSSProperties} */
        const styles = {
          textAlign: 'start',
        }
        $(tds[0]).css(styles) // STT
        $(tds[1]).css(styles) // Tên loại vé
      })
  }, [isGettingList])

  return (
    <ContentContainer>
      <Header
        titleContent={'Thống kê số lượng vé đã phát hành'}
        toolBar={
          <Space>
            <CustomAntButton
              iconCn="fa-regular fa-download"
              text={t('ExportExcel')}
              antProps={{
                type: 'primary',
                className: 'btn btn-success',
                onClick: handleExportExcel,
              }}
            />
          </Space>
        }
      >
        <CustomFilter onApply={refreshList} />
      </Header>
      <Body>
        <CustomAntTable
          rowClassName="custom-row-position"
          columns={columns}
          dataSource={listData?.rows}
          isLoading={isGettingList}
          scroll={{
            y: 'calc(100vh - 40vh)',
          }}
        />
      </Body>
    </ContentContainer>
  )
}
