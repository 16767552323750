import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import StringUtils from 'general/utils/StringUtils'

KTFormSearchDropDown.propTypes = {
  // required
  name: PropTypes.string.isRequired,

  // optional
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isCustom: PropTypes.bool,
  multiple: PropTypes.bool,
  rows: PropTypes.number,
  showValidState: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  enableCheckValid: PropTypes.bool,
  isTouched: PropTypes.bool,
  isValid: PropTypes.bool,
  feedbackText: PropTypes.string,
  haveSearchBar: PropTypes.bool,
  noChoice: PropTypes.bool,
  noSearchBar: PropTypes.bool,
}

KTFormSearchDropDown.defaultProps = {
  options: [],
  value: '',
  text: '',
  disabled: false,
  isCustom: false,
  multiple: false,
  rows: 1,
  showValidState: false,

  onChange: null,
  onBlur: null,
  onFocus: null,
  enableCheckValid: false,
  isValid: true,
  isTouched: false,
  feedbackText: '',
  haveSearchBar: false,
  noChoice: false,
  noSearchBar: false,
}

/**
 *
 * @param {{
 * options: {name: string, value: string}[]
 * name: string,
 * value: string,
 * text: string,
 * disabled: boolean,
 * isCustom: boolean,
 * size: string,
 * multiple: boolean,
 * rows: number,
 * showValidState: boolean,
 * onChange: function,
 * onBlur: function,
 * onFocus: function,
 * enableCheckValid: boolean,
 * isValid: boolean,
 * isTouched: boolean,
 * feedbackText: string,
 * haveSearchBar: boolean,
 * noChoice: boolean,
 * noSearchBar: boolean,
 * }} props
 * @returns
 */
function KTFormSearchDropDown(props) {
  // MARK: --- Params ---
  const {
    name,

    options,
    value,
    text,
    disabled,
    isCustom,
    size,
    multiple,
    rows,
    showValidState,

    onChange,
    onBlur,
    onFocus,
    enableCheckValid,
    isValid,
    isTouched,
    feedbackText,
    haveSearchBar,
    noChoice,
    noSearchBar,
  } = props
  const { t } = useTranslation()
  const [label, setLabel] = useState(options[0]?.name)

  // MARK: --- Functions ---
  function handleChange(targetValue) {
    if (onChange) {
      onChange(targetValue)
    }
  }

  function handleBlur() {
    if (onBlur) {
      onBlur()
    }
  }

  function handleFocus() {
    if (onFocus) {
      onFocus()
    }
  }

  // MARK --- Hooks ---
  useEffect(() => {
    if (value) {
      setLabel(options.find((item) => item.value == value)?.name)
    }
  }, [value, options])

  const [optionsProcessed, setOptionsProcessed] = useState()

  const [textSearch, setTextSearch] = useState('')

  const [optionsRaw, setOptionsRaw] = useState([])

  useEffect(() => {
    if (noChoice) {
      const modifiedOptions = [...options, { name: 'Không chọn', value: null }]
      setOptionsRaw(modifiedOptions)
    } else setOptionsRaw(options)
  }, [options])

  useEffect(() => {
    if (!textSearch) {
      // Nếu textSearch rỗng, hiển thị toàn bộ các phần tử
      setOptionsProcessed(optionsRaw)
    } else {
      // Lọc các phần tử theo điều kiện
      const filteredOptions = optionsRaw.filter((item) =>
        StringUtils.removeVietnameseTones(item.name.toLowerCase()).includes(
          StringUtils.removeVietnameseTones(textSearch.toLowerCase()),
        ),
      )
      setOptionsProcessed(filteredOptions)
    }
  }, [textSearch, optionsRaw])

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          disabled={disabled}
          variant=""
          className={`
                    form-control custom-dropdown-toggle d-flex justify-content-between border
                    ${
                      isCustom
                        ? 'custom-select shadow-none'
                        : `form-select ${size}`
                    }
                    ${
                      enableCheckValid && isTouched
                        ? `${
                            isValid
                              ? `${showValidState ? 'is-valid' : ''}`
                              : 'is-invalid'
                          }`
                        : ''
                    }
                `}
          id="dropdown-basic"
        >
          <span style={{ color: '#3f4254' }}>{label}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className=" max-h-250px w-100">
          {noSearchBar == false && (
            <div className="w-100 pr-3 pl-3">
              <input
                className="w-100"
                style={{
                  height: '35px',
                  border: '2px solid #000',
                  borderRadius: '3px',
                  outline: 'none',
                }}
                type="text"
                value={textSearch}
                onChange={(event) => {
                  setTextSearch(event.target.value)
                }}
              />
            </div>
          )}
          <div className="overflow-auto max-h-200px w-100">
            {optionsProcessed &&
              optionsProcessed.map((item, index) => {
                if (item.name)
                  return (
                    <Dropdown.Item
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        color:
                          !!value && value === item.value
                            ? '#5d95de'
                            : 'rgb(63, 66, 84)',
                      }}
                      key={index}
                      onClick={() => {
                        handleChange(item.value)
                        setLabel(item.name)
                      }}
                    >
                      {t(item.name)}
                      {!!value && value === item.value && (
                        <i className="text-success fa-solid fa-check ml-4"></i>
                      )}
                    </Dropdown.Item>
                  )
              })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {enableCheckValid &&
        !isValid &&
        isTouched &&
        !_.isEmpty(feedbackText) && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{feedbackText}</div>
          </div>
        )}
      {!_.isEmpty(text) && <span className="form-text text-muted">{text}</span>}
    </div>
  )
}

export default KTFormSearchDropDown
