import UserHelper from 'general/helpers/UserHelper'
import { Outlet } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import GuestBaseLayout from '../eInvoiceComponents/BaseLayout/GuestBaseLayout'
import { DescriptionText, LoginHomeScreen, Title } from './style.js'

function GuestRoute() {
  const isAuth = UserHelper.checkAccessTokenValid()

  return !isAuth ? (
    <GuestBaseLayout nonPadding>
      <LoginHomeScreen>
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-center">
          <div className="col-12 col-lg-7 d-flex flex-column justify-content-center">
            <Title>Giải pháp hóa đơn điện tử an toàn và thông minh</Title>
            <DescriptionText>
              Trải nghiệm sử dụng hóa đơn theo cách hoàn toàn mới. Chúng tôi tin
              tưởng bạn sẽ đạt được nhiều hơn những gì mong đợi. Hãy bắt đầu
              trải nghiệm ngay
            </DescriptionText>
          </div>
          <div className="col-11 py-10 py-lg-0 col-sm-8 col-lg-5 col-xxl-5 col-md-6">
            <Outlet />
          </div>
        </div>
      </LoginHomeScreen>
    </GuestBaseLayout>
  ) : (
    <Navigate to="/" />
  )
}

export default GuestRoute
