import { useTranslation } from 'react-i18next'
import AppResource from 'general/constants/AppResource'
import { Empty } from 'antd'
import { downloadZip, embedPDF, extractFields } from 'general/utils/Utils'
import { useEffect, useMemo, useState } from 'react'
import {
  ButtonSave,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalWrapper,
  StyleButtonClose,
  DivColBackGround,
  DivRowBackGround,
  DivColFrame,
  DivCenter,
} from 'features/TaxDeduction/styled'
function ModalBorderBackground(
  { form, visibleModalBorder, setVisibleModalBorder, setChonseBorder },
  props,
) {
  const { t } = useTranslation()
  const [activeDiv, setActiveDiv] = useState()
  //   useEffect(() => {}, [visibleModalBorder])
  useMemo(() => {
    setActiveDiv(null)
  }, [visibleModalBorder])

  return (
    <>
      <ModalWrapper
        // title={t('taxDeduction.registrationFile')}
        open={visibleModalBorder}
        onCancel={() => setVisibleModalBorder(false)}
        width={750}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    setVisibleModalBorder(false)
                  }}
                >
                  {t('taxDeduction.closeModal')}
                </StyleButtonClose>
                <ButtonSave type="primary" onClick={() => {}}>
                  {t('taxDeductionDocuments.chonse')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <DivRowBackGround className="row">
          {Object.entries(AppResource.borderBackground).map(([key, value]) => (
            <DivCenter
              key={key}
              className={`col-sm-4`}
              onClick={() => {
                if (activeDiv && activeDiv == value.key) {
                  setChonseBorder(value.key)
                  setVisibleModalBorder(false)
                  form.setFieldsValue({
                    borderBackground: value.key,
                  })
                }
                setActiveDiv(value.key)
              }}
            >
              <DivColFrame
                className={`${activeDiv == value.key ? 'active' : ''}`}
              >
                <DivColBackGround
                  urlBackGroupImage={value?.url}
                ></DivColBackGround>
              </DivColFrame>
            </DivCenter>
          ))}
        </DivRowBackGround>
      </ModalWrapper>
    </>
  )
}
export default ModalBorderBackground
