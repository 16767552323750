import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import departmentApi from 'api/departmentApi'

export const thunkDepartment = createAsyncThunk(
  'department/getAll',
  async (param, thunkApi) => {
    try {
      const res = await departmentApi.getAll(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkDepartmentById = createAsyncThunk(
  'department/getById',
  async (param, thunkApi) => {
    try {
      const res = await departmentApi.getById(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkSaveDepartment = createAsyncThunk(
  'department/save',
  async (body, thunkApi) => {
    try {
      const res = await departmentApi.save(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkRemoveDepartmentById = createAsyncThunk(
  'department/remove',
  async (param, thunkApi) => {
    try {
      const res = await departmentApi.remove(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
const reportSlice = createSlice({
  name: 'department',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkDepartment.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(thunkDepartment.fulfilled, (state, action) => {
        state.loading = false
        state.processes = action.payload // Cập nhật state với dữ liệu trả về
      })
  },
})

const { reducer } = reportSlice
export default reducer
