import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import templateInvoiceApi from 'api/templateInvoiceApi'
import { setAppSpinning } from 'app/appSlice'
import TemplateInvoiceHelper from 'general/helpers/TemplateInvoiceHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'

const keyFactory = {
  base: {
    scope: ['templateInvoice', 'listTemplateInvoice'],
  },
  list: () => {
    return {
      ...keyFactory.base,
      entity: ['invoiceTemplate'],
    }
  },
  listAll: (query, param) => {
    return [
      {
        ...keyFactory.base,
        params: {
          ...param,
          q: query,
        },
      },
    ]
  },
  viewPdf: () => {
    return [
      {
        ...keyFactory.base,
        action: 'viewPdf',
      },
    ]
  },
  exportTemplate: () => {
    return [
      {
        ...keyFactory.base,
        action: 'exportTemplate',
      },
    ]
  },
  deleteTemplate: () => {
    return [
      {
        ...keyFactory.base,
        action: 'deleteTemplate',
      },
    ]
  },
}
// Query
export const useQueryTemplateInvoice = (query, param) => {
  const { users } = useAppSelector((s) => s.userList)

  return useQuery({
    staleTime: 1000 * 60, // stay fresh for 1 minute
    queryKey: keyFactory.listAll(query, param),
    queryFn: ({ signal }) =>
      templateInvoiceApi.getTemplates({ ...param, q: query }, signal),
    select: (res) => {
      return {
        total: _.get(res, 'total', 0),
        templates: _.isNil(res?.templates)
          ? []
          : res.templates.map((template, index) => ({
              ...template,
              stt: index + 1,
              form: TemplateInvoiceHelper.displayForm(
                template.form,
                template.type,
              ),
              categoryTitle: template.category?.title,
              taxTypeTitle: TemplateInvoiceHelper.displayTaxType(
                template.category?.taxType,
              ),
              serial: TemplateInvoiceHelper.generateSerial(template),
              directTranfer: TemplateInvoiceHelper.displayDirectTranfer(
                template.directTranfer,
              ),
              createdByAccount:
                users.accounts.find(
                  (acc) => acc.accountId === template.createdByAccountId,
                )?.fullname ?? '',
            })),
      }
    },
  })
}

// Mutation
export const useMutationViewPdf = (onSuccess) => {
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.viewPdf(),
    mutationFn: (id) => templateInvoiceApi.exportTemplateById(id),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess,
  })
}

export const useMutationExportTemplate = (id) => {
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: keyFactory.exportTemplate(),
    mutationFn: (exportOptions) => {
      if (!id) return
      return templateInvoiceApi.exportTemplateById(id, exportOptions)
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      const url = URL.createObjectURL(res)
      const link = document.createElement('a')
      link.href = url
      link.download = 'Mau_hoa_don.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
  })
}

export const useMutationDeleteTemplate = () => {
  const dispatch = useAppDispatch()
  const { invalidate } = useInvalidateListTemplateInvoice()
  return useMutation({
    mutationKey: keyFactory.deleteTemplate(),
    mutationFn: (id) => templateInvoiceApi.deleteTemplate(id),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: () => {
      ToastHelper.showSuccess('Xóa mẫu hóa đơn thành công')
      invalidate()
    },
  })
}

export const useMutationChangeActive = () => {
  const dispatch = useAppDispatch()
  const { invalidate } = useInvalidateListTemplateInvoice()

  return useMutation({
    mutationKey: ['templateInvoice', 'updateTemplate'],
    mutationFn: ({ id, active }) =>
      templateInvoiceApi.updateTemplate(id, { active }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: () => {
      ToastHelper.showSuccess('Cập nhật trạng thái thành công')
      invalidate()
    },
  })
}

// Invalidate
export const useInvalidateListTemplateInvoice = () => {
  const queryClient = useQueryClient()
  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: [keyFactory.list()],
      }),
  }
}
