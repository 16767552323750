import { unwrapResult } from '@reduxjs/toolkit'
import { thunkGetAccountProfile } from 'app/authSlice'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { shallowEqual } from 'react-redux'

export default function ResourceStatistics() {
  const sysType = useAppSelector((state) => state.auth.activeInvoice)
  const dispatch = useDispatch()

  const [items, setItems] = useState([])
  const resource_invoice = useAppSelector(
    (state) => state.auth.resource?.invoice,
    shallowEqual,
  )
  const resource_ticket = useAppSelector(
    (state) => state.auth.resource?.ticket,
    shallowEqual,
  )

  const resource_tncn = useAppSelector(
    (state) => state.auth.resource?.tncn,
    shallowEqual,
  )

  const resetResouce = async () => {
    return await dispatch(thunkGetAccountProfile())
  }

  useMemo(() => {
    resetResouce()
  }, [])

  useMemo(() => {
    setItems(
      [
        {
          title: sysType.invoice
            ? 'Số hoá đơn còn lại'
            : sysType.ticket
              ? 'Số vé còn lại'
              : 'Số chứng từ còn lại',
          // hidden: sysType.personalIncomeTax,
          value: sysType.invoice
            ? resource_invoice?.quantity
            : sysType.ticket
              ? resource_ticket?.quantity
              : resource_tncn?.quantity,
          type: 'number',
        },
        {
          title: 'Thời hạn sử dụng',
          hidden: !sysType.personalIncomeTax,
          value: sysType.invoice
            ? resource_invoice?.dateExpired
            : sysType.ticket
              ? resource_ticket?.dateExpired
              : resource_tncn?.dateExpired,
        },
      ],
      [
        resource_invoice?.dateExpired,
        resource_invoice?.quantity,
        resource_ticket?.dateExpired,
        resource_ticket?.quantity,
        resource_tncn?.dateExpired,
        resource_tncn?.quantity,
        sysType.invoice,
        sysType.ticket,
        sysType.tncn,
      ],
    )
  }, [sysType])

  return (
    <div className="px-3 pb-2 aside-support">
      {items.map((item, index) => (
        <div
          key={index}
          className={Utils.cn(
            ' flex-column text-white',
            item.hidden ? 'd-none' : 'd-flex',
          )}
        >
          <span className="fs-6">{`${item.title}:`}</span>
          <span className="fw-semibold fs-5">
            {item.type === 'number'
              ? Utils.formatNumber(item.value ?? 0)
              : item.value}
          </span>
        </div>
      ))}
    </div>
  )
}
