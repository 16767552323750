import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './style.scss'
import dayjs from 'dayjs'

ModalChooseAdjustTicketToCreateReport.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
}

function ModalChooseAdjustTicketToCreateReport(props) {
  const { show, setShow, activeTicket, handleChooseAdjustTicket } = props

  let handleClose = () => setShow(false)

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="ModalChooseAdjustTicketToCreateReport"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div>
          <p className="text-title">Xuất vé điều chỉnh</p>
          <p className="text-sub-title">
            Cho vé số: <span className="text-primary">{activeTicket?.no}</span>,
            ngày{' '}
            <span className="text-dark">
              {activeTicket?.dateRelease &&
                dayjs(activeTicket.dateRelease).format('DD/MM/YYYY')}
            </span>
            , ký hiệu <span className="text-dark">{activeTicket?.serial}</span>
          </p>
          <p className="my-5">
            Dưới đây là vé điện tử đã xuất điều chỉnh cho vé này, hãy chọn vé để
            thực hiện đính kèm biên bản điện tử
          </p>

          <div className="d-flex flex-row">
            {activeTicket?.TicketRelated?.map((adjustTicket) => (
              <div className="adjust-item">
                <span className="text-dark">{adjustTicket.serial}</span>
                <span className="text-primary cursor-pointer">
                  {adjustTicket.no}
                </span>
                <span
                  className="text-primary cursor-pointer mt-1"
                  onClick={() => {
                    handleChooseAdjustTicket(adjustTicket)
                    setShow(false)
                  }}
                >
                  Chọn vé
                </span>
              </div>
            ))}

            <div className="adjust-item cursor-pointer">
              <span className="text-dark"></span>
              <span className="text-primary">
                <i className="fa-solid fa-plus text-primary"></i>
              </span>
              <span className="text-primary mt-1">Xuất vé mới</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button className="btn btn-danger px-8" onClick={handleClose}>
            Đóng
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalChooseAdjustTicketToCreateReport
