import { queryOptions } from '@tanstack/react-query'
import checkTicketPortalApi from 'api/checkTicketPortalApi'

export const ticketCheckPointQueries = {
  base: {
    scope: ['category', 'ticket', 'checkPoint'],
  },
  lists: (params) =>
    queryOptions({
      queryKey: [ticketCheckPointQueries.base, params],
      queryFn: () => checkTicketPortalApi.find(params),
    }),
}
