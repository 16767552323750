import { queryOptions } from '@tanstack/react-query'
import registrationApi from 'api/registrationApi'
import _ from 'lodash'

export const queries = {
  base: {
    scope: ['registration'],
  },
  listRegistration: (params) =>
    queryOptions({
      queryKey: [queries.base, { entity: 'list' }, params],
      queryFn: ({ signal }) => registrationApi.getRegistrations(params, signal),
      select: (result) => {
        return {
          total: _.get(result, 'total', 0),
          registrations: result?.registrations
            ? _.map(result?.registrations, (reg, idx) => ({
                ...reg,
                id: idx + 1,
              }))
            : [],
        }
      },
    }),
  getDetail: (registrationId) =>
    queryOptions({
      queryKey: [queries.base, { entity: 'detail' }, registrationId],
      queryFn: ({ signal }) => {
        if (registrationId) {
          return registrationApi.getDetail(registrationId, signal)
        }
        return registrationApi.getPrototype(signal)
      },
      select: (res) => res?.registration ?? res?.metadata?.registration,
    }),
}
