import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import deductionDocumentsApi from 'api/deductionDocumentsApi'

export const thunkFindDeductionDocuments = createAsyncThunk(
  'deductionDocuments/find',
  async (params, thunkApi) => {
    try {
      const res = await deductionDocumentsApi.find(params)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)

const reportSlice = createSlice({
  name: 'deductionDocuments',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkFindDeductionDocuments.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(thunkFindDeductionDocuments.fulfilled, (state, action) => {
        state.loading = false
        state.processes = action.payload // Cập nhật state với dữ liệu trả về
      })
      .addCase(thunkFindDeductionDocuments.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload // Lưu lỗi vào state
      })
  },
})

const { reducer } = reportSlice
export default reducer
