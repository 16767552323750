import { Typography } from 'antd'
import styles from './Label.module.css'

/**
 * A custom label component.
 * @param {Object} props - The props object.
 * @param {string} props.text - The label text.
 * @param {string} props.htmlFor - The ID of the form element the label is for.
 * @param {boolean} props.isRequired - Whether the label is required or not.
 * @param {string | number} [props.fontSize] - The font size of the label.
 * @param {string | number} [props.fontWeight] - The font weight of the label.
 * @param {string | number} [props.width] - The width of the label.
 * @returns {JSX.Element} - The label component.
 */
export default function CustomLabel({
  text,
  htmlFor,
  isRequired,
  fontSize,
  fontWeight,
  width,
}) {
  return (
    <label
      htmlFor={htmlFor}
      className={`${isRequired ? styles.required : ''} form-label`}
      style={{
        fontSize: fontSize || '14px',
        fontWeight: fontWeight || 'normal',
        width: width ?? '150px',
        margin: 'auto',
      }}
    >
      <Typography.Text>{text}</Typography.Text>
    </label>
  )
}
