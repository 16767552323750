import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useRedux'
import {
  DivColCustom,
  LabelTitle,
  DivContainerUpdate,
} from 'features/Taxpayer/styled'
import {
  CheckboxCumstom,
  CheckboxCustom,
  DatePickerCustom,
  DivFlex,
  DivFlexCustom,
  FormCustom,
  InputCustom,
  InputCustomGY,
  RadioCustom,
  RedStar,
  SelectCustom,
} from 'features/TaxDeductionDocuments/styled'
import { Checkbox, Empty, Select } from 'antd'
import SelectOptions from 'general/constants/SelectOptions'
import { StyleButtonClose } from 'features/TaxDeduction/styled'
import { BookOutlined } from '@ant-design/icons'
import useMutationGetCompanyInfo from 'hooks/Mutations/useMutationGetCompanyInfo'
import ToastHelper from 'general/helpers/ToastHelper'
import {
  REGEX_CMND_CCCD,
  REGEX_EMAIL_MAX,
  REGEX_IDENTIFY,
  REGEX_MST,
  REGEX_PHONE_NUMBER,
  regexLong,
} from 'general/constants/AppRegex'
import { useEffect, useState } from 'react'
import {
  convertToOptions,
  convertToOptionsValueString,
  findObjectInArray,
  getNonEmptyValues,
  removeAccents,
} from 'general/utils/Utils'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { listGender } from 'general/constants/AppConstants'
import { unwrapResult } from '@reduxjs/toolkit'
import { thunkCity } from 'features/City/citySlice'
import { thunkCountry } from 'features/Country/countrySlice'
import { thunkNationlity } from 'features/Nationlity/nationlitySlice'
import AppData from 'general/constants/AppData'
import { thunkDistrict } from 'features/District/districtSlice'
import { thunkWard } from 'features/Ward/wardSlice'
import { thunkDepartment } from 'features/Department/departmentSlice'
import { thunkJobposition } from 'features/Jobposition/jobpositionSlice'
import { thunkJobtitle } from 'features/JobTitle/jobTitleSlice'
import { thunkAutomaticallyTaxpayer } from 'features/Taxpayer/taxpayerSlice'
function Update({ form, formState, setFormState, save, taxpayersId, popup }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dataDefaultAddress = {
    custom: null,
    ward: null,
    district: null,
    city: null,
    country: null,
  }
  const options = [
    {
      label: '',
      value: 1,
    },
  ]
  const AddressCurrent = 'AddressCurrent'
  const AddressCodeLever = 'AddressCodeLever'
  const [disabledTaxCode, setDisabledTaxCode] = useState(false)
  const [listCity, setListCity] = useState([])
  const [listDistrict, setListDistrict] = useState([])
  const [listWard, setListWard] = useState([])
  const [listCityACurrent, setListCityACurrent] = useState([])
  const [listDistrictACurrent, setListDistrictACurrent] = useState([])
  const [listWardACurrent, setListWardACurrent] = useState([])
  const [listCountry, setListCountry] = useState([])
  const [listDepartment, setListDepartment] = useState([])
  const [listNationality, setListNationality] = useState([])
  const [listJobposition, setListJobposition] = useState([])
  const [listJobtitle, setListJobtitle] = useState([])
  const [listCityCodeLever, setListCityCodeLever] = useState([])
  const [address, setAddress] = useState(dataDefaultAddress)
  const [addressCurent, setAddressCurent] = useState(dataDefaultAddress)
  const [checkCountLoad, setCheckCountLoad] = useState(0)

  useEffect(() => {
    setCheckCountLoad(0)
    getAllCountry()
    getAllNationality()
    getAllDepartment()
    getAllJobposition()
    getAllJobtitle()
    getAllCity({ countryId: 1 }, AddressCodeLever)
  }, [])
  //bắt sự thay đổi của quốc gia
  useEffect(() => {
    if (formState.employeeCountryId) {
      getAllCity({ countryId: formState.employeeCountryId })
    }
  }, [formState.employeeCountryId])

  //bắt sự thay đổi của tỉnh thành phố
  useEffect(() => {
    if (formState.employeeCity) {
      getAllDistrict({ cityCode: formState.employeeCity })
    }
  }, [formState.employeeCity])

  //bắt sự thay đổi của tỉnh quận huyện
  useEffect(() => {
    // form.setFieldsValue({ employeeWard: null })
    // setFormState({ ...formState, employeeWard: null })
    if (formState.employeeDistrict) {
      getAllWard({ districtCode: formState.employeeDistrict })
    }
  }, [formState.employeeDistrict])

  //bắt sự thay đổi của toàn bộ
  useEffect(() => {
    form.setFieldsValue({ employeeAddress: getNonEmptyValues(address) })
  }, [address])

  //check của code city
  useEffect(() => {
    let data = address
    if (formState?.employeeCity && listCity.length > 0) {
      const obCity = findObjectInArray(
        listCity,
        'cityCode',
        formState?.employeeCity,
      )
    } else {
      data.city = ''
    }
  }, [formState?.employeeCity])

  // --------------- Address Current   ---------------

  //AddressCurrent bắt sự thay đổi của quốc gia
  useEffect(() => {
    if (formState.employeeAddressCurrent_countryId) {
      getAllCity(
        { countryId: formState.employeeAddressCurrent_countryId },
        AddressCurrent,
      )
    } else {
      setListCityACurrent([])
    }
  }, [formState.employeeAddressCurrent_countryId])

  //AddressCurrent bắt sự thay đổi của tỉnh thành phố
  useEffect(() => {
    if (formState.employeeAddressCurrentCity) {
      getAllDistrict(
        { cityCode: formState.employeeAddressCurrentCity },
        AddressCurrent,
      )
    } else {
      setListDistrictACurrent([])
    }
  }, [formState.employeeAddressCurrentCity])

  //AddressCurrent bắt sự thay đổi của tỉnh quận huyện
  useEffect(() => {
    if (formState.employeeAddressCurrentDistrict) {
      getAllWard(
        { districtCode: formState.employeeAddressCurrentDistrict },
        AddressCurrent,
      )
    } else {
      setListWardACurrent([])
    }
  }, [formState.employeeAddressCurrentDistrict])

  //check của code city
  useEffect(() => {
    let data = address
    if (formState?.employeeAddressCurrentCity && listCity.length > 0) {
      const obCity = findObjectInArray(
        listCityACurrent,
        'cityCode',
        formState?.employeeAddressCurrentCity,
      )
    } else {
      data.city = ''
    }
  }, [formState?.employeeAddressCurrentCity])

  //AddressCurrent bắt sự thay đổi của toàn bộ
  useEffect(() => {
    form.setFieldsValue({
      employeeAddressCurrent: getNonEmptyValues(addressCurent),
    })
  }, [addressCurent])

  //check sự thay đổi check box
  useEffect(() => {
    if (formState?.statusAddressCurrent?.[0]) {
      let data = {
        ...form.getFieldValue(),
        employeeAddressCurrentCity: form.getFieldValue('employeeCity'),
        employeeAddressCurrentDistrict: form.getFieldValue('employeeDistrict'),
        employeeAddressCurrentWard: form.getFieldValue('employeeWard'),
        employeeAddressCurrent: form.getFieldValue('employeeAddress'),
        employeeAddressCurrent_countryId:
          form.getFieldValue('employeeCountryId'),
        employeeAddressCurrentHome: form.getFieldValue('employeeHome'),
      }
      form.setFieldsValue(data)
      setFormState(data)
    }
  }, [formState?.statusAddressCurrent?.[0]])

  useEffect(() => {
    resetNameForm(['employeeIdentification'])
    if (formState.employeeType == 1) {
    } else if (formState.employeeType == 2) {
      form.setFieldsValue({ jobTitleTemp: 'Nhân viên' })
    }
  }, [formState.employeeType])

  useEffect(() => {
    if (formState?.jobPositionId && listJobtitle && listJobtitle.length > 0) {
      changejobTitle(formState?.jobPositionId)
    }
  }, [listJobtitle, formState?.jobPositionId])

  useEffect(() => {
    if (
      !formState?.departmentId &&
      listDepartment &&
      listDepartment.length > 0
    ) {
      const dataDepartment = findObjectInArray(listDepartment, 'type', 1)
      form.setFieldsValue({ departmentId: dataDepartment?.departmentId })
    }
  }, [listDepartment])

  const resetNameForm = async (arrayName) => {
    if (checkCountLoad > 1) {
      // await form?.resetFields(arrayName)
      form
        .validateFields(arrayName)
        .then((values) => {})
        .catch((errorInfo) => {})
    }
  }

  const getCode = async (type) => {
    let result = await getAutomatically()
    if (result && type == 1) {
      form.setFieldsValue({ employeeCode: result?.taxpayersCodeEmployee })
    } else if (result && type == 2) {
      form.setFieldsValue({ employeeCode: result?.taxpayersCode })
    }
  }

  const getAutomatically = async () => {
    try {
      const result = unwrapResult(
        await dispatch(thunkAutomaticallyTaxpayer({})),
      )
      if (result?.message == 'success') {
        return result.data
      } else {
        return {}
      }
    } catch (error) {}
  }

  // useEffect(() => {
  //   if (formState.jobPositionId) {
  //     getAllJobtitle()
  //   } else {
  //     setListWardACurrent([])
  //     form.setFieldsValue({ jobTitleId: null })
  //     setFormState({ ...formState, jobTitleId: null })
  //   }
  // }, [formState.jobPositionId])

  // const searchTaxCode = (key) => {
  //   form
  //     .validateFields([key])
  //     .then((data) => {
  //       setDisabledTaxCode(true)
  //       fetchInfoByTaxCode(data[key])
  //     })
  //     .catch((errorInfo) => {
  //       setDisabledTaxCode(false)
  //     })
  // }

  const getAllCity = async (param, type) => {
    const data = unwrapResult(await dispatch(thunkCity(param)))
    if (type == AddressCurrent) {
      setListCityACurrent(data?.data ?? [])
    } else if (type == AddressCodeLever) {
      setListCityCodeLever(data?.data ?? [])
    } else {
      setListCity(data?.data ?? [])
    }
  }

  const getAllCountry = async () => {
    const data = unwrapResult(await dispatch(thunkCountry({})))
    setListCountry(data?.data ?? [])
  }

  const getAllNationality = async () => {
    const data = unwrapResult(await dispatch(thunkNationlity({})))
    setListNationality(data?.data ?? [])
  }

  const getAllDistrict = async (param, type) => {
    const data = unwrapResult(await dispatch(thunkDistrict(param)))
    if (type == AddressCurrent) {
      setListDistrictACurrent(data?.data ?? [])
    } else {
      setListDistrict(data?.data ?? [])
    }
  }

  const getAllWard = async (param, type) => {
    const data = unwrapResult(await dispatch(thunkWard(param)))
    if (type == AddressCurrent) {
      setListWardACurrent(data?.data ?? [])
    } else {
      setListWard(data?.data ?? [])
    }
  }

  const { mutateAsync } = useMutationGetCompanyInfo()

  const fetchInfoByTaxCode = async (tax_code) => {
    const result = await mutateAsync({
      document_type: 'TAX',
      tax_code,
    })
    setDisabledTaxCode(false)
    if (!result) {
      return
    }
    const { message } = result.data
    if (message === 'SUCCESSFULLY') {
    }
  }

  const getAllDepartment = async () => {
    const data = unwrapResult(await dispatch(thunkDepartment({})))
    setListDepartment(data?.data ?? [])
  }

  const getAllJobposition = async () => {
    const data = unwrapResult(await dispatch(thunkJobposition({})))
    setListJobposition(data?.data ?? [])
  }

  const getAllJobtitle = async () => {
    const data = unwrapResult(await dispatch(thunkJobtitle({})))
    setListJobtitle(data?.data ?? [])
  }

  const changejobTitle = (value) => {
    const dataJobTitle = findObjectInArray(listJobtitle, 'jobTitleId', value)
    if (dataJobTitle) {
      form.setFieldsValue({
        jobTitleIdTemp: dataJobTitle?.jobTitleName,
      })
    }
  }

  return (
    <DivContainerUpdate className="row pt-4 pb-2">
      <div className="col-sm-12">
        <LabelTitle fontSize="13px">{t('taxpayer.inforcn')}</LabelTitle>
      </div>
      <div className="col-sm-6 pt-6">
        <FormCustom.Item
          label={t('taxpayer.employeeType')}
          name={'employeeType'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            onChange={(value) => {
              getCode(value)
            }}
            options={SelectOptions.listTypeEmployee}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
          ></SelectCustom>
        </FormCustom.Item>
      </div>
      <div className="col-sm-6 pt-6">
        <DivFlexCustom>
          <FormCustom.Item
            // className="marginLeftLabel"
            style={{ width: '100%' }}
            label={
              <>
                {`${t('taxpayer.taxCode')} `}
                {/* <RedStar>*</RedStar> */}
              </>
            }
            name={'employeeTaxCode'}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[
              () => ({
                validator(_, value) {
                  // if (!value) {
                  //   return Promise.reject(
                  //     new Error(t('taxpayer.requiredTaxCode')),
                  //   )
                  // }
                  const regex = new RegExp(REGEX_MST)
                  if (value && value.trim() && !regex.test(value.trim())) {
                    return Promise.reject(
                      new Error(` ${t('taxpayer.validateTaxCode')}`),
                    )
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <InputCustom
              placeholder={t('taxpayer.placehoderTaxCode')}
              maxLength={255}
            />
          </FormCustom.Item>
          {/* <FormCustom.Item label={<>&nbsp;</>} className="hideLabel">
            <StyleButtonClose
              icon={<BookOutlined />}
              onClick={() => {
                searchTaxCode('employeeTaxCode')
              }}
              disabled={disabledTaxCode}
            >
              {t('taxpayer.getInfor')}
            </StyleButtonClose>
          </FormCustom.Item> */}
        </DivFlexCustom>
      </div>
      <div className="col-sm-6">
        <FormCustom.Item
          label={
            <>
              {`${t('taxpayer.codennt')} `}
              <RedStar>*</RedStar>
            </>
          }
          name={'employeeCode'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error(t('taxpayer.requiredCode')))
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputCustom
            placeholder={t('taxpayer.placehodercodennt')}
            maxLength={45}
          />
        </FormCustom.Item>
      </div>
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.typeDocument')}
          name={'documentType'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            options={SelectOptions.listIndentify}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            onChange={() => {
              setCheckCountLoad(2)
              form.setFieldsValue({ employeeIdentification_city: null })
            }}
          ></SelectCustom>
        </FormCustom.Item>
      </div>
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={
            <>
              {`${t('taxpayer.titleName')} `}
              <RedStar>*</RedStar>
            </>
          }
          name={'taxpayersName'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(
                      t('taxpayer.requiredKey', {
                        key: t('taxpayer.titleName'),
                      }),
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputCustom
            placeholder={t('taxpayer.placehorderName')}
            maxLength={255}
            allowClear
          />
        </FormCustom.Item>
      </div>
      {/* Số CMND/CCCD/Hộ chiếu */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={
            <>
              {`${t('taxpayer.number')} ${findObjectInArray(SelectOptions.listIndentify, 'value', formState.documentType)?.label}`}
              <RedStar>*</RedStar>
            </>
          }
          name={'employeeIdentification'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(
                      t('taxpayer.requiredKey', {
                        key: `${findObjectInArray(SelectOptions.listIndentify, 'value', formState.documentType)?.label}`,
                      }),
                    ),
                  )
                }
                const regex = new RegExp(
                  formState.documentType == 1
                    ? REGEX_IDENTIFY.REGEX_CMND
                    : formState.documentType == 2
                      ? REGEX_IDENTIFY.REGEX_CCCD
                      : REGEX_IDENTIFY.REGEX_PASSPORT,
                )
                if (value && !regex.test(value.trim())) {
                  return Promise.reject(
                    new Error(
                      ` ${t('taxpayer.typeFeild', { key: `${findObjectInArray(SelectOptions.listIndentify, 'value', formState.documentType)?.label}` })}`,
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputCustom
            placeholder={t('taxpayer.placehoderNumber')}
            maxLength={12}
            allowClear
          />
        </FormCustom.Item>
      </div>
      {/* Ngày sinh */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.employeeBirthDate')}
          name={'employeeBirthDate'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <DatePickerCustom
            locale={locale}
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
          />
        </FormCustom.Item>
      </div>
      {/* Ngày cấp */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={
            <>
              {`${t('taxpayer.employeeIdentification_date')}`}
              <RedStar>*</RedStar>
            </>
          }
          name={'employeeIdentification_date'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(
                      t('taxpayer.requiredKey', {
                        key: t('taxpayer.employeeIdentification_date'),
                      }),
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <DatePickerCustom
            locale={locale}
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
          />
        </FormCustom.Item>
      </div>
      {/* TTC giới tính */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.gender')}
          name={'gender'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <RadioCustom.Group>
            <DivFlex>
              {listGender.map((item, index) => (
                <div key={index}>
                  <RadioCustom value={item}>{item}</RadioCustom>
                </div>
              ))}
            </DivFlex>
          </RadioCustom.Group>
        </FormCustom.Item>
      </div>
      {/* TTC nơi cấp */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={
            <>
              {`${t('taxpayer.employeeIdentification_city')}`}
              <RedStar>*</RedStar>
            </>
          }
          name={'employeeIdentification_city'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(
                      t('taxpayer.requiredKey', {
                        key: t('taxpayer.employeeIdentification_city'),
                      }),
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <SelectCustom
            showSearch
            placeholder={t('taxpayer.placehodercityIndentify')}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={
              formState.documentType == 2
                ? SelectOptions.listCitizenidentificationLocation
                : formState.documentType == 3
                  ? convertToOptionsValueString(
                      listNationality,
                      'nationalityId',
                      'nationalityName',
                    )
                  : convertToOptionsValueString(
                      listCityCodeLever,
                      'cityCode',
                      'cityName',
                    )
            }
          />
        </FormCustom.Item>
      </div>
      {/* TTC số điện thoại */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.phone')}
          name={'employeePhone'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                const regex = new RegExp(regexLong.REGEX_PHONE_NUMBER)
                if (value && !regex.test(value.trim())) {
                  return Promise.reject(
                    new Error(
                      ` ${t('taxpayer.typeFeild', { key: t('taxpayer.phone') })}`,
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputCustom
            placeholder={t('taxpayer.placehoderPhone')}
            maxLength={255}
            allowClear
          />
        </FormCustom.Item>
      </div>
      {/* TTC Quốc tịch */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.nationality')}
          name={'employeeIdentification_nationlityId'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            showSearch
            placeholder={t('taxpayer.placehoderCountry')}
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(
              listNationality,
              'nationalityId',
              'nationalityName',
            )}
          />
        </FormCustom.Item>
      </div>
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.email')}
          name={'employeeEmail'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                const regex = new RegExp(REGEX_EMAIL_MAX)
                if (value && !regex.test(value.trim())) {
                  return Promise.reject(
                    new Error(
                      ` ${t('taxpayer.typeFeild', { key: t('taxpayer.email') })}`,
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputCustom
            placeholder={t('taxpayer.placehodercityEmail')}
            maxLength={255}
            allowClear
          />
        </FormCustom.Item>
      </div>
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={
            <>
              {`${t('taxpayer.contractType')}`}
              <RedStar>*</RedStar>
            </>
          }
          name={'contractType'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(
                      t('taxpayer.requiredKey', {
                        key: t('taxpayer.contractType'),
                      }),
                    ),
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <SelectCustom
            placeholder={t('taxpayer.placehoderDoc')}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            options={convertToOptions(AppData.contractType)}
          />
        </FormCustom.Item>
      </div>
      {/* ------------------ Hộ khẩu thường trú ------------------ */}
      <div className="col-sm-12 pt-4">
        <LabelTitle fontSize="13px">{t('taxpayer.inforcn')}</LabelTitle>
      </div>
      {/* TTCN Quốc gia */}
      <div className="col-sm-6 pt-6">
        <FormCustom.Item
          label={t('taxpayer.country')}
          name={'employeeCountryId'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            showSearch
            onChange={(value, item) => {
              setAddress({
                ...dataDefaultAddress,
                custom: address.custom,
                country: item?.label ?? null,
              })
              form.setFieldsValue({ employeeCity: null })
              setFormState({ ...formState, employeeCity: null })
              setListCity([])
              form.setFieldsValue({ employeeDistrict: null })
              setFormState({ ...formState, employeeDistrict: null })
              setListDistrict([])
              form.setFieldsValue({ employeeWard: null })
              setFormState({ ...formState, employeeWard: null })
              setListWard([])
            }}
            placeholder={t('taxpayer.placehoderCountry')}
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(listCountry, 'id', 'name')}
          />
        </FormCustom.Item>
      </div>
      {/* TTCN Phường xã */}
      <div className="col-sm-6 pt-6">
        <FormCustom.Item
          label={t('taxpayer.ward')}
          name={'employeeWard'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            onChange={(value, item) => {
              setAddress({ ...address, ward: item?.label ?? null })
            }}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            allowClear
            showSearch
            placeholder={t('taxpayer.placehoderward')}
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(listWard, 'wardCode', 'wardName')}
          />
        </FormCustom.Item>
      </div>
      {/* TTC Thành phố  */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.city')}
          name={'employeeCity'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            onChange={(value, item) => {
              setAddress({
                ...dataDefaultAddress,
                custom: address.custom,
                country: address.country,
                city: item?.label ?? null,
              })
              form.setFieldsValue({ employeeDistrict: null })
              setFormState({ ...formState, employeeDistrict: null })
              setListDistrict([])
              form.setFieldsValue({ employeeWard: null })
              setFormState({ ...formState, employeeWard: null })
              setListWard([])
            }}
            showSearch
            allowClear
            placeholder={t('taxpayer.placehoderCity')}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(listCity, 'cityCode', 'cityName')}
          />
        </FormCustom.Item>
      </div>
      {/* TTCN Số nhà, đường/phố, thôn/xóm */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.home')}
          name={'employeeHome'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <InputCustom
            placeholder={t('taxpayer.placehoderHome')}
            onChange={(e) => {
              setAddress({
                ...address,
                custom: e.target.value,
              })
            }}
          />
        </FormCustom.Item>
      </div>
      {/* TTCN Quận huyện  */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.district')}
          name={'employeeDistrict'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            onChange={(value, item) => {
              setAddress({
                ...dataDefaultAddress,
                custom: address.custom,
                country: address.country,
                city: address.city,
                district: item?.label ?? null,
              })
              form.setFieldsValue({ employeeWard: null })
              setFormState({ ...formState, employeeWard: null })
              setListWard([])
            }}
            showSearch
            allowClear
            placeholder={t('taxpayer.placehoderDistrict')}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(
              listDistrict,
              'districtCode',
              'districtName',
            )}
          />
        </FormCustom.Item>
      </div>
      {/* HKTT Địa chỉ */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.address')}
          name={'employeeAddress'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <InputCustom placeholder={t('taxpayer.placehoderAdress')} disabled />
        </FormCustom.Item>
      </div>
      {/* ------------------ Chỗ ở hiện tại  ------------------*/}
      <div className="col-sm-12 pt-4">
        <LabelTitle fontSize="13px">{t('taxpayer.currentAddress')}</LabelTitle>
      </div>
      {/* check box dulicate */}
      <div className="col-sm-6  pt-4">
        <FormCustom.Item
          label={t('taxpayer.duplicateAddress')}
          name={'statusAddressCurrent'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Checkbox.Group options={options} />
        </FormCustom.Item>
      </div>
      <div className="col-sm-6  pt-4"></div>
      {/* COHT Quốc gia */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.country')}
          name={'employeeAddressCurrent_countryId'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            onChange={(value, item) => {
              setAddressCurent({
                ...dataDefaultAddress,
                custom: addressCurent.custom,
                country: item.label,
              })
              form.setFieldsValue({ employeeAddressCurrentCity: null })
              setFormState({ ...formState, employeeAddressCurrentCity: null })
              setListCityACurrent([])
              form.setFieldsValue({ employeeAddressCurrentDistrict: null })
              setFormState({
                ...formState,
                employeeAddressCurrentDistrict: null,
              })
              setListDistrictACurrent([])
              form.setFieldsValue({ employeeAddressCurrentWard: null })
              setFormState({ ...formState, employeeAddressCurrentWard: null })
              setListWardACurrent([])
            }}
            disabled={formState?.statusAddressCurrent?.[0]}
            showSearch
            placeholder={t('taxpayer.placehoderCountry')}
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(listCountry, 'id', 'name')}
          />
        </FormCustom.Item>
      </div>
      {/* COHT Phường xã */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.ward')}
          name={
            formState?.statusAddressCurrent?.[0]
              ? 'employeeWard'
              : 'employeeAddressCurrentWard'
          }
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            disabled={formState?.statusAddressCurrent?.[0]}
            onChange={(value, item) => {
              setAddressCurent({
                ...dataDefaultAddress,
                custom: addressCurent.custom,
                ward: item?.label ?? null,
                district: addressCurent.district,
                city: addressCurent.city,
                country: addressCurent.country,
              })
            }}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            showSearch
            allowClear
            placeholder={t('taxpayer.placehoderward')}
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(
              formState?.statusAddressCurrent?.[0]
                ? listWard
                : listWardACurrent,
              'wardCode',
              'wardName',
            )}
          />
        </FormCustom.Item>
      </div>
      {/* COHT Thành phố  */}
      <div className="col-sm-6 ">
        <FormCustom.Item
          label={t('taxpayer.city')}
          name={'employeeAddressCurrentCity'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            disabled={formState?.statusAddressCurrent?.[0]}
            onChange={(value, item) => {
              setAddressCurent({
                ...dataDefaultAddress,
                custom: addressCurent.custom,
                city: item?.label ?? null,
                country: addressCurent.country,
              })
              form.setFieldsValue({ employeeAddressCurrentDistrict: null })
              setFormState({
                ...formState,
                employeeAddressCurrentDistrict: null,
              })
              setListDistrictACurrent([])
              form.setFieldsValue({ employeeAddressCurrentWard: null })
              setFormState({ ...formState, employeeAddressCurrentWard: null })
              setListWardACurrent([])
            }}
            showSearch
            allowClear
            placeholder={t('taxpayer.placehoderCity')}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(
              formState?.statusAddressCurrent?.[0]
                ? listCity
                : listCityACurrent,
              'cityCode',
              'cityName',
            )}
          />
        </FormCustom.Item>
      </div>
      {/* COHT Số nhà, đường/phố, thôn/xóm */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.home')}
          name={
            formState?.statusAddressCurrent?.[0]
              ? 'employeeHome'
              : 'employeeAddressCurrentHome'
          }
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <InputCustom
            onChange={(e) => {
              setAddressCurent({
                custom: e.target.value,
                ward: addressCurent.district,
                district: addressCurent.district,
                city: addressCurent.city,
                country: addressCurent.country,
              })
            }}
            placeholder={t('taxpayer.placehoderHome')}
            disabled={formState?.statusAddressCurrent?.[0]}
          />
        </FormCustom.Item>
      </div>
      {/* COHT Quận huyện  */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.district')}
          name={'employeeAddressCurrentDistrict'}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <SelectCustom
            disabled={formState?.statusAddressCurrent?.[0]}
            onChange={(value, item) => {
              setAddressCurent({
                ...dataDefaultAddress,
                custom: addressCurent.custom,
                district: item?.label ?? null,
                city: addressCurent.city,
                country: addressCurent.country,
              })
              form.setFieldsValue({ employeeAddressCurrentWard: null })
              setFormState({ ...formState, employeeAddressCurrentWard: null })
              setListWardACurrent([])
            }}
            showSearch
            allowClear
            placeholder={t('taxpayer.placehoderDistrict')}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('taxDeduction.noData')}
              />
            }
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                .toLowerCase()
                .includes(removeAccents(input).toLowerCase())
            }
            options={convertToOptions(
              formState?.statusAddressCurrent?.[0]
                ? listDistrict
                : listDistrictACurrent,
              'districtCode',
              'districtName',
            )}
          />
        </FormCustom.Item>
      </div>
      {/* COHT Địa chỉ */}
      <div className="col-sm-6">
        <FormCustom.Item
          label={t('taxpayer.address')}
          name={
            formState?.statusAddressCurrent?.[0]
              ? 'employeeAddress'
              : 'employeeAddressCurrent'
          }
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <InputCustom placeholder={t('taxpayer.placehoderAdress')} disabled />
        </FormCustom.Item>
      </div>
      {/* ------------------ Thông tin công việc  ------------------*/}
      <div className="col-sm-12">
        <LabelTitle fontSize="15px">{t('taxpayer.inforWork')}</LabelTitle>
      </div>
      {/* Bộ phân/phòng ban*/}

      {formState.employeeType == 1 ? (
        <>
          <div className="col-sm-6 pt-4">
            <FormCustom.Item
              label={
                <>
                  {`${t('taxpayer.departmentName')}`}
                  <RedStar>*</RedStar>
                </>
              }
              name={'departmentId'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error(
                          t('taxpayer.requiredKey', {
                            key: t('taxpayer.departmentName'),
                          }),
                        ),
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <SelectCustom
                showSearch
                placeholder={t('taxpayer.departmentNamePlacehoder')}
                filterOption={(input, option) =>
                  removeAccents(option?.label ?? '')
                    .toLowerCase()
                    .includes(removeAccents(input).toLowerCase())
                }
                options={convertToOptions(
                  listDepartment,
                  'departmentId',
                  'departmentName',
                )}
              />
            </FormCustom.Item>
          </div>
          {/* Ngày học việc*/}
          <div className="col-sm-6 pt-4">
            <FormCustom.Item
              label={t('taxpayer.internshipStartDate')}
              name={'internshipStartDate'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <DatePickerCustom
                locale={locale}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
            </FormCustom.Item>
          </div>
          {/* Vị trí công việc*/}
          <div className="col-sm-6">
            <FormCustom.Item
              label={
                <>
                  {`${t('taxpayer.jobPosition')}`}
                  <RedStar>*</RedStar>
                </>
              }
              name={'jobPositionId'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error(
                          t('taxpayer.requiredKey', {
                            key: t('taxpayer.jobPosition'),
                          }),
                        ),
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <SelectCustom
                showSearch
                placeholder={t('taxpayer.jobtitlePlacehoder')}
                filterOption={(input, option) =>
                  removeAccents(option?.label ?? '')
                    .toLowerCase()
                    .includes(removeAccents(input).toLowerCase())
                }
                options={convertToOptions(
                  listJobposition,
                  'jobPositionId',
                  'jobPositionName',
                )}
                onChange={(value) => {
                  changejobTitle(value)
                }}
              />
            </FormCustom.Item>
          </div>
          {/* Ngày thử việc*/}
          <div className="col-sm-6">
            <FormCustom.Item
              label={t('taxpayer.probationStartDate')}
              name={'probationStartDate'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <DatePickerCustom
                locale={locale}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
            </FormCustom.Item>
          </div>
          {/* Chức danh*/}
          <div className="col-sm-6" style={{ display: 'none' }}>
            <FormCustom.Item
              label={t('taxpayer.jobtitle')}
              name={'jobTitleId'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <SelectCustom
                // disabled={formState.jobPositionId}
                showSearch
                placeholder={t('taxpayer.departmentNamePlacehoder')}
                filterOption={(input, option) =>
                  removeAccents(option?.label ?? '')
                    .toLowerCase()
                    .includes(removeAccents(input).toLowerCase())
                }
                options={convertToOptions(
                  listJobtitle,
                  'jobTitleId',
                  'jobTitleName',
                )}
              />
            </FormCustom.Item>
          </div>
          <div className="col-sm-6">
            <FormCustom.Item
              label={t('taxpayer.jobtitle')}
              name={'jobTitleIdTemp'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <InputCustom disabled />
            </FormCustom.Item>
          </div>
          {/* Ngày chính thức*/}
          <div className="col-sm-6">
            <FormCustom.Item
              label={t('taxpayer.datect')}
              name={'officialStartDate'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <DatePickerCustom
                locale={locale}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
            </FormCustom.Item>
          </div>
          {/* trạng thái làm việc*/}
          <div className="col-sm-6">
            <FormCustom.Item
              label={t('taxpayer.workingStatus')}
              name={'workingStatus'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <SelectCustom
                showSearch
                placeholder={t('taxpayer.plachoderworkingStatus')}
                filterOption={(input, option) =>
                  removeAccents(option?.label ?? '')
                    .toLowerCase()
                    .includes(removeAccents(input).toLowerCase())
                }
                options={convertToOptions(
                  SelectOptions.listWorkingStatus,
                  'value',
                  'label',
                )}
              />
            </FormCustom.Item>
          </div>
          {/* thời gian nghỉ việc*/}
          <div className="col-sm-6">
            <FormCustom.Item
              label={t('taxpayer.datenv')}
              name={'terminationDate'}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <DatePickerCustom
                locale={locale}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
            </FormCustom.Item>
          </div>
        </>
      ) : (
        formState.employeeType == 2 && (
          <>
            <div className="col-sm-6 pt-4">
              <FormCustom.Item
                label={
                  <>
                    {`${t('taxpayer.departmentName')}`}
                    <RedStar>*</RedStar>
                  </>
                }
                name={'departmentId'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            t('taxpayer.requiredKey', {
                              key: t('taxpayer.departmentName'),
                            }),
                          ),
                        )
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <SelectCustom
                  showSearch
                  placeholder={t('taxpayer.departmentNamePlacehoder')}
                  filterOption={(input, option) =>
                    removeAccents(option?.label ?? '')
                      .toLowerCase()
                      .includes(removeAccents(input).toLowerCase())
                  }
                  options={convertToOptions(
                    listDepartment,
                    'departmentId',
                    'departmentName',
                  )}
                />
              </FormCustom.Item>
            </div>
            {/* trạng thái làm việc*/}
            <div className="col-sm-6 pt-4">
              <FormCustom.Item
                label={t('taxpayer.workingStatus')}
                name={'workingStatus'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <SelectCustom
                  showSearch
                  placeholder={t('taxpayer.plachoderworkingStatus')}
                  filterOption={(input, option) =>
                    removeAccents(option?.label ?? '')
                      .toLowerCase()
                      .includes(removeAccents(input).toLowerCase())
                  }
                  options={convertToOptions(
                    SelectOptions.listWorkingStatus,
                    'value',
                    'label',
                  )}
                />
              </FormCustom.Item>
            </div>
            {/* Chức danh*/}
            <div className="col-sm-6">
              <FormCustom.Item
                label={t('taxpayer.jobtitle')}
                name={'jobTitleTemp'}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <InputCustom disabled />
                {/* <SelectCustom
                  disabled
                  showSearch
                  placeholder={t('taxpayer.departmentNamePlacehoder')}
                  filterOption={(input, option) =>
                    removeAccents(option?.label ?? '')
                          .toLowerCase()
                          .includes(removeAccents(input).toLowerCase())
                  }
                  options={convertToOptions(
                    listJobtitle,
                    'jobTitleId',
                    'jobTitleName',
                  )}
                /> */}
              </FormCustom.Item>
            </div>
          </>
        )
      )}
    </DivContainerUpdate>
  )
}
export default Update
