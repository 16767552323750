import { useMutation, useQueryClient } from '@tanstack/react-query'
import checkPointApi from 'api/checkPointApi'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ToastHelper from 'general/helpers/ToastHelper'
import { isArray } from 'lodash'
import { useRef } from 'react'

export default function DeleteModal({ open, toggleModalState, selected }) {
  const modalRef = useRef(null)
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation({
    mutationKey: 'deleteCheckPoint',
    mutationFn: (data) => checkPointApi.delete(data),
    onError: (err) => ToastHelper.showError(err?.message ?? 'Có lỗi xảy ra'),
    onSuccess: () => {
      ToastHelper.showSuccess('Xoá điểm soát vé thành công')
      toggleModalState(false)
      queryClient.invalidateQueries({
        queryKey: ['getTicketCheckPoint'],
      })
    },
  })
  return (
    <DraggableModal
      title="Xác nhận xoá ?"
      description={`Bạn chắc chắn muốn xoá ${
        isArray(selected) ? 'các' : ''
      } điểm giao vé này`}
      cancelText="Huỷ bỏ"
      applyText="Xác nhận"
      ref={modalRef}
      isOpen={open}
      isLoading={isLoading}
      onOpenChange={(state) => toggleModalState(state)}
      handleApplyChanges={() =>
        mutate({
          locationCheckTicketIds: isArray(selected) ? selected : [selected],
        })
      }
    />
  )
}
