import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

ModalUnauthorized.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
}

function ModalUnauthorized(props) {
  const { show, setShow } = props

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal show={show} onHide={handleClose} centered className="ModalConfirm">
      <Modal.Header closeButton>
        <Modal.Title>
          <p>
            <i
              className="fas fa-exclamation-triangle pr-3"
              style={{ color: 'orange' }}
            ></i>
            Cảnh báo
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Bạn không có quyền sử dụng chức năng này. Vui lòng liên hệ với người
        dùng có quyền Quản trị để được phân quyền.
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-4 px-8"
            onClick={handleClose}
          >
            Đóng
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalUnauthorized
