import { useFormik } from 'formik'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useAppDispatch } from 'hooks/useRedux'
import { useRef } from 'react'
import { useMutationCancelInvoice } from '../../queries'
import { transactionActions, useTransactionSelector } from '../../slice'

export default function CancelledInvoiceModal() {
  const modalRef = useRef()
  const { modalState, selectedRow } = useTransactionSelector()
  const dispatch = useAppDispatch()
  const { isPending: isCancelling, mutate: handleCancel } =
    useMutationCancelInvoice()

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: ValidationSchema.transactionCancelledSchema,
    onSubmit: (values) => {
      handleCancel({
        stationPetroId: selectedRow.dataLogPetroId,
        body: values,
      })
      handleToggleModal(false)
    },
  })

  const handleToggleModal = (status) => {
    formik.resetForm()
    dispatch(
      transactionActions.toggleModalState({
        name: 'cancelInvoice',
        status,
      }),
    )
  }

  return (
    <DraggableModal
      title="Vui lòng nhập lý do hủy"
      applyText="Xác nhận"
      cancelText="Hủy bỏ"
      isOpen={modalState.cancelInvoice}
      onOpenChange={() => handleToggleModal(false)}
      width={500}
      handleApplyChanges={formik.submitForm}
      isLoading={isCancelling}
      ref={modalRef}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <CustomLabel
            htmlFor="reason"
            isRequired
            fontWeight={600}
            text="Lý do"
          />
          <CustomAntInput
            type="textarea"
            borderRadius="sm"
            inputProps={{
              ...formik.getFieldProps('reason'),
              style: {
                padding: 0,
              },
              autoSize: {
                minRows: 3,
                maxRows: 6,
              },
              status:
                formik.errors.reason && formik.touched.reason
                  ? 'error'
                  : undefined,
              count: {
                max: 150,
                show: true,
              },
            }}
          />
        </div>
      </form>
    </DraggableModal>
  )
}
