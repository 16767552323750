import AppRegex from 'general/constants/AppRegex'
import _ from 'lodash'
import * as y from 'yup'

/** @param {import("react-i18next").TFunction} t */
export const generateSchema = (t) => {
  return {
    exportTransactionSchema: y.object({
      invoiceTemplateId: y.string().required('Vui lòng chọn mẫu hóa đơn'),
      customerEmail: y.string().email('Email không hợp lệ').nullable(),
      customerTaxCode: y
        .string()
        .matches(AppRegex.mstRegex, 'Mã số thuế không hợp lệ')
        .nullable(),
      customerCompanyName: y.string().when('customerTaxCode', {
        is: (val) => !_.isEmpty(val),
        then: y.string().required('Vui lòng nhập tên đơn vị'),
        otherwise: y.string().nullable(),
      }),
      customerFullAddress: y.string().when('customerTaxCode', {
        is: (val) => !_.isEmpty(val),
        then: y.string().required('Vui lòng nhập địa chỉ'),
        otherwise: y.string().nullable(),
      }),
    }),
  }
}
