import React, { useRef } from 'react'

import './style.scss'
import { Dropdown, DropdownButton, Tab, Tabs } from 'react-bootstrap'
import { Range, getTrackBackground } from 'react-range'
import AppResource from 'general/constants/AppResource'
import CustomColorPicker from 'features/TemplateInvoice/components/CustomColorPicker'
import { useDispatch } from 'react-redux'
import {
  plusMinusFontSizeLineHeightAll,
  setActivePanel,
  setInstanceInfo,
  setLogoInfo,
  setShowWatermark,
  setSpecifiedDataField,
  setSpecifiedDataFieldProperty,
  setWatermarkInfo,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useSelector } from 'react-redux'
import { useColumnsDispatch } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'
import { useState } from 'react'
import { useEffect } from 'react'
import Utils from 'general/utils/Utils'

const displaySelectedLineType = (selectedLineType) => {
  switch (selectedLineType) {
    case 'solid':
    case 'dashed':
    case 'dotted':
      return (
        <div
          className="w-100 bg-transparent my-3"
          style={{
            height: '1px',
            borderBottom: `1px ${selectedLineType} black`,
          }}
        ></div>
      )
    case 'none':
    default:
      return 'Không có'
  }
}

const changeCKTemplateInvoiceSeries = (
  templateInvoiceSeries,
  templateInvoiceForm,
) => {
  templateInvoiceSeries = templateInvoiceSeries.split('')
  templateInvoiceSeries.splice(1, 1, templateInvoiceForm ? 'C' : 'K')
  return templateInvoiceSeries.join('')
}

function GeneralControl(props) {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()
  const { style } = props

  const { showWatermark } = useSelector((state) => state.templateInvoice)
  const {
    templateInvoiceName,
    templateInvoiceForm,
    templateInvoiceSeries,
    dataTransferMethod,
    sellerInfoPosition,
    fontFamily,
    // fontSize,
    color,
    lineType,
    // lineHeight,
    isShowQRCode,
    duplicateInfoMulPages,
    isShowLabelEn,
    isShowWatermarkInAdjustedInvoice,
    isNonTariffArea,
    logoInfo,

    watermarkInfo,
    category,
    notDraftInvoices,
  } = useSelector((state) => state.templateInvoice.instance)

  // Cờ này bật để căn lại vị trí của logo sau khi đổi "Vị trí thông tin ngùoi bán"
  const [flag, setFlag] = useState(false)
  useEffect(() => {
    if (flag) {
      ;(function reAlignLogoPosition() {
        setFlag(false)

        if (Utils.isObjectEmpty(logoInfo)) return
        const { width, height } = logoInfo

        let logoContainer = document.getElementById('logo-container')
        let containerWidth = logoContainer?.clientWidth
        let containerHeight = logoContainer?.clientHeight - 2

        let x = (containerWidth - width) / 2
        let y = (containerHeight - height) / 2

        dispatch(setLogoInfo({ ...logoInfo, x, y }))
      })()
    }
  }, [flag])

  useEffect(() => {
    dispatch(setShowWatermark(false))
  }, [])

  const setInvoiceInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'invoiceInfoColumns',
      payload,
    })
  }

  const setBuyerInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'buyerInfoColumns',
      payload,
    })
  }

  const handleChangeTemplateInvoiceSeries = (e) => {
    dispatch(
      setInstanceInfo({
        templateInvoiceForm: parseInt(e.target.value),
        templateInvoiceSeries: changeCKTemplateInvoiceSeries(
          templateInvoiceSeries,
          parseInt(e.target.value),
        ),
      }),
    )
    dispatch(setInstanceInfo({ dataTransferMethod: '1' }))

    let fullSeries = changeCKTemplateInvoiceSeries(
      templateInvoiceSeries,
      parseInt(e.target.value),
    )
    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'invoiceInfoOther',
        dataFieldName: 'invoiceSeries',
        propertyName: 'value',
        propertyData: {
          value: fullSeries,
        },
      }),
    )
  }

  let oldBuyerInfoWidth = useRef()

  // const invoiceInfoRegion = useMemo(
  //   () => _.find(RegionConfigs, region => region.regionName === 'invoiceInfo'),
  //   [RegionConfigs],
  // )

  // const isNonTariffArea = useMemo(
  //   () =>
  //     invoiceInfoRegion?.regionConfigs?.find(
  //       ({ dataField }) => dataField == 'nonTariffArea',
  //     )?.show,
  //   [invoiceInfoRegion],
  // )

  return (
    <div
      className="GeneralControl d-flex flex-column"
      style={{ ...style, overflowY: 'hidden', overflowX: 'visible' }}
    >
      <div
        className="d-flex flex-row align-items-center mb-3"
        style={{ flex: '0 1 auto' }}
      >
        <button
          type="button"
          className="btn btn-icon mr-2 cursor-pointer"
          onClick={() => {
            dispatch(setActivePanel('main_ctrl'))
          }}
        >
          <i className="fas fa-arrow-left" style={{ color: '#000000' }}></i>
        </button>
        <h3 className="static-title">Thiết lập thông tin chung</h3>
      </div>

      <div
        className=""
        style={{ flex: '1 1 auto', overflowY: 'scroll', overflowX: 'visible' }}
      >
        <div className="row mx-0 g-2 mb-5">
          <div className="col-12 px-0">
            <label htmlFor="template-inv-name" className="form-label">
              Tên mẫu
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              id="template-inv-name"
              // defaultValue="Hóa đơn GTGT test"
              value={templateInvoiceName}
              disabled={notDraftInvoices?.length}
              onChange={(e) =>
                dispatch(
                  setInstanceInfo({ templateInvoiceName: e.target.value }),
                )
              }
            />
          </div>

          <div className="col-12 px-0">
            <p className="mb-0">
              Hình thức hóa đơn
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </p>
          </div>

          <div className="col-6 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="radio"
                name="templateInvoiceSeries"
                id="have-authen-code"
                checked={templateInvoiceForm == 1}
                disabled={notDraftInvoices?.length}
                value={1}
                onChange={handleChangeTemplateInvoiceSeries}
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
              <label className="m-0 cursor-pointer" htmlFor="have-authen-code">
                HĐ có mã của CQT
              </label>
            </div>
          </div>
          <div className="col-6 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer mb-2">
              <input
                className="mr-3 cursor-pointer"
                type="radio"
                name="templateInvoiceSeries"
                id="not-have-authen-code"
                checked={templateInvoiceForm == 0}
                disabled={notDraftInvoices?.length}
                value={0}
                // onChange={e => {
                //   dispatch(
                //     setInstanceInfo({
                //       templateInvoiceForm: parseInt(e.target.value),
                //       templateInvoiceSeries: changeCKTemplateInvoiceSeries(
                //         templateInvoiceSeries,
                //         parseInt(e.target.value),
                //       ),
                //     }),
                //   )
                // }}
                onChange={handleChangeTemplateInvoiceSeries}
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
              <label
                className="m-0 cursor-pointer"
                htmlFor="not-have-authen-code"
              >
                HĐ không có mã của CQT
              </label>
            </div>
          </div>

          <div className="col-12 px-0">
            <p className="mb-0">
              Ký hiệu
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </p>
          </div>

          <div className="col-12 px-0">
            <div className="input-group mb-3">
              <span className="input-group-text" id="template-invoice-series">
                {templateInvoiceSeries?.slice(0, 5)}
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="template-invoice-series"
                disabled={notDraftInvoices?.length}
                value={templateInvoiceSeries?.slice(5)}
                onChange={(e) => {
                  let newTemplateInvoiceSeries =
                    templateInvoiceSeries?.slice(0, 5) + e.target.value
                  dispatch(
                    setInstanceInfo({
                      templateInvoiceSeries: newTemplateInvoiceSeries,
                    }),
                  )
                  dispatch(
                    setSpecifiedDataFieldProperty({
                      regionName: 'invoiceInfoOther',
                      dataFieldName: 'invoiceSeries',
                      propertyName: 'value',
                      propertyData: {
                        value: newTemplateInvoiceSeries,
                      },
                    }),
                  )
                }}
                style={{ flex: '0 0 50px', padding: '0.65rem 0.85rem' }}
              />
            </div>
          </div>

          {category?.form == 2 && (
            <div className="col-12 px-0">
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <input
                  className="mr-3 cursor-pointer"
                  type="checkbox"
                  id="is-non-tariff-area"
                  checked={isNonTariffArea}
                  onChange={(e) => {
                    dispatch(
                      setInstanceInfo({
                        isNonTariffArea: e.target.checked,
                      }),
                    )
                    dispatch(
                      dispatch(
                        setSpecifiedDataField({
                          regionName: 'invoiceInfo',
                          dataFieldName: 'nonTariffArea',
                          propertyData: { show: e.target.checked ? 1 : 0 },
                        }),
                      ),
                    )
                  }}
                  style={{ width: '1.2rem', height: '1.2rem' }}
                />
                <label
                  className="m-0 cursor-pointer"
                  htmlFor="is-non-tariff-area"
                >
                  Là hóa đơn dành cho khu phi thuế quan
                </label>
              </div>
            </div>
          )}

          {templateInvoiceSeries[1] == 'K' && (
            <>
              <div className="col-12 px-0">
                <p className="mb-0">
                  Phương thức chuyển dữ liệu đến CQT
                  <span className="ml-1" style={{ color: 'red' }}>
                    *
                  </span>
                </p>
              </div>

              <div className="col-12 px-0">
                <div className="d-flex flex-row align-items-center cursor-pointer">
                  <input
                    className="mr-3 cursor-pointer"
                    type="radio"
                    name="dataTransferMethod"
                    id="full-content"
                    checked={dataTransferMethod === '1'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setInstanceInfo({ dataTransferMethod: '1' }))
                      }
                    }}
                    style={{ width: '1.2rem', height: '1.2rem' }}
                  />
                  <label className="m-0 cursor-pointer" htmlFor="full-content">
                    Chuyển đầy đủ nội dung từng hóa đơn
                  </label>
                </div>
              </div>
              <div className="col-12 px-0">
                <div className="d-flex flex-row align-items-center cursor-pointer mb-2">
                  <input
                    className="mr-3 cursor-pointer"
                    type="radio"
                    name="dataTransferMethod"
                    id="summary-table"
                    checked={
                      dataTransferMethod === '2' || dataTransferMethod === '3'
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setInstanceInfo({ dataTransferMethod: '2' }))
                      }
                    }}
                    style={{ width: '1.2rem', height: '1.2rem' }}
                  />
                  <label className="m-0 cursor-pointer" htmlFor="summary-table">
                    Chuyển theo bảng tổng hợp dữ liệu hoá đơn điện tử
                  </label>
                </div>

                {dataTransferMethod != '1' && (
                  <div className="d-flex flex-row align-items-center cursor-pointer ml-15">
                    <div className="d-flex flex-row align-items-center cursor-pointer mr-5">
                      <input
                        className="mr-3 cursor-pointer"
                        type="radio"
                        name="dataTransferMethod2"
                        id="summary-table-petro"
                        checked={dataTransferMethod === '2'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(
                              setInstanceInfo({ dataTransferMethod: '2' }),
                            )
                          }
                        }}
                        style={{ width: '1.2rem', height: '1.2rem' }}
                      />
                      <label
                        className="m-0 cursor-pointer"
                        htmlFor="summary-table-petro"
                      >
                        Xăng dầu
                      </label>
                    </div>
                    <div className="d-flex flex-row align-items-center cursor-pointer">
                      <input
                        className="mr-3 cursor-pointer"
                        type="radio"
                        name="dataTransferMethod2"
                        id="summary-table-other"
                        checked={dataTransferMethod === '3'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(
                              setInstanceInfo({ dataTransferMethod: '3' }),
                            )
                          }
                        }}
                        style={{ width: '1.2rem', height: '1.2rem' }}
                      />
                      <label
                        className="m-0 cursor-pointer"
                        htmlFor="summary-table-other"
                      >
                        Khác
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="col-12 px-0">
            <div className="d-flex flex-row bg-light p-3 ml-5">
              <div className="mr-4 mt-1">
                <i
                  className="fa-solid fa-lightbulb-on fa-lg"
                  style={{ color: '#efd358' }}
                ></i>
              </div>
              <div className="remind">
                {dataTransferMethod == '1' ? (
                  <div className="mb-0">
                    Hóa đơn được lập với mẫu này sẽ được chuyển dữ liệu đến cơ
                    quan thuế ngay sau khi gửi hóa đơn cho người mua. <br />
                    Xem chi tiết{' '}
                    <a href="#" target="_blank">
                      tại đây
                    </a>
                  </div>
                ) : dataTransferMethod == '2' ? (
                  <div className="mb-0">
                    Hóa đơn được lập với mẫu này cần chuyển dữ liệu đến CQT theo{' '}
                    <b>Bảng tổng hợp dữ liệu HĐĐT</b> dành cho lĩnh vực{' '}
                    <b>Xăng dầu</b> trong <b>ngày.</b> <br />
                    Xem chi tiết{' '}
                    <a href="#" target="_blank">
                      tại đây
                    </a>
                  </div>
                ) : (
                  <div className="mb-0">
                    Hóa đơn được lập với mẫu này cần chuyển dữ liệu đến CQT theo{' '}
                    <b>Bảng tổng hợp dữ liệu HĐĐT</b> dành cho lĩnh vực{' '}
                    <b>Khác</b> theo <b>kỳ kê khai thuế GTGT.</b> <br />
                    Xem chi tiết{' '}
                    <a href="#" target="_blank">
                      tại đây
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <p className="mt-5 mb-3 font-sfpro-bold">Vị trí thông tin người bán</p>

        <div>
          <Tabs
            activeKey={sellerInfoPosition}
            onSelect={(k) => {
              dispatch(setInstanceInfo({ sellerInfoPosition: k }))

              setInvoiceInfoColumns([
                {
                  title: '',
                  dataIndex: 'col1',
                  key: 'col1',
                  width: k == 1 ? 123 : k == 2 ? 80 : 250,
                },
                {
                  title: '',
                  dataIndex: 'col2',
                  key: 'col2',
                  width: k == 1 ? 415 : k == 2 ? 320 : 158,
                },
                { title: '', dataIndex: 'col3', key: 'col3' },
              ])

              setFlag(true)
            }}
            className="d-inline-flex mx-auto mb-3"
          >
            <Tab eventKey={1} title="Vị trí 1"></Tab>
            <Tab eventKey={2} title="Vị trí 2"></Tab>
            <Tab eventKey={3} title="Vị trí 3"></Tab>
          </Tabs>
        </div>

        <div className="p-3 mb-4 border">
          <div className="d-flex flex-row align-items-center mb-2">
            <div
              className="font-sfpro-bold mr-2"
              style={{ fontSize: '24px', color: 'dodgerblue' }}
            >
              A
            </div>
            <p className="mb-0">
              Thay đổi font chữ, cỡ chữ, chiều cao giữa các dòng
            </p>
          </div>

          <div className="d-flex flex-row">
            <div className="d-flex flex-column mr-5" style={{ width: '160px' }}>
              <div>Font chữ</div>
              <div>
                <DropdownButton
                  title={fontFamily}
                  onSelect={(k) => dispatch(setInstanceInfo({ fontFamily: k }))}
                  className="w-100"
                >
                  <Dropdown.Item eventKey="Roboto">Roboto</Dropdown.Item>
                  <Dropdown.Item eventKey="Arial">Arial</Dropdown.Item>
                  <Dropdown.Item eventKey="Times New Roman">
                    Times New Roman
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>

            <div className="d-flex flex-column mr-5">
              <div>Cỡ chữ</div>
              <div className="d-flex flex-row">
                <div
                  className="fontsize-up mr-2 active-border-blue"
                  onClick={() =>
                    dispatch(
                      plusMinusFontSizeLineHeightAll({
                        func: 'PLUS',
                        propertyName: 'fontSize',
                      }),
                    )
                  }
                >
                  A+
                </div>
                <div
                  className="fontsize-down active-border-blue"
                  onClick={() =>
                    dispatch(
                      plusMinusFontSizeLineHeightAll({
                        func: 'MINUS',
                        propertyName: 'fontSize',
                      }),
                    )
                  }
                >
                  A-
                </div>
              </div>
            </div>

            <div className="d-flex flex-column mr-5" style={{ width: '100px' }}>
              <div>Màu chữ</div>
              <div>
                <CustomColorPicker
                  selectedColor={color}
                  setSelectedColor={(value) =>
                    dispatch(setInstanceInfo({ color: value }))
                  }
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className="d-flex flex-column mr-5" style={{ width: '160px' }}>
              <div>Loại dòng kẻ trên bảng</div>
              <div>
                <DropdownButton
                  title={displaySelectedLineType(lineType)}
                  onSelect={(lt) => dispatch(setInstanceInfo({ lineType: lt }))}
                  className="w-100"
                >
                  <Dropdown.Item eventKey="solid">
                    <div
                      className="w-100 bg-transparent my-3"
                      style={{
                        height: '1px',
                        borderBottom: '1px solid black',
                      }}
                    ></div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="dashed">
                    <div
                      className="w-100 bg-transparent my-3"
                      style={{
                        height: '1px',
                        borderBottom: '1px dashed black',
                      }}
                    ></div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="dotted">
                    <div
                      className="w-100 bg-transparent my-3"
                      style={{
                        height: '1px',
                        borderBottom: '1px dotted black',
                      }}
                    ></div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="none">Không có</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>

            <div className="d-flex flex-column mr-5">
              <div>Chiều cao giữa các dòng</div>
              <div className="d-flex flex-row">
                <div className="lineheight-up mr-2 active-border-blue">
                  <div
                    onClick={() =>
                      dispatch(
                        plusMinusFontSizeLineHeightAll({
                          func: 'PLUS',
                          propertyName: 'lineHeight',
                        }),
                      )
                    }
                    style={{
                      backgroundImage: `url(${AppResource.icons.icLineHeightUp.default})`,
                    }}
                  ></div>
                </div>
                <div className="lineheight-down active-border-blue">
                  <div
                    style={{
                      backgroundImage: `url(${AppResource.icons.icLineHeightDown.default})`,
                    }}
                    onClick={() =>
                      dispatch(
                        plusMinusFontSizeLineHeightAll({
                          func: 'MINUS',
                          propertyName: 'lineHeight',
                        }),
                      )
                    }
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-0 g-3 mb-5">
          <div className="col-12 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="checkbox"
                id="show-qr-code"
                checked={isShowQRCode}
                onChange={(e) => {
                  let checkValue = e.target.checked
                  dispatch(setInstanceInfo({ isShowQRCode: checkValue }))
                  if (!checkValue) {
                    let oldWidth =
                      document.getElementById('buyerInfoContent')
                        ?.offsetWidth || 600
                    oldBuyerInfoWidth.current = oldWidth

                    setBuyerInfoColumns((old) => [
                      ...old.map((column) =>
                        column.width
                          ? { ...column, width: undefined }
                          : { ...column, width: 0.5 },
                      ),
                    ])
                  } else {
                    setBuyerInfoColumns((old) => [
                      ...old.map((column) =>
                        column.width
                          ? { ...column, width: undefined }
                          : {
                              ...column,
                              width: oldBuyerInfoWidth.current || 600,
                            },
                      ),
                    ])
                  }
                }}
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
              <label className="m-0 cursor-pointer" htmlFor="show-qr-code">
                Hiển thị QR code
              </label>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="checkbox"
                id="duplicate-info-mul-page"
                checked={duplicateInfoMulPages}
                onChange={(e) =>
                  dispatch(
                    setInstanceInfo({
                      duplicateInfoMulPages: e.target.checked,
                    }),
                  )
                }
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
              <label
                className="m-0 cursor-pointer"
                htmlFor="duplicate-info-mul-page"
              >
                Lặp lại thông tin hóa đơn trên nhiều trang
              </label>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="checkbox"
                id="show-label-en"
                checked={isShowLabelEn}
                onChange={(e) => {
                  dispatch(setInstanceInfo({ isShowLabelEn: e.target.checked }))

                  let dateString = e.target.checked
                    ? 'Ngày (Date)   tháng (month)   năm (year)   '
                    : 'Ngày   tháng   năm   '
                  const dates = [
                    {
                      regionName: 'invoiceInfo',
                      dataFieldName: 'dateInvoice',
                    },
                    {
                      regionName: 'buyerInfo',
                      dataFieldName: 'deliveryOrderDate',
                    },
                  ]
                  dates.map(({ regionName, dataFieldName }) =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName,
                        dataFieldName,
                        propertyName: 'value',
                        propertyData: {
                          value: dateString,
                        },
                      }),
                    ),
                  )
                }}
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
              <label className="m-0 cursor-pointer" htmlFor="show-label-en">
                Hiển thị song ngữ Việt-Anh
              </label>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="d-flex flex-row align-items-center cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="checkbox"
                id="show-watermark-in-adjusted-invoice"
                checked={isShowWatermarkInAdjustedInvoice}
                onChange={(e) => {
                  let value = e.target.checked
                  dispatch(
                    setInstanceInfo({
                      isShowWatermarkInAdjustedInvoice: value,
                    }),
                  )

                  dispatch(
                    setWatermarkInfo({
                      opacity: 50,
                    }),
                  )

                  dispatch(setShowWatermark(false))
                }}
                style={{ width: '1.2rem', height: '1.2rem' }}
              />
              <label
                className="m-0 cursor-pointer"
                htmlFor="show-watermark-in-adjusted-invoice"
              >
                Hiển thị dấu chìm trên hóa đơn bị điều chỉnh
              </label>
            </div>

            {isShowWatermarkInAdjustedInvoice && (
              <div className="ml-15">
                <div
                  className="d-flex flex-row justify-content-between align-items-center"
                  style={{
                    width: '300px',
                  }}
                >
                  <p className="mb-0">Độ nét của dấu</p>
                  <output id="output" className="ml-5">{`${[
                    watermarkInfo?.opacity || 0,
                  ]}%`}</output>
                </div>
                <div
                  className="d-flex justify-content-center flex-wrap mb-3"
                  style={{
                    width: '300px',
                  }}
                >
                  <Range
                    values={[watermarkInfo?.opacity || 0]}
                    step={1}
                    min={0}
                    max={100}
                    onChange={(values) =>
                      dispatch(
                        setWatermarkInfo({
                          opacity: values[0],
                        }),
                      )
                    }
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        className="d-flex w-100"
                        style={{
                          ...props.style,
                          height: '20px',
                        }}
                      >
                        <div
                          ref={props.ref}
                          className="w-100 align-self-center"
                          style={{
                            height: '5px',
                            borderRadius: '4px',
                            background: getTrackBackground({
                              values: [watermarkInfo?.opacity || 0],
                              colors: ['#548BF4', '#ccc'],
                              min: 0,
                              max: 100,
                            }),
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '5px',
                          width: '20px',
                          backgroundColor: '#FFF',
                          boxShadow: '0px 2px 6px #AAA',
                        }}
                      ></div>
                    )}
                  />
                </div>
                <div className="d-flex flex-row align-items-center cursor-pointer">
                  <input
                    className="mr-3 cursor-pointer"
                    type="checkbox"
                    id="show-watermark"
                    checked={showWatermark}
                    onChange={(e) => {
                      let value = e.target.checked

                      dispatch(setShowWatermark(value))
                    }}
                    style={{ width: '1.2rem', height: '1.2rem' }}
                  />
                  <label
                    className="m-0 cursor-pointer"
                    htmlFor="show-watermark"
                  >
                    Xem trước cách hiển thị
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralControl
