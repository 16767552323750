import PropTypes from 'prop-types'
import CustomDataTable from 'general/components/CustomDataTable'
import { useMemo } from 'react'
import CustomAntSelect from 'general/components/CustomAntSelect'
import { Checkbox } from 'antd'
import './styles.scss'
import { useAppSelector } from 'hooks/useRedux'
import { filterColumnsBaseOnTicketTemplate } from 'general/helpers/ImportTicketHelper'
import { TICKET_UPLOAD_HEADER_COLUMNS } from 'general/constants/AppConstants'

ImportTicketStepTwo.propTypes = {
  tableData: PropTypes.array,
  setTableData: PropTypes.func,
}

ImportTicketStepTwo.defaultProps = {}

function ImportTicketStepTwo(props) {
  const { ticketSerials } = props
  const { selectedSerial, excelData } = useAppSelector(
    (state) => state.importTicket,
  )

  const defaultColumns = useMemo(
    () => [
      {
        name: 'Thông tin bắt buộc',
        width: '15%',
        center: true,
        cell: (row) => {
          return <Checkbox checked={row.require} />
        },
      },
      {
        name: 'Cột thông tin trên Vietinvoice',
        width: '24%',
        center: true,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.webCol}
            </p>
          )
        },
      },
      {
        name: 'Cột thông tin trên tệp dữ liệu',
        width: '26%',
        center: true,
        style: { padding: 0 },

        cell: (row) => {
          return (
            <CustomAntSelect
              options={excelData?.headers?.map(({ textWithColName, key }) => ({
                label: textWithColName,
                value: key,
              }))}
              value={row.excelCol}
              bordered={false}
              showArrow={false}
              // showSearch={false}
              notFoundContent=""
              // onChange={() => }
            />
          )
        },
      },
      {
        name: 'Diễn giải',
        width: 'auto',
        center: true,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.description}
            </p>
          )
        },
      },
    ],
    [],
  )

  const dataSource = useMemo(
    () =>
      filterColumnsBaseOnTicketTemplate({
        baseColumns: TICKET_UPLOAD_HEADER_COLUMNS,
        serial: ticketSerials?.find(({ value }) => value == selectedSerial),
      }).map((item) => ({
        key: item.key,
        require: item.require,
        webCol: item.header,
        excelCol: excelData?.headers?.find(({ key }) => key == item.key)
          ?.textWithColName,
        description: item.description,
      })),
    [ticketSerials, selectedSerial, excelData?.headers],
  )

  return (
    <div className="ImportTicketStepTwo">
      <div className="header-step mb-2">Thông tin chung</div>

      <CustomDataTable
        wrapperClassName="w-100"
        columns={defaultColumns}
        dataSource={dataSource}
        // isLoading={gettingListData || isPending}
      />
    </div>
  )
}

export default ImportTicketStepTwo
