export const emailTemplate = [
  {
    name: 'Gửi email sau khi phát hành hóa đơn',
    content: `
<div class="container" style="width:100%;margin:0;font-family:Tahoma;font-size:14px;">
  <div class="body" style="padding:40px">
    <div style="padding:6px 0">&nbsp;</div>
    <div class="subject" style="background-color:#0f97f1;border-top-left-radius:4px;border-top-right-radius:4px;padding:15px 20px;">
      <span style="color:#ffffff; font-family:Tahoma; font-size:22px">##tendonvi## thông báo gửi ##loaihoadon## cho Quý khách</span>
    </div>
    <div class="content" style="background-color:#fafafa; border-color:#e1e1e1; border-style:none solid solid; border-width:1px; border-radius:0 0 4px 4px;">
      <div class="content-text" style="color:#030303; font-family:Tahoma,serif; line-height:26px; padding:10px 20px 10px 20px">
        <div class="note" style="font-size:14px; color:red; margin-bottom:10px; display:none">
          <i><b>Chú ý</b>: Đây là email tự động từ hệ thống, vui lòng không phản hồi (reply) lại email này</i>
        </div>
        <strong>Kính gửi: Quý khách ##tennguoinhan##</strong><br>
        ##tendonvi## xin gửi cho Quý khách ##loaihoadon## với các thông tin như sau (Chi tiết xem trong file đính kèm):
      </div>
      <div class="detail" style="background-color:#e1eefb; line-height:30px; padding:1px">
        <ul style="margin-left:25px; list-style-type:none">
          <li>Số: <strong>##so##</strong></li>
          <li>Ngày: <strong>##ngay##</strong></li>
          <li>Ký hiệu: <strong>##kyhieu##</strong></li>
          <li>Mã của cơ quan thuế: <strong>##maCQT##</strong></li>
        </ul>
      </div>
      <div class="content-text" style="color:#030303; font-family:Tahoma,serif; line-height:26px; padding:10px 20px 10px 20px">
        Để tra cứu và ký điện tử trên hóa đơn, Quý khách vui lòng nhấn nút:
        <div class="bt-search">
          <a href="${process.env.REACT_APP_TRA_CUU}/?sc=##matracuu##&amp;r=1" style="font-family:Tahoma,serif; background-color:#ff7500; color:#ebebeb; font-weight:500; padding:10px 50px 10px 50px; border-radius:4px; box-shadow:1px 1px 1px #ddd; border-style:none; cursor:pointer; text-decoration:none;">TRA CỨU</a>
        </div>
        &nbsp;
        <div>Hoặc truy cập vào đường dẫn <span style="font-size:11.0pt"><span style="font-family:&quot;Calibri&quot;,sans-serif"><a href="https://www.meinvoice.vn/tra-cuu" style="color:blue; text-decoration:underline"><span style="font-size:13.0pt">https://www.meinvoice.vn/tra-cuu</span></a></span></span> và nhập mã số: <strong>##matracuu##</strong><br>
        Quý khách vui lòng kiểm tra, đối chiếu nội dung ghi trên hóa đơn.</div>
      </div>
      <div class="signer" style="margin-bottom:40px; margin-top:60px; text-align:center; float:left;">
        <div>Trân trọng kính chào!</div>
        <strong>##tendonvi##</strong>
      </div>
      <div style="clear:both">&nbsp;</div>
      <img alt="" height="0" src="##EmailTrackingHandler##" width="0">
      <div style="text-align:center; color:white; background-color:#0f97f1; padding:6px; border-radius:0 0 4px 4px;">
        <strong>##tendonvi##</strong>
        <img alt="" height="0" src="##EmailTrackingHandler##" width="0">
      </div>
    </div>
    <div id="copyright" style="color:rgb(155, 155, 155); padding:8px 0; display:none;"></div>
  </div>
</div>
    `,
  },
  { name: 'Gửi email sau khi phát hành hóa đơn điều chỉnh' },
  { name: 'Gửi email sau khi phát hành hóa đơn thay thế' },
  { name: 'Gửi email sau khi hủy hóa đơn' },
]

export const systemInvoice = [
  {
    state: 'alertWhenDifference',
    label: 'Cảnh báo khi phát hành hóa đơn có số liệu phát sinh sai lệch',
  },
  {
    state: 'invoiceBeforeIssue',
    label: 'Phê duyệt hóa đơn trước khi phát hành',
  },
  {
    state: 'manageStaffInvoice',
    label: 'Quản lý nhân viên bán hàng trên hóa đơn',
  },
  {
    state: 'autoGetInfo',
    label:
      'Tự động lấy Tên khách hàng cá nhân hiển thị lên thông tin Người mua hàng trên hóa đơn',
  },
  {
    state: 'dateSameDate',
    label: 'Ngày lập trùng với ngày phát hành hóa đơn',
  },
  {
    state: 'generateNumberAfterCreate',
    label: 'Sinh số hóa đơn sau khi tạo',
  },
]

export const sixOptions = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
]
export const fourOptions = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
]

export const twoOptions = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
]

export const numberFormat = [
  // {
  //   name: '',
  //   options: {},
  //   state: '',
  // },
  {
    label: 'Tiền quy đổi',
    options: sixOptions,
    state: 'currencyExchange',
  },
  { label: 'Tỷ giá', options: twoOptions, state: 'exchangeRate' },
  {
    label: 'Tiền ngoại tệ',
    options: sixOptions,
    state: 'foreignCurrency',
  },
  { label: 'Tỷ lệ', options: fourOptions, state: 'ratio' },
  { label: 'Số lượng', options: sixOptions, state: 'quantity' },
  {
    label: 'Đơn giá quy đổi',
    options: sixOptions,
    state: 'conversionUnitPrice',
  },
  {
    label: 'Đơn giá ngoại tệ',
    options: sixOptions,
    state: 'currencyUnitPrice',
  },
  {
    label: 'Đơn giá',
    options: sixOptions,
    state: 'unitPrice',
  },
  {
    label: 'Thành tiền',
    options: sixOptions,
    state: 'totalAmount',
  },
  {
    label: 'Tổng tiền',
    options: sixOptions,
    state: 'totalCost',
  },
  {
    label: 'Tổng tiền ngoại tệ',
    options: sixOptions,
    state: 'exchangeTotalCost',
  },
]

export const zeroInten = [
  { label: 'linh', value: 'linh' },
  { label: 'lẻ', value: 'lẻ' },
]

export const zeroInThousand = [
  { label: 'nghìn', value: 'nghìn' },
  { label: 'ngàn', value: 'ngàn' },
]

export const readMoneyInvoice = [
  {
    name: '[Để trống/Âm][Tổng tiền thanh toán]',
    value: '[Để trống/Âm][Tổng tiền thanh toán]',
  },
  {
    name: '[Tăng/Giảm][Tổng tiền thanh toán]',
    value: '[Tăng/Giảm][Tổng tiền thanh toán]',
  },
  {
    name: 'Điều chỉnh [tăng giảm][Tổng tiền thanh toán]',
    value: 'Điều chỉnh [tăng giảm][Tổng tiền thanh toán]',
  },
  {
    name: '[Tổng tiền thanh toán][Tăng/Giảm]',
    value: '[Tổng tiền thanh toán][Tăng/Giảm]',
  },
  {
    name: '[Tổng tiền thanh toán](Điều chỉnh [tăng giảm])',
    value: '[Tổng tiền thanh toán](Điều chỉnh [tăng giảm])',
  },
  { name: '[Tổng tiền thanh toán]', value: '[Tổng tiền thanh toán]' },
]

export const VATValues = [
  { name: '0%', value: '0%' },
  { name: '5%', value: '5%' },
  { name: '8%', value: '8%' },
  { name: '10%', value: '10%' },
  { name: 'KCT', value: 'KCT' },
  { name: 'KKKNT', value: 'KKKNT' },
  { name: 'KHAC', value: 'KHAC' },
]

export const autoSentTime = ['06:00', '12:00', '18:00', '23:45', '23:59']

export const checkBoxItems = [
  {
    state: 'sendEmailAfterCreateInvoice',
    label: 'Gửi email sau khi phát hành hóa đơn',
  },
  {
    state: 'sendEmailAfterCreateAdjustInvoice',
    label: 'Gửi email sau khi phát hành hóa đơn điều chỉnh',
  },
  {
    state: 'sendEmailAfterCreateReplaceInvoice',
    label: 'Gửi email sau khi phát hành hóa đơn thay thế',
  },
  {
    state: 'sendEmailAfterCancelInvoice',
    label: 'Gửi email sau khi hủy hóa đơn',
  },
  {
    state: 'defaultConfig',
    label: 'Sử dụng cấu hình email mặc định',
  },
]

export const inputFieldItems = [
  {
    state: 'typeEmail',
    label: 'Loại email',
    type: 'select',
    options: [
      { name: 'Gmail', value: 'Gmail' },
      { name: 'Khác', value: 'Khác' },
    ],
  },
  {
    state: 'sender',
    label: 'Người gửi',
    type: 'input',
  },
  {
    state: 'stmpHost',
    label: 'Smtp Host',
    type: 'input',
  },
  {
    state: 'stmpPort',
    label: 'Smtp Port',
    type: 'input',
    typeInput: 'number',
  },
  {
    state: 'emailUserName',
    label: 'Tài khoản',
    type: 'input',
    typeInput: 'email',
  },
  {
    state: 'emailPassword',
    label: 'Mật khẩu',
    type: 'input',
    typeInput: 'password',
  },
]
