const InvoiceReportHelper = {
  renderReportStatus: (reportStatus) => {
    switch (reportStatus) {
      case 1:
        return 'Chưa ký'
      case 2:
        return 'Chưa gửi KH'
      case 3:
        return 'Chờ KH ký'
      case 4:
        return 'KH đã ký'
    }
  },
  renderInvoiceReportStatus: (reportStatus) => {
    switch (reportStatus) {
      case 1:
        return 'Chưa lập'
      case 2:
        return 'Chưa ký'
      case 3:
        return 'Chưa gửi KH'
      case 4:
        return 'Chờ KH ký'
      case 5:
        return 'KH đã ký'
    }
  },
  reportTypeToVnese: (reportType) => {
    return reportType == 'CANCEL' ? 'hủy' : 'điều chỉnh'
  },
}

export default InvoiceReportHelper
