import { EyeOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Col, Modal, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import diffMonthDay from 'general/utils/dateHelper'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useCallback } from 'react'
import { Table } from 'react-bootstrap'
import { shallowEqual } from 'react-redux'
import { dashboardQueries } from '../../DashboardTab/queries'
import './style.scss'

export default function CardCert() {
  const { data: certData, isPending: isGettingCerts } = useQuery(
    dashboardQueries.listCert(),
  )
  const [modal, contextHolder] = Modal.useModal()
  const sysType = useAppSelector((state) => state.auth.activeInvoice)

  const resource_invoice = useAppSelector(
    (state) => state.auth.resource?.invoice,
    shallowEqual,
  )
  const resource_ticket = useAppSelector(
    (state) => state.auth.resource?.ticket,
    shallowEqual,
  )

  const resource_accounting_documents = useAppSelector(
    (state) => state.auth.resource?.tncn,
    shallowEqual,
  )

  const getIssuer = useCallback((str) => {
    return str
      .split(',')
      .map((v) => ({
        key: v.split('=')[0],
        value: v.split('=')[1],
      }))
      .filter((v) => v.key === 'CN')?.[0]?.value
  }, [])
  const handleShowDetail = useCallback(
    (v) => {
      const { diffDate, diffMonth } = diffMonthDay(v?.expireDate)
      const hasOtherField = _.includes(v?.issuedBy, '=')
      const issuedBy = hasOtherField ? getIssuer(v?.issuedBy) : v?.issuedBy

      modal.info({
        centered: true,
        width: 650,
        icon: null,
        okText: 'Đóng',
        okButtonProps: {
          className: 'rounded-2',
          type: 'default',
        },
        closable: true,
        title: (
          <Typography.Title level={4}>Chi tiết chứng thư số</Typography.Title>
        ),
        content: (
          <Row className="w-100">
            <Col span={10}>
              <Typography.Text strong>Nhà cung cấp</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text>{issuedBy}</Typography.Text>
            </Col>
            <Col span={10}>
              <Typography.Text strong>Thời hạn sử dụng còn lại</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text strong type="danger">
                {`${diffMonth} tháng ${diffDate} ngày`}
              </Typography.Text>
            </Col>
            <Col span={10}>
              <Typography.Text strong>Ngày kích hoạt</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text>
                {dayjs(v?.issuedDate).format('L hh:mm')}
              </Typography.Text>
            </Col>
            <Col span={10}>
              <Typography.Text strong>Ngày hết hạn</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text>
                {dayjs(v?.expireDate).format('L hh:mm')}
              </Typography.Text>
            </Col>
            <Col span={10}>
              <Typography.Text strong>Serial number</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text>{v?.idValue}</Typography.Text>
            </Col>
            <Col span={10}>
              <Typography.Text strong>Tình trạng</Typography.Text>
            </Col>
            <Col span={14}>
              <Typography.Text>
                {dayjs().isAfter(dayjs(v?.expireDate)) ? (
                  'Hết hạn'
                ) : v?.active === 1 ? (
                  <Typography.Text strong type="success">
                    Đang hoạt động
                  </Typography.Text>
                ) : (
                  <Typography.Text strong type="danger">
                    Ngưng hoạt động
                  </Typography.Text>
                )}
              </Typography.Text>
            </Col>
          </Row>
        ),
      })
    },
    [getIssuer, modal],
  )

  const renderTable = useCallback(() => {
    return (
      <tbody>
        <tr>
          <td>
            <Typography.Text
              style={
                (sysType.invoice && resource_invoice?.quantity <= 100) ||
                (sysType.ticket && resource_ticket?.quantity <= 100) ||
                (sysType.accounting_documents &&
                  resource_accounting_documents?.quantity <= 100)
                  ? { color: 'red', fontWeight: 'bold' }
                  : { color: 'white' }
              }
            >
              {sysType?.invoice
                ? 'Hóa đơn'
                : sysType?.personalIncomeTax
                  ? 'Chứng từ'
                  : 'Tem vé'}
            </Typography.Text>
          </td>
          <td>
            <Typography.Text
              style={
                (sysType.invoice && resource_invoice?.quantity <= 100) ||
                (sysType.ticket && resource_ticket?.quantity <= 100) ||
                (sysType.accounting_documents &&
                  resource_accounting_documents?.quantity <= 100)
                  ? { color: 'red', fontWeight: 'bold' }
                  : { color: 'white' }
              }
            >
              VIETINVOICE
            </Typography.Text>
          </td>
          <td>
            {sysType.invoice ? (
              <Typography.Text
                style={
                  resource_invoice?.quantity <= 100
                    ? { color: 'red', fontWeight: 'bold' }
                    : { color: 'white' }
                }
              >
                {Utils.formatNumber(resource_invoice?.quantity)}
              </Typography.Text>
            ) : sysType.ticket ? (
              <Typography.Text
                style={
                  Utils.formatNumber(resource_ticket?.quantity) <= 100
                    ? { color: 'red', fontWeight: 'bold' }
                    : { color: 'white' }
                }
              >
                {Utils.formatNumber(resource_ticket?.quantity)}
              </Typography.Text>
            ) : (
              <Typography.Text
                style={
                  Utils.formatNumber(resource_accounting_documents?.quantity) <=
                  100
                    ? { color: 'red', fontWeight: 'bold' }
                    : { color: 'white' }
                }
              >
                {Utils.formatNumber(resource_accounting_documents?.quantity)}
              </Typography.Text>
            )}{' '}
          </td>
        </tr>
        {certData?.certs?.map((v) => {
          const { diffDate, diffMonth } = diffMonthDay(v?.expireDate)
          const hasOtherField = _.includes(v?.issuedBy, '=')
          const issuedBy = hasOtherField ? getIssuer(v?.issuedBy) : v?.issuedBy
          return (
            <tr key={v.certId}>
              <td>
                <Typography.Text
                  style={
                    diffMonth < 1
                      ? { color: 'red', fontWeight: 'bold' }
                      : { color: 'white' }
                  }
                >
                  Chữ ký số
                </Typography.Text>
              </td>
              <td>
                <Typography.Text
                  style={
                    diffMonth < 1
                      ? { color: 'red', fontWeight: 'bold' }
                      : { color: 'white' }
                  }
                  ellipsis={{
                    tooltip: issuedBy,
                  }}
                >
                  {issuedBy}
                </Typography.Text>
              </td>
              <td>
                <Typography.Text
                  style={
                    diffMonth < 1
                      ? { color: 'red', fontWeight: 'bold' }
                      : { color: 'white' }
                  }
                >{`${diffMonth} tháng ${diffDate} ngày`}</Typography.Text>
              </td>
              <td
                className="cursor-pointer hover-opacity-50"
                onClick={() => handleShowDetail(v)}
              >
                <EyeOutlined
                  style={{
                    fontSize: '17px',
                  }}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    )
  }, [
    certData?.certs,
    getIssuer,
    handleShowDetail,
    resource_invoice?.quantity,
    resource_ticket?.quantity,
    sysType.invoice,
  ])

  return (
    <div className="card card-custom shadow-none p-3 h-100 text-white cardCertBg justify-content-center">
      {isGettingCerts ? (
        'Loading'
      ) : (
        <Table responsive borderless className="text-white">
          <thead>
            <tr>
              <th>Dịch vụ</th>
              <th>Nhà cung cấp</th>
              <th>Số lượng còn lại</th>
              <th></th>
            </tr>
          </thead>
          {renderTable()}
        </Table>
      )}
      {contextHolder}
    </div>
  )
}
