import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import accountingDocumentApi from 'api/accountingDocumentApi'

export const thunkSaveAccountingDocument = createAsyncThunk(
  'accountingDocuments/update',
  async (body, thunkApi) => {
    try {
      const res = await accountingDocumentApi.update(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkFindAccountingDocument = createAsyncThunk(
  'accountingDocuments/find',
  async (body, thunkApi) => {
    try {
      const res = await accountingDocumentApi.find(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkSignHSMAccountingDocument = createAsyncThunk(
  'accountingDocuments/signHSM',
  async (param, thunkApi) => {
    try {
      const res = await accountingDocumentApi.signHSM(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkRemoveAccountingDocument = createAsyncThunk(
  'accountingDocuments/remove',
  async (param, thunkApi) => {
    try {
      const res = await accountingDocumentApi.remove(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkFindByIdAccountingDocument = createAsyncThunk(
  'accountingDocuments/findById',
  async (param, thunkApi) => {
    try {
      const res = await accountingDocumentApi.findById(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkExportAccountingDocument = createAsyncThunk(
  'accountingDocuments/exportFile',
  async (param, thunkApi) => {
    try {
      const res = await accountingDocumentApi.exportFile(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkCancelAccountingDocument = createAsyncThunk(
  'accountingDocuments/cancel',
  async (body, thunkApi) => {
    try {
      const res = await accountingDocumentApi.cancel(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkSendEmailAccountingDocument = createAsyncThunk(
  'accountingDocuments/sendEmail',
  async (body, thunkApi) => {
    try {
      const res = await accountingDocumentApi.sendEmail(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
const reportSlice = createSlice({
  name: 'accountingDocument',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // builder
    //   .addCase(thunkSaveAccountingDocument.pending, (state) => {
    //     state.loading = true
    //     state.error = null
    //   })
    //   .addCase(thunkSaveAccountingDocument.fulfilled, (state, action) => {
    //     state.loading = false
    //     state.processes = action.payload // Cập nhật state với dữ liệu trả về
    //   })
    //   .addCase(thunkSaveAccountingDocument.rejected, (state, action) => {
    //     state.loading = false
    //     state.error = action.payload // Lưu lỗi vào state
    //   })
  },
})

const { reducer } = reportSlice
export default reducer
