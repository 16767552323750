import axiosClient from './axiosClient'

const toolApi = {
  genToolUrl: ({ param2, param3, jwtToken }) => {
    const url = '/sign-tool/get-url'

    return axiosClient.get(url, { params: { param2, param3, jwtToken } })
  },

  getDigitalCertificatesFromTool: () => {
    const url = '/sign-tool/digital-certificates'

    return axiosClient.get(url)
  },
}

export default toolApi
