import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import taxpayerApi from 'api/taxpayerApi'

export const thunkFindTaxpayer = createAsyncThunk(
  'taxpayer/find',
  async (body, thunkApi) => {
    try {
      const res = await taxpayerApi.find(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkSaveTaxpayer = createAsyncThunk(
  'taxpayer/save',
  async (body, thunkApi) => {
    try {
      const res = await taxpayerApi.save(body)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkRemoveTaxpayer = createAsyncThunk(
  'taxpayer/remove',
  async (param, thunkApi) => {
    try {
      const res = await taxpayerApi.remove(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkFindByIdTaxpayer = createAsyncThunk(
  'taxpayer/findById',
  async (param, thunkApi) => {
    try {
      const res = await taxpayerApi.findById(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
export const thunkAutomaticallyTaxpayer = createAsyncThunk(
  'taxpayer/automatically',
  async (param, thunkApi) => {
    try {
      const res = await taxpayerApi.automatically(param)
      return res // Trả về dữ liệu API trả về
    } catch (error) {
      return thunkApi.rejectWithValue(error) // Xử lý lỗi
    }
  },
)
const reportSlice = createSlice({
  name: 'taxpayer',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkFindTaxpayer.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(thunkFindTaxpayer.fulfilled, (state, action) => {
        state.loading = false
        state.processes = action.payload // Cập nhật state với dữ liệu trả về
      })
  },
})

const { reducer } = reportSlice
export default reducer
