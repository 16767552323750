export const ROUTE_ACTION_TYPE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
}

export const DEFAULT_MODAL_STATE = {
  createNewModal: false,
  editModal: false,
  deleteModal: false,
}
