import { Select, Skeleton, Typography } from 'antd'
import Utils from 'general/utils/Utils'
import { v4 } from 'uuid'
import './style.scss'
import { IconAction } from '../GroupButton/styles'
import customFilterHelpers from '../CustomPopover/functions/helpers'
/**
 * @typedef {{value : unknown, label: string, [x: string] : any, disabled ?: boolean}} OptionType
 */

/**
 * A custom Ant Design Select component.
 * @param {Object} props - The props object.
 * @param {boolean} [props.isOpen] - A flag indicating whether the component is open. Default is false.
 * @param {(open: boolean) => void} [props.onOpenChange] - The function to call when the component is opened or closed.
 * @param {string} props.placeholder - The placeholder text for the Select component.
 * @param {string} [props.searchValue] - The current search value in the Select component. Only use this prop if you want to control the search value. Such as debouncing the search value.
 * @param {(value: string) => void} [props.onSearch] - The function to call when the user types in the search box. Only use this prop if you want to control the search value. Such as debouncing the search value.
 * @param {boolean} [props.isLoading] - A flag indicating whether the component is currently loading data. Default is false.
 * @param {OptionType[]} props.options - An array of options to display in the Select component.
 * @param {string} props.value - The currently selected value in the Select component.
 * @param {(value: unknown, option: OptionType | OptionType[]) => void} props.onChange - The function to call when the user selects an option.
 * @param {string} [props.notFoundContent] - The text to display when no options are found.
 * @param {boolean} [props.allowClear] - Whether select should have a clear button. Default is false.
 * @param {boolean} [props.showSearch] - Whether select can search for value. Default is true.
 * @param {boolean} [props.showArrow] - Whether dropdown should have an arrow. Default is true.
 * @param {boolean} [props.bordered] - Should the select has a bordered. Default is true.
 * @param {boolean | number | string} [props.autoMatchWidth] - Should the options size match its parent. Default is true.
 * @param {boolean} [props.isMultiSelect] - Should the options size match its parent. Default is false.
 * @param {import('antd').SelectProps['filterOption']} [props.customFilterOptionFn] - A custom filter function to use when searching for options.
 * @param {import('antd').SelectProps['onSelect']} [props.onSelected] - A custom function to call when an option is selected.
 * @param {number} [props.maxTagCount] - The maximum number of tags to show when isMultiSelect is true. Default is 5.
 * @param {string} [props.className] - The class name to apply to the component.
 * @param {number} [props.listHeight] - The height of the dropdown list. Default is 250.
 * @param {boolean} [props.error] - Status of the select. Default is false.
 * @param {string} [props.errorText] - The error text to display when the select has an error.
 * @param {import('antd').SelectProps['placement']} [props.placement] - The dropdown placement. Default is 'bottomLeft'.
 * @param {boolean} [props.disabled]
 */
export default function CustomAntSelect(props) {
  const {
    isOpen,
    onOpenChange,
    placeholder,
    searchValue,
    onSearch,
    isLoading = false,
    options,
    value,
    onChange,
    onSelected,
    notFoundContent,
    allowClear = false,
    showSearch = true,
    showArrow = true,
    bordered = true,
    autoMatchWidth = true,
    customFilterOptionFn,
    isMultiSelect = false,
    maxTagCount = 5,
    className: wrapperClassName,
    listHeight,
    error,
    errorText,
    placement,
    disabled,
  } = props

  return (
    <>
      <Select
        {...(isOpen !== undefined && {
          open: isOpen,
          onDropdownVisibleChange: onOpenChange,
        })}
        listHeight={listHeight ?? 250}
        menuItemSelectedIcon={<IconAction className="m-0 fad fa-check" />}
        bordered={bordered}
        mode={isMultiSelect && 'multiple'}
        maxTagCount={maxTagCount}
        className={Utils.cn('custom-ant-select', wrapperClassName)}
        defaultActiveFirstOption={false}
        popupMatchSelectWidth={autoMatchWidth}
        showSearch={showSearch}
        placeholder={placeholder}
        allowClear={allowClear}
        searchValue={searchValue}
        onSearch={onSearch}
        onSelect={onSelected}
        filterOption={
          onSearch
            ? false
            : customFilterOptionFn
              ? customFilterOptionFn
              : customFilterHelpers.customFilterOptionFn
        }
        status={error && 'error'}
        disabled={disabled}
        notFoundContent={
          isLoading ? (
            <div className="d-flex flex-column gap-2 w-100">
              <Skeleton.Input active block />
              <Skeleton.Input active block />
              <Skeleton.Input active block />
            </div>
          ) : (
            <Typography.Text className="text-center">
              {notFoundContent ?? 'Không tìm thấy kết quả'}
            </Typography.Text>
          )
        }
        options={options}
        value={value}
        onChange={onChange}
        loading={isLoading}
        suffixIcon={
          isLoading ? (
            <i className="fa-duotone fa-spinner-third fa-spin text-primary" />
          ) : (
            showArrow && <i className={Utils.cn('fa-regular fa-angle-down')} />
          )
        }
        placement={placement ?? 'bottomLeft'}
      />
      {error && <Typography.Text type="danger">{errorText}</Typography.Text>}
    </>
  )
}
