import React from 'react'
import './style.scss'
import { useDispatch } from 'react-redux'
import {
  addNewDataFieldToRegionConfigs,
  setConfigDynamicFieldPanel,
  setInstanceInfo,
  setSpecifiedDataField,
  setSpecifiedDataFieldProperty,
  thunkGetExtendFields,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useState } from 'react'
import Utils from 'general/utils/Utils'
import { useSelector } from 'react-redux'
import extendFieldApi from 'api/extendFieldApi'
import ToastHelper from 'general/helpers/ToastHelper'
import { useColumnsDispatch } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'
import { useEffect } from 'react'

function ConfigDataFieldPanel(props) {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()
  const { action, configDataFieldId, setConfigDataFieldId } = props

  const { activeRegion, activeDataField, extendFields } = useSelector(
    (state) => state.templateInvoice,
  )
  const { extendFields: instanceExtendFields } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const [dataFieldName, setDataFieldName] = useState()
  const [dataFieldNameEn, setDataFieldNameEn] = useState()
  const [dataFieldDataType, setDataFieldDataType] = useState('TEXT')
  const [dataFieldDefaultData, setDataFieldDefaultData] = useState()

  const setTableDetailColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableDetailColumns',
      payload,
    })
  }

  const setTableDetail1Columns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableDetail1Columns',
      payload,
    })
  }

  useEffect(() => {
    return () => {
      setConfigDataFieldId(undefined)
    }
  }, [])

  useEffect(() => {
    if (!extendFields) return
    let item = extendFields.find(({ fieldId }) => fieldId == configDataFieldId)

    if (item) {
      setDataFieldName(item.label)
      setDataFieldNameEn(item.labelEn)
      setDataFieldDataType(item.dataType)
      setDataFieldDefaultData(item.defaultValue)
    }
  }, [configDataFieldId])

  return (
    <div className="ConfigDataFieldPanel">
      <h3 className="static-title">
        {['tableDetail', 'tableDetail1'].includes(activeRegion?.regionName)
          ? 'Thiết lập cột thông tin'
          : 'Thiết lập trường nhập liệu'}
      </h3>

      <div className="p-3">
        <div className="row mx-0 g-2 pb-4 mb-4 border-bottom">
          <div className="col-12 px-0">
            <label htmlFor="data-field-name" className="form-label">
              {['tableDetail', 'tableDetail1'].includes(
                activeRegion?.regionName,
              )
                ? 'Tên tiêu đề cột'
                : 'Tên tiêu đề'}
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              id="data-field-name"
              placeholder="Tên trường"
              value={dataFieldName}
              onChange={(e) => setDataFieldName(e.target.value)}
            />
          </div>

          <div className="col-12 px-0">
            <label htmlFor="data-field-name-en" className="form-label">
              {['tableDetail', 'tableDetail1'].includes(
                activeRegion?.regionName,
              )
                ? 'Tiêu đề cột tiếng anh'
                : 'Tên tiêu đề tiếng anh'}
            </label>
            <input
              type="text"
              className="form-control"
              id="data-field-name-en"
              placeholder="Tên trường tiếng anh"
              value={dataFieldNameEn}
              onChange={(e) => setDataFieldNameEn(e.target.value)}
            />
          </div>

          <div className="col-12 px-0">
            <label htmlFor="data-field-name-data-type" className="form-label">
              Kiểu dữ liệu
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </label>
            <select className="form-select" id="data-field-data-type">
              value={dataFieldDataType}
              onChange={(e) => setDataFieldDataType(e.target.value)}
              <option value="TEXT">Chữ</option>
              <option value="CURRENCY">Tiền tệ</option>
              <option value="NUMBER">Số lượng</option>
              <option value="DATE">Ngày</option>
              <option value="PERCENT">Phần trăm</option>
            </select>
          </div>

          <div className="col-12 px-0">
            <label
              htmlFor="data-field-name-default-value"
              className="form-label"
            >
              Giá trị mặc định
            </label>
            <input
              type="text"
              className="form-control"
              id="data-field-name-default-value"
              value={dataFieldDefaultData}
              onChange={(e) => setDataFieldDefaultData(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between">
          <button
            type="button"
            className="px-10 py-2 btn btn-outline-secondary"
            onClick={() => dispatch(setConfigDynamicFieldPanel(false))}
          >
            Hủy bỏ
          </button>

          <button
            type="button"
            className="px-10 py-2 btn btn-primary"
            onClick={async () => {
              if (!activeRegion) return

              let name = `extendField-${activeRegion.regionName}-${Utils.makeId(
                3,
              )}`

              let res
              if (action === 'CREATE') {
                res = await extendFieldApi.createExtendField({
                  name,
                  regionName: activeRegion.regionName,
                  dataType: dataFieldDataType,
                  label: dataFieldName,
                  labelEn: dataFieldNameEn,
                  defaultValue: dataFieldDefaultData,
                })
              } else {
                // UPDATE
                res = await extendFieldApi.updateExtendFields({
                  fields: [
                    {
                      fieldId: configDataFieldId,
                      dataType: dataFieldDataType,
                      label: dataFieldName,
                      labelEn: dataFieldNameEn,
                      defaultValue: dataFieldDefaultData,
                    },
                  ],
                })
              }

              let extendFieldRes =
                action == 'CREATE' ? res?.extendField : res?.extendFields?.at(0)
              if (extendFieldRes) {
                let {
                  name,
                  regionName,
                  dataType,
                  label,
                  labelEn,
                  defaultValue,
                } = extendFieldRes
                dispatch(thunkGetExtendFields())

                if (action == 'CREATE') {
                  dispatch(
                    setInstanceInfo({
                      extendFields: [
                        ...instanceExtendFields,
                        {
                          name,
                          regionName,
                          dataType,
                          label,
                          labelEn,
                          defaultValue,
                        },
                      ],
                    }),
                  )

                  if (regionName == 'tableDetail') {
                    setTableDetailColumns((old) => [
                      ...old,
                      { dataIndex: name, key: name },
                    ])
                  } else if (regionName == 'tableDetail1') {
                    setTableDetail1Columns((old) => [
                      ...old,
                      { dataIndex: name, key: name },
                    ])
                  }

                  let colMetadata = {
                    fontSize: 16,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }
                  dispatch(
                    addNewDataFieldToRegionConfigs({
                      regionName: regionName,
                      newDataField: {
                        dataField: name,
                        value: {
                          value: '',
                          editable: false,
                        },
                        label: {
                          value: label,
                          editable: false,
                          ...(['tableDetail', 'tableDetail1'].includes(
                            regionName,
                          )
                            ? colMetadata
                            : {}),
                        },
                        labelEn: {
                          fontStyle: 'italic',
                          value: labelEn,
                          editable: false,
                          ...(['tableDetail', 'tableDetail1'].includes(
                            regionName,
                          )
                            ? colMetadata
                            : {}),
                        },
                        show: true,
                        mergeField: '',
                        typeShow: /* ['tableDetail', 'tableDetail1'].includes(regionName) ? 2 : */ 3,
                      },
                    }),
                  )

                  ToastHelper.showSuccess('Thêm mới trường mở rộng thành công')
                } else {
                  // UPDATE
                  dispatch(
                    setInstanceInfo({
                      extendFields: [
                        ...instanceExtendFields.map((item) =>
                          item.fieldId == configDataFieldId
                            ? {
                                ...item,
                                dataType,
                                label,
                                labelEn,
                                defaultValue,
                              }
                            : item,
                        ),
                      ],
                    }),
                  )

                  dispatch(
                    setSpecifiedDataFieldProperty({
                      regionName: activeRegion.regionName,
                      dataFieldName: activeDataField.dataField,
                      propertyName: 'label',
                      propertyData: {
                        value: label,
                      },
                    }),
                  )
                  dispatch(
                    setSpecifiedDataFieldProperty({
                      regionName: activeRegion.regionName,
                      dataFieldName: activeDataField.dataField,
                      propertyName: 'labelEn',
                      propertyData: {
                        value: labelEn,
                      },
                    }),
                  )

                  ToastHelper.showSuccess('Sửa trường mở rộng thành công')
                }
                dispatch(setConfigDynamicFieldPanel(false))
              }
            }}
          >
            Lưu
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfigDataFieldPanel
