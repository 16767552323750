import { useTranslation } from 'react-i18next'
import { Result, Button, Space } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useRedux'
import { useCallback, useEffect, useState } from 'react'
import {
  ButtonSave,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalCustom,
  StyleButtonClose,
} from 'features/TaxDeduction/styled'
import { ContainerContentModal } from 'features/Department/styled'
import AddAccountingDocuments from 'features/TaxDeduction/screens/addAccountingDocuments'
import Update from 'features/Taxpayer/component/Update/index'
import { FormCustom } from 'features/TaxDeductionDocuments/styled'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  thunkAutomaticallyTaxpayer,
  thunkSaveTaxpayer,
} from 'features/Taxpayer/taxpayerSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import AppData from 'general/constants/AppData'
import { debounce } from 'lodash'
function PopupSaveTaxpayer({
  visible,
  setVisible,
  taxpayerSelect,
  setTaxpayerSelect,
  onSave,
  form,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formState, setFormState] = useState({})
  const dispatch = useAppDispatch()

  useEffect(() => {
    start()
  }, [visible])

  const start = async () => {
    let result = await getAutomatically()
    await resetForm(result)
  }

  const getAutomatically = async () => {
    try {
      const result = unwrapResult(
        await dispatch(thunkAutomaticallyTaxpayer({})),
      )
      if (result?.message == 'success') {
        return result.data
      } else {
        return {}
      }
    } catch (error) {}
  }

  const resetForm = async (values) => {
    console.log('values', values)
    // setAutomatically(values)
    let data = {
      employeeType: 1,
      employeeCode: values?.taxpayersCodeEmployee ?? 'NV0000001',
      documentType: 1,
      gender: 'Nam',
      employeeIdentification_nationlityId: 1,
      contractType: AppData.contractType[0],
      employeeIdentification_countryId: 1,
      employeeAddress: '',
      statusAddressCurrent: [],
    }
    form.setFieldsValue(data)
    setFormState(data)
  }

  const submit = () => {
    form
      .validateFields()
      .then((data) => {
        save(data)
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo)
        const errorFields = errorInfo?.errorFields
        if (errorFields.length > 0) {
          const fieldName = errorFields[0].name[0] // Get the name of the first field that failed validation
          const fieldInstance = form.getFieldInstance(fieldName) // Get the instance of that field
          if (fieldInstance) {
            fieldInstance.focus() // Focus the field
          }
        }
      })
  }

  const save = async (body) => {
    const result = unwrapResult(await dispatch(thunkSaveTaxpayer(body)))
    console.log('data', result)
    if (result?.message === 'success') {
      ToastHelper.showSuccess(`${t('taxpayer.messageNnt')}`)
      onSave(result?.data)
    } else {
      ToastHelper.showError(result?.data)
    }
  }

  const debouncedSetFormState = useCallback(
    debounce((dataForm) => {
      setFormState(dataForm)
    }, 0), // 300ms delay
    [],
  )

  const onValuesChange = (changedValues, allValues) => {
    debouncedSetFormState(allValues)
  }

  return (
    <>
      <ModalCustom
        title={t('taxpayer.addTitle')}
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        width={1200}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    setVisible(false)
                  }}
                >
                  {t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  onClick={() => {
                    submit()
                  }}
                >
                  {t('taxDeductionDocuments.dy')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <ContainerContentModal padding="0px 20px 0px 20px">
          <FormCustom form={form} onValuesChange={onValuesChange}>
            <Update
              formState={formState}
              form={form}
              popup
              setFormState={(value) => {
                setFormState(value)
              }}
            />
          </FormCustom>
        </ContainerContentModal>
      </ModalCustom>
    </>
  )
}
export default PopupSaveTaxpayer
