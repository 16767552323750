import axiosClient from './axiosClient'
const jobtitleApi = {
  getAll: (params) => {
    const url = '/job-title/getAll'
    return axiosClient.get(url, { params })
  },
  update: (body) => {
    const url = '/job-title/update'
    return axiosClient.post(url, { body })
  },
  remove: (params) => {
    const url = '/job-title/remove'
    return axiosClient.delete(url, { params })
  },
}
export default jobtitleApi
