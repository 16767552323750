import BaseTextField from 'general/components/Forms/BaseTextField'
import './style.scss'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CustomButton from 'general/components/Button/CustomButton'
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import PropTypes from 'prop-types'
import FormLabel from 'general/components/Forms/FormLabel'
import KTFormInput, {
  KTFormInputBTDPickerType,
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import moment from 'moment'
import Utils from 'general/utils/Utils'
import { useDropzone } from 'react-dropzone'
import GroupTextField from 'general/components/Forms/GroupTextTextField'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import ToastHelper from 'general/helpers/ToastHelper'

CancelInvoiceModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onExistDone: PropTypes.func,
  title: PropTypes.string,
  describeText: PropTypes.string,
  buttonAgreeOnlick: PropTypes.func,
  invoice: PropTypes.object,
  isInvoice: PropTypes.bool,
}

CancelInvoiceModal.defaultProps = {
  title: 'Xác nhận',
  show: false,
  onClose: null,
  describeText:
    'Bạn cần hủy hóa đơn bị sai sót trước khi lập hóa đơn thay thế. Bạn có muốn hủy hóa đơn này không?',
  onExistDone: null,
  isInvoice: true,
}

function CancelInvoiceModal(props) {
  const {
    title,
    describeText,
    invoiceId,
    show,
    buttonAgreeOnClick,
    invoice,
    onHide,
    isInvoice,
    ticket,
    ...restProps
  } = props
  const today = new Date()

  const [showCc, setShowCc] = useState(false)
  const [showBcc, setShowBcc] = useState(false)

  const validationSchema = Yup.object().shape({
    errAnnouceReason: Yup.string().required('Không được để trống'),
    sendEmailToCustomer: Yup.boolean(),
    customerEmail: Yup.string()
      .email('Email không hợp lệ')
      .when('sendEmailToCustomer', {
        is: true,
        then: Yup.string().required('Vui lòng điền email'),
      }),
    // .required('Vui lòng điền email'),
    // customerTaxCode: Yup.string().required(t('Required')),
  })
  const formik = useFormik({
    initialValues: {
      errAnnouceReason: '',
      cancelDate: moment(today).format('YYYY-MM-DD'),
      sendEmailToCustomer: false,
      customerName: invoice?.customerName ?? '',
      customerEmail: invoice?.customerEmail ?? '',
      customerEmailCc: '',
      customerEmailBcc: '',
    },
    // validationSchema,
    onSubmit: async (values) => {},
    enableReinitialize: true,
    validateOnChange: false,
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
    },
    maxFiles: 1,
    onDrop: async (files) => {
      await new Promise((resolve) => {
        const img = new Image()
        img.src = URL.createObjectURL(files[0])
        img.onload = ({ target: img }) => {
          const { width, height } = img

          let logoContainer = document.getElementById('lc-frame')
          let containerWidth = logoContainer?.clientWidth
          let containerHeight = logoContainer?.clientHeight

          let imageBiggerThanContainer =
            width > containerWidth * 0.96 || height > containerHeight
          let scalingFactor = imageBiggerThanContainer
            ? Math.min(
                (containerWidth * 0.96) / width,
                containerHeight / height,
              )
            : 1
          let imageWidth = scalingFactor * width
          let imageHeight = scalingFactor * height
          let x = (containerWidth - width * scalingFactor) / 2
          let y = (containerHeight - height * scalingFactor) / 2

          // dispatch(
          //   setCustomBackgroundInfo({
          //     file: files[0],
          //     opacity: 35,
          //     position: 'center',
          //     x,
          //     y,
          //     width: imageWidth,
          //     height: imageHeight,
          //   }),
          // )
          resolve()
        }
      })
    },
  })

  return (
    <Modal
      className="CancelInvoiceModal font-sfpro-regular"
      {...restProps}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-bottom-0" closeButton>
        <span className="font-weight-bolder" style={{ fontSize: '18px' }}>
          {title}
        </span>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div
          className="d-flex flex-row justify-content-between p-3"
          style={{ backgroundColor: '#F3F5F7' }}
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Ký hiệu:
              </FormLabel>
              <span style={{ fontSize: '14px', color: '#333333' }}>
                {isInvoice ? invoice?.serial : ticket?.serial}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Số hoá đơn:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {isInvoice ? invoice?.no : ticket?.no}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Mã của CQT:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {isInvoice
                  ? invoice?.taxAuthorityCode
                  : ticket?.taxAuthorityCode}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Ngày hóa đơn:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {Utils.formatDate(ticket?.dateRelease, 'DD-MM-YYYY')}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-start">
              <FormLabel height="30px" width="120px" fontWeight="600">
                Khách hàng:
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '400',
                }}
              >
                {invoice?.customerCompanyName}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end justify-content-between">
            <div className="d-flex flex-column align-items-end">
              <FormLabel height="30px" width="100%" fontWeight="500">
                Tổng tiền thanh toán
              </FormLabel>
              <span
                style={{
                  fontSize: '14px',
                  color: '#333333',
                  fontWeight: '700',
                }}
              >
                {isInvoice
                  ? Utils.formatNumber(invoice?.totalAfterTax)
                  : Utils.formatNumber(ticket?.totalPrice)}
                {invoice?.currency}
              </span>
            </div>

            {invoice?.invoiceErrAnnouceStatus != 5 && (
              <span
                className="badge badge-pill mb-2"
                style={{
                  fontSize: '1.1em',
                  color: InvoiceHelper.cancelInvoiceWarning(
                    invoice?.invoiceErrAnnouceStatus,
                  )?.color,
                  backgroundColor: InvoiceHelper.cancelInvoiceWarning(
                    invoice?.invoiceErrAnnouceStatus,
                  )?.bgColor,
                }}
              >
                <i
                  class="fa-regular fa-circle-exclamation"
                  style={{ color: 'inherit' }}
                ></i>{' '}
                {
                  InvoiceHelper.cancelInvoiceWarning(
                    invoice?.invoiceErrAnnouceStatus,
                  )?.text
                }
              </span>
            )}
          </div>
        </div>

        {invoice?.invoiceErrAnnouceStatus != 5 && (
          <div
            className="border p-3"
            style={{
              fontSize: '1.1em',
              backgroundColor: '#fbfbe3',
              color: '#333',
            }}
          >
            <i
              className="fa-light fa-triangle-exclamation text-warning mr-2"
              style={{ fontSize: '1.4em' }}
            ></i>
            {invoice?.serial?.at(1) == 'C'
              ? 'Hóa đơn đã được cấp mã bởi cơ quan thuế, DN cần làm TBSS trước khi hủy hóa đơn theo quy định của CQT (TT78 -NĐ 123)'
              : 'Hóa đơn đã được cơ quan thuế xác nhận hợp lệ, DN cần làm TBSS trước khi hủy hóa đơn theo quy định của CQT (TT78 -NĐ 123)'}
            {/* Hóa đơn đã được duyệt bởi cơ quan thuế (hoặc phân biệt được 2 TH có
            mã thì là được cấp mã CQT, không mã thì là : CQT xác nhận hợp lệ) DN
            cần làm TBSS trước khi hủy hóa đơn theo quy định của CQT (TT78 -NĐ
            123) */}
          </div>
        )}

        <div className="d-flex flex-column pt-3" style={{ gap: '10px' }}>
          <div className="d-flex flex-row">
            <FormLabel height="30px" width="130px" fontWeight="500">
              Ngày huỷ:
            </FormLabel>
            {/* <KTFormInput
              btdPickerNoIcon={false}
              name="cancelDate"
              value={formik.getFieldProps('date').value}
              onChange={(value) => {
                formik.setFieldValue('cancelDate', value);
              }}
              btdPickerType={KTFormInputBTDPickerType.date}
              type={KTFormInputType.btdPicker}
              // placeholder={`${_.capitalize(t('SendingDate'))}...`}
            /> */}
            <KTFormInput
              name="1"
              value={formik.getFieldProps('cancelDate').value}
              onChange={(value) => {
                formik.setFieldValue('cancelDate', value)
              }}
              onFocus={() => {
                formik.setFieldTouched('cancelDate', true)
              }}
              enableCheckValid
              btdPickerType={KTFormInputBTDPickerType.date}
              // isValid={_.isEmpty(meta.error)}
              // isTouched={meta.touched}
              // feedbackText={meta.error}
              type={KTFormInputType.date}
              // disabled={!canEdit}
            />
          </div>

          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel height="30px" width="130px" fontWeight="500">
              Lý do huỷ
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </FormLabel>
            <div className="w-100">
              <BaseTextField
                className="w-100"
                name="errAnnouceReason"
                fieldProps={formik.getFieldProps('errAnnouceReason')}
                fieldMeta={formik.getFieldMeta('errAnnouceReason')}
                fieldHelper={formik.getFieldHelpers('errAnnouceReason')}
              />
            </div>
          </div>

          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel height="30px" width="130px" fontWeight="500"></FormLabel>

            <div className="w-100">
              <div
                {...getRootProps({
                  className: 'dropzone',
                })}
                className="d-inline-flex flex-row justify-content-center align-items-center bg-secondary p-3 rounded cursor-pointer"
              >
                <input
                  {...getInputProps()}
                  id="upload-custom-background-input"
                />
                <i
                  className="fa-regular fa-paperclip-vertical mr-2"
                  style={{ fontSize: `1.2em`, color: 'inherit' }}
                ></i>
                <p className="mb-0">Đính kèm biên bản</p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row align-items-center cursor-pointer">
            <input
              className="mr-3 cursor-pointer"
              type="checkbox"
              id="send-email-to-customer"
              checked={formik.values.sendEmailToCustomer}
              onChange={(e) => {
                formik.setFieldValue('sendEmailToCustomer', !!e.target.checked)
              }}
              style={{ width: '1.3rem', height: '1.3rem' }}
            />
            <label
              className="m-0 cursor-pointer font-weight-bold"
              htmlFor="send-email-to-customer"
            >
              Gửi thông báo huỷ cho khách hàng
            </label>
          </div>

          <div className="d-flex flex-row align-items-center">
            <FormLabel height="30px" width="130px" fontWeight="500">
              Tên người nhận:
              {!!formik.values?.sendEmailToCustomer && (
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              )}
            </FormLabel>
            <div className="w-100">
              <BaseTextField
                className="w-100"
                name="customerName"
                disabled={!formik.values.sendEmailToCustomer}
                fieldProps={formik.getFieldProps('customerName')}
                fieldMeta={formik.getFieldMeta('customerName')}
                fieldHelper={formik.getFieldHelpers('customerName')}
              />
            </div>
          </div>

          <div className="d-flex flex-row align-items-start justify-content-start w-100">
            <FormLabel
              className="mt-1"
              height="30px"
              width="130px"
              fontWeight="500"
            >
              Email
              {!!formik.values?.sendEmailToCustomer && (
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              )}
            </FormLabel>
            <div className="w-100">
              <GroupTextField
                className="w-100"
                name="customerEmail"
                disabled={!formik.values.sendEmailToCustomer}
                fieldProps={formik.getFieldProps('customerEmail')}
                fieldMeta={formik.getFieldMeta('customerEmail')}
                fieldHelper={formik.getFieldHelpers('customerEmail')}
                inputGroupEnd={
                  <div className="h-100 p-2 border-left d-flex align-items-center">
                    <span
                      className={`${!showCc ? 'cursor-pointer text-blue' : ''}`}
                      onClick={
                        formik.values.sendEmailToCustomer
                          ? () => setShowCc(true)
                          : () => {}
                      }
                    >
                      Cc
                    </span>
                    <span className="vr-span mx-2"></span>
                    <span
                      className={`${
                        !showBcc ? 'cursor-pointer text-blue' : ''
                      }`}
                      onClick={
                        formik.values.sendEmailToCustomer
                          ? () => setShowBcc(true)
                          : () => {}
                      }
                    >
                      Bcc
                    </span>
                  </div>
                }
              />

              {showCc && (
                <GroupTextField
                  className="mt-4"
                  name="customerEmailCc"
                  fieldProps={formik.getFieldProps('customerEmailCc')}
                  fieldMeta={formik.getFieldMeta('customerEmailCc')}
                  fieldHelper={formik.getFieldHelpers('customerEmailCc')}
                  inputGroupText="Cc"
                  placeholder="Mỗi email cách nhau bởi dấu ;"
                />
              )}

              {showBcc && (
                <GroupTextField
                  className="mt-4"
                  name="customerEmailBcc"
                  fieldProps={formik.getFieldProps('customerEmailBcc')}
                  fieldMeta={formik.getFieldMeta('customerEmailBcc')}
                  fieldHelper={formik.getFieldHelpers('customerEmailBcc')}
                  inputGroupText="Bcc"
                  placeholder="Mỗi email cách nhau bởi dấu ;"
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Body className="pt-0">
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ height: '70px' }}
        >
          <span className="font-weight-bolder" style={{ fontSize: '18px' }}>
            {title}
          </span>
        </div>
        <span style={{ fontSize: '14px' }}>Bạn có muốn huỷ hoá đơn này không</span>
      </Modal.Body> */}

      <Modal.Footer className="py-2">
        <CustomButton
          backgroundColor="white"
          text="Huỷ bỏ"
          width="90px"
          color="#304FFD"
          onClick={props.onHide}
        ></CustomButton>
        <CustomButton
          backgroundColor="#304FFD"
          text="Đồng ý"
          width="90px"
          color="white"
          onClick={() => {
            if (!formik.values.errAnnouceReason) {
              ToastHelper.showError('Vui lòng nhập lý do huỷ')
              return
            }
            // debugger
            if (formik.values.sendEmailToCustomer) {
              if (!formik.values.customerEmail) {
                ToastHelper.showError('Vui lòng nhập email')
                return
              }
              if (!Utils.isValidEmail(formik.values.customerEmail)) {
                ToastHelper.showError('Email sai định dạng')
                return
              }
              // if (
              //   formik.values.customerEmailCc &&
              //   !Utils.isValidEmail(formik.values.customerEmailCc)
              // ) {
              //   ToastHelper.showError('Email sai định dạng')
              //   return
              // }
            }
            buttonAgreeOnClick(
              formik.values,
              // invoice.invoiceId,
            )
          }}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default CancelInvoiceModal
