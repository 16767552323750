import { Flex } from 'antd'
import Utils from 'general/utils/Utils'

/** @param {Object} param
 * @param {import('react').ReactNode} param.children
 * @param {import('react').ReactNode} [param.tabs]
 * @param {string} [param.wrapperClassName]  */
function ContentContainer({ children, tabs, wrapperClassName }) {
  return (
    <div className={Utils.cn('p-3 d-flex h-100 flex-column', wrapperClassName)}>
      {tabs && <div className="w-100 p-2">{tabs}</div>}
      <div className="card card-custom w-100 flex-grow-1 overflow-auto">
        {children}
      </div>
    </div>
  )
}

function ContainerHeader({ titleContent, description, children, toolBar }) {
  return (
    <div className={`card-header border-0`}>
      <Flex
        wrap="wrap"
        justify="space-between"
        gap={'5px'}
        style={{ width: '100%', fontFamily: 'unset' }}
      >
        <div className="card-title my-0">
          <h3 className="">
            {titleContent}
            {description && (
              <span className="pt-2 font-size-sm d-block">{description}</span>
            )}
          </h3>
        </div>
        {toolBar && (
          <div className="card-toolbar flex-nowrap gap-2">{toolBar}</div>
        )}
      </Flex>
      {children}
    </div>
  )
}

function ContainerBody({ children, className }) {
  return (
    <div
      className={Utils.cn(
        'card-body p-5 d-flex flex-column h-100 justify-content-between',
        className,
      )}
    >
      {children}
    </div>
  )
}

ContentContainer.Header = ContainerHeader
ContentContainer.Body = ContainerBody

export default ContentContainer
