import { Card, Flex, Image, List, Space, Typography } from 'antd'
import systemApi from 'api/systemApi'
import { setAppSpinning } from 'app/appSlice'
import HSMBanner from 'assets/images/Einvoice/hsm-banner.png'
import HSM from 'assets/images/Einvoice/hsm.svg'
import USBToken from 'assets/images/Einvoice/usb.svg'
import ModalConfirmCancel from 'features/System/components/Modals/ModalConfirmCancel'
import ModalConnectConfig from 'features/System/components/Modals/ModalConnectConfig'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
const { Body, Header } = ContentContainer
const linkDownload = `${process.env.REACT_APP_BASE_URL}/resources/einvoice_tool/vietinvoiceSign.msi`

export default function DigitalSignPreference() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeSigningMethod, hsmAgreementUUID, companyId } = useAppSelector(
    (state) => state.system.company,
  )

  const [activeId, setActiveId] = useState(null)
  const [modalState, setModalState] = useState({
    cancelConnect: false,
    connectConfig: false,
  })

  const methods = useMemo(
    () => [
      {
        id: 'USB_TOKEN',
        title: 'Kí số trực tiếp qua USB TOKEN',
        thumbnail: USBToken,
        content: (
          <ol className="pl-4">
            <li>
              Máy tính bạn đang dùng cần cài đặt công cụ <b>VietinvoiceSign</b>.
            </li>
            <li>
              Bạn cần cắm USB chứa chứng thư số khi thực hiện <b>ký số</b>.
            </li>
          </ol>
        ),
        isActive: activeSigningMethod === DIGITAL_SIGN_METHOD.USB,
        actions: (
          <Space>
            <CustomAntButton
              text="Tải về VietinvoiceSign"
              antProps={{
                className: 'btn btn-active-primary',
                onClick: () => open(linkDownload, '_blank'),
              }}
              customStyle={{
                boxShadow: 'none',
              }}
            />
          </Space>
        ),
      },
      {
        id: 'HSM',
        title: 'Chữ ký số HSM',
        thumbnail: HSM,
        isActive: activeSigningMethod === DIGITAL_SIGN_METHOD.HSM,
        content: (
          <>
            <Typography.Paragraph>
              Ký số thông qua máy chủ chữ ký số HSM
            </Typography.Paragraph>
            {activeSigningMethod === DIGITAL_SIGN_METHOD.HSM ? (
              <div className="bg-secondary p-3">
                <Flex align="center" gap={'1rem'} className="w-100">
                  <Image
                    src={HSMBanner}
                    alt="cyber hsm"
                    preview={false}
                    width={150}
                    height={100}
                    className="object-fit-contain"
                  />
                  <div className="d-flex flex-column py-4">
                    <span>Thông tin kết nối</span>
                    <b>{hsmAgreementUUID}</b>
                  </div>
                </Flex>
              </div>
            ) : null}
          </>
        ),
      },
    ],
    [activeSigningMethod, hsmAgreementUUID],
  )

  const handleConfirmCancel = async () => {
    try {
      await systemApi.setHSMCredential({
        hsmAgreementUUID: '',
        hsmPasscode: '',
      })
      await systemApi.setActiveSigningMethod({
        activeSigningMethod: DIGITAL_SIGN_METHOD.USB,
      })
    } catch (e) {
      console.log(e)
    }

    dispatch(thunkGetCompanyProfile({ companyId }))
    ToastHelper.showSuccess('Hủy kết nối chữ ký số HSM thành công!')

    setModalState((prev) => ({
      ...prev,
      cancelConnect: false,
    }))
  }

  const handleConnectConfig = async (values) => {
    dispatch(setAppSpinning(true))
    try {
      if (activeId == 'HSM') {
        await systemApi.setHSMCredential({
          hsmAgreementUUID: values.agreementUUID,
          hsmPasscode: values.passcode,
        })
        await systemApi.setActiveSigningMethod({
          activeSigningMethod: 'HSM',
        })
      } else {
        if (hsmAgreementUUID) {
          await systemApi.setHSMCredential({
            hsmAgreementUUID: '',
            hsmPasscode: '',
          })
        }
        await systemApi.setActiveSigningMethod({
          activeSigningMethod: DIGITAL_SIGN_METHOD.USB,
        })
      }

      dispatch(setAppSpinning(false))
      dispatch(thunkGetCompanyProfile({ companyId }))
      ToastHelper.showSuccess(
        `Kết nối chữ ký số ${
          activeId == 'HSM' ? 'HSM' : 'USB TOKEN'
        } thành công!`,
      )

      setModalState({
        ...modalState,
        connectConfig: false,
      })
    } catch (error) {
      dispatch(setAppSpinning(false))
      console.error(error)
      throw new Error(error.message)
    }
  }

  return (
    <ContentContainer wrapperClassName="digital-sign-screen">
      <Header
        titleContent={t('DigitalSignPreferences')}
        description={'Vui lòng chọn hình thức ký số của đơn vị đang sử dụng'}
      ></Header>
      <Body>
        <List
          grid={{ column: 1, gutter: 16 }}
          dataSource={methods}
          renderItem={({
            title,
            thumbnail,
            content,
            actions,
            isActive,
            id,
          }) => (
            <List.Item>
              <Card
                title={
                  <div>
                    <Image
                      src={thumbnail}
                      alt={title}
                      preview={false}
                      width={30}
                      height={30}
                      className="rounded-circle"
                    />
                    {title}
                  </div>
                }
                extra={
                  isActive ? (
                    <Space align="center" className="text-primary">
                      <i
                        className="fa-solid fa-check-circle"
                        style={{ color: 'inherit' }}
                      />
                      <Typography.Text strong style={{ color: 'inherit' }}>
                        Đang sử dụng
                      </Typography.Text>
                    </Space>
                  ) : null
                }
                className={Utils.cn(isActive ? 'border-primary' : '')}
              >
                {content}
                <Flex
                  justify="flex-end"
                  align="center"
                  gap={'1rem'}
                  className="pt-4"
                >
                  {actions}
                  <CustomAntButton
                    text={isActive ? 'Huỷ kết nối' : 'Sử dụng ngay'}
                    antProps={{
                      type: 'default',
                      danger: isActive,
                      disabled:
                        isActive &&
                        activeSigningMethod === DIGITAL_SIGN_METHOD.USB,
                      onClick: () => {
                        setActiveId(id)
                        id === 'HSM' &&
                        activeSigningMethod === DIGITAL_SIGN_METHOD.HSM
                          ? setModalState({
                              ...modalState,
                              cancelConnect: true,
                            })
                          : setModalState({
                              ...modalState,
                              connectConfig: true,
                            })
                      },
                    }}
                  />
                </Flex>
              </Card>
            </List.Item>
          )}
        />
      </Body>

      <ModalConfirmCancel
        title={'Huỷ kết nối HSM'}
        open={modalState.cancelConnect}
        onOpenChange={(state) =>
          setModalState({ ...modalState, cancelConnect: state })
        }
        description={
          'Sau khi hủy kết nối, hệ thống sẽ tự động chuyển hình thức ký số sang "Ký trực tiếp qua USB Token". Bạn có muốn tiếp tục không?'
        }
        onClick={handleConfirmCancel}
      />

      <ModalConnectConfig
        title={
          activeId === 'HSM'
            ? 'Thiết lập kết nối tới máy chủ ký số'
            : 'Thiết lập ký số qua USB Token'
        }
        method={activeId}
        open={modalState.connectConfig}
        onOpenChange={(state) =>
          setModalState({
            ...modalState,
            connectConfig: state,
          })
        }
        onApply={handleConnectConfig}
      />
    </ContentContainer>
  )
}
