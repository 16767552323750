import { useMutation } from '@tanstack/react-query'
import { Checkbox, Divider, Input, Space, Typography } from 'antd'
import axiosClient from 'api/axiosClient'
import { setAppSpinning } from 'app/appSlice'
import { useFormik } from 'formik'
import ToastHelper from 'general/helpers/ToastHelper'
import ValidationSchema from 'general/utils/ValidationSchema'
import { forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import DraggableModal from '../DragableModal/DraggableModal'

const ModalSendNoticeCancel = forwardRef(
  ({ isOpen, onOpenChange, invoiceId }, ref) => {
    console.log(invoiceId)
    const appDispatch = useDispatch()
    const initialValues = {
      customerEmail: '',
      customerName: '',
    }
    const validationSchema = ValidationSchema.sendMail
    const sendMailMutation = useMutation({
      mutationKey: ['sendMailNoticeCancel'],
      mutationFn: async (params) => {
        const { formValues, formHelper } = params
        const res = await axiosClient.post('/invoice/send-mail-notice-cancel', {
          ...formValues,
          invoiceId,
        })
        if (res.result !== 'success') {
          ToastHelper.showError('Đã có lỗi trong quá trình gửi mail')
          throw new Error(res.statusText ?? res.result)
        }
        onOpenChange(false)
        ToastHelper.showSuccess('Gửi email thành công')
        formHelper.resetForm({ values: initialValues })
      },
      onSettled: () => appDispatch(setAppSpinning(false)),
      onMutate: () => appDispatch(setAppSpinning(true)),
      // onError: (error) => ToastHelper.showError(error),
    })
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values, formHelper) => {
        sendMailMutation.mutate({
          formValues: values,
          formHelper,
        })
      },
      validateOnChange: false,
      validateOnBlur: true,
    })

    return (
      <DraggableModal
        title="Gửi thông báo huỷ"
        isOpen={isOpen}
        onOpenChange={(state) => {
          onOpenChange(state)
          formik.resetForm({
            values: initialValues,
          })
        }}
        handleApplyChanges={() => formik.submitForm()}
        ref={ref}
        applyText={'Gửi thông báo huỷ'}
        width={'40rem'}
      >
        <form onSubmit={formik.handleSubmit}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <div>
              <Typography.Text style={{ color: '#000' }} strong>
                Tên người nhận
              </Typography.Text>
              <Input
                style={{ height: 38 }}
                {...formik.getFieldProps('customerName')}
                placeholder="Nhập tên người nhận"
              />
              <Typography.Text type="danger">
                {formik.getFieldMeta('customerName').error
                  ? formik.getFieldMeta('customerName').error
                  : ''}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text
                style={{ color: '#000' }}
                strong
                type={
                  formik.getFieldMeta('email').error ? 'danger' : 'secondary'
                }
              >
                Email
                <span className="ml-1 text-danger">*</span>
              </Typography.Text>
              <Input
                style={{ height: 38 }}
                {...formik.getFieldProps('customerEmail')}
                placeholder="Nhập email cách nhau bởi dấu chấm phẩy"
                status={formik.getFieldMeta('customerEmail').error && 'error'}
              />
              <Typography.Text type="danger">
                {formik.getFieldMeta('customerEmail').error
                  ? formik.getFieldMeta('customerEmail').error
                  : ''}
              </Typography.Text>
            </div>
            <Typography.Text type="danger">
              {formik.getFieldMeta('type').error
                ? formik.getFieldMeta('type').error
                : ''}
            </Typography.Text>
          </Space>
        </form>
      </DraggableModal>
    )
  },
)

export default ModalSendNoticeCancel
