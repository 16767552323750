import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import _ from 'lodash'
import FolderTree from 'general/components/eInvoiceComponents/FolderTree'
import KeenSearchBarNoFormik from 'general/components/KeenSearchBarNoFormik'
import { useTranslation } from 'react-i18next'

ModalChooseCompanyUnit.propTypes = {
  openChildrenModal: PropTypes.func,
  setCompanyUnitItem: PropTypes.func,
  companyUnitItem: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  openModalCreateCompanyUnit: PropTypes.func,
}

ModalChooseCompanyUnit.defautProps = {
  openChildrenModal: null,
  setCompanyUnitItem: null,
  companyUnitItem: null,
  show: false,
  onClose: null,
  openModalCreateCompanyUnit: null,
}

function ModalChooseCompanyUnit(props) {
  // MARK: --- Params ---
  const {
    openChildrenModal,
    companyUnitItem,
    setCompanyUnitItem,
    show,
    onClose,
    openModalCreateCompanyUnit,
  } = props
  const { companyUnits } = useSelector((state) => state.category)
  const [dataItems, setDataItems] = useState(
    companyUnits?.filter(
      (item) => item?.companyUnitId !== companyUnitItem?.companyUnitId,
    ),
  )
  const [showing, setShowing] = useState(true)
  const [filter, setFilter] = useState('')
  const { t } = useTranslation()

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose()
    }
  }

  function handleOpendChildrenModal() {
    if (openChildrenModal) {
      openChildrenModal()
    }
  }

  function handleOpenModalCreateCompanyUnit() {
    if (openModalCreateCompanyUnit) {
      openModalCreateCompanyUnit()
    }
  }

  function handleSetUnitId(item) {
    if (setCompanyUnitItem) {
      setCompanyUnitItem(item)
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    const text = _.lowerCase(filter)
    let filteredItems = _.filter(companyUnits, function (item) {
      return (
        _.lowerCase(item.name).includes(text) &&
        item?.companyUnitId !== companyUnitItem?.companyUnitId
      )
    })
    setDataItems(filteredItems)
  }, [filter, companyUnits])

  return (
    <Modal
      className="ModalChooseCompanyUnit"
      show={show && showing}
      size="lg"
      onHide={handleClose}
      centered
      onExited={() => {
        // formik.handleReset();
      }}
    >
      {/* header */}
      <Modal.Header className="px-5 py-5 d-flex align-items-center justify-content-center position-relative">
        <Modal.Title className="">{t('ChooseCompanyUnit')}</Modal.Title>
        <div
          className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer position-absolute right-0 mr-5"
          onClick={handleClose}
        >
          <i className="far fa-times"></i>
        </div>
      </Modal.Header>

      {/* body */}
      <Modal.Body className="bg-light">
        {companyUnits?.length > 0 ? (
          <div className="border">
            <div className="p-3 bg-white">
              <KeenSearchBarNoFormik
                name="companyUnitSearch"
                className="mr-0"
                placeholder={t('Search')}
                value={filter}
                onSubmit={(text) => {
                  setFilter(text)
                }}
              />
            </div>
            <FolderTree
              // currentItem={companyUnitItem}
              data={dataItems}
              setCompanyUnitItem={(item) => {
                handleSetUnitId(item)
              }}
            />
          </div>
        ) : (
          <div className="d-flex aling-items-center justify-content-center">
            <div
              className="btn btn-success"
              onClick={() => {
                handleOpenModalCreateCompanyUnit()
              }}
              style={{ whiteSpace: 'nowrap', maxWidth: '200px' }}
            >
              <i className="fas fa-plus-circle"></i>
              <span>{t('NewCompanyUnit')}</span>
            </div>
          </div>
        )}
      </Modal.Body>

      {/* footer */}
      <Modal.Footer>
        <div className="w-100 d-flex row">
          <Button
            className="font-weight-bold flex-grow-1 col mr-3"
            variant="secondary"
            onClick={() => {
              if (openChildrenModal) {
                handleOpendChildrenModal()
              } else {
                handleClose()
              }
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            className={`font-weight-bold flex-grow-1 col ml-3`}
            variant="primary"
            onClick={() => {
              handleClose()
              // formik.handleSubmit();
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalChooseCompanyUnit
