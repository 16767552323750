import { createSlice } from '@reduxjs/toolkit'

// MARK: --- Thunks ---

export const importTicketSlice = createSlice({
  name: 'importTicket',
  initialState: {
    currentStep: 1,
    selectedFile: null,
    selectedSheetName: null,
    sheetNamesOption: [],
    selectedSerial: null,
    headerIndex: 8,
    excelData: {
      headers: [],
      rows: [],
    },
    checkRowsInfo: null,
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload

      state.selectedSheetName = null
      state.sheetNamesOption = []
      state.excelData = {
        headers: [],
        rows: [],
      }
    },
    setSelectedSheetName: (state, action) => {
      state.selectedSheetName = action.payload
    },
    setSheetNamesOption: (state, action) => {
      state.sheetNamesOption = action.payload
    },
    setSelectedSerial: (state, action) => {
      state.selectedSerial = action.payload
    },
    setHeaderIndex: (state, action) => {
      state.headerIndex = action.payload
    },
    setExcelData: (state, action) => {
      state.excelData = action.payload
    },
    setCheckRowsInfo: (state, action) => {
      state.checkRowsInfo = action.payload
    },
  },
  extraReducers: {},
})

const { reducer, actions } = importTicketSlice
export const {
  setCurrentStep,
  setSelectedFile,
  setSheetNamesOption,
  setSelectedSheetName,
  setSelectedSerial,
  setHeaderIndex,
  setExcelData,
  setCheckRowsInfo,
} = actions
export default reducer
