import map from 'lodash/map'
import CustomAntButton from '../Button/CustomAntButton'

// import './style.scss'
export default function CustomSegmented({
  defaultValue,
  options,
  onChange,
  fullWidth = false,
}) {
  return (
    <div
      className="d-flex flex-row align-items-center bg-secondary rounded-3"
      style={{
        width: fullWidth ? '100%' : 'fit-content',
      }}
    >
      {map(options, (option, idx) => (
        <CustomAntButton
          key={'segment-' + idx}
          text={option}
          antProps={{
            onClick: () => onChange(option),
            type: defaultValue === option ? 'primary' : 'text',
          }}
          customStyle={{
            width: '100%',
            fontWeight: 500,
          }}
        />
      ))}
    </div>
  )
}
