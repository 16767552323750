const { default: axiosClient } = require('./axiosClient')

const invoiceApprovalProcessApi = {
  // Lấy các invoiceApprovalProcess theo companyId
  getAllByCompanyId: (params) => {
    const url = '/invoiceApprovalProcess/getAllByCompanyId'
    return axiosClient.get(url, { params })
  },

  // Cập nhật nhiều invoiceApprovalProcess
  update: (params) => {
    const url = '/invoiceApprovalProcess/update'
    return axiosClient.put(url, params)
  },

  // Tạo nhiều invoiceApprovalProcess
  create: (params) => {
    const url = '/invoiceApprovalProcess/create'
    return axiosClient.post(url, params)
  },

  // Xóa các invoiceApprovalProcess
  deleteProcess: (params) => {
    const url = '/invoiceApprovalProcess/deleteProcess'
    return axiosClient.delete(url, { data: { params } })
  },
}

export default invoiceApprovalProcessApi
