import axiosClient from './axiosClient'

const invoiceApi = {
  create: (params) => {
    const url = '/invoice/create'
    return axiosClient.post(url, params)
  },
  checkExternal: (params) => {
    const url = '/invoice/check-external'
    return axiosClient.post(url, params)
  },
  bulkCreateExternal: (params) => {
    const url = '/invoice/bulk-create-external'
    return axiosClient.post(url, params)
  },
  listAll: (params, signal) => {
    const url = '/invoice/list-all'
    return axiosClient.get(url, { params, signal })
  },
  latest: (params, signal) => {
    const url = '/invoice/latest'
    return axiosClient.get(url, { params, signal })
  },
  findById: (id, jwtToken) => {
    let url = `/invoice/find-by-id?invoiceId=${id}`
    if (jwtToken) {
      url += `&jwtToken=${jwtToken}`
    }
    return axiosClient.get(url)
  },
  findByLookupCode: (lookupCode) => {
    const url = `/invoice/find-by-lookup-code?lookupCode=${lookupCode}`
    return axiosClient.get(url)
  },
  update: (id, params) => {
    const url = `/invoice/update/${id}`
    return axiosClient.put(url, params)
  },
  updatePaidStatus: (id, params) => {
    const url = `/invoice/update-paid-status/${id}`
    return axiosClient.patch(url, params)
  },
  bulkUpdatePaidStatus: (body) => {
    return axiosClient.put('/invoice/update-paid-status-all', body)
  },
  bulkUpdate: (params) => {
    const url = `/invoice/bulk-update`
    return axiosClient.put(url, params)
  },
  delete: (ids) => {
    const url = `/invoice/delete`
    return axiosClient.delete(url, {
      data: {
        invoiceIds: ids,
      },
    })
  },
  download: async (params) => {
    const url = `/invoice/download`
    return axiosClient.get(url, { params, responseType: 'blob' })
  },
  print: async (body) => {
    const url = `/invoice/print`
    return axiosClient.post(url, body, { responseType: 'blob' })
  },
  downloadByLookupCode: (lookupCode) => {
    const url = `/invoice/download-by-lookup-code?lookupCode=${lookupCode}`
    return axiosClient.get(url)
  },
  checkMessage: (messageId) => {
    const url = `/invoice/check-message?messageId=${messageId}`
    return axiosClient.get(url, { messageId })
  },
  checkMessages: (params) => {
    const url = `/invoice/check-messages`
    return axiosClient.get(url, { params })
  },

  exportInvoice: (params) => {
    const url = '/invoice/export'

    return axiosClient.post(url, params, { responseType: 'blob' })
  },

  exportInvoiceById: (invoiceId) => {
    const url = `/invoice/export/${invoiceId}`

    return axiosClient.get(url, { responseType: 'blob' })
  },

  exportInvoiceByLookupCode: (lookupCode) => {
    const url = `/invoice/export-by-lookup-code?lookupCode=${lookupCode}`

    return axiosClient.get(url, { responseType: 'blob' })
  },

  convertPaper: (params) => {
    const url = '/invoice/convert-paper'

    return axiosClient.post(url, params, { responseType: 'blob' })
  },

  sendInvoiceToCustomer: (params) => {
    const url = '/invoice/send-invoice-to-customer'

    return axiosClient.post(url, params)
  },

  cancel: (id, params) => {
    const url = `/invoice/cancel/${id}`
    return axiosClient.post(url, params)
  },

  restoreToOrigin: (id) => {
    const url = `/invoice/restore-to-original/${id}`
    return axiosClient.put(url)
  },

  sendExplanationAnnounceToCustomer: (id, params) => {
    const url = `/invoice/send-explanation-announce-to-customer/${id}`

    return axiosClient.post(url, params)
  },

  findLatestInvoiceRelease: (invoiceTemplateId, params) => {
    const url = `/invoice/latest-invoice-template/${invoiceTemplateId}`
    return axiosClient.get(url, params)
  },

  upload: (params) => {
    const url = '/invoice/upload'
    let formData = new FormData()

    for (let [key, value] of Object.entries(params)) {
      formData.append(key, value)
    }

    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  sendBulkInvoiceToCustomer: (params) =>
    axiosClient.post('/invoice/sent-invoices', params),

  hsmInvoicesSign: (params) => {
    const url = '/invoice/hsm-invoices-sign'

    return axiosClient.post(url, params)
  },

  getTransactionData: (params) =>
    axiosClient.get('/invoice/transaction', {
      params,
    }),

  reIssue: (params) => {
    const url = '/invoice/re-issue'

    return axiosClient.post(url, params)
  },

  reSignHSM: (params) => {
    const url = '/invoice/re-sign-hsm'

    return axiosClient.post(url, params)
  },

  createFromLogPetro: (params) => {
    const url = '/invoice/create-from-log-petro'

    return axiosClient.post(url, params)
  },

  createFromLogPetros: (params) => {
    const url = '/invoice/create-from-log-petros'

    return axiosClient.post(url, params)
  },

  issueFromLogPetro: (params) => {
    const url = '/invoice/issue-from-log-petro'

    return axiosClient.post(url, params)
  },

  issueInvoicesMTT: (params) => {
    const url = '/invoice/issue-invoices-mtt'

    return axiosClient.post(url, params)
  },
  getInvoiceMttNeedSendTax: (params, signal) => {
    const url = '/invoice/get-invoice-mtt-need-send-tax'

    return axiosClient.get(url, {
      params,
      signal,
    })
  },
  hsmSignSummaryInvoice: (params) => {
    const url = '/invoice/hsm-sign-summary-invoice'

    return axiosClient.post(url, params)
  },
  getMySummaryInvoice: (params, signal) => {
    const url = '/invoice/get-my-summary-invoice'
    return axiosClient.get(url, { params, signal })
  },
  hsmFromPosInvoicesSign: (params) => {
    const url = '/invoice/hsm-from-pos-invoices-sign'

    return axiosClient.post(url, params)
  },
}

export default invoiceApi
