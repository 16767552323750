const { default: axiosClient } = require('./axiosClient')

const servicePackApi = {
  // lay toan bo goi
  getAll: (params) => {
    const url = '/servicePack'
    return axiosClient.get(url, { params })
  },
}

export default servicePackApi
