import { DatabaseOutlined } from '@ant-design/icons'
import styled from 'styled-components'
export const DivColRightTitle = styled.div`
  text-align: end;
`

export const DivRight = styled.div`
  border: 1px solid #ddd;
  display: inline-block;
  :hover {
    border: 1px solid #007aff;
    color: #007aff;
  }
  border-radius: 3px;
  background: #fff;
  padding: 2px 4px;
  padding: 3px 15px;
  cursor: pointer;
`
export const DivRowTitle = styled.div`
  height: 55px;
`
export const DatabaseOutlinedTitleIcon = styled(DatabaseOutlined)`
  font-size: 17px;
  color: #007aff;
  position: relative;
  top: 2px;
  margin-right: 5px;
`
export const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => `${props.width - 30}px`};
  color: ${(props) => props.color};
`
export const LabelTitle = styled.span`
  font-size: 16px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-weight: 600;
  color: #000000;
`
export const DivColCustom = styled.div`
  padding-right: 50px;
`
export const DivContainerUpdate = styled.div`
  height: 750px;
  overflow-y: auto;
`
