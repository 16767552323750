import React from 'react'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import DataTable from 'react-data-table-component'
import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Utils from 'general/utils/Utils'
import KTTooltip from 'general/components/eInvoiceComponents/KTTooltip'
import _ from 'lodash'
import Loading from 'general/components/Loading'
import AppResource from 'general/constants/AppResource'
import Empty from 'general/components/Empty'
// import './style.scss';
import KeenSearchBarNoFormik from 'general/components/KeenSearchBarNoFormik'
import { Form } from 'react-bootstrap'
import DateRangePickerInput from 'general/components/Forms/DateRangePickerInput'
import moment from 'moment'

const fakeData = [
  {
    emailMessageId: 'icorp1345925',
    emailTemplateName: 'Công ty cổ phần ICORP',
    subject: 'Nguyễn Văn A',
    sentDate: '5.000.000 VNĐ',
    toAddress: 'Chuyển khoản',
    fromUserFullName: '25/05/2023',
  },
  {
    emailMessageId: 'icorp1345925',
    emailTemplateName: 'Công ty cổ phần ICORP',
    subject: 'Nguyễn Văn A',
    sentDate: '5.000.000 VNĐ',
    toAddress: 'Momo',
    fromUserFullName: '25/05/2023',
  },
  {
    emailMessageId: 'icorp1345925',
    emailTemplateName: 'Công ty cổ phần ICORP',
    subject: 'Nguyễn Văn A',
    sentDate: '5.000.000 VNĐ',
    toAddress: 'Chuyển khoản',
    fromUserFullName: '25/05/2023',
  },
]

const fullColumns = [
  {
    name: 'Mã đơn',
    sortable: false,
    width: '140px',
    center: true,
    cell: (row) => {
      return (
        <div
          data-tag="allowRowEvents"
          className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
        >
          {row?.emailMessageId}
        </div>
      )
    },
  },
  {
    name: 'Tên người bán',
    sortable: false,
    width: '200px',
    center: true,
    // minWidth: '220px',
    cell: (row) => {
      return (
        <div
          className={`${
            row?.emailTemplateName == 'NCC-Đại lý' ? 'redBox' : 'blueBox'
          }`}
        >
          {row?.emailTemplateName}
        </div>
      )
    },
  },
  {
    name: 'Tên người mua',
    sortable: false,
    center: true,
    width: '200px',
    cell: (row) => {
      return (
        <p
          data-tag="allowRowEvents"
          className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
        >
          {row?.subject}
        </p>
      )
    },
  },
  {
    name: 'Kênh thanh toán',
    sortable: false,
    center: true,
    width: '200px',
    cell: (row) => {
      return (
        <div
          className={`${row?.toAddress == 'Đã ký' ? 'greenBox' : 'blueBox'}`}
        >
          {row?.toAddress}
        </div>
      )
    },
  },

  {
    name: 'Giá trị đơn hàng',
    sortable: false,
    center: true,
    width: '200px',
    cell: (row) => {
      return (
        <div className="d-flex justify-content-center align-items-center">
          {row?.sentDate}
        </div>
      )
    },
  },
  {
    name: 'Ngày thanh toán',
    sortable: false,
    center: true,
    width: '200px',
    cell: (row) => {
      return (
        <div
          className={`${
            row?.fromUserFullName == 'Đã thanh toán' ? 'greenBox' : 'redBox'
          }`}
        >
          {row?.fromUserFullName}
        </div>
      )
    },
  },
  {
    name: 'Hành động',
    center: true,
    minWidth: '150px',
    // width: '150px',
    cell: (row) => (
      <div className="d-flex align-items-center">
        {/* {VoucherHelper.checkCanEdit(row?.state) && ( */}
        <KTTooltip text="Xem chi tiết">
          <div
            className="btn btn-icon btn-sm btn-primary btn-hover-primary mr-2"
            onClick={(e) => {
              //   e.preventDefault();
              //   handleEditVoucher(row);
            }}
          >
            <i className="fa-solid fa-eye"></i>
          </div>
        </KTTooltip>
        {/* )} */}
        <KTTooltip text="Gửi lại">
          <div
            className="btn btn-icon btn-sm btn-warning btn-hover-warning"
            onClick={(e) => {
              //   e.preventDefault();
              //   handleDeleteVoucher(row);
            }}
          >
            <i className="fa-regular fa-paper-plane"></i>
          </div>
        </KTTooltip>
      </div>
    ),
  },
]

function ServiceContract() {
  const { t } = useTranslation()
  const [tableMaxHeight, setTableMaxHeight] = useState('100vh')
  const columns = fullColumns
  const [date, setDate] = useState(new Date())
  const dateFormat = 'DD/MM/YYYY'

  return (
    <div className="ServiceContract w-100 bg-white">
      <div className="card-body pt-0 pr-0 pl-0">
        <div className="w-100 d-flex justify-content-end pr-5">
          <div>
            {' '}
            <KeenSearchBarNoFormik
              name="searchQuery"
              className="mb-0 pb-0"
              placeholder={`${t('Tìm kiếm')}...`}
              // value={Global.gFiltersUsedInvoiceStatistic.name}
              onSubmit={(text) => {
                // needToRefreshData.current = true;
                // Global.gFiltersCustomerList = {
                //   ...filters,
                //   name: text,
                //   pageId: 1,
                // };
                // setFilters(Global.gFiltersCustomerList);
              }}
            />
          </div>
          <div className="ml-2">
            <Form.Select
              aria-label="Kênh thanh toán"
              size="lg"
              style={{ width: '200px' }}
            >
              <option>Kênh thanh toán</option>
              <option value="1">Chuyển khoản</option>
              <option value="2">Momo</option>
            </Form.Select>
          </div>
          <div className="ml-4">
            <DateRangePickerInput
              buttonHeight="100%"
              className=""
              initialLabel="30 ngày gần đây"
              initialEndDate={moment()}
              initialStartDate={moment().subtract(29, 'days')}
              getDateRange={(dateRange) => {
                console.log(dateRange)
                // needToRefreshData.current = true;
                // setRevenueChonSoDateRange(
                //   Utils.getRangeDates({
                //     startDate: dateRange.startDate,
                //     endDate: dateRange.endDate,
                //   })
                // );
                // switch (dateRange.label) {
                //   case 'Tất cả':
                //     setRevenueChonSoFilter({
                //       ...revenueChonSoFilter,
                //       from: '',
                //       to: '',
                //     });
                //     break;
                //   default:
                //     setRevenueChonSoFilter({
                //       ...revenueChonSoFilter,
                //       from: Utils.formatDateTime(dateRange.startDate, 'MM/DD/YYYY'),
                //       to: Utils.formatDateTime(dateRange.endDate, 'MM/DD/YYYY'),
                //     });
                // }
              }}
              customRange={{
                'Hôm qua': [
                  moment().subtract(1, 'day').startOf('day'),
                  moment().subtract(1, 'day').endOf('day'),
                ],
                'Tuần này': [moment().startOf('week'), moment()],
                '7 ngày gần đây': [moment().subtract(6, 'days'), moment()],
                '30 ngày gần đây': [moment().subtract(29, 'days'), moment()],
                'Tháng trước': [
                  moment().subtract(1, 'month').startOf('month'),
                  moment().subtract(1, 'month').endOf('month'),
                ],
                'Tháng này': [moment().startOf('month'), moment()],
              }}
            />
          </div>
        </div>
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight={tableMaxHeight}
          columns={columns}
          data={fakeData}
          customStyles={customDataTableStyle}
          responsive={true}
          noHeader
          className="mt-5"
          // selectableRows={true}
          striped
          noDataComponent={
            <div className="pt-12">
              <Empty
                text={t('NoData')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
                imageEmptyPercentWidth={170}
              />
            </div>
          }
          // progressPending={isGettingApplicationList}
          progressComponent={
            <Loading showBackground={false} message={`${t('Loading')}...`} />
          }
          // onSelectedRowsChange={handleSelectedApplicationsChanged}
          // clearSelectedRows={toggledClearApplications}
          onRowClicked={(row) => {
            // handleEditApplication(row);
          }}
          pointerOnHover
          highlightOnHover
          selectableRowsHighlight
        />

        {/* Pagination */}
        {/* {pagination && (
              <div className="d-flex align-items-center justify-content-center mt-3">
                <Pagination
                rowsPerPage={filters?.limit}
                rowCount={applications?.length}
                currentPage={filters?.pageId}
                onChangePage={(newPage) => {
                  let iNewPage = parseInt(newPage);
                  needToRefreshData.current = true;
                  Global.gFiltersApplicationList = { ...filters, page: iNewPage };
                  setFilters({
                    ...filters,
                    pageId: iNewPage,
                  });
                }}
                onChangeRowsPerPage={(newPerPage) => {
                  const iNewPerPage = parseInt(newPerPage);
                  dispatch(setPaginationPerPage(iNewPerPage));
                  needToRefreshData.current = true;
                  Global.gFiltersApplicationList = { ...filters, limit: iNewPerPage };
                  setFilters({
                    ...filters,
                    limit: iNewPerPage,
                    pageId: 1,
                  });
                }}
                />
              </div>
            )} */}
      </div>
    </div>
  )
}

export default ServiceContract
