import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'

export const KTFormSelectCustomSize = {
  default: '',
  large: 'form-control-lg',
  small: 'form-control-sm',
}

KTFormSelectCustom.propTypes = {
  // required
  name: PropTypes.string,

  // optional
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.object,
    }),
  ),
  value: PropTypes.object,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isCustom: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(KTFormSelectCustomSize)),
  multiple: PropTypes.bool,
  rows: PropTypes.number,
  showValidState: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  enableCheckValid: PropTypes.bool,
  isTouched: PropTypes.bool,
  isValid: PropTypes.bool,
  feedbackText: PropTypes.string,
  haveSearchBar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  style: PropTypes.object,
}

KTFormSelectCustom.defaultProps = {
  options: [],
  value: {},
  text: '',
  disabled: false,
  isCustom: false,
  size: KTFormSelectCustomSize.default,
  multiple: false,
  rows: 1,
  showValidState: false,

  onChange: null,
  onBlur: null,
  onFocus: null,
  enableCheckValid: false,
  isValid: true,
  isTouched: false,
  feedbackText: '',
  haveSearchBar: false,
  fullWidth: false,
  style: {},
}

/**
 *
 * @param {{
 * options: {name: string, value: string}[]
 * name: string,
 * value: object,
 * text: string,
 * disabled: boolean,
 * isCustom: boolean,
 * size: string,
 * multiple: boolean,
 * rows: number,
 * showValidState: boolean,
 * onChange: function,
 * onBlur: function,
 * onFocus: function,
 * enableCheckValid: boolean,
 * isValid: boolean,
 * isTouched: boolean,
 * feedbackText: string,
 * haveSearchBar: boolean,
 * fullWidth: boolean,
 * style: object,
 * }} props
 * @returns
 */
function KTFormSelectCustom(props) {
  // MARK: --- Params ---
  const {
    // name,

    options,
    value,
    text,
    disabled,
    isCustom,
    size,
    // multiple,
    // rows,
    showValidState,

    onChange,
    // onBlur,
    // onFocus,
    enableCheckValid,
    isValid,
    isTouched,
    feedbackText,
    // haveSearchBar,
    fullWidth,
    style,
  } = props
  const { t } = useTranslation()
  const [label, setLabel] = useState(options[0]?.name)

  // MARK: --- Functions ---
  function handleChange(targetValue) {
    if (onChange) {
      onChange(targetValue)
    }
  }

  // function handleBlur() {
  //   if (onBlur) {
  //     onBlur()
  //   }
  // }

  // function handleFocus() {
  //   if (onFocus) {
  //     onFocus()
  //   }
  // }

  // MARK --- Hooks ---
  useEffect(() => {
    if (value) {
      setLabel(options.find((item) => _.isEqual(item.value, value))?.name)
      // console.log(
      //   'options.find((item) => _.isEqual(item.value, value))?.name',
      //   options.find((item) => _.isEqual(item.value, value))?.name
      // );
    }
  }, [value, options])

  return (
    <div>
      <Dropdown style={style}>
        <Dropdown.Toggle
          disabled={disabled}
          variant=""
          className={`
                    form-control custom-dropdown-toggle d-flex justify-content-between border
                    ${
                      isCustom
                        ? 'custom-select shadow-none'
                        : `form-select ${size}`
                    }
                    ${
                      enableCheckValid && isTouched
                        ? `${
                            isValid
                              ? `${showValidState ? 'is-valid' : ''}`
                              : 'is-invalid'
                          }`
                        : ''
                    }
                `}
          id="dropdown-basic"
        >
          {label}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="overflow-auto max-h-400px"
          style={{ width: fullWidth ? '100%' : 'auto' }}
        >
          {options.map((item, index) => {
            if (item.name)
              return (
                <Dropdown.Item
                  className="d-flex justify-content-between align-items-center"
                  key={index}
                  onClick={() => {
                    handleChange(item.value)
                    setLabel(item.name)
                  }}
                >
                  {t(item.name)}
                  {!!value && value === item.value && (
                    <i className="text-success fa-solid fa-check ml-4"></i>
                  )}
                </Dropdown.Item>
              )
          })}
        </Dropdown.Menu>
      </Dropdown>
      {enableCheckValid &&
        !isValid &&
        isTouched &&
        !_.isEmpty(feedbackText) && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{feedbackText}</div>
          </div>
        )}
      {!_.isEmpty(text) && <span className="form-text text-muted">{text}</span>}
    </div>
  )
}

export default KTFormSelectCustom
