import { InfoCircleOutlined } from '@ant-design/icons'
import { Tabs, Modal, Button, Table, Select } from 'antd'
import styled from 'styled-components'

export const TabCustom = styled(Tabs)`
  margin-top: 10px;
  .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: transform 0.3s;
    color: #1f2a49 !important;
    font-size: 13px;
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #089ddd !important;
    font-size: 13px;
  }
  .ant-tabs-tab-btn:hover {
    color: #089ddd !important;
  }
  .ant-tabs-ink-bar {
    background: #089ddd !important;
  }
  .ant-tabs-nav {
    padding: 0px 10px;
    margin-bottom: 0px;
  }
  .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    position: absolute;
    right: 10px !important;
    left: 10px !important;
    border-bottom: 1px solid #f0f0f0;
    content: '';
  }
  .ant-tabs-tab {
    margin: unset !important;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 13px;
  }
`

export const DivImg = styled.div`
  width: 160px;
  position: ${(props) => props.position ?? 'unset'};
  top: ${(props) => props.top ?? 'unset'};
  bottom: ${(props) => props.bottom ?? 'unset'};
  right: ${(props) => props.right ?? 'unset'};
  left: ${(props) => props.left ?? 'unset'};
  max-height: ${(props) => (props.position ? '170px' : 'unset')};
  img {
    width: 81px;
    height: 81px;
    margin: 20px 40px 15px 40px;
  }
  :hover {
    background: #fff;
    border: 1px solid #e3f1ff;
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 122, 255, 0.24);
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    div {
      color: #007aff;
      font-weight: 700;
    }
  }
  div {
    text-align: center;
    font-size: 12px;
  }
  div:last-child {
    padding-bottom: 20px;
  }
`

export const ContainerDivImg = styled.div`
  width: 1200px;
  text-align: center;
  position: relative;
  > img {
    padding-bottom: 65px;
    padding-top: 125px;
    width: 787px;
    height: 100%;
  }
`

export const ContainerTab = styled.div`
  padding: 0px 10px;
`

export const HeaderLabel = styled.div`
  text-align: center;
  font-weight: 500;
  color: #212121;
  font-size: 17px;
  div:first-child {
    color: #007aff;
    padding: 23px 0 8px 0;
    font-weight: 490;
    text-transform: uppercase;
    font-size: 13px;
  }
`

export const ContainerDivTab = styled.div`
  min-height: 700px;
`

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .ant-modal-body {
    height: 100%;
    overflow-y: overlay;
    padding: 20px;
    padding-bottom: 0px;
    margin: 4px 2px 4px 0;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 16px;
  }

  .ant-modal-footer {
    border-top: ${(props) =>
      props.notBorderTopFoodter ? 'unset' : '1px solid #e6e6e6'};
    background-color: ${(props) =>
      props.notBorderTopFoodter ? 'unset' : '#f5f6f8'};
    margin: 0px -24px;
    padding: 10px 24px;
    height: 60px;
    margin-bottom: -20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  button:focus {
    outline: none;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #212121;
  }
  .ant-modal-close {
    right: 20px;
  }
`

export const ModalCustom = styled(Modal)`
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 16px;
  }

  .ant-modal-footer {
    border-top: ${(props) =>
      props.notBorderTopFoodter ? 'unset' : '1px solid #e6e6e6'};
    background-color: ${(props) =>
      props.notBorderTopFoodter ? 'unset' : '#f5f6f8'};
    margin: 0px -24px;
    padding: 10px 24px;
    height: 60px;
    margin-bottom: -20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  button:focus {
    outline: none;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #212121;
  }
  .ant-modal-close {
    right: 20px;
  }
`

export const DivFooter = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  font-size: 1.07142em;
  line-height: 20px;
  align-items: center;
`

const ButtonFooter = styled(Button)`
  border-radius: 3px;
`

export const StyleButtonClose = styled(ButtonFooter)`
  color: black;
  font-weight: 500;
  margin-right: 5px;
`

export const ButtonSave = styled(ButtonFooter)`
  background-color: ${(props) => props.backGroundButtonSave ?? '#1d88fe'};
  color: ${(props) => props.colorButtonSave};
  :hover {
    background-color: ${(props) => props.backGroundButtonSaveHover};
  }
  margin-left: ${(props) => props.marginLeft};
`

export const ButtonTutorial = styled(ButtonFooter)`
  border: 1px solid #205cd6;
  color: #205cd6;
  font-weight: 500;
`

export const DivRowFooter = styled.div`
  width: 100%;
`

export const DivColFooter = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'unset'};
  padding-left: 0px;
  padding-right: 0px;
`

export const TableCustom = styled(Table)`
  .active-row {
    background-color: #d6eaff !important;
  }
  margin: 0px -20px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  max-width: unset !important;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '50px'};
  margin-top: -10px;
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  .ant-table-cell {
    color: black;
    font-size: 12px;
  }
  .ant-table-cell {
    color: black;
    font-size: 12px;
    border-right: 0.5px solid #e0e0e0;
  }
  .left-header {
    text-align: left !important;
    color: black !important;
    font-size: 12px;
    font-weight: 500;
  }
  .right-header {
    text-align: right !important;
    color: black !important;
    font-size: 12px;
    font-weight: 500;
  }
  .center-header {
    text-align: center !important;
    color: black !important;
    font-size: 12px;
    font-weight: 500;
  }
  .center-cell {
    text-align: center !important;
  }
  .left-cell {
    text-align: left !important;
  }
  .right-cell {
    text-align: right !important;
  }
  .ant-table-tbody > tr > td {
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }

  .ant-table-thead > tr > th {
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
  }

  tr {
    height: ${(props) => props.heightTr};
  }

  .ant-table-tbody > tr > td:hover {
    .view {
      display: unset !important;
    }
  }
  .ant-table-tbody > tr > td img {
    padding-left: 10px;
    position: absolute;
    right: 10px;
    display: none;
  }
  tr:hover {
    background-color: #eee;
    .listFuntion {
      display: flex !important;
    }
    .opacityhide {
      opacity: 1;
    }
    .listFuntionFlex {
      top: -18px;
      display: flex !important;
      right: 22px;
    }
  }
`
export const DivColBackGround = styled.div`
  height: 211px;
  width: 149px;
  background-image: url('${(props) => props.urlBackGroupImage}');
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
  margin-bottom: 10px;
  .active {
    border: 1px solid #007aff;
  }
`
export const DivRowBackGround = styled.div`
  .active {
    border: 1px solid #007aff;
  }
`
export const DivColFrame = styled.div`
  display: inline-block;
  height: 235px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: -35px !important;
  .active: {
    border: 1.5px solid #4998e8;
  }
`
export const DivCenter = styled.div`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`
export const InfoCircleOutlinedIcon = styled(InfoCircleOutlined)`
  color: #0079fd;
  font-size: 14px;
  margin-left: 5px;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SpanContainer = styled.div`
  display: flex;
  align-items: center;
  right: 0px;
  top: -25px;
  float: right;
`

export const Span = styled.span`
  margin-left: 5px;
  cursor: pointer;
  &:first-child::after {
    content: ' |';
    margin-left: 5px;
  }
`
export const DivAdd = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  font-weight: 600;
  cursor: pointer;
  color: #1d88fe;
  display: inline-block;
`
