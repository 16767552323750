import axiosClient from './axiosClient'

const systemApi = {
  getCompanyProfile: (params) => {
    const url = `/company/${params.companyId}`
    return axiosClient.get(url)
  },

  // lay danh sach tinh/ thanh pho
  getProvince: (params) => {
    const url = '/province'
    return axiosClient.get(url, { params })
  },

  // lay danh sach co quan thue theo tinh/thanh pho
  getTaxAuthorityByProvine: (params) => {
    const url = '/tax-authority'
    return axiosClient.get(url, { params })
  },

  // cap nhat thong tin cong ty
  updateCompanyProfile: (params) => {
    const url = `/company/update`
    return axiosClient.put(url, params)
  },

  // lấy thông tin cty theo MST
  getCompanyInfoByTaxCode: async (taxCode) => {
    const accessToken =
      'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'
    const response = await axiosClient.get(
      `https://taxcode.icorp.vn/api/v1/tax_code?q=${taxCode}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    return response
  },

  // thay đổi phương thức ký
  setActiveSigningMethod: (params) => {
    const url = '/company/set-active-signing-method'
    return axiosClient.post(url, params)
  },

  setHSMCredential: (params) => {
    const url = '/company/set-hsm-credential'

    return axiosClient.post(url, params)
  },

  // Configurations
  configurations: {
    // Number format
    getNumberFormat: (signal) =>
      axiosClient.get('/systemFormatNumber', { signal }),
    updateNumberFormat: (query) =>
      axiosClient.put('/systemFormatNumber/update', query),

    // Currency
    getCurrency: (signal) => axiosClient.get('/systemCurrency', { signal }),
    updateCurrency: (query) => axiosClient.put('/systemCurrency/update', query),

    // Invoice setting
    getInvoiceSetting: (signal) =>
      axiosClient.get('/systemInvoiceSetting', { signal }),
    updateInvoiceSetting: (query) =>
      axiosClient.put('/systemInvoiceSetting/update', query),

    // Time remind
    getTimeRemind: (signal) => axiosClient.get('/timeRemind', { signal }),
    createTimeRemind: (query) => axiosClient.post('/timeRemind/create', query),
    deleteTimeRemind: () => axiosClient.delete('/timeRemind/deleteAll'),

    // Email config
    getEmailConfig: (signal) =>
      axiosClient.get('/emailConfig/getByCompanyId', { signal }),
    updateEmailConfig: (query) => axiosClient.put('/emailConfig/update', query),

    // Vat setting
    getVatSetting: (signal) =>
      axiosClient.get('/VAT/getByCompanyId', { signal }),
    updateVatSetting: (query) => axiosClient.put('/VAT/update', query),

    // Email template
    getEmailTemplate: (signal) =>
      axiosClient.get('/emailTemplate/getByCompanyId', { signal }),
    updateEmailTemplate: (query) =>
      axiosClient.put('/emailTemplate/updateById', query),

    // Data Log
    getDataLog: () => axiosClient.get('/data-log-config/detail'),
    updateDataLog: (body) => axiosClient.post('/data-log-config/upsert', body),
  },
}

export default systemApi
