// import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import _ from 'lodash'
import './styles.scss'
import PropTypes from 'prop-types'

ImportTicketStep.propTypes = {
  stepNumber: PropTypes.number,
  stepDescription: PropTypes.stepDescription,
  active: PropTypes.bool,
}

ImportTicketStep.defaultProps = {
  stepNumber: 1,
  // stepDescription: 'Chọn tệp nguồn',
  // active: false,
}

function ImportTicketStep(props) {
  const { stepNumber, stepDescription, active } = props
  return (
    <div className={`importTicketStep ${active ? 'active' : 'inactive'}`}>
      <div
        className={`stepNumber ${active ? 'active' : 'inactive'}`}
        style={
          active
            ? { border: 'solid 2px #1877f2', color: '#1877f2' }
            : { border: 'solid 2px #c1c1c1', color: '#c1c1c1' }
        }
      >
        {stepNumber}
      </div>
      <div
        className={`stepDescription ${active ? 'active' : 'inactive'}`}
        style={active ? { color: '#1877f2' } : { color: '#c1c1c1' }}
      >
        {_.toUpper(stepDescription)}
      </div>
    </div>
  )
}

export default ImportTicketStep
