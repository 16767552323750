// import lifeStyleApi from 'api/lifeStyleApi';
import companyUnitApi from 'api/companyUnitApi'
import { thunkGetCompanyUnitList } from 'features/Category/categorySlice'
import { FastField, Formik } from 'formik'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import ModalChooseCompanyUnit from '../ModalChooseCompanyUnit'

ModalEditCompanyUnit.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onRefreshCompanyUnitList: PropTypes.func,
  companyUnitItem: PropTypes.object,
  active: PropTypes.string,
  parentCompanyUnit: PropTypes.object,
}

ModalEditCompanyUnit.defaultProps = {
  show: false,
  onClose: null,
  onRefreshCompanyUnitList: null,
  companyUnitItem: null,
  active: 'ADD',
  parentCompanyUnit: {},
}

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * onRefreshCompanyUnitList: function,
 * companyUnitItem: object,
 * active: string,
 * }} props
 * @returns
 */
function ModalEditCompanyUnit(props) {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { show, onClose, companyUnitItem, active, parentCompanyUnit } = props
  const [showing, setShowing] = useState(true)
  const [showModalChooseCompanyUnit, setShowModalChooseCompanyUnit] =
    useState(false)
  const [selectedCompanyUnitItem, setSelectedCompanyUnitItem] =
    useState(parentCompanyUnit)

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose()
    }
  }

  // Request create new money type
  async function requestCreateCompanyUnit(values) {
    let params = { ...values }

    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(companyUnitItem)) {
      params.companyUnitId = ''
    }
    if (selectedCompanyUnitItem) {
      params.parentCompanyUnitId = selectedCompanyUnitItem.companyUnitId
      params.level = selectedCompanyUnitItem.level + 1
    } else {
      params.level = 1
    }

    try {
      const res = await companyUnitApi.create(params)
      const { result, companyUnit } = res
      if (result === 'success') {
        ToastHelper.showSuccess(`Thêm đơn vị ${params.name} thành công`)
        setSelectedCompanyUnitItem(null)
        dispatch(thunkGetCompanyUnitList())
        handleClose()
      }
    } catch (error) {
      ToastHelper.showError(error.response.data.message)
    }
  }

  // Request replicate money type
  async function requestReplicateCompanyUnit(values) {
    let params = { ...values }

    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(companyUnitItem)) {
      params.companyUnitId = ''
    }
    if (selectedCompanyUnitItem) {
      params.parentCompanyUnitId = selectedCompanyUnitItem.companyUnitId
    }
    try {
      const res = await companyUnitApi.create(params)
      const { result, companyUnit } = res
      if (result === 'success') {
        ToastHelper.showSuccess(`Nhân bản đơn vị ${params.name} thành công`)
        setSelectedCompanyUnitItem(null)
        dispatch(thunkGetCompanyUnitList())
        handleClose()
      }
    } catch (error) {
      ToastHelper.showError(error.response.data.message)
    }
  }

  // Request update money type
  async function requestUpdateCompanyUnit(values) {
    let params = { ...values }
    if (_.isNull(companyUnitItem)) {
      params.companyUnitId = ''
    }
    if (parentCompanyUnit) {
      params.level = parentCompanyUnit.level + 1
    } else {
      params.level = 1
    }
    const res = await companyUnitApi.update(params)
    const { result, companyUnit } = res
    if (result === 'success') {
      ToastHelper.showSuccess(
        `Cập nhật thông tin đơn vị ${companyUnitItem.name} thành công`,
      )
      setSelectedCompanyUnitItem(null)
      dispatch(thunkGetCompanyUnitList())
      handleClose()
    }
  }

  // MARK --- Hooks ---
  useEffect(() => {
    if (!Utils.isObjectEmpty(parentCompanyUnit)) {
      setSelectedCompanyUnitItem(parentCompanyUnit)
    } else {
      setSelectedCompanyUnitItem(null)
    }
  }, [parentCompanyUnit, active])

  useEffect(() => {}, [companyUnitItem])

  return (
    <div>
      <Formik
        initialValues={{
          companyUnitId: companyUnitItem ? companyUnitItem.companyUnitId : '',
          code: companyUnitItem ? companyUnitItem.code : '',
          name: companyUnitItem ? companyUnitItem.name : '',
          parentCompanyUnitId: parentCompanyUnit
            ? parentCompanyUnit.companyUnitId
            : null,
          level: companyUnitItem ? companyUnitItem.level : 1,
        }}
        validationSchema={Yup.object({
          code: Yup.string().trim().required(t('Required')),
          name: Yup.string().trim().required(t('Required')),
        })}
        enableReinitialize={true}
        onSubmit={(values) => {
          switch (active) {
            case 'ADD':
              requestCreateCompanyUnit(values)
              break
            case 'EDIT':
              requestUpdateCompanyUnit(values)
              break
            case 'REPLICATE':
              requestReplicateCompanyUnit(values)
              break
            default:
              requestReplicateCompanyUnit(values)
              break
          }
        }}
      >
        {(formikProps) => (
          <>
            <Modal
              className=""
              show={show && showing}
              backdrop="static"
              size="lg"
              onHide={handleClose}
              centered
              onExit={() => {
                // formikProps.handleReset();
                if (!showModalChooseCompanyUnit) {
                  formikProps.handleReset()
                }
              }}
              onExited={() => {
                // handleExistDone();
                // formikProps.handleReset();
              }}
            >
              <Modal.Header className="px-5 py-5">
                <Modal.Title>
                  {active === 'ADD'
                    ? 'Thêm đơn vị'
                    : active === 'EDIT'
                      ? 'Chỉnh sửa thông tin đơn vị'
                      : 'Nhân bản đơn vị'}
                </Modal.Title>
                <div
                  className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                  onClick={() => {
                    handleClose()
                    formikProps.handleReset()
                  }}
                >
                  <i className="far fa-times"></i>
                </div>
              </Modal.Header>

              <Modal.Body
                className="overflow-auto bg-light"
                style={{
                  maxHeight: '70vh',
                }}
              >
                <div className="row">
                  {/* ma don vi */}
                  <div className="">
                    <KTFormGroup
                      label={
                        <>
                          {t('Mã đơn vị')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="code"
                      inputElement={
                        <div>
                          <FastField name="code">
                            {({ field, form, meta }) => {
                              return (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Mã đơn vị'),
                                  )}...`}
                                />
                              )
                            }}
                          </FastField>
                        </div>
                      }
                    />
                  </div>

                  {/* ten don vi */}
                  <div className="">
                    <KTFormGroup
                      label={
                        <>
                          {t('Tên đơn vị')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="name"
                      inputElement={
                        <FastField name="name">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Tên đơn vị'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* don vi cha */}
                  <div className="mb-6">
                    <span
                      className="mb-2 font-weight-bolder"
                      style={{ color: '#7177A8' }}
                    >
                      {t('ParentCompanyUnit')}{' '}
                    </span>
                    <div className="mt-2 position-relative">
                      {selectedCompanyUnitItem ? (
                        <div className="bg-white border rounded p-2">
                          <div
                            className="d-flex flex-row align-items-center px-3 py-1 rounded font-weight-bolder"
                            style={{
                              color: '#4A5677',
                              width: 'fit-content',
                              backgroundColor: '#DBE3EF',
                            }}
                          >
                            {selectedCompanyUnitItem?.name}
                            <i
                              className="far fa-times ml-4 cursor-pointer"
                              onClick={() => {
                                setSelectedCompanyUnitItem(null)
                                formikProps
                                  .getFieldHelpers('parentCompanyUnitId')
                                  .setValue(null)
                              }}
                              style={{
                                color: '#4A5677',
                              }}
                            ></i>
                            {/* )} */}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="bg-white border cursor-pointer text-muted p-3"
                          style={{ borderRadius: '14px' }}
                          onClick={() => {
                            setShowModalChooseCompanyUnit(true)
                            setShowing(false)
                          }}
                        >
                          {t('ChooseParentCompanyUnit')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="w-100 d-flex row">
                  <Button
                    className="font-weight-bold flex-grow-1 col mr-3"
                    variant="secondary"
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    {t('Đóng')}
                  </Button>

                  <Button
                    className="font-weight-bold flex-grow-1 col"
                    variant="primary"
                    onClick={() => {
                      formikProps.handleSubmit()
                    }}
                  >
                    {t('Lưu')}
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <ModalChooseCompanyUnit
              show={showModalChooseCompanyUnit}
              companyUnitItem={companyUnitItem}
              setCompanyUnitItem={(item) => {
                formikProps
                  .getFieldHelpers('parentCompanyUnitId')
                  .setValue(item.companyUnitId)
                setSelectedCompanyUnitItem(item)
              }}
              onClose={() => {
                setShowModalChooseCompanyUnit(false)
                setShowing(true)
              }}
              openModalCreateCompanyUnit={() => {
                setShowModalChooseCompanyUnit(false)
                setShowing(true)
              }}
            />
          </>
        )}
      </Formik>
    </div>
  )
}

export default ModalEditCompanyUnit
