import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import AntdDropdown from 'general/components/Dropdown/AntdDropdown'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import './styles.scss'

const CustomTimePicker = (props) => {
  const { initialValue, onBlur, canSelectTime, timeArray } = props
  const [value, setValue] = useState(initialValue ?? '12:00')
  const format = 'HH:mm'
  const onChooseTime = ({ key }) => {
    const timeString = timeArray[parseInt(key)]
    setValue(timeString)
    if (onBlur) {
      onBlur(timeString)
    }
  }

  const selectTimeOption = useMemo(() => {
    return _.map(timeArray, (item, key) => {
      return { label: item, value: item, key }
    })
  }, [timeArray])

  return (
    <div className="d-flex flex-row justify-content-start position-relative">
      <TimePicker
        // defaultValue={dayjs(value, format)}
        value={dayjs(value, format)}
        format={format}
        onBlur={(e) => {
          e.preventDefault()
          const timeValue = dayjs(e.target.value, format)
          console.log(timeValue)
          let timeString = timeValue.format(format)
          if (!Utils.isValidTimeFormat(timeString)) {
            timeString = '12:00'
          }
          setValue(timeString)
          onBlur(timeString)
        }}
        allowClear={false}
        suffixIcon={null}
        // disabled={!_.isEmpty(selectTimeOption)}
      />
      {!_.isEmpty(selectTimeOption) && (
        <AntdDropdown items={selectTimeOption} onClick={onChooseTime} />
        // <Dropdown
        //   menu={{
        //     items: selectTimeOption,
        //     onClick: onChooseTime,
        //   }}
        // >
        //   <div
        //     className="d-flex align-items-center justify-content-center flex-row position-absolute"
        //     style={{
        //       width: '30px',
        //       height: '30px',
        //       cursor: 'pointer',
        //       right: 0,
        //     }}
        //   >
        //     <i className="fas fa-caret-down" style={{ color: '#1877f2' }}></i>
        //   </div>
        // </Dropdown>
      )}
    </div>
  )
}

CustomTimePicker.propTypes = {
  value: PropTypes.string,
}

CustomTimePicker.defaultProps = {
  value: '',
}
export default CustomTimePicker
