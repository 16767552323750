import axiosClient from './axiosClient'

const authApi = {
  signIn: (params) => {
    const url = '/sign-in'
    return axiosClient.post(url, params)
  },
  logOut: (params) => {
    const url = '/log-out'
    return axiosClient.post(url, params)
  },
  signUp: ({ linkParams, body }) => {
    const url = '/sign-up/?' + linkParams
    return axiosClient.post(url, body)
  },
}

export default authApi
