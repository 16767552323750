import { useMutation } from '@tanstack/react-query'
import { Card, Typography } from 'antd'
import { thunkSignIn } from 'app/authSlice'
import { Form, Formik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import AppRegex from 'general/constants/AppRegex'
import ToastHelper from 'general/helpers/ToastHelper'
import UserHelper from 'general/helpers/UserHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

export default function LoginHomeScreen() {
  // MARK --- Params: ---
  const [isPersonalAccount, setIsPersonalAccount] = useState(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleSignIn = useMutation({
    mutationKey: ['auth', 'signIn'],
    mutationFn: (params) => {
      const cloned = _.cloneDeep(params)
      _.set(cloned, 'password', Utils.sha256(cloned.password))
      if (!isPersonalAccount) {
        _.set(cloned, 'taxCode', cloned.companyUsername)
        _.unset(cloned, ['companyUsername'])
      }
      return dispatch(thunkSignIn(cloned)).unwrap()
    },
    onSuccess: (res) => {
      if (res) {
        const displayName = UserHelper.getDisplayName(res.account)
        ToastHelper.showSuccess(`Xin chào, ${displayName}`)
        navigate('/ban-lam-viec', {
          replace: true,
        })
      }
    },
  })

  //MARK --- Hooks: ---

  const checkIsPersonalAccount = (companyUsernameValue) => {
    if (_.isEmpty(companyUsernameValue)) return
    if (AppRegex.taxCode.test(companyUsernameValue)) {
      setIsPersonalAccount(false)
    } else {
      setIsPersonalAccount(true)
    }
  }

  return (
    <Formik
      initialValues={{
        companyUsername: '',
        password: '',
        taxCode: '',
      }}
      enableReinitialize
      validationSchema={Yup.object({
        companyUsername: isPersonalAccount
          ? Yup.string().nullable()
          : Yup.string().trim().required(t('Required')),
        password: Yup.string().trim().required(t('Required')),
        taxCode: isPersonalAccount
          ? Yup.string().trim().required(t('Required'))
          : Yup.string().nullable(),
      })}
      onSubmit={handleSignIn.mutate}
    >
      {(formik) => (
        <Card
          bordered={false}
          title={
            <Typography.Title level={2} className="text-center m-0">
              {t('Sign in')}
            </Typography.Title>
          }
          bodyStyle={{
            padding: 0,
          }}
          style={{
            maxWidth: 500,
          }}
        >
          <Form>
            <section className="p-5 bg-light">
              <div>
                {/* companyUsername */}
                <KTFormGroup
                  label={
                    <>
                      {t('MST/Email/Tên đăng nhập')}{' '}
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="companyUsername"
                  inputElement={
                    <KTFormInput
                      name="companyUsername"
                      value={formik.getFieldProps('companyUsername').value}
                      onChange={(value) => {
                        checkIsPersonalAccount(value)
                        formik
                          .getFieldHelpers('companyUsername')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('companyUsername')
                          .setTouched(true)
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(
                        formik.getFieldMeta('companyUsername').error,
                      )}
                      isTouched={formik.getFieldMeta('companyUsername').touched}
                      feedbackText={
                        formik.getFieldMeta('companyUsername').error
                      }
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(
                        t('MST/Email/Tên đăng nhập'),
                      )}...`}
                    />
                  }
                />

                {/* password */}
                <KTFormGroup
                  label={
                    <>
                      {t('Password')} <span className="text-danger">(*)</span>
                    </>
                  }
                  additionalClassName={`${isPersonalAccount ? '' : 'm-0'}`}
                  inputName="password"
                  inputElement={
                    <KTFormInput
                      name="password"
                      value={formik.getFieldProps('password').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('password').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('password').setTouched(true)
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(formik.getFieldMeta('password').error)}
                      isTouched={formik.getFieldMeta('password').touched}
                      feedbackText={formik.getFieldMeta('password').error}
                      type={KTFormInputType.password}
                      placeholder={`${_.capitalize(t('Password'))}...`}
                    />
                  }
                />

                {/* taxCode */}
                {isPersonalAccount ? (
                  <KTFormGroup
                    label={
                      <>
                        {t('Tax code')} <span className="text-danger">(*)</span>
                      </>
                    }
                    additionalClassName="m-0"
                    inputName="taxCode"
                    inputElement={
                      <KTFormInput
                        name="taxCode"
                        value={formik.getFieldProps('taxCode').value}
                        onChange={(value) => {
                          formik.getFieldHelpers('taxCode').setValue(value)
                        }}
                        onBlur={() => {
                          formik.getFieldHelpers('taxCode').setTouched(true)
                        }}
                        enableCheckValid
                        isValid={_.isEmpty(
                          formik.getFieldMeta('taxCode').error,
                        )}
                        isTouched={formik.getFieldMeta('taxCode').touched}
                        feedbackText={formik.getFieldMeta('taxCode').error}
                        type={KTFormInputType.text}
                        placeholder={`${_.capitalize(t('Tax code'))}...`}
                      />
                    }
                  />
                ) : null}
              </div>
              <div className="mt-5 text-end">
                <Link
                  to={{
                    pathname: '/auth/quen-mat-khau',
                    search: Utils.stringifySearchParams({
                      e: isPersonalAccount ? formik.values.companyUsername : '',
                      t: isPersonalAccount
                        ? formik.values.taxCode
                        : formik.values.companyUsername,
                    }),
                  }}
                  className="font-size-base font-weight-bolder cursor-pointer text-primary text-hover-muted"
                >
                  {t('Forgot password')} ?
                </Link>
              </div>
            </section>
            <section className="p-5">
              <CustomAntButton
                text={t('Sign in')}
                isLoading={handleSignIn.isPending}
                antProps={{
                  htmlType: 'submit',
                  block: true,
                  type: 'primary',
                }}
              />
            </section>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
