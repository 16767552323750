import { Checkbox, Space, Typography } from 'antd'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomLabel from 'general/components/CustomLabel'
import InputNumber from 'general/components/Forms/InputNumber'
import useRouter from 'hooks/useRouter'
import { useMemo } from 'react'

function InvoiceTableGeneralInfo(props) {
  const {
    formik,
    currencies,
    discountTypeArray,
    handleSelectDiscountType,
    checkedHHDV,
    onChangeCheckHHDV,
    checkedPayment,
    onChangePayment,
    onToggleMulTaxRate,
    mulTaxRate,
    autoCalculate,
    currency,
  } = props

  const { pathname } = useRouter()
  const isDeliveryBill = useMemo(
    () => pathname.includes('phieu-xuat-kho'),
    [pathname],
  )
  const handleSelectCurrency = (curr) => {
    let currentCurrency = currencies.find((option) => option.value === curr)
    formik.setFieldValue('currency', curr)
    formik.setFieldValue('exchangeRate', currentCurrency.exchangeRate)
  }

  return (
    <div className="my-5">
      <Typography.Title className="w-100" style={{ fontSize: 18 }}>
        Hàng hoá/ Dịch vụ
      </Typography.Title>
      {/* Checkbox group */}
      <Space size={'large'} className="w-100" wrap>
        <Checkbox checked={checkedHHDV} onChange={onChangeCheckHHDV}>
          Hiện cột "Tính chất HHDV"
        </Checkbox>
        <Checkbox
          checked={autoCalculate}
          onChange={() => formik.setFieldValue('autoCalculate', !autoCalculate)}
        >
          Tự động tính số liệu điều chỉnh
        </Checkbox>
        <Checkbox checked={checkedPayment} onChange={onChangePayment}>
          Đã thanh toán
        </Checkbox>
        {formik.values.taxType === 3 && (
          <Checkbox checked={mulTaxRate} onChange={onToggleMulTaxRate}>
            Nhiều thuế suất
          </Checkbox>
        )}
        {currency?.transactionForeign && (
          <>
            <div className="d-flex flex-row align-items-center w-100 gap-2">
              <CustomLabel text="Loại tiền:" width="100%"></CustomLabel>
              <CustomAntSelect
                className="w-100"
                autoMatchWidth={150}
                showSearch={false}
                options={currencies.map((v) => ({
                  label: v.label,
                  value: v.value,
                }))}
                value={formik.values.currency}
                onChange={(value) => handleSelectCurrency(value)}
              />
            </div>
            <div className="d-flex flex-row align-items-center w-130px">
              <CustomLabel text="Tỷ giá:" width="100%"></CustomLabel>
              <InputNumber
                systemConfigType="exchangeRate"
                name="exchangeRate"
                disabled={formik.values.currency === 'VNĐ'}
                fieldProps={formik.getFieldProps('exchangeRate')}
                fieldMeta={formik.getFieldMeta('exchangeRate')}
                fieldHelper={formik.getFieldHelpers('exchangeRate')}
                className="w-100"
                statusChangeNumericFormat
              />
            </div>
          </>
        )}
        <div
          className={`flex-row align-items-center w-100 gap-2 ${isDeliveryBill ? 'd-none' : 'd-flex'}`}
        >
          <CustomLabel text="Chiết khấu:" width="100%"></CustomLabel>
          <CustomAntSelect
            autoMatchWidth={200}
            value={formik.values.discountType}
            options={discountTypeArray.map((v) => ({
              label: v.label,
              value: v.value,
            }))}
            onChange={(value) => handleSelectDiscountType(value)}
            showSearch={false}
          />
        </div>
        {/* <div className="d-flex flex-row align-items-center w-100 gap-2">
          <CustomLabel text="Cố định cột:" width="100%"></CustomLabel>
          <CustomAntSelect
            autoMatchWidth={150}
            value={persistent}
            options={[
              { label: 'Số lượng', value: 'quantityPersistent' },
              { label: 'Đơn giá sau thuế', value: 'priceAfterTaxPersistent' },
            ]}
            onChange={(value) => setPersistent(value)}
            showSearch={false}
          />
        </div> */}
      </Space>
    </div>
  )
}

export default InvoiceTableGeneralInfo
