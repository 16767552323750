/**
 *
 * @type {import("react-data-table-component").TableStyles}
 */
export const hasPinnedActionCol = {
  head: {
    style: {
      zIndex: 2,
    },
  },
  rows: {
    style: {
      minHeight: '40px', // override the row height
      fontSize: '1rem',
      color: '#3C4376',
      paddingLeft: '0px',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#ebedf3',
      },
      '&:last-of-type': {
        borderBottom: '1px solid #ebedf3',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#F3F6F9 ',
    },
  },
  headRow: {
    style: {
      borderBottomColor: '#ebedf3',
    },
  },
  cells: {
    style: {
      padding: '0.6rem 1rem',
      // '&:first-of-type': {
      //   paddingLeft: '1.25rem',
      // },
      borderLeft: '1px solid #ebedf3',
      color: '#3C4376',
      '&:last-of-type': {
        position: 'sticky',
        right: 0,
        borderRight: '1px solid #ebedf3',
        backgroundColor: 'inherit',
      },
    },
  },
  headCells: {
    style: {
      gap: '4px',
      fontSize: '0.9rem',
      fontWeight: 600,
      paddingLeft: '0px',
      paddingRight: '0px',
      textAlign: 'center',
      color: '#3C4376',
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
      lineHeight: '1.5 !important',
      padding: '0 1rem',
      borderLeft: '1px solid #ebedf3',
      borderTop: '1px solid #ebedf3',
      '&:last-of-type': {
        position: 'sticky',
        right: 0,
        borderRight: '1px solid #ebedf3',
        backgroundColor: 'inherit',
      },
    },
  },
}
