import { useMutation } from '@tanstack/react-query'
import { Checkbox, Modal, Typography } from 'antd'
import customerApi from 'api/customerApi'
import invoiceApi from 'api/invoiceApi'
import { useMutationDownloadPdf } from 'features/Invoice/screens/ListInvoice/queries'
import { useSystemConfigSelector } from 'features/System/screens/SystemConfiguration/SystemConfigurationSlice'
import { useFormik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TextFieldSelect from 'general/components/Forms/TextFieldSelect'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import { generateSchema } from 'general/utils/YupSchema/ListTransaction'
import useMutationGetCompanyInfo from 'hooks/Mutations/useMutationGetCompanyInfo'
import useGetSerials from 'hooks/Queries/useGetSerials'
import useToken from 'hooks/useToken'
import _ from 'lodash'
import { useMemo } from 'react'
import { transactionQueries, useInvalidateQuery } from '../../queries'
import { useTransactionSelector } from '../../slice'

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.type
 * @param {boolean} props.isOpen
 * @param {(status: boolean) => void} props.onOpenChange
 * @param {() => void} props.onApply
 */
export default function ExportInvoiceModal(props) {
  const { token } = useToken()
  const { selectedRows, selectedRow } = useTransactionSelector()
  const { invalidate } = useInvalidateQuery()
  const dataLogPetroIds = useMemo(
    () => selectedRows.map((row) => row?.dataLogPetroId),
    [selectedRows],
  )
  const { mutateAsync: handleExport, isPending: isExporting } = useMutation({
    mutationKey: [transactionQueries.base, { action: 'export-invoice' }],
    mutationFn: (params) => invoiceApi.createFromLogPetro(params),
  })
  const { mutateAsync: handleExportMultiple, isPending: isExportingMultiple } =
    useMutation({
      mutationKey: [
        transactionQueries.base,
        { action: 'export-invoice-multiple' },
      ],
      mutationFn: (params) => invoiceApi.createFromLogPetros(params),
    })

  const { data: serialTemplates, isPending: isGettingTemplate } =
    useGetSerials('ALL')

  const { autoDataLog } = useSystemConfigSelector()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      taxRate: '10',
      paymentMethod: 1,
      customerTaxCode: '',
      customerCompanyName: '',
      customerFullAddress: '',
      customerPhone: '',
      customerEmail: '',
      customerName: autoDataLog?.customerName ?? '',
      customerAccountNumber: '',
      customerBankName: '',
      invoiceTemplateId: autoDataLog?.invoiceTemplateId ?? null,

      hsmSign: false,
    },
    validationSchema: generateSchema().exportTransactionSchema,
    onSubmit: async (values) => {
      //Check thông tin người mua
      if (_.isEmpty(values.customerTaxCode) && _.isEmpty(values.customerName)) {
        ToastHelper.showError('Vui lòng nhập tên người mua hoặc mã số thuế')
        return
      }

      if (props.type == 'MULTIPLE') {
        const { result, data } = await handleExportMultiple({
          ...values,
          dataLogPetroIds,
        })
      } else {
        const { result, data } = await handleExport({
          ...values,
          dataLogPetroIds: selectedRow
            ? [selectedRow.dataLogPetroId]
            : dataLogPetroIds,
        })
        if (result == 'success') {
          return data?.invoiceId
        }
      }
    },
  })

  const { mutate: downloadPdf } = useMutationDownloadPdf()

  const handleClearFields = () => {
    formik.setFieldValue('customerFullAddress', '')
    formik.setFieldValue('customerCompanyName', '')
    formik.setFieldValue('customerName', '')
    formik.setFieldValue('customerEmail', '')
    formik.setFieldValue('customerPhone', '')
    formik.setFieldValue('dateOfOperation', '')
    formik.setFieldValue('status', '')
    formik.setFieldValue('customerAccountNumber', '')
    formik.setFieldValue('customerBankName', '')
  }

  const { mutateAsync, isPending: isGettingInfo } = useMutationGetCompanyInfo()

  const fetchInfoByTaxCode = async () => {
    const getExternalResult = await mutateAsync({
      document_type: 'TAX',
      tax_code: formik.values.customerTaxCode,
    })
    const getSavedCustomerResult = await customerApi.detail(
      formik.values.customerTaxCode,
    )
    const { document_data, message } = getExternalResult?.data
    if (
      message !== 'SUCCESSFULLY' &&
      getSavedCustomerResult.result !== 'success'
    ) {
      handleClearFields()
      ToastHelper.showError('Không tìm thấy thông tin khách hàng')
      return
    }
    const taxInfo = document_data.tax_informations[0]
    formik.setFieldValue(
      'customerFullAddress',
      taxInfo?.company_information?.address ?? '',
    )
    formik.setFieldValue(
      'customerCompanyName',
      taxInfo?.company_information?.official_name ?? '',
    )
    formik.setFieldValue(
      'dateOfOperation',
      taxInfo?.company_information?.operation_start_date ?? '',
    )

    formik.setFieldValue(
      'customerName',
      getSavedCustomerResult?.buyerName ?? '',
    )
    formik.setFieldValue(
      'customerEmail',
      getSavedCustomerResult?.buyerEmail ?? '',
    )
    formik.setFieldValue(
      'customerPhone',
      getSavedCustomerResult?.buyerPhone ?? '',
    )
    formik.setFieldValue(
      'customerAccountNumber',
      getSavedCustomerResult?.bankAccount ?? '',
    )
    formik.setFieldValue(
      'customerBankName',
      getSavedCustomerResult?.bankName ?? '',
    )
  }

  const handleSummitForm = async (action) => {
    if (props.type == 'MULTIPLE') {
      await formik.submitForm()
      ToastHelper.showSuccess('Tạo hoá đơn thành công')

      handleCancelModal()
      invalidate()
      props.onApply()
    } else {
      let invoiceId = await formik.submitForm()
      if (_.isNil(invoiceId)) {
        return
      }
      ToastHelper.showSuccess('Tạo hoá đơn thành công')

      if (action == 'CREATE_AND_PRINT') {
        downloadPdf(invoiceId)
      }
      handleCancelModal()
      invalidate()
      props.onApply()
    }
  }

  const handleCancelModal = () => {
    props.onOpenChange(false)
    formik.resetForm()
  }
  const handleSelectCustomer = (customer) => {
    formik.setFieldValue('customerCompanyName', customer?.name ?? '')
    formik.setFieldValue('customerFullAddress', customer?.address ?? '')
    formik.setFieldValue('customerTaxCode', customer?.taxCode ?? '')
    formik.setFieldValue('customerAccountNumber', customer?.bankAccount ?? '')
    formik.setFieldValue('customerBankName', customer?.bankName ?? '')
    formik.setFieldValue('customerPhone', customer?.buyerPhone ?? '')
    formik.setFieldValue('customerName', customer?.buyerName ?? '')
    formik.setFieldValue('customerEmail', customer?.buyerEmail ?? '')
  }

  return (
    <Modal
      open={props.isOpen}
      onCancel={handleCancelModal}
      destroyOnClose
      centered
      maskClosable={false}
      width="60rem"
      keyboard={false}
      title={
        <Typography.Title
          level={4}
          className="d-flex flex-row align-items-center"
        >
          {props.title}
          {props.type == 'MULTIPLE' ? (
            <span className="fs-5 badge bg-warning ml-5">Xuất lẻ</span>
          ) : props.type == 'COMBINE' ? (
            <span className="fs-5 badge bg-warning ml-5">Xuất gộp</span>
          ) : (
            <span className="fs-5 text-info ml-5">
              Mã bơm: {selectedRow?.saleNumber}
            </span>
          )}
        </Typography.Title>
      }
      footer={
        <div>
          <div className="d-flex align-items-center justify-content-end gap-1 mb-2">
            <label className="m-0" htmlFor="hsm-sign">
              Phát hành khi tạo hóa đơn
            </label>
            <Checkbox
              id="hsm-sign"
              checked={!!formik.values.hsmSign}
              onChange={(e) =>
                formik.setFieldValue('hsmSign', e.target.checked)
              }
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            {props.type != 'MULTIPLE' && (
              <CustomAntButton
                text={'Tạo và in'}
                customStyle={{
                  borderColor: token.colorPrimaryBorderHover,
                  color: token.colorPrimaryText,
                }}
                isLoading={isExporting}
                antProps={{
                  type: 'default',
                  htmlType: 'button',
                  disabled: isExporting,
                  onClick: () => {
                    handleSummitForm('CREATE_AND_PRINT')
                  },
                }}
              />
            )}

            <CustomAntButton
              text="Tạo hóa đơn"
              isLoading={isExporting || isExportingMultiple}
              antProps={{
                type: 'primary',
                htmlType: 'button',
                disabled: isExporting || isExportingMultiple,
                onClick: () => {
                  handleSummitForm('CREATE')
                },
              }}
            />
          </div>
        </div>
      }
    >
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-4 m-0 " htmlFor="taxRate">
              Thuế xuất
            </label>

            <div className="col-md-8">
              <CustomAntSelect
                showSearch={false}
                options={SelectOptions.GTGT}
                value={formik.values.taxRate}
                onChange={(value) => formik.setFieldValue('taxRate', value)}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-4 m-0 " htmlFor="invoiceTemplateId">
              Ký hiệu
            </label>

            <div className="col-md-8">
              <CustomAntSelect
                isLoading={isGettingTemplate}
                value={formik.values.invoiceTemplateId}
                options={serialTemplates
                  ?.filter(({ value }) => !!value)
                  ?.map((item) => ({
                    label: item.label + ' - ' + item.invoiceTemplateName,
                    value: item.invoiceTemplateId,
                  }))}
                placeholder={'Chọn ký hiệu'}
                onChange={(value) =>
                  formik.setFieldValue('invoiceTemplateId', value)
                }
                autoMatchWidth={false}
                placement="bottomRight"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-2 m-0 " htmlFor="customerTaxCode">
              MST người mua
            </label>

            <div className="col-md-10 m-0 d-flex justify-content-between gap-4">
              <div className="flex-fill">
                <TextFieldSelect
                  placement="bottomLeft"
                  className="flex-grow-1 w-100"
                  fieldHelper={formik.getFieldHelpers('customerTaxCode')}
                  fieldMeta={formik.getFieldMeta('customerTaxCode')}
                  fieldProps={formik.getFieldProps('customerTaxCode')}
                  onSelect={handleSelectCustomer}
                />
              </div>
              <div className="">
                <CustomAntButton
                  text="Lấy thông tin"
                  iconCn="fal fa-search"
                  isLoading={isGettingInfo}
                  antProps={{
                    type: 'default',
                    htmlType: 'button',
                    onClick: fetchInfoByTaxCode,
                    disabled: isGettingInfo,
                    block: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-2 m-0 " htmlFor="customerCompanyName">
              Tên đơn vị
            </label>

            <div className="col-md-10 m-0">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  className: 'flex-grow-1 w-100',
                  value: formik.values.customerCompanyName,
                  onChange: (e) =>
                    formik.setFieldValue('customerCompanyName', e.target.value),
                  status:
                    formik.errors.customerCompanyName &&
                    formik.touched.customerCompanyName
                      ? 'error'
                      : undefined,
                }}
              />
              <Typography.Text type="danger" className="font-size-sm">
                {formik.errors.customerCompanyName &&
                formik.touched.customerCompanyName
                  ? formik.errors.customerCompanyName
                  : ''}
              </Typography.Text>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-2 m-0 " htmlFor="customerFullAddress">
              Địa chỉ
            </label>

            <div className="col-md-10 m-0">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  className: 'flex-grow-1 w-100',
                  value: formik.values.customerFullAddress,
                  onChange: (e) =>
                    formik.setFieldValue('customerFullAddress', e.target.value),
                  status:
                    formik.errors.customerFullAddress &&
                    formik.touched.customerFullAddress
                      ? 'error'
                      : undefined,
                }}
              />
              <Typography.Text type="danger" className="font-size-sm">
                {formik.errors.customerFullAddress &&
                formik.touched.customerFullAddress
                  ? formik.errors.customerFullAddress
                  : ''}
              </Typography.Text>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-2 m-0 " htmlFor="customerName">
              Người mua hàng
            </label>

            <div className="col-md-10 m-0">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  className: 'flex-grow-1 w-100',
                  value: formik.values.customerName,
                  onChange: (e) =>
                    formik.setFieldValue('customerName', e.target.value),
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-2 m-0 " htmlFor="customerPhone">
              Số điện thoại
            </label>

            <div className="col-md-10 m-0">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  className: 'flex-grow-1 w-100',
                  value: formik.values.customerPhone,
                  onChange: (e) =>
                    formik.setFieldValue('customerPhone', e.target.value),
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-4 m-0 " htmlFor="customerAccountNumber">
              STK ngân hàng
            </label>

            <div className="col-md-8">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  className: 'flex-grow-1 w-100',
                  value: formik.values.customerAccountNumber,
                  onChange: (e) =>
                    formik.setFieldValue(
                      'customerAccountNumber',
                      e.target.value,
                    ),
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-4 m-0 " htmlFor="customerBankName">
              Tên ngân hàng
            </label>

            <div className="col-md-8">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  className: 'flex-grow-1 w-100',
                  value: formik.values.customerBankName,
                  onChange: (e) =>
                    formik.setFieldValue('customerBankName', e.target.value),
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-4 m-0 " htmlFor="paymentMethod">
              Hình thức TT
            </label>

            <div className="col-md-8">
              <CustomAntSelect
                value={formik.values.paymentMethod}
                options={SelectOptions.paymentMethod}
                onChange={(value) =>
                  formik.setFieldValue('paymentMethod', value)
                }
                placeholder="Chọn hình thức thanh toán"
                showSearch={false}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group d-flex align-items-center">
            <label className="col-md-4 m-0 " htmlFor="customerEmail">
              Email
            </label>

            <div className="col-md-8">
              <CustomAntInput
                type="text"
                borderRadius="sm"
                inputProps={{
                  className: 'flex-grow-1 w-100',
                  value: formik.values.customerEmail,
                  onChange: (e) =>
                    formik.setFieldValue('customerEmail', e.target.value),
                  status:
                    formik.errors.customerEmail && formik.touched.customerEmail
                      ? 'error'
                      : undefined,
                }}
              />
              <Typography.Text type="danger" className="font-size-sm">
                {formik.errors.customerEmail &&
                  formik.touched.customerEmail &&
                  formik.errors.customerEmail}
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
