import { transformingBack } from 'general/components/AppDragAndDrop/functions/helpers'
import last from 'lodash/last'
import { useMemo } from 'react'
import { useAppSelector } from './useRedux'

/**
 * @template T
 * @param {T[]} defaultValue
 * @returns {T[]}
 */
export default function useAppDnD(defaultValue) {
  const columns = useAppSelector((s) => s.table.dynamicColumns)
  const detectLastItemIsActionCol =
    last(defaultValue)?.disabled ||
    last(defaultValue)?.name.toLowerCase() === 'hành động' ||
    last(defaultValue)?.name.toLowerCase() === ''

  const dynamicColumns = useMemo(() => {
    const transformed = transformingBack(columns, defaultValue)
    return detectLastItemIsActionCol
      ? [...transformed, last(defaultValue)]
      : transformed
  }, [columns, defaultValue, detectLastItemIsActionCol])

  return dynamicColumns
}
