import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import orderApi from 'api/orderApi'
import ToastHelper from 'general/helpers/ToastHelper'

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (params, thunkApi) => {
    try {
      const response = await orderApi.getByCompanyId(params)
      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (params, thunkApi) => {
    try {
      const response = await orderApi.create(params)
      // ToastHelper.showSuccess('Đơn hàng đã được tạo thành công');
      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

export const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async (params, thunkApi) => {
    try {
      const response = await orderApi.update(params)
      ToastHelper.showSuccess('Đơn hàng đã được cập nhật thành công')
      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

export const deleteOrder = createAsyncThunk(
  'orders/deleteOrder',
  async (orderId, thunkApi) => {
    try {
      await orderApi.deleteOrder(orderId)
      ToastHelper.showSuccess('Đơn hàng đã được xóa thành công')
      return orderId
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false
        state.orders = action.payload
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      //   .addCase(createOrder.fulfilled, (state, action) => {
      //     state.orders.push(action.payload);
      //   })
      .addCase(updateOrder.fulfilled, (state, action) => {
        const updatedOrder = action.payload
        const index = state.orders.findIndex(
          (order) => order?.value?.orderId == updatedOrder?.orderId,
        )
        if (index !== -1) {
          state.orders[index].value = updatedOrder
        }
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        const deletedOrderId = action.payload
        state.orders = state.orders.filter(
          (order) => order?.value?.orderId !== deletedOrderId,
        )
      })
  },
})

// Export reducer và actions
const { actions, reducer } = orderSlice
export default reducer
