import { Button, Flex, Modal } from 'antd'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import useKeyboardPress from 'hooks/useKeyboardPress'
import useToken from 'hooks/useToken'
import useWindowSize from 'hooks/useWindowSize'
import _merge from 'lodash/merge'
import { forwardRef, useState } from 'react'
import Draggable from 'react-draggable'

/**
 * A draggable modal component that wraps around Ant Design's Modal component.
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.title - The title of the modal.
 * @param {string} props.description - The description of the modal.
 * @param {boolean} props.isOpen - A boolean indicating whether the modal is open or not.
 * @param {function} props.onOpenChange - A function that is called when the modal is opened or closed.
 * @param {function} props.handleApplyChanges - A function that is called when the "Apply" button is clicked.
 * @param {string} props.applyText - The text to display on the "Apply" button.
 * @param {string} props.cancelText - The text to display on the "Cancel" button.
 * @param {string | number} props.width - The width of the modal.
 * @param {string | number} props.height - The height of the modal.
 * @param {boolean} props.isLoading - Loading state of the modal.
 * @param {Object} props.children - The children of the modal.
 * @param {boolean} props.hasApplyButton - A boolean indicating whether the modal has an "Apply" button or not.
 * @param {boolean} props.hasCancelButton - A boolean indicating whether the modal has a "Cancel" button or not.
 * @param {'warning' | 'danger'} props.status - Whether the title has an icon depends on the status.
 * @param {import('antd').ModalProps['styles']} props.styles - The styles of the modal.
 * @param {Object} ref - The parent ref object which modal can drag.
 * @returns {JSX.Element} - A JSX element representing the draggable modal.
 */
const DraggableModal = (props, ref) => {
  const {
    title,
    description,
    isOpen,
    onOpenChange,
    handleApplyChanges,
    applyText,
    cancelText,
    width,
    isLoading,
    height,
    hasApplyButton = true,
    styles,
    children,
    status,
    hasCancelButton,
  } = props
  const [disabled, setDisabled] = useState(true)
  const { token } = useToken()
  const windowSize = useWindowSize()
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  })
  const draggleRef = ref
  const onStart = (_event, uiData) => {
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) return null
    setBounds({
      left: -targetRect.left + uiData.x,
      right: windowSize.width - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: windowSize.height - (targetRect.bottom - uiData.y),
    })
  }

  useKeyboardPress('Escape', () => onOpenChange(false))

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      centered
      destroyOnClose
      closeIcon={false}
      onCancel={() => onOpenChange(false)}
      width={width ?? '55rem'}
      styles={_merge(
        {
          content: { padding: 0 },
          body: {
            height: height ?? 'auto',
            display: 'flex',
            flexDirection: 'column',
            padding: token.paddingMD,
          },
          footer: {
            padding: `0.75rem`,
            marginTop: 0,
          },
          header: {
            padding: token.paddingSM,
          },
        },
        styles,
      )}
      title={
        <Flex
          align="center"
          justify="space-between"
          style={{
            width: '100%',
            cursor: 'move',
            userSelect: 'none',
          }}
          onMouseOver={() => {
            setDisabled(false)
          }}
          onMouseOut={() => {
            setDisabled(true)
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          <div className="d-flex flex-column gap-2">
            <h3 className="fw-bold fs-4 flex-fill m-0 d-inline-flex gap-2 align-items-center">
              {status === 'danger' ? (
                <i className="fa-regular fa-times-circle fa-lg text-danger" />
              ) : status === 'warning' ? (
                <i className="fa-regular fa-exclamation-triangle fa-lg text-warning" />
              ) : null}
              {title}
            </h3>
            {description && (
              <span className="fs-6 fst-italic fw-medium">{description}</span>
            )}
          </div>
          <Button
            className="align-self-start"
            type="text"
            shape="circle"
            icon={<i className="fa-regular fa-times text-dark-75" />}
            onClick={() => onOpenChange(false)}
          />
        </Flex>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          nodeRef={draggleRef}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      footer={
        <div className="d-flex justify-content-end gap-3">
          {hasCancelButton && (
            <CustomAntButton
              text={cancelText ?? 'Hủy bỏ'}
              antProps={{
                type: 'default',
                htmlType: 'button',
                danger: true,
                disabled: isLoading,
                onClick: () => onOpenChange(false),
              }}
            />
          )}
          {hasApplyButton && (
            <CustomAntButton
              text={applyText ?? 'Áp dụng'}
              isLoading={isLoading}
              antProps={{
                type: 'primary',
                htmlType: 'button',
                disabled: isLoading,
                onClick: handleApplyChanges,
              }}
            />
          )}
        </div>
      }
    >
      {children}
    </Modal>
  )
}

export default forwardRef(DraggableModal)
