import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import AdjustedTicketScreen from './screens/AdjustedTicketScreen/AdjustedTicketScreen'
import CanceledTicketScreen from './screens/CanceledTicketScreen/CanceledTicketScreen'
import CreateTicketErrorAnnouncement from './screens/CreateTicketErrorAnnouncement'
import ErrorTicketAnnoucement from './screens/ErrorTicketAnnoucement'
import ReplacedTicketScreen from './screens/ReplacedTicketScreen/ReplacedTicketScreen'

export default function HandleTicket() {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path="" element={<Navigate to="huy-bo" />} />
      <Route path="huy-bo/*" element={<CanceledTicketScreen />} />
      <Route path="thay-the/*" element={<ReplacedTicketScreen />} />
      <Route path="dieu-chinh/*" element={<AdjustedTicketScreen />} />
      <Route
        path="thong-bao-sai-sot"
        element={<ErrorTicketAnnoucement currentTab={0} />}
      />
      <Route
        path="thong-bao-sai-sot/hoa-don"
        element={<ErrorTicketAnnoucement currentTab={1} />}
      />
      <Route
        path="thong-bao-sai-sot/hoa-don-chua-lap-thong-bao-sai-sot"
        element={<ErrorTicketAnnoucement currentTab={2} />}
      />

      <Route
        path={`thong-bao-sai-sot/:ticketIds`}
        element={<CreateTicketErrorAnnouncement />}
      />

      <Route
        path={'chinh-sua-thong-bao-sai-sot/:errAnnouceId'}
        element={<CreateTicketErrorAnnouncement />}
      />

      <Route
        path={'thong-bao-sai-sot-khac-he-thong/:invoiceInfo'}
        element={<CreateTicketErrorAnnouncement />}
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}
