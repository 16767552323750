import customDataTableStyle from 'assets/styles/customDataTableStyle'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomDataTable from 'general/components/CustomDataTable'
import useKeyboardPress from 'hooks/useKeyboardPress'
import useToken from 'hooks/useToken'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryBuyerByTaxCode } from './queries'
import './style.scss'

/**
 * @param {object} props
 * @param {function} props.handleSelectCustomer
 * @param {function} props.onClickAddCustomer
 * @param {(open ?: boolean) => void} props.onOpenChange
 * @param {any} props.inputRef
 * @param {string} props.searchValue
 */
const CustomDropdown = (props) => {
  const { token } = useToken()
  const {
    handleSelectCustomer,
    onClickAddCustomer,
    searchValue,
    onOpenChange,
    inputRef,
  } = props
  const { t } = useTranslation()
  const [filterParams] = useState({
    q: '',
    page: 1,
    limit: 20,
    status: 'ACTIVE',
  })

  const dropdownRef = useRef(null)

  const { dataSource, isLoading, isRefetching } = useQueryBuyerByTaxCode(
    searchValue,
    filterParams,
  )

  const columns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<any>[]} */
    const arr = [
      {
        name: 'Mã khách hàng',
        width: '150px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.code}
            </p>
          )
        },
      },
      {
        name: 'Tên khách hàng',
        // width: '250px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.name}
            </p>
          )
        },
      },
      {
        name: 'Địa chỉ',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.address}
            </p>
          )
        },
      },

      {
        name: 'Mã số thuế',
        // sortable: false,
        // right: true,
        width: '180px',
        cell: (row) => {
          return (
            <p data-tag="allowRowEvents" className="m-0">
              {row?.taxCode}
            </p>
          )
        },
      },
    ]
    return arr
  }, [])

  useKeyboardPress('Escape', () => onOpenChange(false))

  useEffect(() => {
    const listener = (event) => {
      if (
        !dropdownRef?.current ||
        dropdownRef?.current?.contains(event.target) ||
        inputRef?.current?.contains(event.target)
      ) {
        return
      }
      onOpenChange(false)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [inputRef, onOpenChange])

  return (
    <div className="custom-dropdown" ref={dropdownRef} style={{ ...props }}>
      <CustomDataTable
        columns={columns}
        dataSource={dataSource}
        customTableStyle={customDataTableStyle}
        isLoading={isLoading || isRefetching}
        isClickable
        handleClickRow={handleSelectCustomer}
      />
      <div className="d-flex justify-content-between p-2">
        <CustomAntButton
          text={t('AddNew')}
          // iconCn="fas fa-plus"
          customStyle={{
            color: token.colorPrimaryText,
          }}
          antProps={{
            type: 'text',
            onClick: onClickAddCustomer,
          }}
        />
        <CustomAntButton
          text={t('Close') + ' (Esc)'}
          // iconCn="fas fa-xmark"
          customStyle={{
            color: token.colorErrorText,
          }}
          antProps={{
            type: 'text',
            onClick: () => onOpenChange(false),
          }}
        />
      </div>
    </div>
  )
}

export default CustomDropdown
