import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userFunctionApi from 'api/userFunctionApi'
import listFunctionApi from 'api/listFunctionApi'
import ToastHelper from 'general/helpers/ToastHelper'

// Hàm async thunk để lấy danh sách user functions
export const getAllUserFunction = createAsyncThunk(
  'functions/getAllUserFunction',
  async () => {
    const response = await userFunctionApi.getAll() // Gọi API lấy user functions
    return response // Trả về dữ liệu từ API
  },
)

// Hàm async thunk để lấy danh sách list functions
export const getAllListFunction = createAsyncThunk(
  'functions/getAllListFunction',
  async (params, thunkApi) => {
    const response = await listFunctionApi.getAll(params) // Gọi API lấy list functions
    return response // Trả về dữ liệu từ API
  },
)

export const updateListFunction = createAsyncThunk(
  'functions/update',
  async (params, thunkApi) => {
    try {
      const response = await listFunctionApi.update(params) // Gọi API để tạo hàng mới
      return response // Trả về dữ liệu từ API
    } catch (error) {
      // Xử lý lỗi nếu có
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const updateAllListFunction = createAsyncThunk(
  'functions/updateAllListFunction',
  async (params, thunkApi) => {
    try {
      const response = await listFunctionApi.updateAll(params) // Gọi API để tạo hàng mới
      ToastHelper.showSuccess('Cập nhật thành công')
      return response // Trả về dữ liệu từ API
    } catch (error) {
      // Xử lý lỗi nếu có
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

// Slice cho functions
const functionSlice = createSlice({
  name: 'functions',
  initialState: {
    userFunctions: [],
    listFunctions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserFunction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllUserFunction.fulfilled, (state, action) => {
        state.loading = false
        state.userFunctions = action.payload
      })
      .addCase(getAllUserFunction.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getAllListFunction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllListFunction.fulfilled, (state, action) => {
        state.loading = false
        state.listFunctions = action.payload
      })
      .addCase(getAllListFunction.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(updateAllListFunction.pending, (state) => {
        state.loading = true
      })
      .addCase(updateAllListFunction.fulfilled, (state, action) => {
        state.loading = false
        // console.log('action.payload', action.payload);
        // Thêm hàng mới vào listFunctions
        state.listFunctions = action.payload
      })
      .addCase(updateAllListFunction.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      .addCase(updateListFunction.fulfilled, (state, action) => {
        state.loading = false
        const index = state.listFunctions.findIndex(
          (item) => item.listFunctionId === action.payload.listFunctionId,
        )
        if (index !== -1) {
          state.listFunctions[index] = action.payload
        }
      })
  },
})

// Export reducer và actions
const { actions, reducer } = functionSlice
export default reducer
