import invoiceApi from 'api/invoiceApi'

const DownloadXMLFileHelper = async ({ invoiceId, lookupCode }) => {
  const response =
    (invoiceId && (await invoiceApi.download(invoiceId))) ||
    (lookupCode && (await invoiceApi.downloadByLookupCode(lookupCode)))
  if (response?.result === 'success') {
    const blob = new Blob([response.data], { type: 'application/xml' })
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    // link.setAttribute('download', `invoice_${invoiceId}.xml`);
    link.setAttribute('download', `invoice.xml`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export default DownloadXMLFileHelper
