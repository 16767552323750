import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import customerApi from 'api/customerApi'
import { thunkGetCustomerList } from 'features/Category/categorySlice'
import ModalEditCustomer from 'features/Category/components/ModalEditCustomer'
import ViewImportFile from 'features/Category/components/ViewImportFile'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import VIModal from 'general/components/eInvoiceComponents/VIModal'
import AppData from 'general/constants/AppData'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import fileSample from '../../components/ViewImportFile/Mau_danh_muc_khach_hang.xls'

function CustomerScreen() {
  // MARK --- Parmas: ---
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const systemInvoiceType = useAppSelector((s) => s.auth?.activeInvoice)
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'customerList',
    'gFiltersCustomerList',
  )
  const {
    isLoading: gettingListData,
    refetch: refreshData,
    data: customers,
  } = useQuery({
    queryKey: [
      'getListCustomer-customerScreen',
      filters,
      systemInvoiceType.ticket,
      systemInvoiceType.invoice,
      debouncedSearch,
    ],
    queryFn: () =>
      customerApi.find({
        ...filters,
        typeInvoice: systemInvoiceType.ticket
          ? 'TICKET'
          : systemInvoiceType.invoice
            ? 'INVOICE'
            : 'RECEIPT',
        q: debouncedSearch,
      }),

    select: (res) => {
      return {
        total: _.get(res, 'total', 0),
        rows: _.get(res, 'customers', []),
      }
    },
  })
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [toggleClearCustomers, setToggledClearCustomers] = useState(false)
  const [selectedCustomerItem, setSelectedCustomerItem] = useState(null)
  const [showingModalEditCustomer, setShowingModalEditCustomer] =
    useState(false)
  const [active, setActive] = useState('ADD')
  const [modalImportShowing, setModalImportShowing] = useState(false)

  const handleDeleteCustomer = useCallback(
    (customer) => {
      Swal.fire({
        title: t('Xác nhận'),
        text: `Bạn có muốn xóa khách hàng ${customer.name}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Đồng ý'),
        cancelButtonText: t('Hủy'),
        customClass: {
          confirmButton: 'btn btn-danger font-weight-bolder',
          cancelButton: 'btn btn-light font-weight-bolder',
        },
      }).then(async function (result) {
        if (result.value) {
          try {
            const res = await customerApi.delete([customer.customerId])
            if (res.result === 'success') {
              Global.gNeedToRefreshCustomerList = true
              ToastHelper.showSuccess(t('Xóa khách hàng thành công'))
              refreshData()
              // dispatch(thunkGetCustomerList(Global.gFiltersCustomerList))
            }
          } catch (error) {
            console.log(`Delete customer error: ${error?.message}`)
          }
        }
      })
    },
    [refreshData, t],
  )
  const fullColumns = useMemo(
    () => [
      {
        name: 'Mã KH',
        // sortable: false,
        width: '110px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal q-max-line-1 m-0 text-maxline-3 mr-4"
            >
              {row?.code}
            </p>
          )
        },
      },
      {
        name: 'Tên KH',
        // sortable: false,
        minWidth: '250px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="q-max-line-3  font-weight-normal  m-0 text-maxline-3 mr-4"
            >
              {row?.name}
            </p>
          )
        },
      },
      {
        name: 'MST',
        // sortable: false,
        width: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal q-max-line-1 m-0 text-maxline-3 mr-4"
            >
              {row?.taxCode}
            </p>
          )
        },
      },
      {
        name: 'Địa chỉ',
        // sortable: false,
        minWidth: '350px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="q-max-line-3  font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.address}
            </p>
          )
        },
      },
      {
        name: 'Người nhận hoá đơn',
        // sortable: false,
        minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal q-max-line-1 m-0 text-maxline-3 mr-4"
            >
              {row?.contractRecipientName}
            </p>
          )
        },
      },
      {
        name: 'Email',
        // sortable: false,
        minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal q-max-line-1 m-0 text-maxline-3 mr-4"
            >
              {row?.contractRecipientEmails}
            </p>
          )
        },
      },
      {
        name: 'Số điện thoại',
        // sortable: false,
        // minWidth: '220px',
        width: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal q-max-line-1 m-0 text-maxline-3 mr-4"
            >
              {row?.contractRecipientPhone}
            </p>
          )
        },
      },
      {
        name: t('Status'),
        width: '150px',
        center: true,
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className={`badge badge-light-${
                row?.status === 'ACTIVE' ? 'success' : 'danger'
              } `}
            >
              {row.status === 'ACTIVE' ? 'Đang sử dụng' : 'Ngừng sử dụng'}
            </div>
          )
        },
      },
      {
        name: '',
        center: true,
        grow: 0,
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <TableAction
              titleActionText={t('Edit')}
              icon={<IconAction className="fa-light fa-edit" />}
              onClick={() => {
                handleEditCustomer(row)
              }}
            />
            <TableAction
              titleActionText={t('Delete')}
              icon={<IconAction className="fa-light fa-trash" />}
              onClick={() => {
                handleDeleteCustomer(row)
              }}
            />
            <TableAction
              titleActionText={t('Replicate')}
              icon={<IconAction className="fa-light fa-clone" />}
              onClick={() => {
                handleReplicateCustomer(row)
              }}
            />
          </div>
        ),
      },
    ],
    [handleDeleteCustomer, t],
  )

  // MARK --- Function ---

  function handleDeleteMultiCustomer() {
    const arrIdsToDelete = selectedCustomers.map((item) => item.customerId)

    Swal.fire({
      title: t('Xác nhận'),
      text: `Bạn có muốn xóa ${arrIdsToDelete.length} khách hàng ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Đồng ý'),
      cancelButtonText: t('Hủy'),
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder',
        cancelButton: 'btn btn-light font-weight-bolder',
      },
    }).then(async function (result) {
      if (result.value) {
        const customerIds = arrIdsToDelete
        try {
          const res = await customerApi.delete(customerIds)
          if (res.result === 'success') {
            clearSelectedCustomers()
            Global.gNeedToRefreshCustomerList = true
            ToastHelper.showSuccess(t('Xóa khách hàng thành công'))
            refreshData()
          }
        } catch (error) {
          console.log(`Delete customer error: ${error?.message}`)
        }
      }
    })
  }

  function clearSelectedCustomers() {
    setSelectedCustomers([])
    setToggledClearCustomers((prev) => !prev)
  }

  function handleEditCustomer(customer) {
    setActive('EDIT')
    setSelectedCustomerItem(customer)
    setShowingModalEditCustomer(true)
  }

  function handleReplicateCustomer(customer) {
    setActive('REPLICATE')
    setSelectedCustomerItem(customer)
    setShowingModalEditCustomer(true)
  }

  const dynamicColumns = useAppDnD(fullColumns)

  return (
    <ContentContainer wrapperClassName="list-replaced-invoice">
      <ContentContainer.Header
        titleContent={t('Khách hàng')}
        description={`${t('Tổng')}: ${customers?.total} ${_.capitalize(
          t('Khách hàng'),
        )}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('Thêm khách hàng')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActive('ADD')
                  setShowingModalEditCustomer(true)
                },
              }}
            />
            <CustomAntButton
              text={t('Upload')}
              iconCn="fa-regular fa-upload"
              antProps={{
                type: 'primary',
                className: 'btn btn-success',
                onClick: () => setModalImportShowing(true),
              }}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={PreferenceKeys.savedColumnsCustomer}
            />
          </Space>
        }
      >
        <Space>
          {selectedCustomers.length > 0 ? (
            <CustomAntButton
              iconCn="fa-regular fa-trash"
              text={Utils.cn(t('Xóa'), selectedCustomers.length, 'khách hàng')}
              antProps={{
                type: 'primary',
                danger: true,
                onClick: () => {
                  handleDeleteMultiCustomer()
                },
              }}
            />
          ) : (
            <>
              <CustomAntInput
                type="search"
                borderRadius="sm"
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
              <label className="mr-2 mb-0" htmlFor="state">
                {_.capitalize(t('Trạng thái'))}
              </label>
              <KTFormSelect
                name="status"
                isCustom
                options={[{ name: 'Tất cả', value: '' }, ...AppData.status]}
                value={filters.status}
                onChange={(newValue) => {
                  setFilters({
                    page: 0,
                    status: newValue,
                  })
                }}
              />
              <label className="mr-2 mb-0" htmlFor="state">
                {_.capitalize(t('Loại khách hàng'))}
              </label>
              <KTFormSelect
                name="type"
                isCustom
                options={[
                  { name: 'Tất cả', value: '' },
                  ...AppData.customerType,
                ]}
                value={filters.type}
                onChange={(newValue) => {
                  setFilters({
                    page: 0,
                    type: newValue,
                  })
                }}
              />
            </>
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={customers?.rows}
          isLoading={gettingListData}
          isClickable
          isSelectable
          handleDoubleClickRow={(row) => handleEditCustomer(row)}
          pagination={{
            currentPage: filters?.page + 1,
            rowsPerPage: filters?.limit,
            total: customers?.total,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              if (iNewPage > 0) {
                iNewPage = iNewPage - 1
              }
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 0,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows: toggleClearCustomers,
            handleSelectedRows: ({ selectedRows }) =>
              setSelectedCustomers(selectedRows),
            selectableRowSelected: (row) => {
              const arrId = selectedCustomers.map((item) => item.customerId)
              return arrId.includes(row.customerId)
            },
          }}
        />
      </ContentContainer.Body>

      {/* Modal customer edit */}
      <ModalEditCustomer
        show={showingModalEditCustomer}
        onClose={() => {
          setShowingModalEditCustomer(false)
          setSelectedCustomerItem(null)
        }}
        onRefreshCustomerList={() => {
          refreshData()
        }}
        active={active}
        customerItem={selectedCustomerItem}
      />

      {/* modal import */}
      <VIModal
        titleModal={t('Upload')}
        modalSize="lg"
        show={modalImportShowing}
        contentModal={
          <ViewImportFile
            onPressCancel={() => {
              setModalImportShowing(false)
            }}
            onDownloadSampleFile={() => {
              const link = document.createElement('a')
              link.href = fileSample
              link.download = 'Mau_danh_muc_khach_hang.xls'
              link.click()
            }}
            onUpload={async (file) => {
              const res = await customerApi.upload(file)
              if (res.result === 'success') {
                ToastHelper.showSuccess(t('ImportCustomerListSuccess'))
                dispatch(thunkGetCustomerList())
                setModalImportShowing(false)
              }
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalImportShowing(false)
        }}
      />
    </ContentContainer>
  )
}

export default CustomerScreen
