import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import Empty from 'general/components/Empty'
import Loading from 'general/components/Loading'
import AppData from 'general/constants/AppData'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

CardAreaChartV2.propTypes = {
  additionalClassName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  chartSeries: PropTypes.array,
  chartLabels: PropTypes.array,
  toolbarElement: PropTypes.element,
  sideBarElement: PropTypes.element,
  chartColors: PropTypes.array,
  headerSidebar: PropTypes.element,
  loading: PropTypes.bool,
  fullChartLabels: PropTypes.array,
  customYAxis: PropTypes.string,
  fill: PropTypes.bool,
}

CardAreaChartV2.defaultProps = {
  additionalClassName: '',
  title: '',
  subTitle: '',
  chartSeries: [],
  chartLabels: [],
  toolbarElement: null,
  sideBarElement: null,
  chartColors: AppData.chartColors,
  headerSidebar: null,
  loading: false,
  fullChartLabels: [],
  customYAxis: '',
  fill: true,
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)
function CardAreaChartV2(props) {
  const {
    title,
    subTitle,
    chartSeries,
    chartLabels,
    toolbarElement,
    sideBarElement,
    chartColors,
    headerSidebar,
    loading,
    fullChartLabels,
    customYAxis,
    fill,
  } = props
  const { t } = useTranslation()

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      devicePixelRatio: 2,
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            font: {
              size: 12,
            },
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || ''

              if (label) {
                label += ': '
              }
              if (customYAxis) {
                return label + context.parsed.y + '%'
              } else {
                return label + Utils.formatNumber(context.parsed.y)
              }
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            callback: function (_value, index) {
              return fullChartLabels[index].slice(0, 2)
            },
          },
        },
        y: {
          ticks: {
            callback: function (value) {
              if (customYAxis) {
                return Utils.formatNumber(value) + customYAxis
              } else {
                return Utils.formatNumber(value)
              }
            },
          },
        },
      },
    }),
    [customYAxis, fullChartLabels],
  )
  const series = useMemo(() => {
    const labelIndexMap = new Map(
      chartLabels.map((label, index) => [label, index]),
    )
    const result = chartSeries.map((s) => {
      const data = fullChartLabels.map((chartLabel) => {
        const index = labelIndexMap.get(chartLabel)
        if (!_.isNil(index)) {
          return s.data[index]
        } else {
          return 0
        }
      })
      return { ...s, data }
    })
    return result
  }, [chartLabels, chartSeries, fullChartLabels])

  const data = useMemo(() => {
    return {
      labels: fullChartLabels,
      datasets: series.map((item, index) => {
        return {
          label: item.name,
          data: item.data,
          borderColor: chartColors[index],
          fill,
          backgroundColor: [0.6].map((i) =>
            Utils.blurColor(chartColors[index], i),
          ),
          cubicInterpolationMode: 'monotone',
          tension: 0.5,
        }
      }),
    }
  }, [fullChartLabels, series, chartColors, fill])

  const apexChartEl = useMemo(() => {
    return <Line options={options} data={data} />
  }, [options, data])

  return (
    <div
      className={`CardAreaChartV2 card card-custom bg-white ${
        sideBarElement ? 'row m-0 pr-0' : ''
      }`}
    >
      <div className={`${sideBarElement ? 'col-9' : ''}`}>
        <div
          className={`card-header bg-transparent px-6 border-0 py-6 ${
            toolbarElement ? '' : 'pb-0'
          }`}
        >
          <div
            className={`${toolbarElement ? 'mb-6' : ''} ${
              headerSidebar
                ? 'd-flex flex-wrap align-items-center justify-content-between'
                : ''
            }`}
          >
            {/* card title */}
            <h3 className="card-title m-0">
              <span className="card-label font-weight-bolder font-size-h4 ">
                {title}
              </span>
              <span className="d-block text-muted mt-2 font-size-base">
                {subTitle}
              </span>
            </h3>
            <div>{headerSidebar}</div>
          </div>
          {/* card toolbar */}
          {toolbarElement && (
            <div className="card-toolbar">{toolbarElement}</div>
          )}
        </div>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center h-100 pb-3">
            <Loading showBackground={false} />
          </div>
        ) : chartSeries?.some((item) => item.data?.length > 0) ? (
          <div className="h-100 pr-3 pl-6" style={{ minHeight: '340px' }}>
            {apexChartEl}
          </div>
        ) : (
          <div className="pt-12" style={{ height: '340px' }}>
            <Empty
              text={t('NoData')}
              visible={false}
              imageEmpty={AppResource.icons.icEmptyBox}
              imageEmptyPercentWidth={20}
            />
          </div>
        )}
      </div>
      {sideBarElement && (
        <div className="border-left col-3 px-0">{sideBarElement}</div>
      )}
    </div>
  )
}

export default CardAreaChartV2
