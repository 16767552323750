export const keyFactory = {
  base: {
    scope: ['invoice', 'listInvoice'],
  },
  lists: (q, p) => [
    {
      ...keyFactory.base,
      params: {
        ...p,
        q,
      },
    },
  ],
  updatePaidStatus: () => [
    {
      ...keyFactory.base,
      action: 'updatePaidStatus',
    },
  ],
  bulkUpdatePaidStatus: () => [
    {
      ...keyFactory.base,
      action: 'updatePaidStatus',
    },
  ],
  downloadPdf: () => [
    {
      ...keyFactory.base,
      action: 'downloadPdf',
    },
  ],
  cancel: () => [
    {
      ...keyFactory.base,
      action: 'cancelInvoice',
    },
  ],
  sendExplanationAnnounce: () => [
    {
      ...keyFactory.base,
      action: 'sendExplanationAnnounce',
    },
  ],

  downloadInvoice: () => [
    {
      ...keyFactory.base,
      action: 'downloadInvoice',
    },
  ],

  prints: () => [
    {
      ...keyFactory.base,
      action: 'prints',
    },
  ],

  delete: () => [
    {
      ...keyFactory.base,
      action: 'delete',
    },
  ],
  listTaxAuthorityMessage: () => [
    {
      ...keyFactory.base,
      entity: 'taxAuthorityMessage',
    },
  ],
  viewPdf: () => [
    {
      ...keyFactory.base,
      action: 'viewPdf',
    },
  ],
  resendIssue: () => [
    {
      ...keyFactory.base,
      action: 'resendIssue',
    },
  ],
}
