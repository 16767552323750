import { useTranslation } from 'react-i18next'
import { TableCustom, ButtonSave } from '../../TaxDeduction/styled'
import {
  TitleRight,
  ContainerSearch,
  ContainerTable,
  DivListFunction,
  ContainerFunction,
  DivRight,
  InputCustom,
  FormCustom,
} from '../../TaxDeductionDocuments/styled'
import {
  DatabaseOutlinedTitleIcon,
  DivColRightTitle,
  DivRight as DivRightTitle,
  DivRowTitle,
  EllipsisDiv,
  LabelTitle,
} from '../styled'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dropdown, Empty, Form, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useRedux'
import SelectOptions from 'general/constants/SelectOptions'
import HeaderTaxpayer from '../component/Layout/headerRight'
import Update from '../component/Update'
import { debounce } from 'lodash'
import AppData from 'general/constants/AppData'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  thunkAutomaticallyTaxpayer,
  thunkFindByIdTaxpayer,
  thunkSaveTaxpayer,
} from '../taxpayerSlice'
import AlertTaxDectionDocumentsUpdate from 'features/TaxDeductionDocuments/component/Alert'
import ToastHelper from 'general/helpers/ToastHelper'
import dayjs from 'dayjs'
import { setDate } from 'general/utils/Utils'

function UpdateScreen({ popup }) {
  const { taxpayersId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataSource, setDataSource] = useState([])
  const [auth, setAuth] = useState({})
  const [taxpayers, setTaxpayers] = useState({})
  const [form] = Form.useForm()
  const [formState, setFormState] = useState({})
  const [ppenModalAlert, setOpenModalAlert] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [automatically, setAutomatically] = useState({})

  const resetForm = async (values) => {
    setAutomatically(values)
    form?.resetFields()
    if (taxpayersId) {
      const result = await getInforById(taxpayersId)
      if (result?.message == 'success') {
        let data = {
          ...result?.data,
          internshipStartDate: setDate(result?.data?.internshipStartDate),
          employeeBirthDate: setDate(result?.data?.employeeBirthDate),
          probationStartDate: setDate(result?.data?.probationStartDate),
          employeeIdentification_date: setDate(
            result?.data?.employeeIdentification_date,
          ),
          statusAddressCurrent: result?.data?.statusAddressCurrent
            ? [result?.data?.statusAddressCurrent]
            : [],
          terminationDate: setDate(result?.data?.terminationDate),
          officialStartDate: setDate(result?.data?.officialStartDate),
          jobTitleId: result?.data?.jobTitleId
            ? result?.jobPosition?.jobTitleId
              ? result.jobPosition.jobTitleId
              : null
            : null,
        }
        form.setFieldsValue(data)
        setFormState(data)
      }
    } else {
      let data = {
        employeeType: 1,
        documentType: 1,
        employeeCode: values?.taxpayersCodeEmployee,
        gender: 'Nam',
        employeeIdentification_nationlityId: 1,
        contractType: AppData.contractType[0],
        employeeIdentification_countryId: 1,
        employeeAddress: '',
        statusAddressCurrent: [],
        workingStatus: AppData.workingStatus[0],
      }
      form.setFieldsValue(data)
      setFormState(data)
    }
  }
  useEffect(() => {
    start()
  }, [])

  const start = async () => {
    let result = await getAutomatically()
    await resetForm(result)
  }

  const getAutomatically = async () => {
    try {
      const result = unwrapResult(
        await dispatch(thunkAutomaticallyTaxpayer({})),
      )
      if (result?.message == 'success') {
        return result.data
      } else {
        return {}
      }
    } catch (error) {}
  }

  const debouncedSetFormState = useCallback(
    debounce((dataForm) => {
      setFormState(dataForm)
    }, 0), // 300ms delay
    [],
  )

  const onValuesChange = (changedValues, allValues) => {
    debouncedSetFormState(allValues)
  }

  const submit = (type) => {
    form
      .validateFields()
      .then((values) => {
        setDisableSubmit(true)
        save(
          {
            ...values,
            taxpayersId,
            statusAddressCurrent: values?.statusAddressCurrent?.[0],
          },
          type,
        )
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo)
        const errorFields = errorInfo?.errorFields
        if (errorFields.length > 0) {
          const fieldName = errorFields[0].name[0] // Get the name of the first field that failed validation
          const fieldInstance = form.getFieldInstance(fieldName) // Get the instance of that field
          if (fieldInstance) {
            fieldInstance.focus() // Focus the field
          }
        }
      })
  }

  const save = async (body, type) => {
    const result = unwrapResult(await dispatch(thunkSaveTaxpayer(body)))
    setDisableSubmit(false)
    if (result?.message === 'success') {
      if (type) {
        ToastHelper.showSuccess(`${t('taxpayer.messageNnt')}`)
        resetForm()
      } else {
        ToastHelper.showSuccess(`${t('taxpayer.messageNnt')}`)
        navigate('/hoa-don-dau-ra/chung-tu-tncn/nguoi-nop-thue')
      }
    } else {
      ToastHelper.showError(result?.data)
    }
  }

  const getInforById = async (id) => {
    const result = unwrapResult(
      await dispatch(thunkFindByIdTaxpayer({ taxpayersId: id })),
    )
    return result
  }

  return (
    <>
      <ContainerSearch>
        <DivRowTitle
          className="pt-4 row"
          style={{ borderBottom: '1px solid #e0e0e0' }}
        >
          <div className="col-sm-4">
            <TitleRight>
              {taxpayersId ? t('taxpayer.editTitle') : t('taxpayer.addTitle')}
            </TitleRight>
          </div>
          <DivColRightTitle className="col-sm-8">
            <HeaderTaxpayer
              hideSave={taxpayersId}
              onClickSave={() => {
                submit(1)
              }}
              onClickSavePrimary={() => {
                submit()
              }}
              onClickCancel={() => {
                setOpenModalAlert(true)
              }}
              disableSubmit={disableSubmit}
            />
          </DivColRightTitle>
        </DivRowTitle>
        <FormCustom form={form} onValuesChange={onValuesChange}>
          <ContainerTable
            style={{
              padding: '0px 10px',
              margin: '0px -10px',
              borderRadius: '3px',
            }}
          >
            <div className="pt-4 pb-2 row">
              <div className="col-sm-12 ">
                <LabelTitle>{t('taxpayer.inforpublic')}</LabelTitle>
              </div>
            </div>
            <Update
              taxpayersId={taxpayersId}
              form={form}
              formState={formState}
              setFormState={(value) => {
                setFormState(value)
              }}
              save={(type) => {
                submit(type)
              }}
            />
          </ContainerTable>
        </FormCustom>
      </ContainerSearch>
      <AlertTaxDectionDocumentsUpdate
        titlePrimary={t('taxDeductionDocuments.save')}
        openModalAlert={ppenModalAlert}
        titleAlert={t('taxDeductionDocuments.dataHasChanged')}
        messageAlert={t('taxDeductionDocuments.messageAlert')}
        setOpenModalAlert={(value) => {
          setOpenModalAlert(value)
        }}
        titleButtonNotRecommended={t('taxDeductionDocuments.notSave')}
        onClickButtonNotRecommended={() => {
          navigate('/hoa-don-dau-ra/chung-tu-tncn/nguoi-nop-thue')
        }}
        onClickPrimary={() => {
          submit()
        }}
      />
    </>
  )
}
export default UpdateScreen
