import { Typography } from 'antd'
import { useFormik } from 'formik'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { useRef } from 'react'
import * as yup from 'yup'

export default function ModalConvertPaper(props) {
  const {
    showModalConvertPaper,
    setShowModalConvertPaper,
    handleConvertPaper,
    isLoading,
  } = props
  const {
    submitForm,
    resetForm,
    getFieldProps,
    getFieldMeta,
    getFieldHelpers,
  } = useFormik({
    initialValues: {
      converterName: '',
    },
    validationSchema: yup.object({
      converterName: yup.string().required('Trường không được để trống'),
    }),
    onSubmit: (values) => {
      handleConvertPaper(values.converterName)
    },
  })
  const ref = useRef(null)

  return (
    <DraggableModal
      ref={ref}
      title="Chuyển thành hóa đơn giấy"
      isOpen={showModalConvertPaper}
      onOpenChange={(status) => {
        setShowModalConvertPaper(status)
        resetForm()
      }}
      applyText="Tiếp tục"
      cancelText="Đóng"
      isLoading={isLoading}
      width={400}
      handleApplyChanges={submitForm}
    >
      <div className="form-group">
        <CustomLabel
          text="Họ và tên người chuyển đổi"
          isRequired
          fontWeight={600}
          width={'100%'}
        />
        <CustomAntInput
          type="text"
          inputProps={{
            value: getFieldProps('converterName').value,
            onChange: (e) =>
              e.target.value.length < 31 &&
              getFieldHelpers('converterName').setValue(e.target.value),
            status:
              getFieldMeta('converterName').touched &&
              getFieldMeta('converterName').error
                ? 'error'
                : undefined,
          }}
        />
        <Typography.Text type="danger" className="font-size-sm">
          {getFieldMeta('converterName').touched &&
            getFieldMeta('converterName').error}
        </Typography.Text>
      </div>
    </DraggableModal>
  )
}
