import _ from 'lodash'
import * as Y from 'yup'

/**
 * @template T
 * @param {object} params
 * @param {Y.SchemaOf<T>} params.schema
 * @param {string} params.clientPrefix - prefix of environment variables
 * @param {Record<string, string>} params.runtimeEnv - runtime environment variables
 * @returns
 */
function createEnv({ schema, clientPrefix, runtimeEnv }) {
  const env = _.chain(runtimeEnv)
    .pickBy((value, key) => key.startsWith(clientPrefix))
    .mapKeys((value, key) => key.slice(clientPrefix.length))
    .value()
  return schema.validateSync(env)
}

export const env = createEnv({
  schema: Y.object({
    API_URL: Y.string(),
    BASE_URL: Y.string(),
    PORT: Y.string(),
    TRA_CUU: Y.string(),
    DEV_TOOLS_ENABLED: Y.number().oneOf([0, 1]),
    SOCKET_URL: Y.string(),
    WEBSOCKET: Y.string(),
    BASE_URL_SCHEMA: Y.string(),
    BASE_FILE_URL: Y.string(),
    MAX_WAIT_SIGN: Y.string(),
    PROJECT_TYPE: Y.string().oneOf(
      ['TULUC', 'PETRO', 'HOADONDIENTU', 'SONPHAT'],
      'Wrong type. Must be one of TULUC, PETRO, HOADONDIENTU, SONPHAT',
    ),
  }),
  clientPrefix: 'REACT_APP_',
  runtimeEnv: process.env,
})
