import { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'

import './style.scss'
import dayjs from 'dayjs'

function ModalSelectReportingPeriod(props) {
  const { show, setShow, exportTemplateHandler, exportTemplateByIdHandler } =
    props

  const [productType, setProductType] = useState('PETRO') /* 'PETRO'. 'OTHER' */
  const [reportingPeriod, setReportingPeriod] =
    useState('MONTHLY') /* 'MONTHLY', 'QUARTERLY' */
  const [summaryTableType, setSummaryTableType] =
    useState('FIRST') /* 'FIRST', 'MODIFIED', 'ADDITIONAL' */
  const [nth, setNth] = useState()
  const [monthOrQuarterNth, setMonthOrQuarterNth] = useState(1)
  const [year, setYear] = useState(dayjs(new Date()).year())

  let handleClose = () => setShow(false)

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="ModalSelectReportingPeriod"
    >
      <Modal.Header closeButton>
        <Modal.Title>Chọn kỳ báo cáo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row mx-0 g-2 mb-5">
            <div className="col-4 px-0">
              <p className="mb-2 font-sfpro-bold">Loại HHDV</p>
            </div>
            <div className="col-4 px-0">
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-petro"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="hhdv-type"
                  checked={productType == 'PETRO'}
                  value={'PETRO'}
                  onChange={(e) => setProductType(e.target.value)}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-petro"
                >
                  Xăng dầu
                </label>
              </div>
            </div>

            <div className="col-4 px-0">
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-other"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="hhdv-type"
                  checked={productType == 'OTHER'}
                  value={'OTHER'}
                  onChange={(e) => setProductType(e.target.value)}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-other"
                >
                  Khác
                </label>
              </div>
            </div>
          </div>

          <div className="row mx-0 g-2 mb-5">
            <div className="col-4 px-0">
              <p className="mb-2 font-sfpro-bold">Kỳ báo cáo</p>
            </div>
            <div className="col-4 px-0">
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-monthly"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="reporting-period"
                  checked={reportingPeriod == 'MONTHLY'}
                  value={'MONTHLY'}
                  onChange={(e) => {
                    setReportingPeriod(e.target.value)
                    setMonthOrQuarterNth(1)
                  }}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-one-tax-rate"
                >
                  Theo tháng
                </label>
              </div>
            </div>

            <div className="col-4 px-0">
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-quarterly"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="reporting-period"
                  checked={reportingPeriod == 'QUARTERLY'}
                  value={'QUARTERLY'}
                  onChange={(e) => {
                    setReportingPeriod(e.target.value)
                    setMonthOrQuarterNth(1)
                  }}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-mul-tax-rate"
                >
                  Theo quý
                </label>
              </div>
            </div>
          </div>

          <div className="row mx-0 g-2 mb-5">
            <div className="col-5 px-0">
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                style={{ height: '30px' }}
              >
                {reportingPeriod == 'MONTHLY' ? (
                  <>
                    <p className="mb-0 font-sfpro-bold mr-5">Tháng</p>
                    <Form.Select
                      style={{ height: '30px' }}
                      className="mr-5"
                      value={monthOrQuarterNth}
                      onChange={(e) => setMonthOrQuarterNth(e.target.value)}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                      <option value={11}>11</option>
                      <option value={12}>12</option>
                    </Form.Select>
                  </>
                ) : (
                  <>
                    <p className="mb-0 font-sfpro-bold mr-5">Quý</p>
                    <Form.Select
                      style={{ height: '30px' }}
                      className="mr-5"
                      value={monthOrQuarterNth}
                      onChange={(e) => setMonthOrQuarterNth(e.target.value)}
                    >
                      <option value="1">Quý I</option>
                      <option value="2">Quý II</option>
                      <option value="3">Quý III</option>
                      <option value="4">Quý IV</option>
                    </Form.Select>
                  </>
                )}
              </div>
            </div>

            <div className="col-7 px-0">
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                style={{ height: '30px' }}
              >
                <p
                  className="mb-0 font-sfpro-bold"
                  style={{ flex: '1 1 60px' }}
                >
                  Năm
                </p>
                <input
                  type="number"
                  className="form-control flex-fill"
                  style={{ height: '30px' }}
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row mx-0 g-2 mb-5">
            <div className="col-5 px-0 mb-2">
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                style={{ height: '30px' }}
              >
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-lan-dau"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="summary-table-type"
                  checked={summaryTableType == 'FIRST'}
                  value={'FIRST'}
                  onChange={(e) => setSummaryTableType(e.target.value)}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-lan-dau"
                >
                  Lần đầu
                </label>
              </div>
            </div>
            <div className="col-7 px-0 mb-2"></div>

            <div className="col-5 px-0 mb-2">
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                style={{ height: '30px' }}
              >
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-bo-sung"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="summary-table-type"
                  checked={summaryTableType == 'ADDITIONAL'}
                  value={'ADDITIONAL'}
                  onChange={(e) => setSummaryTableType(e.target.value)}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-bo-sung"
                >
                  Bổ sung
                </label>
              </div>
            </div>
            <div className="col-7 px-0 mb-2">
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                style={{ height: '30px' }}
              >
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-one-tax-rate"
                  style={{ flex: '1 1 60px' }}
                >
                  Lần thứ
                </label>

                <input
                  type="text"
                  className="form-control flex-fill h-100"
                  disabled={summaryTableType != 'ADDITIONAL'}
                  value={summaryTableType == 'ADDITIONAL' ? nth : ''}
                  onChange={(e) => setNth(e.target.value)}
                />
              </div>
            </div>

            <div className="col-5 px-0">
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                style={{ height: '30px' }}
              >
                <input
                  className="mr-3 cursor-pointer"
                  type="radio"
                  id="m-sua-doi"
                  style={{ width: '1.2rem', height: '1.2rem' }}
                  name="summary-table-type"
                  checked={summaryTableType == 'MODIFIED'}
                  value={'MODIFIED'}
                  onChange={(e) => setSummaryTableType(e.target.value)}
                />
                <label
                  className="m-0 font-weight-bolder cursor-pointer"
                  htmlFor="m-sua-doi"
                >
                  Sửa đổi
                </label>
              </div>
            </div>
            <div className="col-7 px-0">
              <div
                className="d-flex flex-row align-items-center cursor-pointer"
                style={{ height: '30px' }}
              >
                <label
                  className="m-0 mr-2 font-weight-bolder cursor-pointer"
                  htmlFor="m-one-tax-rate"
                  style={{ flex: '1 1 60px' }}
                >
                  Lần thứ
                </label>

                <input
                  type="text"
                  className="form-control flex-fill h-100"
                  disabled={summaryTableType != 'MODIFIED'}
                  value={summaryTableType == 'MODIFIED' ? nth : ''}
                  onChange={(e) => setNth(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="align-seft-end">
          <button
            className="btn btn-outline-secondary mr-4 px-8"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>
          <button
            className="btn btn-danger px-8"
            onClick={() => {
              if (exportTemplateHandler) {
                exportTemplateHandler(exportOptions)
              } else if (exportTemplateByIdHandler) {
                let { discount, foreignCurrency, ...rest } = exportOptions
                exportTemplateByIdHandler(rest)
              }
            }}
          >
            Đồng ý
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSelectReportingPeriod
