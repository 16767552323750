import KTMenu from 'assets/plugins/ktmenu'
import KTOffcanvas from 'assets/plugins/ktoffcanvas'
import 'assets/styles/keen/theme01/layout/header/base/light.css'
import 'assets/styles/keen/theme01/layout/header/menu/dark.css'
import { getAllCart } from 'features/Cart/CartSlice'
import moment from 'moment'
import 'moment/locale/vi'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import TitleOfHeader from './Components/TitleOfHeader'
import TopBarHeader from './Components/TopBarHeader'
import './style.scss'
moment.locale('vi')

function KT01Header() {
  // MARK: --- Params ---
  const refHeader = useRef()
  const [backgroundColor, setBackgroundColor] = useState(
    'bg-transparent shadow-none',
  )
  const refBackgroundColorTransparent = useRef(true)
  const dispatch = useDispatch()

  // MARK: --- Functions ---

  useEffect(() => {
    dispatch(getAllCart())
  }, [dispatch])

  // MARK: --- Hooks ---
  useEffect(() => {
    function handleScroll() {
      const headerElHeight = refHeader.current.offsetHeight
      if (
        window.scrollY > headerElHeight &&
        refBackgroundColorTransparent.current
      ) {
        refBackgroundColorTransparent.current = false
        setBackgroundColor('bg-white shadow')
      }
      if (
        window.scrollY < headerElHeight &&
        !refBackgroundColorTransparent.current
      ) {
        refBackgroundColorTransparent.current = true
        setBackgroundColor('bg-transparent shadow-none')
      }
    }
    new KTOffcanvas('kt_header_menu_wrapper', {
      baseClass: 'header-menu-wrapper',
      overlay: true,
      toggleBy: {
        target: 'kt_header_mobile_toggle',
      },
    })

    if (KTMenu !== undefined) {
      new KTMenu('kt_header_menu', {
        submenu: {
          desktop: 'dropdown',
          tablet: 'accordion',
          mobile: 'accordion',
        },
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      id="kt_header"
      className={`border-bottom ${backgroundColor}`}
      style={{ height: 'auto' }}
      ref={refHeader}
    >
      <div className="container-fluid d-flex align-items-stretch justify-content-between">
        <TitleOfHeader />
        {/* Top bar */}
        <TopBarHeader />
      </div>
    </div>
  )
}

export default KT01Header
