import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FormCustom,
  SelectCustom,
  InputCustom,
  RedStar,
  DivTddCode,
  DatePickerCustom,
  DivCenterImg,
  UploadCustom,
  IconFileImageOutlined,
  DivUpdateFileLogo,
  DivRemmoveFileLogo,
  RadioCustom,
  DivFlex,
  DivBorder,
  TextlogoFont,
  TextFont,
  ButtonCustom,
  DivfontS,
  ColorPickerCustom,
  LabelForm,
  CheckboxCustom,
} from 'features/TaxDeductionDocuments/styled'
import { Empty } from 'antd'
import AppResource from 'general/constants/AppResource'
import { stubFalse } from 'lodash'
import SelectOptions from 'general/constants/SelectOptions'
import { updateInforCompanyValue, updateJsonKey } from 'general/utils/Utils'
import dayjs from 'dayjs'
function TabSetiingPublic({
  form,
  listDataTemplate,
  resetForm,
  imgLogo,
  setImgLogo,
  setListContentFeild,
  listContentFeild,
  activeTd,
  setActiveTd,
  activeLayout,
  setActiveLayout,
  activeFeild,
  setActiveFeild,
  formState,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const updateStyleAll = (data, value, keyT) => {
    let jsonObj = data

    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        if (typeof jsonObj[key] === 'object' && jsonObj[key] !== null) {
          updateStyleAll(jsonObj[key], value, keyT)
        } else if (key === keyT) {
          if (keyT === 'fontSize' && typeof jsonObj[key] === 'number') {
            if (value === '+') {
              jsonObj[key] += 1
            } else if (value === '-') {
              jsonObj[key] -= 1
            }
          } else {
            jsonObj[key] = value
          }
        }
      }
    }

    return jsonObj
  }

  const changeDataStyle = (value, key) => {
    let listContentFeildConvert = updateStyleAll(listContentFeild, value, key)
    setActiveLayout({
      index: activeLayout.index,
      data: listContentFeildConvert?.[activeLayout.index],
    })
    setActiveTd({
      index: activeTd.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ],
    })
    setActiveFeild({
      index: activeFeild.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ]?.value?.[activeFeild.index],
    })
    setListContentFeild(listContentFeildConvert)
  }

  const changeViewConversionTemplate = (value) => {
    let listContentFeildConvert = updateJsonKey(
      listContentFeild,
      'transformationTemplate',
      value,
    )
    console.log('listContentFeildConvert', listContentFeildConvert)
    setActiveLayout({
      index: activeLayout.index,
      data: listContentFeildConvert?.[activeLayout.index],
    })
    setActiveTd({
      index: activeTd.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ],
    })
    setActiveFeild({
      index: activeFeild.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ]?.value?.[activeFeild.index],
    })
    setListContentFeild(listContentFeildConvert)
  }

  useMemo(() => {
    let listContentFeildConvert = updateInforCompanyValue(
      listContentFeild,
      formState?.organizationName,
      'inforCompany',
    )
    setActiveLayout({
      index: activeLayout.index,
      data: listContentFeildConvert?.[activeLayout.index],
    })
    setActiveTd({
      index: activeTd.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ],
    })
    setActiveFeild({
      index: activeFeild.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ]?.value?.[activeFeild.index],
    })
    setListContentFeild(listContentFeildConvert)
  }, [formState?.organizationName])

  useMemo(() => {
    let listContentFeildConvert = updateInforCompanyValue(
      listContentFeild,
      formState?.typeMoney == 'no'
        ? ''
        : formState?.typeMoney == 'vnd'
          ? 'VND'
          : formState?.typeMoney == 'd'
            ? 'đ'
            : '',
      'inforTypeMoney',
    )
    setActiveLayout({
      index: activeLayout.index,
      data: listContentFeildConvert?.[activeLayout.index],
    })
    setActiveTd({
      index: activeTd.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ],
    })
    setActiveFeild({
      index: activeFeild.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ]?.value?.[activeFeild.index],
    })
    setListContentFeild(listContentFeildConvert)
  }, [formState?.typeMoney])

  useMemo(() => {
    const year = dayjs(formState?.symbolYear).year()
    let listContentFeildConvert = updateInforCompanyValue(
      listContentFeild,
      `${formState?.symbolCode ? formState.symbolCode + '/' : ''}${year}/${formState?.symbolType ? formState.symbolType : ''}`,
      'inforSymbol',
    )
    setActiveLayout({
      index: activeLayout.index,
      data: listContentFeildConvert?.[activeLayout.index],
    })
    setActiveTd({
      index: activeTd.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ],
    })
    setActiveFeild({
      index: activeFeild.index,
      data: listContentFeildConvert?.[activeLayout.index]?.content?.[
        activeTd.index
      ]?.value?.[activeFeild.index],
    })
    setListContentFeild(listContentFeildConvert)
  }, [formState?.symbolType, formState?.symbolYear, formState?.symbolCode])

  return (
    <div>
      <FormCustom.Item
        label={t('taxDeductionDocuments.template')}
        name={'taxDuctionDocumentsTemplateId'}
      >
        <SelectCustom
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('taxDeduction.noData')}
            />
          }
        >
          {listDataTemplate &&
            listDataTemplate.map((item, index) => (
              <SelectCustom.Option value={item.templatePaperId} key={index}>
                {item.templatePaperDetail}
              </SelectCustom.Option>
            ))}
        </SelectCustom>
      </FormCustom.Item>

      <FormCustom.Item
        label={
          <>
            {`${t('taxDeductionDocuments.formNumber')} `}
            <RedStar>*</RedStar>
          </>
        }
        name={'formNumber'}
      >
        <InputCustom disabled />
      </FormCustom.Item>

      <DivTddCode display="flex">
        <FormCustom.Item
          label={
            <>
              {`${t('taxDeductionDocuments.code')} `}
              <RedStar>*</RedStar>
            </>
          }
          name={'symbolCode'}
          style={{ paddingRight: 10 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(
                      t('taxDeductionDocuments.requiredcode', { length: 2 }),
                    ),
                  )
                }
                if (value && value.trim()) {
                  if (value !== value.toUpperCase()) {
                    value = value.trim().toUpperCase()
                    form.setFieldsValue({ symbolCode: value })
                  }
                  if (value.length < 2) {
                    return Promise.reject(
                      new Error(
                        t('taxDeductionDocuments.requiredcode', { length: 2 }),
                      ),
                    )
                  }
                  const regex = /^[ABCDEGHKLMNPQRSTUVXY]+$/
                  if (!regex.test(value.trim())) {
                    return Promise.reject(
                      new Error(t('taxDeductionDocuments.fontCode')),
                    )
                  }
                }

                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputCustom maxLength={2} placeholder="AA" />
        </FormCustom.Item>
        <FormCustom.Item name={'symbolYear'} style={{ paddingRight: 10 }}>
          <DatePickerCustom
            picker="year"
            placeholder={t('taxDeductionDocuments.chonseYear')}
          />
        </FormCustom.Item>
        <FormCustom.Item name={'symbolType'}>
          <InputCustom disabled width={57} />
        </FormCustom.Item>
      </DivTddCode>

      <FormCustom.Item
        label={t('taxDeductionDocuments.number')}
        name={'number'}
      >
        <InputCustom disabled />
      </FormCustom.Item>

      <FormCustom.Item
        style={{ height: 180 }}
        label={t('taxDeductionDocuments.logo')}
        name="fileLink"
        rules={[{ required: false, message: 'Chọn file!' }]}
      >
        <DivCenterImg urlBackGroupImage={imgLogo?.url}>
          {imgLogo?.url ? <></> : <IconFileImageOutlined />}
        </DivCenterImg>
        <UploadCustom
          showUploadList={false}
          maxCount={1}
          name="fileLogo"
          accept=".png,.jpeg,.jpg,.svg"
          beforeUpload={(file) => {
            return false
          }}
          file={imgLogo?.file}
          // onPreview={this.handlePreview}
          onChange={(file) => {
            setImgLogo(file)
          }}
        >
          <DivUpdateFileLogo>
            <img src={AppResource.taxDeucationDocuments.keentddEdit} />
          </DivUpdateFileLogo>
        </UploadCustom>
        {imgLogo?.url && (
          <DivRemmoveFileLogo
            onClick={() => {
              setImgLogo(false)
            }}
          >
            <img src={AppResource.taxDeucationDocuments.keentddDelete} />
          </DivRemmoveFileLogo>
        )}
      </FormCustom.Item>
      <FormCustom.Item
        label={t('taxDeductionDocuments.typeMoney')}
        name={'typeMoney'}
      >
        <RadioCustom.Group>
          <RadioCustom value={'no'}>
            {t('taxDeductionDocuments.no')}
          </RadioCustom>
          <RadioCustom value={'vnd'}>
            {t('taxDeductionDocuments.vnd')}
          </RadioCustom>
          <RadioCustom value={'d'}>{t('taxDeductionDocuments.d')}</RadioCustom>
        </RadioCustom.Group>
      </FormCustom.Item>

      <FormCustom.Item
        label={t('taxDeductionDocuments.organizationName')}
        name={'organizationName'}
      >
        <InputCustom.TextArea rows={3} />
      </FormCustom.Item>

      <FormCustom.Item
        label={t('taxDeductionDocuments.positionLogo')}
        name={'positionLogo'}
      >
        <RadioCustom.Group>
          <DivFlex>
            <div>
              <RadioCustom value={'top'}>
                {t('taxDeductionDocuments.top')}
              </RadioCustom>
              <div>
                <img src={AppResource.taxDeucationDocuments.keentddTopLogo} />
              </div>
            </div>
            <div>
              <RadioCustom value={'under'}>
                {t('taxDeductionDocuments.under')}
              </RadioCustom>
              <div>
                <img
                  src={AppResource.taxDeucationDocuments.keentddBottomLogo}
                />
              </div>
            </div>
          </DivFlex>
        </RadioCustom.Group>
      </FormCustom.Item>
      <DivBorder className="p-3 mb-4 border">
        <div className="d-flex flex-row align-items-center mb-2">
          <TextlogoFont className="font-sfpro-bold mr-2">A</TextlogoFont>
          <TextFont className="mb-0">
            {t('taxDeductionDocuments.labeltextFont')}
          </TextFont>
        </div>
        <div className="d-flex flex-row row">
          <div className="col-sm-5" style={{ width: '160px' }}>
            <div>
              <LabelForm>{t('taxDeductionDocuments.textF')}</LabelForm>
            </div>
            <div>
              <FormCustom.Item name={'fontText'}>
                <SelectCustom
                  onChange={(value) => {
                    changeDataStyle(value, 'fontFamily')
                  }}
                  options={SelectOptions.fontText}
                ></SelectCustom>
              </FormCustom.Item>
            </div>
          </div>

          <div className="col-sm-4">
            <div>
              <LabelForm>{t('taxDeductionDocuments.fontS')}</LabelForm>
            </div>
            <FormCustom.Item name={'fontSize'} style={{ display: 'none' }}>
              <InputCustom type="hidden" disabled />
            </FormCustom.Item>
            <DivfontS>
              <ButtonCustom
                onClick={() => {
                  if (form.getFieldValue('fontSize') > 15) {
                    form.setFieldsValue({
                      fontSize: Number(form.getFieldValue('fontSize')) - 1,
                    })
                    changeDataStyle('-', 'fontSize')
                  }
                }}
              >
                A-
              </ButtonCustom>
              <ButtonCustom
                onClick={() => {
                  if (form.getFieldValue('fontSize') < 25) {
                    form.setFieldsValue({
                      fontSize: Number(form.getFieldValue('fontSize')) + 1,
                    })
                    changeDataStyle('+', 'fontSize')
                  }
                }}
              >
                A+
              </ButtonCustom>
            </DivfontS>
          </div>
          <div className="col-sm-3">
            <div>
              <LabelForm>{t('taxDeductionDocuments.changeColor')}</LabelForm>
            </div>
            <FormCustom.Item name={'textColor'}>
              <ColorPickerCustom
                style={{ width: 50 }}
                onChange={(value, hex) => {
                  changeDataStyle(hex, 'color')
                }}
              />
            </FormCustom.Item>
          </div>
        </div>
      </DivBorder>
      <DivFlex>
        <LabelForm>{t('taxDeductionDocuments.viewCd')}</LabelForm>
        <FormCustom.Item name="viewConversionTemplate" valuePropName="checked">
          <CheckboxCustom
            checked={form.getFieldValue('viewConversionTemplate')}
            onChange={(e) => {
              changeViewConversionTemplate(e.target.checked)
              form.setFieldsValue({ viewConversionTemplate: e.target.checked })
            }}
          />
        </FormCustom.Item>
      </DivFlex>
    </div>
  )
}
export default TabSetiingPublic
