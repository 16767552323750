import {
  REGISTRATION_STATUS,
  SUMMARY_INVOICE_STATUS,
} from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import { STATUS_RELEASE } from 'general/constants/AppConstants'

export const _taxMessageHelper = {
  renderStatus: (status) => {
    return {
      text: SelectOptions.registrationStatus.find(
        (item) => item.value === status,
      )?.label,
      className: [REGISTRATION_STATUS.DRAFT].includes(status)
        ? 'badge-light-secondary'
        : [REGISTRATION_STATUS.SENT_TAX].includes(status)
          ? 'badge-light-primary'
          : [
                REGISTRATION_STATUS.TAX_NOT_ACCEPT,
                REGISTRATION_STATUS.TAX_NOT_RECEIVE,
                REGISTRATION_STATUS.TAX_NOT_VALID,
              ].includes(status)
            ? 'badge-light-danger'
            : [
                  REGISTRATION_STATUS.TAX_RECEIVE,
                  REGISTRATION_STATUS.TAX_VALID,
                  REGISTRATION_STATUS.TAX_ACCEPT,
                ].includes(status)
              ? 'badge-light-success'
              : '',
    }
  },
  renderSummaryInvoiceStatusRelease: (status) => {
    return {
      text: SelectOptions.summaryInvoiceStatusRelease.find(
        (item) => item.value === status,
      )?.label,
      className: [SUMMARY_INVOICE_STATUS.NOT_SENT_TO_AUTHORITY].includes(status)
        ? 'badge-light-secondary'
        : [SUMMARY_INVOICE_STATUS.AUTHORITY_ACCEPT].includes(status)
          ? 'badge-light-primary'
          : [SUMMARY_INVOICE_STATUS.WAITING_FOR_AUTHORITY].includes(status)
            ? 'badge-light-warning'
            : [
                  SUMMARY_INVOICE_STATUS.INVOICE_INVALID,
                  SUMMARY_INVOICE_STATUS.SEND_ERROR,
                ].includes(status)
              ? 'badge-light-danger'
              : '',
    }
  },
}

export const taxMessageHelper = {
  renderStatus: (status) => {
    const statusClassMap = {
      [STATUS_RELEASE.SEND_ERROR]: 'badge-light-danger',
      [STATUS_RELEASE.INVOICE_INVALID]: 'badge-light-danger',
      [STATUS_RELEASE.TAX_VALID]: 'badge-light-primary',
      [STATUS_RELEASE.NOT_SENT_TO_AUTHORITY]: 'badge-light-primary',
      [STATUS_RELEASE.TAX_NOT_VALID]: 'badge-light-danger',
      [STATUS_RELEASE.AUTHORITY_ACCEPT]: 'badge-light-success',
      [STATUS_RELEASE.WAITING_FOR_AUTHORITY]: 'badge-light-warning',
    }

    return {
      text: SelectOptions.statusRelease.find((item) => item.value === status)
        ?.label,
      className: statusClassMap[status] || '',
    }
  },
}
