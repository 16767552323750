import { Space, Typography } from 'antd'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import {
  TICKET_MAIL_SENT_AUTHORITY_STATUS,
  TICKET_STATUS,
} from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useGetTicketSerial from 'hooks/Queries/useGetTicketSerial'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { find, isNil, omit, replace, concat } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ticketCheckPointQueries } from 'features/Category/screens/CheckTicketPortalScreen/queries'
import { useExportMutation, useGetAccount, useGetReportList } from './queries'

export default function ReportListTicketScreen() {
  const { t } = useTranslation()
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)
  const [filter, setFilter] = useFilter(
    'ticketReportList',
    'gFilterTicketReport',
  )

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')

  // ====================== Queries, mutations ======================

  const { data: listTicket, isFetching: gettingListTicket } = useGetReportList(
    debouncedSearch,
    filter,
  )

  const { data: accounts } = useGetAccount({
    page: 0,
    limit: 50,
  })

  const { data: serials } = useGetTicketSerial({
    includeAllOption: true,
    disabled: false,
  })

  const { data: listTicketPortal } = useQuery({
    ...ticketCheckPointQueries.lists(),
    select: (res) => {
      return concat(
        { serialDevice: null, namePointCheckTicket: 'Tất cả' },
        res?.namePointCheckTickets,
      ).map((namePointCheckTicket) => ({
        label: namePointCheckTicket?.namePointCheckTicket,
        value: namePointCheckTicket?.serialDevice,
      }))
    },
  })

  // ====================== Memos ======================
  const default_options = [
    {
      label: 'Ký hiệu',
      alias: 'Ký hiệu',
      formName: 'serials',
      options: serials,
      value: null,
      multiselect: true,
      colSpan: 24,
    },
    {
      label: 'Người xuất vé',
      alias: 'Người xuất vé',
      formName: 'accountIds',
      options: accounts,
      value: null,
      multiselect: true,
    },
    {
      label: 'Trạng thái cấp mã',
      alias: 'Trạng thái cấp mã',
      formName: 'typeRelease',
      options: SelectOptions.ticketGrantedStatus,
      value: SelectOptions.ticketGrantedStatus[0].value,
      multiselect: true,
    },
    {
      label: 'Trạng thái vé',
      alias: 'Trạng thái vé',
      formName: 'typeTicket',
      options: SelectOptions.ticketStatus,
      value: SelectOptions.ticketStatus[0].value,
      multiselect: true,
    },
    {
      label: 'Trạng thái gửi vé cho KH',
      alias: 'Trạng thái gửi vé cho KH',
      formName: 'typeSendCustomer',
      options: SelectOptions.ticketMailSentCustomerStatus,
      value: SelectOptions.ticketMailSentCustomerStatus[0].value,
      multiselect: true,
    },
    {
      label: 'Trạng thái gửi CQT',
      alias: 'Trạng thái gửi CQT',
      formName: 'typeSendCQTs',
      options: SelectOptions.ticketMailSentAuthorityStatus,
      value: SelectOptions.ticketMailSentAuthorityStatus[0].value,
      multiselect: true,
      // colSpan: 24,
    },
    {
      label: 'Cổng soát vé',
      alias: 'Cổng soát vé',
      formName: 'serialDevices',
      options: listTicketPortal,
      value: null,
      multiselect: true,
    },
  ]

  const default_columns = useMemo(() => {
    /**
     * An array of table columns.
     * @type {import('react-data-table-component').TableColumn<ReturnTicketData>[]}
     */
    const arr = [
      {
        name: 'STT',
        id: 'stt',
        center: true,
        width: '60px',
        selector: (row) => row?.stt,
      },
      {
        name: 'Số vé',
        id: 'no',
        center: true,
        selector: (row) => row.no,
      },
      {
        name: 'Ký hiệu',
        id: 'serial',
        center: true,
        selector: (row) => row.serial,
      },
      {
        name: 'Ngày xuất vé',
        id: 'dateRelease',
        center: true,
        format: (row) => dayjs(row.dateRelease).format('L'),
        selector: (row) => row.dateRelease,
      },
      {
        name: 'Mã của CQT',
        id: 'taxAuthorityCode',
        center: true,
        width: '250px',
        cell: (row) => (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100"
          >
            {row.taxAuthorityCode}
          </p>
        ),
      },
      {
        name: 'Thuế suất',
        id: 'taxRate',
        center: true,
        selector: (row) => row.taxRate,
      },
      {
        name: 'Tiền trước thuế',
        id: 'price',
        center: true,
        cell: (row) => (
          <p
            style={{
              textAlign: 'right',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100"
          >
            {Utils.formatNumber(row?.price, sysConfig.totalCost)}
          </p>
        ),
      },
      {
        name: 'Tiền thuế GTGT',
        id: 'priceVAT',
        center: true,
        cell: (row) => (
          <p
            style={{
              textAlign: 'right',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100"
          >
            {Utils.formatNumber(
              (row?.price * replace(row.taxRate, '%', '')) / 100,
              sysConfig.totalCost,
            )}
          </p>
        ),
      },
      {
        name: 'Tổng tiền',
        id: 'totalPrice',
        center: true,
        cell: (row) => (
          <p
            style={{
              textAlign: 'right',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100"
          >
            {Utils.formatNumber(row?.totalPrice, sysConfig.totalCost)}
          </p>
        ),
      },
      {
        name: 'Trạng thái gửi CQT',
        id: 'typeSendCQT',
        grow: 1,
        minWidth: '150px',
        center: true,
        // selector: (row) => row.typeSendCQT,
        cell: (row) => (
          <div
            className={`badge badge-light-${
              row.typeSendCQT ===
              TICKET_MAIL_SENT_AUTHORITY_STATUS.NOT_SENT_TO_AUTHORITY
                ? 'secondary'
                : row.typeSendCQT === TICKET_MAIL_SENT_AUTHORITY_STATUS.SENT
                  ? 'info'
                  : row.typeSendCQT ===
                      TICKET_MAIL_SENT_AUTHORITY_STATUS.MESSAGE_NOT_VALID
                    ? 'warning'
                    : row.typeSendCQT ===
                        TICKET_MAIL_SENT_AUTHORITY_STATUS.TICKET_VALID
                      ? 'success'
                      : row.typeSendCQT ===
                          TICKET_MAIL_SENT_AUTHORITY_STATUS.TICKET_NOT_VALID
                        ? 'danger'
                        : row.typeSendCQT ===
                            TICKET_MAIL_SENT_AUTHORITY_STATUS.EMPTY
                          ? 'secondary'
                          : 'primary'
            }`}
          >
            {
              find(SelectOptions.ticketMailSentAuthorityStatus, {
                value: row.typeSendCQT,
              })?.label
            }
          </div>
        ),
      },
      {
        name: 'Trạng thái vé',
        id: 'typeTicket',
        center: true,
        width: '150px',
        selector: (row) => row.typeTicket,
        cell: (row) => (
          <div className="w-100 justify-content-start">
            <div
              className={`badge badge-light-${
                row.typeTicket === TICKET_STATUS.ADJUST
                  ? 'success'
                  : row.typeTicket === TICKET_STATUS.ADJUSTED
                    ? 'info'
                    : row.typeTicket === TICKET_STATUS.CANCELLED
                      ? 'danger'
                      : row.typeTicket === TICKET_STATUS.NEW
                        ? 'primary'
                        : row.typeTicket === TICKET_STATUS.REPLACE
                          ? 'info'
                          : row.typeTicket === TICKET_STATUS.REPLACED
                            ? 'warning'
                            : 'primary'
              }`}
            >
              {
                find(SelectOptions.ticketStatus, {
                  value: row.typeTicket,
                })?.label
              }
            </div>
          </div>
        ),
      },
      {
        name: 'Người xuất vé',
        id: 'accountId',
        center: true,
        grow: 2,
        minWidth: '200px',
        cell: (row) => (
          <Typography.Text className="w-100 text-left">
            {row.accountId &&
              find(accounts, (acc) => acc.value === row.accountId)?.label}
          </Typography.Text>
        ),
      },
      {
        name: 'Mã thiết bị',
        id: 'serialDevice',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.serialDevice}
            </p>
          )
        },
      },
      {
        name: 'Mã tra cứu',
        id: 'lookupCode',
        center: true,
        width: '150px',
        selector: (row) => row.lookupCode,
      },
    ]
    return arr
  }, [accounts, sysConfig.totalCost])

  const columns = useAppDnD(default_columns)

  const { mutate: handleExportExcel } = useExportMutation(columns)

  // ====================== Functions ======================
  useEffect(() => {
    setFilter({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilter])

  return (
    <ContentContainer wrapperClassName="report-list-ticket">
      <ContentContainer.Header
        titleContent={'Bảng kê vé đã sử dụng'}
        description={
          'Tổng tiền: ' +
          Utils.formatCurrency(
            isNil(listTicket?.sumMoney) ? 0 : listTicket?.sumMoney,
            ' VND',
          )
        }
        toolBar={
          <Space>
            <CustomAntButton
              text={t('ExportExcel')}
              iconCn="fa-solid fa-download"
              antProps={{
                type: 'default',
                className: 'btn btn-success',
                onClick: () =>
                  handleExportExcel(omit(filter, ['page', 'limit'])),
              }}
            />
            <AppDnD
              defaultColumns={default_columns}
              localStorageName={PreferenceKeys.listReportTicket}
            />
          </Space>
        }
      >
        <Space>
          <CustomAntInput
            type="search"
            width={150}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
            borderRadius="sm"
          />

          <FilterPopover
            menuWidth={'50rem'}
            menuPosition="bottomLeft"
            onApply={setFilter}
            defaultValues={default_options}
            savedKey="gFilterTicketReport"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          customTableStyle={customDataTableStyle}
          columns={columns}
          dataSource={listTicket?.rows}
          isLoading={gettingListTicket}
          pagination={{
            currentPage: filter.page,
            rowsPerPage: filter.limit,
            total: listTicket?.total,
            onChangePage: (page) => setFilter({ page }),
            onChangeRowsPerPage: (limit) => setFilter({ limit, page: 1 }),
          }}
        />
      </ContentContainer.Body>
    </ContentContainer>
  )
}
