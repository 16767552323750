import { Col, Row } from 'antd'
import { useFormik } from 'formik'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ValidationSchema from 'general/utils/ValidationSchema'
import { useAppDispatch } from 'hooks/useRedux'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutationToggleMark } from '../../queries'
import { transactionActions, useTransactionSelector } from '../../slice'

export default function MarkInvoiceModal() {
  const { t } = useTranslation()
  const { modalState, selectedRow } = useTransactionSelector()
  const dispatch = useAppDispatch()
  const modalRef = useRef(null)
  const { mutate: handleToggleMark } = useMutationToggleMark()

  const handleToggleModal = (status) => {
    dispatch(
      transactionActions.toggleModalState({
        name: 'markInvoice',
        status: status,
      }),
    )
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: ValidationSchema.transactionMarkedSchema,
    onSubmit: (values) => {
      handleToggleMark({
        stationPetroId: selectedRow?.dataLogPetroId,
        body: {
          isMarked: !selectedRow.isMarked,
          note: values.note,
        },
      })
      handleToggleModal(false)
    },
  })

  return (
    <DraggableModal
      ref={modalRef}
      isOpen={modalState.markInvoice}
      onOpenChange={handleToggleModal}
      applyText={t('listTransaction.mark')}
      title="Khoá hoá đơn"
      description="Các hóa đơn bị khoá sẽ không được xuất tự động, nhưng vẫn có thể xuất thủ công!"
      width={600}
      handleApplyChanges={() => formik.submitForm()}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <Row className="w-100">
          <Col span={4}>
            <CustomLabel
              width={'100%'}
              htmlFor="note"
              isRequired
              text="Ghi chú"
            />
          </Col>
          <Col span={20}>
            <CustomAntInput
              type="textarea"
              borderRadius="sm"
              inputProps={{
                style: {
                  padding: 0,
                },
                autoSize: {
                  maxRows: 3,
                  minRows: 3,
                },
                maxLength: 255,
                showCount: true,
                ...formik.getFieldProps('note'),
                status:
                  formik.errors.note && formik.touched.note
                    ? 'error'
                    : undefined,
              }}
            />
            <span className="fs-6 text-danger">
              {formik.errors.note && formik.touched.note
                ? formik.errors.note
                : ''}
            </span>
          </Col>
        </Row>
      </form>
    </DraggableModal>
  )
}
