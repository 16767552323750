import { useMutation } from '@tanstack/react-query'
import { setAppSpinning } from 'app/appSlice'
import { useAppDispatch } from 'hooks/useRedux'
import { useEffect, useState } from 'react'

export default function useExportById(mutateApi, fileName, fileType) {
  const dispatch = useAppDispatch()

  const [file, setFile] = useState(null)
  const mutation = useMutation({
    mutationKey: 'exportById',
    mutationFn: mutateApi,
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      const newFile = new File([res], fileName, {
        type: fileType,
      })
      setFile(newFile)
    },
  })

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file)
      open(url, '_blank')
    }
    return () => {
      URL.revokeObjectURL(file)
      setFile(null)
    }
  }, [file])
  return {
    file,
    exportFunc: mutation.mutate,
    exportFuncAsync: mutation.mutateAsync,
  }
}
