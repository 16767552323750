const { default: axiosClient } = require('./axiosClient')
const accountingDocumentApi = {
  update: (body) => {
    const url = '/accounting-documents/update'
    return axiosClient.post(url, { body })
  },
  find: (body) => {
    const url = '/accounting-documents/find'
    return axiosClient.post(url, { body })
  },
  signHSM: (params) => {
    const url = '/accounting-documents/signHSM'
    return axiosClient.get(url, { params })
  },
  remove: (params) => {
    const url = '/accounting-documents/remove'
    return axiosClient.delete(url, { params })
  },
  findById: (params) => {
    const url = '/accounting-documents/findById'
    return axiosClient.get(url, { params })
  },
  exportFile: (params) => {
    const url = '/accounting-documents/exportFile'
    return axiosClient.get(url, { params })
  },
  cancel: (body) => {
    const url = '/accounting-documents/cancel'
    return axiosClient.post(url, { body })
  },
  sendEmail: (body) => {
    const url = '/accounting-documents/sendEmail'
    return axiosClient.post(url, { body })
  },
}

export default accountingDocumentApi
