import { Navigate, Route, Routes } from 'react-router-dom'

// import ListRegistration from './ListRegistration';
import ListCancelledInvoice from 'features/HandleInvoice/screens/ListCancelledInvoice'
import ListReplacedInvoice from 'features/HandleInvoice/screens/ListReplacedInvoice'
import CreateInvoice from 'features/Invoice/screens/CreateInvoice'
import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ErrorAnnouncement from './screens/ErrorAnnouncement'
import ListAdjustedInvoice from './screens/ListAdjustedInvoice'
import ListErrorAnnoucement from './screens/ListErrorAnnoucement'
import useCheckPermission from 'hooks/useCheckPermission'

function HandleInvoice() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const {
    isViewListInvoice,
    replaceInvoice,
    isAnnounceIssueView,
    isAnnounceIssueEdit,
    isLoadingPermission,
  } = useCheckPermission([
    {
      keyAction: 'isViewListInvoice',
      permissionCode: 'INVOICE_VIEW_LIST',
    },
    {
      keyAction: 'replaceInvoice',
      permissionCode: 'INVOICE_CREATE_REPLACEMENT_INVOICE',
    },
    {
      keyAction: 'isAnnounceIssueView',
      permissionCode: 'ANNOUNCE_ISSUE_VIEW_LIST',
    },
    {
      keyAction: 'isAnnounceIssueEdit',
      permissionCode: 'ANNOUNCE_ISSUE_EDIT',
    },
  ])
  // const listFunction = useAppSelector((state) => state?.function?.listFunctions)
  // const userFunction = useAppSelector((state) => state?.function?.userFunctions)
  // const [isViewListInvoice, isAnnounceIssueView, isAnnounceIssueEdit] =
  //   useMemo(() => {
  //     return Utils.checkFunction(
  //       [
  //         {
  //           listFunctionCode: 'INVOICE_VIEW_LIST',
  //         },
  //         { listFunctionCode: 'ANNOUNCE_ISSUE_VIEW_LIST' },
  //         { listFunctionCode: 'ANNOUNCE_ISSUE_EDIT' },
  //       ],
  //       userFunction,
  //       listFunction,
  //     )
  //   }, [userFunction, listFunction])
  return (
    <Routes>
      <Route path="" element={<Navigate to="them-moi" />} />
      <Route
        path={'thay-the/:invoiceId'}
        element={
          <ProtectedRoute
            permission={replaceInvoice}
            isLoading={isLoadingPermission}
          >
            <CreateInvoice key={4} />
          </ProtectedRoute>
        }
      />
      <Route
        path={'may-tinh-tien/thay-the/:invoiceId'}
        element={
          <ProtectedRoute
            permission={replaceInvoice}
            isLoading={isLoadingPermission}
          >
            <CreateInvoice key={5} />
          </ProtectedRoute>
        }
      />
      <Route
        path={'thay-the-khac-he-thong/:invoiceInfo'}
        element={<CreateInvoice key={6} />}
      />
      <Route
        path={'may-tinh-tien/thay-the-khac-he-thong/:invoiceInfo'}
        element={<CreateInvoice key={7} />}
      />
      <Route
        path={'dieu-chinh/:invoiceId'}
        element={<CreateInvoice key={8} />}
      />
      <Route
        path={'may-tinh-tien/dieu-chinh/:invoiceId'}
        element={<CreateInvoice key={9} />}
      />
      <Route
        path={'dieu-chinh-khac-he-thong/:invoiceInfo'}
        element={<CreateInvoice key={10} />}
      />
      <Route
        path={'may-tinh-tien/dieu-chinh-khac-he-thong/:invoiceInfo'}
        element={<CreateInvoice key={11} />}
      />
      <Route
        path={'danh-sach-hoa-don-thay-the'}
        element={
          <ProtectedRoute permission={isViewListInvoice}>
            <ListReplacedInvoice />
          </ProtectedRoute>
        }
      />
      <Route
        path={'danh-sach-hoa-don-dieu-chinh'}
        element={
          <ProtectedRoute permission={isViewListInvoice}>
            <ListAdjustedInvoice />
          </ProtectedRoute>
        }
      />
      <Route
        path={'danh-sach-hoa-don-da-huy'}
        element={
          <ProtectedRoute permission={isViewListInvoice}>
            <ListCancelledInvoice />
          </ProtectedRoute>
        }
      />
      <Route
        path={'danh-sach-thong-bao-sai-sot'}
        element={
          <ProtectedRoute permission={isViewListInvoice}>
            <ListErrorAnnoucement currentTab={0} />
          </ProtectedRoute>
        }
      />
      <Route
        path={'danh-sach-thong-bao-sai-sot/hoa-don'}
        element={
          <ProtectedRoute permission={isViewListInvoice}>
            <ListErrorAnnoucement currentTab={1} />
          </ProtectedRoute>
        }
      />
      <Route
        path={'danh-sach-thong-bao-sai-sot/chua-lap-thong-bao-sai-sot'}
        element={
          <ProtectedRoute permission={isViewListInvoice}>
            <ListErrorAnnoucement currentTab={2} />
          </ProtectedRoute>
        }
      />
      <Route
        path={'thong-bao-sai-sot/:invoiceIds'}
        element={
          <ProtectedRoute permission={isAnnounceIssueView}>
            <ErrorAnnouncement />
          </ProtectedRoute>
        }
      />
      <Route
        path={'thong-bao-sai-sot-khac-he-thong/:invoiceInfo'}
        element={<ErrorAnnouncement />}
      />
      <Route
        path={'chinh-sua-thong-bao-sai-sot/:errAnnouceId'}
        element={
          <ProtectedRoute permission={isAnnounceIssueEdit}>
            <ErrorAnnouncement />
          </ProtectedRoute>
        }
      />
      // MARK: --- Phiếu xuất kho ---
      <Route
        path={'phieu-xuat-kho/thay-the/:invoiceId'}
        element={
          <ProtectedRoute permission={isAnnounceIssueEdit}>
            <CreateInvoice key={8} />
          </ProtectedRoute>
        }
      />
      <Route
        path={'phieu-xuat-kho/dieu-chinh/:invoiceId'}
        element={<CreateInvoice key={9} />}
      />
      // MARK: --- Phiếu xuất kho ---
      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default HandleInvoice
