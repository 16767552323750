import { useMutation } from '@tanstack/react-query'
import { Col, Image, Row } from 'antd'
import accountApi from 'api/accountApi'
import { setAppSpinning } from 'app/appSlice'
import { setActiveInvoice } from 'app/authSlice'
import EContracts from 'assets/images/Einvoice/e-contracts.svg'
import IncomingInvoiceProcess from 'assets/images/Einvoice/incoming-invoice-process.svg'
import InvoiceIcon from 'assets/images/Einvoice/invoice.svg'
import PersonalCollectionDoc from 'assets/images/Einvoice/personal-collection-documents.svg'
import ReceiptIcon from 'assets/images/Einvoice/receipt.svg'
import TicketIcon from 'assets/images/Einvoice/ticket.svg'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import { shallowEqual } from 'react-redux'
import './styles.scss'

export default function DropdownHeaderMenuInfo() {
  return (
    <div className="p-6" style={{ width: '450px' }}>
      <h2 className="font-weight-bold m-0 mb-6">Sản phẩm, dịch vụ</h2>
      <MenuItem />
    </div>
  )
}

const MenuItem = () => {
  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)
  const { navigate } = useRouter()
  const dispatch = useAppDispatch()
  const { mutate } = useMutation({
    mutationKey: ['changeTab', 'activeInvoice'],
    mutationFn: (param) => accountApi.changeActiveInvoice(param),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      dispatch(setActiveInvoice(res.activeInvoice))
      const prefixPath = '/hoa-don-dau-ra'
      const toPath = res.activeInvoice.ticket
        ? prefixPath + '/tem-ve/ve-dien-tu/ve-da-xuat'
        : res.activeInvoice.invoice
          ? prefixPath + '/hoa-don/danh-sach-hoa-don'
          : res.activeInvoice.personalIncomeTax
            ? prefixPath + '/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan'
            : prefixPath + ''
      navigate(toPath, {
        replace: true,
      })
    },
  })
  const items = [
    {
      id: 'invoice',
      img: InvoiceIcon,
      text: 'Hoá đơn, PXK điện tử',
      isActive: systemType.invoice,
      onClick: () => mutate({ invoice: true }),
    },
    {
      id: 'ticket',
      img: TicketIcon,
      text: 'Vé điện tử',
      isActive: systemType.ticket,
      onClick: () => mutate({ ticket: true }),
    },
    {
      id: 'receipt',
      img: ReceiptIcon,
      text: 'Biên lai thu phí, lệ phí',
      isActive: systemType.receipt,
      onClick: () => mutate({ receipt: true }),
    },
    {
      id: 'incoming-invoice-process',
      img: IncomingInvoiceProcess,
      text: 'Quản lý hoá đơn đầu vào',
      isActive: false,
      onClick: () => {
        const accessToken = localStorage.getItem(PreferenceKeys.qlbhAccessToken)
        open(
          `https://ibot.vietinvoice.vn/auth/sign-in/?accessToken="${accessToken}"`,
          '_top',
        )
      },
    },
    {
      id: 'personalIncomeTax',
      img: PersonalCollectionDoc,
      text: 'Chứng từ thuế TNCN điện tử',
      isActive: systemType.personalIncomeTax,
      onClick: () => mutate({ personalIncomeTax: true }),
    },
    {
      id: 'e-contract',
      img: EContracts,
      text: 'Hợp đồng điện tử',
      isActive: false,
      onClick: () => open('https://hopdongdientu.vn/'),
    },
  ]
  return (
    <Row gutter={[16, 16]} align="center" justify="space-between">
      {items.map((item) => (
        <Col key={item.id} span={8}>
          <ButtonItem
            text={item.text}
            img={item.img}
            isActive={item.isActive}
            onClick={item?.onClick}
          />
        </Col>
      ))}
    </Row>
  )
}

const ButtonItem = ({ img, isActive, text, onClick }) => {
  return (
    <button
      className={Utils.cn(
        'btn btn-block text-center p-4 h-100',
        isActive ? 'btn-is-active' : 'btn-not-active',
      )}
      onClick={onClick}
    >
      <Image
        preview={false}
        src={img}
        alt={'ic-' + text}
        placeholder={false}
        // className="w-50"
      />
      <span className="d-block font-weight-bold font-size-lg mt-2 text-wrap">
        {text}
      </span>
    </button>
  )
}
