import React from 'react'
import './style.scss'

function NotFoundError({ text }) {
  return (
    <div className="nfe-container mt-5">
      <div className="nfe-wrapper">
        <i
          className="fa-sharp fa-light fa-circle-xmark mr-2"
          style={{ color: '#ff5757' }}
        ></i>
        <span className="nfe-text">{text}</span>
      </div>
    </div>
  )
}

export default NotFoundError
