import { Typography } from 'antd'

export default function Text({ content, textAlign }) {
  return (
    <Typography.Text
      style={{
        textAlign: textAlign,
      }}
      ellipsis={{
        tooltip: {
          title: content,
          destroyTooltipOnHide: true,
        },
      }}
      className="w-100"
    >
      {content}
    </Typography.Text>
  )
}
