import { useMutation, useQuery } from '@tanstack/react-query'
import reportApi from 'api/reportApi'
import dayjs from 'dayjs'
import reportHelper from 'features/Report/reportHelper'
import {
  calcMaxCellWidth,
  generateCellObject,
  generateCellStyle,
} from 'general/helpers/ExcelsHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { get, isNil, isNumber, map, merge, repeat } from 'lodash'
import { utils, writeFile } from 'xlsx-js-style'

import $ from 'jquery'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import { saveAs } from 'file-saver'

const keyFactory = {
  base: { scope: ['report', 'detailInvoiceReport'] },
  lists: (q, f) => [
    {
      ...keyFactory.base,
      params: {
        ...f,
        q,
      },
    },
  ],
  exportExcel: () => [
    {
      ...keyFactory.base,
      action: 'exportExcel',
    },
  ],
}

export function useExportMutation() {
  return useMutation({
    mutationKey: keyFactory.exportExcel(),
    mutationFn: (params) => reportApi.exportData(params),
    onSuccess: (res) => {
      saveAs(res, 'Bảng kê ct hóa đơn đã sd .xlsx')
    },
  })
}

export function useGetDetailInvoiceReport(debouncedSearch, filterParams) {
  return useQuery({
    enabled: !isNil(filterParams),
    queryKey: keyFactory.lists(debouncedSearch, filterParams),
    queryFn: ({ signal }) =>
      reportApi.getDetailInvoices(
        {
          ...filterParams,
          q: debouncedSearch,
        },
        signal,
      ),
    select: (res) => {
      return {
        rows: get(res, 'data.rows', []),
        total: get(res, 'data.rowsCount', 0),
      }
    },
  })
}
