const { default: axiosClient } = require('./axiosClient')
const taxDeductionDocumentsApi = {
  find: (params) => {
    const url = '/templatePaper/find'
    return axiosClient.get(url, { params })
  },
  getAllDefault: () => {
    const url = '/templatePaper/getAllDefault'
    return axiosClient.get(url, {})
  },
  save: (formData) => {
    const url = '/templatePaper'
    return axiosClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  remove: (params) => {
    const url = '/templatePaper'
    return axiosClient.delete(url, { params })
  },
  findById: (params) => {
    const url = '/templatePaper/findbyId'
    return axiosClient.get(url, { params })
  },
  findByTempapeRrelease: (params) => {
    const url = '/templatePaper/findByTempapeRrelease'
    return axiosClient.get(url, { params })
  },
  getIp: (params) => {
    const url = '/templatePaper/ip'
    return axiosClient.get(url, { params })
  },
  updateDateSign: (params) => {
    const url = '/templatePaper/updateDateSign'
    return axiosClient.get(url, { params })
  },
  exportFile: (params) => {
    const url = '/templatePaper/exportFile' // Added semicolon to end the statement correctly
    return axiosClient.get(url, {
      params,
      // responseType: 'blob'
    })
  },
  cancel: (params) => {
    const url = '/templatePaper/cancel'
    return axiosClient.get(url, { params })
  },
}

export default taxDeductionDocumentsApi
