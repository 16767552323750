import Utils from 'general/utils/Utils'
import React, { useState } from 'react'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
// import { addAmount, deleItemCart, deleteAmount } from "features/Cart/cartSlice";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import AppDeleteModal from 'general/components/AppDeleteModal'
// import PaymentHelper from 'general/helpers/PaymentHelper';

function CarItem(props) {
  const dispatch = useDispatch()
  const { itemCart, updateNumberUp, updateNumberDown, remove } = props
  const { amount = 1 } = itemCart

  return (
    <tr className="CarItem ">
      <td>
        <div
          className=""
          style={{ maxWidth: '300px', wordBreak: 'break-word' }}
        >
          <div
            className=" d-flex flex-row align-items-center flex-wrap"
            align="center"
          >
            <p className="m-0 fw-bold pr-3">{itemCart?.productName}</p>
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              className="bg-danger"
              overlay={
                <Tooltip id="tooltip-bottom" className="p-0">
                  <div className="text-left text-tooltip  ">
                    <p className="m-0 ">
                      Loại dịch vụ: {itemCart?.productType}
                      <span className="fw-bold">
                        {itemCart?.productType === 'quantity'
                          ? 'Gói số lượng'
                          : itemCart?.productType === 'license'
                            ? 'Gói thời hạn bản quyền'
                            : ''}
                      </span>
                    </p>
                    <p className="m-0 ">
                      Ngày tạo:{' '}
                      <span className="fw-bold">
                        {Utils.formatDateTime(itemCart?.createdAt)}
                      </span>
                    </p>
                    <p className="m-0 "></p>
                  </div>
                </Tooltip>
              }
            >
              <di className="d-flex cursor-pointer  align-items-center">
                <i className="fas fa-info-circle  pr-2 text-primary "></i>
                <p className="m-0 text-primary">Thông tin tài nguyên</p>
              </di>
            </OverlayTrigger>
          </div>
          <p className="mb-0 pt-2" style={{ minWidth: '70px' }}>
            {itemCart?.description ?? 'Gói dịch vụ nhiều ưu đãi hấp dẫn'}
          </p>
        </div>
      </td>
      <td className="align-bottom">
        <p className="mb-0" style={{ minWidth: '70px' }}>
          Gói
        </p>
      </td>
      <td className="text-right align-bottom ">
        <ItemAmount
          amount={itemCart?.productNumber ?? 1}
          cartId={itemCart?.cartId}
          updateNumberUp={updateNumberUp}
          updateNumberDown={updateNumberDown}
          remove={remove}
        />
      </td>
      <td className="align-bottom">
        <p className="price mb-0  text-right fw-bold">
          {Utils.formatCurrency(itemCart?.productPrice ?? 0)} <ins> đ</ins>
        </p>
      </td>
      <td className="align-bottom">
        <div>
          <DeleteItemButton cartId={itemCart?.cartId} remove={remove} />
          <p
            className="Content_13B mb-0 text-right fw-bold"
            style={{ color: '#663DAA' }}
          >
            {Utils.formatCurrency(
              itemCart?.productPrice * itemCart?.productNumber,
            )}

            <ins> đ</ins>
          </p>
        </div>
      </td>
    </tr>
  )
}

export default CarItem
function ItemAmount({
  amount,
  cartId,
  updateNumberUp,
  updateNumberDown,
  remove,
}) {
  const dispatch = useDispatch()
  const [showModalDelete, setShowModalDelete] = useState(false)

  return (
    <div className="ItemAmount d-flex flex-row justify-content-end align-items-end bg-white">
      <div
        className="ItemAmount-button cursor-pointer border"
        align="center"
        onClick={() => {
          if (amount === 1) {
            setShowModalDelete(true)
          } else {
            updateNumberDown(cartId)
          }
        }}
      >
        <i className="fas fa-minus" />
      </div>
      <div className="ItemAmount-amount border-top border-bottom text-center d-flex align-items-center justify-content-center">
        <p className="Content_16 p-1 m-0">{amount}</p>
      </div>
      <div
        className="ItemAmount-button cursor-pointer border"
        align="center"
        onClick={() => updateNumberUp(cartId)}
      >
        <i className="fas fa-plus" />
      </div>
      <AppDeleteModal
        show={showModalDelete}
        onClose={() => {
          setShowModalDelete(false)
        }}
        deleteItem={() => {
          remove(cartId)
        }}
        deleteTitle="Xóa sản phẩm"
        deleteText="Xóa sản phẩm ra khỏi giỏ hàng"
      />
    </div>
  )
}

function DeleteItemButton({ cartId, remove }) {
  const dispatch = useDispatch()
  const [showModalDelete, setShowModalDelete] = useState(false)

  return (
    <div className="d-flex justify-content-end pb-2 DeleteItemButton">
      {/* <i className="far fa-edit pr-4 text-primary"></i> */}
      <i
        className="fas fa-trash text-danger"
        onClick={() => setShowModalDelete(true)}
      ></i>
      <AppDeleteModal
        show={showModalDelete}
        onClose={() => {
          setShowModalDelete(false)
        }}
        deleteItem={() => {
          remove(cartId)
        }}
        deleteTitle="Xóa sản phẩm"
        deleteText="Xóa sản phẩm ra khỏi giỏ hàng"
      />
    </div>
  )
}
