import { useQuery, useQueryClient } from '@tanstack/react-query'
import customerApi from 'api/customerApi'
import _ from 'lodash'

const keyFactory = {
  base: {
    scope: ['invoice', 'create'],
  },

  list: () => ({
    ...keyFactory.base,
    entity: ['taxCode', 'customer'],
  }),

  lists: (q, p) => [
    {
      ...keyFactory.list(),
      params: {
        ...p,
        q,
      },
    },
  ],
}

export const useQueryBuyerByTaxCode = (q, params) => {
  const query = useQuery({
    staleTime: 1000 * 60,
    queryKey: keyFactory.lists(q, params),
    queryFn: ({ signal }) =>
      customerApi.findFromCreateInvoice(
        {
          ...params,
          q,
        },
        signal,
      ),
  })

  return {
    dataSource: _.get(query, 'data.rows', []),
    total: _.get(query, 'data.count', 0),
    isLoading: query.isLoading,
    isRefetching: query.isRefetching,
  }
}

export const useRevalidateBuyerByTaxCode = () => {
  const queryClient = useQueryClient()
  return {
    invalidate: () => queryClient.invalidateQueries([keyFactory.list()]),
  }
}
