import { unwrapResult } from '@reduxjs/toolkit'
import { Space } from 'antd'
import companyUnitApi from 'api/companyUnitApi'
import { thunkGetCompanyUnitList } from 'features/Category/categorySlice'
import ModalEditCompanyUnit from 'features/Category/components/ModalEditCompanyUnit'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const sTag = '[CompanyUnitScreen]'

function CompanyUnitScreen() {
  // MARK --- Parmas: ---
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { companyUnits, isGettingCompanyUnit } = useSelector(
    (state) => state?.category,
  )
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'companyUnitList',
    'gFiltersCompanyUnitList',
  )
  const [selectedCompanyUnits, setSelectedCompanyUnits] = useState([])
  const [toggleClearCompanyUnits, setToggledClearCompanyUnits] = useState(false)
  const [selectedCompanyUnitItem, setSelectedCompanyUnitItem] = useState(null)
  const [showingModalEditCompanyUnit, setShowingModalEditCompanyUnit] =
    useState(false)
  const refLoading = useRef(false)
  const [active, setActive] = useState('ADD')
  const [arrBreadcrumb, setArrBreadcrumb] = useState([])
  const [rootDataItems, setRootDataItems] = useState(companyUnits)
  const [dataItems, setDataItems] = useState(rootDataItems)
  const [parentCompanyUnit, setParentCompanyUnit] = useState(null)
  const [dataFilterNoPagination, setDataFilterNoPagination] = useState([])

  const handleEditCompanyUnit = useCallback((companyUnit) => {
    setActive('EDIT')
    setSelectedCompanyUnitItem(companyUnit)
    setShowingModalEditCompanyUnit(true)
  }, [])

  const getListCompanyUnit = useCallback(
    async (params) => {
      refLoading.current = true
      try {
        unwrapResult(dispatch(thunkGetCompanyUnitList(params)))
      } catch (error) {
        console.error(`${sTag} get company unit list error: ${error.message}`)
      }
      refLoading.current = false
    },
    [dispatch],
  )
  const handleDeleteCompanyUnit = useCallback(
    (companyUnit) => {
      Swal.fire({
        title: t('Xác nhận'),
        text: `Bạn có muốn xóa đơn vị ${companyUnit.name}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Đồng ý'),
        cancelButtonText: t('Hủy'),
        customClass: {
          confirmButton: 'btn btn-danger font-weight-bolder',
          cancelButton: 'btn btn-light font-weight-bolder',
        },
      }).then(async function (swalResult) {
        if (swalResult.value) {
          try {
            const res = await companyUnitApi.delete([companyUnit.companyUnitId])
            const { result } = res
            if (result === 'success') {
              getListCompanyUnit({ ...filters, q: debouncedSearch })
              ToastHelper.showSuccess(
                `Xóa đơn vị ${companyUnit.name} thành công`,
              )
            }
          } catch (error) {
            console.log(`Delete companyUnit error: ${error?.message}`)
          }
        }
      })
    },
    [debouncedSearch, filters, getListCompanyUnit, t],
  )

  const fullColumns = useMemo(
    () => [
      {
        name: 'Mã đơn vị',
        center: true,
        // width: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {/* {Utils.formatDateTime(row?.createTime, 'DD/MM/YYYY')} */}
              {row?.code}
            </p>
          )
        },
      },
      {
        name: 'Tên đơn vị',
        center: true,
        // minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.name}
            </p>
          )
        },
      },
      {
        name: 'Đơn vị cha',
        center: true,
        // minWidth: '220px',
        style: {
          borderRight: 'none',
        },
        cell: () => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {parentCompanyUnit?.name}
            </p>
          )
        },
      },
      {
        name: '',
        width: '150px',
        center: true,
        cell: (row) => (
          <Space align="center">
            <TableAction
              titleActionText={'Chỉnh sửa đơn vị'}
              icon={<IconAction className="fa-light fa-pen-to-square" />}
              onClick={() => handleEditCompanyUnit(row)}
            />
            <TableAction
              titleActionText={'Xóa đơn vị'}
              icon={<IconAction className="fa-light fa-trash" />}
              onClick={() => handleDeleteCompanyUnit(row)}
            />
          </Space>
        ),
      },
    ],
    [handleDeleteCompanyUnit, handleEditCompanyUnit, parentCompanyUnit?.name],
  )

  // MARK --- Function ---
  function handleDeleteMultiCompanyUnit() {
    const arrIdsToDelete = selectedCompanyUnits.map(
      (item) => item.companyUnitId,
    )

    Swal.fire({
      title: t('Xác nhận'),
      text: `Bạn có muốn xóa ${arrIdsToDelete.length} đơn vị ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Đồng ý'),
      cancelButtonText: t('Hủy'),
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder',
        cancelButton: 'btn btn-light font-weight-bolder',
      },
    }).then(async function (result) {
      if (result.value) {
        const companyUnitIds = arrIdsToDelete
        try {
          const res = await companyUnitApi.delete(companyUnitIds)
          if (res.result === 'success') {
            clearSelectedCompanyUnits()
            getListCompanyUnit({ ...filters, q: debouncedSearch })
            ToastHelper.showSuccess(t('Xóa đơn vị thành công'))
          }
        } catch (error) {
          console.log(`Delete companyUnit error: ${error?.message}`)
        }
      }
    })
  }

  function handleSelectedCompanyUnitChanged(state) {
    setSelectedCompanyUnits(state.selectedRows)
  }

  function clearSelectedCompanyUnits() {
    setSelectedCompanyUnits([])
    setToggledClearCompanyUnits(!toggleClearCompanyUnits)
  }

  // const handleReplicateCompanyUnit = useCallback((companyUnit) => {
  //   setActive('REPLICATE')
  //   setSelectedCompanyUnitItem(companyUnit)
  //   setShowingModalEditCompanyUnit(true)
  // }, [])

  function handleClickedCompanyCompanyUnit(companyUnit) {
    setParentCompanyUnit(companyUnit)
    setRootDataItems(companyUnit.ChildrenUnits ?? [])
    setArrBreadcrumb([
      ...arrBreadcrumb,
      {
        id: companyUnit.companyUnitId,
        name: companyUnit.name,
        items: companyUnit.ChildrenUnits,
      },
    ])
  }

  // MARK --- Hooks ---
  useEffect(() => {
    getListCompanyUnit({ ...filters, q: debouncedSearch })
  }, [debouncedSearch, filters, getListCompanyUnit])

  useEffect(() => {
    const { page, limit, status } = filters
    const text = _.lowerCase(filters.text)
    let filteredItems = _.filter(rootDataItems, function (item) {
      return (
        _.lowerCase(item.code).includes(text) ||
        _.lowerCase(item.name).includes(text)
      )
    })
    if (status.length > 0) {
      filteredItems = _.filter(filteredItems, function (item) {
        return item.status === status
      })
    }
    setDataFilterNoPagination(filteredItems)
    if ((page, limit)) {
      if (page * limit <= filteredItems?.length) {
        filteredItems = filteredItems.slice((page - 1) * limit, page * limit)
      } else {
        filteredItems = filteredItems.slice(
          (page - 1) * limit,
          filteredItems?.length,
        )
      }
    }

    setDataItems(filteredItems)
  }, [filters, rootDataItems])

  useEffect(() => {
    setRootDataItems(companyUnits)

    setArrBreadcrumb([])
  }, [companyUnits])

  useEffect(() => {
    if (Global.gOpenModalCreateCompanyUnit) {
      setShowingModalEditCompanyUnit(true)
    }
  }, [])

  return (
    <ContentContainer>
      <ContentContainer.Header
        titleContent={t('Đơn vị')}
        description={
          <ol className="breadcrumb m-0 fw-semibold bg-light">
            <li
              title="Trở về"
              className={`breadcrumb-item cursor-pointer text-hover-primary`}
              onClick={() => {
                setParentCompanyUnit({})
                setRootDataItems(companyUnits)
                setArrBreadcrumb([])
              }}
            >
              <i
                className="fa-light fa-list-dropdown"
                style={{ color: 'inherit' }}
              />
            </li>
            {arrBreadcrumb?.map((item, index) => {
              return (
                <li
                  key={item.id}
                  className={`breadcrumb-item cursor-pointer text-hover-primary ${
                    selectedCompanyUnitItem?.name === item?.name
                      ? 'text-primary'
                      : ''
                  }`}
                >
                  {index < arrBreadcrumb.length - 1 ? (
                    <span
                      className=""
                      onClick={() => {
                        setRootDataItems(item.items ?? [])
                        const arrRet = _.slice(arrBreadcrumb, 0, index + 1)
                        setArrBreadcrumb(arrRet)
                      }}
                    >
                      {item.name}
                    </span>
                  ) : (
                    item.name
                  )}
                </li>
              )
            })}
          </ol>
        }
        toolBar={
          <Space>
            <CustomAntButton
              text={t('NewCompanyUnit')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActive('ADD')
                  setShowingModalEditCompanyUnit(true)
                },
              }}
            />
          </Space>
        }
      >
        <Space>
          {selectedCompanyUnits.length > 0 ? (
            <CustomAntButton
              text={Utils.cn(t('Delete'), selectedCompanyUnits.length)}
              iconCn="fa-regular fa-trash"
              antProps={{
                type: 'primary',
                danger: true,
                onClick: () => {
                  handleDeleteMultiCompanyUnit()
                },
              }}
            />
          ) : null}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={fullColumns}
          dataSource={dataItems}
          pagination={{
            currentPage: filters.page,
            total: dataFilterNoPagination.length,
            rowsPerPage: filters.limit,
            onChangePage: (page) => setFilters({ page }),
            onChangeRowsPerPage: (limit) => setFilters({ limit }),
          }}
          isClickable
          isLoading={isGettingCompanyUnit}
          handleDoubleClickRow={(row) => handleClickedCompanyCompanyUnit(row)}
          isSelectable
          selectedRows={{
            clearSelectedRows: toggleClearCompanyUnits,
            handleSelectedRows: handleSelectedCompanyUnitChanged,
            selectableRowSelected: (row) => {
              const arrId = selectedCompanyUnits.map(
                (item) => item.companyUnitId,
              )
              return arrId.includes(row.companyUnitId)
            },
          }}
        />
      </ContentContainer.Body>

      {/* Modal companyUnit edit */}
      <ModalEditCompanyUnit
        show={showingModalEditCompanyUnit}
        onClose={() => {
          setShowingModalEditCompanyUnit(false)
          setSelectedCompanyUnitItem(null)
          Global.gOpenModalCreateCompanyUnit = false
        }}
        active={active}
        companyUnitItem={selectedCompanyUnitItem}
        parentCompanyUnit={parentCompanyUnit}
      />
    </ContentContainer>
  )
}

export default CompanyUnitScreen
