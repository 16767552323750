import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import './style.scss'

ModalSelectCancellationMethod.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  createCancellationReportSelect: PropTypes.func,
  createErrorAnnounceSelect: PropTypes.func,
  cancelInvoiceSelect: PropTypes.func,
  isInvoice: PropTypes.bool,
}

ModalSelectCancellationMethod.defaultProps = {
  isInvoice: true,
}

function ModalSelectCancellationMethod(props) {
  const {
    show,
    setShow,
    header,
    content,
    createCancellationReportSelect,
    createErrorAnnounceSelect,
    cancelInvoiceSelect,
    isInvoice,
  } = props

  let handleClose = () => setShow(false)
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="ModalSelectCancellationMethod"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>
            <i
              className="fa-light fa-triangle-exclamation mr-3"
              style={{ fontSize: 20, color: '#ff9f0e' }}
            ></i>
            {header}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-2 px-6"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>

          {createCancellationReportSelect && (
            <button
              className="btn btn-outline-secondary mr-2 px-5"
              onClick={createCancellationReportSelect}
            >
              Lập biên bản
            </button>
          )}

          {createErrorAnnounceSelect && (
            <button
              className="btn btn-outline-secondary mr-2 px-5"
              onClick={createErrorAnnounceSelect}
            >
              Lập thông báo
            </button>
          )}

          {cancelInvoiceSelect && (
            <button
              className="btn btn-danger px-4"
              onClick={cancelInvoiceSelect}
            >
              {isInvoice ? 'Hủy hóa đơn' : 'Huỷ vé'}
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSelectCancellationMethod
