import { unwrapResult } from '@reduxjs/toolkit'
import { Space } from 'antd'
import {
  thunkGetCompanyProfile,
  thunkGetTaxAuthority,
  thunkUpdateCompanyProfile,
} from 'features/System/systemSlice'
import { useFormik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import KTFormSearchDropDown from 'general/components/Forms/KTFormSearchDropDown'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import ValidationSchema from 'general/utils/ValidationSchema'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './style.scss'

EInvoiceFile.propTypes = {}

const sTag = '[EInvoiceFile]'

function EInvoiceFile(props) {
  // MARK --- Params ---
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isEditMode, setIsEditMode] = useState(false)
  const [showNoRight, setShowNoRight] = useState(false)
  // const [taxAuthoritiesByProvince, setTaxAuthorityByProvince] = useState([{ name: '', value: '' }]);
  const { company, provinces, taxAuthorities } = useSelector(
    (state) => state?.system,
  )
  const { currentAccount } = useSelector((state) => state?.auth)

  const formik = useFormik({
    initialValues: {
      companyId: company?.companyId ?? '',
      taxCode: company?.taxCode ?? '',
      companyName: company?.companyName ?? '',
      companyEmail: company?.companyEmail ?? '',
      companyPhone: company?.companyPhone ?? '',
      fax: company?.fax ?? '',
      website: company?.website ?? '',
      address: company?.address ?? '',
      businessPermitAddress: company?.businessPermitAddress ?? '',
      contactPersonPosition: company?.contactPersonPosition ?? '',
      contactPersonName: company?.contactPersonName ?? '',
      contactPersonPhone: company?.contactPersonPhone ?? '',
      contactPersonEmail: company?.contactPersonEmail ?? '',
      bankName: company?.bankName ?? '',
      bankAccountNumber: company?.bankAccountNumber ?? '',
      provinceId: company?.provinceId ?? null,
      taxAuthorityId: company?.taxAuthorityId ?? null,
      invoiceDisplayedInfo: {
        email: company?.invoiceDisplayedInfo?.email ?? null,
        fax: company?.invoiceDisplayedInfo?.fax ?? null,
        website: company?.invoiceDisplayedInfo?.website ?? null,
        phone: company?.invoiceDisplayedInfo?.phone ?? null,
        // fullName: company?.contactTaxInfo?.fullName ?? null,
        // email: company?.contactTaxInfo?.email ?? null,
        // phone: company?.contactTaxInfo?.phone ?? null,
        // address: company?.contactTaxInfo?.address ?? null,
      },
      contactTaxInfo: {
        fullName: company?.contactTaxInfo?.fullName ?? null,
        email: company?.contactTaxInfo?.email ?? null,
        phone: company?.contactTaxInfo?.phone ?? null,
        address: company?.contactTaxInfo?.address ?? null,
      },
    },
    validationSchema: ValidationSchema.enterpriseProfile,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const params = { ...values }
        console.log(params)
        const res = unwrapResult(
          await dispatch(thunkUpdateCompanyProfile(params)),
        )
        const { company, result } = res
        if (result === 'success') {
          dispatch(
            thunkGetCompanyProfile({ companyId: currentAccount.companyId }),
          )
          ToastHelper.showSuccess('Cập nhật thông tin công ty thành công')
          setIsEditMode(false)
        }
      } catch (error) {
        console.error(`${sTag} update company profile error: ${error.message}`)
      }
    },
  })

  const userFunction = useSelector((state) => state?.function?.userFunctions)
  const listFunction = useSelector((state) => state?.function?.listFunctions)

  const [suaFunction] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'INVOICE_RECORDS_EDIT',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])

  // MARK --- Function: ---
  // MARK --- Hooks: ---
  useEffect(() => {
    if (company) {
      formik.getFieldHelpers('companyId').setValue(company?.companyId ?? '')
      formik.getFieldHelpers('taxCode').setValue(company?.taxCode ?? '')
      formik.getFieldHelpers('companyName').setValue(company?.companyName ?? '')
      formik
        .getFieldHelpers('companyEmail')
        .setValue(company?.companyEmail ?? '')
      formik
        .getFieldHelpers('companyPhone')
        .setValue(company?.companyPhone ?? '')
      formik.getFieldHelpers('fax').setValue(company?.fax ?? '')
      formik.getFieldHelpers('website').setValue(company?.website ?? '')
      formik.getFieldHelpers('address').setValue(company?.address ?? '')
      formik
        .getFieldHelpers('businessPermitAddress')
        .setValue(company?.businessPermitAddress ?? '')
      formik
        .getFieldHelpers('contactPersonPosition')
        .setValue(company?.contactPersonPosition ?? '')
      formik
        .getFieldHelpers('contactPersonName')
        .setValue(company?.contactPersonName ?? '')
      formik
        .getFieldHelpers('contactPersonPhone')
        .setValue(company?.contactPersonPhone ?? '')
      formik
        .getFieldHelpers('contactPersonEmail')
        .setValue(company?.contactPersonEmail ?? '')
      formik.getFieldHelpers('bankName').setValue(company?.bankName ?? '')
      formik
        .getFieldHelpers('bankAccountNumber')
        .setValue(company?.bankAccountNumber ?? '')
      formik.getFieldHelpers('provinceId').setValue(company?.provinceId ?? null)
      formik
        .getFieldHelpers('taxAuthorityId')
        .setValue(company?.taxAuthorityId ?? null)

      //huy,
      formik
        .getFieldHelpers('invoiceDisplayedInfo.email')
        .setValue(company?.invoiceDisplayedInfo?.email ?? null)
      formik
        .getFieldHelpers('invoiceDisplayedInfo.phone')
        .setValue(company?.invoiceDisplayedInfo?.phone ?? null)
      formik
        .getFieldHelpers('invoiceDisplayedInfo.fax')
        .setValue(company?.invoiceDisplayedInfo?.fax ?? null)
      formik
        .getFieldHelpers('invoiceDisplayedInfo.website')
        .setValue(company?.invoiceDisplayedInfo?.website ?? null)
      formik
        .getFieldHelpers('contactTaxInfo.fullName')
        .setValue(company?.contactTaxInfo?.fullName ?? null)
      formik
        .getFieldHelpers('contactTaxInfo.email')
        .setValue(company?.contactTaxInfo?.email ?? null)
      formik
        .getFieldHelpers('contactTaxInfo.phone')
        .setValue(company?.contactTaxInfo?.phone ?? null)
      formik
        .getFieldHelpers('contactTaxInfo.address')
        .setValue(company?.contactTaxInfo?.address ?? null)
    }
  }, [company, isEditMode])

  useEffect(() => {
    if (formik.getFieldProps('provinceId').value) {
      dispatch(
        thunkGetTaxAuthority({
          provinceId: formik.getFieldProps('provinceId').value,
        }),
      )
    }
  }, [company, provinces, formik.getFieldProps('provinceId').value])

  return (
    <form>
      <div className="EInvoiceFile row m-0">
        {/* thong tin don vi */}
        {/* button */}
        <div
          className="d-flex justify-content-end mb-5 p-0"
          style={{ position: 'absolute', top: '20px', right: '20px' }}
        >
          {!isEditMode ? (
            <CustomAntButton
              text="Chỉnh sửa"
              iconCn="fa-regular fa-edit"
              antProps={{
                type: 'primary',
                onClick: () => {
                  if (suaFunction) {
                    setIsEditMode(true)
                  } else {
                    setShowNoRight(true)
                  }
                },
              }}
            />
          ) : (
            <Space>
              <CustomAntButton
                text="Hủy"
                iconCn="fa-regular fa-times"
                antProps={{
                  type: 'primary',
                  danger: true,
                  onClick: () => {
                    setIsEditMode(false)
                  },
                }}
              />
              <CustomAntButton
                text="Lưu"
                iconCn="fa-regular fa-check"
                antProps={{
                  className: 'btn btn-success',
                  onClick: () => {
                    formik.handleSubmit()
                  },
                }}
              />
            </Space>
          )}
        </div>

        <div className="p-0">
          <div className="bg-white border">
            <div className="px-8 py-3 d-flex align-items-center border-bottom">
              <div className="w-35px h-35px bg-primary rounded-circle d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-building text-white"></i>
              </div>
              <span className="font-size-lg font-weight-bold ml-4">
                Thông tin đơn vị
              </span>
            </div>

            <div className="p-4 row m-0">
              {/* MST */}
              <div className="col-lg-6">
                <KTFormGroup
                  label={
                    <>
                      {t('Mã số thuế')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="taxCode"
                  inputElement={
                    <KTFormInput
                      name="taxCode"
                      value={formik.getFieldProps('taxCode').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('taxCode').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('taxCode').setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(formik.getFieldMeta('taxCode').error)}
                      isTouched={formik.getFieldMeta('taxCode').touched}
                      feedbackText={formik.getFieldMeta('taxCode').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Mã số thuế'))}`}
                      disabled={true}
                    />
                  }
                />
              </div>

              {/* name */}
              <div className="col-lg-6">
                <KTFormGroup
                  label={
                    <>
                      {t('Tên công ty')}{' '}
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="companyName"
                  inputElement={
                    <KTFormInput
                      name="companyName"
                      value={formik.getFieldProps('companyName').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('companyName').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('companyName').setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('companyName').error,
                      )}
                      isTouched={formik.getFieldMeta('companyName').touched}
                      feedbackText={formik.getFieldMeta('companyName').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Tên công ty'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* companyEmail */}
              <div className="col-lg-3 col-md-6">
                <KTFormGroup
                  label={
                    <>
                      {t('Email ')}
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="companyEmail"
                  inputElement={
                    <KTFormInput
                      name="companyEmail"
                      value={formik.getFieldProps('companyEmail').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('companyEmail').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('companyEmail').setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('companyEmail').error,
                      )}
                      isTouched={formik.getFieldMeta('companyEmail').touched}
                      feedbackText={formik.getFieldMeta('companyEmail').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Email'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* companyPhone */}
              <div className="col-lg-3 col-md-6">
                <KTFormGroup
                  label={<>{t('Số điện thoại')}</>}
                  inputName="companyPhone"
                  inputElement={
                    <KTFormInput
                      name="companyPhone"
                      value={formik.getFieldProps('companyPhone').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('companyPhone').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('companyPhone').setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('companyPhone').error,
                      )}
                      isTouched={formik.getFieldMeta('companyPhone').touched}
                      feedbackText={formik.getFieldMeta('companyPhone').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Số điện thoại'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* fax */}
              <div className="col-lg-3 col-md-6">
                <KTFormGroup
                  label={<>{t('Số fax')}</>}
                  inputName="fax"
                  inputElement={
                    <KTFormInput
                      name="fax"
                      value={formik.getFieldProps('fax').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('fax').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('fax').setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(formik.getFieldMeta('fax').error)}
                      isTouched={formik.getFieldMeta('fax').touched}
                      feedbackText={formik.getFieldMeta('fax').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Số fax'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* website */}
              <div className="col-lg-3 col-md-6">
                <KTFormGroup
                  label={<>{t('Địa chỉ website')}</>}
                  inputName="website"
                  inputElement={
                    <KTFormInput
                      name="website"
                      value={formik.getFieldProps('website').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('website').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('website').setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(formik.getFieldMeta('website').error)}
                      isTouched={formik.getFieldMeta('website').touched}
                      feedbackText={formik.getFieldMeta('website').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Địa chỉ website'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* address */}
              {/* <div className="col-md-6">
                <KTFormGroup
                  label={
                    <>
                      {t('Địa chỉ')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="address"
                  inputElement={
                    <KTFormInput
                      name="address"
                      value={formik.getFieldProps('address').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('address').setValue(value);
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('address').setTouched(true);
                      }}
                      enableCheckValid = {isEditMode}
                      isValid={_.isEmpty(formik.getFieldMeta('address').error)}
                      isTouched={formik.getFieldMeta('address').touched}
                      feedbackText={formik.getFieldMeta('address').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Địa chỉ'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div> */}
              {/* tinh/thanh pho */}
              <div className="d-flex flex-column col-md-6">
                <label className="mb-2" htmlFor="province">
                  <>
                    {_.capitalize(t('Tỉnh/thành phố'))}{' '}
                    <span className="text-danger">(*)</span>
                  </>
                </label>
                <KTFormSearchDropDown
                  name="provinceId"
                  isCustom
                  options={[{ name: '', value: '' }].concat(
                    provinces?.map((item) => {
                      return { name: item.name, value: item.provinceId }
                    }),
                  )}
                  value={formik.getFieldProps('provinceId').value?.toString()}
                  onChange={(newValue) => {
                    formik.getFieldHelpers('provinceId').setValue(newValue)
                    // console.log('taxAuthorityByProvince', taxAuthorities[newValue]);
                    // setTaxAuthorityByProvince(taxAuthorities[newValue]);
                  }}
                  disabled={!isEditMode}
                />
              </div>

              {/* businessPermitAddress */}
              <div className="col-md-6">
                <KTFormGroup
                  label={
                    <>
                      {t('Địa chỉ đăng ký kinh doanh')}{' '}
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="businessPermitAddress"
                  inputElement={
                    <KTFormInput
                      name="businessPermitAddress"
                      value={
                        formik.getFieldProps('businessPermitAddress').value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('businessPermitAddress')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('businessPermitAddress')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('businessPermitAddress').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('businessPermitAddress').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('businessPermitAddress').error
                      }
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(
                        t('Địa chỉ đăng ký kinh doanh'),
                      )}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* nguoi dai dien */}
        <div className="p-0 mt-4">
          <div className="bg-white border">
            <div className="px-8 py-3 d-flex align-items-center border-bottom">
              <div className="w-35px h-35px bg-primary rounded-circle d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-user text-white"></i>
              </div>
              <span className="font-size-lg font-weight-bold ml-4">
                Người đại diện
              </span>
            </div>

            <div className="p-4 row m-0">
              {/* contactPerson */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Tên người đại diện')}</>}
                  inputName="contactPersonName"
                  inputElement={
                    <KTFormInput
                      name="contactPersonName"
                      value={formik.getFieldProps('contactPersonName').value}
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactPersonName')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactPersonName')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactPersonName').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactPersonName').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactPersonName').error
                      }
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* contactPersonPosition */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Chức vụ người đại diện')}</>}
                  inputName="contactPersonPosition"
                  inputElement={
                    <KTFormInput
                      name="contactPersonPosition"
                      value={
                        formik.getFieldProps('contactPersonPosition').value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactPersonPosition')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactPersonPosition')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactPersonPosition').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactPersonPosition').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactPersonPosition').error
                      }
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(
                        t('Chức vụ người đại diện'),
                      )}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* contactPersonEmail */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Email')}</>}
                  inputName="contactPersonEmail"
                  inputElement={
                    <KTFormInput
                      name="contactPersonEmail"
                      value={formik.getFieldProps('contactPersonEmail').value}
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactPersonEmail')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactPersonEmail')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactPersonEmail').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactPersonEmail').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactPersonEmail').error
                      }
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Email'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* contactPersonPhone */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Số điện thoại')}</>}
                  inputName="contactPersonPhone"
                  inputElement={
                    <KTFormInput
                      name="contactPersonPhone"
                      value={formik.getFieldProps('contactPersonPhone').value}
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactPersonPhone')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactPersonPhone')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactPersonPhone').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactPersonPhone').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactPersonPhone').error
                      }
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Số điện thoại'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* thong tin hien thi tren hoa don dien tu */}
        <div className="p-0 mt-4">
          <div className="bg-white border">
            <div className="px-8 py-3 d-flex align-items-center border-bottom">
              <div className="w-35px h-35px bg-primary rounded-circle d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-file-invoice text-white"></i>
              </div>
              <span className="font-size-lg font-weight-bold ml-4">
                Thông tin hiển thị trên hóa đơn điện tử
              </span>
            </div>

            <div className="p-4 row m-0">
              {/* invoiceDisplayedInfo.email */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Email')}</>}
                  inputName="invoiceDisplayedInfo.email"
                  inputElement={
                    <KTFormInput
                      name="invoiceDisplayedInfo.email"
                      value={
                        formik.getFieldProps('invoiceDisplayedInfo.email').value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.email')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.email')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('invoiceDisplayedInfo.email').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('invoiceDisplayedInfo.email')
                          .touched
                      }
                      feedbackText={
                        formik.getFieldMeta('invoiceDisplayedInfo.email').error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* invoiceDisplayedInfo.phone */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Số điện thoại')}</>}
                  inputName="invoiceDisplayedInfo.phone"
                  inputElement={
                    <KTFormInput
                      name="invoiceDisplayedInfo.phone"
                      value={
                        formik.getFieldProps('invoiceDisplayedInfo.phone').value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.phone')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.phone')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('invoiceDisplayedInfo.phone').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('invoiceDisplayedInfo.phone')
                          .touched
                      }
                      feedbackText={
                        formik.getFieldMeta('invoiceDisplayedInfo.phone').error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* invoiceDisplayedInfo.fax */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Fax')}</>}
                  inputName="invoiceDisplayedInfo.fax"
                  inputElement={
                    <KTFormInput
                      name="invoiceDisplayedInfo.fax"
                      value={
                        formik.getFieldProps('invoiceDisplayedInfo.fax').value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.fax')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.fax')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('invoiceDisplayedInfo.fax').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('invoiceDisplayedInfo.fax').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('invoiceDisplayedInfo.fax').error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* invoiceDisplayedInfo.website */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Website')}</>}
                  inputName="invoiceDisplayedInfo.website"
                  inputElement={
                    <KTFormInput
                      name="invoiceDisplayedInfo.website"
                      value={
                        formik.getFieldProps('invoiceDisplayedInfo.website')
                          .value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.website')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('invoiceDisplayedInfo.website')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('invoiceDisplayedInfo.website')
                          .error,
                      )}
                      isTouched={
                        formik.getFieldMeta('invoiceDisplayedInfo.website')
                          .touched
                      }
                      feedbackText={
                        formik.getFieldMeta('invoiceDisplayedInfo.website')
                          .error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* thong tin lien he voi co quan thue */}
        <div className="p-0 mt-4">
          <div className="bg-white border">
            <div className="px-8 py-3 d-flex align-items-center border-bottom">
              <div className="w-35px h-35px bg-primary rounded-circle d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-address-card text-white"></i>
              </div>
              <span className="font-size-lg font-weight-bold ml-4">
                Thông tin liên hệ đến cơ quan thuế
              </span>
            </div>

            <div className="p-4 row m-0">
              <div className="col-md-12">
                <span className="font-size-base font-italic">
                  Cơ quan thuế sẽ gửi các thông báo quan trọng liên quan đến hóa
                  đơn điện tử tới email/số điện thoại/địa chỉ này. Để thay đổi
                  thông tin, đơn vị cần lập tờ khai thay đổi thông tin sử dụng
                  HĐĐT gửi CQT và được chấp nhận.{' '}
                  <a
                    // className="text-decoration-underline"
                    style={{ color: '#4287f5' }}
                    onClick={(e) => navigate('/hoa-don-dau-ra/to-khai')}
                  >
                    Lập tờ khai.
                  </a>
                </span>
              </div>
              {/* contactTaxInfo.fullName */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Họ và tên')}</>}
                  inputName="contactTaxInfo.fullName"
                  inputElement={
                    <KTFormInput
                      name="contactTaxInfo.fullName"
                      value={
                        formik.getFieldProps('contactTaxInfo.fullName').value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactTaxInfo.fullName')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactTaxInfo.fullName')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactTaxInfo.fullName').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactTaxInfo.fullName').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactTaxInfo.fullName').error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={true}
                    />
                  }
                />
              </div>

              {/* contactTaxInfo.email */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Email')}</>}
                  inputName="contactTaxInfo.email"
                  inputElement={
                    <KTFormInput
                      name="contactTaxInfo.email"
                      value={formik.getFieldProps('contactTaxInfo.email').value}
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactTaxInfo.email')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactTaxInfo.email')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactTaxInfo.email').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactTaxInfo.email').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactTaxInfo.email').error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={true}
                    />
                  }
                />
              </div>

              {/* contactTaxInfo.phone */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Số điện thoại')}</>}
                  inputName="contactTaxInfo.phone"
                  inputElement={
                    <KTFormInput
                      name="contactTaxInfo.phone"
                      value={formik.getFieldProps('contactTaxInfo.phone').value}
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactTaxInfo.phone')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactTaxInfo.phone')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactTaxInfo.phone').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactTaxInfo.phone').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactTaxInfo.phone').error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={true}
                    />
                  }
                />
              </div>

              {/* contactTaxInfo.address */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Địa chỉ')}</>}
                  inputName="contactTaxInfo.address"
                  inputElement={
                    <KTFormInput
                      name="contactTaxInfo.address"
                      value={
                        formik.getFieldProps('contactTaxInfo.address').value
                      }
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('contactTaxInfo.address')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('contactTaxInfo.address')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('contactTaxInfo.address').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('contactTaxInfo.address').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('contactTaxInfo.address').error
                      }
                      type={KTFormInputType.text}
                      // placeholder={`${_.capitalize(t('Tên người đại diện'))}`}
                      disabled={true}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* thong tin co quan thua chu quan */}
        <div className="col-lg-6 pl-0 mt-4 pr-2 h-100">
          <div className="bg-white border">
            <div className="px-8 py-3 d-flex align-items-center border-bottom">
              <div className="w-35px h-35px bg-primary rounded-circle d-flex align-items-center justify-content-center">
                <i className="fas fa-briefcase text-white"></i>
              </div>
              <span className="font-size-lg font-weight-bold ml-4">
                Thông tin cơ quan thuế chủ quản
              </span>
            </div>

            <div className="p-4 row m-0">
              {/* co quan thue */}
              <div className="d-flex flex-column col-md-12 mb-4 w-100">
                <label className="mb-2" htmlFor="taxAuthority">
                  {_.capitalize(t('Cơ quan thuế'))}
                </label>
                <KTFormSearchDropDown
                  name="taxAuthority"
                  isCustom
                  options={[{ name: '', value: '' }].concat(
                    taxAuthorities?.map((item) => {
                      return { name: item?.name, value: item?.taxAuthorityId }
                    }),
                  )}
                  noChoice={true}
                  value={formik
                    .getFieldProps('taxAuthorityId')
                    .value?.toString()}
                  onChange={(newValue) => {
                    formik.getFieldHelpers('taxAuthorityId').setValue(newValue)
                  }}
                  disabled={
                    !isEditMode || !formik.getFieldProps('provinceId').value
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* thong tin thanh toan */}
        <div className="col-lg-6 pr-0 mt-4 pl-2 h-100">
          <div className="bg-white border">
            <div className="px-8 py-3 d-flex align-items-center border-bottom">
              <div className="w-35px h-35px bg-primary rounded-circle d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-credit-card text-white"></i>
              </div>
              <span className="font-size-lg font-weight-bold ml-4">
                Thông tin thanh toán
              </span>
            </div>

            <div className="p-4 row m-0">
              {/* bankAccountNumber */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Số tài khoản')}</>}
                  inputName="bankAccountNumber"
                  inputElement={
                    <KTFormInput
                      name="bankAccountNumber"
                      value={formik.getFieldProps('bankAccountNumber').value}
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('bankAccountNumber')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('bankAccountNumber')
                          .setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(
                        formik.getFieldMeta('bankAccountNumber').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('bankAccountNumber').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('bankAccountNumber').error
                      }
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Số tài khoản'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>

              {/* bankName */}
              <div className="col-md-6">
                <KTFormGroup
                  label={<>{t('Ngân hàng')}</>}
                  inputName="bankName"
                  inputElement={
                    <KTFormInput
                      name="bankName"
                      value={formik.getFieldProps('bankName').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('bankName').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('bankName').setTouched(true)
                      }}
                      enableCheckValid={isEditMode}
                      isValid={_.isEmpty(formik.getFieldMeta('bankName').error)}
                      isTouched={formik.getFieldMeta('bankName').touched}
                      feedbackText={formik.getFieldMeta('bankName').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('Ngân hàng'))}`}
                      disabled={!isEditMode}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
      </div>
    </form>
  )
}

export default EInvoiceFile
