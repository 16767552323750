import { useTranslation } from 'react-i18next'
import { Result, Button, Form } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import HeaderTaxDectionDocumentsUpdate from 'features/TaxDeductionDocuments/component/Layout/header'
import HeaderAccountDocuments from '../component/TabInfor/AccountingDocuments/HeaderUpdate'
import { useEffect, useMemo, useState } from 'react'
import LeftContentTaxDeductionDocuments from 'features/TaxDeductionDocuments/component/ContentUpdate/leftContent'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  thunkFindByIdTaxDeductionDocuments,
  thunkGetAllTaxDeductionDocumentsDefault,
} from 'features/TaxDeductionDocuments/taxDeductionDocumentsSlice'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import Utils, {
  convertToDayjs,
  convertToOptions,
  convertToOptionsValueString,
  findKeyInBorderBackground,
  getParam,
} from 'general/utils/Utils'
import AppResource from 'general/constants/AppResource'
import { FormCustom } from 'features/TaxDeductionDocuments/styled'
import {
  thunkFindByIdAccountingDocument,
  thunkSaveAccountingDocument,
  thunkSignHSMAccountingDocument,
} from 'features/AccountingDocument/accountDoucmentsSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import AlertTaxDectionDocumentsUpdate from 'features/TaxDeductionDocuments/component/Alert'
import PopupSelectTaxpayer from 'features/Taxpayer/component/PopupSelect'
import PopupSave from 'features/Taxpayer/component/PopupSave'
import PopupSaveTaxpayer from 'features/Taxpayer/component/PopupSave'
import { thunkGetAccountProfile } from 'app/authSlice'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { thunkNationlity } from 'features/Nationlity/nationlitySlice'
import { thunkCity } from 'features/City/citySlice'
import SelectOptions from 'general/constants/SelectOptions'
function AddAccountingDocuments() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const accounting_documentsId = getParam('accounting_documents')
  const typeSave = getParam('type')
  const [auth, setAuth] = useState({})
  const [templatePaperId, setTemplatePaperId] = useState(
    getParam('templatePaper'),
  )
  const [listDataTemplateDefault, setListDataTemplateDefault] = useState([])
  const [templateDefault, setTemplateDefault] = useState({})
  const [imgLogoTemplateDefault, setImgLogoTemplateDefault] = useState({})
  const [imgLOwnWallpaper, setImgLOwnWallpaper] = useState({})
  const [form] = Form.useForm()
  const [formTaxpayers] = Form.useForm()
  const [openModalAlert, setOpenModalAlert] = useState(false)
  const [formState, setFormState] = useState(false)
  const typePaper = templateDefault?.typePaper ?? 'A4'
  const language = useState(JSON.parse(templateDefault?.language ?? '["vi"]'))
  const [taxDeductionDocumentsTemplate, setTaxDeductionDocumentsTemplate] =
    useState({})
  const [backgroundImg, setBackgroundImg] = useState({
    x: 225,
    y: 200,
    width: 200,
    height: 200,
    location: null,
  })
  const [imgLogo, setImgLogo] = useState()
  const [fileOwnWallpaper, setFileOwnWallpaper] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [fileContentBackground, setFileContentBackground] = useState()
  const [chonseBorder, setChonseBorder] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [styleOwnWallpaper, setStyleOwnWallpaper] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [chonseContent, setChonseContent] = useState()
  const [contentBackgroundColor, setContentBackgroundColor] = useState()
  const [fileBorderBackground, setFileBorderBackground] = useState()
  const [colorBorderBackground, setColorBorderBackground] = useState()
  const [listContentFeild, setListContentFeild] = useState([])
  const [typeSign, setTypeSign] = useState()
  const [activeFeild, setActiveFeild] = useState({})
  const [activeLayout, setActiveLayout] = useState({})
  const [activeTd, setActiveTd] = useState({})
  const [accountingDocuments, setAccountingDocuments] = useState()
  const [visibleSelectTaxpaper, setVisibleSelectTaxpaper] = useState(false)
  const [visibleAddTaxpaper, setVisibleAddTaxpaper] = useState(false)
  const [taxpayerSelect, setTaxpayerSelect] = useState(null)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [listIndentify, setListIndentify] = useState([])
  const [listNationality, setListNationality] = useState([])
  const [listCity, setListCity] = useState([])

  const resetForm = () => {
    if (form) {
      if (!accounting_documentsId) {
        form.resetFields()
      }

      let day = dayjs()
      const dataReset = {
        taxDuctionDocumentsTemplateId: templateDefault?.templateId,
        formNumber: templateDefault?.formNumber,
        symbolCode: templateDefault?.symbolCode,
        symbolYear: day.set('year', templateDefault?.symbolYear),
        symbolType: templateDefault?.symbolType,
        number: templateDefault?.number,
        typeMoney: templateDefault?.typeMoney,
        organizationName: templateDefault?.organizationName
          ? templateDefault?.organizationName
          : auth?.currentCompany?.companyName,
        positionLogo: templateDefault?.positionLogo,
        fontText: 'Times New Roman',
        fontSize: '16',
        textColor: '#000000',
        viewConversionTemplate: templateDefault?.viewConversionTemplate,
        borderBackgroundColor: templateDefault?.borderBackgroundColor,
        borderBackground: templateDefault?.boderBackground,
        contentBackgroundColor: templateDefault?.contentBackgroundColor,
        contentBackground: templateDefault?.contentBackground,
        opacityContentBackground: templateDefault?.contentBackgroundOpactity,
        ownWallpaper: 1,
        ownWallpaperColor: templateDefault?.ownWallpaperColor,
        describeownWallpaper: templateDefault?.describeownWallpaper,
        boldOwnWallpaper: templateDefault?.boldOwnWallpaper,
        opacityOwnWallpaper: templateDefault?.opacityOwnWallpaper,
        opacityTextOwnWallpaper: templateDefault?.opacityTextOwnWallpaper,
        fontTextOwnWallpaper:
          templateDefault?.fontTextOwnWallpaper ?? 'Times New Roman',
        fontSizeTextOwnWallpaper: templateDefault?.fontSizeTextOwnWallpaper,
      }
      if (!accounting_documentsId) {
        form.setFieldsValue({
          employeeStatusResidence: 1,
          insurance: 0,
          totalTaxableIncome: 0,
          totalTaxIncome: 0,
          totalDeductedTax: 0,
          income: 'Tiền lương, tiền công',
        })
      }

      setFormState(dataReset)
      setFileBorderBackground(templateDefault?.boderBackground)
      setChonseBorder(
        findKeyInBorderBackground(
          AppResource.borderBackground,
          templateDefault?.boderBackground,
        ),
      )
      setColorBorderBackground(templateDefault?.borderBackgroundColor)
      setContentBackgroundColor(templateDefault?.contentBackgroundColor)
      setFileContentBackground(templateDefault?.contentBackground)
      // setChonseContent({
      //   ...findKeyInBorderBackground(
      //     AppResource.contentBackground,
      //     templateDefault?.contentBackground,
      //   ),
      //   x: 150,
      //   y: 320,
      //   width: 500,
      //   height: 500,
      //   key: templateDefault?.contentBackground,
      // })
    }
    setListContentFeild(
      JSON.parse(templateDefault?.data ? templateDefault.data : '[]'),
    )
  }

  const findDataSource = async () => {
    try {
      const data = unwrapResult(
        await dispatch(thunkGetAllTaxDeductionDocumentsDefault({})),
      )
      setListDataTemplateDefault(data?.items)
    } catch (error) {}
  }

  const findById = async () => {
    try {
      const data = unwrapResult(
        await dispatch(thunkFindByIdTaxDeductionDocuments({ templatePaperId })),
      )
      setTemplateDefault(data?.data)
    } catch (error) {}
  }

  useEffect(() => {
    findDataSource()
  }, [])

  useEffect(() => {
    if (accounting_documentsId) {
      findByIdAccountingDocument(accounting_documentsId)
    }
  }, [accounting_documentsId])

  useEffect(() => {
    if (templatePaperId) {
      findById()
    }
  }, [templatePaperId])

  useEffect(() => {
    if (templateDefault) {
      setImgLogoTemplateDefault(
        JSON.parse(templateDefault?.imglogo ? templateDefault.imglogo : '{}'),
      )
      setImgLOwnWallpaper(
        JSON.parse(
          templateDefault?.fileOwnWallpaper
            ? templateDefault.fileOwnWallpaper
            : '{}',
        ),
      )
    }
  }, [templateDefault])

  useMemo(() => {
    form.setFieldsValue({
      organizationName: auth?.companyName,
    })
  }, [auth])

  useEffect(() => {
    resetForm()
  }, [auth, templateDefault, form])

  useEffect(() => {
    if (accountingDocuments) {
      form.resetFields()
      let jsonFormDataCurrent = JSON.parse(
        accountingDocuments?.jsonForm ?? '{}',
      )
      checkListIdentify(jsonFormDataCurrent)
      jsonFormDataCurrent = convertToDayjs(jsonFormDataCurrent)
      // let requestFormCurrent =
      form.setFieldsValue(jsonFormDataCurrent)
    }
  }, [templateDefault])

  useMemo(() => {
    setImgLogo({
      x: 0,
      y: 0,
      width: imgLogoTemplateDefault?.width ?? 75,
      height: imgLogoTemplateDefault?.height ?? 56,
      location: imgLogoTemplateDefault?.location,
      url: imgLogoTemplateDefault?.url
        ? `${process.env.REACT_APP_BASE_URL}${imgLogoTemplateDefault.url}`
        : null,
    })
  }, [imgLogoTemplateDefault])

  useMemo(() => {
    setFileOwnWallpaper({
      x: imgLOwnWallpaper.x ?? 0,
      y: imgLOwnWallpaper.y ?? 0,
      width: imgLOwnWallpaper?.width ?? 75,
      height: imgLOwnWallpaper?.height ?? 56,
      location: imgLOwnWallpaper?.location,
      url: imgLOwnWallpaper?.url
        ? `${process.env.REACT_APP_BASE_URL}${imgLOwnWallpaper.url}`
        : null,
    })
  }, [imgLOwnWallpaper])

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(thunkGetAccountProfile())
      if (data?.payload) {
        const dataCompany = await dispatch(
          thunkGetCompanyProfile({
            companyId: data?.payload?.company?.companyId,
          }),
        )
        setTypeSign(dataCompany?.payload?.activeSigningMethod)
      }
      setAuth(data?.payload?.company)
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    getAllNationality()
    getAllCity({ countryId: 1 })
  }, [])

  const submit = (type) => {
    form
      .validateFields()
      .then((values) => {
        setDisableSubmit(true)
        let data = { ...values, templatePaperId }
        save(data, type)
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo)
        //trường hợp đặc biệt
        if (errorInfo?.errorFields?.length == 0) {
          setDisableSubmit(true)
          let data = { ...errorInfo?.values, templatePaperId }
          save(data, type)
        } else {
          setDisableSubmit(false)
          const errorFields = errorInfo?.errorFields
          if (errorFields.length > 0) {
            const fieldName = errorFields[0].name[0] // Get the name of the first field that failed validation
            const fieldInstance = form.getFieldInstance(fieldName) // Get the instance of that field
            if (fieldInstance) {
              fieldInstance.focus() // Focus the field
            }
          }
        }
      })
  }

  const save = async (body, type) => {
    if (accounting_documentsId) {
      body.accounting_documentsId = accounting_documentsId
    }
    if (typeSave) {
      delete body['accounting_documentsId']
    }
    const result = unwrapResult(
      await dispatch(thunkSaveAccountingDocument(body)),
    )
    if (result?.message === 'success') {
      if (type) {
        if (typeSign === DIGITAL_SIGN_METHOD.HSM) {
          ToastHelper.showSuccess(`${t('accountingDocuments.saveSuc')}`)
          sign(result?.data?.accounting_documentsId)
        } else if (typeSign === DIGITAL_SIGN_METHOD.USB) {
          ToastHelper.showSuccess(`${t('accountingDocuments.saveSuc')}`)
          let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
          let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${auth.taxCode},${result?.data?.accounting_documentsId},AccountingDocuments`
          console.log('urlSchema', urlSchema)
          Utils.openToolSignAccountingDocuments(urlSchema, accessToken)
        } else {
        }
      } else {
        setDisableSubmit(false)
        ToastHelper.showSuccess(`${t('accountingDocuments.saveSuc')}`)
        navigate(
          '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan?tab=2',
        )
      }
    } else {
      setDisableSubmit(false)
      ToastHelper.showError(result?.data)
    }
  }

  const findByIdAccountingDocument = async (id) => {
    const result = unwrapResult(
      await dispatch(
        thunkFindByIdAccountingDocument({ accounting_documentsId: id }),
      ),
    )
    if (result?.message === 'success') {
      setTemplatePaperId(result?.data?.templatePaperId)
      setAccountingDocuments(result?.data)
    }
  }

  const sign = async (accounting_documentsId) => {
    try {
      const result = unwrapResult(
        await dispatch(
          thunkSignHSMAccountingDocument({ accounting_documentsId, typeSign }),
        ),
      )
      if (result?.message == 'success') {
        setDisableSubmit(false)
        resetResouce()
        ToastHelper.showSuccess(t('accountingDocuments.sign'))
        navigate(
          '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan?tab=2',
        )
      } else {
        setDisableSubmit(false)
        ToastHelper.showError(result?.data)
      }
    } catch (error) {}
  }

  const getAllNationality = async () => {
    const data = unwrapResult(await dispatch(thunkNationlity({})))
    setListNationality(data?.data ?? [])
  }

  const getAllCity = async (param) => {
    const data = unwrapResult(await dispatch(thunkCity(param)))
    setListCity(data?.data ?? [])
  }

  const checkListIdentify = (value) => {
    if (value?.employeeIdentification?.trim().length == 8) {
      setListIndentify(
        convertToOptions(listNationality, 'nationalityId', 'nationalityName'),
      )
    } else if (value?.employeeIdentification?.trim().length == 9) {
      setListIndentify(
        convertToOptionsValueString(listCity, 'cityCode', 'cityName'),
      )
    } else if (value?.employeeIdentification?.trim().length == 12) {
      setListIndentify(SelectOptions.listCitizenidentificationLocation)
    } else {
      setListIndentify([])
    }
  }

  const resetResouce = async () => {
    return await dispatch(thunkGetAccountProfile())
  }

  return (
    <>
      <HeaderAccountDocuments
        setOpenModalAlert={(value) => {
          setOpenModalAlert(value)
        }}
        setOpenModalAlertTemplate={(value) => {
          // setOpenModalAlertTemplate(value)
        }}
        setOpenModalMaintenance={(value) => {
          // setOpenModalMaintenance(value)
        }}
        openModalAlertTemplateSave={false}
        setOpenModalAlertTemplateSave={(value) => {}}
        nameTemplate={
          accounting_documentsId && !typeSave
            ? t('accountingDocuments.titleEditLCT', {})
            : t('accountingDocuments.titleAddLCT', {})
        }
        disableSubmit={disableSubmit}
        onClickSave={() => {
          submit()
        }}
        onClickSaveAndSign={() => {
          submit(1)
        }}
      />
      <center
        style={{
          // paddingTop: 20,
          backgroundColor: '#dadce0',
          height: 800,
          overflowX: 'auto',
        }}
      >
        <FormCustom form={form}>
          <LeftContentTaxDeductionDocuments
            form={form}
            styleInput={1}
            setImgLogo={(value) =>
              setImgLogo((imgLogo) => ({
                ...imgLogo,
                ...value,
              }))
            }
            typePaper={typePaper}
            taxDeductionDocumentsTemplate={taxDeductionDocumentsTemplate}
            backgroundImg={backgroundImg}
            imgLogo={imgLogo}
            fileOwnWallpaper={fileOwnWallpaper}
            chonseBorder={chonseBorder}
            // fileBorderBackground={fileBorderBackground}
            // colorBorderBackground={colorBorderBackground}
            chonseContent={chonseContent}
            fileContentBackground={fileContentBackground}
            contentBackgroundColor={contentBackgroundColor}
            listContentFeild={listContentFeild}
            formState={formState}
            tabActiveKey={1}
            activeFeild={activeFeild}
            activeLayout={activeLayout}
            activeTd={activeTd}
            language={language}
            styleOwnWallpaper={styleOwnWallpaper}
            setActiveTd={() => {}}
            setActiveFeild={() => {}}
            setActiveLayout={() => {}}
            setTabActiveKey={() => {}}
            setVisibleSelectTaxpaper={(value) => {
              setVisibleSelectTaxpaper(value)
            }}
            setVisibleAddTaxpaper={(value) => {
              setVisibleAddTaxpaper(value)
            }}
            listIndentify={listIndentify}
            setListIndentify={(value) => {
              setListIndentify(value)
            }}
          />
        </FormCustom>
      </center>
      <AlertTaxDectionDocumentsUpdate
        titlePrimary={t('taxDeductionDocuments.save')}
        openModalAlert={openModalAlert}
        titleAlert={t('taxDeductionDocuments.dataHasChanged')}
        messageAlert={t('taxDeductionDocuments.messageAlert')}
        setOpenModalAlert={(value) => {
          setOpenModalAlert(value)
        }}
        titleButtonNotRecommended={t('taxDeductionDocuments.notSave')}
        onClickButtonNotRecommended={() => {
          navigate(
            '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan?tab=2',
          )
        }}
        onClickPrimary={() => {
          submit()
        }}
      />
      <PopupSelectTaxpayer
        visible={visibleSelectTaxpaper}
        setVisible={(value) => setVisibleSelectTaxpaper(value)}
        taxpayerSelect={taxpayerSelect}
        setTaxpayerSelect={(value) => {
          setTaxpayerSelect(value)
        }}
        onSave={(value) => {
          checkListIdentify(value)
          setVisibleSelectTaxpaper(false)
          form.setFieldsValue({
            taxpayersName: value?.taxpayersName,
            employeeTaxCode: value?.employeeTaxCode,
            employeePhone: value?.employeePhone,
            employeeIdentification: value?.employeeIdentification,
            employeeNationality: value?.employeeIdentification_nationlityId
              ? value.employeeIdentification_nationlityId
              : null,
            employeeIdentificationDate: value?.employeeIdentification_date
              ? dayjs(value?.employeeIdentification_date)
              : null,
            employeeIdentification_city: value?.employeeIdentification_city
              ? value.employeeIdentification_city
              : null,
            employeeStatusResidence:
              value?.contractType == 'Không cư trú' ? 0 : 1,
          })
        }}
      />
      <PopupSaveTaxpayer
        form={formTaxpayers}
        visible={visibleAddTaxpaper}
        setVisible={(value) => setVisibleAddTaxpaper(value)}
        taxpayerSelect={taxpayerSelect}
        setTaxpayerSelect={(value) => {
          setTaxpayerSelect(value)
        }}
        onSave={(value) => {
          checkListIdentify(value)
          setVisibleAddTaxpaper(false)
          form.setFieldsValue({
            taxpayersName: value?.taxpayersName,
            employeeTaxCode: value?.employeeTaxCode,
            employeePhone: value?.employeePhone,
            employeeIdentification: value?.employeeIdentification,
            employeeNationality: value?.employeeIdentification_nationlityId
              ? value.employeeIdentification_nationlityId
              : null,
            employeeIdentificationDate: value?.employeeIdentification_date
              ? dayjs(value?.employeeIdentification_date)
              : null,
            employeeIdentification_city: value?.employeeIdentification_city
              ? value.employeeIdentification_city
              : null,
            employeeStatusResidence:
              value?.contractType == 'Không cư trú' ? 0 : 1,
          })
        }}
      />
    </>
  )
}
export default AddAccountingDocuments
