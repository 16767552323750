// Toast
import { Suspense, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
// router
import { Navigate, Route, Routes } from 'react-router-dom'

import AppToast from 'general/components/AppToast'
import DataCommonListener from 'general/listeners/DataCommonListener'
// router
import PrivateRoute from 'general/components/Routes/PrivateRoute'

import store from 'app/store'
import OutPutEInvoice from 'features'
import AccountListener from 'features/Account/AccountListener'
import KTPageError01 from 'general/components/OtherKeenComponents/KTPageError01'
import { injectStore } from 'general/helpers/WebSocketHelper'

import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import {
  hideNotification,
  setAppNotInstalledToolNotiModalShow,
} from 'app/appSlice'
import GuestSignInvoiceReport from 'features/Invoice/screens/GuestSignInvoiceReport'
import Search from 'features/Search'
import NotificationModal from 'general/components/Modal/NotificationModal'
import { useDispatch, useSelector } from 'react-redux'
// Load BS
require('bootstrap/dist/js/bootstrap.min')
// Load KT plugins
require('assets/plugins/ktutil')
require('assets/plugins/ktmenu')
require('assets/plugins/ktoffcanvas')
require('assets/plugins/ktcookie')
require('assets/plugins/kttoggle')
// aside
require('assets/plugins/aside/aside')
require('assets/plugins/aside/aside-menu')
require('assets/plugins/aside/aside-toggle')
// header
require('assets/plugins/header/ktheader-mobile')
require('assets/plugins/header/ktheader-topbar')
// dayjs
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import lf from 'dayjs/plugin/localizedFormat'
import quarter from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import ForgotPassword from 'features/Auth/screens/ForgotPassword'
import LoginHomeScreen from 'features/Auth/screens/LoginHomeScreen'
import ResetPassword from 'features/Auth/screens/ResetPassword'
import GuestSignTicketReport from 'features/Invoice/screens/GuestSignTicketReport'
import GuestRoute from 'general/components/Routes/GuestRoute'
import useProjectBranding from 'hooks/useProjectBranding'
import SignUpScreen from 'features/Auth/screens/SignUpScreen'
import NotInstalledSigningToolNotiModal from 'general/components/Modal/NotInstalledToolNotiModal'

// Load the timezone and UTC plugins
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(lf)
dayjs.extend(customParseFormat)
dayjs.extend(quarter)
dayjs.extend(relativeTime)
dayjs.extend(duration)

// Set the default time zone to +07:00
dayjs.tz.setDefault('Asia/Ha_Noi')
dayjs.locale('vi') // use locale globally

window.$ = window.jQuery = require('jquery')
window.moment = require('moment')
window.dayjs = dayjs

function App() {
  const dispatch = useDispatch()
  const {
    appSpinning,
    appNotificationModalShow,
    appNotificationInfo,
    appNotInstalledToolNotiModalShow,
  } = useSelector((state) => state.app)
  useEffect(() => {
    // console.log(`${sTag} did load`);
    injectStore(store)
    return () => {
      // console.log(`${sTag} will dismiss`);
    }
  }, [])

  useProjectBranding()

  return (
    <Spin
      spinning={appSpinning}
      className="vw-100 vh-100"
      style={{
        maxHeight: 'none',
        position: 'fixed',
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      }}
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/*" element={<Navigate to="/hoa-don-dau-ra" />} />

          {/* hop dong dien tu */}
          <Route
            path="/hoa-don-dau-ra/*"
            element={
              <PrivateRoute>
                <OutPutEInvoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/dang-nhap"
            element={<Navigate to={'/auth/dang-nhap'} replace />}
          />
          <Route path="/auth" element={<GuestRoute />}>
            <Route index element={<Navigate to={'dang-nhap'} replace />} />
            <Route path="dang-nhap" element={<LoginHomeScreen />} />
            <Route path="quen-mat-khau" element={<ForgotPassword />} />
            <Route path="dat-lai-mat-khau" element={<ResetPassword />} />
            <Route path="dang-ky/*" element={<SignUpScreen />} />
          </Route>

          <Route path="/tra-cuu" element={<Search />} />

          <Route
            path="/bien-ban-huy/ky/:invoiceId"
            element={<GuestSignInvoiceReport />}
          />

          <Route
            path="/bien-ban-dieu-chinh/ky/:invoiceId"
            element={<GuestSignInvoiceReport />}
          />

          <Route
            path="/tem-ve/bien-ban-huy/ky/:ticketId"
            element={<GuestSignTicketReport />}
          />

          <Route
            path="/tem-ve/bien-ban-dieu-chinh/ky/:ticketId"
            element={<GuestSignTicketReport />}
          />

          <Route path="*" element={<KTPageError01 />} />
        </Routes>
      </Suspense>
      {/* Toast */}
      <AppToast />
      {/* Notification modal */}
      <NotificationModal
        title={appNotificationInfo?.title}
        describeText={appNotificationInfo?.describeText}
        icon={appNotificationInfo?.icon}
        show={appNotificationModalShow}
        onClose={() => dispatch(hideNotification())}
      />
      {/* NotInstalledToolNoti modal */}
      <NotInstalledSigningToolNotiModal
        show={appNotInstalledToolNotiModalShow}
        onClose={() => dispatch(setAppNotInstalledToolNotiModalShow(false))}
      />
      {/* Listener */}
      <DataCommonListener />
      <AccountListener />
    </Spin>
  )
}

export default App
