import userApi from 'api/userApi'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ToastHelper from 'general/helpers/ToastHelper'

export const getAllUsers = createAsyncThunk(
  'getAll/users',
  async (params, thunkApi) => {
    const response = await userApi.getAll(params)
    return response // Trả về dữ liệu từ API
  },
)

export const getUserById = createAsyncThunk(
  'getUserById',
  async (params, thunkApi) => {
    const response = await userApi.getUserById()
    return response
  },
)

export const createUser = createAsyncThunk(
  'createUser',
  async (query, thunkApi) => {
    const response = await userApi.create(query)
    ToastHelper.showSuccess('Thêm người dùng thành công')
    return response
  },
)

export const updateUser = createAsyncThunk(
  'updateUser',
  async (query, thunkApi) => {
    const response = await userApi.update(query)
    return response
  },
)

export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (params, thunkApi) => {
    console.log(params)
    const res = await userApi.delete(params)
    ToastHelper.showSuccess('Xóa dùng thành công')
    return res
  },
)

const userSlice = createSlice({
  name: 'userList',
  initialState: {
    user: {},
    users: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload
      state.loading = false
      state.error = null
    })
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getUserById.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.user = action.payload
      state.loading = false
      state.error = null
    })
    builder.addCase(getUserById.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(createUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.users.accounts.push(action.payload.account)
      state.loading = false
      state.error = null
    })
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const updatedUser = action.payload.account
      let index = -1
      index = state.users.accounts.findIndex(
        (item) => item.accountId == updatedUser.accountId,
      )
      // console.log('index', index);
      // console.log('updatedUser', updatedUser);
      if (index !== -1) {
        state.users.accounts[index] = updatedUser
      }
      state.loading = false
      state.error = null
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      // console.log(action.payload);
      const deletedUserId = action.payload.accountId
      state.users.accounts = state.users.accounts.filter(
        (item) => item.accountId != deletedUserId,
      )
      state.loading = false
      state.error = null
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

const { actions, reducer } = userSlice
export default reducer
