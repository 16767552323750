const TemplateInvoiceHelper = {
  regionConfigsGroupByMergeFieldFunc: (regionConfigs) => {
    let groupObj = regionConfigs?.reduce((group, reConf) => {
      const { mergeField } = reConf
      group[mergeField] = group[mergeField] ?? []
      group[mergeField].push(reConf)

      return group
    }, [])

    let result = []
    for (let i in groupObj) {
      result.push([i, groupObj[i]])
    }
    return result
    // return Object.entries(groupObj ?? {});
  },

  regionConfigsGroupByMergeFieldFunc2: (regionConfigs) => {
    let mergeFieldIndexObj = {}
    let groupArr = regionConfigs?.reduce((group, reConf, index) => {
      const { mergeField } = reConf
      if (mergeField) {
        mergeFieldIndexObj[mergeField] = mergeFieldIndexObj[mergeField] ?? index
        let mergeFieldIndex = mergeFieldIndexObj[mergeField]

        group[mergeFieldIndex] = group[mergeFieldIndex] ?? [mergeField]
        group[mergeFieldIndex].push(reConf)
      } else {
        group.push(['', reConf])
      }

      return group
    }, [])

    return [...groupArr]?.filter((item) => item)
  },

  widthBaseOnPaperSize: (paperSize) => {
    switch (paperSize) {
      case 'A4':
        return '595.35pt'
      case 'A5':
        return '595.28pt'
      case 'POS':
      default:
        return '240pt'
    }
  },

  heightBaseOnPaperSize: (paperSize) => {
    switch (paperSize) {
      case 'A4':
        return '841.89pt'
      case 'A5':
        return '419.53pt'
      case 'POS':
      default:
        return 'auto'
    }
  },

  displayForm: (form, type) => {
    switch (form) {
      case 1:
        if (type === 'M') return 'Hóa đơn GTGT khởi tạo từ MTT'
        return 'Hóa đơn GTGT'
      case 2:
        if (type === 'M') return 'Hóa đơn bán hàng khởi tạo từ MTT'
        return 'Hóa đơn bán hàng'
      case 3:
        return 'Hóa đơn GTGT khởi tạo từ MTT'
      case 4:
        return 'Hóa đơn bán hàng khởi tạo từ MTT'
      case 5:
        return 'PXK kiêm vận chuyển nội bộ'
      case 6:
        if (type === 'N') return 'PXK kiêm vận chuyển nội bộ'
        else if (type === 'B') return 'PXK hàng gửi bán đại lý'
    }
  },

  displayType: (type) => {
    switch (type) {
      case 1:
        return 'Hóa đơn GTGT'
      case 2:
        return 'Hóa đơn bán hàng'
      case 3:
        return 'Hóa đơn GTGT khởi tạo từ MTT'
      case 4:
        return 'Hóa đơn bán hàng khởi tạo từ MTT'
      case 5:
        return 'Hóa đơn bán tài sản công'
      case 6:
        return 'Chứng từ khác'
      case 7:
        return 'Hoá đơn bán hàng dự trữ quốc gia'
      // case 7:
      //   return 'Hóa đơn khác'
      case 8:
        return 'PXK kiêm vận chuyển nội bộ'
      case 9:
        return 'PXK hàng gửi bán đại lý'
    }
  },

  displayTypeSymbol: (type) => {
    switch (type) {
      case 1:
        return 'GT'
      case 2:
        return 'BH'
      case 3:
        return 'GTMTT'
      case 4:
        return 'BHMTT'
      case 5:
        return 'VCNB'
      case 6:
        return 'HGDL'
      case 8:
        return 'VCNB'
      case 9:
        return 'HGDL'
    }
  },

  displayDirectTranfer: (directTransfer) => {
    switch (directTransfer) {
      case 1:
        return 'Chuyển đầy đủ nội dung hóa đơn'
      case 2:
        return 'Chuyển theo bảng tổng hợp (Xăng dầu)'
      case 3:
        return 'Chuyển theo bảng tổng hợp (Khác)'
      default:
        return ''
    }
  },

  displayTaxType: (type) => {
    switch (type) {
      case 1:
      case '1':
        return 'Một thuế suất'
      case 2:
      case '2':
        return 'Nhiều thuế suất'
      case 3:
      case '3':
        return 'Gộp thuế suất'
      default:
        return ''
    }
  },

  displayTypeTicket: (type) => {
    switch (type) {
      case 1:
        return 'Vé điện tử'
      case 2:
        return 'Vé điện tử từ máy tính tiền'
    }
  },

  displayTypeDeduct: (type) => {
    switch (type) {
      case 'KT':
        return 'Khấu trừ'
      case 'TT':
        return 'Trực tiếp'
    }
  },

  displayTypeServices: (type) => {
    switch (type) {
      case 'GT':
        return 'Vé dịch vụ'
      case 'CI':
        return 'Vé công ích'
      case 'VT':
        return 'Vé vận tải hành khách'
      case 'GX':
        return 'Vé gửi xe'
    }
  },

  displayTypePrice: (type) => {
    switch (type + '') {
      case '1':
        return 'In sẵn mệnh giá'
      case '2':
        return 'Không in sẵn mệnh giá'
    }
  },

  typeTicketToSymbol: (type) => {
    switch (type) {
      case 1:
        return 'VE'
      case 2:
        return 'VEMTT'
    }
  },

  generateSerial: (template) =>
    template.form +
    template.hasTaCode +
    template.year +
    template.type +
    template.managementCode,
}

export const fieldAlignConvert = (fieldAlign) => {
  switch (fieldAlign) {
    case 'start':
      return 'left'
    case 'center':
      return 'center'
    case 'end':
      return 'right'
  }
}

export default TemplateInvoiceHelper
