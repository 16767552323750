import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Space, Spin } from 'antd'

/**
 *
 * @param {Object} props
 * @param {string} props.message
 * @param {import('antd').SpinProps['size']} props.size
 * @param {'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'light' | 'dark'} props.variant
 * @returns {JSX.Element} - A loading component with a custom message.
 */
function LoadingCustom(props) {
  const { message, size, variant } = props
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <Space>
        <Spin
          size={size}
          indicator={
            <Loading3QuartersOutlined spin className={'text-' + variant} />
          }
        />
        {message}
      </Space>
    </div>
  )
}

export default LoadingCustom
