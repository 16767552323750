import axiosClient from './axiosClient'

const prefixPath = '/route'
const routeApi = {
  createNewRoute: (params) => axiosClient.post(`${prefixPath}/create`, params),
  updateRoute: (params) =>
    axiosClient.put(`${prefixPath}/update/${params.routeId}`, params),
  getListOfRoute: (params) => axiosClient.get(`${prefixPath}/find`, { params }),
  getDetailInfoRoute: (params) =>
    axiosClient.get(`${prefixPath}/detail/${params}`),
  deleleRoute: (data) => axiosClient.delete(`${prefixPath}/delete`, { data }),
}

export default routeApi
