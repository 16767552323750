import { Space, Typography } from 'antd'
import { useFormik } from 'formik'
import Tagnify from 'general/components/CustomTag/Tagnify'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { generateSchema } from 'general/utils/YupSchema/ListIssuedTicket'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {function} props.onOpenChange
 * @param {function} props.onApply
 * @param {string} props.title
 */
export default function ModalSendBulkTicket(props) {
  const { onOpenChange, open, onApply, title } = props
  const { t } = useTranslation()
  const modalRef = useRef(null)
  const {
    handleReset,
    handleSubmit,
    resetForm,
    submitForm,
    getFieldProps,
    errors,
    touched,
    setFieldValue,
    values,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    enableReinitialize: false,
    validationSchema: generateSchema(t).sendEmail,
    onSubmit: onApply,
  })

  useEffect(() => {
    if (open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <DraggableModal
      isOpen={open}
      onOpenChange={onOpenChange}
      title={title}
      width={600}
      applyText="Gửi"
      ref={modalRef}
      handleApplyChanges={submitForm}
    >
      <form onReset={handleReset} onSubmit={handleSubmit}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Typography.Text strong>Tên người nhận</Typography.Text>
            <CustomAntInput
              type="text"
              borderRadius="sm"
              inputProps={{
                ...getFieldProps('name'),
                placeholder: 'Nhập tên người nhận',
              }}
            />
            <Typography.Text type="danger">
              {errors.name && touched.name ? errors.name : ''}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text strong>
              Email
              <span className="ml-1 text-danger">*</span>
            </Typography.Text>
            <Tagnify
              delimiterString=";"
              pillColor="primary"
              name="email"
              setFieldValue={setFieldValue}
              placeholder="Các mail cách nhau bởi dấu chấm phẩy"
              defaultValue={values.email}
              restriction={{
                type: 'text',
                validationSchema: generateSchema(t).sendEmail.fields.email,
              }}
            />
            <Typography.Text type="danger">
              {errors.email && touched.email ? errors.email : ''}
            </Typography.Text>
          </div>
        </Space>
      </form>
    </DraggableModal>
  )
}
