import { clone } from 'lodash'
import axiosClient from './axiosClient'

const taxAuthorityMessageApi = {
  exportPdfMessage102: (messageId) => {
    const url = `/tax-message/message102/export/${messageId}`

    return axiosClient.get(url, { responseType: 'blob' })
  },
  exportPdfMessage103: (messageId) => {
    const url = `/tax-message/message103/export/${messageId}`

    return axiosClient.get(url, { responseType: 'blob' })
  },
  exportPdfMessage301: (messageId) => {
    const url = `/tax-message/message301/export/${messageId}`

    return axiosClient.get(url, { responseType: 'blob' })
  },
  listInvoiceMessage: (params, signal) => {
    const url = '/tax-message/invoice/list'
    return axiosClient.get(url, { params, signal })
  },
  listTicketMessage: (params, signal) => {
    const url = '/tax-message/ticket/list'
    return axiosClient.get(url, { params, signal })
  },
  listErrAnnouceMessage: (params) => {
    const url = '/tax-message/err-annouce/list'
    return axiosClient.get(url, { params })
  },
  listRegistrationMessage: (params) => {
    const clonedParams = clone(params)
    clonedParams.page =
      clonedParams.page === 0 ? clonedParams.page : clonedParams.page - 1
    const url = '/tax-message/registration/list'
    return axiosClient.get(url, { params: clonedParams })
  },
}

export default taxAuthorityMessageApi
