import { Navigate, Route, Routes } from 'react-router-dom'

// import ListRegistration from './ListRegistration';
import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import CreateInvoice from './screens/CreateInvoice'

import ListInvoice from './screens/ListInvoice'

import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CreateInvoiceReport from './screens/CreateInvoiceReport'
import ListInvoiceFromPOS from './screens/ListInvoiceFromPOS'
import TransactionsScreen from './screens/ListTransactions'
import ListGoodsDeliveryNote from './screens/ListGoodsDeliveryNote'
import useCheckPermission from 'hooks/useCheckPermission'
import { env } from 'general/env/env'

function Invoice() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const {
    isViewListInvoice,
    isCreateInvoice,
    isEditInvoice,
    isLoadingPermission,
  } = useCheckPermission([
    {
      keyAction: 'isViewListInvoice',
      permissionCode: 'INVOICE_VIEW_LIST',
    },
    {
      keyAction: 'isCreateInvoice',
      permissionCode: 'INVOICE_ADD_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'isEditInvoice',
      permissionCode: 'INVOICE_EDIT_ORIGINAL_INVOICE',
    },
  ])
  return (
    <Routes>
      <Route path="" element={<Navigate to="danh-sach-hoa-don" />} />
      <Route
        path={'them-moi'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isCreateInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path={'may-tinh-tien/them-moi'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isCreateInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path={'may-tinh-tien/them-moi/:invoiceId'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isCreateInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path={'may-tinh-tien/cap-nhat/:invoiceId'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isEditInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path={'them-moi/:invoiceId'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isCreateInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />
      <Route
        path={'cap-nhat/:invoiceId'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isEditInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path="may-tinh-tien/"
        element={<Navigate to="danh-sach-hoa-don" />}
      />

      <Route
        path="may-tinh-tien/danh-sach-hoa-don"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isCreateInvoice}
          >
            <ListInvoiceFromPOS />
          </ProtectedRoute>
        }
      />

      <Route
        path={'danh-sach-hoa-don'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isViewListInvoice}
          >
            <ListInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path="phieu-xuat-kho"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isViewListInvoice}
          >
            <ListGoodsDeliveryNote />
          </ProtectedRoute>
        }
      />

      <Route
        path={'/phieu-xuat-kho/them-moi'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isEditInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path={'phieu-xuat-kho/them-moi/:invoiceId'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isCreateInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      <Route
        path={'/phieu-xuat-kho/cap-nhat/:invoiceId'}
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isEditInvoice}
          >
            <CreateInvoice />
          </ProtectedRoute>
        }
      />

      {process.env.REACT_APP_PROJECT_TYPE === 'PETRO' && (
        <Route
          path="/danh-sach-log-bom"
          element={
            <ProtectedRoute isLoading={isLoadingPermission}>
              <TransactionsScreen />
            </ProtectedRoute>
          }
        />
      )}

      <Route
        path={'bien-ban-huy/them-moi/:invoiceId'}
        element={<CreateInvoiceReport />}
      />
      <Route
        path={'bien-ban-huy/cap-nhat/:invoiceId'}
        element={<CreateInvoiceReport />}
      />
      <Route
        path={'bien-ban-huy/xem/:invoiceId'}
        element={<CreateInvoiceReport />}
      />
      <Route
        path={'bien-ban-dieu-chinh/them-moi/:invoiceId'}
        element={<CreateInvoiceReport />}
      />
      <Route
        path={'bien-ban-dieu-chinh/cap-nhat/:invoiceId'}
        element={<CreateInvoiceReport />}
      />
      <Route
        path={'bien-ban-dieu-chinh/xem/:invoiceId'}
        element={<CreateInvoiceReport />}
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Invoice
