import BaseTextField from 'general/components/Forms/BaseTextField'
import FormLabel from 'general/components/Forms/FormLabel'
import InputNumberNoFormik from 'general/components/Forms/InputNumerNoFormik'
import _ from 'lodash'

function TableHospitalInfo(props) {
  const { formik } = props
  console.log(formik)

  return (
    <div className="row mt-5">
      <div className="row">
        <div className="col-3">
          <FormLabel maxWidth="250px">Số tiền đã tạm ứng: </FormLabel>
        </div>
        <div className="col-3">
          <InputNumberNoFormik
            systemConfigType="totalCost"
            onChange={(e) => {
              formik.setFieldValue('advanceAmount', e.target.value)
            }}
            value={formik?.values?.advanceAmount}
            textAlign={'center'}
          />
        </div>
        <div className="col-3">
          <FormLabel maxWidth="250px">
            Số tiền bệnh nhân được miễn giảm:
          </FormLabel>
        </div>
        <div className="col-3">
          <InputNumberNoFormik
            systemConfigType="totalCost"
            onChange={(e) => {
              formik.setFieldValue('patientExemptedAmount', e.target.value)
            }}
            value={formik?.values?.patientExemptedAmount}
            textAlign={'center'}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <FormLabel maxWidth="250px">Số tiền bệnh viện trả lại: </FormLabel>
        </div>
        <div className="col-3">
          {/* <BaseTextField
            className=""
            name="hospitalReturnAmount"
            fieldProps={formik.getFieldProps('hospitalReturnAmount')}
            fieldMeta={formik.getFieldMeta('hospitalReturnAmount')}
            fieldHelper={formik.getFieldHelpers('hospitalReturnAmount')}
          /> */}
          <InputNumberNoFormik
            systemConfigType="totalCost"
            onChange={(e) => {
              formik.setFieldValue('hospitalReturnAmount', e.target.value)
            }}
            value={formik?.values?.hospitalReturnAmount}
            textAlign={'center'}
          />
        </div>
        <div className="col-3">
          <FormLabel maxWidth="250px">Số tiền bệnh nhân nộp thêm:</FormLabel>
        </div>
        <div className="col-3">
          <InputNumberNoFormik
            systemConfigType="totalCost"
            onChange={(e) => {
              formik.setFieldValue(
                'patientPaidAdditionalAmount',
                e.target.value,
              )
            }}
            value={formik?.values?.patientPaidAdditionalAmount}
            textAlign={'center'}
          />
        </div>
      </div>
    </div>
  )
}

export default TableHospitalInfo
