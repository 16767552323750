import {
  setAppNotInstalledToolNotiModalShow,
  setAppSpinning,
  showNotification,
} from 'app/appSlice'
import CancelInvoiceModal from 'general/components/Modal/CancelInvoiceModal'
// import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import { List, Space, Tooltip } from 'antd'
import invoiceApi from 'api/invoiceApi'
import dayjs from 'dayjs'
import {
  setClearRows,
  setModalsState,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import ModalConvertPaper from 'features/Search/components/ModalConvertPaper'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import ExplanationInvoiceModal from 'general/components/Modal/ExplanationInvoiceModal'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ModalSelectAdjustInvoiceMethod from 'general/components/Modal/ModalSelectAdjustInvoiceMethod'
import ModalSelectCancellationMethod from 'general/components/Modal/ModalSelectCancellationMethod'
import ModalSendEmail from 'general/components/Modal/ModalSendEmail'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import ModalUploadInvoiceExcel from 'general/components/Modal/ModalUploadInvoiceExcel'
import SendInvoiceModal from 'general/components/Modal/SendInvoiceModal'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import {
  INVOICE_STATUS,
  INVOICE_TYPE,
  MESSAGE_SUCCESS,
} from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useGetSerials from 'hooks/Queries/useGetSerials'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import InvoiceDashboard from './Components/InvoiceDashboard'
import {
  useMutationBulkInvoicePaidStatus,
  useMutationCancelInvoice,
  useMutationCheckMessage,
  useMutationDeleteInvoice,
  useMutationDownloadInvoice,
  useMutationDownloadPdf,
  useMutationDownloadXml,
  useMutationGetBulkMessage,
  useMutationHsmSigning,
  useMutationInvoicePaidStatus,
  useMutationPrintInvoice,
  useMutationResendIssue,
  useMutationSendExplanationAnnounce,
  useMutationViewPdf,
  useQueryListInvoice,
} from './queries'
import './style.scss'
import { useQuery } from '@tanstack/react-query'
import { goodsQueries } from 'features/Category/screens/GoodsScreen/queries'

function ListInvoice() {
  // MARK --- Parmas: ---
  const { t } = useTranslation()
  const modalSentMailRef = useRef(null)
  const { navigate } = useRouter()
  const dispatch = useAppDispatch()

  const [hsmSignModalConfig, setHSMSignModalConfig] = useState({
    show: false,
    invoiceId: null,
  })

  const [reIssueModalConfig, setReIssueModalConfig] = useState({
    show: false,
    invoiceId: null,
  })

  const [reSignHSMModalConfig, setReSignHSMModalConfig] = useState({
    show: false,
    invoiceId: null,
  })

  const [restoreOriginModalConfig, setRestoreOriginModalConfig] = useState({
    show: false,
    invoiceId: null,
  })

  const [convertPaperModalConfig, setConvertPaperModalConfig] = useState({
    show: false,
    lookupCode: null,
  })

  const { listFunctions, userFunctions } = useAppSelector(
    (state) => state?.function,
    shallowEqual,
  )

  const { activeSigningMethod } = useAppSelector(
    (state) => state.system.company,
  )

  const { company } = useAppSelector((state) => state?.system)
  const { selectedInvoices, toggledClearRows, modalStates } = useAppSelector(
    (state) => state.invoice,
    shallowEqual,
  )

  const [
    isCreateInvoice,
    isEditInvoice,
    isDeleteInvoice,
    isReleaseOriginalInvoice,
    isSendInvoiceToCustomer,
    isDownloadInvoice,
    isCreateAdjustedInvoice,
    isCreateReplacementInvoice,
    isCreateCancelInvoice,
  ] = useMemo(() => {
    return Utils.checkFunction(
      [
        { listFunctionCode: 'INVOICE_ADD_ORIGINAL_INVOICE' },
        { listFunctionCode: 'INVOICE_EDIT_ORIGINAL_INVOICE' },
        { listFunctionCode: 'INVOICE_DELETE_ORIGINAL_INVOICE' },
        { listFunctionCode: 'INVOICE_RELEASE_ORIGINAL_INVOICE' },
        { listFunctionCode: 'INVOICE_SEND_INVOICE_TO_CUSTOMER_ORIGINAL' },
        { listFunctionCode: 'INVOICE_DOWNLOAD_ORIGINAL_INVOICE' },
        { listFunctionCode: 'INVOICE_CREATE_ADJUSTED_INVOICE' },
        { listFunctionCode: 'INVOICE_CREATE_REPLACEMENT_INVOICE' },
        { listFunctionCode: 'INVOICE_CREATE_CANCEL_INVOICE' },
      ],
      userFunctions,
      listFunctions,
    )
  }, [userFunctions, listFunctions])

  const { haveCanDownloadInvoice, allCanSignInvoice, allCanDownloadInvoice } =
    useMemo(() => {
      const canDownloadInvoice = selectedInvoices?.some((invoice) => {
        return (
          (invoice.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
            invoice.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT) &&
          invoice.type !== INVOICE_TYPE.CANCELLED &&
          invoice.type !== INVOICE_TYPE.REPLACED &&
          invoice.type !== INVOICE_TYPE.DELETED
        )
      })
      const canAllSignInvoice = selectedInvoices?.some((invoice) => {
        return [
          INVOICE_STATUS.SEND_ERROR,
          INVOICE_STATUS.SIGN_ERROR,
          INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
        ].includes(invoice.issueStatus)
      })
      const canAllDownloadInvoice = !selectedInvoices?.some((invoice) => {
        return (
          (invoice.issueStatus !== INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
            invoice.issueStatus !== INVOICE_STATUS.AUTHORITY_ACCEPT) ||
          invoice.type === INVOICE_TYPE.CANCELLED ||
          invoice.type === INVOICE_TYPE.REPLACED
        )
      })

      return {
        haveCanDownloadInvoice: canDownloadInvoice,
        allCanSignInvoice: canAllSignInvoice,
        allCanDownloadInvoice: canAllDownloadInvoice,
      }
    }, [selectedInvoices])

  const [filterParams, setFilterParams] = useFilter(
    'invoiceList',
    'gFiltersInvoiceList',
  )

  const [activeInvoice, setActiveInvoice] = useState(null)
  const [isInvoiceAdjust, setIsInvoiceAdjust] = useState(false)

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [goodsFilterParams] = useState({
    status: 'ACTIVE',
    page: 0,
    limit: 50,
  })
  //#region --- Queries, Mutate ---
  const { data: serials } = useGetSerials()
  const {
    invoiceListData,
    isGettingListData,
    isRefetching,
    refetchListInvoice,
  } = useQueryListInvoice(debouncedSearch, filterParams)

  const { data: goodsOption } = useQuery({
    ...goodsQueries.listOfGoods(goodsFilterParams),
    select: (res) => {
      return _.concat(
        { value: null, label: 'Tất cả hàng hóa' },
        res?.goods,
      ).map((v) => ({
        label: v.label ?? v.name,
        value: v.name ?? v.value,
      }))
    },
  })

  const { mutate: updateInvoicePaidStatus } = useMutationInvoicePaidStatus()
  const { mutate: updateBulkInvoicePaidStatus } =
    useMutationBulkInvoicePaidStatus()
  const { mutate: downloadPdf } = useMutationDownloadPdf()
  const { downloadXml } = useMutationDownloadXml()
  const { mutate: hsmSignInvoiceMutation } = useMutationHsmSigning()
  // cancel là huỷ hoá đơn đã gửi thuế
  const { mutate: cancelInvoice } = useMutationCancelInvoice()
  // explanation là giải trình hoá đơn đã gửi thuế
  const { mutate: sendExplanationAnnounceToCustomer } =
    useMutationSendExplanationAnnounce()

  const { mutate: downloadInvoices } = useMutationDownloadInvoice()

  const { mutate: printInvoices } = useMutationPrintInvoice()

  const { mutate: deleteInvoiceMutation } = useMutationDeleteInvoice()

  const { mutate: fetchTaxAuthorityMessage } = useMutationCheckMessage()

  const { mutate: viewPdfInvoice } = useMutationViewPdf()

  const { mutate: checkMessages, isPending: checkingBulkMessage } =
    useMutationGetBulkMessage()

  const { mutate: resendInvoices } = useMutationResendIssue()

  //#endregion

  const signInvoices = async (passedInvoices) => {
    dispatch(setAppSpinning(true))
    let invoicesToSign = passedInvoices

    invoicesToSign = Array.isArray(invoicesToSign)
      ? invoicesToSign
      : [invoicesToSign]
    try {
      const invoiceIdsToSign = invoicesToSign
        .filter((invoice) =>
          [
            INVOICE_STATUS.SEND_ERROR,
            INVOICE_STATUS.SIGN_ERROR,
            INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
          ].includes(invoice.issueStatus),
        )
        .map((invoice) => invoice.invoiceId)
        .sort((a, b) => a - b)
      if (invoiceIdsToSign.length === 0) {
        ToastHelper.showError('Các hoá đơn đã chọn không thể phát hành')

        dispatch(setAppSpinning(false))
        return
      }

      if (activeSigningMethod == 'HSM') {
        hsmSignInvoiceMutation(invoiceIdsToSign)
      } else {
        let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
        let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},`
        invoiceIdsToSign.map((invoiceId, index) => {
          if (index + 1 === invoiceIdsToSign.length) {
            urlSchema = urlSchema + `${invoiceId}`
          } else {
            urlSchema = urlSchema + `${invoiceId};`
          }
        })
        Utils.openToolSignInvoice(urlSchema, accessToken, () =>
          dispatch(setAppNotInstalledToolNotiModalShow(true)),
        )
      }
    } catch (err) {
      console.log(err)
      ToastHelper.showError('Có lỗi xảy ra khi tải')
    } finally {
      dispatch(setAppSpinning(false))
      dispatch(
        setModalsState({
          modalName: 'sign',
          status: false,
        }),
      )
    }
  }

  const renderInvoiceType = (type) => {
    const { color, text } = InvoiceHelper.renderInvoiceType(type)
    return (
      <div className={`badge badge-light-${color}`}>{_.upperFirst(text)}</div>
    )
  }

  const handleConvertPaper = async (converterName) => {
    dispatch(setAppSpinning(true))
    var res
    try {
      res = await invoiceApi.convertPaper({
        lookupCode: convertPaperModalConfig.lookupCode,
        converterName,
      })
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }
    let newFile = new File([res], 'Hóa đơn chuyển đổi.pdf', {
      type: 'application/pdf',
    })
    var fileURL = URL.createObjectURL(newFile)
    window.open(fileURL, '_blank')
  }

  const renderIssueStatus = useCallback(
    (invoice) => {
      let { issueStatus } = invoice
      switch (issueStatus) {
        case INVOICE_STATUS.AUTHORITY_CODE_GIVEN:
          return <div className="badge badge-light-primary">Đã cấp mã</div>
        case INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN:
          return (
            <Tooltip title="Xem lý do từ chối">
              <div
                className="badge badge-light-danger"
                onClick={() => {
                  setActiveInvoice(invoice)
                  dispatch(
                    setModalsState({
                      modalName: 'transmission',
                      status: true,
                    }),
                  )
                }}
              >
                <u>Từ chối cấp mã</u>
              </div>
            </Tooltip>
          )
        case INVOICE_STATUS.NOT_SENT_TO_AUTHORITY:
          return <div className="badge badge-light-success">Chưa phát hành</div>
        case INVOICE_STATUS.SENDING_TAX:
          return <div className="badge badge-light-warning">Đang gửi CQT</div>
        case INVOICE_STATUS.WAITING_FOR_AUTHORITY:
          return <div className="badge badge-light-warning">Chờ CQT xử lý</div>
        case INVOICE_STATUS.AUTHORITY_ACCEPT:
          return (
            <div className="badge badge-light-primary">CQT xác nhận hợp lệ</div>
          )
        case INVOICE_STATUS.AUTHORITY_DENY:
          return <div className="badge badge-light-danger">CQT từ chối</div>
        case INVOICE_STATUS.SEND_ERROR:
          return <div className="badge badge-light-danger">Gửi lỗi</div>
        case INVOICE_STATUS.SIGN_ERROR:
          return <div className="badge badge-light-danger">Ký lỗi</div>
        default:
        // return <div className="label label-lg label-outline-primary label-inline">Hoá đơn gốc</div>;
      }
    },
    [dispatch],
  )

  const renderPayment = (isPayment) => {
    switch (isPayment) {
      case null:
        return <div className="badge badge-light-danger">Chưa thanh toán</div>
      case false:
        return <div className="badge badge-light-danger">Chưa thanh toán</div>
      case true:
        return <div className="badge badge-light-success">Đã thanh toán</div>
      default:
    }
  }

  const defaultValues = useMemo(() => {
    /** @type {Array<FilterDefaultValueProp>} */
    const arr = [
      {
        label: 'Ký hiệu',
        alias: 'Ký hiệu',
        formName: 'serials',
        options: serials,
        value: _.isEmpty(serials) ? null : serials[0]?.value ?? null,
        multiselect: true,
        colSpan: 'FULL',
      },
      {
        label: ['Hoá đơn từ số', 'Đến số'],
        formName: ['fromNumberInvoice', 'toNumberInvoice'],
        alias: 'Số hoá đơn: Từ',
        separator: 'đến',
        value: [null, null],
        restrict: {
          type: 'number',
          min: 1,
          max: 99999999,
        },
      },
      {
        label: 'Trạng thái phát hành',
        alias: 'Trạng thái HĐ',
        formName: 'issueStatus',
        options: SelectOptions.issueStatus,
        value: null,
      },
      {
        label: 'Loại hoá đơn',
        alias: 'Loại HĐ',
        formName: 'types',
        options: SelectOptions.invoiceType,
        value: null,
      },
      {
        label: 'Trạng thái gửi hoá đơn',
        alias: 'Trạng thái gửi HĐ',
        formName: 'isSent',
        options: SelectOptions.isSent,
        value: SelectOptions.isSent[0].value,
      },
      {
        label: 'Tên hàng hóa',
        alias: 'Hàng hóa',
        formName: 'goodsName',
        options: goodsOption,
        value: null,
      },
    ]
    return arr
  }, [goodsOption, serials])

  const issueInvoiceHandler = useCallback(
    async (row, invoiceIssueStatus) => {
      if (isReleaseOriginalInvoice) {
        switch (invoiceIssueStatus) {
          case INVOICE_STATUS.NOT_SENT_TO_AUTHORITY:
            if (activeSigningMethod == 'HSM') {
              // hsmSignInvoiceMutation(row?.invoiceId)
              setHSMSignModalConfig({
                show: true,
                invoiceId: row?.invoiceId,
              })
            } else {
              let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
              const urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},${row?.invoiceId}`
              Utils.openToolSignInvoice(urlSchema, accessToken, () =>
                dispatch(setAppNotInstalledToolNotiModalShow(true)),
              )
            }
            break
          case INVOICE_STATUS.SEND_ERROR:
            setReIssueModalConfig({
              show: true,
              invoiceId: row?.invoiceId,
            })

            break
          case INVOICE_STATUS.SIGN_ERROR:
            if (activeSigningMethod == 'HSM') {
              setReSignHSMModalConfig({
                show: true,
                invoiceId: row?.invoiceId,
              })
            } else {
              alert('Chưa xử lý trạng thái ký lỗi usb_token')
            }
            break
        }
      }
      if (!isReleaseOriginalInvoice)
        dispatch(
          setModalsState({
            modalName: 'noRight',
            status: true,
          }),
        )
    },
    [activeSigningMethod, company?.taxCode, dispatch, isReleaseOriginalInvoice],
  )

  const reIssueInvoice = async () => {
    dispatch(setAppSpinning(true))

    try {
      let res = await invoiceApi.reIssue({
        invoiceIds: [reIssueModalConfig.invoiceId],
      })
      dispatch(setAppSpinning(false))

      if (res?.data) res = res.data
      if (
        !!res?.reIssueSuccessInvoices?.find(
          ({ invoiceId }) => invoiceId == reIssueModalConfig.invoiceId,
        )
      ) {
        ToastHelper.showSuccess(MESSAGE_SUCCESS.RE_ISSUED_INVOICE)
        // reload page
        refetchListInvoice()
        return
      }
      // Phát hành lại thất bại
      ToastHelper.showError(res?.reIssueErrorInvoices?.at(0)?.reason)
      refetchListInvoice()
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  const reSignHSM = async () => {
    dispatch(setAppSpinning(true))

    try {
      let res = await invoiceApi.reSignHSM({
        invoiceIds: [reSignHSMModalConfig.invoiceId],
      })
      dispatch(setAppSpinning(false))

      if (res?.data) res = res.data
      if (
        !!res?.successSignInvoices?.find(
          ({ invoiceId }) => invoiceId == reSignHSMModalConfig.invoiceId,
        )
      ) {
        ToastHelper.showSuccess(MESSAGE_SUCCESS.RE_ISSUED_INVOICE)
        // reload page
        refetchListInvoice()
        return
      }
      // Phát hành lại thất bại
      ToastHelper.showError(res?.errorSignInvoices?.at(0)?.reason)
      refetchListInvoice()
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  const restoreOrigin = async () => {
    dispatch(setAppSpinning(true))
    try {
      let res = await invoiceApi.restoreToOrigin(
        restoreOriginModalConfig.invoiceId,
      )
      dispatch(setAppSpinning(false))

      if (res?.result == 'success') {
        ToastHelper.showSuccess('Khôi phục hóa đơn thành công')
        // reload page
        refetchListInvoice()
        return
      }
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  const defaultColumns = useMemo(
    () => [
      {
        id: 'serial',
        name: 'Ký hiệu',
        minWidth: '110px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        id: 'no',
        name: 'Số',
        minWidth: '110px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.no}
            </p>
          )
        },
      },
      {
        id: 'date',
        name: 'Ngày hoá đơn',
        minWidth: '150px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {dayjs(row?.date).format('L')}
            </p>
          )
        },
      },
      {
        id: 'related',
        name: 'HĐ liên kết',
        grow: 1,
        center: true,
        cell: (row) =>
          _.isEmpty(row?.Related) ? null : (
            <List
              itemLayout="vertical"
              dataSource={row?.Related}
              style={{
                padding: 0,
              }}
              renderItem={(item) =>
                item?.issueStatus !== INVOICE_STATUS.AUTHORITY_ACCEPT ||
                item?.issueStatus !==
                  INVOICE_STATUS.AUTHORITY_CODE_GIVEN ? null : (
                  <List.Item onClick={() => viewPdfInvoice(item)}>
                    <Tooltip title={'Xem hoá đơn số ' + item?.no}>
                      <p className="m-0">
                        <a href="#">{_.padStart(item?.no, 8, '0')}</a>
                      </p>
                    </Tooltip>
                  </List.Item>
                )
              }
            />
          ),
      },
      {
        id: 'customerTaxCode',
        name: 'Mã số thuế',
        selector: (rows) => rows.customerTaxCode,
        center: true,
        minWidth: '150px',
      },
      {
        id: 'customerCompanyName',
        name: 'Khách hàng',
        center: true,
        minWidth: '200px',
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'left',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.customerCompanyName ?? row?.customerName}
            </p>
          )
        },
      },
      {
        id: 'customerName',
        name: 'Tên người mua',
        center: true,
        minWidth: '150px',
        omit: false,
        selector: (rows) => rows.customerName,
      },
      {
        id: 'isPayment',
        name: 'Thanh toán',
        minWidth: '150px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <div className="w-100 align-items-center justify-content-center">
              {renderPayment(row?.isPayment)}
            </div>
          )
        },
      },
      {
        id: 'taxMoney',
        name: 'Tiền thuế',
        omit: false,
        minWidth: '120px',
        center: true,
        cell: (row) => (
          <p
            data-tag="allowRowEvents"
            style={{
              textAlign: 'right',
            }}
            className="text-dark-75 m-0 text-maxline-3 w-100"
          >
            {Utils.formatNumber(row?.taxMoney)}
          </p>
        ),
      },
      {
        id: 'total',
        name: 'Tổng tiền trước thuế',
        omit: false,
        minWidth: '120px',
        center: true,
        cell: (row) => (
          <p
            style={{
              textAlign: 'right',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 m-0 text-maxline-3 w-100"
          >
            {Utils.formatNumber(row?.total)}
          </p>
        ),
      },
      {
        id: 'totalAfterTax',
        name: 'Tổng tiền thanh toán',
        center: true,
        omit: false,
        minWidth: '120px',
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {Utils.formatNumber(row?.totalAfterTax)}
              {/* {row?.currency} */}
            </p>
          )
        },
      },
      {
        id: 'invoice-type',
        name: 'Loại hoá đơn',
        center: true,
        omit: false,
        minWidth: '120px',
        cell: (row) => {
          return (
            <div className="w-100 align-items-center justify-content-center">
              {renderInvoiceType(row?.type)}
            </div>
          )
        },
      },
      {
        id: 'issueStatus',
        name: 'Trạng thái phát hành',
        minWidth: '150px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <div className="w-100 align-items-center justify-content-center">
              {renderIssueStatus(row)}
            </div>
          )
        },
      },
      {
        id: 'taxAuthorityCode',
        name: 'Mã CQT',
        center: true,
        omit: false,
        minWidth: '24.25rem',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.taxAuthorityCode}
            </p>
          )
        },
      },
      {
        id: 'lookupCode',
        name: 'Mã tra cứu',
        center: true,
        omit: false,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.lookupCode}
            </p>
          )
        },
      },
      {
        id: 'isSent',
        name: 'Trạng thái gửi mail',
        center: true,
        cell: (row) => (
          <IconAction
            className={
              row?.isSent === 0
                ? 'fa-duotone fa-do-not-enter text-light-dark'
                : 'fa-duotone fa-circle-check'
            }
            style={
              row?.isSent !== 0
                ? {
                    '--fa-primary-color': '#fff',
                    '--fa-secondary-color': '#50cd89',
                    '--fa-secondary-opacity': 1,
                  }
                : {}
            }
          />
        ),
      },
      {
        name: 'Hành động',
        center: true,
        disabled: true,
        compact: true,
        minWidth: '200px',
        grow: 2,
        style: {
          justifyContent: 'flex-end',
        },
        cell: (row) => (
          <>
            <TableAction
              titleActionText="Xem"
              icon={<IconAction className="fa-light fa-eye" />}
              onClick={() => viewPdfInvoice(row)}
            />
            {[
              INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
              INVOICE_STATUS.SEND_ERROR,
              INVOICE_STATUS.SIGN_ERROR,
            ].includes(_.get(row, 'issueStatus')) &&
            _.get(row, 'type') !== INVOICE_TYPE.DELETED ? (
              <TableAction
                titleActionText={'Phát hành hoá đơn'}
                icon={<IconAction className="fa-light fa-paper-plane" />}
                onClick={() =>
                  issueInvoiceHandler(row, _.get(row, 'issueStatus'))
                }
              />
            ) : null}

            {_.get(row, 'issueStatus') ===
              INVOICE_STATUS.NOT_SENT_TO_AUTHORITY &&
            _.get(row, 'type') !== INVOICE_TYPE.DELETED ? (
              <TableAction
                titleActionText={t('Edit')}
                icon={<IconAction className="fa-light fa-pen-to-square" />}
                onClick={() => {
                  !isEditInvoice
                    ? dispatch(
                        setModalsState({
                          modalName: 'noRight',
                          status: true,
                        }),
                      )
                    : navigate(
                        `/hoa-don-dau-ra/hoa-don/cap-nhat/${row?.invoiceId}`,
                      )
                }}
              />
            ) : null}
            {[
              INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
              INVOICE_STATUS.SIGN_ERROR,
              INVOICE_STATUS.SEND_ERROR,
            ].includes(_.get(row, 'issueStatus')) &&
            _.get(row, 'type') !== INVOICE_TYPE.DELETED ? (
              <TableAction
                titleActionText="Xoá"
                icon={<IconAction className="fa-light fa-trash" />}
                onClick={() => {
                  const handleDeleteAction = () => {
                    setActiveInvoice(row)
                    dispatch(
                      setModalsState({
                        modalName: 'delete',
                        status: true,
                      }),
                    )
                  }
                  !isDeleteInvoice
                    ? dispatch(
                        setModalsState({
                          modalName: 'noRight',
                          status: true,
                        }),
                      )
                    : handleDeleteAction()
                }}
              />
            ) : null}
            <TableAction
              titleActionText="Gửi hoá đơn cho khách hàng"
              icon={<IconAction className="fa-light fa-envelope" />}
              onClick={() => {
                const handleSentMailAction = () => {
                  setActiveInvoice(row)
                  dispatch(
                    setModalsState({
                      modalName: 'sendDraftInvoice',
                      status: true,
                    }),
                  )
                }
                !isSendInvoiceToCustomer
                  ? dispatch(
                      setModalsState({
                        modalName: 'noRight',
                        status: true,
                      }),
                    )
                  : handleSentMailAction()
              }}
            />
            {(_.get(row, 'issueStatus') ===
              INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
              _.get(row, 'issueStatus') === INVOICE_STATUS.AUTHORITY_ACCEPT) &&
            _.get(row, 'type') === INVOICE_TYPE.CANCELLED &&
            _.get(row, 'type') === INVOICE_TYPE.REPLACED ? (
              <TableAction
                icon={<IconAction className="fa-light fa-file-download" />}
                titleActionText="Tải xuống"
                onClick={() => {
                  !isDownloadInvoice
                    ? dispatch(
                        setModalsState({
                          modalName: 'noRight',
                          status: true,
                        }),
                      )
                    : downloadInvoices({
                        invoices: [row],
                      })
                }}
              />
            ) : null}
            {/* Dropdown */}
            <TableAction
              type="dropdown"
              items={[
                row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                  row?.type !== INVOICE_TYPE.DELETED && {
                    label: 'Tải hóa đơn PDF',
                    icon: <IconAction className="fa-light fa-file-download" />,
                    onClick: () => downloadPdf(row?.invoiceId),
                  },
                row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN &&
                  row?.type !== INVOICE_TYPE.DELETED && {
                    label: 'Tải hóa đơn XML',
                    icon: <IconAction className="fa-light fa-file-code" />,
                    onClick: () => downloadXml(row?.xmlString),
                  },
                {
                  label: 'Nhân bản hóa đơn',
                  icon: <IconAction className="fa-light fa-copy" />,
                  onClick: () => {
                    if (isCreateInvoice)
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/them-moi/${row?.invoiceId}`,
                      )
                    if (!isCreateInvoice)
                      dispatch(
                        setModalsState({
                          modalName: 'noRight',
                          status: true,
                        }),
                      )
                  },
                },
                (row?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
                  row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
                  row?.type === 0 && {
                    label: 'Gửi thông báo giải trình cho KH',
                    icon: <IconAction className="fa-light fa-envelope" />,
                    onClick: () => {
                      if (![0, 4].includes(row.errAnnouceType)) {
                        let describeText = `Hóa đơn <${row.serial} - ${
                          row.no
                        }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là <${InvoiceHelper.errAnnouceTypeToVnese(
                          row.errAnnouceType,
                        )}>. Bạn không được phép gửi thông báo giải trình sai sót cho hóa đơn này.`
                        dispatch(showNotification({ describeText }))
                        return
                      }

                      // Trường hợp chưa lập tbss
                      if (row.errAnnouceType == 0) {
                        // đã lập hóa đơn thay thế, điều chỉnh
                        if (row.Related?.at(0)) {
                          let relatedType = row.Related.at(0).type
                          if (![1, 2].includes(relatedType)) {
                            ToastHelper.showError(
                              'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                            )
                            return
                          }

                          let relatedTypeVNese =
                            relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'
                          let describeText = `Hóa đơn <${row.serial} - ${
                            row.no
                          }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                            row.Related.serial
                          } - ${
                            row.Related.no ?? 'Chưa câp số'
                          }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`
                          dispatch(showNotification({ describeText }))
                          return
                        }

                        // kiểm tra xem có lập biên bản hủy hay điều chỉnh không
                        if (row.reportType) {
                          let reportTypeVnese =
                            row.reportType == 'CANCEL' ? 'hủy' : 'điều chỉnh'
                          let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản ${reportTypeVnese}. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${reportTypeVnese}).`
                          dispatch(showNotification({ describeText }))
                          return
                        }
                      }

                      setActiveInvoice(row)
                      dispatch(
                        setModalsState({
                          modalName: 'explanation',
                          status: true,
                        }),
                      )
                    },
                  },
                row?.issueStatus === INVOICE_STATUS.WAITING_FOR_AUTHORITY && {
                  label: 'Kiểm tra trạng thái',
                  icon: <IconAction className="fa-light fa-sync" />,
                  onClick: () => fetchTaxAuthorityMessage(row?.messageId),
                },
                (row?.type === 0 || row?.type === 5) &&
                  (row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
                    row?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT) && {
                    label: 'Điều chỉnh hoá đơn',
                    icon: <IconAction className="fa-light fa-pen-to-square" />,
                    onClick: () => {
                      if (isCreateAdjustedInvoice) {
                        if (![0, 2, 4].includes(row.errAnnouceType)) {
                          let describeText
                          const errAnnouceTypeText =
                            InvoiceHelper.errAnnouceTypeToVnese(
                              row.errAnnouceType,
                            )
                          console.log(errAnnouceTypeText)
                          if ([3, 5].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${errAnnouceTypeText}. Bạn không được phép điều chỉnh hóa đơn này.`
                            dispatch(showNotification({ describeText }))
                            return
                          }
                          if ([2, 6].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${row.no}> đã lập thông báo sai sót với hình thức xử lý là ${errAnnouceTypeText} nhưng chưa gửi CQT. Bạn cần hoàn thành TBSS này.`
                            dispatch(showNotification({ describeText }))
                            return
                          }
                        }
                        // đã lập hóa đơn thay thế, điều chỉnh
                        if (row.Related?.at(0)) {
                          let relatedType = row.Related.at(0).type
                          if (![1, 2].includes(relatedType)) {
                            ToastHelper.showError(
                              'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                            )
                            return
                          }

                          if (relatedType == 1) {
                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn Thay thế số <${
                              row.Related.serial
                            } - ${
                              row.Related.no ?? 'Chưa cấp số'
                            }> . Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (Thay thế).`
                            dispatch(showNotification({ describeText }))
                            return
                          }
                          // show modal confirm
                          setActiveInvoice(row)
                          setIsInvoiceAdjust(true)
                          return
                        }

                        // Trường hợp chưa lập tbss
                        if (row.errAnnouceType == 0) {
                          // kiểm tra xem có lập biên bản hủy không
                          if (row.reportType == 'CANCEL') {
                            let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản hủy. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (hủy).`
                            dispatch(showNotification({ describeText }))
                            return
                          }
                        }
                        setActiveInvoice(row)
                        dispatch(
                          setModalsState({
                            modalName: 'selectAdjustInvoiceMethod',
                            status: true,
                          }),
                        )
                      }
                      if (!isCreateAdjustedInvoice)
                        dispatch(
                          setModalsState({
                            modalName: 'noRight',
                            status: true,
                          }),
                        )
                    },
                  },
                (row?.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN ||
                  row?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT) &&
                  (row?.type === 0 ||
                    row?.type === 1 ||
                    row?.type === 3 ||
                    row?.type === 4) && {
                    label: 'Thay thế hoá đơn',
                    icon: <IconAction className="fa-light fa-file-minus" />,
                    onClick: () => {
                      if (isCreateReplacementInvoice) {
                        // đã lập thông báo huỷ, điều chỉnh
                        if (![0, 3, 4].includes(row.errAnnouceType)) {
                          let describeText
                          if ([3, 5].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                              row.errAnnouceType,
                            )}. Bạn không được phép thay thế hóa đơn này.`
                            dispatch(showNotification({ describeText }))
                            return
                          }
                          if ([2, 6].includes(row?.invoiceErrAnnouceStatus)) {
                            describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã lập thông báo sai sót với hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                              row.errAnnouceType,
                            )} nhưng chưa gửi CQT. Bạn cần hoàn thành TBSS này.`
                            dispatch(showNotification({ describeText }))
                            return
                          }
                        }
                        if (row.type === INVOICE_TYPE.CANCELLED) {
                          navigate(
                            `/hoa-don-dau-ra/xu-ly-hoa-don/thay-the/${row?.invoiceId}`,
                          )
                          return
                        }
                        if (row.type === INVOICE_TYPE.REPLACED) {
                          if (row.Related?.at(0)) {
                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn Thay thế số <${
                              row.Related.at(0).serial
                            } - ${
                              row.Related.at(0).no ?? 'Chưa câp số'
                            }>. Bạn không thể tiếp tục lập hoá đơn thay thế cho hoá đơn này.`
                            dispatch(showNotification({ describeText }))
                            return
                          }
                          navigate(
                            `/hoa-don-dau-ra/xu-ly-hoa-don/thay-the/${row?.invoiceId}`,
                          )
                          return
                        }
                        // Trường hợp chưa lập tbss
                        if (row.errAnnouceType == 0) {
                          // đã lập hóa đơn thay thế, điều chỉnh
                          if (row.Related?.at(0)) {
                            let relatedType = row.Related.at(0).type
                            if (![1, 2].includes(relatedType)) {
                              ToastHelper.showError(
                                'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                              )
                              return
                            }

                            let relatedTypeVNese =
                              relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'
                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                              row.Related.at(0).serial
                            } - ${
                              row.Related.at(0).no ?? 'Chưa câp số'
                            }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`
                            dispatch(showNotification({ describeText }))
                            return
                          }

                          // kiểm tra xem có lập biên bản hủy không
                          // if (row.reportType == 'CANCEL') {
                          //   let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản hủy. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (hủy).`
                          //   dispatch(showNotification({ describeText }))
                          //   return
                          // }
                        }
                        setActiveInvoice(row)
                        dispatch(
                          setModalsState({
                            modalName: 'selectReplacementMethod',
                            status: true,
                          }),
                        )
                      }
                      if (!isCreateReplacementInvoice)
                        dispatch(
                          setModalsState({
                            modalName: 'noRight',
                            status: true,
                          }),
                        )
                    },
                  },
                (row?.issueStatus == INVOICE_STATUS.AUTHORITY_ACCEPT ||
                  row?.issueStatus == INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
                  [0, 1, 3].includes(row?.type) &&
                  (row?.reportStatus == 1 ||
                    [1, 2, 4, 6].includes(row?.invoiceErrAnnouceStatus)) && {
                    label: 'Huỷ hoá đơn',
                    icon: <IconAction className="fa-light fa-file-slash" />,
                    onClick: () => {
                      if (isCreateCancelInvoice) {
                        if (![0, 1, 4].includes(row.errAnnouceType)) {
                          let describeText = `Hóa đơn <${row.serial} - ${
                            row.no
                          }> đã được thông báo sai sót với cơ quan thuế và chọn hình thức xử lý là ${InvoiceHelper.errAnnouceTypeToVnese(
                            row.errAnnouceType,
                          )}. Bạn không được phép hủy hóa đơn này.`
                          dispatch(showNotification({ describeText }))
                          return
                        }

                        // Trường hợp chưa lập tbss
                        if (row.errAnnouceType == 0) {
                          // đã lập hóa đơn thay thế, điều chỉnh
                          if (row.Related?.at(0)) {
                            let relatedType = row.Related.at(0).type
                            if (![1, 2].includes(relatedType)) {
                              ToastHelper.showError(
                                'Hoá đơn thay thế hoặc điều chỉnh cho hoá đơn này đã xảy ra lỗi trạng thái. Cần thông báo cho nhà cung cấp kiểm tra',
                              )
                              return
                            }

                            let relatedTypeVNese =
                              relatedType === 1 ? 'Thay thế' : 'Điều chỉnh'
                            let describeText = `Hóa đơn <${row.serial} - ${
                              row.no
                            }> đã được lập hóa đơn ${relatedTypeVNese} số <${
                              row.Related.serial
                            } - ${
                              row.Related.no ?? 'Chưa câp số'
                            }>. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (${relatedTypeVNese}).`
                            dispatch(showNotification({ describeText }))
                            return
                          }

                          // kiểm tra xem có lập biên bản điều chỉnh không
                          if (row.reportType) {
                            if (row.reportType == 'ADJUST') {
                              let describeText = `Hóa đơn <${row.serial} - ${row.no}> đã được lập biên bản điều chỉnh. Nếu tiếp tục phát hiện ra sai sót, bạn cần thực hiện theo hình thức đã áp dụng khi xử lý sai sót lần đầu (điều chỉnh).`
                              dispatch(showNotification({ describeText }))
                              return
                            }
                          }
                        }

                        setActiveInvoice(row)
                        dispatch(
                          setModalsState({
                            modalName: 'selectCancelationMethod',
                            status: true,
                          }),
                        )
                      }
                      if (!isCreateCancelInvoice)
                        dispatch(
                          setModalsState({
                            modalName: 'noRight',
                            status: true,
                          }),
                        )
                    },
                  },
                (row?.issueStatus == INVOICE_STATUS.AUTHORITY_ACCEPT ||
                  row?.issueStatus == INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
                  row?.type == 3 && {
                    label: 'Khôi phục về HĐ gốc',
                    icon: <IconAction className="fa-light fa-rotate-left" />,
                    onClick: () => {
                      if (isCreateCancelInvoice) {
                        setRestoreOriginModalConfig({
                          show: true,
                          invoiceId: row?.invoiceId,
                        })
                      }
                      if (!isCreateCancelInvoice)
                        dispatch(
                          setModalsState({
                            modalName: 'noRight',
                            status: true,
                          }),
                        )
                    },
                  },
                { type: 'divider' },
                row?.issueStatus !== INVOICE_STATUS.NOT_SENT_TO_AUTHORITY &&
                  row?.type !== INVOICE_TYPE.DELETED && {
                    label: 'Xem lịch sử',
                    icon: <IconAction className="fa-light fa-history" />,
                    onClick: () => {
                      setActiveInvoice(row)
                      dispatch(
                        setModalsState({
                          modalName: 'transmission',
                          status: true,
                        }),
                      )
                    },
                  },
                row?.type !== INVOICE_TYPE.DELETED && {
                  label: 'Đã thanh toán',
                  icon: (
                    <IconAction
                      className={`${
                        row?.isPayment
                          ? 'fa-light fa-check text-success'
                          : 'fa-light fa-square text-black'
                      }`}
                    />
                  ),
                  onClick: () =>
                    updateInvoicePaidStatus({
                      invoiceId: row.invoiceId,
                      isPaid: !row.isPayment,
                    }),
                },
                [
                  INVOICE_STATUS.AUTHORITY_ACCEPT,
                  INVOICE_STATUS.AUTHORITY_DENY,
                  INVOICE_STATUS.AUTHORITY_CODE_GIVEN,
                  INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
                ].includes(row?.issueStatus) && {
                  label: 'Chuyển thành hóa đơn giấy',
                  icon: (
                    <IconAction className={'fa-light fa-paper-plane-alt'} />
                  ),
                  onClick: () =>
                    setConvertPaperModalConfig({
                      lookupCode: row?.lookupCode,
                      show: true,
                    }),
                },
              ]}
            />
          </>
        ),
      },
    ],
    [
      dispatch,
      downloadInvoices,
      downloadPdf,
      downloadXml,
      fetchTaxAuthorityMessage,
      isCreateAdjustedInvoice,
      isCreateCancelInvoice,
      isCreateInvoice,
      isCreateReplacementInvoice,
      isDeleteInvoice,
      isDownloadInvoice,
      isEditInvoice,
      isSendInvoiceToCustomer,
      issueInvoiceHandler,
      navigate,
      renderIssueStatus,
      t,
      updateInvoicePaidStatus,
      viewPdfInvoice,
    ],
  )

  const dynamicColumns = useAppDnD(defaultColumns)

  const renderInvoiceActionButtons = () => {
    const buttonGroups = [
      {
        text: 'Phát hành',
        icon: 'fa-light fa-paper-plane',
        color: '#2563EB',
        disabled: !allCanSignInvoice,
        onClick: () => {
          allCanSignInvoice
            ? signInvoices(selectedInvoices)
            : dispatch(
                setModalsState({
                  modalName: 'sign',
                  status: true,
                }),
              )
        },
      },
      {
        text: 'Gửi lại',
        icon: 'fa-light fa-clock-rotate-left',
        color: '#0284C7',
        onClick: () => {
          resendInvoices({
            invoiceIds: selectedInvoices.map((invoice) => invoice.invoiceId),
          })
        },
      },
      {
        text: 'Gửi',
        icon: 'fa-light fa-envelope',
        color: '#0891B2',
        disabled: false,
        onClick: () => {
          const customer_arr = _.map(
            selectedInvoices,
            'customerTaxCode',
          ).filter((code) => !_.isEmpty(code))
          const hasMultipleCode = _.uniq(customer_arr).length > 1
          if (hasMultipleCode) {
            dispatch(
              setModalsState({
                modalName: 'detectMultipleCustomer',
                status: true,
              }),
            )
            return
          } else {
            dispatch(
              setModalsState({
                modalName: 'sentMail',
                status: true,
              }),
            )
          }
        },
      },
      {
        text: 'Tải về',
        icon: 'fa-light fa-file-download',
        color: '#059669',
        disabled: !haveCanDownloadInvoice,
        onClick: () => {
          if (allCanDownloadInvoice) {
            downloadInvoices({
              invoices: selectedInvoices,
            })
          } else {
            dispatch(
              setModalsState({
                modalName: 'download',
                status: true,
              }),
            )
          }
        },
      },
      {
        text: 'In',
        icon: 'fa-light fa-print',
        color: '#4F46E5',
        disabled: false,
        onClick: () => {
          printInvoices(selectedInvoices.map((invoice) => invoice.invoiceId))
        },
      },
      {
        text: 'Đã thanh toán',
        icon: 'fa-light fa-check',
        color: '#3CC65E',
        onClick: () =>
          updateBulkInvoicePaidStatus({
            invoiceIds: selectedInvoices
              .filter((invoice) => !invoice.isPayment)
              .map((invoice) => invoice.invoiceId),
            isPayment: true,
          }),
      },
      {
        text: 'Xoá',
        icon: 'fa-light fa-trash',
        danger: true,
        disabled: false,
        onClick: () => {
          dispatch(
            setModalsState({
              modalName: 'delete',
              status: true,
            }),
          )
        },
      },
      {
        text: 'Huỷ chọn',
        icon: 'fa-light fa-times',
        type: 'default',
        danger: true,
        onClick: () => {
          dispatch(setSelectedInvoices([]))
          dispatch(setClearRows())
        },
      },
    ]
    return (
      <div
        className={Utils.cn(
          selectedInvoices.length > 0 ? 'd-flex' : 'd-none',
          'flex-row justify-content-end align-items-center',
        )}
        style={{ gap: '10px' }}
      >
        {buttonGroups.map((bg) => (
          <CustomAntButton
            key={bg.text}
            text={bg.text + ' (' + selectedInvoices.length + ')'}
            fontSizeText={12}
            iconCn={bg.icon}
            color={bg.color}
            antProps={{
              danger: bg.danger,
              disabled: bg.disabled,
              type: bg.type ?? 'primary',
              className: Utils.cn(bg.className, bg.disabled ? 'disabled' : ''),
              onClick: bg.onClick,
            }}
          />
        ))}
      </div>
    )
  }

  useEffect(() => {
    refetchListInvoice()
  }, [refetchListInvoice])

  useEffect(() => {
    setFilterParams({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilterParams])

  return (
    <ContentContainer
      wrapperClassName="list-invoice"
      tabs={<InvoiceDashboard statistics={invoiceListData?.statistics} />}
    >
      <ContentContainer.Header
        titleContent={'Danh sách hoá đơn'}
        toolBar={
          <Space>
            <CustomAntButton
              text="Tạo hoá đơn"
              antProps={{
                type: 'primary',
                onClick: () =>
                  isCreateInvoice
                    ? navigate('/hoa-don-dau-ra/hoa-don/them-moi')
                    : dispatch(
                        setModalsState({
                          modalName: 'noRight',
                          status: true,
                        }),
                      ),
              }}
              iconCn={'fa-regular fa-plus text-white'}
            />
            <CustomAntButton
              text="Upload excel"
              iconCn="fa-regular fa-file-arrow-up"
              antProps={{
                className: 'btn btn-success',
                onClick: () =>
                  dispatch(
                    setModalsState({
                      modalName: 'upload',
                      status: true,
                    }),
                  ),
              }}
            />

            <CustomAntButton
              iconCn={Utils.cn(
                'far fa-rotate',
                checkingBulkMessage && 'fa-spin',
              )}
              antProps={{
                type: 'default',
                title: 'Kiểm tra',
                className: 'p-3',
                shape: 'round',
                disabled: checkingBulkMessage,
                onClick: () =>
                  checkMessages(_.pick(filterParams, ['startDate', 'endDate'])),
              }}
              customStyle={{
                minWidth: 'fit-content',
              }}
            />

            <AppDnD
              defaultColumns={defaultColumns}
              localStorageName={PreferenceKeys.savedColumnsInvoice}
            />
          </Space>
        }
      >
        {renderInvoiceActionButtons()}
        <Space
          wrap
          className={Utils.cn(selectedInvoices.length > 0 ? 'd-none' : '')}
        >
          {' '}
          <CustomAntInput
            type="search"
            borderRadius="sm"
            width={150}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            menuPosition="bottomLeft"
            defaultValues={defaultValues}
            savedKey={'gFiltersInvoiceList'}
            onApply={setFilterParams}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={invoiceListData?.tableData}
          isLoading={isGettingListData || isRefetching}
          isClickable
          handleDoubleClickRow={(row) => viewPdfInvoice(row)}
          pagination={{
            total: invoiceListData?.total,
            rowsPerPage: filterParams.limit,
            currentPage: filterParams.page,
            onChangePage: (page) => {
              setFilterParams({
                page,
              })
            },
            onChangeRowsPerPage: (limit) => {
              setFilterParams({
                limit,
                page: 1,
              })
            },
          }}
          isSelectable
          selectedRows={{
            clearSelectedRows: toggledClearRows,
            handleSelectedRows: ({ selectedRows }) =>
              dispatch(setSelectedInvoices(selectedRows)),
            selectableRowSelected: (row) => _.includes(selectedInvoices, row),
          }}
        />
      </ContentContainer.Body>

      <CancelInvoiceModal
        show={modalStates.cancel}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'cancel',
              status: false,
            }),
          )
        }
        title="Huỷ hoá đơn"
        // invoiceId={selectedInvoiceId}
        invoice={activeInvoice}
        buttonAgreeOnClick={(value) =>
          cancelInvoice({ ...value, invoiceId: activeInvoice.invoiceId })
        }
      />
      <ExplanationInvoiceModal
        show={modalStates.explanation}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'explanation',
              status: false,
            }),
          )
        }
        title="Gửi thông báo giải trình cho khách hàng"
        invoice={activeInvoice}
        buttonAgreeOnClick={sendExplanationAnnounceToCustomer}
      />

      <SendInvoiceModal
        invoiceId={activeInvoice?.invoiceId}
        invoice={activeInvoice}
        show={modalStates.sendDraftInvoice}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'sendDraftInvoice',
              status: false,
            }),
          )
        }
      />

      <ModalDataTransmissionLog
        show={modalStates.transmission}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'transmission',
              status: false,
            }),
          )
        }
        invoice={activeInvoice}
      />

      <ModalSelectCancellationMethod
        show={modalStates.selectCancelationMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',
              status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập thông báo hóa đơn điện tử có sai sót gửi cơ quan thuế và lập biên bản điện tử để thỏa thuận với người mua trước khi thực hiện hủy hóa đơn."
        createCancellationReportSelect={
          (activeInvoice?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
            activeInvoice?.issueStatus ===
              INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
          [0, 1, 3].includes(activeInvoice?.type) &&
          activeInvoice?.reportStatus == 1
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/hoa-don/bien-ban-huy/them-moi/${activeInvoice?.invoiceId}`,
                )
            : null
        }
        createErrorAnnounceSelect={
          [1, 4, 6].includes(activeInvoice?.invoiceErrAnnouceStatus)
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot/${activeInvoice?.invoiceId}`,
                )
            : null
        }
        cancelInvoiceSelect={
          [0, 1].includes(activeInvoice?.type)
            ? () => {
                dispatch(
                  setModalsState({
                    modalName: 'selectCancelationMethod',
                    status: false,
                  }),
                )
                dispatch(
                  setModalsState({
                    modalName: 'cancel',
                    status: true,
                  }),
                )
              }
            : null
        }
      />

      <ModalSelectAdjustInvoiceMethod
        show={modalStates.selectAdjustInvoiceMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectAdjustInvoiceMethod',
              status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập biên bản điện tử để thỏa thuận với người mua trước khi lập hóa đơn điều chỉnh."
        createAdjustedReportSelect={
          (activeInvoice?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
            activeInvoice?.issueStatus ===
              INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
          [0, 5].includes(activeInvoice?.type) &&
          activeInvoice?.reportStatus == 1
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/hoa-don/bien-ban-dieu-chinh/them-moi/${activeInvoice?.invoiceId}`,
                )
            : null
        }
        createAdjustAnnounceSelect={
          [1, 4, 6].includes(activeInvoice?.invoiceErrAnnouceStatus)
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot/${activeInvoice?.invoiceId}`,
                )
            : null
        }
        adjustInvoiceSelect={
          [0, 5].includes(activeInvoice?.type)
            ? () => {
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/dieu-chinh/${activeInvoice?.invoiceId}`,
                )
              }
            : null
        }
      />

      <ModalUnauthorized
        show={modalStates.noRight}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'noRight',
              status,
            }),
          )
        }
      />

      <ModalConfirm
        header="Tải hoá đơn điện tử"
        content="Chương trình chỉ thực hiện tải các hóa đơn đã được cấp mã của cơ quan thuế, có trạng thái hóa đơn khác với <Hóa đơn đã bị hủy; Hóa đơn đã bị thay thế>."
        show={modalStates.download}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'download',
              status,
            }),
          )
        }
        handleConfirm={(e) => {
          e.preventDefault()
          downloadInvoices({
            invoices: selectedInvoices,
          })
        }}
      />

      <ModalSendEmail
        ref={modalSentMailRef}
        isOpen={modalStates.sentMail}
        selectedInvoices={selectedInvoices}
        onOpenChange={(status) =>
          dispatch(setModalsState({ modalName: 'sentMail', status }))
        }
      />

      <ModalConfirm
        header="Xác nhận"
        content="Chương trình chỉ thực hiện xóa các hóa đơn chưa cấp số và có trạng thái chưa phát hành. Bạn có muốn tiếp tục không?"
        show={modalStates.delete}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'delete',
              status,
            }),
          )
        }
        handleConfirm={(e) => {
          e.preventDefault()
          deleteInvoiceMutation({
            invoices:
              selectedInvoices.length > 0 ? selectedInvoices : [activeInvoice],
          })
        }}
      />

      <ModalConfirm
        header="Phát hành hoá đơn"
        content="Chương trình chỉ thực hiện phát hành các hóa đơn chưa cấp số, có trạng thái Chưa phát hành và đã được phê duyệt"
        show={modalStates.sign}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',
              status,
            }),
          )
        }
        handleConfirm={(e) => {
          e.preventDefault()
          signInvoices(selectedInvoices)
        }}
      />

      <ModalConfirm
        header="Xác nhận"
        content="Bạn cần hủy hóa đơn bị sai sót trước khi lập hóa đơn thay thế. Bạn có muốn hủy hóa đơn này không?"
        show={modalStates.selectReplacementMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({ modalName: 'selectReplacementMethod', status }),
          )
        }
        handleConfirm={(e) => {
          e.preventDefault()
          dispatch(
            setModalsState({
              modalName: 'selectReplacementMethod',
              status: false,
            }),
          )
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',
              status: true,
            }),
          )
        }}
      />

      <ModalConfirm
        header="Hoá đơn điều chỉnh"
        content="Hoá đơn này đã được lập hoá đơn điều chỉnh. Bạn có muốn tiếp tục lập hoá
      đơn điều chỉnh cho hoá đơn này không"
        show={isInvoiceAdjust}
        setShow={setIsInvoiceAdjust}
        handleConfirm={(e) => {
          e.preventDefault()
          setIsInvoiceAdjust(false)
          navigate(
            `/hoa-don-dau-ra/xu-ly-hoa-don/dieu-chinh/${activeInvoice?.invoiceId}`,
          )
        }}
      />

      <ModalConfirm
        header="Xác nhận"
        content="Chương trình nhận thấy bạn đang chọn nhiều hoá đơn với các khách hàng khác nhau. Bạn có chắc chắn muốn tiếp tục?"
        show={modalStates.detectMultipleCustomer}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'detectMultipleCustomer',
              status,
            }),
          )
        }
        handleConfirm={() => {
          dispatch(
            setModalsState({
              modalName: 'sentMail',
              status: true,
            }),
          )
        }}
      />

      <ModalUploadInvoiceExcel
        show={modalStates.upload}
        setShow={(status) =>
          dispatch(setModalsState({ modalName: 'upload', status }))
        }
        refetchListInvoice={refetchListInvoice}
      />

      <ModalConfirm
        show={hsmSignModalConfig.show}
        setShow={(value) =>
          setHSMSignModalConfig((old) => ({ ...old, show: value }))
        }
        header="Ký số HSM"
        content="Chương trình sẽ thực hiện ký số HSM. Bạn chắc chắn vẫn ký?"
        handleConfirm={() =>
          hsmSignInvoiceMutation(hsmSignModalConfig.invoiceId)
        }
        runWhenClosedFunc={() =>
          setHSMSignModalConfig((old) => ({ ...old, invoiceId: null }))
        }
      />

      <ModalConfirm
        show={reIssueModalConfig.show}
        setShow={(value) =>
          setReIssueModalConfig((old) => ({ ...old, show: value }))
        }
        header="Phát hành lại hóa đơn"
        content="Chương trình sẽ thực hiện phát hành lại hóa đơn. Bạn chắc chắn muốn phát hành lại?"
        handleConfirm={() => reIssueInvoice(reIssueModalConfig.invoiceId)}
        runWhenClosedFunc={() =>
          setReIssueModalConfig((old) => ({ ...old, invoiceId: null }))
        }
      />

      <ModalConfirm
        show={reSignHSMModalConfig.show}
        setShow={(value) =>
          setReSignHSMModalConfig((old) => ({ ...old, show: value }))
        }
        header="Phát hành lại hóa đơn sử dụng cks HSM"
        content="Chương trình sẽ thực hiện phát hành lại hóa đơn sử dụng cks HSM. Bạn chắc chắn muốn phát hành lại?"
        handleConfirm={() => reSignHSM(reSignHSMModalConfig.invoiceId)}
        runWhenClosedFunc={() =>
          setReSignHSMModalConfig((old) => ({ ...old, invoiceId: null }))
        }
      />

      <ModalConfirm
        show={restoreOriginModalConfig.show}
        setShow={(value) =>
          setRestoreOriginModalConfig((old) => ({ ...old, show: value }))
        }
        header="Xác nhận"
        content="Bạn có thực sự muốn khôi phục hóa đơn này về trạng thái Hóa đơn mới?"
        handleConfirm={() => restoreOrigin(restoreOriginModalConfig.invoiceId)}
        runWhenClosedFunc={() =>
          setRestoreOriginModalConfig((old) => ({ ...old, invoiceId: null }))
        }
      />

      <ModalConvertPaper
        showModalConvertPaper={convertPaperModalConfig.show}
        setShowModalConvertPaper={(value) =>
          setConvertPaperModalConfig((pre) => ({ ...pre, show: value }))
        }
        handleConvertPaper={handleConvertPaper}
      />
    </ContentContainer>
  )
}

export default ListInvoice
