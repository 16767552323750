import { useDebounce } from '@uidotdev/usehooks'
import { useMemo, useState } from 'react'

/**
 *
 * @param {string} defaultValue
 * @param {number} [timeout]
 */
export default function useSearchDebounce(defaultValue, timeout) {
  const [search, setSearch] = useState(defaultValue)
  const debouncedSearch = useDebounce(search, timeout ?? 1000)
  return useMemo(
    () => ({
      search,
      setSearch,
      debouncedSearch,
    }),
    [search, debouncedSearch],
  )
}
