import { useQuery } from '@tanstack/react-query'
import axiosClient from 'api/axiosClient'
import _ from 'lodash'

const queryKeys = {
  base: ['shared', 'permissions'],
}

const useGetUserPermissions = () => {
  return useQuery({
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    queryKey: queryKeys.base,
    queryFn: ({ signal }) =>
      axiosClient.get('/users/getPermissions', { signal }),
    select: (data) => {
      if (data.result !== 'success')
        return {
          count: 0,
          rows: [],
        }
      return {
        count: data.count ?? 0,
        rows: _.map(data.metadata.rows, (row) => ({
          userFunctionName: row.userFunctionName,
          actionName: row.actionName,
          listFunctionCode: row.listFunctionCode,
          isActive: row.active === 1,
        })),
      }
    },
  })
}

export default useGetUserPermissions
