// import lifeStyleApi from 'api/lifeStyleApi';
import employeeApi from 'api/employeeApi'
import { thunkGetEmployeeList } from 'features/Category/categorySlice'
import { FastField, Formik } from 'formik'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import ModalChooseCompanyUnit from '../ModalChooseCompanyUnit'
import VIToggleSwitch from 'general/components/eInvoiceComponents/VIToggleSwitch'

ModalEditEmployee.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  employeeItem: PropTypes.object,
  active: PropTypes.string,
  parentEmployee: PropTypes.object,
}

ModalEditEmployee.defaultProps = {
  show: false,
  onClose: null,
  employeeItem: null,
  active: 'ADD',
  parentEmployee: {},
}

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * employeeItem: object,
 * active: string,
 * }} props
 * @returns
 */
function ModalEditEmployee(props) {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { show, onClose, employeeItem, active } = props
  const [showing, setShowing] = useState(true)
  const [showModalChooseCompanyUnit, setShowModalChooseCompanyUnit] =
    useState(false)
  const [selectedCompanyUnitItem, setSelectedCompanyUnitItem] = useState(
    employeeItem?.companyUnit,
  )
  const { companyTitles } = useSelector((state) => state?.category)
  const navigate = useNavigate()

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose()
      setSelectedCompanyUnitItem(null)
    }
  }

  // Request create new money type
  async function requestCreateEmployee(values) {
    let params = { ...values }

    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(employeeItem)) {
      params.employeeId = ''
    }

    const res = await employeeApi.create(params)
    const { result, employee } = res
    if (result === 'success') {
      ToastHelper.showSuccess(`Thêm nhân viên ${params.name} thành công`)
      dispatch(thunkGetEmployeeList(Global.gFiltersEmployeeList))
      handleClose()
    }
  }

  // Request replicate money type
  async function requestReplicateEmployee(values) {
    let params = { ...values }

    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(employeeItem)) {
      params.employeeId = ''
    }

    const res = await employeeApi.create(params)
    const { result, employee } = res
    if (result === 'success') {
      ToastHelper.showSuccess(`Nhân bản nhân viên ${params.name} thành công`)
      dispatch(thunkGetEmployeeList(Global.gFiltersEmployeeList))
      handleClose()
    }
  }

  // Request update money type
  async function requestUpdateEmployee(values) {
    let params = { ...values }
    if (_.isNull(employeeItem)) {
      params.employeeId = ''
    }
    const res = await employeeApi.update(params)
    const { result, employee } = res
    if (result === 'success') {
      ToastHelper.showSuccess(
        `Cập nhật thông tin nhân viên ${employeeItem.name} thành công`,
      )
      dispatch(thunkGetEmployeeList(Global.gFiltersEmployeeList))
      handleClose()
    }
  }

  // MARK --- Hooks ---
  useEffect(() => {
    if (employeeItem?.companyUnit) {
      setSelectedCompanyUnitItem(employeeItem?.companyUnit)
    } else {
      setSelectedCompanyUnitItem(null)
    }
  }, [employeeItem])

  return (
    <div>
      <Formik
        initialValues={{
          employeeId:
            active === 'ADD' ? '' : employeeItem ? employeeItem.employeeId : '',
          code: active === 'ADD' ? '' : employeeItem ? employeeItem.code : '',
          name: active === 'ADD' ? '' : employeeItem ? employeeItem.name : '',
          email: active === 'ADD' ? '' : employeeItem ? employeeItem.email : '',
          phone: active === 'ADD' ? '' : employeeItem ? employeeItem.phone : '',
          companyUnitId:
            active === 'ADD'
              ? ''
              : employeeItem
                ? employeeItem?.companyUnit?.companyUnitId
                : '',
          companyTitleId:
            active === 'ADD'
              ? ''
              : employeeItem
                ? employeeItem?.companyTitle?.companyTitleId
                : '',
          status:
            active === 'ADD'
              ? 'ACTIVE'
              : employeeItem
                ? employeeItem.status
                : 'ACTIVE',
        }}
        validationSchema={Yup.object({
          code: Yup.string().trim().required(t('Required')),
          name: Yup.string().trim().required(t('Required')),
          email: Yup.string().email('Email không hợp lệ').nullable(),
          phone: Yup.string().nullable(),
        })}
        enableReinitialize
        onSubmit={(values) => {
          switch (active) {
            case 'ADD':
              requestCreateEmployee(values)
              break
            case 'EDIT':
              requestUpdateEmployee(values)
              break
            case 'REPLICATE':
              requestReplicateEmployee(values)
              break
            default:
              requestReplicateEmployee(values)
              break
          }
        }}
      >
        {(formikProps) => (
          <>
            <Modal
              className=""
              show={show && showing}
              backdrop="static"
              size="lg"
              onHide={handleClose}
              centered
              onExit={() => {
                if (!showModalChooseCompanyUnit) {
                  formikProps.handleReset()
                }
              }}
              onExited={() => {
                // handleExistDone();
                // formikProps.handleReset();
              }}
            >
              <Modal.Header className="px-5 py-5">
                <Modal.Title>
                  {active === 'ADD'
                    ? 'Thêm nhân viên'
                    : active === 'EDIT'
                      ? 'Chỉnh sửa thông tin nhân viên'
                      : 'Nhân bản nhân viên'}
                </Modal.Title>
                <div
                  className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                  onClick={() => {
                    handleClose()
                    formikProps.handleReset()
                  }}
                >
                  <i className="far fa-times"></i>
                </div>
              </Modal.Header>

              <Modal.Body
                className="overflow-auto bg-light"
                style={{
                  maxHeight: '70vh',
                }}
              >
                <div className="row">
                  {/* ma nhan vien */}
                  <div className="">
                    <KTFormGroup
                      label={
                        <>
                          {t('Mã nhân viên')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="code"
                      inputElement={
                        <FastField name="code">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Mã nhân viên'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* ten nhan vien */}
                  <div className="">
                    <KTFormGroup
                      label={
                        <>
                          {t('Tên nhân viên')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="name"
                      inputElement={
                        <FastField name="name">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Tên nhân viên'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* don vi */}
                  <div className="mb-6">
                    <span className="text-remaining">Đơn vị</span>
                    <div className="mt-2 position-relative">
                      {selectedCompanyUnitItem ? (
                        <div className={`bg-white border rounded p-2 `}>
                          <div
                            className="d-flex flex-row align-items-center px-3 py-1 rounded font-weight-bolder"
                            style={{
                              color: '#4A5677',
                              width: 'fit-content',
                              backgroundColor: '#DBE3EF',
                            }}
                          >
                            {selectedCompanyUnitItem?.name}
                            <i
                              className="far fa-times ml-4 cursor-pointer"
                              onClick={() => {
                                setSelectedCompanyUnitItem(null)
                                formikProps
                                  .getFieldHelpers('parentCompanyUnitId')
                                  .setValue(null)
                              }}
                              style={{
                                color: '#4A5677',
                              }}
                            ></i>
                            {/* )} */}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`bg-white border cursor-pointer rounded p-3 `}
                          onClick={() => {
                            setShowModalChooseCompanyUnit(true)
                            setShowing(false)
                          }}
                        >
                          {t('ChooseCompanyUnit')}
                        </div>
                      )}
                    </div>
                    {formikProps.getFieldMeta('companyUnitId').error && (
                      <div className={`d-block invalid-feedback`}>
                        {formikProps.getFieldMeta('companyUnitId').error}
                      </div>
                    )}
                  </div>

                  {/* chuc danh */}
                  <div className="mb-4 d-flex flex-column">
                    <label className="mb-2" htmlFor="type">
                      {_.capitalize(t('Chức danh'))}
                    </label>
                    <KTFormSelect
                      name="companyTitleId"
                      isCustom
                      options={[
                        // { name: '', value: '' },
                        ...companyTitles
                          ?.filter((item) => item?.status === 'ACTIVE')
                          ?.map((item) => {
                            return {
                              name: item.name,
                              value: item.companyTitleId.toString(),
                            }
                          }),
                      ]}
                      value={formikProps
                        .getFieldProps('companyTitleId')
                        .value?.toString()}
                      onChange={(newValue) => {
                        formikProps
                          .getFieldHelpers('companyTitleId')
                          .setValue(newValue)
                      }}
                    />
                  </div>

                  {/* email */}
                  <div className="">
                    <KTFormGroup
                      label={<>{t('Email')}</>}
                      inputName="email"
                      inputElement={
                        <FastField name="email">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('Email'))}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* so dien thoai */}
                  <div className="">
                    <KTFormGroup
                      label={<>{t('Số điện thoại')}</>}
                      inputName="phone"
                      inputElement={
                        <FastField name="phone">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Số điện thoại'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="w-100 d-flex flex-wrap gap-3 justify-content-between">
                  <div>
                    <FastField name="status">
                      {({ field, form, meta }) => (
                        <VIToggleSwitch
                          label={
                            field.value === 'ACTIVE'
                              ? t('Using')
                              : t('StopUsing')
                          }
                          status={field.value === 'ACTIVE' ? true : false}
                          name={field.name}
                          onSwitch={(checked) => {
                            if (checked) {
                              form.setFieldValue(field.name, 'ACTIVE')
                            } else {
                              form.setFieldValue(field.name, 'INACTIVE')
                            }
                          }}
                        />
                      )}
                    </FastField>
                  </div>

                  <div className="d-flex">
                    <Button
                      className="font-weight-bold  mr-3"
                      variant="secondary"
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      {t('Close')}
                    </Button>

                    <Button
                      className="font-weight-bold "
                      variant="primary"
                      onClick={() => {
                        formikProps.handleSubmit()
                      }}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
            <ModalChooseCompanyUnit
              show={showModalChooseCompanyUnit}
              companyUnitItem={employeeItem?.companyUnit}
              setCompanyUnitItem={(item) => {
                formikProps
                  .getFieldHelpers('companyUnitId')
                  .setValue(item.companyUnitId)
                setSelectedCompanyUnitItem(item)
              }}
              onClose={() => {
                setShowModalChooseCompanyUnit(false)
                setShowing(true)
              }}
              openModalCreateCompanyUnit={() => {
                setShowModalChooseCompanyUnit(false)
                // setShowing(true);
                navigate('/hoa-don-dau-ra/danh-muc/don-vi')
                Global.gOpenModalCreateCompanyUnit = true
              }}
            />
          </>
        )}
      </Formik>
    </div>
  )
}

export default ModalEditEmployee
