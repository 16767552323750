import { QueryClient, keepPreviousData } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      retry: false,
      placeholderData: keepPreviousData,
    },
  },
})

export default queryClient
