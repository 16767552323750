import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Checkbox, Switch } from 'antd'
import goodsApi from 'api/goodsApi'
import { goodsGroupQueries } from 'features/Category/screens/GoodsGroupScreen/queries'
import { goodsQueries } from 'features/Category/screens/GoodsScreen/queries'
import { useFormik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import { useMemo } from 'react'
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { object, string } from 'yup'
import './style.scss'

const init = {
  goodsId: '',
  code: '',
  name: '',
  unit: '',
  vat: '0',
  status: 'ACTIVE',
  discountRate: '',
  description: '',
  priceAfterTax: false,
  price: '',
  goodsGroupId: null,
  numericalOrder: '',
}

/**
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onClose
 * @param {object} props.goodsItem
 * @param {'ADD'| 'CREATE_NEW' | 'EDIT' | 'REPLICATE'} props.active
 */
export default function ModalEditGoods(props) {
  const { show, onClose, goodsItem, active } = props
  const { t } = useTranslation()
  const { data: goodsGroups } = useQuery(goodsGroupQueries.listOfGoodsGroup())
  const formatStrToFloat = (str) => {
    const sanitizedStr = str?.replace(/\./g, '')?.replace(/,/g, '.')
    if (_.isEmpty(sanitizedStr)) return 0
    return parseFloat(sanitizedStr)
  }

  const queryClient = useQueryClient()
  const { mutate: handleUpdate, isPending } = useMutation({
    mutationKey: ['goods', 'crud'],
    mutationFn: (params) => {
      switch (active) {
        case 'ADD':
        case 'CREATE_NEW':
        case 'REPLICATE':
          return goodsApi.create(params)
        case 'EDIT':
          return goodsApi.update(params)
      }
    },
    onMutate: () => toast.loading('Loading...'),
    onError: (_err, _var, ctx) =>
      toast.update(ctx, { render: 'Tạo mới không thành công' }),
    onSettled: () => toast.dismiss(),
    onSuccess: (res) => {
      if (res.result === 'success') {
        switch (active) {
          case 'ADD':
            ToastHelper.showSuccess(t('CreateGoodsSuccessful'))
            break
          case 'CREATE_NEW':
            ToastHelper.showSuccess(t('CreateGoodsSuccessful'))
            break
          case 'EDIT':
            ToastHelper.showSuccess(t('UpdateGoodsSuccessful'))
            break
          case 'REPLICATE':
            ToastHelper.showSuccess(t('ReplicateGoodsSuccessful'))
            break
        }
        onClose()
        // onExistDone()
        queryClient.invalidateQueries({
          queryKey: [goodsQueries.base],
        })
      }
    },
  })

  const initialValues = useMemo(() => {
    switch (active) {
      case 'ADD':
        return init
      case 'CREATE_NEW':
      case 'EDIT':
      case 'REPLICATE':
        return {
          goodsId: goodsItem ? goodsItem?.goodsId ?? '' : '',
          code: goodsItem ? goodsItem?.code ?? '' : '',
          name: goodsItem ? goodsItem?.name ?? '' : '',
          unit: goodsItem ? goodsItem?.unit ?? '' : '',
          vat: goodsItem ? goodsItem?.vat ?? '0' : '0',
          status: goodsItem ? goodsItem?.status ?? 'ACTIVE' : '',
          discountRate: goodsItem?.discountRate
            ? Utils.formatNumber(goodsItem.discountRate) ?? ''
            : '',
          description: goodsItem ? goodsItem?.description ?? '' : '',
          priceAfterTax: goodsItem ? goodsItem?.priceAfterTax : '',
          price: goodsItem?.price,
          // ? Utils.formatNumber(
          //     goodsItem.price,
          //     goodsItem.price.toString().split('.')[1]
          //       ? goodsItem.price.toString().split('.')[1].length
          //       : 0,
          //   ) ?? ''
          // : '',
          goodsGroupId: goodsItem ? goodsItem?.goodsGroupId : null,
          numericalOrder: goodsItem?.numericalOrder,
        }
    }
  }, [active, goodsItem])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: object({
      code: string().trim().required(t('Required')),
      name: string().trim().required(t('Required')),
      description: string().trim().nullable(),
      price: string()
        .trim()
        .nullable()
        .test('isValid', t('PriceIsNotValid'), (val) => {
          const sanitizedVal = _.toNumber(_.replace(val, /[.,]/g, ''))
          return _.isNumber(sanitizedVal) && parseFloat(sanitizedVal) >= 0
        }),
      discountRate: string().trim().nullable(),
    }),
    onSubmit: (values) => {
      const params = {
        ...values,
        price: formatStrToFloat(values.price), // 0 number
        discountRate: formatStrToFloat(values.discountRate),
        goodsId: _.isNull(values.goodsId) ? '' : values.goodsId,
        numericalOrder: values.numericalOrder,
      }
      handleUpdate(params)
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit} onReset={() => formik.handleReset()}>
      <Modal
        show={show}
        backdrop={'static'}
        size="lg"
        centered
        scrollable
        keyboard
        onHide={onClose}
      >
        <ModalHeader
          className="p-5"
          closeButton
          closeLabel="Close"
          closeVariant="light"
        >
          <ModalTitle>
            {active === 'ADD' || active === 'CREATE_NEW'
              ? t('NewGoods')
              : active === 'EDIT'
                ? t('UpdateGoods')
                : t('ReplicateGoods')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody className="bg-light">
          <Row>
            {/* Ma hang hoa, dich vu */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {t('GoodsCode')}
                  <span className="text-danger ml-1">*</span>
                </FormLabel>
                <CustomAntInput
                  type="text"
                  borderRadius="sm"
                  inputProps={{
                    ...formik.getFieldProps('code'),
                    id: 'code',
                    autoComplete: 'off',
                    placeholder: t('GoodsCode'),
                    status:
                      formik.getFieldMeta('code').touched &&
                      !!formik.getFieldMeta('code').error &&
                      'error',
                  }}
                />
                <FormControl.Feedback type="invalid">
                  {formik.getFieldMeta('code').touched &&
                    !!formik.getFieldMeta('code').error && (
                      <span>{formik.getFieldMeta('code').error}</span>
                    )}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
            {/* So thu tu uu tien */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {t('NumericalOrder')}
                </FormLabel>
                <CustomAntInput
                  type="number"
                  borderRadius="sm"
                  inputProps={{
                    ...formik.getFieldProps('numericalOrder'),
                    className: 'w-100',
                    id: 'numericalOrder',
                    autoComplete: 'off',
                    min: 1,
                    size: 'small',
                    onChange: (value) => {
                      formik.setFieldValue('numericalOrder', value)
                    },
                    placeholder: t('NumericalOrder'),
                    status:
                      formik.getFieldMeta('numericalOrder').touched &&
                      !!formik.getFieldMeta('numericalOrder').error &&
                      'error',
                  }}
                />
                <FormControl.Feedback type="invalid">
                  {formik.getFieldMeta('numericalOrder').touched &&
                    !!formik.getFieldMeta('numericalOrder').error && (
                      <span>{formik.getFieldMeta('numericalOrder').error}</span>
                    )}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
            {/* Ten hang hoa, dich vu */}
            <Col md={12}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {t('GoodsName')}
                  <span className="text-danger ml-1">*</span>
                </FormLabel>
                <CustomAntInput
                  type="text"
                  borderRadius="sm"
                  inputProps={{
                    ...formik.getFieldProps('name'),
                    id: 'name',
                    autoComplete: 'off',
                    placeholder: t('GoodsName'),
                    status:
                      formik.getFieldMeta('name').touched &&
                      !!formik.getFieldMeta('name').error &&
                      'error',
                  }}
                />
                <FormControl.Feedback type="invalid">
                  {formik.getFieldMeta('name').touched &&
                    !!formik.getFieldMeta('name').error && (
                      <span>{formik.getFieldMeta('name').error}</span>
                    )}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
            {/* Nhom hang hoa, dich vu */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {t('GoodsGroup')}
                </FormLabel>
                <CustomAntSelect
                  showSearch={false}
                  options={_.map(goodsGroups?.data, (v) => ({
                    label: v.name,
                    value: v.goodsGroupId,
                    disabled: v.status !== 'ACTIVE',
                  }))}
                  placeholder={t('GoodsGroup')}
                  value={formik.getFieldProps('goodsGroupId').value}
                  onChange={(value) =>
                    formik.setFieldValue('goodsGroupId', value)
                  }
                  className="custom-antd-select"
                  customFilterOptionFn={(input, opt) =>
                    _.toLower(opt.FormLabel).includes(_.toLower(input))
                  }
                />
              </FormGroup>
            </Col>
            {/* Don vi tinh */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">{t('Unit')}</FormLabel>
                <CustomAntInput
                  type="text"
                  borderRadius="sm"
                  inputProps={{
                    ...formik.getFieldProps('unit'),
                    id: 'unit',
                    autoComplete: 'off',
                    placeholder: t('Unit'),
                    status:
                      formik.getFieldMeta('unit').touched &&
                      !!formik.getFieldMeta('unit').error &&
                      'error',
                  }}
                />
                <FormControl.Feedback type="invalid">
                  {formik.getFieldMeta('unit').touched &&
                    !!formik.getFieldMeta('unit').error && (
                      <span>{formik.getFieldMeta('unit').error}</span>
                    )}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
            {/* Gia */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {t('PriceBeforeTax')}
                </FormLabel>
                <CustomAntInput
                  type="text"
                  borderRadius="sm"
                  inputProps={{
                    ...formik.getFieldProps('price'),
                    id: 'price',
                    onChange: (e) => {
                      const sanitizedVal = formatStrToFloat(e.target.value)
                      if (isNaN(sanitizedVal) && !e.target.value.includes(','))
                        return
                      formik.setFieldValue('price', e.target.value)
                    },
                    onBlur: (e) => {
                      const value = e.target.value
                      const formatValue = value
                        .replace(/\./g, '')
                        .replace(/,/g, '.')
                      const noDecimal = value.split(',')[1]
                        ? value.split(',')[1].length
                        : 0
                      const newValue = Utils.formatNumber(
                        formatValue,
                        noDecimal,
                      )
                      formik.setFieldValue(
                        'price',
                        newValue === 'NaN' ? 0 : newValue,
                      )
                    },
                    autoComplete: 'off',
                    placeholder: t('Price'),
                    status:
                      formik.getFieldMeta('price').touched &&
                      !!formik.getFieldMeta('price').error &&
                      'error',
                  }}
                />
                <FormControl.Feedback type="invalid">
                  {formik.getFieldMeta('price').touched &&
                    !!formik.getFieldMeta('price').error && (
                      <span>{formik.getFieldMeta('price').error}</span>
                    )}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
            {/* Gia sau thue */}
            <Col md={6}>
              <FormGroup>
                <Checkbox
                  {...formik.getFieldProps('priceAfterTax')}
                  checked={formik.getFieldProps('priceAfterTax').value}
                  className="mt-10"
                >
                  {t('PriceAfterTax')}
                </Checkbox>
              </FormGroup>
            </Col>
            {/* VAT */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">{t('VAT')}</FormLabel>
                <CustomAntSelect
                  showSearch={false}
                  options={AppData.VAT.map((v) => ({
                    ...v,
                    label: v.name,
                  }))}
                  placeholder={t('VAT')}
                  value={formik.getFieldProps('vat').value}
                  onChange={(value) => formik.setFieldValue('vat', value)}
                  className="custom-antd-select"
                  customFilterOptionFn={(input, opt) =>
                    _.toLower(opt.FormLabel).includes(_.toLower(input))
                  }
                />
                <FormControl.Feedback type="invalid">
                  {formik.getFieldMeta('vat').touched &&
                    !!formik.getFieldMeta('vat').error && (
                      <span>{formik.getFieldMeta('vat').error}</span>
                    )}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
            {/* Ty le ck */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {t('DiscountRate')}
                </FormLabel>
                <CustomAntInput
                  type="text"
                  borderRadius="sm"
                  inputProps={{
                    ...formik.getFieldProps('discountRate'),
                    id: 'discountRate',
                    onChange: (e) => {
                      const sanitizedVal = formatStrToFloat(e.target.value)
                      if (isNaN(sanitizedVal)) return
                      formik.setFieldValue(
                        'discountRate',
                        Utils.formatNumber(sanitizedVal),
                      )
                    },
                    autoComplete: 'off',
                    placeholder: t('DiscountRate'),
                    status:
                      formik.getFieldMeta('discountRate').touched &&
                      !!formik.getFieldMeta('discountRate').error &&
                      'error',
                  }}
                />
                <FormControl.Feedback type="invalid">
                  {formik.getFieldMeta('discountRate').touched &&
                    !!formik.getFieldMeta('discountRate').error && (
                      <span>{formik.getFieldMeta('discountRate').error}</span>
                    )}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
            {/* Mo ta */}
            <Col md={12}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {t('Description')}
                </FormLabel>
                <CustomAntInput
                  type="textarea"
                  borderRadius="sm"
                  inputProps={{
                    ...formik.getFieldProps('description'),
                    id: 'description',
                    autoComplete: 'off',
                    placeholder: t('Description'),
                  }}
                />
              </FormGroup>
            </Col>
            {/* Trang thai */}
            <Col md={6}>
              <FormGroup>
                <FormLabel className="fw-bold w-100">
                  {formik.getFieldProps('status').value === 'ACTIVE'
                    ? t('Using')
                    : t('StopUsing')}
                </FormLabel>
                <Switch
                  checked={formik.getFieldProps('status').value === 'ACTIVE'}
                  onChange={(checked) => {
                    formik.setFieldValue(
                      'status',
                      checked ? 'ACTIVE' : 'INACTIVE',
                    )
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            variant="light"
            className="fw-bold"
            type="reset"
            onClick={() => formik.resetForm()}
          >
            <i className="fa-regular fa-history mr-2" />
            {t('Reset')}
          </Button>
          <div className="d-flex gap-2">
            <Button
              disabled={isPending}
              variant="secondary"
              onClick={() => onClose()}
            >
              {t('Close')}
            </Button>
            <Button
              disabled={isPending}
              variant="primary"
              type="submit"
              onClick={() => formik.submitForm()}
            >
              {t('Save')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Form>
  )
}
