const { default: axiosClient } = require('./axiosClient')

const listFunction = {
  // lay toan bo
  getAll: () => {
    const url = '/listFunction'
    return axiosClient.get(url)
  },

  getAllByUserId: (params) => {
    const url = `/listFunction/getAllByUserId`
    return axiosClient.get(url, { params })
  },

  update: (params) => {
    const url = '/listFunction/update'
    return axiosClient.put(url, params)
  },

  updateAll: (params) => {
    const url = '/listFunction/updateAll'
    return axiosClient.put(url, params)
  },

  createAll: (params) => {
    console.log(params)
    const url = '/listFunction/createAll'
    return axiosClient.post(url, params)
  },

  deleteByUserId: (params) => {
    const url = '/listFunction/deleteByUserId'
    return axiosClient.delete(url, { data: { params } })
  },
}

export default listFunction
