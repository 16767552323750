import CustomAntSelect from 'general/components/CustomAntSelect'
import FormLabel from 'general/components/Forms/FormLabel'
import InputNumber from 'general/components/Forms/InputNumber'
import SelectOptions from 'general/constants/SelectOptions'

function SelectGeneralDiscountAndTaxRate(props) {
  const { formik, handleChangeGTGT, formatNumber } = props

  return (
    <div className="d-flex flex-column" style={{ gap: '10px' }}>
      {parseInt(formik.values?.serial?.charAt(0)) === 1 &&
        (formik.values.taxType === 1 ||
          (formik.values.taxType === 3 && !formik.values.multipleTaxRate)) && (
          <div className="d-flex flex-row align-items-center">
            <FormLabel width="110px">Thuế GTGT:</FormLabel>
            <CustomAntSelect
              options={SelectOptions.GTGT}
              value={formik.values.taxRate}
              onChange={(value) => handleChangeGTGT(value)}
              showSearch={false}
              autoMatchWidth={false}
            />
            {/* <CustomSelect
              autoSize
              selectOptions={SelectOptions.GTGT}
              currentValue={formik.values.taxRate}
              // defaultValue={SelectOptions.GTGT[0]}
              onChange={(selectedOption) => {
                handleChangeGTGT(selectedOption.value)
              }}
            /> */}
          </div>
        )}

      {formik.values.discountType === 2 ? (
        <div className="d-flex flex-row align-items-center w-100">
          <FormLabel width="110px">Tỉ lệ CK (%):</FormLabel>
          <InputNumber
            name="discount"
            systemConfigType="ratio"
            maxLength={formatNumber?.ratio + 4}
            fieldProps={formik.getFieldProps('discount')}
            fieldMeta={formik.getFieldMeta('discount')}
            fieldHelper={formik.getFieldHelpers('discount')}
            // disabled={true}
            className="w-100"
          />
        </div>
      ) : null}
    </div>
  )
}

export default SelectGeneralDiscountAndTaxRate
