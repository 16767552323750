import _ from 'lodash'
import _merge from 'lodash/merge'
import _reduce from 'lodash/reduce'
import {
  BorderType,
  CellObject,
  CellStyle,
  CellStyleColor,
  utils,
} from 'xlsx-js-style'

/** @typedef {Array<keyof CellStyle['border']> | 'around'} Directions */

/**
 * @param {*} value
 * @param {CellObject['t']} type - The Excel Data Type of the cell. b Boolean, n Number, e Error, s String, d Date, z Empty
 * @param {CellStyle} [style]
 * @param {CellObject['z']} [format] - number format - Example: #,##0. More info: https://docs.sheetjs.com/docs/csf/features/nf/
 * @example
 * const cellObject = generateCellObject('Hello World', 's', { font: { bold: true } }, '@')
 *
 *  @returns {CellObject}
 * */
const generateCellObject = (value, type, style, format) => ({
  v: value,
  t: type,
  s: style,
  z: format,
})

/**
 *
 * @param {Array} rows
 * @param {import('xlsx-js-style').WorkSheet['!ref']} ref
 * @param {number} minWidth - Min number in char
 * @param {number} maxWidth - Max number in char. Default to 35
 * @returns
 */
const calcMaxCellWidth = (rows, ref, minWidth, maxWidth = 35) => {
  const maxCol = utils.decode_range(ref).e.c
  const columnWidths = _.map(_.range(maxCol + 1), (i) => {
    return {
      wch: _.reduce(
        rows,
        (prev, curr) => {
          const itemObj = Object.values(curr)[i]
          let value = _.clamp(itemObj?.v?.length ?? 0, minWidth, maxWidth)
          return Math.max(prev, value)
        },
        minWidth,
      ),
    }
  })
  return columnWidths
}

/**
 * @param {import('xlsx-js-style/types').RowInfo[]} row
 * @param {Array<{
 *  level: number,
 *  height: number,
 * }>} heightInfo
 */
const setRowsHeight = (row, heightInfo) => {
  if (!row) row = []
  heightInfo.forEach(({ height, level }) => {
    row[level] = {
      ...row[level],
      hpx: height,
    }
  })
  return row
}

/**
 * @param {Object} params
 * @param {boolean} [params.isTextWrap] - Whether the text should wrap. default to false
 * @param {Pick<CellStyle['alignment'], 'horizontal' | 'vertical'>} [params.alignment] - Alignment of the cell
 * @param {Object} [params.border]
 * @param {BorderType} [params.border.widthStyle]
 * @param {CellStyleColor} [params.border.color]
 * @param {Directions} [params.border.directions] - 'around' or array of 'top' | 'bottom' | 'left' | 'right'
 * @param {CellStyle['font']} [params.font]
 * @param {CellStyle['fill']} [params.background]
 * @returns {CellStyle} - Helper function to generate cell style
 */
const generateCellStyle = (params) => {
  const { alignment, border, isTextWrap, font, background } = params
  const borderStyles =
    border?.directions === 'around'
      ? {
          top: {
            style: border?.widthStyle,
            color: border?.color,
          },
          bottom: {
            style: border?.widthStyle,
            color: border?.color,
          },
          left: {
            style: border?.widthStyle,
            color: border?.color,
          },
          right: {
            style: border?.widthStyle,
            color: border?.color,
          },
        }
      : Array.isArray(border?.directions)
        ? Object.assign(
            {},
            ...border?.directions.map((direction) => {
              return {
                [direction]: {
                  color: border?.color,
                  style: border?.widthStyle,
                },
              }
            }),
          )
        : undefined

  const fontStyles = _merge(
    {},
    {
      name: 'Times New Roman',
      sz: 12,
    },
    font,
  )

  return {
    alignment: {
      horizontal: alignment?.horizontal,
      vertical: alignment?.vertical,
      wrapText: isTextWrap,
    },
    border: borderStyles,
    font: fontStyles,
    fill: background,
  }
}

/**
 *
 * @param {{
 *  startIndex: number,
 *  endIndex: number
 * }} row
 * @param {{
 *  startIndex: number,
 *  endIndex: number
 * }} column
 * @returns {import('xlsx-js-style').WorkSheet['!merges'][0]}
 */
const createMergeRow = (row, column) => {
  return {
    s: { r: row.startIndex, c: column.startIndex },
    e: { r: row.endIndex, c: column.endIndex },
  }
}

export {
  calcMaxCellWidth,
  generateCellObject,
  generateCellStyle,
  createMergeRow,
  setRowsHeight,
}
