import { useQuery } from '@tanstack/react-query'
import axiosClient from 'api/axiosClient'
import Marquee from 'react-fast-marquee'
import './styles.scss'
import _ from 'lodash'
export default function Banner() {
  const { data: banners, isLoading } = useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['banner'],
    queryFn: ({ signal }) =>
      axiosClient.get('/notification-marquee/show', {
        signal,
      }),
  })

  if (isLoading) {
    return null
  }
  return (
    banners?.data?.length > 0 && (
      <div
        className="d-flex align-items-center px-4 text-danger"
        style={{
          height: '30px',
          backgroundColor: '#ff0',
          color: '#ff0000',
        }}
      >
        <Marquee className="custom-marquee" pauseOnHover speed={50}>
          {banners?.data.map((banner) => (
            <div
              className="mx-40 font-size-normal font-weight-bold text-danger"
              key={banner.notificationMarqueeId}
            >
              <li>
                {banner?.title}: {banner?.body}
              </li>
            </div>
          ))}
        </Marquee>
      </div>
    )
  )
}
