import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import SearchTaxDeductionDocuments from '../TaxDeductionDocuments/component/Search'
import AddTaxDeductionDocuments from './screens/Update/add'
import { useAppDispatch } from 'hooks/useRedux'
import EditTaxDeductionDocuments from './screens/Update/edit'
import DigitalSignPreference from 'features/System/screens/DigitalSignPreference'
import AddAccountingDocuments from 'features/TaxDeduction/screens/addAccountingDocuments'
function TaxDeductionDocuments() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <Routes>
      <Route path="mau" element={<SearchTaxDeductionDocuments />} />
      <Route path="mau/them" element={<AddTaxDeductionDocuments />} />
      <Route path="thiet-lap-ki-so" element={<DigitalSignPreference />} />
      <Route path="chungtu/lap-chung-tu" element={<AddAccountingDocuments />} />
      <Route
        path="mau/nhan-ban/:templatePaperId"
        element={<EditTaxDeductionDocuments copy />}
      />
      <Route
        path="mau/sua/:templatePaperId"
        element={<EditTaxDeductionDocuments />}
      />
      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default TaxDeductionDocuments
