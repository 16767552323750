import './style.scss'
import CustomButton from 'general/components/Button/CustomButton'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
NotInstalledSigningToolNotiModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

NotInstalledSigningToolNotiModal.defaultProps = {
  show: false,
  onClose: null,
}

function NotInstalledSigningToolNotiModal({ show, onClose }) {
  return (
    <Modal
      className="NotInstalledSigningToolNotiModal font-sfpro-regular"
      show={show}
      onHide={onClose}
      //   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className="pb-0" closeButton={true}></Modal.Header>
      <Modal.Body className="">
        <h5 className="text-danger mb-3">
          Phần mềm ký hóa đơn VietInvoiceSign chưa hoạt động
        </h5>
        <p className="mb-3">
          Nếu chưa cài phần mềm ký hóa đơn VietInvoiceSign, hãy bấm vào nút{' '}
          <b>Tải bộ cài</b> bên dưới để cài đặt
        </p>
        <div className="d-flex flex-row align-items-center">
          <div className="flex-fill">
            <button
              className="btn bg-transparent d-flex flex-row align-items-center text-primary"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/resources/einvoice_tool/vietinvoiceSign.msi`,
                )
              }
            >
              <i className="fa-regular fa-download text-primary"></i> Tải bộ cài
            </button>
          </div>
          <div className="flex-fill">
            <button
              className="btn bg-transparent d-flex flex-row align-items-center text-primary"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/resources/einvoice_tool/guide.pdf`,
                  '_blank',
                )
              }
            >
              <i className="fa-regular fa-file text-primary"></i> Xem hướng dẫn
            </button>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="">
        <CustomButton
          backgroundColor="#304FFD"
          text="Đóng"
          width="90px"
          color="white"
          onClick={onClose}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default NotInstalledSigningToolNotiModal
