import Banner from 'general/components/Banner'
import KT01Content from 'general/components/Content/KT01Content'

import KT01Header from 'general/components/Header/KT01Header'

import KT01HeaderMobile from 'general/components/Header/KT01HeaderMobile'

import KTScrollTop from 'general/components/OtherKeenComponents/KTScrollTop'

import KT01Sidebar from 'general/components/Sidebars/KT01Sidebar'
import useRouter from 'hooks/useRouter'

function KT01BaseLayout(props) {
  // MARK: --- Params ---

  const { pathname } = useRouter()

  return (
    <div className="d-flex flex-column vh-100">
      <div class="position-sticky sticky-top">
        <Banner />
      </div>
      {!pathname.includes('/hoa-don-dau-ra/mau-hoa-don/tao/') &&
        !pathname.includes('/hoa-don-dau-ra/mau-hoa-don/sua/') &&
        !pathname.includes('/hoa-don-dau-ra/tem-ve/mau-tem-ve/tao/') &&
        !pathname.includes('/hoa-don-dau-ra/tem-ve/mau-tem-ve/sua/') && (
          <KT01HeaderMobile />
        )}

      <div className="d-flex flex-row flex-column-fluid page">
        {/* Sidebar */}

        <KT01Sidebar />
        <div
          id="kt_wrapper"
          className="d-flex flex-column flex-row-fluid wrapper position-relative pt-0 justify-content-between"
        >
          {/* Header */}

          {!pathname.includes('/hoa-don-dau-ra/mau-hoa-don/tao/') &&
            !pathname.includes('/hoa-don-dau-ra/mau-hoa-don/sua/') &&
            !pathname.includes('/hoa-don-dau-ra/tem-ve/mau-tem-ve/tao/') &&
            !pathname.includes('/hoa-don-dau-ra/tem-ve/mau-tem-ve/sua/') && (
              <KT01Header />
            )}
          {/* Content */}

          <KT01Content>{props.children}</KT01Content>

          {/* Footer */}

          {/* <KT01Footer /> */}
        </div>
      </div>
      {/* <div className="d-flex flex-column flex-root">
      </div> */}

      {/* Scroll Top */}

      <KTScrollTop />
    </div>
  )
}

export default KT01BaseLayout
