import errAnnouceApi from 'api/errAnnouceApi'
import { setAppNotInstalledToolNotiModalShow } from 'app/appSlice'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { default as ToastHelper } from 'general/helpers/ToastHelper'
import { default as Utils } from 'general/utils/Utils'

export const createErrAnnouce = async (
  currentCompany,
  submitAction,
  tableData,
  values,
  dispatch,
  setAppSpinning,
  navigate,
  signingConfig,
) => {
  try {
    const res = await errAnnouceApi.create({
      ...values,
      ticketsInfo: tableData,
      invoiceType: 'TICKET',
    })
    if (res?.result === 'success') {
      ToastHelper.showSuccess('Lưu thông báo nháp thành công')
      // navigate('/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot')
      switch (submitAction) {
        case 'Save': {
          navigate('/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot')
          break
        }
        case 'SaveAndSign': {
          let errorAnnoucementId = res.data?.errorAnnoucementId

          let { signingMethod } = signingConfig
          if (signingMethod == 'HSM') {
            try {
              await errAnnouceApi.hsmSignErrAnnouce({
                errorAnnoucementId,
              })

              ToastHelper.showSuccess('Ký thông báo sai sót thành công')
              navigate('/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot')
            } catch (e) {
              navigate(
                `/hoa-don-dau-ra/tem-ve/xu-ly-ve/chinh-sua-thong-bao-sai-sot/${errorAnnoucementId}`,
              )
            }
          } else {
            let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
            const urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},message300,${errorAnnoucementId},${currentCompany?.taxCode}`
            Utils.openToolSignErrAnnouce(urlSchema, accessToken, () =>
              dispatch(setAppNotInstalledToolNotiModalShow(true)),
            )
          }

          break
        }
        default:
          return
      }
    }
  } catch (err) {
    console.log(err)
  } finally {
    dispatch(setAppSpinning(false))
  }
}
