import React from 'react'
import { useSelector } from 'react-redux'
import './style.scss'
import Utils from 'general/utils/Utils'
import ResizableCell from '../ResizableCell'
import { useEffect } from 'react'
import Table from 'rc-table'
import {
  useSubColumns,
  useSubColumnsDispatch,
} from 'features/TemplateInvoice/screens/CreateTemplateInvoice/SubColumnsContext'
import { fieldAlignConvert } from 'general/helpers/TemplateInvoiceHelper'

const handleResize =
  (index, cb) =>
  (e, { size }) => {
    cb((oldColumns) => {
      const nextColumns = [...oldColumns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      return nextColumns
    })
  }

const regionNameStand = (regionName) => {
  switch (regionName) {
    case 'sellerInfo':
      return 'SI'
    case 'buyerInfo':
      return 'BI'
    case 'invoiceInfoOther':
      return 'IIO'
  }
}

function ResizableFlexBox(props) {
  const {
    mergeField,
    regionName,
    regionConfArr,
    maxWidth,
    id = Utils.makeId(5),
  } = props

  const { activeRegion, activeDataField } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const subColumns = useSubColumns()
  const subColumnsDispatch = useSubColumnsDispatch()

  function setSubColumns(param) {
    let payload =
      typeof param == 'function' ? param(subColumns[mergeField]) : param

    subColumnsDispatch({
      type: 'set',
      field: mergeField,
      payload,
    })
  }

  useEffect(() => {
    if (regionConfArr?.length) {
      let totalWidth = document.getElementById(id)?.offsetWidth
      setSubColumns((old) =>
        !old
          ? regionConfArr.map((reConf, index, arr) =>
              index == arr.length - 1
                ? {
                    title: '',
                    dataIndex: reConf.dataField,
                    key: reConf.dataField,
                  }
                : {
                    title: '',
                    dataIndex: reConf.dataField,
                    key: reConf.dataField,
                    width: totalWidth / arr.length,
                  },
            )
          : regionConfArr.map((reConf, index, arr) =>
              index == arr.length - 1
                ? {
                    title: '',
                    dataIndex: reConf.dataField,
                    key: reConf.dataField,
                  }
                : {
                    title: '',
                    dataIndex: reConf.dataField,
                    key: reConf.dataField,
                    width: old[index].width,
                  },
            ),
      )
    }
  }, [JSON.stringify(regionConfArr)])

  const genTableData = (regionConfArr, regionName) => {
    if (!regionConfArr?.length) return
    return [
      {
        ...regionConfArr.reduce(
          (result, reConf, index) => ({
            ...result,
            [reConf.dataField]:
              !reConf.typeShow || reConf.typeShow == 3 ? (
                <div
                  key={index}
                  className={`horizontal-content same-width-child ${
                    reConf.fieldAlign
                      ? `justify-content-${reConf.fieldAlign}`
                      : ''
                  } ${
                    reConf.fieldAlign
                      ? `text-${fieldAlignConvert(reConf.fieldAlign)}`
                      : ''
                  } ${
                    reConf.dataField === activeDataField?.dataField
                      ? 'active-data-field-l '
                      : ''
                  }`}
                  style={{ marginLeft: `${(reConf.mrRank || 0) * 12}pt` }}
                >
                  <div
                    className={`${regionNameStand(regionName)}-wrap ${
                      reConf?.displayType === 'CONTENT_LEFT'
                        ? 'd-none'
                        : 'horizontal-content'
                    }`}
                    style={{ minWidth: maxWidth, whiteSpace: 'nowrap' }}
                  >
                    {/* label */}
                    <div
                      className={`${regionNameStand(
                        regionName,
                      )}-item edit-label mr-1`}
                      style={Utils.filterStyles(reConf.label)}
                    >
                      <span
                        className={`${
                          reConf?.displayType === 'CONTENT' ? 'hidden-span' : ''
                        }`}
                      >
                        {reConf.label.value}
                      </span>
                    </div>
                    {/* labelEn */}
                    {isShowLabelEn && (
                      <div
                        className={`${regionNameStand(
                          regionName,
                        )}-item edit-label-en`}
                        style={Utils.filterStyles(reConf.labelEn)}
                      >
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : ''
                          }`}
                        >
                          {reConf.labelEn.value}
                        </span>
                      </div>
                    )}
                    {activeRegion?.alignType !== 'ALIGN_TYPE_3' && (
                      <div className="two-dot">
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : ''
                          }`}
                        >
                          :
                        </span>
                      </div>
                    )}
                  </div>
                  {activeRegion?.alignType === 'ALIGN_TYPE_3' && (
                    <div className="two-dot">
                      <span
                        className={`${
                          reConf?.displayType === 'CONTENT'
                            ? 'hidden-span'
                            : reConf.displayType === 'CONTENT_LEFT'
                              ? 'd-none'
                              : ''
                        }`}
                      >
                        :
                      </span>
                    </div>
                  )}
                  {/* value */}
                  <div
                    className={`${regionNameStand(
                      regionName,
                    )}-item edit-value ml-1`}
                    style={{
                      ...Utils.filterStyles(reConf.value),
                      flexShrink: 100,
                    }}
                  >
                    {reConf.dataField.includes('TaxCode') &&
                    reConf?.mstSplitCells ? (
                      <div className="d-flex flex-row align-items-center tax-code-input mr-2">
                        {reConf.value.value.split('').map((num, index) => (
                          <div key={index} className="tax-code-number">
                            {num}
                          </div>
                        ))}
                      </div>
                    ) : (
                      reConf.value.value
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`${regionNameStand(regionName)}-item ${
                    reConf.dataField === activeDataField?.dataField
                      ? 'active-data-field-l '
                      : ''
                  } ${
                    reConf.fieldAlign
                      ? `text-${fieldAlignConvert(reConf.fieldAlign)}`
                      : ''
                  } edit-value `}
                  style={{
                    ...Utils.filterStyles(reConf.value),
                    flexShrink: 100,
                  }}
                >
                  {reConf.value.value}
                </div>
              ),
          }),
          {},
        ),
      },
    ]
  }

  return (
    <div className="ResizableFlexBox" id={id}>
      <Table
        className={`custom-resizable-table`}
        showHeader={false}
        tableLayout="fixed"
        emptyText={<></>}
        components={{
          body: {
            cell: ResizableCell,
          },
        }}
        columns={subColumns[mergeField]?.map((col, indexCol) => ({
          ...col,
          onCell: (_) => {
            let props = {
              width: col.width,
              resizeEnable: regionName == activeRegion?.regionName,
              onResize: handleResize(indexCol, setSubColumns),
            }

            return props
          },
        }))}
        data={genTableData(regionConfArr, regionName)}
      />
    </div>
  )
}

export default ResizableFlexBox
