import React, { Fragment, useEffect, useMemo, useState } from 'react'
import './style.scss'
import '../../resize.scss'

import { Rnd } from 'react-rnd'
import Table from 'rc-table'
import 'react-resizable/css/styles.css'
import { useSelector } from 'react-redux'
import AppResource from 'general/constants/AppResource'
import { useDispatch } from 'react-redux'
import {
  setActiveRegionBaseOnName,
  setBackgroundInfo,
  setCustomBackgroundInfo,
  setLogoInfo,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import _ from 'lodash'
import Utils from 'general/utils/Utils'
import { ReactSVG } from 'react-svg'
import TemplateInvoiceHelper from 'general/helpers/TemplateInvoiceHelper'
import ResizableFlexBox from 'features/TemplateInvoice/components/ResizableFlexbox'
import ResizableTitle from 'features/TemplateInvoice/components/ResizableTitle'
import ResizableCell from 'features/TemplateInvoice/components/ResizableCell'
import { Resizable } from 're-resizable'
import Draggable from 'react-draggable'
import { useColumns, useColumnsDispatch } from '../../ColumnsContext'
import PreferenceKeys from 'general/constants/PreferenceKeys'

const handleResize =
  (index, cb) =>
  (e, { size }) => {
    cb((oldColumns) => {
      const nextColumns = [...oldColumns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      if (nextColumns[index]?.children?.length) {
        nextColumns[index].children = nextColumns[index].children.map(
          (item) => ({
            ...item,
            width: size.width / 2,
          }),
        )
      }
      return nextColumns
    })
  }

const genTableDetailData = (numRows, keys) => {
  return [...Array(numRows).keys()].map((num) => {
    let returnValue = {}
    for (let key of keys) {
      returnValue[key] = <div key={key}>{'\u200b'}</div>
    }
    return returnValue
  })
}

const genTableFooterData = (
  type,
  regionConfigs,
  activeDataFieldName,
  isShowLabelEn,
  fullColumnDataFields,
) => {
  if (!regionConfigs) return null

  let returnValue = []
  let tableRow = []

  regionConfigs
    ?.filter(({ show }) => show)
    .forEach((dataField) => {
      if (fullColumnDataFields?.includes(dataField.dataField)) {
        returnValue.push({
          col1: (
            <div
              key={Utils.makeId(2)}
              className={`horizontal-content ${
                dataField.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
              style={{ marginLeft: `${(dataField.mrRank || 0) * 12}pt` }}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(dataField.label)}
              >
                {dataField.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(dataField.labelEn)}
                >
                  {dataField.labelEn.value}
                </div>
              )}
              <div style={Utils.filterStyles(dataField.label)}>:</div>
              {dataField?.note && (
                <div
                  className="edit-note mr-1"
                  style={Utils.filterStyles(dataField.label)}
                >
                  {dataField.note}
                </div>
              )}
              {dataField.value.value && (
                <div
                  className="edit-value ml-1"
                  style={Utils.filterStyles(dataField.value)}
                >
                  {dataField.value.value}
                </div>
              )}
            </div>
          ),
        })
        return
      } else if (
        /* type =2 */
        type == 2 &&
        !['discountRate', 'totalDiscountAmount'].includes(dataField.dataField)
      ) {
        returnValue.push({
          col1: (
            <div
              key={Utils.makeId(2)}
              className={`horizontal-content ${
                dataField.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
              style={{ marginLeft: `${(dataField.mrRank || 0) * 12}pt` }}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(dataField.label)}
              >
                {dataField.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(dataField.labelEn)}
                >
                  {dataField.labelEn.value}
                </div>
              )}
              <div style={Utils.filterStyles(dataField.label)}>:</div>
              {dataField?.note && (
                <div
                  className="edit-note mr-1"
                  style={Utils.filterStyles(dataField.label)}
                >
                  {dataField.note}
                </div>
              )}
              {dataField.value.value && (
                <div
                  className="edit-value ml-1"
                  style={Utils.filterStyles(dataField.value)}
                >
                  {dataField.value.value}
                </div>
              )}
            </div>
          ),
          col2: <div key={Utils.makeId(2)} className={`wh-100`}></div>,
          col3: <div key={Utils.makeId(2)} className={`wh-100`}></div>,
          col4: (
            <div
              key={Utils.makeId(2)}
              className={`wh-100 ${
                dataField.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            ></div>
          ),
        })
        return
      }

      tableRow.push(dataField)

      if (tableRow.length == 2) {
        let [ele1, ele2] = tableRow
        returnValue.push({
          col1:
            ele1.dataField.includes('notShow') || !ele1.show ? (
              <Fragment key={Utils.makeId(2)}></Fragment>
            ) : (
              <div
                className={`horizontal-content ${
                  ele1.dataField === activeDataFieldName
                    ? 'active-data-field-l '
                    : ''
                }`}
                key={Utils.makeId(2)}
                style={{ marginLeft: `${(ele1.mrRank || 0) * 12}pt` }}
              >
                <div
                  className="edit-label mr-1"
                  style={Utils.filterStyles(ele1.label)}
                >
                  {ele1.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(ele1.labelEn)}
                  >
                    {ele1.labelEn.value}
                  </div>
                )}
                <div style={Utils.filterStyles(ele1.label)}>:</div>
              </div>
            ),
          col2: (
            <div
              key={Utils.makeId(2)}
              className={`wh-100 ${
                ele1.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            ></div>
          ),
          col3: !ele2?.show ? (
            <Fragment key={Utils.makeId(2)}></Fragment>
          ) : (
            <div
              key={Utils.makeId(2)}
              className={`horizontal-content ${
                ele2.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(ele2.label)}
              >
                {ele2.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(ele2.labelEn)}
                >
                  {ele2.labelEn.value}
                </div>
              )}
              <div style={Utils.filterStyles(ele2.label)}>:</div>
            </div>
          ),
          col4: (
            <div
              key={Utils.makeId(2)}
              className={`wh-100 ${
                ele2?.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
            ></div>
          ),
        })
        tableRow = []
      }
    })

  return returnValue
}

const genTableFooter1Data = (
  type,
  regionConfigs,
  activeDataFieldName,
  isShowLabelEn,
  discountCheck,
) => {
  if (!regionConfigs) return null

  var returnValue = []
  if (type == 1) {
    let [discountRateConf, discountAmountConf] = regionConfigs.slice(0, 2)
    let discountRow = {
      col1: (
        <div
          className={`horizontal-content ${
            discountRateConf.dataField === activeDataFieldName
              ? 'active-data-field-l '
              : ''
          }`}
          key={Utils.makeId(2)}
        >
          <div
            className="edit-label mr-1"
            style={Utils.filterStyles(discountRateConf.label)}
          >
            {discountRateConf.label.value}
          </div>
          {isShowLabelEn && (
            <div
              className="edit-label-en"
              style={Utils.filterStyles(discountRateConf.labelEn)}
            >
              {discountRateConf.labelEn.value}
            </div>
          )}
          <div style={Utils.filterStyles(discountRateConf.label)}>:</div>
        </div>
      ),
      col2: <div key={Utils.makeId(2)}>{'\u200b'}</div>,
      col3: (
        <div
          className={`horizontal-content ${
            discountAmountConf.dataField === activeDataFieldName
              ? 'active-data-field-l '
              : ''
          }`}
          key={Utils.makeId(2)}
        >
          <div
            className="edit-label mr-1"
            style={Utils.filterStyles(discountAmountConf.label)}
          >
            {discountAmountConf.label.value}
          </div>
          {isShowLabelEn && (
            <div
              className="edit-label-en"
              style={Utils.filterStyles(discountAmountConf.labelEn)}
            >
              {discountAmountConf.labelEn.value}
            </div>
          )}
          <div style={Utils.filterStyles(discountAmountConf.label)}>:</div>
        </div>
      ),
      col4: <div key={Utils.makeId(2)}>{'\u200b'}</div>,
    }
    if (discountCheck) {
      returnValue.push(discountRow)
    }

    let headerRow = regionConfigs.slice(2, 6)?.reduce(
      (kq, dataField, index) => ({
        ...kq,
        [`col${index + 1}`]: (
          <div
            key={index}
            className={`vertical-content ${
              dataField.dataField === activeDataFieldName
                ? 'active-data-field-l '
                : ''
            }`}
          >
            <div
              className="edit-label w-100"
              style={Utils.filterStyles(dataField.label)}
            >
              {dataField.label.value}
            </div>
            {isShowLabelEn && (
              <div
                className="edit-label-en w-100"
                style={Utils.filterStyles(dataField.labelEn)}
              >
                {dataField.labelEn.value}
              </div>
            )}
          </div>
        ),
      }),
      {},
    )
    returnValue.push(headerRow)

    let bodyRow = regionConfigs
      .slice(6, -2)
      ?.filter(({ show }) => show)
      ?.map((dataField, index) => ({
        /* Dùng thẻ p thay cho thẻ div để có thể tự xuống dòng khi tràn */
        col1: (
          <p
            className={`mb-0 ${
              dataField.dataField === activeDataFieldName
                ? 'active-data-field-l '
                : ''
            }`}
            key={Utils.makeId(2)}
          >
            <span
              className="edit-label mr-1"
              style={Utils.filterStyles(dataField.label)}
            >
              {dataField.label.value}
            </span>
            {isShowLabelEn && (
              <span
                className="edit-label-en"
                style={Utils.filterStyles(dataField.labelEn)}
              >
                {dataField.labelEn.value}
              </span>
            )}
            <span style={Utils.filterStyles(dataField.label)}>:</span>
          </p>
        ),
        col2: <div key={Utils.makeId(2)}>{'\u200b'}</div>,
        col3: <div key={Utils.makeId(2)}>{'\u200b'}</div>,
        col4: <div key={Utils.makeId(2)}>{'\u200b'}</div>,
      }))
    returnValue.push(...bodyRow)

    let footerRow = regionConfigs
      .slice(-2)
      .filter((rc) => rc.show)
      .map((reConf) => ({
        col1: (
          <div
            key={Utils.makeId(2)}
            className={`horizontal-content ${
              reConf.dataField === activeDataFieldName
                ? 'active-data-field-l '
                : ''
            }`}
          >
            <div
              className="edit-label mr-1"
              style={Utils.filterStyles(reConf.label)}
            >
              {reConf.label.value}
            </div>
            {isShowLabelEn && (
              <div
                className="edit-label-en"
                style={Utils.filterStyles(reConf.labelEn)}
              >
                {reConf.labelEn.value}
              </div>
            )}
            <div style={Utils.filterStyles(reConf.label)}>:</div>
            {reConf.value.value && (
              <div
                className="edit-value ml-1"
                style={Utils.filterStyles(reConf.value)}
              >
                {reConf.value.value}
              </div>
            )}
          </div>
        ),
      }))
    returnValue.push(...footerRow)
  } else {
    let tableRow = []
    let fullColumnDataFields = ['TotalAmountInWords', 'TemplateNote']

    regionConfigs
      ?.filter(({ show }) => show)
      .forEach((dataField) => {
        if (fullColumnDataFields?.includes(dataField.dataField)) {
          returnValue.push({
            col1: (
              <div
                key={Utils.makeId(2)}
                className={`horizontal-content ${
                  dataField.dataField === activeDataFieldName
                    ? 'active-data-field-l '
                    : ''
                }`}
                style={{ marginLeft: `${(dataField.mrRank || 0) * 12}pt` }}
              >
                <div
                  className="edit-label mr-1"
                  style={Utils.filterStyles(dataField.label)}
                >
                  {dataField.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(dataField.labelEn)}
                  >
                    {dataField.labelEn.value}
                  </div>
                )}
                <div style={Utils.filterStyles(dataField.label)}>:</div>
                {dataField?.note && (
                  <div
                    className="edit-note mr-1"
                    style={Utils.filterStyles(dataField.label)}
                  >
                    {dataField.note}
                  </div>
                )}
                {dataField.value.value && (
                  <div
                    className="edit-value ml-1"
                    style={Utils.filterStyles(dataField.value)}
                  >
                    {dataField.value.value}
                  </div>
                )}
              </div>
            ),
          })
          return
        }

        tableRow.push(dataField)

        if (tableRow.length == 2) {
          let [ele1, ele2] = tableRow
          returnValue.push({
            col1:
              ele1.dataField.includes('NotShow') || !ele1.show ? (
                <Fragment key={Utils.makeId(2)}></Fragment>
              ) : (
                <div
                  className={`horizontal-content ${
                    ele1.dataField === activeDataFieldName
                      ? 'active-data-field-l '
                      : ''
                  }`}
                  key={Utils.makeId(2)}
                  style={{ marginLeft: `${(ele1.mrRank || 0) * 12}pt` }}
                >
                  <div
                    className="edit-label mr-1"
                    style={Utils.filterStyles(ele1.label)}
                  >
                    {ele1.label.value}
                  </div>
                  {isShowLabelEn && (
                    <div
                      className="edit-label-en"
                      style={Utils.filterStyles(ele1.labelEn)}
                    >
                      {ele1.labelEn.value}
                    </div>
                  )}
                  <div style={Utils.filterStyles(ele1.label)}>:</div>
                </div>
              ),
            col2: (
              <div
                key={Utils.makeId(2)}
                className={`wh-100 ${
                  ele1.dataField === activeDataFieldName
                    ? 'active-data-field-l '
                    : ''
                }`}
              ></div>
            ),
            col3: !ele2?.show ? (
              <Fragment key={Utils.makeId(2)}></Fragment>
            ) : (
              <div
                key={Utils.makeId(2)}
                className={`horizontal-content ${
                  ele2.dataField === activeDataFieldName
                    ? 'active-data-field-l '
                    : ''
                }`}
              >
                <div
                  className="edit-label mr-1"
                  style={Utils.filterStyles(ele2.label)}
                >
                  {ele2.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(ele2.label)}
                  >
                    {ele2.labelEn.value}
                  </div>
                )}
                <div style={Utils.filterStyles(ele2.label)}>:</div>
              </div>
            ),
            col4: (
              <div
                key={Utils.makeId(2)}
                className={`wh-100 ${
                  ele2?.dataField === activeDataFieldName
                    ? 'active-data-field-l '
                    : ''
                }`}
              ></div>
            ),
          })
          tableRow = []
        }
      })
  }

  return returnValue
}

const genSignXmlData = (
  regionConfigsGroupByMergeField,
  activeDataFieldName,
  isShowLabelEn,
  paperSize,
) => {
  let returnValue = regionConfigsGroupByMergeField.reduce(
    (returnObj, [mergeField, dataFields]) => {
      const renderSignInfo = (dataField, isHorizontal = true) => {
        if (!dataField.show) return null

        const contentClass = isHorizontal
          ? 'horizontal-content'
          : 'vertical-content'
        return (
          <div
            className={`${contentClass} ${dataField.dataField === activeDataFieldName ? 'active-data-field-l ' : ''}`}
            key={Utils.makeId(2)}
          >
            <div
              className="edit-label mr-1"
              style={Utils.filterStyles(dataField.label)}
            >
              {dataField.label.value}
            </div>
            {isShowLabelEn ? (
              <div
                className="edit-label-en"
                style={Utils.filterStyles(dataField.labelEn)}
              >
                {dataField.labelEn.value}
              </div>
            ) : null}
          </div>
        )
      }

      const renderSignatureValid = (dataFields) => (
        <div
          className="p-2 mx-auto"
          style={{
            backgroundColor: '#E3F2E4',
            border: '1px solid #7cc576',
            marginTop: `${paperSize == 'A5' ? 3 : 15}pt`,
            width: '200pt',
          }}
        >
          <div style={{ fontWeight: 600 }}>Signature Valid</div>
          {dataFields.map((dataField) => renderSignInfo(dataField))}
        </div>
      )

      const renderSignatureRegion = (dataFields) => {
        const twoFieldFirst = dataFields.slice(0, 2)
        const twoFieldSeconds = dataFields.slice(2, 4)
        return (
          <div key={Utils.makeId(2)}>
            {twoFieldFirst.map((field) => {
              return twoFieldFirst[0].show ? renderSignInfo(field, false) : null
            })}
            {twoFieldSeconds.length > 0 && twoFieldFirst[0].show
              ? renderSignatureValid(twoFieldSeconds)
              : ''}
          </div>
        )
      }
      if (dataFields.length <= 4) {
        returnObj[mergeField] = renderSignatureRegion(dataFields)
      } else {
        returnObj[mergeField] = []
        for (let i = 0; i < dataFields.length; i += 4) {
          returnObj[mergeField].push(
            renderSignatureRegion(dataFields.slice(i, i + 4)),
          )
        }
      }
      return returnObj
    },
    {},
  )
  return [returnValue]
}

const genTableHospitalData = (
  regionConfigs,
  activeDataFieldName,
  isShowLabelEn,
) => {
  if (!regionConfigs) return null

  let returnValue = []

  returnValue.push({
    col1: (
      <div className={`horizontal-content`}>
        <div className="edit-label mr-1">{'Trong đó'}</div>
        {isShowLabelEn && <div className="edit-label-en">{'In'}</div>}
        <div>:</div>
      </div>
    ),
    col2: <></>,
  })
  let tableRow = []

  regionConfigs
    ?.filter(({ show }) => show)
    .forEach((dataField) => {
      tableRow.push(dataField)

      if (tableRow.length == 2) {
        let [ele1, ele2] = tableRow
        returnValue.push({
          col1: (
            <div
              className={`horizontal-content ${
                ele1.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
              key={Utils.makeId(2)}
              style={{ marginLeft: `${(ele1.mrRank || 0) * 12}pt` }}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(ele1.label)}
              >
                {ele1.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(ele1.labelEn)}
                >
                  {ele1.labelEn.value}
                </div>
              )}
              <div style={Utils.filterStyles(ele1.label)}>:</div>
            </div>
          ),
          col2: (
            <div
              key={Utils.makeId(2)}
              className={`horizontal-content ${
                ele2.dataField === activeDataFieldName
                  ? 'active-data-field-l '
                  : ''
              }`}
              style={{ marginLeft: `${(ele2.mrRank || 0) * 12}pt` }}
            >
              <div
                className="edit-label mr-1"
                style={Utils.filterStyles(ele2.label)}
              >
                {ele2.label.value}
              </div>
              {isShowLabelEn && (
                <div
                  className="edit-label-en"
                  style={Utils.filterStyles(ele2.labelEn)}
                >
                  {ele2.labelEn.value}
                </div>
              )}
              <div style={Utils.filterStyles(ele2.label)}>:</div>
            </div>
          ),
        })
        tableRow = []
      }
    })

  return returnValue
}

const LogoContainer = (props) => {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()

  const setFirstInvoiceColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'firstInvoiceColumns',
      payload,
    })
  }
  const { activePanel } = useSelector((state) => state.templateInvoice)
  const { logoInfo, logoPosition } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const oldLogoContainerWidth = useMemo(() => 140, [])

  return (
    <>
      {logoInfo && (
        <Draggable
          bounds="#logo-container"
          handle=".lc-frame-background"
          cancel=".icon-gripsmall"
          disabled={activePanel !== 'logo_ctrl'}
          position={{ x: logoInfo.x, y: logoInfo.y }}
          onStop={(e, data) => dispatch(setLogoInfo({ x: data.x, y: data.y }))}
        >
          <Resizable
            width={logoInfo.width}
            height={logoInfo.height}
            maxWidth={'250px'}
            enable={
              activePanel !== 'logo_ctrl'
                ? false
                : {
                    top: false,
                    right: true,
                    bottom: true,
                    left: false,
                    topRight: false,
                    bottomRight: true,
                    bottomLeft: false,
                    topLeft: false,
                  }
            }
            size={{ width: logoInfo.width, height: logoInfo.height }}
            onResize={(e, direction, ref, d) => {
              let parentWidth =
                document.getElementById('logo-container')?.offsetWidth

              let style = window.getComputedStyle(ref)
              let matrix = new DOMMatrix(style.transform)
              let { m41: x, m42: y } = matrix

              let w = x + logoInfo.width + d.width
              if (w > parentWidth || parentWidth > oldLogoContainerWidth) {
                setFirstInvoiceColumns((oldColumns) => {
                  let index = logoPosition == 1 ? 0 : 1
                  const nextColumns = [...oldColumns]
                  nextColumns[index] = {
                    ...nextColumns[index],
                    width: w,
                  }
                  return nextColumns
                })
              }
            }}
            onResizeStop={(e, direction, ref, d) => {
              dispatch(
                setLogoInfo({
                  width: logoInfo.width + d.width,
                  height: logoInfo.height + d.height,
                }),
              )
            }}
          >
            <div
              className={`lc-frame-background w-100 h-100 ${
                activePanel === 'logo_ctrl' ? 'border border-primary' : ''
              }`}
              // style={{width: logoInfo.width + 'px', height: logoInfo.height + 'px'}}
            >
              <img
                className="w-100 h-100"
                src={
                  logoInfo?.logoFile instanceof File
                    ? URL.createObjectURL(logoInfo.logoFile)
                    : `${process.env.REACT_APP_BASE_URL}/resources/logo/${logoInfo?.logoFile}`
                }
                alt="..."
                draggable={false}
                title="Logo"
              />
              {activePanel === 'logo_ctrl' && (
                <div className="icon-gripsmall"></div>
              )}
            </div>
          </Resizable>
        </Draggable>
      )}
    </>
  )
}

/* SellerInfo */
const SellerInfo = (props) => {
  const dispatch = useDispatch()
  const { activeDataField, activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const sellerInfoRegion = useMemo(
    () => _.find(RegionConfigs, (region) => region.regionName === 'sellerInfo'),
    [RegionConfigs],
  )
  const regionConfigs = useMemo(
    () => sellerInfoRegion?.regionConfigs,
    [sellerInfoRegion],
  )

  const [maxWidth, setMaxWidth] = useState(0)

  useEffect(() => {
    setMaxWidth(0)

    if (sellerInfoRegion) {
      if (sellerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
        setTimeout(() => {
          let items = document.querySelectorAll('.SI-wrap')
          let mw = 0
          items.forEach((item) => {
            mw = Math.max(mw, item.offsetWidth)
          })

          setMaxWidth(mw)
        }, [0.2])
      }
    }
  }, [isShowLabelEn])

  useEffect(() => {
    if (!sellerInfoRegion) return

    if (sellerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
      let items = document.querySelectorAll('.SI-wrap')
      let mw = 0
      items.forEach((item) => {
        mw = Math.max(mw, item.offsetWidth)
      })

      setMaxWidth(mw)
    } else {
      setMaxWidth(0)
    }
  }, [sellerInfoRegion])

  useEffect(() => {
    if (_.isNumber(maxWidth)) {
      sessionStorage.setItem(PreferenceKeys.sellerInfoMaxWidth, maxWidth)
    }
  }, [maxWidth])

  return (
    <div
      className={`w-100 sellerInfo ${
        activeRegion?.regionName == 'sellerInfo' ||
        ['logo_ctrl', 'gen_ctrl'].includes(activePanel)
          ? 'region-active'
          : ''
      }`}
      style={{ minHeight: '100%' }}
      onClick={() => {
        if (activePanel === 'detail_ctrl') {
          dispatch(setActiveRegionBaseOnName('sellerInfo'))
        }
      }}
    >
      {regionConfigs &&
        TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
          regionConfigs,
        )?.map(([mergeField, ...regionConfArr], indexP) =>
          regionConfArr?.length > 1 ? (
            <ResizableFlexBox
              mergeField={mergeField}
              regionName="sellerInfo"
              regionConfArr={regionConfArr?.filter(({ show }) => show)}
              maxWidth={maxWidth}
              key={indexP}
            />
          ) : (
            <div className="d-flex flex-row" key={indexP}>
              {regionConfArr?.map((reConf, index) =>
                reConf.show ? (
                  !reConf?.typeShow || reConf?.typeShow == 3 ? (
                    <div
                      key={index}
                      className={`horizontal-content same-width-child ${
                        reConf.dataField === activeDataField?.dataField
                          ? 'active-data-field-l '
                          : ''
                      }`}
                    >
                      <div
                        className={`SI-wrap ${
                          reConf?.displayType === 'CONTENT_LEFT'
                            ? 'd-none'
                            : 'horizontal-content'
                        }`}
                        style={{ minWidth: maxWidth, whiteSpace: 'nowrap' }}
                      >
                        {/* label */}
                        <div
                          className={`SI-item edit-label mr-1 ${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : ''
                          }`}
                          style={Utils.filterStyles(reConf.label)}
                        >
                          {reConf.label.value}
                        </div>
                        {/* labelEn */}
                        {isShowLabelEn && (
                          <div
                            className={`SI-item edit-label-en ${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                            style={Utils.filterStyles(reConf.labelEn)}
                          >
                            {reConf.labelEn.value}
                          </div>
                        )}
                        {sellerInfoRegion?.alignType !== 'ALIGN_TYPE_3' && (
                          <div
                            className={`two-dot ${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                            style={Utils.filterStyles(reConf.label)}
                          >
                            :
                          </div>
                        )}
                      </div>
                      {sellerInfoRegion?.alignType === 'ALIGN_TYPE_3' && (
                        <div className="two-dot">
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : reConf.displayType === 'CONTENT_LEFT'
                                  ? 'd-none'
                                  : ''
                            }`}
                          >
                            :
                          </span>
                        </div>
                      )}
                      {/* value */}
                      <div
                        className={`SI-item edit-value ${
                          reConf?.displayType != 'CONTENT_LEFT' ? 'ml-1' : ''
                        }`}
                        style={{
                          ...Utils.filterStyles(reConf.value),
                          flexShrink: 100,
                        }}
                      >
                        {reConf.dataField.includes('TaxCode') &&
                        reConf?.mstSplitCells ? (
                          <div className="d-flex flex-row align-items-center tax-code-input mr-2">
                            {reConf.value.value.split('').map((num, index) => (
                              <div key={index} className="tax-code-number">
                                {num}
                              </div>
                            ))}
                          </div>
                        ) : (
                          reConf.value.value
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`SI-item edit-value ${
                        reConf.dataField === activeDataField?.dataField
                          ? 'active-data-field-l '
                          : ''
                      }`}
                      style={{
                        ...Utils.filterStyles(reConf.value),
                        flexShrink: 100,
                      }}
                    >
                      {reConf.value.value}
                    </div>
                  )
                ) : (
                  <Fragment key={index}></Fragment>
                ),
              )}
            </div>
          ),
        )}
    </div>
  )
}

/* InvoiceInfo */
const InvoiceInfo = (props) => {
  const dispatch = useDispatch()

  const { activeDataField, activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceInfoRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'invoiceInfo'),
    [RegionConfigs],
  )
  const regionConfigs = invoiceInfoRegion?.regionConfigs

  return (
    <div
      className="w-100 invoice-info"
      style={{ minHeight: '100%' }}
      onClick={() => {
        if (activePanel === 'detail_ctrl') {
          dispatch(setActiveRegionBaseOnName('invoiceInfo'))
        }
      }}
    >
      {regionConfigs?.map((reConf, index) =>
        reConf.show ? (
          <Fragment key={index}>
            {(!reConf?.typeShow || reConf?.typeShow === 2) && (
              <div
                className={`vertical-content text-center ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
              >
                <div
                  className="edit-label"
                  style={Utils.filterStyles(reConf.label)}
                >
                  {reConf.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(reConf.labelEn)}
                  >
                    {reConf.labelEn.value}
                  </div>
                )}
              </div>
            )}

            {reConf?.typeShow === 1 && (
              <div
                key={index}
                className={`text-center ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
                style={Utils.filterStyles(reConf.value)}
              >
                {reConf.value.value}
              </div>
            )}

            {reConf?.typeShow === 3 && (
              <div
                className={`horizontal-content justify-content-center ${
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : ''
                }`}
              >
                <div
                  className="edit-label mr-1"
                  style={Utils.filterStyles(reConf.label)}
                >
                  {reConf.label.value}
                </div>
                {isShowLabelEn && (
                  <div
                    className="edit-label-en"
                    style={Utils.filterStyles(reConf.labelEn)}
                  >
                    {reConf.labelEn.value}
                  </div>
                )}
                {reConf?.dataField != 'typeInvoice' && (
                  <div style={Utils.filterStyles(reConf.label)}>:</div>
                )}
                <div
                  className="edit-value ml-1"
                  style={Utils.filterStyles(reConf.value)}
                >
                  {reConf.value.value}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment key={index}></Fragment>
        ),
      )}
    </div>
  )
}

/* InvoiceInfoOther */
const InvoiceOtherInfo = (props) => {
  const dispatch = useDispatch()

  const { activeDataField, activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceInfoOtherRegion = useMemo(
    () =>
      _.find(
        RegionConfigs,
        (region) => region.regionName === 'invoiceInfoOther',
      ),
    [RegionConfigs],
  )
  const regionConfigs = invoiceInfoOtherRegion?.regionConfigs

  return (
    <table
      className="invoice-other-info"
      onClick={(e) => {
        e.stopPropagation()
        if (activePanel === 'detail_ctrl') {
          dispatch(setActiveRegionBaseOnName('invoiceInfoOther'))
        }
      }}
    >
      <colgroup>
        <col style={{ width: 'min-content' }}></col>
      </colgroup>
      <tbody>
        {regionConfigs?.map((reConf, index) =>
          reConf.show ? (
            <tr>
              <td>
                <p
                  className="mb-0"
                  // key={index}
                  // className={`horizontal-content ${
                  //   reConf.dataField === activeDataField?.dataField
                  //     ? 'active-data-field-l '
                  //     : ''
                  // }`}
                >
                  <span
                    className="edit-label mr-1"
                    style={Utils.filterStyles(reConf.label)}
                  >
                    {reConf.label.value}
                  </span>
                  {isShowLabelEn && (
                    <span
                      className="edit-label-en"
                      style={Utils.filterStyles(reConf.labelEn)}
                    >
                      {reConf.labelEn.value}
                    </span>
                  )}
                  <span style={Utils.filterStyles(reConf.label)}>:</span>
                </p>
              </td>
              <td>
                <span
                  className="edit-value ml-1"
                  style={Utils.filterStyles(reConf.value)}
                >
                  {reConf.value.value}
                </span>
              </td>
            </tr>
          ) : (
            <></>
          ),
        )}
      </tbody>
    </table>
  )
}

/* "InvoiceTable" */
const InvoiceTable = (props) => {
  const columnsDispatch = useColumnsDispatch()

  const invoiceInfoColumns = useColumns('invoiceInfoColumns')
  const setInvoiceInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'invoiceInfoColumns',
      payload,
    })
  }

  const { activeRegion, activePanel } = useSelector(
    (state) => state.templateInvoice,
  )
  const { sellerInfoPosition } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const invoiceTableData = useMemo(() => {
    return [
      {
        col1: sellerInfoPosition == 3 ? <SellerInfo /> : <></>,
        col2: <InvoiceInfo />,
        col3: <InvoiceOtherInfo />,
      },
    ]
  }, [sellerInfoPosition])

  const invoiceTableActive = useMemo(
    () =>
      ['gen_ctrl'].includes(activePanel) ||
      (sellerInfoPosition == 1 &&
        (activeRegion?.regionName === 'invoiceInfo' ||
          activeRegion?.regionName === 'invoiceInfoOther')),
    [activePanel, sellerInfoPosition, activeRegion],
  )

  return (
    <Table
      className={`custom-resizable-table invoiceInfo ${
        invoiceTableActive ? 'region-active' : ''
      }`}
      showHeader={false}
      tableLayout="fixed"
      emptyText={<></>}
      components={{
        body: {
          cell: ResizableCell,
        },
      }}
      columns={invoiceInfoColumns.map((col, indexCol) => ({
        ...col,
        onCell: (_) => ({
          width: col.width,
          resizeEnable: invoiceTableActive,
          onResize: handleResize(indexCol, setInvoiceInfoColumns),
        }),
      }))}
      data={invoiceTableData}
    />
  )
}

/* BuyerInfoContent */
const BuyerInfoContent = (props) => {
  const { activeDataField } = useSelector((state) => state.templateInvoice)
  const { isShowLabelEn, RegionConfigs } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const buyerInfoRegion = useMemo(
    () => _.find(RegionConfigs, (region) => region.regionName === 'buyerInfo'),
    [RegionConfigs],
  )
  const regionConfigs = useMemo(
    () => buyerInfoRegion?.regionConfigs,
    [buyerInfoRegion],
  )

  const [maxWidth, setMaxWidth] = useState(0)

  useEffect(() => {
    setMaxWidth(0)

    if (buyerInfoRegion) {
      if (buyerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
        setTimeout(() => {
          let items = document.querySelectorAll('.BIC-wrap')
          let mw = 0
          items.forEach((item) => {
            mw = Math.max(mw, item.offsetWidth)
          })

          setMaxWidth(mw)
        }, [0.2])
      }
    }
  }, [isShowLabelEn])

  useEffect(() => {
    if (!buyerInfoRegion) return

    if (buyerInfoRegion.alignType !== 'ALIGN_TYPE_1') {
      let items = document.querySelectorAll('.BIC-wrap')
      let mw = 0
      items.forEach((item) => {
        mw = Math.max(mw, item.offsetWidth)
      })

      setMaxWidth(mw)
    } else {
      setMaxWidth(0)
    }
  }, [buyerInfoRegion])

  useEffect(() => {
    if (_.isNumber(maxWidth)) {
      sessionStorage.setItem(PreferenceKeys.buyerInfoMaxWidth, maxWidth)
    }
  }, [maxWidth])

  return (
    <div
      className="w-100 buyerInfoContent"
      style={{ minHeight: '100%' }}
      id="buyerInfoContent"
    >
      {regionConfigs &&
        TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
          regionConfigs,
        )?.map(([mergeField, ...regionConfArr], indexP) => {
          return regionConfArr?.length > 1 ? (
            <ResizableFlexBox
              mergeField={mergeField}
              regionName="buyerInfo"
              regionConfArr={regionConfArr?.filter(({ show }) => show)}
              maxWidth={maxWidth}
              key={indexP}
            />
          ) : (
            <div className="d-flex flex-row" key={indexP}>
              {regionConfArr.map((reConf, index) =>
                reConf.show ? (
                  <div
                    key={index}
                    className={`horizontal-content same-width-child ${
                      reConf.dataField === activeDataField?.dataField
                        ? 'active-data-field-l '
                        : ''
                    }`}
                    style={{ marginLeft: `${(reConf.mrRank || 0) * 12}pt` }}
                  >
                    <div
                      className={`BIC-wrap ${
                        reConf?.displayType === 'CONTENT_LEFT'
                          ? 'd-none'
                          : 'd-flex flew-row align-items-center'
                      }`}
                      style={{ minWidth: maxWidth, whiteSpace: 'nowrap' }}
                    >
                      {/* label */}
                      <div
                        className="BIC-item edit-label mr-1"
                        style={Utils.filterStyles(reConf.label)}
                      >
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : ''
                          }`}
                        >
                          {reConf.label.value}
                        </span>
                      </div>
                      {/* labelEn */}
                      {isShowLabelEn && (
                        <div
                          className="BIC-item edit-label-en"
                          style={Utils.filterStyles(reConf.labelEn)}
                        >
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                          >
                            {reConf.labelEn.value}
                          </span>
                        </div>
                      )}
                      {buyerInfoRegion?.alignType !== 'ALIGN_TYPE_3' && (
                        <div className="two-dot">
                          <span
                            className={`${
                              reConf?.displayType === 'CONTENT'
                                ? 'hidden-span'
                                : ''
                            }`}
                            style={Utils.filterStyles(reConf.label)}
                          >
                            :
                          </span>
                        </div>
                      )}
                    </div>
                    {buyerInfoRegion?.alignType === 'ALIGN_TYPE_3' && (
                      <div className="two-dot">
                        <span
                          className={`${
                            reConf?.displayType === 'CONTENT'
                              ? 'hidden-span'
                              : reConf.displayType === 'CONTENT_LEFT'
                                ? 'd-none'
                                : ''
                          }`}
                        >
                          :
                        </span>
                      </div>
                    )}
                    {/* value */}
                    <div
                      className="BIC-item edit-value ml-1"
                      style={{
                        ...Utils.filterStyles(reConf.value),
                        flexShrink: 100,
                      }}
                    >
                      {reConf.dataField.includes('TaxCode') &&
                      reConf?.mstSplitCells ? (
                        <div className="d-flex flex-row align-items-center tax-code-input mr-2">
                          {reConf.value.value.split('').map((num, index) => (
                            <div key={index} className="tax-code-number">
                              {num}
                            </div>
                          ))}
                        </div>
                      ) : (
                        reConf.value.value
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
          )
        })}
    </div>
  )
}

/* QRCode */
const QRCode = (props) => {
  let { isShowQRCode, paperSize } = useSelector(
    (state) => state.templateInvoice.instance,
  )
  return isShowQRCode ? (
    <div
      style={{
        width: paperSize == 'A4' ? '80px' : '70px',
        height: paperSize == 'A4' ? '80px' : '70px',
        backgroundImage: `url(${AppResource.images.img_qr_code})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    ></div>
  ) : (
    <></>
  )
}

const buyerInfoData = [
  {
    col1: <BuyerInfoContent />,
    col2: <QRCode />,
  },
]

function LeftContent(props) {
  const dispatch = useDispatch()
  const {
    className,
    style,
    firstInvoiceColumns,
    setFirstInvoiceColumns,
    buyerInfoColumns,
    setBuyerInfoColumns,
    tableDetailColumns,
    setTableDetailColumns,
    tableDetail1Columns,
    setTableDetail1Columns,
    tableFooterColumns,
    setTableFooterColumns,
    tableHospitalColumns,
    setTableHospitalColumns,
    tableFooter1Columns,
    setTableFooter1Columns,
    signXmlColumns,
    setSignXmlColumns,
  } = props

  const {
    activePanel,
    activeRegion,
    activeDataField,
    mulTaxRate,
    showWatermark,
  } = useSelector((state) => state.templateInvoice)
  const {
    sellerInfoPosition,
    paperSize,
    logoPosition,
    backgroundInfo,
    customBackgroundInfo,
    surroundInfo,
    watermarkInfo,
    fontSize,
    color,
    lineHeight,
    fontFamily,
    lineType,
    isShowLabelEn,
    isShowQRCode,
    RegionConfigs,
    category,
  } = useSelector((state) => state.templateInvoice.instance)

  const tableDetailRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'tableDetail'),
    [RegionConfigs],
  )
  const tableDetailRegionConfigs = useMemo(
    () => tableDetailRegion?.regionConfigs,
    [tableDetailRegion],
  )

  useEffect(() => {
    if (tableDetailRegionConfigs) {
      setTableDetailColumns((old) => {
        let indexShowWidthUndefined = tableDetailRegionConfigs.findIndex(
          ({ dataField, show }) =>
            !old.find((oldI) => oldI.dataIndex === dataField)?.width && show,
        )
        let lastIndexShowWidthUndefined =
          tableDetailRegionConfigs.findLastIndex(
            ({ dataField, show }) =>
              !old.find((oldI) => oldI.dataIndex === dataField)?.width && show,
          )
        let lastIndexShowWidth = tableDetailRegionConfigs.findLastIndex(
          ({ dataField, show }) =>
            old.find((oldI) => oldI.dataIndex === dataField)?.width && show,
        )
        let genTitle = (reConf, index) => (
          <div key={index} className={`vertical-content`}>
            <div
              className="edit-label w-100"
              style={Utils.filterStyles(reConf.label)}
            >
              {reConf.label?.value}
            </div>
            {isShowLabelEn && (
              <div
                className="edit-label-en w-100"
                style={Utils.filterStyles(reConf.labelEn)}
              >
                {reConf.labelEn?.value}
              </div>
            )}
          </div>
        )

        return (
          tableDetailRegionConfigs
            // .filter(({ show }) => show) /* không lọc ngay, vì cần giữ lại width */
            .map((reConf, index, arr) => {
              let width =
                index == lastIndexShowWidth && indexShowWidthUndefined == -1
                  ? undefined
                  : index == indexShowWidthUndefined &&
                      index != lastIndexShowWidthUndefined
                    ? 60
                    : old.find((oldI) => oldI.dataIndex === reConf.dataField)
                        ?.width || reConf.width
              let children = reConf.children?.map((item, indexC) => ({
                className:
                  activeDataField &&
                  item.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : '',
                dataIndex: item.dataField,
                key: item.dataField,
                width: width / 2,
                title: genTitle(item, indexC),
                show: item.show,
              }))

              return {
                className:
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : '',
                dataIndex: reConf.dataField,
                key: reConf.dataField,
                width,
                title: genTitle(reConf, index),
                show: reConf.show,
                children,
              }
            })
        )
      })
    }
  }, [tableDetailRegionConfigs, activeDataField, isShowLabelEn])

  const tableDetail1Region = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'tableDetail1'),
    [RegionConfigs],
  )
  const tableDetail1RegionConfigs = useMemo(
    () => tableDetail1Region?.regionConfigs,
    [tableDetail1Region],
  )

  useEffect(() => {
    if (tableDetail1RegionConfigs) {
      setTableDetail1Columns((old) => {
        let indexShowWidthUndefined = tableDetail1RegionConfigs.findIndex(
          ({ dataField, show }) =>
            !old.find((oldI) => oldI.dataIndex === dataField)?.width && show,
        )
        let lastIndexShowWidthUndefined =
          tableDetail1RegionConfigs.findLastIndex(
            ({ dataField, show }) =>
              !old.find((oldI) => oldI.dataIndex === dataField)?.width && show,
          )
        let lastIndexShowWidth = tableDetail1RegionConfigs.findLastIndex(
          ({ dataField, show }) =>
            old.find((oldI) => oldI.dataIndex === dataField)?.width && show,
        )

        let genTitle = (reConf, index) => (
          <div key={index} className={`vertical-content`}>
            <div
              className="edit-label w-100"
              style={Utils.filterStyles(reConf.label)}
            >
              {reConf.label.value}
            </div>
            {isShowLabelEn && (
              <div
                className="edit-label-en w-100"
                style={Utils.filterStyles(reConf.labelEn)}
              >
                {reConf.labelEn.value}
              </div>
            )}
          </div>
        )
        return (
          tableDetail1RegionConfigs
            // .filter(({ show }) => show) /* không lọc ngay, vì cần giữ lại width */
            .map((reConf, index, arr) => {
              let width =
                index == lastIndexShowWidth && indexShowWidthUndefined == -1
                  ? undefined
                  : index == indexShowWidthUndefined &&
                      index != lastIndexShowWidthUndefined
                    ? 100
                    : old.find((oldI) => oldI.dataIndex === reConf.dataField)
                        ?.width || reConf.width
              let children = reConf.children?.map((item, indexC) => ({
                className:
                  activeDataField &&
                  item.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : '',
                dataIndex: item.dataField,
                key: item.dataField,
                width: width / 2,
                title: genTitle(item, indexC),
                show: item.show,
              }))
              return {
                className:
                  reConf.dataField === activeDataField?.dataField
                    ? 'active-data-field-l '
                    : '',
                dataIndex: reConf.dataField,
                key: reConf.dataField,
                width,
                title: genTitle(reConf, index),
                show: reConf.show,
                children,
              }
            })
        )
      })
    }
  }, [tableDetail1RegionConfigs, activeDataField, isShowLabelEn])

  const tableFooterRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'tableFooter'),
    [RegionConfigs],
  )
  const tableFooterRegionConfigs = useMemo(
    () => tableFooterRegion?.regionConfigs,
    [tableFooterRegion],
  )
  const tableHospitalRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'tableHospital'),
    [RegionConfigs],
  )
  const tableHospitalRegionConfigs = useMemo(
    () => tableHospitalRegion?.regionConfigs,
    [tableHospitalRegion],
  )
  const discountCheck = useMemo(
    () =>
      tableFooterRegionConfigs?.find(({ dataField }) =>
        ['totalDiscountAmount'].includes(dataField),
      )?.show,
    [tableFooterRegionConfigs],
  )

  const tableFooter1Region = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'tableFooter1'),
    [RegionConfigs],
  )
  const tableFooter1RegionConfigs = useMemo(
    () => tableFooter1Region?.regionConfigs,
    [tableFooter1Region],
  )
  const discount1Check = useMemo(
    () =>
      tableFooter1RegionConfigs?.find(({ dataField }) =>
        ['TotalDiscountAmount'].includes(dataField),
      )?.show,
    [tableFooter1RegionConfigs],
  )

  const currencyBlockRegion = useMemo(
    () =>
      _.find(RegionConfigs, (region) => region.regionName === 'currencyBlock'),
    [RegionConfigs],
  )
  const currencyBlockRegionConfigs = useMemo(
    () => currencyBlockRegion?.regionConfigs,
    [currencyBlockRegion],
  )

  const exchangeRateField = currencyBlockRegion?.regionConfigs.find(
    ({ dataField }) => dataField === 'exchangeRate',
  )

  const advanceAmountField = currencyBlockRegion?.regionConfigs.find(
    ({ dataField }) => dataField === 'advanceAmount',
  )

  const signXmlRegion = useMemo(
    () => _.find(RegionConfigs, (region) => region.regionName === 'signXml'),
    [RegionConfigs],
  )

  const signRegionConfigsGroupByMergeField = useMemo(() => {
    let signXmlRegionConfigs = signXmlRegion?.regionConfigs
    return TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc(
      signXmlRegionConfigs,
    )
  }, [signXmlRegion])

  // useEffect(() => {
  //   if (signRegionConfigsGroupByMergeField?.length) {
  //     setSignXmlColumns(
  //       signRegionConfigsGroupByMergeField.map(([mergeField], index) => ({
  //         title: '',
  //         dataIndex: mergeField,
  //         key: mergeField,
  //         width: index != signRegionConfigsGroupByMergeField.length - 1 ? '33%' : null,
  //       }))
  //     );
  //   }
  // }, [signRegionConfigsGroupByMergeField]);

  let firstInvoiceData = useMemo(() => {
    let content = sellerInfoPosition == 1 ? <SellerInfo /> : <InvoiceTable />
    let leftContent = logoPosition == 1 ? <LogoContainer /> : content
    let rightContent = logoPosition == 1 ? content : <LogoContainer />

    return [{ col1: leftContent, col2: rightContent }]
  }, [sellerInfoPosition, logoPosition])

  let firstInvoiceActive = useMemo(
    () =>
      ['logo_ctrl', 'gen_ctrl'].includes(activePanel) ||
      (sellerInfoPosition == 1 && activeRegion?.regionName === 'sellerInfo') ||
      (sellerInfoPosition == 2 &&
        (activeRegion?.regionName === 'invoiceInfo' ||
          activeRegion?.regionName === 'invoiceInfoOther')) ||
      (sellerInfoPosition == 3 &&
        (activeRegion?.regionName === 'sellerInfo' ||
          activeRegion?.regionName === 'invoiceInfo' ||
          activeRegion?.regionName === 'invoiceInfoOther')),
    [activePanel, sellerInfoPosition, activeRegion],
  )

  let buyerInfoActive = useMemo(
    () =>
      activeRegion?.regionName === 'buyerInfo' ||
      (activePanel == 'gen_ctrl' && isShowQRCode),
    [activeRegion, activePanel, isShowQRCode],
  )

  let pageWidth = useMemo(
    () => TemplateInvoiceHelper.widthBaseOnPaperSize(paperSize),
    [paperSize],
  )
  let pageHeight = useMemo(
    () => TemplateInvoiceHelper.heightBaseOnPaperSize(paperSize),
    [paperSize],
  )

  return (
    <div className={className} style={{ ...style, overflow: 'hidden scroll' }}>
      <div
        className="lc-frame"
        id="lc-frame"
        style={{
          width: pageWidth,
          minHeight: pageHeight,
          height: 'auto',
        }}
      >
        {/* surround */}
        {surroundInfo?.surroundData &&
          (surroundInfo.surroundData?.filename?.endsWith('.svg') ? (
            <ReactSVG
              className="position-absolute lc-frame-surround"
              src={`${process.env.REACT_APP_BASE_URL}/resources/border/${surroundInfo.surroundData.filename}`}
              beforeInjection={(svg) => {
                let parent = document.querySelector('.lc-frame-surround')
                svg.setAttribute(
                  'style',
                  paperSize == 'A5'
                    ? `width: ${parent.offsetHeight}px;height: ${parent.offsetWidth}px;transform: rotate(-90deg);transform-origin: 280px 280px;`
                    : 'height: 100%;width: 100%;',
                )
                svg.setAttribute('fill', surroundInfo?.surroundColor)
              }}
            />
          ) : (
            <div
              className="position-absolute lc-frame-surround"
              style={
                paperSize != 'A5'
                  ? {
                      backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/border/${surroundInfo.surroundData.filename})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                    }
                  : {}
              }
            >
              {paperSize == 'A5' ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/resources/border/${surroundInfo.surroundData.filename}`}
                  style={{
                    height: pageWidth,
                    width: pageHeight,
                    transform: `rotate(90deg) translate(0,-${pageWidth})`,
                    transformOrigin: '0 0',
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          ))}

        {/* background image */}
        {backgroundInfo &&
          backgroundInfo.backgroundData?.name?.startsWith('mau_') && (
            <div
              className="position-absolute lc-frame-surround"
              style={
                paperSize != 'A5'
                  ? {
                      backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo.backgroundData.filename})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      // backgroundPosition: '-5px -1px',
                      opacity: backgroundInfo.opacity / 100,
                    }
                  : {}
              }
            >
              {paperSize == 'A5' ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo.backgroundData.filename}`}
                  style={{
                    height: pageWidth,
                    width: pageHeight,
                    opacity: backgroundInfo.opacity / 100,
                    transform: `rotate(90deg) translate(0,-${pageWidth})`,
                    transformOrigin: '0 0',
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        {/* background svg */}
        {backgroundInfo &&
          !backgroundInfo.backgroundData?.name?.startsWith('mau_') && (
            <Rnd
              bounds=".lc-frame"
              lockAspectRatio={true}
              disableDragging={activePanel !== 'logo_ctrl'}
              enableResizing={
                activePanel !== 'logo_ctrl'
                  ? false
                  : {
                      top: false,
                      right: true,
                      bottom: true,
                      left: false,
                      topRight: false,
                      bottomRight: true,
                      bottomLeft: false,
                      topLeft: false,
                    }
              }
              style={{ zIndex: activePanel === 'logo_ctrl' ? 2 : 1 }}
              size={{
                width: backgroundInfo.width,
                height: backgroundInfo.height,
              }}
              position={{ x: backgroundInfo.x, y: backgroundInfo.y }}
              onDragStop={(e, d) => {
                e.preventDefault()
                dispatch(setBackgroundInfo({ x: d.x, y: d.y }))
              }}
              onResizeStop={(e, direction, ref, delta, position) => {
                dispatch(
                  setBackgroundInfo({
                    x: position.x,
                    y: position.y,
                    width: parseFloat(
                      ref.style.width.slice(ref.style.width - 1),
                    ),
                    height: parseFloat(
                      ref.style.height.slice(ref.style.height - 1),
                    ),
                  }),
                )
              }}
            >
              <div
                className={`lc-frame-background w-100 h-100 ${
                  activePanel === 'logo_ctrl' ? 'border border-primary' : ''
                }`}
              >
                {backgroundInfo.backgroundData?.filename?.endsWith('.svg') ? (
                  <ReactSVG
                    className="w-100 h100"
                    title="File hình nền"
                    src={`${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo?.backgroundData.filename}`}
                    beforeInjection={(svg) => {
                      svg.setAttribute(
                        'style',
                        `opacity: ${backgroundInfo?.opacity / 100}`,
                      )
                      svg.setAttribute('fill', backgroundInfo?.backgroundColor)
                    }}
                  />
                ) : (
                  <img
                    draggable={false}
                    className="w-100 h-100"
                    src={`${process.env.REACT_APP_BASE_URL}/resources/background/${backgroundInfo?.backgroundData.filename}`}
                    style={{ opacity: backgroundInfo?.opacity / 100 }}
                  />
                )}
                {activePanel === 'logo_ctrl' && (
                  <div className="icon-gripsmall"></div>
                )}
              </div>
            </Rnd>
          )}

        {/* customBackground */}
        {customBackgroundInfo?.file && (
          <Rnd
            bounds=".lc-frame"
            lockAspectRatio={true}
            disableDragging={activePanel !== 'logo_ctrl'}
            enableResizing={
              activePanel !== 'logo_ctrl'
                ? false
                : {
                    top: false,
                    right: true,
                    bottom: true,
                    left: false,
                    topRight: false,
                    bottomRight: true,
                    bottomLeft: false,
                    topLeft: false,
                  }
            }
            style={{ zIndex: activePanel === 'logo_ctrl' ? 2 : 1 }}
            size={{
              width: customBackgroundInfo.width,
              height: customBackgroundInfo.height,
            }}
            position={{ x: customBackgroundInfo.x, y: customBackgroundInfo.y }}
            onDragStop={(e, d) => {
              dispatch(setCustomBackgroundInfo({ x: d.x, y: d.y }))
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              dispatch(
                setCustomBackgroundInfo({
                  x: position.x,
                  y: position.y,
                  width: parseFloat(ref.style.width.slice(ref.style.width - 1)),
                  height: parseFloat(
                    ref.style.height.slice(ref.style.height - 1),
                  ),
                }),
              )
            }}
          >
            <div
              className={`lc-frame-background w-100 h-100 ${
                activePanel === 'logo_ctrl' ? 'border border-primary' : ''
              }`}
            >
              <img
                className="w-100 h-100"
                src={
                  customBackgroundInfo?.file instanceof File
                    ? URL.createObjectURL(customBackgroundInfo.file)
                    : `${process.env.REACT_APP_BASE_URL}/resources/customBackground/${customBackgroundInfo?.file}`
                }
                alt="..."
                draggable={false}
                title="File hình nền tùy chỉnh"
                style={{ opacity: (customBackgroundInfo?.opacity || 0) / 100 }}
              />
              {activePanel === 'logo_ctrl' && (
                <div className="icon-gripsmall"></div>
              )}
            </div>
          </Rnd>
        )}

        {/* watermark */}
        {showWatermark && (
          <div
            className="lc-frame-watermark"
            style={{
              backgroundImage: `url(${AppResource.images.img_watermark})`,
              opacity: (watermarkInfo?.opacity || 0) / 100,
            }}
          ></div>
        )}

        {/* content */}
        <div
          className="lc-frame-content"
          style={{
            fontSize,
            lineHeight,
            fontFamily,
            color,

            whiteSpace: 'nowrap',
            width: `calc(100% - ${paperSize == 'A4' ? '30pt' : '20pt'} * 2)`,
            marginLeft: paperSize == 'A4' ? '30pt' : '20pt',
            marginRight: paperSize == 'A4' ? '30pt' : '20pt',
            paddingTop: paperSize == 'A4' ? '30pt' : '20pt',
            paddingBottom: paperSize == 'A4' ? '30pt' : '20pt',
          }}
        >
          <div
            className="lc-frame-content-detail"
            id="lc-frame-content-detail"
            style={{ minHeight: paperSize == 'A4' ? '975px' : '460px' }}
          >
            {/* invoice-first */}
            <div className="invoice-first border-bottom">
              <Table
                className={`custom-resizable-table firstInvoice ${
                  firstInvoiceActive ? 'region-active' : ''
                }`}
                showHeader={false}
                tableLayout="fixed"
                emptyText={<></>}
                components={{
                  body: {
                    cell: ResizableCell,
                  },
                }}
                columns={firstInvoiceColumns.map((col, indexCol) => ({
                  ...col,
                  onCell: (_) => ({
                    id: col.id,
                    width: col.width,
                    resizeEnable: firstInvoiceActive,
                    onResize: handleResize(indexCol, setFirstInvoiceColumns),
                  }),
                }))}
                data={firstInvoiceData}
              />
            </div>

            {/* invoice-second */}
            <div
              className="invoice-second border-bottom" /* style={{ marginTop: '2pt' }} */
            >
              {sellerInfoPosition == 1 ? (
                <InvoiceTable />
              ) : sellerInfoPosition == 2 ? (
                <SellerInfo />
              ) : (
                <></>
              )}
            </div>

            {/* buyerInfo */}
            <div
              onClick={() => {
                if (activePanel === 'detail_ctrl') {
                  dispatch(setActiveRegionBaseOnName('buyerInfo'))
                }
              }}
              style={{ marginTop: '2pt' }}
            >
              <Table
                className={`custom-resizable-table buyerInfo ${
                  buyerInfoActive ? 'region-active' : ''
                }`}
                showHeader={false}
                tableLayout="fixed"
                emptyText={<></>}
                components={{
                  body: {
                    cell: ResizableCell,
                  },
                }}
                columns={buyerInfoColumns.map((col, indexCol) => ({
                  ...col,
                  onCell: (_, indexRow) => {
                    let props = {
                      width: col.width,
                      resizeEnable: buyerInfoActive,
                      onResize: handleResize(indexCol, setBuyerInfoColumns),
                      maxWidth:
                        document.getElementById('lc-frame-content-detail')
                          ?.offsetWidth - (paperSize == 'A5' ? 72 : 82),
                    }

                    return props
                  },
                }))}
                data={buyerInfoData}
              />
            </div>

            {/* tableDetail */}
            {!mulTaxRate && (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('tableDetail'))
                  }
                }}
              >
                <Table
                  className={`custom-resizable-table vertical-align-middle tableDetail realtable ${
                    activeRegion?.regionName === 'tableDetail'
                      ? 'region-active'
                      : ''
                  }`}
                  tableLayout="fixed"
                  emptyText={<></>}
                  components={{
                    header: {
                      cell: ResizableTitle,
                    },
                    body: {
                      cell: ResizableCell,
                    },
                  }}
                  columns={tableDetailColumns
                    .map((col, index) => ({
                      ...col,
                      key: col.dataField,
                      onHeaderCell: (column) => {
                        return {
                          dataIndex: col.dataIndex,
                          tableHeaderColor: tableDetailRegion?.tableHeaderColor,
                          width: column.width,
                          resizeEnable:
                            activeRegion?.regionName === 'tableDetail',
                          onResize: handleResize(index, setTableDetailColumns),
                        }
                      },
                      onCell: () => {
                        return {
                          width: col.width,
                          resizeEnable:
                            activeRegion?.regionName === 'tableDetail',
                          lineType,
                          // color,
                          onResize: handleResize(index, setTableDetailColumns),
                        }
                      },
                    }))
                    .filter(({ show }) => show)}
                  data={
                    (tableDetailRegion &&
                      genTableDetailData(
                        tableDetailRegion?.minItemRows,
                        tableDetailRegionConfigs?.map(
                          ({ dataField }) => dataField,
                        ),
                      )) ||
                    []
                  }
                />
              </div>
            )}

            {/* tableDetail1 */}
            {mulTaxRate && (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('tableDetail1'))
                  }
                }}
              >
                <Table
                  className={`custom-resizable-table vertical-align-middle tableDetail1 realtable ${
                    activeRegion?.regionName === 'tableDetail1'
                      ? 'region-active'
                      : ''
                  }`}
                  tableLayout="fixed"
                  emptyText={<></>}
                  components={{
                    header: {
                      cell: ResizableTitle,
                    },
                    body: {
                      cell: ResizableCell,
                    },
                  }}
                  columns={tableDetail1Columns
                    .map((col, index) => ({
                      ...col,
                      key: col.dataField,
                      onHeaderCell: (column) => {
                        return {
                          tableHeaderColor:
                            tableDetail1Region?.tableHeaderColor,
                          width: column.width,
                          resizeEnable:
                            activeRegion?.regionName === 'tableDetail1',
                          onResize: handleResize(index, setTableDetail1Columns),
                        }
                      },
                      onCell: () => {
                        return {
                          width: col.width,
                          resizeEnable:
                            activeRegion?.regionName === 'tableDetail1',
                          lineType,
                          // color,
                          onResize: handleResize(index, setTableDetail1Columns),
                        }
                      },
                    }))
                    .filter(({ show }) => show)}
                  data={
                    (tableDetail1Region &&
                      genTableDetailData(
                        tableDetail1Region?.minItemRows,
                        tableDetail1RegionConfigs?.map(
                          ({ dataField }) => dataField,
                        ),
                      )) ||
                    []
                  }
                />
              </div>
            )}

            {/* tableFooter */}
            {!mulTaxRate && (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('tableFooter'))
                  }
                }}
                className={`${category?.code === 'BV' ? 'tableHospital' : ''}`}
              >
                <Table
                  className={`custom-resizable-table vertical-align-middle tableFooter realtable ${
                    activeRegion?.regionName === 'tableFooter'
                      ? 'region-active'
                      : ''
                  }`}
                  showHeader={false}
                  tableLayout="fixed"
                  emptyText={<></>}
                  components={{
                    body: {
                      cell: ResizableCell,
                    },
                  }}
                  columns={tableFooterColumns.map((col, indexCol) => ({
                    ...col,
                    onCell: (_, indexRow) => {
                      let props = {
                        width: col.width,
                        resizeEnable:
                          activeRegion?.regionName === 'tableFooter',
                        onResize: handleResize(indexCol, setTableFooterColumns),
                      }

                      // Sửa theo yêu cầu bv C ngày 10/06/2023. Chỉ áp dụng với bv
                      if (category?.code === 'BV') {
                        props.lineType = 'none'
                        props.className = 'cell-hospital'
                      }
                      if (
                        tableFooterRegion?.fullColumnRowIndexs
                          ?.map((i) => (discountCheck ? i + 1 : i))
                          ?.includes(indexRow)
                      ) {
                        if (indexCol === 0) {
                          props.colSpan = 4
                        } else {
                          props.colSpan = 0
                        }
                      }
                      return props
                    },
                  }))}
                  data={genTableFooterData(
                    tableFooterRegion?.type || 1,
                    tableFooterRegionConfigs,
                    activeDataField?.dataField,
                    isShowLabelEn,
                    tableFooterRegion?.fullColumnDataFields,
                  )}
                />
              </div>
            )}

            {/* tableFooter1 */}
            {mulTaxRate && (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('tableFooter1'))
                  }
                }}
              >
                <Table
                  className={`custom-resizable-table vertical-align-middle tableFooter1 ${
                    tableFooter1Region?.type == 2 ? 'type-2' : ''
                  } realtable ${
                    activeRegion?.regionName === 'tableFooter1'
                      ? 'region-active'
                      : ''
                  }`}
                  showHeader={false}
                  tableLayout="fixed"
                  emptyText={<></>}
                  components={{
                    body: {
                      cell: ResizableCell,
                    },
                  }}
                  columns={tableFooter1Columns.map((col, indexCol) => ({
                    ...col,
                    onCell: (_, indexRow) => {
                      let props = {
                        width: col.width,
                        resizeEnable:
                          activeRegion?.regionName === 'tableFooter1',
                        onResize: handleResize(
                          indexCol,
                          setTableFooter1Columns,
                        ),
                      }
                      // if (tableFooter1Region?.type == 1) {
                      //   if (
                      //     indexRow >
                      //     (tableFooter1RegionConfigs?.filter(({ show }) => show)?.length || 0) - 6
                      //   ) {

                      //     if (indexCol === 0) {
                      //       props.colSpan = 4;
                      //     } else {
                      //       props.colSpan = 0;
                      //     }
                      //   }
                      // } else {
                      if (
                        tableFooter1Region?.fullColumnRowIndexs
                          ?.map((i) => (discount1Check ? i + 1 : i))
                          ?.includes(indexRow)
                      ) {
                        if (indexCol === 0) {
                          props.colSpan = 4
                        } else {
                          props.colSpan = 0
                        }
                      }
                      // }

                      return props
                    },
                  }))}
                  data={genTableFooter1Data(
                    tableFooter1Region?.type || 1,
                    tableFooter1RegionConfigs,
                    activeDataField?.dataField,
                    isShowLabelEn,
                    discount1Check,
                  )}
                />
              </div>
            )}

            {/* currencyBlock */}
            {exchangeRateField?.show ? (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('currencyBlock'))
                  }
                }}
                className={`${
                  activeRegion?.regionName === 'currencyBlock'
                    ? 'region-active'
                    : ''
                }`}
              >
                {currencyBlockRegionConfigs?.map((reConf, index) =>
                  reConf?.show ? (
                    <div
                      key={index}
                      className={`horizontal-content ${
                        reConf.dataField === activeDataField?.dataField
                          ? 'active-data-field-l '
                          : ''
                      }`}
                    >
                      <div
                        className="edit-label mr-1"
                        style={Utils.filterStyles(reConf.label)}
                      >
                        {reConf.label.value}
                      </div>
                      {isShowLabelEn && (
                        <div
                          className="edit-label-en"
                          style={Utils.filterStyles(reConf.labelEn)}
                        >
                          {reConf.labelEn.value}
                        </div>
                      )}
                      <div style={Utils.filterStyles(reConf.label)}>:</div>
                      {reConf.value.value && (
                        <div
                          className="edit-value ml-1"
                          style={Utils.filterStyles(reConf.value)}
                        >
                          {reConf.value.value}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  ),
                )}
              </div>
            ) : (
              <></>
            )}

            {/* tableHospital */}
            {category?.code == 'BV' && (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('tableHospital'))
                  }
                }}
              >
                <Table
                  className={`custom-resizable-table vertical-align-middle tableHospital realtable ${
                    activeRegion?.regionName === 'tableHospital'
                      ? 'region-active'
                      : ''
                  }`}
                  showHeader={false}
                  tableLayout="fixed"
                  emptyText={<></>}
                  components={{
                    body: {
                      cell: ResizableCell,
                    },
                  }}
                  columns={tableHospitalColumns?.map((col, indexCol) => ({
                    ...col,
                    onCell: (_, indexRow) => {
                      let props = {
                        width: col.width,
                        className: 'cell-hospital',
                        resizeEnable:
                          activeRegion?.regionName === 'tableHospital',
                        onResize: handleResize(
                          indexCol,
                          setTableHospitalColumns,
                        ),
                      }
                      return props
                    },
                  }))}
                  data={genTableHospitalData(
                    tableHospitalRegionConfigs,
                    activeDataField?.dataField,
                    isShowLabelEn,
                  )}
                />
              </div>
            )}

            {/* signXml */}
            {signXmlColumns?.length ? (
              <div
                onClick={() => {
                  if (activePanel === 'detail_ctrl') {
                    dispatch(setActiveRegionBaseOnName('signXml'))
                  }
                }}
              >
                <Table
                  style={{ marginTop: `${paperSize == 'A5' ? '2pt' : '7pt'}` }}
                  className={`custom-resizable-table signXml  ${
                    activeRegion?.regionName === 'signXml'
                      ? 'region-active'
                      : ''
                  }`}
                  showHeader={false}
                  tableLayout="fixed"
                  emptyText={<></>}
                  components={{
                    body: {
                      cell: ResizableCell,
                    },
                  }}
                  // phải dùng signRegionConfigsGroupByMergeField map lại để đúng thứ tự
                  columns={signRegionConfigsGroupByMergeField
                    .map(([mergeField]) =>
                      signXmlColumns.find(
                        ({ dataIndex }) => dataIndex == mergeField,
                      ),
                    )
                    .map((col, indexCol) => ({
                      ...col,
                      onCell: (_) => {
                        let props = {
                          width: col.width,
                          height: 'auto',
                          resizeEnable: activeRegion?.regionName === 'signXml',
                          onResize: handleResize(indexCol, setSignXmlColumns),
                        }
                        return props
                      },
                    }))}
                  data={genSignXmlData(
                    signRegionConfigsGroupByMergeField,
                    activeDataField?.dataField,
                    isShowLabelEn,
                    paperSize,
                  )}
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="lc-frame-search-block" style={{ fontSize: '0.95em' }}>
            <div className="info-website text-center">
              <span>
                <span>Tra cứu tại Website</span>
                {isShowLabelEn && (
                  <span className="pl-1">(Search in Website)</span>
                )}
                <span>:</span>
              </span>

              <span className="pl-1">
                {process.env.REACT_APP_SEARCH_PAGE_URL ||
                  'https://v2.vietinvoice.vn/tra-cuu'}
              </span>

              <span className="pl-1">
                <span>- Mã tra cứu</span>
                {isShowLabelEn && <span className="pl-1">(Invoice code)</span>}
                <span>:</span>
              </span>
            </div>

            <div className="note text-center">
              <span>(Cần kiểm tra, đối chiếu khi lập, giao, nhận hóa đơn)</span>
              {isShowLabelEn && (
                <span>
                  (You need to check invoice when issuing, delivering and
                  receiving)
                </span>
              )}
            </div>

            <div className="text-center">
              Phát hành bởi phần mềm VietInvoice.vn - CÔNG TY CỔ PHẦN ICORP
              (www.icorp.vn) - MST 0106870211
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftContent
