import { thunkGetAccountProfile } from 'app/authSlice'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import UserHelper from 'general/helpers/UserHelper'
import Utils from 'general/utils/Utils'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

function AccountListener(props) {
  const dispatch = useDispatch()
  const { signedIn, currentAccount, currentCompany } = useSelector(
    (state) => state?.auth,
  )
  const jwtToken = useSearchParams().at(0).get('jwtToken')

  useEffect(() => {
    if (
      !jwtToken /* Nếu có jwtToken thì thôi ko cần get me */ &&
      Utils.isObjectEmpty(currentAccount) &&
      UserHelper.checkAccessTokenValid()
    ) {
      // get account infor
      dispatch(thunkGetAccountProfile())
    }

    if (currentAccount.companyId) {
      // get company infor
      dispatch(thunkGetCompanyProfile({ companyId: currentAccount.companyId }))
    }
  }, [currentAccount, dispatch, currentAccount.companyId])

  return <></>
}

export default AccountListener
