import { queryOptions } from '@tanstack/react-query'
import checkPointApi from 'api/checkPointApi'

export const ticketCheckPointQueries = {
  base: {
    scope: ['category', 'ticket', 'checkPoint'],
  },
  lists: (params) =>
    queryOptions({
      queryKey: [ticketCheckPointQueries.base, params],
      queryFn: ({ signal }) => checkPointApi.find(params, signal),
    }),
}
