import React, { useMemo } from 'react'
import dayjs from 'dayjs'

import './style.scss'
import RegistrationHelper from 'general/helpers/RegistrationHelper'
import Utils from 'general/utils/Utils'

function Message200(props) {
  const { message } = props

  const isSent = useMemo(() => message.messageId?.startsWith('V'), [message])
  const TGGui = useMemo(() => {
    return message.contentRaw?.XML?.HDon?.DSCKS?.NBan?.Signature?.Object
      ?.SignatureProperties?.SignatureProperty?.SigningTime
  }, [message])

  return (
    <div className="Message200 border-bottom mb-5">
      <div className="d-flex flex-row">
        <div style={{ maxWidth: '500px' }}>
          <p className="mb-2">
            <i
              className={`fa-regular ${
                isSent ? 'fa-arrow-up' : 'fa-arrow-down'
              } mr-2 ${isSent ? 'i-send' : 'i-receivce'}`}
            ></i>
            <span className="font-weight-bold">
              {TGGui && dayjs(TGGui).format('DD/MM/YYYY hh:mm:ss')}
            </span>
          </p>

          <p className="mb-2">
            {`[${
              message.typeCode
            }] ${RegistrationHelper.renderRegistrationTypeCode(
              message.typeCode,
            )}`}
            Thông điệp gửi hóa đơn điện tử tới cơ quan thuế để cấp mã
          </p>

          <p className="mb-2" style={{ color: '#c4c4c4' }}>
            {`Mã thông điệp: ${message.messageId}`}
          </p>
        </div>
        <div className="text-right flex-fill">
          <p className="mb-2 font-weight-bold">
            {isSent ? '0106870211' : 'Cơ quan thuế'}
          </p>

          <p className="mb-2">
            <span
              className="text-dark-75 font-weight-normal"
              style={{
                borderRadius: '40px',
                backgroundColor: /* '#e0e0e0' : */ '#a9efb7',
                color: /* '#666666' : */ '#12a744',
                padding: '3px 12px',
              }}
            >
              Đã gửi CQT
            </span>
          </p>

          <p
            className="mb-2 cursor-pointer"
            onClick={() => {
              Utils.exportXmlFile({
                data: message?.contentRaw,
                fileName: 'thong-diep-200',
                _parse: true,
              })
            }}
            title="Xuất khẩu XML"
          >
            <i
              className="fa-regular fa-download"
              style={{ color: 'inherit' }}
            ></i>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Message200
