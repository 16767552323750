import { Col, Flex, Row, Typography } from 'antd'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import dayjs from 'dayjs'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomLabel from 'general/components/CustomLabel'
import { IconAction } from 'general/components/GroupButton/styles'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { PAYMENT_STATUS } from 'general/constants/AppConstants'
import Utils from 'general/utils/Utils'
import _reduce from 'lodash/reduce'
import { Fragment, useMemo, useRef } from 'react'
import { renderPaymentMethod, renderPaymentStatus } from '../helpers'
export default function ModalInfoOrder({ isOpen, onOpenChange, rowData }) {
  const modalRef = useRef(null)
  const totalCostBeforeDiscount = useMemo(
    () =>
      _reduce(
        rowData?.orderItems,
        (sum, item) => sum + item.price * item.number,
        0,
      ),
    [rowData?.orderItems],
  )

  const descriptions = useMemo(
    () => [
      {
        label: 'Số đơn hàng',
        value: rowData?.orderCode,
        extras: (
          <div>
            <IconAction
              className={Utils.cn(
                'fa-light mr-2',
                rowData?.paymentStatus === PAYMENT_STATUS.COMPLETED
                  ? 'fa-check-circle text-success'
                  : rowData?.paymentStatus === PAYMENT_STATUS.PENDING
                    ? 'fa-exclamation-circle text-warning'
                    : 'fa-times-circle text-danger',
              )}
            />
            {renderPaymentStatus(rowData?.paymentStatus).text}
          </div>
        ),
      },
      {
        label: 'Ngày đặt hàng',
        value:
          dayjs(rowData?.dateOrder).isValid() &&
          dayjs(rowData?.dateOrder).format('L'),
      },
      {
        label: 'Phương thức thanh toán',
        value: renderPaymentMethod(rowData?.paymentMethod),
      },
    ],
    [
      rowData?.dateOrder,
      rowData?.orderCode,
      rowData?.paymentMethod,
      rowData?.paymentStatus,
    ],
  )

  const columns = useMemo(
    /** @returns {import('react-data-table-component').TableColumn<ReturnOrderData['orderItems'][number]>[]} */
    () => [
      {
        id: 'packageName',
        name: 'Gói sản phẩm',
        center: true,
        minWidth: '200px',
        cell: ({ name }) => (
          <Typography.Text className="text-primary">{name}</Typography.Text>
        ),
      },
      {
        id: 'unit',
        name: 'Đơn vị tính',
        center: true,
        selector: () => 'Gói',
      },
      {
        id: 'number',
        name: 'Số lượng',
        right: true,
        selector: (row) => row.number,
      },
      {
        id: 'price',
        name: 'Đơn giá (VNĐ)',
        right: true,
        selector: (row) => Utils.formatNumber(row.price),
      },
      {
        id: 'total',
        name: 'Thành tiền (VNĐ)',
        right: true,
        selector: (row) => Utils.formatNumber(row.price * row.number),
      },
    ],
    [],
  )

  const summaries = useMemo(
    () => [
      {
        label: 'Tổng tiền',
        value: Utils.formatCurrency(totalCostBeforeDiscount, ' VNĐ'),
      },
      {
        label: 'Giảm giá',
        value: Utils.formatCurrency(rowData?.promotionMoney ?? 0, ' VNĐ'),
      },
      {
        label: 'Tổng tiền thanh toán',
        value: Utils.formatCurrency(
          totalCostBeforeDiscount - rowData?.promotionMoney ?? 0,
          ' VNĐ',
        ),
      },
    ],
    [rowData?.promotionMoney, totalCostBeforeDiscount],
  )

  return (
    <DraggableModal
      ref={modalRef}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      hasApplyButton={false}
      cancelText="Đóng"
      title="Thông tin đơn hàng"
      styles={{
        body: {
          paddingTop: 0,
        },
      }}
    >
      {descriptions.map((item, index) => (
        <Fragment key={index}>
          <Row>
            <Col>
              <CustomLabel
                text={item.label}
                fontSize={13}
                fontWeight={500}
                width={180}
              />
            </Col>
            <Col span={24 / 4}>
              <Typography.Text className="fw-bold fs-6">
                {item.value}
              </Typography.Text>
            </Col>
            <Col span={24 / 3}>{item.extras}</Col>
          </Row>
        </Fragment>
      ))}
      <CustomDataTable
        wrapperClassName="my-5"
        customTableStyle={customDataTableStyle}
        columns={columns}
        dataSource={rowData?.orderItems}
        scrollY="250px"
      />
      <Flex
        justify={rowData?.voucherCode ? 'space-between' : 'flex-end'}
        gap={8}
      >
        {rowData?.voucherCode ? (
          <div
            className="w-50 d-inline-flex align-items-center justify-content-center gap-2 rounded"
            style={{
              backgroundColor: '#e3f8e0',
            }}
          >
            <IconAction className="fa-regular fa-gift text-danger fa-2x" />
            <Typography.Text className="text-black">
              Mã giảm giá/khuyến mại: {rowData?.voucherCode}
            </Typography.Text>
          </div>
        ) : null}
        <div>
          {summaries.map((item, index) => (
            <Fragment key={index}>
              <Row>
                <Col>
                  <CustomLabel
                    text={item.label}
                    fontSize={13}
                    fontWeight={500}
                    width={180}
                  />
                </Col>
                <Col>
                  <Typography.Text className="fw-bold fs-6">
                    {item.value}
                  </Typography.Text>
                </Col>
              </Row>
            </Fragment>
          ))}
        </div>
      </Flex>
    </DraggableModal>
  )
}
