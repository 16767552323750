import { useFormik } from 'formik'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import KTFormTextArea from 'general/components/OtherKeenComponents/Forms/KTFormTextArea'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import Bill from './components/bill/'
import './style.scss'

function index() {
  const { currentAccount, currentCompany } = useSelector((state) => state?.auth)
  const { company } = useSelector((state) => state?.system)
  const dispatch = useDispatch()
  const tmp = ''
  const stepTmp = [
    {
      imgSrc: [tmp, AppResource.images.img_user_circle],
      text: '1. Thông tin đối tượng sử dụng',
    },
    {
      imgSrc: [
        AppResource.images.img_invoice_dollar,
        AppResource.images.img_invoice_dollar_active,
      ],
      text: '2. Thông tin cấp hóa đơn tài chính',
    },
    {
      imgSrc: [
        AppResource.images.img_money_check_dollar_pen,
        AppResource.images.img_money_check_dollar_pen_active,
      ],
      text: '3. Thông tin thanh toán',
    },
  ]
  const { t } = useTranslation()

  const [step, setStep] = useState(0)

  const [isEditMode, setIsEditMode] = useState(true)
  const formik = useFormik({
    initialValues: {
      taxCode: company?.taxCode,
      companyName: company?.companyName,
      address: company?.businessPermitAddress,
      fullName: currentAccount?.fullname,
      phone: currentAccount?.phone,
      email: currentAccount?.email,
      title: '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setFullName(formik?.values?.fullName)
      setPhone(formik?.values?.phone)
      setEmail(formik?.values?.email)
      setAddress(formik?.values?.address)
    },

    validationSchema: Yup.object({
      companyName: Yup.string().required('Không đươc để trống'),
      taxCode: Yup.string()
        .matches(/^[0-9]{10}-[0-9]{3}|[0-9]{10}$/, 'Mã số thuế không hợp lệ')
        .required('Không được để trống'),
      address: Yup.string().required('Không đươc để trống'),
      email: Yup.string()
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email không hợp lệ')
        .required('Không được để trống'),
      phone: Yup.string().nullable(),
    }),
  })

  const handleStep = (value) => {
    if (value == -1 && step == 0) return
    if (value == +1 && step == 2) return
    setStep((prev) => prev + value)
  }

  const twoButton = (
    <div className="w-100 d-flex justify-content-between mt-5">
      <button className="w-50 mr-3 btnCustom1" onClick={() => handleStep(-1)}>
        <i
          className="fa-regular fa-arrow-left mr-3"
          style={{ color: '#3C4376' }}
        />
        Quay Lại
      </button>
      <button className="w-50 ml-3 btnCustom2" onClick={() => handleStep(+1)}>
        <i
          className="fa-regular fa-arrow-right mr-3"
          style={{ color: '#fff' }}
        />
        Tiếp tục
      </button>
    </div>
  )

  const [fullName, setFullName] = useState(currentAccount?.fullname)
  const [phone, setPhone] = useState(currentAccount?.phone)
  const [email, setEmail] = useState(currentAccount?.email)
  const [address, setAddress] = useState(company?.businessPermitAddress)

  const [same, setSame] = useState(true)

  useEffect(() => {
    if (same == true) {
      setFullName(currentAccount?.fullname)
      setPhone(currentAccount?.phone)
      setEmail(currentAccount?.email)
      setAddress(company?.businessPermitAddress)
    } else {
      setFullName('')
      setPhone('')
      setEmail('')
      setAddress('')
    }
  }, [same])

  const dataCart = useSelector((state) => state?.cart?.cartItems)

  const [dataTotal, setDataTotal] = useState()

  useEffect(() => {
    let tmp = dataCart?.reduce(
      (sum, item) =>
        sum + Number(item?.productPrice) * Number(item?.productNumber),
      0,
    )
    setDataTotal(tmp)
  }, [dataCart])

  const infoTmp = [
    { name: 'Tên tài khoản', value: 'Công ty Cổ phần ICORP' },
    { name: 'Số tài khoản', value: '211309302' },
    { name: 'Chi nhánh', value: 'Hội sở Ngân hàng TMCP Á Châu - ACB' },
    {
      name: 'Nội dung chuyển khoản',
      value: 'DH89123842934-MST89834203490234234',
    },
    { name: 'Số tiền', value: `${Utils?.formatCurrency(dataTotal)}` },
  ]

  return (
    <div className="Payment">
      <div className="paymentBanner">
        <div className="text-center">
          <div
            style={{ fontSize: '24px', fontWeight: '700', color: '#1C2256' }}
          >
            Xác nhận thanh toán{' '}
          </div>
          <div
            style={{ fontSize: '14px', fontWeight: '700', color: '#7177A8' }}
          >
            Vui lòng điền chính xác nhưng thông tin dưới đây để hoàn thành mua
            hàng
          </div>
        </div>
        <div className="stepBox d-flex align-items-center">
          {stepTmp.map((item, index) => (
            <div className="d-flex align-items-center" key={index}>
              <div
                className="d-flex align-items-center p-1"
                style={{
                  background: index <= step ? '#4570FE' : '#fff',
                  borderRadius: '10px',
                }}
              >
                <div
                  className="imgBx mr-2"
                  style={{
                    background: index <= step ? '#fff' : '#E8E9EB',
                    padding:
                      index == 0
                        ? '5px'
                        : index == 1
                          ? '5px 7px'
                          : '3px 2px 3px',
                  }}
                >
                  {index <= step ? (
                    <img src={item?.imgSrc[1]} />
                  ) : (
                    <img src={item?.imgSrc[0]} />
                  )}
                </div>
                <div
                  className="mr-2"
                  style={{
                    color: index <= step ? '#fff' : '#1C2256',
                    fontWeight: '700',
                  }}
                >
                  {item?.text}
                </div>
              </div>
              {index !== stepTmp.length - 1 && (
                <div className="ml-5 mr-5">
                  <i
                    className="fal fa-arrow-right"
                    style={{
                      fontWeight: '500',
                      fontSize: '17px',
                      color: index <= step ? '#4570FE' : '#7177A8',
                    }}
                  ></i>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {step == 0 && (
        <div className="row paymentInfo mt-20 mr-10 ml-10">
          <div className="col-lg-8 col-12 p-5" style={{ background: '#fff' }}>
            <div>
              <div
                className="mt-5 d-flex align-items-center"
                style={{
                  fontSize: '17px',
                  fontWeight: '600',
                  color: '#1C2256',
                }}
              >
                <img
                  className="mr-3"
                  style={{ width: '20px', height: '20px' }}
                  src={AppResource.images.img_user_circle}
                />
                Thông tin đối tượng sử dụng
              </div>
              <div className="border p-5 mt-5">
                <div
                  style={{
                    fontSize: '15px',
                    fontWeight: '600',
                    color: '#1C2256',
                  }}
                >
                  THÔNG TIN ĐƠN VỊ
                </div>
                <div className="row mt-5">
                  <div className="col-lg-6 col-12">
                    <KTFormGroup
                      label={
                        <>
                          {t('Mã số thuế')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="taxCode"
                      inputElement={
                        <KTFormInput
                          name="taxCode"
                          value={formik.getFieldProps('taxCode').value}
                          onChange={(value) => {
                            formik.getFieldHelpers('taxCode').setValue(value)
                          }}
                          onBlur={() => {
                            formik.getFieldHelpers('taxCode').setTouched(true)
                          }}
                          enableCheckValid={isEditMode}
                          isValid={_.isEmpty(
                            formik.getFieldMeta('taxCode').error,
                          )}
                          isTouched={formik.getFieldMeta('taxCode').touched}
                          feedbackText={formik.getFieldMeta('taxCode').error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('Mã số thuế'))}`}
                          disabled={isEditMode}
                        />
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <KTFormGroup
                      label={
                        <>
                          {t('Tên công ty')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="companyName"
                      inputElement={
                        <KTFormInput
                          name="companyName"
                          value={formik.getFieldProps('companyName').value}
                          onChange={(value) => {
                            formik
                              .getFieldHelpers('companyName')
                              .setValue(value)
                          }}
                          onBlur={() => {
                            formik
                              .getFieldHelpers('companyName')
                              .setTouched(true)
                          }}
                          enableCheckValid={isEditMode}
                          isValid={_.isEmpty(
                            formik.getFieldMeta('companyName').error,
                          )}
                          isTouched={formik.getFieldMeta('companyName').touched}
                          feedbackText={
                            formik.getFieldMeta('companyName').error
                          }
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('Tên công ty'))}`}
                          disabled={isEditMode}
                        />
                      }
                    />
                  </div>
                  <div className="col-12">
                    <KTFormGroup
                      label={<>{_.capitalize(t('Địa chỉ đầy đủ'))}</>}
                      inputName="address"
                      inputElement={
                        <KTFormTextArea
                          placeholder={t('address')}
                          name="address"
                          type="text"
                          value={formik.getFieldProps('address').value}
                          onChange={(newValue) => {
                            formik.getFieldHelpers('address').setValue(newValue)
                          }}
                          enableCheckValid
                          disabled={isEditMode}
                        />
                        // <FastField name="address">
                        //   {({ field, form, meta }) => (
                        //   )}
                        // </FastField>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="border p-5 mt-5">
                <div
                  style={{
                    fontSize: '15px',
                    fontWeight: '600',
                    color: '#1C2256',
                  }}
                >
                  NGƯỜI ĐẠI DIỆN MUA HÀNG
                </div>
                <div className="row mt-5">
                  <div className="col-lg-6 col-12">
                    <KTFormGroup
                      label={
                        <>
                          {t('Họ và tên')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="fullName"
                      inputElement={
                        <KTFormInput
                          name="fullName"
                          value={formik.getFieldProps('fullName').value}
                          onChange={(value) => {
                            formik.getFieldHelpers('fullName').setValue(value)
                          }}
                          onBlur={() => {
                            formik.getFieldHelpers('fullName').setTouched(true)
                          }}
                          enableCheckValid={isEditMode}
                          isValid={_.isEmpty(
                            formik.getFieldMeta('fullName').error,
                          )}
                          isTouched={formik.getFieldMeta('fullName').touched}
                          feedbackText={formik.getFieldMeta('fullName').error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('Họ và tên'))}`}
                          disabled={isEditMode}
                        />
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <KTFormGroup
                      label={
                        <>
                          {t('Số điện thoại')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="phone"
                      inputElement={
                        <KTFormInput
                          name="phone"
                          value={formik.getFieldProps('phone').value}
                          onChange={(value) => {
                            formik.getFieldHelpers('phone').setValue(value)
                          }}
                          onBlur={() => {
                            formik.getFieldHelpers('phone').setTouched(true)
                          }}
                          enableCheckValid={isEditMode}
                          isValid={_.isEmpty(
                            formik.getFieldMeta('phone').error,
                          )}
                          isTouched={formik.getFieldMeta('phone').touched}
                          feedbackText={formik.getFieldMeta('phone').error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('Số điện thoại'))}`}
                          disabled={isEditMode}
                        />
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <KTFormGroup
                      label={
                        <>
                          {t('Email')} <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="email"
                      inputElement={
                        <KTFormInput
                          name="email"
                          value={formik.getFieldProps('email').value}
                          onChange={(value) => {
                            formik.getFieldHelpers('email').setValue(value)
                          }}
                          onBlur={() => {
                            formik.getFieldHelpers('email').setTouched(true)
                          }}
                          enableCheckValid={isEditMode}
                          isValid={_.isEmpty(
                            formik.getFieldMeta('email').error,
                          )}
                          isTouched={formik.getFieldMeta('email').touched}
                          feedbackText={formik.getFieldMeta('email').error}
                          type={KTFormInputType.text}
                          placeholder={`${_.capitalize(t('email'))}`}
                          disabled={isEditMode}
                        />
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <label
                      className="mb-2 font-weight-bolder"
                      style={{ color: '#7177A8' }}
                      htmlFor="title"
                    >
                      {_.capitalize(t('chức vụ'))}
                    </label>
                    <KTFormSelect
                      name="title"
                      isCustom
                      options={[{ name: 'Giám đốc', value: 'Giám đốc' }]}
                      value={formik.getFieldProps('title').value}
                      onChange={(newValue) => {
                        formik.getFieldHelpers('title').setValue(newValue)
                      }}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              </div>
              {twoButton}
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <Bill step={step} />
          </div>
        </div>
      )}
      {step == 1 && (
        <div className="row paymentInfo mt-20 mr-10 ml-10">
          <div className="col-lg-8 col-12">
            <div
              className="p-5 d-flex justify-content-between align-items-center"
              style={{ background: '#fff' }}
            >
              <div>
                <div className="mb-3 d-flex align-items-center w-100 ">
                  <img
                    className="mr-3"
                    style={{ width: '20px', height: '20px' }}
                    src={AppResource.images.img_user_circle}
                  />
                  <div
                    style={{
                      fontSize: '17px',
                      fontWeight: '600',
                      color: '#1C2256',
                    }}
                  >
                    Thông tin đối tượng sử dụng
                  </div>
                </div>
                <div style={{ fontSize: '13px' }}>
                  {currentAccount?.fullname} / {currentAccount?.phone} /{' '}
                  {currentAccount?.email}
                </div>
                <div style={{ fontSize: '13px' }}>
                  {company?.businessPermitAddress}
                </div>
              </div>
              {/* <button className="customBtn" style={{ minWidth: 'max-content', height: '40px' }}>
                <i style={{ color: '#3C4376' }} className="fa-solid fa-pen-to-square"></i> Chỉnh sửa
              </button> */}
            </div>
            <div
              className="mt-7 p-5 align-items-center"
              style={{ background: '#fff' }}
            >
              <div>
                <div className="mb-3 d-flex align-items-center w-100 ">
                  <img
                    className="mr-3"
                    style={{ width: '18px' }}
                    src={AppResource.images.img_invoice_dollar_active}
                  />
                  <div
                    style={{
                      fontSize: '17px',
                      fontWeight: '600',
                      color: '#1C2256',
                    }}
                  >
                    Thông tin cấp hóa đơn tài chính
                  </div>
                </div>
                {/* <div className="mt-7">
                  <KTCheckbox
                    label={'Thông tin cấp hóa đơn tài chính giống thông tin đơn vị sử dụng'}
                    checked={same}
                    onChange={() => {
                      setSame(!same);
                    }}
                  />
                </div> */}
                <div className="border p-5 mt-7">
                  <div className="row mt-5">
                    <div className=" col-12">
                      <KTFormGroup
                        label={
                          <>
                            {t('Họ và tên')}{' '}
                            <span className="text-danger">(*)</span>
                          </>
                        }
                        inputName="fullName"
                        inputElement={
                          <KTFormInput
                            name="fullName"
                            value={formik.getFieldProps('fullName').value}
                            onChange={(value) => {
                              formik.getFieldHelpers('fullName').setValue(value)
                            }}
                            onBlur={() => {
                              formik
                                .getFieldHelpers('fullName')
                                .setTouched(true)
                            }}
                            enableCheckValid={isEditMode}
                            isValid={_.isEmpty(
                              formik.getFieldMeta('fullName').error,
                            )}
                            isTouched={formik.getFieldMeta('fullName').touched}
                            feedbackText={formik.getFieldMeta('fullName').error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('Họ và tên'))}`}
                            // disabled={isEditMode}
                          />
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-12">
                      <KTFormGroup
                        label={
                          <>
                            {t('Số điện thoại')}{' '}
                            <span className="text-danger">(*)</span>
                          </>
                        }
                        inputName="phone"
                        inputElement={
                          <KTFormInput
                            name="phone"
                            value={formik.getFieldProps('phone').value}
                            onChange={(value) => {
                              formik.getFieldHelpers('phone').setValue(value)
                            }}
                            onBlur={() => {
                              formik.getFieldHelpers('phone').setTouched(true)
                            }}
                            enableCheckValid={isEditMode}
                            isValid={_.isEmpty(
                              formik.getFieldMeta('phone').error,
                            )}
                            isTouched={formik.getFieldMeta('phone').touched}
                            feedbackText={formik.getFieldMeta('phone').error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('Số điện thoại'))}`}
                            // disabled={isEditMode}
                          />
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-12">
                      <KTFormGroup
                        label={
                          <>
                            {t('Email')}{' '}
                            <span className="text-danger">(*)</span>
                          </>
                        }
                        inputName="email"
                        inputElement={
                          <KTFormInput
                            name="email"
                            value={formik.getFieldProps('email').value}
                            onChange={(value) => {
                              formik.getFieldHelpers('email').setValue(value)
                            }}
                            onBlur={() => {
                              formik.getFieldHelpers('email').setTouched(true)
                            }}
                            enableCheckValid={isEditMode}
                            isValid={_.isEmpty(
                              formik.getFieldMeta('email').error,
                            )}
                            isTouched={formik.getFieldMeta('email').touched}
                            feedbackText={formik.getFieldMeta('email').error}
                            type={KTFormInputType.text}
                            placeholder={`${_.capitalize(t('email'))}`}
                            // disabled={isEditMode}
                          />
                        }
                      />
                    </div>
                    <div className="col-12">
                      <KTFormGroup
                        label={<>{_.capitalize(t('Địa chỉ đầy đủ'))}</>}
                        inputName="address"
                        inputElement={
                          <KTFormTextArea
                            placeholder={t('address')}
                            name="address"
                            type="text"
                            value={formik.getFieldProps('address').value}
                            onChange={(newValue) => {
                              formik
                                .getFieldHelpers('address')
                                .setValue(newValue)
                            }}
                            enableCheckValid
                            // disabled={isEditMode}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-between mt-5">
                <button
                  className="w-50 mr-3 btnCustom1"
                  onClick={() => handleStep(-1)}
                >
                  <i
                    className="fa-regular fa-arrow-left mr-3"
                    style={{ color: '#3C4376' }}
                  />
                  Quay Lại
                </button>
                <button
                  className="w-50 ml-3 btnCustom2"
                  onClick={() => {
                    formik.handleSubmit()
                    handleStep(+1)
                  }}
                >
                  <i
                    className="fa-regular fa-arrow-right mr-3"
                    style={{ color: '#fff' }}
                  />
                  Tiếp tục
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <Bill step={step} />
          </div>
        </div>
      )}
      {step == 2 && (
        <div className="row paymentInfo mt-20 mr-10 ml-10">
          <div className="col-lg-8 col-12">
            <div
              className="p-5 d-flex justify-content-between align-items-center"
              style={{ background: '#fff' }}
            >
              <div>
                <div className="mb-3 d-flex align-items-center w-100 ">
                  <img
                    className="mr-3"
                    style={{ width: '20px', height: '20px' }}
                    src={AppResource.images.img_user_circle}
                  />
                  <div
                    style={{
                      fontSize: '17px',
                      fontWeight: '600',
                      color: '#1C2256',
                    }}
                  >
                    Thông tin đối tượng sử dụng
                  </div>
                </div>
                <div style={{ fontSize: '13px' }}>
                  {fullName} / {phone} / {email}
                </div>
                <div style={{ fontSize: '13px' }}>{address}</div>
              </div>
              {/* <button className="customBtn" style={{ minWidth: 'max-content', height: '40px' }}>
                <i style={{ color: '#3C4376' }} className="fa-solid fa-pen-to-square"></i> Chỉnh sửa
              </button> */}
            </div>
            <div
              className="mt-7 p-5 d-flex align-items-center justify-content-between w-100"
              style={{ background: '#fff' }}
            >
              <div>
                <div>
                  <div className="mb-3 d-flex align-items-center w-100 ">
                    <img
                      className="mr-3"
                      style={{ width: '18px' }}
                      src={AppResource.images.img_invoice_dollar_active}
                    />
                    <div
                      style={{
                        fontSize: '17px',
                        fontWeight: '600',
                        color: '#1C2256',
                      }}
                    >
                      Thông tin cấp hóa đơn tài chính
                    </div>
                  </div>
                </div>
                <div style={{ fontSize: '13px' }}>
                  {fullName} / {phone} / {email}
                </div>
                <div style={{ fontSize: '13px' }}>{address}</div>
              </div>
              {/* <button className="customBtn" style={{ minWidth: 'max-content', height: '40px' }}>
                <i style={{ color: '#3C4376' }} className="fa-solid fa-pen-to-square"></i> Chỉnh sửa
              </button> */}
            </div>
            <div className="mt-7 p-5 w-100" style={{ background: '#fff' }}>
              <div>
                <div className="mb-3 d-flex align-items-center w-100 ">
                  <img
                    className="mr-3"
                    style={{ width: '25px' }}
                    src={AppResource.images.img_money_check_dollar_pen_active}
                  />
                  <div
                    style={{
                      fontSize: '17px',
                      fontWeight: '600',
                      color: '#1C2256',
                    }}
                  >
                    Thông tin thanh toán
                  </div>
                </div>
              </div>
              <div>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <i
                        className="fa-solid fa-circle-dot mr-2"
                        style={{ color: '#5180fb' }}
                      ></i>
                      <i
                        className="fa-regular fa-credit-card mr-2"
                        style={{ color: '#00BBB3' }}
                      ></i>{' '}
                      <span
                        style={{
                          color: '#3C4376',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        Chuyển khoản
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Để việc xác nhận thanh toán được nhanh chóng và tự động,
                      sau khi đặt hàng quý khách vui lòng chuyển khoản theo đúng
                      các thông tin dưới đây.
                      <div className="w-100 d-flex row pr-2 pl-2 mt-5">
                        <div
                          className="border col-lg-8 col-12 "
                          style={{ padding: '0' }}
                        >
                          {infoTmp.map((item, index) => {
                            return (
                              <div
                                className="d-flex w-100"
                                style={{
                                  borderBottom:
                                    index != infoTmp.length - 1
                                      ? '1px solid #EBEDF3'
                                      : '',
                                }}
                              >
                                <div className="border-right w-50 p-2">
                                  {item?.name}
                                </div>
                                <div className="w-50 p-2">{item?.value}</div>
                              </div>
                            )
                          })}
                        </div>
                        <div className="col-lg-4 col-12 d-flex justify-content-center align-items-center text-center flex-column">
                          <div className="imgBx" style={{ width: '120px' }}>
                            <img
                              style={{ width: '100%', objectFit: 'contain' }}
                              src={AppResource.images.img_image_outline_filled}
                            />
                          </div>
                          <div>
                            Quý khách vui lòng sử dụng ứng dụng mobile banking
                            để chuyển khoản bằng cách quét mã QR code{' '}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {' '}
                      <i
                        className="fa-solid fa-circle-dot mr-2"
                        style={{ color: '#5180fb' }}
                      ></i>
                      <div className="imgBx mr-2" style={{ width: '45px' }}>
                        <img
                          style={{ width: '100%', objectFit: 'contain' }}
                          src={AppResource.images.img_VNPay}
                        />
                      </div>
                      <span
                        style={{
                          color: '#3C4376',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        VNPAY
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Hiển thị giao diện mặc định của VNPAY ra
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {twoButton}
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <Bill
              step={step}
              fullName={fullName}
              email={email}
              phone={phone}
              address={address}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default index
