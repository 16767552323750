import { useQuery } from '@tanstack/react-query'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import dayjs from 'dayjs'
import { listInvoiceFromPosQueries } from 'features/Invoice/screens/ListInvoiceFromPOS/queries'
import CustomDataTable from 'general/components/CustomDataTable'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import Utils from 'general/utils/Utils'
import { useMemo, useRef, useState } from 'react'

/**
 *
 * @param {object} props
 * @param {boolean} props.show
 * @param {(v: boolean) => void} props.onOpenChange
 */
export default function ModalListInvoiceFromPOS(props) {
  const modalRef = useRef(null)
  const [filterParams, setFilterParams] = useState({
    page: 1,
    limit: 20,
  })

  const invoiceListQueryResult = useQuery(
    listInvoiceFromPosQueries.listInvoiceMttNeedSendTax(filterParams),
  )

  const columns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<InvoiceFromPos>[]} */
    const arr = [
      {
        id: '',
        name: 'Ký hiệu',
        center: true,
        selector: (row) => row.serial,
      },
      {
        id: '',
        name: 'Ngày hóa đơn',
        center: true,
        selector: (row) => row.date,
        format: (row) =>
          dayjs(row.date).isValid() ? dayjs(row.date).format('L') : '',
      },
      {
        id: '',
        name: 'Số hóa đơn',
        center: true,
        selector: (row) => row.no,
      },
      {
        id: '',
        name: 'Mã CQT',
        center: true,
        selector: (row) => row.taxAuthorityCode,
      },
      {
        id: '',
        name: 'Khách hàng',
        center: true,
        selector: (row) => row.customerCompanyName,
      },
      {
        id: '',
        name: 'Mã số thuế',
        center: true,
        selector: (row) => row.customerTaxCode,
      },
      {
        id: '',
        name: 'Tổng tiền',
        center: true,
        style: { justifyContent: 'flex-end' },
        selector: (row) => row.totalAfterTax,
        format: (row) =>
          row.totalAfterTax ? Utils.formatNumber(row.totalAfterTax) : '',
      },
    ]
    return arr
  }, [])
  return (
    <DraggableModal
      ref={modalRef}
      title="Danh sách dữ liệu máy tính tiền gửi đến CQT"
      description={
        'Số lượng hóa đơn gửi: ' +
        Utils.formatNumber(invoiceListQueryResult?.data?.invoices?.length)
      }
      isOpen={props.show}
      onOpenChange={props.onOpenChange}
      handleApplyChanges={() => {
        alert('Gửi hoá đơn')
        props.onOpenChange(false)
      }}
      applyText="Gửi"
      cancelText="Hủy bỏ"
      width={'70%'}
    >
      <CustomDataTable
        scrollY="300px"
        columns={columns}
        dataSource={invoiceListQueryResult?.data?.invoices}
        customTableStyle={customDataTableStyle}
        pagination={{
          currentPage: filterParams.page,
          rowsPerPage: filterParams.limit,
          total: invoiceListQueryResult?.data?.total,
          onChangePage: (page) =>
            setFilterParams((prev) => ({ ...prev, page: parseInt(page) })),
          onChangeRowsPerPage: (limit) =>
            setFilterParams((prev) => ({
              ...prev,
              page: 1,
              limit: parseInt(limit),
            })),
        }}
        isLoading={
          invoiceListQueryResult?.isPending ||
          invoiceListQueryResult?.isRefetching
        }
      />
    </DraggableModal>
  )
}
