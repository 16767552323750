import { useMutation } from '@tanstack/react-query'
import { Card, Typography } from 'antd'
import accountApi from 'api/accountApi'
import { Form, Formik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useRouter from 'hooks/useRouter'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

export default function ResetPassword() {
  const [searchParam] = useSearchParams()

  const { t } = useTranslation()
  const { navigate } = useRouter()

  //#region --- Mutation ---
  const handleResetPassword = useMutation({
    mutationKey: ['auth', 'reset-password'],
    mutationFn: accountApi.resetPassword,
    onSuccess: async (res) => {
      if (res.result === 'success') {
        ToastHelper.showSuccess('Đổi mật khẩu thành công')
        navigate('/auth/dang-nhap', {
          replace: true,
        })
      }
    },
  })
  //#endregion
  return (
    <Formik
      initialValues={{
        resetCode: '',
        newPassword: '',
        confirmNewPassword: '',
        taxCode: _.defaultTo(searchParam.get('t'), ''),
        email: _.defaultTo(searchParam.get('e'), ''),
      }}
      enableReinitialize
      validationSchema={Yup.object({
        resetCode: Yup.string().required('Bạn chưa nhập mã'),
        newPassword: Yup.string()
          .required('Bạn chưa nhập mật khẩu')
          .min(6, 'Mật khẩu phải chứa ít nhất 6 kí tự')
          .matches(/^\S*$/, 'Mật khẩu không được chứa khoảng trắng'),
        confirmNewPassword: Yup.string()
          .required('Bạn chưa nhập lại mật khẩu mới')
          .oneOf([Yup.ref('newPassword'), null], 'Mật khẩu không khớp'),
      })}
      onSubmit={(values) => {
        const clonedValue = _.chain(values)
          .cloneDeep()
          .omit('confirmNewPassword')
          .omitBy(
            (value, key) =>
              (key === 'taxCode' || key === 'email') && _.isEmpty(value),
          )
          .set('newPassword', Utils.sha256(values.newPassword))
          .value()
        handleResetPassword.mutate(clonedValue)
      }}
    >
      {(formik) => (
        <Card
          bordered={false}
          title={
            <Typography.Title level={4} className="text-center m-0">
              {t('ResetPassword')}{' '}
            </Typography.Title>
          }
          bodyStyle={{
            padding: 0,
          }}
          style={{
            maxWidth: 500,
          }}
        >
          <Form>
            <section className="p-5 bg-light">
              <div>
                {/* resetCode */}
                <KTFormGroup
                  label={
                    <>
                      Mã xác nhận<span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="resetCode"
                  inputElement={
                    <KTFormInput
                      name="resetCode"
                      value={formik.getFieldProps('resetCode').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('resetCode').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('resetCode').setTouched(true)
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(
                        formik.getFieldMeta('resetCode').error,
                      )}
                      isTouched={formik.getFieldMeta('resetCode').touched}
                      feedbackText={formik.getFieldMeta('resetCode').error}
                      type={KTFormInputType.text}
                      placeholder="Mã xác nhận..."
                      // disabled={!canEdit}
                    />
                  }
                />

                {/* newPassword */}
                <KTFormGroup
                  label={
                    <>
                      {t('NewPassword')}{' '}
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="newPassword"
                  inputElement={
                    <KTFormInput
                      name="newPassword"
                      value={formik.getFieldProps('newPassword').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('newPassword').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('newPassword').setTouched(true)
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(
                        formik.getFieldMeta('newPassword').error,
                      )}
                      isTouched={formik.getFieldMeta('newPassword').touched}
                      feedbackText={formik.getFieldMeta('newPassword').error}
                      type={KTFormInputType.password}
                      placeholder={`${_.capitalize(t('NewPassword'))}...`}
                      // disabled={!canEdit}
                    />
                  }
                />

                <KTFormGroup
                  label={
                    <>
                      {t('ConfirmNewPassword')}{' '}
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="confirmNewPassword"
                  inputElement={
                    <KTFormInput
                      name="confirmNewPassword"
                      value={formik.getFieldProps('confirmNewPassword').value}
                      onChange={(value) => {
                        formik
                          .getFieldHelpers('confirmNewPassword')
                          .setValue(value)
                      }}
                      onBlur={() => {
                        formik
                          .getFieldHelpers('confirmNewPassword')
                          .setTouched(true)
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(
                        formik.getFieldMeta('confirmNewPassword').error,
                      )}
                      isTouched={
                        formik.getFieldMeta('confirmNewPassword').touched
                      }
                      feedbackText={
                        formik.getFieldMeta('confirmNewPassword').error
                      }
                      type={KTFormInputType.password}
                      placeholder={`${_.capitalize(
                        t('ConfirmNewPassword'),
                      )}...`}
                      // disabled={!canEdit}
                    />
                  }
                />
              </div>
            </section>
            <section className="d-flex justify-content-between p-5">
              <CustomAntButton
                text="Hủy bỏ"
                iconCn="fa-regular fa-arrow-left"
                antProps={{
                  type: 'primary',
                  ghost: true,
                  onClick: () => navigate('/auth/dang-nhap', { replace: true }),
                }}
              />

              <CustomAntButton
                text={'Tiếp tục'}
                isLoading={handleResetPassword.isPending}
                antProps={{
                  htmlType: 'submit',
                  type: 'primary',
                }}
              />
            </section>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
