// import lifeStyleApi from 'api/lifeStyleApi';
import moneyTypeApi from 'api/moneyTypeApi'
import { thunkGetMoneyTypeList } from 'features/Category/categorySlice'
import { FastField, Formik } from 'formik'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import VIToggleSwitch from 'general/components/eInvoiceComponents/VIToggleSwitch'
import ToastHelper from 'general/helpers/ToastHelper'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

ModalEditMoneyType.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onRefreshMoneyTypeList: PropTypes.func,
  moneyTypeItem: PropTypes.object,
  onExistDone: PropTypes.func,
  active: PropTypes.string,
}

ModalEditMoneyType.defaultProps = {
  show: false,
  onClose: null,
  onRefreshMoneyTypeList: null,
  moneyTypeItem: null,
  onExistDone: null,
  active: 'ADD',
}

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * onRefreshMoneyTypeList: function,
 * moneyTypeItem: object,
 * onExistDone: function,
 * active: string,
 * }} props
 * @returns
 */
function ModalEditMoneyType(props) {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { show, onClose, moneyTypeItem, onExistDone, active } = props
  const [numberVN, setNumberVN] = useState('')
  const [numberEN, setNumberEN] = useState('')

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      setNumberEN('')
      setNumberVN('')
      onClose()
    }
  }

  function handleExistDone() {
    if (onExistDone) {
      onExistDone()
    }
  }

  // Request create new money type
  async function requestCreateMoneyType(values) {
    let params = { ...values }
    if (params.exchangeRate) {
      params.exchangeRate = parseFloat(
        params.exchangeRate.replaceAll('.', '').replace(',', '.'),
      )
    }
    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(moneyTypeItem)) {
      params.moneyTypeId = ''
    }
    const res = await moneyTypeApi.create(params)
    const { result, moneyType } = res
    if (result === 'success') {
      ToastHelper.showSuccess(t('Thêm loại tiền thành công'))
      dispatch(thunkGetMoneyTypeList(Global.gFiltersMoneyTypeList))
      handleClose()
    }
  }

  // Request replicate money type
  async function requestReplicateMoneyType(values) {
    let params = { ...values }
    if (params.exchangeRate) {
      params.exchangeRate = parseFloat(
        params.exchangeRate.replaceAll('.', '').replace(',', '.'),
      )
    }
    Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(moneyTypeItem)) {
      params.moneyTypeId = ''
    }
    const res = await moneyTypeApi.create(params)
    const { result, moneyType } = res
    if (result === 'success') {
      ToastHelper.showSuccess(t('Nhân bản loại tiền thành công'))
      dispatch(thunkGetMoneyTypeList(Global.gFiltersMoneyTypeList))
      handleClose()
    }
  }

  // Request update money type
  async function requestUpdateMoneyType(values) {
    let params = { ...values }
    if (params.exchangeRate) {
      params.exchangeRate = parseFloat(
        params.exchangeRate.replaceAll('.', '').replace(',', '.'),
      )
    }
    // Utils.formatEmptyKey(params)
    Utils.removeNullKey(params)
    if (_.isNull(moneyTypeItem)) {
      params.moneyTypeId = ''
    }
    const res = await moneyTypeApi.update(params)
    const { result, moneyType } = res
    if (result === 'success') {
      ToastHelper.showSuccess(
        `Cập nhật thông tin loại tiền ${moneyTypeItem.name} thành công`,
      )
      dispatch(thunkGetMoneyTypeList(Global.gFiltersMoneyTypeList))
      handleClose()
    }
  }

  // MARK --- Hooks ---

  return (
    <div>
      <Formik
        initialValues={{
          moneyTypeId: moneyTypeItem ? moneyTypeItem.moneyTypeId : '',
          code: moneyTypeItem ? moneyTypeItem.code : '',
          name: moneyTypeItem ? moneyTypeItem.name : '',
          exchangeRate: moneyTypeItem
            ? Utils.formatNumber(moneyTypeItem.exchangeRate)
            : '',
          status: moneyTypeItem ? moneyTypeItem.status : 'ACTIVE',
          VNPrefix: moneyTypeItem ? moneyTypeItem.VNPrefix : '',
          VNUnit: moneyTypeItem ? moneyTypeItem.VNUnit : '',
          VNSeperation: moneyTypeItem ? moneyTypeItem.VNSeperation : 'phẩy',
          VNRate: moneyTypeItem ? moneyTypeItem.VNRate : '',
          VNEndSymbol: moneyTypeItem ? moneyTypeItem.VNEndSymbol : '',
          ENPrefix: moneyTypeItem ? moneyTypeItem.ENPrefix : '',
          ENUnit: moneyTypeItem ? moneyTypeItem.ENUnit : '',
          ENSeperation: moneyTypeItem ? moneyTypeItem.ENSeperation : '',
          ENRate: moneyTypeItem ? moneyTypeItem.ENRate : '',
          ENEndSymbol: moneyTypeItem ? moneyTypeItem.ENEndSymbol : '',
          VNAfterDecimal: moneyTypeItem ? moneyTypeItem.VNAfterDecimal : '',
          ENAfterDecimal: moneyTypeItem ? moneyTypeItem.ENAfterDecimal : '',
          example: moneyTypeItem ? moneyTypeItem.example : '',
        }}
        validationSchema={Yup.object({
          code: Yup.string().trim().required(t('Required')),
          name: Yup.string().trim().required(t('Required')),
          exchangeRate: Yup.string()
            .trim()
            .required(t('Required'))
            .test('len', 'tỷ giá chuyển đổi chưa hợp lệ', (val) => {
              if (val) {
                let number = parseFloat(
                  val.replaceAll('.', '').replace(',', '.'),
                )
                if (isNaN(number)) {
                  return false
                }

                if (number < 0) {
                  return false
                } else {
                  return true
                }
              }
            }),
          status: Yup.string().trim().required(t('Required')),
        })}
        enableReinitialize
        onSubmit={(values) => {
          switch (active) {
            case 'ADD':
              requestCreateMoneyType(values)
              break
            case 'EDIT':
              requestUpdateMoneyType(values)
              break
            case 'REPLICATE':
              requestReplicateMoneyType(values)
              break
            default:
              requestReplicateMoneyType(values)
              break
          }
        }}
      >
        {(formikProps) => (
          <>
            <Modal
              className=""
              show={show}
              backdrop="static"
              size="xl"
              onHide={handleClose}
              centered
              onExit={() => {
                formikProps.handleReset()
              }}
              onExited={() => {
                handleExistDone()
              }}
            >
              <Modal.Header className="px-5 py-5">
                <Modal.Title>
                  {active === 'ADD'
                    ? 'Thêm loại tiền'
                    : active === 'EDIT'
                      ? 'Chỉnh sửa thông tin loại tiền'
                      : 'Nhân bản loại tiền'}
                </Modal.Title>
                <div
                  className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <i className="far fa-times"></i>
                </div>
              </Modal.Header>

              <Modal.Body
                className="overflow-auto bg-light"
                style={{
                  maxHeight: '70vh',
                }}
              >
                <div className="row">
                  {/* ma loai tien */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Mã loại tiền')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="code"
                      inputElement={
                        <FastField name="code">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Mã loại tiền'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* ten loai tien */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Tên loại tiền')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="name"
                      inputElement={
                        <FastField name="name">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Tên loại tiền'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>

                  {/* ty gia quy doi */}
                  <div className="col-lg-6">
                    <KTFormGroup
                      label={
                        <>
                          {t('Tỷ giá quy đổi')}{' '}
                          <span className="text-danger">(*)</span>
                        </>
                      }
                      inputName="exchangeRate"
                      inputElement={
                        <FastField name="exchangeRate">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              inputMask={{
                                type: 'number',
                                used: true,
                              }}
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value)
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true)
                              }}
                              enableCheckValid
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(
                                t('Tỷ giá quy đổi'),
                              )}...`}
                              //   disabled={formikProps.getFieldProps('status').value === 'INACTIVE'}
                            />
                          )}
                        </FastField>
                      }
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="w-100">
                  <div className="row">
                    {/* vi */}
                    <div className="col-lg-4">
                      <p className="font-weight-bolder">
                        Cách đọc số tiền bằng chữ tiếng Việt
                      </p>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Ký hiệu bắt đầu')}</>}
                          inputName="VNPrefix"
                          inputElement={
                            <FastField name="VNPrefix">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Ký hiệu bắt đầu'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Đọc tên loại tiền')}</>}
                          inputName="VNUnit"
                          inputElement={
                            <FastField name="VNUnit">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Đọc tên loại tiền'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Đọc phân cách thập phân')}</>}
                          inputName="VNSeperation"
                          inputElement={
                            <FastField name="VNSeperation">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Đọc phân cách thập phân'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Đọc số tiền sau phần thập phân')}</>}
                          inputName="VNAfterDecimal"
                          inputElement={
                            <FastField name="VNAfterDecimal">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Đọc số tiền sau phần thập phân'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Tỷ lệ chuyển đổi')}</>}
                          inputName="VNRate"
                          inputElement={
                            <FastField name="VNRate">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Tỷ lệ chuyển đổi'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Ký hiệu kết thúc')}</>}
                          inputName="VNEndSymbol"
                          inputElement={
                            <FastField name="VNEndSymbol">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Ký hiệu kết thúc'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                    </div>

                    {/* en */}
                    <div className="col-lg-4">
                      <p className="font-weight-bolder">
                        Cách đọc số tiền bằng chữ tiếng Anh
                      </p>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Ký hiệu bắt đầu')}</>}
                          inputName="ENPrefix"
                          inputElement={
                            <FastField name="ENPrefix">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Ký hiệu bắt đầu'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Đọc tên loại tiền')}</>}
                          inputName="ENUnit"
                          inputElement={
                            <FastField name="ENUnit">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Đọc tên loại tiền'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Đọc phân cách thập phân')}</>}
                          inputName="ENSeperation"
                          inputElement={
                            <FastField name="ENSeperation">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Đọc phân cách thập phân'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Đọc số tiền sau phần thập phân')}</>}
                          inputName="ENAfterDecimal"
                          inputElement={
                            <FastField name="ENAfterDecimal">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Đọc số tiền sau phần thập phân'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Tỷ lệ chuyển đổi')}</>}
                          inputName="ENRate"
                          inputElement={
                            <FastField name="ENRate">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Tỷ lệ chuyển đổi'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                      <div className="">
                        <KTFormGroup
                          label={<>{t('Ký hiệu kết thúc')}</>}
                          inputName="ENEndSymbol"
                          inputElement={
                            <FastField name="ENEndSymbol">
                              {({ field, form, meta }) => (
                                <KTFormInput
                                  name={field.name}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue(field.name, value)
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched(field.name, true)
                                  }}
                                  enableCheckValid
                                  isValid={_.isEmpty(meta.error)}
                                  isTouched={meta.touched}
                                  feedbackText={meta.error}
                                  type={KTFormInputType.text}
                                  placeholder={`${_.capitalize(
                                    t('Ký hiệu kết thúc'),
                                  )}...`}
                                  // disabled={!canEdit}
                                />
                              )}
                            </FastField>
                          }
                        />
                      </div>
                    </div>

                    {/* read */}
                    <div className="col-lg-4">
                      <p className="font-weight-bolder ">Ví dụ</p>

                      <div className="d-flex flex-row justify-content-between">
                        <div className="flex-grow-1 mr-4">
                          <KTFormGroup
                            label={<>{t('Số ví dụ')}</>}
                            inputName="example"
                            inputElement={
                              <FastField name="example">
                                {({ field, form, meta }) => (
                                  <KTFormInput
                                    inputMask={{
                                      type: 'number',
                                      used: true,
                                    }}
                                    name={field.name}
                                    value={field.value}
                                    onChange={(value) => {
                                      form.setFieldValue(field.name, value)
                                    }}
                                    onBlur={() => {
                                      //   form.setFieldTouched(field.name, true);
                                    }}
                                    enableCheckValid
                                    isValid={_.isEmpty(meta.error)}
                                    isTouched={meta.touched}
                                    feedbackText={meta.error}
                                    type={KTFormInputType.text}
                                    placeholder={`${_.capitalize(
                                      t('Ví dụ'),
                                    )}...`}
                                    // disabled={!canEdit}
                                  />
                                )}
                              </FastField>
                            }
                          />
                        </div>
                        <div className="pt-8 form-group d-flex justify-content-end flex-column align-items-end">
                          <Button
                            onClick={() => {
                              setNumberVN(
                                Utils.numberToWords(
                                  formikProps.getFieldProps('example').value,
                                  'vi',
                                  formikProps.getFieldProps('VNPrefix').value,
                                  formikProps.getFieldProps('VNUnit').value,
                                  formikProps.getFieldProps('VNSeperation')
                                    .value,
                                  formikProps.getFieldProps('VNEndSymbol')
                                    .value,
                                  formikProps.getFieldProps('VNAfterDecimal')
                                    .value,
                                ),
                              )
                              setNumberEN(
                                Utils.numberToWords(
                                  formikProps.getFieldProps('example').value,
                                  'en',
                                  formikProps.getFieldProps('ENPrefix').value,
                                  formikProps.getFieldProps('ENUnit').value,
                                  formikProps.getFieldProps('ENSeperation')
                                    .value || 'and',
                                  formikProps.getFieldProps('ENEndSymbol')
                                    .value,
                                  formikProps.getFieldProps('ENAfterDecimal')
                                    .value,
                                ),
                              )
                            }}
                            variant="success"
                            style={{ height: '38px' }}
                          >
                            Đọc
                          </Button>
                        </div>
                      </div>

                      <div>
                        <p className="font-weight-bolder">
                          Bằng chữ tiếng Việt
                        </p>
                        <p>{numberVN}</p>
                      </div>

                      <div>
                        <p className="font-weight-bolder">Bằng chữ tiếng Anh</p>
                        <p>{numberEN}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex flex-wrap gap-3 justify-content-between">
                  <div>
                    <FastField name="status">
                      {({ field, form, meta }) => (
                        <VIToggleSwitch
                          label={
                            field.value === 'ACTIVE'
                              ? t('Using')
                              : t('StopUsing')
                          }
                          status={field.value === 'ACTIVE' ? true : false}
                          name={field.name}
                          onSwitch={(checked) => {
                            if (checked) {
                              form.setFieldValue(field.name, 'ACTIVE')
                            } else {
                              form.setFieldValue(field.name, 'INACTIVE')
                            }
                          }}
                        />
                      )}
                    </FastField>
                  </div>

                  <div className="d-flex">
                    <Button
                      className="font-weight-bold  mr-3"
                      variant="secondary"
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      {t('Close')}
                    </Button>

                    <Button
                      className="font-weight-bold "
                      variant="primary"
                      onClick={() => {
                        formikProps.handleSubmit()
                      }}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </div>
  )
}

export default ModalEditMoneyType
