import {
  Checkbox,
  Divider,
  Image,
  Modal,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning, showNotification } from 'app/appSlice'
import HSM from 'assets/images/Einvoice/hsm.svg'
import NoSignMethod from 'assets/images/Einvoice/no-sign-icon.png'
import USBToken from 'assets/images/Einvoice/usb-icon.png'
import dayjs from 'dayjs'
import { useInvoiceSelector } from 'features/Invoice/invoiceSlice'
import {
  invoiceFromPosActions,
  useInvoiceFromPosSelector,
} from 'features/Invoice/screens/ListInvoiceFromPOS/invoiceFromPosSlice'
import {
  useMutationViewAllPdf,
  useMutationViewPdf,
} from 'features/Invoice/screens/ListInvoiceFromPOS/queries'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'

/**
 * @param {object} props
 * @param {boolean} props.show
 * @param {(status) => void} props.onOpenChange
 */
export default function IssueInvoicesFromPosModal(props) {
  const dispatch = useAppDispatch()
  const { selectedInvoices } = useInvoiceSelector()
  const { selectedRows } = useInvoiceFromPosSelector()
  const { activeSigningMethod, taxCode } = useAppSelector(
    (s) => s.system.company,
  )

  const [selectedInvoicesFromPos, setSelectedInvoicesFromPos] = useState([])
  const [selectedMethod, setSelectedMethod] = useState(
    activeSigningMethod === DIGITAL_SIGN_METHOD.USB
      ? DIGITAL_SIGN_METHOD.USB
      : DIGITAL_SIGN_METHOD.HSM,
  )
  const [showCc, setShowCc] = useState(false)
  const [showBcc, setShowBcc] = useState(false)
  const [sendTaxImmediately, setSendTaxImmediately] = useState(true)

  const handleClose = () => {
    setShowCc(false)
    setShowBcc(false)
    dispatch(invoiceFromPosActions.setToggleClearRows())
    dispatch(invoiceFromPosActions.setSelectedRows([]))
    props.onOpenChange(false)
  }

  const handleChangeInput = useCallback((value, formName, currentRow) => {
    setSelectedInvoicesFromPos((prev) => {
      const index = _.findIndex(prev, { invoiceId: currentRow.invoiceId })
      if (index === -1) return prev
      const newSelectedRows = _.cloneDeep(prev)
      newSelectedRows[index] = {
        ...newSelectedRows[index],
        [formName]: value,
      }
      return newSelectedRows
    })
  }, [])

  const handleIssueInvoicesFromPos = async (invoices) => {
    let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
    let sendEmailInfos = invoices
      .filter(
        ({ invoiceId }) =>
          !!selectedRows.find(
            ({ invoiceId: _invoiceId }) => invoiceId == _invoiceId,
          ),
      )
      .map(
        ({ invoiceId, customerEmail, customerEmailCc, customerEmailBcc }) => ({
          invoiceId,
          customerEmail,
          customerEmailCc,
          customerEmailBcc,
        }),
      )
    let invoiceIds = invoices.map(({ invoiceId }) => invoiceId)

    switch (selectedMethod) {
      case null:
      case DIGITAL_SIGN_METHOD.HSM:
        dispatch(setAppSpinning(true))
        try {
          let response = {}

          if (selectedMethod == null) {
            let res = await invoiceApi.issueInvoicesMTT({
              invoiceIds,
              sendEmailInfos,
            })
            if (res?.data) res = res.data

            response.success = res.successIssueInvoices
            response.error = res.errorIssueInvoices
          } else {
            let res = await invoiceApi.hsmFromPosInvoicesSign({
              invoiceIds,
              sendEmailInfos,
            })
            if (res?.data) res = res.data

            response.success = res.successSignInvoices
            response.error = res.errorSignInvoices
          }
          dispatch(setAppSpinning(false))

          let { error, success } = response
          let describeText = `Hóa đơn phát hành thành công: <ul class="mb-0">${success
            .map(
              ({ no, serial }) =>
                `<li>Hóa đơn ký hiệu: ${serial}, số: ${no}</li>`,
            )
            .join('')}</ul>
                                  Hóa đơn phát hành thất bại: <ul class="mb-0">${error
                                    .map(
                                      ({ invoiceId, reason }) =>
                                        `<li>Hóa đơn invoiceId: ${invoiceId}.</br> Lí do ký lỗi: ${reason}</li>`,
                                    )
                                    .join('')}</ul>`
          handleClose()
          dispatch(
            showNotification({
              title: 'Thông báo',
              describeText,
              icon: 'fa-light fa-circle-info text-primary',
            }),
          )

          if (sendTaxImmediately && success?.length) {
            if (activeSigningMethod == 'HSM') {
              dispatch(setAppSpinning(true))
              try {
                await invoiceApi.hsmSignSummaryInvoice({
                  invoiceIds: success.map(({ invoiceId }) => invoiceId),
                })
                dispatch(setAppSpinning(false))

                ToastHelper.showSuccess('Gửi dữ liệu MTT đến CQT thành công')
              } catch (e) {
                dispatch(setAppSpinning(false))
                console.log(e)
                return
              }
            } else {
              let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},summaryInvoiceSignedBySeller,${success.map(({ invoiceId }) => invoiceId).join(';')}`
              Utils.openToolSignSummaryInvoice(urlSchema, accessToken)

              return
            }
          }

          props.refetchListInvoice()
        } catch (e) {
          console.log(e)
          dispatch(setAppSpinning(false))
        }
        break
      case DIGITAL_SIGN_METHOD.USB:
        if (sendTaxImmediately) {
          let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},summaryInvoiceSignedBySeller,${invoiceIds.join(';')},SIGN-INVOICE`

          Utils.openToolSignSummaryInvoice(
            urlSchema,
            accessToken,
            JSON.stringify(sendEmailInfos),
          )
        } else {
          let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${taxCode},${invoiceIds.join(';')},MTT`
          Utils.openToolSignInvoice(
            urlSchema,
            accessToken,
            JSON.stringify(sendEmailInfos),
          )
        }
        break
    }
  }

  const { mutate: handleViewPdf } = useMutationViewPdf()
  const { mutate: handleViewAllPdf, isPending: isViewing } =
    useMutationViewAllPdf()
  const columns = useMemo(() => {
    /** @type {import('antd/es/table').ColumnType<InvoiceFromPos>[]} */
    const arr = [
      {
        key: 'modal.customerName',
        title: 'Tên người nhận',
        align: 'center',
        width: 175,
        dataIndex: 'customerName',
        render: (value, row) => {
          return (
            <CustomAntInput
              type="text"
              borderRadius="sm"
              inputProps={{
                value: value ?? '',
                maxLength: 255,
                autoComplete: 'off',
                disabled: !selectedRows.find(
                  ({ invoiceId }) => invoiceId == row.invoiceId,
                ),
                onChange: (e) =>
                  handleChangeInput(e.target.value, 'customerName', row),
              }}
            />
          )
        },
      },
      {
        key: 'modal.customerEmail',
        title: 'Email người nhận',
        align: 'center',
        width: 175,
        dataIndex: 'customerEmail',
        render: (value, row) => {
          return (
            <CustomAntInput
              type="text"
              borderRadius="sm"
              inputProps={{
                value: value ?? '',
                maxLength: 255,
                autoComplete: 'off',
                disabled: !selectedRows.find(
                  ({ invoiceId }) => invoiceId == row.invoiceId,
                ),
                onChange: (e) =>
                  handleChangeInput(e.target.value, 'customerEmail', row),
              }}
            />
          )
        },
      },
      showCc && {
        key: 'modal.customerEmailCc',
        title: 'Email người nhận (Cc)',
        align: 'center',
        width: 175,
        dataIndex: 'customerEmailCc',
        render: (value, row) => {
          return (
            <CustomAntInput
              type="text"
              borderRadius="sm"
              inputProps={{
                value: value ?? '',
                maxLength: 255,
                autoComplete: 'off',
                disabled: !selectedRows.find(
                  ({ invoiceId }) => invoiceId == row.invoiceId,
                ),
                onChange: (e) =>
                  handleChangeInput(e.target.value, 'customerEmailCc', row),
              }}
            />
          )
        },
      },
      showBcc && {
        key: 'modal.customerEmailBcc',
        title: 'Email người nhận (Bcc)',
        align: 'center',
        width: 175,
        dataIndex: 'customerEmailBcc',
        render: (value, row) => {
          return (
            <CustomAntInput
              type="text"
              borderRadius="sm"
              inputProps={{
                value: value ?? '',
                maxLength: 255,
                autoComplete: 'off',
                disabled: !selectedRows.find(
                  ({ invoiceId }) => invoiceId == row.invoiceId,
                ),
                onChange: (e) =>
                  handleChangeInput(e.target.value, 'customerEmailBcc', row),
              }}
            />
          )
        },
      },
      {
        title: 'Ký hiệu',
        key: 'modal.serial',
        align: 'center',
        dataIndex: 'serial',
        width: 100,
      },
      {
        key: 'modal.date',
        title: 'Ngày HĐ',
        align: 'center',
        dataIndex: 'date',
        width: 125,

        render: (value) => dayjs(value).format('L'),
      },
      {
        key: 'modal.no',
        title: 'Số HĐ',
        align: 'center',
        dataIndex: 'no',
        width: 150,

        render: () => '<Chưa cấp số>',
      },
      {
        key: 'modal.customerCompanyName',
        title: 'Khách hàng',
        align: 'center',
        dataIndex: 'customerCompanyName',
        width: 200,
        render: (value) => (
          <Tooltip destroyTooltipOnHide title={value} placement={'topLeft'}>
            <span>{value}</span>
          </Tooltip>
        ),
      },
      {
        key: 'modal.customerTaxCode',
        title: 'Mã số thuế',
        align: 'center',
        // width: 200,
        dataIndex: 'customerTaxCode',
      },
      {
        key: 'modal.totalAfterTax',
        title: 'Tổng tiền',
        align: 'center',
        dataIndex: 'totalAfterTax',
        render: (value) => <span>{Utils.formatNumber(value)}</span>,
      },
      {
        align: 'center',
        // fixed: 'right',
        width: 70,
        render: (_value, row) => {
          return (
            <TableAction
              icon={<IconAction className="fa-light fa-eye" />}
              onClick={() => handleViewPdf(row.invoiceId)}
            />
          )
        },
      },
    ]
    return arr.filter((item) => !!item)
  }, [handleChangeInput, handleViewPdf, selectedRows, showCc, showBcc])

  useEffect(() => {
    setSelectedInvoicesFromPos(selectedInvoices)
  }, [selectedInvoices])

  return (
    <Modal
      destroyOnClose
      title={
        <Typography.Title level={4} style={{ margin: 0 }}>
          Phát hành hóa đơn
        </Typography.Title>
      }
      open={props.show}
      onCancel={handleClose}
      centered
      cancelText="Hủy bỏ"
      cancelButtonProps={{
        danger: true,
        type: 'default',
      }}
      okText="Phát hành"
      okButtonProps={{
        type: 'primary',
        disabled: false,
        loading: false,
        onClick: () => handleIssueInvoicesFromPos(selectedInvoicesFromPos),
      }}
      width={1000}
    >
      <Typography.Text strong className="font-size-md fw-bold">
        Vui lòng kiểm tra lại thông tin hóa đơn và xác nhận gửi hóa đơn cho
        khách hàng trước khi thực hiện phát hành.
      </Typography.Text>

      <div className="d-flex flex-row">
        <div className="mr-5">
          <Checkbox
            checked={showCc}
            onChange={(e) => {
              setShowCc(e.target.checked)
              if (!e.target.checked) {
                selectedInvoicesFromPos.forEach((row) =>
                  handleChangeInput('', 'customerEmailCc', row),
                )
              }
            }}
          >
            CC
          </Checkbox>
          <Checkbox
            checked={showBcc}
            onChange={(e) => {
              setShowBcc(e.target.checked)
              if (!e.target.checked) {
                selectedInvoicesFromPos.forEach((row) =>
                  handleChangeInput('', 'customerEmailBcc', row),
                )
              }
            }}
          >
            BCC
          </Checkbox>
        </div>
      </div>

      <Table
        bordered
        sticky
        rootClassName="h-100 flex-grow-1 mt-3"
        dataSource={selectedInvoicesFromPos}
        columns={columns}
        scroll={{ x: 1300 }}
        pagination={false}
        rowKey="invoiceId"
        rowSelection={{
          columnWidth: 50,
          // fixed: true,
          defaultSelectedRowKeys: selectedRows.map((r) => r.invoiceId),
          onChange: (_sk, sr) => {
            dispatch(invoiceFromPosActions.setSelectedRows(sr))
          },
        }}
      />

      <div className="bg-light py-3">
        <div className="px-3 d-flex w-100 justify-content-between align-items-center">
          <span>
            Tổng số hóa đơn: <b>{selectedInvoicesFromPos.length}</b>
          </span>
          <span>
            <CustomAntButton
              iconCn="fa-light fa-eye"
              text="Xem tất cả hóa đơn"
              isLoading={isViewing}
              antProps={{
                type: 'text',
                onClick: () =>
                  handleViewAllPdf({
                    invoiceIds: selectedInvoices.map((i) => i.invoiceId),
                  }),
              }}
            />
          </span>
        </div>
      </div>
      <div className="mt-3 rounded-2 h-100px d-flex container py-3 gap-2">
        <Space size={'middle'}>
          <span>
            <Image
              src={
                selectedMethod === null
                  ? NoSignMethod
                  : selectedMethod === DIGITAL_SIGN_METHOD.USB
                    ? USBToken
                    : HSM
              }
              alt="Không sử dụng ký số"
              preview={false}
              width={42}
              height={42}
            />
          </span>
          <span>
            {selectedMethod === null ? (
              <>
                <h5>Không ký số khi phát hành hóa đơn</h5>
                <p>Chỉ áp dụng với loại HĐĐT khởi tạo từ máy tính tiền</p>
              </>
            ) : selectedMethod === DIGITAL_SIGN_METHOD.USB ? (
              <>
                <h5>Ký số trực tiếp qua USB TOKEN</h5>
                <p>Hãy chắc chắn bạn đã mở VietinvoiceSign và cắm USB</p>
              </>
            ) : (
              <>
                <h5>Ký số thông qua máy chủ chữ ký số HSM</h5>
              </>
            )}
          </span>
        </Space>
        <div className="flex-grow-1"></div>
        <Divider type="vertical" className="h-100 bg-light-dark" />
        <Space className=" border-2" direction="vertical">
          <Typography.Text strong>Thay đổi hình thức ký số</Typography.Text>
          <Space>
            <Tooltip title="Không sử dụng ký số">
              <Button variant="icon" onClick={() => setSelectedMethod(null)}>
                <Image
                  src={NoSignMethod}
                  alt="Không sử dụng ký số"
                  preview={false}
                  width={30}
                  height={30}
                  className={Utils.cn(
                    selectedMethod === null ? '' : 'opacity-50',
                  )}
                />
              </Button>
            </Tooltip>
            <Tooltip
              title={Utils.cn(
                'Ký số thông qua',
                activeSigningMethod === DIGITAL_SIGN_METHOD.USB
                  ? 'Usb token'
                  : activeSigningMethod === DIGITAL_SIGN_METHOD.HSM
                    ? 'Hsm'
                    : '',
              )}
            >
              <Button
                variant="icon"
                onClick={() =>
                  setSelectedMethod(
                    activeSigningMethod === DIGITAL_SIGN_METHOD.USB
                      ? DIGITAL_SIGN_METHOD.USB
                      : DIGITAL_SIGN_METHOD.HSM,
                  )
                }
              >
                <Image
                  src={
                    activeSigningMethod === DIGITAL_SIGN_METHOD.USB
                      ? USBToken
                      : activeSigningMethod === DIGITAL_SIGN_METHOD.HSM
                        ? HSM
                        : ''
                  }
                  alt="Ký số USB"
                  preview={false}
                  width={30}
                  height={30}
                  className={Utils.cn(
                    !_.isNil(selectedMethod) ? '' : 'opacity-50',
                  )}
                />
              </Button>
            </Tooltip>
          </Space>
        </Space>
      </div>

      <div className="d-flex flex-row justify-content-end align-items-center cursor-pointer mt-3">
        <Checkbox
          checked={sendTaxImmediately}
          onChange={(e) => {
            setSendTaxImmediately(!!e.target.checked)
          }}
        >
          <span className="fw-bold">Gửi hóa đơn đến cơ quan thuế</span>
        </Checkbox>
      </div>
    </Modal>
  )
}
