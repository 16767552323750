import { useTranslation } from 'react-i18next'
import { Result, Button, Empty, Space, Form } from 'antd'
import { PlusOutlined, SearchOutlined, SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalCustom,
  StyleButtonClose,
  TableCustom,
} from 'features/TaxDeduction/styled'
import { ContainerContentModal } from 'features/Department/styled'
import {
  DivRight,
  InputCustom,
  SpanNotYetReleasedTd,
} from 'features/TaxDeductionDocuments/styled'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'hooks/useRedux'
import { unwrapResult } from '@reduxjs/toolkit'
import { thunkFindTaxpayer } from 'features/Taxpayer/taxpayerSlice'
import { debounce } from 'lodash'
import { EllipsisDiv } from 'features/Taxpayer/styled'
import PopupSaveTaxpayer from '../PopupSave'
function PopupSelectTaxpayer({
  visible,
  setVisible,
  taxpayerSelect,
  setTaxpayerSelect,
  onSave,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [dataSource, setDataSource] = useState([])
  const dispatch = useAppDispatch()
  const [formTaxpayers] = Form.useForm()
  const [visibleAddTaxpaper, setVisibleAddTaxpaper] = useState(false)
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
  })
  const columns = [
    {
      title: t('accountingDocuments.codennt'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <div className="left-cell">{record?.employeeCode}</div>
      ),
    },
    {
      title: t('taxpayer.taxpaperName'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={150}
          title={record?.taxpayersName}
        >
          {record?.taxpayersName}
        </EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.taxCode'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <div className="left-cell">{record?.employeeTaxCode}</div>
      ),
    },
    {
      title: t('taxpayer.identification'),
      width: 200,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <div className="left-cell">{record?.employeeIdentification}</div>
      ),
    },
    {
      title: t('accountingDocuments.jobposition'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={150}
          title={record?.jobPosition?.jobPositionName}
        >
          {record?.jobPosition?.jobPositionName}
        </EllipsisDiv>
      ),
    },
    {
      title: t('accountingDocuments.department'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={150}
          title={record?.department?.departmentName}
        >
          {record?.department?.departmentName}
        </EllipsisDiv>
      ),
    },
  ]

  const findDataSource = async () => {
    try {
      const data = unwrapResult(
        await dispatch(thunkFindTaxpayer(panningDataSource)),
      )
      setDataSource(data.items)
      setPanningDataSource({
        ...panningDataSource,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
      })
    } catch (error) {}
  }

  useEffect(() => {
    setTaxpayerSelect(null)
  }, [visible])

  useEffect(() => {
    findDataSource()
  }, [
    panningDataSource.page,
    panningDataSource.limit,
    panningDataSource.key,
    visible,
  ])

  const debouncedSetFormState = useCallback(
    debounce((keyValue, value) => {
      // Gọi API tại đây với keyValue và value
      setPanningDataSource((prevPanningDataSource) => ({
        ...prevPanningDataSource,
        [keyValue]: value,
      }))
    }, 300), // Thời gian chờ sau khi kết thúc nhập liệu, ví dụ: 500ms
    [], // Empty dependency array means this callback will only be created once
  )

  const handleChange = (key, value) => {
    // Xóa timeout hiện tại để tránh gọi API nếu người dùng vẫn đang nhập
    clearTimeout(timeoutId)

    // Thiết lập timeout mới với debounce
    timeoutId = setTimeout(() => {
      debouncedSetFormState(key, value)
    }, 500) // Đợi 500ms sau khi kết thúc nhập liệu
  }

  let timeoutId = null // Biến lưu trữ timeout ID

  return (
    <>
      <ModalCustom
        title={t('accountingDocuments.selectE')}
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        width={1200}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    setVisible(false)
                  }}
                >
                  {t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  disabled={!taxpayerSelect}
                  onClick={() => {
                    onSave(taxpayerSelect)
                  }}
                >
                  {t('taxDeductionDocuments.dy')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <div className="pt-2 pb-5 row">
          <div className="col-sm-6">
            <Space.Compact>
              <InputCustom
                onChange={(e) => {
                  handleChange('key', e.target.value)
                }}
                minWidth="300px"
                addonBefore={
                  <SearchOutlined
                    onClick={() => {
                      findDataSource()
                    }}
                  />
                }
                placeholder={t('taxpayer.keySearch')}
                allowClear
              />
            </Space.Compact>
          </div>
          <DivRight className="col-sm-6">
            <ButtonSave
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setVisibleAddTaxpaper(true)
              }}
            >
              {t('taxpayer.add')}
            </ButtonSave>
          </DivRight>
        </div>
        <ContainerContentModal padding="20px 20px 0px 20px">
          <TableCustom
            rowClassName={(record) =>
              taxpayerSelect &&
              record.taxpayersId === taxpayerSelect.taxpayersId
                ? 'active-row'
                : ''
            }
            onRow={(record) => ({
              onClick: () => {
                console.log('record', record)
                setTaxpayerSelect(record)
              },
              onDoubleClick: () => {
                setTaxpayerSelect(record)
                onSave(record)
              },
            })}
            dataSource={dataSource}
            columns={columns}
            locale={{
              emptyText: <Empty description={t('taxDeduction.noData')} />,
            }}
            marginRight={'0px'}
            marginLeft={'0px'}
            scroll={{ x: 1000 }}
            pagination={
              panningDataSource.totalPages > 1
                ? {
                    current: panningDataSource.page,
                    pageSize: panningDataSource.limit,
                    total: panningDataSource.totalItems,
                  }
                : false
            }
            onChange={(pagination) => {
              setPanningDataSource({
                ...panningDataSource,
                page: pagination.current,
                limit: pagination.pageSize,
              })
            }}
          />
        </ContainerContentModal>
      </ModalCustom>

      <PopupSaveTaxpayer
        form={formTaxpayers}
        visible={visibleAddTaxpaper}
        setVisible={(value) => setVisibleAddTaxpaper(value)}
        taxpayerSelect={taxpayerSelect}
        setTaxpayerSelect={(value) => {
          setTaxpayerSelect(value)
        }}
        onSave={(value) => {
          findDataSource()
          setVisibleAddTaxpaper(false)
        }}
      />
    </>
  )
}
export default PopupSelectTaxpayer
