import { useMutation } from '@tanstack/react-query'
import { Card, Typography } from 'antd'
import accountApi from 'api/accountApi'
import { Form, Formik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import AppRegex from 'general/constants/AppRegex'
import Utils from 'general/utils/Utils'
import useFireSwal from 'hooks/useFireSwal'
import useRouter from 'hooks/useRouter'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

export default function ForgotPassword() {
  const [searchParam] = useSearchParams()
  const { fireSuccess } = useFireSwal({
    showCancelButton: false,
    showDenyButton: false,
    customClass: {
      confirmButton: 'btn btn-primary ',
    },
  })
  const { t } = useTranslation()
  const { navigate } = useRouter()

  //#region --- Mutation ---
  const handleResetPassword = useMutation({
    mutationKey: ['auth', 'forgot-password'],
    mutationFn: accountApi.requestToResetPassword,
    onSuccess: async (_data, inputValue) => {
      const swalResult = await fireSuccess(
        'Yêu cầu đổi mật khẩu thành công',
        'Vui lòng kiểm tra email để nhận mã xác nhận. Hãy kiểm tra cả hộp thư spam nếu không thấy email trong hộp thư đến.',
      )
      if (swalResult.isConfirmed) {
        navigate(
          {
            pathname: '/auth/dat-lai-mat-khau',
            search: Utils.stringifySearchParams({
              t: inputValue?.taxCode,
              e: inputValue?.email,
            }),
          },
          {
            replace: true,
          },
        )
      }
    },
  })
  //#endregion

  return (
    <Formik
      initialValues={{
        email: _.defaultTo(searchParam.get('e'), ''),
        taxCode: _.defaultTo(searchParam.get('t'), ''),
      }}
      enableReinitialize
      validationSchema={Yup.object({
        email: Yup.string()
          .trim()
          .email('Email không hợp lệ')
          .required(t('Required')),
        taxCode: Yup.string()
          .trim()
          .matches(AppRegex.taxCode, 'Mã số thuế không hợp lệ')
          .required(t('Required')),
      })}
      onSubmit={handleResetPassword.mutate}
    >
      {(formik) => (
        <Card
          bordered={false}
          title={
            <Typography.Title level={4} className="text-center m-0">
              {t('ForgotPassword')}
            </Typography.Title>
          }
          bodyStyle={{
            padding: 0,
          }}
          style={{
            maxWidth: 500,
          }}
        >
          <Form>
            <section className="p-5 bg-light">
              <div>
                <KTFormGroup
                  label={
                    <>
                      {t('Email')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="email"
                  inputElement={
                    <KTFormInput
                      name="email"
                      value={formik.getFieldProps('email').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('email').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('email').setTouched(true)
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(formik.getFieldMeta('email').error)}
                      isTouched={formik.getFieldMeta('email').touched}
                      feedbackText={formik.getFieldMeta('email').error}
                      type={KTFormInputType.email}
                      placeholder={`${_.capitalize(t('Email'))}...`}
                    />
                  }
                />
                <KTFormGroup
                  label={
                    <>
                      {t('TaxCode')} <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="taxCode"
                  inputElement={
                    <KTFormInput
                      name="taxCode"
                      value={formik.getFieldProps('taxCode').value}
                      onChange={(value) => {
                        formik.getFieldHelpers('taxCode').setValue(value)
                      }}
                      onBlur={() => {
                        formik.getFieldHelpers('taxCode').setTouched(true)
                      }}
                      enableCheckValid
                      isValid={_.isEmpty(formik.getFieldMeta('taxCode').error)}
                      isTouched={formik.getFieldMeta('taxCode').touched}
                      feedbackText={formik.getFieldMeta('taxCode').error}
                      type={KTFormInputType.text}
                      placeholder={`${_.capitalize(t('TaxCode'))}...`}
                    />
                  }
                />
              </div>
            </section>
            <section className="d-flex justify-content-between p-5">
              <CustomAntButton
                text="Quay lại"
                iconCn="fa-regular fa-arrow-left"
                antProps={{
                  type: 'primary',
                  ghost: true,
                  onClick: () => navigate('/auth/dang-nhap', { replace: true }),
                }}
              />

              <CustomAntButton
                text={t('SendCode')}
                isLoading={handleResetPassword.isPending}
                antProps={{
                  htmlType: 'submit',
                  type: 'primary',
                }}
              />
            </section>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
