import { createSlice } from '@reduxjs/toolkit'
import _map from 'lodash/map'

const TableSlice = createSlice({
  name: 'column-order-dnd',
  initialState: {
    visualAppears: [],
    dynamicColumns: [],
  },
  reducers: {
    setVisualAppears: (state, action) => {
      state.visualAppears = action.payload
    },
    setDynamicColumns: (state, action) => {
      state.dynamicColumns = action.payload
    },
    /**
     * @param {import('@reduxjs/toolkit').PayloadAction<import('antd/es/checkbox').CheckboxChangeEvent>} action
     *
     */
    onShowColumn: (state, action) => {
      const checked = action.payload.target.checked
      const targetId = action.payload.target.id
      const newColumns = _map(state.visualAppears, (column) => {
        const isTarget = column.id === targetId
        return {
          ...column,
          hidden: isTarget ? !checked : column.hidden,
        }
      })
      state.visualAppears = newColumns
    },
  },
})

export const { onShowColumn, setDynamicColumns, setVisualAppears } =
  TableSlice.actions

const TableReducer = TableSlice.reducer

export default TableReducer
