import { env } from 'general/env/env'

const prefixPath = '/hoa-don-dau-ra'
const prefixTicketPath = prefixPath + '/tem-ve'
const prefixPersonalIncomeTaxPath = prefixPath + '/chung-tu-tncn'
/** @type {SidebarContent[]} */
export const SIDEBAR_MENU_ITEMS = [
  // tong quan
  {
    type: 'item',
    text: 'Tổng quan',
    icon: 'fa-regular fa-grid-2',
    path: prefixPath + '/ban-lam-viec',
  },
  // dang-ky-phat-hanh
  {
    type: 'item',
    text: 'Đăng ký phát hành',
    icon: 'fa-regular fa-square-plus',
    subMenuItems: [
      {
        type: 'item',
        text: 'Lập tờ khai',
        path: prefixPath + '/to-khai',
      },
      {
        type: 'item',
        text: 'Lập tờ khai ủy nhiệm',
        path: prefixPath + '/to-khai/uy-nhiem',
      },
      {
        type: 'item',
        text: 'Mẫu hóa đơn',
        path: prefixPath + '/mau-hoa-don',
      },
    ],
  },
  // hoa don
  {
    type: 'item',
    text: 'Hóa đơn',
    icon: 'fa-regular fa-receipt',
    path: prefixPath + '/hoa-don',
    subMenuItems: [
      {
        type: 'item',
        text: 'Tạo hoá đơn',
        path: prefixPath + '/hoa-don/them-moi',
      },
      {
        type: 'item',
        text: 'Danh sách hoá đơn',
        path: prefixPath + '/hoa-don/danh-sach-hoa-don',
      },
      {
        type: 'item',
        text: 'HĐ từ máy tính tiền',
        path: prefixPath + '/hoa-don/may-tinh-tien/danh-sach-hoa-don',
      },
      env.PROJECT_TYPE === 'PETRO'
        ? {
            type: 'item',
            text: 'Danh sách log bơm',
            path: prefixPath + '/hoa-don/danh-sach-log-bom',
          }
        : null,
      {
        type: 'item',
        text: 'Phiếu xuất kho',
        path: prefixPath + '/hoa-don/phieu-xuat-kho',
      },
    ],
  },
  // xu ly hoa don
  {
    type: 'item',
    text: 'Xử lý hóa đơn',
    icon: 'fa-regular fa-list-check',
    path: prefixPath + '/xu-ly-hoa-don',
    subMenuItems: [
      {
        type: 'item',
        text: 'Thông báo hoá đơn sai sót',
        path: prefixPath + '/xu-ly-hoa-don/danh-sach-thong-bao-sai-sot',
      },
      {
        type: 'item',
        text: 'Thay thế hoá đơn',
        path: prefixPath + '/xu-ly-hoa-don/danh-sach-hoa-don-thay-the',
      },
      {
        type: 'item',
        text: 'Điều chỉnh hoá đơn',
        path: prefixPath + '/xu-ly-hoa-don/danh-sach-hoa-don-dieu-chinh',
      },
      {
        type: 'item',
        text: 'Huỷ hoá đơn',
        path: prefixPath + '/xu-ly-hoa-don/danh-sach-hoa-don-da-huy',
      },
    ],
  },
  // lich-su-truyen-nhan
  {
    type: 'item',
    text: 'Lịch sử truyền nhận',
    icon: 'fa-regular fa-clock-rotate-left',
    path: prefixPath + '/lich-su-truyen-nhan',
    subMenuItems: [
      {
        type: 'item',
        text: 'Tờ khai',
        path: prefixPath + '/lich-su-truyen-nhan/to-khai',
      },
      {
        type: 'item',
        text: 'Hóa đơn điện tử',
        path: prefixPath + '/lich-su-truyen-nhan/hoa-don',
      },
      {
        type: 'item',
        text: 'Hóa đơn khởi tạo từ máy tính tiền',
        path:
          prefixPath + '/lich-su-truyen-nhan/hoa-don-khoi-tao-tu-may-tinh-tien',
      },
      {
        type: 'item',
        text: 'Xử lý hóa đơn',
        path: prefixPath + '/lich-su-truyen-nhan/xu-ly-hoa-don',
      },
    ],
  },
  // bao-cao
  {
    type: 'item',
    text: 'Báo cáo',
    icon: 'fa-regular fa-chart-user',
    path: prefixPath + '/bao-cao',
    subMenuItems: [
      {
        type: 'item',
        text: 'Bảng kê HĐ đã sử dụng',
        path: prefixPath + '/bao-cao/da-su-dung',
      },
      {
        type: 'item',
        text: 'Bảng kê chi tiết HĐ đã sử dụng',
        path: prefixPath + '/bao-cao/chi-tiet',
      },
      {
        type: 'item',
        text: 'Bảng kê HĐ tổng hợp',
        path: prefixPath + '/bao-cao/hoa-don-tong-hop',
      },
      {
        type: 'item',
        text: 'Số lượng HĐ đã phát hành',
        path: prefixPath + '/bao-cao/da-phat-hanh',
      },
      {
        type: 'item',
        text: 'Bảng tổng hợp dữ liệu HĐĐT gửi CQT',
        path: prefixPath + '/bao-cao/tong-hop-du-lieu-hoa-don-gui-co-quan-thue',
      },
      {
        type: 'item',
        text: 'Xuất dữ liệu báo cáo',
        path: prefixPath + '/bao-cao/xuat-khau',
      },
    ],
  },
  // danh muc
  {
    type: 'item',
    text: 'Danh mục',
    icon: 'fa-regular fa-sitemap',
    path: prefixPath + '/danh-muc',
    subMenuItems: [
      {
        type: 'item',
        text: 'Đơn vị',
        path: prefixPath + '/danh-muc/don-vi',
      },
      {
        type: 'item',
        text: 'Chức danh',
        path: prefixPath + '/danh-muc/chuc-danh',
      },
      {
        type: 'item',
        text: 'Nhân viên',
        path: prefixPath + '/danh-muc/nhan-vien',
      },
      {
        type: 'item',
        text: 'Khách hàng',
        path: prefixPath + '/danh-muc/khach-hang',
      },
      {
        type: 'item',
        text: 'Hàng hóa, dịch vụ',
        path: prefixPath + '/danh-muc/hang-hoa',
      },
      {
        type: 'item',
        text: 'Nhóm hàng hóa, dịch vụ',
        path: prefixPath + '/danh-muc/nhom-hang-hoa',
      },
      {
        type: 'item',
        text: 'Loại tiền',
        path: prefixPath + '/danh-muc/loai-tien',
      },
    ],
  },
  // he thong
  {
    type: 'item',
    text: 'Hệ thống',
    icon: 'fa-regular fa-gear',
    path: prefixPath + '/he-thong',
    subMenuItems: [
      {
        type: 'item',
        text: 'Thông tin doanh nghiệp',
        path: prefixPath + '/he-thong/doanh-nghiep',
      },
      {
        type: 'item',
        text: 'Thiết lập ký số',
        path: prefixPath + '/he-thong/thiet-lap-ki-so',
      },
      {
        type: 'item',
        text: 'Mua thêm / gia hạn',
        path: prefixPath + '/he-thong/mua-them',
      },
      {
        type: 'item',
        text: 'Quản lý người dùng',
        path: prefixPath + '/he-thong/nguoi-dung',
      },
      {
        type: 'item',
        text: 'Quản lý tài nguyên',
        path: prefixPath + '/he-thong/tai-nguyen',
      },
      {
        type: 'item',
        text: 'Cấu hình hệ thống',
        path: prefixPath + '/he-thong/cau-hinh',
      },
      {
        type: 'item',
        text: 'Quy trình phê duyệt HĐ',
        path: prefixPath + '/he-thong/quy-trinh-phe-duyet-HD',
      },
    ],
  },
  {
    type: 'item',
    text: 'Tải phần mềm ký',
    icon: 'fa-regular fa-download',
    isDownload: true,
    path: `${process.env.REACT_APP_BASE_URL}/resources/einvoice_tool/vietinvoiceSign.msi`,
  },
]

export const SIDEBAR_TICKET_CONTENT = [
  // tong quan
  {
    type: 'item',
    text: 'Tổng quan',
    icon: 'fa-regular fa-grid-2',
    path: '/ban-lam-viec',
  },
  {
    type: 'item',
    text: 'Đăng ký phát hành',
    icon: 'fa-regular fa-square-plus',
    path: prefixTicketPath,
    subMenuItems: [
      {
        type: 'item',
        text: 'Lập tờ khai',
        path: prefixTicketPath + '/to-khai',
      },
      {
        type: 'item',
        text: 'Lập tờ khai uỷ nhiệm',
        path: prefixTicketPath + '/to-khai-uy-nhiem',
      },
      {
        type: 'item',
        text: 'Mẫu vé',
        path: prefixTicketPath + '/mau-tem-ve',
      },
    ],
  },
  {
    type: 'item',
    text: 'Vé điện tử',
    icon: 'fa-regular fa-ticket',
    path: prefixTicketPath + '/ve-dien-tu/',
    subMenuItems: [
      {
        type: 'item',
        text: 'Tạo vé',
        path: prefixTicketPath + '/ve-dien-tu/xuat-ve',
      },
      {
        type: 'item',
        text: 'Danh sách vé đã xuất',
        path: prefixTicketPath + '/ve-dien-tu/ve-da-xuat',
      },
      {
        type: 'item',
        text: 'Tạo vé hàng loạt',
        path: prefixTicketPath + '/ve-dien-tu/nhap-khau',
      },
    ],
  },
  {
    type: 'item',
    text: 'Xử lý vé',
    icon: 'fa-regular fa-ballot-check',
    path: prefixTicketPath + '/xu-ly-ve',
    subMenuItems: [
      {
        type: 'item',
        text: 'Thông báo vé sai sót',
        path: prefixTicketPath + '/xu-ly-ve/thong-bao-sai-sot',
      },
      {
        type: 'item',
        text: 'Huỷ vé',
        path: prefixTicketPath + '/xu-ly-ve/huy-bo',
      },
      {
        type: 'item',
        text: 'Thay thế vé',
        path: prefixTicketPath + '/xu-ly-ve/thay-the',
      },
      {
        type: 'item',
        text: 'Điều chỉnh vé',
        path: prefixTicketPath + '/xu-ly-ve/dieu-chinh',
      },
      // {
      //   type: 'item',
      //   text: 'Hướng dẫn xử lý',
      //   path: prefixTicketPath + '/xu-ly-ve/huong-dan-xy-ly',
      // },
    ],
  },
  {
    type: 'item',
    text: 'Lich sử truyền nhận',
    icon: 'fa-regular fa-clock-rotate-left',
    path: prefixTicketPath + '/lich-su-truyen-nhan',
    subMenuItems: [
      {
        type: 'item',
        text: 'Tờ khai',
        path: prefixTicketPath + '/lich-su-truyen-nhan/to-khai',
      },
      {
        type: 'item',
        text: 'Hoá đơn có mã',
        path: prefixTicketPath + '/lich-su-truyen-nhan/hoa-don',
      },
      // {
      //   type: 'item',
      //   text: 'Hoá đơn khởi tạo từ máy tính tiền',
      //   path:
      //     prefixTicketPath + '/lich-su-truyen-nhan/hoa-don-tu-may-tinh-tien',
      // },
      {
        type: 'item',
        text: 'Xử lý hoá đơn',
        path: prefixTicketPath + '/lich-su-truyen-nhan/xu-ly-hoa-don',
      },
    ],
  },
  {
    type: 'item',
    text: 'Báo cáo',
    icon: 'fa-regular fa-chart-bar',
    path: prefixTicketPath + '/bao-cao',
    subMenuItems: [
      {
        type: 'item',
        text: 'Bảng kê',
        path: prefixTicketPath + '/bao-cao/bang-ke',
      },
      {
        type: 'item',
        text: 'Số lượng vé đã phát hành',
        path: prefixTicketPath + '/bao-cao/ve-da-phat-hanh',
      },
      {
        type: 'item',
        text: 'Bảng tổng hợp vé đã xuất',
        path: prefixTicketPath + '/bao-cao/ve-da-xuat',
      },
    ],
  },
  {
    type: 'item',
    text: 'Danh mục',
    icon: 'fa-regular fa-box-open',
    path: prefixTicketPath + '/danh-muc',
    subMenuItems: [
      {
        type: 'item',
        text: 'Chức danh',
        path: prefixTicketPath + '/danh-muc/chuc-danh',
      },
      {
        type: 'item',
        text: 'Khách hàng',
        path: prefixTicketPath + '/danh-muc/khach-hang',
      },
      {
        type: 'item',
        text: 'Tuyến đường',
        path: prefixTicketPath + '/danh-muc/tuyen-duong',
      },
      {
        type: 'item',
        text: 'Điểm soát vé',
        path: prefixTicketPath + '/danh-muc/diem-soat-ve',
      },
      {
        type: 'item',
        text: 'Cổng soát vé',
        path: prefixTicketPath + '/danh-muc/cong-soat-ve',
      },
    ],
  },
  {
    type: 'item',
    text: 'Hệ thống',
    icon: 'fa-regular fa-gear',
    path: prefixTicketPath + '/he-thong',
    subMenuItems: [
      {
        type: 'item',
        text: 'Thông tin doanh nghiệp',
        path: prefixTicketPath + '/he-thong/doanh-nghiep',
      },
      {
        type: 'item',
        text: 'Thiết lập kí số',
        path: prefixTicketPath + '/he-thong/thiet-lap-ki-so',
      },
      {
        type: 'item',
        text: 'Mua thêm / gia hạn',
        path: prefixTicketPath + '/he-thong/gia-han',
      },
      {
        type: 'item',
        text: 'Quản lý người dùng',
        path: prefixTicketPath + '/he-thong/nguoi-dung',
      },
      {
        type: 'item',
        text: 'Quản lý tài nguyên',
        path: prefixTicketPath + '/he-thong/tai-nguyen',
      },
      {
        type: 'item',
        text: 'Cấu hình hệ thống',
        path: prefixTicketPath + '/he-thong/cau-hinh',
      },
      {
        type: 'item',
        text: 'Quy trình phê duyệt HĐ',
        path: prefixTicketPath + '/he-thong/quy-trình',
      },
    ],
  },
  {
    type: 'item',
    text: 'Tải phần mềm ký',
    icon: 'fa-regular fa-download',
    isDownload: true,
    path: `${process.env.REACT_APP_BASE_URL}/resources/einvoice_tool/vietinvoiceSign.msi`,
  },
]
export const SIDEBAR_PERSONAL_INCOME_TAX = [
  {
    type: 'item',
    text: 'Thông tin đơn vị',
    icon: 'fa-regular fa-receipt',
    path: prefixPersonalIncomeTaxPath + '/thong-tin-don-vi',
  },
  {
    type: 'item',
    text: 'Cơ cấu tổ chức',
    icon: 'fa-regular fa-sitemap',
    path: prefixPersonalIncomeTaxPath + '/co-cau-to-chuc',
  },
  {
    type: 'item',
    text: 'Vị trí công việc',
    icon: 'fa-regular fa-chart-user',
    path: prefixPersonalIncomeTaxPath + '/vi-tri-cong-viec',
  },
  {
    type: 'item',
    text: 'Chứng từ khấu trừ thuế thu nhập cá nhân',
    icon: 'fa-regular fa-address-card',
    path: prefixPersonalIncomeTaxPath + '/khau-tru-thue-thu-nhap-ca-nhan',
  },
  {
    type: 'item',
    text: 'Người nộp thuế',
    icon: 'fa-solid fa-users-rectangle',
    path: prefixPersonalIncomeTaxPath + '/nguoi-nop-thue',
  },
  {
    type: 'item',
    text: 'Thiết lập',
    icon: 'fa-regular fa-gear',
    path: prefixPersonalIncomeTaxPath + '/thiet-lap',
    subMenuItems: [
      {
        type: 'item',
        text: 'Mẫu số, ký hiệu chứng từ khấu trừ thuế',
        path: prefixPersonalIncomeTaxPath + '/thiet-lap/mau',
      },
      {
        type: 'item',
        text: 'Thiếp lập chữ ký số',
        path: prefixPersonalIncomeTaxPath + '/thiet-lap/thiet-lap-ki-so',
      },
    ],
  },
  {
    type: 'item',
    text: 'Quản lý người dùng',
    icon: 'fa-solid fa-user-tie',
    path: prefixPersonalIncomeTaxPath + '/quan-ly-nguoi-dung',
  },
]
