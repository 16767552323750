import { REGISTRATION_STATUS } from 'general/constants/AppConstants'

const RegistrationHelper = {
  renderRegistrationStatus: (status) => {
    if (!status) return
    switch (status) {
      case REGISTRATION_STATUS.DRAFT:
        return 'Chưa gửi CQT'
      case REGISTRATION_STATUS.SENT_TAX:
        return 'Chờ CQT xử lý'
      case REGISTRATION_STATUS.TAX_VALID:
        return 'Thông điệp hợp lệ'
      case REGISTRATION_STATUS.TAX_NOT_VALID:
        return 'Thông điệp không hợp lệ'
      case REGISTRATION_STATUS.TAX_RECEIVE:
        return 'CQT đã tiếp nhận'
      case REGISTRATION_STATUS.TAX_NOT_RECEIVE:
        return 'CQT không tiếp nhận'
      case REGISTRATION_STATUS.TAX_ACCEPT:
        return 'CQT đã chấp nhận'
      case REGISTRATION_STATUS.TAX_NOT_ACCEPT:
        return 'CQT không chấp nhận'
      default:
        return
    }
  },
  renderInvoiceFormsOfRegistration: (registration) => {
    if (!registration) return ''
    let { formWithTaxAuthCode, formWithoutTaxAuthCode, formPosTaxAuthCode } =
      registration

    var returnValue = []
    if (formWithTaxAuthCode) {
      returnValue.push('Có mã của cơ quan thuế')
    }
    if (formWithoutTaxAuthCode) {
      returnValue.push('Không có mã của cơ quan thuế')
    }
    if (formPosTaxAuthCode) {
      returnValue.push('Hoá đơn có mã khởi tạo từ máy tính tiền')
    }

    return returnValue.join(', ')
  },
  renderRegistrationType: (type) => {
    return type == 1 ? 'Đăng ký mới' : type == 2 ? 'Thay đổi thông tin' : ''
  },
  renderCertificateType: (type) => {
    if (!type) return ''

    switch (type) {
      case 1:
        return 'Đăng ký mới'
      case 2:
        return 'Gia hạn'
      case 3:
        return 'Ngừng sử dụng'
    }
  },

  renderRegistrationTypeCode: (typeCode) => {
    if (!typeCode) return ''

    switch (parseInt(typeCode)) {
      case 999:
        return 'Thông điệp phản hồi kỹ thuật của cơ quan thuế'
      case 100:
        return 'Thông điệp gửi tờ khai đăng ký/thay đổi thông tin sử dụng hóa đơn điện tử.'
      case 102:
        return 'Thông điệp thông báo về việc tiếp nhận/không tiếp nhận tờ khai đăng ký/thay đổi thông tin sử dụng HĐĐT.'
      case 103:
        return 'Thông điệp thông báo về việc chấp nhận/không chấp nhận đăng ký/thay đổi thông tin sử dụng hóa đơn điện tử.'
      default:
        return ''
    }
  },
}

export default RegistrationHelper
