import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toolApi from 'api/toolApi'

// MARK: --- Thunks ---
export const thunkGetDigitalCertificatesFromTool = createAsyncThunk(
  'tool/get-certificates-from-tool',
  async (param, thunkParams) => {
    try {
      const response = await toolApi.getDigitalCertificatesFromTool()

      const { rejectWithValue } = thunkParams

      // if (/* res error */ false) {
      //   rejectWithValue(response)
      // }

      return response
    } catch (e) {
      console.log(e)
    }
  },
)

export const toolSlice = createSlice({
  name: 'tool',
  initialState: {
    selectedDigitalCertificate: undefined,
  },
  reducers: {
    setSelectedDigitalCertificate: (state, action) => {
      state.selectedDigitalCertificate = action.payload
    },

    clearToolState: (state) => {
      state.selectedDigitalCertificate = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      thunkGetDigitalCertificatesFromTool.fulfilled,
      (state, action) => {
        state.selectedDigitalCertificate = action.payload?.certificate
      },
    )
  },
})

const { reducer, actions } = toolSlice
export const { setSelectedDigitalCertificate, clearToolState } = actions
export default reducer
