import AppResource from 'general/constants/AppResource'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import './style.scss'

function SuccessFulOrder() {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const orderCode = searchParams.get('orderCode')

  return (
    <div className="SuccessFulOrder w-100 h-100 d-flex justify-content-center align-items-center">
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>
            <div style={{ color: '#28A043' }} className="w-100 text-center">
              <i
                className="fa-regular fa-circle-check"
                style={{ color: '#28A043' }}
              ></i>{' '}
              Đặt hàng thành công
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="imgBx mr-2" style={{ width: '56px' }}>
              <img
                style={{ width: '100%', objectFit: 'contain' }}
                src={AppResource.images.img_email}
              />
            </div>
            <div>
              Xác nhận thành công đơn hàng mã{' '}
              <span className="ml-1" style={{ fontWeight: '700' }}>
                {orderCode}
              </span>
              {/* Email xác nhận đặt hàng thành công đã được gửi về địa chỉ {currentAccount?.email} */}
              <div>
                {' '}
                Quay về màn quản lý tài nguyên và chờ quản trị phê duyệt thanh
                toán
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              navigate(`/hoa-don-dau-ra/he-thong/tai-nguyen`)
            }}
          >
            Quản lý tài nguyuên
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SuccessFulOrder
