import { createSlice } from '@reduxjs/toolkit'

// MARK: --- Thunks ---

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    appSpinning: false,
    appNotificationModalShow: false,
    appNotInstalledToolNotiModalShow: false,
    appNotificationInfo: {
      title: '',
      describeText: '',
      icon: '',
    },
  },
  reducers: {
    setAppSpinning: (state, action) => {
      state.appSpinning = !!action.payload
    },
    showNotification: (state, action) => {
      let { title, describeText, icon } = action.payload
      state.appNotificationInfo.title = title
      state.appNotificationInfo.describeText = describeText
      state.appNotificationInfo.icon = icon

      state.appNotificationModalShow = true
    },
    hideNotification: (state) => {
      state.appNotificationInfo.title = ''
      state.appNotificationInfo.describeText = ''
      state.appNotificationModalShow = false
    },
    setAppNotInstalledToolNotiModalShow: (state, action) => {
      state.appNotInstalledToolNotiModalShow = !!action.payload
    },
  },
  extraReducers: {},
})

const { reducer, actions } = appSlice
export const {
  setAppSpinning,
  showNotification,
  hideNotification,
  setAppNotInstalledToolNotiModalShow,
} = actions
export default reducer
