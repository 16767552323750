import { useMutation } from '@tanstack/react-query'
import { Editor } from '@tinymce/tinymce-react'
import { Checkbox, Col, Flex, Modal, Row, Space, Typography } from 'antd'
import { Formik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput from 'general/components/Forms/KTFormInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useFireSwal from 'hooks/useFireSwal'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import { useRef, useState } from 'react'
import {
  getEmailConfig,
  getEmailTemplate,
  updateEmailConfig,
  updateEmailTemplate,
} from '../../asyncThunks'
import { checkBoxItems, inputFieldItems } from '../../constants'
import {
  systemConfigSliceActions,
  useSystemConfigSelector,
} from '../../SystemConfigurationSlice'
import FooterCard from '../FooterCard'
import * as Yup from 'yup'

export default function TabEmailSetting() {
  const { emailConfig, emailTemplate } = useSystemConfigSelector()
  const dispatch = useAppDispatch()

  const handleSaveEmailConfig = useMutation({
    mutationKey: ['systemConfig', 'email-config'],
    mutationFn: (values) =>
      dispatch(
        updateEmailConfig({
          emailConfigData: values,
        }),
      ).unwrap(),
  })

  const handleSaveEmailTemplate = useMutation({
    mutationKey: ['systemConfig', 'email-template'],
    mutationFn: (values) => dispatch(updateEmailTemplate(values)).unwrap(),
  })

  return (
    <Formik
      initialValues={emailConfig}
      enableReinitialize
      validationSchema={Yup.object({
        defaultConfig: Yup.boolean().notRequired(),
        sender: Yup.string().when('defaultConfig', {
          is: false,
          then: (schema) =>
            schema
              .email('Tài khoản email không hợp lệ')
              .required('Trường không được để trống'),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
        emailPassword: Yup.string().when('defaultConfig', {
          is: false,
          then: (schema) =>
            schema
              .min(6, 'Mật khẩu phải có ít nhất 6 ký tự')
              .required('Trường không được để trống'),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
        emailUserName: Yup.string().when('defaultConfig', {
          is: false,
          then: (schema) => schema.required('Trường không được để trống'),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
      })}
      onSubmit={async (values) => {
        const results = await Promise.allSettled([
          handleSaveEmailConfig.mutate(values),
          handleSaveEmailTemplate.mutate(emailTemplate),
        ])
        const hasError = _.some(results, { status: 'rejected' })
        if (!hasError) {
          ToastHelper.showSuccess('Cập nhật thành công')
          await dispatch(getEmailConfig())
          await dispatch(getEmailTemplate())
        }
      }}
    >
      {(formikProps) => (
        <Flex justify={'space-between'} vertical className="w-100 h-100">
          <section className="ml-10">
            <div>
              <div className="title mb-5">Email</div>
              <div className="row w-100">
                {checkBoxItems.map((item, idx) => (
                  <div
                    className="mb-5 col-lg-6 col-sm-12"
                    key={Utils.cn('checkbox-mail-', idx)}
                  >
                    <Checkbox
                      checked={formikProps.values[item.state]}
                      onChange={(e) => {
                        const isChecked = e.target.checked
                        formikProps.setFieldValue(item?.state, isChecked)
                        if (item.state === 'defaultConfig') {
                          formikProps.setErrors({
                            sender: undefined,
                            emailPassword: undefined,
                            emailUserName: undefined,
                          })
                          formikProps.setTouched({
                            sender: false,
                            emailPassword: false,
                            emailUserName: false,
                          })
                          if (!isChecked) return
                          inputFieldItems.map((inputField) => {
                            formikProps.setFieldValue(
                              inputField.state,
                              inputField.type === 'select'
                                ? inputField.options[1].value // khac
                                : '',
                            )
                          })
                        }
                      }}
                    >
                      {item.label}
                    </Checkbox>
                  </div>
                ))}
              </div>
              <div className="row w-100">
                {inputFieldItems.map((item, idx) => {
                  if (item.type === 'select') {
                    return (
                      <div
                        className=" w-lg-50 w-100 mb-lg-2 mb-3 "
                        key={Utils.cn('input-field-', idx)}
                      >
                        <label
                          className="mr-4 mb-2"
                          htmlFor="state"
                          style={{
                            color: 'rgb(113, 119, 168)',
                            fontWeight: '600',
                            fontSize: '13px',
                          }}
                        >
                          {item.label}
                        </label>
                        <KTFormSelect
                          value={formikProps.values[item.state]}
                          options={item.options}
                          onChange={(newValue) => {
                            formikProps.setFieldValue(item?.state, newValue)
                          }}
                          disabled={formikProps.values.defaultConfig}
                          style={{ width: '400px' }}
                        />
                      </div>
                    )
                  }

                  if (item.type === 'input') {
                    return (
                      <div
                        className="w-lg-50 w-100"
                        key={Utils.cn('input-field-', idx)}
                      >
                        <div style={{ maxWidth: '400px' }}>
                          <KTFormGroup
                            label={item.label}
                            inputName={item?.state}
                            inputElement={
                              <KTFormInput
                                name={item.state}
                                value={formikProps.values[item.state]}
                                onChange={(value) => {
                                  formikProps.setFieldValue(item?.state, value)
                                }}
                                enableCheckValid
                                isTouched={formikProps.touched[item.state]}
                                isValid={_.isEmpty(
                                  formikProps.errors[item.state],
                                )}
                                feedbackText={formikProps.errors[item.state]}
                                type={item?.typeInput}
                                disabled={formikProps.values?.defaultConfig}
                              />
                            }
                          />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
            <br />
            <div>
              <EmailTemplate />
            </div>
            <br />
          </section>
          <FooterCard
            onRestoreDefault={() => {
              _.entries(AppData.default_formatNumber).forEach(
                ([key, value]) => {
                  formikProps.setFieldValue(key, value)
                },
              )
            }}
            onRestoreChange={formikProps.resetForm}
            onSave={formikProps.submitForm}
            isLoading={formikProps.isSubmitting}
            show={formikProps.dirty}
          />
        </Flex>
      )}
    </Formik>
  )
}

function EmailTemplate() {
  const { emailTemplate, selectedTemplate } = useSystemConfigSelector()
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useAppDispatch()
  /** @type {React.MutableRefObject<Editor['editor']>} */
  const editorRef = useRef(null)
  const { fireError } = useFireSwal()

  const cancelChange = async () => {
    const detectChange = editorRef?.current.isDirty()
    if (detectChange) {
      const result = await fireError('Bạn có muốn hủy bỏ thay đổi ?')
      if (result.isConfirmed) {
        setIsOpen(false)
        editorRef?.current.resetContent(selectedTemplate?.content)
      }
      return
    }
    setIsOpen(false)
    const currentEmailTemplateType = emailTemplate.findIndex(
      (template) =>
        template.emailTemplateId === selectedTemplate.emailTemplateId,
    )
    dispatch(
      systemConfigSliceActions.setSelectedTemplate(
        emailTemplate[currentEmailTemplateType],
      ),
    )
  }

  const changeSystemEmailTemplate = (emailTemplateId) => {
    const updatedIndex = emailTemplate.findIndex(
      (template) => template.emailTemplateId === emailTemplateId,
    )
    if (updatedIndex === -1) {
      ToastHelper.showError('Không tìm thấy mẫu email')
      return
    }
    const cloneEmailTemplate = _.cloneDeep(emailTemplate)
    cloneEmailTemplate[updatedIndex].content = editorRef.current.getContent({
      format: 'html',
    })
    dispatch(systemConfigSliceActions.setEmailTemplate([...cloneEmailTemplate]))
    dispatch(
      systemConfigSliceActions.setSelectedTemplate(
        cloneEmailTemplate[updatedIndex],
      ),
    )
  }

  const resetDefaultTemplate = () => {
    const currentEmailTemplateType = emailTemplate.findIndex(
      (template) =>
        template.emailTemplateId === selectedTemplate.emailTemplateId,
    )
    const cloneEmailTemplate = _.cloneDeep(emailTemplate)
    cloneEmailTemplate[currentEmailTemplateType].content =
      selectedTemplate.defaultContent
    dispatch(systemConfigSliceActions.setEmailTemplate([...cloneEmailTemplate]))
    // setContent(selectedTemplate.defaultContent)
    dispatch(
      systemConfigSliceActions.setSelectedTemplate(
        cloneEmailTemplate[currentEmailTemplateType],
      ),
    )
  }

  return (
    <>
      <div className="title mb-5">Cấu hình mẫu email</div>
      <Row gutter={[24, 24]}>
        {emailTemplate?.map((item, index) => (
          <Col md={24} lg={12} key={`email-template-${index}`}>
            <CustomAntButton
              text={item?.name}
              antProps={{
                className: 'w-400px',
                type: 'default',
                size: 'large',
                onClick: () => {
                  setIsOpen(true)
                  dispatch(systemConfigSliceActions.setSelectedTemplate(item))
                },
              }}
            />
          </Col>
        ))}
      </Row>
      <Modal
        open={isOpen}
        centered
        maskClosable={false}
        width="1000px"
        onCancel={() => {
          cancelChange()
        }}
        title={
          <Typography.Title level={3}>
            {selectedTemplate?.name}
          </Typography.Title>
        }
        footer={
          <div className="w-100 d-flex justify-content-between mb-1 mt-2">
            <CustomAntButton
              text="Thiết lập mặc định"
              antProps={{
                type: 'default',
                className: 'btn btn-secondary',
                onClick: () => {
                  resetDefaultTemplate()
                },
              }}
            />
            <Space>
              <CustomAntButton
                text="Lưu thay đổi"
                antProps={{
                  type: 'primary',
                  onClick: () => {
                    setIsOpen(false)
                    changeSystemEmailTemplate(selectedTemplate.emailTemplateId)
                  },
                }}
              />
            </Space>
          </div>
        }
      >
        <Editor
          onInit={(evt, editor) => {
            editorRef.current = editor
          }}
          initialValue={selectedTemplate?.content}
          // onEditorChange={setContent}
          apiKey="wxyx2jurph3jz4bcx7xaxvxiee68ovunhji0e94k7fg3w9x5"
          init={{
            language: 'vi',
            height: 500,
            menubar: true,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
      </Modal>
    </>
  )
}
