import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import './style.scss'
import Utils from 'general/utils/Utils'
import { useDispatch, useSelector } from 'react-redux'
import cartApi from 'api/cartApi'
import { useNavigate } from 'react-router-dom'
import orderApi from 'api/orderApi'
import { removeAll } from '../../../Cart/CartSlice'

function Bill(props) {
  const {
    step,
    fullName,
    email,
    address,
    phone,
    paymentMethod = 'Chuyển khoản',
  } = props

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const dataCart = useSelector((state) => state.cart.cartItems)

  let dataTotal = dataCart?.reduce(
    (sum, item) =>
      sum + Number(item?.productPrice) * Number(item?.productNumber),
    0,
  )

  const [numberProducts, setNumberProducts] = useState()

  useEffect(() => {
    if (dataCart) {
      let tmp = 0
      dataCart.map((item) => {
        tmp += item?.productNumber
      })
      setNumberProducts(tmp)
    }
  }, [dataCart])

  const [code, setCode] = useState()

  const voucher = useSelector((state) => state?.cart?.voucher)

  const [discount, setDiscount] = useState(0)

  useEffect(() => {
    if (voucher) {
      setDiscount((dataTotal / 100) * voucher?.percent)
    }
  }, [voucher])

  const handleClickBuy = async () => {
    let list = dataCart.map((item) => {
      let tmp = {
        type: item?.productType,
        name: item?.productName,
        price: item?.productPrice,
        amount: item?.productAmount,
        number: item?.productNumber,
      }
      return tmp
    })

    dispatch(removeAll())

    const res = await orderApi.create({
      fullName,
      email,
      address,
      phone,
      list,
      total: dataTotal,
      paymentMethod: paymentMethod,
      voucherId: voucher?.voucherId,
      voucherCode: voucher?.code,
      voucherPercent: voucher?.percent,
    })

    return res?.orderCode
  }

  return (
    <div className="bg-white Bill">
      <div className="header-Bill p-5">
        <div className="d-flex justify-content-between  ">
          <div>
            <div style={{ fontWeight: '600', fontSize: '17px' }}>Đơn hàng</div>
            <div style={{ fontSize: '13px' }}>{numberProducts} sản phẩm</div>
          </div>
          <button
            className="customBtn"
            style={{ minWidth: 'max-content', height: '40px' }}
            onClick={() => {
              navigate(`/hoa-don-dau-ra/gio-hang`)
            }}
          >
            <i
              style={{ color: '#3C4376' }}
              className="fa-solid fa-pen-to-square"
            ></i>{' '}
            Chỉnh sửa
          </button>
        </div>
      </div>
      <div className="price-Bill  p-5 pl-7">
        <div className="d-flex justify-content-between">
          <p>Tổng cộng</p>
          <p className="pr-1">
            {Utils?.formatCurrency(dataTotal ?? 0)} <ins>đ</ins>
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p>Khuyễn mãi</p>
          {voucher ? (
            <p className="pr-1">
              {Utils?.formatCurrency(discount)} <ins>đ</ins>
            </p>
          ) : (
            <p className="pr-1">
              {Utils?.formatCurrency(0)} <ins>đ</ins>
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between text-danger fw-bold">
          <p>Tiền phải trả</p>
          <p className="pr-1">
            {Utils?.formatCurrency(dataTotal - discount)} <ins>đ</ins>
          </p>
        </div>
        <div className="d-flex justify-content-between ">
          <p className="mb-0"></p>
          <p className="mb-0">(Đã bao gồm VAT)</p>
        </div>
      </div>
      <div className="px-5 pb-5">
        <Button
          disabled={dataCart?.length <= 0 || step < 2}
          variant={dataCart?.length <= 0 || step < 2 ? 'secondary' : 'primary'}
          className="w-100 fw-bolder"
          onClick={async () => {
            const orderCode = await handleClickBuy()
            navigate(
              `/hoa-don-dau-ra/dat-hang-thanh-cong?orderCode=${orderCode}`,
            )
          }}
        >
          Mua hàng
        </Button>
      </div>
    </div>
  )
}

export default Bill
