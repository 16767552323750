import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import employeeApi from 'api/employeeApi'
import { thunkGetEmployeeList } from 'features/Category/categorySlice'
import ModalEditEmployee from 'features/Category/components/ModalEditEmployee'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import KTFormSelect from 'general/components/Forms/KTFormSelect'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import AppData from 'general/constants/AppData'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'

function EmployeeScreen() {
  // MARK --- Parmas: ---
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { employees, pagination } = useAppSelector(
    (state) => state?.category,
    shallowEqual,
  )
  const { search, setSearch, debouncedSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'employeeList',
    'gFiltersEmployeeList',
  )
  const { isFetching, refetch } = useQuery({
    queryKey: ['employeeQuery', filters, debouncedSearch],
    queryFn: () =>
      dispatch(thunkGetEmployeeList({ ...filters, q: debouncedSearch })),
  })
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [toggleClearEmployees, setToggledClearEmployees] = useState(false)
  const [selectedEmployeeItem, setSelectedEmployeeItem] = useState(null)
  const [showingModalEditEmployee, setShowingModalEditEmployee] =
    useState(false)
  const [active, setActive] = useState('ADD')

  const handleDeleteEmployee = useCallback(
    (employee) => {
      Swal.fire({
        title: t('Xác nhận'),
        text: `Bạn có muốn xóa nhân viên ${employee.name}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('Đồng ý'),
        cancelButtonText: t('Hủy'),
        customClass: {
          confirmButton: 'btn btn-danger font-weight-bolder',
          cancelButton: 'btn btn-light font-weight-bolder',
        },
      }).then(async function (result) {
        if (result.value) {
          try {
            const res = await employeeApi.delete([employee.employeeId])
            const { result } = res
            if (result === 'success') {
              ToastHelper.showSuccess(t('Xóa nhân viên thành công'))
              void refetch()
            }
          } catch (error) {
            console.log(`Delete employee error: ${error?.message}`)
          }
        }
      })
    },
    [refetch, t],
  )

  const fullColumns = useMemo(
    () => [
      {
        name: 'Mã nhân viên',
        // sortable: false,
        width: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.code}
            </p>
          )
        },
      },
      {
        name: 'Tên nhân viên',
        // sortable: false,
        // width: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.name}
            </p>
          )
        },
      },
      {
        name: 'Chức danh',
        // sortable: false,
        // minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.companyTitle?.name}
            </p>
          )
        },
      },
      {
        name: 'Đơn vị',
        // sortable: false,
        // minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.companyUnit?.name}
            </p>
          )
        },
      },
      {
        name: 'Email',
        // sortable: false,
        // minWidth: '220px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.email}
            </p>
          )
        },
      },
      {
        name: 'Số điện thoại',
        // sortable: false,
        // minWidth: '220px',
        width: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.phone}
            </p>
          )
        },
      },
      {
        name: t('Status'),
        width: '150px',
        center: true,
        style: {
          borderRight: 'none',
        },
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className={`badge badge-light-${
                row?.status === 'ACTIVE' ? 'success' : 'danger'
              } `}
            >
              {row.status === 'ACTIVE' ? 'Đang sử dụng' : 'Ngừng sử dụng'}
            </div>
          )
        },
      },
      {
        name: '',
        center: true,
        grow: 0,
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <TableAction
              titleActionText={t('Edit')}
              icon={<IconAction className="fa-light fa-edit"></IconAction>}
              onClick={() => handleEditEmployee(row)}
            />
            <TableAction
              titleActionText={t('Delete')}
              icon={<IconAction className="fa-light fa-trash"></IconAction>}
              onClick={() => handleDeleteEmployee(row)}
            />
            <TableAction
              titleActionText={t('Replicate')}
              icon={<IconAction className="fa-light fa-clone"></IconAction>}
              onClick={() => handleReplicateEmployee(row)}
            />
          </div>
        ),
      },
    ],
    [handleDeleteEmployee, t],
  )

  function handleDeleteMultiEmployee() {
    const arrIdsToDelete = selectedEmployees.map((item) => item.employeeId)

    Swal.fire({
      title: t('Xác nhận'),
      text: `Bạn có muốn xóa ${arrIdsToDelete.length} nhân viên ?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Đồng ý'),
      cancelButtonText: t('Hủy'),
      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder',
        cancelButton: 'btn btn-light font-weight-bolder',
      },
    }).then(async function (result) {
      if (result.value) {
        const employeeIds = arrIdsToDelete
        try {
          const res = await employeeApi.delete(employeeIds)
          const { result } = res
          if (result === 'success') {
            clearSelectedEmployees()
            ToastHelper.showSuccess(t('Xóa nhân viên thành công'))
            void refetch()
          }
        } catch (error) {
          console.log(`Delete employee error: ${error?.message}`)
        }
      }
    })
  }

  const handleSelectedEmployeeChanged = useCallback((state) => {
    console.log('rendered')
    setSelectedEmployees(state.selectedRows)
  }, [])

  function clearSelectedEmployees() {
    setSelectedEmployees([])
    setToggledClearEmployees(!toggleClearEmployees)
  }

  function handleEditEmployee(employee) {
    setActive('EDIT')
    setSelectedEmployeeItem(employee)
    setShowingModalEditEmployee(true)
  }

  function handleReplicateEmployee(employee) {
    setActive('REPLICATE')
    setSelectedEmployeeItem(employee)
    setShowingModalEditEmployee(true)
  }

  const dynamicColumns = useAppDnD(fullColumns)

  return (
    <ContentContainer wrapperClassName="list-replaced-invoice">
      <ContentContainer.Header
        titleContent={t('Nhân viên')}
        description={`${t('Tổng')}: ${pagination?.total ?? 0} ${_.capitalize(
          t('Nhân viên'),
        )}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('Thêm nhân viên')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActive('ADD')
                  setShowingModalEditEmployee(true)
                },
              }}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={PreferenceKeys.savedColumnsEmployee}
            />
          </Space>
        }
      >
        <Space>
          {selectedEmployees.length > 0 ? (
            <CustomAntButton
              text={Utils.cn(t('Xóa'), selectedEmployees.length, 'nhân viên')}
              iconCn="fa-regular fa-trash"
              antProps={{
                type: 'primary',
                danger: true,
                onClick: () => {
                  handleDeleteMultiEmployee()
                },
              }}
            />
          ) : (
            <>
              <CustomAntInput
                type="search"
                borderRadius="sm"
                inputProps={{
                  value: search,
                  onChange: (e) => {
                    setSearch(e.target.value)
                  },
                }}
              />
              <label className="mr-2 mb-0" htmlFor="state">
                {_.capitalize(t('Trạng thái'))}
              </label>
              <KTFormSelect
                name="status"
                isCustom
                options={[{ name: 'Tất cả', value: '' }, ...AppData.status]}
                value={filters.status}
                onChange={(newValue) => {
                  setFilters({
                    page: 0,
                    status: newValue,
                  })
                }}
              />
            </>
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={employees}
          isLoading={isFetching}
          isClickable
          isSelectable
          pagination={{
            currentPage: filters.page + 1,
            rowsPerPage: filters.limit,
            total: pagination.total,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              if (iNewPage > 0) {
                iNewPage = iNewPage - 1
              }
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 0,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows: toggleClearEmployees,
            selectableRowSelected: (row) => {
              const arrId = selectedEmployees.map((item) => item.employeeId)
              return arrId.includes(row.employeeId)
            },
            handleSelectedRows: handleSelectedEmployeeChanged,
          }}
          handleDoubleClickRow={handleEditEmployee}
        />
      </ContentContainer.Body>

      {/* Modal employee edit */}
      <ModalEditEmployee
        show={showingModalEditEmployee}
        onClose={() => {
          setShowingModalEditEmployee(false)
          setSelectedEmployeeItem(null)
        }}
        active={active}
        // onExistDone={() => {
        //   setSelectedEmployeeItem(null);
        // }}
        employeeItem={selectedEmployeeItem}
        // onRefreshEmployeeList={() => {
        //   setSelectedEmployeeItem(null);
        //   getEmployeeList();
        // }}
      />
    </ContentContainer>
  )
}

export default EmployeeScreen
