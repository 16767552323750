import $ from 'jquery'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import './style.scss'
const animatedComponents = makeAnimated()
CustomSelect.propTypes = {
  onChange: PropTypes.any,
  className: PropTypes.string,
  selectOptions: PropTypes.array,
  currentValue: PropTypes.any,
  defaultValue: PropTypes.any,
  width: PropTypes.string,
  components: PropTypes.any,
  autoSize: PropTypes.bool,
  placeholder: PropTypes.string,
}

CustomSelect.defaultProps = {
  className: 'flex-grow-1',
  width: 'auto',
  placeholder: 'Chọn',
}

/**
 *
 * @param {{
 * selectOptions: Array<{value: unknown, label: unknown}>,
 * currentValue: unknown,
 * defaultValue: unknown,
 * onChange: () => void,
 * className: string,
 * width: string,
 * height: string,
 * components: any,
 * customFilterFn: () => void,
 * autoSize: boolean,
 * }} props
 */
function CustomSelect(props) {
  const {
    selectOptions,
    currentValue,
    onChange,
    className,
    defaultValue,
    width,
    height,
    components,
    classNamePrefix,
    customFilterFn,
    autoSize,
    menuParentRef,
    haveMenuParentRef = true,
    placeholder,
  } = props

  const selectRef = useRef(null)
  const [selectWidth, setSelectWidth] = useState(0)
  const [selectPosition, setSelectPosition] = useState(0)
  // const [isCollided, setIsCollided] = useState(false)
  useEffect(() => {
    const $kyHieuSelect = $(selectRef.current.controlRef).hasClass(
      'rs-kyhieu__control',
    )
    if (!$kyHieuSelect) return
    const handleResize = () => {
      setSelectWidth($(selectRef.current.controlRef).outerWidth())
      setSelectPosition($(selectRef.current.controlRef).offset().left)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  /** @type {import('react-select').StylesConfig} */
  const selectStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? '#304ffd' : '#dbe3ef',
      height,
    }),
    option: (style) => ({
      ...style,
    }),
    menu: (styles) => ({
      ...styles,
      width: autoSize ? $(selectRef.current.controlRef).outerWidth() : width,
    }),
    menuPortal: (styles) => ({
      ...styles,
      width,
      zIndex: 999,
      left: $(selectRef.current.controlRef).hasClass('rs-kyhieu__control')
        ? Math.floor(
            selectPosition -
              parseInt(width.replace(/[\D]+/g, '') - selectWidth),
          ) + 'px'
        : styles.left,
    }),
  }

  return (
    <Select
      {...props}
      styles={selectStyle}
      className={className}
      classNamePrefix={classNamePrefix}
      options={selectOptions}
      value={selectOptions.find((option) => option?.value === currentValue)}
      defaultValue={defaultValue}
      onChange={onChange}
      components={{
        ...animatedComponents,
        ...components,
        IndicatorSeparator: () => null,
      }}
      menuPortalTarget={
        haveMenuParentRef
          ? menuParentRef ?? document.getElementById('root')
          : null
      }
      // menuPosition='fixed'
      isOptionDisabled={(opt) => opt.disabled}
      filterOption={customFilterFn}
      ref={selectRef}
      placeholder={placeholder}
    />
  )
}

export default CustomSelect
