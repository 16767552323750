import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import KTAdvanceNav from 'general/components/OtherKeenComponents/KTAdvanceNav'
import AppResource from 'general/constants/AppResource'

DropdownHeaderHelpMenuInfo.propTypes = {}

function DropdownHeaderHelpMenuInfo(props) {
  // ----- Params -----
  const { t } = useTranslation()

  return (
    <div className="min-w-300px">
      <div>
        <div className="card-body p-4">
          {/* nav */}
          <KTAdvanceNav
            enableHoverStyle
            enableLinkRounded={true}
            itemList={[
              {
                text: 'Hướng dẫn sử dụng online',
                iconElement: <i className="fa-regular fa-circle-info" />,
              },
              {
                text: 'Nhân viên tư vấn số trên Facebook',
                iconElement: <i className="fa-regular fa-user" />,
              },
              {
                text: 'Cộng đồng hỗ trợ phần mềm',
                iconElement: <i className="fa-regular fa-user-group" />,
              },
              {
                text: 'Diễn đàn hỗ trợ ICORP',
                iconElement: <i className="fa-regular fa-chart-network" />,
              },
              {
                text: 'Tổng đài ICORP: 19000099',
                iconElement: <i className="fa-regular fa-phone" />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default DropdownHeaderHelpMenuInfo
