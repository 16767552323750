const calculateExchangedMoneyTableData = (tableData, exchangeRate) => {
  tableData.forEach((item) => {
    item.exchangedTotalUnitPrice = item.totalUnitPrice * exchangeRate
    item.exchangedTotalUnitPriceAfterTax =
      item.totalUnitPriceAfterTax * exchangeRate
    // item.discountMoney = (item.totalUnitPrice * item.discount) / 100
    item.exchangedDiscountMoney = item.discountMoney * exchangeRate
    // item.taxMoney = ((item.totalUnitPrice - item.discount) * item.taxRate) / 100
    item.exchangedTaxMoney = item.taxMoney * exchangeRate
    item.taxReductionExchange = item.taxReduction * exchangeRate
  })

  return tableData
}

export default calculateExchangedMoneyTableData
