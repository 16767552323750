import './style.scss'
import CustomButton from 'general/components/Button/CustomButton'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
NotificationModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  describeText: PropTypes.string,
}

NotificationModal.defaultProps = {
  title: 'Cảnh báo',
  describeText: '',
  icon: 'fa-light fa-triangle-exclamation',
  show: false,
  onClose: null,
}

function NotificationModal(props) {
  const { title, describeText, icon, ...restProps } = props

  return (
    <Modal
      className="NotificationModal font-sfpro-regular"
      {...restProps}
      //   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className=" ">
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="font-weight-bolder" style={{ fontSize: '18px' }}>
            <i
              className={`${icon} mr-3`}
              style={{ fontSize: 20, color: '#ff9f0e' }}
            ></i>
            {title}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-start p-5">
          <div
            className="font-weight-bold"
            style={{ fontSize: '14px' }}
            dangerouslySetInnerHTML={{ __html: describeText }}
          ></div>
        </div>
      </Modal.Body>

      <Modal.Footer className="">
        <CustomButton
          backgroundColor="#304FFD"
          text="Đóng"
          width="90px"
          color="white"
          onClick={props.onClose}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default NotificationModal
