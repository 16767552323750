import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import Utils from 'general/utils/Utils'
import Message100 from './components/Message100'
import Message102 from './components/Message102'
import Message103 from './components/Message103'
import Message200 from './components/Message200'
import Message202 from './components/Message202'
import Message203 from './components/Message203'
import Message204 from './components/Message204'
import Message301 from './components/Message301'
import Message999 from './components/Message999'
import './style.scss'
import Message300 from './components/Message300'
import { REGISTRATION_TAX_MESS_ORDER } from 'general/constants/AppConstants'
import Message206 from './components/Message206'
import Message_1 from './components/Message_1'

function ModalDataTransmissionLog(props) {
  const navigate = useNavigate()

  const {
    show,
    onHide,
    viewUrl,
    registration,
    invoice,
    errAnnouce,
    summaryInvoice,
    isInvoice = true,
  } = props

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      dialogClassName="ModalDataTransmissionLog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nhật ký truyền nhận dữ liệu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {registration && (
          <div>
            <div className="bg-secondary px-5 py-3">
              <div className="d-flex flex-row justify-content-between">
                <p className="mb-2 font-weight-bold">{registration.name}</p>
                <span
                  className="cursor-pointer p-1"
                  title="Xem tờ khai"
                  onClick={() => navigate(viewUrl)}
                >
                  <i
                    className="fa-regular fa-eye"
                    style={{ color: '#3F4254' }}
                  ></i>
                </span>
              </div>
              <div className="d-flex flex-row">
                <p className="mb-2 mr-8">{`Số: ${registration.no}`}</p>
                <p className="mb-2">{`Ngày lập: ${registration.date}`}</p>
              </div>
            </div>

            <div className="px-5 py-3 message-container">
              {registration.tax_authority_messages
                ?.sort(
                  (a, b) =>
                    REGISTRATION_TAX_MESS_ORDER[a.typeCode] -
                    REGISTRATION_TAX_MESS_ORDER[b.typeCode],
                )
                ?.map((message, index) =>
                  message.typeCode == 102 ? (
                    <Message102 key={index} message={message} />
                  ) : message.typeCode == 100 ? (
                    <Message100 key={index} message={message} />
                  ) : message.typeCode == 103 ? (
                    <Message103 key={index} message={message} />
                  ) : message.typeCode == 999 ? (
                    <Message999 key={index} message={message} />
                  ) : (
                    <></>
                  ),
                )}
            </div>
          </div>
        )}
        {invoice && (
          <div>
            <div className="bg-secondary px-5 py-3">
              <div className="d-flex flex-row justify-content-between">
                <p className="mb-2 font-weight-bold">
                  Lịch sử truyền nhận hoá đơn
                </p>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column">
                    <p className="mb-2 mr-8">{`Số: ${invoice?.no}`}</p>
                    <p className="mb-2 mr-8">{`Ký hiệu: ${invoice?.serial}`}</p>
                    <p className="mb-2">{`Ngày lập: ${Utils.formatDate(
                      invoice?.date,
                      'DD-MM-YYYY',
                    )}`}</p>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="">{`Tổng tiền thanh toán:`}</span>
                    <span style={{ fontSize: '20px', fontWeight: '600' }}>
                      {isInvoice
                        ? Utils.formatNumber(invoice?.totalAfterTax)
                        : Utils.formatNumber(invoice?.totalPrice)}{' '}
                      {invoice?.currency}
                    </span>
                  </div>
                </div>

                <p className="mb-2">{`Khách hàng: ${
                  invoice?.customerCompanyName ?? invoice?.customerName ?? ''
                }`}</p>
              </div>
            </div>

            <div className="px-5 py-3 message-container">
              {invoice.tax_authority_messages?.map((message, index) => {
                switch (message.typeCode) {
                  case 200:
                    return <Message200 key={index} message={message} />
                  case 202:
                    return <Message202 key={index} message={message} />
                  case 203:
                    return <Message203 key={index} message={message} />
                  case 204:
                    return <Message204 key={index} message={message} />
                  default:
                }
              })}
            </div>
          </div>
        )}
        {errAnnouce && (
          <div>
            <div className="bg-secondary px-5 py-3">
              <div className="d-flex flex-row justify-content-between">
                <p className="mb-2 font-weight-bold">
                  Lịch sử truyền nhận thông báo
                </p>
              </div>
            </div>

            <div className="px-5 py-3 message-container">
              {errAnnouce.tax_authority_messages?.map((message, index) => {
                switch (message.typeCode) {
                  case 300:
                    return <Message300 key={index} message={message} />
                  case 301:
                    return <Message301 key={index} message={message} />

                  case 204:
                    return <Message204 key={index} message={message} />
                  default:
                }
              })}
            </div>
          </div>
        )}
        {summaryInvoice && (
          <div>
            <div className="bg-secondary px-5 py-3">
              <div className="d-flex flex-row justify-content-between">
                <p className="mb-2 font-weight-bold">
                  Chuyển dữ liệu hóa đơn khởi tạo từ máy tính tiền đến cơ quan
                  thuế
                </p>
              </div>
              <div className="d-flex flex-row">
                <p className="mb-2 mr-8">{`Số lượng hóa đơn: ${summaryInvoice.invoiceQuantity}`}</p>
              </div>
            </div>

            <div className="px-5 py-3 message-container">
              {summaryInvoice.tax_authority_messages
                ?.sort(
                  (a, b) =>
                    REGISTRATION_TAX_MESS_ORDER[a.typeCode] -
                    REGISTRATION_TAX_MESS_ORDER[b.typeCode],
                )
                ?.map((message, index) =>
                  message.typeCode == 206 ? (
                    <Message206 key={index} message={message} />
                  ) : message.typeCode == 204 ? (
                    <Message204 key={index} message={message} />
                  ) : message.typeCode == -1 ? (
                    <Message_1 key={index} message={message} />
                  ) : message.typeCode == 999 ? (
                    <Message999 key={index} message={message} />
                  ) : (
                    <></>
                  ),
                )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  )
}

export default ModalDataTransmissionLog
