import axiosClient from './axiosClient'

const dashboardApi = {
  getOverall: (params, signal) => {
    const url = '/statistic/get-overall'
    return axiosClient.get(url, { params, signal })
  },
  getOverallNew: (url, params, signal) =>
    axiosClient.get(url, { params, signal }),
  getRemainingResource: (signal) => {
    const url = '/resource/synthetic'
    return axiosClient.get(url, { signal })
  },
  getCerts: (signal) => axiosClient.get('/account/cert', { signal }),
}

export default dashboardApi
