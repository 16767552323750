import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Space, Typography } from 'antd'
import goodsApi from 'api/goodsApi'
import { setAppSpinning } from 'app/appSlice'
import fileSample from 'assets/examples/Mau_danh_muc_hang_hoa_demo.xlsx'
import ModalEditGoods from 'features/Category/components/ModalEditGoods'
import { saveAs } from 'file-saver'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomDataTable from 'general/components/CustomDataTable'
import Dropzone from 'general/components/Dropzone'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import useFireSwal from 'hooks/useFireSwal'
import { useAppDispatch } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { goodsGroupQueries } from '../GoodsGroupScreen/queries'
import helpers from './helpers'
import { goodsQueries } from './queries'

export default function GoodsScreen() {
  // MARK --- Params: ---
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter('goodsList', 'gFiltersGoodsList')
  const [selectedGoods, setSelectedGoods] = useState([])
  const [toggleClearGoods, setToggledClearGoods] = useState(true)
  const [selectedGoodsItem, setSelectedGoodsItem] = useState(null)
  const [showingModalEditGoods, setShowingModalEditGoods] = useState(false)
  const [active, setActive] = useState('ADD')
  const [modalImportShowing, setModalImportShowing] = useState(false)
  const { fireConfirm } = useFireSwal()
  const modalRef = useRef(null)
  //#region Queries n mutations ============
  const queryClient = useQueryClient()

  const { data: goods, isLoading: isLoadingGoods } = useQuery(
    goodsQueries.listOfGoods(filters),
  )
  const { data: goodsGroups } = useQuery(goodsGroupQueries.listOfGoodsGroup())
  const deleteGoodsMutation = useMutation({
    mutationKey: ['deleteGoods'],
    mutationFn: (goodsIds) => goodsApi.delete(goodsIds),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: async () => {
      ToastHelper.showSuccess(t('DeleteGoodsSuccessful'))
      setSelectedGoods([])
      setToggledClearGoods((prev) => !prev)
      await queryClient.invalidateQueries({
        queryKey: [goodsQueries.base, { entity: 'goods' }],
      })
    },
  })

  const exportToExcel = useMutation({
    mutationKey: ['exportGoods'],
    mutationFn: goodsApi.exportToExcel,
    onSuccess: (res) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, 'Danh_sach_hang_hoa.xlsx')
    },
  })

  //#endregion

  // MARK --- Function ---

  async function handleDeleteMultiGoods() {
    const arrIdsToDelete = selectedGoods.map((item) => item.goodsId)
    const result = await fireConfirm(
      t('Confirm'),
      t('DoYouWantToDeleteMultipleGoods', { length: arrIdsToDelete.length }),
    )
    if (result.isDismissed) return false
    deleteGoodsMutation.mutate(arrIdsToDelete)
  }

  function handleEditGoods(row) {
    setActive('EDIT')
    setSelectedGoodsItem(row)
    setShowingModalEditGoods(true)
  }

  const handleDeleteGoods = useCallback(
    async (row) => {
      const result = await fireConfirm(
        t('Confirm'),
        t('DoYouWantToDeleteGoods', { name: row.name }),
      )
      if (result.isDismissed) return false
      deleteGoodsMutation.mutate([row.goodsId])
    },
    [deleteGoodsMutation, fireConfirm, t],
  )

  function handleReplicateGoods(row) {
    setActive('REPLICATE')
    setSelectedGoodsItem(row)
    setShowingModalEditGoods(true)
  }

  const fullColumns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<any>[]} */
    const arr = [
      {
        name: 'STT ưu tiên',
        selector: (row) => row?.numericalOrder,
      },
      {
        name: t('GoodsCode'),
        minWidth: '150px',
        selector: (row) => row?.code,
      },
      {
        name: t('GoodsName'),
        minWidth: '250px',
        selector: (row) => row?.name,
      },
      {
        name: t('GoodsGroup'),
        minWidth: '250px',
        selector: (row) => row?.goodsGroupName,
      },
      {
        name: t('Unit'),
        selector: (row) => row?.unit,
      },
      {
        name: t('Price'),
        minWidth: '130px',
        center: true,
        selector: (row) => row?.price,
        style: {
          justifyContent: 'flex-end',
        },
      },
      {
        name: t('VAT') + '(%)',
        center: true,
        style: {
          justifyContent: 'flex-end',
        },
        selector: (row) => row?.vat,
      },
      {
        name: t('DiscountRate'),
        center: true,
        style: {
          justifyContent: 'flex-end',
        },
        selector: (row) => row?.discountRate,
      },
      {
        name: t('Status'),
        width: '150px',
        center: true,
        style: {
          borderRight: 'none',
        },
        selector: (row) => row?.status,
        format: (row) => {
          const { color, text } = helpers.renderStatus(row?.status)
          return <div className={`badge badge-light-${color}`}>{text}</div>
        },
      },
      {
        name: '',
        disabled: true,
        center: true,
        cell: (row) => (
          <>
            <TableAction
              titleActionText={t('Edit')}
              icon={<IconAction className="fa-light fa-edit" />}
              onClick={() => {
                handleEditGoods(row)
              }}
            />
            <TableAction
              titleActionText={t('Delete')}
              icon={<IconAction className="fa-light fa-trash" />}
              onClick={() => {
                handleDeleteGoods(row)
              }}
            />
            <TableAction
              titleActionText={t('Replicate')}
              icon={<IconAction className="fa-light fa-clone" />}
              onClick={() => {
                handleReplicateGoods(row)
              }}
            />
          </>
        ),
      },
    ]
    return arr
  }, [handleDeleteGoods, t])
  const dynamicColumns = useAppDnD(fullColumns)

  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 0,
    })
  }, [debouncedSearch, setFilters])

  return (
    <ContentContainer wrapperClassName="goods-screen">
      <ContentContainer.Header
        titleContent={t('Goods')}
        description={`${t('Tổng')}: ${goods?.total} ${_.capitalize(
          t('Goods'),
        )}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('NewGoods')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActive('ADD')
                  setShowingModalEditGoods(true)
                },
              }}
            />
            <CustomAntButton
              text={t('Upload')}
              iconCn="fa-regular fa-upload"
              antProps={{
                type: 'primary',
                ghost: true,
                onClick: () => {
                  setModalImportShowing(true)
                },
              }}
            />
            <CustomAntButton
              text={t('Download')}
              iconCn="fa-regular fa-download"
              variant="success"
              isLoading={exportToExcel.isPending}
              antProps={{
                type: 'primary',
                onClick: () => {
                  exportToExcel.mutate(_.pick(filters, ['goodsGroupId']))
                },
              }}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={PreferenceKeys.savedColumnsGoods}
            />
          </Space>
        }
      >
        <Space>
          {selectedGoods.length > 0 ? (
            <CustomAntButton
              text={Utils.cn(t('Delete'), selectedGoods.length, 'hàng hoá')}
              iconCn="fa-regular fa-trash"
              antProps={{
                type: 'primary',
                danger: true,
                onClick: () => {
                  handleDeleteMultiGoods()
                },
              }}
            />
          ) : (
            <>
              <CustomAntInput
                type="search"
                borderRadius="sm"
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
              <label className="mr-2 mb-0" htmlFor="state">
                {_.capitalize(t('GoodsGroup'))}
              </label>
              <CustomAntSelect
                showSearch={false}
                autoMatchWidth={false}
                value={filters.goodsGroupId}
                options={_.concat(
                  [{ label: t('All'), value: '' }],
                  _.map(goodsGroups?.data, (item) => ({
                    label: item.name,
                    value: item.goodsGroupId.toString(),
                  })),
                )}
                onChange={(newValue) => {
                  setFilters({
                    page: 0,
                    goodsGroupId: newValue,
                  })
                }}
              />
            </>
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={goods?.data}
          isClickable
          isSelectable
          isLoading={isLoadingGoods}
          handleDoubleClickRow={handleEditGoods}
          pagination={{
            currentPage: filters?.page + 1,
            total: goods?.total,
            rowsPerPage: filters?.limit,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              if (iNewPage > 0) {
                iNewPage = iNewPage - 1
              }
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 0,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows: toggleClearGoods,
            handleSelectedRows: ({ selectedRows }) =>
              setSelectedGoods(selectedRows),
            selectableRowSelected: (row) => {
              const arrId = selectedGoods.map((item) => item.goodsId)
              return arrId.includes(row.goodsId)
            },
          }}
        />
      </ContentContainer.Body>

      {/* Modal goods edit */}
      <ModalEditGoods
        show={showingModalEditGoods}
        onClose={() => {
          setShowingModalEditGoods(false)
          setSelectedGoodsItem(null)
        }}
        active={active}
        goodsItem={selectedGoodsItem}
        onExistDone={() => {}}
      />

      {/* modal import */}
      <DraggableModal
        title={t('Upload')}
        hasApplyButton={false}
        isOpen={modalImportShowing}
        onOpenChange={setModalImportShowing}
        ref={modalRef}
        width={800}
      >
        <Dropzone
          extensions={['.xlsx', '.xls', '.csv']}
          server={{
            url: '/goods/bulk-upload',
            fieldName: 'file',
            method: 'post',
          }}
          onSuccess={(response) => {
            if (response) {
              saveAs(response, 'Tổng hợp hàng hóa lỗi.txt')
            }
            queryClient.invalidateQueries({
              queryKey: [goodsQueries.base, { entity: 'goods' }],
            })
          }}
        />
        <div>
          <span className="text-danger font-weight-bolder">Lưu ý:</span> Để có
          kết quả nhập khẩu chính xác nhất hãy sử dụng tệp mẫu.{' '}
          <Typography.Link
            href={fileSample}
            download={'Mau_danh_muc_hang_hoa.xlsx'}
            className="font-weight-bolder w-100"
          >
            {t('DownloadFileSample')}
          </Typography.Link>
        </div>
      </DraggableModal>
      {/* <VIModal
        titleModal={t('Upload')}
        modalSize="lg"
        show={modalImportShowing}
        contentModal={
          <ViewImportFile
            onPressCancel={() => {
              setModalImportShowing(false)
            }}
            onDownloadSampleFile={() => {
              const link = document.createElement('a')
              link.href = fileSample
              link.download = 'Mau_danh_muc_hang_hoa.xlsx'
              link.click()
              document.removeChild(link)
            }}
            onUpload={async (file) => {
              const res = await goodsApi.uploadFile(file)
              if (res.result === 'success') {
                ToastHelper.showSuccess(t('ImportGoodsListSuccess'))
                refetchGoods()
                setModalImportShowing(false)
              }
            }}
          />
        }
        closeBtn
        handleSubmit={() => {}}
        onHide={() => {
          setModalImportShowing(false)
        }}
      /> */}
    </ContentContainer>
  )
}
