import React, { useState } from 'react'

import './style.scss'
import AppResource from 'general/constants/AppResource'

import { Accordion, Dropdown, DropdownButton } from 'react-bootstrap'
import { SketchPicker } from 'react-color'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import {
  addNewDataFieldToRegionConfigs,
  configsToMatrixRegionBaseOnName,
  deleteSpecifiedDataField,
  setActiveDataField,
  setActiveDataFieldPropertyName,
  setActivePanel,
  setActiveRegionBaseOnName,
  setConfigDynamicFieldPanel,
  setInstanceInfo,
  setSpecifiedDataField,
  setSpecifiedDataFieldProperty,
  setSpecifiedRegion,
  thunkGetExtendFields,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import TemplateInvoiceHelper from 'general/helpers/TemplateInvoiceHelper'
import Utils from 'general/utils/Utils'
import ConfigDataFieldPanel from '../ConfigDataFieldPanel'
import extendFieldApi from 'api/extendFieldApi'
import ToastHelper from 'general/helpers/ToastHelper'
import { useColumnsDispatch } from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'

const displayAlignType = (alignType) => {
  switch (alignType) {
    case 'ALIGN_TYPE_1':
      return <i className="fas fa-align-left" style={{ color: 'black' }}></i>
    case 'ALIGN_TYPE_2':
      return <i className="fas fa-indent" style={{ color: 'black' }}></i>
    case 'ALIGN_TYPE_3':
      return <i className="fas fa-align-justify" style={{ color: 'black' }}></i>
    default:
      return ''
  }
}

const convertDisplayType = (displayType) => {
  switch (displayType) {
    case 'TITLE_CONTENT':
      return (
        <div
          className="display-type"
          style={{
            backgroundImage: `url(${AppResource.icons.icTitleContent.default})`,
          }}
        ></div>
      )
    case 'CONTENT_LEFT':
      return (
        <div
          className="display-type"
          style={{
            backgroundImage: `url(${AppResource.icons.icContentLeft.default})`,
          }}
        ></div>
      )
    case 'CONTENT':
      return (
        <div
          className="display-type"
          style={{
            backgroundImage: `url(${AppResource.icons.icContent.default})`,
          }}
        ></div>
      )
    default:
      return (
        <div
          className="display-type"
          style={{
            backgroundImage: `url(${AppResource.icons.icTitleContent.default})`,
          }}
        ></div>
      )
  }
}

// const displayBorderType = borderType => {
//   switch (borderType) {
//     case 'OUTER':
//       return <i className="fas fa-border-outer" style={{ color: 'black' }}></i>
//     case 'TOP':
//       return <i className="fas fa-border-top" style={{ color: 'black' }}></i>
//     case 'BOTTOM':
//       return <i className="fas fa-border-bottom" style={{ color: 'black' }}></i>
//     case 'LEFT':
//       return <i className="fas fa-border-left" style={{ color: 'black' }}></i>
//     case 'RIGHT':
//       return <i className="fas fa-border-right" style={{ color: 'black' }}></i>
//     case 'NONE':
//       return <i className="fas fa-border-none" style={{ color: 'black' }}></i>
//     default:
//       return ''
//   }
// }

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const reorderSignXml = (list, startIndex, num, endIndex) => {
  const result = Array.from(list)
  const removedArray = result.splice(startIndex, num)
  result.splice(
    startIndex < endIndex ? endIndex - num : endIndex,
    0,
    ...removedArray,
  )

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
})

function DetailControl(props) {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()
  const { style } = props

  const {
    activeRegion,
    activeDataField,
    activeDataFieldPropertyName,
    configDynamicFieldPanel,
    extendFields,
  } = useSelector((state) => state.templateInvoice)
  const {
    fontSize,
    color,
    lineHeight,
    extendFields: instanceExtendFields,
  } = useSelector((state) => state.templateInvoice.instance)

  // const [borderType, setBorderType] =
  //   useState('OUTER') /* OUTER, LEFT, RIGHT, TOP, BOTTOM, NONE*/
  const [selectedDataFields, setSelectedDataFields] = useState([])
  const [configDataPanelAction, setConfigDynamicFieldAction] =
    useState('CREATE')
  const [configDataFieldId, setConfigDataFieldId] = useState()

  const setTableDetailColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableDetailColumns',
      payload,
    })
  }

  const setTableDetail1Columns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'tableDetail1Columns',
      payload,
    })
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newRegionConfigs = reorder(
      TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
        activeRegion.regionConfigs,
      ),
      result.source.index,
      result.destination.index,
    )

    dispatch(
      setSpecifiedRegion({
        regionName: activeRegion.regionName,
        propertyData: {
          regionConfigs: newRegionConfigs
            .flat()
            .filter((item) => typeof item !== 'string'),
        },
      }),
    )
  }

  const onDragEndSignXml = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const mergeField = result.draggableId

    const groupRegionConfigs = activeRegion?.regionConfigs?.filter(
      (reConf) => reConf.mergeField === mergeField,
    )

    const groupLengthArr =
      TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc(
        activeRegion?.regionConfigs,
      ).map(([reConfArr]) => reConfArr.length)

    const source =
      result.source.index === 0
        ? result.source.index
        : groupLengthArr
            .slice(0, result.source.index)
            .reduce((total, num) => total + num)
    const destination =
      result.destination.index === 0
        ? result.destination.index
        : groupLengthArr
            .slice(
              0,
              result.destination.index > result.source.index
                ? result.destination.index + 1
                : result.destination.index,
            )
            .reduce((total, num) => total + num)

    const newRegionConfigs = reorderSignXml(
      activeRegion?.regionConfigs,
      source,
      groupRegionConfigs.length,
      destination,
    )

    dispatch(
      setSpecifiedRegion({
        regionName: activeRegion.regionName,
        propertyData: { regionConfigs: newRegionConfigs },
      }),
    )
  }

  const genCustomAccordionItem = (regionConf, provided) => {
    return (
      <Accordion.Item
        className="position-relative"
        eventKey={regionConf.dataField}
        onClick={(e) => {
          e.stopPropagation()
          if (activeDataField?.dataField !== regionConf.dataField) {
            dispatch(setActiveDataField(regionConf))
          }

          if (e.ctrlKey) {
            setSelectedDataFields((old) => {
              let index = old.findIndex(
                ({ dataField }) => dataField === regionConf.dataField,
              )

              if (index === -1) {
                old.push(regionConf)
              }

              return old
            })
          } else {
            setSelectedDataFields([regionConf])
          }
        }}
      >
        <Accordion.Button
          className={`d-flex align-items-center ${
            selectedDataFields.find(
              ({ dataField }) => dataField === regionConf.dataField,
            ) || activeDataField?.dataField === regionConf.dataField
              ? 'active-data-field-r'
              : 'bg-hover-secondary'
          }`}
        >
          <span
            className="mr-4"
            style={{
              visibility: 'hidden',
            }}
          >
            <i
              className="fas fa-bars text-secondary"
              style={{ marginTop: '1px' }}
            ></i>
          </span>

          <input
            className="cursor-pointer mr-4"
            type="checkbox"
            style={{ width: '16px', height: '16px', minWidth: '16px' }}
            checked={regionConf.show}
            disabled={regionConf?.canHide === false}
            onChange={(e) => {
              /* Nếu là trường mở rộng */
              if (regionConf.dataField.startsWith('extendField')) {
                if (e.target.checked) {
                  let existedInInstanceExtendFields =
                    !!instanceExtendFields?.find(
                      ({ name }) => name == regionConf.dataField,
                    )

                  if (!existedInInstanceExtendFields) {
                    dispatch(
                      setInstanceInfo({
                        extendFields: [
                          ...instanceExtendFields,
                          {
                            name: regionConf.dataField,
                            regionName: activeRegion.regionName,
                            dataType: regionConf.dataType,
                            label: regionConf.label.value,
                            labelEn: regionConf.labelEn.value,
                            defaultValue: regionConf.labelEn.value,
                          },
                        ],
                      }),
                    )
                  }
                } else {
                  dispatch(
                    setInstanceInfo({
                      extendFields: [
                        ...instanceExtendFields.filter(
                          ({ name }) => name != regionConf.dataField,
                        ),
                      ],
                    }),
                  )
                }
              }

              dispatch(
                setSpecifiedDataField({
                  regionName: activeRegion.regionName,
                  dataFieldName: regionConf.dataField,
                  propertyData: {
                    show: e.target.checked,
                  },
                }),
              )

              /* Nếu bật trường số tiền chưa thuế sẽ auto bật cả trường tiền thuế */
              if (regionConf.dataField == 'ticketPriceBeforeVAT') {
                dispatch(
                  setSpecifiedDataField({
                    regionName: activeRegion.regionName,
                    dataFieldName: 'vatPrice',
                    propertyData: {
                      show: e.target.checked,
                    },
                  }),
                )
              }
            }}
            onClick={(e) => e.stopPropagation()}
          />

          <span className="flex-fill text-truncate">
            {['CONTENT_LEFT', 'CONTENT'].includes(regionConf.displayType) ||
            regionConf.typeShow == 1
              ? `${regionConf.value.value}`
              : `${regionConf.label.value} ${regionConf.labelEn.value}: ${regionConf.value.value}`
                  ?.trim()
                  ?.replace(/^:+|:+$/g, '')}
          </span>

          {regionConf.dataField?.startsWith('extendField') && (
            <span
              className="cursor-pointer mr-4"
              onClick={(e) => {
                e.stopPropagation()

                setConfigDynamicFieldAction('UPDATE')
                let extendField = extendFields.find(
                  ({ name }) => name == regionConf.dataField,
                )
                if (!extendField) alert('Có lỗi !!')
                setConfigDataFieldId(extendField?.fieldId)

                dispatch(setConfigDynamicFieldPanel(true))
              }}
            >
              <i
                className="fas fa-gear text-secondary"
                style={{ marginTop: '1px' }}
              ></i>
            </span>
          )}

          {(regionConf.dataField?.startsWith('extendField') ||
            regionConf.dataField?.startsWith('custom')) && (
            <span
              className="cursor-pointer mr-4"
              onClick={(e) => {
                e.stopPropagation()

                handleDeleteDataField(regionConf)
              }}
              title="Click để xóa thông tin"
            >
              <i
                className="fas fa-trash text-danger"
                style={{ marginTop: '1px' }}
              ></i>
            </span>
          )}
        </Accordion.Button>

        <div
          className="position-absolute"
          {...provided.dragHandleProps}
          style={{
            visibility:
              activeRegion?.regionName !== 'tableFooter' ? 'visible' : 'hidden',
            top: '1rem',
            left: '1.25rem',
            zIndex: 10,
          }}
        >
          <i
            className="fas fa-bars text-secondary"
            style={{ marginTop: '1px' }}
          ></i>
        </div>

        <Accordion.Body
          className={`${
            selectedDataFields.find(
              ({ dataField }) => dataField === regionConf.dataField,
            ) || activeDataField?.dataField === regionConf.dataField
              ? 'active-data-field-r'
              : 'bg-hover-secondary'
          }`}
        >
          <div className="d-flex flex-column">
            {regionConf?.typeShow !== 1 && (
              <div className="d-flex flex-row align-items-center mb-2">
                <p className="mb-0" style={{ width: 70, fontSize: '0.90em' }}>
                  Tiêu đề
                </p>
                <input
                  type="text"
                  className={`form-control data-field-input ${
                    activeDataField?.dataField == regionConf.dataField &&
                    activeDataFieldPropertyName == 'label'
                      ? 'active'
                      : ''
                  }`}
                  readOnly={regionConf.label.editable === false}
                  value={regionConf.label.value}
                  placeholder="[Tiêu đề]"
                  onChange={(e) =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: regionConf.dataField,
                        propertyName: 'label',
                        propertyData: {
                          value: e.target.value,
                        },
                      }),
                    )
                  }
                  onClick={() => {
                    dispatch(setActiveDataFieldPropertyName('label'))
                  }}
                />
              </div>
            )}

            {regionConf?.typeShow !== 1 && (
              <div className="d-flex flex-row align-items-center mb-2">
                <p className="mb-0" style={{ width: 70, fontSize: '0.90em' }}>
                  Song ngữ
                </p>
                <input
                  type="text"
                  className={`form-control data-field-input ${
                    activeDataField?.dataField == regionConf.dataField &&
                    activeDataFieldPropertyName == 'labelEn'
                      ? 'active'
                      : ''
                  }`}
                  readOnly={regionConf.labelEn.editable === false}
                  value={regionConf?.labelEn.value}
                  placeholder="[Tiêu đề song ngữ]"
                  onChange={(e) =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: regionConf.dataField,
                        propertyName: 'labelEn',
                        propertyData: {
                          value: e.target.value,
                        },
                      }),
                    )
                  }
                  onClick={() => {
                    dispatch(setActiveDataFieldPropertyName('labelEn'))
                  }}
                />
              </div>
            )}

            {regionConf?.typeShow !== 2 && (
              <div className="d-flex flex-row align-items-center">
                <p className="mb-0" style={{ width: 70, fontSize: '0.90em' }}>
                  Nội dung
                </p>
                <input
                  type="text"
                  className={`form-control data-field-input ${
                    activeDataField?.dataField == regionConf.dataField &&
                    activeDataFieldPropertyName == 'value'
                      ? 'active'
                      : ''
                  }`}
                  readOnly={regionConf.value.editable === false}
                  value={regionConf.value.value}
                  placeholder={
                    regionConf.dataField?.startsWith('custom')
                      ? '[Nội dung]'
                      : undefined
                  }
                  onChange={(e) =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: regionConf.dataField,
                        propertyName: 'value',
                        propertyData: {
                          value: e.target.value,
                        },
                      }),
                    )
                  }
                  onClick={() => {
                    dispatch(setActiveDataFieldPropertyName('value'))
                  }}
                />
              </div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  useEffect(() => {
    dispatch(setActiveRegionBaseOnName('sellerInfo'))

    return () => {
      dispatch(setConfigDynamicFieldPanel(false))
    }
  }, [])

  useEffect(() => {
    if (activeDataField && selectedDataFields?.length == 0) {
      setSelectedDataFields([activeDataField])
    }
  }, [activeDataField])

  useEffect(() => {
    if (activeRegion) {
      dispatch(setActiveDataField(activeRegion.regionConfigs[0]))
    }
  }, [activeRegion?.regionName])

  function handleDeleteDataField(reConf) {
    if (!activeRegion || !reConf) return
    switch (activeRegion.regionName) {
      case 'sellerInfo':
      case 'invoiceInfo':
        dispatch(
          deleteSpecifiedDataField({
            regionName: activeRegion.regionName,
            dataFieldName: reConf.dataField,
          }),
        )

        break
      case 'buyerInfo':
      case 'tableDetail':
      case 'tableDetail1':
        if (reConf.dataField.startsWith('extendField')) {
          ;(async function () {
            try {
              let res = await extendFieldApi.deleteExtendField({
                name: reConf.dataField,
              })

              if (res.result == 'success') {
                dispatch(
                  setInstanceInfo({
                    extendFields: [
                      ...instanceExtendFields.filter(
                        ({ name }) => name != reConf.dataField,
                      ),
                    ],
                  }),
                )

                dispatch(thunkGetExtendFields())

                if (activeRegion.regionName == 'tableDetail') {
                  setTableDetailColumns((old) =>
                    old.filter(
                      ({ dataIndex }) => dataIndex != reConf.dataField,
                    ),
                  )
                } else if (activeRegion.regionName == 'tableDetail1') {
                  setTableDetail1Columns((old) =>
                    old.filter(
                      ({ dataIndex }) => dataIndex != reConf.dataField,
                    ),
                  )
                }

                dispatch(
                  deleteSpecifiedDataField({
                    regionName: activeRegion.regionName,
                    dataFieldName: reConf.dataField,
                  }),
                )

                ToastHelper.showSuccess('Xóa thành công')
              }
            } catch (e) {
              console.log(e)
            }
          })()
        } else {
          dispatch(
            deleteSpecifiedDataField({
              regionName: activeRegion.regionName,
              dataFieldName: reConf.dataField,
            }),
          )
        }

        break
    }
  }

  return (
    <div
      className="DetailControl d-flex flex-column"
      style={{ ...style, overflowY: 'hidden', overflowX: 'visible' }}
    >
      <div
        className="d-flex flex-row align-items-center mb-3"
        style={{ flex: '0 1 auto' }}
      >
        <button
          type="button"
          className="btn btn-icon mr-2"
          onClick={() => {
            dispatch(setActivePanel('main_ctrl'))
            dispatch(setActiveRegionBaseOnName(null))
          }}
        >
          <i className="fas fa-arrow-left" style={{ color: '#000000' }}></i>
        </button>
        <h3 className="static-title">Tùy chỉnh nội dung chi tiết hoá đơn</h3>
      </div>

      {!configDynamicFieldPanel && (
        <>
          <div className="tool-dashboard">
            <div
              className="d-flex flex-row align-items-center mb-1"
              style={{ paddingLeft: '1.25rem' }}
            >
              {/* fontSize */}
              <div className="mr-1">
                <DropdownButton
                  title={
                    (activeDataField &&
                      activeDataField[activeDataFieldPropertyName]?.fontSize) ||
                    fontSize
                  }
                  onSelect={(fs) =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyName: activeDataFieldPropertyName,
                        propertyData: { fontSize: parseInt(fs) },
                      }),
                    )
                  }
                  className="dd-font-size"
                >
                  {[...Array(30).keys()].slice(8).map((num) => (
                    <Dropdown.Item eventKey={num} key={num}>
                      {num}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              {/* alignType */}
              <div className="mr-1">
                <DropdownButton
                  title={displayAlignType(
                    activeRegion?.alignType || 'ALIGN_TYPE_1',
                  )}
                  disabled={!activeRegion?.alignType}
                  onSelect={(fs) =>
                    dispatch(
                      setSpecifiedRegion({
                        regionName: activeRegion?.regionName,
                        propertyData: { alignType: fs },
                      }),
                    )
                  }
                  className="dd-align-type"
                >
                  <Dropdown.Item eventKey="ALIGN_TYPE_1">
                    <div>
                      <i
                        className="fas fa-align-left mr-4"
                        style={{ color: 'black' }}
                      ></i>
                      Căn theo tiêu đề
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="ALIGN_TYPE_2">
                    <div>
                      <i
                        className="fas fa-indent mr-4"
                        style={{ color: 'black' }}
                      ></i>
                      Căn theo tiêu đề dài nhất
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="ALIGN_TYPE_3">
                    <div>
                      <i
                        className="fas fa-align-justify mr-4"
                        style={{ color: 'black' }}
                      ></i>
                      Căn theo tiêu đề dài nhất bao gồm ":"
                    </div>
                  </Dropdown.Item>
                </DropdownButton>
              </div>

              {/* bold */}
              <div className="mr-1">
                <div
                  className={`bold-type bg-hover-white cursor-pointer ${
                    activeDataField &&
                    activeDataField[activeDataFieldPropertyName]?.fontWeight ===
                      'bold'
                      ? 'bg-white'
                      : ''
                  }`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyName: activeDataFieldPropertyName,
                        propertyData: {
                          fontWeight:
                            activeDataField &&
                            activeDataField[activeDataFieldPropertyName]
                              ?.fontWeight === 'bold'
                              ? 'normal'
                              : 'bold',
                        },
                      }),
                    )
                  }
                  title="In đậm"
                >
                  <i
                    className="fas fa-bold"
                    style={{
                      color:
                        activeDataField &&
                        activeDataField[activeDataFieldPropertyName]
                          ?.fontWeight === 'bold'
                          ? 'blue'
                          : 'black',
                    }}
                  ></i>
                </div>
              </div>

              {/* italic */}
              <div className="mr-1">
                <div
                  className={`italic-type bg-hover-white cursor-pointer ${
                    activeDataField &&
                    activeDataField[activeDataFieldPropertyName]?.fontStyle ===
                      'italic'
                      ? 'bg-white'
                      : ''
                  }`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyName: activeDataFieldPropertyName,
                        propertyData: {
                          fontStyle:
                            activeDataField &&
                            activeDataField[activeDataFieldPropertyName]
                              ?.fontStyle === 'italic'
                              ? 'normal'
                              : 'italic',
                        },
                      }),
                    )
                  }
                  title="In nghiêng"
                >
                  <i
                    className="fas fa-italic"
                    style={{
                      color:
                        activeDataField &&
                        activeDataField[activeDataFieldPropertyName]
                          ?.fontStyle === 'italic'
                          ? 'blue'
                          : 'black',
                    }}
                  ></i>
                </div>
              </div>

              {/* mst */}
              <div className="">
                <div
                  className={`mst-type bg-hover-white cursor-pointer ${
                    activeDataField?.dataField.includes('TaxCode')
                      ? activeDataField.mstSplitCells
                        ? 'bg-white'
                        : ''
                      : 'disable'
                  }`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataField({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyData: {
                          mstSplitCells: !activeDataField.mstSplitCells,
                        },
                      }),
                    )
                  }
                  title="Hiển thị mã số thuế tách thành từng ô"
                >
                  <p
                    className="mb-0 font-weight-bold"
                    style={{
                      color: activeDataField?.mstSplitCells ? 'blue' : 'black',
                    }}
                  >
                    MST
                  </p>
                </div>
              </div>

              <div className="divide"></div>

              {/* tableHeaderColor */}
              <div className="mr-1">
                <Dropdown
                  title="Đổ màu nền tiêu đề bảng"
                  className="bg-hover-white dd-th-color"
                >
                  <Dropdown.Toggle
                    className="active-border-blue dd-color-picker border-0"
                    disabled={activeRegion?.regionName !== 'tableDetail'}
                  >
                    <>
                      <i
                        className="fas fa-tint mb-1 px-1"
                        style={{ color: 'black' }}
                      ></i>
                      <div
                        className="w-100"
                        style={{
                          borderBottom: `3px solid ${
                            activeRegion?.tableHeaderColor || 'transparent'
                          }`,
                          height: '3px',
                        }}
                      ></div>
                    </>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <SketchPicker
                      // color={activeRegion?.tableHeaderColor}
                      onChangeComplete={(c) =>
                        dispatch(
                          setSpecifiedRegion({
                            regionName: activeRegion?.regionName,
                            propertyData: { tableHeaderColor: c.hex },
                          }),
                        )
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* color */}
              <div className="">
                <Dropdown
                  title="Thay đổi màu chữ"
                  className="bg-hover-white dd-text-color"
                >
                  <Dropdown.Toggle
                    className="dd-color-picker border-0"
                    disabled={true}
                  >
                    <>
                      <i
                        className="fal fa-font mb-1 px-1"
                        style={{ color: 'black' }}
                      ></i>
                      <div
                        className="w-100"
                        style={{
                          borderBottom: `3px solid ${
                            (activeDataField &&
                              activeDataField[activeDataFieldPropertyName]
                                ?.color) ||
                            color
                          }`,
                          height: '3px',
                        }}
                      ></div>
                    </>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <SketchPicker
                      color={
                        activeDataField &&
                        activeDataField[activeDataFieldPropertyName]?.color
                      }
                      onChangeComplete={(c) =>
                        dispatch(
                          setSpecifiedDataFieldProperty({
                            regionName: activeRegion.regionName,
                            dataFieldName: activeDataField.dataField,
                            propertyName: activeDataFieldPropertyName,
                            propertyData: {
                              color: c.hex,
                            },
                          }),
                        )
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="divide mr-1"></div>

              {/* align-left */}
              <div className="mr-1">
                <div
                  className={`align-left-type bg-hover-white cursor-pointer ${
                    ['sellerInfo', 'ticketPrice'].includes(
                      activeRegion?.regionName,
                    )
                      ? activeDataField?.fieldAlign === 'start'
                        ? 'bg-white'
                        : ''
                      : 'disable'
                  }`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataField({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyData: {
                          fieldAlign: 'start',
                        },
                      }),
                    )
                  }
                  title="Căn trái"
                >
                  <i
                    className="fas fa-align-left"
                    style={{
                      color:
                        activeDataField?.fieldAlign === 'start'
                          ? 'blue'
                          : 'black',
                    }}
                  ></i>
                </div>
              </div>

              {/* align-center */}
              <div className="mr-1">
                <div
                  className={`align-center-type bg-hover-white cursor-pointer ${
                    ['sellerInfo', 'ticketPrice'].includes(
                      activeRegion?.regionName,
                    )
                      ? activeDataField?.fieldAlign === 'center'
                        ? 'bg-white'
                        : ''
                      : 'disable'
                  }`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataField({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyData: {
                          fieldAlign: 'center',
                        },
                      }),
                    )
                  }
                  title="Căn giữa"
                >
                  <i
                    className="fas fa-align-center"
                    style={{
                      color:
                        activeDataField?.fieldAlign === 'center'
                          ? 'blue'
                          : 'black',
                    }}
                  ></i>
                </div>
              </div>

              {/* align-right */}
              <div className="mr-1">
                <div
                  className={`align-right-type bg-hover-white cursor-pointer ${
                    ['sellerInfo', 'ticketPrice'].includes(
                      activeRegion?.regionName,
                    )
                      ? activeDataField?.fieldAlign === 'end'
                        ? 'bg-white'
                        : ''
                      : 'disable'
                  }`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataField({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyData: {
                          fieldAlign: 'end',
                        },
                      }),
                    )
                  }
                  title="Căn phải"
                >
                  <i
                    className="fas fa-align-right"
                    style={{
                      color:
                        activeDataField?.fieldAlign === 'end'
                          ? 'blue'
                          : 'black',
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-row align-items-center"
              style={{ paddingLeft: '1.25rem' }}
            >
              {/* fontsize minus */}
              <div className="mr-1">
                <div
                  className={`font-size-minus bg-hover-white cursor-pointer`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyName: activeDataFieldPropertyName,
                        propertyData: {
                          fontSize:
                            (activeDataField[activeDataFieldPropertyName]
                              ?.fontSize || fontSize) - 1,
                        },
                      }),
                    )
                  }
                  title="Giảm cỡ chữ 1 đơn vị"
                >
                  <p
                    className="mb-0 font-weight-bold"
                    style={{ color: 'black' }}
                  >
                    A-
                  </p>
                </div>
              </div>

              {/* fontsize plus */}
              <div className="mr-1">
                <div
                  className={`font-size-minus bg-hover-white cursor-pointer`}
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyName: activeDataFieldPropertyName,
                        propertyData: {
                          fontSize:
                            (activeDataField[activeDataFieldPropertyName]
                              ?.fontSize || fontSize) + 1,
                        },
                      }),
                    )
                  }
                  title="Tăng cỡ chữ 1 đơn vị"
                >
                  <p
                    className="mb-0 font-weight-bold"
                    style={{ color: 'black' }}
                  >
                    A+
                  </p>
                </div>
              </div>

              {/* lineHeight plus */}
              <div className="mr-1">
                <div
                  className="lineheight-down bg-hover-white cursor-pointer"
                  title="Tăng chiều cao 1 đơn vị"
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyName: activeDataFieldPropertyName,
                        propertyData: {
                          lineHeight:
                            (activeDataField[activeDataFieldPropertyName]
                              ?.lineHeight || lineHeight) + 0.1,
                        },
                      }),
                    )
                  }
                >
                  <div
                    style={{
                      backgroundImage: `url(${AppResource.icons.icLineHeightUp.default})`,
                    }}
                  ></div>
                </div>
              </div>

              {/* lineHeight minus */}
              <div className="mr-1">
                <div
                  className="lineheight-down bg-hover-white cursor-pointer"
                  title="Giảm chiều cao 1 đơn vị"
                  onClick={() =>
                    dispatch(
                      setSpecifiedDataFieldProperty({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyName: activeDataFieldPropertyName,
                        propertyData: {
                          lineHeight:
                            (activeDataField[activeDataFieldPropertyName]
                              ?.lineHeight || lineHeight) - 0.1,
                        },
                      }),
                    )
                  }
                >
                  <div
                    style={{
                      backgroundImage: `url(${AppResource.icons.icLineHeightDown.default})`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="divide"></div>

              {/* displayType */}
              <div className="mr-1">
                <DropdownButton
                  title={convertDisplayType(activeDataField?.displayType)}
                  onSelect={(ds) =>
                    dispatch(
                      setSpecifiedDataField({
                        regionName: activeRegion.regionName,
                        dataFieldName: activeDataField.dataField,
                        propertyData: {
                          displayType: ds,
                        },
                      }),
                    )
                  }
                  className="dd-display-type"
                  disabled={activeRegion?.regionName !== 'sellerInfo'}
                >
                  <Dropdown.Item eventKey="TITLE_CONTENT">
                    <div className="d-flex flex-row align-items-center">
                      <div
                        className="display-type mr-4"
                        style={{
                          backgroundImage: `url(${AppResource.icons.icTitleContent.default})`,
                        }}
                      ></div>
                      Hiển thị tiêu đề và nội dung
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="CONTENT_LEFT">
                    <div className="d-flex flex-row align-items-center">
                      <div
                        className="display-type mr-4"
                        style={{
                          backgroundImage: `url(${AppResource.icons.icContentLeft.default})`,
                        }}
                      ></div>
                      Chỉ hiển thị nội dung và căn mép trái
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="CONTENT">
                    <div className="d-flex flex-row align-items-center">
                      <div
                        className="display-type mr-4"
                        style={{
                          backgroundImage: `url(${AppResource.icons.icContent.default})`,
                        }}
                      ></div>
                      Chỉ hiển thị nội dung
                    </div>
                  </Dropdown.Item>
                </DropdownButton>
              </div>

              {/* borderType */}
              {/* <div className="mr-1">
                <DropdownButton
                  title={displayBorderType(borderType)}
                  onSelect={(bt) => setBorderType(bt)}
                  className="dd-display-border-type"
                  disabled={activeRegion?.regionName !== 'tableFooter'}
                >
                  <div className="d-flex flex-row">
                    <Dropdown.Item eventKey="OUTER">
                      <div>
                        <i className="fas fa-border-outer" style={{ color: 'black' }}></i>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="BOTTOM">
                      <div>
                        <i className="fas fa-border-bottom" style={{ color: 'black' }}></i>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="TOP">
                      <div>
                        <i className="fas fa-border-top" style={{ color: 'black' }}></i>
                      </div>
                    </Dropdown.Item>
                  </div>
                  <div className="d-flex flex-row">
                    <Dropdown.Item eventKey="LEFT">
                      <div>
                        <i className="fas fa-border-left" style={{ color: 'black' }}></i>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="RIGHT">
                      <div>
                        <i className="fas fa-border-right" style={{ color: 'black' }}></i>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="NONE">
                      <div>
                        <i className="fas fa-border-none" style={{ color: 'black' }}></i>
                      </div>
                    </Dropdown.Item>
                  </div>
                </DropdownButton>
              </div> */}

              <div className="mr-1">
                <div
                  className="breakline bg-hover-white cursor-pointer"
                  title="Xuống dòng tự động"
                >
                  <div
                    style={{
                      backgroundImage: `url(${AppResource.icons.icBreakLine.default})`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="mr-1">
                <div
                  className={`align-right-type bg-hover-white cursor-pointer`}
                  title="Đặt lại giá trị mặc định"
                >
                  <i className="fas fa-eraser" style={{ color: 'black' }}></i>
                </div>
              </div>

              <div className="divide"></div>

              <div className="mr-1 position-relative mergeline-gif-container">
                <div
                  className={`mergeline bg-hover-white d-flex flex-row align-items-center`}
                  style={{
                    cursor: `${
                      activeRegion?.regionName == 'sellerInfo' ||
                      activeRegion?.regionName == 'buyerInfo'
                        ? 'pointer'
                        : 'default'
                    }`,
                  }}
                  onClick={() => {
                    if (
                      activeRegion?.regionName !== 'sellerInfo' &&
                      activeRegion?.regionName !== 'buyerInfo'
                    ) {
                      return
                    }

                    if (selectedDataFields.length > 1) {
                      if (activeDataField?.mergeField) {
                        for (let item of selectedDataFields) {
                          let { dataField } = item
                          dispatch(
                            setSpecifiedDataField({
                              regionName: activeRegion?.regionName,
                              dataFieldName: dataField,
                              propertyData: { mergeField: '' },
                            }),
                          )
                        }
                      } else {
                        let mergeFieldId = Utils.makeId(5)
                        for (let item of selectedDataFields) {
                          let { dataField } = item
                          dispatch(
                            setSpecifiedDataField({
                              regionName: activeRegion?.regionName,
                              dataFieldName: dataField,
                              propertyData: { mergeField: mergeFieldId },
                            }),
                          )
                        }

                        dispatch(
                          configsToMatrixRegionBaseOnName({
                            regionName: activeRegion.regionName,
                          }),
                        )
                      }
                    }
                  }}
                >
                  <div
                    className="mr-1"
                    style={{
                      backgroundImage: `url(${AppResource.icons.icMergeLine.default})`,
                    }}
                  ></div>
                  <p className="mb-0">Gộp dòng</p>
                </div>

                <div
                  className="position-absolute mergeline-gif bg-white"
                  width="250"
                >
                  <img
                    src={AppResource.images.img_merge_line}
                    className=""
                    alt=""
                    width="250"
                  />
                  <div className="mergeline-gif-note font-weight-bold">
                    Giữ "Ctrl" và chọn các dòng cần gộp để thực hiện gộp
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="border mt-1 py-2"
            style={{
              flex: '1 1 auto',
              overflowY: 'scroll',
              overflowX: 'visible',
            }}
          >
            <DragDropContext
              onDragEnd={
                activeRegion?.regionName === 'signXml'
                  ? onDragEndSignXml
                  : onDragEnd
              }
            >
              <Droppable droppableId="ALL-DATA-FIELD">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Accordion alwaysOpen flush>
                      {/* != signXml */}
                      {activeRegion &&
                        activeRegion?.regionName !== 'signXml' &&
                        TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc2(
                          activeRegion.regionConfigs,
                        )?.map(([mergeField, ...regionConfArr], index) =>
                          mergeField ? (
                            <Draggable
                              key={`group-${mergeField}`}
                              id={`group-${mergeField}`}
                              draggableId={`group-${mergeField}`}
                              index={index}
                              isDragDisabled={true}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <Droppable
                                    droppableId={mergeField}
                                    type={mergeField}
                                  >
                                    {(provided) => (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                          borderBottom: '2px solid #e3e3e3',
                                          borderTop: '2px solid #e3e3e3',
                                        }}
                                      >
                                        {regionConfArr.map((regionConf) =>
                                          regionConf.dataField.includes(
                                            'notShow',
                                          ) ||
                                          regionConf.dataField.includes(
                                            'NotShow',
                                          ) ||
                                          (!regionConf.show &&
                                            regionConf.previewField &&
                                            regionConf.dataField !==
                                              'translationCurrency') ? (
                                            <></>
                                          ) : (
                                            <Draggable
                                              key={regionConf.dataField}
                                              id={regionConf.dataField}
                                              draggableId={regionConf.dataField}
                                              index={index}
                                              isDragDisabled={
                                                activeRegion?.regionName ===
                                                'tableFooter'
                                              }
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                      .style,
                                                  )}
                                                >
                                                  {genCustomAccordionItem(
                                                    regionConf,
                                                    provided,
                                                  )}
                                                </div>
                                              )}
                                            </Draggable>
                                          ),
                                        )}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              )}
                            </Draggable>
                          ) : (
                            regionConfArr.map((regionConf) =>
                              regionConf.dataField.includes('notShow') ||
                              regionConf.dataField.includes('NotShow') ||
                              (!regionConf.show &&
                                regionConf.previewField &&
                                regionConf.dataField !==
                                  'translationCurrency') ? (
                                <></>
                              ) : (
                                <Draggable
                                  key={regionConf.dataField}
                                  id={regionConf.dataField}
                                  draggableId={regionConf.dataField}
                                  index={index}
                                  isDragDisabled={
                                    activeRegion?.regionName === 'tableFooter'
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                      )}
                                    >
                                      {genCustomAccordionItem(
                                        regionConf,
                                        provided,
                                      )}

                                      {/* tableDetail col children */}
                                      {regionConf?.children?.map(
                                        (regionConfC) => (
                                          <div
                                            className={`d-flex flex-row align-items-start justify-content-between px-2 py-2 ${
                                              activeDataField?.dataField ===
                                              regionConfC.dataField
                                                ? 'active-data-field-r'
                                                : 'bg-hover-secondary'
                                            }`}
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              if (
                                                activeDataField?.dataField !==
                                                regionConfC.dataField
                                              ) {
                                                dispatch(
                                                  setActiveDataField(
                                                    regionConfC,
                                                  ),
                                                )
                                              }
                                            }}
                                          >
                                            <div className="">
                                              <input
                                                className="cursor-pointer mt-2"
                                                type="checkbox"
                                                style={{
                                                  width: '2rem',
                                                  height: '2rem',
                                                }}
                                                checked={
                                                  regionConf.show
                                                } /* phụ thuộc vào cha */
                                                disabled
                                              />
                                            </div>
                                            <div className="flex-fill">
                                              <div className="row mx-0 g-2">
                                                {regionConfC?.typeShow !==
                                                  1 && (
                                                  <div className="col-6">
                                                    <input
                                                      type="text"
                                                      className={`form-control data-field-input ${
                                                        activeDataField?.dataField ==
                                                          regionConfC.dataField &&
                                                        activeDataFieldPropertyName ==
                                                          'label'
                                                          ? 'active'
                                                          : ''
                                                      }`}
                                                      readOnly={
                                                        regionConfC.label
                                                          .editable === false
                                                      }
                                                      value={
                                                        regionConfC.label.value
                                                      }
                                                      placeholder="[Tiêu đề]"
                                                      onChange={(e) =>
                                                        dispatch(
                                                          setSpecifiedDataFieldProperty(
                                                            {
                                                              regionName:
                                                                activeRegion.regionName,
                                                              dataFieldName:
                                                                regionConfC.dataField,
                                                              propertyName:
                                                                'label',
                                                              propertyData: {
                                                                value:
                                                                  e.target
                                                                    .value,
                                                              },
                                                            },
                                                          ),
                                                        )
                                                      }
                                                      onClick={() => {
                                                        dispatch(
                                                          setActiveDataFieldPropertyName(
                                                            'label',
                                                          ),
                                                        )
                                                      }}
                                                    />
                                                  </div>
                                                )}

                                                {regionConfC?.typeShow !==
                                                  1 && (
                                                  <div className="col-6">
                                                    <input
                                                      type="text"
                                                      className={`form-control data-field-input ${
                                                        activeDataField?.dataField ==
                                                          regionConfC.dataField &&
                                                        activeDataFieldPropertyName ==
                                                          'labelEn'
                                                          ? 'active'
                                                          : ''
                                                      }`}
                                                      readOnly={
                                                        regionConfC.labelEn
                                                          .editable === false
                                                      }
                                                      value={
                                                        regionConfC?.labelEn
                                                          .value
                                                      }
                                                      placeholder="[Tiêu đề song ngữ]"
                                                      onChange={(e) =>
                                                        dispatch(
                                                          setSpecifiedDataFieldProperty(
                                                            {
                                                              regionName:
                                                                activeRegion.regionName,
                                                              dataFieldName:
                                                                regionConfC.dataField,
                                                              propertyName:
                                                                'labelEn',
                                                              propertyData: {
                                                                value:
                                                                  e.target
                                                                    .value,
                                                              },
                                                            },
                                                          ),
                                                        )
                                                      }
                                                      onClick={() => {
                                                        dispatch(
                                                          setActiveDataFieldPropertyName(
                                                            'labelEn',
                                                          ),
                                                        )
                                                      }}
                                                    />
                                                  </div>
                                                )}

                                                {regionConfC?.typeShow !==
                                                  2 && (
                                                  <div className="col-12">
                                                    <input
                                                      type="text"
                                                      className={`form-control data-field-input ${
                                                        activeDataField?.dataField ==
                                                          regionConfC.dataField &&
                                                        activeDataFieldPropertyName ==
                                                          'value'
                                                          ? 'active'
                                                          : ''
                                                      }`}
                                                      readOnly={
                                                        regionConfC.value
                                                          .editable === false
                                                      }
                                                      value={
                                                        regionConfC.value.value
                                                      }
                                                      placeholder={
                                                        regionConfC.dataField?.startsWith(
                                                          'custom',
                                                        )
                                                          ? '[Nội dung]'
                                                          : undefined
                                                      }
                                                      onChange={(e) =>
                                                        dispatch(
                                                          setSpecifiedDataFieldProperty(
                                                            {
                                                              regionName:
                                                                activeRegion.regionName,
                                                              dataFieldName:
                                                                regionConfC.dataField,
                                                              propertyName:
                                                                'value',
                                                              propertyData: {
                                                                value:
                                                                  e.target
                                                                    .value,
                                                              },
                                                            },
                                                          ),
                                                        )
                                                      }
                                                      onClick={() => {
                                                        dispatch(
                                                          setActiveDataFieldPropertyName(
                                                            'value',
                                                          ),
                                                        )
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ),
                            )
                          ),
                        )}

                      {/* == signXml */}
                      {activeRegion?.regionName === 'signXml' &&
                        TemplateInvoiceHelper.regionConfigsGroupByMergeFieldFunc(
                          activeRegion?.regionConfigs,
                        ).map(([mergeField, regionConfArr], index) => (
                          <Draggable
                            key={mergeField}
                            id={mergeField}
                            draggableId={mergeField}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  ),
                                  borderBottom: '2px solid #e3e3e3',
                                }}
                              >
                                {regionConfArr?.map(
                                  (regionConf, indexI, arr) =>
                                    !regionConf.show &&
                                    regionConf.previewField ? (
                                      <></>
                                    ) : (
                                      <Accordion.Item
                                        key={indexI}
                                        className="position-relative"
                                        eventKey={regionConf.dataField}
                                        onClick={() => {
                                          if (
                                            activeDataField?.dataField !==
                                            regionConf.dataField
                                          ) {
                                            dispatch(
                                              setActiveDataField(regionConf),
                                            )
                                          }
                                        }}
                                      >
                                        <Accordion.Button
                                          className={`d-flex align-items-center ${
                                            activeDataField?.dataField ===
                                            regionConf.dataField
                                              ? 'active-data-field-r'
                                              : 'bg-hover-secondary'
                                          }`}
                                        >
                                          <span
                                            className="mr-4"
                                            style={{
                                              visibility: 'hidden',
                                            }}
                                          >
                                            <i
                                              className="fas fa-bars text-secondary"
                                              style={{ marginTop: '1px' }}
                                            ></i>
                                          </span>

                                          <input
                                            className="cursor-pointer mr-4"
                                            type="checkbox"
                                            style={{
                                              visibility:
                                                (mergeField != 'undefined' &&
                                                  indexI == 0) ||
                                                mergeField == 'undefined'
                                                  ? 'visible'
                                                  : 'hidden',
                                              width: '16px',
                                              height: '16px',
                                              minWidth: '16px',
                                            }}
                                            checked={regionConf.show}
                                            disabled={
                                              regionConf?.canHide === false
                                            }
                                            onChange={(e) => {
                                              arr.forEach((item) => {
                                                dispatch(
                                                  setSpecifiedDataField({
                                                    regionName:
                                                      activeRegion.regionName,
                                                    dataFieldName:
                                                      item.dataField,
                                                    propertyData: {
                                                      show: e.target.checked,
                                                    },
                                                  }),
                                                )
                                              })
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                          />

                                          <span className="flex-fill text-truncate">
                                            {[
                                              'CONTENT_LEFT',
                                              'CONTENT',
                                            ].includes(regionConf.displayType)
                                              ? `${regionConf.value.value}`
                                              : `${regionConf.label.value} ${regionConf.labelEn.value}: ${regionConf.value.value}`
                                                  ?.trim()
                                                  ?.replace(/^:+|:+$/g, '')}
                                          </span>
                                        </Accordion.Button>

                                        <div
                                          className="position-absolute"
                                          {...provided.dragHandleProps}
                                          style={{
                                            visibility:
                                              indexI === 0
                                                ? 'visible'
                                                : 'hidden',
                                            top: '1rem',
                                            left: '1.25rem',
                                            zIndex: 10,
                                          }}
                                        >
                                          <i
                                            className="fas fa-bars text-secondary"
                                            style={{ marginTop: '1px' }}
                                          ></i>
                                        </div>

                                        <Accordion.Body
                                          className={`${
                                            activeDataField?.dataField ===
                                            regionConf.dataField
                                              ? 'active-data-field-r'
                                              : 'bg-hover-secondary'
                                          }`}
                                        >
                                          <div className="d-flex flex-column">
                                            {regionConf?.typeShow !== 1 && (
                                              <div className="d-flex flex-row align-items-center mb-2">
                                                <p
                                                  className="mb-0"
                                                  style={{
                                                    width: 70,
                                                    fontSize: '0.90em',
                                                  }}
                                                >
                                                  Tiêu đề
                                                </p>
                                                <input
                                                  type="text"
                                                  className={`form-control data-field-input ${
                                                    activeDataField?.dataField ==
                                                      regionConf.dataField &&
                                                    activeDataFieldPropertyName ==
                                                      'label'
                                                      ? 'active'
                                                      : ''
                                                  }`}
                                                  value={regionConf.label.value}
                                                  onChange={(e) =>
                                                    dispatch(
                                                      setSpecifiedDataFieldProperty(
                                                        {
                                                          regionName:
                                                            activeRegion.regionName,
                                                          dataFieldName:
                                                            regionConf.dataField,
                                                          propertyName: 'label',
                                                          propertyData: {
                                                            value:
                                                              e.target.value,
                                                          },
                                                        },
                                                      ),
                                                    )
                                                  }
                                                  onClick={() => {
                                                    dispatch(
                                                      setActiveDataFieldPropertyName(
                                                        'label',
                                                      ),
                                                    )
                                                  }}
                                                  readOnly={
                                                    regionConf.label
                                                      ?.editable === false
                                                  }
                                                />
                                              </div>
                                            )}

                                            {regionConf?.typeShow !== 1 && (
                                              <div className="d-flex flex-row align-items-center mb-2">
                                                <p
                                                  className="mb-0"
                                                  style={{
                                                    width: 70,
                                                    fontSize: '0.90em',
                                                  }}
                                                >
                                                  Song ngữ
                                                </p>
                                                <input
                                                  type="text"
                                                  className={`form-control data-field-input ${
                                                    activeDataField?.dataField ==
                                                      regionConf.dataField &&
                                                    activeDataFieldPropertyName ==
                                                      'labelEn'
                                                      ? 'active'
                                                      : ''
                                                  }`}
                                                  value={
                                                    regionConf?.labelEn.value
                                                  }
                                                  onChange={(e) =>
                                                    dispatch(
                                                      setSpecifiedDataFieldProperty(
                                                        {
                                                          regionName:
                                                            activeRegion.regionName,
                                                          dataFieldName:
                                                            regionConf.dataField,
                                                          propertyName:
                                                            'labelEn',
                                                          propertyData: {
                                                            value:
                                                              e.target.value,
                                                          },
                                                        },
                                                      ),
                                                    )
                                                  }
                                                  onClick={() => {
                                                    dispatch(
                                                      setActiveDataFieldPropertyName(
                                                        'labelEn',
                                                      ),
                                                    )
                                                  }}
                                                  readOnly={
                                                    regionConf?.label
                                                      .editable === false
                                                  }
                                                />
                                              </div>
                                            )}

                                            {regionConf?.typeShow !== 2 && (
                                              <div className="d-flex flex-row align-items-center">
                                                <p
                                                  className="mb-0"
                                                  style={{
                                                    width: 70,
                                                    fontSize: '0.90em',
                                                  }}
                                                >
                                                  Nội dung
                                                </p>
                                                <input
                                                  type="text"
                                                  className={`form-control data-field-input ${
                                                    activeDataField?.dataField ==
                                                      regionConf.dataField &&
                                                    activeDataFieldPropertyName ==
                                                      'value'
                                                      ? 'active'
                                                      : ''
                                                  }`}
                                                  readOnly={
                                                    regionConf.value
                                                      .editable === false
                                                  }
                                                  value={regionConf.value.value}
                                                  onChange={(e) =>
                                                    dispatch(
                                                      setSpecifiedDataFieldProperty(
                                                        {
                                                          regionName:
                                                            activeRegion.regionName,
                                                          dataFieldName:
                                                            regionConf.dataField,
                                                          propertyName: 'value',
                                                          propertyData: {
                                                            value:
                                                              e.target.value,
                                                          },
                                                        },
                                                      ),
                                                    )
                                                  }
                                                  onClick={() => {
                                                    dispatch(
                                                      setActiveDataFieldPropertyName(
                                                        'value',
                                                      ),
                                                    )
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    ),
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Accordion>
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div>
              {['invoiceInfoOther', 'tableFooter'].includes(
                activeRegion?.regionName,
              ) ? (
                <></>
              ) : ['tableDetail', 'tableDetail1'].includes(
                  activeRegion?.regionName,
                ) ? (
                <button
                  type="button"
                  className="btn btn-success text-white mt-1 w-100"
                  onClick={() => {
                    setConfigDynamicFieldAction('CREATE')
                    dispatch(setConfigDynamicFieldPanel(true))
                  }}
                >
                  <i
                    className="fal fa-plus"
                    style={{ color: 'white', marginBottom: '1px' }}
                  ></i>
                  Thêm cột
                </button>
              ) : activeRegion?.regionName === 'signXml' ? (
                // <button type="button" className="btn btn-success text-white mt-1 w-100">
                //   <i className="fal fa-plus" style={{ color: 'white', marginBottom: '1px' }}></i>
                //   Thêm người ký
                // </button>
                <></>
              ) : activeRegion?.regionName === 'sellerInfo' ? (
                <button
                  type="button"
                  className="btn btn-success text-white mt-1 w-100"
                  onClick={() => {
                    dispatch(
                      addNewDataFieldToRegionConfigs({
                        regionName: 'sellerInfo',
                        newDataField: {
                          dataField: `customSellerInfo-${Utils.makeId(3)}`,
                          value: {
                            value: '',
                            editable: true,
                          },
                          label: {
                            value: 'Trường mở rộng',
                          },
                          labelEn: {
                            fontStyle: 'italic',
                            value: '',
                          },
                          show: true,
                          mergeField: '',
                        },
                      }),
                    )
                  }}
                >
                  <i
                    className="fal fa-plus"
                    style={{ color: 'white', marginBottom: '1px' }}
                  ></i>
                  Thêm dòng
                </button>
              ) : activeRegion?.regionName === 'invoiceInfo' ? (
                <button
                  type="button"
                  className="btn btn-success text-white mt-1 w-100"
                  onClick={() => {
                    dispatch(
                      addNewDataFieldToRegionConfigs({
                        regionName: 'invoiceInfo',
                        newDataField: {
                          dataField: `customInvoiceInfo-${Utils.makeId(3)}`,
                          value: {
                            value: '',
                          },
                          label: {
                            value: '',
                          },
                          labelEn: {
                            fontStyle: 'italic',
                            value: '',
                          },
                          typeShow: 1,
                          show: true,
                          mergeField: '',
                        },
                      }),
                    )
                  }}
                >
                  <i
                    className="fal fa-plus"
                    style={{ color: 'white', marginBottom: '1px' }}
                  ></i>
                  Thêm dòng
                </button>
              ) : (
                /* buyerInfo */
                <DropdownButton
                  title={
                    <>
                      <i
                        className="fal fa-plus"
                        style={{ color: 'white', marginBottom: '1px' }}
                      ></i>
                      Thêm dòng
                    </>
                  }
                  drop="up"
                  variant="success"
                  className="text-white dd-add-line"
                  onSelect={(s) => {
                    if (s === 'STATIC_FIELD') {
                      dispatch(
                        addNewDataFieldToRegionConfigs({
                          regionName: 'buyerInfo',
                          newDataField: {
                            dataField: `customBuyerInfo-${Utils.makeId(3)}`,
                            value: {
                              value: '',
                            },
                            label: {
                              value: 'Dòng ghi chú',
                            },
                            labelEn: {
                              fontStyle: 'italic',
                              value: '',
                            },
                            typeShow: 3,
                            show: true,
                            mergeField: '',
                          },
                        }),
                      )
                    } else if (s === 'DYNAMIC_FIELD') {
                      setConfigDynamicFieldAction('CREATE')
                      dispatch(setConfigDynamicFieldPanel(true))
                    }
                  }}
                >
                  <div className="d-flex flex-row">
                    <Dropdown.Item eventKey="STATIC_FIELD">
                      Dòng ghi chú
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="DYNAMIC_FIELD">
                      Trường nhập liệu
                    </Dropdown.Item>
                  </div>
                </DropdownButton>
              )}
            </div>
          </div>
        </>
      )}

      {configDynamicFieldPanel && (
        <ConfigDataFieldPanel
          action={configDataPanelAction}
          configDataFieldId={configDataFieldId}
          setConfigDataFieldId={setConfigDataFieldId}
        />
      )}
    </div>
  )
}

export default DetailControl
