import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { Tooltip } from 'antd'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import { INVOICE_STATISTIC } from 'general/constants/AppConstants'
import Utils from 'general/utils/Utils'
import useEmbla from 'hooks/EmblaCarousel/useEmbla'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import StatisticDroppable from './StatisticDroppable'
import StatisticItem from './StatisticItem'
import {
  Button,
  EmblaButtons,
  EmblaContainer,
  EmblaSlide,
  EmblaWrapper,
  InvoiceStatusBox,
  InvoiceStatusNumber,
  InvoicetStatusText,
} from './styles'

const ScrollableDashboard = ({ statistics }) => {
  const emblaSlideRef = useRef(null)
  const {
    emblaRef,
    canItShowButton,
    functions: { scrollNext, scrollPrev },
  } = useEmbla()
  const [isModalOpen, setModalOpen] = useState(false)
  const [itemGroups, setItemGroups] = useState({
    activedItems: [],
    items: [],
  })
  const [activedItems, setActivedItems] = useState(
    () => itemGroups.activedItems,
  )
  const [activeId, setActiveId] = useState(null)
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 10 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  const modalRef = useRef(null)
  const buttonHeight = emblaSlideRef?.current?.offsetHeight

  // FUNctions
  const handleDragStart = ({ active }) => setActiveId(active.id)

  const handleDragCancel = () => setActiveId(null)

  const handleDragOver = useCallback(
    ({ active, over }) => {
      if (!over || !over.id) return
      const overId = over.id
      const activeContainer = active.data.current.sortable?.containerId
      const overContainer = over.data.current?.sortable.containerId ?? overId
      if (activeContainer === overContainer) return
      const activeIndex = active.data.current.sortable.index
      const overIndex = _.has(itemGroups, overId)
        ? itemGroups[overId].length + 1
        : over.data.current.sortable.index
      setItemGroups((prev) =>
        moveBetweenContainers(
          prev,
          activeContainer,
          activeIndex,
          overContainer,
          overIndex,
          {
            id: active.id,
            value: statistics[active.id],
          },
        ),
      )
    },
    [itemGroups, statistics],
  )

  const handleDragEnd = useCallback(
    ({ active, over }) => {
      setActiveId(null)
      if (!over) return
      if (active.id === over.id) return
      const overId = over.id
      const activeContainer = active.data.current?.sortable?.containerId
      const overContainer = over.data.current?.sortable?.containerId ?? overId
      const activeIndex = active.data.current?.sortable?.index
      const overIndex = _.has(itemGroups, overId)
        ? itemGroups[overId].length + 1
        : over.data.current.sortable.index

      setItemGroups((prev) =>
        _.isEqual(activeContainer, overContainer)
          ? {
              ...prev,
              [overContainer]: arrayMove(
                itemGroups[overContainer],
                activeIndex,
                overIndex,
              ),
            }
          : moveBetweenContainers(
              prev,
              activeContainer,
              activeIndex,
              overContainer,
              overIndex,
              {
                id: active.id,
                value: statistics[active.id],
              },
            ),
      )
    },
    [itemGroups, statistics],
  )

  const handleDeleteTag = useCallback(
    (e, tagId) => {
      e.preventDefault()
      e.stopPropagation()
      setItemGroups((prev) => {
        const removedActivedItems = _.filter(
          prev.activedItems,
          (item) => item.id !== tagId,
        )
        return {
          activedItems: removedActivedItems,
          items: Utils.insertAtIndex(prev.items, prev.items.length, {
            id: tagId,
            value: statistics[tagId],
          }),
        }
      })
    },
    [statistics],
  )

  const moveBetweenContainers = (
    items,
    activeContainer,
    activeIndex,
    overContainer,
    overIndex,
    item,
  ) => {
    return {
      ...items,
      [activeContainer]: Utils.removeAtIndex(
        items[activeContainer],
        activeIndex,
      ),
      [overContainer]: Utils.insertAtIndex(
        items[overContainer],
        overIndex,
        item,
      ),
    }
  }

  const measuring = useCallback((element) => {
    const modalPos = modalRef.current?.getBoundingClientRect()
    const elementPos = element.getBoundingClientRect()
    return {
      width: elementPos.width,
      height: elementPos.height,
      left: elementPos.left - modalPos.left,
      right: elementPos.right - modalPos.right,
      top: elementPos.top - modalPos.top,
      bottom: elementPos.bottom - modalPos.bottom,
    }
  }, [])

  const handleApplyChanges = useCallback(() => {
    setModalOpen(false)
    setActivedItems(itemGroups.activedItems)
    localStorage.setItem(
      'StatisticItems',
      JSON.stringify(itemGroups.activedItems),
    )
  }, [itemGroups.activedItems])

  useEffect(() => {
    const getSavedItems = JSON.parse(localStorage.getItem('StatisticItems'))
    setActivedItems(getSavedItems ?? [])
    setItemGroups((prev) => ({ ...prev, activedItems: getSavedItems ?? [] }))
  }, [])

  useEffect(() => {
    statistics &&
      setItemGroups((prev) => {
        const keys = _.keys(statistics)
        const mappedActivedId = _.map(prev.activedItems, 'id')
        const picked = _.filter(
          keys,
          (key) => !_.includes(mappedActivedId, key),
        )
        const _activedItems = _.map(mappedActivedId, (v) => ({
          id: v,
          value: statistics[v],
        }))
        const _items = _.map(picked, (v) => ({ id: v, value: statistics[v] }))
        setActivedItems(_activedItems)
        localStorage.setItem('StatisticItems', JSON.stringify(_activedItems))
        return {
          activedItems: _activedItems,
          items: _items,
        }
      })
  }, [statistics])

  return (
    <div className="py-2 d-flex w-100 justify-content-between align-items-center column-gap-2">
      {_.isEmpty(activedItems) ? null : (
        <EmblaWrapper ref={emblaRef}>
          <EmblaContainer className="row">
            {activedItems.map((item) => (
              <EmblaSlide className="col-2" key={item.id} ref={emblaSlideRef}>
                <InvoiceStatusBox>
                  <InvoiceStatusNumber>{item.value}</InvoiceStatusNumber>
                  <InvoicetStatusText>
                    {_.upperFirst(INVOICE_STATISTIC[item.id])}
                  </InvoicetStatusText>
                </InvoiceStatusBox>
              </EmblaSlide>
            ))}
          </EmblaContainer>
          {canItShowButton.isPrev ? (
            <EmblaButtons isPrev onClick={scrollPrev}>
              <i className="fa-solid fa-chevron-left"></i>
            </EmblaButtons>
          ) : null}
          {canItShowButton.isNext ? (
            <EmblaButtons isNext onClick={scrollNext}>
              <i className="fa-solid fa-chevron-right"></i>
            </EmblaButtons>
          ) : null}
        </EmblaWrapper>
      )}
      <Tooltip
        // open
        title="Thiết lập chỉ tiêu thống kê"
        placement="bottomLeft"
        color="#9b9b9b"
        prefixCls="edit-dashboard"
        arrow={false}
      >
        <Button
          height={buttonHeight ? `${buttonHeight}px` : '3rem'}
          onClick={() => setModalOpen(true)}
        >
          <i className="fa-regular fa-pen-to-square fa-xl"></i>
          {_.isEmpty(activedItems) ? 'Thiết lập chỉ tiêu thống kê' : null}
        </Button>
      </Tooltip>
      <DraggableModal
        title="Thiết lập chỉ tiêu thống kê"
        description="Thống kê nhanh số lượng hoá đơn theo kỳ đã chọn tại bộ lọc"
        isOpen={isModalOpen}
        onOpenChange={setModalOpen}
        ref={modalRef}
        handleApplyChanges={handleApplyChanges}
      >
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          measuring={{
            draggable: {
              measure: measuring,
            },
          }}
        >
          {Object.keys(itemGroups).map((group) => (
            <StatisticDroppable
              id={group}
              items={itemGroups[group]}
              activeId={activeId}
              strategy={rectSortingStrategy}
              key={group}
              disabled={group === 'items'}
              className={group === 'activedItems' ? 'bg-light' : ''}
              handleDeleteTag={handleDeleteTag}
            />
          ))}
          <DragOverlay dropAnimation={null} zIndex={20}>
            {_.isNull(activeId) ? null : (
              <StatisticItem id={activeId} dragOverlay />
            )}
          </DragOverlay>
        </DndContext>
      </DraggableModal>
    </div>
  )
}

export default ScrollableDashboard
