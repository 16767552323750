import { TICKET_UPLOAD_HEADER_COLUMNS } from 'general/constants/AppConstants'
import { isArray } from 'lodash'

export const filterColumnsBaseOnTicketTemplate = ({ baseColumns, serial }) => {
  if (!isArray(baseColumns) || !serial) return baseColumns

  let columns = [...baseColumns]
  if (serial.routeType != 'MULTIPLE')
    columns = columns.filter(({ key }) => key != 'tuyenduong')
  if (serial.category_ticket.typeDeduct == 'TT')
    columns = columns.filter(({ key }) => key != 'thuesuatgtgt')
  if (serial.category_ticket.typePrice == 1) {
    columns = columns.filter(({ key }) => key != 'tongtien')
    if (serial.category_ticket.typeDeduct == 'KT')
      columns = columns.filter(({ key }) => key != 'thuesuatgtgt')
  }

  return columns
}

export const getRefObjFromHeaderBaseOnMappingKeyword = (header) => {
  return TICKET_UPLOAD_HEADER_COLUMNS.find(({ mappingKeywords }) =>
    mappingKeywords?.split(';')?.includes(header),
  )
}
