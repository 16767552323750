import { useMutation } from '@tanstack/react-query'
import { Checkbox, Flex, TimePicker, Typography } from 'antd'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomLabel from 'general/components/CustomLabel'
import customFilterHelpers from 'general/components/CustomPopover/functions/helpers'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { TYPE_TIME } from 'general/constants/AppConstants'
import AppData from 'general/constants/AppData'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import useGetSerials from 'hooks/Queries/useGetSerials'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import { getDataLog, updateAutoLog } from '../../asyncThunks'
import { useSystemConfigSelector } from '../../SystemConfigurationSlice'
import FooterCard from '../FooterCard'

export default function TabLogSetting() {
  const { autoDataLog } = useSystemConfigSelector()
  const dispatch = useAppDispatch()
  const { data: serials, isLoading: isGettingSerial } = useGetSerials(
    'ALL',
    1,
  ) /* 1 vì hóa đơn từ log xăng chỉ dùng hóa đơn 1 thuế suất */

  const handleSaveChange = useMutation({
    mutationKey: ['systemConfig', 'formikProps.values'],
    mutationFn: (values) => dispatch(updateAutoLog(values)).unwrap(),
    onSettled: async () => {
      ToastHelper.showSuccess('Cập nhật thành công')
      await dispatch(getDataLog())
    },
  })

  return (
    <Formik
      initialValues={autoDataLog}
      enableReinitialize
      onSubmit={(values) => {
        handleSaveChange.mutate(values)
      }}
    >
      {(formikProps) => (
        <Flex justify={'space-between'} vertical className="w-100 h-100">
          <section>
            <Typography.Title level={5}>Cấu hình tự động</Typography.Title>
            <section className="mt-5 pl-10">
              <div className="row container">
                <div className="col-md-12 col-lg-6">
                  {/* Xuất tự động */}
                  <div className="form-group row align-items-center">
                    <div className="col-md-4 max-w-200px">
                      <CustomLabel
                        width={'100%'}
                        text="Xuất tự động"
                        fontWeight={600}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomAntSelect
                        className="max-w-300px"
                        options={[
                          { label: 'Tự động', value: true },
                          { label: 'Thủ công', value: false },
                        ]}
                        value={formikProps.values.isAuto}
                        showSearch={false}
                        onChange={(value) =>
                          formikProps.setFieldValue('isAuto', value)
                        }
                      />
                    </div>
                  </div>
                  {/* Thời điểm xuất */}
                  <div className="form-group row align-items-center">
                    <div className="col-md-4 max-w-200px">
                      <CustomLabel
                        width={'100%'}
                        text="Thời điểm xuất"
                        fontWeight={600}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomAntSelect
                        className="max-w-300px"
                        options={SelectOptions.typeTime}
                        value={formikProps.values.typeTime}
                        showSearch={false}
                        onChange={(value) =>
                          formikProps.setFieldValue('typeTime', value)
                        }
                      />
                    </div>
                  </div>
                  {/* Thời gian xuất */}
                  <div className="form-group row align-items-center">
                    <div className="col-md-4 max-w-200px">
                      <CustomLabel
                        width={'100%'}
                        text={
                          formikProps.values.typeTime === TYPE_TIME.LOG
                            ? 'Thời gian chờ xuất'
                            : formikProps.values.typeTime === TYPE_TIME.MOMENT
                              ? 'Thời gian xuất'
                              : null
                        }
                        fontWeight={600}
                      />
                    </div>
                    <div className="col-md-6">
                      {formikProps.values.typeTime === TYPE_TIME.LOG ? (
                        <CustomAntInput
                          type="number"
                          borderRadius="sm"
                          inputProps={{
                            className: 'max-w-300px',
                            addonAfter: 'phút',
                            value: formikProps.values.timeLog,
                            onChange: (inputValue) => {
                              formikProps.setFieldValue('timeLog', inputValue)
                            },
                            placeholder: 'Min: 1, max: 999',
                            min: 1,
                            max: 999,
                            style: {
                              width: '100%',
                              padding: 0,
                            },
                          }}
                        />
                      ) : formikProps.values.typeTime === TYPE_TIME.MOMENT ? (
                        <TimePicker
                          className="w-100 max-w-300px"
                          changeOnBlur
                          placeholder="Chọn 1 mốc thời gian"
                          format={'HH:mm'}
                          allowClear={false}
                          hourStep={1}
                          minuteStep={5}
                          suffixIcon={<i className="far fa-clock" />}
                          value={
                            dayjs(
                              formikProps.values.timeMoment,
                              'HH:mm',
                            ).isValid()
                              ? dayjs(formikProps.values.timeMoment, 'HH:mm')
                              : dayjs('20:00', 'HH:mm')
                          }
                          onChange={(value) => {
                            formikProps.setFieldValue(
                              'timeMoment',
                              value?.format('HH:mm'),
                            )
                          }}
                        />
                      ) : null}
                    </div>
                  </div>

                  {/* Phát hành tự dộng */}
                  <div className="form-group row align-items-center">
                    <div className="col-md-4 max-w-200px">
                      <CustomLabel
                        width={'100%'}
                        text="Phát hành tự động"
                        fontWeight={600}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                      <Checkbox
                        className="mr-2 mb-1"
                        checked={!!formikProps.values.sendTaxMTT}
                        onChange={(e) =>
                          formikProps.setFieldValue(
                            'sendTaxMTT',
                            e.target.checked,
                          )
                        }
                      />
                      <span className="text-info">
                        <i class="fa-regular fa-circle-info text-info mr-1"></i>
                        Chỉ có tác dụng với hd mtt
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  {/* Hình thức xuất */}
                  <div className="form-group row align-items-center">
                    <div className="col-md-4 max-w-200px">
                      <CustomLabel
                        width={'100%'}
                        text="Hình thức xuất"
                        fontWeight={600}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomAntSelect
                        className="max-w-300px"
                        options={SelectOptions.systemConfigTypeInvoice}
                        value={formikProps.values.typeInvoice}
                        showSearch={false}
                        onChange={(value) =>
                          formikProps.setFieldValue('typeInvoice', value)
                        }
                      />
                    </div>
                  </div>

                  {/* Tên người mua hàng */}
                  <div className="form-group row align-items-center">
                    <div className="col-md-4 max-w-200px">
                      <CustomLabel
                        width={'100%'}
                        text="Tên người mua hàng"
                        fontWeight={600}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomAntInput
                        type="text"
                        borderRadius="sm"
                        inputProps={{
                          className: 'max-w-300px',
                          value: formikProps.values.customerName,
                          onChange: (inputValue) => {
                            formikProps.setFieldValue(
                              'customerName',
                              inputValue.target.value,
                            )
                          },
                          placeholder: 'Tên người mua hàng',
                        }}
                      />
                    </div>
                  </div>

                  {/* Chọn mẫu hóa đơn */}
                  <div className="form-group row align-items-center">
                    <div className="col-md-4 max-w-200px">
                      <CustomLabel
                        width={'100%'}
                        text="Chọn mẫu hóa đơn"
                        fontWeight={600}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomAntSelect
                        className="max-w-300px"
                        customFilterOptionFn={
                          customFilterHelpers.customFilterOptionFn
                        }
                        placeholder="Chọn mẫu hóa đơn"
                        isLoading={isGettingSerial}
                        value={formikProps.values.invoiceTemplateId}
                        options={serials?.map((item) => ({
                          value: item?.invoiceTemplateId,
                          label: item?.label,
                        }))}
                        onChange={(value) =>
                          formikProps.setFieldValue('invoiceTemplateId', value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <FooterCard
            onRestoreDefault={() => {
              _.entries(AppData.default_autoDataLog, ([key, value]) => {
                formikProps.setFieldValue(key, value)
              })
            }}
            onRestoreChange={formikProps.resetForm}
            onSave={formikProps.submitForm}
            isLoading={formikProps.isSubmitting}
            show={formikProps.dirty}
          />
        </Flex>
      )}
    </Formik>
  )
}
