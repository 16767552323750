import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import useCheckPermission from 'hooks/useCheckPermission'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import DataSummaryTable from './screens/DataSummaryTable'
import DetailInvoiceReportScreen from './screens/DetailInvoiceReportScreen'
import ExportToExcelFile from './screens/ExportToExcelFile'
import GeneralReport from './screens/GeneralReport'
import IssueInvoiceScreen from './screens/IssueInvoiceScreen'
import ListTicketExported from './screens/ListTicketExported/ListTicketExported'
import ListTicketPublishedScreen from './screens/ListTicketPublishedScreen/ListTicketPublishedScreen'
import ReportListTicketScreen from './screens/ReportListTicketScreen/ReportListTicketScreen'
import UsedInvoiceStatisticScreen from './screens/UsedInvoiceStatisticScreen'

Report.propTypes = {}

function Report() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const {
    isLoadingPermission,
    isReportInvoiceUsed,
    isReportDetailInvoiceUsed,
    isReportNumberInvoiceRelease,
    isReportInvoiceSynthetic,
    isExportDataReport,
  } = useCheckPermission([
    {
      keyAction: 'isReportInvoiceUsed',
      permissionCode: 'LIST_INVOICE_VIEW_LIST',
    },
    {
      keyAction: 'isReportDetailInvoiceUsed',
      permissionCode: 'LIST_INVOICE_DETAIL_VIEW_LIST',
    },
    {
      keyAction: 'isReportNumberInvoiceRelease',
      permissionCode: 'STATISTIC_NUMBER_INVOICE_USED_VIEW_LIST',
    },
    {
      keyAction: 'isReportInvoiceSynthetic',
      permissionCode: 'VIEW_LIST_INVOICE_SYNTHETIC',
    },
    {
      keyAction: 'isExportDataReport',
      permissionCode: 'VIEW_EXPORT_DATA_REPORT',
    },
  ])

  return (
    <Routes>
      <Route path="" element={<Navigate to="da-su-dung" />} />
      <Route
        path="da-su-dung"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isReportInvoiceUsed}
          >
            <UsedInvoiceStatisticScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="chi-tiet"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isReportDetailInvoiceUsed}
          >
            <DetailInvoiceReportScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="hoa-don-tong-hop"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isReportInvoiceSynthetic}
          >
            <GeneralReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="da-phat-hanh"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isReportNumberInvoiceRelease}
          >
            <IssueInvoiceScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="xuat-khau"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isExportDataReport}
          >
            <ExportToExcelFile />
          </ProtectedRoute>
        }
      />

      <Route
        path="bang-ke"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isReportInvoiceUsed}
          >
            <ReportListTicketScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path="tong-hop-du-lieu-hoa-don-gui-co-quan-thue"
        element={
          // isReportInvoiceUsed ? (
          <DataSummaryTable />
          // ) : (
          //   <UnauthorizedScreen />
          // )
        }
      />

      <Route
        path="ve-da-phat-hanh"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isReportInvoiceUsed}
          >
            <ListTicketPublishedScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="ve-da-xuat"
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={isReportInvoiceUsed}
          >
            <ListTicketExported />
          </ProtectedRoute>
        }
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Report
