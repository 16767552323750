import { queryOptions } from '@tanstack/react-query'
import errAnnouceApi from 'api/errAnnouceApi'
import invoiceApi from 'api/invoiceApi'
import { INVOICE_TYPE_TO_QUERY } from 'general/constants/AppConstants'
import _ from 'lodash'

export const queries = {
  base: {
    scope: ['handleInvoice', 'listErrorAnnouncement'],
    entity: 'listErrorAnnouncement',
  },
  list: (params) => ({
    ...queries.base,
    params,
  }),
  lists: (params) =>
    queryOptions({
      queryKey: [queries.list(params)],
      queryFn: ({ signal }) => {
        switch (params.invoiceType) {
          case 0:
            return errAnnouceApi.list(
              {
                ...params,
                type: params.error_annoucements,
                errAnnouceType: params.issueStatus,
              },
              signal,
            )
          case 1:
            return invoiceApi.listAll(
              {
                ...params,
                serials: [params.serials],
                errAnnouceType: params.invoiceTypeErrAnnouceList,
                invoiceType: INVOICE_TYPE_TO_QUERY.ERR_ANNOUCED,
              },
              signal,
            )
          case 2:
            return invoiceApi.listAll(
              {
                ...params,
                serials: [params.serials],
                errAnnouceType: params.invoiceTypeErrAnnouceList,
                invoiceType: INVOICE_TYPE_TO_QUERY.NEED_ERR_ANNOUCED,
              },
              signal,
            )
        }
      },
      staleTime: 1000 * 30,
      select: (res) => {
        if (res.result !== 'success') throw new Error(res?.statusText)
        const newRows = _.map(res?.rows, (item, idx) => ({
          ...item,
          id: idx + 1,
        }))

        return {
          rows: newRows,
          count: _.get(res, 'count', 0),
        }
      },
    }),
}
