import { Checkbox, DatePicker } from 'antd'
import ModalEditCustomer from 'features/Category/components/ModalEditCustomer'
import CustomButton from 'general/components/Button/CustomButton'
import CustomSelect from 'general/components/CustomSelect'
import Option from 'general/components/CustomSelect/CustomOption'
import MenuList from 'general/components/CustomSelect/MenuList'
import { useRevalidateBuyerByTaxCode } from 'general/components/Dropdown/CustomDropdown/queries'
import BaseTextField from 'general/components/Forms/BaseTextField'
import FormLabel from 'general/components/Forms/FormLabel'
import { ACTION_TYPE, INVOICE_TYPE } from 'general/constants/AppConstants'
import _ from 'lodash'
import { Fragment, useState } from 'react'
import CheckCustomerStatusModal from '../CheckCustomerStatusModal'
import TaxCodeDisplay from '../TaxCodeDisplay'
import vi from 'antd/lib/date-picker/locale/vi_VN'
import TextFieldSelect from 'general/components/Forms/TextFieldSelect'
import dayjs from 'dayjs'

function DeliveryGeneralInfo(props) {
  const {
    formik,
    // currentCompany,
    buyerInfoExtendFields,
    templateInvoiceSerials,
    actionType,
    originalInvoiceInfo,
    // tableData,
    setTableData,
    isTaxReduction,
    onCheckTaxReduction,
    systemTaxRate,
    invoiceType,
  } = props
  const [checkCustomerStatusModalShow, setCheckCustomerStatusModalShow] =
    useState(false)
  const queryCache = useRevalidateBuyerByTaxCode()

  const [showingModalEditCustomer, setShowingModalEditCustomer] =
    useState(false)

  const handleSelectInvoiceSerial = (invoiceTemplate) => {
    const { invoiceTemplateId, serial } = invoiceTemplate
    formik.setFieldValue('invoiceTemplateId', invoiceTemplateId)
    formik.setFieldValue('serial', serial)
    formik.setFieldValue('invoiceTemplateName', invoiceTemplate.name)
    formik.setFieldValue('invoiceType', invoiceTemplate.type)
    const category = invoiceTemplate?.category
    if (category) {
      // chỉ 1 thuế suất
      formik.setFieldValue(
        'multipleTaxRate',
        invoiceTemplate?.category?.taxType === 2,
      )
      formik.setFieldValue('taxType', invoiceTemplate?.category?.taxType)

      if (category?.code == 'BV') {
        formik.setFieldValue('advanceAmount', 0)
        formik.setFieldValue('patientExemptedAmount', 0)
        formik.setFieldValue('hospitalReturnAmount', 0)
        formik.setFieldValue('patientPaidAdditionalAmount', 0)
      } else {
        formik.setFieldValue('advanceAmount', '')
        formik.setFieldValue('patientExemptedAmount', '')
        formik.setFieldValue('hospitalReturnAmount', '')
        formik.setFieldValue('patientPaidAdditionalAmount', '')
      }
    }
    formik.setFieldValue('isTaxReduction', false)
    const taxRate = serial.charAt(0) == '2' ? 0 : systemTaxRate
    // if (serial.charAt(0) == '2') {
    //   formik.setFieldValue('taxRate', 0)
    // }
    formik.setFieldValue('taxRate', taxRate)
    formik.setFieldValue('discount', 0)
    setTableData([
      {
        no: 1,
        type: 1,
        name: '',
        specification: '',
        inventoryItemCode: '',
        actualExport: 0,
        actualImport: 0,
        unit: '',
        unitPrice: 0,
        quantity: 0,
        discount: 0,
        totalUnitPrice: 0,
        unitPriceAfterTax: 0,
        totalUnitPriceAfterTax: 0,
        taxRate: systemTaxRate ?? '0',
        taxReduction: 0,
        taxReductionExchange: 0,
        taxReductionRate: 0,
        taxMoney: 0,
        discountMoney: 0,
        productId: 0,
        exchangeRate: 1,
        exchangedTotalUnitPriceAfterTax: 0,
        exchangedTotalUnitPrice: 0,
        exchangedTaxMoney: 0,
        exchangedDiscountMoney: 0,
      },
    ])
    formik.setFieldValue('invoiceType', invoiceTemplate.type)
    // setInvoiceType(invoiceTemplate.type)
  }

  const renderDescription = () => {
    if (
      actionType === ACTION_TYPE.ADJUST ||
      (actionType === ACTION_TYPE.UPDATE &&
        formik.values.type === INVOICE_TYPE.ADJUST)
    ) {
      return (
        <>
          <span className="my-2">
            Điều chỉnh cho hóa đơn ký hiệu{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.serial}
            </span>
            , số{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.no}
            </span>
            , ngày{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getDate()}
            </span>{' '}
            tháng{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getMonth() + 1}
            </span>{' '}
            năm{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getFullYear()}
            </span>
            {/* <span className="font-weight-bolder">
              {originalInvoiceInfo?.date?.slice(0, 10)}
            </span> */}
          </span>
          <div className="d-flex flex-row align-items-center w-100">
            <span style={{ color: 'red', width: '140px' }}>Lý do *</span>
            <div className="w-100">
              <BaseTextField
                className="flex-grow-1"
                name="description"
                fieldProps={formik.getFieldProps('description')}
                fieldMeta={formik.getFieldMeta('description')}
                fieldHelper={formik.getFieldHelpers('description')}
              />
            </div>
          </div>
        </>
      )
    }
    if (
      actionType === ACTION_TYPE.REPLACE ||
      (actionType === ACTION_TYPE.UPDATE &&
        formik.values.type === INVOICE_TYPE.REPLACE)
    ) {
      return (
        <>
          <span className="my-2">
            Thay thế cho hóa đơn ký hiệu{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.serial}
            </span>
            , số{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.no}
            </span>
            , ngày{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getDate()}
            </span>{' '}
            tháng{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getMonth() + 1}
            </span>{' '}
            năm{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getFullYear()}
            </span>
          </span>
          <div className="d-flex flex-row align-items-center w-100">
            <span style={{ color: 'red', width: '140px' }}>Lý do *</span>
            <div className="w-100">
              <BaseTextField
                className="flex-grow-1"
                name="description"
                fieldProps={formik.getFieldProps('description')}
                fieldMeta={formik.getFieldMeta('description')}
                fieldHelper={formik.getFieldHelpers('description')}
              />
            </div>
          </div>
        </>
      )
    }
    if (actionType === ACTION_TYPE.UPDATE) {
      return <></>
    }
  }

  const handleSelectCustomerInternalWarehouse = (customer) => {
    formik.setFieldValue('consigneeUnitTaxcode', customer?.taxCode ?? '')
    formik.setFieldValue('consigneeUnitName', customer?.name ?? '')
  }
  const handleSelectCustomerWarehouseForSale = (customer) => {
    formik.setFieldValue('buyerTaxCode', customer?.taxCode ?? '')
    formik.setFieldValue('invoiceNote', customer?.name ?? '')
  }

  return (
    <>
      <div className="d-flex flex-column" style={{ gap: '10px' }}>
        <div className="d-flex flex-row justify-content-between">
          {invoiceType === 'N' ? (
            <InternalWarehouseComponent
              formik={formik}
              setCheckCustomerStatusModalShow={setCheckCustomerStatusModalShow}
              handleSelectCustomer={handleSelectCustomerInternalWarehouse}
              setShowingModalEditCustomer={setShowingModalEditCustomer}
            />
          ) : (
            <WarehouseForSaleComponent
              formik={formik}
              setCheckCustomerStatusModalShow={setCheckCustomerStatusModalShow}
              handleSelectCustomer={handleSelectCustomerWarehouseForSale}
              setShowingModalEditCustomer={setShowingModalEditCustomer}
            />
          )}

          <div
            className="d-flex flex-row"
            style={{ flexWrap: 'wrap', gap: '10px 10px' }}
          ></div>

          <div
            className="d-flex flex-row"
            style={{ flexWrap: 'wrap', gap: '10px 10px' }}
          >
            {buyerInfoExtendFields
              ?.filter(({ forceShow, show }) => forceShow || show)
              .map((item, index, { length }) => (
                <Fragment key={index}>
                  <div className="d-flex flex-row align-items-center flex-grow-1 ">
                    <FormLabel width={200}>{item?.label}</FormLabel>
                    <div className="w-100">
                      <BaseTextField
                        className="flex-grow-1"
                        name={`extendFields.${item?.name}`}
                        fieldProps={formik.getFieldProps(
                          `extendFields.${item?.name}`,
                        )}
                        fieldMeta={formik.getFieldMeta(
                          `extendFields.${item?.name}`,
                        )}
                        fieldHelper={formik.getFieldHelpers(
                          `extendFields.${item?.name}`,
                        )}
                      />
                    </div>
                  </div>
                  {length % 2 != 0 && (
                    <div className="d-flex flex-row align-items-center flex-grow-1 opacity-0 visibility-hidden">
                      <FormLabel width={200}></FormLabel>
                      <div className="w-100">
                        <BaseTextField className="flex-grow-1" />
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
          </div>

          <div className="create-invoice-right-col">
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel width="60px">Ký hiệu:</FormLabel>
              <CustomSelect
                classNamePrefix="rs-kyhieu"
                placeholder={'Chọn ký hiệu'}
                currentValue={formik.values.invoiceTemplateId}
                width="300px"
                selectOptions={templateInvoiceSerials}
                customFilterFn={(option, searchText) => {
                  return (
                    option.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    option.data.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                }}
                onChange={(selectedOption) =>
                  handleSelectInvoiceSerial(selectedOption)
                }
                components={{
                  MenuList,
                  Option,
                }}
                // menuIsOpen
              />
            </div>
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel width="60px">Số:</FormLabel>
              <div className="w-100">
                <BaseTextField
                  disabled={true}
                  className="flex-grow-1"
                ></BaseTextField>
              </div>
            </div>
            {parseInt(formik.values?.serial?.charAt(0)) === 2 && (
              <div className="d-flex flex-row mt-2">
                <Checkbox
                  checked={isTaxReduction}
                  onChange={onCheckTaxReduction}
                >
                  Áp dụng giảm thuế GTGT theo nghị quyết theo nghị quyết số
                  101/2023/QH15
                </Checkbox>
              </div>
            )}
          </div>
        </div>
        {renderDescription()}
      </div>
      <CheckCustomerStatusModal
        show={checkCustomerStatusModalShow}
        onHide={() => setCheckCustomerStatusModalShow(false)}
        taxCode={
          formik.values?.[
            invoiceType === 'N' ? 'consigneeUnitTaxcode' : 'buyerTaxCode'
          ]
        }
        companyName={''}
        address={''}
        dateOfOperation={''}
        status={''}
      />
      <ModalEditCustomer
        show={showingModalEditCustomer}
        onClose={() => {
          setShowingModalEditCustomer(false)
        }}
        active="ADD"
        onRefreshCustomerList={() => {
          queryCache.invalidate()
        }}
      />
    </>
  )
}

const InternalWarehouseComponent = (props) => {
  const {
    formik,
    setCheckCustomerStatusModalShow,
    setShowingModalEditCustomer,
    handleSelectCustomer,
  } = props
  return (
    <div className="create-invoice-left-col">
      <div className="d-flex flex-row align-items-center w-100">
        <FormLabel width={200} maxWidth={250}>
          Đơn vị xuất hàng:
        </FormLabel>
        <span className="">{formik.values?.sellerName}</span>
      </div>
      <div className="d-flex flex-row align-items-center w-100">
        <FormLabel width={200}>Mã số thuế đơn vị xuất hàng:</FormLabel>
        <TaxCodeDisplay taxCode={formik.values?.sellerTaxCode} />
      </div>

      <div className="d-flex flex-row align-items-center w-100">
        <div className="d-flex flex-row" style={{ width: '50%' }}>
          <FormLabel width={200} className="flex-1">
            Lệnh điều động số <span style={{ color: 'red' }}>*</span>:
          </FormLabel>
          <div className="flex-1 w-100 pr-3">
            <BaseTextField
              className="flex-grow-1 w-100"
              fieldHelper={formik.getFieldHelpers('buyerName')}
              fieldMeta={formik.getFieldMeta('buyerName')}
              fieldProps={formik.getFieldProps('buyerName')}
            />
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-center"
          style={{ width: '50%' }}
        >
          <FormLabel width={100}>
            Ngày<span style={{ color: 'red' }}>*</span>:
          </FormLabel>
          <div className="flex-1 w-100">
            <DatePicker
              locale={vi}
              format="L"
              allowClear={false}
              className="w-100"
              value={
                formik.values.deliveryOrderDate
                  ? dayjs(
                      formik.values.deliveryOrderDate,
                      'YYYY-MM-DDTHH:mm:ss',
                    )
                  : dayjs()
              }
              onChange={(value) => {
                formik.setFieldValue('deliveryOrderDate', value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center w-100">
        <FormLabel width={200}>Của:</FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1 w-100"
            name="deliveryOrderBy"
            fieldProps={formik.getFieldProps('deliveryOrderBy')}
            fieldMeta={formik.getFieldMeta('deliveryOrderBy')}
            fieldHelper={formik.getFieldHelpers('deliveryOrderBy')}
          />
        </div>
      </div>

      <div
        className="d-flex flex-row"
        style={{ flexWrap: 'wrap', gap: '10px 10px' }}
      >
        <div className="d-flex flex-row align-items-center flex-grow-1">
          <FormLabel width={200}>Về việc: </FormLabel>
          <div className="w-100">
            <BaseTextField
              className="flex-grow-1"
              name="invoiceNote"
              fieldProps={formik.getFieldProps('invoiceNote')}
              fieldMeta={formik.getFieldMeta('invoiceNote')}
              fieldHelper={formik.getFieldHelpers('invoiceNote')}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-row">
        <div className="d-flex flex-row align-items-center flex-grow-1">
          <FormLabel width={200}>
            Địa chỉ kho xuất hàng<span style={{ color: 'red' }}>*</span>:
          </FormLabel>
          <div className="w-100">
            <BaseTextField
              className="flex-grow-1"
              name="addressWarehouse"
              fieldProps={formik.getFieldProps('addressWarehouse')}
              fieldMeta={formik.getFieldMeta('addressWarehouse')}
              fieldHelper={formik.getFieldHelpers('addressWarehouse')}
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>Tên người xuất hàng: </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="exporterName"
            fieldProps={formik.getFieldProps('exporterName')}
            fieldMeta={formik.getFieldMeta('exporterName')}
            fieldHelper={formik.getFieldHelpers('exporterName')}
          />
        </div>
      </div>

      <div className="d-flex flex-row align-items-center w-100">
        <div
          className="d-flex flex-row align-items-center"
          style={{ width: '50%' }}
        >
          <FormLabel width={200}>Tên người vận chuyển:</FormLabel>
          <div className="w-100 pr-3">
            <BaseTextField
              className="flex-grow-1"
              name="deliveryBy"
              fieldProps={formik.getFieldProps('deliveryBy')}
              fieldMeta={formik.getFieldMeta('deliveryBy')}
              fieldHelper={formik.getFieldHelpers('deliveryBy')}
            />
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-center"
          style={{ width: '50%' }}
        >
          <FormLabel width={200}>Hợp đồng vận chuyển: </FormLabel>
          <div className="w-100">
            <BaseTextField
              className="flex-grow-1"
              name="containerNumber"
              fieldProps={formik.getFieldProps('containerNumber')}
              fieldMeta={formik.getFieldMeta('containerNumber')}
              fieldHelper={formik.getFieldHelpers('containerNumber')}
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>
          Phương tiện vận chuyển<span style={{ color: 'red' }}>*</span>:{' '}
        </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="transportationMethod"
            fieldProps={formik.getFieldProps('transportationMethod')}
            fieldMeta={formik.getFieldMeta('transportationMethod')}
            fieldHelper={formik.getFieldHelpers('transportationMethod')}
          />
        </div>
      </div>

      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>Đơn vị nhận hàng: </FormLabel>
        <div className="w-100">
          <TextFieldSelect
            className="flex-grow-1 w-100"
            fieldHelper={formik.getFieldHelpers('consigneeUnitName')}
            fieldMeta={formik.getFieldMeta('consigneeUnitName')}
            fieldProps={formik.getFieldProps('consigneeUnitName')}
            onCreateNew={() => {
              setShowingModalEditCustomer(true)
            }}
            onSelect={handleSelectCustomer}
          />
        </div>
      </div>

      <div className="d-flex flex-row align-items-center flex-grow-1">
        <FormLabel width={200}>Mã số thuế đơn vị nhận hàng: </FormLabel>
        <div className="w-100">
          <TextFieldSelect
            className="flex-grow-1 w-100"
            fieldHelper={formik.getFieldHelpers('consigneeUnitTaxcode')}
            fieldMeta={formik.getFieldMeta('consigneeUnitTaxcode')}
            fieldProps={formik.getFieldProps('consigneeUnitTaxcode')}
            onCreateNew={() => {
              setShowingModalEditCustomer(true)
            }}
            onSelect={handleSelectCustomer}
          />
        </div>
        <CustomButton
          marginLeft={8}
          color="#304FFD"
          text="KT tình trạng hoạt động của KH"
          backgroundColor="white"
          width="250px"
          onClick={() => {
            setCheckCustomerStatusModalShow(true)
          }}
        />
      </div>

      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>
          Địa điểm nhận hàng<span style={{ color: 'red' }}>*</span>:
        </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="consigneeUnitAddress"
            fieldProps={formik.getFieldProps('consigneeUnitAddress')}
            fieldMeta={formik.getFieldMeta('consigneeUnitAddress')}
            fieldHelper={formik.getFieldHelpers('consigneeUnitAddress')}
          />
        </div>
      </div>

      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>Tên người nhận hàng: </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="importerName"
            fieldProps={formik.getFieldProps('importerName')}
            fieldMeta={formik.getFieldMeta('importerName')}
            fieldHelper={formik.getFieldHelpers('importerName')}
          />
        </div>
      </div>
    </div>
  )
}
const WarehouseForSaleComponent = (props) => {
  const {
    formik,
    setCheckCustomerStatusModalShow,
    handleSelectCustomer,
    setShowingModalEditCustomer,
  } = props
  return (
    <div className="create-invoice-left-col">
      <div className="d-flex flex-row align-items-center w-100">
        <FormLabel width={200}>Đơn vị bán hàng:</FormLabel>
        <span className="">{formik.values?.sellerName}</span>
      </div>
      <div className="d-flex flex-row align-items-center w-100">
        <FormLabel width={200}>Mã số thuế:</FormLabel>
        <TaxCodeDisplay taxCode={formik.values?.sellerTaxCode} />
      </div>

      <div className="d-flex flex-row">
        <div className="d-flex flex-row align-items-center">
          <FormLabel width={200}>Địa chỉ:</FormLabel>
          <span className="">{formik.values.sellerFullAddress}</span>
        </div>
      </div>

      <div className="d-flex flex-row align-items-center w-100">
        <div
          className="d-flex flex-row align-items-center"
          style={{ width: '50%' }}
        >
          <FormLabel width={200}>
            Căn cứ hợp đồng kinh tế số<span style={{ color: 'red' }}>*</span>:
          </FormLabel>
          <div className="flex-1 w-100 pr-3">
            <BaseTextField
              className="flex-grow-1 w-100"
              fieldHelper={formik.getFieldHelpers('buyerName')}
              fieldMeta={formik.getFieldMeta('buyerName')}
              fieldProps={formik.getFieldProps('buyerName')}
            />
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-center"
          style={{ width: '50%' }}
        >
          <FormLabel width={100}>
            Ngày<span style={{ color: 'red' }}>*</span>:
          </FormLabel>
          <div className="flex-1 w-100">
            <DatePicker
              locale={vi}
              format="L"
              allowClear={false}
              className="w-100"
              value={
                formik.values.deliveryOrderDate
                  ? dayjs(
                      formik.values.deliveryOrderDate,
                      'YYYY-MM-DDTHH:mm:ss',
                    )
                  : dayjs()
              }
              onChange={(value) => {
                formik.setFieldValue('deliveryOrderDate', value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center w-100">
        <FormLabel width={200}>Của:</FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1 w-100"
            name="deliveryOrderBy"
            fieldProps={formik.getFieldProps('deliveryOrderBy')}
            fieldMeta={formik.getFieldMeta('deliveryOrderBy')}
            fieldHelper={formik.getFieldHelpers('deliveryOrderBy')}
          />
        </div>
      </div>

      <div
        className="d-flex flex-row"
        style={{ flexWrap: 'wrap', gap: '10px 10px' }}
      >
        <div className="d-flex flex-row align-items-center flex-grow-1">
          <FormLabel width={200}>Với (tổ chức, cá nhân): </FormLabel>
          <div className="w-100">
            <TextFieldSelect
              className="flex-grow-1 w-100"
              fieldHelper={formik.getFieldHelpers('invoiceNote')}
              fieldMeta={formik.getFieldMeta('invoiceNote')}
              fieldProps={formik.getFieldProps('invoiceNote')}
              onCreateNew={() => {
                setShowingModalEditCustomer(true)
              }}
              onSelect={handleSelectCustomer}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center flex-grow-1">
        <FormLabel width={200}>Mã số thuế đại lý: </FormLabel>
        <div className="w-100">
          <TextFieldSelect
            className="flex-grow-1 w-100"
            fieldHelper={formik.getFieldHelpers('buyerTaxCode')}
            fieldMeta={formik.getFieldMeta('buyerTaxCode')}
            fieldProps={formik.getFieldProps('buyerTaxCode')}
            onCreateNew={() => {
              setShowingModalEditCustomer(true)
            }}
            onSelect={handleSelectCustomer}
          />
        </div>
        <CustomButton
          marginLeft={8}
          color="#304FFD"
          text="KT tình trạng hoạt động của KH"
          backgroundColor="white"
          width="250px"
          onClick={() => {
            setCheckCustomerStatusModalShow(true)
          }}
        />
      </div>

      <div className="d-flex flex-row align-items-center w-100">
        <div
          className="d-flex flex-row align-items-center"
          style={{ width: '50%' }}
        >
          <FormLabel width={200}>
            Tên người vận chuyển<span style={{ color: 'red' }}>*</span>:
          </FormLabel>
          <div className="w-100 pr-3">
            <BaseTextField
              className="flex-grow-1"
              name="deliveryBy"
              fieldProps={formik.getFieldProps('deliveryBy')}
              fieldMeta={formik.getFieldMeta('deliveryBy')}
              fieldHelper={formik.getFieldHelpers('deliveryBy')}
            />
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-center"
          style={{ width: '50%' }}
        >
          <FormLabel width={200}>Hợp đồng vận chuyển: </FormLabel>
          <div className="w-100">
            <BaseTextField
              className="flex-grow-1"
              name="containerNumber"
              fieldProps={formik.getFieldProps('containerNumber')}
              fieldMeta={formik.getFieldMeta('containerNumber')}
              fieldHelper={formik.getFieldHelpers('containerNumber')}
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>
          Phương tiện vận chuyển<span style={{ color: 'red' }}>*</span>:
        </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="transportationMethod"
            fieldProps={formik.getFieldProps('transportationMethod')}
            fieldMeta={formik.getFieldMeta('transportationMethod')}
            fieldHelper={formik.getFieldHelpers('transportationMethod')}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>Tên người xuất hàng: </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="stockOutFullName"
            fieldProps={formik.getFieldProps('stockOutFullName')}
            fieldMeta={formik.getFieldMeta('stockOutFullName')}
            fieldHelper={formik.getFieldHelpers('stockOutFullName')}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>Tên người nhận hàng: </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="stockInFullName"
            fieldProps={formik.getFieldProps('stockInFullName')}
            fieldMeta={formik.getFieldMeta('stockInFullName')}
            fieldHelper={formik.getFieldHelpers('stockInFullName')}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>
          Xuất tại kho<span style={{ color: 'red' }}>*</span>:{' '}
        </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="fromWarehouseName"
            fieldProps={formik.getFieldProps('fromWarehouseName')}
            fieldMeta={formik.getFieldMeta('fromWarehouseName')}
            fieldHelper={formik.getFieldHelpers('fromWarehouseName')}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row align-items-center"
        // style={{ width: '49.45%' }}
      >
        <FormLabel width={200}>
          Nhập tại kho<span style={{ color: 'red' }}>*</span>:{' '}
        </FormLabel>
        <div className="w-100">
          <BaseTextField
            className="flex-grow-1"
            name="toWarehouseName"
            fieldProps={formik.getFieldProps('toWarehouseName')}
            fieldMeta={formik.getFieldMeta('toWarehouseName')}
            fieldHelper={formik.getFieldHelpers('toWarehouseName')}
          />
        </div>
      </div>
    </div>
  )
}

export default DeliveryGeneralInfo
