import { useMutation } from '@tanstack/react-query'
import { Flex, Typography } from 'antd'
import { Formik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomLabel from 'general/components/CustomLabel'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import {
  getAllSystemFormatNumber,
  updateSystemFormatNumber,
} from '../../asyncThunks'
import { numberFormat } from '../../constants'
import { useSystemConfigSelector } from '../../SystemConfigurationSlice'
import FooterCard from '../FooterCard'

export default function TabNumberSetting() {
  const { formatNumber } = useSystemConfigSelector()
  const dispatch = useAppDispatch()

  const handleSaveChange = useMutation({
    mutationKey: ['systemConfig', 'number'],
    mutationFn: (values) =>
      dispatch(
        updateSystemFormatNumber({
          newSystemFormatNumber: values,
        }),
      ).unwrap(),
    onSettled: async () => {
      ToastHelper.showSuccess('Cập nhật thành công')
      await dispatch(getAllSystemFormatNumber())
    },
  })

  return (
    <Formik
      initialValues={formatNumber}
      enableReinitialize
      onSubmit={(values) => {
        handleSaveChange.mutate(values)
      }}
    >
      {(formikProps) => (
        <Flex justify={'space-between'} vertical className="w-100 h-100">
          <section className="">
            <Typography.Title level={5} className="">
              Định dạng số
            </Typography.Title>
            <div className="ml-10">
              <CustomLabel
                width={'100%'}
                fontWeight={700}
                text="Số chữ số của phần thập phân"
              />
              <div className="row mt-3">
                {/* Phần đầu tiên (cột 1) */}
                <div className="col-lg-6">
                  {numberFormat.map((item, index) => {
                    if (index < 5 && index >= 0) {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-5"
                        >
                          <div
                            style={{ width: '100px' }}
                            className="d-flex flex-column"
                          >
                            <span>{item?.label}:</span>
                            {item.state == 'currencyExchange' ? (
                              <span
                                className="fw-light"
                                style={{ fontSize: '0.9em' }}
                              >
                                (Tiền vat, tiền ck)
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="ml-5" style={{ width: '70px' }}>
                            <CustomAntSelect
                              options={item?.options}
                              value={formikProps.values[item?.state]}
                              onChange={(newValue) => {
                                formikProps.setFieldValue(item?.state, newValue)
                                // changeSystemFormatNumber(item?.state, newValue)
                              }}
                            />
                          </div>
                          <Typography.Text className="ml-5 fw-light">
                            Ví dụ:
                            {Utils.formatDecimal(
                              123456789,
                              formikProps.values[item?.state],
                            )}
                          </Typography.Text>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>

                {/* Phần thứ hai (cột 2) */}
                <div className="col-lg-6">
                  {numberFormat.map((item, index) => {
                    if (index >= 5) {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-5"
                        >
                          <div style={{ width: '120px' }}>{item?.label}:</div>
                          <div className="ml-5" style={{ width: '70px' }}>
                            <CustomAntSelect
                              options={item?.options}
                              value={formikProps.values[item?.state]}
                              onChange={(newValue) => {
                                formikProps.setFieldValue(item?.state, newValue)
                                // changeSystemFormatNumber(item?.state, newValue)
                              }}
                            />
                          </div>
                          <Typography.Text className="ml-5 fw-light">
                            Ví dụ:
                            {Utils.formatDecimal(
                              123456789,
                              formikProps.values[item?.state],
                            )}
                          </Typography.Text>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            </div>
          </section>
          <FooterCard
            onRestoreDefault={() => {
              _.entries(AppData.default_formatNumber).forEach(
                ([key, value]) => {
                  formikProps.setFieldValue(key, value)
                },
              )
            }}
            onRestoreChange={formikProps.resetForm}
            onSave={formikProps.submitForm}
            isLoading={formikProps.isSubmitting}
            show={formikProps.dirty}
          />
        </Flex>
      )}
    </Formik>
  )
}
