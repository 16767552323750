import { useMutation, useQuery } from '@tanstack/react-query'
import { Space, Tooltip } from 'antd'
import registrationApi from 'api/registrationApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import { queries } from 'features/Registration/queries'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomTag from 'general/components/CustomTag'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import { REGISTRATION_STATUS } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import RegistrationHelper from 'general/helpers/RegistrationHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import useAppDnD from 'hooks/useAppDnD'
import useCheckPermission from 'hooks/useCheckPermission'
import useFilter from 'hooks/useFilter'
import { useAppDispatch } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

ListRegistration.propTypes = {}

function ListRegistration() {
  // MARK --- Params: ---
  const { navigate } = useRouter()
  const { t } = useTranslation()
  const appDispatch = useAppDispatch()
  const { canCreateRegistration, canEditRegistration, canDeleteRegistration } =
    useCheckPermission([
      {
        keyAction: 'canCreateRegistration',
        permissionCode: 'TOKHAI_ADD',
      },
      {
        keyAction: 'canEditRegistration',
        permissionCode: 'TOKHAI_EDIT',
      },
      {
        keyAction: 'canDeleteRegistration',
        permissionCode: 'TOKHAI_DELETE',
      },
    ])
  const [filters, setFilters] = useFilter(
    'registrationList',
    'gFiltersRegistrationList',
  )

  const [showNoRight, setShowNoRight] = useState(false)
  const [showModalDataTransmissionLog, setShowModalDataTransmissionLog] =
    useState(false)
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [activeRegistration, setActiveRegistration] = useState(null)

  const showTransmissionLog = () => setShowModalDataTransmissionLog(true)

  async function actionCreateRegistration() {
    if (canCreateRegistration) navigate('tao')
    if (!canCreateRegistration) setShowNoRight(true)
  }

  const {
    data: registrationData,
    isFetching: gettingRegistrationData,
    refetch: refetchRegistrationData,
  } = useQuery(queries.listRegistration(filters))

  const { mutate: deleteRegistration } = useMutation({
    mutationKey: ['deleteRegistration'],
    mutationFn: (registrationId) =>
      registrationApi.deleteRegistration(registrationId),
    onMutate: () => appDispatch(setAppSpinning(true)),
    onSettled: () => appDispatch(setAppSpinning(false)),
    onSuccess: () => {
      ToastHelper.showSuccess('Xóa tờ khai thành công')
      refetchRegistrationData()
    },
  })

  const { mutate: viewPdfRegistration } = useMutation({
    mutationKey: ['exportRegistration'],
    mutationFn: (registrationId) =>
      registrationApi.exportRegistrationById(registrationId),
    onMutate: () => appDispatch(setAppSpinning(true)),
    onSettled: () => appDispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      const blobData = new File(
        [res],
        'Tờ khai đăng ký/thay đổi thông tin sử dụng hóa đơn.pdf',
        { type: 'application/pdf' },
      )
      window.open(URL.createObjectURL(blobData), '_blank')
    },
  })

  const { mutate: syncRegistration } = useMutation({
    mutationKey: ['syncRegistration'],
    mutationFn: (registrationId) =>
      registrationApi.syncRegistration(registrationId),
    onMutate: () => appDispatch(setAppSpinning(true)),
    onSettled: () => appDispatch(setAppSpinning(false)),
    onSuccess: () => {
      ToastHelper.showSuccess('Đồng bộ tờ khai thành công')
      refetchRegistrationData()
    },
  })

  const fullColumns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<any>[]} */
    const arr = [
      {
        name: 'Ngày lập',
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = dayjs(a.date)
          const dateB = dayjs(b.date)
          if (dateA.isBefore(dateB)) {
            return -1
          } else if (dateA.isAfter(dateB)) {
            return 1
          }
          return 0
        },
        left: true,
        grow: 0,
        minWidth: '120px',
        format: (row) => (
          <b className="m-0 font-weight-bold">{dayjs(row?.date).format('L')}</b>
        ),
        selector: (row) => row?.date,
      },
      {
        name: 'Số',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.no || !b.no) return 0
          return a.no.localeCompare(b.no)
        },
        grow: 0,
        minWidth: '140px',
        format: (row) => <Tooltip title={row?.no}>{row?.no}</Tooltip>,
        selector: (row) => row?.no,
      },

      {
        name: 'Hình thức tờ khai',
        sortable: true,
        sortFunction: (a, b) => {
          if (a.type - b.type > 0) {
            return 1
          } else if (a.type - b.type < 0) {
            return -1
          }
          return 0
        },
        grow: 0,
        minWidth: '150px',
        format: (row) => (
          <Tooltip title={RegistrationHelper.renderRegistrationType(row?.type)}>
            {RegistrationHelper.renderRegistrationType(row?.type)}
          </Tooltip>
        ),
        selector: (row) => row?.type,
      },
      {
        name: 'Hình thức hóa đơn',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a || !b) return 0
          return RegistrationHelper.renderInvoiceFormsOfRegistration(
            a,
          ).localeCompare(
            RegistrationHelper.renderInvoiceFormsOfRegistration(b),
          )
        },
        minWidth: '150px',
        cell: (row) => RegistrationHelper.renderInvoiceFormsOfRegistration(row),
      },
      {
        name: 'Thông báo tiếp nhận',
        minWidth: '130px',
        sortable: true,
        sortFunction: (a, b) => {
          let aT = a?.tax_authority_messages?.find(
            ({ typeCode }) => typeCode == 102,
          )?.messageId
          let bT = b?.tax_authority_messages?.find(
            ({ typeCode }) => typeCode == 102,
          )?.messageId
          if (!aT || !bT) return 0
          return aT.localeCompare(bT)
        },
        cell: (row) => {
          return (
            <p className="m-0">
              {
                row?.tax_authority_messages?.find(
                  ({ typeCode }) => typeCode == 102,
                )?.messageId
              }
            </p>
          )
        },
      },
      {
        name: 'Thông báo chấp nhận',
        sortable: true,
        grow: 0,
        minWidth: '150px',
        cell: (row) => {
          return (
            <p className="m-0">
              {
                row?.tax_authority_messages?.find(
                  ({ typeCode }) => typeCode == 103,
                )?.messageId
              }
            </p>
          )
        },
      },
      {
        name: 'Trạng thái',
        sortable: true,
        sortFunction: (a, b) => {
          let aT = RegistrationHelper.renderRegistrationStatus(a?.status)
          let bT = RegistrationHelper.renderRegistrationStatus(b?.status)
          if (!aT || !bT) return 0
          return aT.localeCompare(bT)
        },
        // width: 'auto',
        minWidth: '200px',
        grow: 0,
        cell: (row) => {
          return (
            <CustomTag
              tagProps={{
                className: `badge badge-light-${
                  row?.status === 'DRAFT'
                    ? 'secondary'
                    : row?.status === 'SENT_TAX'
                      ? 'success'
                      : row?.status === 'TAX_NOT_RECEIVE' ||
                          row?.status === 'TAX_NOT_ACCEPT'
                        ? 'danger'
                        : 'primary'
                }`,
                // 'data-tag': 'allowRowEvents',
              }}
              text={RegistrationHelper.renderRegistrationStatus(row?.status)}
            />
          )
        },
      },
      {
        name: 'Hành động',
        center: true,
        disabled: true,
        grow: 0,
        minWidth: '150px',
        cell: (row) => {
          return (
            <Space align="center">
              <TableAction
                titleActionText="Xem chi tiết"
                icon={<IconAction className="fa-light fa-eye" />}
                onClick={() => viewPdfRegistration(row.registrationId)}
              />
              {[REGISTRATION_STATUS.DRAFT].includes(row?.status) ? (
                <TableAction
                  titleActionText="Sửa"
                  icon={<IconAction className="fa-light fa-pen-to-square" />}
                  onClick={() => {
                    if (canEditRegistration)
                      navigate(`sua/${row.registrationId}`)
                    if (!canEditRegistration) setShowNoRight(true)
                  }}
                />
              ) : null}
              <TableAction
                type="dropdown"
                items={[
                  {
                    label: 'Tải xuống file PDF',
                    icon: <IconAction className="fa-light fa-file-download" />,

                    onClick: () => viewPdfRegistration(row.registrationId),
                  },
                  row.status !== 'DRAFT' && {
                    label: 'Xem lịch sử truyền nhận',
                    icon: (
                      <IconAction className="fa-light fa-clock-rotate-left" />
                    ),
                    onClick: () => {
                      setActiveRegistration(row)
                      showTransmissionLog()
                    },
                  },
                  ['TAX_RECEIVE', 'SENT_TAX'].includes(row.status) && {
                    label: 'Cập nhật trạng thái tờ khai',
                    icon: <IconAction className="fa-light far fa-rotate" />,
                    onClick: () => {
                      syncRegistration(row.messageId)
                    },
                  },
                  {
                    label: 'Xóa',
                    icon: <IconAction className="fa-light fa-trash" />,
                    onClick: () => {
                      if (canDeleteRegistration)
                        deleteRegistration(row.registrationId)
                      if (!canDeleteRegistration) setShowNoRight(true)
                    },
                  },
                ]}
              />
            </Space>
          )
        },
      },
    ]
    return arr
  }, [
    deleteRegistration,
    navigate,
    syncRegistration,
    viewPdfRegistration,
    canEditRegistration,
    canDeleteRegistration,
  ])

  const dynamicColumns = useAppDnD(fullColumns)

  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilters])

  return (
    <ContentContainer wrapperClassName={'ListRegistration'}>
      <ContentContainer.Header
        titleContent={'Danh sách tờ khai'}
        description={`Tổng: ${registrationData?.total} tờ khai`}
        toolBar={
          <Space>
            <CustomAntInput
              borderRadius="sm"
              type="search"
              inputProps={{
                name: 'search',
                defaultValue: search,
                onChange: (e) => setSearch(e.target.value),
              }}
            />
            <CustomAntButton
              text={t('AddNew')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => actionCreateRegistration(),
              }}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={PreferenceKeys.savedColumnsRegistration}
            />
          </Space>
        }
      />
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={registrationData?.registrations}
          isLoading={gettingRegistrationData}
          isClickable
          handleDoubleClickRow={(row) =>
            viewPdfRegistration(row.registrationId)
          }
          pagination={{
            rowsPerPage: filters?.limit,
            total: registrationData?.total,
            currentPage: filters?.page,
            onChangePage: (page) =>
              setFilters({
                page,
              }),
            onChangeRowsPerPage: (page) => {
              setFilters({
                limit: page,
                page: 1,
              })
            },
          }}
        />
      </ContentContainer.Body>
      <ModalDataTransmissionLog
        show={showModalDataTransmissionLog}
        onHide={() => setShowModalDataTransmissionLog(false)}
        viewUrl={
          activeRegistration &&
          `/to-khai/xem/${activeRegistration.registrationId}`
        }
        registration={activeRegistration}
      />
      <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
    </ContentContainer>
  )
}

export default ListRegistration
