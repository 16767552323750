import customRegex from 'general/constants/AppRegex'
import { t } from 'i18next'
import { array, number, object, ref, string } from 'yup'
import Utils from './Utils'
export default {
  addNewGood: object({
    code: string().trim().nullable().required(t('Required')),
    name: string().trim().nullable().required(t('Required')),
    description: string().trim().nullable(),
    price: string()
      .trim()
      .nullable()
      .test('isValidType', t('PriceIsNotValid'), (val) =>
        Utils.checkIfValueIsValidFloatType(val),
      ),
    discountRate: string().trim().nullable(),
    // .test('isValidType', t('DiscountRateIsNotValid'), (val) =>
    //   Utils.checkIfValueIsValidFloatType(val),
    // ),
  }),
  filtering: object({
    type: string()
      .trim()
      .test('isEmpty', t('Required'), (val) => val !== ''),
    issueStatus: string()
      .trim()
      .test('isEmpty', t('Required'), (val) => val !== ''),
  }),
  createNewInvoice: object({
    customerTaxCode: string()
      .trim()
      .matches(/^[\d-]{10}(?:[\d-]{4})?$/, t('CheckNumberTaxCode')),
  }),
  sendMail: object({
    emailCustomer: string()
      .trim()
      // .email('Định dạng mail không hợp lệ')
      .required('Vui lòng nhập email'),
    type: array(string()).min(1, 'Chọn ít nhất 1 lựa chọn'),
  }),
  findInvoice: object({
    no: string().required('Bạn chưa nhập số hoá đơn'),
    serial: string().required('Bạn chưa nhập ký hiệu'),
    // taxAuthorityCode: string().required(t('Required')),
  }),
  createNewRoute: object({
    nameRoute: string().trim().required('Vui lòng nhập tên tuyến đường'),
    routeStart: string().trim().required('Điểm đi không được để trống'),
    routeEnd: string().trim().required('Điểm đến không được để trống'),
  }),
  createNewCheckPoint: object({
    nameLocationCheckTicket: string()
      .trim()
      .required('Cổng soát vé không được để trống'),
  }),
  enterpriseProfile: object({
    companyId: string().nullable(),
    taxCode: string()
      .matches(customRegex.taxCode, 'Mã số thuế không hợp lệ')
      .required('Không được để trống'),
    companyName: string().required('Không được để trống'),
    companyEmail: string()
      .email('Email không hợp lệ')
      .required('Không được để trống'),
    companyPhone: string().trim().nullable(),
    fax: string().matches(customRegex.fax, 'Fax không hợp lệ').nullable(),
    website: string()
      .matches(customRegex.website, 'Website không hợp lệ')
      .nullable(),
    address: string().nullable(),
    businessPermitAddress: string().required('Không được để trống'),
    contactPersonPosition: string().nullable(),
    contactPersonName: string().nullable(),
    contactPersonPhone: string().trim().nullable(),
    contactPersonEmail: string().email('Email không hợp lệ').nullable(),
    bankName: string().nullable(),
    bankAccountNumber: string()
      .matches(/^[0-9]+$/, 'Số tài khoản chỉ bao gồm các chữ số')
      .nullable(),
    provinceId: string().nullable(),
    taxAuthorityId: string().nullable(),
    invoiceDisplayedInfo: object({
      fax: string().matches(customRegex.fax, 'Fax không hợp lệ').nullable(),
      website: string()
        .matches(customRegex.website, 'Website không hợp lệ')
        .nullable(),
      email: string().email('Email không hợp lệ').nullable(),
      phone: string().nullable(),
    }),
    contactTaxInfo: object({
      fullName: string().nullable(),
      email: string().email('Email không hợp lệ').nullable(),
      phone: string().nullable(),
      address: string().nullable(),
    }),
  }),
  adjustReplaceTicket: object({
    ticketReason: string().required('Vui lòng nhập lí do'),
  }),
  connectConfig: object({
    agreementUUID: string()
      .matches(
        /^[0-9A-z]{8}-[0-9A-z]{4}-[0-9A-z]{4}-[0-9A-z]{4}-[0-9A-z]{12}$/,
        { message: 'AgreementUUID không đúng định dạng' },
      )
      .required('Vui lòng nhập UUID'),
    passcode: string().required('Vui lòng nhập passcode'),
  }),
  connectConfigNotPasscode: object({
    agreementUUID: string()
      .matches(
        /^[0-9A-z]{8}-[0-9A-z]{4}-[0-9A-z]{4}-[0-9A-z]{4}-[0-9A-z]{12}$/,
        { message: 'AgreementUUID không đúng định dạng' },
      )
      .required('Vui lòng nhập UUID'),
  }),
  transactionFilterSchema: object({
    totalAmountStart: number().nullable(),
    totalAmountEnd: number()
      .nullable()
      .min(
        ref('totalAmountStart'),
        'Số tiền kết thúc phải lớn hơn số tiền bắt đầu',
      ),
  }),
  transactionCancelledSchema: object({
    reason: string().required('Vui lòng nhập lý do hủy'),
  }),
  transactionMarkedSchema: object({
    note: string().required('Vui lòng nhập ghi chú'),
  }),
  signatureInfo: object({
    issuedBy: string().required('Vui lòng nhập tên tổ chức cơ quan chứng thực'),
    idValue: string().required('Vui lòng nhập số seri chứng thư'),
    issuedDate: string().required('Vui lòng nhập từ ngày'),
    expireDate: string().required('Vui lòng nhập đến ngày'),
  }),
  // Cổng soát vé
  ticketCheckPortalSchema: object({
    namePointCheckTicket: string().required('Vui lòng nhập tên cổng soát vé!'),
    serialDevice: string().required('Vui lòng nhập serial number!'),
  }),
}
