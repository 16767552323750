import invoiceApi from 'api/invoiceApi'
import { setAppNotInstalledToolNotiModalShow } from 'app/appSlice'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'

const updateInvoice = async (
  values,
  submitAction,
  invoiceProduct,
  company,
  dispatch,
  setAppSpinning,
  invoiceId,
  buyerInfoExtendFields,
  navigate,
  isFromPOS,
  isDeliveryBill,
  handleIssueFromPos,
  sendInvoiceModal,
) => {
  //   let invoiceProduct = [...tableData];
  if (values.customerTaxCode) {
    if (!(values.customerCompanyName && values.customerFullAddress)) {
      ToastHelper.showError(
        'Tên đơn vị và dịa chỉ người mua không được để trống khi có MST người mua',
      )
      return
    }
  }
  // item cuối không có name thì không gửi lên api
  if (invoiceProduct[invoiceProduct.length - 1].name === '') {
    invoiceProduct.pop()
  }
  const params = {
    ...values,
    invoiceProduct,
    buyerInfoExtendFields,
    total: parseFloat(values.total),
    totalAfterTax: parseFloat(values.totalAfterTax),
    taxMoney: parseFloat(values.taxMoney),
  }

  const navigateHandler = () => {
    if (isFromPOS)
      return '/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don'
    if (isDeliveryBill) return '/hoa-don-dau-ra/hoa-don/phieu-xuat-kho'
    return '/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don'
  }

  switch (submitAction) {
    case 'SaveNoNavigate':
      try {
        const res = await invoiceApi.update(invoiceId, params)
        if (res) {
          if (res.result === 'success') {
            ToastHelper.showSuccess('Cập nhật hoá đơn nháp thành công')
            // navigate(
            //   isFromPOS
            //     ? '/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don'
            //     : '/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don',
            // )
            sendInvoiceModal()
          }
        }
      } catch (err) {
        console.log(err)
        ToastHelper.showError('Cập nhật hoá đơn thất bại')
      }
      break
    case 'Save': {
      try {
        const res = await invoiceApi.update(invoiceId, params)
        if (res) {
          if (res.result === 'success') {
            ToastHelper.showSuccess('Cập nhật hoá đơn nháp thành công')
            navigate(navigateHandler())
          }
        }
      } catch (err) {
        console.log(err)
        ToastHelper.showError('Cập nhật hoá đơn thất bại')
      }
      break
    }

    case 'SaveAndSign': {
      try {
        const res = await invoiceApi.update(invoiceId, params)
        if (res) {
          if (res.result === 'success') {
            ToastHelper.showSuccess('Cập nhật hoá đơn nháp thành công')

            /* Trường hợp phát hành hóa đơn mtt */
            if (isFromPOS) {
              handleIssueFromPos(invoiceId)
              return
            }

            /* Trường hợp không phải hóa đơn mtt */
            let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
            navigate(navigateHandler())
            const urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},${invoiceId}`
            Utils.openToolSignInvoice(urlSchema, accessToken, () =>
              dispatch(setAppNotInstalledToolNotiModalShow(true)),
            )
          } else {
            ToastHelper.showSuccess('Cập nhật hoá đơn hoá đơn thất bại')
          }
        }
      } catch (err) {
        console.log(err)
        ToastHelper.showError('Cập nhật hoá đơn hoá đơn thất bại')
      }
      break
    }

    //huy
    case 'Preview': {
      dispatch(setAppSpinning(true))
      var res
      try {
        res = await invoiceApi.exportInvoice({ ...params })
      } catch (e) {
        console.log(e)
      } finally {
        dispatch(setAppSpinning(false))
      }

      let newFile = new File([res], 'Hóa đơn.pdf', {
        type: 'application/pdf',
      })
      var fileURL = URL.createObjectURL(newFile)
      window.open(fileURL, '_blank')
      break
    }

    default:
      return null
  }
}

export default updateInvoice
