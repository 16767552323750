import PropTypes from 'prop-types'
import { Accordion, Form } from 'react-bootstrap'
import './style.scss'

FolderTree.propTypes = {
  data: PropTypes.array,
  setCompanyUnitItem: PropTypes.func,
  multilchoice: PropTypes.bool,
  setCheckedAll: PropTypes.func,
  hasCheckedAllButton: PropTypes.bool,
  defaultCheckedAll: PropTypes.bool,
}

FolderTree.defaultProps = {
  data: [],
  setCompanyUnitItem: null,
  multilchoice: false,
  setCheckedAll: null,
  hasCheckedAllButton: false,
  defaultCheckedAll: false,
}

function FolderTree(props) {
  const {
    data,
    setCompanyUnitItem,
    multilchoice,
    // setCheckedAll,
    // hasCheckedAllButton,
    // defaultCheckedAll,
  } = props

  function handleChange(item, e) {
    if (setCompanyUnitItem) {
      setCompanyUnitItem(item, e)
    }
  }

  return (
    <div className="FolderTree">
      {data?.map((item, index) => {
        return (
          <div className="accordion-items" key={index}>
            <Accordion>
              <Accordion.Item className="position-relative" eventKey="">
                <Accordion.Header>
                  <div className="accordion-name">
                    <p> {item.name} </p>
                  </div>
                </Accordion.Header>
                <Form.Check
                  className="FolderTree_Input"
                  id={item.companyCompanyUnitId}
                  type="radio"
                  name="companyCompanyUnit"
                  value={item}
                  onChange={(e) => handleChange(item, e)}
                />

                <Accordion.Body
                  style={{ display: 'absolute', marginLeft: '30px' }}
                >
                  <div className="">
                    {
                      <FolderTree
                        multilchoice={multilchoice}
                        data={item?.ChildrenUnits}
                        setCompanyUnitItem={(item, e) => handleChange(item, e)}
                      />
                    }
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )
      })}
    </div>
  )
}

export default FolderTree
