import { useTranslation } from 'react-i18next'
import { SmileOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  ButtonTutorial,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalWrapper,
  StyleButtonClose,
} from 'features/TaxDeduction/styled'
import {
  ContainerContentModal,
  TreeSelectCustom,
} from 'features/Department/styled'
import {
  CheckboxCumstom,
  DatePickerCustom,
  FormCustom,
  InputCustom,
  InputNumberCumstom,
  RadioCustom,
  RedStar,
  SelectCustom,
  TextAreaCustom,
} from 'features/TaxDeductionDocuments/styled'
import { Empty, Form } from 'antd'
import { convertToOptions, removeAccents, toLower } from 'general/utils/Utils'
import { useEffect, useState } from 'react'
import { thunkGetAccountProfile } from 'app/authSlice'
import { useAppDispatch } from 'hooks/useRedux'
import { listFieldOfActivity } from 'general/constants/AppConstants'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { symbol } from 'prop-types'
import { unwrapResult } from '@reduxjs/toolkit'
import { thunkSaveDepartment } from 'features/Department/departmentSlice'
import { thunkOrganizationallevel } from 'features/Organizationallevel/organizationallevelSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import dayjs from 'dayjs'
function UpdateDepartment({
  openModal,
  changeOpenModal,
  departmentId,
  maxId = 0,
  companyId,
  dataTree,
  getAllDepartment,
  selectedKey,
  setSelectedKey,
  departmentActive,
  departmentIdActive,
  typeActive,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [listOrganizationallevel, setListOrganizationallevel] = useState([])
  const dateNow = new Date()
  const day = dateNow.getDate()
  const month = dateNow.getMonth() + 1
  const year = dateNow.getFullYear()
  const dispatch = useAppDispatch()

  const resetForm = async () => {
    form?.resetFields()
    if (departmentId) {
    } else {
      form?.resetFields()
      let data = {
        departmentCode: `D${companyId}D${day}M${month}Y${year}K${maxId}`,
        filiation: departmentIdActive ?? dataTree?.[0].value,
        fieldOfActivity: [],
      }
      form.setFieldsValue(data)
    }
  }

  useEffect(() => {
    if (openModal) {
      if (departmentActive) {
        let data = {
          ...departmentActive,
          fieldOfActivity: departmentActive?.fieldOfActivity
            ? departmentActive.fieldOfActivity.split(',')
            : [],
          unitHead: departmentActive?.unitHead
            ? departmentActive.unitHead.split(',')
            : [],
          registrationDate: departmentActive?.registrationDate
            ? dayjs(departmentActive?.registrationDate)
            : null,
          organizationallevelIdTemp:
            departmentActive?.type == 1 && t('department.tct'),
        }
        form.setFieldsValue(data)
      } else {
        resetForm()
      }
      getOrganizationallevel()
    }
    console.log('departmentActive', departmentActive)
  }, [openModal])

  const submit = async () => {
    form
      .validateFields()
      .then((values) => {
        setSelectedKey(null)
        save(values)
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo)
        // setSelectedKey(selectedKey)
        // ToastHelper.showError(t('department.errorMessage'))
      })
  }

  const save = async (values) => {
    const result = unwrapResult(
      await dispatch(
        thunkSaveDepartment({
          ...values,
          departmentId: departmentActive?.departmentId,
        }),
      ),
    )
    if (result?.message == 'success') {
      changeOpenModal(false)
      if (departmentActive) {
        ToastHelper.showSuccess(`${t('department.editMessage')}`)
      } else {
        ToastHelper.showSuccess(`${t('department.addMessage')}`)
      }

      getAllDepartment()
      setSelectedKey(selectedKey)
    } else {
      setSelectedKey(selectedKey)
      ToastHelper.showError(result?.data)
    }
  }

  const getOrganizationallevel = async () => {
    const result = unwrapResult(await dispatch(thunkOrganizationallevel()))
    setListOrganizationallevel(result?.data ?? [])
  }

  return (
    <>
      <ModalWrapper
        title={
          !departmentActive ? t('department.addfull') : t('department.editfull')
        }
        open={openModal}
        onCancel={() => changeOpenModal(false)}
        width={750}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    changeOpenModal(false)
                  }}
                >
                  {t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                <ButtonSave
                  type="primary"
                  onClick={() => {
                    submit()
                  }}
                >
                  {t('taxDeductionDocuments.save')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <ContainerContentModal>
          <FormCustom form={form} layout="vertical" widthLabel="160px">
            <div className="row" style={{ width: '100%' }}>
              <div className="col-8">
                <FormCustom.Item
                  layout="horizontal"
                  label={
                    <>
                      {`${t('department.namedv')} `}
                      <RedStar>*</RedStar>
                    </>
                  }
                  name={'departmentName'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              t('department.keyRequired', {
                                key: t('department.namedv'),
                              }),
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputCustom
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('department.namedv')),
                    })}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-4">
                <FormCustom.Item
                  label={
                    <>
                      {`${t('department.codedv')} `}
                      <RedStar>*</RedStar>
                    </>
                  }
                  name={'departmentCode'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              t('department.keyRequired', {
                                key: t('department.codedv'),
                              }),
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputCustom
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('department.codedv')),
                    })}
                    color={'#000000'}
                    maxLength={45}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-8">
                <FormCustom.Item
                  label={t('department.namet')}
                  name={'abbreviation'}
                >
                  <InputCustom
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('department.namet')),
                    })}
                    maxLength={255}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-4">
                <FormCustom.Item
                  label={t('department.textstt')}
                  name={'orders'}
                >
                  <InputNumberCumstom
                    style={{ width: 140 }}
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('department.textstt')),
                    })}
                    max={1000}
                    min={0}
                  />
                </FormCustom.Item>
              </div>
              {departmentActive?.type ? (
                <></>
              ) : (
                <div className="col-12">
                  <FormCustom.Item
                    label={
                      <>
                        {`${t('department.thdv')} `}
                        <RedStar>*</RedStar>
                      </>
                    }
                    name={'filiation'}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              new Error(
                                t('department.keyRequired', {
                                  key: t('department.thdv'),
                                }),
                              ),
                            )
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <TreeSelectCustom
                      placeholder={t('department.placehoderthdv')}
                      showSearch
                      style={{
                        width: '100%',
                      }}
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                      }}
                      allowClear
                      treeDefaultExpandAll
                      treeData={dataTree}
                    />
                  </FormCustom.Item>
                </div>
              )}
              <div className="col-12">
                <FormCustom.Item
                  label={
                    <>
                      {`${t('department.levertc')} `}
                      <RedStar>*</RedStar>
                    </>
                  }
                  name={'organizationallevelId'}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              t('department.keyRequired', {
                                key: t('department.levertc'),
                              }),
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  {departmentActive?.type != 1 ? (
                    <SelectCustom
                      showSearch
                      placeholder={t('department.plctc')}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={t('taxDeduction.noData')}
                        />
                      }
                      filterOption={(input, option) =>
                        removeAccents(option?.label ?? '')
                          .toLowerCase()
                          .includes(removeAccents(input).toLowerCase())
                      }
                      options={convertToOptions(
                        listOrganizationallevel,
                        'organizationallevelId',
                        'nameOrganizationallevel',
                      )}
                    />
                  ) : (
                    <InputCustom
                      disabled
                      maxLength={255}
                      placeholder={t('department.pltdv')}
                    />
                  )}
                </FormCustom.Item>
              </div>
              <div className="col-12">
                <FormCustom.Item label={t('taxpayer.address')} name={'address'}>
                  <InputCustom
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('taxpayer.address')),
                    })}
                    maxLength={255}
                  />
                </FormCustom.Item>
              </div>
              <div className="col-12">
                <FormCustom.Item
                  label={t('department.fieldOfActivity')}
                  name={'fieldOfActivity'}
                >
                  <CheckboxCumstom.Group>
                    <div className="row">
                      {listFieldOfActivity.map((item, index) => (
                        <div className="col-6 pb-2" key={index}>
                          <CheckboxCumstom
                            style={{ color: '#000000' }}
                            value={item}
                          >
                            {item}
                          </CheckboxCumstom>
                        </div>
                      ))}
                    </div>
                  </CheckboxCumstom.Group>
                </FormCustom.Item>
              </div>
              {/* <div className="col-12">
                <FormCustom.Item label={t('department.tdv')} name={'unitHead'}>
                  <SelectCustom
                    mode="multiple"
                    allowClear
                    showSearch
                    placeholder={t('department.pltdv')}
                    filterOption={(input, option) =>
                      removeAccents(option?.label ?? '')
                        .toLowerCase()
                        .includes(removeAccents(input).toLowerCase())
                    }
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('taxDeduction.noData')}
                      />
                    }
                  ></SelectCustom>
                </FormCustom.Item>
              </div> */}
              <div className="col-12">
                <FormCustom.Item
                  label={t('department.cnnv')}
                  name={'functionsAndDuties'}
                >
                  <TextAreaCustom
                    placeholder={t('department.keyPlacehoder', {
                      key: toLower(t('department.cnnv')),
                    })}
                    rows={4}
                    maxLength={2500}
                    showCount
                  />
                </FormCustom.Item>
              </div>
              {typeActive == 1 && (
                <>
                  <div className="col-8">
                    <FormCustom.Item
                      layout="horizontal"
                      label={t('department.sdkkd')}
                      name={'businessRegistrationNumber'}
                    >
                      <InputCustom
                        placeholder={t('department.keyPlacehoder', {
                          key: toLower(t('department.sdkkd')),
                        })}
                      />
                    </FormCustom.Item>
                  </div>
                  <div className="col-4">
                    <FormCustom.Item
                      label={t('taxpayer.employeeIdentification_date')}
                      name={'registrationDate'}
                    >
                      <DatePickerCustom
                        locale={locale}
                        format="DD/MM/YYYY"
                        style={{ width: '100%' }}
                        placeholder={t('department.keyPlacehoder', {
                          key: toLower(t('department.codedv')),
                        })}
                      />
                    </FormCustom.Item>
                  </div>
                  <div className="col-12">
                    <FormCustom.Item
                      layout="horizontal"
                      label={t('taxpayer.employeeIdentification_city')}
                      name={'placeOfIssue'}
                    >
                      <InputCustom
                        placeholder={t('department.keyPlacehoder', {
                          key: toLower(
                            t('taxpayer.employeeIdentification_city'),
                          ),
                        })}
                        maxLength={255}
                      />
                    </FormCustom.Item>
                  </div>
                </>
              )}
            </div>
          </FormCustom>
        </ContainerContentModal>
      </ModalWrapper>
    </>
  )
}
export default UpdateDepartment
