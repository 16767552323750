import { Checkbox, Flex } from 'antd'
import { onShowColumn } from 'app/tableSettingSlice'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { memo } from 'react'
import { Draggable } from 'react-beautiful-dnd'

function DnDColumnList() {
  const items = useAppSelector((s) => s.table.visualAppears)
  const dispatch = useAppDispatch()
  return items.map((item, index) =>
    !item.isAppear ? null : (
      <Draggable
        isDragDisabled={item.disabled}
        key={item.id}
        draggableId={item.id ?? item?.name}
        index={index}
      >
        {(draggableProvided, draggableSnapshot) => (
          <div
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className="snapshot-drag user-select-none "
            ref={draggableProvided.innerRef}
          >
            <Flex
              align="center"
              justify="space-between"
              className={Utils.cn(
                index !== 0 ? 'border-top' : '',
                draggableSnapshot.isDragging ? 'border bg-light' : '',
              )}
              style={{
                cursor: draggableSnapshot.isDragging ? 'grabbing' : 'grab',
                pointerEvents: item.disabled ? 'none' : 'auto',
                opacity: item.disabled ? 0.5 : 1,
              }}
            >
              <div className="p-3 d-flex align-items-center">
                <Checkbox
                  checked={!item.hidden}
                  id={item.id}
                  onChange={(event) => dispatch(onShowColumn(event))}
                >
                  {item.name}
                </Checkbox>
              </div>
              <span className="p-3 border-right">
                <i className="fa-solid fa-bars"></i>
              </span>
            </Flex>
          </div>
        )}
      </Draggable>
    ),
  )
}
export default memo(DnDColumnList)
