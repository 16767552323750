import React, { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'

import './style.scss'
import RegistrationHelper from 'general/helpers/RegistrationHelper'
import Utils from 'general/utils/Utils'
import { parse } from 'js2xmlparser'

function Message202(props) {
  const { message } = props

  const isSent = useMemo(() => message.messageId?.startsWith('V'), [message])
  const TGNhan = useMemo(
    () =>
      message?.contentRaw?.TDiep?.DLieu?.HDon?.DSCKS?.CQT?.Signature?.Object
        ?.SignatureProperties.SignatureProperty.SigningTime,
    [message],
  )

  const isReceived = useMemo(
    () => !message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.DSLDKCNhan,
    [message],
  )
  const taxAuthorityCode = useMemo(
    () => message.contentRaw?.TDiep?.DLieu?.HDon?.MCCQT['#'],
    [message],
  )
  const LDos = useMemo(() => {
    let LDos = message.contentRaw?.TDiep?.DLieu?.TBao?.DLTBao?.DSLDKCNhan?.LDo
    if (!LDos) return
    return Array.isArray(LDos) ? LDos : [LDos]
  }, [message])

  return (
    <div className="Message202 border-bottom mb-5">
      <div className="d-flex flex-row">
        <div style={{ maxWidth: '500px' }}>
          <p className="mb-2">
            <i
              className={`fa-regular ${
                isSent ? 'fa-arrow-up' : 'fa-arrow-down'
              } mr-2 ${isSent ? 'i-send' : 'i-receivce'}`}
            ></i>
            <span className="font-weight-bold">
              {TGNhan && dayjs(TGNhan).format('DD/MM/YYYY hh:mm:ss')}
            </span>
          </p>

          <p className="mb-2">
            {`[${
              message.typeCode
            }] ${RegistrationHelper.renderRegistrationTypeCode(
              message.typeCode,
            )}`}
            Thông điệp gửi hóa đơn điện tử tới cơ quan thuế để cấp mã
          </p>

          {LDos && (
            <div className="p-3" style={{ backgroundColor: '#fff3cd' }}>
              {LDos?.map(({ MLoi, MTa }, index) => (
                <p key={index} className="mb-2">
                  {`[${MLoi}] ${MTa}`}
                </p>
              ))}
            </div>
          )}

          <p className="mb-2">
            Mã CQT:{' '}
            <a href="#" title="Xem thông báo">
              {taxAuthorityCode}
            </a>
          </p>

          <p className="mb-2" style={{ color: '#c4c4c4' }}>
            {`Mã thông điệp: ${message.messageId}`}
          </p>
        </div>
        <div className="text-right flex-fill">
          <p className="mb-2 font-weight-bold">
            {isSent ? '0106870211' : 'Cơ quan thuế'}
          </p>

          <p className="mb-2">
            <span
              className="text-dark-75 font-weight-normal"
              style={{
                borderRadius: '40px',
                backgroundColor: isReceived ? '#a9efb7' : '#fff3cd',
                color: isReceived ? '#12a744' : '#856404',
                padding: '3px 12px',
              }}
            >
              CQT cấp mã
            </span>
          </p>

          <p
            className="mb-2 cursor-pointer"
            onClick={() => {
              Utils.exportXmlFile({
                data: message.contentRaw.TDiep,
                fileName: 'thong-diep-202',
                _parse: true,
              })
            }}
            title="Xuất khẩu XML"
          >
            <i
              className="fa-regular fa-download"
              style={{ color: 'inherit' }}
            ></i>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Message202
