import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import invoiceApprovalProcessApi from 'api/invoiceApprovalProcessApi' // Update the path accordingly
import ToastHelper from 'general/helpers/ToastHelper'

export const fetchInvoiceApprovalProcesses = createAsyncThunk(
  'invoiceApprovalProcesses/fetchInvoiceApprovalProcesses',
  async (params, thunkApi) => {
    try {
      const response = await invoiceApprovalProcessApi.getAllByCompanyId(params)
      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

export const createInvoiceApprovalProcess = createAsyncThunk(
  'invoiceApprovalProcesses/createInvoiceApprovalProcess',
  async (params, thunkApi) => {
    try {
      const response = await invoiceApprovalProcessApi.create(params)
      ToastHelper.showSuccess('Quy trình duyệt hoá đơn đã được tạo thành công')
      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

export const updateInvoiceApprovalProcess = createAsyncThunk(
  'invoiceApprovalProcesses/updateInvoiceApprovalProcess',
  async (params, thunkApi) => {
    try {
      const response = await invoiceApprovalProcessApi.update(params)
      ToastHelper.showSuccess(
        'Quy trình duyệt hoá đơn đã được cập nhật thành công',
      )
      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

export const deleteInvoiceApprovalProcess = createAsyncThunk(
  'invoiceApprovalProcesses/deleteProcess',
  async (params, thunkApi) => {
    try {
      const response = await invoiceApprovalProcessApi.deleteProcess(params)
      ToastHelper.showSuccess('Quy trình duyệt hoá đơn đã được xóa thành công')
      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  },
)

// Define additional async thunks for update and delete if needed

const invoiceApprovalProcessSlice = createSlice({
  name: 'invoiceApprovalProcesses',
  initialState: {
    processes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceApprovalProcesses.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchInvoiceApprovalProcesses.fulfilled, (state, action) => {
        state.loading = false
        state.processes = action.payload
      })
      .addCase(fetchInvoiceApprovalProcesses.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(createInvoiceApprovalProcess.fulfilled, (state, action) => {
        state.processes.push(action.payload)
      })
      .addCase(updateInvoiceApprovalProcess.fulfilled, (state, action) => {
        const updatedProcess = action.payload
        console.log(action.payload)
        const index = state.processes.findIndex(
          (process) =>
            process.invoiceApprovalProcessId ==
            updatedProcess.invoiceApprovalProcessId,
        )
        if (index !== -1) {
          state.processes[index] = updatedProcess
        }
      })
      .addCase(deleteInvoiceApprovalProcess.fulfilled, (state, action) => {
        const { invoiceApprovalProcessId } = action.payload
        state.processes = state.processes.filter(
          (process) =>
            process.invoiceApprovalProcessId !== invoiceApprovalProcessId,
        )
      })
  },
})

// Export reducer and actions
const {
  // actions,
  reducer,
} = invoiceApprovalProcessSlice
export default reducer
