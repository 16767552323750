import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from 'hooks/useRedux'
import { shallowEqual } from 'react-redux'

const initialState = {
  /** @type {InvoiceFromPos[]} */
  selectedRows: [],
  toggleClearRows: false,
}

const invoiceFromPosSlice = createSlice({
  name: 'invoiceFromPos',
  initialState,
  reducers: {
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload
    },
    setToggleClearRows: (state) => {
      state.toggleClearRows = !state.toggleClearRows
    },
  },
})

export const useInvoiceFromPosSelector = () =>
  useAppSelector((state) => state.invoiceFromPos, shallowEqual)

export const {
  actions: invoiceFromPosActions,
  reducer: invoiceFromPosReducer,
} = invoiceFromPosSlice
