import React from 'react'
import PropTypes from 'prop-types'
import AppResource from 'general/constants/AppResource'

EInvoiceFromCashRegisters.propTypes = {}

function EInvoiceFromCashRegisters(props) {
  return (
    <div className="position-relative">
      <img src={AppResource.images.imgInvoiceFromCashRegisterProcessBG} />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '16px', top: '96px' }}
        src={AppResource.images.imgDangKySuDungHDDT}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '16px', top: '492px' }}
        src={AppResource.images.imgKhoiTaoMau}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '167px', top: '96px' }}
        src={AppResource.images.imgTieuHuy}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '151px', top: '492px' }}
        src={AppResource.images.imgThayDoiThongTinDangKy}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '262px', top: '303px' }}
        src={AppResource.images.imgLapHDDTKySo}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '421px', top: '303px' }}
        src={AppResource.images.imgCapMaCQT}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '574px', top: '303px' }}
        src={AppResource.images.imgGuiHoaDonChoNguoiMua}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '721px', top: '303px' }}
        src={AppResource.images.imgKySoGuiMTTDenCQT}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '834px', top: '303px' }}
        src={AppResource.images.imgThongBaoHDDTSaiSot}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '988px', top: '96px' }}
        src={AppResource.images.imgThongBaoChoNguoiMuaVeSaiSot}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '988px', top: '303px' }}
        src={AppResource.images.imgLapHoaDonThayThe}
      />
      <img
        className="cursor-pointer"
        style={{ position: 'absolute', left: '988px', top: '492px' }}
        src={AppResource.images.imgLapHoaDonDieuChinh}
      />
    </div>
  )
}

export default EInvoiceFromCashRegisters
