import axiosClient from './axiosClient'

const invoiceReportApi = {
  create: (params) => {
    const url = '/report/create'
    return axiosClient.post(url, params)
  },

  createAdjustReport: (params) => {
    const url = '/adjust-report/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = `/report/update`
    return axiosClient.put(url, params)
  },

  updateAdjustReport: (params) => {
    const url = '/adjust-report/update'
    return axiosClient.put(url, params)
  },

  preview: (params) => {
    const url = `/report/export`

    return axiosClient.post(url, params, { responseType: 'blob' })
  },

  sendCancellationReportToCustomer: (params) => {
    const url = '/report/send-to-customer'

    return axiosClient.post(url, params)
  },

  hsmReportSign: (params) => {
    const url = '/report/hsm-report-sign'

    return axiosClient.post(url, params)
  },
}

export default invoiceReportApi
