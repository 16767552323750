import React from 'react'
import { useState, useEffect } from 'react'
import './style.scss'
import Table from 'react-bootstrap/Table'
import Empty from 'general/components/Empty'
import AppResource from 'general/constants/AppResource'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CarItem from '../CarItem'
import { Fragment } from 'react'
import {
  getAllCart,
  updateNumberUp,
  updateNumberDown,
  remove,
} from 'features/Cart/CartSlice'
function ListCart() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const HeaderCart = [
    {
      name: 'Gói dịch vụ',
      align: '',
    },
    {
      name: 'Đơn vị tính',
      align: '',
    },
    {
      name: 'Số lượng',
      align: 'text-right',
    },
    {
      name: 'Đơn giá',
      align: 'text-right',
    },
    {
      name: 'Thành tiền',
      align: 'text-right',
    },
  ]

  const [dataCart, setDataCart] = useState([])
  const res = useSelector((state) => state.cart.cartItems)

  useEffect(() => {
    setDataCart(res)
  }, [res])

  const updateNumberUpHandle = async (cartId) => {
    await dispatch(updateNumberUp({ cartId: cartId, amount: 1 }))
    await dispatch(getAllCart())
  }

  const updateNumberDownHandle = async (cartId) => {
    await dispatch(updateNumberDown({ cartId: cartId, amount: -1 }))
    await dispatch(getAllCart())
  }

  const removeHandle = async (cartId) => {
    await dispatch(remove(cartId))
    await dispatch(getAllCart())
  }

  return (
    <div className="ListCart mb-2 mb-lg-0">
      <Table className="mb-0 bg-white rounded ">
        {dataCart?.length > 0 && (
          <thead>
            <tr>
              {HeaderCart?.map((item, index) => (
                <th key={index} className={`${item?.align}`}>
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {dataCart?.length > 0 ? (
            <Fragment>
              {dataCart?.map((item) => (
                <Fragment key={item?.cartId}>
                  <CarItem
                    itemCart={item}
                    updateNumberUp={updateNumberUpHandle}
                    updateNumberDown={updateNumberDownHandle}
                    remove={removeHandle}
                  />
                </Fragment>
              ))}
              <tr>
                <td colSpan={5}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      variant="outline-primary py-2"
                      onClick={() => {
                        navigate('/hoa-don-dau-ra/he-thong/mua-them')
                      }}
                    >
                      Mua thêm
                    </Button>
                  </div>
                </td>
              </tr>
            </Fragment>
          ) : (
            <tr>
              <td colSpan={5}>
                <div className="Content_13 py-10" align="center">
                  <Empty
                    imageEmpty={AppResource?.images?.noCart}
                    text=""
                    visible={false}
                    imageEmptyPercentWidth={20}
                  />
                  <h2 className="fw-bold">Giỏ hàng trống</h2>
                  <p>
                    Chưa có mặt hàng nào trong giỏ. Chọn mua ít nhất 1 sản phẩm
                    để tiếp tục
                  </p>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate('/hoa-don-dau-ra/he-thong/mua-them')
                    }}
                    style={{ width: '200px' }}
                  >
                    Mua ngay
                  </Button>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default ListCart
