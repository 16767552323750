// import lifeStyleApi from 'api/lifeStyleApi';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Switch, Typography } from 'antd'
import goodsGroupApi from 'api/goodsGroupApi'
import { goodsGroupQueries } from 'features/Category/screens/GoodsGroupScreen/queries'
import { useFormik } from 'formik'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import ToastHelper from 'general/helpers/ToastHelper'
import { generateSchema } from 'general/utils/YupSchema/Category'
import _ from 'lodash'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * goodsGroupItem: object,
 * onExistDone: function,
 * onToggleClear: function,
 * }} props
 * @returns
 */

function ModalEditGoodsGroup(props) {
  // MARK: --- Params ---
  const ref = useRef(null)
  const { t } = useTranslation()
  const { show, onClose, active, goodsGroupItem, onExistDone, onToggleClear } =
    props
  const { mutateAsync, isPending } = useMutation({
    mutationKey: [goodsGroupQueries.base, { action: 'crud' }],
    mutationFn: (params) => {
      if (active === 'ADD' || active === 'REPLICATE')
        return goodsGroupApi.create(params)
      if (active === 'EDIT') return goodsGroupApi.update(params)
    },
  })

  // MARK: --- Functions ---

  const { submitForm, getFieldMeta, getFieldProps, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        goodsGroupId:
          active === 'ADD'
            ? ''
            : goodsGroupItem
              ? goodsGroupItem.goodsGroupId
              : '',
        code: active === 'ADD' ? '' : goodsGroupItem ? goodsGroupItem.code : '',
        name: active === 'ADD' ? '' : goodsGroupItem ? goodsGroupItem.name : '',
        status:
          active === 'ADD'
            ? 'ACTIVE'
            : goodsGroupItem
              ? goodsGroupItem.status
              : 'ACTIVE',
      },
      validationSchema: generateSchema(t).goodsGroup,
      enableReinitialize: true,
      onSubmit: async (values) => {
        // Utils.formatEmptyKey(values)
        // Utils.removeNullKey(values)
        // if (_.isNull(goodsGroupItem)) {
        //   values.goodsGroupId = ''
        // }
        debugger
        const res = await mutateAsync(values)
        if (res?.result !== 'success') return
        ToastHelper.showSuccess(
          active === 'ADD'
            ? t('CreateGoodsGroupSuccessful')
            : active === 'EDIT'
              ? t('UpdateGoodsGroupInforSuccessful')
              : t('ReplicateGoodsGroupSuccessful', {
                  name: values.name,
                }),
        )
        handleClose()
        onToggleClear()
        onExistDone()
      },
    })

  function handleClose(status) {
    onClose(status)
    resetForm()
  }

  return (
    <DraggableModal
      isOpen={show}
      title={
        active === 'ADD'
          ? t('NewGoodsGroup')
          : active === 'EDIT'
            ? t('UpdateGoodsGroup')
            : t('ReplicateGoodsGroup')
      }
      onOpenChange={handleClose}
      applyText={t('Save')}
      handleApplyChanges={submitForm}
      isLoading={isPending}
      ref={ref}
      styles={{
        body: {
          padding: '0px',
        },
      }}
    >
      <div className="container py-2 bg-light">
        <div className="row gap-3">
          {/* ma nhom hang hoa, dich vu */}
          <div className="col-lg-12">
            <CustomLabel
              htmlFor="code"
              isRequired
              text={t('GoodsGroupCode')}
              fontWeight={600}
              fontSize={12}
              width={'100%'}
            />
            <CustomAntInput
              type="text"
              inputProps={{
                ...getFieldProps('code'),
                onChange: (e) => setFieldValue('code', e.target.value),
                placeholder: `${_.capitalize(t('GoodsGroupCode'))}...`,
                status:
                  getFieldMeta('code').touched && getFieldMeta('code').error
                    ? 'error'
                    : undefined,
              }}
            />
            {getFieldMeta('code').touched && getFieldMeta('code').error && (
              <Typography.Text type="danger" className="font-size-sm">
                {getFieldMeta('code').error}
              </Typography.Text>
            )}
          </div>
          {/* ten nhom hang hoa, dich vu */}
          <div className="col-lg-12">
            <CustomLabel
              htmlFor="name"
              isRequired
              text={t('GoodsGroupName')}
              fontWeight={600}
              fontSize={12}
              width={'100%'}
            />
            <CustomAntInput
              type="text"
              inputProps={{
                ...getFieldProps('name'),
                onChange: (e) => setFieldValue('name', e.target.value),
                placeholder: `${_.capitalize(t('GoodsGroupName'))}...`,
                status:
                  getFieldMeta('name').touched && getFieldMeta('name').error
                    ? 'error'
                    : undefined,
              }}
            />
            {getFieldMeta('name').touched && getFieldMeta('name').error && (
              <Typography.Text type="danger" className="font-size-sm">
                {getFieldMeta('name').error}
              </Typography.Text>
            )}
          </div>
          <div className="col-lg-12">
            <CustomLabel
              htmlFor="status"
              text={t('Status')}
              fontWeight={600}
              fontSize={12}
              width={100}
            />
            <Switch
              checked={getFieldProps('status').value === 'ACTIVE'}
              onChange={(checked) => {
                setFieldValue('status', checked ? 'ACTIVE' : 'INACTIVE')
              }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            ></Switch>
          </div>
        </div>
      </div>
    </DraggableModal>
  )
}

export default ModalEditGoodsGroup
