import { useMutation, useQueryClient } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import {
  setClearRows,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import { useFormik } from 'formik'
import CustomButton from 'general/components/Button/CustomButton'
import BaseTextField from 'general/components/Forms/BaseTextField'
import FormLabel from 'general/components/Forms/FormLabel'
import GroupTextField from 'general/components/Forms/GroupTextTextField'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import './style.scss'

SendInvoiceModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

SendInvoiceModal.defaultProps = {
  show: false,
  onClose: null,
}

function SendInvoiceModal(props) {
  const { show, onHide, invoice, invoiceId } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const qc = useQueryClient()
  const filterParams = useAppSelector((s) => s.filter.invoiceList)
  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required(t('Required')),
    customerEmail: Yup.string()
      .email('Email không đúng định dạng')
      .nullable()
      .required(t('Required')),
    customerEmailCc: Yup.string().test(
      'is-valid-email-list',
      'Invalid email list format',
      (value) => {
        if (!value) return true

        const emailListPattern =
          /^([\w+-.%]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(;|$))+$/
        return emailListPattern.test(value)
      },
    ),
  })
  const sendMailMutation = useMutation({
    mutationKey: ['sent-invoice'],
    mutationFn: ({
      customerName,
      customerEmail,
      customerEmailCc,
      customerEmailBcc,
    }) =>
      invoiceApi.sendInvoiceToCustomer({
        invoiceId,
        customerName,
        customerEmail,
        ccEmails: customerEmailCc?.split(';')?.filter((item) => item) || [],
        bccEmails: customerEmailBcc?.split(';')?.filter((item) => item) || [],
        // dataInfo: {
        //   invoiceNo: invoice?.no ?? undefined,
        //   invoiceDate: invoice?.date,
        //   invoiceSerial: invoice?.serial,
        //   totalAmount: invoice?.totalAfterTax + invoice?.currency,
        // },
      }),
    onSettled: () => dispatch(setAppSpinning(false)),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSuccess: (res) => {
      if (res?.status !== 200 && res.result !== 'success') {
        ToastHelper.showError('Đã có lỗi trong quá trình gửi mail')
        throw new Error(res.statusText ?? res.result)
      }
      onHide()
      ToastHelper.showSuccess('Gửi email thành công')
      qc.invalidateQueries(['invoiceListData', filterParams], { exact: true })
      dispatch(setSelectedInvoices([]))
      dispatch(setClearRows())
    },
  })

  const formik = useFormik({
    initialValues: {
      customerEmail: invoice?.customerEmail || '',
      customerName: invoice?.customerName || '',
      customerEmailCc: undefined,
      customerEmailBcc: undefined,
    },
    validationSchema,
    onSubmit: async (values) => sendMailMutation.mutate(values),
    enableReinitialize: true,
    validateOnChange: false,
  })

  const [showCc, setShowCc] = useState(false)
  const [showBcc, setShowBcc] = useState(false)
  const { formatNumber } = useAppSelector((state) => state.systemConfig)

  const isDraft = useMemo(
    () => invoice?.issueStatus == 'NOT_SENT_TO_AUTHORITY',
    [invoice],
  )

  return (
    <Modal
      className="SendInvoiceModal"
      show={show}
      onHide={() => {
        formik.handleReset()
        onHide()
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="border-bottom-0" closeButton>
        <span
          className="font-weight-boldest"
          style={{ fontSize: '20px', color: '#333333' }}
        >
          {`Gửi hoá đơn ${isDraft ? 'nháp ' : ''}cho khách hàng`}
        </span>
      </Modal.Header>
      {/* <Modal.Body> */}
      <div
        className="d-flex flex-row p-5 justify-content-between"
        style={{ backgroundColor: '#f0f2f5' }}
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Ký hiệu:</FormLabel>
            <div className="w-100 d-flex align-items-center">
              {invoice?.serial}
            </div>
          </div>
          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Ngày hoá đơn:</FormLabel>
            <div className="w-100 d-flex align-items-center">
              <span>
                {invoice?.date && dayjs(invoice.date).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Khách hàng:</FormLabel>
            <div className="w-100 d-flex align-items-center">
              <span>{invoice?.customerCompanyName}</span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-end">
          <div
            className="d-flex align-items-center"
            style={{ height: '40px', fontSize: '14px', fontWeight: '500' }}
          >
            Tổng tiền thanh toán
          </div>
          <span style={{ fontSize: '24px', fontWeight: '600' }}>
            {_.isNumber(invoice?.totalAfterTax)
              ? Utils.formatNumber(
                  invoice?.totalAfterTax,
                  invoice?.numberFormat?.totalCost || formatNumber?.totalCost,
                ) +
                ' ' +
                invoice?.currency
              : invoice?.totalAfterTax + ' ' + invoice?.currency}
          </span>
        </div>
      </div>
      <div className="d-flex flex-column px-5 mt-5">
        <div className="d-flex flex-column" style={{ gap: '5px' }}>
          <span style={{ fontSize: '14px', fontWeight: '500' }}>
            Tên người nhận
          </span>
          <BaseTextField
            name="customerName"
            fieldProps={formik.getFieldProps('customerName')}
            fieldMeta={formik.getFieldMeta('customerName')}
            fieldHelper={formik.getFieldHelpers('customerName')}
          />
        </div>
        <div className="d-flex flex-column" style={{ gap: '5px' }}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span style={{ fontSize: '14px', fontWeight: '500' }}>Email</span>

            <div>
              <span
                className={`cursor-pointer ${!showCc ? 'text-blue' : ''}`}
                onClick={() => setShowCc(true)}
              >
                Cc
              </span>
              <span className="vr-span  m-2"></span>
              <span
                className={`cursor-pointer ${!showBcc ? 'text-blue' : ''}`}
                onClick={() => setShowBcc(true)}
              >
                Bcc
              </span>
            </div>
          </div>
          <BaseTextField
            name="customerEmail"
            fieldProps={formik.getFieldProps('customerEmail')}
            fieldMeta={formik.getFieldMeta('customerEmail')}
            fieldHelper={formik.getFieldHelpers('customerEmail')}
          />

          {showCc && (
            <GroupTextField
              name="customerEmailCc"
              fieldProps={formik.getFieldProps('customerEmailCc')}
              fieldMeta={formik.getFieldMeta('customerEmailCc')}
              fieldHelper={formik.getFieldHelpers('customerEmailCc')}
              inputGroupText="Cc"
              placeholder="Mỗi email cách nhau bởi dấu ;"
            />
          )}

          {showBcc && (
            <GroupTextField
              name="customerEmailCc"
              fieldProps={formik.getFieldProps('customerEmailBcc')}
              fieldMeta={formik.getFieldMeta('customerEmailBcc')}
              fieldHelper={formik.getFieldHelpers('customerEmailBcc')}
              inputGroupText="Bcc"
              placeholder="Mỗi email cách nhau bởi dấu ;"
            />
          )}
        </div>
      </div>
      {/* </Modal.Body> */}
      <Modal.Footer className="border-top-0">
        <CustomButton
          backgroundColor="white"
          text="Đóng"
          width="90px"
          color="#304FFD"
          onClick={props.onHide}
        ></CustomButton>
        <CustomButton
          backgroundColor="#304FFD"
          text="Gửi"
          width="90px"
          color="white"
          onClick={formik.handleSubmit}
        ></CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default SendInvoiceModal
