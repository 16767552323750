import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  DivFieldValue,
  DivTddCode,
  SpanFieldValue,
} from 'features/TaxDeductionDocuments/styled'
import {
  createDivFieldValue,
  createSpanFieldValue,
} from 'features/TaxDeductionDocuments/Utils/component'
import { groupByMerge } from 'general/utils/Utils'
import SpanFieldValueComponent from 'features/TaxDeductionDocuments/Utils/component/SpanFieldValueCp'
import DivFieldValueComponent from 'features/TaxDeductionDocuments/Utils/component/DivFieldValueCp'
function ValueContent({
  form,
  styleInput,
  data,
  language,
  textAlign,
  indexLayout,
  setTabActiveKey,
  activeFeild,
  setActiveFeild,
  activeLayout,
  indexTd,
  activeTd,
  formState,
  setVisibleSelectTaxpaper,
  setVisibleAddTaxpaper,
  listIndentify,
  setListIndentify,
  itemLayout,
  templateDefault,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      {language?.map((lang, index) => {
        // Assuming groupByMerge is a function that groups data appropriately
        const checkMerge = []
        const checkMergeValueSingle = []
        const groupedItems = groupByMerge(data)
        return (
          <div key={index}>
            {data.value?.map((dataItem, idx) => {
              const content = dataItem[lang]
              if (
                content &&
                !dataItem.hide &&
                (!content.transformationTemplate ||
                  formState.viewConversionTemplate)
              ) {
                return (
                  <DivTddCode key={idx} padding={content.labelStyle?.padding}>
                    {content.label ? (
                      dataItem.merge ? (
                        <DivTddCode display="flex">
                          {!checkMerge.includes(dataItem.merge) &&
                            groupedItems[dataItem.merge]?.map((item, id) => {
                              const contentItem = item[lang]
                              const widthDiv =
                                contentItem?.labelStyle?.width +
                                contentItem?.valueStyle?.width
                              return (
                                <>
                                  <DivTddCode
                                    width={widthDiv && `${widthDiv}px`}
                                  >
                                    {dataItem.merge && (
                                      <SpanFieldValueComponent
                                        form={form}
                                        styleInput={styleInput}
                                        value={contentItem.label}
                                        style={contentItem.labelStyle}
                                        funClick={() => {
                                          setActiveFeild({
                                            type: 'label',
                                            data: contentItem,
                                            index: item.indexArrayMerge,
                                          })
                                        }}
                                        type="label"
                                        activeFeild={activeFeild}
                                        indexLayout={indexLayout}
                                        activeLayout={activeLayout}
                                        idx={item.indexArrayMerge}
                                        itemFeild={item}
                                        indexTd={indexTd}
                                        activeTd={activeTd}
                                        language={lang}
                                        formState={formState}
                                        setVisibleSelectTaxpaper={
                                          setVisibleSelectTaxpaper
                                        }
                                        setVisibleAddTaxpaper={
                                          setVisibleAddTaxpaper
                                        }
                                        listIndentify={listIndentify}
                                        setListIndentify={setListIndentify}
                                        itemLayout={itemLayout}
                                        templateDefault={templateDefault}
                                      />
                                    )}
                                    {dataItem.merge && (
                                      <SpanFieldValueComponent
                                        form={form}
                                        styleInput={styleInput}
                                        value={contentItem.value}
                                        style={contentItem.valueStyle}
                                        funClick={() => {
                                          setActiveFeild({
                                            type: 'value',
                                            data: contentItem,
                                            index: item.indexArrayMerge,
                                          })
                                        }}
                                        type="value"
                                        activeFeild={activeFeild}
                                        indexLayout={indexLayout}
                                        activeLayout={activeLayout}
                                        idx={item.indexArrayMerge}
                                        itemFeild={item}
                                        indexTd={indexTd}
                                        activeTd={activeTd}
                                        language={lang}
                                        formState={formState}
                                        setVisibleSelectTaxpaper={
                                          setVisibleSelectTaxpaper
                                        }
                                        setVisibleAddTaxpaper={
                                          setVisibleAddTaxpaper
                                        }
                                        listIndentify={listIndentify}
                                        setListIndentify={setListIndentify}
                                        itemLayout={itemLayout}
                                        templateDefault={templateDefault}
                                      />
                                    )}
                                  </DivTddCode>
                                  {!checkMerge.includes(dataItem.merge) &&
                                    id ==
                                      groupedItems[dataItem.merge].length - 1 &&
                                    checkMerge.push(dataItem.merge) && <></>}
                                </>
                              )
                            })}
                        </DivTddCode>
                      ) : (
                        <DivTddCode textAlign={textAlign}>
                          <SpanFieldValueComponent
                            form={form}
                            styleInput={styleInput}
                            value={content.label}
                            style={content.labelStyle}
                            funClick={() => {
                              setActiveFeild({
                                type: 'label',
                                data: content,
                                index: idx,
                              })
                            }}
                            type="label"
                            activeFeild={activeFeild}
                            indexLayout={indexLayout}
                            activeLayout={activeLayout}
                            idx={idx}
                            itemFeild={dataItem}
                            indexTd={indexTd}
                            activeTd={activeTd}
                            language={lang}
                            formState={formState}
                            setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                            setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                            listIndentify={listIndentify}
                            setListIndentify={setListIndentify}
                            itemLayout={itemLayout}
                            templateDefault={templateDefault}
                          />
                          <SpanFieldValueComponent
                            form={form}
                            styleInput={styleInput}
                            value={content.value}
                            style={content.valueStyle}
                            funClick={() => {
                              setActiveFeild({
                                type: 'value',
                                data: content,
                                index: idx,
                              })
                            }}
                            type="value"
                            activeFeild={activeFeild}
                            indexLayout={indexLayout}
                            activeLayout={activeLayout}
                            idx={idx}
                            itemFeild={dataItem}
                            indexTd={indexTd}
                            activeTd={activeTd}
                            language={lang}
                            formState={formState}
                            setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                            setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                            listIndentify={listIndentify}
                            setListIndentify={setListIndentify}
                            itemLayout={itemLayout}
                            templateDefault={templateDefault}
                          />
                        </DivTddCode>
                      )
                    ) : dataItem.merge ? (
                      <DivTddCode display={'flex'}>
                        {!checkMergeValueSingle.includes(dataItem.merge) &&
                          groupedItems[dataItem.merge]?.map((item, id) => {
                            const contentItem = item[lang]
                            return (
                              <>
                                <DivFieldValueComponent
                                  form={form}
                                  styleInput={styleInput}
                                  content={contentItem}
                                  idx={id}
                                  funClick={() => {
                                    setActiveFeild({
                                      type: 'value',
                                      data: contentItem,
                                      index: item.indexArrayMerge,
                                    })
                                  }}
                                  type="value"
                                  activeFeild={activeFeild}
                                  indexLayout={indexLayout}
                                  activeLayout={activeLayout}
                                  itemFeild={item}
                                  indexTd={indexTd}
                                  activeTd={activeTd}
                                  language={lang}
                                  formState={formState}
                                  setVisibleSelectTaxpaper={
                                    setVisibleSelectTaxpaper
                                  }
                                  setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                                  listIndentify={listIndentify}
                                  setListIndentify={setListIndentify}
                                  itemLayout={itemLayout}
                                  templateDefault={templateDefault}
                                />
                                {!checkMergeValueSingle.includes(
                                  dataItem.merge,
                                ) &&
                                  id ==
                                    groupedItems[dataItem.merge].length - 1 &&
                                  checkMergeValueSingle.push(
                                    dataItem.merge,
                                  ) && <></>}
                              </>
                            )
                          })}
                      </DivTddCode>
                    ) : (
                      <>
                        <DivFieldValueComponent
                          form={form}
                          styleInput={styleInput}
                          content={content}
                          idx={idx}
                          funClick={() => {
                            setActiveFeild({
                              type: 'value',
                              data: content,
                              index: idx,
                            })
                          }}
                          type="value"
                          activeFeild={activeFeild}
                          indexLayout={indexLayout}
                          activeLayout={activeLayout}
                          itemFeild={dataItem}
                          indexTd={indexTd}
                          activeTd={activeTd}
                          language={lang}
                          formState={formState}
                          setVisibleSelectTaxpaper={setVisibleSelectTaxpaper}
                          setVisibleAddTaxpaper={setVisibleAddTaxpaper}
                          listIndentify={listIndentify}
                          setListIndentify={setListIndentify}
                          itemLayout={itemLayout}
                          templateDefault={templateDefault}
                        />
                      </>
                    )}
                  </DivTddCode>
                )
              }
              return null
            })}
          </div>
        )
      })}
    </>
  )
}

export default ValueContent
